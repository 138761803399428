import * as React from "react";
import { createStyles, Theme, WithStyles, withStyles } from '@material-ui/core/styles'
import { Provider } from "mobx-react";

const styles = (theme: Theme) => createStyles({

})

export interface IFieldValidator {
  validate?(value: string): boolean
}

interface IFormValidatorProps {
  autoComplete?: any
  name?: string
  className?: any
  onSubmit?(event: React.FormEvent<HTMLFormElement> | undefined): void 
  id?: string
  ref?: React.RefObject<HTMLFormElement>
  [rest:string]: any;
}

class FormValidator extends React.Component<WithStyles<typeof styles> & IFormValidatorProps> {

  state = {
    isValid: true
  }

  fields: any[]

  constructor(props: any) {
    super(props);
    this.fields = []
  }

  render() {
    const {
      onSubmit,
      ...rest
    } = this.props

    // We pass this FormValidator down to the form fields to run validation on each field. 
    const resources = {
      formvalidator: this
    }

    return (
      <Provider {...resources}>
        <form
          {...rest}
          onSubmit={this.onSubmit}
          noValidate={true}
        >
          {this.props.children}
        </form>
      </Provider>
    )
  }

  attachToForm = (component: any) => {
    this.fields.push(component)
  }

  private onSubmit = (event: React.FormEvent<HTMLFormElement> | undefined): void => {
    event?.preventDefault()

    const { onSubmit } = this.props

    // Validate fields
    const isValid = this.validateFields()
    this.setState({isValid})

    if (isValid && onSubmit) {
      onSubmit(event);
    }
  }

  private validateFields = (): boolean => {
    let isValid = true

    this.fields.forEach((field: any) => {
      if (field.validate) {
        const isFieldValid = field.props.autocompleteOptions ? field.validate(field.props.autocompleteOptions.value) : field.validate(field.props.value)
        if (!isFieldValid) {
          isValid = false
        }
      }
    })

    return isValid
  }
}

export default withStyles(styles)(FormValidator)