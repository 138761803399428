import {AgencyType} from "../API";
import {isoToLocalDate} from "../stores/StoreUtilities";
import {differenceInCalendarDays} from "date-fns";

export enum FederalAgency {
  AFCEC = "AFCEC",
  NAVFAC = "NAVFAC",
  USACE = "USACE"
}

export class Experience {
  id: string
  createdAt: string
  updatedAt: string
  userId: string
  profileId: string
  jobTitle: string
  agencyType: AgencyType
  agencyName: string
  employerName: string
  employerLocation: string
  projectLocation: string
  startDate: string
  endDate: string
  projectNo: string
  projectName: string
  projectDollarAmount: number
  jobDescription: string

  constructor (data: any) {
    this.id = data.id
    this.createdAt = data.createdAt
    this.updatedAt = data.updatedAt
    this.userId = data.userId
    this.profileId = data.profileId
    this.jobTitle = data.jobTitle
    this.agencyType = data.agencyType
    this.agencyName = data.agencyName
    this.employerName = data.employerName
    this.employerLocation = data.employerLocation
    this.projectLocation = data.projectLocation
    this.startDate = data.startDate
    this.endDate = data.endDate
    this.projectNo = data.projectNo
    this.projectName = data.projectName
    this.projectDollarAmount = data.projectDollarAmount
    this.jobDescription = data.jobDescription
  }

  get years() : number {
    if (this.startDate) {
      const startDate = isoToLocalDate(this.startDate)
      let endDate
      if (this.endDate) {
        endDate = isoToLocalDate(this.endDate)
      } else {
        endDate = new Date()
      }
      const diff = differenceInCalendarDays(endDate, startDate) / 365.0
      return Math.max(diff, 0)
    } else {
      return 0
    }
  }

  get isProject(): boolean {
    return this.agencyType !== null && this.agencyType !== undefined && this.agencyType !== AgencyType.None
  }

  get isFederal(): boolean {
    return (this.agencyType && this.agencyType === AgencyType.Federal)
  }
}

export default Experience