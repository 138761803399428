import * as React from 'react'
import {
  Box,
  Button,
  createStyles,
  FormGroup,
  Grid,
  Paper,
  Theme,
  Typography,
  withStyles,
  WithStyles,
  withWidth
} from '@material-ui/core';
import {RouteComponentProps} from '@reach/router';
import StateSelector from '../../components/form/StateSelector';
import {inject, observer} from 'mobx-react';
import {WithWidth} from '@material-ui/core/withWidth';
import {
  AccountStatus,
  AgreementType,
  CreateAccountInput,
  CreateAgreementInput,
  CreateContractInput,
  CreateUserInput,
  SubjectType,
  UserRole,
  UserStatus
} from "../../API";
import MarginRow from '../../components/page/MarginRow'
import {createUUID, getErrorMessage, phoneToE164Format} from '../../stores/StoreUtilities'
import {makeObservable, observable} from "mobx";
import DialogButton from "../../components/form/DialogButton";
import User from "../../model/User";
import ControlTower, {Routes} from "../../components/ControlTower";
import TextFieldValidator from "../../components/form/TextFieldValidator";
import FormValidator from "../../components/form/FormValidator";
import {CognitoAttribute, UserStoreConstants} from "../../stores/UserStore";
import CheckboxValidator from "../../components/form/CheckboxValidator";
import Logger from "../../components/Logger";
import TermsOfUse from "../../components/page/TermsOfUse";
import PrivacyPolicy from "../../components/page/PrivacyPolicy";
import Notify from "../../components/notify/Notify";
import Confirm from "../../components/confirm/Confirm";
import Tracking from "../../components/Tracking";
import Hidden from "@material-ui/core/Hidden";
import CircularProgress from '@material-ui/core/CircularProgress';
import {Auth} from "aws-amplify";
import ProgressButton from "../../components/form/ProgressButton";
import AccountStore from "../../stores/AccountStore";
import Page from "../../components/page/Page";
import SignupPromo from "./SignupPromo";
import Visible from "../../components/Visible";
import {isWidthUp} from "@material-ui/core/withWidth/withWidth";
import Account from "../../model/Account";
import JobStore from "../../stores/JobStore";
import IndustrySelector from "../../components/form/IndustrySelector";
import {ActivityType} from "../../model/UserActivity";

const styles = (theme: Theme) => createStyles({
  container: {
    fontFamily: theme.typography.body2.fontFamily,
    paddingTop: theme.spacing(2)
  },
  leftPanel: {
    [theme.breakpoints.up('md')]: {
      paddingRight: theme.spacing(2),
      paddingBottom: '25px',
    },
    [theme.breakpoints.down('md')]: {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      paddingBottom: '10px'
    },
  },
  rightPanel: {
    [theme.breakpoints.up('md')]: {
      paddingBottom: '25px',
    },
    [theme.breakpoints.down('md')]: {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      paddingBottom: '20px'
    },
    [theme.breakpoints.down('xs')]: {
      paddingBottom: 10,
    },

  },
  signUpContainer: {
    [theme.breakpoints.down('xs')]: {
      padding: 10,
    },
    [theme.breakpoints.up('sm')]: {
      padding: "30px 30px 0px 30px",
    },
  },
  submitButtonContainer: {
    paddingTop: theme.spacing(2),
  },
  submitButton: {
    color: "#fff",
  },
  formTitle: {
    [theme.breakpoints.down('xs')]: {
      marginTop: 0,
      marginBottom: 0,
      paddingTop: 0
    },
    [theme.breakpoints.up('sm')]: {
      marginBottom: 0
    }
  },
  formSubTitle: {
    color: '#181818',
    letterSpacing: '0.75px',
    fontWeight: 400,
    [theme.breakpoints.down('xs')]: {
      display: "none"
    },
  },
  form: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    [theme.breakpoints.down('md')]: {
      marginBottom: "50px"
    },
    [theme.breakpoints.down('xs')]: {
      marginBottom: "0px"
    },
  },
  formGroupTerms: {
    display: "flex",
    justifyContent: "flex-start",
    flexWrap: "nowrap",
    margin: 0,
    padding: 0,
    maxHeight: 40,
  },
  termsCheckbox: {
    flex: 0,
    width: 32,
    maxWidth: 32
  },
  termsLabel: {
    flex: 1,
    alignSelf: "center",
    color: '#252525',
    fontSize: '13px',
    paddingTop: 0,
    lineHeight: 1.2,
  },
  termsOfUse: {
    paddingTop: '16px'
  },
  termsOfUseText: {
    color: '#252525',
    fontSize: '13px',
    [theme.breakpoints.up('md')]: {
      paddingLeft: 36,
    },
    [theme.breakpoints.down('md')]: {
      paddingLeft: 22,
    },
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 36,
    },
    [theme.breakpoints.down('xs')]: {
      paddingLeft: 40,
    },
  },
  firstName: {
    [theme.breakpoints.up('sm')]: {
      paddingRight: theme.spacing(2)
    }
  },
  formGroupRow: {
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "nowrap"
  },
  formGroupField: {
    flexGrow: 1,
  },
  formGroupSpacing: {
    flexGrow: 0,
    width: 10,
  },
  formGroupSpacingSmUp: {
    flexGrow: 0,
    [theme.breakpoints.up('sm')]: {
      width: 10,
    },
  },
  stateSelectorField: {
    top: 5
  },
  zipCodeField: {
    width: 100
  },
  link: {
    textDecoration: 'none',
    color: theme.palette.primary.main
  },
  continueContainer: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  continueButton: {
    marginBottom: 20,
    color: "#FFF"
  },
  progressButton: {
    color: theme.palette.primary.main,
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  resendContent: {
    display: "flex",
    justifyContent: "flex-start",
    margin: 20
  },
  verifyText: {
    marginTop: 10
  },
  attrControlLabel: {
  },
  attrCheckbox: {
    paddingTop: 2,
    paddingBottom: 2
  },
  instructions: {
    marginTop: 10
  },
  stepContent: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    width: "100%"
  },
  terms: {
    height: "calc(50vh)",
    width: "100%",
    marginBottom: theme.spacing(1)
  },
  iframe: {
    width: "100%",
    height: "100%",
    border: "none",
    overflowY: "scroll"
  }

});


interface ISignupPageProps {
  promo?: string
  userStore?: any;
  accountStore?: AccountStore
  jobStore?: JobStore
  govGigAPI?: any
  notify?: Notify
  confirm?: Confirm
  termsOfUse?: TermsOfUse
  privacyPolicy?: PrivacyPolicy
  location?: any
}

interface ICognitoError {
  __type: string;
  code: string;
  message: string;
}

enum SignupStep {
  Promo,
  Application,
  Verification,
  TermsOfUse,
  PrivacyPolicy,
  MasterServiceAgreement,
  ConfidentialityAgreement
}

@inject("userStore", "accountStore", "jobStore", "govGigAPI", "notify", "confirm", "termsOfUse", "privacyPolicy")
@observer
class SignupPage extends React.Component<WithStyles<typeof styles> & RouteComponentProps & ISignupPageProps & WithWidth, {}> {
  private userId = "";

  @observable step = SignupStep.Application
  @observable isContinued = false
  @observable showTerms = false
  @observable showPrivacy = false
  @observable isSubmitted = false
  @observable forgotPassword = false
  @observable showTermsOfUse = false
  @observable isProcessing = false
  @observable verifyAttr = CognitoAttribute.PHONE_NUMBER
  @observable atBottom = false
  @observable completedAgreementTypes: AgreementType[] = []

  private cognitoUser?: any
  private user?: User

  private _message = ""

  constructor(
    props: WithStyles<typeof styles> & RouteComponentProps & ISignupPageProps & WithWidth
  ) {
    super(props);
    makeObservable(this);
  }

  set message(msg: string) {
    this._message = msg
    if (msg !== "") {
      this.props.notify!.show("info", msg)
    } else {
      this.props.notify!.close()
    }
  }
  get message() {
    return this._message
  }

  private _error = ""
  set error(err: string) {
    this._error = err
    if (err !== "") {
      this.props.notify!.show("error", err)
    } else {
      this.props.notify!.close()
    }
  }
  get error() {
    return this._error
  }

  state = {
    user: User,
    termsCheckbox: false,
    privacyCheckbox: false,
    msaCheckbox: false,
    accountName: "",
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    phone: "",
    addressLine1: "",
    addressCity: "",
    addressState: "",
    addressPostalCode: "",
    accountPhone: "",
    accountWebUrl: "",
    verificationCode: "",
    accountId: createUUID(),
    role: UserRole.Employer,
    // dunsNumber: "",
    // fein: "",
    // naicsCode: "",
    // sicCode: "",
    // cageCode: "",
    socioEconomicDesignation: "",
    industries: []
  }

  componentDidMount() {
    window.addEventListener('message', this.handleAtBottom)
  }

  componentWillUnmount() {
    window.removeEventListener('message', this.handleAtBottom)
  }

  handleAtBottom = (event: any) => {
    if (event?.data === 'at-bottom') {
      console.log(`Signup atBottom = true`)
      this.atBottom = true
    }
  }

  render() {
    const { classes, width } = this.props;

    let promoContent = <SignupPromo onJoin={this.handleContinue}/>

    return (
      <Page title="Sign Up">
        <MarginRow>
          <Grid
            className={classes.container}
            container
            direction="row"
            justifyContent="center"
            alignItems="stretch"
          >
            <Hidden xsDown>
              <Visible if={this.step === SignupStep.Application || isWidthUp('lg', width)}>
                <Grid item lg={6} className={classes.leftPanel}>
                  {promoContent}
                </Grid>
              </Visible>
              <Grid item xs={12} lg={6} className={classes.rightPanel}>
                <Paper elevation={3} className={classes.signUpContainer}>
                  <Grid container justifyContent="center">
                    <Grid item xs={12}>
                      <Typography align="center" color="primary" variant="h3" className={classes.formTitle}>
                        Employer Sign Up
                      </Typography>
                      <Typography
                        align="center"
                        gutterBottom={true}
                        variant="subtitle1"
                        className={classes.formSubTitle}>
                        to find talent
                      </Typography>
                    </Grid>
                    {this.step === SignupStep.Application && (
                      <Grid item xs={12} sm={12}>
                        {this.renderSignupForm()}
                      </Grid>
                    )
                    }
                    {this.step === SignupStep.TermsOfUse && this.renderTermsOfUse()}
                    {this.step === SignupStep.PrivacyPolicy && this.renderPrivacyPolicy()}
                    {this.step === SignupStep.MasterServiceAgreement && this.renderMasterServiceAgreement()}
                    {this.step === SignupStep.ConfidentialityAgreement && this.renderConfidentialityAgreement()}
                    {this.step === SignupStep.Verification && this.renderVerifyForm()}
                  </Grid>
                </Paper>
              </Grid>
            </Hidden>
            <Hidden smUp>
              {!this.isContinued &&
                <React.Fragment>
                  <Grid item xs={12} className={classes.leftPanel}>
                    {promoContent}
                  </Grid>
                  <Grid item xs={12} className={classes.continueContainer}>
                    <Button variant="contained" color="primary" size="large" fullWidth
                            className={classes.continueButton} onClick={this.handleContinue}>
                      Continue
                    </Button>
                  </Grid>
                </React.Fragment>
              }
              {this.isContinued &&
                <React.Fragment>
                  <Grid item xs={12} md={6} className={classes.rightPanel}>
                    <Paper elevation={3} className={classes.signUpContainer}>
                      <Grid container justifyContent="center">
                        <Grid item xs={12}>
                          <Typography align="center" variant="h3" color="primary" className={classes.formTitle}>
                            Apply Now
                          </Typography>
                          <Typography
                            align="center"
                            gutterBottom={true}
                            variant="subtitle1"
                            className={classes.formSubTitle}>
                            to get hired
                          </Typography>
                        </Grid>
                        {this.step === SignupStep.Application && (
                          <div>
                            {this.renderSignupForm()}
                          </div>
                        )
                        }
                        {this.step === SignupStep.TermsOfUse && this.renderTermsOfUse()}
                        {this.step === SignupStep.PrivacyPolicy && this.renderPrivacyPolicy()}
                        {this.step === SignupStep.MasterServiceAgreement && this.renderMasterServiceAgreement()}
                        {this.step === SignupStep.ConfidentialityAgreement && this.renderConfidentialityAgreement()}
                        {this.step === SignupStep.Verification && this.renderVerifyForm()}
                      </Grid>
                    </Paper>
                  </Grid>
                </React.Fragment>
              }
            </Hidden>
          </Grid>
        </MarginRow>
      </Page>
    )
  }

  renderSignupForm = () => {
    const { classes, accountStore } = this.props;

    return <div>
      <Grid item xs={12} sm={12}>
        <FormValidator
          id="employerSignUpForm"
          name="employerSignUpForm"
          className={classes.form}
          onSubmit={this.handleSignUp}
          autoComplete="on"
        >
          <FormGroup row classes={{row: classes.formGroupRow}}>
            <TextFieldValidator
              margin="normal"
              name="firstName"
              label="First Name"
              type="text"
              value={this.state.firstName}
              validators={{required:true}}
              required
              onChange={this.changeHandler}
              className={classes.formGroupField}
            />
            <div className={classes.formGroupSpacing}/>
            <TextFieldValidator
              margin="normal"
              name="lastName"
              label="Last Name"
              type="text"
              value={this.state.lastName}
              validators={{required:true}}
              required
              onChange={this.changeHandler}
              className={classes.formGroupField}
            />
          </FormGroup>
          <FormGroup row classes={{ row: classes.formGroupRow }}>
            <TextFieldValidator
              margin="normal"
              name="email"
              label="Email Address"
              type="email"
              validators={{ required: true, isEmail: null }}
              required
              value={this.state.email}
              onChange={this.changeHandler}
              className={classes.formGroupField}
            />
            <div className={classes.formGroupSpacing} />
            <TextFieldValidator
              margin="normal"
              name="password"
              label="Password"
              type="password"
              validators={{ required: true, isStrongPassword: 3 }}
              required
              value={this.state.password}
              autoComplete="new-password"
              onChange={this.changeHandler}
              helperText="8+ chars + 1 digit or symbol"
              className={classes.formGroupField}
            />
          </FormGroup>
          <TextFieldValidator
            margin="dense"
            name="phone"
            label="Mobile Phone"
            // helperText="Numbers only"
            type="text"
            validators={{required:true, isMobilePhone:null}}
            required
            onChange={this.changeHandler}
            fullWidth
            value={this.state.phone}
          />
          <TextFieldValidator
            margin="normal"
            name="accountName"
            label="Company Name"
            type="text"
            value={this.state.accountName}
            validators={{required:true}}
            required
            onChange={this.changeHandler}
            fullWidth
          />
          <TextFieldValidator
            margin="dense"
            name="addressLine1"
            label="Street Address"
            type="text"
            validators={{required:true}}
            required
            onChange={this.changeHandler}
            fullWidth
            value={this.state.addressLine1}
          />
          <FormGroup row classes={{row: classes.formGroupRow}}>
            <TextFieldValidator
              margin="dense"
              name="addressCity"
              label="City"
              type="text"
              validators={{required:true}}
              required
              onChange={this.changeHandler}
              value={this.state.addressCity}
              className={classes.formGroupField}
            />
            <div className={classes.formGroupSpacing}/>
            <StateSelector
              value={this.state.addressState}
              onChange={this.changeHandler}
              className={classes.stateSelectorField}
            />
            <div className={classes.formGroupSpacing}/>
            <TextFieldValidator
              margin="dense"
              name="addressPostalCode"
              label="ZIP"
              type="text"
              validators={{required:true, isPostalCode:null}}
              required
              onChange={this.changeHandler}
              value={this.state.addressPostalCode}
              className={classes.zipCodeField}
            />
          </FormGroup>

          <FormGroup row classes={{ row: classes.formGroupRow }}>
            <TextFieldValidator
              margin="normal"
              name="accountPhone"
              label="Company Phone"
              type="text"
              validators={{required:true, isMobilePhone:null}}
              required
              value={this.state.accountPhone}
              onChange={this.changeHandler}
              className={classes.formGroupField}
            />
            <div className={classes.formGroupSpacing} />
            <TextFieldValidator
              margin="normal"
              name="accountWebUrl"
              label="Company Web Address"
              type="text"
              validators={{ required: false }}
              value={this.state.accountWebUrl}
              onChange={this.changeHandler}
              className={classes.formGroupField}
            />
          </FormGroup>

          {/*<FormGroup row classes={{ row: classes.formGroupRow }}>*/}
          {/*  <TextFieldValidator*/}
          {/*    margin="normal"*/}
          {/*    name="dunsNumber"*/}
          {/*    label="DUNS Number"*/}
          {/*    type="text"*/}
          {/*    validators={{ required: true, matches: "^\\d{2}-\\d{7}$|^\\d{9}$" }}*/}
          {/*    required*/}
          {/*    value={this.state.dunsNumber}*/}
          {/*    onChange={this.changeHandler}*/}
          {/*    className={classes.formGroupField}*/}
          {/*    placeholder="12-3456789"*/}
          {/*  />*/}
          {/*  <div className={classes.formGroupSpacing} />*/}
          {/*  <TextFieldValidator*/}
          {/*    margin="normal"*/}
          {/*    name="fein"*/}
          {/*    label="FEIN"*/}
          {/*    type="text"*/}
          {/*    validators={{ required: true, matches: "^\\d{2}-\\d{7}$|^\\d{9}$" }}*/}
          {/*    required*/}
          {/*    value={this.state.fein}*/}
          {/*    onChange={this.changeHandler}*/}
          {/*    className={classes.formGroupField}*/}
          {/*    placeholder="12-3456789"*/}
          {/*  />*/}
          {/*</FormGroup>*/}

          {/*<FormGroup row classes={{ row: classes.formGroupRow }}>*/}
          {/*  <TextFieldValidator*/}
          {/*    margin="normal"*/}
          {/*    name="naicsCode"*/}
          {/*    label="NAICS Code"*/}
          {/*    type="text"*/}
          {/*    validators={{ required: false }}*/}
          {/*    value={this.state.naicsCode}*/}
          {/*    onChange={this.changeHandler}*/}
          {/*    className={classes.formGroupField}*/}
          {/*  />*/}
          {/*  <div className={classes.formGroupSpacing} />*/}
          {/*  <TextFieldValidator*/}
          {/*    margin="normal"*/}
          {/*    name="sicCode"*/}
          {/*    label="SIC Code"*/}
          {/*    type="text"*/}
          {/*    validators={{ required: false }}*/}
          {/*    value={this.state.sicCode}*/}
          {/*    onChange={this.changeHandler}*/}
          {/*    helperText=""*/}
          {/*    className={classes.formGroupField}*/}
          {/*  />*/}
          {/*</FormGroup>*/}
          {/*<TextFieldValidator*/}
          {/*  margin="normal"*/}
          {/*  name="cageCode"*/}
          {/*  label="CAGE Code"*/}
          {/*  type="text"*/}
          {/*  validators={{ required: false }}*/}
          {/*  value={this.state.cageCode}*/}
          {/*  onChange={this.changeHandler}*/}
          {/*  fullWidth*/}
          {/*/>*/}
          <TextFieldValidator
            margin="normal"
            name="socioEconomicDesignation"
            label="Socio-Economic Designation"
            type="text"
            validators={{ required: false }}
            value={this.state.socioEconomicDesignation}
            onChange={this.changeHandler}
            helperText="If applicable, select your company's SBA Socio-Economic Designation"
            fullWidth
            autocompleteOptions={{
              freeSolo: true,
              options: accountStore!.getSocioEconomicDesignationOptions()
            }}
          />
          <Box py={2}>
            <IndustrySelector value={this.state.industries} onChange={this.handleChangeIndustries}/>
          </Box>
          <div className={classes.submitButtonContainer}>
            <ProgressButton fullWidth={true} variant="contained" size="large" color="secondary"
                            type="submit" className={classes.submitButton} processing={this.isProcessing}>
              Continue
            </ProgressButton>
            <Button fullWidth variant="text" color="primary" type="button" onClick={this.handleCancel}>Cancel</Button>
          </div>
        </FormValidator>
      </Grid>
    </div>
  }

  renderVerifyForm() {
    const { classes } = this.props;

    return (
      <div className={classes.stepContent}>
        <FormValidator
          id="employerSignUpVerificationCodeForm"
          name="employerSignUpVerificationCodeForm"
          onSubmit={this.handleVerify}
          autoComplete="on"
        >
          <Typography variant="body2" paragraph={true} className={classes.instructions}>
            {`Please check your ${this.state.email} email for the verification code.`}
          </Typography>
          <TextFieldValidator
            margin="dense"
            name="verificationCode"
            label="Verification Code"
            type="text"
            value={this.state.verificationCode}
            validators={{ required: true, matches: "^\\d{6}$" }}
            onChange={this.changeHandler}
            fullWidth
          />
          <DialogButton variant="tertiary" onClick={this.onResendCode}>
            Resend verification email code
          </DialogButton>
          <div className={classes.submitButtonContainer}>
            <Button fullWidth={true} variant="contained" color="secondary"
                    type="submit" style={{ color: "#fff" }} disabled={this.isProcessing}>
              Continue
              {this.isProcessing && <CircularProgress size={24} className={classes.progressButton} />}
            </Button>
            <Button fullWidth variant="text" color="primary" type="button" onClick={this.handleCancel}>Cancel</Button>
          </div>
        </FormValidator>
      </div>)
  }

  renderTermsOfUse() {
    const { classes } = this.props

    return (
      <div className={classes.stepContent}>
        <FormValidator
          id="employerSignUpTermsOfUseForm"
          name="employerSignUpTermsOfUseForm"
          onSubmit={this.handleTermsOfUse}
          autoComplete="on"
        >
          <Typography variant="body2" paragraph={true} className={classes.instructions}>
            Please review and accept the GovGig Terms of Use.<br/>
            <b>You must review the entire document.</b>
          </Typography>
          <div className={classes.terms}>
            <iframe id="termsOfUse" src={Routes.terms} title="Terms Of Service" className={classes.iframe}>
            </iframe>
          </div>
          <Box display="flex" flexGrow={1} justifyContent="space-between" alignItems="center">
            <Box display="flex" flexGrow={1} justifyContent="flex-start">
              {this.renderTermsCheckbox("termsCheckbox", "I ACCEPT the GovGig Terms of Use. (You must scroll to the bottom)", true)}
            </Box>
            <Box display="flex" flexGrow={0} justifyContent="flex-end">
              <Button variant="outlined" type="button"
                      color="primary" style={{ height:32 }} size="small"
                      onClick={() => this.printFrame("termsOfUse")}>
                Print
              </Button>
            </Box>
          </Box>
          <div className={classes.submitButtonContainer}>
            <Button fullWidth={true} variant="contained" color="secondary"
                    type="submit" style={{ color: "#fff" }}>
              Continue
            </Button>
            <Button fullWidth variant="text" color="primary" type="button" onClick={this.handleCancel}>Cancel</Button>
          </div>
        </FormValidator>
      </div>
    )
  }

  renderPrivacyPolicy() {
    const { classes } = this.props

    return (
      <div className={classes.stepContent}>
        <FormValidator
          id="employerSignUpPrivacyPolicyForm"
          name="employerSignUpPrivacyPolicyForm"
          onSubmit={this.handlePrivacyPolicy}
          autoComplete="on"
        >
          <Typography variant="body2" paragraph={true} className={classes.instructions}>
            Please review and accept the GovGig Privacy Policy.<br/>
            <b>You must review the entire document.</b>
          </Typography>
          <div className={classes.terms}>
            <iframe id="privacyPolicy" src={Routes.privacy} title="Privacy Policy" className={classes.iframe}>
            </iframe>
          </div>
          <Box display="flex" flexGrow={1} justifyContent="space-between" alignItems="center">
            <Box display="flex" flexGrow={1} justifyContent="flex-start">
              {this.renderTermsCheckbox("privacyCheckbox", "I ACCEPT the GovGig Privacy Policy. (You must scroll to the bottom)", true)}
            </Box>
            <Box display="flex" flexGrow={0} justifyContent="flex-end">
              <Button variant="outlined" type="button"
                      color="primary" style={{ height:32 }} size="small"
                      onClick={() => this.printFrame("privacyPolicy")}>
                Print
              </Button>
            </Box>
          </Box>
          <div className={classes.submitButtonContainer}>
            <ProgressButton fullWidth={true} variant="contained" size="large" color="secondary"
                            type="submit" className={classes.submitButton} processing={this.isProcessing}>
              Continue
            </ProgressButton>
            <Button fullWidth variant="text" color="primary" type="button" onClick={this.handleCancel}>Cancel</Button>
          </div>
        </FormValidator>
      </div>
    )
  }

  renderMasterServiceAgreement() {
    const { classes } = this.props

    return (
      <div className={classes.stepContent}>
        <FormValidator
          id="employerSignUpMasterServiceAgreementForm"
          name="employerSignUpMasterServiceAgreementForm"
          onSubmit={this.handleMasterServiceAgreement}
          autoComplete="on"
        >
          <Typography variant="body2" paragraph={true} className={classes.instructions}>
            Please review and accept the GovGig Master Services & Non-Solicitation Agreement.<br/>
            <em>You may press Skip, if you are only planning to create self-service job posts or are not able to complete this agreement on behalf of the company.</em>
          </Typography>
          <div className={classes.terms}>
            <iframe id="msa" src={Routes.msa} title="Master Services Agreement" className={classes.iframe}>
            </iframe>
          </div>
          <Box display="flex" flexGrow={1} justifyContent="space-between" alignItems="center">
            <Box display="flex" flexGrow={1} justifyContent="flex-start">
              {this.renderTermsCheckbox("msaCheckbox", <p>I ACCEPT the GovGig Master Services & Non-Solicitation Agreement.<br/>(You must scroll to the bottom)</p>, true)}
            </Box>
            <Box display="flex" flexGrow={0} justifyContent="flex-end">
              <Button variant="outlined" type="button"
                      color="primary" style={{ height:32 }} size="small"
                      onClick={() => this.printFrame("msa")}>
                Print
              </Button>
            </Box>
          </Box>
          <div className={classes.submitButtonContainer}>
            <ProgressButton fullWidth={true} variant="contained" size="large" color="secondary"
                            type="submit" className={classes.submitButton} processing={this.isProcessing}>
              Continue
            </ProgressButton>
            <Box display="flex" justifyContent="center">
              <Button fullWidth variant="text" color="primary" type="button" onClick={this.handleSkipMasterServiceAgreement}>Skip</Button>
              <Button fullWidth variant="text" color="primary" type="button" onClick={this.handleCancel}>Cancel</Button>
            </Box>
          </div>
        </FormValidator>
      </div>
    )
  }

  renderConfidentialityAgreement() {
    const { classes } = this.props

    return (
      <div className={classes.stepContent}>
        <FormValidator
          id="employerSignUpConfidentialityAgreementForm"
          name="employerSignUpConfidentialityAgreementForm"
          onSubmit={this.handleConfidentialityAgreement}
          autoComplete="on"
        >
          <Typography variant="body2" paragraph={true} className={classes.instructions}>
            Please review and accept the GovGig Confidentiality and Non-Disclosure Agreement.<br/>
            <em>You may press Skip, if you are only planning to create self-service job posts or are not able to complete this agreement on behalf of the company.</em>
          </Typography>
          <div className={classes.terms}>
            <iframe id="msa" src={Routes.nda} title="Confidentiality Agreement" className={classes.iframe}>
            </iframe>
          </div>
          <Box display="flex" flexGrow={1} justifyContent="space-between" alignItems="center">
            <Box display="flex" flexGrow={1} justifyContent="flex-start">
              {this.renderTermsCheckbox("confidentialityCheckbox", <p>I ACCEPT the GovGig Confidentiality and Non-Disclosure Agreement.<br/>(You must scroll to the bottom)</p>, true)}
            </Box>
            <Box display="flex" flexGrow={0} justifyContent="flex-end">
              <Button variant="outlined" type="button"
                      color="primary" style={{ height:32 }} size="small"
                      onClick={() => this.printFrame("msa")}>
                Print
              </Button>
            </Box>
          </Box>
          <div className={classes.submitButtonContainer}>
            <ProgressButton fullWidth={true} variant="contained" size="large" color="secondary"
                            type="submit" className={classes.submitButton} processing={this.isProcessing}>
              Continue
            </ProgressButton>
            <Box display="flex" justifyContent="center">
              <Button fullWidth variant="text" color="primary" type="button" onClick={this.handleSkipConfidentialityAgreement}>Skip</Button>
              <Button fullWidth variant="text" color="primary" type="button" onClick={this.handleCancel}>Cancel</Button>
            </Box>
          </div>
        </FormValidator>
      </div>
    )
  }

  renderTermsCheckbox = (name: string, label: any, required: boolean) => {
    const { classes } = this.props

    return (
      <FormGroup row classes={{ row: classes.formGroupTerms }}>
        <div className={classes.termsCheckbox}>
          <CheckboxValidator name={name} color="primary"
                             value="checked" required={required}
                             checked={this.state[name]}
                             disabled={!this.atBottom}
                             onChange={this.changeHandler} />
        </div>
        <div className={classes.termsLabel}>
          {label}
        </div>
      </FormGroup>
    )
  }

  printFrame = (id: string) => {
    const frame = window.frames[id]
    if (frame) {
      const contentWindow = frame.contentWindow
      if (contentWindow) {
        contentWindow.focus();
        contentWindow.print();
      }
    }

    return false;
  };

  scrollToTop() {
    window.scrollTo({top: 0, behavior: 'smooth'})
    this.atBottom = false
  }

  handleContinue = () => {
    this.isContinued = true
    this.scrollToTop()
  }

  handleSignUp = async () => {
    const values = this.state
    const { userStore } = this.props

    this.message = ""
    this.error = ""

    this.isProcessing = true

    this.userId = createUUID();

    // Check for an existing Cognito user by email
    this.cognitoUser = await Auth.signIn(values.email, values.password)
      .catch((err: any) => {
        if (err.code === UserStoreConstants.NOT_AUTHORIZED_EXCEPTION) {
          this.error = "A user with this email already exists. If you are upgrading from a customer account, be sure to use the same password."
        } else if (err.code !== UserStoreConstants.USER_NOT_FOUND_EXCEPTION && err.message !== UserStoreConstants.USER_NOT_FOUND) {
          this.error = err.message
          Tracking.event({action: "SignIn Error", label: this.error})
        }
      })

    if (this.cognitoUser) {
      // The username/password already exists.  Verify that the application process was completed.
      const user = await userStore!.getUser(this.cognitoUser.username)
      if (user) {
        this.error = "You have already signed up.  Please login to use GovGig."
        Auth.signOut()
      } else {
        // Send to TermsOfService step
        this.userId = this.cognitoUser.username
        this.step = SignupStep.TermsOfUse
        this.isProcessing = false
        return
      }
    }

    if (this.error) {
      this.isProcessing = false
      return
    }

    // Sign up and send email verification

    this.cognitoUser =
      await userStore.signUp(this.userId, values.password, values.email, undefined, values.accountId, values.role)
        .catch((result: ICognitoError) => {
          // Handle updating an existing customer to provider
          Logger.debug(`SignUp.signUp(${this.userId}) error`, result)
          this.error = result.message
        });

    this.isProcessing = false

    if (!this.error) {
      this.isSubmitted = true
      this.step = SignupStep.Verification
      this.scrollToTop()
    }

  }

  handleVerify = async () => {
    const values = this.state
    this.message = ""
    this.error = ""
    const userStore = this.props.userStore

    if (this.forgotPassword) {
      // Handle verification code for password reset
      userStore!.forgotPasswordSubmit(this.state.email, this.state.verificationCode, this.state.password)
        .then((_result: any) => {
          Logger.debug("forgotPasswordSubmit succeeded")
        })
        .catch((err: any) => {
          this.error = err.message
          Tracking.event({ action: "Forgot Password Error", label: this.error })
        })
    } else if (this.cognitoUser) {
      // Handle verification code for signup
      this.isProcessing = true

      await userStore!.confirmSignUp(this.userId, values.verificationCode)
        .catch((err: Error) => {
          this.error = getErrorMessage(err)
          this.isProcessing = false
        })

      if (this.error) {
        return
      }
    }

    Logger.debug("signInUser")
    await userStore!.signIn(this.userId, this.state.password)
      .then((cognitoUser: any) => {
        this.cognitoUser = cognitoUser
      })
      .catch((error: any) => {
        this.isProcessing = false
        Logger.debug("SignupPage.handleVerify signIn error", error)
        if (error.code === UserStoreConstants.NOT_AUTHORIZED_EXCEPTION && !this.forgotPassword) {
          // Password doesn't match original.  Reset password
          userStore!.forgotPassword(this.state.email).then((_result: any) => {
            this.forgotPassword = true
            this.state.verificationCode = ""
            this.error = "Password does not match. Please check your email for a password reset verification code"
          })
            .catch((forgotPasswordErr: any) => {
              this.message = forgotPasswordErr.message
            })
        } else if (error.message !== UserStoreConstants.USER_NOT_FOUND) {
          this.error = error.message
        }
      });

    if (this.error) {
      return
    }

    this.step = SignupStep.TermsOfUse
    this.scrollToTop()

    this.isProcessing = false
  }

  handleTermsOfUse = () => {
    this.completedAgreementTypes.push(AgreementType.TermsOfUse)
    this.step = SignupStep.PrivacyPolicy
    this.scrollToTop()
  }

  handlePrivacyPolicy = async () => {
    this.completedAgreementTypes.push(AgreementType.PrivacyPolicy)
    this.step = SignupStep.MasterServiceAgreement
    this.scrollToTop()
  }

  handleMasterServiceAgreement = async () => {
    this.completedAgreementTypes.push(AgreementType.MasterServicesAndNonSolicitation)
    this.step = SignupStep.ConfidentialityAgreement
    this.scrollToTop()
  }

  handleSkipMasterServiceAgreement = () => {
    this.step = SignupStep.ConfidentialityAgreement
    this.scrollToTop()
  }

  handleConfidentialityAgreement = async () => {
    this.completedAgreementTypes.push(AgreementType.ConfidentialityAndNonDisclosure)
    this.completeSignup()
  }

  handleSkipConfidentialityAgreement = async () => {
    this.completeSignup()
  }

  completeSignup = async () => {
    const { accountStore, userStore, notify } = this.props

    const user = await this.createUser()
    if (user) {
      const account = await this.createAccount()

      if (account) {
        accountStore?.init(account)
        await this.createDefaultJobGroup(account)

        this.isProcessing = false
        if (account.accountStatus === AccountStatus.Pending) {
          userStore!.signOut()
          ControlTower.route(Routes.accountPending)
        } else {
          notify!.show("success", "Welcome to GovGig!")
          ControlTower.route(Routes.dashboard)
        }
      }
    }

  }

  handleCancel = () => {
    ControlTower.route(Routes.home)
  }

  changeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const name = event.target.name
    if (name === "state") {
      this.setState({addressState: event.target.value})
    } else if (name === "email") {
      this.setState({
        [name]: event.target.value.toLowerCase()
      });
    } else {
      if (this.state[name] !== undefined) {
        this.setState({
          [name]: event.target.value
        });
      }
    }
    // Clear message
    this.message = ""
    this.error = ""
  };

  handleChangeIndustries = (industries: string[]) => {
    this.setState({industries: industries})
  }

  onResendCode = () => {
    const {userStore} = this.props
    this.message = ""
    this.error = ""

    userStore!.resendSignUp(this.userId)
      .then((_result: any) => {
        this.message = "Verification email requested"
      })
      .catch((err: Error) => {
        this.error = err.message
      })
  }

  createUser = async () => {
    const { userStore } = this.props

    this.isProcessing = true

    let accountId = this.state.accountId

    if (this.cognitoUser) {
      // Get the account attribute to make sure we use the correct id
      const accountValue = await userStore!.getUserAttribute(this.cognitoUser, CognitoAttribute.ACCOUNT)
      if (accountValue) {
        accountId = accountValue
        this.setState({accountId: accountValue})
      }
    }

    const input: CreateUserInput = {
      id: this.userId,
      accountId: accountId,
      active: true,
      userStatus: UserStatus.Registered,
      email: this.state.email,
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      phone: phoneToE164Format(this.state.phone),
      role: this.state.role,
      address: this.state.addressLine1,
      city: this.state.addressCity,
      state: this.state.addressState,
      postalCode: this.state.addressPostalCode,
      country: "US",
    }

    Logger.debug("SignUp.createUser", input)
    const user = await userStore!.createUser(input)

    if (!user) {
      this.error = "Error creating user"
      this.isProcessing = false
      return
    }

    // Record Agreements
    const agreementInput: CreateAgreementInput = {
      userId: user.id,
      accountId: user.accountId,
      agreementTypes: this.completedAgreementTypes
    }

    const agreement = await userStore!.createAgreement(agreementInput)

    if (!agreement) {
      this.error = "Error saving agreement"
      this.isProcessing = false
      return
    }

    return user
  }

  createAccount = async () => {
    const {userStore, accountStore, notify} = this.props

    let user = userStore!.user

    if (!user) {
      return
    }

    const createAccountInput: CreateAccountInput = {
      id: user.accountId,
      ownerId: user.id,
      name: this.state.accountName,
      address: this.state.addressLine1,
      city: this.state.addressCity,
      state: this.state.addressState,
      postalCode: this.state.addressPostalCode,
      country: "US",
      phone: phoneToE164Format(this.state.accountPhone),
      webUrl: this.state.accountWebUrl,
      active: true,
      // dunsNumber: this.state.dunsNumber,
      // cageCode: this.state.cageCode,
      // naicsCode: this.state.naicsCode,
      // sicCode: this.state.sicCode,
      socioEconomicDesignation: this.state.socioEconomicDesignation,
      industries: this.state.industries,
      // fein: this.state.fein,
      accountStatus: AccountStatus.Approved
    }

    const account = await accountStore!.createAccount(createAccountInput)
      .catch((err: Error) => {
        notify!.show("error", "Unable to create account")
        Tracking.event({action: 'SignupError', label: `Unable to create account: ${err.message}`})
        this.isProcessing = false
      })

    if (account && account.accountStatus === AccountStatus.Approved) {
      await userStore!.logUserActivity(user.id, SubjectType.Account, account.id, ActivityType.AccountCreated, account.name)
      user = await userStore!.signIn(this.state.email.toLowerCase(), this.state.password)
        .catch((err: Error) => {
          notify!.show("error", "Unable to sign in new user")
          Tracking.event({action: 'SignupError', label: `Unable to sign in new user: ${err.message}`})
          this.isProcessing = false
        })
    }

    return account
  }

  createDefaultJobGroup = async (account: Account) => {
    const { jobStore } = this.props

    const input: CreateContractInput = {
      accountId: account.id,
      name: "General"
    }
    const jobGroup = await jobStore!.createContract(input)
    return jobGroup
  }

}

export default withWidth()(withStyles(styles)(SignupPage));
