import { Box, Paper, Theme, Typography, createStyles, makeStyles, useTheme } from "@material-ui/core"
import Stack from "../Stack"

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    width: "100%",
    // maxWidth: "100vw"
  }
}))

const SectionPanel = ({
  title, 
  elevation, 
  backgroundColor, 
  button, 
  children 
}: {
  title: string 
  elevation?: number 
  backgroundColor?: string 
  button?: React.ReactNode
  children: React.ReactNode
}) => {
  const theme = useTheme()
  const classes = useStyles() 
  return (
    <Paper
      elevation={elevation}
      className={classes.root}
    >
      <Box
        bgcolor={backgroundColor}
        borderRadius={theme.spacing(1)}
        width="100%"
        // pt={2}
        // maxWidth="100vw"
        pb={3}
      >
        <Stack
          direction="column"
          sx={{
            width: "100%"
          }}
          xs={12}
          wrap="nowrap"
        >
          <Stack
            direction="row"
            justifyContent="space-between"
            sx={{
              pb: 2,
              width: "100%"
            }}
          >
            <Box
              pt={2}
              pl={3}
            >
              <Typography 
                variant="h5" 
                color="primary"
              >
                {title}
              </Typography>
            </Box>
            { button && 
              <Box
                pt={2}
                pr={3}
              >
                {button} 
              </Box>
            }
          </Stack>
          <Box
            pl={3}
            pr={3}
          >
            {children}
          </Box>
        </Stack>
      </Box>
    </Paper>
  )
}

export default SectionPanel
