import {CognitoUser, CognitoUserAttribute} from "amazon-cognito-identity-js";
import {Auth} from "aws-amplify";
import {differenceInMinutes, fromUnixTime, isToday} from "date-fns";
import {computed, makeObservable, observable} from "mobx";
import * as APITypes from "../API";
import {
  AccountStatus,
  CreateUserActivityInput,
  ModelSortDirection,
  SubjectType,
  UpdateUserInput,
  UserRole,
  UserStatus
} from "../API";
import AdminAPI from "../apis/AdminAPI";
import GovGigAPI from "../apis/GovGigAPI";
import LocationAPI from "../apis/LocationAPI";
import ControlTower, {Routes} from "../components/ControlTower";
import Logger from "../components/Logger";
import Tracking from "../components/Tracking";
import Agreement from "../model/Agreement";
import JobPost from "../model/JobPost";
import Profile from "../model/Profile";
import User from "../model/User";
import UserActivity, {ActivityType} from "../model/UserActivity";
import {getISODateTime, getISODateToday, getISODateTodayAddDays, phoneToE164Format} from "./StoreUtilities";
import {NAICS} from "../model/Industry";
import {getCookie, setCookie} from "./CookieHelpers";
import AppConfig from "../AppConfig";
import { RoutesConfigHelpers } from "../components/RoutesConfigHelpers";
import { IRouteData } from "../RoutesConfig";

export const UserStoreConstants = {

  USER_ALREADY_CONFIRMED: "User cannot be confirm. Current status is CONFIRMED",
  CONFIRMATION_SUCCESS: "SUCCESS",
  USER_NOT_FOUND: "User not found",
  USER_ALREADY_EXISTS: "User already exists",
  USERNAME_EXISTS_EXCEPTION: "UsernameExistsException",
  EMAIL_EXISTS_MESSAGE: "PreSignUp failed with error Email exists.",
  USERNAME_NOT_CONFIRMED_EXCEPTION: "UserNotConfirmedException",
  USER_NOT_CONFIRMED: "User not confirmed",
  NOT_AUTHORIZED_EXCEPTION: "NotAuthorizedException",
  USER_NOT_FOUND_EXCEPTION: "UserNotFoundException",
  CODE_MISMATCH_EXEPTION: "CodeMismatchException",
  CONDITIONAL_REQUEST_FAILED: "The conditional request failed",
  EMAIL_VERIFICATION_PENDING: "Email verification pending",
  PHONE_VERIFICATION_PENDING: "Phone verification pending",
  COMPANY_EMAIL: ""
}

export interface ICognitoAttributes {
  email?: string,
  phone_number?: string,
  "custom:account"?: string,
  "custom:role"?: string
}

export const CognitoAttribute = {
  EMAIL: "email",
  PHONE_NUMBER: "phone_number",
  ACCOUNT: "custom:account",
  ROLE: "custom:role"
}

export const CognitoAttributeValue = {
  FALSE: "false",
  TRUE: "true"
}


class UserStore {
  @observable groups: string[] = []
  @observable user?: User
  @observable isLoading: boolean = true 
  @observable isAdmin: boolean = false
  @observable isPublic: boolean = false
  @observable industry?: string

  govGigAPI: GovGigAPI
  adminAPI: AdminAPI
  locationAPI: LocationAPI

  @observable private _cognitoUser?: CognitoUser
  get cognitoUser() {
    // Logger.debug(`UserStore get cognitoUser = ${this._cognitoUser?.getUsername()}`)
    return this._cognitoUser
  }

  set cognitoUser(cognitoUser: CognitoUser | undefined) {
    // Logger.debug(`UserStore set cognitoUser = ${cognitoUser?.getUsername()}`)
    this._cognitoUser = cognitoUser
  }

  private _attributes: CognitoUserAttribute[] = []

  constructor(options: any) {
    makeObservable(this);
    this.govGigAPI = (options && options.govGigAPI) ? options.govGigAPI : null
    this.adminAPI = (options && options.adminAPI) ? options.adminAPI: null
    this.locationAPI = (options && options.locationAPI) ? options.locationAPI: null
    this.industry = this.getIndustry()
  }
  
  /**
   * When switching between *.govgig.us properties, 
   * we need to sign out the user if they've logged in on another property,
   * otherwise too many cookies will build up and the site won't load,
   * resulting in a 431 error. 
   */
   async signOutCurrentAuthenticatedUser() {
    try {
      // const user: CognitoUser | undefined = await Auth.currentAuthenticatedUser()
      await Auth.currentAuthenticatedUser()
      // Logger.debug('Current authenticated user', user)
      await Auth.signOut()
    } catch (err) {
      // If we see err === "No current user," then we are not logged in, which is fine. 
      // Logger.debug('UserStore.signIn: ', err)
    } finally {
      this.deleteAllCookies()
    }
  }

  signUp = async (username: string, password: string, email: string, phone?: string, accountId?: string, role?: UserRole) => {
    this.cognitoUser = undefined
    this.user = undefined
    this._attributes = []

    await this.signOutCurrentAuthenticatedUser()

    return new Promise<CognitoUser | any>((resolve, reject) => {
      const attributes = {}
      if (email) {
        attributes[CognitoAttribute.EMAIL] = email.toLowerCase()
      }
      if (phone) {
        attributes[CognitoAttribute.PHONE_NUMBER] = phoneToE164Format(phone)
      }
      if (accountId) {
        attributes[CognitoAttribute.ACCOUNT] = accountId
      }
      if (role) {
        attributes[CognitoAttribute.ROLE] = role
      }

      Auth.signUp({
        username,
        password,
        attributes,
        validationData: []  // optional
      })
        .then(data => {
          resolve(data.user)
        })
        .catch(err => {
          reject(err)
        })
    })
  }

  confirmSignUp = async (username: string, code: string, options?: any): Promise<string | any> => {
    return await Auth.confirmSignUp(username, code, options)
  }

  resendSignUp = async (username: string): Promise<void> => {
    return await Auth.resendSignUp(username)
  }

  verifyUserAttribute = async (user: CognitoUser, attribute: string) => {
     return await Auth.verifyUserAttribute(user, attribute)
  }

  verifyUserAttributeSubmit = async (user: CognitoUser, attribute: string, code: string) => {
    return await Auth.verifyUserAttributeSubmit(user, attribute, code)
  }

  signIn = async (username: string, password: string) => {
    this.cognitoUser = undefined
    this.user = undefined
    this._attributes = []

    await this.signOutCurrentAuthenticatedUser()

    return new Promise<CognitoUser | any>((resolve, reject) => {
      Auth.signIn(username.toLowerCase(), password)
        .then(async (cognitoUser: any) => {
          if (cognitoUser.challengeName === "NEW_PASSWORD_REQUIRED") {
            // Logger.debug(`Auth.signIn(${username}) = ${cognitoUser.challengeName}`)
            resolve(cognitoUser)
          } else {
            // Logger.debug(`Auth.signIn(${username}) success`)
            // Load and initialize User
            this.initSession(cognitoUser)
              .then(user => {
                // this.createActivity(ActivityType.UserSignIn, result.id)
                this.logUserActivity(user.id, SubjectType.User, user.id, ActivityType.UserSignIn)
                resolve(user)
              })
              .catch(async (reason: any) => {
                // await this.signInAsGuest()
                reject(reason)
              })
          }
        }).catch(err => {
          if (err.code !== UserStoreConstants.USER_NOT_FOUND_EXCEPTION &&
            err.code !== UserStoreConstants.NOT_AUTHORIZED_EXCEPTION) {
            Logger.error("Auth.SignIn error.", err)
          }
          reject(err)
        })
    })
  }

  routeInitial = () => {
    const location = window.location

    const routes: IRouteData[] = Object.values(Routes).map(value => { 
      return { 
        "title": value, // We don't really need the title here, but we need to supply a value.  
        "pathname": value
      }  
    })
    const route = RoutesConfigHelpers.getRouteForPathname(location.origin, location.pathname, routes)

    console.debug("location", location, route)

    // If they are currently on the home page, then route them to the appropriate dashboard page
    if (route 
      && (route.pathname === Routes.home 
        || route.pathname === Routes.login
        || route.pathname === Routes.apply
        || route.pathname === Routes.signup
        || route.pathname === Routes.register)) {
      // const env = config.get("env")
      if (this.isAdminOrAgent) {
        // ControlTower.route((env === "staging" || env === "main") ? Routes.manage : Routes.manageJobs)
        ControlTower.route(Routes.dashboard)
      } else if (this.isApplicant) {
        ControlTower.route(Routes.profile)
      } else if (this.isCandidate) {
        ControlTower.route(Routes.findJobs)
      } else if (this.isEmployer) {
        // ControlTower.route(Routes.manageJobs)
        ControlTower.route(Routes.dashboard)
      }  
    }
  }

  reloadAuthenticatedUser = async (): Promise<User | null | undefined> => {
    const cognitoUser = await this.currentAuthenticatedUser()
    if (cognitoUser) {
      console.log("reloading current authenticated user")
      this.isLoading = true
      // Load and initialize User
      return this.initSession(cognitoUser)
        .then(async (result: any) => {
          console.log("Reloaded user from cache")
          return this.user
        })
        .catch(async (reason: any) => {
          this.isLoading = false
          console.log("Unable to load user from cache")
          return null
        })
    } else {
      console.log("No existing authenticated user found")
      this.isPublic = true 
      this.isLoading = false 
      return null
    }

  }

  signOut = async () => {
    return new Promise<any>((resolve, reject) => {
      this.currentAuthenticatedUser()
        .then((cognitoUser: CognitoUser) => {
          Auth.signOut()
            .then(() => {
              // Logger.debug("Auth.signOut success")
              this.cognitoUser = undefined
              this.user = undefined
              this.isAdmin = false
              this._attributes = []
              this.setIndustry(this.industry)
              if (this.checkInterval) {
                clearInterval(this.checkInterval)
                this.checkInterval = undefined
              }
              resolve(null)
            })
            .catch(err => {
              Logger.error("Auth.signOut error", err)
              reject(err)
            })
            // .finally(() => {
            //   this.deleteAllCookies()
            // })
        })
    })
  }

  currentSession = async () => {
    Auth.currentSession()
      .then(data => {
        return data
      })
      .catch(err => {
        console.log(`Auth.currentSession err: ${JSON.stringify(err)}`)
      });
  }

  currentAuthenticatedUser = async () => {
    const cognitoUser = await Auth.currentAuthenticatedUser()
      .catch(err => {
        this.cognitoUser = undefined
      })
    if (cognitoUser) {
      this.cognitoUser = cognitoUser
      return cognitoUser
    } else {
      this.cognitoUser = undefined
      return null
    }
  }

  getUserAttribute = async (cognitoUser: any, name: string) => {
    const attributes = await Auth.userAttributes(cognitoUser ? cognitoUser : this.cognitoUser)
    const attribute = attributes.find(a => a.getName() === name)
    if (attribute) {
      return attribute.getValue()
    } else {
      return null
    }
  }

  getUserAttributes = async (cognitoUser?: any) => {
    return await Auth.userAttributes(cognitoUser ? cognitoUser : this.cognitoUser)
  }

  updateUserAttributes = async (cognitoUser: any, attributes: any) => {
    return await Auth.updateUserAttributes(cognitoUser ? cognitoUser : this.cognitoUser, attributes)
  }


  initSession = async (cognitoUser: CognitoUser) => {

    console.log("UserStore.initSession")
    this.isLoading = true

    return new Promise<CognitoUser | any>(async (resolve, reject) => {
      this.cognitoUser = cognitoUser
      await this.getCurrentSessionPayload()
      const username = cognitoUser.getUsername()
      await this.checkAuthentication()
      if (this.locationAPI) {
        this.locationAPI.init()
      }

      this.loadUser(username)
        .then(async (user: any) => {
          await this.verifyRole(user)
          Tracking.set({ userId: user.email })
          this.isLoading = false
          resolve(user)
        })
        .catch((err: any) => {
          this.isLoading = false
          reject(err)
        })
    })
  }

  async verifyRole(user: User) {
    // Verify the cognito role matches the User record role and update if needed
    const attributes: CognitoUserAttribute[] = await this.getUserAttributes(this.cognitoUser)
    const updateAttributes: ICognitoAttributes = {}

    const role = user.role
    const customRole = attributes.find(attribute => attribute.getName() === CognitoAttribute.ROLE)
    if (customRole && customRole.getValue() !== role) {
      updateAttributes[CognitoAttribute.ROLE] = role
      console.log(`Updating ${user.email} role to ${role}`)
      await this.updateUserAttributes(null, updateAttributes)
    }
  }

  async getCurrentSessionPayload() {
    const session = await Auth.currentSession()
    // const authTimeValue = session.getIdToken().payload['auth_time']
    // const authTime = fromUnixTime(authTimeValue)
    // Logger.debug(`User authenticated at ${format(authTime!, "M/d/yyyy h:mm:ss aa")}`)
    // const payload = session.getIdToken().payload
    const groups = session.getIdToken().payload['cognito:groups']
    this.isAdmin = (groups && groups.indexOf("Admin") >= 0)
    return groups
  }

  @computed get isAuthenticated() {
    const isAuthenticated = this.cognitoUser !== undefined && this.user !== undefined
    // Logger.debug(`UserStore get isAuthenticated = ${isAuthenticated}`)
    return isAuthenticated
  }

  @computed get isAgent() {
    return this.user !== undefined ? this.user.isAgent : false
  }

  @computed get isAdminOrAgent() {
    return this.user !== undefined ? this.user.isAdminOrAgent : false
  }

  @computed get isEmployer() {
    return this.user !== undefined ? this.user.isEmployer : false
  }

  @computed get isCandidate() {
    return this.user !== undefined ? this.user.isCandidate : false
  }

  @computed get isApplicant() {
    return this.user !== undefined ? this.user.isApplicant : false
  }

  @computed get isStudent() {
    return this.user !== undefined ? this.user.isStudent : false
  }

  isProfileEditable = (profile: Profile): boolean => {
    return (this.user !== undefined && (this.user.id === profile.userId || this.user.isAdminOrAgent === true))
  }

  /**
   * Check current user, if user is employer and the job post belongs to their account, then show editing features.  
   */
  isJobPostEditable = (jobPost: JobPost): boolean => {
    const user = this.user 
    const account = user?.account
    if (user && account) {
      const isEmployerOwningJobPost = user.isEmployer && (account.id === jobPost.accountId)
      if (isEmployerOwningJobPost || this.isAdminOrAgent) {
        return true 
      }
    }
    return false 
  }

  checkInterval: any

  async checkAuthentication() {
    // Logger.debug("checkAuthentication")
    const redirectHome = async () => {
      ControlTower.route(`${Routes.home}?logout=true`)
    }
    try {
      const session = await Auth.currentSession()
      const authTimeValue = session.getIdToken().payload['auth_time']
      const authTime = fromUnixTime(authTimeValue)
      // Logger.debug(`Id Token Auth Time: ${idAuthTime.toLocaleDateString()} ${idAuthTime.toLocaleTimeString()}`)

      if (!isToday(authTime)) {  // Sign-out at midnight the day authenticated
        Logger.debug("Authentication expired")
        await this.signOut()
        redirectHome()
      }
    } catch (err) {
      Logger.error('checkAuthentication error', err)
      redirectHome() 
    }

    if (!this.checkInterval) {
      // Check authentication every hour
      this.checkInterval = setInterval(this.checkAuthentication, 60 * 60000)
    }
  }

  completeNewPassword = async (user: string, newPassword: string) => {
    return await new Promise<any>((resolve, reject) => {
      Auth.completeNewPassword(user, newPassword, null)
        .then(data => {
          // Logger.debug("Auth.completeNewPassword success")
          resolve(data)
        })
        .catch(err => {
          Logger.debug("Auth.completeNewPassword error", err)
          reject(err)
        });
    })
  }

  loadUser = async (userId: string): Promise<User | undefined> => {
    // Logger.debug(`UserStore.loadUser(${userId})`)

    const data = await this.govGigAPI.getUser(userId)
    console.log("Loaded user")

    if (data) {
      let user = new User(data)
      if (user) {
        if (user.account && user.account.accountStatus && user.account.accountStatus !== AccountStatus.Approved &&
            user.role !== UserRole.Student) {
          throw new Error(`Your account is ${user.account.accountStatus.toLowerCase()}. Please contact account owner or admin.`)
        } else if (user.userStatus && user.userStatus !== UserStatus.Invited && user.userStatus !== UserStatus.Registered) {
          throw new Error(`This user is ${user.userStatus.toLowerCase()}. Please contact account owner or admin.`)
        // } else if (user.role === UserRole.Student) {
        //   throw new Error(`This is a GovGig Academy student login. Please login at https://academy.govgig.us.`)
        } else {
          this.user = user
          if (this.user.profile && (this.user.isAdminOrAgent || this.user.isEmployer)) {
            // Drop profile for admin, agent, and employer
            this.user.profile = undefined
          }
          this.getIndustry()
          this.updateUserLastActiveAt(user)
          Logger.configureUser(this.user.id)
        }
      }
    } else {
      throw new Error("User not found")
    }

    return this.user
  }

  getUser = async (userId: string): Promise<User | undefined> => {
    const data = await this.govGigAPI.getUser(userId)
    if (data) {
      return new User(data)
    } else {
      return undefined
    }
  }

  getUserAndProfile = async (userId: string): Promise<User | undefined> => {
    const data = await this.govGigAPI.getUserAndProfile(userId)
    if (data) {
      const user = new User(data)
      if (user.profile) {
        user.profile.user = user
      }
      return user
    } else {
      return undefined
    }
  }

  async createUser(input: APITypes.CreateUserInput, setStoreUser: boolean = true) {
    if (input.phone) {
      input.phone = phoneToE164Format(input.phone)
    }
    const userRes = await this.govGigAPI!.createUser(input)
    if (userRes) {
      Tracking.event({ action: "Create Account" })
      //   const attributes: ICognitoAttributes = {}
      //   let updateAttributes = false
      //   if (user.accountId) {
      //     attributes[CognitoAttribute.ACCOUNT] = user.accountId
      //     updateAttributes = true
      //   }
      //   if (user.role) {
      //     attributes[CognitoAttribute.ROLE] = user.role
      //     updateAttributes = true
      //   }
      //   if (updateAttributes) {
      //     await this.updateUserAttributes(null, attributes)
      //   }

      const user = new User(userRes)
      if (setStoreUser) this.user = user
      this.logUserActivity(this.user ? this.user.id : user.id, SubjectType.User, user.id, ActivityType.UserCreated, user.email)
      return user
    } else {
      return null
    }
  }

  async updateUser(input: APITypes.UpdateUserInput) {

    if (input.phone) {
      input.phone = phoneToE164Format(input.phone)
    }
    const user = await this.govGigAPI!.updateUser(input)
    if (user) {
      const updatedUser = new User(user)

      if (this.user && user.id === this.user!.id) {
        // Verify custom account user attribute.  Only works for current authenticated user
        const accountValue = await this.getUserAttribute(null, CognitoAttribute.ACCOUNT)
        if (accountValue !== user.accountId) {
          const attributes: ICognitoAttributes = {}
          attributes[CognitoAttribute.ACCOUNT] = user.accountId
          console.log(`Updated custom:Account Cognito attribute: ${user.accountId}`)
          await this.updateUserAttributes(null, attributes)
        }
        const roleValue = await this.getUserAttribute(null, CognitoAttribute.ROLE)
        if (roleValue !== user.role) {
          const attributes: ICognitoAttributes = {}
          attributes[CognitoAttribute.ROLE] = user.role
          console.log(`Updated custom:Role Cognito attribute: ${user.role}`)
          await this.updateUserAttributes(null, attributes)
        }

        if (user.id === this.user!.id) {
          // Preserve related data
          updatedUser.account = this.user!.account
          this.user = updatedUser
        }
      }

      return updatedUser
    } else {
      return null
    }
  }
  
  async updateUserLastActiveAt(user: User) {
    if (user &&
      (!user.lastActiveAt || differenceInMinutes(new Date(), new Date(user.lastActiveAt)) > 5)) {
      const update: UpdateUserInput = {
        id: user.id,
        lastActiveAt: getISODateTime()
      }
      Logger.info(`Updated ${user.email} lastActiveAt to: ${update.lastActiveAt}`)
      return await this.updateUser(update)
    } else {
      return user
    }
  }

  async addUserToGroup(userId: string, groupName: string) {
    await this.adminAPI.addUserToGroup(userId, groupName)
  }

  async removeUserFromGroup(userId: string, groupName: string) {
    await this.adminAPI.removeUserFromGroup(userId, groupName)
  }

  async forgotPassword(userId: string) {
    return await Auth.forgotPassword(userId)
  }

  async forgotPasswordSubmit(userId: string, code: string, password: string) {
    return await Auth.forgotPasswordSubmit(userId, code, password)
  }

  // Profile methods

  getUserProfile = async (userId?: string): Promise<Profile | undefined> => {
    if (!userId) {
      if (this.user) {
        userId = this.user.id
      } else {
        return undefined
      }
    }

    const data = await this.govGigAPI.getUserProfiles(userId)
    if (data && data.profiles && data.profiles.items && data.profiles.items.length > 0) {
      return new Profile(data.profiles.items[0])
    } else {
      return undefined
    }
  }

  getProfile = async (profileId: string): Promise<Profile | undefined> => {
    const data = await this.govGigAPI.getProfile(profileId)
    if (data) {
      return new Profile(data)
    } else {
      return undefined
    }
  }

  getIndustry(): string | undefined {
    let industry: string | undefined
    if (this.user) {
      const user = this.user
      if (user.industry) {
        industry = user.industry
      } else if ((user.isApplicant || user.isCandidate) &&
                 user.profile && user.profile.industries &&
                 user.profile.industries.length > 0) {
        industry = user.profile.industries.length === 1 ?
                     user.profile.industries[0] : undefined // ALL
      } else if (user.account && user.account.industries &&
                 user.account.industries.length > 0) {
        industry = user.account.industries.length === 1 ?
                     user.account.industries[0] : undefined // ALL
      } else {
        industry = NAICS.Construction // Default
      }
    } else {
      // Use cookie storage instead of localStorage b/c we want to pull in the industry selection a user potentially made from the marketing site.
      // i.e. localStorage does not allow saving, restoring values from different subdomains. 
      // const localIndustry = localStorage.getItem("industry")
      // if (localIndustry) {
      //   industry = localIndustry
      // }
      // Get cookie (client-side)
      industry = getCookie('industry') as string
    }
    this.industry = industry
    return industry
  }

  setIndustry(value: string | undefined) {
    // console.debug("UserStore.setIndustry", value)
    if (this.user) {
      const input: APITypes.UpdateUserInput = {
        id: this.user.id,
        industry: value ?? null
      }
      // Intentionally left asynchronous
      this.updateUser(input)
    } else {
      // Use cookie storage instead of localStorage b/c we want to pull in the industry selection a user potentially made from the marketing site.
      // i.e. localStorage does not allow saving, restoring values from different subdomains. 
      // if (value) {
      //   localStorage.setItem("industry", value)
      // } else {
      //   localStorage.removeItem("industry")
      // }
      // Set cookie (client-side)
      setCookie('industry', value, {
        expires: new Date(getISODateTodayAddDays(365)),
        domain: AppConfig.domain,
        sameSite: 'strict'
      })
    }
    this.industry = value
  }

  async createProfile(input: APITypes.CreateProfileInput) {
    const data = await this.govGigAPI!.createProfile(input)
    if (data) {
      return new Profile(data)
    } else {
      return undefined
    }
  }

  async updateProfile(input: APITypes.UpdateProfileInput) {
    const data = await this.govGigAPI!.updateProfile(input)
    if (data) {
      return new Profile(data)
    } else {
      return undefined
    }
  }

  async getAgreement (agreementId: string): Promise<Agreement | undefined> {
    const data = await this.govGigAPI.getAgreement(agreementId)
    if (data) {
      const agreement = new Agreement(data)
      return agreement
    }

    return undefined
  }

  async createAgreement(input: APITypes.CreateAgreementInput) {
    const data = await this.govGigAPI!.createAgreement(input)
    if (data) {
      return new Agreement(data)
    } else {
      return undefined
    }
  }

  async updateAgreement(input: APITypes.UpdateAgreementInput) {
    const data = await this.govGigAPI!.updateAgreement(input)
    if (data) {
      return new Agreement(data)
    } else {
      return undefined
    }
  }

  async deleteAgreement(agreementId: string) {
    const data = await this.govGigAPI!.deleteAgreement(agreementId)
    if (data) {
      return new Agreement(data)
    } else {
      return undefined
    }
  }

  fullName(): string {
    let title = "Profile"
    if (this.isAuthenticated && this.user) {
      title = `${this.user.firstName} ${this.user.lastName}`
    }
    return title
  }

  // UserActivity methods 

  listUserActivityByUser = async (userId: string, filter?: APITypes.ModelUserActivityFilterInput): Promise<UserActivity[]> => {
    let userActivity: UserActivity[] = []

    const data = await this.govGigAPI.listUserActivityByUser(userId, filter)
    if (data && data.items) {
      userActivity = data.items.map((item: any) => {
        return new UserActivity(item)
      })
    }

    return userActivity
  }

  listUserActivityBySubject = async (subjectId: string,
                                     filter?: APITypes.ModelUserActivityFilterInput,
                                     sortDirection?: ModelSortDirection): Promise<UserActivity[]> => {
    let userActivity: UserActivity[] = []

    const data = await this.govGigAPI.listUserActivityBySubject(subjectId, filter, sortDirection)
    if (data && data.items) {
      userActivity = data.items.map((item: any) => {
        return new UserActivity(item)
      })
    }

    return userActivity
  }

  getUserActivity = async (jobInterestId: string): Promise<UserActivity | undefined> => {
    const data = await this.govGigAPI.getUserActivity(jobInterestId)
    if (data) {
      return new UserActivity(data)
    }

    return undefined
  }

  async createUserActivity(input:  APITypes.CreateUserActivityInput): Promise<UserActivity | undefined> {
    const data = await this.govGigAPI!.createUserActivity(input)
    if (data) {
      return new UserActivity(data)
    } else {
      return undefined
    }
  }

  logUserActivity = async (userId: string, subjectType: SubjectType, subjectId: string, activityType: ActivityType, details?: string)
    : Promise<UserActivity | undefined> => {
    if (!this.user) {
      return
    }

    const input: CreateUserActivityInput = {
      userId: userId,
      subjectType: subjectType,
      subjectId: subjectId ?? "NA",
      activityType: activityType,
      actorId: this.user!.id,
      accountId: this.user!.accountId,
      details: details ?? null
    }

    // Logger.debug(`UserActivity: ${details}`)

    const userActivity = await this.createUserActivity(input)

    this.updateUserLastActiveAt(this.user)

    return userActivity
  }


  async updateUserActivity(input:  APITypes.UpdateUserActivityInput): Promise<UserActivity | undefined> {
    const data = await this.govGigAPI!.updateUserActivity(input)
    if (data) {
      return new UserActivity(data)
    } else {
      return undefined
    }
  }

  deleteUserActivity = async (jobInterestId: string): Promise<UserActivity | undefined> => {
    const data = await this.govGigAPI.deleteUserActivity(jobInterestId)
    if (data) {
      return new UserActivity(data)
    } else {
      return undefined
    }
  }

  // Misc methods

  deleteAllCookies = () => {
    const cookies = document.cookie.split("; ")
    for (let c = 0; c < cookies.length; c++) {
      let d = window.location.hostname.split(".")
      while (d.length > 0) {
        const cookieBase = encodeURIComponent(cookies[c].split(";")[0].split("=")[0]) + '=; expires=Thu, 01-Jan-1970 00:00:01 GMT; domain=' + d.join('.') + ' ;path='
        let p = window.location.pathname.split('/')
        document.cookie = cookieBase + '/'
        while (p.length > 0) {
          document.cookie = cookieBase + p.join('/')
          p.pop()
        }
        d.shift()
      }
    }

  }

}

export default UserStore