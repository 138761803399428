/* eslint-disable @typescript-eslint/no-unused-vars */
import React from "react";
import {
  TextField,
  withWidth,
  WithWidth
} from "@material-ui/core";
import { createStyles, Theme, WithStyles, WithTheme } from "@material-ui/core";
import { withStyles, withTheme } from "@material-ui/core/styles";
import { Autocomplete } from "@material-ui/lab";
import { makeObservable, observable, when } from "mobx";
import { inject, observer } from "mobx-react";
import UserStore from "../../../stores/UserStore";
import AccountStore from "../../../stores/AccountStore";
import Progress from "../../Progress";
import ResourceCache from "../../../stores/ResourceCache";
import Agency from "../../../model/opportunity/Agency";

const styles = (theme: Theme) => createStyles({
  filterTextField: {
    width: "100%",
    flexGrow: 1, 
    backgroundColor: theme.palette.background.paper,
    borderRadius: 10
  }
})

export interface IAgencyFilterProps {
  onSelectAgency: (Agency?: Agency) => any
  value?: Agency
  accountStore?: AccountStore
  progress?: Progress
  userStore?: UserStore
  resourceCache?: ResourceCache
}

@inject("accountStore", "userStore", "resourceCache", "progress")
@observer
class AgencyFilter extends React.Component<WithStyles<typeof styles> & WithTheme & IAgencyFilterProps & WithWidth> {

  @observable isLoading: boolean = true 
  @observable agencyFilter?: Agency
  @observable Agencys: Agency[] = []

  constructor(props: any) {
    super(props)
    makeObservable(this)
  }

  componentDidMount() {
    const { resourceCache, value } = this.props
    when(
      // once...
      () => resourceCache!.isLoading === false,
      // ... then
      async () => {
        this.agencyFilter = value
        this.isLoading = false
        this.Agencys = (await resourceCache!.getAgencyOptions())
      }
    )
  }

  componentDidUpdate(prevProps: any) {
    if (prevProps.value !== this.props.value) {
      this.agencyFilter = this.props.value
    }
  }

  render() {
    const { classes, resourceCache } = this.props

    if (this.isLoading === true) {
      return null 
    }

    return (
      <Autocomplete
        autoSelect
        includeInputInList
        options={this.Agencys}
        getOptionLabel={(option: Agency) => option.agency_display_name ?? option}
        getOptionSelected={(option: Agency, value: Agency) => value && option.agency_display_name === value.agency_display_name}
        // groupBy={(option: Agency) => option.group}
        onChange={this.onSelectAgency}
        value={this.agencyFilter ?? null}
        renderInput={(params: any) =>
          <div ref={params.InputProps.ref}>
            <TextField
              {...params}
              className={classes.filterTextField}
              variant="outlined"
              size="small"
              name="agencyFilter"
              label="Agency"
            />
          </div>
        }
      />
    )
  }

  onSelectAgency = (event: any, value: any, reason: string) => {
    if (value) {
      this.agencyFilter = value
    } else {
      this.agencyFilter = undefined
    }
    this.props.onSelectAgency(this.agencyFilter)
  }
}

export default withTheme(withStyles(styles)(withWidth()(AgencyFilter)))