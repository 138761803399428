import {
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Theme,
  withStyles,
  WithStyles,
  WithTheme,
  withTheme,
  withWidth,
  WithWidth
} from "@material-ui/core";
import {makeObservable, observable} from "mobx";
import {inject, observer} from "mobx-react";
import React from "react";
import {ProfileStatus, UpdateProfileInput, UpdateUserInput, UserRole, UserStatus} from "../../API";
import CancelButton from "../../components/form/CancelButton";
import FormValidator from "../../components/form/FormValidator";
import ProgressButton from "../../components/form/ProgressButton";
import TextFieldValidator from "../../components/form/TextFieldValidator";
import TextMenuFieldValidator from "../../components/form/TextMenuFieldValidator";
import Profile, {ProfileStatusMessages} from "../../model/Profile";
import ProfileStore from "../../stores/ProfileStore";
import UserStore from "../../stores/UserStore";
import AddButton from "../AddButton";
import {getErrorMessage} from "../../stores/StoreUtilities";
import Notify from "../notify/Notify";
import Confirm from "../confirm/Confirm";
import JobStore from "../../stores/JobStore";
import Stack from "../Stack";

const styles = (theme: Theme) => createStyles({
  container: {
    flex: 1
  },
  progressButton: {
    minWidth: 80
  }
})

export interface IProfileCandidateStatusDialogProps {
  profile?: Profile
  onChange?: (profile: Profile) => any
  onClose?: () => any
  userStore?: UserStore
  profileStore?: ProfileStore
  jobStore?: JobStore
  notify?: Notify
  confirm?: Confirm
}

class ProfileCandidateStatusViewModel {
  @observable profileStatus: ProfileStatus
  @observable statusMessage?: string
  @observable notes?: string

  constructor (profile: Profile) {
    makeObservable(this);
    this.profileStatus = profile.profileStatus
    this.statusMessage = profile.statusMessage ?? ProfileStatusMessages[profile.profileStatus]
    this.notes = profile.notes
  }
}

@inject("userStore", "profileStore", "jobStore", "notify", "confirm")
@observer
class ProfileCandidateStatusDialog extends React.Component<WithStyles<typeof styles> & WithTheme & WithWidth & IProfileCandidateStatusDialogProps> {
  @observable viewModel?: ProfileCandidateStatusViewModel
  @observable isProcessing = false

  constructor(
    props: WithStyles<typeof styles> & WithTheme & WithWidth & IProfileCandidateStatusDialogProps
  ) {
    super(props);
    makeObservable(this);
  }

  componentDidMount() {
    const { profile } = this.props
    if (profile) {
      this.viewModel = new ProfileCandidateStatusViewModel(profile)
    }
  }

  componentDidUpdate(prevProps: any) {
    if (prevProps.profile !== this.props.profile) {
      this.componentDidMount()
    }
  }

  render() {
    const { profile, userStore, classes } = this.props

    if (!this.viewModel || !profile || !profile.user || !userStore!.isAdminOrAgent) {
      return null
    }

    const viewModel = this.viewModel!

    return (
      <Dialog
        open={true}
        onClose={this.handleClose}
        fullWidth
        maxWidth="sm"
      >
        <FormValidator onSubmit={this.onSubmit} autoComplete="off" name="editForm" id="editForm">
          <DialogTitle id="form-dialog-title">Candidate Status</DialogTitle>
          <DialogContent>
            <Stack
              direction="column"
              spacing={1}
            >
              <TextMenuFieldValidator
                type="text"
                name="profileStatus"
                label="Application Status"
                value={viewModel.profileStatus}
                onChange={this.handleChange}
                fullWidth
                required
                values={Object.values(ProfileStatus)}
              />
              <TextFieldValidator
                type="text"
                validators={{required: false}}
                name="statusMessage"
                label="Status Message (to candidate)"
                value={viewModel.statusMessage}
                onChange={this.handleChange}
                fullWidth
                variant="outlined"
                multiline
                rows={5}
                InputLabelProps={{ shrink: viewModel.statusMessage ? true : false }}
              />
              <TextFieldValidator
                type="text"
                validators={{required: false}}
                name="notes"
                label="Notes (internal only)"
                value={viewModel.notes}
                onChange={this.handleChange}
                fullWidth
                variant="outlined"
                multiline
                rows={10}
                InputLabelProps={{ shrink: viewModel.notes ? true : false }}
              />
            </Stack>
          </DialogContent>
          <DialogActions>
            <Grid container justifyContent="space-between" spacing={1}>
              <Grid item>
                <AddButton
                  text="Delete"
                  tracking="deleteApplicant"
                  buttonColor="default"
                  buttonVariant="contained"
                  icon="remove"
                  click={this.handleDelete}
                />
              </Grid>
              <Grid item>
                <Grid container>
                  <CancelButton onClick={this.handleClose} />
                  <ProgressButton variant="contained" size="medium" color="secondary"
                                  type="submit" className={classes.progressButton} processing={this.isProcessing}>
                    Save
                  </ProgressButton>
                </Grid>
              </Grid>
            </Grid>
          </DialogActions>
        </FormValidator>
      </Dialog>
    )
  }

  onClickProfileStatus = async (value: string) => {
    const { profile, profileStore, userStore, onChange } = this.props

    const profileStatus = ProfileStatus[value]

    // Update profile
    const updateProfileInput: UpdateProfileInput = {
      id: profile!.id,
      profileStatus
    }

    let updatedProfile = await profileStore!.updateProfile(updateProfileInput)

    if (updatedProfile) {
      // Update user role if needed
      const newRole = updatedProfile.profileStatus === ProfileStatus.Accepted ? UserRole.Candidate : UserRole.Applicant

      if (updatedProfile.user && updatedProfile.user!.role !== newRole) {
        console.log(`Updating user role to: ${newRole}`)
        const updateUserInput: UpdateUserInput = {
          id: updatedProfile.user!.id,
          role: newRole
        }

        const updatedUser = await userStore!.updateUser(updateUserInput)
        if (updatedUser) {
          updatedProfile.user = updatedUser
        }
      }

      if (onChange) {
        onChange(updatedProfile)
      }
    }
  }

  handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const name = event.target.name
    this.viewModel![name] = event.target.value
    if (name === "profileStatus") {
      this.viewModel!.statusMessage = ProfileStatusMessages[event.target.value]
    }
  }

  handleClose = () => {
    if (this.props.onClose) {
      this.props.onClose()
    }
  }

  handleDelete = async () => {
    const {profileStore, jobStore, userStore, confirm, notify} = this.props

    try {
      confirm!.show(
        'Confirm Delete',
        'Are you sure that you want to delete this applicant? All applicant data will be lost.',
        ['Delete', 'Cancel'],
        async () => {
          const profile = await profileStore!.getUserProfile(this.props.profile!.userId)
          if (profile) {
            await profileStore!.deleteProfile(profile)
            const jobInterests = await jobStore!.listJobInterestsByUser(profile.userId)
            if (jobInterests.length > 0) {
              await Promise.all((jobInterests).map(({ id }) => jobStore!.deleteJobInterest(id)))
            }
            const jobCandidates = await jobStore!.listJobCandidatesByProfile(profile.id)
            if (jobCandidates.length > 0) {
              await Promise.all((jobCandidates).map(({ id }) => jobStore!.deleteJobCandidate(id)))
            }
            const update: UpdateUserInput = {
              id: profile.userId,
              userStatus: UserStatus.Suspended
            }
            const updatedUser = await userStore!.updateUser(update)
            if (updatedUser) {
              notify!.show('success', 'Candidate deleted')
              this.handleClose()
              if (this.props.onChange) {
                profile.profileStatus = ProfileStatus.Inactive
                this.props.onChange(profile)
              }
              return true
            }
          }
          return false
        })
    } catch (err) {
      notify!.show('error', getErrorMessage(err))
    }
  }

  onSubmit = async () => {
    const { profile, profileStore, userStore } = this.props
    const viewModel = this.viewModel!

    if (this.props.onChange) {
      this.isProcessing = true

      // Update profile
      const updateProfileInput: UpdateProfileInput = {
        id: profile!.id,
        profileStatus: viewModel.profileStatus,
        statusMessage: viewModel.statusMessage,
        notes: viewModel!.notes,
      }

      let updatedProfile = await profileStore!.updateProfile(updateProfileInput)

      if (updatedProfile) {
        // Update user role if needed
        const newRole = updatedProfile.profileStatus === ProfileStatus.Accepted ? UserRole.Candidate : UserRole.Applicant

        if (updatedProfile.user && updatedProfile.user!.role !== newRole) {
          console.log(`Updating user role to: ${newRole}`)
          const updateUserInput: UpdateUserInput = {
            id: updatedProfile.user!.id,
            role: newRole
          }

          const updatedUser = await userStore!.updateUser(updateUserInput)
          if (updatedUser) {
            updatedProfile.user = updatedUser
          }
        }

        this.props.onChange(updatedProfile)
        this.isProcessing = false
        this.handleClose()
      } else {
        this.isProcessing = false
      }
    }
  }
}

export default withTheme(withStyles(styles)(withWidth()(ProfileCandidateStatusDialog)))
