

export class Education {
  id: string
  createdAt: string
  updatedAt: string
  userId: string
  profileId: string
  school: string
  degree: string
  field: string
  startDate: string
  endDate: string
  description: string

  constructor (data: any) {
    this.id = data.id
    this.createdAt = data.createdAt
    this.updatedAt = data.updatedAt
    this.userId = data.userId
    this.profileId = data.profileId
    this.school = data.school
    this.degree = data.degree
    this.field = data.field
    this.startDate = data.startDate
    this.endDate = data.endDate
    this.description = data.description
  }
}

export default Education