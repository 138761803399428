import * as React from 'react'
import Page from '../../components/page/Page'
import {RouteComponentProps} from "@reach/router";
import {inject, observer} from "mobx-react";
import {
  Box,
  createStyles,
  alpha,
  Grid,
  isWidthDown,
  Theme,
  Typography,
  withStyles,
  WithStyles,
  withTheme,
  WithTheme,
  withWidth,
  WithWidth
} from "@material-ui/core";
import UserStore from "../../stores/UserStore";
import Progress from "../../components/Progress";
import {makeObservable, observable, when} from "mobx";
import ProfileStore, {IProfileSearchOptions, LocationProfiles} from "../../stores/ProfileStore";
import AccountStore from "../../stores/AccountStore";
import ProfileSearchCard from "./ProfileSearchCard";
import Profile from "../../model/Profile";
import Account from "../../model/Account";
import JobPost from "../../model/JobPost";
import Contract from "../../model/Contract";
import "react-datepicker/dist/react-datepicker.css";
import LocationAPI from "../../apis/LocationAPI";
import CandidateMap from "./CandidateMap";
import {LngLatBounds} from "mapbox-gl";
import DialogButton from "../../components/form/DialogButton";
import MapIcon from "@material-ui/icons/Room";
import ListIcon from '@material-ui/icons/ViewStream';
import FilterBar from "../../components/filter/FilterBar";
import AccountFilter from "../../components/filter/AccountFilter";
import ServiceFilter from "../../components/filter/ServiceFilter";
import PlaceFilter from "../../components/filter/PlaceFilter";
import JobPostFilter from "../../components/filter/JobPostFilter";
import JobCandidate from "../../model/JobCandidate";
import ResourceCache, {LocationOption, ServiceOption} from "../../stores/ResourceCache";
import {ActivityType} from "../../model/UserActivity";
import {AddressType, SubjectType, WorkSetting} from "../../API";
import JobStore, {IJobSearchOptions} from "../../stores/JobStore";
import ProfilePage from '../profile/ProfilePage';
import JobCandidateStatusFlowCard from '../../components/jobCandidates/JobCandidateStatusFlowCard';
import Logger from '../../components/Logger';
import Column, {IRow} from '../../components/panel/Column';
import {ReactElement} from "react";
import ControlTower, {Routes} from "../../components/ControlTower";
import {NAICS} from "../../model/Industry";
import Service from "../../model/Service";
import {createUUID} from "../../stores/StoreUtilities";
import CertificationFilter from "../../components/filter/CertificationFilter";
import SecurityClearanceFilter from "../../components/filter/SecurityClearanceFilter";

const styles = (theme: Theme) => createStyles({
  root: {
    flexGrow: 1,
    width: "100%",
    justifyContent: 'top',
    alignItems: 'center',
    overflow: "hidden"
  },
  title: {
    paddingTop: theme.spacing(0.5),
    paddingLeft: theme.spacing(1)
  },
  filter: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "left",
    width: 308,
  },
  filterControl: {
    paddingTop: 3,
    textAlign: "left",
    width: "100%"
  },
  availableFilter: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
    borderWidth: 0,
    borderColor: "#c4c4c4",
    borderRadius: 10,
    fontSize: 16,
    height: 39,
    padding: "4px 0 0 15px"
  },
  filterButton: {
    backgroundColor: theme.palette.secondary.main,
    color: '#fff',
    borderWidth: 0,
    marginTop: 0,
    marginRight: 8,
    paddingLeft: 8,
    boxShadow: 'rgba(0, 0, 0, 0.25) 0px 1px 3px 0px',
    "&:hover": {
      borderWidth: 0,
      backgroundColor: alpha(theme.palette.secondary.light,0.8),
    }
  },
  content: {
    display: "flex",
    flexDirection:"row"
  },
  cardspace: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(2) 
  },
  cardspaceEmbed: {
    [theme.breakpoints.down('xs')]: {
      height: "calc(100vh - 156px)",
      width: "100%",
      marginTop: theme.spacing(0),
    },
    [theme.breakpoints.up('sm')]: {
      height: "calc(100vh - 184px)",
      marginTop: theme.spacing(1),
      marginRight: theme.spacing(2)
    },
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(2)
  },
  card: {
    width: 308
  },
  profile: {
    flexGrow: 1,
    width: "100%",
    padding: 0,
    margin: 0
  },
  map: {
    flexGrow: 0,
    width: "100%",
    padding: 0,
    [theme.breakpoints.down('xs')]: {
      height: "calc(100vh - 182px)",
      width: "100%",
      marginTop: theme.spacing(0),
    },
    [theme.breakpoints.up('sm')]: {
      height: "calc(100vh - 210px)",
      width: "100%",
      marginTop: theme.spacing(1),
      borderRadius: 10,
    },
    marginBottom: 12,
    overflow: "hidden",
  },
  mapEmbed: {
    flexGrow: 0,
    width: "100%",
    padding: 0,
    [theme.breakpoints.down('xs')]: {
      height: "calc(100vh - 156px)",
      width: 360,
      marginTop: theme.spacing(0),
    },
    [theme.breakpoints.up('sm')]: {
      height: "calc(100vh - 184px)",
      width: "100%",
      marginTop: theme.spacing(1),
      borderRadius: 10,
    },
    marginBottom: 12,
    overflow: "hidden",
  },
})

enum View {
  Map,
  List,
  Profile
}

interface ICandidatesPageProps {
  // The jobPostId is passed in when this page is being embedded, such as on the Manage Jobs and Manage Job pages. 
  embed?: boolean
  jobPostId?: string

  userStore?: UserStore
  profileStore?: ProfileStore
  resourceCache?: ResourceCache
  accountStore?: AccountStore
  jobStore?: JobStore
  locationAPI?: LocationAPI
  progress?: Progress
  location?: any
}

@inject("userStore", "profileStore", "resourceCache", "accountStore", "jobStore", "progress", "locationAPI")
@observer
class CandidatesPage extends React.Component<WithStyles<typeof styles> & RouteComponentProps & ICandidatesPageProps & WithTheme & WithWidth> {
  @observable accounts: Account[] = []
  @observable accountFilter?: Account
  @observable showAccountFilter = false
  @observable jobPostOptions: JobPost[] = []
  @observable jobPostFilter?: JobPost
  @observable showJobPostFilter = false
  @observable serviceFilter?: ServiceOption
  @observable showServiceFilter = true
  @observable locationFilter?: LocationOption
  @observable availableFilter = "" // format(new Date(), "MM/dd/yyyy")
  @observable securityClearanceFilter?: string
  @observable showSecurityClearanceFilter = false
  @observable certificationFilter?: string
  @observable showCertificationFilter = false
  @observable profiles: Profile[] = []
  @observable profile?: Profile
  // @observable profileIsSaved = false
  @observable showResults = false
  @observable isMapOn: boolean = false
  @observable isCardspaceOn: boolean = true
  @observable industryFilter?: string
  @observable locationProfiles: LocationProfiles[] = []
  @observable view: View = View.Map
  @observable viewport = {
    // Geographic center of continental US
    latitude: 39.828303,
    longitude: -98.579476,
    zoom: 2,
  }
  @observable bounds: LngLatBounds = new LngLatBounds([-146.4359213125012, 12.365141670254033, -50.72303068749926, 59.525084020223886])

  @observable isLoading = true

  constructor(
    props: WithStyles<typeof styles> & RouteComponentProps & ICandidatesPageProps & WithTheme & WithWidth
  ) {
    super(props);
    makeObservable(this);
  }

  componentDidMount () {
    const { userStore, resourceCache, jobStore, progress, location, jobPostId } = this.props

    const initFilters = async () => {
      this.industryFilter = userStore!.industry
      if (location) {
        if (location.search) {

          const searchParams = new URLSearchParams(location.search)
          const jobPostId = searchParams.get("jobPostId")
          if (jobPostId) {
            if (userStore!.isAdminOrAgent) {
              await this.loadAccounts()
              this.showAccountFilter = true
              this.showJobPostFilter = true
            } else if (userStore!.isEmployer) {
              await this.loadJobPostOptions()
              this.showJobPostFilter = true
            }
            this.showServiceFilter = true
            this.jobPostFilter = await jobStore!.getJobPost(jobPostId)
            if (this.jobPostFilter) {
              await this.loadJobPostOptions(this.jobPostFilter.accountId)
              if (userStore!.isAdminOrAgent) {
                this.accountFilter = this.findAccount(this.jobPostFilter.accountId)
              } 
            }
            if (this.jobPostFilter) {
              this.serviceFilter = resourceCache!.getServiceOption(this.jobPostFilter.serviceId)
              this.locationFilter = resourceCache!.getLocationOption(this.jobPostFilter.locationId)
            }
          } else {
            const serviceId = searchParams.get("serviceId")
            if (serviceId) {
              this.serviceFilter = resourceCache!.getServiceOption(serviceId)
            }
            const locationId = searchParams.get("locationId")
            if (locationId) {
              this.locationFilter = resourceCache!.getLocationOption(locationId)
            }
          }
        } else {
          // If there's no service or location selected, default to project management.
          if (this.industryFilter === NAICS.Construction) {
            this.serviceFilter = resourceCache!.getServiceOption("b9a73bba-9475-40f2-be7d-bb349f34d52f")
          }
        }
      } else {
        if (userStore!.isAdminOrAgent) {
          await this.loadAccounts()
          this.showAccountFilter = true
          this.showJobPostFilter = true
        } else if (userStore!.isEmployer) {
          await this.loadJobPostOptions()
          this.showJobPostFilter = true
        }
        if (jobPostId) {
          const jobPost = await jobStore!.getJobPost(jobPostId)
          if (jobPost) {
            this.jobPostFilter = jobPost
            await this.loadJobPostOptions(this.jobPostFilter.accountId)
            this.accountFilter = this.jobPostFilter.account
            this.serviceFilter = resourceCache!.getServiceOption(this.jobPostFilter.serviceId)
            this.locationFilter = resourceCache!.getLocationOption(this.jobPostFilter.locationId)
          }
        }
      }

      if (userStore!.isAdminOrAgent) {
        this.showSecurityClearanceFilter = true
        this.showCertificationFilter = true
      } else if (userStore!.isEmployer) {
        this.showSecurityClearanceFilter = true
        this.showCertificationFilter = true
      }

    }

    const initView = async () => {
      this.updateView(View.Map)
      this.isMapOn = false
      if (this.isWidthSM()) {
        this.viewport.zoom = 2
      }
      if (this.locationFilter) {
        this.updateViewport()
        this.refreshMap()
      } else {
        this.refreshMap()
        await this.searchProfiles()
      }

      // Wait for map to load
      // TODO: Investigate other options based on map loading status
      setTimeout(() => {this.isMapOn = true}, 500)
    }

    when(
      // once
      () => !userStore!.isLoading 
        && !jobStore!.isLoading 
        && !resourceCache?.isLoading,
      // then 
      async () => {
        this.isLoading = true
        progress!.show("CandidatesPage")
        await initFilters() 
        await initView() 
        this.isLoading = false
        progress!.hide("CandidatesPage")
      }
    )

    when(
      () => userStore!.isAuthenticated === true,
      async () => {
        this.isLoading = true
        progress!.show("CandidatesPage")
        await initFilters() 
        if (this.profile) {
          await this.onClickProfile(this.profile)
        }
        this.isLoading = false
        progress!.hide("CandidatesPage")
      }
    )
  }

  componentDidUpdate(prevProps: any) {
    if (prevProps.width !== this.props.width) {
      if (isWidthDown('xs', this.props.width)) {
        this.isCardspaceOn = !this.isMapOn
      } else {
        this.isCardspaceOn = true
      }
    }
  }

  render() {
    const { classes, embed } = this.props

    const title = "Find Talent"

    const cardspaceClassName = embed ? classes.cardspaceEmbed : classes.cardspace
    const mapClassName = embed ? classes.mapEmbed : classes.map
    const mapHeight = embed ? "calc(100vh - 184px)" : undefined

    const content =
      <Box px={{ xs: 0, sm: 1 }} paddingTop={1} width={'100%'}>
        {!embed &&
          <div className={classes.title}>
            <Grid
              container
              alignItems='center'
              spacing={2}
            >
              <Grid item>
                <Typography variant="h3" color="primary">
                  Find Talent
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="body2">
                  Search for candidates available for positions by job title and/or location.
                </Typography>
              </Grid>
            </Grid>
          </div>
        }

        {this.renderFilterBar()}

        <div className={classes.content}>
          {this.isCardspaceOn &&
            <div className={cardspaceClassName}>
              {this.showResults === true &&
                <Column 
                  // index={0}
                  column={{
                    id: "Results", 
                    title: "Talent Found", 
                    rows: this.profiles
                  }}
                  options={{
                    renderCard: (row: IRow) => {
                      const isSaved = this.isProfileSaved(row.id)
                      return (
                        <ProfileSearchCard
                          profile={row} 
                          service={this.serviceFilter}
                          certificationType={this.certificationFilter}
                          key={row.id}
                          isSaved={isSaved}
                          onClick={this.onClickProfile}
                        />
                      )
                    }
                  }}
                />
              }
              {this.showResults === false &&
                <Box
                  borderRadius={10}
                  bgcolor="grey.500"
                  boxShadow={0}
                  px={1} 
                  pt={1} 
                  pb={1}
                  mr={2}
                  width={360}
                  >
                  <Typography variant="h6" align="center">
                    {this.showJobPostFilter ? "Select a Job Post or Job Title to view candidates" :
                                              "Select a Job Title to view candidates"}

                  </Typography>
                </Box>
              }
            </div>
          }
          {this.profile &&
            <div className={classes.profile}>
              {this.renderProfile()}
            </div>
          }
          {this.isMapOn &&
            <div className={mapClassName}>
              <CandidateMap
                viewport={this.viewport}
                height={mapHeight}
                locationProfiles={this.locationProfiles}
                selectedLocationId={this.locationFilter ? this.locationFilter.id : undefined}
                onViewportChange={this.onViewportChange}
                onClickLocation={this.onClickLocation}
              />
            </div>
          }
        </div>
      </Box>

    if (this.props.embed) {
      return (content)
    } else {
      return (
        <Page title={title} hideFooter={true}>
          {this.isLoading ? null : content}
        </Page>
      )
    }
  }

  renderFilterBar = () => {
    const {classes, userStore} = this.props

    const buttons: ReactElement[] = []
    buttons.push(
        <DialogButton variant='secondary' size="large" onClick={this.onSelectMap} customClassName={classes.filterButton}>
        {this.isCardspaceOn &&
          <React.Fragment>
            <MapIcon />&nbsp;&nbsp;{this.isMapOn ? "Hide Map" : "Show Map"}
          </React.Fragment>
        }
        {!this.isCardspaceOn &&
          <React.Fragment><ListIcon />&nbsp;&nbsp;Show List</React.Fragment>
        }
      </DialogButton>
    )

    return (
      <Box py={1}>
        <FilterBar
          searchResultCount={this.profiles.length}
          buttons={buttons}
          // embed={false /*embed*/}
        >
          {userStore!.isAdminOrAgent && this.showAccountFilter &&
            <AccountFilter
              value={this.accountFilter}
              accounts={this.accounts!}
              onSelectAccount={this.onSelectAccount}
            />
          }
          {(userStore!.isEmployer || userStore!.isAdminOrAgent) && this.showJobPostFilter &&
            <JobPostFilter
              jobPosts={this.jobPostOptions!}
              value={this.jobPostFilter}
              onSelectJobPost={this.onSelectJobPost}
            />
          }
          {this.showServiceFilter &&
            <ServiceFilter
              value={this.serviceFilter}
              industry={userStore!.industry}
              onSelectService={this.onSelectService}
            />
          }
          <PlaceFilter
            value={this.locationFilter}
            onSelectLocation={this.onSelectLocation}
          />
          {this.showSecurityClearanceFilter &&
            <SecurityClearanceFilter
              value={this.securityClearanceFilter}
              onSelectSecurityClearance={this.onSelectSecurityClearance}
            />
          }
          {this.showCertificationFilter &&
            <CertificationFilter
              value={this.certificationFilter}
              freeSolo={true}
              onSelectCertificationType={this.onSelectCertificationType}
            />
          }
          {/*<div className={classes.filter} style={{width: 150}}>*/}
          {/*  <div className={classes.filterControl}>*/}
          {/*    <FormControl variant="outlined" size="small">*/}
          {/*      <InputLabel htmlFor="availableFilter"*/}
          {/*                  shrink={this.availableFilter.length > 0}>*/}
          {/*        Available Date*/}
          {/*      </InputLabel>*/}
          {/*      <DatePicker id="availableFilter"*/}
          {/*                  className={classes.availableFilter}*/}
          {/*                  selected={this.availableFilter ? new Date(this.availableFilter) : undefined}*/}
          {/*                  onChange={this.onChangeAvailableDate}*/}
          {/*                  dateFormat="M/d/yyyy"*/}
          {/*                  isClearable*/}
          {/*      />*/}
          {/*    </FormControl>*/}
          {/*  </div>*/}
          {/*</div>*/}

        </FilterBar>
      </Box>
    )
  }

  renderProfile = () => {
    const { jobStore, userStore } = this.props

    if (!this.profile) {
      return null
    }

    const profile = this.profile

    let jobCandidate
    if (this.jobPostFilter) {
      jobCandidate = this.jobPostFilter.jobCandidates.find((jc: JobCandidate) => jc.profileId === profile.id)
    }

    return (
      <React.Fragment>
        <Box
          bgcolor="grey.500"
          borderRadius={10}
          p={2}
          mt={1}
          mb={2}
          >
          { userStore!.isAuthenticated && 
            <JobCandidateStatusFlowCard
              dense 
              jobCandidate={jobCandidate}
              jobPost={this.jobPostFilter}
              profile={profile}
              onUpdateJobCandidate={async (updatedJobCandidate: JobCandidate) => {
                if (this.jobPostFilter) {
                  const jobCandidates = await jobStore!.listJobCandidatesByJobPost(this.jobPostFilter.id)
                  if (jobCandidates && jobCandidates.length > 0) {
                    // this.profileIsSaved = jobCandidates.findIndex((jc: JobCandidate) => jc.profileId === profile.id) >= 0
                    this.jobPostFilter.jobCandidates = jobCandidates
                    
                    this.onClickProfile(profile)
                  }
                }
              }}
              onCreateJobCandidate={async (newJobCandidate: JobCandidate) => {
                if (this.jobPostFilter) {
                  this.jobPostFilter.jobCandidates.push(newJobCandidate)
                  
                  // TODO: Find a better way to refresh?
                  this.onClickProfile(profile)
                  
                  // const jobCandidates = this.jobPostFilter.jobCandidates
                  // jobCandidates.push(newJobCandidate)
                  // this.jobPostFilter.jobCandidates = jobCandidates
                }
              }}
            />
          }
          <ProfilePage
            embed={true}
            profileId={profile.id}
            jobCandidate={jobCandidate}
          />
        </Box>
      </React.Fragment>
      )
  }

  onSelectAccount = (account?: Account): void  => {

    if (account) {
      this.accountFilter = account
      this.jobPostFilter = undefined
      this.jobPostOptions = []
      this.loadJobPostOptions(account.id)
      this.serviceFilter = undefined
      this.locationFilter = undefined
    } else {
      this.accountFilter = undefined
      this.jobPostFilter = undefined
      this.jobPostOptions = []
      this.serviceFilter = undefined
      this.locationFilter = undefined
    }
    this.searchProfiles()
  }

  onSelectJobPost = async (value?: JobPost)  => {
    const { jobStore, resourceCache } = this.props

    if (value) {
      const jobPost = value as JobPost
      // Load JobCandidates
      if (jobPost.jobCandidates.length === 0) {
        jobPost.jobCandidates = await jobStore!.listJobCandidatesByJobPost(jobPost.id)
      }
      // if (this.profile && jobPost.jobCandidates.length > 0) {
      //   this.profileIsSaved = jobPost.jobCandidates.findIndex((jc: JobCandidate) => jc.profileId === this.profile!.id) >= 0
      // } else {
      //   this.profileIsSaved = false
      // }
      this.jobPostFilter = jobPost
      this.serviceFilter = resourceCache!.getServiceOption(jobPost.serviceId)
      this.locationFilter = resourceCache!.getLocationOption(jobPost.locationId)
      this.updateViewport()
      this.refreshMap()
    } else {
      this.jobPostFilter = undefined
      this.serviceFilter = undefined
      this.locationFilter = undefined
      // this.profileIsSaved = false
    }
    this.refreshMap()
  }

  onSelectService = (value?: ServiceOption) => {
    if (value) {
      this.serviceFilter = value
    } else {
      this.serviceFilter = undefined
    }
    if (this.profile) {
      this.updateView(View.Map)
    }
    this.refreshMap()
  }

  refreshMap = () => {
    if (this.bounds) {
      this.onViewportChange(this.viewport, this.bounds)
    }
  }

  // onChangeAvailableDate = (value: Date) => {
  //   if (value) {
  //     this.availableFilter = format(value, "M/d/yyyy")
  //   } else {
  //     this.availableFilter = ""
  //   }
  //   this.refreshMap()
  // }

  onSelectLocation = (value?: LocationOption) => {
    // Temporarily hide the map to force a call to onViewportChange
    this.isMapOn = false

    if (value) {
      this.locationFilter = value
      this.updateViewport()
    } else {
      this.locationFilter = undefined
    }

    this.profiles = []
    this.searchProfiles()
    // TODO: Investigate other options based on map loading status
    setTimeout(() => {this.isMapOn = true}, 200)
  }

  onSelectSecurityClearance = (value?: string) => {
    this.securityClearanceFilter = value ?? undefined
    if (this.profile) {
      this.updateView(View.Map)
    }
    this.refreshMap()
  }

  onSelectCertificationType = (value?: string) => {
    this.certificationFilter = value ?? undefined
    if (this.profile) {
      this.updateView(View.Map)
    }
    this.refreshMap()
  }

  onAddJobPost = () => {
    ControlTower.route(Routes.jobPostCreate)
  }

  onSelectMap = () => {
    if (this.profile && this.isWidthSM()) {
      this.updateView(View.List)
    }  else {
      this.updateView((this.isMapOn) ? View.List : View.Map)
    }
  }

  onViewportChange = async (viewport: any, bounds: LngLatBounds) => {
    const { profileStore, resourceCache } = this.props
    // console.debug(`onViewportChange: (${JSON.stringify(viewport)}) (${JSON.stringify(bounds)})`)

    this.viewport = viewport
    this.bounds = bounds

    if (!this.isMapOn) {
      // Wait until map is on to search
      return
    }

    // Disabled to allow viewing all pins
    if (!this.serviceFilter) {
      this.locationProfiles = []
      this.showResults = false
      return
    }

    const serviceId = this.serviceFilter?.id
    // A serviceId is required to search
    if (!serviceId) {
      this.locationProfiles = []
      this.searchProfiles()
      return
    }

    let stateSearch
    let countrySearch
    let anywhereSearch
    let remoteSearch
    if (this.locationFilter) {
      const location = resourceCache!.getLocation(this.locationFilter.id)
      if (location?.addressType === AddressType.State) {
        stateSearch = location.state
      } else if (location?.addressType === AddressType.Country) {
        countrySearch = location.country
      } else if (location?.addressType === AddressType.World) {
        if (location.name === 'Remote') {
          remoteSearch = true
        } else {
          anywhereSearch = true
        }
      }
    }

    let locationProfiles
    let options: IProfileSearchOptions = {
      industry: this.industryFilter,
      workSetting: remoteSearch ? WorkSetting.Remote : undefined,
      certificationType: this.certificationFilter ?? undefined,
      securityClearance: this.securityClearanceFilter ?? undefined
    }
    if (stateSearch) {
      locationProfiles = await profileStore!.listLocationProfilesByState(stateSearch, serviceId)
    } else if (countrySearch) {
      locationProfiles = await profileStore!.listLocationProfilesByCountry(countrySearch, serviceId)
    } else if (anywhereSearch || remoteSearch) {
      locationProfiles = await profileStore!.listLocationProfilesByService(serviceId, options)
    } else {
      locationProfiles = await profileStore!.listLocationProfilesByBounds(bounds, serviceId, options)
    }

    if (this.locationFilter) {
      // Make sure what is currently selected in the location filter is in the search results
      const found = locationProfiles.find((item: LocationProfiles) => item.location.id === this.locationFilter!.id)
      if (!found) {
        let profiles: Profile[]
        profiles = await profileStore!.findProfilesByServiceAndLocation(serviceId, this.locationFilter.id)

        const location = resourceCache!.getLocation(this.locationFilter.id)
        if (location) {
          locationProfiles.push(new LocationProfiles(location!, profiles))
        }
      }
    }
    this.locationProfiles = locationProfiles

    this.searchProfiles()
  }

  isWidthXS = (): boolean => {
    return isWidthDown('xs', this.props.width)
  }

  isWidthSM = (): boolean => {
    return isWidthDown('sm', this.props.width)
  }

  onClickLocation = (locationId?: string) => {
    const { resourceCache } = this.props

    if (locationId && (!this.locationFilter || this.locationFilter!.id !== locationId)) {
      this.locationFilter = resourceCache!.getLocationOption(locationId)
      if (this.isWidthSM()) {
        this.updateView(View.List)
      }
    } else {
      this.locationFilter = undefined
    }
    this.searchProfiles()
  }

  updateViewport = () => {
    const { resourceCache } = this.props

    if (this.locationFilter) {
      const location = resourceCache!.getLocation(this.locationFilter!.id)
      if (location) {
        this.viewport.latitude = location.latitude
        this.viewport.longitude = location.longitude
        let zoom = 8
        switch (location.addressType) {
          case AddressType.World:
            zoom = 1
            break;
          case AddressType.Country:
            zoom = 3
            break
          case AddressType.State:
            zoom = 5
            break
          case AddressType.County:
            zoom = 7
            break
          case AddressType.City:
            zoom = 8
            break
          default:
            zoom = 9
            break
        }
        this.viewport.zoom = zoom
      }
    }
  }

  updateView = (view: View) => {
    if (this.isWidthSM()) {
      if (view === View.Map) {
        this.isCardspaceOn = false
        this.profile = undefined
        this.isMapOn = true
      } else if (view === View.List) {
        this.isMapOn = false
        this.profile = undefined
        this.isCardspaceOn = true
      } else if (view === View.Profile) {
        this.isMapOn = false
        this.isCardspaceOn = false
      }
    } else {
      if (view === View.Map) {
        this.isCardspaceOn = true
        this.profile = undefined
        this.isMapOn = true
      } else if (view === View.List) {
        this.profile = undefined
        this.isMapOn = false
        this.isCardspaceOn = true
      } else if (view === View.Profile) {
        this.isMapOn = false
        this.isCardspaceOn = true
      }
    }
    this.view = view
  }

  onClickProfile = async (profile: Profile) => {
    const { profileStore, jobStore, userStore, progress } = this.props
    progress!.show("getProfile")
    this.profile = undefined
    this.profile = await profileStore!.getProfile(profile.id)
    // this.profileIsSaved = false

    if (this.profile) {
      this.updateView(View.Profile)

      // Check if already saved to current position
      if (this.jobPostFilter) {
        const jobCandidates = await jobStore!.listJobCandidatesByJobPost(this.jobPostFilter.id)
        if (jobCandidates && jobCandidates.length > 0) {
          // this.profileIsSaved = jobCandidates.findIndex((jc: JobCandidate) => jc.profileId === profile.id) >= 0
          this.jobPostFilter.jobCandidates = jobCandidates
        }
      }

      if (userStore!.isEmployer) {
        userStore!.logUserActivity(this.profile.userId, SubjectType.Profile, this.profile.id, ActivityType.ProfileViewed,
          `${userStore!.user!.fullName} viewed the profile for '${this.profile.name}'`)
      }
    }

    progress!.hide("getProfile")
  }

  onChangeProfile = async (profile: Profile) => {
    if (profile) {
      // Update saved indicator
      if (this.jobPostFilter) {
        await this.onClickProfile(profile)
        await this.searchProfiles()
        this.profile = profile
      }
    }
  }

  searchProfiles = async () => {
    Logger.debug('CandidatesPage.searchProfiles')
    this.profile = undefined
    this.showResults = false

    let locationId = (this.locationFilter) ? this.locationFilter.id : ""

    let profiles: Profile[] = []

    if (this.locationProfiles) {
      if (locationId) {
        const found = this.locationProfiles.find((item: LocationProfiles) => item.location.id === locationId)
        if (found) {
          profiles = found.profiles
          this.showResults = true
        }
      }

      if (profiles.length === 0) {
        // Get all unique profiles
        const profileMap = new Map()
        this.locationProfiles.forEach((lp: LocationProfiles) => {
          lp.profiles.forEach((profile: Profile) => {
            profileMap.set(profile.id, profile)
          })
        })
        profileMap.forEach((value: Profile, key: string) => {
          profiles.push(value)
        })
        this.showResults = true
      }
    }

    // Sort by experience then name?
    profiles.sort((a: Profile, b: Profile) => {
      if (a.federalExperience !== b.federalExperience) {
        return b.federalExperience - a.federalExperience
      } else if (a.totalExperience !== b.totalExperience) {
        return b.totalExperience - a.totalExperience
      } else {
        return a.name.localeCompare(b.name)
      }
    })

    this.profiles = profiles
  }

  isProfileSaved = (profileId: string) => {
    if (this.jobPostFilter) {
      const jobCandidate = this.jobPostFilter.jobCandidates.find((jc: JobCandidate) => jc.profileId === profileId)
      if (jobCandidate) {
        return true
      }
    }
    return false
  }

  loadJobPostOptions = async (accountId?: string) => {
    const { accountStore, jobStore, userStore } = this.props

    if (!userStore!.isEmployer && !userStore!.isAdminOrAgent) {
      return
    }

    if (!accountId) {
      if (!accountStore!.account) {
        return
      }
      accountId = accountStore!.account!.id
    }

    const contracts = await jobStore!.listContractsByAccount(accountId)

    if (contracts) {
      const jobPosts: JobPost[] = []
      contracts.forEach((contract: Contract) => {
        contract.jobPosts.forEach((jobPost: JobPost) => {
          jobPost.contract = contract
          jobPosts.push(jobPost)
        })
      })

      this.jobPostOptions = jobPosts
    }
  }

  findJobPostOptions = (jobPostId: string) => {
    return this.jobPostOptions.find((jobPost: JobPost) => jobPost.id === jobPostId)
  }

  loadAccounts = async () => {
    const { userStore, accountStore } = this.props

    if (!userStore!.isAdminOrAgent) {
      return
    }

    const accounts = await accountStore!.listAccounts()
    if (accounts) {
      accounts.sort((a: Account, b: Account) => a.name.localeCompare(b.name))
      this.accounts = accounts
    }
  }

  findAccount = (accountId: string) => {
    return this.accounts.find((account: Account) => account.id === accountId)
  }

}

export default withTheme(withStyles(styles)(withWidth()(CandidatesPage)))
