import * as React from "react";
import {SecurityClearanceQuestion, QuestionType} from "../../../model/Question";
import {
  Checkbox, FormControl,
  FormControlLabel, Grid, Radio, RadioGroup,
  Typography
} from "@material-ui/core";
import TextFieldValidator from "../../form/TextFieldValidator";
import {useEffect, useState} from "react";
import QuestionCard from "./QuestionCard";
import Answer from "../../../model/Answer";
import AnswerCard from "./AnswerCard";
import AnswerChip from "../../jobCandidates/AnswerChip";

interface ISecurityClearanceQuestionCardProps {
  question: SecurityClearanceQuestion
  answer?: Answer
  onChange?: any
  onDelete?: any
  onAnswer?: any
}
const SecurityClearanceQuestionCard = (props: ISecurityClearanceQuestionCardProps) => {
  const { onChange, onDelete, onAnswer } = props
  const [question, setQuestion] = useState(new SecurityClearanceQuestion({
    name: "Security Clearance Question",
    type: QuestionType.SecurityClearanceQuestion,
    required: false,
    clearance: ""
  }))
  const [answer, setAnswer] = useState<Answer>(new Answer({
    name: "",
    type: "",
    required: false,
    value: "false",
    pass: true,
    label: ""
  }))

  useEffect(() => {
    setQuestion(props.question)
    if (props.answer) {
      setAnswer(props.answer)
    } else {
      const change =  validateAnswer(onAnswer ? "" : "true", props.question)
      setAnswer(change)
      if (onAnswer) {
        onAnswer(change) // Set default answer
      }
    }
  }, [props.question, props.answer, onAnswer])

  const handleChange = (event: any) => {
    const { name, value, checked } = event.target
    let change
    if (name === "required") {
      change = {...question, [name]: checked}
    } else {
      change = {...question, [name]: value}
    }
    setQuestion(change)
    validateAnswer("true", change) // Render AnswerChip
    onChange(change)
  }

  const handleAnswer = (event: any) => {
    const change = validateAnswer(event.target.value, question)
    if (onAnswer) {
      onAnswer(change)
    }
  }

  const handleDelete = (name: string) => {
    if (onDelete) {
      onDelete(name)
    }
  }

  const validateAnswer = (value: string, question: SecurityClearanceQuestion) => {
    const change = new Answer({
      name: question.name,
      type: question.type,
      required: question.required,
      value: value,
      pass: value === "true",
      label: question.clearance
    })
    setAnswer(change)
    return change
  }

  if (onChange) {
    return (
      <QuestionCard name={question.name} onDelete={handleDelete}>
        <Typography variant="body1" style={{paddingTop: 8, paddingBottom: 8}}>
          Do you have an active or recent (within last 2 years)
          <span style={{fontWeight: 600, marginLeft: 8, marginRight: 8}}>
          {question.clearance.length > 0 ? question.clearance : "____________"}
         </span>or greater security clearance?
        </Typography>

        <TextFieldValidator
          name="clearance"
          label="Clearance"
          value={question.clearance}
          variant="standard"
          fullWidth
          multiline={true}
          validators={{required: true}}
          onChange={handleChange}
        />
        <Grid container>
          <Grid item>
            <FormControlLabel
              label="Required"
              control={
                <Checkbox name="required"
                          checked={question.required}
                          onChange={handleChange}/>
              }
            />
          </Grid>
          <Grid item style={{display:"flex", flexGrow:1, justifyContent:"flex-end", alignItems:"center", paddingTop:8, paddingRight:0}}>
            {answer.label &&
              <AnswerChip answer={answer}/>
            }
          </Grid>
        </Grid>
      </QuestionCard>
    )
  } else if (onAnswer) {
    return (
      <AnswerCard>
        <Typography variant="body1">
          Do you have an active or recent (within last 2 years)
          <span style={{fontWeight: 600, marginLeft: 8, marginRight: 8}}>
          {question.clearance}
          </span>or greater security clearance?
        </Typography>
        <FormControl margin="dense">
          <RadioGroup
            name="value"
            value={answer.value}
            row
            onChange={handleAnswer}
          >
            <FormControlLabel value="false" control={<Radio />} label="No" />
            <FormControlLabel value="true" control={<Radio />} label="Yes" />
          </RadioGroup>
        </FormControl>
      </AnswerCard>
    )
  } else {
    return null
  }
}

export default SecurityClearanceQuestionCard