import { Grid, isWidthDown, MenuItem, MenuItemProps, MenuList, Popover, Theme, WithStyles, withStyles, withWidth, WithWidth } from "@material-ui/core";
import { Link } from '@reach/router';
import { makeObservable, observable } from 'mobx';
import { observer } from "mobx-react";
import React, { MouseEvent, ReactNode } from "react";
import Tracking from "../Tracking";

const styles = (theme: Theme) => ({
  menuListContainer: {
    width: '100%',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2)
  }
})

type IPopperMenuItemListItem = MenuItemProps & { label: string, to: string, onClick: any, tracking: string } | any

interface IPopperMenuProps {
  button: (props: MenuItemProps & { button: true | undefined, open?: boolean }) => ReactNode
  menuList: IPopperMenuItemListItem | IPopperMenuItemListItem[]
  onClick: (e: React.MouseEvent) => Promise<void>
}

@observer
class PopoverMenuItem extends React.Component<WithStyles & WithWidth & IPopperMenuProps> {

  @observable open = false
  @observable menuItemRef: any = null

  @observable focusButton: boolean = false
  @observable focusMenu: boolean = false

  constructor(props: WithStyles<typeof styles> & WithWidth & IPopperMenuProps) {
    super(props)
    makeObservable(this)
  }

  render() {
    const { classes, width, menuList, button } = this.props
    const { open } = this

    const isXS = isWidthDown('sm', width)

    const MenuListComp = (
      <MenuList className={ isXS ? classes.menuListContainer : undefined }>
        {Array.isArray(menuList)
          ? menuList.map((item: IPopperMenuItemListItem, key: number) => {
            const { label, to, ...rest } = item
            if (label && to) {
              return (
                <Link {...{ key, to }}
                  style={{ textDecoration: 'none', color: 'inherit' }}
                  onClick={(e) => {
                    if (isXS) {
                      Tracking.event({ category: 'Navigation', action: label })
                      this.props.onClick(e)
                    } else {
                      this.open = false
                    }
                  }}
                >
                  <MenuItem {...rest} button={rest.button || undefined}>{label}</MenuItem>
                </Link>
              )
            } else {
              return item
            }
          })
          : menuList}
      </MenuList>
    )

    return (
      <React.Fragment>
        {button({
          button: true,
          onClick: (e: MouseEvent) => {
            this.menuItemRef = e.currentTarget
            this.open = !this.open
          },
          'aria-owns': open ? "mouse-over-popover" : 'undefined',
          'aria-haspopup': "true",
          open,
        })}
        {isXS
          ? open && <Grid container>{MenuListComp}</Grid>
          : <Popover
              {...{ open }}
              id="mouseover-popover"
              anchorEl={this.menuItemRef} disableRestoreFocus
              anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
              transformOrigin={{ vertical: 'top', horizontal: 'left' }}
              onClose={() => this.open = false}
            >
            {MenuListComp}
          </Popover>
        }
      </React.Fragment>
    )
  }
}

export default withStyles(styles)(withWidth()(PopoverMenuItem))