import {
  Box,
  createStyles,
  alpha,
  FormGroup,
  Grid,
  Theme,
  Typography,
  withStyles,
  WithStyles,
  withTheme,
  WithTheme
} from "@material-ui/core";
import {inject, observer} from "mobx-react";
import React, {ReactElement} from "react";
import {AgreementType, CreateAgreementInput, ProfileStatus, UpdateAgreementInput} from "../../API";
import DialogButton from "../../components/form/DialogButton";
import Notify from "../../components/notify/Notify";
import Profile from "../../model/Profile";
import ProfileStore from "../../stores/ProfileStore";
import UserStore from "../../stores/UserStore";
import ProfileClearButton from "./ProfileClearButton";
import UploadResumeDialog from "./upload-resume/UploadResumeDialog";
import PublishIcon from '@material-ui/icons/Publish';
import CheckboxValidator from "../../components/form/CheckboxValidator";
import FormValidator from "../../components/form/FormValidator";
import {makeObservable, observable} from "mobx";
import CardValue from "../../components/CardValue";
import TextBlock from "../../components/TextBlock";
import Progress from "../../components/Progress";
import ProfileErrors from "./ProfileErrors";

const styles = (theme: Theme) => createStyles({
  actionButton: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
    border: 'none',
    boxShadow: 'rgba(0, 0, 0, 0.25) 0px 1px 3px 0px',
    "&:hover": {
      color: theme.palette.secondary.contrastText,
      backgroundColor: theme.palette.secondary.light,
      border: 'none',
    },
    "&:disabled": {
      backgroundColor: alpha(theme.palette.secondary.main, 0.5),
      color: alpha(theme.palette.secondary.contrastText, 0.5),
    },
  },
  actionButtonSubmit: {
    minWidth: 80,
  }
})

export interface IProfileInstructionsProps {
  profile?: Profile
  errors?: ReactElement[]
  profileStore?: ProfileStore
  userStore?: UserStore
  notify?: Notify
  onSubmit?: any
  onChange?: (profile: Profile) => any
  progress?: Progress
}

@inject('profileStore', 'userStore', 'notify', 'progress')
@observer
class ProfileInstructions extends React.Component<WithStyles<typeof styles> & WithTheme & IProfileInstructionsProps> {

  @observable values = {
    eligibleCheckbox: false,
    backgroundCheckbox: false,
  }
  @observable error: string = ""
  @observable errors: ReactElement[] = []

  constructor(props: any) {
    super(props)
    makeObservable(this)
  }

  componentDidMount() {
    const { profile } = this.props
    if (profile && profile!.user) {
      const agreement = profile!.user!.agreements.length > 0 ? profile!.user!.agreements[0] : undefined
      if (agreement) {
        if (agreement.hasAgreementType(AgreementType.BackgroundCheck)) {
          this.values.backgroundCheckbox = true
        }
        if (agreement.hasAgreementType(AgreementType.I9Eligible)) {
          this.values.eligibleCheckbox = true
        }
      }
    }
    this.errors = this.props.errors ?? []
  }

  componentDidUpdate(prevProps: any) {
    if (this.props.errors !== prevProps.errors) {
      console.log(`ProfileInstructions.componentDidUpdate: ${this.props.errors?.length}`)
      this.errors = this.props.errors ?? []
    }
  }

  render() {
    const {
      profile,
      userStore,
      onChange,
    } = this.props

    const user = userStore!.user
    if (!profile || profile.profileStatus === ProfileStatus.Accepted || !profile.user || !(profile.user.isApplicant && user?.id === profile.userId)) {
      return null
    }

    let heading = `Profile ${profile.profileStatus}`
    let message = profile.statusMessageOrDefault

    return (
      <Box my={2} px={3} py={3} bgcolor="warning.light" borderColor="warning.light" border={0} borderRadius={10} boxShadow='rgba(0, 0, 0, 0.15) 0px 5px 15px 0px'>
        <Grid container direction="column">
          <Grid item>
            <Typography variant="h3" gutterBottom>{heading}</Typography>
          </Grid>
          <Grid item>
            <CardValue label="Message" wrapText labelWidth={120}>
              <TextBlock>{message}</TextBlock>
            </CardValue>
          </Grid>
          <Grid item>
              {profile.isEmpty ? (
                <Box px={0} py={2}>
                  <UploadResumeDialog 
                    profile={profile}
                    onChange={onChange}
                    buttonText="Import your resume to auto-fill your profile"
                    buttonTracking="profileResumeAutofillImport"
                  />
                </Box>
              )
              : ((userStore!.isAdminOrAgent || userStore!.isApplicant) && profile.profileStatus === ProfileStatus.Pending) && (
                <Grid item>
                  <Box px={0} py={2}>
                    <ProfileClearButton {...{ profile, onChange }} />
                  </Box>
                </Grid>
              )}
          </Grid>
        </Grid>
        <Grid container direction="column">
          <Grid item>
            <FormValidator>
              {this.renderTermsCheckbox("eligibleCheckbox", <React.Fragment>I am eligible to work in the United States (Form I-9).</React.Fragment>, true)}
              {this.renderTermsCheckbox("backgroundCheckbox", "I am willing and able to pass a criminal background check.", true)}
              {this.renderActionButton()}
            </FormValidator>
          </Grid>
          { this.error !== "" && 
            <Grid item>
              <Box py={2} color="error.main">
                <Typography variant="body1">
                  {this.error}
                </Typography>
              </Box>
            </Grid>
          }
          { this.errors &&
            <ProfileErrors profile={profile} errors={this.errors}/>
          }
        </Grid>
      </Box>
    )
  }

  renderTermsCheckbox = (name: string, label: any, required: boolean) => {
    const { profile } = this.props

    if (!profile?.canSubmit()) {
      return null 
    }

    return (
      <FormGroup row>
        <Grid container direction="row" wrap="nowrap" alignItems="center">
          <Grid item>
            <CheckboxValidator name={name} color="primary"
              value="checked" required={required}
              checked={this.values[name]}
              onChange={this.changeHandler} />
          </Grid>
          <Grid item>
            {label}
          </Grid>
        </Grid>
      </FormGroup>
    )
  }

  renderActionButton() {
    const { classes, profile, userStore } = this.props

    let actionButton = null

    const sharedProps = {
      variant: 'secondary',
      customClassName: `${classes.actionButton} ${classes.actionButtonSubmit}`,
    }

    if (profile && userStore!.isProfileEditable(profile) && userStore!.isApplicant) {
      if (profile?.user) {
        if (profile?.canSubmit()) {
          actionButton = <DialogButton 
              {...sharedProps} 
              type="submit"
              onClick={this.handleSubmit}
              // disabled={!(this.isCompleteProfile())}
            >
              <PublishIcon />&nbsp;Submit
          </DialogButton>
        } else {
          actionButton = <DialogButton {...sharedProps} disabled>{profile?.profileStatus}</DialogButton>
        }
      }
    }

    if (actionButton) {
      return (
        <Grid item>
          <Box pt={2}>
            {actionButton}
          </Box>
        </Grid>
      )
    } else {
      return null 
    }
  }

  changeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const name = event.target.name
    if (name === "eligibleCheckbox" || name === "backgroundCheckbox") {
      this.values[name] = event.target.checked
    } 

    this.error = ""
  }

  handleSubmit = async () => { 
    const { onSubmit, notify, userStore, progress, profile } = this.props

    this.error = ""
    
    if (this.values.eligibleCheckbox === false || this.values.backgroundCheckbox === false) {
      if (this.values.eligibleCheckbox === false) {
        this.error = "You must indicate that you are eligible to work in the United States."  
      }
      
      if (this.values.backgroundCheckbox === false) {
        this.error += "You must indicate that you are able to pass a criminal background check."
      } 
      return 
    }
    
    const user = profile!.user!
    const agreement = user.agreements.length > 0 ? user.agreements[0] : undefined

    progress!.show('UpdateAgreement')

    if (agreement) {
      try {
        const agreementInput: UpdateAgreementInput = {
          id: agreement!.id,
          agreementTypes: [
            ...agreement!.agreementTypes
          ]
        }

        if (agreementInput.agreementTypes!.includes(AgreementType.BackgroundCheck) === false) {
          agreementInput.agreementTypes!.push(AgreementType.BackgroundCheck)
        }
        if (agreementInput.agreementTypes!.includes(AgreementType.I9Eligible) === false) {
          agreementInput.agreementTypes!.push(AgreementType.I9Eligible)
        }

        if (agreementInput.agreementTypes!.length > agreement.agreementTypes.length) {
          const agreementUpdated = await userStore!.updateAgreement(agreementInput)
          if (agreementUpdated) {
            user.agreements[0] = agreementUpdated
          }
        }
      } catch (error) {
        notify!.show('error', "Error saving agreement")
      }
    } else {
      try {
        const agreementInput: CreateAgreementInput = {
          userId: user.id,
          accountId: user.accountId,
          agreementTypes: [AgreementType.BackgroundCheck, AgreementType.I9Eligible]
        }
        const agreementCreated = await userStore!.createAgreement(agreementInput)
        if (agreementCreated) {
          user.agreements.push(agreementCreated)
        }
      } catch (error) {
        notify!.show('error', "Error creating agreement")
      }
    }

    if (onSubmit) {
      onSubmit()
    }

    progress!.hide('UpdateAgreement')
  }

}

export default withTheme(withStyles(styles)(ProfileInstructions))