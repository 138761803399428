import { CircularProgress, Container } from "@material-ui/core"
import Stack from "../Stack"

const LoadingPanel = () => {
  return (
    <Container
    >
      <Stack
        justifyContent="center"
        alignItems="center"
        sx={{
          width: "100%",
          height: "100%"
        }}
      >
        <CircularProgress /> 
      </Stack>
    </Container>
  )
}

export default LoadingPanel
