import Stack from "../../../components/Stack"
import BulletItem from "../../../components/marketing/BulletItem"
import { SectionBackgroundColors } from "../../../components/marketing/sections/Section"
import TitleGroup from "../../../components/marketing/TitleGroup"
import TitleSection from "../../../components/marketing/sections/TitleSection"
import { 
  Box, 
  Grid, 
  Paper, 
  useMediaQuery, 
  // Stack, 
  useTheme 
} from "@material-ui/core"


const BulletsCard = ({
  title, 
  body, 
  bulletPoints
}: {
  title: string, 
  body: string, 
  bulletPoints: string[]
}) => {
  const theme = useTheme()
  const isExtraSmall = useMediaQuery(theme.breakpoints.down('sm'))
  return (
    <Box
      p={ isExtraSmall ? 3 : 5 }
      bgcolor='#fff'
      borderRadius={theme.spacing(1)}
      boxShadow={'rgba(0, 0, 0, 0.15) 0px 5px 15px 0px'}
      height="100%"
    >
      <Box
        minHeight={ isExtraSmall ? 150 : 225 }
      >
        <TitleGroup
          title={title}
          titleVariant="h3"
          alignment="left"
          body={body}
        />
      </Box>
      <Stack 
        spacing={3} 
        // pt={5}
      >
        { bulletPoints.map((bulletPoint, index) => {
          return (
            <BulletItem 
              key={`point/${index}`}
              text={ bulletPoint }
              icon='Check'
            />
          )
        })}
      </Stack>
    </Box>
  )
}

const EmployerOptionsSection = () => {
  const theme = useTheme()
  const isExtraSmall = useMediaQuery(theme.breakpoints.down('sm'))
  return (
    <TitleSection
      title="Multiple Ways to Play."
      body="We have a couple of main options for staffing your jobs, as well as other professional services. Please reach out if you need a custom solution."
      backgroundColor={SectionBackgroundColors[400]}
    >
      <Grid 
        container 
        direction={ isExtraSmall ? 'column' : 'row' }
        // sx={{
        //   width: "100%"
        // }}
        spacing={ isExtraSmall ? 3 : 3 }
      >
        <Grid item xs={12} md={4}>
          <BulletsCard 
            title="Self-Service Job Posts"
            body="Take control to easily add job posts to the job board and manage your candidate flow."
            bulletPoints={[
              "Use our fast workflow tools to get your job posts published to the job board.",
              "Invite, and receive applications from, our pre-vetted and pre-qualified candidates.",
              "Save time and money with our platform of high-quality candidates, versus those other general platforms full of unqualified candidates."
            ]}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <BulletsCard 
            title="Recruiting Services"
            body="If you need a viable candidate fast, consider our Recruiting Services, where we manage the recruiting process on your behalf."
            bulletPoints={[
              "We help you craft the perfect job posts with detailed descriptions for your audience.",
              "We quickly identify, attract and screen qualified Candidates who hit all position requirements.",
              "We verify the Candidate resumes and information, present them to you, and set up your interviews."
            ]}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <BulletsCard 
            title="Professional Services"
            body="We offer many other services to help you in Federal contracting."
            bulletPoints={[
              "We can help you with you Requests for Equitable Adjustment (REA).",
              "We offer help on other reports, such as Time Impact Analysis.",
              "We have experts in a range of Federal contracting efforts, ask us about a custom set of services!"
            ]}
          />
        </Grid>
      </Grid>
    </TitleSection>
  )
}

export default EmployerOptionsSection
