import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles';
import * as React from 'react';
import { 
  DragDropContext, 
  DropResult
} from 'react-beautiful-dnd';
import KanbanColumn from "./KanbanColumn";

const styles = (theme: Theme) => createStyles({
  board: {
    display: "flex",
    flexDirection: "row",
    overflowX: 'scroll'    
  }
})

//FIXME: Will need for reordering cards
// const reorder = (list: IRow[], startIndex: number, endIndex: number) => {
//   const result = Array.from(list);
//   const [removed] = result.splice(startIndex, 1);
//   result.splice(endIndex, 0, removed);

//   return result;
// };

/**
 * Moves an item from one list to another list.
 */
// const move = (source: IKanbanRow[], destination: IKanbanRow[], droppableSource: DraggableLocation, droppableDestination: DraggableLocation) => {
//   const sourceClone = Array.from(source);
//   const destClone = Array.from(destination);
//   const [removed] = sourceClone.splice(droppableSource.index, 1);

//   destClone.splice(droppableDestination.index, 0, removed);

//   const result = {};
//   result[droppableSource.droppableId] = sourceClone;
//   result[droppableDestination.droppableId] = destClone;

//   return result;
// };

export interface IKanbanColumn {
  id: string
  title: string
  rows: IKanbanRow[]
  isDragDisabled?: boolean
  isDropDisabled?: boolean
}

export interface IKanbanRow {
  id: string
}

export interface IKanbanOptions {
  columnWidth?: any
  columnHeight: string 
  renderCard: (
    row: IKanbanRow
  ) => React.ReactElement<HTMLElement>
  isDragDisabled?: (row: IKanbanRow) => boolean
}

export interface IKanbanBoardProps {
  columns: IKanbanColumn[]
  options: IKanbanOptions
  onDrop: (
    row: IKanbanRow, 
    sourceIndex: number, 
    sourceColumnId: string, 
    destinationIndex: number, 
    destinationColumnId: string
  ) => void
}

class KanbanBoard extends React.Component<WithStyles<typeof styles> & IKanbanBoardProps> {
  onDragEnd = (result: DropResult) => {
    const { 
      source, 
      destination 
    } = result;

    // console.debug('onDragEnd', {
    //   result: JSON.stringify(result),
    //   source: JSON.stringify(source), 
    //   destination: JSON.stringify(destination)
    // })

    // dropped outside the list
    if (!destination) {
      return;
    }

    const sourceColumnId = source.droppableId
    const sourceColumn = this.props.columns.find(column => column.id === sourceColumnId)
    const destinationColumnId = destination.droppableId
    const row = sourceColumn!.rows[source.index]

    this.props.onDrop(
      row, 
      source.index, 
      sourceColumnId, 
      destination.index, 
      destinationColumnId
    )
  }

  render() {
    const { classes, columns, options } = this.props
    
    return (
      <div className={classes.board}>
        <style>
          {`
            .height-preserving-container:empty {
              min-height: calc(var(--child-height));
              box-sizing: border-box;
            }
          `}
        </style>
        <DragDropContext 
          onDragEnd={this.onDragEnd}
        >
          { columns.map((column: IKanbanColumn) => {
            return (
              <KanbanColumn 
                key={column.id} 
                column={column} 
                options={options} 
              />
            )
          })}
        </DragDropContext>
      </div>
    )
  }
}

export default withStyles(styles)(KanbanBoard)
