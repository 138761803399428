import {
  Card,
  CardContent,
  createStyles,
  Grid,
  Theme,
  Typography,
  withStyles,
  WithStyles
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import * as React from "react";
import CardValue from "../../components/CardValue";
import IconicButton from "../../components/controls/IconicButton";
import { phoneToNationalFormat } from "../../stores/StoreUtilities";
import Stack from "../../components/Stack";

const styles = (theme: Theme) => createStyles({
  container: {
    minHeight: "100%"
  },
  card: {
    fontSize: 12,
    padding: '16px !important', // overriding CardContent root setting
    // "&:hover $button": {
    //   visibility: "visible",
    // },
  },
  icon: {
    fontSize: 45,
    height: 45,
    paddingRight: theme.spacing(2),
  },
  name: {
    fontSize: 20,
    fontWeight: 600,
    color: theme.palette.primary.main,
  },
  title: {
    paddingTop: '3px',
    paddingBottom: '6px',
  },
  button: {
    flex: 0,
    height: 0,
    textAlign: "right",
    // visibility: "hidden",
  },
})

interface IPeopleCardProps {
  user: any
  handleEdit: (user: any) => void
}

class PeopleCard extends React.Component<WithStyles<typeof styles> & IPeopleCardProps> {
  
  render() {
    const { classes, user, handleEdit } = this.props
    const phoneNumber = user.phone ? phoneToNationalFormat(user.phone) : ""
    const email = user.email ?? ""

    let editButton =
      <div className={classes.button}>
        <IconicButton onClick={() => handleEdit(user)}>
          <EditIcon />
        </IconicButton>
      </div>

    return (
        <Card className={classes.container}>
          <CardContent className={classes.card}>
            {editButton}
            <Grid container>
              {/* <Grid item className={classes.icon}>
                <AccountBox fontSize={'inherit'}/>
              </Grid> */}
              <Grid item xs={9}>
                <Typography variant='h6' className={classes.name}>{user.firstName} {user.lastName}&#8203;</Typography>
                <Typography variant='body2' className={classes.title}>{user.account.name}&#8203;</Typography>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12}>
                <Stack direction="column" justifyContent="flex-start" spacing={1}>
                  { user.address && 
                    <CardValue label="Address">
                      {user.address} <br/>{user.city}, {user.state} {user.postalCode}
                    </CardValue>
                  }
                  <CardValue label="Email">
                    {email}
                  </CardValue>
                  <CardValue label="Phone">
                    {phoneNumber}
                  </CardValue>
                  <CardValue label="Role">
                    {user.role}
                  </CardValue>
                  <CardValue label="Status">
                    {user.userStatus}
                  </CardValue>
                </Stack>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
    )
  }
}

export default withStyles(styles)(PeopleCard)