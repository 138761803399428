import { Box, Grid, Paper, Typography, useTheme } from "@material-ui/core"
import DialogPanel from "../../components/panel/DialogPanel"
import DialogPanelInstructions from "../../components/panel/DialogPanelInstructions"
import SelectionCard from "../../components/marketing/SelectionCard"
import { useEffect, useState } from "react"
import DialogPanelButtons from "../../components/panel/DialogPanelButtons"
import Stack from "../../components/Stack"
import { useStores } from "../../stores/StoreProvider"
import RequestMeetingSection from "../../components/marketing/sections/RequestMeetingSection"
import { when } from "mobx"
import LoadingPanel from "../../components/panel/LoadingPanel"
import ServiceRequestPricing from "../../views/serviceRequests/ServiceRequestPricing"
import { PricingAgreementType } from "../../stores/AccountStore"
import Agreement from "../../model/Agreement"
import * as APITypes from "../../API"
import ServiceRequest from "../../model/ServiceRequest"

const ServiceRequestPricingForm = ({
  accountId, 
  serviceRequest, 
  onSelectPricingAgreement, 
  onClickBack, 
  onClickNext
}: {
  accountId: string
  serviceRequest: ServiceRequest
  onSelectPricingAgreement: (pricingAgreementType: PricingAgreementType) => void 
  onClickBack: () => void 
  onClickNext: () => Promise<void>
}) => {
  const theme = useTheme() 
  const { accountStore } = useStores()
  
  const [isLoading, setIsLoading] = useState(true)
  const [isProcessing, setIsProcessing] = useState(false)
  const [pricingAgreementType, setPricingAgreementType] = useState<PricingAgreementType | null>()
  const [isAgreementInPlace, setIsAgreementInPlace] = useState(false)

  useEffect(() => {
    const init = async () => {
      const pricingAgreementType = await accountStore.getPricingAgreementType(accountId)
      setPricingAgreementType(pricingAgreementType)
      if (pricingAgreementType) {
        setIsAgreementInPlace(true)
      }

      setIsLoading(false)
    }

    when(
      () => accountStore.isLoading === false, 
      () => {
        init() 
      }
    )

  }, [
    accountStore,
    accountId
  ])

  const agreementTypeForPricingAgreementType = (pricingAgreementType: PricingAgreementType) => {
    if (pricingAgreementType === PricingAgreementType.Standard) {
      return APITypes.AgreementType.MasterServicesAndNonSolicitation
    } else if (pricingAgreementType === PricingAgreementType.Exclusive) {
      return APITypes.AgreementType.ExclusiveRecruitment
    } else {
      return null 
    }
  }

  const handleSelectPricingAgreementType = async (pricingAgreementType: PricingAgreementType) => {
    setIsProcessing(true)

    const agreements = await accountStore.listAgreementsByAccount(accountId)
    const agreementType = agreementTypeForPricingAgreementType(pricingAgreementType)
    const hasAgreement = agreements.find((a: Agreement) => a.agreementTypes.includes(agreementType!))

    setIsAgreementInPlace(hasAgreement !== undefined)
    
    onSelectPricingAgreement(pricingAgreementType)
    setPricingAgreementType(pricingAgreementType)

    setIsProcessing(false)
  }

  const renderMessage = () => {
    if (serviceRequest.serviceRequestType === APITypes.ServiceRequestType.StaffingServices) {
      return (
        <Paper>
          <Box
            py={3}
            px={3}
          >
            <Typography>When you complete and send your request, we will work on an agreement proposal for staffing services.</Typography>
          </Box>
        </Paper>
      )
    } else if (pricingAgreementType === PricingAgreementType.Exclusive) {
      return (
        <Paper>
          <Box
            py={3}
            px={3}
          >
            <Typography variant="body1">On the next screen, you'll have the opportunity to accept our exclusive master services and non-solicitation (MS&NSA) agreement. The content is exactly the same as our regular agreement, but states that all your out-of-house recruiting will go through GovGig first. Feel free to review that agreement and accept to claim your special 15% rate.</Typography>
          </Box>
        </Paper>
      )
    } else if (pricingAgreementType === PricingAgreementType.Standard) {
      return (
        <Paper>
          <Box
            py={3}
            px={3}
          >
            <Typography variant="body1">On the next screen, you'll have the opportunity to accept our master services and non-solicitation (MS&NSA) agreement.</Typography>
          </Box>
        </Paper>
      )
    }
  }

  const renderNextStep = () => {
    return (
      <Box>
        { isAgreementInPlace ? 
          <ServiceRequestPricing 
            accountId={accountId} 
            serviceRequest={serviceRequest}
          />
          : 
          renderMessage()
        }
      </Box>
    )
  }

  const renderContent = () => {
    return (
      <Stack direction="column" spacing={3}>
        <Grid container spacing={3} direction="row" justifyContent="center">
          <Grid item xs={12} md={6}>
            <SelectionCard 
              title="20% Standard"
              titleColor={theme.palette.primary.light}
              features={[
                "Our regular recruiting agreement fee is 20% of placed candidates’ first-year base salary, pro-rated over a 6-month warranty period.", 
              ]}
              buttonTitle={ pricingAgreementType === PricingAgreementType.Standard ? "Standard Selected" : "Select Standard" }
              backgroundColor={theme.palette.grey[200]}
              selected={ pricingAgreementType === PricingAgreementType.Standard }
              disabled={ pricingAgreementType === PricingAgreementType.Exclusive && isAgreementInPlace }
              onButtonClick={() => {
                handleSelectPricingAgreementType(PricingAgreementType.Standard)
              }}
              onLearnMoreClick={() => {

              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <SelectionCard 
              title="15% Exclusive"
              titleColor={theme.palette.primary.light}
              features={[
                "We also offer an exclusive agreement for a reduced rate of 15% of base salary.",
                "To qualify for our exclusive rate, we ask that you come to us for all your out-of-house recruiting work first.",
              ]}
              buttonTitle={ pricingAgreementType === PricingAgreementType.Exclusive ? "Exclusive Selected" : "Select Exclusive" }
              backgroundColor={'hsl(215, 57%, 93%)'}
              selected={ pricingAgreementType === PricingAgreementType.Exclusive }
              onButtonClick={() => {
                handleSelectPricingAgreementType(PricingAgreementType.Exclusive)
              }}
              onLearnMoreClick={() => {

              }}
            />
          </Grid>
        </Grid>
        { renderNextStep() }
        <DialogPanelButtons 
          isProcessing={isProcessing}
          buttonDisabled={ pricingAgreementType === null } 
          backButtonTitle="Back" 
          onClickNext={async () => {
            setIsProcessing(true)
            onClickNext()
          }} 
          onClickBack={onClickBack}
        />
      </Stack>
    )
  }

  return (
    <DialogPanel>
      <DialogPanelInstructions
        title="Select a pricing agreement"
        instructions={[
          "You may select the standard agreement, or select an exclusive agreement."
        ]}
      /> 
      { isLoading === true ? <LoadingPanel /> : renderContent() }
      <RequestMeetingSection />
    </DialogPanel>
  )
}

export default ServiceRequestPricingForm
