import React, {useEffect, useRef, useState} from 'react';
import {
  Box,
  createStyles,
  makeStyles,
  Theme,
} from "@material-ui/core";
import JobCandidate from "../../model/JobCandidate";
import {useStores} from "../../stores/StoreProvider";
import UserActivity, {ActivityType} from "../../model/UserActivity";
import {
  ModelSortDirection,
  ModelUserActivityFilterInput,
} from "../../API";
import {isoDateToWhen} from "../../stores/StoreUtilities";

const useStyles = makeStyles((theme: Theme) => createStyles({
  activityFrame: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    height: 160,
    maxHeight: 160,
    backgroundColor: theme.palette.background.paper,
    borderRadius: 10,
    border: `1px solid ${theme.palette.grey[300]}`,
  },
  activityList: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    padding: 8,
    overflow: "scroll",
  }
}))

const JobCandidateActivity = ({
  jobCandidate,
  elevation
}: {
  jobCandidate: JobCandidate
  elevation?: number
}) => {
  const [activityList, setActivityList] = useState<UserActivity[]>([])

  const { userStore, notify } = useStores()
  const classes = useStyles();
  const scrollRef = useRef(null)

  useEffect(() => {
    loadActivity()
  }, [jobCandidate])

  useEffect(() => {
    if (scrollRef.current) {
      (scrollRef.current as Element).scrollIntoView({ behavior: 'auto', block: 'nearest', inline: 'start' });
    }
  }, [activityList])

  const loadActivity = async () => {
    const filter: ModelUserActivityFilterInput = {
      activityType: { ne: ActivityType.NoteAdded }
    }

    const activity = await userStore.listUserActivityBySubject(jobCandidate.id, filter, ModelSortDirection.ASC)
    setActivityList(activity)
  }

  const renderActivity = (a: UserActivity) => {
    const when = isoDateToWhen(a.createdAt)
    let content
    if (a.actorName) {
      if (a.updatedAt > a.createdAt) {
        content = <p><b>{a.actorName}</b> - {when} (edited {isoDateToWhen(a.updatedAt)})<br/>{a.details}</p>
      } else {
        content = <p><b>{a.actorName}</b> - {when}<br/>{a.details}</p>
      }
    } else {
      content = <p><b>{when}</b><br/>{a.details}</p>
    }

    return (content)
  }

  return (
    <Box>
      <Box className={classes.activityFrame}>
        <Box className={classes.activityList}>
          {activityList.map((a: UserActivity) => renderActivity(a))}
          <span ref={scrollRef}/>
        </Box>
      </Box>
    </Box>
  )

}

export default JobCandidateActivity