import { SectionBackgroundColors } from "../../../components/marketing/sections/Section"
import Testimonials from "../../../components/marketing/Testimonials"
import TitleSection from "../../../components/marketing/sections/TitleSection"

const EmployerTestimonialsSection = () => {
  return (
    <TitleSection
      title="Our Results"
      // titleAlignment='center'
      backgroundColor={SectionBackgroundColors[100]}
    >
      <Testimonials /> 
    </TitleSection>
  )
}

export default EmployerTestimonialsSection
