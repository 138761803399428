import * as React from "react";
import {
  Avatar, Box,
  Card,
  CardActionArea,
  CardContent,
  Grid,
  Typography,
  useTheme
} from "@material-ui/core";
import {humanizeString, isoToLocalDate} from "../../stores/StoreUtilities";
// TODO: In the future, highlight featured job posts with a star and background color.
// import StarIcon from '@mui/icons-material/Star';
import JobPost from "../../model/JobPost";
import CardValue from "../../components/CardValue";
import { AccountStoreConstants } from "../../stores/AccountStore";
import GovGigLogo from "../../images/GovGig_Logo_512.png"
import JobInterest from "../../model/JobInterest";
import {useStores} from "../../stores/StoreProvider";
import {format} from "date-fns";

const JobBoardSearchCard = ({
  jobPost,
  jobInterest,
  onClick   
}: {
  jobPost: JobPost
  jobInterest?: JobInterest
  onClick?: () => void
}) => {
  // const postDate = isoToLocalDate(jobPost.createdAt)
  // TODO: Include post date? May not want to until we reach an amount of job posts. 
  // const _heading = `Posted on ${format(postDate, "M/d/yyyy")}`
  const theme = useTheme()
  const { resourceCache } = useStores()

  const renderLogo = () => {
    const isPrimary = jobPost.account ? jobPost.account.id === AccountStoreConstants.PRIMARY_ACCOUNT_ID : false
    const initial = jobPost.account ? jobPost.account.name.substring(0, 1) : undefined 
    return (
      <React.Fragment>
        { isPrimary ? 
          <Avatar 
            alt={`${jobPost.account?.name}`} 
            src={GovGigLogo}
            // variant="rounded"
            style={{
              padding: 2,
              // backgroundColor: theme.palette.grey[50]
            }}  
          />
        : 
          <Avatar 
            alt={`${jobPost.account?.name}`}
            // variant="rounded"
            style={{
              color: theme.palette.common.white,
              backgroundColor: theme.palette.grey[500]
            }}
          >
            {initial}
          </Avatar>
        }
      </React.Fragment>
    )
  }

  const renderDetails = () => {
    const securityClearance = jobPost.securityClearance
    const hiringPeriod = jobPost.hiringPeriod
    const postDate = isoToLocalDate(jobPost.openDate)
    const status = `Posted on ${format(postDate, "M/d/yyyy")}`

    return (
      <Grid container spacing={1}>
        {/* <Grid 
          item 
          xs={10}
          sx={{
            fontWeight: 200,
            color: theme.palette.text.secondary,
            maxHeight: 18
          }}
        >
          {heading}
        </Grid> */}
        <Grid item xs={12}>
          <Grid container spacing={1}>
            <Grid
              item
              xs={12}
              style={{
                marginTop: -8
              }}
            >
              <Typography
                variant="h6"
                color="primary"
                style={{
                  fontWeight: 700,
                  fontSize: 18,
                  // pb: 0.5

                }}
              >
                {jobPost.title}{jobPost.openings > 1 && ` [${jobPost.openings}]`}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={5}>
          <Grid container spacing={1}>
            {jobPost.account &&
              <Grid item xs={12}>
                <CardValue
                  label="Employer"
                >
                  <Typography variant='body1' style={{ fontWeight: 600 }}>{jobPost.account?.name}</Typography>
                </CardValue>
              </Grid>
            }
            <Grid item xs={12}>
              <CardValue
                label="Location"
              >
                {jobPost.locationDescription}
              </CardValue>
              {/* <Typography noWrap={true} variant='body2'>{jobPost.location?.shortName}</Typography> */}
            </Grid>
            {securityClearance &&
              <Grid item xs={12}>
                <CardValue
                  label="Clearance"
                >
                  {securityClearance}
                </CardValue>
              </Grid>
            }
            <Grid item xs={12}>
              <CardValue
                label="Hiring"
              >
                {humanizeString(jobPost.employmentType)} Hire {hiringPeriod}
              </CardValue>
            </Grid>
            <Grid item xs={12}>
              <CardValue
                label="Status"
              >
                {status}
              </CardValue>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={7}>
          <Grid container spacing={1}>
            {jobInterest &&
              <Grid item xs={12}>
                <CardValue label="Status">
                  {jobInterest.statusText}
                </CardValue>
              </Grid>
            }
            <Grid item xs={12}>
              <CardValue label="Summary" wrapText={true}>
                  {jobPost.summary}
              </CardValue>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    )
  }

  return (
    <Card>
      <CardContent
        style={{
          fontSize: 12,
          // padding: '8px !important', // overriding CardContent root setting
          // TODO: '&:last-child': {
          //   paddingBottom: 16
          // }
        }}
      >
          <CardActionArea
            onClick={onClick}
          >
            <Box display="flex" flexDirection="row" justifyContent="space-between">
              <Box mr={1} flexGrow={0}>
                {renderLogo()}
              </Box>
              <Box ml={1} mt={1} flexGrow={1}>
                {renderDetails()}
              </Box>
            </Box>
          </CardActionArea>
      </CardContent>
    </Card>

  )
}

export default JobBoardSearchCard
