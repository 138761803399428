import {alpha, Box, createStyles, Grid, makeStyles, Paper, Theme, Typography} from "@material-ui/core"
import React, { useState } from "react"
import { CreateJobCandidateInput, CreateUserActivityInput, JobCandidateStatus, ProfileStatus, SubjectType } from "../../API"
import JobCandidate from "../../model/JobCandidate"
import JobPost from "../../model/JobPost"
import Profile from "../../model/Profile"
import UserActivity, { ActivityType } from "../../model/UserActivity"
import JobPickerDialog from "../../pages/profile/job-picker/JobPickerDialog"
import { useStores } from "../../stores/StoreProvider"
import DialogButton from "../form/DialogButton"
import JobCandidateInviteDialog from "../jobPosts/JobCandidateInviteDialog"
import JobPostMiniCard from "../jobPosts/JobPostMiniCard"
import Logger from "../Logger"
import Stack from "../Stack"
import AnswerChipArray from "./AnswerChipArray"
import JobCandidateStatusFlow from "./JobCandidateStatusFlow"
import StarBorderIcon from '@material-ui/icons/StarBorder';
import StarIcon from '@material-ui/icons/Star';
import SendIcon from '@material-ui/icons/Send';
import ActionLink from "../ActionLink"
import ControlTower, { Routes } from "../ControlTower"
import PublishIcon from "@material-ui/icons/Publish";
import JobInterestApplyDialog from "../jobPosts/JobInterestApplyDialog";
import AddButton from "../AddButton";

const useStyles = makeStyles((theme: Theme) => createStyles({
  avatar: {
    backgroundColor: theme.palette.primary.main,
    borderWidth: 2,
  },
  avatarLocked: {
    backgroundColor: theme.palette.grey[300],
    borderWidth: 2,
  },
  avatarTextLarge: {
    fontSize: 24,
  },
  avatarTextSmall: {
    fontSize: 14,
  },
  large: {
    fontSize: 24,
    width: theme.spacing(7),
    height: theme.spacing(7),
    fontWeight: 'bold'
  },
  small: {
    fontSize: 14,
    width: theme.spacing(4),
    height: theme.spacing(4)
  },
  actionButton: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
    marginTop: 0,
    marginLeft: 0,
    border: 'none',
    boxShadow: 'rgba(0, 0, 0, 0.25) 0px 1px 3px 0px',
    "&:hover": {
      color: theme.palette.secondary.contrastText,
      backgroundColor: theme.palette.secondary.light,
      border: 'none',
    },
    "&:disabled": {
      backgroundColor: alpha(theme.palette.secondary.main, 0.5),
      color: alpha(theme.palette.secondary.contrastText, 0.5),
    },
  },
  actionButtonSubmit: {
    minWidth: 80,
  },
  actionButtonShare: {
    // marginLeft: '1rem',
    borderRadius: 50,
    padding: '.5rem',
    minWidth: 'fit-content',
  },
  actionButtonShareIcon: {
  },
}))

const JobCandidateStatusFlowCard = ({
  dense, 
  jobCandidate,
  jobPost, 
  profile,
  onUpdateJobCandidate,
  onCreateJobCandidate
}: {
  dense?: boolean 
  jobCandidate?: JobCandidate,
  jobPost?: JobPost
  profile: Profile 
  onUpdateJobCandidate(updatedJobCandidate: JobCandidate): void 
  onCreateJobCandidate(newJobCandidate: JobCandidate): void 
}) => {
  const [inviteDialogOpen, setInviteDialogOpen] = useState(false)
  const [applyDialogOpen, setApplyDialogOpen] = useState(false)
  const [jobPickerDialogOpen, setJobPickerDialogOpen] = useState(false)

  const { jobStore, notify, userStore } = useStores()
  const classes = useStyles()

  const onClickInvite = async () => {
    setInviteDialogOpen(true)
  }

  const onClickApply = async () => {
    setApplyDialogOpen(true)
  }

  const onSave = async () => {
    if (jobPost && !jobCandidate) {
      await createJobCandidateIfNeeded(jobPost.id, JobCandidateStatus.Interested)
    }
  }

  const createJobCandidateIfNeeded = async (jobPostId: string, status: JobCandidateStatus) => {
    let foundJobPost
    if (jobPost && jobPostId === jobPost.id) {
      foundJobPost = jobPost 
    } else {
      foundJobPost = await jobStore!.getJobPost(jobPostId)
    }

    if (!foundJobPost) {
      throw new Error('Could not get job post record')
    }

    const accountId: string = foundJobPost.accountId

    let foundJobCandidate 
    if (jobCandidate && jobCandidate.jobPostId === jobPostId) {
      foundJobCandidate = jobCandidate
    } else {
      foundJobCandidate = await jobStore!.findJobCandidateForJobPost(accountId, jobPostId, profile!.id)
    }

    if (foundJobCandidate) {
      Logger.info("JobCandidate already exists")
      return 
    }

    const newJobCandidate = await createJobCandidate(foundJobPost, status)
    if (newJobCandidate) {
      newJobCandidate.profile = profile
      notify!.show("success", `Job candidate saved to selected job post(s)!`)
      await onCreateJobCandidate(newJobCandidate)
    } else {
      throw new Error('Could not create job candidate record')
    }

    const userActivity = await createUserActivity(newJobCandidate, foundJobPost)
        
    if (!userActivity) {
      throw new Error('Could not create user activity record')
    }
  }

  const createJobCandidate = async (jobPost: JobPost, status: JobCandidateStatus): Promise<JobCandidate | undefined> => {
    const input: CreateJobCandidateInput = {
      jobPostId: jobPost.id,
      accountId: jobPost.accountId,
      profileId: profile!.id,
      status,
      // ranking: 0
    }

    const candidate = await jobStore!.createJobCandidate(input)
    if (candidate) {
      candidate.jobPost = jobPost
    }
    return candidate
  }

  const createUserActivity = async (jobCandidate: JobCandidate, jobPost: JobPost): Promise<UserActivity | undefined> => {
    const input: CreateUserActivityInput = {
      userId: profile.userId,
      subjectType: SubjectType.JobPost, // Should it be SubjectType.JobCandidate instead? 
      subjectId: jobCandidate.jobPostId,
      activityType: ActivityType.JobCandidateAdded,
      actorId: userStore!.user!.id,
      actorName: userStore!.user!.fullName,
      accountId: userStore!.user!.accountId,
      details: `Added ${profile!.name} to the job post '${jobPost!.fullName}'`
    }

    return await userStore!.createUserActivity(input)
  }

  const renderFlow = () => {
    if (!jobCandidate) {
      return null 
    }

    return (
      <JobCandidateStatusFlow
        elevation={0}
        jobCandidate={jobCandidate}
        onUpdateJobCandidate={(updatedJobCandidate: JobCandidate) => {
          onUpdateJobCandidate && onUpdateJobCandidate(updatedJobCandidate)
        }}
      /> 
    )
  }

  const renderJobPost = () => {
    if (!jobPost) {
      return null 

    }
    return (
      <React.Fragment>
        { (dense === undefined || dense === false) &&
          <Stack direction="column" spacing={1}>
            <JobPostMiniCard 
              elevation={0}
              jobPost={jobPost}
            />
            { jobPost.status && 
              <Typography variant="h6" color="primary">
                Status: {jobPost.status}
              </Typography>
            }
          </Stack>
        }
        { dense === true && 
          <Typography gutterBottom variant="h3" color="primary">
            {jobPost.title}{jobPost.openings > 1 && ` [${jobPost.openings}]`}
          </Typography>
        }
      </React.Fragment>
    )
  }

  const renderJobCandidate = () => {
    if (!jobCandidate || !jobPost) {
      return null 
    }
    return (
      <React.Fragment>
        <Typography gutterBottom variant="h6">Review Candidate for this Job Post:</Typography> 
        <Grid container direction="row" justifyContent="space-between" spacing={2}>
          <Grid item xs={12} md={dense ? 12: 3}>
            { renderJobPost() }
          </Grid>
          {jobCandidate.application.length > 0 &&
            <Grid item xs={12} md={dense ? 12: 2}>
              <AnswerChipArray answers={jobCandidate.application}/>
            </Grid>
          }
          <Grid item xs={12} md={dense ? 12: 7}>
            { renderFlow() }
          </Grid>
        </Grid>
      </React.Fragment>
    )
  }

  const renderJobPickerDialog = () => {
    if (jobPost !== undefined || userStore.user === undefined) {
      return null 
    }
    const account = userStore.user!.account!
    return (
      <JobPickerDialog
        isDialogOpen={jobPickerDialogOpen}
        isProcessing={false}
        onClose={() => {
          setJobPickerDialogOpen(false)
        }}
        onSubmitSelections={async (jobPostIds: string[]) => {
          const promises = jobPostIds.map((jobPostId: string) => {
            // TODO: Allow for Inviting as well here? 
            return createJobCandidateIfNeeded(jobPostId, JobCandidateStatus.Interested)
          })
      
          try {
            await Promise.all(promises)
          } catch (error) {
            // TODO: It would be better to show exactly which operation failed. Keeping it simple for now. 
            Logger.error('Error adding candidate to job post(s)', error)
            notify!.show('error', 'Could not add candidate to all job post(s)')
            // this.onClose()
            // this.invitePending = false
            return
          }
        }}
        {...{ account }}
      />
    )
  }

  const renderJobInviteDialog = () => {
    if (!jobPost) {
      return 
    }
    return (
      <JobCandidateInviteDialog
        isOpen={inviteDialogOpen}
        jobPost={jobPost}
        profile={profile}
        onSubmit={async () => {
          await createJobCandidateIfNeeded(jobPost.id, JobCandidateStatus.Invited)
          setInviteDialogOpen(false)
        }}
        onClose={() => {
          setInviteDialogOpen(false)
        }}
      />
    )
  }

  const renderInviteButton = () => {
    let actionButton = null

    const sharedProps = {
      variant: 'secondary',
      customClassName: `${classes.actionButton} ${classes.actionButtonSubmit}`,
    }

    if (userStore!.isAdminOrAgent || userStore!.isEmployer) {
      const isDisabled = (jobCandidate !== undefined && jobCandidate.status !== JobCandidateStatus.Interested) ||
        (jobPost && jobStore.isJobPostUnpublished(jobPost)) 
      actionButton =
        <DialogButton {...sharedProps} onClick={onClickInvite} disabled={isDisabled}>
          <SendIcon />&nbsp;{isDisabled ? "Invited to Job Post" : "Invite to Job Post"}
        </DialogButton>
    }

    if (actionButton) {
      return (
        <Grid item>
          {actionButton}
        </Grid>
      )
    } else {
      return null
    }
  }

  const renderJobApplyDialog = () => {
    if (!jobPost) {
      return
    }
    return (
      <JobInterestApplyDialog
        isOpen={applyDialogOpen}
        jobPost={jobPost}
        onSubmit={async () => {
          await createJobCandidateIfNeeded(jobPost.id, JobCandidateStatus.Applied)
          setApplyDialogOpen(false)
        }}
        onClose={() => {
          setApplyDialogOpen(false)
        }}
      />
    )
  }

  const renderApplyButton = () => {
    let actionButton = null

    const sharedProps = {
      variant: 'secondary',
      customClassName: `${classes.actionButton} ${classes.actionButtonSubmit}`,
    }

    if (userStore!.isAdminOrAgent) {
      const isDisabled = (jobCandidate !== undefined && jobCandidate.status !== JobCandidateStatus.Interested) ||
        (jobPost && jobStore.isJobPostUnpublished(jobPost))
      if (!isDisabled) {
        actionButton =
          <DialogButton {...sharedProps} onClick={onClickApply} disabled={isDisabled}>
            <PublishIcon />&nbsp;Apply to Job Post
          </DialogButton>
      }
    }

    if (actionButton) {
      return (
        <Grid item>
          {actionButton}
        </Grid>
      )
    } else {
      return null
    }
  }

  const renderActionButton = () => {
    let actionButton = null

    const sharedProps = {
      variant: 'secondary',
      customClassName: `${classes.actionButton} ${classes.actionButtonSubmit}`,
    }

    const isSaved = jobCandidate !== undefined 
    const isDisabled = isSaved || (jobPost && jobStore.isJobPostUnpublished(jobPost))

    if (profile?.profileStatus === ProfileStatus.Accepted && 
      (userStore!.isAdminOrAgent || userStore!.isEmployer)) {
      if (isSaved) {
        actionButton =
          <DialogButton  {...sharedProps} disabled={isDisabled}>
            <StarIcon />&nbsp;Saved to Job Post
          </DialogButton>
      } else {
        actionButton =
          <DialogButton {...sharedProps} onClick={onSave} disabled={isDisabled}>
            <StarBorderIcon />&nbsp;Save to Job Post
          </DialogButton>
      }
    }

    if (actionButton) {
      return (
        <Grid item>
          {actionButton}
        </Grid>
      )
    } else {
      return null 
    }
  }

  const renderPreJobCandidateButtons = () => {
    if (!jobPost) {
      return null 
    }
    const isUnpublished: boolean = (jobPost && jobStore.isJobPostUnpublished(jobPost))
    return (
      <Stack justifyContent="space-between" spacing={2}>
        <Stack direction="column" spacing={1}>
          <Typography variant="body1">Review this Candidate for Job Post:</Typography>
          <Typography gutterBottom variant="h3" color="primary">
            {jobPost.title}{jobPost.openings > 1 && ` [${jobPost.openings}]`}
          </Typography>
          { isUnpublished && 
            <Stack>
              <Typography variant="body1">This job post is unpublished. Head over to Manage Jobs to publish this job post before inviting or saving candidates.&nbsp;</Typography>
              <ActionLink
                text="Manage Jobs"
                tracking="ManageJobs"
                click={() => {
                  ControlTower.route(Routes.manageJobs)
                }}
              />
            </Stack>
          }
          <Typography variant="body1">Note that you can unlock their profile and contact information after they apply to this job post.</Typography>
        </Stack>
        <Stack spacing={2}>
          { renderInviteButton() }
          { renderApplyButton() }
          { renderActionButton() }
        </Stack>
      </Stack>
    )
  }

  return (
    <Paper
      elevation={1}
    >
      <Box 
        sx={{
          p: 3,
          // borderRadius: 10,
          // border: `1px solid ${theme.palette.grey[300]}`
          my: 2
        }}
      >
        { jobPost && jobCandidate && renderJobCandidate() }
        { jobPost === undefined && 
          <Stack spacing={1}>
            <Typography variant="body1" style={{paddingTop:5}}>
              Please select a specific job post in order to invite or save a candidate or&nbsp;
              {/*<Link href={Routes.jobPostCreate}>Create a Job Post</Link>.*/}
            </Typography>
            <AddButton
              key="addJobButton"
              text="Create a Job Post"
              tracking="employerAddJob"
              buttonColor="secondary"
              buttonVariant="contained"
              buttonSize="small"
              click={() => ControlTower.route(Routes.jobPostCreate)}
              icon="add"
            />
            { userStore.isEmployer &&
              <Typography variant="body1">After the candidate applies to the job post, you'll be able to unlock their contact info and resume.
              </Typography>
            }
          </Stack>
        }
        { jobPost && jobCandidate === undefined && 
          renderPreJobCandidateButtons()
        }
      </Box>
      { renderJobPickerDialog() }
      { renderJobInviteDialog() }
      { renderJobApplyDialog() }
    </Paper>
  )
}

export default JobCandidateStatusFlowCard
