import { Typography, useTheme } from "@material-ui/core"
import Stack from "../Stack"

const DialogPanelInstructions = ({
  title, 
  instructions, 
}: {
  title: string 
  instructions: string[]
}) => {
  const theme = useTheme()
  return (
    <Stack
      direction="column"
      sx={{
        pb: 3, 
        borderBottom: `1px solid ${theme.palette.grey[400]}`
      }}
      spacing={2}
    >
      <Typography variant="h6">{title}</Typography>
      { instructions.map((instruction, index) => {
        return (
          <Typography
            key={`${title}/instruction/${index}`}
          >
            {instruction}
          </Typography>
        )
      })}
    </Stack>
  )
}

export default DialogPanelInstructions
