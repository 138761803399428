import * as React from 'react'
import { createStyles, withStyles, Theme, WithStyles } from '@material-ui/core/styles'
import Header from "./Header";
import NotifyBar from "../notify/NotifyBar";
import ConfirmDialog from "../confirm/ConfirmDialog";
import AlertDialog from "../alert/AlertDialog";
import Footer from "./Footer";
import { Box } from '@material-ui/core';

const styles = (theme: Theme) => createStyles({
  rootStyle: {
    flexGrow: 1,
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.background.default,
    justifyContent: 'space-between',
    display: 'flex',
    flexDirection: 'column',
    minHeight: "100vh",
    overflow: "hidden"
  },
  contentStyle: {
    display: "flex",
    flex: "auto",
    flexGrow: 1,
    flexDirection: "column",
    minHeight: "100%",
    overflow: "hidden",
    // paddingLeft: theme.spacing(3),
    // paddingRight: theme.spacing(3)
  },
  header: {
    flex: "none",
    height: 76
  },
  footer: {
  }
})

interface IPageProps {
  hideHeader?: boolean
  hideFooter?: boolean
  title?: string
}

class Page extends React.Component<WithStyles<typeof styles> & IPageProps> {

  componentDidMount() {
    if (this.props.title) {
      document.title = `GovGig - ${this.props.title}`
    } else {
      document.title = "GovGig"
    }
  }


  componentDidUpdate(prevProps: any) {
    if (prevProps.title !== this.props.title) {
      this.componentDidMount()
    }
  }

  render() {
    const { classes, hideHeader, hideFooter } = this.props

    return (
      <div className={classes.rootStyle}>
        { (hideHeader === undefined || hideHeader === false) &&
          <div className={classes.header}>
            <Header />
          </div>
        }
        <AlertDialog />
        <ConfirmDialog />
        <NotifyBar />
        <Box className={classes.contentStyle}>
          {this.props.children}
        </Box>
        { (hideFooter === undefined || hideFooter === false) &&
          <Box className={classes.footer}>
            <Footer />
          </Box>
        }
      </div>
    )
  }
}

export default withStyles(styles)(Page)