import * as React from "react";
import {Box, createStyles, Theme, Typography, withStyles, WithStyles} from "@material-ui/core";
import UserStore from "../../stores/UserStore";
import { inject, observer } from "mobx-react";
import { NAICS } from "../../model/Industry";

import banner from "../../images/JobSeekerApply_All.png"
import constructionBanner from '../../images/JobSeekerApply_Construction.png'
import itBanner from '../../images/ForJobSeekersCard.png'

const styles = (theme: Theme) => createStyles({
  formTitle: {
    [theme.breakpoints.down('xs')]: {
      fontSize: '28px',
      marginBottom: 10
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: '32px',
      marginBottom: 30
    }
  },
  itemHeading: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    fontWeight: 600,
    color: theme.palette.primary.main 
  },
  image: {
    width: '70%',
    borderRadius: theme.spacing(2),
    marginBottom: theme.spacing(3),
  },
});

interface IApplyPromoProps {
  onJoin?: any
  // promo?: string
  userStore?: UserStore
}

@inject("userStore")
@observer
class ApplyPromo extends React.Component<WithStyles<typeof styles> & IApplyPromoProps> {
  onJoin = () => {
    if (this.props.onJoin) {
      this.props.onJoin()
    }
  }

  render() {
    const {classes, userStore} = this.props

    return <Box borderRadius={10} pt={3} pb={5} bgcolor="grey.50" px={{ xs: 3, sm: 5 }}>
      <Typography variant="h1" align="left" color="primary" className={classes.formTitle}>
        APPLY TODAY AND CONTROL YOUR OWN DESTINY
      </Typography>
      
      <Box
        width="100%"
        sx={{
          flex: 1,
          flexGrow: 1, 
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-start"
        }}
      >
        <img src={ userStore!.industry === NAICS.Construction ? constructionBanner : userStore!.industry === NAICS.InformationTechnology ? itBanner : banner } alt="" className={classes.image}/>
      </Box>

      <Typography variant="body1" gutterBottom={true}>
        GovGig empowers you to upgrade your career and do it on your own terms. Choose where you work and when you work.
      </Typography>

      <Typography variant="h4" gutterBottom={true} className={classes.itemHeading}>
        Showcase Your Accomplishments
      </Typography>
      <Typography variant="body1" gutterBottom={true}>
        Show the world you are more than your resume.
      </Typography>
      <Typography variant="h4" gutterBottom={true} className={classes.itemHeading}>
        Work with Humans (Not a Computer):
      </Typography>
      <Typography variant="body1" gutterBottom={true}>
        Our knowledgable agents review your profile, and you can reach out with questions in your job search process. 
      </Typography>
      <Typography variant="h4" gutterBottom={true} className={classes.itemHeading}>
        Be the First to Know 
      </Typography>
      <Typography variant="body1" gutterBottom={true}>
        No more chasing jobs. The opportunities come to you.
      </Typography>
      <Typography variant="h4" gutterBottom={true} className={classes.itemHeading}>
        Learn & Grow 
      </Typography>
      <Typography variant="body1" gutterBottom={true}>
        Stay abreast of career advancement tips, inspiring stories, and the latest industry news.
      </Typography>

      <Typography variant="h4" gutterBottom={true} className={classes.itemHeading}>
        How it Works
      </Typography>
      <ul>
        <li>
          <Typography variant="body1" gutterBottom={true}>
            Apply on GovGig. It only takes a few minutes to get started.
          </Typography>
        </li>
        <li>
          <Typography variant="body1" gutterBottom={true}>
            Create your professional profile. Upload your resume, certifications and select your location preferences, including remote work.
          </Typography>
        </li>
        <li>
          <Typography variant="body1" gutterBottom={true}>
            Submit your profile to be approved as a candidate. 
          </Typography>
        </li>
        <li>
          <Typography variant="body1" gutterBottom={true}>
            Begin applying for interesting jobs, and get notified when new opportunities are posted. 
          </Typography>
        </li>
      </ul>
      {/* <Typography variant="body1" gutterBottom={true}>
        <b>2. CREATE YOUR PROFILE:</b> Upload your resume, certifications and select your location preferences.
      </Typography> */}
      {/* <Typography variant="body1" gutterBottom={true}>
        <b>3. WE'LL BE IN TOUCH:</b> A real person will review your application and get in touch with you to discuss your timeline and goals.
      </Typography>
      <Typography variant="body1" gutterBottom={true}>
        <b>4. STAY TUNED:</b> As opportunities within your criteria become available, you'll be contacted by GovGig or our staffing partners.
      </Typography> */}

      {/* <br/>
      <Typography variant="h3" gutterBottom={true}>
        TESTIMONIALS
      </Typography>
      <Typography variant="body1" gutterBottom={true}>
        Real: <i>"Watermark placed me on a project in Navy Region Northwest that was a perfect match for my experience. I was able to have an immediate impact within the team, and the client has utmost confidence in my abilities for this role. The compensation package was very well put together. As a career construction professional, I have been working here-and-there as an Independent Contractor on Federal projects and have seen a lot of offers. Watermark has opened doors for me in this department, and I wouldn’t hesitate to work with them again."</i> - Joe – Navy Region NW (from Davis)
      </Typography>
      <Typography variant="body1" gutterBottom={true}>
        Real: <i>"I'm getting too old to chase contracts. I'm tired of trying to find who has which contracts. With GovGig, they handled all that for me and I was able to get placed in my current job with no fuss."</i> - David Sear in Yakima
      </Typography>
      <Typography variant="body1" gutterBottom={true}>
        Real: <i>"There was a bad family situation and I needed to get out of town. Through GovGig I was placed in a town 3 hours away within minutes."</i> - Luke's story
      </Typography>
      <Typography variant="body1" gutterBottom={true}>
        Hypothetical example: <i>"I’m a site-safety officer who was looking for my next gig. I signed up on GovGig thinking it would be like most of the other job sites and I’d never hear back. To my surprise, I got a phone call the next day. Not only that, but they found me a gig within a week! I was shocked, and I’m definitely a big fan now."</i> - Candidate X
      </Typography> */}
    </Box>
  }
}

export default withStyles(styles)(ApplyPromo)