import React from "react";
import {
  createStyles, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider,
  Theme,
  Typography,
  withStyles,
  WithStyles,
  withTheme,
  WithTheme,
  withWidth,
  WithWidth
} from "@material-ui/core";
import {inject, observer} from "mobx-react";
import {makeObservable, observable} from "mobx";
import CancelButton from "../form/CancelButton";
import ProgressButton from "../form/ProgressButton";
import ResourceCache, {LocationOption} from "../../stores/ResourceCache";
import Profile from "../../model/Profile";
import ProfileLocation from "../../model/ProfileLocation";
import PlaceFilter from "../filter/PlaceFilter";
import Stack from "../Stack";

const styles = (theme: Theme) => createStyles({
  container: {
    flex: 1
  },
  progressButton: {
    minWidth: 80
  }
})

export interface IProfileLocationsDialogProps {
  isDialogOpen: boolean
  profile?: Profile
  onClose: any
  onSubmitSelections: any
  resourceCache?: ResourceCache
  isProcessing: boolean
}

@inject("resourceCache")
@observer
class ProfileLocationsDialog extends React.Component<WithStyles<typeof styles> & WithTheme & WithWidth & IProfileLocationsDialogProps> {

  @observable selectedLocations: LocationOption[] = []
  
  constructor(props: WithStyles<typeof styles> & WithTheme & WithWidth & IProfileLocationsDialogProps) {
    super(props)
    makeObservable(this);
  }
  
  componentDidMount() {
    const { profile, resourceCache } = this.props

    if (profile && profile.profileLocations) {
      profile.profileLocations.forEach((pl: ProfileLocation) => {
        const locationOption = resourceCache!.getLocationOption(pl.locationId)
        if (locationOption) {
          this.selectedLocations.push(locationOption)
        }
      })
    }
  }
  
  render() {
    const { classes, isDialogOpen, onClose } = this.props

    const title = "Job Locations"
    
    if (!isDialogOpen) {
      return null
    }

    return (
      <Dialog
        open={true}
        onClose={onClose}
        maxWidth={'sm'}
        fullWidth={true}
      >
        <DialogTitle
          id="form-dialog-title"
        >
          {title}
        </DialogTitle>
        <Divider />
        <DialogContent>
          <DialogContentText>
            <Stack spacing={2}>
              <Typography>
                Add multiple locations, cities, states, or countries to be considered for more positions!.
              </Typography>
              <Typography>
                You can also specify "Remote" if you are looking for a remote or hybrid position. 
              </Typography>
            </Stack>
          </DialogContentText>
          <PlaceFilter
            multiple={true}
            placeholder="Type here..."
            defaultOptions={this.selectedLocations}
            onSelectLocations={this.onSelectLocations}
            />
        </DialogContent>
        <Divider />
        <DialogActions>
          <CancelButton onClick={this.props.onClose} />
          <ProgressButton
            variant="contained"
            size="medium"
            color="secondary"
            type="submit"
            className={classes.progressButton}
            processing={this.props.isProcessing}
            onClick={this.handleSubmit}
          >
            Save
          </ProgressButton>
        </DialogActions>
      </Dialog>
    )
  }

  onSelectLocations = (locations: LocationOption[]) => {
    this.selectedLocations = [...locations]
  }

  handleSubmit = async () => {
    let selectedIds: string[] = this.selectedLocations.map((option: LocationOption) => option.id)
    this.props.onSubmitSelections(selectedIds)
  }

}

export default withTheme(withStyles(styles)(withWidth()(ProfileLocationsDialog)))