import React from 'react';
import {Elements, StripeProvider} from "react-stripe-elements";
import config from "react-global-configuration";

// TODO: Use new Stripe SDK: 
// import {Elements} from '@stripe/react-stripe-js';
// import {Appearance, loadStripe, StripeElementsOptions} from '@stripe/stripe-js';
// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
// const stripePromise = loadStripe('pk_test_51K1bRpGEKsOeGTHntvBbIn5a8nmgJdIGJncIv68hAjPweAtFgRMWa9NPDd6CcJdU5eotg16M1iGQrLOagHojKZi600rfXIOK3q');

const StripeElementProvider: React.FC = ({children}) => {
  // const [clientSecret, setClientSecret] = useState("");
  
  // TODO: Fetch or create a PaymentIntent in order to save the customer's payment info. 
  // useEffect(() => {
  //   // Create PaymentIntent as soon as the page loads
  //   fetch("/create-payment-intent", {
  //     method: "POST",
  //     headers: { "Content-Type": "application/json" },
  //     body: JSON.stringify({ items: [{ id: "xl-tshirt" }] }),
  //   })
  //     .then((res) => res.json())
  //     .then((data) => setClientSecret(data.clientSecret));
  // }, []);

  // const appearance: Appearance = {
  //   theme: "stripe"
  // };

  // const options: StripeElementsOptions = {
  //   // clientSecret,
  //   appearance
  // };
  
  return (
    <React.Fragment>
      <StripeProvider apiKey={config.get("stripe.apiKey")}>
        <Elements>
          {children}
        </Elements>
      </StripeProvider>
      {/* <Elements options={options} stripe={stripePromise}>
        {children}
      </Elements> */}
      {/* { clientSecret && 
        <Elements options={options} stripe={stripePromise}>
          {children}
        </Elements>
      } */}
    </React.Fragment>
  )
}

export default StripeElementProvider
