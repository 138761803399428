import { Box, Button, Grid, List, ListItem, Paper, Theme, Typography, createStyles, makeStyles, useTheme } from "@material-ui/core"
import { useState } from "react"
import Stack from "../Stack"
import { Check } from "@material-ui/icons"

const useStyles = makeStyles((theme: Theme) => createStyles({ 
  root: {
    height: "100%"
  },
  container: {
    // paddingTop: theme.spacing(3), 
    paddingBottom: theme.spacing(3), 
    // paddingLeft: theme.spacing(3), 
    // paddingRight: theme.spacing(3), 
    height: "100%"
  },
  list: {
    // paddingTop: theme.spacing(2), 
    // paddingBottom: theme.spacing(2), 
    paddingLeft: theme.spacing(5), 
    paddingRight: theme.spacing(3), 
    listStyleType: 'disc',
    [theme.breakpoints.up("md")]: {
      minHeight: "200px"
    }
    // listStylePosition: 'inside',
    // ['& .MuiListItem-root']: {
    //   display: 'list-item'
    // },
  },
  listItem: {
    display: 'list-item',
    marginBottom: 0 
  }
}))

const SelectionCard = ({
  title,
  titleColor, 
  titleBackgroundColor,
  features,
  buttonTitle, 
  backgroundColor,
  selected, 
  disabled, 
  onButtonClick,
  onLearnMoreClick,
}: {
  title: string
  titleColor?: string 
  titleBackgroundColor?: string 
  features: string[] 
  buttonTitle: string 
  backgroundColor?: string 
  selected: boolean 
  disabled?: boolean 
  onButtonClick: () => void 
  onLearnMoreClick: () => void 
}) => {
  const classes = useStyles()
  const theme = useTheme()
  return (
    <Box 
      pb={2}
      bgcolor={backgroundColor}
      borderRadius={theme.spacing(1)}
      sx={{
        width: "100%",
        height: "100%",
        overflow: "hidden",
        boxShadow: 'rgba(0, 0, 0, 0.15) 0px 5px 15px 0px',
      }}
    >
      <Grid 
        container 
        direction="column"
        justifyContent="space-between"
        alignItems="stretch"
        // sx={{
        //   width: "100%",
        //   height: "100%",
        // }}
        spacing={1}
        className={classes.container}
        wrap="nowrap"
      >
        <Grid item>
          <Grid 
            container 
            direction="column"
            justifyContent="space-between"
            alignItems="stretch"
          >
            <Grid item>
              <Box
                bgcolor={titleBackgroundColor}
                py={3}
                px={2}
                sx={{
                  color: titleColor ?? "#fff",
                  minWidth: "100%",
                  // height: "100%",
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: "row",
                  display: "flex"
                }}
              >
                <Typography 
                  variant="h3"
                  color="inherit"
                >
                  {title}
                </Typography>
              </Box>
            </Grid>
            <Grid item>
              <List className={classes.list}>
                { features.map((feature, index) => {
                  return(
                    <ListItem 
                      key={`${title}/${feature}/${index}`}
                      className={classes.listItem}
                    >
                      <Typography>
                        {feature}
                      </Typography>
                    </ListItem>
                  )
                }) }
              </List>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Stack 
            justifyContent="center"
            spacing={2}
            sx={{
              px: 2 
            }}
          >
            <Button
              variant="contained"
              color="secondary"
              disabled={disabled}
              onClick={() => {
                onButtonClick()
              }}
              startIcon={ selected && <Check /> }
            >
              {buttonTitle}
            </Button>
            {/* TODO: Add content for Learn More
            <Button
              variant="text"
              color="primary"
              onClick={onLearnMoreClick}
            >
              Learn More 
            </Button> */}
          </Stack>
        </Grid>
      </Grid>
    </Box>
  )
}

export default SelectionCard
