import { 
  Paper,
  // Paper, 
  Table, 
  TableBody, 
  TableCell, 
  TableContainer, 
  TableHead, 
  // TableProps, 
  TableRow, 
  TableRowProps, 
  Theme, 
  createStyles, 
  makeStyles, 
  // alpha, 
  styled, 
  // tableCellClasses, // Only MUI 5
  // tableRowClasses, // Only MUI 5
  useTheme 
} from "@material-ui/core";

const TableRowStyled = styled((props: TableRowProps) => {
  return (
    <TableRow {...props} />
  )
})(({ theme }) => ({
  ['&:hover td, &:hover th']: {
    // backgroundColor: alpha(theme.palette.primary.main, 0.75),
    backgroundColor: theme.palette.grey[200],
    // color: theme.palette.common.white,
    // borderBottom: "1px solid blue "
    // fontWeight: "bold"
    // color: theme.palette.common.white,
    cursor: "pointer"
  },
  ['&:last-child td, &:last-child th']: { 
    border: 0 
  } 
}))

const useStyles = makeStyles((theme: Theme) => createStyles({
  tableContainer: {
    overflowX: 'auto',
    width: "100%",
    // maxWidth: "calc(100vw - 96px)"
    maxWidth: "100%",
    borderRadius: "4px",
    border: "1px solid hsl(0, 0%, 88%)",
    overflow: "hidden"
  },
  table: {
    // width: "100%",
    minWidth: 650,
  },
  headTableCell: {
    fontWeight: "bold"
  },
  tableBody: {
    backgroundColor: theme.palette.common.white
  },
}))

export interface ITableRow {
  id: string 
  name: string 
  cells: (string | React.ReactNode)[]
}

const SimpleTable = ({
  head, 
  data,
  onClickRow
}: {
  head: ITableRow
  data: ITableRow[]
  onClickRow(row: ITableRow): void 
}) => {
  const theme = useTheme()
  const classes = useStyles()

  const renderHead = () => {
    return (
      <TableHead
      
      >
        <TableRow
          key="tableHead"
        >
          <TableCell component="th" scope="row">
            {head.name}
          </TableCell>
          { head.cells.map((cell, index) => {
            return (
              <TableCell 
                key={`tableHeadRowCell/${index}`}
                className={classes.headTableCell}
              >
                {cell}
              </TableCell>
            )
          }) }
          {/* <TableCell>Dessert (100g serving)</TableCell>
          <TableCell align="right">Calories</TableCell>
          <TableCell align="right">Fat&nbsp;(g)</TableCell>
          <TableCell align="right">Carbs&nbsp;(g)</TableCell>
          <TableCell align="right">Protein&nbsp;(g)</TableCell> */}
        </TableRow>
      </TableHead>
    )
  }

  const renderBody = () => {
    return (
      <TableBody
        className={classes.tableBody}
      >
        {data.map((row, index) => (
          <TableRowStyled
            key={`${row.name}/${index}`}
            onClick={() => {
              onClickRow(row)
            }}
          >
            <TableCell component="th" scope="row">
              {row.name}
            </TableCell>
            { row.cells.map((cell, index) => {
              return (
                <TableCell 
                  key={`tableBodyRowCell/${index}`} 
                  // align="right"
                >
                  {cell}
                </TableCell>
              )
            }) }
            {/* <TableCell align="right">{row.calories}</TableCell>
            <TableCell align="right">{row.fat}</TableCell>
            <TableCell align="right">{row.carbs}</TableCell>
            <TableCell align="right">{row.protein}</TableCell> */}
          </TableRowStyled>
        ))}
      </TableBody>
    )
  }

  return (
    <TableContainer 
      component={Paper}
      elevation={0}
      className={classes.tableContainer}
    >
      <Table 
        aria-label="simple table"
        className={classes.table}

        // sx={{ 
        //   minWidth: 650,
        //   [`& .${tableCellClasses.root}`]: {
        //     borderBottom: `1px solid ${theme.palette.grey[300]}`
        //   } 
        // }} 
      >
        { renderHead() }
        { renderBody() }
      </Table>
    </TableContainer>
  );
}

export default SimpleTable
