import { Box } from "@material-ui/core"
import { PropsWithChildren } from "react"

const PageTabsPanel = ({
  embed,
  children 
}: {
  embed: boolean 
} & PropsWithChildren<any>) => {
  return (
    <Box 
      px={{ 
        xs: 2, 
        sm: 3 
      }} 
      bgcolor="common.white" 
      borderRadius={ embed ? 0 : "10px 10px 0px 0px"}
    >
      {children}
    </Box>
  )
}

export default PageTabsPanel
