import {useEffect, useState} from "react"
import {useStores} from "../../stores/StoreProvider";
import {NAICS} from "../../model/Industry";
import {Box, Theme, Typography, useMediaQuery} from "@material-ui/core";
import MenuButton, { IMenuItem } from "../controls/MenuButton";
import get from "lodash.get";

const IndustryMenu = () => {
  const [industryCode, setIndustryCode] = useState<string | undefined>()
  const { userStore, accountStore } = useStores()
  const isXS = useMediaQuery((theme: Theme) => theme.breakpoints.down('xs'))

  useEffect(() => {
    setIndustryCode(userStore.industry)
  }, [
    accountStore.account,
    accountStore.account?.industries,
    userStore.user,
    userStore.industry,
  ])

  const onClickIndustry = (_e: any, industryCode: string) => {
    // console.debug(`onClickIndustry(${industryCode})`)
    userStore.setIndustry(industryCode)
    setIndustryCode(industryCode)
  }

  const buttonLabelForIndustryCode = (industryCode: string | undefined) => {
    let label: string
    switch (industryCode) {
      case NAICS.Construction:
        label = isXS ? "Construction" : "For Construction"
        break
      case NAICS.InformationTechnology:
        label = isXS ? "IT" : "For Information Technology"
        break
      case NAICS.SecurityServices: 
        label = isXS ? "Security" : "For Physical Security"
        break 
      default:
        label = isXS ? "Industries" : "All Industries"
    } 
    return label 
  }

  const menuItemLabelForIndustryCode = (industryCode: string | undefined) => {
    let label: string
    switch (industryCode) {
      case NAICS.Construction:
        label = "For Construction"
        break
      case NAICS.InformationTechnology:
        label = "For Information Technology"
        break
      case NAICS.SecurityServices: 
        label = "For Physical Security"
        break 
      default:
        label = "All Industries"
    }
    return label 
  }

  const renderAsText = (industryCode: string | undefined) => {
    return (
      <Box
        sx={{
          pl: 1 
        }}
      >
        <Typography
          color="primary"
        >
          {buttonLabelForIndustryCode(industryCode)}
        </Typography>
      </Box>
    )
  }

  const menuItemsForIndustries = () => {
    const menuItems: IMenuItem[] = []

    let industries
    industries = get(userStore, "user.profile.industries")

    if (!industries) {
      industries = get(accountStore, "account.industries")
    }

    if (industries && industries.length > 1) {
      const menuItem: IMenuItem = {
        label: menuItemLabelForIndustryCode(undefined),
        value: undefined,
        selected: industryCode === undefined
      }
      menuItems.push(menuItem)

      industries?.forEach(industry => {
        const menuItem: IMenuItem = {
          label: menuItemLabelForIndustryCode(industry),
          value: industry,
          selected: industryCode === industry
        }
        menuItems.push(menuItem)
      })
    } else if (industries && industries.length === 1) {
      const industry = industries[0]
      return [{
        label: menuItemLabelForIndustryCode(industry),
        value: industry,
        selected: industryCode === industry
      }]
    }

    if (menuItems.length === 0) {
      menuItems.push(
        {
          label: menuItemLabelForIndustryCode(undefined),
          value: undefined,
          selected: industryCode === undefined
        },
        {
          label: menuItemLabelForIndustryCode(NAICS.Construction),
          value: NAICS.Construction,
          selected: industryCode === NAICS.Construction
        },
        {
          label: menuItemLabelForIndustryCode(NAICS.InformationTechnology),
          value: NAICS.InformationTechnology,
          selected: industryCode === NAICS.InformationTechnology
        },
        {
          label: menuItemLabelForIndustryCode(NAICS.SecurityServices),
          value: NAICS.SecurityServices,
          selected: industryCode === NAICS.SecurityServices
        }
      )
    }


    // if (userStore.isAuthenticated && userStore.user && userStore.user.profile && userStore.user.profile.industries.length > 1) {
      
    // } else if (userStore.isAuthenticated && accountStore.account && accountStore.account.industries.length <= 1) {
      
    // } else {

    // }

    return menuItems
  }

  // console.debug("IndustryMenu", {
  //   user: userStore.user,
  //   account: accountStore.account 
  // })

  const menuItems = menuItemsForIndustries()

  if (menuItems.length === 1) {
    return renderAsText(menuItems[0].value)
  } else {
    return (
      <MenuButton 
        label={buttonLabelForIndustryCode(industryCode)}
        menuItems={menuItems}
        onClickMenuItem={(e: any, menuItem: IMenuItem) => {
          onClickIndustry(e, menuItem.value)
        }}
      /> 
    )
  }


  // if (userStore.isAuthenticated && userStore.user && userStore.user.profile && userStore.user.profile.industries.length <= 1) {
  //   return renderAsText(industryCode)
  // } else if (userStore.isAuthenticated && accountStore.account && accountStore.account.industries.length <= 1) {
  //   return renderAsText(industryCode)
  // } else {
  //   return (
  //     <MenuButton 
  //       label={buttonLabelForIndustryCode(industryCode)}
  //       menuItems={[
  //         {
  //           label: menuItemLabelForIndustryCode(undefined),
  //           value: undefined,
  //           selected: industryCode === undefined
  //         },
  //         {
  //           label: menuItemLabelForIndustryCode(NAICS.Construction),
  //           value: NAICS.Construction,
  //           selected: industryCode === NAICS.Construction
  //         },
  //         {
  //           label: menuItemLabelForIndustryCode(NAICS.InformationTechnology),
  //           value: NAICS.InformationTechnology,
  //           selected: industryCode === NAICS.InformationTechnology
  //         },
  //         {
  //           label: menuItemLabelForIndustryCode(NAICS.SecurityServices),
  //           value: NAICS.SecurityServices,
  //           selected: industryCode === NAICS.SecurityServices
  //         }
  //       ]}
  //       onClickMenuItem={(e: any, menuItem: IMenuItem) => {
  //         onClickIndustry(e, menuItem.value)
  //       }}
  //     /> 
  //   )
  // }

}

export default IndustryMenu