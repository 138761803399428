import { 
  Box, 
  Button, 
  Grid, 
  // Paper, 
  // Typography 
} from "@material-ui/core"
import get from "lodash.get"
import { useEffect, useState } from "react"
import Product from "../../model/stripe/Product"
import JobPost from "../../model/JobPost"
import { useStores } from "../../stores/StoreProvider"
import * as APITypes from '../../API'
import AddButton from "../../components/AddButton"
import PricingList from "../../components/marketing/pricing/PricingList"

const JobPostSelectProductOptions = ({
  jobPost,
  onContinue,
  onAddJobPost  
}: {
  jobPost: JobPost
  onContinue(): void 
  onAddJobPost(): void 
}) => {
  const [selectedProduct, setSelectedProduct] = useState<Product>()
  const [products, setProducts] = useState<Product[]>([])

  const { accountStore, userStore, jobStore } = useStores()

  useEffect(() => {
    const loadProducts = async (): Promise<Product[]> => {
      const products: Product[] = await accountStore.listProductsByProductLineAndProductType('GovGigJobs', 'JobPost')
      return products 
    }

    const init = async () => {
      if (accountStore.isLoading === false && userStore.isLoading === false) {
        const products = await loadProducts()
        setProducts(products)
      }
    }

    init() 
  }, [
    accountStore, 
    accountStore.isLoading,
    userStore, 
    userStore.isLoading
  ])

  const handleSelectProduct = (product: Product) => {
    setSelectedProduct(product)
  }
  
  const handleContinue = async () => {
    const updatedJobPost = await updateJobPost()
    if (updatedJobPost) {
      onContinue()
    }
  }

  const handleAddAnotherJobPost = async () => {
    const updatedJobPost = await updateJobPost()
    if (updatedJobPost) {
      onAddJobPost() 
    }
  }

  const updateJobPost = async (): Promise<JobPost | undefined> => {
    if (!selectedProduct) return 

    const jobPostType = get(selectedProduct.metadata, 'jobPostType')

    // TODO: Also indicate selected add-ons. 
    const input: APITypes.UpdateJobPostInput = {
      id: jobPost.id,
      jobPostType
    }
    const updatedJobPost = await jobStore.updateJobPost(input)
    return updatedJobPost
  }

  // TODO: Implement add-ons: 
  // const renderAddOns = () => {
  //   return (
  //     <Paper>
  //       <Box
  //         sx={{
  //           p: 2
  //         }}
  //       >
  //         <Typography variant="h6">Select Add-ons</Typography>
  //         <Typography>List add-ons and allow user to select them here.</Typography>
  //       </Box>
  //     </Paper>
  //   )
  // }

  return (
    <Box
      sx={{
        // p: 2 
      }}
    >
      <Grid container direction="column" alignItems="center" spacing={3}>
        <Grid item>
          <Grid 
            container 
            direction="row" 
            // spacing={2}
            // alignItems="stretch"
          >
            <PricingList 
              products={products}
              dense 
              onSelectProduct={handleSelectProduct}
            />
          </Grid>
        </Grid>
        {/* TODO: Render the add-ons when we're ready to include them: 
        <Grid item xs={12}>
          { renderAddOns() }
        </Grid> 
        */}
        <Grid item>
          <Button
            size="small"
            variant="contained"
            color="secondary"
            onClick={handleContinue}
            disabled={selectedProduct === undefined}
          >
            Continue to Checkout
          </Button>
        </Grid>
        <Grid item>
          <AddButton
            key="addJobButton"
            text="Add Another Job Post(s)"
            tracking="employerAddJobToContract"
            buttonColor="secondary"
            buttonVariant="contained"
            buttonSize="small"
            click={handleAddAnotherJobPost}
            icon="add"
            disabled={selectedProduct === undefined}
          />
        </Grid>
      </Grid>
    </Box>
  )
}

export default JobPostSelectProductOptions
