import React from "react";
import {
  alpha,
  Box,
  Chip,
  createStyles,
  Grid,
  Theme,
  Typography,
  withStyles,
  WithStyles,
  withTheme,
  WithTheme,
  withWidth,
  WithWidth
} from "@material-ui/core";
import UserStore from "../../stores/UserStore";
import ProfileStore from "../../stores/ProfileStore";
import {inject, observer} from "mobx-react";
import { observable, when, makeObservable } from "mobx";
import Profile from "../../model/Profile";
import IconicButton from "../../components/controls/IconicButton";
import Service from "../../model/Service";
import AddButton from "../../components/AddButton";
import FaceIcon from '@material-ui/icons/Face';
import Logger from "../../components/Logger";
import EditIcon from "@material-ui/icons/Edit";
import PositionsDialog from "../../components/selection/PositionsDialog";
import {CreateProfileServiceInput} from "../../API";
import ProfileService from "../../model/ProfileService";
import {getErrorMessage} from "../../stores/StoreUtilities";
import Notify from "../../components/notify/Notify";
import Visible from "../../components/Visible";
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
// import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import Stack from "../../components/Stack";

const styles = (theme: Theme) => createStyles({
  
})

export interface IProfilePositionsProps {
  profile?: Profile
  isOpen?: boolean
  onChange?: any
  onClose?: any
  profileStore?: ProfileStore
  userStore?: UserStore
  notify?: Notify
}

class ServiceViewModel {
  id: string 
  name: string 
  constructor(service: Service) {
    this.id = service.id 
    this.name = service.name 
  }
}

class ProfilePositionsViewModel {
  @observable services: ServiceViewModel[] = []
  
  constructor (profileServices: ProfileService[]) {
    makeObservable(this);
    profileServices.forEach(profileService => {
      if (profileService && profileService.service) {
        const viewModel = new ServiceViewModel(profileService.service)
        this.services.push(viewModel)
      }
    })
  }
}

@inject("userStore", "profileStore", "notify")
@observer
class ProfilePositions extends React.Component<WithStyles<typeof styles> & WithTheme & WithWidth & IProfilePositionsProps> {
  @observable viewModel?: ProfilePositionsViewModel
  @observable isDialogOpen: boolean = false
  @observable isProcessing = false
  state = {
    isListExpanded: false,
  }

  constructor(
    props: WithStyles<typeof styles> & WithTheme & WithWidth & IProfilePositionsProps
  ) {
    super(props);
    makeObservable(this);
  }

  componentDidMount() {
    when(
      // once...
      () => this.props.profile !== undefined,
      // ... then
      () => this.loadServices()
    )
  }

  componentDidUpdate(prevProps: any) {
    if (prevProps.isOpen !== this.props.isOpen) {
      this.isDialogOpen = this.props.isOpen ?? false
    }
  }

  render() {
    const { 
      profile,
      theme 
    } = this.props

    if (!profile) {
      return null
    }

    return (
      <Box 
        px={2} 
        py={2}
        bgcolor={theme.palette.common.white}
        borderRadius={10}
      >
        <Grid 
          container 
          direction="row" 
          justifyContent="flex-start" 
          alignItems="center" 
          spacing={1}
        >
          <Grid item>
            <Stack
              alignItems="center"
              spacing={1}
              sx={{
                // pr: 3,
                width: '150px'
              }}
            >
              <Box
                px={1}
                pt={1}
                pb={0.5}
                bgcolor={theme.palette.grey[200]}
                borderRadius={10}
                color="#000"
              >
                <FaceIcon color="inherit" />
              </Box>
              <Typography variant="body1">Services</Typography>
            </Stack>
          </Grid>
          {this.renderProfileChips()}
          <Grid item>
            {this.renderEditButton()}
          </Grid>
        </Grid>
        {this.renderDialog()}
      </Box>
    )
  }

  loadServices = async () => {
    let profile = this.props.profile
    if (profile === undefined) {
      Logger.error('Profile not found.')
      return 
    }

    this.viewModel = new ProfilePositionsViewModel(profile.profileServices)
  }

  renderProfileChips() {
    const viewModel = this.viewModel
    if (viewModel === undefined) {
      return null 
    }

    const numberToShow = 6
    if (viewModel.services.length > numberToShow) {
      const visible = viewModel.services.slice(0, numberToShow)
      const invisible = viewModel.services.slice(numberToShow)
      return (
        <React.Fragment>
          { visible.map(service => {
            return (
              <Grid item key={service.id}>
                <Chip color="default" size="medium" icon={<FaceIcon />} label={service.name}/>
              </Grid>
            )
          })}
          <Visible if={this.state.isListExpanded}>
            { invisible.map(service => {
              return (
                <Grid item key={service.id}>
                  <Chip color="default" size="medium" icon={<FaceIcon />} label={service.name}/>
                </Grid>
              )
            })}
          </Visible>
          <Grid item>
            <IconicButton 
              onClick={() => {
                this.setState({ isListExpanded: !this.state.isListExpanded })
              }}
            >
              { this.state.isListExpanded ? <ChevronLeftIcon /> : <ChevronRightIcon /> }
            </IconicButton>
          </Grid>
        </React.Fragment>
      )
    } else {
      return (
        viewModel.services.map(service => {
          return (
            <Grid item key={service.id}>
              <Chip color="default" size="medium" icon={<FaceIcon />} label={service.name}/>
            </Grid>
          )
        })
      )
    }
  }

  renderEditButton() {
    const { onChange } = this.props 
    let editButton
    if (onChange) {
      if (this.viewModel && this.viewModel.services.length > 0) {
        editButton = <IconicButton onClick={this.handleEdit}>
          <EditIcon />
        </IconicButton>
      } else {
        editButton = <AddButton
          text="Add Job Positions"
          tracking="profileAddJobPositions"
          buttonColor="secondary"
          buttonVariant="contained"
          click={this.handleEdit}
        />
      }
    }
    return (
      <div>
        {editButton}
      </div>
    )
  }

  renderDialog() {
    const { onChange, profile } = this.props
    if (onChange === undefined) {
      return null 
    }

    return (
      <PositionsDialog
        isDialogOpen={this.isDialogOpen}
        profile={profile}
        onClose={this.handleClose}
        onSubmitSelections={this.handleSubmitSelections}
        isProcessing={this.isProcessing}
      /> 
    )
  }

  handleEdit = (): void => {
    if (this.props.profile) {
      this.isDialogOpen = true
    }
  }

  handleClose = () => {
    this.isDialogOpen = false
    if (this.props.onClose) {
      this.props.onClose()
    }
  }

  handleSubmitSelections = async (selectedIds: string[]) => {
    const { profile, profileStore, notify } = this.props
    
    if (this.props.onChange) {
      this.isProcessing = true

      // Update ProfileServices

      const results = await this.updateServices(selectedIds)
        .catch((err) => {
          notify!.show("error", getErrorMessage(err))
        })

      if (results) {
        const updatedProfile = await profileStore!.getProfile(profile!.id)
        if (updatedProfile) {
          this.viewModel = new ProfilePositionsViewModel(updatedProfile.profileServices)
          this.props.onChange(updatedProfile)
        }
        this.isProcessing = false
        this.handleClose()
      }

      this.isProcessing = false
    } else {
      this.isProcessing = false
    }
  }

  updateServices = async (selectedIds: string[]) => {
    const {profile, profileStore} = this.props

    const promises: Promise<ProfileService | undefined>[] = []

    // Create new ProfileServices
    selectedIds.forEach((serviceId: string) => {
      let profileService = profile!.getProfileService(serviceId)
      if (!profileService) {
        const input: CreateProfileServiceInput = {
          userId: profile!.userId,
          profileId: profile!.id,
          serviceId: serviceId,
          industries: profile!.industries ?? []
        }
        promises.push(profileStore!.createProfileService(input))
      }
    })

    // Delete unused ProfileServices
    profile!.profileServices.forEach((profileService: ProfileService) => {
      if (selectedIds.indexOf(profileService.serviceId) < 0) {
        promises.push(profileStore!.deleteProfileService(profileService.id))
      }
    })

    return await Promise.all(promises)
  }
}

export default withTheme(withStyles(styles)(withWidth()(ProfilePositions)))