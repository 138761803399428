import { useEffect, useState } from "react"
import SimpleForm from "../../components/form/SimpleForm"
import Account from "../../model/Account"
import { useStores } from "../../stores/StoreProvider"
import TextFieldValidator from "../../components/form/TextFieldValidator"
import DialogPanel from "../../components/panel/DialogPanel"
import DialogPanelInstructions from "../../components/panel/DialogPanelInstructions"
import LoadingPanel from "../../components/panel/LoadingPanel"
import { when } from "mobx"
import DialogPanelButtons from "../../components/panel/DialogPanelButtons"

const ServiceRequestAccountForm = ({
  account,
  onClickBack,
  onSelectAccount
}: {
  account?: Account
  onClickBack: () => void 
  onSelectAccount: (account: Account) => Promise<void> 
}) => {
  const { accountStore, userStore } = useStores()

  const [isLoading, setIsLoading] = useState(true)
  const [isProcessing, setIsProcessing] = useState(false)
  const [selectedAccount, setSelectedAccount] = useState<Account>() 
  const [accounts, setAccounts] = useState<Account[]>()

  useEffect(() => {
    const init = async () => {
      const accounts = await accountStore.listAccounts()
      accounts.sort((a: Account, b: Account) => a.name.localeCompare(b.name))
      setAccounts(accounts)

      setSelectedAccount(account)

      setIsLoading(false)
    }

    when(
      () => accountStore.isLoading === false, 
      () => {
        init() 
      }
    )
  }, [
    account 
  ])
  
  const renderContent = () => {
    return (
      <SimpleForm 
        formId={""} 
        onSubmit={async () => {
          setIsProcessing(true)
          await onSelectAccount(selectedAccount!)
        }} 
        buttonArea={
          <DialogPanelButtons
            isProcessing={isProcessing}
            buttonDisabled={false}
            // onClickNext={() => {
            //   setStep(RequestStep.EnterJob)
            // }} 
            onClickBack={onClickBack} 
          />
        }
      >
        { userStore.isAdminOrAgent && accounts && 
          <TextFieldValidator
            type="text"
            validators={{ required: true }}
            name="account"
            label="Account"
            fullWidth
            required
            margin="dense"
            autocompleteOptions={{
              freeSolo: false,
              options: accounts,
              value: selectedAccount ?? null,
              getOptionLabel: (option: Account) => option?.name,
              getOptionSelected: (option: Account, value: Account) => option.id === value.id,
              onChange: (event: any, value: any, reason: any) => {
                const account = value as Account
                setSelectedAccount(account)
  
                // if (account.industries) {
                //   const industryOptions = account.industries
                //   if (industryOptions.length === 1) {
                //     // If an account only has one industry, set that industry automatically and only show positions relevant to that industry.
                //     setIndustries(industryOptions)
                //   }
                //   setIndustryOptions(industryOptions)
                // } else {
                //   const industryOptions = Object.values(NAICS)
                //   setIndustryOptions(industryOptions)
                // }
              }
            }}
          />
        }
      </SimpleForm>
    )
  }

  return (
    <DialogPanel>
      <DialogPanelInstructions 
        title="Select Account"
        instructions={[
          "Choose the account for this service request."
        ]}
      />
      {/* { message && 
        <Box>
          <Typography color="error">
            {message}
          </Typography>
        </Box>
      } */}
      { isLoading ? <LoadingPanel /> : renderContent() }
    </DialogPanel>
  )
}

export default ServiceRequestAccountForm
