import React from "react";
import {
  Box,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Link,
  TextareaAutosize,
  Theme,
  Typography,
  withStyles,
  WithStyles,
  withTheme,
  WithTheme,
  withWidth,
  WithWidth
} from "@material-ui/core";
import {makeObservable, observable} from "mobx";
import {inject, observer} from "mobx-react";
import JobPost from "../../model/JobPost";
import FormValidator from "../form/FormValidator";
import CardValue from "../CardValue";
import ProfileStore from "../../stores/ProfileStore";
import {durationBetweenISODates, getISODateToday, humanizeString} from "../../stores/StoreUtilities";
import ProgressButton from "../form/ProgressButton";
import {
  CreateJobInterestInput,
  JobInterestStatus,
  ProfileStatus,
  UpdateJobInterestInput,
  UpdateProfileInput
} from "../../API";
import JobInterest from "../../model/JobInterest";
import UserStore from "../../stores/UserStore";
import CancelButton from "../form/CancelButton";
import ControlTower, {Routes} from "../ControlTower";
import DialogButton from "../form/DialogButton";
import Question, {
  CertificationQuestion,
  EducationQuestion,
  ExperienceQuestion,
  LocationQuestion,
  QuestionType, SecurityClearanceQuestion,
  YesNoQuestion
} from "../../model/Question";
import ExperienceQuestionCard from "./screening/ExperienceQuestionCard";
import Answer from "../../model/Answer";
import LocationQuestionCard from "./screening/LocationQuestionCard";
import CertificationQuestionCard from "./screening/CertificationQuestionCard";
import EducationQuestionCard from "./screening/EducationQuestionCard";
import YesNoQuestionCard from "./screening/YesNoQuestionCard";
import JobStore from "../../stores/JobStore";
import Notify, {NotifyVariant} from "../notify/Notify";
import AuthButton from "../login/AuthButton";
import ApplyPage from "../../pages/apply/ApplyPage";
import SecurityClearanceQuestionCard from "./screening/SecurityClearanceQuestionCard";

const styles = (theme: Theme) => createStyles({
  form: {
    overflowY: "auto",
    display: "flex",
    flexDirection: "column"
  },
  progressButton: {
    minWidth: 80
  },
  error: {
    color: theme.palette.error.main
  },
  label: {
    color: theme.palette.text.secondary,
    fontSize: 15,
    marginTop: theme.spacing(1),
    marginBottom: 4,
    marginLeft: 0,
  },
  textarea: {
    fontFamily: [
      'Roboto',
      'sans-serif'
    ].join(','),
    minWidth: "100%",
    maxWidth: "100%",
    padding: 8,
    fontSize: 16
  },
})

const SignupQuestion = "Signup Question"

export interface IJobInterestApplyDialogProps {
  jobPost: JobPost
  isOpen: boolean 
  onSubmit?(jobPost: JobPost): void
  onClose?(): void
  profileStore?: ProfileStore
  userStore?: UserStore
  jobStore?: JobStore
  notify?: Notify
}

@inject("profileStore", "userStore", "jobStore", "notify")
@observer
class JobInterestApplyDialog extends React.Component<WithStyles<typeof styles> & WithTheme & WithWidth & IJobInterestApplyDialogProps> {

  @observable message = ""
  @observable error = ""
  @observable isProcessing = false
  @observable isCandidate = false
  @observable showSummary = true
  @observable showQuestion = -1
  @observable questions: Question[] = []
  @observable answers: Answer[] = []
  @observable isAuthVisible: boolean = false
  @observable isGovGigApplyVisible: boolean = false

  constructor(
    props: WithStyles<typeof styles> & WithTheme & WithWidth & IJobInterestApplyDialogProps
  ) {
    super(props);
    makeObservable(this);
    const {userStore} = this.props
    if (userStore!.user && userStore!.user!.isCandidate) {
      this.isCandidate = true
    }
  }

  componentDidMount() {
    const { jobPost, userStore } = this.props
    this.message = ""
    this.showSummary = true
    this.showQuestion = -1
    if (!userStore!.isAuthenticated) {
      const signupQuestion = new YesNoQuestion({
        type: QuestionType.YesNoQuestion,
        name: SignupQuestion,
        question: "Have you signed up for a GovGig account?",
        label: "GovGig Candidate"
      })
      this.questions = [signupQuestion, ...jobPost.questions]
    } else {
      this.questions = jobPost.questions
    }
    this.answers = []
  }

  componentDidUpdate(prevProps: Readonly<WithStyles<typeof styles> & WithTheme & WithWidth & IJobInterestApplyDialogProps>, prevState: Readonly<{}>, snapshot?: any) {
    if (this.props.jobPost.updatedAt !== prevProps.jobPost.updatedAt) {
      this.componentDidMount()
    }
  }

  render() {
    const { classes, jobPost, isOpen } = this.props

    const hiringPeriod = jobPost.hiringPeriod
    const duration = durationBetweenISODates(jobPost.startDate, jobPost.endDate)

    return (
      <Dialog
        open={isOpen}
        onClose={this.onClose}
        fullWidth
        maxWidth="sm"
        scroll="paper"
      >
        <FormValidator onSubmit={this.onSubmit} autoComplete="off" name="inviteForm" id="inviteForm" className={classes.form}>
          <DialogTitle id="form-dialog-title">Apply for Job</DialogTitle>
          <DialogContent dividers>
            <DialogContentText className={classes.error}>
              {this.error}
            </DialogContentText>
            <Grid container direction="column">
              <Grid item>
                <CardValue label="Position">
                  {jobPost.title}
                </CardValue>
              </Grid>
              <Grid item>
                <CardValue label="Location">
                  {jobPost.location ? jobPost.location.name : ""}
                </CardValue>
              </Grid>
              <Grid item>
                <CardValue label="Job Type">
                  {humanizeString(jobPost.employmentType)}
                </CardValue>
              </Grid>
              {hiringPeriod &&
                <Grid item>
                  <CardValue label="Hiring">
                    {hiringPeriod}
                  </CardValue>
                </Grid>
              }
              {duration &&
                <Grid item>
                  <CardValue label="Date(s)">
                    {duration}
                  </CardValue>
                </Grid>
              }
              {this.showSummary && this.renderSummary()}
              {this.showQuestion >= 0 && this.renderQuestion()}
            </Grid>
          </DialogContent>
          <DialogActions>
            <Grid container justifyContent="flex-end">
              {this.renderActionButtons()}
            </Grid>
          </DialogActions>
        </FormValidator>
        {this.isAuthVisible &&
          <AuthButton
            open={true}
            onLogin={this.onLogin}
            onClose={() => this.isAuthVisible = false} />
        }
        {this.isGovGigApplyVisible &&
          <ApplyPage
            renderAsDialog={true}
            onClose={() => this.isGovGigApplyVisible =false}
            onApply={this.onGovGigApply}
            />
        }
      </Dialog>
    )
  }

  renderActionButtons() {
    const {classes, userStore} = this.props

    const buttons = []
    buttons.push(<CancelButton onClick={this.onClose} />)
    if (this.showQuestion >= 0) {
      buttons.push(
        <Box mr={1} key="applyDialog/prev">
          <DialogButton variant="primary" type="button"
                        onClick={this.onPrev}>
            Prev
          </DialogButton>
        </Box>
      )
    }
    if (this.showQuestion+1 === this.questions.length && userStore!.isAuthenticated) {
      buttons.push(
        <ProgressButton variant="contained" size="small" color="primary"
                        type="submit" className={classes.progressButton}
                        processing={this.isProcessing} key="applyDialog/apply">
          Apply
        </ProgressButton>
      )
    } else {
      buttons.push(
        <DialogButton variant="primary" type="button" onClick={this.onNext} key="applyDialog/next">
          Next
        </DialogButton>
      )
    }

    return buttons
  }

  renderSummary() {
    const { classes, jobPost } = this.props

    return (
      <Grid item style={{minHeight:154}}>
        <div className={classes.label}>Job Summary</div>
        <Typography variant="body1" style={{overflow: 'hidden', textOverflow: 'ellipses'}}>
          {jobPost.summary}
        </Typography>
      </Grid>
    )
  }

  renderMessage() {
    const { classes } = this.props
    return (
      <React.Fragment>
        <Grid item style={{minHeight:160}}>
          <div className={classes.label}>Message</div>
          <TextareaAutosize
            name="message"
            value={this.message}
            onChange={this.onChangeMessage}
            minRows={6}
            className={classes.textarea}
            placeholder="Briefly tell who you are and why you are a good fit for this position."
          />
        </Grid>
        {!this.isCandidate &&
          <Grid item>
            <DialogContentText style={{paddingTop:8}}>
              <b>Pro Tip:</b> Submit your&nbsp;
              <Link onClick={() => ControlTower.route(Routes.profile)}>
                GovGig Profile
              </Link>
              &nbsp;to make your application complete.
            </DialogContentText>
          </Grid>
        }
      </React.Fragment>
    )
  }

  renderQuestion() {
    const { classes } = this.props
    if (this.showQuestion+1 > this.questions.length) {
      return null
    }

    let card = null
    const question = this.questions[this.showQuestion]
    const answer = this.showQuestion < this.answers.length ? this.answers[this.showQuestion] : undefined
    switch (question.type) {
      case QuestionType.CertificationQuestion:
        card = <CertificationQuestionCard question={question as CertificationQuestion} answer={answer} onAnswer={this.onAnswer}/>
        break
      case QuestionType.EducationQuestion:
        card = <EducationQuestionCard question={question as EducationQuestion} answer={answer} onAnswer={this.onAnswer}/>
        break
      case QuestionType.ExperienceQuestion:
        card = <ExperienceQuestionCard question={question as ExperienceQuestion} answer={answer} onAnswer={this.onAnswer}/>
        break
      case QuestionType.LocationQuestion:
        card = <LocationQuestionCard question={question as LocationQuestion} answer={answer} onAnswer={this.onAnswer}/>
        break
      case QuestionType.YesNoQuestion:
        card = <YesNoQuestionCard question={question as YesNoQuestion} answer={answer} onAnswer={this.onAnswer}/>
        break
      case QuestionType.SecurityClearanceQuestion:
        card = <SecurityClearanceQuestionCard question={question as SecurityClearanceQuestion} answer={answer} onAnswer={this.onAnswer}/>
        break
    }

    return (
      <Grid item style={{minHeight:150}}>
        <div className={classes.label}>Question {this.showQuestion+1} of {this.questions.length}</div>
        {card}
      </Grid>
    )
  }

  isSignupQuestion = () => {
    return (this.showQuestion === 0 && this.questions.length > 0 && this.questions[0].name === SignupQuestion)
  }

  onChangeMessage = (event: any) => {
    this.message = event.target.value
  }

  onPrev = () => {
    this.showQuestion -= 1
    this.showSummary = this.showQuestion < 0
  }

  onNext = () => {
    if (this.isSignupQuestion()) {
      const answer = this.answers[0]
      console.log(`Signup Answer: ${answer.value}`)
      if (answer?.value === "true") {
        // Show Login Dialog
        this.isAuthVisible = true
      } else {
        // Show Apply Dialog
        this.isGovGigApplyVisible = true
      }
    } else {
      this.showQuestion += 1
      this.showSummary = false
    }
  }

  onAnswer = (answer: Answer) => {
    const index = this.answers.findIndex((a: Answer) => a.name === answer.name)
    if (index >= 0) {
      this.answers[index] = answer
      console.debug(`answer[${index}] = ${JSON.stringify(answer)}`)
    } else {
      this.answers.push(answer)
      console.debug(`answer[${this.answers.length-1}] = ${JSON.stringify(answer)}`)
    }
  }

  onLogin = () => {
    this.isAuthVisible = false
    if (this.showQuestion < this.questions.length-1) {
      this.showQuestion += 1
    }
  }

  onGovGigApply = () => {
    this.isGovGigApplyVisible = false
    if (this.showQuestion < this.questions.length-1) {
      this.showQuestion += 1
    }
  }

  onSubmit = async () => {
    const {onSubmit, jobPost, userStore, jobStore, profileStore, notify} = this.props

    this.isProcessing = true
    this.error = ""

    // Remove Signin Question answer if present
    let answers: Answer[]
    if (this.answers.length > 0 && this.answers['name'] === SignupQuestion) {
      answers = this.answers.slice(1)
    } else {
      answers = this.answers
    }

    let jobInterest: JobInterest | undefined | void = jobPost.jobInterests.length > 0 ? jobPost.jobInterests[0] : undefined
    if (!jobInterest) {
      // Create new jobInterest
      const input: CreateJobInterestInput = {
        userId: userStore!.user!.id,
        jobPostId: jobPost.id,
        status: JobInterestStatus.Applied,
        ranking: 0,
        message: this.message,
        application: answers.length > 0 ? JSON.stringify(answers) : null
      }

      jobInterest = await jobStore!.createJobInterest(input)
        .catch((err: Error) => {
          this.error = "Error applying for job."
        })

      if (jobInterest) {
        jobPost.jobInterests.push(jobInterest)
      }
    } else {
      // Update JobInterest
      const input: UpdateJobInterestInput = {
        id: jobInterest.id,
        status: JobInterestStatus.Applied,
        message: this.message,
        application: answers.length > 0 ? JSON.stringify(answers) : null
      }
      jobInterest = await jobStore!.updateJobInterest(input)
        .catch((err: Error) => {
          this.error = "Error applying for job."
        })

      if (jobInterest) {
        jobPost.jobInterests[0] = jobInterest
      }

      this.isProcessing = false
    }

    if (this.error === "" && onSubmit) {
      let variant: NotifyVariant = "success"
      if (userStore!.isApplicant) {
        const profile = await profileStore!.getUserProfile(userStore!.user!.id)
        if (profile && profile.profileStatus !== ProfileStatus.Accepted) {
          // Add a note to the profile
          const notes = profile.notes && profile.notes.length > 0 ?
            `${profile.notes} \n ${getISODateToday()} Applied for ${jobPost.title}` :
            `${getISODateToday()} Applied for ${jobPost.title}`
          const update: UpdateProfileInput = {
            id: profile.id,
            notes: notes
          }
          await profileStore!.updateProfile(update)
        }
        if (profile && profile.profileStatus === ProfileStatus.Pending) {
          variant = "info"
          notify!.show(variant, "Your application has been submitted! Please complete your profile to make your application stand out.")
          ControlTower.route(Routes.profile)
          return
        }
      }

      notify!.show(variant, "Your application has been submitted!")
      onSubmit(jobPost)
    }
  }

  onClose = () => {
    const { onClose } = this.props
    this.showSummary = true
    this.showQuestion = -1

    if (onClose) {
      onClose()
    }
  }

}

export default withTheme(withStyles(styles)(withWidth()(JobInterestApplyDialog)))