import {JobCandidateStatus, ProfileStatus} from "../API";
import Account from "./Account";
import Profile from "./Profile";
import JobPost from "./JobPost";
import {format} from "date-fns";
import {isoToLocalDate} from "../stores/StoreUtilities";
import Answer from "./Answer";
import {QuestionType} from "./Question";

class JobCandidate {
  id: string
  createdAt: string
  updatedAt: string
  jobPostId: string
  jobPost?: JobPost
  accountId: string
  account?: Account
  profileId: string
  profile?: Profile
  status: JobCandidateStatus
  ranking: number
  notes: string
  message: string
  unlocked: boolean
  application: Answer[]

  constructor(data: any) {
    this.id = data.id
    this.createdAt = data.createdAt ?? ""
    this.updatedAt = data.updatedAt ?? ""
    this.jobPostId = data.jobPostId
    this.jobPost = data.jobPost ? new JobPost(data.jobPost) : undefined
    this.accountId = data.accountId
    this.account = data.account ? new Account(data.account) : undefined
    this.profileId = data.profileId
    this.profile = data.profile ? new Profile(data.profile) : undefined
    this.status = data.status
    this.ranking = this.computeRanking()
    this.notes = data.notes
    this.message = data.message
    this.unlocked = data.unlocked ?? false 
    this.application = this.loadApplication(data)
  }
  
  loadApplication(data: any) {
    let application: Answer[] = []
    if (data.application) {
      let rawData
      if (Array.isArray(data.application)) {
        // Assume data is JSON, i.e. from DynamoDB via AWS SDK directly. 
        rawData = data.application
      } else {
        // Assume typeof data.application === 'string', i.e. from AppSync. 
        rawData = JSON.parse(data.application)
      }
      application = rawData.map((answerData: any) => new Answer(answerData))
    }

    if (this.profile && this.profile.profileStatus !== ProfileStatus.Accepted) {
      // Add - GovGig Candidate answer if profile is not accepted
      const answer = new Answer({
        name: "GovGig Candidate",
        type: QuestionType.YesNoQuestion,
        required: false,
        value: "false",
        pass: false,
        label: `GovGig Candidate: ${this.profile.profileStatus}`
      })
      return [answer, ...application]
    } else {
      return application
    }
  }

  get statusText() : string {
    const formattedDate = format(isoToLocalDate(this.updatedAt), "M/d/yyyy")
    let text
    if (this.status === JobCandidateStatus.Interested) {
      text = `Added on ${formattedDate}`
    } else {
      text = `${this.status} on ${formattedDate}`
    }
    return text
  }

  static statusRanking: JobCandidateStatus[] = [
    JobCandidateStatus.Declined,
    JobCandidateStatus.Rejected,
    JobCandidateStatus.Interested,
    JobCandidateStatus.Invited,
    JobCandidateStatus.Unlocked,
    JobCandidateStatus.Contacted,
    JobCandidateStatus.Reviewing,
    JobCandidateStatus.Accepted,
    JobCandidateStatus.Applied,
    JobCandidateStatus.Hired,
  ]

  computeRanking() {
    return JobCandidate.statusRanking.findIndex((status: JobCandidateStatus) => status === this.status)
  }

  static compareFn(a: JobCandidate, b: JobCandidate): number {
    let result = b.ranking - a.ranking
    if (result === 0) {
      result = b.updatedAt.localeCompare(a.updatedAt)
    }
    return result
  }
}

export default JobCandidate