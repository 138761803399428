import { Box, Paper, Typography, useTheme } from "@material-ui/core"
import { useStores } from "../../stores/StoreProvider"
import { useEffect, useState } from "react"
import Agreement from "../../model/Agreement"
import * as APITypes from "../../API"
import ControlTower, { Routes } from "../../components/ControlTower"
import Stack from "../../components/Stack"
import ActionLink from "../../components/ActionLink"
import { when } from "mobx"
import LoadingPanel from "../../components/panel/LoadingPanel"
import { PricingAgreementType } from "../../stores/AccountStore"
import ServiceRequest from "../../model/ServiceRequest"

const ServiceRequestPricing = ({
  accountId, 
  serviceRequest 
}: {
  accountId: string 
  serviceRequest: ServiceRequest

}) => {
  // const theme = useTheme() 
  const { accountStore, userStore } = useStores()

  const [isLoading, setIsLoading] = useState(true)
  const [agreements, setAgreements] = useState<Agreement[]>()
  const [pricingAgreementType, setPricingAgreementType] = useState<PricingAgreementType | null>()

  useEffect(() => {
    const init = async () => {
      // setIsLoading(true)
      const agreements = await accountStore.listAgreementsByAccount(accountId)
      setAgreements(agreements)

      const pricingAgreementType = await accountStore.getPricingAgreementType(accountId)
      setPricingAgreementType(pricingAgreementType)

      // let hasMasterServicesAgremement = false 
      // // let hasConfidentialityAgreement = false 
      // let hasExclusiveAgreement = false 
      // if (agreements && agreements.length > 0) {
      //   agreements.forEach((agreement: Agreement) => {
      //     if (agreement.hasAgreementType(APITypes.AgreementType.MasterServicesAndNonSolicitation)) {
      //       hasMasterServicesAgremement = true 
      //     } 
      //     // if (agreement.hasAgreementType(APITypes.AgreementType.ConfidentialityAndNonDisclosure)) {
      //     //   hasConfidentialityAgreement = true 
      //     // }
      //     if (agreement.hasAgreementType(APITypes.AgreementType.ExclusiveRecruitment)) {
      //       hasExclusiveAgreement = true 
      //     }
      //   })
      // }
      
      // if (hasMasterServicesAgremement && !hasExclusiveAgreement) {
      //   setPricingAgreementType(PricingAgreementType.Standard)
      // } else if (hasMasterServicesAgremement && hasExclusiveAgreement) {
      //   setPricingAgreementType(PricingAgreementType.Exclusive)
      // }

      setIsLoading(false)
    }

    when(
      () => accountStore.isLoading === false, 
      () => {
        init() 
      }
    )

  }, [
    accountStore,
    accountId
  ])
  
  const renderSelectionTitle = () => {
    if (serviceRequest.serviceRequestType === APITypes.ServiceRequestType.StaffingServices) {
      return "Staffing Services Agreement Pending"
    } else if (pricingAgreementType === PricingAgreementType.Exclusive) {
      return "Exclusive Agreement"
    } else if (pricingAgreementType === PricingAgreementType.Standard) {
      return "Standard Agreement"
    } else {
      return ""
    }
  }

  const renderConfidentialityAgreement = () => {
    let ndaRoute: string | undefined = undefined
    const accountId = accountStore.account!.id
    if (agreements && agreements.length > 0) {
      const nda = agreements.find((a: Agreement) => a.agreementTypes.includes(APITypes.AgreementType.ConfidentialityAndNonDisclosure))
      if (nda) {
        ndaRoute = `${Routes.nda}?accountId=${accountId}`
      }
    }

    if (ndaRoute) {
      return (
        <Stack
          direction="column"
          spacing={2}
        >
          <Typography variant="h6">Confidentiality And Non-Disclosure Agreement</Typography>
          <Box>
            <Typography>Existing Confidentiality agreement in place:</Typography>
            <ActionLink
              text="Confidentiality And Non-Disclosure Agreement"
              tracking="NDA-Agreement"
              click={() => {
                ControlTower.open(ndaRoute!, "nda")
              }}
            />
          </Box>
        </Stack>
      )
    } else {
      return (
        <Stack
          direction="column"
          spacing={2}
        >
          <Typography variant="h6">Confidentiality And Non-Disclosure Agreement</Typography>
          <Typography>Note there is no Confidentiality And Non-Disclosure Agreement in place.</Typography>
        </Stack>
      )
    }
  }

  const renderPricingAgreement = () => {
    let exclusiveRoute: string | undefined = undefined
    let msaRoute: string | undefined = undefined
    const accountId = accountStore.account!.id
    if (agreements && agreements.length > 0) {
      const exclusive = agreements.find((a: Agreement) => a.agreementTypes.includes(APITypes.AgreementType.ExclusiveRecruitment))
      if (exclusive) {
        exclusiveRoute = `${Routes.msaExclusive}?accountId=${accountId}`
      } 
      const msa = agreements.find((a: Agreement) => a.agreementTypes.includes(APITypes.AgreementType.MasterServicesAndNonSolicitation))
      if (msa) {
        msaRoute = `${Routes.msa}?accountId=${accountId}`
      }
    }

    if (userStore.isAdminOrAgent && serviceRequest.serviceRequestType === APITypes.ServiceRequestType.StaffingServices) {
      return (
        <Box>
          <Typography>Since this is a staffing services request, GovGig will work on an agreement proposal for this request.</Typography>
        </Box>
      )
    } else if (userStore.isAdminOrAgent && !pricingAgreementType) {
      return (
        <Box>
          <Typography>There is no pricing agreement in place yet for this account.</Typography>
        </Box>
      )
    } else if (serviceRequest.serviceRequestType === APITypes.ServiceRequestType.StaffingServices) {
      return (
        <Box>
          <Typography>When you complete and send your request, we will work on an agreement proposal for staffing services.</Typography>
        </Box>
      )
    } else if (pricingAgreementType === PricingAgreementType.Exclusive && exclusiveRoute) {
      return (
        <Stack
          direction="column"
          spacing={0}
        >
          <Typography>Existing agreement in place:</Typography>
          <ActionLink
            text="Exclusive Master Services & Non-Solicitation Agreement"
            tracking="Exclusive-MSNSA-Agreement"
            click={() => {
              ControlTower.open(exclusiveRoute!, "msnsa")
            }}
          />
        </Stack>
      )
    } else if (pricingAgreementType === PricingAgreementType.Standard && msaRoute) {
      return (
        <Box
          // px={2}
        >
          <Typography>Existing agreement in place:</Typography>
          <ActionLink
            text="Master Services & Non-Solicitation Agreement"
            tracking="MSNSA-Agreement"
            click={() => {
              ControlTower.open(msaRoute!, "msnsaExclusive")
            }}
          />
        </Box>
      )
    }
  }
  
  const renderSelectionInfo = () => {
    
    return (
      <Stack
        direction="column"
        sx={{
          p: 3
        }}
        spacing={3}
      >
        <Typography variant="h6">{renderSelectionTitle()}</Typography>
        { renderPricingAgreement() }
        { renderConfidentialityAgreement() }
      </Stack>
    )
  }

  const renderLoading = () => {
    return (
      <Box py={3}>
        <LoadingPanel /> 
      </Box>
    )
  }

  return (
    <Paper>
      { isLoading === true ? renderLoading() : renderSelectionInfo() }
    </Paper>
  )
}

export default ServiceRequestPricing
