import { RouteComponentProps } from "@reach/router"
import Page from "../../components/page/Page"
import { useEffect, useState } from "react"
import { useStores } from "../../stores/StoreProvider"
import User from "../../model/User"
import { when } from "mobx"
import { Box, useMediaQuery, useTheme } from "@material-ui/core"
import Stack from "../../components/Stack"
import AvailableServices from "../../views/employers/AvailableServices"
import ServiceRequestsList from "../../views/serviceRequests/ServiceRequestsList"
import LoadingPanel from "../../components/panel/LoadingPanel"
import PageTitle from "../../components/page/PageTitle"

const ServiceRequestsPage = ({

}: RouteComponentProps) => {
  const title = "Service Requests"
  const [isLoading, setIsLoading] = useState(true)
  const { accountStore, userStore } = useStores()
  const [user, setUser] = useState<User>()
  const theme = useTheme()
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'))

  useEffect(() => {
    when(
      () => accountStore.isLoading === false && userStore.isLoading === false, 
      () => {
        setUser(userStore.user!)
        setIsLoading(false)
      }
    )
  }, [

  ])

  const renderContent = () => {
    return (
      <Box
        px={ isSmallScreen ? 2 : 3 }
        pb={5}
      >
        <Stack
          direction="column"
          spacing={2}
        >
          <AvailableServices />
          <ServiceRequestsList 
            
          />
        </Stack>
      </Box>
    )
  }

  const renderLoading = () => {
    return (
      <LoadingPanel />
    )
  }

  return (
    <Page
      title={title}
    >
      <PageTitle title={title} />
      { isLoading ? renderLoading() : renderContent() }
    </Page>
  )
}

export default ServiceRequestsPage 
