import {
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormGroup,
  Theme,
  withStyles
} from '@material-ui/core';
import {WithStyles} from '@material-ui/core/styles';
import {makeObservable, observable} from 'mobx';
import {inject, observer} from 'mobx-react';
import React from 'react';
import FormValidator from '../../../components/form/FormValidator';
import ProgressButton from '../../../components/form/ProgressButton';
import StateSelector from '../../../components/form/StateSelector';
import TextFieldValidator from '../../../components/form/TextFieldValidator';
import {phoneToE164Format, phoneToNationalFormat} from "../../../stores/StoreUtilities";
import Notify from '../../../components/notify/Notify';
import Account from '../../../model/Account';
import AccountStore from '../../../stores/AccountStore';
import {AccountStatus, SubjectType} from '../../../API';
import UserStore from '../../../stores/UserStore';
import CancelButton from '../../../components/form/CancelButton';
import IndustrySelector from "../../../components/form/IndustrySelector";
import {ActivityType} from "../../../model/UserActivity";
import Stack from '../../../components/Stack';

const styles = (theme: Theme) => createStyles({
  wrapper: {
    maxWidth: theme.breakpoints.values.sm,
    paddingBottom: theme.spacing(3)
  },
  submitButtonContainer: {
    paddingTop: theme.spacing(2),
  },
  submitButton: {
    color: "#fff",
  },
  formTitle: {
    color: '#181818',
    [theme.breakpoints.down('xs')]: {
      fontSize: '30px',
      marginTop: 0,
      marginBottom: 0,
      paddingTop: 0
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: '36px',
      marginBottom: 0
    }
  },
  formSubTitle: {
    fontSize: '17px',
    color: '#181818',
    letterSpacing: '0.75px',
    fontWeight: 400,
    [theme.breakpoints.down('xs')]: {
      display: "none"
    },
  },
  formGroupRow: {
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "nowrap"
  },
  formGroupField: {
    flexGrow: 1,
  },
  formGroupSpacing: {
    flexGrow: 0,
    width: 10,
  },
  formGroupSpacingSmUp: {
    flexGrow: 0,
    [theme.breakpoints.up('sm')]: {
      width: 10,
    },
  },
  stateSelectorField: {
    top: -3
  },
  zipCodeField: {
    width: 100
  },
  formGroup: {
    marginBottom: 0,
    paddingBottom: 0
  },
  fieldLabel: {
    fontSize: 12,
    fontWeight: 400,
    color: theme.palette.text.secondary,
    paddingTop: theme.spacing(1),
    paddingBottom: 0
  },
})

class AccountSettingsViewModel {
  id: string
  @observable accountName: string
  @observable addressLine1: string
  @observable addressCity: string
  @observable addressState: string
  @observable addressPostalCode: string
  // @observable dunsNumber: string
  // @observable fein: string
  @observable accountStatus: AccountStatus
  @observable phone: string
  @observable webUrl: string
  // @observable naicsCode: string
  // @observable sicCode: string
  // @observable cageCode: string
  @observable socioEconomicDesignation: string
  @observable industries: string[]

  constructor(account: Account) {
    makeObservable(this)
    this.id = account.id
    this.accountName = account.name
    this.addressLine1 = account.address
    this.addressCity = account.city
    this.addressState = account.state
    this.addressPostalCode = account.postalCode
    // this.dunsNumber = account.dunsNumber
    // this.fein = account.fein
    this.accountStatus = account.accountStatus
    this.phone = phoneToNationalFormat(account.phone)
    this.webUrl = account.webUrl
    // this.naicsCode = account.naicsCode
    // this.sicCode = account.sicCode
    // this.cageCode = account.cageCode
    this.socioEconomicDesignation = account.socioEconomicDesignation
    this.industries = account.industries
  }
}

interface IAccountSettingsDialog {
  userStore?: UserStore
  accountStore?: AccountStore
  account?: Account
  notify?: Notify
  open?: boolean
  onClose: () => any
  onCreateAccount(account: Account): Promise<void> 
  onUpdateAccount(account: Account): Promise<void> 
}

@inject('userStore', 'accountStore', 'notify')
@observer
class AccountSettingsDialog extends React.Component<WithStyles<typeof styles> & IAccountSettingsDialog> {

  @observable vm?: AccountSettingsViewModel
  @observable isProcessing: boolean = false
  
  constructor(props: WithStyles<typeof styles> & IAccountSettingsDialog) {
    super(props)
    makeObservable(this)
  }

  componentDidMount() {
    const { account, userStore } = this.props
    if (account) {
      this.vm = new AccountSettingsViewModel(account)
    } else {
      this.vm = new AccountSettingsViewModel({
        id: "",
        name: "",
        address: "",
        city: "",
        state: "",
        postalCode: "",
        // fein: "",
        accountStatus: AccountStatus.Approved,
        phone: "",
        webUrl: "",
        // naicsCode: "",
        // sicCode: "",
        // cageCode: "",
        socioEconomicDesignation: ""
      } as Account)
    }
  }

  componentDidUpdate(prevProps: any) {
    if (prevProps.account !== this.props.account) {
      this.componentDidMount()
    }
  }

  handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const name = event.target.name
    this.vm![name] = event.target.value
  }

  handleChangeIndustries = (industries: string[]) => {
      this.vm!.industries =industries
  }

  handleSave = async () => {
    const { onCreateAccount, onUpdateAccount: onAccountUpdate, account, userStore } = this.props

    if (!this.vm) {
      return
    }
    this.isProcessing = true
    try {
      if (account) {
        const updatedAccount = await this.props.accountStore!.updateAccount({
          id: this.vm.id,
          name: this.vm.accountName,
          address: this.vm.addressLine1,
          city: this.vm.addressCity,
          state: this.vm.addressState,
          postalCode: this.vm.addressPostalCode,
          // dunsNumber: this.vm.dunsNumber,
          // fein: this.vm.fein,
          accountStatus: this.vm.accountStatus,
          phone: phoneToE164Format(this.vm.phone),
          webUrl: this.urlEnsureProtocolFormat(this.vm.webUrl),
          // naicsCode: this.vm.naicsCode,
          // sicCode: this.vm.sicCode,
          // cageCode: this.vm.cageCode,
          socioEconomicDesignation: this.vm.socioEconomicDesignation,
          industries: this.vm.industries
        })

        if (updatedAccount) {
          if (updatedAccount.id === this.props.userStore!.user!.account!.id) {
            this.props.userStore!.user!.account = updatedAccount
            this.props.userStore!.getIndustry()
          }
          await onAccountUpdate(updatedAccount)
        }
      } else {
        const newAccount = await this.props.accountStore!.createAccount({
          name: this.vm.accountName,
          address: this.vm.addressLine1,
          city: this.vm.addressCity,
          state: this.vm.addressState,
          postalCode: this.vm.addressPostalCode,
          // dunsNumber: this.vm.dunsNumber,
          // fein: this.vm.fein,
          accountStatus: this.vm.accountStatus,
          phone: phoneToE164Format(this.vm.phone),
          webUrl: this.urlEnsureProtocolFormat(this.vm.webUrl),
          // naicsCode: this.vm.naicsCode,
          // sicCode: this.vm.sicCode,
          // cageCode: this.vm.cageCode,
          socioEconomicDesignation: this.vm.socioEconomicDesignation,
          industries: this.vm.industries
        })

        if (newAccount) {
          userStore!.logUserActivity(userStore!.user!.id, SubjectType.Account, newAccount.id, ActivityType.AccountCreated, newAccount.name)
          await onCreateAccount(newAccount)
        }
      }

      if (this.props.onClose) {
        this.props.onClose()
      }
    } catch (err: any) {
      this.props.notify!.show('error', err.message)
    }
    this.isProcessing = false
  }

  handleCancel = () => {
    if (this.props.onClose) {
      this.props.onClose()
    }
  }

  urlEnsureProtocolFormat = (webUrl: string | null | undefined) => {
    if (!webUrl) return null // purposefully also sets "" to null
    const L = webUrl.length
    if (webUrl.slice(0, L>=7?7:0) !== "http://" && webUrl.slice(0, L>=8?8:0) !== "https://") {
      return "http://" + webUrl
    }
    return webUrl
  }

  urlRemoveProtocol = (webUrl: string | null | undefined) => {
    if (!webUrl) return null
    const L = webUrl.length
    if (webUrl.slice(0, L>=8?8:0) === "https://") {
      return webUrl.slice(8)
    } if (webUrl.slice(0, L>=7?7:0) === "http://") {
      return webUrl.slice(7)
    }
    return webUrl
  }

  render() {
    const { classes, open, userStore, accountStore } = this.props;

    if (!this.vm) {
      return null
    }

    const isAdminOrAgent = userStore!.isAdminOrAgent
    const isSelf = userStore!.user!.accountId === this.vm.id

    return (
      <Dialog fullWidth maxWidth="sm" open={Boolean(open)}>
        <DialogTitle>Account Settings</DialogTitle>
        <FormValidator onSubmit={this.handleSave} autoComplete="off" name="accountInfoForm" id="accountInfoForm">
          <DialogContent>
            <div className={classes.wrapper}>
              <Stack
                direction="column"
                spacing={1}
              >
                <TextFieldValidator
                  margin="normal"
                  name="accountName"
                  label="Company Name"
                  type="text"
                  value={this.vm.accountName}
                  validators={{ required: true }}
                  required
                  onChange={this.handleChange}
                  fullWidth
                />
                <TextFieldValidator
                  margin="dense"
                  name="addressLine1"
                  label="Street Address"
                  type="text"
                  validators={{ required: true }}
                  required
                  onChange={this.handleChange}
                  fullWidth
                  value={this.vm.addressLine1}
                />
                <FormGroup row classes={{ row: classes.formGroupRow }}>
                  <TextFieldValidator
                    margin="dense"
                    name="addressCity"
                    label="City"
                    type="text"
                    validators={{ required: true }}
                    required
                    onChange={this.handleChange}
                    value={this.vm.addressCity}
                    className={classes.formGroupField}
                  />
                  <div className={classes.formGroupSpacing} />
                  <StateSelector
                    name="addressState"
                    value={this.vm.addressState}
                    onChange={this.handleChange}
                    className={classes.stateSelectorField}
                  />
                  <div className={classes.formGroupSpacing} />
                  <TextFieldValidator
                    margin="dense"
                    name="addressPostalCode"
                    label="ZIP"
                    type="text"
                    validators={{ required: true, isPostalCode: null }}
                    required
                    onChange={this.handleChange}
                    value={this.vm.addressPostalCode}
                    className={classes.zipCodeField}
                  />
                </FormGroup>

                <FormGroup row classes={{ row: classes.formGroupRow }}>
                  <TextFieldValidator
                    margin="normal"
                    name="phone"
                    label="Company Phone"
                    type="text"
                    validators={{ required: true, isMobilePhone: null }}
                    required
                    value={this.vm.phone}
                    onChange={this.handleChange}
                    className={classes.formGroupField}
                  />
                  <div className={classes.formGroupSpacing} />
                  <TextFieldValidator
                    margin="normal"
                    name="webUrl"
                    label="Web Address"
                    placeholder="Company web address"
                    type="text"
                    validators={{ required: false }}
                    value={this.urlRemoveProtocol(this.vm.webUrl)}
                    onChange={this.handleChange}
                    className={classes.formGroupField}
                  />
                </FormGroup>

                {/*<FormGroup row classes={{ row: classes.formGroupRow }}>*/}
                {/*  <TextFieldValidator*/}
                {/*    margin="normal"*/}
                {/*    name="dunsNumber"*/}
                {/*    label="DUNS Number"*/}
                {/*    type="text"*/}
                {/*    validators={{ required: false, matches: "^\\d{2}-\\d{7}$|^\\d{9}$" }}*/}
                {/*    value={this.vm.dunsNumber}*/}
                {/*    onChange={this.handleChange}*/}
                {/*    className={classes.formGroupField}*/}
                {/*    placeholder="12-3456789"*/}
                {/*  />*/}
                {/*  <div className={classes.formGroupSpacing} />*/}
                {/*  <TextFieldValidator*/}
                {/*    margin="normal"*/}
                {/*    name="fein"*/}
                {/*    label="FEIN"*/}
                {/*    type="text"*/}
                {/*    validators={{ required: false, matches: "^\\d{2}-\\d{7}$|^\\d{9}$" }}*/}
                {/*    value={this.vm.fein}*/}
                {/*    onChange={this.handleChange}*/}
                {/*    className={classes.formGroupField}*/}
                {/*    placeholder="12-3456789"*/}
                {/*  />*/}
                {/*</FormGroup>*/}

                {/*<FormGroup row classes={{ row: classes.formGroupRow }}>*/}
                {/*  <TextFieldValidator*/}
                {/*    margin="normal"*/}
                {/*    name="naicsCode"*/}
                {/*    label="NAICS Code"*/}
                {/*    type="text"*/}
                {/*    validators={{ required: false }}*/}
                {/*    value={this.vm.naicsCode}*/}
                {/*    onChange={this.handleChange}*/}
                {/*    className={classes.formGroupField}*/}
                {/*  />*/}
                {/*  <div className={classes.formGroupSpacing} />*/}
                {/*  <TextFieldValidator*/}
                {/*    margin="normal"*/}
                {/*    name="sicCode"*/}
                {/*    label="SIC Code"*/}
                {/*    type="text"*/}
                {/*    validators={{ required: false }}*/}
                {/*    value={this.vm.sicCode}*/}
                {/*    onChange={this.handleChange}*/}
                {/*    helperText=""*/}
                {/*    className={classes.formGroupField}*/}
                {/*  />*/}
                {/*</FormGroup>*/}

                {/*<TextFieldValidator*/}
                {/*  margin="normal"*/}
                {/*  name="cageCode"*/}
                {/*  label="CAGE Code"*/}
                {/*  type="text"*/}
                {/*  validators={{ required: false }}*/}
                {/*  value={this.vm.cageCode}*/}
                {/*  onChange={this.handleChange}*/}
                {/*  fullWidth*/}
                {/*/>*/}

                <TextFieldValidator
                  margin="normal"
                  name="socioEconomicDesignation"
                  label="Socio-Economic Status"
                  type="text"
                  validators={{ required: false }}
                  value={this.vm.socioEconomicDesignation}
                  onChange={this.handleChange}
                  helperText=""
                  fullWidth
                  autocompleteOptions={{
                    freeSolo: true,
                    options: accountStore!.getSocioEconomicDesignationOptions()
                  }}
                />

                <IndustrySelector value={this.vm!.industries} onChange={this.handleChangeIndustries} required/>

                {isAdminOrAgent &&
                  <TextFieldValidator
                    margin="normal"
                    name="accountStatus"
                    label="Account Status"
                    type="text"
                    validators={{required: false}}
                    required
                    autocompleteOptions={{
                      freeSolo: false,
                      options: Object.values(AccountStatus),
                      value: this.vm.accountStatus,
                      onChange: (event: any, value: AccountStatus, reason: string) => {
                        this.vm!.accountStatus = value
                      }
                    }}
                    disabled={isSelf && !userStore!.isAdmin}
                    InputLabelProps={{shrink: this.vm.accountStatus ? true : false}}
                  />
                }
              </Stack>
            </div>
          </DialogContent>
          <DialogActions>
            <CancelButton onClick={this.handleCancel} />
            <ProgressButton variant="contained" color="secondary"
              type="submit" processing={this.isProcessing}>
              Save
              </ProgressButton>
          </DialogActions>
        </FormValidator>
      </Dialog>
    )
  }
}

export default withStyles(styles)(AccountSettingsDialog)