import * as React from 'react'
import {autorun, makeObservable, observable, toJS, when} from "mobx";
import {inject, observer} from "mobx-react";
import {createMachine, interpret} from 'xstate'
import {addDays} from "date-fns";
// Routing
import ControlTower, {Routes} from '../../components/ControlTower';
import {RouteComponentProps} from "@reach/router";
// UI
import {
  Box,
  Button,
  createStyles,
  Grid,
  Theme,
  Typography,
  withStyles,
  WithStyles,
  withTheme,
  WithTheme
} from "@material-ui/core";
import {AvatarGroup} from '@material-ui/lab';
// Stores
import {getISODateFromDate, getISODateTodayAddDays, isoToLocalDate} from '../../stores/StoreUtilities';
import AccountStore from "../../stores/AccountStore";
import ProfileStore from "../../stores/ProfileStore";
import UserStore from "../../stores/UserStore";
import JobStore from "../../stores/JobStore";
// Components
import AddButton from '../../components/AddButton';
import Confirm from "../../components/confirm/Confirm";
import JobPostForm from '../../components/jobPosts/JobPostForm';
import JobPostMiniCard from '../../components/jobPosts/JobPostMiniCard';
import KanbanBoard, {IKanbanColumn, IKanbanRow} from '../../components/kanban/KanbanBoard';
import Notify from "../../components/notify/Notify";
import Page from '../../components/page/Page'
import Progress from "../../components/Progress";
import SideDrawer from '../../components/page/SideDrawer';
import ManageJobPage from './ManageJobPage';
import ManageCandidatePage from "../manageCandidates/ManageCandidatePage";
import CandidatesPage from "../candidates/FindTalentPage";
import JobPostPublish from '../../components/jobPosts/JobPostPublish';
import JobCandidateAvatar from '../../components/jobCandidates/JobCandidateAvatar';
// FilterBar
import FilterBar from '../../components/filter/FilterBar';
import ContractFilter from '../../components/filter/ContractFilter';
import AccountFilter from '../../components/filter/AccountFilter';
import LocationFilter from '../../components/filter/LocationFilter';
import ServiceFilter from '../../components/filter/ServiceFilter';
import JobPostTypeFilter from '../../components/filter/JobPostTypeFilter';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
// Models
import * as APITypes from '../../API'
import JobPost from "../../model/JobPost";
import Account from '../../model/Account';
import Contract from '../../model/Contract';
import JobCandidate from '../../model/JobCandidate';
import ResourceCache, {LocationOption, ServiceOption} from "../../stores/ResourceCache";
import {ActivityType} from "../../model/UserActivity";

const styles = (theme: Theme) => createStyles({

})

interface IManageJobsPageProps {
  userStore?: UserStore
  profileStore?: ProfileStore
  accountStore?: AccountStore
  jobStore?: JobStore
  resourceCache?: ResourceCache
  progress?: Progress
  notify?: Notify
  confirm?: Confirm
  location?: any
}

enum ManageJobsStates {
  loading = 'loading',
  idle = 'idle',
  addJobPost = 'addJobPost',
  viewJobPostLargeDrawer = 'viewJobPostLargeDrawer',
  editJobPost = 'editJobPost',
  findTalent = 'findTalent',
  manageCandidate = 'manageCandidate',
  publishJobPost = 'publishJobPost'
}

enum ManageJobsActions {
  ADD_JOB_POST = 'ADD_JOB_POST',
  EDIT_JOB_POST = 'EDIT_JOB_POST',
  VIEW_JOB_POST_LARGE_DRAWER = 'VIEW_JOB_POST_LARGE_DRAWER',
  PUBLISH_JOB_POST =  'PUBLISH_JOB_POST',
  MANAGE_CANDIDATE = 'MANAGE_CANDIDATE',
  CLOSE = 'CLOSE'
}

const stateMachine = createMachine({
  id: 'manageJobs',
  initial: 'loading',
  states: {
    loading: { 
      on: { 
        LOADED: ManageJobsStates.idle 
      }
    },
    idle: { 
      on: { 
        [ManageJobsActions.ADD_JOB_POST]: ManageJobsStates.addJobPost, 
        [ManageJobsActions.VIEW_JOB_POST_LARGE_DRAWER]: ManageJobsStates.viewJobPostLargeDrawer,
        [ManageJobsActions.PUBLISH_JOB_POST]: ManageJobsStates.publishJobPost,
        [ManageJobsActions.EDIT_JOB_POST]: ManageJobsStates.editJobPost 
      }
    },
    [ManageJobsStates.addJobPost]: { 
      on: {
        CLOSE: ManageJobsStates.idle
      }
    },
    [ManageJobsStates.publishJobPost]: {
      on: {
        [ManageJobsActions.CLOSE]: ManageJobsStates.idle
      }
    },
    [ManageJobsStates.editJobPost]: {
      on: {
        CLOSE: ManageJobsStates.idle
      }
    },
    [ManageJobsStates.viewJobPostLargeDrawer]: {
      initial: 'view', 
      states: {
        view: {
          on: {
            [ManageJobsActions.MANAGE_CANDIDATE]: ManageJobsStates.manageCandidate
          }
        },
        [ManageJobsStates.manageCandidate]: {
          on: {
            [ManageJobsActions.CLOSE]: 'view'
          }
        }
      },
      on: {
        [ManageJobsActions.CLOSE]: ManageJobsStates.idle
      }
    }
  }
})

@inject("userStore", "profileStore", "accountStore", "jobStore", "resourceCache", "progress", "notify", "confirm")
@observer
class ManageJobsPage extends React.Component<WithStyles<typeof styles> & RouteComponentProps & IManageJobsPageProps & WithTheme> {
  
  @observable currentState = stateMachine.initialState
  stateService = interpret(stateMachine).onTransition((state) => {
    this.currentState = state
  })

  // Filter Bar 
  @observable accountFilter?: Account 
  @observable contractFilter?: Contract
  @observable serviceFilter?: ServiceOption
  @observable locationFilter?: LocationOption
  @observable jobPostTypeFilter?: APITypes.JobPostType
  
  // Columns and Jobs on the Board 
  @observable columns: IKanbanColumn[] = []
  @observable jobPosts: JobPost[] = []
  @observable jobPostsFiltered: JobPost[] = []
  @observable jobPostCartCount: number = 0 
  
  // Contract and Job Post Creation 
  @observable accounts?: Account[]
  @observable contracts: Contract[] = [] 
  @observable contract?: Contract
  @observable jobPost?: JobPost
  @observable jobCandidate?: JobCandidate
  @observable industryFilter?: string
  
  constructor(
    props: WithStyles<typeof styles> & RouteComponentProps & IManageJobsPageProps & WithTheme
  ) {
    super(props);
    makeObservable(this);
  }

  componentDidMount() {
    const { accountStore, location, userStore, progress } = this.props
    
    this.stateService.start();
    const { send } = this.stateService

    progress!.show("ManageJobsPage")
    when(
      // once
      () => !accountStore!.isLoading && userStore!.user !== undefined,
      // then 
      async () => {
        this.industryFilter = userStore!.industry
        await this.loadAccounts()
        await this.loadColumns()
        await this.loadJobPosts()
        const searchParams = new URLSearchParams(location.search)
        const accountId = searchParams.get("accountId")
        if (accountId && this.accounts) {
          this.accountFilter = this.accounts.find((account: Account) => account.id === accountId)
        }
        await this.loadContracts()
        const contractId = searchParams.get("contractId")
        if (contractId) {
          this.contractFilter = this.contracts.find(checkContract => checkContract.id === contractId)
        }
        this.filterJobPosts()
        this.loadJobPostCartCount()
        this.populateColumns()
        send('LOADED')
        progress!.hide("ManageJobsPage")
      }
    )

    autorun(
      async () => {
        if (!userStore!.isLoading && userStore!.industry !== this.industryFilter) {
          progress!.show("ManageJobsPage")
          console.log(`Industry changed`)
          this.industryFilter = userStore!.industry
          await this.loadJobPosts()
          this.filterJobPosts()
          this.populateColumns()
          send('LOADED')
          progress!.hide("ManageJobsPage")
        }
      }
    )
  }

  componentWillUnmount() {
    this.stateService.stop() 
  }

  render() {
    const title = "Manage Jobs"

    return (
      <Page 
        title={title}
        hideFooter
      >
        <Box 
          px={1} 
          paddingTop={0.5} 
          width={'100%'}
        >
          <Box pl={1}>
            <Typography variant="h3">
              {title}
            </Typography>
          </Box>
          {this.renderFilterBar()}
          {this.renderKanbanBoard()}
          {this.renderJobPostForm()}
          {this.renderJobPostLargeDrawer()}
          {this.renderFindTalentDrawer()}
          {this.renderManageCandidateDrawer()}
        </Box>
      </Page>
    )
  }

  renderFilterBar = () => {
    const { userStore, location } = this.props

    if (this.currentState.matches(ManageJobsStates.loading)) {
      return null 
    }

    const buttons = [
      <AddButton
        key="addJobButton"
        text="Post Job"
        tracking="employerAddJobToContract"
        buttonColor="secondary"
        buttonVariant="contained"
        buttonSize="medium"
        click={this.handleAddJobPost}
        icon="add"
      />
    ]

    if (this.jobPostCartCount > 0 || userStore!.user?.isAdminOrAgent) {
      const infoString = this.jobPostCartCount > 0 ? `(${this.jobPostCartCount})` : ""
      buttons.push(
        <Button
          variant="contained"
          color="secondary"
          size="medium"
          onClick={() => {
            const route = (this.accountFilter) ? `${Routes.jobPostPaymentCart}?accountId=${this.accountFilter!.id}` : Routes.jobPostPaymentCart
            ControlTower.route(route)
          }}
          startIcon={ <ShoppingCartIcon /> }
        >
          {`Job Cart ${infoString}`}
        </Button>
      )
    }

    return (
      <Box py={1}>
        <FilterBar
          searchResultCount={this.jobPostsFiltered.length}
          buttons={buttons}
        >
          { userStore!.user?.isAdminOrAgent ?
            <AccountFilter
              key="accountFilter"
              // className={classes.filters}
              accounts={this.accounts!}
              value={this.accountFilter}
              onSelectAccount={(account?: Account) => {
                const searchParams = new URLSearchParams(location.search)
                if (searchParams.get("accountId")) {
                  ControlTower.route(account ? `${Routes.manageJobs}?accountId=${account.id}` : Routes.manageJobs)
                }
                this.accountFilter = account
                this.contractFilter = undefined
                this.contracts = []
                this.loadContracts()
                this.filterJobPosts()
                this.populateColumns()
                this.loadJobPostCartCount()
              }}
            /> 
          : null }
          <ContractFilter
            key="contractFilter"
            value={this.contractFilter}
            contracts={this.contracts}
            onSelectContract={(contract?: Contract) => {
              this.contractFilter = contract 
              this.filterJobPosts()
              this.populateColumns() 
            }}
          />
          <ServiceFilter
            key="serviceFilter"
            value={this.serviceFilter}
            onSelectService={(value?: ServiceOption) => {
              if (value) {
                this.serviceFilter = value
              } else {
                this.serviceFilter = undefined
              }
              this.filterJobPosts()
              this.populateColumns()
            }}
          />
          <LocationFilter
            key="locationFilter"
            value={this.locationFilter}
            onSelectLocation={(value?: LocationOption) => {
              if (value) {
                this.locationFilter = value
              } else {
                this.locationFilter = undefined
              }
              this.filterJobPosts()
              this.populateColumns()
            }}
          /> 
          { userStore!.user?.isAdminOrAgent &&
            <JobPostTypeFilter 
              key="jobPostTypeFilter"
              value={this.jobPostTypeFilter}
              jobPostTypes={ Object.keys(APITypes.JobPostType).map(type => APITypes.JobPostType[type])}
              onSelectJobPostType={(value?: APITypes.JobPostType) => {
                if (value) {
                  this.jobPostTypeFilter = value 
                } else {
                  this.jobPostTypeFilter = undefined 
                }
                this.filterJobPosts() 
                this.populateColumns() 
              }}
            />
          }
        </FilterBar>
      </Box>
    )
  }

  renderKanbanBoard = () => {
    if (this.currentState.matches(ManageJobsStates.loading)) {
      return null 
    }

    return (
      <Box px={0.5} pt={0.5}>
        <Grid container direction="column" spacing={1} justifyContent="center" wrap="nowrap">
          <KanbanBoard
            columns={this.columns}
            options={{
              columnWidth: 297,
              columnHeight: "calc(100vh - 226px)", // Columns should take up remaining space on the page, but leaving some padding.
              renderCard: this.renderJobPostCard,
              isDragDisabled: this.isDragDisabled
            }}
            onDrop={this.handleUpdateJobPostStatus}
          />
        </Grid>
      </Box>
    )
  }

  showJobPostPublishButton = (jobPost: JobPost): boolean => {
    const { userStore } = this.props 
    let showPublishButton = false 
    if (jobPost && jobPost.status === APITypes.JobPostStatus.Draft) {
      if (userStore!.isAdminOrAgent) {
        // Show publish button always for Admin/Agent. 
        showPublishButton = true 
      } else if (jobPost.jobPostType === APITypes.JobPostType.SelfService) {
        // Show the publish button to the Employer if not a Recruiting Service job post. 
        showPublishButton = true 
      }
    }
    return showPublishButton 
  }

  renderJobPostCard = (row: IKanbanRow) => {
    const jobPost = row as JobPost

    return (
      <JobPostMiniCard
        key={jobPost.id}
        jobPost={jobPost}
        // elevation={1}
        onClickTitle={() => {
          this.jobPost = jobPost
          // Send action to open the drawer: 
          this.stateService.send(ManageJobsActions.VIEW_JOB_POST_LARGE_DRAWER)
          // ControlTower.route(`${Routes.manageJob}?jobPostId=${jobPost.id}`)
        }}
        onEdit={() => {
          this.jobPost = jobPost
          this.stateService.send(ManageJobsActions.EDIT_JOB_POST)
        }}
      >
        <Box 
          pt={jobPost.jobCandidates.length > 0 ? 1 : 0} 
        >
          <AvatarGroup max={6} spacing={5}>
            {jobPost.jobCandidates.map((jobCandidate: JobCandidate) => {
              return (
                this.getJobCandidateAvatar(jobCandidate)
              )
            })}
          </AvatarGroup>
        </Box>
        <Box 
          pt={this.showJobPostPublishButton(jobPost) ? 1 : 0} 
        >
          <JobPostPublish 
            jobPost={jobPost}
            isOpen={this.currentState.matches(ManageJobsStates.publishJobPost) && this.jobPost?.id === jobPost.id}
            onClickPublish={() => {
              this.jobPost = jobPost 
              this.stateService.send(ManageJobsActions.PUBLISH_JOB_POST)
            }}
            onUpdateJobPost={async (jobPost: JobPost) => {
              await this.handleUpdateJobPost(jobPost)
              if (this.currentState.matches(ManageJobsStates.publishJobPost)) {
                this.stateService.send(ManageJobsActions.CLOSE) 
              }
            }}
            onCancel={() => {
              this.stateService.send(ManageJobsActions.CLOSE) 
            }}
          />
        </Box>
      </JobPostMiniCard> 
    )
  }

  getJobCandidateAvatar = (jobCandidate: JobCandidate) => {
    let avatar

    switch (jobCandidate.status) {
      case APITypes.JobCandidateStatus.Applied:
      case APITypes.JobCandidateStatus.Accepted:
      case APITypes.JobCandidateStatus.Reviewing:
      case APITypes.JobCandidateStatus.Contacted:
      case APITypes.JobCandidateStatus.Hired:
        avatar = <JobCandidateAvatar jobCandidate={jobCandidate}/>
        break
      default:
        avatar = null
        break
    }

    return avatar
  }

  isDragDisabled = (row: IKanbanRow) => {
    const { userStore } = this.props

    const jobPost = row as JobPost
    if (jobPost.status === APITypes.JobPostStatus.Cart || jobPost.status === APITypes.JobPostStatus.Draft) {
      return true 
    } else if (userStore!.isEmployer === false || jobPost.jobPostType === APITypes.JobPostType.SelfService) {
      return false 
    } else {
      return true 
    }
  }

  renderJobPostForm = () => {
    if (!this.jobPost) {
      return null
    }

    let isOpen: boolean = this.currentState.matches(ManageJobsStates.addJobPost) 
      || this.currentState.matches(ManageJobsStates.editJobPost)

    if (isOpen === false) {
      return null 
    }
    
    return (
      <JobPostForm
        // accounts={this.accounts}
        jobPost={this.jobPost}
        isFormOpen={isOpen}
        renderAsDialog={true}
        didClose={() => {
          this.stateService.send(ManageJobsActions.CLOSE) 
        }}
        didCreate={(jobPost: JobPost) => {
          if (this.contracts && this.contracts.length > 0) {
            const contract = this.contracts.find(contract => contract.id === jobPost.contractId)
            if (contract) {
              contract!.jobPosts.push(jobPost)
              jobPost.contract = contract!
            }
          }
          this.jobPosts.push(jobPost)
          this.filterJobPosts() 
          this.populateColumns()
        }}
        didDelete={(jobPost: JobPost) => {
          const contract = this.contracts.find(contract => contract.id === jobPost.contractId)
          if (contract) {
            const index = contract!.jobPosts.findIndex(checkJobPost => checkJobPost.id === jobPost.id)
            contract!.jobPosts.splice(index, 1)
          }
          const jobPostIndex = this.jobPosts.findIndex(checkJobPost => checkJobPost.id === jobPost.id)
          if (jobPostIndex >= 0) {
            this.jobPosts.splice(jobPostIndex, 1)
          }
          this.filterJobPosts()
          this.populateColumns()
        }}
        didEdit={async (jobPost: JobPost) => {
          await this.handleUpdateJobPost(jobPost)
        }}
      />
    )
  }

  renderJobPostLargeDrawer = () => {
    if (this.jobPost === undefined) {
      return null 
    }
    
    const isOpen = this.currentState.matches(ManageJobsStates.viewJobPostLargeDrawer)
    
    // if (!isOpen) {
    //   return null 
    // }

    return (
      <SideDrawer 
        size="large"
        title={'Manage Job Post'} 
        isOpen={isOpen} 
        onClose={() => {
          this.stateService.send(ManageJobsActions.CLOSE)
        }}        
      >
        {this.jobPost && 
          <ManageJobPage
            embed 
            jobPostId={this.jobPost.id}
            onClickJobCandidate={(jobCandidate: JobCandidate) => {
              this.jobCandidate = jobCandidate 
              this.stateService.send(ManageJobsActions.MANAGE_CANDIDATE)
            }}
            onUpdateJobPost={async (jobPost: JobPost) => {
              await this.handleUpdateJobPost(jobPost)
            }}
          /> 
        }
      </SideDrawer>
    )
  }

  renderFindTalentDrawer = () => {
    if (this.jobPost === undefined) {
      return null
    }

    const isOpen = this.currentState.matches({
      viewJobPostDrawer: ManageJobsStates.findTalent
    })

    if (!isOpen) {
      return null 
    }

    return (
      <React.Fragment>
        <SideDrawer
          title={"Find Talent"}
          isOpen={isOpen}
          onClose={async () => {
            // Update JobPost
            await this.populateJobPost(this.jobPost!)
            this.stateService.send(ManageJobsActions.CLOSE)
          }}
          size="large"
        >
          <Box pt={1} pb={2}>
            <CandidatesPage embed={true} jobPostId={this.jobPost?.id}/>
          </Box>
        </SideDrawer>
      </React.Fragment>
    )
  }

  renderManageCandidateDrawer = () => {
    if (this.jobCandidate === undefined) {
      return null
    }

    const isOpen = this.currentState.matches({[ManageJobsStates.viewJobPostLargeDrawer]: ManageJobsStates.manageCandidate})

    if (!isOpen) {
      return null 
    }

    return (
      <React.Fragment>
        <SideDrawer
          title={"Manage Candidate"}
          isOpen={isOpen}
          onClose={async () => {
            // Update JobPost
            this.jobCandidate = undefined 
            this.stateService.send(ManageJobsActions.CLOSE)
          }}
          size="large"
        >
          <Box pt={0} pb={2}>
            { this.jobCandidate && 
              <ManageCandidatePage 
                embed={true} 
                profileId={this.jobCandidate!.profileId} 
                tab="profile"
                jobCandidate={this.jobCandidate}
                onUpdateJobCandidate={(updatedJobCandidate: JobCandidate) => {
                  this.jobCandidate = updatedJobCandidate
                  const jobCandidateIndex = this.jobPost!.jobCandidates.findIndex(checkJobCandidate => checkJobCandidate.id === updatedJobCandidate.id)
                  if (jobCandidateIndex > -1) {
                      this.jobPost!.jobCandidates[jobCandidateIndex] = updatedJobCandidate
                  }
                }}
              />
            }
          </Box>
        </SideDrawer>
      </React.Fragment>
    )
  }

  // Loading

  loadAccounts = async () => {
    const { accountStore, userStore } = this.props 

    if (userStore!.user?.isAdminOrAgent) {
      const accounts: Account[] = await accountStore!.listAccounts()
      const sorted = accounts.sort((a: Account, b: Account) => a.name.localeCompare(b.name))
      this.accounts = sorted  
    } 
  }

  loadContracts = async () => {
    const { jobStore, progress, userStore } = this.props
    
    progress!.show("ManageJobsPage")

    // TODO: Ability to filter on completed contracts. 
    // let filter: APITypes.ModelContractFilterInput
    // if (contractStatus === 'Completed') {
    //   filter = {
    //     endDate: {lt: getISODateToday()}
    //   }
    // } else {
    //   filter = {
    //     endDate: {ge: getISODateToday()}
    //   }
    // }
    // const contracts = await accountStore!.listContractsByAccount(accountId, filter) 

    let contracts: Contract[] = []

    let account: Account | undefined

    if (userStore!.user?.isAdminOrAgent) {
      if (this.accountFilter) {
        account = this.accountFilter
      }
    } else {
      account = userStore!.user!.account
    }

    if (account) {
      contracts = await jobStore!.listContractsByAccount(account!.id)
    }

    this.contracts = contracts.sort((a: Contract, b: Contract) => a.name.localeCompare(b.name))

    progress!.hide("ManageJobsPage")
  }

  loadColumns = async () => {
    const { jobStore } = this.props
    const statuses = await jobStore!.getJobPostStatusOptions()
    let columns: IKanbanColumn[] = []
    statuses!.forEach(status => {
      const column: IKanbanColumn = {
        id: status,
        title: status,
        rows: [],
        isDragDisabled: status === "Cart" || status === "Draft",
        isDropDisabled: status === "Cart" || status === "Draft"
      }
      if (status !== APITypes.JobPostStatus.Cart.toString()) {
        columns.push(column)
      }
    })
    this.columns = columns 
  }

  loadJobPosts = async () => {
    const { jobStore, userStore } = this.props

    if (!userStore!.user) {
      this.jobPosts = []
      this.jobPostsFiltered = []
      return
    }

    let jobPosts: JobPost[]
    const isAdminOrAgent = userStore!.user!.isAdminOrAgent
    
    // If we want to hide Closed JobPost records: 
    // Perhaps add the ability to load/show Closed column with a checkbox. 
    // const filter: ModelJobPostFilterInput = {
    //   or: [
    //     {closedDate: {attributeExists: false}},
    //     {closedDate: {attributeType: ModelAttributeTypes._null}}
    //   ]
    // }

    // const filter: ModelJobPostFilterInput = {
    //   or: [
    //     {or: [
    //       {closedDate: {attributeExists: false}},
    //       {closedDate: {attributeType: ModelAttributeTypes._null}}
    //     ]},
    //     {endDate: { gt: getISODateToday()}  }
    //   ]
    // }

    if (isAdminOrAgent) {
      if (this.accountFilter) {
        // Only load job posts for the selected account. 
        jobPosts = await jobStore!.listJobPostsAndCandidatesByAccount(this.accountFilter!.id)
      } else {
        // Load all.
        jobPosts = await jobStore!.listJobPostsAndCandidates(this.industryFilter)
      }
    } else {
      this.accountFilter = userStore!.user!.account
      jobPosts = await jobStore!.listJobPostsAndCandidatesByAccount(this.accountFilter!.id)
    }

    // await this.populateJobPosts(jobPosts)

    this.jobPosts = jobPosts
    this.jobPostsFiltered = jobPosts 
  }

  populateJobPosts = async (jobPosts: JobPost[]) => {
    const promises = jobPosts.map(jobPost => {
      return this.populateJobPost(jobPost)    
    })

    await Promise.all(promises)
  }

  populateJobPost = async (jobPost: JobPost) => {
    const { jobStore } = this.props

    // Need to fetch candidates to get the related profile/user info.
    const jobCandidates = await jobStore!.listJobCandidatesByJobPost(jobPost.id)

    if (jobCandidates && jobCandidates.length > 0) {
      jobPost.jobCandidates = jobCandidates.sort(JobCandidate.compareFn)
    }
  }

  loadJobPostCartCount = () => {
    const { userStore } = this.props
    if (userStore!.isEmployer) {
      const inCart = this.jobPosts.filter(jobPost => jobPost.status === APITypes.JobPostStatus.Cart)
      this.jobPostCartCount = inCart.length
    } else if (userStore!.isAdminOrAgent && this.accountFilter) {
      const inCart = this.jobPosts.filter(jobPost => jobPost.accountId === this.accountFilter!.id && jobPost.status === APITypes.JobPostStatus.Cart)
      this.jobPostCartCount = inCart.length
    } else {
      this.jobPostCartCount = 0
    }
  }

  // Filtering 

  filterJobPosts = async () => {
    let accountId = (this.accountFilter) ? this.accountFilter.id : ""
    let contractId = (this.contractFilter) ? this.contractFilter.id : ""
    let serviceId = (this.serviceFilter) ? this.serviceFilter.id : ""
    let locationId = (this.locationFilter) ? this.locationFilter.id : ""
    let jobPostType = (this.jobPostTypeFilter) ? this.jobPostTypeFilter : ""
    const jobPosts: JobPost[] = []
    
    this.jobPosts.forEach((jobPost: JobPost) => {
      if (accountId !== "" && jobPost.accountId !== accountId) {
        return 
      }
      if (contractId !== "" && jobPost.contractId !== contractId) {
        return 
      }
      if (serviceId !== "" && jobPost.serviceId !== serviceId) {
        return 
      }
      if (locationId !== "" && jobPost.locationId !== locationId) {
        return 
      }
      if (jobPostType !== "" && jobPost.jobPostType !== jobPostType) {
        return 
      }
      jobPosts.push(jobPost)
    })

    this.jobPostsFiltered = jobPosts
  }

  populateColumns = () => {
    const columns = toJS(this.columns)
    columns.forEach((column: IKanbanColumn) => {
      column.rows = []
    })
    const jobPosts = toJS(this.jobPostsFiltered)
    jobPosts.forEach((jobPost: JobPost) => {
      const column: IKanbanColumn | undefined = columns.find(column => column.id === jobPost.status)
      if (column) {
        column!.rows.push(jobPost)
      }
    })

    columns.forEach((column: IKanbanColumn) => {
      const jobPosts = column.rows as JobPost[]
      const sorted = jobPosts.sort((a: JobPost, b: JobPost) => {
        // return String(a.service?.name).localeCompare(String(b.service?.name))
        
        // Order by most recently updated first. 
        return b.updatedAt.localeCompare(a.updatedAt)
      })
      column.rows = sorted
    })

    this.columns = columns 
  }

  // JobPosts Actions 

  handleUpdateJobPostStatus = async (
    row: IKanbanRow,
    sourceIndex: number, 
    sourceColumnId: string, 
    destinationIndex: number, 
    destinationColumnId: string
  ) => {
    const { jobStore, userStore } = this.props
    const isAdminOrAgent = userStore!.isAdminOrAgent

    this.props.progress!.show('ManageJobs-UpdateJobPostStatus')
    try {
      if (sourceColumnId === destinationColumnId) {
        // We don't currently handle re-ordering job posts within a column, 
        // as they are sorted by date updated. 
        return 
      }
      // const row = this.jobPostsFiltered.find(jobPost => jobPost.id === rowId)
      const sourceColumn: IKanbanColumn = this.columns.find(column => column.id === sourceColumnId)!
      const destinationColumn: IKanbanColumn = this.columns.find(column => column.id === destinationColumnId)!
      
      sourceColumn.rows.splice(sourceIndex, 1);
      destinationColumn.rows.splice(destinationIndex, 0, row);

      const currentPost = row as JobPost
      const status = destinationColumn.id
      if (!status) {
        throw new Error('Column is not a valid job post status')
      }
      if (!isAdminOrAgent && status === "Draft") {
        throw new Error('Cannot set a published job back to draft status')
      }

      const input: APITypes.UpdateJobPostInput = { 
        id: currentPost.id, 
        status: APITypes.JobPostStatus[status]
      }

      if (input.status === APITypes.JobPostStatus.Closed || input.status === APITypes.JobPostStatus.Hired) {
        // Note, set it to yesterday so that it will not be picked up on the job board.
        input['closedDate'] = getISODateTodayAddDays(-1)
      } else if (isAdminOrAgent) {
        // Only admins and agents can unclose a job post
        input['closedDate'] = null
      }
      // Handle re-opening a job
      if ((input.status === APITypes.JobPostStatus.Posted || input.status === APITypes.JobPostStatus.Pending) &&
        (currentPost.status === APITypes.JobPostStatus.Hired || currentPost.status === APITypes.JobPostStatus.Closed)) {
        // Reset closedDate to +30 days
        // TODO: Handle charging for this if necessary
        if (currentPost.openDate) {
          const closedDate = addDays(isoToLocalDate(currentPost.openDate), 30)
          input['closedDate'] = getISODateFromDate(closedDate)
        }
      }

      const jobPost = await jobStore!.updateJobPost(input)
      if (jobPost) {
        await this.populateJobPost(jobPost)
        jobPost.account = currentPost.account
        jobPost.contract = currentPost.contract

        userStore!.logUserActivity(userStore!.user!.id, APITypes.SubjectType.JobPost, jobPost.id, ActivityType.JobPostStatusUpdated,
          `${userStore!.user!.fullName} updated the job post '${jobPost.fullName}' status to '${destinationColumn.title}'`)

        const index = this.jobPosts.findIndex(checkJobPost => checkJobPost.id === jobPost.id)
        if (index >= 0) {
          this.jobPosts[index] = jobPost
        }

        const indexFiltered = this.jobPostsFiltered.findIndex(checkJobPost => checkJobPost.id === jobPost.id)
        if (indexFiltered >= 0) {
          this.jobPostsFiltered[indexFiltered] = jobPost
        }
        // Repopulate to show updated post
        this.populateColumns()
      }
    } catch (err: any) {
      this.props.notify!.show('error', `Could not update job post status: ${err.message}`)
    } finally {
      this.props.progress!.hide('ManageJobs-UpdateJobPostStatus')
    }
  }

  handleAddJobPost = () => {
    const { userStore } = this.props 

    if (userStore!.isEmployer) {
      ControlTower.route(Routes.jobPostCreate)
    } else {
      // For Agents to add job posts. 
      const { send } = this.stateService
      const jobPost = new JobPost({
        accountId: this.accountFilter ? this.accountFilter!.id : userStore!.user!.accountId,
        industries: this.accountFilter ? this.accountFilter.industries : [userStore!.industry]
      })
      this.jobPost = jobPost
      send(ManageJobsActions.ADD_JOB_POST)
    }

  }

  handleUpdateJobPost = async (jobPost: JobPost) => {
    await this.populateJobPost(jobPost)
    if (this.contracts && this.contracts.length > 0) {
      const contract = this.contracts.find(contract => contract.id === jobPost.contractId)
      if (contract) {
        const index = contract.jobPosts.findIndex(checkJobPost => checkJobPost.id === jobPost.id)
        if (index >= 0) {
          contract!.jobPosts[index] = jobPost
        }
      }
    }
    const jobPostIndex = this.jobPosts.findIndex(checkJobPost => checkJobPost.id === jobPost.id)
    this.jobPosts[jobPostIndex] = jobPost 
    this.filterJobPosts() 
    this.populateColumns() 
  }
}

export default withTheme((withStyles(styles)(ManageJobsPage)))