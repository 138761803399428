import {Box, Paper} from "@material-ui/core";
import { createStyles, Theme, withStyles, WithStyles, withTheme, WithTheme } from '@material-ui/core/styles';
import { RouteComponentProps } from '@reach/router';
import {action, makeObservable, observable, when} from "mobx";
import { inject, observer } from "mobx-react";
import * as React from 'react';
import {CreateProfileInput, ProfileStatus, SubjectType, UpdateProfileInput} from "../../API";
import Logger from "../../components/Logger";
import MarginRow from "../../components/page/MarginRow";
import Page from "../../components/page/Page";
import Progress from "../../components/Progress";
import Profile, {ProfileStatusMessages} from "../../model/Profile";
import User from "../../model/User";
import ProfileStore from '../../stores/ProfileStore';
import { getErrorMessage, isUUID } from "../../stores/StoreUtilities";
import UserStore from "../../stores/UserStore";
import ProfileAbout from './ProfileAbout';
import ProfileCandidateStatus from './ProfileCandidateStatus';
import ProfileCertifications from './ProfileCertifications';
import ProfileEducation from './ProfileEducation';
import ProfileHero from './ProfileHero';
import ProfileInstructions from "./ProfileInstructions";
import ProfileLocations from './ProfileLocations';
import ProfilePersonalInfo from "./ProfilePersonalInfo";
import ProfilePositions from './ProfilePositions';
import ProfileWorkExperience from './ProfileWorkExperience';
import CallToActionPanel from "../../components/CallToActionPanel";
import {ActivityType} from "../../model/UserActivity";
import AddButton from "../../components/AddButton";
import {ReactElement} from "react";
import UploadResumeDialog from "./upload-resume/UploadResumeDialog";
import Experience from "../../model/Experience";
import ProfileFAQs from "./ProfileFAQs";
import JobStore from "../../stores/JobStore";
import JobCandidate from "../../model/JobCandidate";
import ProfileSecurityClearance from "./ProfileSecurityClearance";
import Stack from "../../components/Stack";

const styles = (theme: Theme) => createStyles({
  root: {
    flexGrow: 1,
    justifyContent: 'top',
    alignItems: 'center',
  },
  content: {
    display: 'flex',
    flexGrow: 1,
    width: "100%",
    flex: "auto",
    overflowY: "auto",
    marginTop: theme.spacing(1),
    padding: 0
  },
  instructions: {
    padding: theme.spacing(2)
  }
})

interface IProfilePageProps {
  profileId?: string
  onUpdateProfile?(profile: Profile): Promise<void>

  // Pass in the JobCandidate when reviewing this Candidate for a specific JobPost. 
  embed?: boolean
  jobCandidate?: JobCandidate

  userStore?: UserStore
  profileStore?: ProfileStore
  jobStore?: JobStore
  progress?: Progress
  location?: any
}

@inject("userStore", "profileStore", "jobStore", "progress")
@observer
class ProfilePage extends React.Component<WithStyles<typeof styles> & RouteComponentProps & IProfilePageProps & WithTheme> {
  @observable isLoading = true
  @observable profile?: Profile
  @observable isProfilePositionsDialogOpen = false
  @observable isProfileLocationsDialogOpen = false
  @observable isProfileAboutDialogOpen = false
  @observable isProfileWorkExperienceDialogOpen = false
  @observable isProfileEducationDialogOpen = false
  @observable user?: User
  @observable errors: ReactElement[] = []

  constructor(
    props: WithStyles<typeof styles> & RouteComponentProps & IProfilePageProps & WithTheme
  ) {
    super(props);
    makeObservable(this);
  }

  componentDidMount() {
    const { userStore, progress } = this.props
    progress!.show("Profile")
    when(
      // once...
      () => !userStore!.isLoading,
      // ... then
      async () => {
        // Load public profile
        await this.loadProfile()
        progress!.hide("Profile")
        this.isLoading = false
      }
    )

    // when(
    //   // once...
    //   () => !userStore!.isLoading && userStore!.user !== undefined,
    //   // ... then
    //   async () => {
    //     // Reload if user is authenticated
    //     await this.loadProfile()
    //     progress!.hide("Profile")
    //     this.isLoading = false
    //   }
    // )
  }

  render() {
    const { userStore, embed, jobCandidate, theme } = this.props
    const profile = this.profile
    const isProfileEditable = profile && userStore!.isProfileEditable(profile)
    const onChangeProfile = isProfileEditable ? this.onChangeProfile : undefined
    const onSubmit = isProfileEditable && (profile?.canSubmit()) ?
      this.onSubmit : undefined
    const onChangeStatus = userStore!.isAdminOrAgent ? this.onChangeProfile : undefined

    let content = !this.isLoading ?
      <Box mt={{ xs: 1, sm: 2 }} mb={{ xs: 2, sm: 3 }}>
        {/* <Box my={{ xs: 1, sm: 2 }} px={{ xs: 1, sm: 2 }} py={2} bgcolor="grey.500" borderRadius="10px 10px 10px 10px"> */}
        {!userStore!.isAuthenticated &&
          <Box mb={2}>
            <CallToActionPanel prompt="Employers: Join or log in to see the full profile and invite this candidate to apply to your job post."
                               onLogin={() => this.loadProfile()}/>
          </Box>
        }
        <ProfileCandidateStatus profile={profile} onChange={onChangeStatus} />
        <ProfileInstructions profile={profile} errors={this.errors} onSubmit={onSubmit} onChange={onChangeProfile}/>
        <ProfileFAQs profile={profile}/>
        <Box mb={1}>
          <Paper>
            <ProfileHero 
              profile={profile} 
              jobCandidate={jobCandidate}
              onChange={onChangeProfile} 
            />
            <Box
              bgcolor={theme.palette.grey[200]}
              borderRadius={'0px 0px 10px 10px'}
              p={3}
            >
              <Stack
                direction="column"
                spacing={2}
              >
                <ProfilePositions 
                  profile={profile} 
                  onChange={onChangeProfile}
                  isOpen={this.isProfilePositionsDialogOpen}
                  onClose={() => this.isProfilePositionsDialogOpen = false}
                />
                <ProfileLocations 
                  profile={profile} 
                  onChange={onChangeProfile}
                  isOpen={this.isProfileLocationsDialogOpen}
                  onClose={() => this.isProfileLocationsDialogOpen = false}
                />
              </Stack>
            </Box>
          </Paper>
        </Box>
        <ProfilePersonalInfo 
          profile={profile} 
          jobCandidate={jobCandidate} 
          onChange={onChangeProfile} 
        />
        <ProfileAbout 
          profile={profile} 
          jobCandidate={jobCandidate} 
          onChange={onChangeProfile}
          isOpen={this.isProfileAboutDialogOpen}
          onClose={() => this.isProfileAboutDialogOpen = false}
        />
        <ProfileWorkExperience 
          profile={profile} 
          onChange={onChangeProfile}
          isOpen={this.isProfileWorkExperienceDialogOpen}
          onClose={() => this.isProfileWorkExperienceDialogOpen = false}
        />
        <ProfileEducation 
          profile={profile} 
          onChange={onChangeProfile}
          isOpen={this.isProfileEducationDialogOpen}
          onClose={() => this.isProfileEducationDialogOpen = false}
        />
        <ProfileCertifications 
          profile={profile} 
          jobCandidate={jobCandidate} 
          onChange={onChangeProfile} 
        />
        <ProfileSecurityClearance 
          profile={profile}
          jobCandidate={jobCandidate} 
          onChange={onChangeProfile} 
          onDelete={onChangeProfile} 
        />
      </Box> : null

    if (embed) {
      return content
    } else {
      return (
        <Page 
          title="GovGig Candidate Profile" // TODO: Use their full name when unlocked?  
          hideHeader={false}
        >
          <MarginRow>
            {content}
          </MarginRow>
        </Page>
      )
    }

  }

  loadProfile = async () => {
    const { userStore, profileStore, location } = this.props

    let profile
    const profileId = this.props.profileId ? this.props.profileId : location ? new URLSearchParams(location.search).get("profileId") : undefined
    if (profileId) {
      if (isUUID(profileId)) {
        profile = await profileStore!.getProfile(profileId)
      } else {
        profile = await profileStore!.getAliasProfile(profileId)
      }

    } else {
      // Load the profile of the current user.
      const user = userStore!.user
      if (!user) {
        return
      }
      const userId = user.id
      profile = await profileStore!.getUserProfile(userId)

      // Create if it doesn't already exist for some reason
      if (!profile) {
        profile = await this.createProfile(userId)
        if (profile) {
          // Reload including User
          profile = await profileStore!.getUserProfile(userId)
        }
      }
    }

    if (profile) {
      if (profile.user) {
        // getUserProfile should return the user unless unauthenticated
        this.user = profile.user
      }
      this.profile = profile

      if (userStore!.isEmployer) {
        userStore!.logUserActivity(this.profile.userId, SubjectType.Profile, this.profile.id, ActivityType.ProfileViewed,
          `${userStore!.user!.fullName} viewed the profile for '${this.profile.name}'`)
      }

      // Sort experiences
      this.profile.experiences.sort((a: Experience, b: Experience) => String(b.startDate).localeCompare(String(a.startDate)))
    } else {
      Logger.error('Could not load profile!')
    }

    this.isLoading = false
  }

  createProfile = async (userId: string) => {
    const { profileStore } = this.props

    const input: CreateProfileInput = {
      active: true,
      userId,
      profileStatus: ProfileStatus.Pending,
    }

    const profile = await profileStore!.createProfile(input)
      .catch((error: any) => {
        Logger.debug("createProfile error", getErrorMessage(error))
      });

    return profile
  }

  @action onChangeProfile = (profile: Profile) => {
    this.isProfilePositionsDialogOpen = false
    this.isProfileLocationsDialogOpen = false
    this.isProfileAboutDialogOpen = false
    this.isProfileWorkExperienceDialogOpen = false
    this.isProfileEducationDialogOpen = false

    this.profile = profile
    if (profile.user) {
      this.user = profile.user
    }
    if (this.errors && this.errors.length > 0) {
      this.errors = this.validate(profile)
    }

    this.props.onUpdateProfile && this.props.onUpdateProfile(profile)
  }

  onSubmit = async () => {
    const { profileStore } = this.props

    this.errors = this.validate(this.profile!)

    if (this.errors.length > 0) {
      return
    }

    // Update profile
    const updateProfileInput: UpdateProfileInput = {
      id: this.profile!.id,
      profileStatus: ProfileStatus.Submitted,
      statusMessage: ProfileStatusMessages[ProfileStatus.Submitted]
    }

    let updatedProfile = await profileStore!.updateProfile(updateProfileInput)

    if (updatedProfile) {
      this.onChangeProfile(updatedProfile)
    }
  }

  validate = (profile: Profile) => {
    let errors = []

    if (!profile!.resumeKey) {
      errors.push(
        // <Typography variant="body1">&bull; Please add a resume.</Typography>
        <UploadResumeDialog 
          profile={profile}
          onChange={this.onChangeProfile}
          buttonText="Import your resume"
          buttonTracking="profileResumeAutofillImport"
        />
      )
    }

    if (!profile!.profileServices || profile!.profileServices.length === 0) {
      errors.push(
        <AddButton
          text="Add Job Positions"
          tracking="profileAddJobPositions"
          buttonColor="secondary"
          buttonVariant="contained"
          click={() => this.isProfilePositionsDialogOpen = true}
        />
      )
    }

    if (!profile!.profileLocations || profile!.profileLocations.length === 0) {
      errors.push(
        <AddButton
          text="Add Locations to Work or Remote"
          tracking="profileAddJobPositions"
          buttonColor="secondary"
          buttonVariant="contained"
          click={() => this.isProfileLocationsDialogOpen = true}
        />
      )
    }

    // if (!this.profile!.availableDate) {
    //   errors.push(
    //     <Typography variant="body1">&bull; Available Date is required.</Typography>
    //   )
    // }
    //
    // if (!this.profile!.desiredRate) {
    //   errors.push(
    //     <Typography variant="body1">&bull; Desired Rate is required.</Typography>
    //   )
    // }

    if (!profile!.about) {
      errors.push(
        <AddButton
          text="Add About Statement"
          tracking="profileAddAbout"
          buttonColor="secondary"
          buttonVariant="contained"
          click={() => this.isProfileAboutDialogOpen = true}
        />
      )
    }

    if (!profile!.experiences || profile!.experiences.length === 0) {
      errors.push(
        <AddButton
          text="Add Work Experience"
          tracking="profileAddExperience"
          buttonColor="secondary"
          buttonVariant="contained"
          click={() => this.isProfileWorkExperienceDialogOpen = true}
        />
      )
    } else {
      // Look for missing agency types
      const found = profile.experiences.find((e: Experience) => !e.agencyType)
      if (found) {
        errors.push(<AddButton
          text="Select Agency or None for all Work Experiences"
          tracking="profileAddExperience"
          buttonColor="secondary"
          buttonVariant="contained"
          click={() => {window.location.href = `#${found.id}`} }
        />)
      }
    }

    if (!profile!.education || profile!.education.length === 0) {
      errors.push(
        <AddButton
          text="Add Education"
          tracking="profileAddEducation"
          buttonColor="secondary"
          buttonVariant="contained"
          click={() => this.isProfileEducationDialogOpen = true}
        />
      )
    }


    return errors
  }
}

export default withTheme((withStyles(styles)(ProfilePage)))
