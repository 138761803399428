import get from 'lodash.get'

class Price {
  id: string 
  productId: string 
  quantity: number 
  unitPrice: number 
  frequency: string 
  metadata: any 
  nickname: string 

  constructor(data: any) {
    this.id = data.id 
    this.productId = data.product 

    const transformQuantity = get(data, 'transform_quantity.divide_by')
    this.quantity = transformQuantity ? transformQuantity : 1 
    
    const unitAmount = get(data, 'unit_amount')
    this.unitPrice = unitAmount ? unitAmount / 100.00 : 0
    
    this.frequency = data.type === 'recurring' ? "per month" : "per unit"
    this.metadata = data.metadata
    this.nickname = data.nickname
  }
}

export default Price 

/*
Example price listings: 
{
  "id": "price_1LYEM0GEKsOeGTHni5yT3Q33",
  "object": "price",
  "active": true,
  "billing_scheme": "per_unit",
  "created": 1660850768,
  "currency": "usd",
  "custom_unit_amount": null,
  "livemode": false,
  "lookup_key": null,
  "metadata": {},
  "nickname": null,
  "product": "prod_MGltVZzjcSC6uM",
  "recurring": null,
  "tax_behavior": "exclusive",
  "tiers_mode": null,
  "transform_quantity": null,
  "type": "one_time",
  "unit_amount": 0,
  "unit_amount_decimal": "0"
},
{
  "id": "price_1LYFrtGEKsOeGTHnfF7msT34",
  "object": "price",
  "active": true,
  "billing_scheme": "per_unit",
  "created": 1660856589,
  "currency": "usd",
  "custom_unit_amount": null,
  "livemode": false,
  "lookup_key": null,
  "metadata": {},
  "nickname": null,
  "product": "prod_MEUoUp8JZmBHfK",
  "recurring": null,
  "tax_behavior": "exclusive",
  "tiers_mode": null,
  "transform_quantity": {
    "divide_by": 10,
    "round": "up"
  },
  "type": "one_time",
  "unit_amount": 34900,
  "unit_amount_decimal": "34900"
},
{
  "id": "price_1LYFrgGEKsOeGTHnmqUAgZOH",
  "object": "price",
  "active": true,
  "billing_scheme": "per_unit",
  "created": 1660856576,
  "currency": "usd",
  "custom_unit_amount": null,
  "livemode": false,
  "lookup_key": null,
  "metadata": {},
  "nickname": null,
  "product": "prod_MEUoUp8JZmBHfK",
  "recurring": null,
  "tax_behavior": "exclusive",
  "tiers_mode": null,
  "transform_quantity": {
    "divide_by": 5,
    "round": "up"
  },
  "type": "one_time",
  "unit_amount": 39900,
  "unit_amount_decimal": "39900"
},
{
  "id": "price_1LYFrRGEKsOeGTHnX5Lt9xwd",
  "object": "price",
  "active": true,
  "billing_scheme": "per_unit",
  "created": 1660856561,
  "currency": "usd",
  "custom_unit_amount": null,
  "livemode": false,
  "lookup_key": null,
  "metadata": {},
  "nickname": null,
  "product": "prod_MEUoUp8JZmBHfK",
  "recurring": null,
  "tax_behavior": "exclusive",
  "tiers_mode": null,
  "transform_quantity": {
    "divide_by": 2,
    "round": "up"
  },
  "type": "one_time",
  "unit_amount": 44900,
  "unit_amount_decimal": "44900"
},
{
  "id": "price_1LW1pKGEKsOeGTHnoPyIKVT9",
  "object": "price",
  "active": true,
  "billing_scheme": "per_unit",
  "created": 1660325958,
  "currency": "usd",
  "custom_unit_amount": null,
  "livemode": false,
  "lookup_key": null,
  "metadata": {},
  "nickname": null,
  "product": "prod_MEUoUp8JZmBHfK",
  "recurring": null,
  "tax_behavior": "exclusive",
  "tiers_mode": null,
  "transform_quantity": null,
  "type": "one_time",
  "unit_amount": 49900,
  "unit_amount_decimal": "49900"
},
*/
