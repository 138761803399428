import { SubjectType } from "../API"

export enum ActivityType {
  ContractAdded = 'ContractAdded',
  ContractDeleted = 'ContractDeleted',
  JobCandidateAdded = 'JobCandidateAdded',
  JobCandidateApplied = 'JobCandidateApplied',
  JobCandidateDeleted = 'JobCandidateDeleted',
  JobCandidateStatusUpdated = 'JobCandidateStatusUpdated',
  JobPostCreated = 'JobPostCreated',
  JobPostDeleted = 'JobPostDeleted',
  JobPostStatusUpdated = 'JobPostStatusUpdated',
  JobPostViewed = 'JobPostViewed',
  ProfileViewed = 'ProfileViewed',
  NoteAdded = 'NoteAdded',
  InvoiceCreated = 'InvoiceCreated',
  UserCreated = 'UserCreated',
  UserRegistered = 'UserRegistered',
  UserSignIn = 'UserSignIn',
  AccountCreated = 'AccountCreated',
  Error = 'Error'
}

class UserActivity {
  id: string
  createdAt: string
  updatedAt: string
  userId: string
  accountId: string
  subjectType: SubjectType
  subjectId: string // contractId, jobPostId, profileId, etc. 
  activityType: string // Added, Favorited, Invited, Offered, Applied, Approved, Pending, etc. 
  actorId: string
  actorName: string
  details: string
  
  constructor (data: any) {
    this.id = data.id
    this.createdAt = data.createdAt
    this.updatedAt = data.updatedAt
    this.userId = data.userId
    this.accountId = data.accountId
    this.subjectType = data.subjectType
    this.subjectId = data.subjectId
    this.activityType = data.activityType
    this.actorId = data.actorId
    this.actorName = data.actorName
    this.accountId = data.accountId
    this.details = data.details 
  }
}

export default UserActivity
