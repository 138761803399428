/* eslint-disable @typescript-eslint/no-unused-vars */
import React from "react";
import {
  CircularProgress,
  TextField,
  withWidth,
  WithWidth
} from "@material-ui/core";
import { createStyles, Theme, WithStyles, WithTheme } from "@material-ui/core";
import { withStyles, withTheme } from "@material-ui/core/styles";
import { Autocomplete } from "@material-ui/lab";
import { makeObservable, observable, when } from "mobx";
import { inject, observer } from "mobx-react";
import UserStore from "../../../stores/UserStore";
import AccountStore from "../../../stores/AccountStore";
import Progress from "../../Progress";
import ResourceCache, {LocationOption} from "../../../stores/ResourceCache";
import LocationAPI from "../../../apis/LocationAPI";
import LocationClient, {SearchForSuggestionsResult, SearchForTextResult, Place} from "aws-sdk/clients/location";
import Location from "../../../model/Location";
import Logger from "../../Logger";
import {AddressType} from "../../../API";
import {IFieldValidator} from "../../form/FormValidator";

const styles = (theme: Theme) => createStyles({
  filterTextField: {
    // minWidth: 308,
    // width: "100%",
    flexGrow: 1, 
    backgroundColor: theme.palette.background.paper,
    borderRadius: 10
  }
})

export interface IPlaceFilterProps {
  onSelectLocation?: (locationOption?: LocationOption) => any
  onSelectLocations?: (locationOptions: LocationOption[]) => any
  value?: any // For single select (!multiple)
  multiple?: boolean
  disabled?: boolean
  defaultOptions?: LocationOption[]  // For multiple = true
  accountStore?: AccountStore
  progress?: Progress
  userStore?: UserStore
  resourceCache?: ResourceCache
  locationAPI?: LocationAPI
  formvalidator?: any
  variant?: 'filled' | 'outlined' | 'standard'
  required?: boolean
  error?: boolean
  label?: string
  placeholder?: string
  helperText?: string
  className?: string
}

@inject("accountStore", "userStore", "resourceCache", "progress", "locationAPI")
@inject((allStores: any) => ({
  formvalidator: allStores.formvalidator ?? undefined
}))
@observer
class PlaceFilter extends React.Component<WithStyles<typeof styles> & WithTheme & IPlaceFilterProps & IFieldValidator & WithWidth> {

  @observable isLoading: boolean = true 
  @observable isProcessing: boolean = false 
  @observable placeOptions: string[] = []
  @observable placeText?: string | undefined = ""
  @observable defaultValue: string[] = []
  @observable placeLocationOption?: LocationOption
  @observable isValid = true

  private defaultPlaceOptions = []

  locationClient?: LocationClient

  placesOrgName = "Places"

  constructor(props: any) {
    super(props)
    makeObservable(this)
  }

  componentDidMount() {
    const { resourceCache, value, defaultOptions, formvalidator } = this.props

    if (formvalidator) {
      formvalidator.attachToForm(this)
    }

    when(
      // once...
      () => resourceCache!.isLoading === false,
      // ... then
      async () => {
        if (this.props.multiple) {
          this.defaultValue = defaultOptions ? defaultOptions.map((option: LocationOption) => option.name) : []
          this.placeOptions = this.defaultPlaceOptions // this.defaultValue
          this.isLoading = false
        } else {
          this.placeLocationOption = value
          this.placeText = value ? value.name : ""
          this.placeOptions = this.placeText ? [this.placeText] : this.defaultPlaceOptions
          this.isLoading = false
        }
      }
    )
  }

  componentDidUpdate(prevProps: any) {
    const { value } = this.props
    const prevId = prevProps.value ? (prevProps.value as LocationOption).id : null 
    const nextId = value ? (value as LocationOption).id : null 
    if (prevId !== nextId) {
      this.placeLocationOption = value
      this.placeText = value ? value.name : ""
    }
  }

  render() {
    const { classes, multiple, required, disabled, label, placeholder, helperText } = this.props

    if (this.isLoading === true) {
      return null 
    }

    const variant = this.props.variant ?? 'outlined'
    const className = this.props.className ?? classes.filterTextField

    return (
      <Autocomplete
        autoSelect
        includeInputInList
        options={this.placeOptions}
        freeSolo
        forcePopupIcon
        onChange={this.onSelectLocation}
        onInputChange={this.onInputChange}
        multiple={multiple ? multiple : false}
        value={!multiple ? this.placeText : undefined}
        defaultValue={multiple ? this.defaultValue : undefined}
        disabled={disabled}
        fullWidth
        noOptionsText='Enter location, Remote or Anywhere'
        loading={this.isProcessing}
        style={{width:"100%"}}
        renderInput={(params: any) =>
          <div ref={params.InputProps.ref}>
            <TextField
              {...params}
              className={className}
              variant={variant}
              size={multiple ? "medium" : "small"}
              name="PlaceFilter"
              label={label ? label : multiple ? "Locations" : "Location"}
              placeholder={placeholder ? placeholder : multiple ? "Location" : "Location or Remote"}
              helperText={helperText}
              fullWidth
              required={required ?? false}
              onBlur={(event: any) => this.onBlur(event, params.inputProps)}
              error={!this.isValid || this.props.error}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <React.Fragment>
                    {this.isProcessing ? <CircularProgress color="inherit" size={20} /> : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                ),
              }}
            />
          </div>
        }
      />
    )
  }

  searchTimer?: any

  onInputChange = (event: any, value: string, reason: string) => {
    const { onSelectLocation } = this.props
    clearTimeout(this.searchTimer);
    if(event) {
      if (value === "") {
        this.placeOptions = this.defaultPlaceOptions
        if (onSelectLocation) {
          onSelectLocation(undefined)
        }
        return
      }
  
      // Wait for a pause before searching
      this.searchTimer = setTimeout(() => {
        this.getLocationSuggestions(value)
          .then((options: string[]) => {
            this.placeOptions = options
          })
      }, 300)
    }
  }

  onSelectLocation = async (event: any, value: any, reason: string) => {
    const { multiple, onSelectLocation, onSelectLocations } = this.props
    // console.log(`onSelectLocation(${value}, ${reason}`)
    if (!multiple && onSelectLocation) {
      const locationOption = await this.getLocationOption(value)
      if (locationOption) {
        this.isValid = true
        onSelectLocation(locationOption)
      }
    } else if (Array.isArray(value) && onSelectLocations) {
      const locationOptions: LocationOption[] = []
      const promises: Promise<void | LocationOption | undefined>[] = []
      value.forEach((text: string) => {
        promises.push(this.getLocationOption(text)
          .then((locationOption: LocationOption | undefined) => {
            if (locationOption) {
              locationOptions.push(locationOption)
            }
          })
        )
      })
      await Promise.all(promises)

      this.isValid = true
      onSelectLocations(locationOptions)
    }
  }

  getLocationClient = async () => {
    if (!this.locationClient) {
      this.locationClient = await this.props.locationAPI?.createClient()
    }
    return this.locationClient
  }

  getLocationSuggestions = async (text: string): Promise<string[]> => {
    const { resourceCache } = this.props

    if (text.length < 3) {
      return []
    }

    const locationOptions = resourceCache!.matchLocationOptions(text)
    let suggestions = locationOptions.filter((option: LocationOption) => option.group !== this.placesOrgName)
                                     .map((option: LocationOption) => option.name)

    const {locationAPI} = this.props
    const client = await this.getLocationClient()
    
    return new Promise<string[]>((resolve, reject) => {
      if (client) {
        this.isProcessing = true 
        const params: LocationClient.Types.SearchPlaceIndexForSuggestionsRequest = {
          IndexName: locationAPI!.placeIndex,
          Text: text
        }
        client.searchPlaceIndexForSuggestions(params, (err: any, data: LocationClient.SearchPlaceIndexForSuggestionsResponse) => {
          this.isProcessing = false 
          if (err) {
            reject(err)
          } else {
            const results: string[] = []
            data.Results.forEach((result: SearchForSuggestionsResult) => {
              // Don't include if it is already in the Locations
              if (suggestions.indexOf(result.Text) < 0) {
                results.push(result.Text)
              }
            })
            suggestions = [...suggestions, ...results]
            resolve(suggestions)
          }
        })
      }
    })
  }

  getLocationOption = async (text: string): Promise<LocationOption | undefined> => {
    const {locationAPI, resourceCache} = this.props

    if (!text || text.length === 0) {
      return undefined
    }

    // Check cached LocationOptions first
    const options = resourceCache!.matchLocationOptions(text, true)
    if (options.length > 0) {
      return options[0]
    }

    // Get Location from Place service
    const client = await this.getLocationClient()

    return new Promise<LocationOption>((resolve, reject) => {
      let locationOption: LocationOption

      if (client) {
        const params: LocationClient.Types.SearchPlaceIndexForSuggestionsRequest = {
          IndexName: locationAPI!.placeIndex,
          Text: text,
          MaxResults: 5
        }
        client.searchPlaceIndexForText(params, (err: any, data: LocationClient.SearchPlaceIndexForTextResponse) => {
          if (err) {
            Logger.error("searchPlaceIndexForText Error: ", err.message)
            reject(err)
          } else {
            const results = data.Results.map((result: SearchForTextResult) => result.Place)
            if (results.length > 0) {
              // Find the matching name
              let result = results.find((place: Place) => place.Label === text)
              if (!result) {
                result = results[0]
              }
              const point = result.Geometry.Point
              if (point && point.length >= 2) {
                const geoHash = locationAPI?.geoHashEncode(point[1], point[0])
                if (geoHash) {
                  let location = resourceCache!.getLocation(geoHash)
                  if (!location) {
                    // Create new Location
                    let addressType
                    if (result.AddressNumber && result.Street) {
                      addressType = AddressType.Street
                    } else if (result.Neighborhood) {
                      addressType = AddressType.Neighborhood
                    } else if (result.PostalCode && result.Municipality && result.Region) {
                      addressType = AddressType.PostalCode
                    } else if (result.Municipality && result.Region) {
                      addressType = AddressType.City
                    } else if (result.SubRegion) {
                      addressType = AddressType.County
                    } else if (result.Region) {
                      addressType = AddressType.State
                    } else if (result.Country) {
                      addressType = AddressType.Country
                    }
                    // TODO: Handle mapping all country abbreviations to full names
                    // This is for consistency with our standard facility locations
                    let country = result.Country
                    if (country === "USA") {
                      country = "United States"
                    } else if (country === "CAN") {
                      country = "Canada"
                    }
                    location = new Location({
                      id: geoHash,
                      active: false, // Indicates this is temporary
                      organizationName: this.placesOrgName,
                      name: result.Label,
                      addressType: addressType,
                      address: (result.AddressNumber && result.Street) ? `${result.AddressNumber} ${result.Street}` : undefined,
                      city: result.Municipality,
                      state: result.Region,
                      postalCode: result.PostalCode,
                      country: country,
                      latitude: point[1],
                      longitude: point[0]
                    })

                    resourceCache!.addLocation(location)
                  }

                  locationOption = new LocationOption(geoHash, text, result.Region ?? "")
                }
              }
            }

            if (!locationOption) {
              Logger.error("Location Not Found", text)
            }

            resolve(locationOption)
          }
        })
      }
    })
  }

  // For FormValidator use
  validate = (value: any): boolean => {
    this.isValid = !this.props.required || value ? true : false

    return (this.isValid)
  }

  private onBlur = (event: React.FocusEvent<HTMLInputElement>, inputParams?: any): void => {
    const {value} = event.currentTarget;

    this.validate(value)
  }
}

export default withTheme(withStyles(styles)(withWidth()(PlaceFilter)))
