import Location from "./Location"

export class ProfileLocation {
  id: string
  createdAt: string
  updatedAt: string
  userId: string
  profileId: string
  locationId: string
  location?: Location

  constructor(data: any) {
    this.id = data.id
    this.createdAt = data.createdAt
    this.updatedAt = data.updatedAt
    this.userId = data.userId
    this.profileId = data.profileId
    this.locationId = data.locationId
    this.location = data.location ? new Location(data.location) : undefined
  }
}

export default ProfileLocation