import * as React from "react";
import {
  Box,
  createStyles,
  Grid, Link,
  Paper, 
  Theme,
  Typography,
  withStyles,
  WithStyles,
  withWidth,
  WithWidth
} from "@material-ui/core";
import {inject, observer} from "mobx-react";
import UserStore from "../../stores/UserStore";
import JobCandidate from "../../model/JobCandidate";
import {grey} from "@material-ui/core/colors";
import JobPost from "../../model/JobPost";
import Confirm from "../confirm/Confirm";
import AccountStore from "../../stores/AccountStore";
import Notify from "../notify/Notify";
import JobStore from "../../stores/JobStore";
import AnswerChipArray from "./AnswerChipArray";
import JobCandidateStatusFlow from "./JobCandidateStatusFlow";
import ProfileNameView from "../profile/ProfileNameView";
import Answer from "../../model/Answer";
import {ProfileStatus, SubjectType} from "../../API";
import JobCandidateNotes from "./JobCandidateNotes";
import {makeObservable, observable} from "mobx";
import UserActivityList from "../userActivity/UserActivityList";
import theme from "../../styles/theme";
import JobCandidateActivity from "./JobCandidateActivity";

const styles = (theme: Theme) => createStyles({
  border: {
    border: `1px solid ${grey[300]}`
  },
  tab: {
    color: theme.palette.grey["600"],
    width: 100,
    textAlign: "center",
    cursor: "pointer"
  },
  activeTab: {
    color: theme.palette.secondary.dark,
    width: 100,
    textAlign: "center",
    cursor: "pointer"
  },
  tabLabel: {
    fontWeight: 800
  }
})

interface IJobCandidateCardProps {
  jobCandidate: JobCandidate
  jobPost?: JobPost
  elevation: number
  onUpdate(jobCandidate: JobCandidate): void
  onDelete(jobCandidate: JobCandidate): void 
  onClickTitle?(): void
  
  accountStore?: AccountStore
  jobStore?: JobStore
  confirm?: Confirm
  notify?: Notify
  userStore?: UserStore
}

@inject('accountStore', 'jobStore', 'confirm', 'userStore')
@observer
class JobCandidateCard extends React.Component<WithStyles<typeof styles> & IJobCandidateCardProps & WithWidth> {
  @observable tab = 0

  constructor(props: WithStyles<typeof styles> & IJobCandidateCardProps & WithWidth) {
    super(props);
    makeObservable(this);
  }

  render() {
    const { classes, elevation } = this.props
    return (
      <Paper elevation={ elevation } className={ elevation === 0 ? classes.border : undefined }>
        <Box p={ 2 }>
          <Grid container justifyContent="space-between" alignItems="center" spacing={1} wrap="nowrap">
            <Grid item xs={12} md={12}>
              { this.renderJobCandidate() }
            </Grid>
            {/* <Grid item>
              { this.renderStatusArea() }
            </Grid> */}
            {/*<Grid item xs={2} md={1}>*/}
            {/*  <Stack direction="column" alignItems="center">*/}
            {/*    <IconButton*/}
            {/*      color="primary"*/}
            {/*      onClick={onClickTitle}*/}
            {/*    >*/}
            {/*      <ArrowForwardIos />*/}
            {/*    </IconButton>*/}
            {/*    /!* <Typography variant="body2">Review</Typography> *!/*/}
            {/*    /!* <Typography variant="body2">Candidate</Typography> *!/*/}
            {/*  </Stack>*/}
            {/*</Grid>*/}
          </Grid>
        </Box>
      </Paper>
    )
  }

  renderJobCandidate = () => {
    const { 
      jobCandidate, 
      // userStore, 
      onClickTitle,
      classes
    } = this.props

    const profile = jobCandidate.profile!
    // const user = profile.user!
    // const name = (userStore!.isAdminOrAgent || jobCandidate.unlocked) ? profile.name : ""

    return (
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="flex-start"
        spacing={1}
        // wrap="nowrap"
      >
        <Grid item xs={12} md={4}>
          <ProfileNameView 
            profile={profile}
            jobCandidate={jobCandidate}
            onClickTitle={onClickTitle}
            showAvatar
            size="small"
          />
          <Box pb={1} fontWeight={200} maxHeight={26}>
            <Typography variant="body2" color="textSecondary">
              {jobCandidate.statusText}
            </Typography>
          </Box>
          { this.renderAnswerChips() }
        </Grid>
        <Grid item xs={12} md={8}>
          <Box display="flex" p={0} m={0}>
            <Link className={this.tab === 0 ? classes.activeTab : classes.tab} onClick={() => this.handleTabClick(0)}>
              <Typography variant="body2" className={classes.tabLabel}>Status</Typography>
            </Link>
            <Link className={this.tab === 1 ? classes.activeTab : classes.tab} onClick={() => this.handleTabClick(1)}>
              <Typography variant="body2" className={classes.tabLabel}>Notes</Typography>
            </Link>
            <Link className={this.tab === 2 ? classes.activeTab : classes.tab} onClick={() => this.handleTabClick(2)}>
              <Typography variant="body2" className={classes.tabLabel}>Activity</Typography>
            </Link>
          </Box>
          {this.tab === 0 &&
            <JobCandidateStatusFlow
              elevation={0}
              jobCandidate={jobCandidate}
              onClickProfile={() => {
                if (this.props.onClickTitle) {
                  this.props.onClickTitle()
                }
              }}
              onUpdateJobCandidate={(updatedJobCandidate: JobCandidate) => {
                this.props.onUpdate(updatedJobCandidate)
              }}
            />
          }
          {this.tab === 1 &&
            <Box height={158} maxHeight={158}>
              <JobCandidateNotes
                elevation={0}
                jobCandidate={jobCandidate}/>
            </Box>
          }
          {this.tab === 2 &&
            <Box height={158} maxHeight={158}>
              <JobCandidateActivity
                elevation={0}
                jobCandidate={jobCandidate}/>
            </Box>
          }
        </Grid>
      </Grid>
    )
  }

  renderActivty = () => {
    const { elevation, jobCandidate } = this.props

    return (
      <Paper elevation={0}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            flexGrow: 1,
            px: 1,
            py: 0,
            m: 0,
            height: 158,
            maxHeight: 158,
            borderRadius: 10,
            border: `1px solid ${theme.palette.grey[300]}`,
            overflow: "scroll",
          }}
        >
          <UserActivityList subject={SubjectType.JobCandidate} subjectId={jobCandidate.id}/>
        </Box>
      </Paper>
    )
  }

  handleTabClick = (index: number) => {
    this.tab = index
  }

  // renderStatusArea = () => {
  //   const { jobCandidate } = this.props
  //   return (
  //     <Box bgcolor="grey.200" px={2} borderRadius={10} boxShadow="rgba(0,0,0,0.25) 0px 1px 5px 0px">
  //       <Grid container justifyContent="space-between" spacing={2}>
  //         <Grid item xs={10}>
  //           <Box>
  //             <JobCandidateStatusSelector
  //               jobCandidate={jobCandidate}
  //               onChange={this.props.onUpdate}
  //               />
  //           </Box>
  //         </Grid>
  //         <Grid item xs={2}>
  //           <Box textAlign="right">
  //             <IconicButton onClick={this.onClickUnfavorite} disabled={ jobCandidate.status === JobCandidateStatus.Hired }>
  //               <StarIcon />
  //             </IconicButton>
  //           </Box>
  //         </Grid>
  //       </Grid>
  //     </Box>
  //   )
  // }

  renderAnswerChips = () => {
    const { jobCandidate } = this.props

    if (jobCandidate.application.length > 0) {
      let answers: Answer[] = []
      jobCandidate.application.forEach((a: Answer) => {
        if (a.name === "Signup Question") {
          return
        } else if (a.name === "GovGig Candidate" && a.value === "false" && jobCandidate.profile && jobCandidate.profile.profileStatus === ProfileStatus.Accepted) {
          return
        } else {
          answers.push(a)
        }
      })

      if (answers.length > 0) {
        return (
          <AnswerChipArray answers={answers}/>
          // <Grid item xs={12} md={3}>
          // </Grid>
        )
      }
    }

    return null
  }

  // renderActionSelector = () => {
  //   return (
  //     <Grid item>
  //       {/* TODO: Use the split button for actions like inviting a candidate, or making an offer: <SplitButton /> */}
  //     </Grid>
  //   )
  // }
  
  // renderProfileMetadata = () => {
  //   const { jobCandidate } = this.props
  //   const profile = jobCandidate.profile!

  //   let location
  //   if (profile.city && profile.state) {
  //     location = `${profile.city}, ${profile.state}`
  //   }
    
  //   let availableDate: string | undefined = undefined
    
  //   if (profile!.availableDate) {
  //     availableDate = format(isoToLocalDate(profile!.availableDate), "MMM d, yyyy")
  //   }
  //   return (
  //     <Grid item xs={12}>
  //       <Grid container direction="column">
  //         { location &&
  //           <Grid item>
  //             <CardValue label="Location">
  //               {location}
  //             </CardValue>
  //           </Grid>
  //         }
  //         <Grid item>
  //           <CardValue label="Available">
  //             {availableDate}
  //           </CardValue>
  //         </Grid>
  //         <Grid item>
  //           <CardValue label="Rate">
  //             {profile.desiredRate}
  //           </CardValue>
  //         </Grid>
  //         <Grid item>
  //           <CardValue label="Summary" wrapLabelAndValue={false} wrapText={true}>
  //             <Typography variant='body2'>{profile.about}</Typography>
  //           </CardValue>
  //         </Grid>
  //       </Grid>
  //     </Grid>
  //   )
  // }

  // onClickUnfavorite = async () => {
  //   const { jobStore, userStore, confirm, jobCandidate, notify, onDelete: onDeleteJobCandidate } = this.props

  //   // TODO: Should also guard against deletion for other statuses? 
  //   if (jobCandidate.status === JobCandidateStatus.Hired) {
  //     return 
  //   }

  //   confirm!.show('Confirm Remove Candidate', 
  //     `Please confirm you want to remove this candidate from the job post.`,
  //     ["Confirm","Cancel"], async () => {
  //       const deleted = await jobStore!.deleteJobCandidate(jobCandidate.id)
  //         .catch((err: Error) => {
  //           notify!.show("error", "Error deleting job candidate")
  //         })

  //       if (deleted) {
  //         if (jobCandidate.profile && jobCandidate.jobPost) {
  //           userStore!.logUserActivity(jobCandidate.profile.userId, SubjectType.JobPost, jobCandidate.jobPostId, ActivityType.JobCandidateDeleted,
  //             `${userStore!.user!.fullName} deleted '${jobCandidate.profile.name}' from the job post '${jobCandidate.jobPost.fullName}'`)
  //         }

  //         await onDeleteJobCandidate(jobCandidate)
  //       }
  //       return true 
  //     }
  //   )
  // }
}

export default withStyles(styles)(withWidth()(JobCandidateCard))
