import User from "./User";
import {AccountStatus} from "../API";
import {NAICS} from "./Industry";


class Account {
  id: string
  createdAt: string
  updatedAt: string
  ownerId: string
  name: string
  address: string
  city: string
  state: string
  postalCode: string
  country: string
  active: boolean
  // dunsNumber: string
  // cageCode: string
  // naicsCode: string
  // sicCode: string
  socioEconomicDesignation: string
  // fein: string
  industries: string[]
  accountStatus: AccountStatus
  customerId: string
  phone: string
  webUrl: string
  users: User[]

  constructor(data: any) {
    this.id = data.id
    this.createdAt = data.createdAt
    this.updatedAt = data.updatedAt
    this.ownerId = data.ownerId
    this.name = data.name
    this.address = data.address
    this.city = data.city
    this.state = data.state
    this.postalCode = data.postalCode
    this.country = data.country
    this.active = data.active
    // this.dunsNumber = data.dunsNumber
    // this.cageCode = data.cageCode
    // this.naicsCode = data.naicsCode
    // this.sicCode = data.sicCode
    this.socioEconomicDesignation = data.socioEconomicDesignation
    // this.fein = data.fein
    this.industries = data.industries ?? [NAICS.Construction]
    this.accountStatus = AccountStatus[data.accountStatus]
    this.customerId = data.customerId
    this.phone = data.phone
    this.webUrl = data.webUrl
    this.users = []
    if (data.users && data.users.items) {
      this.loadUsers(data.users.items)
    }
  }

  loadUsers(items: any[]) {
    this.users = items.map((item: any) => new User(item))
  }

}

export default Account