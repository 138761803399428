import * as React from 'react'
import {inject, observer} from "mobx-react";
import {autorun, makeObservable, observable, when} from "mobx";
import {
  createStyles,
  Theme,
  Grid,
  Box,
} from "@material-ui/core";
import AccountStore from "../../stores/AccountStore";
import {withStyles, WithStyles, withTheme, WithTheme} from "@material-ui/core/styles";
import {RouteComponentProps} from "@reach/router";
import { AccountStatus } from '../../API';
import Account from "../../model/Account";
import AccountCard from './AccountCard';
import SearchBar from "material-ui-search-bar";
import TextFieldValidator from "../../components/form/TextFieldValidator";
import ControlTower, { Routes } from "../../components/ControlTower";
import Progress from "../../components/Progress";
import FilterBar from "../../components/filter/FilterBar";
import AddButton from "../../components/AddButton";
import AccountSettingsDialog from "../settings/account-settings/AccountSettingsDialog";
import UserStore from "../../stores/UserStore";
import SideDrawer from '../../components/page/SideDrawer';
import AccountPage from '../accounts/AccountPage';

const styles = (theme: Theme) => createStyles({
  content: {
    flexGrow: 1,
    width: "100%",
    padding: 3,
  },
  searchbar: {
    display: "flex",
    flexGrow: 2,
    width: '100%',
    height: 38
  },
  cardspace: {
    // width: "100%",
    overflowY: "auto",
    paddingBottom: theme.spacing(3), // card shadow was being cut off on left and bottom of cardspace, card size reduced by 3 to accommodate
    paddingLeft: theme.spacing(1), 
    paddingRight: theme.spacing(1),
    paddingTop: theme.spacing(1)
  },
  searchIcon: {
    color: theme.palette.secondary.main,
    fontSize: "16px"
  },
  searchFilter: {
    flexGrow: 1
  },
  filterControl: {
    textAlign: "left"
  },
  filter: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    marginRight: theme.spacing(1),
  },
  statusFilter: {
    width: 180,
    backgroundColor: theme.palette.background.paper,
    borderRadius: 10,
  },
  numFound: {
    flexGrow: 1,
    width: "100%",
    padding: 3,
    color: "initial",
  },
})

interface IEmployerListProps {
  accountStore?: AccountStore
  userStore?: UserStore
  progress?: Progress
}

@inject("accountStore", "userStore", "progress")
@observer
class EmployerList extends React.Component<WithStyles<typeof styles> & RouteComponentProps & IEmployerListProps & WithTheme> {
  @observable accountsUnfiltered: Account[] = []
  @observable accounts: Account[] = []
  @observable search: string = ""
  @observable sortBy?: any = {field: "default", asc: true}
  @observable statusFilter: AccountStatus | null = null
  @observable isAccountFormOpen = false
  @observable industryFilter?: string
  @observable account?: Account 

  constructor(props: any) {
    super(props)
    makeObservable(this);
  }

  componentDidMount() {
    const { accountStore, userStore, progress } = this.props

    when(
      () => !userStore!.isLoading && !accountStore!.isLoading && accountStore!.account !== undefined,
      async () => {
        progress!.show("EmployerList")
        this.industryFilter = userStore!.industry
        await this.loadAccounts()
        progress!.hide("EmployerList")
      }
    )

    autorun(
      async () => {
        if (!userStore!.isLoading && userStore!.industry !== this.industryFilter) {
          this.industryFilter = userStore!.industry
          await this.loadAccounts()
        }
      }
    )

  }

  render() {
    const { classes } = this.props

    return (
    <Box paddingTop={1} width={'100%'}>

      {this.renderFilterBar()}
      {this.renderAccountLargeDrawer()}

      <div className={classes.numFound} key={'content'}>
        {this.accounts.length} Accounts found
      </div>
      <Grid container spacing={2} justifyContent={"flex-start"} className={classes.cardspace} key={'grid'}>
        {this.accounts.map((account: any) => {
          return (
          <Grid item xs={12} md={6} lg={4} key={account.id}>
            <AccountCard 
              account={account} 
              onNameClick={() => this.onNameClick(account.id)} 
            />
          </Grid>
          )
        })}
      </Grid>
      {this.isAccountFormOpen &&
        <AccountSettingsDialog
          open={true}
          onClose={this.onCloseAccountForm}
          onCreateAccount={this.onCreateAccount}
          onUpdateAccount={this.onUpdateAccount}
        />
      }
    </Box>
    )
  }

  renderFilterBar = () => {
    const {classes } = this.props

    return (
      <Box py={1}>
        <FilterBar
          searchResultCount={ this.accounts.length }
          buttons={[
            <AddButton
              key="addJobButton"
              text="Add Account"
              tracking="addAccount"
              buttonColor="secondary"
              buttonVariant="contained"
              buttonSize="medium"
              click={this.onAddAccount}
            />
          ]}
        >
          <div className={classes.searchFilter} key={'searchFilter'}>
            <SearchBar className={classes.searchbar} value={this.search} onChange={this.onSearchChange}
                       classes={{icon: classes.searchIcon}} placeholder={"Search by name"}
                       onRequestSearch={this.onRequestSearch} onCancelSearch={this.onCancelSearch}/>
          </div>

          <div className={classes.filter}>
            <div className={classes.filterControl}>
              <TextFieldValidator
                type="text"
                className={classes.statusFilter}
                variant="outlined"
                size="small"
                name="statusFilter"
                label="Status"
                autocompleteOptions={{
                  options: [...Object.values(AccountStatus)],
                  onChange: this.onSelectStatus,
                  value: this.statusFilter
                }}
                InputLabelProps={{ shrink: this.statusFilter ? true : false }}
              />
            </div>
          </div>
        </FilterBar>
      </Box>
    )
  }

  renderAccountLargeDrawer = () => {
    return (
      <SideDrawer 
        size="large"
        title={'Account Details'} 
        isOpen={this.account !== undefined} 
        onClose={() => {
          this.account = undefined
        }}        
      >
        {this.account && 
          <AccountPage 
            accountId={this.account!.id}
            embed 
            onUpdateAccount={this.onUpdateAccount}
            onCreateAccount={this.onCreateAccount}
          />
        }
      </SideDrawer>
    )
  }

  loadAccounts = async () => {
    const { accountStore } = this.props
    this.accountsUnfiltered = await accountStore!.listAccounts(this.industryFilter)
    this.sortAccounts()
    this.filterAccounts()
  }

  onSearchChange = (value: string) => {
    this.search = value
  }

  onSelectStatus = (event: any, value: any, reason: string) => {
    this.statusFilter = value
    this.filterAccounts()
  }

  filterAccounts = () => {
    if (this.search === "" && this.statusFilter === null) {
      this.accounts = this.accountsUnfiltered // If no search or filters present
    } else {
      let filteredAccounts: Account[] = []
      const search = this.search!.toLowerCase().replace(/\s{2,}/g, " ")
      this.accountsUnfiltered.forEach((account: Account) => {
        if (account) {
          if ((search === "" || this.searchText(account.name, search))
            && (this.statusFilter === null || account.accountStatus === this.statusFilter)) {
            filteredAccounts.push(account)
          }
        }
      })
      this.accounts = filteredAccounts
    }
  }

  searchText = (text: string, search: string) => {
    return (
      text ? text.toLowerCase().indexOf(search) >= 0 : false
    )
  }

  sortAccounts = () => {
    if (this.sortBy.field === "default") {
      if (this.sortBy.asc) {
        this.accountsUnfiltered.sort((a: Account, b: Account) => {
          return a.name.localeCompare(b.name)
        })
      } else {
        this.accountsUnfiltered.sort((a: Account, b: Account) => {
          return b.name.localeCompare(a.name)
        })
      }
    }
  }

  onRequestSearch = () => {
    this.search = this.search ? this.search.trim() : ""
    this.filterAccounts()
  }

  onCancelSearch = () => {
    this.search = ""
    this.filterAccounts()
  }

  onNameClick = (accountId: String) => {
    // ControlTower.route(`${Routes.manage}/people?acctname=${name}`)
    // ControlTower.route(`${Routes.manageJobs}?accountId=${accountId}`)
    const account = this.accounts.find(account => account.id === accountId)
    // console.debug('onNameClick', {
    //   account, 
    //   accountId
    // })
    this.account = account 
  }

  onAddAccount = () => {
    this.isAccountFormOpen = true
  }

  onCloseAccountForm = () => {
    this.isAccountFormOpen = false
  }

  onCreateAccount = async (createdAccount: Account) => {
    this.accountsUnfiltered.push(createdAccount)
    this.sortAccounts()
    this.filterAccounts()
  }

  onUpdateAccount = async (updatedAccount: Account) => {
    const foundIndex = this.accountsUnfiltered.findIndex(checkAccount => checkAccount.id === updatedAccount.id)
    if (foundIndex !== -1) {
      this.accountsUnfiltered.splice(foundIndex, 1, updatedAccount)
      this.sortAccounts()
      this.filterAccounts()
    }
  }
}

export default withTheme((withStyles(styles)(EmployerList)))


