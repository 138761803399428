import React from "react";
import {
  alpha,
  Box,
  Chip,
  createStyles,
  Grid,
  Theme,
  Typography,
  withStyles,
  WithStyles,
  withTheme,
  WithTheme,
  withWidth,
  WithWidth
} from "@material-ui/core";
import UserStore from "../../stores/UserStore";
import ProfileStore from "../../stores/ProfileStore";
import {inject, observer} from "mobx-react";
import { observable, when, makeObservable } from "mobx";
import Profile from "../../model/Profile";
import {CreateProfileLocationInput, UpdateProfileInput, WorkSetting} from "../../API";
import IconicButton from "../../components/controls/IconicButton";
import EditIcon from "@material-ui/icons/Edit";
import AddButton from "../../components/AddButton";
import Logger from "../../components/Logger";
import Location from '../../model/Location'
import LocationOnIcon from '@material-ui/icons/LocationOn';
import ProfileLocation from "../../model/ProfileLocation";
import Notify from "../../components/notify/Notify";
import {getErrorMessage} from "../../stores/StoreUtilities";
import Visible from "../../components/Visible";
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ProfileLocationsDialog from "../../components/selection/ProfileLocationsDialog";
import ResourceCache from "../../stores/ResourceCache";
import Stack from "../../components/Stack";
import WifiIcon from '@material-ui/icons/Wifi';

const styles = (theme: Theme) => createStyles({
  label: {
    width: 150,
    fontWeight: 'bold',
    color: theme.palette.primary.main
  }
})

export interface IProfileLocationsProps {
  profile?: Profile
  isOpen?: boolean
  onChange?: any
  onClose?: any
  profileStore?: ProfileStore
  userStore?: UserStore
  resourceCache?: ResourceCache
  notify?: Notify
}

class LocationViewModel {
  id: string 
  name: string 
  constructor(location: Location) {
    this.id = location.id 
    this.name = location.name 
  }
}

class ProfileLocationsViewModel {
  @observable locations: LocationViewModel[] = []
  
  constructor (profileLocations: ProfileLocation[]) {
    makeObservable(this);

    profileLocations.forEach(profileLocation => {
      if (profileLocation && profileLocation.location) {
        const viewModel = new LocationViewModel(profileLocation.location)
        this.locations.push(viewModel)
      }
    })
    this.locations.sort((a: LocationViewModel, b: LocationViewModel) => a.name.localeCompare(b.name) )
  }
}

@inject("userStore", "profileStore", "resourceCache", "notify")
@observer
class ProfileLocations extends React.Component<WithStyles<typeof styles> & WithTheme & WithWidth & IProfileLocationsProps> {
  @observable viewModel?: ProfileLocationsViewModel
  @observable isDialogOpen: boolean = false
  @observable isProcessing = false
  state = {
    isListExpanded: false,
  }

  constructor(
    props: WithStyles<typeof styles> & WithTheme & WithWidth & IProfileLocationsProps
  ) {
    super(props);
    makeObservable(this);
  }

  componentDidMount() {
    when(
      // once...
      () => this.props.profile !== undefined,
      // ... then
      () => this.loadLocations()
    )
  }

  componentDidUpdate(prevProps: any) {
    if (prevProps.isOpen !== this.props.isOpen) {
      this.isDialogOpen = this.props.isOpen ?? false
    }
  }

  render() {
    const { 
      profile,
      theme,
      userStore
    } = this.props

    if (!profile) {
      return null
    }

    return (
      <Box 
        px={2} 
        py={2}
        bgcolor={theme.palette.common.white}
        borderRadius={10}
      >
        <Grid 
          container 
          direction="row" 
          justifyContent="flex-start" 
          alignItems="center" 
          spacing={1}
        >
          <Grid item>
            <Stack
              alignItems="center"
              spacing={1}
              sx={{
                // pr: 3 
                width: '150px'
              }}
            >
              <Box
                px={1}
                pt={1}
                pb={0.5}
                bgcolor={theme.palette.grey[200]}
                borderRadius={10}
                color="#000"
              >
                <LocationOnIcon color="inherit" />
              </Box>
              <Typography variant="body1">Locations</Typography>
            </Stack>
          </Grid>
          {this.renderLocationChips()}
          <Grid item>
            {this.renderEditButton()}
          </Grid>
        </Grid>
        {(userStore!.isApplicant || userStore!.isCandidate) &&
          <Box mt={1}>
            <Typography variant="body2">
              <em>Add multiple locations, cities, states, or countries to be considered for more positions! You can also specify Remote if you are looking for a remote or hybrid position.</em>
            </Typography>
          </Box>
        }
        {this.renderDialog()}
      </Box>
    )
  }

  loadLocations = async () => {
    let profile = this.props.profile
    if (profile === undefined) {
      Logger.error('Profile not found.')
      return 
    }

    this.viewModel = new ProfileLocationsViewModel(profile.profileLocations)
  }

  renderLocationChips() {
    const viewModel = this.viewModel
    if (!viewModel) {
      return null 
    }

    const numberToShow = 12
    if (viewModel.locations.length > numberToShow) {
      const visible = viewModel.locations.slice(0, numberToShow)
      const invisible = viewModel.locations.slice(numberToShow)
      return (
        <React.Fragment>
          { visible.map(location => {
            return (
              <Grid item key={location.id}>
                <Chip color="default" size="medium" icon={<LocationOnIcon />} label={location.name}/>
              </Grid>
            )
          })}
          <Visible if={this.state.isListExpanded}>
            { invisible.map(location => {
              return (
                <Grid item key={location.id}>
                  <Chip color="default" size="medium" icon={<LocationOnIcon />} label={location.name}/>
                </Grid>
              )
            })}
          </Visible>
          <Grid item>
            <IconicButton 
              onClick={() => {
                this.setState({ isListExpanded: !this.state.isListExpanded })
              }}
            >
              { this.state.isListExpanded ? <ChevronLeftIcon /> : <ChevronRightIcon /> }
            </IconicButton>
          </Grid>
        </React.Fragment>
      )
    } else {
      return (
        viewModel.locations.map(location => {
          return (
            <Grid item key={location.id}>
              <Chip color="default" size="medium" icon={ location.name === "Remote" ? <WifiIcon /> : <LocationOnIcon />} label={location.name}/>
            </Grid>
          )
        })
      )
    }
  }

  renderEditButton() {
    const { onChange } = this.props 
    let editButton
    if (onChange) {
      if (this.viewModel && this.viewModel.locations.length > 0) {
        editButton = <IconicButton onClick={this.handleEdit}>
          <EditIcon />
        </IconicButton>
      } else {
        editButton = <AddButton
          text="Add Locations to Work or Remote"
          tracking="profileAddWorkLocations"
          buttonColor="secondary"
          buttonVariant="contained"
          click={this.handleEdit}
        />
      }
    }
    return (
      <div>
        {editButton}
      </div>
    )
  }

  renderDialog() {
    const { onChange, profile } = this.props
    if (onChange === undefined) {
      return null 
    }

    return (
      <ProfileLocationsDialog
        isDialogOpen={this.isDialogOpen}
        profile={profile}
        onClose={this.handleClose}
        onSubmitSelections={this.handleSubmitSelections}
        isProcessing={this.isProcessing}
      />
      // <LocationsDialog
      //   isDialogOpen={this.isDialogOpen}
      //   profile={profile}
      //   onClose={this.handleClose}
      //   onSubmitSelections={this.handleSubmitSelections}
      //   isProcessing={this.isProcessing}
      // />
    )
  }

  handleEdit = (): void => {
    if (this.props.profile) {
      this.isDialogOpen = true
    }
  }

  handleClose = () => {
    this.isDialogOpen = false
    if (this.props.onClose) {
      this.props.onClose()
    }
  }

  handleSubmitSelections = async (selectedIds: string[]) => {
    const { profile, profileStore, notify } = this.props
    
    if (this.props.onChange) {
      this.isProcessing = true

      // Update ProfileLocations

      const results = await this.updateLocations(selectedIds)
        .catch((err) => {
          notify!.show("error", getErrorMessage(err))
        })

      if (results) {
        let updatedProfile = await profileStore!.getProfile(profile!.id)
        if (updatedProfile) {
          this.viewModel = new ProfileLocationsViewModel(updatedProfile.profileLocations)
          
          let isRemote = false 
          let isInPerson = false 
          updatedProfile.profileLocations.forEach((profileLocation: ProfileLocation) => {
            if (profileLocation.location!.name === 'Remote') {
              isRemote = true 
            } else {
              isInPerson = true 
            }
          })

          const input: UpdateProfileInput = {
            id: profile!.id 
          }
          if (isRemote && isInPerson) {
            input["workSettings"] = [WorkSetting.InPerson, WorkSetting.Remote, WorkSetting.Hybrid]
          } else if (isRemote) {
            input["workSettings"] = [WorkSetting.Remote]
          } else if (isInPerson) {
            input["workSettings"] = [WorkSetting.InPerson]
          } else {
            input["workSettings"] = [WorkSetting.InPerson]
          }

          updatedProfile = await profileStore!.updateProfile(input)

          this.props.onChange(updatedProfile)
        }
        this.isProcessing = false
        this.handleClose()
      }

      this.isProcessing = false
    } else {
      this.isProcessing = false
    }
  }

  updateLocations = async (selectedIds: string[]) => {
    const {profile, profileStore} = this.props

    const promises: Promise<ProfileLocation | undefined>[] = []

    // Create new ProfileLocations
    selectedIds.forEach((locationId: string) => {
      let profileLocation = profile!.getProfileLocation(locationId)
      if (!profileLocation) {
        const input: CreateProfileLocationInput = {
          userId: profile!.userId,
          profileId: profile!.id,
          locationId: locationId
        }
        promises.push(profileStore!.createProfileLocation(input))
      }
    })

    // Delete unused ProfileLocations
    profile!.profileLocations.forEach((profileLocation: ProfileLocation) => {
      if (selectedIds.indexOf(profileLocation.locationId) < 0) {
        promises.push(profileStore!.deleteProfileLocation(profileLocation.id))
      }
    })

    const results = await Promise.all(promises)

    return results
  }
}

export default withTheme(withStyles(styles)(withWidth()(ProfileLocations)))