import * as React from "react";
import {ExperienceQuestion, QuestionType} from "../../../model/Question";
import {
  Checkbox,
  FormControl,
  FormControlLabel, Grid, Input, InputAdornment,
  makeStyles,
  Typography
} from "@material-ui/core";
import TextFieldValidator from "../../form/TextFieldValidator";
import {useEffect, useState} from "react";
import QuestionCard from "./QuestionCard";
import Answer from "../../../model/Answer";
import AnswerCard from "./AnswerCard";
import AnswerChip from "../../jobCandidates/AnswerChip";

const useStyles = makeStyles((theme) => ({
  textField: {
    margin: theme.spacing(1),
    marginTop: theme.spacing(3),
    width: '10ch',
  },
}));

interface IExperienceQuestionCardProps {
  question: ExperienceQuestion
  answer?: Answer
  onChange?: any
  onDelete?: any
  onAnswer?: any
}
const ExperienceQuestionCard = (props: IExperienceQuestionCardProps) => {
  const { onChange, onDelete, onAnswer } = props
  const [question, setQuestion] = useState(new ExperienceQuestion({
    name: "Experience Question",
    type: QuestionType.ExperienceQuestion,
    required: false,
    minYears: 0,
    jobTitle: ""
  }))
  const [answer, setAnswer] = useState<Answer>(new Answer({
    name: "",
    type: "",
    required: false,
    value: "",
    pass: true,
    label: ""
  }))

  const classes = useStyles();

  useEffect(() => {
    setQuestion(props.question)
    if (props.answer) {
      setAnswer(props.answer)
    } else {
      const change =  validateAnswer(onAnswer ? "" : props.question.minYears.toString(), props.question)
      setAnswer(change)
      if (onAnswer) {
        onAnswer(change) // Set default answer
      }
    }
  }, [props.question, props.answer, onAnswer])
  
  const handleChange = (event: any) => {
    const { name, value, checked } = event.target
    let change
    if (name === "required") {
      change = {...question, [name]: checked}
    } else if (name === "minYears") {
      const minYears = parseInt(value)
      change = {...question, [name]: isNaN(minYears) ? "0" : minYears.toString()}
    } else {
      change = {...question, [name]: value}
    }
    setQuestion(change)
    validateAnswer(change.minYears.toString(), change)
    onChange(change)
  }

  const handleDelete = (name: string) => {
    if (onDelete) {
      onDelete(name)
    }
  }

  const handleAnswer = (event: any) => {
    const change = validateAnswer(event.target.value, question)
    if (onAnswer) {
      onAnswer(change)
    }
  }

  const validateAnswer = (value: string, question: ExperienceQuestion) => {
    let years = parseInt(value)
    if (isNaN(years)) {
      years = 0
    }

    const change = new Answer({
      name: question.name,
      type: question.type,
      required: question.required,
      value: `${years}`,
      pass: (!question.required || years >= question.minYears),
      label: `${question.jobTitle}: ${years} yrs`
    })
    setAnswer(change)
    return change
  }

  if (onChange) {
    return (
      <QuestionCard name={question.name} onDelete={handleDelete}>
        <Typography variant="body1" style={{paddingTop:8, paddingBottom:8}}>
          How many years of experience as
          <span style={{fontWeight:600, marginLeft:8, marginRight:8}}>
            {question.jobTitle.length > 0 ? question.jobTitle : "____________"}
          </span>
          do you have?
        </Typography>
        <TextFieldValidator
          name="jobTitle"
          label="Job Title"
          value={question.jobTitle}
          variant="standard"
          fullWidth
          validators={{required: true}}
          onChange={handleChange}
        />
        <Grid container>
          <Grid item>
            <Typography variant="body1"
                        style={{paddingTop:8, display:"flex", flexDirection:"row", alignItems:"flex-end"}}>
              <FormControlLabel
                label="Require at least"
                control={
                  <Checkbox name="required"
                            checked={question.required}
                            onChange={handleChange}/>
                }
              />
              <TextFieldValidator
                name="minYears"
                label="Years"
                value={question.minYears.toString()}
                variant="standard"
                style={{width: 50, marginRight:8}}
                inputProps={{style: {fontWeight: 600}}}
                validators={{required: question.required, minValue: 0}}
                // autocompleteOptions={autocompleteOptions}
                onChange={handleChange}
              />
              <div style={{paddingBottom: 12}}>years.</div>
            </Typography>
          </Grid>
          <Grid item style={{display:"flex", flexGrow:1, justifyContent:"flex-end", alignItems:"center", paddingTop:30, paddingRight:0}}>
            {answer.label &&
              <AnswerChip answer={answer}/>
            }
          </Grid>
        </Grid>
      </QuestionCard>
    )
  } else if (onAnswer) {
    return (
      <AnswerCard>
        <Typography variant="body1">
          How many years of experience as
          <span style={{marginLeft:8, marginRight:8}}>
              {question.jobTitle}
            </span>
          do you have?
        </Typography>
        <FormControl className={classes.textField}>
          <Input
            autoFocus
            name="answer"
            value={answer.value === "0" ? "" : answer.value}
            required
            onChange={handleAnswer}
            endAdornment={<InputAdornment position="end">Years</InputAdornment>}
            aria-describedby="year-helper-text"
            inputProps={{
              'aria-label': 'years',
            }}
          />
        </FormControl>
      </AnswerCard>
    )
  } else {
    return null
  }

}

export default ExperienceQuestionCard