import { RouteComponentProps, useLocation } from "@reach/router"
import Page from "../../components/page/Page"
import PageTitle from "../../components/page/PageTitle"
import { Button, Container, Grid, LinearProgress, Typography, useTheme } from "@material-ui/core"
import { useEffect, useState } from "react"
import Stack from "../../components/Stack"
import DialogPanel from "../../components/panel/DialogPanel"
import SelectionCard from "../../components/marketing/SelectionCard"
import DialogPanelInstructions from "../../components/panel/DialogPanelInstructions"
import * as APITypes from "../../API"
import DialogPanelButtons from "../../components/panel/DialogPanelButtons"
import ServiceRequestJobForm from "./ServiceRequestJobForm"
import JobPost from "../../model/JobPost"
import ServiceRequest, { ServiceRequestStatus } from "../../model/ServiceRequest"
import { useStores } from "../../stores/StoreProvider"
import { when } from "mobx"
import LoadingPanel from "../../components/panel/LoadingPanel"
import ServiceRequestJobsListForm from "./ServiceRequestJobsListForm"
import ServiceRequestPricingForm from "./ServiceRequestPricingForm"
// import ServiceRequestCalendarForm from "./ServiceRequestCalendarForm"
import SectionPanel from "../../components/panel/SectionPanel"
import EditButton from "../../components/controls/EditButton"
import ServiceRequestCard from "../../views/serviceRequests/ServiceRequestCard"
import Add from "@material-ui/icons/Add"
import ServiceRequestJobList from "../../views/serviceRequests/ServiceRequestJobList"
import ControlTower, { Routes } from "../../components/ControlTower"
import Agreement from "../../model/Agreement"
import DialogAgreementPanel from "../../components/panel/DialogAgreementPanel"
import RequestMeetingSection from "../../components/marketing/sections/RequestMeetingSection"
import ServiceRequestPricing from "../../views/serviceRequests/ServiceRequestPricing"
import Account from "../../model/Account"
import { PricingAgreementType } from "../../stores/AccountStore"
import ServiceRequestAccountForm from "./ServiceRequestAccountForm"

enum RequestStep {
  SelectAccount = "SelectAccount",
  SelectType = "SelectType",
  EnterJob = "EnterJob", 
  JobSummary = "JobSummary", 
  PricingSelect = "PricingSelect",
  MasterServicesAgreementSign = "MasterServiceAgreementSign",
  ConfidentialityAgreementSign = "ConfidentialityAgreementSign",
  ExclusiveMasterServicesAgreementSign = "ExclusiveMasterServicesAgreementSign",
  // CalendarSelect = "CalendarSelect", // TODO: Future, wire up Calendly. 
  Summary = "Summary", 
  Finished = "Finished"  
}

const ServiceRequestCreatePage = ({

}: RouteComponentProps) => {
  const title = "Create Service Request"
  const progressName = "ServiceRequestsCreatePage"
  const theme = useTheme()
  const { accountStore, jobStore, progress, userStore } = useStores()
  const location = useLocation()
  
  const [isLoading, setIsLoading] = useState(true)
  const [isProcessing, setIsProcessing] = useState(false)
  const [progressPercentage, setProgressPercentage] = useState<number>(1)
  const [step, setStep] = useState<RequestStep>()
  // const [message, setMessage] = useState<string>()
  
  // Service Request Info 
  const [serviceRequestType, setServiceRequestType] = useState<APITypes.ServiceRequestType>()
  const [pricingAgreementType, setPricingAgreementType] = useState<PricingAgreementType | null>()
  const [serviceRequest, setServiceRequest] = useState<ServiceRequest>()
  const [jobPostId, setJobPostId] = useState<string>()
  const [account, setAccount] = useState<Account>()
  
  useEffect(() => {
    const updateProgressPercentage = () => {
      let numerator = Object.values(RequestStep).indexOf(step as RequestStep)
      if (userStore.isAdminOrAgent === false) {
        numerator -= 1 
      }
      const denominator = userStore.isAdminOrAgent ? Object.keys(RequestStep).length - 1 : Object.keys(RequestStep).length - 2 // Finished step doesn't count for all, account step doesn't count for employers.
      const ratio = numerator/denominator
      setProgressPercentage(ratio * 100)
    }

    const scrollToTop = () => {
      window.scrollTo({top: 0, behavior: 'smooth'})
    }

    updateProgressPercentage()
    scrollToTop()
  }, [
    accountStore, 
    step, 
    userStore 
  ])

  useEffect(() => {
    progress.show(progressName) 
    const init = async () => {
      const searchParams = new URLSearchParams(location.search)
      const serviceRequestId = searchParams.get("serviceRequestId")
      
      console.debug("serviceRequestId", serviceRequestId)
      
      if (serviceRequestId) {
        const serviceRequest = await jobStore.getServiceRequest(serviceRequestId)
        setServiceRequest(serviceRequest)

        const account = await accountStore.getAccount(serviceRequest!.accountId) 
        setAccount(account)

        const pricingAgreementType = await accountStore.getPricingAgreementType(account!.id)
        setPricingAgreementType(pricingAgreementType)

        const {
          serviceRequestType
        } = serviceRequest!
        setServiceRequestType(serviceRequestType)
      }

      if (userStore.isAdminOrAgent) {
        setStep(RequestStep.SelectAccount)
      } else {
        const account = accountStore.account! 
        console.debug('ServiceRequestCreatePage init, Account id: ', account?.id)
        setAccount(account)
        setStep(RequestStep.SelectType)
      }

      progress.hide(progressName)
      setIsLoading(false)
    }

    when(
      () => accountStore.isLoading === false 
        && jobStore.isLoading === false 
        && userStore.isLoading === false, 
      () => {
        init() 
      }
    )
  }, [
    accountStore, 
    location,
    userStore   
  ])

  const renderSelectAccount = () => {
    return (
      <ServiceRequestAccountForm 
        account={account}
        onClickBack={() => {
          ControlTower.route(Routes.dashboard)
        }} 
        onSelectAccount={async (account: Account) => {
          setAccount(account)
          setStep(RequestStep.SelectType)
        }}        
      />
    )
  }

  const renderSelectType = () => {
    return (
      <DialogPanel>
        <DialogPanelInstructions 
          title="Select a request type"
          instructions={[
            "Let's gather some information about your request.",
            "Do you have a recruiting need, or a staffing need?"
          ]}
        />
        <Grid container spacing={3} direction="row" justifyContent="center">
          <Grid item xs={12} md={6}>
            <SelectionCard 
              title="Recruiting Services"
              titleBackgroundColor="hsl(218, 33%, 27%)"
              features={[
                "GovGig will create a job listing and find candidates on your behalf.", 
                "We will deliver candidates to you and set up interviews.", 
                "You directly hire the candidate when we find you a good fit for your open roles." 
              ]}
              buttonTitle="Select Recruiting"
              backgroundColor={
                theme.palette.common.white 
                // theme.palette.grey[200]
              }
              selected={ serviceRequestType === APITypes.ServiceRequestType.RecruitingServices }
              onButtonClick={() => {
                setServiceRequestType(APITypes.ServiceRequestType.RecruitingServices)
              }}
              onLearnMoreClick={() => {

              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <SelectionCard 
              title="Staffing Services"
              titleBackgroundColor="hsl(187, 35%, 37%)"
              features={[
                "GovGig will create a job listing and find candidates on your behalf.",
                "We will deliver candidates to you and set up interviews.", 
                "GovGig hires the candidate and places them as a temporary worker on your staff.", 
                "The worker can convert to a full-time hire for your company over time." 
              ]}
              buttonTitle="Select Staffing"
              backgroundColor={
                theme.palette.common.white 
                // 'hsl(215, 57%, 93%)'
              }
              selected={ serviceRequestType === APITypes.ServiceRequestType.StaffingServices }
              onButtonClick={() => {
                setServiceRequestType(APITypes.ServiceRequestType.StaffingServices)
              }}
              onLearnMoreClick={() => {

              }}
            />
          </Grid>
        </Grid>
        <DialogPanelButtons 
          isProcessing={isProcessing}
          buttonDisabled={ serviceRequestType === undefined } 
          onClickNext={async () => {
            setIsProcessing(true)
            let serviceRequestReference 
            if (serviceRequest) {
              const input: APITypes.UpdateServiceRequestInput = {
                id: serviceRequest.id,
                serviceRequestType: serviceRequestType!
              }
              const updatedServiceRequest = await jobStore.updateServiceRequest(input)
              setServiceRequest(updatedServiceRequest)
              serviceRequestReference = updatedServiceRequest
            } else {
              let name: string
              // const date = getISODateToday()
              if (serviceRequestType === APITypes.ServiceRequestType.StaffingServices) {
                // name = `Staffing Service Request ${date}`
                name = "Staffing Service Request" 
              } else if (serviceRequestType === APITypes.ServiceRequestType.RecruitingServices) {
                // name = `Recruiting Service Request ${date}`
                name = "Recruiting Service Request"
              }
              const serviceRequestInput: APITypes.CreateServiceRequestInput = {
                accountId: account!.id,
                serviceRequestType: serviceRequestType!,
                name: name!,
                status: ServiceRequestStatus.Draft
              }
              const createdServiceRequest = await jobStore.createServiceRequest(serviceRequestInput)
              setServiceRequest(createdServiceRequest)
              serviceRequestReference = createdServiceRequest
            }
            
            // setStep(RequestStep.ProjectQuery)
            if (serviceRequestReference!.jobPosts && serviceRequestReference!.jobPosts.length > 0) {
              setStep(RequestStep.JobSummary)
            } else {
              setStep(RequestStep.EnterJob)
            }

            setIsProcessing(false)
          }} 
          backButtonTitle="Back" 
          onClickBack={() => {
            if (userStore.isAdminOrAgent) {
              setStep(RequestStep.SelectAccount)
            } else {
              ControlTower.route(Routes.dashboard)
            }
          }}
        />
        <RequestMeetingSection />
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="center"
          spacing={2}
        >
          <Typography>If you would like to post jobs and manage candidates yourself, feel free to utilize our Self-service job post option.</Typography>
          <Button 
            size="small" 
            variant="outlined" 
            color="primary"
            onClick={() => {
              ControlTower.route(Routes.jobPostCreate)
            }}
          >
            Use Self-service
          </Button>
        </Stack>
      </DialogPanel>
    )
  }

  const renderEnterJob = () => {
    return (
      <ServiceRequestJobForm 
        account={account!}
        onClickBack={() => {
          setStep(RequestStep.SelectType)
        }} 
        serviceRequest={serviceRequest!} 
        jobPostId={jobPostId}
        onUpdateJobPost={async (jobPost: JobPost) => {
          const copy = Object.assign({}, serviceRequest)
          const copyJobPosts: JobPost[] = copy.jobPosts
          const foundIndex = copyJobPosts.findIndex(checkJobPost => checkJobPost.id === jobPost.id)
          if (foundIndex !== -1) {
            copyJobPosts[foundIndex] = jobPost 
          }
          setServiceRequest(copy)
          setStep(RequestStep.JobSummary)
        }}
        onCreateJobPost={async (jobPost: JobPost) => {
          const copy = Object.assign({}, serviceRequest)
          const copyJobPosts: JobPost[] = copy.jobPosts
          copyJobPosts.push(jobPost)
          setServiceRequest(copy)
          setStep(RequestStep.JobSummary)
        }}     
      />
    )
  }

  const renderJobSummary = () => {
    return (
      <ServiceRequestJobsListForm 
        serviceRequest={serviceRequest!} 
        onClickBack={() => {
          setStep(RequestStep.SelectType)
        }} 
        onClickAddJob={() => {
          setJobPostId(undefined)
          setStep(RequestStep.EnterJob)
        }}
        onEditJobPost={(jobPostId) => {
          setJobPostId(jobPostId)
          setStep(RequestStep.EnterJob)
        }}      
        onClickNext={async () => {
          setStep(RequestStep.PricingSelect) 
        }}  
      />
    )
  }

  const renderPricingSelect = () => {
    return (
      <ServiceRequestPricingForm
        accountId={account!.id}
        serviceRequest={serviceRequest!}
        onClickBack={() => {
          setStep(RequestStep.JobSummary)
        }}
        onSelectPricingAgreement={(pricingAgreementType: PricingAgreementType) => {
          setPricingAgreementType(pricingAgreementType)
        }}
        onClickNext={async () => {
          await handleNextAgreementStep() 
        }}
      />
    )
  }

  const handleNextAgreementStep = async () => {
    const accountId = account!.id 
    const agreements = await accountStore.listAgreementsByAccount(accountId)
    let hasMasterServicesAgremement = false 
    let hasConfidentialityAgreement = false 
    let hasExclusiveAgreement = false 
    if (agreements && agreements.length > 0) {
      agreements.forEach((agreement: Agreement) => {
        if (agreement.hasAgreementType(APITypes.AgreementType.MasterServicesAndNonSolicitation)) {
          hasMasterServicesAgremement = true 
        } 
        if (agreement.hasAgreementType(APITypes.AgreementType.ConfidentialityAndNonDisclosure)) {
          hasConfidentialityAgreement = true 
        }
        if (agreement.hasAgreementType(APITypes.AgreementType.ExclusiveRecruitment)) {
          hasExclusiveAgreement = true 
        }
      })
    }
    
    if (userStore.isAdminOrAgent) {
      setStep(RequestStep.Summary)
    } else if (serviceRequestType === APITypes.ServiceRequestType.StaffingServices) {
      // If they are doing staffing services, they will need a proposal agreement sent by a GovGig agent, versus an MSA.
      if (!hasConfidentialityAgreement) {
        setStep(RequestStep.ConfidentialityAgreementSign)
      } else {
        setStep(RequestStep.Summary)
      }
    } else if (!pricingAgreementType) {
      if (hasExclusiveAgreement) {
        setPricingAgreementType(PricingAgreementType.Exclusive)
        // They already have the highest form of agreement, send them to the summary.
        if (!hasConfidentialityAgreement) {
          setStep(RequestStep.ConfidentialityAgreementSign)
        } else {
          setStep(RequestStep.Summary)
        }
      } else if (hasMasterServicesAgremement) {
        setPricingAgreementType(PricingAgreementType.Standard)
        if (!hasConfidentialityAgreement) {
          setStep(RequestStep.ConfidentialityAgreementSign)
        } else {
          // The first time through, show them the pricing selection, so they could potentially upgrade to exclusive. 
          setStep(RequestStep.PricingSelect) 
        }
      } else {
        setStep(RequestStep.PricingSelect)
      }
    } else if (pricingAgreementType === PricingAgreementType.Exclusive && !hasExclusiveAgreement) {
      setStep(RequestStep.ExclusiveMasterServicesAgreementSign)
    } else if (pricingAgreementType === PricingAgreementType.Standard && !hasMasterServicesAgremement) {
      setStep(RequestStep.MasterServicesAgreementSign)
    } else if (!hasConfidentialityAgreement) {
      setStep(RequestStep.ConfidentialityAgreementSign)
    } else {
      setStep(RequestStep.Summary)
    }
  }

  const renderMasterServicesAgreementSign = () => {
    return (
      <DialogAgreementPanel 
        formId={"msaForm"} 
        checkboxId={"msaCheckbox"} 
        agreementId={"msa"} 
        agreementTitle={"Master Services & Non-Solicitation Agreement"} 
        agreementRoute={Routes.msa} 
        agreementType={APITypes.AgreementType.MasterServicesAndNonSolicitation}
        instructions={[
          "Please review and accept the GovGig Master Services & Non-Solicitation Agreement.",
          // "You may press Skip, if you are only planning to create self-service job posts or are not able to complete this agreement on behalf of the company."
        ]}
        onClickBack={() => {
          setStep(RequestStep.PricingSelect)
        }}
        onClickNext={async () => {
          await handleNextAgreementStep() 
        }}
      />
    )
  }

  const renderExclusiveMasterServicesAgreementSign = () => {
    return (
      <DialogAgreementPanel 
        formId={"msaExclusiveForm"} 
        checkboxId={"msaExclusiveCheckbox"} 
        agreementId={"msaExclusive"} 
        agreementTitle={"Exclusive Master Services & Non-Solicitation Agreement"} 
        agreementRoute={Routes.msaExclusive} 
        agreementType={APITypes.AgreementType.ExclusiveRecruitment}
        instructions={[
          "Please review and accept the GovGig Exclusive Master Services & Non-Solicitation Agreement.",
          // "You may press Skip, if you are only planning to create self-service job posts or are not able to complete this agreement on behalf of the company."
        ]}
        onClickBack={() => {
          setStep(RequestStep.PricingSelect)
        }}
        onClickNext={async () => {
          await handleNextAgreementStep() 
        }}
      />
    )
  }

  const renderConfidentialityAgreementSign = () => {
    return (
      <DialogAgreementPanel 
        formId={"confidentialityAgreementForm"} 
        checkboxId={"confidentialityAgreementCheckbox"} 
        agreementId={"confidentialityAgreement"} 
        agreementTitle={"Confidentiality & Non-Disclosure Agreement"} 
        agreementRoute={Routes.nda} 
        agreementType={APITypes.AgreementType.ConfidentialityAndNonDisclosure}
        instructions={[
          "Please review and accept the GovGig Confidentiality and Non-Disclosure Agreement.",
          // "You may press Skip, if you are only planning to create self-service job posts or are not able to complete this agreement on behalf of the company."
        ]}
        onClickSkip={ serviceRequest!.serviceRequestType === APITypes.ServiceRequestType.StaffingServices ? () => {
          setStep(RequestStep.Summary)
        } : undefined }
        onClickBack={() => {
          setStep(RequestStep.PricingSelect)
        }}
        onClickNext={async () => {
          await handleNextAgreementStep() 
        }}
      />
    )
  }

  // const renderCalendarSelect = () => {
  //   return (
  //     <ServiceRequestCalendarForm 
  //       onClickBack={() => {
  //         // TODO: Check if they need to see the pricing step. 
  //         setStep(RequestStep.PricingSelect)
  //       }}
  //       onClickNext={async () => {

  //       }}
  //       onClickSkip={() => {
  //         setStep(RequestStep.Summary)
  //       }}
  //     />
  //   )
  // }

  const renderSummary = () => {
    return (
      <DialogPanel
        elevation={0}
        backgroundColor={theme.palette.grey[100]}
      >
        <DialogPanelInstructions
          title="Review Request"
          instructions={[
            "Review your request then press Send Request to send the request to our GovGig recruiting agents."
          ]}
        />
        <SectionPanel
          elevation={0}
          title="Service Request Info"
          button={
            <EditButton 
              onClick={() => {
                setStep(RequestStep.SelectType)
              }}
            />
          }
        >
          <ServiceRequestCard serviceRequest={serviceRequest!} />
        </SectionPanel>
        <SectionPanel
          elevation={0}
          title="Jobs"
          button={
            <Button
              variant="contained"
              color="secondary"
              onClick={() => {
                setJobPostId(undefined)
                setStep(RequestStep.EnterJob)
              }}
              startIcon={<Add />}
            >
              Add Job
            </Button>
          }
        >
          <ServiceRequestJobList 
            serviceRequest={serviceRequest!}
            onClickEditJobPost={(jobPostId: string) => {
              setJobPostId(jobPostId)
              setStep(RequestStep.EnterJob)
            }}
          />
        </SectionPanel>
        <SectionPanel
          elevation={0}
          title="Agreement(s)"
          button={
            <EditButton 
              onClick={() => {
                setStep(RequestStep.PricingSelect)
              }}
            />
          }
        >
          <ServiceRequestPricing 
            accountId={account!.id} 
            serviceRequest={serviceRequest!}
          />
        </SectionPanel>
        {/* <SectionPanel
          elevation={0}
          title="Meeting Scheduled"
          button={
            <EditButton 
              onClick={() => {
                setStep(RequestStep.CalendarSelect)
              }}
            />
          }
        >

        </SectionPanel> */}
        <DialogPanelButtons 
          buttonDisabled={false} 
          isProcessing={isProcessing} 
          onClickBack={() => {
            if (serviceRequestType === APITypes.ServiceRequestType.StaffingServices) {
              setStep(RequestStep.JobSummary)
            } else {
              setStep(RequestStep.PricingSelect)
            }
          }}
          buttonTitle="Send Request"
          onClickNext={async () => {
            setIsProcessing(true)
            const input: APITypes.UpdateServiceRequestInput = {
              id: serviceRequest!.id,
              status: ServiceRequestStatus.Submitted
            }
            const updatedServiceRequest = await jobStore.updateServiceRequest(input)
            setServiceRequest(updatedServiceRequest)
            setIsProcessing(false)
            setStep(RequestStep.Finished)
          }}
        />
        <RequestMeetingSection />
      </DialogPanel>
    )
  }

  const renderFinished = () => {
    return (
      <Stack
        direction="column"
        alignItems="center"
        spacing={3}
      >
        <Typography variant="h3">You're all set!</Typography>
        <Typography>We're looking forward to fulfilling your request, we'll be in touch!</Typography>
        <Button
          variant="contained"
          color="secondary"
          onClick={() => {
            ControlTower.route(Routes.dashboard)
          }}
        >
          Go to Dashboard 
        </Button>
      </Stack>
    )
  }

  const renderContent = () => {
    return (
      <Container maxWidth="md">
        <Stack
          direction="column"
          spacing={4}
          sx={{
            pb: 5 
          }}
        >
          <LinearProgress variant="determinate" value={progressPercentage} /> 
          { step === RequestStep.SelectAccount && renderSelectAccount() }
          { step === RequestStep.SelectType && renderSelectType() }
          { step === RequestStep.EnterJob && renderEnterJob() }
          { step === RequestStep.JobSummary && renderJobSummary() }
          { step === RequestStep.PricingSelect && renderPricingSelect() }
          { step === RequestStep.MasterServicesAgreementSign && renderMasterServicesAgreementSign() }
          { step === RequestStep.ExclusiveMasterServicesAgreementSign && renderExclusiveMasterServicesAgreementSign() }
          { step === RequestStep.ConfidentialityAgreementSign && renderConfidentialityAgreementSign() }
          {/* { step === RequestStep.CalendarSelect && renderCalendarSelect() } */}
          { step === RequestStep.Summary && renderSummary() }
          { step === RequestStep.Finished && renderFinished() }
        </Stack>
      </Container>
    )
  }

  const renderLoading = () => {
    return (
      <LoadingPanel />
    )
  }

  return (
    <Page
      title={title}
    >
      <PageTitle title={title} align="center" variant="h2" />
      { isLoading ? renderLoading() : renderContent() }
    </Page>
  )
}

export default ServiceRequestCreatePage
