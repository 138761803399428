import { Box, LinearProgress, Typography } from "@material-ui/core";
import AppBar from '@material-ui/core/AppBar';
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import { inject, observer } from "mobx-react";
import * as React from 'react';
import logo from '../../images/header_logo_640x192.png';
import UserStore from "../../stores/UserStore";
import Progress from "../Progress";
// import MarginRow from "./MarginRow";
import SimpleMenu from "./SimpleMenu";
import {makeObservable, observable} from "mobx";
import config from 'react-global-configuration';
import IndustryMenu from "./IndustryMenu";

const styles = (theme: Theme) => createStyles({
  appBar: {
    top: 0,
    backgroundColor: theme.palette.primary.contrastText,
    height: 76,
    maxHeight: 76
  },
  toolBar: {
    minHeight: 76,
    height: 76,
  },
  toolBarWrapper: {
    [theme.breakpoints.up('sm')] : {
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3)
    },
    [theme.breakpoints.down('sm')] : {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1)
    }
  },
  grow: {
    flexGrow: 1,
  },
  linkStyle: {
    marginBottom: '-5px',
    marginLeft: 5
  },
  appLogo: {
  },
  progressWrapper: {
    flexGrow: 1
  },
  progress: {
    height: 3,
    backgroundColor: "#d72222" // Logo red
  },
})

interface IHeaderProps {
  userStore?: UserStore
  progress?: Progress
}

@inject("userStore", "progress")
@observer
class Header extends React.Component<WithStyles<typeof styles> & IHeaderProps> {
  @observable test: boolean = false // Needs this fake observable variable for MobX to not complain. 

  constructor(props: WithStyles<typeof styles> & IHeaderProps) {
    super(props);
    makeObservable(this);
  }

  render() {
    const {classes, progress} = this.props

    const homeUrl = config.get("homeUrl")

    return (
      <AppBar position="fixed" className={classes.appBar}>
        {/* <MarginRow> */}
        <Box
          className={classes.toolBarWrapper}
        >
          <Toolbar className={classes.toolBar} disableGutters={true}>
            <a href={homeUrl} className={classes.linkStyle}>
              <img src={logo} className={classes.appLogo} alt="logo" style={{ width: 160, height: "auto" }} />
            </a>
            <Box
              sx={{
                pl: 1
              }}
            >
              <IndustryMenu />
            </Box>
            <Typography variant="h6" color="inherit" className={classes.grow} />
            <SimpleMenu />
          </Toolbar>
        </Box>
        {/* </MarginRow> */}
        <div className={classes.progressWrapper}>
          {<LinearProgress hidden={!progress!.isVisible} className={classes.progress} />}
        </div>
      </AppBar>
    )
  }
}

export default withStyles(styles)(Header)