import React from 'react';
import { Box, Typography, WithTheme, withTheme } from '@material-ui/core';
import { Storage } from 'aws-amplify';
import { makeObservable, observable, when } from 'mobx';
import { inject, observer } from 'mobx-react';
// import Profile from '../../model/Profile';
import { 
  IFileStorageLevel, 
  // IKeyGetter 
} from './FileDialog';
import Stack from '../Stack';
import ControlTower from '../ControlTower';
import ActionLink from '../ActionLink';
import ProfileStore from '../../stores/ProfileStore';

export interface IFilePreviewProps {
  // onChange?: (profile: Profile) => any
  title?: string 
  fileKey: string | null 
  emptyLabel?: string
  label?: string
  style?: object
  // profile?: Profile
  level: IFileStorageLevel
  // keyGetter: IKeyGetter
  onClickEmpty(): any 

  profileStore?: ProfileStore
}

@inject("profileStore")
@observer
class FilePreview extends React.Component<WithTheme & IFilePreviewProps> {
  @observable isFetching: boolean = false
  @observable url: string = ''
  @observable error: string = ''

  constructor(props: WithTheme & IFilePreviewProps) {
    super(props);
    makeObservable(this);
  }

  async componentDidMount() {
    when(
      () => Boolean(this.props.fileKey && !this.url && !this.isFetching),
      async () => {
        const { profileStore } = this.props 
        if (!this.props.fileKey) { return }
        try {
          this.error = ''
          // this.url = String(await Storage.get(this.props.fileKey, { level: this.props.level, download: false }))
          this.url = await profileStore!.getFileURL(this.props.fileKey, this.props.level)
        } catch (err: any) {
          this.error = err.message
        }
      }
    )
  }

  async componentDidUpdate(prevProps: any) {
    const { profileStore } = this.props 
    if (prevProps.fileKey !== this.props.fileKey) {
      if (!this.props.fileKey) {
        this.url = ''
      } else {
        // this.url = String(await Storage.get(this.props.fileKey, { level: this.props.level, download: false }))
        this.url = await profileStore!.getFileURL(this.props.fileKey, this.props.level)
      }
    }
  }

  renderPDF = () => {
    const { label, style, theme } = this.props 
    return (
      <iframe
        title="PDF Preview"
        style={{
          border: 'none',
          boxShadow: 'rgba(0, 0, 0, 0.15) 0px 1px 3px 0px', 
          borderRadius: theme.spacing(1),
          width: 150, // 8.5 x 11 ratio for full page
          height: 194,
          pointerEvents: 'none',
          ...style
        }}
        src={this.url}
      >
        <a href={this.url}>{label}</a>
      </iframe>
    )
  }

  renderOtherFile = () => {
    const { emptyLabel } = this.props 
    return (
      <img 
        src={this.url} 
        alt={emptyLabel} 
        style={{ 
          maxWidth: '100%' 
        }} 
      />
    )
  }

  renderEmpty = () => {
    const { emptyLabel, theme } = this.props
    return (
      <Box 
        bgcolor='#ddd'
        sx={{ 
          boxShadow: 'rgba(0, 0, 0, 0.15) 0px 1px 3px 0px', 
          borderRadius: theme.spacing(1),
          width: 150, 
          height: 194, 
          // paddingTop: 85, 
          textAlign: "center", 
          fontSize: 16,
          paddingTop: theme.spacing(3)
        }}
      >
        <Typography>
          {emptyLabel}
        </Typography>
      </Box>
    )
  }

  renderContent = () => {
    const { onClickEmpty } = this.props 
    return (
      <Stack
        direction="row"
        spacing={2}
        wrap='nowrap'
      >
        <Box
          sx={{ 
            width: 'fit-content',
          }}
          style={{
            cursor: 'pointer' // TODO: Move to sx prop in MUI v5. 
          }}
          onClick={() => {
            if (this.url) {
              ControlTower.open(this.url)
            } else {
              onClickEmpty()
            }
          }}
        >
          { this.url
            ? 
              /\.pdf/.test(this.url)
              ? 
                this.renderPDF() 
              : 
                this.renderOtherFile() 
            : 
              this.renderEmpty()
          }
        </Box>
      </Stack>
    )
  }

  render() {
    const { title } = this.props 
    if (this.error) {
      return (
        <Typography style={{ color: 'red' }}>
          {this.error}
        </Typography>
      )
    }

    return (
      <Box>
        {this.renderContent()}
      </Box>
    )
  }
}

export default withTheme(FilePreview)
