
export enum NAICS {
  Construction = "23",
  InformationTechnology = "54",
  SecurityServices = "5616"
}

class Industry {
  level: number
  code: string
  name: string

  constructor (data: any) {
    this.level = data.level ?? data.code.length ?? 0
    this.code = data.code
    this.name = data.name
  }

  static stringForNAICSCode(code: NAICS): string | null {
    if (code === NAICS.Construction) {
      return "Construction"
    } else if (code === NAICS.InformationTechnology) {
      return "Information Technology"
    } else if (code === NAICS.SecurityServices) {
      return "Physical Security"
    } else {
      return null 
    }
  }
}

export default Industry