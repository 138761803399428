import { observable, makeObservable } from "mobx";

class Progress {
  @observable isVisible: boolean = false
  name: string = ''

  show = (name: string) => {
    this.isVisible = true
    this.name = name
  }

  hide = (name: string) => {
    this.isVisible = false
  }

  constructor() {
    makeObservable(this);
  }
}

export default Progress