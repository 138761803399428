import { Box } from "@material-ui/core"
import { PropsWithChildren } from "react"

const PageHeadingPanel = ({
  embed,
  children  
}: {
  embed: boolean 
} & PropsWithChildren<any>) => {
  return (
    <Box 
      py={{
        xs: 2, 
        md: 3
      }} 
      px={{ 
        xs: 2, 
        md: 3
      }} 
      bgcolor="common.white" 
      // border={0} 
      // borderColor="grey.400" 
      borderRadius={ embed ? 0 : 10 }
      mb={1}
    >
      {children}
    </Box>
  )
}

export default PageHeadingPanel
