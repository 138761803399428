import * as React from "react";
import {Box, createStyles, Theme, Typography, withStyles, WithStyles} from "@material-ui/core";
import UserStore from "../../stores/UserStore";
import { inject, observer } from "mobx-react";
import { NAICS } from "../../model/Industry";

import constructionBanner from '../../images/EmployerApply_Construction.png';
import itBanner from '../../images/ForEmployersCard.png'
import banner from "../../images/EmployerApply_All.png"

import { autorun, makeObservable, observable, when } from "mobx";

const styles = (theme: Theme) => createStyles({
  formTitle: {
    [theme.breakpoints.down('xs')]: {
      fontSize: '28px',
      marginBottom: 10
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: '32px',
      marginBottom: 30
    }
  },
  itemHeading: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    fontWeight: 600,
    color: theme.palette.primary.main 
  },
  image: {
    width: '70%',
    borderRadius: theme.spacing(2),
    marginBottom: theme.spacing(3),
  },
});

interface ISignupPromoProps {
  onJoin?: any
  // promo?: string
  userStore?: UserStore
}

@inject("userStore")
@observer
class SignupPromo extends React.Component<WithStyles<typeof styles> & ISignupPromoProps> {

  @observable industry?: string  

  constructor(props: WithStyles<typeof styles> & ISignupPromoProps) {
    super(props)
    makeObservable(this)
  }

  componentDidMount(): void {
    const { userStore } = this.props 

    const init = () => {
      const industry = userStore!.getIndustry()
      // console.debug("SignupPromo.componentDidMount when", industry)
      this.industry = industry 
    }

    when(
      () => userStore!.isLoading === false, 
      () => {
        init() 
      }
    )

    autorun(
      () => {
        if (this.industry !== userStore!.industry) {
          init() 
        }
      }
    )
  }

  onJoin = () => {
    if (this.props.onJoin) {
      this.props.onJoin()
    }
  }

  render() {
    const {classes, userStore} = this.props

    let title
    if (this.industry === NAICS.Construction) {
      title = "Find Qualified Construction Talent, Fast"
    } else if (this.industry === NAICS.InformationTechnology) {
      title = "Find Qualified IT Talent, Fast"
    } else {
      title = "Find Qualified Talent, Fast"
    }

    return (
      <Box borderRadius={10} pt={3} pb={5} bgcolor="grey.50" px={{ xs: 3, sm: 5 }}>
        <Typography align="left" variant="h1" color="primary" className={ classes.formTitle }>
          {title}
        </Typography>
        <Box
          width="100%"
          sx={{
            flex: 1,
            flexGrow: 1, 
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start"
          }}
        >
          <img src={ this.industry === NAICS.Construction ? constructionBanner : this.industry === NAICS.InformationTechnology ? itBanner : banner } alt="" className={classes.image}/>
        </Box>
        <Typography variant="body1" gutterBottom={true}>
          { this.industry === "" && // The All insdustries case. 
            "GovGig currently offers talent acquisition and professional services for Federal contractors in the construction and IT industry. Be on the lookout for new verticals soon!"
          }
          { this.industry === NAICS.Construction && 
            "GovGig offers talent acquisition and professional services for Federal construction projects across the country. Our founder has 15+ years’ experience with a particular specialty in electrical grid distribution and substations. We are specially qualified to meet your project needs to help you succeed."
          }
          { this.industry === NAICS.InformationTechnology && 
            "GovGig now offers talent acquisition and professional services for Federal contractors in the information technology industry. Our nation is a the cutting edge, and GovGig is here to quickly fill roles with qualified, cleared professionals so you can keep driving innovation."
          }
        </Typography>
        <Typography variant="h4" gutterBottom={true} className={classes.itemHeading}>
          Focused
        </Typography>
        <Typography variant="body1" gutterBottom={true}>
          GovGig is 100% focused on government-related work - we're professionals in the space.
        </Typography>
        <Typography variant="h4" gutterBottom={true} className={classes.itemHeading}>
          Fast
        </Typography>
        { this.industry === "" && 
          <Typography variant="body1" gutterBottom={true}>
            Whether you need a Safety Officer in San Diego or a remote Cloud Architect, GovGig can fill your needs with qualified, hand-select personnel. Our thousands of candidates are pre-vetted and eager to work, so we can get boots on the ground as quickly as possible.
          </Typography>
        }
        { this.industry === NAICS.Construction && 
        <Typography variant="body1" gutterBottom={true}>
          Need a Safety Officer in San Diego tomorrow? Your job can't wait. Fill emergency positions, fast.
        </Typography>
        }
        { this.industry === NAICS.InformationTechnology && 
          <Typography variant="body1" gutterBottom={true}>
          Need a Cloud Architect yesterday? Your project can&apos;t wait. GovGig can mine its extensive candidate pool to find you qualified candidates, ready to work <i>today</i>.
          </Typography>
        }
        <Typography variant="h4" gutterBottom={true} className={classes.itemHeading}>
          Flexible Search 
        </Typography>
        <Typography variant="body1" gutterBottom={true}>
          Simple, powerful search - search by geography, qualification, position, and other criteria.
        </Typography>
        <Typography variant="h4" gutterBottom={true} className={classes.itemHeading}>
          Follow and Track 
        </Typography>
        <Typography variant="body1" gutterBottom={true}>
          Save & track interesting candidates - manage them in one place.
        </Typography>

        <Typography variant="h4" gutterBottom={true} className={classes.itemHeading}>
          How it Works 
        </Typography>
        <ul>
          <li>
            <Typography variant="body1" gutterBottom={true}>
              Create your account - takes just a few minutes. 
            </Typography>
          </li>
          <li>
            <Typography variant="body1" gutterBottom={true}>
              Select your industry, such as Construction or Information Technology, or multiple options. 
            </Typography>
          </li>
          <li>
            <Typography variant="body1" gutterBottom={true}>
              Choose to post jobs yourself with our Self-service option. Or, get some help with our Recruiting Services options. 
            </Typography>
          </li>
          <li>
            <Typography variant="body1" gutterBottom={true}>
              When we activate your account, you'll be up and running and hiring in no time! 
            </Typography>
          </li>
          <li>
            <Typography variant="body1" gutterBottom={true}>
              We offer other professional services beyond hiring, such as help with in the world Federal contracting, such as Requests for Equitable Adjustment (REA).
            </Typography>
          </li>
          <li>
            <Typography variant="body1" gutterBottom={true}>
              Work with our agents for anything you need to make your recruiting process as smooth as possible! 
            </Typography>
          </li>
        </ul>
      </Box>
    )
  }
}

export default withStyles(styles)(SignupPromo)