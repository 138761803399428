import * as React from "react";
import {Box} from "@material-ui/core";

interface IAnswerCardProps {
  children: any
}

const AnswerCard = (props: IAnswerCardProps) => {

  return (
    <Box >
      <Box border="1px solid rgb(118,118,118)" p={1} minHeight={120}>
        {props.children}
      </Box>
    </Box>
  )
}

export default AnswerCard