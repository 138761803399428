import React, { CSSProperties } from "react";
import { Draggable, DraggableProvided, DraggableRubric, DraggableStateSnapshot } from "react-beautiful-dnd";
import { IKanbanColumn } from "./KanbanBoard";
import KanbanCard from "./KanbanCard";

// Memoizing row items for even better performance!
const KanbanRow = React.memo(({ 
  index, 
  style, 
  data
}: {
  index: number
  style?: CSSProperties
  data: any 
}) => {
  const { column, options } = data 
  const row = (column as IKanbanColumn).rows[index]

  // We are rendering an extra item for the placeholder to show a visual gap on drop.
  // Too do this we increased our data set size to include one 'fake' item, so return null for the fake item.
  if (!row) {
    return null 
  }
  
  const checkIsDragDisabled = () => {
    let isDragDisabled = false 
    
    if (options.isDragDisabled !== undefined) {
      isDragDisabled = options.isDragDisabled!(row)
    } else if (column.isDragDisabled !== undefined) {
      isDragDisabled = column.isDragDisabled!
    } 

    return isDragDisabled 
  }

  return (
    <Draggable 
      draggableId={row.id} 
      index={index} 
      key={row.id}
      isDragDisabled={checkIsDragDisabled()}
    >
      {(provided: DraggableProvided, snapshot: DraggableStateSnapshot, rubric: DraggableRubric) => {
        return (
          <KanbanCard 
            innerRef={provided.innerRef}
            row={row}
            options={options}
            virtualStyle={style}
            provided={provided}
            snapshot={snapshot}
          />
        )
      }}
    </Draggable>
  );
})
// }, areEqual);

export default KanbanRow 
