import React from "react";
import {
  Box,
  TextField,
  withWidth,
  WithWidth
} from "@material-ui/core";
import { createStyles, Theme, WithStyles, WithTheme } from "@material-ui/core";
import { withStyles, withTheme } from "@material-ui/core/styles";
import { Autocomplete } from "@material-ui/lab";
import { makeObservable, observable, when } from "mobx";
import { inject, observer } from "mobx-react";
import UserStore from "../../stores/UserStore";
import ResourceCache from "../../stores/ResourceCache";
import CertificationType from "../../model/CertificationType";

const styles = (theme: Theme) => createStyles({
  filterTextField: {
    flexGrow: 1, 
    backgroundColor: theme.palette.background.paper,
    borderRadius: 10
  },
})

export interface ICertificationFilterProps {
  onSelectCertificationType: (certificationType?: string) => any
  name?: string 
  label?: string 
  value?: string
  industry?: string
  freeSolo?: boolean
  fullWidth?: boolean
  variant?: 'filled' | 'outlined' | 'standard'
  formvalidator?: any
  required?: boolean
  margin?: 'dense' | 'none' | 'normal'
  helperText?: string

  userStore?: UserStore
  resourceCache?: ResourceCache
}

@inject("userStore", "resourceCache", "formvalidator")
@observer
class CertificationFilter extends React.Component<WithStyles<typeof styles> & WithTheme & ICertificationFilterProps & WithWidth> {

  @observable isLoading: boolean = true 
  @observable certificationFilter?: string
  @observable isValid: boolean = true
  @observable industry?: string
  @observable certificationTypes: string[] = []

  constructor(props: any) {
    super(props)
    makeObservable(this)
  }

  componentDidMount() {
    const { resourceCache, userStore, value, industry, formvalidator } = this.props
    if (formvalidator) {
      formvalidator.attachToForm(this)
    }

    when(
      // once...
      () => resourceCache!.isLoading === false && userStore!.isLoading === false,
      // ... then
      async () => {
        this.industry = industry !== undefined ? industry : userStore!.industry
        this.certificationFilter = value
        this.certificationTypes = resourceCache!.getCertificationTypes(this.industry).map(({ name }) => name)
        this.isLoading = false 
      }
    )
  }

  componentDidUpdate(prevProps: any) {
    const { userStore, value, industry } = this.props

    if (prevProps.value !== value) {
      this.certificationFilter = this.props.value
    }
    const newIndustry = industry !== undefined ? industry : userStore!.industry
    if (this.industry !== newIndustry) {
      this.industry = newIndustry
    }
  }

  render() {
    const { 
      classes, 
      freeSolo, 
      fullWidth,
      required, 
      helperText, 
      resourceCache 
    } = this.props

    if (this.isLoading === true) {
      return null 
    }

    const variant = this.props.variant ?? 'outlined'
    const margin = this.props.margin ?? 'none'

    return (
      <Box
        width={ fullWidth ? "100%" : "200px" }
      >
        <Autocomplete
          autoSelect
          freeSolo={true}
          includeInputInList
          options={this.certificationTypes}
          onChange={this.onSelectCertificationType}
          value={this.certificationFilter ?? null}
          renderInput={(params: any) =>
            <div ref={params.InputProps.ref}>
              <TextField
                {...params}
                className={classes.filterTextField}
                variant={variant}
                margin={margin}
                size="small"
                name="certificationType"
                label="Certification Type"
                required={required}
                helperText={helperText}
              />
            </div>
          }
        />
      </Box>
    )
  }

  validate = (value: string): boolean => {
    const isRequired = this.props.required ?? true
    this.isValid = !isRequired || (this.certificationFilter !== undefined)
    return this.isValid
  }

  onSelectCertificationType = (event: any, value: any, reason: string) => {
    this.certificationFilter = value
    this.props.onSelectCertificationType(this.certificationFilter)
  }
}

export default withTheme(withStyles(styles)(withWidth()(CertificationFilter)))