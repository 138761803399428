import { createStyles, Grid, isWidthDown, Theme, withStyles, WithStyles, WithWidth, withWidth } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import { RouteComponentProps } from '@reach/router';
import { makeObservable, observable } from 'mobx';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import CardValue from '../../../components/CardValue';
import IconicButton from '../../../components/controls/IconicButton';
import { phoneToNationalFormat } from '../../../stores/StoreUtilities';
import UserStore from '../../../stores/UserStore';
import ProfileCard from '../../profile/ProfileCard';
import UserSettingsDialog from './UserSettingsDialog';
import User from "../../../model/User";

const styles = (theme: Theme) => createStyles({
})

interface IUserSettingsProps {
  user: User
  userStore?: UserStore
  route?: string
}

@inject('userStore')
@observer
class UserSettings extends React.Component<WithStyles<typeof styles> & WithWidth & RouteComponentProps & IUserSettingsProps> {
  @observable userSettingsOpen = false

  constructor(
    props: WithStyles<typeof styles> & WithWidth & RouteComponentProps & IUserSettingsProps
  ) {
    super(props)
    makeObservable(this)
  }

  handleClose = () => {
    this.userSettingsOpen = false
  }

  renderStaticFields() {
    const { width, userStore } = this.props

    const labelStyle = { flex: "0 0 100px" }
    const user = userStore!.user!

    if (!user) {
      return null
    }

    return (
      <div>
        <Grid
          container
          spacing={isWidthDown('xs', width) ? 0 : 5}
        >
          <Grid item xs={12} sm={6}>
            <Grid container direction="column" justifyContent="flex-start">
              <CardValue label="First Name" {...{ labelStyle }}>
                {user.firstName}
              </CardValue>
              <CardValue label="Last Name" {...{ labelStyle }}>
                {user.lastName}
              </CardValue>
              <CardValue label="Email" {...{ labelStyle }}>
                {user.email}
              </CardValue>
              <CardValue label="Phone" {...{ labelStyle }}>
                {phoneToNationalFormat(user.phone)}
              </CardValue>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6}>
            <CardValue label="Address" {...{ labelStyle }}>
              {user.address}
            </CardValue>
            <CardValue label="City, State, ZIP" {...{ labelStyle }}>
              {user.city}, {user.state} {user.postalCode}
            </CardValue>
          </Grid>
        </Grid>
      </div>
    )
  }

  render() {
    const { user } = this.props

    return (
      <Grid
        container
        justifyContent="flex-start"
        alignItems="flex-start"
        spacing={3}
      >
        <Grid item xs={12}>
          <ProfileCard title="User Settings" button={
            <IconicButton onClick={() => this.userSettingsOpen = true}>
              <EditIcon />
            </IconicButton>
          }>
            {this.renderStaticFields()}
          </ProfileCard>
        </Grid>

        {this.userSettingsOpen &&
          <UserSettingsDialog
            open={true}
            onClose={this.handleClose}
            user={user}
          />
        }
      </Grid>
    )
  }
}

export default withStyles(styles)(withWidth()(UserSettings))
