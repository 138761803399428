import React from "react";
import {
  Collapse,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Theme,
  Typography,
  withStyles,
  WithStyles,
  withTheme,
  WithTheme,
  withWidth,
  WithWidth
} from "@material-ui/core";
import { observer } from "mobx-react";
import { autorun, observable, makeObservable } from "mobx";
import ProgressButton from "../form/ProgressButton";
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Checkbox from '@material-ui/core/Checkbox';
import Logger from "../Logger";
import CancelButton from "../form/CancelButton";

const styles = (theme: Theme) => createStyles({
  container: {
    flex: 1
  },
  listBackground: {
  },
  progressButton: {
    minWidth: 80
  },
  list: {
    backgroundColor: theme.palette.background.paper,
  },
  listItem: {
    paddingRight: 0
  },
  listItemTextHeader: {
    fontWeight: 'bold',
    color: theme.palette.primary.main
  },
  listItemText: {
    fontWeight: 300,
    fontSize: theme.typography.h5.fontSize
  },
  checkboxContainer: {
    minWidth: 0
  },
  checkbox: {

  },
  expandButton: {
    "&:focus,&:hover": {
      color: theme.palette.common.black
    }
  }
})

export interface IListHeaderViewModelOptions {
  id: string
  title: string
  isCheckable: boolean
  isExpandable: boolean
}

export class ListHeaderViewModel {
  id: string 
  title: string
  isCheckable: boolean
  isExpandable: boolean
  constructor(data: IListHeaderViewModelOptions) {
    this.id = data.id
    this.title = data.title
    this.isCheckable = data.isCheckable
    this.isExpandable = data.isExpandable
  }
}

export interface IListItemViewModelOptions {
  id: string
  title: string
  isCheckable: boolean
  isChecked: boolean 
}

export class ListItemViewModel {
  id: string 
  title: string
  isCheckable: boolean
  isChecked: boolean 
  constructor(data: IListItemViewModelOptions) {
    this.id = data.id
    this.title = data.title
    this.isCheckable = data.isCheckable
    this.isChecked = data.isChecked
  }
}

export interface IListSectionViewModelOptions {
  header: ListHeaderViewModel
  sections?: ListSectionViewModel[]
  items?: ListItemViewModel[]
}

export class ListSectionViewModel {
  header: ListHeaderViewModel 
  sections?: ListSectionViewModel[]
  items?: ListItemViewModel[]
  constructor(data: IListSectionViewModelOptions) {
    this.header = data.header
    this.items = data.items
    this.sections = data.sections
  }
}

export interface ISelectionsDialogViewModelOptions {
  isDialogOpen: boolean
  title: string
  helpText: string
  sections: ListSectionViewModel[]
}

export class SelectionsDialogViewModel {
  isDialogOpen: boolean
  title: string
  helpText: string
  sections: ListSectionViewModel[]
  constructor(data: ISelectionsDialogViewModelOptions) {
    this.isDialogOpen = data.isDialogOpen
    this.title = data.title
    this.helpText = data.helpText
    this.sections = data.sections
  }
}

export interface ISelectionsDialogProps {
  viewModel: SelectionsDialogViewModel
  onClose: any
  onSubmitSelections?: any
  onSubmitSingleSelection?: any 
  isProcessing: boolean
}

enum HeaderCheckedState {
  Checked,
  Unchecked,
  Indeterminate 
}

@observer
class SelectionsDialog extends React.Component<WithStyles<typeof styles> & WithTheme & WithWidth & ISelectionsDialogProps> {

  @observable viewModel?: SelectionsDialogViewModel
  @observable isHeaderExpanded: Map<string, boolean> = new Map()
  @observable isHeaderChecked: Map<string, HeaderCheckedState> = new Map()
  @observable isItemChecked: Map<string, boolean> = new Map() 

  constructor(props: any) {
    super(props)

    makeObservable(this);

    this.initializeValues()
    // this.loadCheckedValues() 
    // this.loadExpandedValues()
  }

  componentDidMount() {
    autorun(() => {
      if (this.props.viewModel.isDialogOpen === true) {
        this.initializeValues() 
      }
    })
  }

  render() {
    const {
      classes,
      viewModel
    } = this.props

    return (
      <Dialog
        open={viewModel.isDialogOpen}
        onClose={this.props.onClose}
        // className={classes.dialog}
        maxWidth={'sm'}
        fullWidth={true}
      >
        <DialogTitle 
          id="form-dialog-title" 
        >
          {viewModel.title}
        </DialogTitle>
        <Divider />
        <DialogContent>
          <DialogContentText>
            {viewModel.helpText}
          </DialogContentText>
          {this.renderSelections()}
        </DialogContent>
        <Divider />
        <DialogActions>
          <CancelButton onClick={this.props.onClose} />
          <ProgressButton
            variant="contained"
            size="medium"
            color="secondary"
            type="submit"
            className={classes.progressButton}
            processing={this.props.isProcessing}
            onClick={this.handleSubmit}
          >
            Save
          </ProgressButton>
        </DialogActions>
      </Dialog>
    )
  }

  /**
   * Each time the dialog is opened, automatically open sections if any sub-sections or items are checked. 
   * Trace through child sections/items and, if any are checked, expand the section. 
   */
  initializeValues = () => {
    const { viewModel } = this.props 
    viewModel!.sections.forEach(section => {
      const values = this.isCheckedTrace(section)
      this.updateHeaderState(section, values)
    })
  }
  
  updateHeaderState = (section: ListSectionViewModel, values: boolean[]) => {
    const isAllTrue = values.every(value => value === true)
    const isAllFalse = values.every(value => value === false)
    const headerState = isAllTrue ? HeaderCheckedState.Checked : isAllFalse ? HeaderCheckedState.Unchecked : HeaderCheckedState.Indeterminate
    const header = section.header 
    
    if (header.isCheckable) {
      this.isHeaderChecked.set(header.id, headerState)
    }

    if (header.isExpandable && this.isHeaderExpanded.get(header.id) === undefined) {
      if (isAllTrue || !isAllFalse) {
        this.isHeaderExpanded.set(header.id, true)
      } else {
        this.isHeaderExpanded.set(header.id, false)
      }
    }
  }

  isCheckedTrace = (section: ListSectionViewModel): boolean[] => {
    if (section.sections && section.sections.length > 0) {
      let subSectionValues: boolean[] = []
      section.sections.forEach(subSection => {
        const values = this.isCheckedTrace(subSection)
        this.updateHeaderState(subSection, values)
        subSectionValues = subSectionValues.concat(values)
      })
      return subSectionValues
    } else {
      const values = section.items!.map(item => {
        let isChecked = this.isItemChecked.get(item.id)
        if (isChecked === undefined) {
          isChecked = item.isChecked
          this.isItemChecked.set(item.id, isChecked)
        }
        return isChecked
      })
      return values 
    }
  }

  renderSelections() {
    const {
      classes,
      viewModel
    } = this.props

    return (
      <div className={classes.listBackground}>
        {viewModel.sections.map(section => {
          return this.renderSection(section, 0)
        })}
      </div>
    )
  }

  renderSection = (section: ListSectionViewModel, level: number) => {
    const {
      classes 
    } = this.props 

    const isOpen = this.isHeaderExpanded.get(section.header.id) ?? false

    return (
      <List
        key={section.header.id}
        dense
        disablePadding
        component="nav"
        aria-labelledby="nested-list-subheader"
        className={classes.list}
      >
        {this.renderHeaderItem(section, level)}
        <Collapse 
          in={isOpen} 
          timeout="auto" 
          unmountOnExit
        >
          { // If a section of sections, render the sub-sections. 
          section.sections && section.sections.length > 0 ?
            section.sections.map(subSection => {
              return this.renderSection(subSection, level + 1)
            })
            : 
          // Otherwise, if a section containing items, render the items. 
          section.items && section.items.length > 0 ?
            section.items.map(item => {
              return this.renderItem(item, section, level + 1)
            })
            : 
          null}
        </Collapse>
      </List>
    )
  }

  renderHeaderItem = (section: ListSectionViewModel, level: number = 0) => {
    const { classes, theme } = this.props 
    const header = section.header
    const labelId = `checkbox-list-label-${header.id}`;
    
    let checkbox = null 
    let isChecked = false 
    const headerState = this.isHeaderChecked.get(header.id)
    if (header.isCheckable) {
      if (headerState === undefined) {
        Logger.error('SelctionsDialog', 'Undefined header checked state')
      }
      if (headerState === HeaderCheckedState.Checked || headerState === HeaderCheckedState.Indeterminate) {
        isChecked = true 
      }
      checkbox = <ListItemIcon 
        className={classes.checkboxContainer} 
        // style={{ paddingLeft: theme.spacing(2 + level) }} 
      >
        <Checkbox
          color="primary"
          edge="start"
          checked={isChecked}
          indeterminate={headerState === HeaderCheckedState.Indeterminate}
          tabIndex={-1}
          disableRipple
          inputProps={{ 'aria-labelledby': labelId }}
          // onClick={this.handleChecked(item.id)}
          className={classes.checkbox}
          // style={{ paddingLeft: theme.spacing(1 + level) }}
        />
      </ListItemIcon>
    }
    
    let expand = null 
    if (header.isExpandable) {
      if (this.isHeaderExpanded.get(header.id) ?? false) {
        expand = <IconButton color="primary" aria-label="collapse">
          <ExpandLess className={classes.expandButton} />
        </IconButton> 
      } else {
        expand = <IconButton color="primary" aria-label="expand">
          <ExpandMore className={classes.expandButton} />
        </IconButton> 
      }
    }

    return (
      <ListItem 
        key={header.id} 
        role={undefined} 
        dense 
        button 
        onClick={checkbox ? this.handleToggleCheckedHeader(header, section) : this.handleToggleExpandHeader(header, section)}
        className={classes.listItem}
        // ContainerProps={{ style: { paddingLeft: theme.spacing(1 + level) } }}
        style={{ paddingLeft: theme.spacing(2 + level) }}
        // disableGutters
        divider
      >
        {checkbox}
        <ListItemText 
          id={labelId} 
          disableTypography
          primary={<Typography className={classes.listItemTextHeader}>{header.title}</Typography>}
          // primary={`${item.title}`}         
          // style={{ fontWeight: 800 }}
          // primaryTypographyProps={{ variant: 'h5' }}
        />
        {expand && <ListItemSecondaryAction
          onClick={this.handleToggleExpandHeader(header, section)}
        >
          {expand}
        </ListItemSecondaryAction>}
      </ListItem>
    )
  }

  renderItem = (item: ListItemViewModel, section: ListSectionViewModel, level: number = 0) => {
    const { classes, theme } = this.props 
    const labelId = `checkbox-list-label-${item.id}`;

    let checkbox = null 
    if (item.isCheckable) {
      let isChecked = this.isItemChecked.get(item.id)
      if (isChecked === undefined) {
        Logger.error('SelctionsDialog', 'Undefined checkbox state')
      }
      checkbox = <ListItemIcon 
        className={classes.checkboxContainer} 
        // style={{ paddingLeft: theme.spacing(2 + level) }} 
      >
        <Checkbox
          color="primary"
          edge="start"
          checked={isChecked}
          tabIndex={-1}
          disableRipple
          inputProps={{ 'aria-labelledby': labelId }}
          // onClick={this.handleChecked(item.id)}
          className={classes.checkbox}
          // style={{ paddingLeft: theme.spacing(1 + level) }}
        />
      </ListItemIcon>
    }

    return (
      <ListItem 
        key={item.id} 
        role={undefined} 
        dense 
        button 
        onClick={checkbox ? this.handleToggleChecked(item, section) : undefined}
        className={classes.listItem}
        // ContainerProps={{ style: { paddingLeft: theme.spacing(1 + level) } }}
        style={{ paddingLeft: theme.spacing(2 + level) }}
        // disableGutters
        divider
      >
        {checkbox}
        <ListItemText 
          id={labelId} 
          disableTypography
          primary={<Typography className={classes.listItemText}>{item.title}</Typography>}
          // primary={`${item.title}`}         
          // style={{ fontWeight: 800 }}
          // primaryTypographyProps={{ variant: 'h5' }}
        />
      </ListItem>
    )
  }

  handleToggleExpandHeader = (header: ListHeaderViewModel, section: ListSectionViewModel) => () => {
    const isOpen = this.isHeaderExpanded.get(header.id) ?? false 
    this.isHeaderExpanded.set(header.id, !isOpen)
  }

  handleToggleCheckedHeader = (header: ListHeaderViewModel, section: ListSectionViewModel) => () => {
    let checkedState = this.isHeaderChecked.get(header.id)
    
    if (checkedState === HeaderCheckedState.Checked || checkedState === HeaderCheckedState.Indeterminate) {
      this.isHeaderChecked.set(header.id, HeaderCheckedState.Unchecked)
      section.items?.forEach(item => {
        this.isItemChecked.set(item.id, false)
      })
    } else {
      this.isHeaderChecked.set(header.id, HeaderCheckedState.Checked)
      section.items?.forEach(item => {
        this.isItemChecked.set(item.id, true)
      })
    }
  }

  handleToggleChecked = (item: ListItemViewModel, section: ListSectionViewModel) => () => {
    let isChecked = this.isItemChecked.get(item.id)
    if (isChecked === undefined) {
      Logger.error('isChecked undefined!')
      isChecked = item.isChecked 
    }
    // Toggle the value. 
    isChecked = !isChecked
    this.isItemChecked.set(item.id, isChecked)

    const values = this.isCheckedTrace(section)
    this.updateHeaderState(section, values)
  }
  
  handleSubmit = () => {
    let selectedIds: string[] = []
    this.isItemChecked.forEach((value, key)=> {
      if (value === true) {
        selectedIds.push(key)
      }
    })
    this.props.onSubmitSelections(selectedIds)
  }
}

export default withTheme(withStyles(styles)(withWidth()(SelectionsDialog)))