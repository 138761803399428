import {
  Box,
  createStyles,
  Grid,
  isWidthDown,
  Theme,
  Typography,
  withStyles,
  WithStyles,
  withTheme,
  WithTheme, withWidth, WithWidth
} from "@material-ui/core";
import {inject, observer} from "mobx-react";
import React from "react";
import Profile from "../../model/Profile";
import UserStore from "../../stores/UserStore";
import ProfileCard from "./ProfileCard";
import IconicButton from "../../components/controls/IconicButton";
import {makeObservable, observable} from "mobx";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import Visible from "../../components/Visible";

const styles = (theme: Theme) => createStyles({
  container: {
    paddingTop: theme.spacing(0),
    paddingBottom: theme.spacing(2),
    color: theme.palette.primary.main
  },
  list: {
    marginTop: theme.spacing(1),
    fontSize: 15
  },
})

export interface IProfileFAQsProps {
  profile?: Profile
  userStore?: UserStore
}

@inject('userStore')
@observer
class ProfileFAQs extends React.Component<WithStyles<typeof styles> & WithTheme & WithWidth & IProfileFAQsProps> {

  @observable isExpanded = false

  constructor(props: any) {
    super(props)
    makeObservable(this)
  }

  renderInstructions = () => {
    const {
      width,
      classes
    } = this.props

    return (
      <Grid
        container
        direction={isWidthDown('xs', width) ? "column" : "row"}
        justifyContent="flex-start"
        alignItems="flex-start"
        spacing={3}
      >
        <Grid item xs={12}>
          <Typography variant="body1">
            Here are some frequently asked questions and tips about GovGig profiles!
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography variant="h6">
            What will employers be able to see about me?
          </Typography>
          <ul className={classes.list}>
            <li>First and last initial</li>
            <li>Years of experience<br/>
              <em>(Federal and overall work experience)</em>
            </li>
            <li>Positions that you are applying for and are qualified for</li>
            <li>Locations where you want to work, and/or Remote.</li>
            <li>“About” bio</li>
            <li>Security clearance and clearance status.</li>
            <li>Certifications you have listed and/or uploaded.</li>
            <li><u>Your resume and contact information are only provided to an employer after you apply to their job post and they request to unlock your profile.</u></li>
          </ul>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography variant="h6">
            Why is “About” Information important?
          </Typography>
          <ul className={classes.list}>
            <li>“About” information is a summary of your resume.</li>
            <li>“About” information is the first thing a hiring manager reads prior to viewing your resume.</li>
            <li>Providing no “About” information or too little information in your GovGig profile may result in a hiring manager never viewing your resume.</li>
          </ul>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography variant="h6">
            How do I improve the visibility of my profile to employers?
          </Typography>
          <ul className={classes.list}>
            <li>Include all your relevant work experience and dates.</li>
            <ul>
              <li>Be sure to indicate your federal work experience by setting the Agency Type as Federal.</li>
            </ul>
            <li>Be Available!</li>
            <ul>
              <li>Set your Availability in the Personal Information section.</li>
              <li>Indicate more cities where you want work to show up on the map. GovGig searches a radius of about 50 miles for matching profiles.</li>
            </ul>
          </ul>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography variant="h6">
            How do I improve my "About" information?
          </Typography>
          <ul className={classes.list}>
            <li>Your privacy is Important!</li>
            <ul>
            <li>Please keep your contact information out of your About section for your privacy. Only vetted and approved recruiters and employers can unlock your profile to see your contact info when you apply for a job post.</li>
              <li>Do not include your name or any personal identifiers.</li>
              <li>Do not include the names of past employers or companies.</li>
            </ul>
            <li>Ideas for what you want to include:</li>
            <ul>
              <li>State the <u>years of experience</u> you have in federal and/or non-federal work.</li>
              <ul>
                <li>If making a career transition, identify the role and industry you are currently in and how your skills can support a federal project.</li>
              </ul>
              <li>State job titles you have held and the types of projects you have been employed on.</li>
              <li><u>Number of projects you have completed and the estimated value</u>.</li>
              <li>Include project awards or personal professional recognition.</li>
              <li>Identify knowledge of software or other technical skills.</li>
              <li>Identify professional certifications or degrees.</li>
              <li>State if you are willing to travel or relocate for work at the end of the summary.</li>
            </ul>
          </ul>
        </Grid>
      </Grid>
    )
  }

  render() {
    const {
      userStore,
      width,
      classes
    } = this.props

    if (!userStore!.isApplicant && !userStore!.isCandidate) {
      return null 
    }

    const expandButton = <IconicButton onClick={this.onExpand}>
      {this.isExpanded ? <ExpandLessIcon/> : <ExpandMoreIcon/>}
    </IconicButton>

    return (
      <ProfileCard
        title="Profile FAQS"
        button={expandButton}
        overrides={{container: classes.container}}
      >
        {this.isExpanded && this.renderInstructions()}        
      </ProfileCard>
    )
  }

  onExpand = () => {
    this.isExpanded = !this.isExpanded
  }

}

export default withTheme(withStyles(styles)(withWidth()(ProfileFAQs)))
