/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-useless-constructor */
/* eslint-disable no-useless-computed-key */
import * as React from "react";
import {
    Card,
    CardActionArea,
    CardContent,
    createStyles,
    Grid,
    Theme,
    withStyles,
    WithStyles,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    makeStyles,
    styled,
    Box
} from "@material-ui/core";
import { format } from "date-fns";
import { isoToLocalDate } from "../../stores/StoreUtilities";

const pageLimit = 7;

const TableRowStyled = styled((props: any) => <TableRow {...props} />)(({ theme }: any) => ({
    '&:hover td, &:hover th': {
        backgroundColor: theme.palette.grey[200],
        cursor: "pointer"
    },
    '&:last-child td, &:last-child th': {
        border: 0
    }
}))

const useStyles = makeStyles((theme: Theme) => createStyles({
    tableContainer: {
        overflowX: 'auto',
        width: "100%",
        maxWidth: "calc(100vw - 96px)",
        borderRadius: "4px",
        overflow: "hidden"
    },
    table: {
        width: "100%"
    },
    headTableCell: {
        fontWeight: "bold"
    },
    bodyTableCell: {
        fontSize: 14,
        paddingTop: 10,
        paddingLeft: 4,
        paddingRight: 6,
    },
    tableBody: {
        backgroundColor: theme.palette.common.white,
    },
}))

export interface ITableRow {
    id: string
    name: string
    cells: (string | React.ReactNode)[]
}

const DataTable = ({
    head,
    data,
    onClickOpportunityPost,
    handleChangePage,
    page,
    rowsPerPage
}: {
    head: ITableRow
    data: any
    onClickOpportunityPost(row: ITableRow): void
    handleChangePage(event: unknown, newPage: number): void
    handleChangeRowsPerPage(event: React.ChangeEvent<HTMLInputElement>): void
    page: number
    rowsPerPage: number
}) => {
    const classes = useStyles();

    const renderHead = () => {
        return (
            <TableHead>
                <TableRow key="tableHead">
                    {head.cells.map((cell, index) => {
                        return (
                            <TableCell
                                key={`tableHeadRowCell/${index}`}
                                className={classes.headTableCell}
                            >
                                {cell}
                            </TableCell>
                        )
                    })}
                </TableRow>
            </TableHead>
        )
    }

    const dateFormat = (date: string) => {
        return date ? format(isoToLocalDate(date), "MM/dd/yyyy") : '' 
    }

    const renderBody = () => {
        return (
            <TableBody
                className={classes.tableBody}
            >
                {(pageLimit > 0
                    ? data?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    : data)?.map((row: any, index: number) => (
                        <TableRowStyled
                            key={`${row.name}/${index}`}
                            onClick={() => {
                                onClickOpportunityPost(row)
                            }}
                        >
                            <TableCell className={classes.bodyTableCell} align="left" style={{ width: '45%' }}>
                                {row?.title}
                            </TableCell>
                            <TableCell className={classes.bodyTableCell} style={{ width: '25%' }} align="left">
                                {row?.agency?.agency_name}
                            </TableCell>
                            <TableCell className={classes.bodyTableCell} align="left" style={{ width: '15%' }}>
                                {row?.naicsCode}
                            </TableCell>
                            <TableCell className={classes.bodyTableCell} align="left" style={{ width: '15%' }}>
                                {dateFormat(row.postedDate)}
                            </TableCell>
                        </TableRowStyled>
                    ))}
            </TableBody>
        )
    }

    return (<>
        <TableContainer
            component={Paper}
            elevation={0}
            className={classes.tableContainer}
        >
            <Table
                aria-label="simple table"
                className={classes.table}
            >
                {renderHead()}
                {renderBody()}
            </Table>
        </TableContainer>
        <Box display="flex" justifyContent="flex-end" width="100%" p={0}>
            <TablePagination
                rowsPerPageOptions={[pageLimit]} // Customize options as needed
                component="div"
                count={data?.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
            />
        </Box>
    </>
    );
}


const styles = (theme: Theme) => createStyles({
    card: {
        fontSize: 12,
      padding:'10px 0',
    }
    // Your other styles here
});

class OpportunityPostSearchCard extends React.Component<WithStyles<typeof styles> & any> {
    state = {
        page: 0,
        rowsPerPage: pageLimit
    };
    
    constructor(props:any) {
        super(props);
    }

    render() {
        const { classes, jobPost, mockData, onClickOpportunityPost } = this.props
        
        const { page, rowsPerPage } = this.state;
        const head: ITableRow = {
            id: "1",
            name: "Opportunities Found",
            cells: ["Title", "Agency", "NAICS", "Date"]
        }
        const handleChangePage = (event: unknown, newPage: number) => {
            this.setState({ page: newPage });
        };

        const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
            this.setState({ rowsPerPage: parseInt(event.target.value, 10), page: 0 });
        };
        return (
            <Card>
                <CardActionArea onClick={this.props.onClick ? () => this.props.onClick(jobPost) : undefined}>
                    <CardContent className={classes.card}>
                        <Grid container className={classes.body} style={{width:"100%"}}>
                            <DataTable 
                                head={head} 
                                data={mockData} 
                                onClickOpportunityPost={onClickOpportunityPost} 
                                handleChangePage={handleChangePage} 
                                handleChangeRowsPerPage={handleChangeRowsPerPage}
                                page={page}
                                rowsPerPage={rowsPerPage}/>
                        </Grid>
                    </CardContent>
                </CardActionArea>
            </Card>

        )
    }
}

export default withStyles(styles)(OpportunityPostSearchCard)
