import { Autocomplete } from "@material-ui/lab"
import { useEffect, useState } from "react"
import ServiceRequest, { ServiceRequestStatus } from "../../model/ServiceRequest"
import { Box, TextField, Theme, createStyles, makeStyles } from "@material-ui/core"
import Stack from "../../components/Stack"
import ProgressButton from "../../components/form/ProgressButton"
import CancelButton from "../../components/form/CancelButton"
import { useStores } from "../../stores/StoreProvider"
import * as APITypes from "../../API"

const useStyles = makeStyles((theme: Theme) => createStyles({
  filterTextField: {
    flexGrow: 1, 
    backgroundColor: theme.palette.background.paper,
    borderRadius: 10
  },
}))

const ServiceRequestStatusSelector = ({
  fullWidth,
  helperText,
  margin,  
  required, 
  variant,
  serviceRequest,
  onUpdateServiceRequest
}: {
  fullWidth?: boolean 
  helperText?: string 
  margin?: 'dense' | 'none' | 'normal'
  required?: boolean
  variant?: 'filled' | 'outlined' | 'standard',
  serviceRequest: ServiceRequest,
  onUpdateServiceRequest: (updatedServiceRequest: ServiceRequest) => Promise<void>
}) => {
  const classes = useStyles()
  const { jobStore } = useStores()

  const [isProcessing, setIsProcessing] = useState(false)
  const [serviceRequestStatus, setServiceRequestStatus] = useState<ServiceRequestStatus | string>()
  const [inputValue, setInputValue] = useState("");
  const [isEditing, setIsEditing] = useState(false)

  useEffect(() => {
    console.debug("init")
    setServiceRequestStatus(serviceRequest.status)
    setIsEditing(false)
  }, [
    serviceRequest
  ])

  return (
    <Stack
      direction="column"
      justifyContent="center"
      // alignItems="center"
      spacing={1}
    >
      <Box
        width={ fullWidth ? "100%" : "300px" }
      >
        <Autocomplete 
          autoSelect
          freeSolo={true}
          includeInputInList
          value={serviceRequestStatus ?? ""}
          options={Object.values(ServiceRequestStatus)} 
          renderInput={(params: any) =>
            <div ref={params.InputProps.ref}>
              <TextField
                {...params}
                className={classes.filterTextField}
                variant={variant}
                margin={margin}
                size="small"
                name="serviceRequestStatus"
                label="Status"
                required={required}
                helperText={helperText}
              />
            </div>
          }  
          onChange={(event: any, newValue: ServiceRequestStatus | string | null) => {
            console.debug("onChange", newValue)
            setServiceRequestStatus(newValue ?? "");
            setIsEditing(true)
          }}
          inputValue={inputValue}
          onInputChange={(event, newInputValue) => {
            console.debug("onInputChange", newInputValue, event)
            if (event && event.type) {
              if (event.type === "click" || event.type === "change") {
                if (newInputValue === serviceRequest.status) {
                  setIsEditing(false)
                } else {
                  setIsEditing(true)
                }
                setInputValue(newInputValue);
              }
            } else {
              setInputValue(newInputValue);
            }
          }}
        />
      </Box>
      { isEditing && 
        <Stack spacing={1}>
          <ProgressButton
            variant="contained"
            color="secondary"
            size="medium"
            disabled={inputValue === "" || isProcessing}
            processing={isProcessing}
            onClick={async () => {
              setIsProcessing(true)

              const input: APITypes.UpdateServiceRequestInput = {
                id: serviceRequest.id, 
                status: serviceRequestStatus
              }

              const updatedServiceRequest = await jobStore.updateServiceRequest(input)
              await onUpdateServiceRequest(updatedServiceRequest!)

              setIsProcessing(false)
              setIsEditing(false)
            }}
          >
            Save
          </ProgressButton>
          <CancelButton 
            onClick={() => {
              setServiceRequestStatus(serviceRequest.status)
              // setInputValue("")
              setIsEditing(false)
            }} 
          />
        </Stack>
      }
    </Stack>
  )
}

export default ServiceRequestStatusSelector 
