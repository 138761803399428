import { Box } from "@material-ui/core"
import { PropsWithChildren } from "react"

const PageTabPanel = ({
  embed,
  children 
}: {
  embed: boolean 
} & PropsWithChildren<any>) => {
  return (
    <Box 
      px={{ 
        xs: 1, 
        sm: 3
      }} 
      mb={2} 
      bgcolor="grey.50" 
      borderRadius={ embed ? 0 : "0px 0px 10px 10px" }
    >
      <Box py={2}>
        {children}
      </Box>
    </Box>
  )
}

export default PageTabPanel
