import { Box, Button, IconButton, Paper, Theme, Typography, createStyles, makeStyles, useTheme } from "@material-ui/core"
import Stack from "../Stack"
import ArrowForwardIos from "@material-ui/icons/ArrowForwardIos"

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    width: "100%",
    // maxWidth: "100vw"
  }
}))

const DashboardPanel = ({
  title, 
  showNavButton,
  handleNavButton, 
  elevation, 
  backgroundColor, 
  children 
}: {
  title: string 
  showNavButton?: boolean 
  handleNavButton?: () => void 
  elevation?: number 
  backgroundColor?: string 
  children: React.ReactNode
}) => {
  const theme = useTheme()
  const classes = useStyles() 
  return (
    <Paper
      elevation={elevation}
      className={classes.root}
    >
      <Box
        bgcolor={backgroundColor}
        borderRadius={theme.spacing(1)}
        width="100%"
        // pt={2}
        // maxWidth="100vw"
        pb={2}
      >
        <Stack
          direction="column"
          sx={{
            width: "100%"
          }}
          xs={12}
        >
          <Stack
            direction="row"
            justifyContent="space-between"
            sx={{
              pb: 2,
              width: "100%"
            }}
          >
            <Box
              pt={2}
              pl={3}
            >
              <Typography 
                variant="h5" 
                color="primary"
              >
                {title}
              </Typography>
            </Box>
            { showNavButton && 
              <Box
                pt={1}
                pr={1}
              >
                <Button
                  color="secondary"
                  onClick={handleNavButton}
                  endIcon={<ArrowForwardIos />}
                >
                  See All 
                </Button> 
              </Box>
            }
          </Stack>
          <Box
            pl={3}
            pr={3}
          >
            {children}
          </Box>
        </Stack>
      </Box>
    </Paper>
  )
}

export default DashboardPanel
