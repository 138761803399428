import * as React from "react";
import {
  Box,
  Card,
  CardContent,
  createStyles, Dialog, DialogActions, DialogContent, DialogTitle, FormGroup,
  Grid,
  Theme,
  withStyles,
  WithStyles
} from "@material-ui/core";
import {inject, observer} from "mobx-react";
import ControlTower, {Routes} from "../../components/ControlTower";
import Profile, {ProfileAvailabilityOptions} from "../../model/Profile";
import CardValue from "../../components/CardValue";
import {getErrorMessage, isoDateToWhen, phoneToNationalFormat} from "../../stores/StoreUtilities";
import ResourceCache from "../../stores/ResourceCache";
import IconicButton from "../../components/controls/IconicButton";
import EditIcon from "@material-ui/icons/Edit";
import User from "../../model/User";
import FormValidator from "../../components/form/FormValidator";
import TextFieldValidator from "../../components/form/TextFieldValidator";
import FormGroupSpacer from "../../components/form/FormGroupSpacer";
import StateSelector from "../../components/form/StateSelector";
import CancelButton from "../../components/form/CancelButton";
import ProgressButton from "../../components/form/ProgressButton";
import Logger from "../../components/Logger";
import {UpdateProfileInput, UpdateUserInput, UserStatus} from "../../API";
import {makeObservable, observable} from "mobx";
import Notify from "../../components/notify/Notify";
import UserStore from "../../stores/UserStore";
import ProfileStore from "../../stores/ProfileStore";
import SimpleSelect from "../../components/controls/SimpleSelect";
import AddButton from "../../components/AddButton";
import JobStore from "../../stores/JobStore";
import Confirm from "../../components/confirm/Confirm";
import ProfileNameView from "../../components/profile/ProfileNameView";
import Stack from "../../components/Stack";

const styles = (theme: Theme) => createStyles({
  container: {
    minHeight: "100%"
  },
  card: {
    fontSize: 12,
    padding: '16px !important', // overriding CardContent root setting
  },
  icon: {
    fontSize: 45,
    height: 45,
    paddingRight: theme.spacing(2),
  },
  name: {
    fontSize: 20,
    fontWeight: 600,
    color: theme.palette.primary.main,
  },
  title: {
    paddingTop: '3px',
    paddingBottom: '6px',
  },
  body: {
  },
  leftFields: {
    paddingRight: theme.spacing(2),
    overflowX: "hidden",
  },
  rightFields: {
    // overflowX: "hidden",
    whiteSpace: "nowrap",
    [theme.breakpoints.down('lg')]: {
      flex: "0 0 90px",
    },
  },
  formGroupRow: {
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "nowrap"
  },
  formGroupField: {
    flexGrow: 1,
  },
  postalCodeField: {
    flexGrow: 0,
    width: 100
  },
  stateSelector: {
    minWidth: 60
  },
  progressButton: {
    minWidth: 80
  },
  availabilitySelect: {
    width: 480
  }
  // chip: {
  //   margin: 2
  // }
})

class CandidateViewModel {
  @observable firstName: string
  @observable lastName: string
  @observable email: string
  @observable phone: string
  @observable address: string
  @observable city: string
  @observable state: string
  @observable postalCode: string
  @observable country: string
  @observable availability: string
  @observable desiredRate: string

  constructor(user: User) {
    makeObservable(this);
    this.firstName = user!.firstName
    this.lastName = user!.lastName
    this.email = user!.email
    this.phone = user!.phone
    this.address = user!.address
    this.city = user!.city
    this.state = user!.state
    this.country = user!.country ?? "US"
    this.postalCode = user!.postalCode
    this.availability = user.profile!.availability ?? ""
    this.desiredRate = user.profile!.desiredRate ? user.profile!.desiredRate : ""
  }
}

interface ICandidateCardProps {
  user: any
  onCardClick?(user: User): void
  onChange?(user: User): any

  resourceCache?: ResourceCache
  notify?: Notify
  confirm?: Confirm
  userStore?: UserStore
  profileStore?: ProfileStore
  jobStore?: JobStore
}

@inject("resourceCache", "notify", "confirm", "userStore", "profileStore", "jobStore")
@observer
class CandidateCard extends React.Component<WithStyles<typeof styles> & ICandidateCardProps> {
  @observable viewModel?: CandidateViewModel
  @observable isFormOpen: boolean = false
  @observable isProcessing = false
  // @observable services: string[] = []

  constructor(props: any) {
    super(props)
    makeObservable(this)
  }

  componentDidMount() {
    // const { user } = this.props

    // if (user) {
    //   this.getServices()
    // }
  }

  render() {
    const { classes, user } = this.props
    return (
      <Card className={classes.container}>
        <CardContent className={classes.card}>
          <Grid container direction="column" spacing={0}>
            <Grid item xs={12}>
              <Stack direction="row" spacing={1} justifyContent="space-between" alignItems="center" wrap="nowrap">
                <Box pb={1}>
                  <ProfileNameView 
                    profile={user.profile}
                    onClickTitle={this.onCardClick}
                  />
                </Box>
                <IconicButton onClick={this.handleEdit}>
                  <EditIcon />
                </IconicButton>
              </Stack>
            </Grid>
            <Grid item xs={12}>
              { this.renderContent() }
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    )
  }

  renderContent() {
    const { user } = this.props
    const phoneNumber = user.phone ? phoneToNationalFormat(user.phone) : ""
    const email = user.email
    const profile: Profile | undefined = user.profile
    const lastActiveAt = isoDateToWhen(user.lastActiveAt)

    return (
      <Box>
        {/*<Box maxHeight={84} overflow="hidden">*/}
        {/*  {this.services.map((service: string) => {*/}
        {/*    return <Chip color="secondary" size="small" icon={<FaceIcon />} label={service} className={classes.chip}/>*/}
        {/*  })}*/}
        {/*</Box>*/}
        <CardValue label="Address">
          {user.address} {user.address && <br/>} {user.city}, {user.state} {user.postalCode}
        </CardValue>
        <CardValue label="Email">
          {email}
        </CardValue>
        <CardValue label="Phone">
          {phoneNumber}
        </CardValue>
        <CardValue label="Available">
          {profile!.availability ?? ""}
        </CardValue>
        {lastActiveAt &&
          <CardValue label="Last Active">
            {lastActiveAt}
          </CardValue>
        }
        {this.isFormOpen && this.renderForm()}
      </Box>
    )
  }

  // getServices = () => {
  //   const { user, resourceCache } = this.props
  //
  //   if (user && user.profile && user.profile.profileServices) {
  //     // Sort and pick off the first 3 services
  //     const services = user.profile.profileServices.map((p: ProfileService) => {
  //       const service = resourceCache!.getService(p.serviceId)
  //       return service?.name
  //     })
  //     services.sort()
  //     this.services = services.slice(0, Math.max(services.length, 3))
  //   }
  // }

  onCardClick = async () => {
    if (this.props.onCardClick) {
      this.props.onCardClick(this.props.user)
    } else {
      const profile = this.props.user.profile
      let searchParams = new URLSearchParams()
      searchParams.append('profileId', profile.id)
      const route = `${Routes.manageCandidate}/profile?${searchParams.toString()}`
      ControlTower.route(route)
    }
  }

  renderForm() {
    const { classes, /* width */ } = this.props
    const viewModel = this.viewModel!

    return (
      <React.Fragment>
        <Dialog
          open={true}
          onClose={this.handleClose}
          fullWidth
          maxWidth="sm"
        >
          <FormValidator onSubmit={this.onSubmit} autoComplete="off" name="personalInfoForm" id="personalInfoForm">
            <DialogTitle id="form-dialog-title">Candidate Information</DialogTitle>
            <DialogContent>
              <TextFieldValidator
                type="text"
                validators={{ required: true }}
                name="firstName"
                label="First Name"
                value={viewModel.firstName}
                onChange={this.handleChange}
                fullWidth
              />
              <TextFieldValidator
                type="text"
                validators={{ required: true }}
                name="lastName"
                label="Last Name"
                value={viewModel.lastName}
                onChange={this.handleChange}
                fullWidth
              />
              <TextFieldValidator
                type="text"
                validators={{ required: false }}
                name="address"
                label="Address"
                value={viewModel.address}
                onChange={this.handleChange}
                fullWidth
              />
              <FormGroup row classes={{ row: classes.formGroupRow }}>
                <TextFieldValidator
                  type="text"
                  validators={{ required: true }}
                  name="city"
                  label="City"
                  value={viewModel.city}
                  onChange={this.handleChange}
                  className={classes.formGroupField}
                />
                <FormGroupSpacer />
                <StateSelector
                  value={viewModel.state}
                  onChange={this.handleChange}
                  className={classes.stateSelector}
                />
                <FormGroupSpacer />
                <TextFieldValidator
                  type="text"
                  validators={{ required: false, isPostalCode: null }}
                  name="postalCode"
                  label="ZIP"
                  value={viewModel.postalCode}
                  onChange={this.handleChange}
                  className={classes.postalCodeField}
                />
              </FormGroup>
              <TextFieldValidator
                type="text"
                validators={{ required: true, isEmail: null }}
                name="email"
                label="Email"
                value={viewModel.email}
                onChange={this.handleChange}
                fullWidth disabled
              />
              <TextFieldValidator
                type="text"
                validators={{ required: true, isMobilePhone: null }}
                name="phone"
                label="Phone"
                value={viewModel.phone}
                onChange={this.handleChange}
                fullWidth
              />
              <FormGroup row classes={{ row: classes.formGroupRow }}>
                <SimpleSelect
                  className={classes.availabilitySelect}
                  fullWidth={true}
                  placeholderText="Availability to start work after hire"
                  selections={ProfileAvailabilityOptions}
                  value={viewModel.availability}
                  valueName={"availability"}
                  elementId={"availability"}
                  onChange={this.handleChange}
                />
                <FormGroupSpacer />
                <TextFieldValidator
                  type="text"
                  name="desiredRate"
                  label="Desired Rate"
                  value={viewModel.desiredRate}
                  onChange={this.handleChange}
                  fullWidth
                  required
                  helperText="Desired hourly rate or annual salary"
                />
              </FormGroup>
            </DialogContent>
            <DialogActions>
              <Grid container justifyContent="space-between" spacing={1}>
                <Grid item>
                  <AddButton
                    text="Delete"
                    tracking="deleteCandidate"
                    buttonColor="default"
                    buttonVariant="contained"
                    icon="remove"
                    click={this.handleDelete}
                  />
                </Grid>
                <Grid item>
                  <Grid container>
                    <CancelButton onClick={this.handleClose} />
                    <ProgressButton variant="contained" size="small" color="primary"
                                    type="submit" className={classes.progressButton} processing={this.isProcessing}>
                      Save
                    </ProgressButton>
                  </Grid>
                </Grid>
              </Grid>
            </DialogActions>
          </FormValidator>
        </Dialog>
      </React.Fragment>
    )
  }

  handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const name = event.target.name
    this.viewModel![name] = event.target.value
  }

  handleEdit = (): void => {
    if (this.props.user) {
      this.viewModel = new CandidateViewModel(this.props.user)
      this.isFormOpen = true
    }
  }

  handleClose = () => {
    this.isFormOpen = false
  }

  handleDelete = async () => {
    const {user, profileStore, jobStore, userStore, confirm, notify} = this.props

    try {
      confirm!.show(
        'Confirm Delete',
        'Are you sure that you want to delete this candidate? All candidate data will be lost.',
        ['Delete', 'Cancel'],
        async () => {
          const profile = await profileStore!.getUserProfile(user.id)
          if (profile) {
            await profileStore!.deleteProfile(profile)
            const jobInterests = await jobStore!.listJobInterestsByUser(user.id)
            if (jobInterests.length > 0) {
              await Promise.all((jobInterests).map(({ id }) => jobStore!.deleteJobInterest(id)))
            }
            const jobCandidates = await jobStore!.listJobCandidatesByProfile(profile.id)
            if (jobCandidates.length > 0) {
              await Promise.all((jobCandidates).map(({ id }) => jobStore!.deleteJobCandidate(id)))
            }
            const update: UpdateUserInput = {
              id: user.id,
              userStatus: UserStatus.Suspended
            }
            const updatedUser = await userStore!.updateUser(update)
            if (updatedUser) {
              notify!.show('success', 'Candidate deleted')
              this.handleClose()
              if (this.props.onChange) {
                this.props.onChange(updatedUser)
              }
              return true
            }
          } else {
            notify!.show('error', "Candidate Profile not found")
          }
          return false
        })
    } catch (err) {
      notify!.show('error', getErrorMessage(err))
    }
  }



  onSubmit = async () => {
    const { notify, user, profileStore, userStore } = this.props
    const viewModel = this.viewModel!
    const profile = user.profile!

    this.isProcessing = true

    let updatedUser: User | null

    try {
      const updateUserInput: UpdateUserInput = {
        id: user!.id,
        firstName: viewModel.firstName,
        lastName: viewModel.lastName,
        email: viewModel.email,
        address: viewModel.address,
        city: viewModel.city,
        state: viewModel.state,
        postalCode: viewModel.postalCode,
        country: viewModel.country
      }
      updatedUser = await userStore!.updateUser(updateUserInput)
      if (!updatedUser) {
        throw new Error('Could not update user')
      }
    } catch (error) {
      this.isProcessing = false
      this.handleClose()
      const reason = 'Could not update user record'
      Logger.error(reason, error)
      notify!.show("error", reason)
      return
    }

    let updatedProfile: Profile | undefined

    try {
      // Update profile after updating the user, since some profile fields depend on the user record.
      // For example, the nickname and alias depend on the user's name.
      const updateProfileInput: UpdateProfileInput = {
        id: profile!.id,
        nickname: updatedUser!.nickname,
        alias: profileStore!.generateAlias(updatedUser!),
        availability: viewModel.availability ?? null,
        desiredRate: viewModel.desiredRate,
        city: viewModel.city,
        state: viewModel.state,
        country: viewModel.country
      }
      updatedProfile = await profileStore!.updateProfile(updateProfileInput)
      if (!updatedProfile) {
        throw new Error('Could not update profile')
      }
    } catch (error) {
      this.isProcessing = false
      this.handleClose()
      const reason = 'Could not update profile record'
      Logger.error(reason, error)
      notify!.show("error", reason)
      return
    }

    updatedUser.profiles[0] = updatedProfile
    this.isProcessing = false
    this.handleClose()
    if (this.props.onChange) {
      this.props.onChange(updatedUser)
    }
  }
}

export default withStyles(styles)(CandidateCard)