// import { Autocomplete } from "@material-ui/lab"
import FileDialog from "../../components/file/FileDialog"
// import FilePreviewWithDialog from "../../components/file/FilePreviewWithDialog"
import { createUUID } from "../../stores/StoreUtilities"
import TextFieldValidator from "../../components/form/TextFieldValidator"
import { useStores } from "../../stores/StoreProvider"
import { AgreementType, CreateAgreementInput, UpdateAgreementInput } from "../../API"
import { useEffect, useState } from "react"
import FormValidator from "../../components/form/FormValidator"
import { when } from "mobx"
import LoadingPanel from "../../components/panel/LoadingPanel"
import Agreement from "../../model/Agreement"
import { onDeleteAgreement } from "../../graphql/subscriptions"

const AgreementDialog = ({
  isOpen,
  accountId,
  agreementId, 
  onClose, 
  onUpdateAgreement,
  onCreateAgreement, 
  onDeleteAgreement
}: {
  isOpen: boolean 
  accountId: string 
  agreementId?: string 
  onClose: () => void 
  onUpdateAgreement(updatedAgreement: Agreement): Promise<void> 
  onCreateAgreement(createdAgreement: Agreement): Promise<void> 
  onDeleteAgreement(deletedAgreement: Agreement): Promise<void> 
}) => {
  const { accountStore, userStore } = useStores()
  const [isLoading, setIsLoading] = useState(true)
  const [agreementType, setAgreementType] = useState<AgreementType>()
  const [agreement, setAgreement] = useState<Agreement>()

  const agreementTypes: AgreementType[] = [
    AgreementType.MasterServicesAndNonSolicitation,
    AgreementType.ExclusiveRecruitment,
    AgreementType.StaffingAgreement,
    AgreementType.ProfessionalServicesAgreement,
    AgreementType.ConfidentialityAndNonDisclosure
  ]

  useEffect(() => {
    when(
      () => userStore.isLoading === false && accountStore.isLoading === false, 
      async () => {
        if (agreementId) {
          const agreement = await userStore.getAgreement(agreementId)
          setAgreement(agreement)
          if (agreement) {
            const agreementType = agreement!.agreementTypes[0]
            setAgreementType(agreementType)
          } else {
            setAgreementType(undefined)
          }
        } else {
          setAgreement(undefined)
          setAgreementType(undefined)
        }
        setIsLoading(false)
      }
    )
  }, [
    accountId, 
    agreementId 
  ])

  const fileFolder = () => {
    return `accounts/${accountId}/agreements`
  }

  return (
    <FileDialog 
      open={isOpen} 
      isLoading={isLoading}
      level="private" 
      fileKey={agreement?.agreementKey ?? null}
      keyGenerator={(file?: File, ext?: string) => file ? `${fileFolder()}/${createUUID()}-${file.name}` : ''}
      fileNameGetter={() => {
        if (agreement?.agreementKey) {
          const chunks = agreement.agreementKey.split(`${fileFolder()}`)
          return chunks[chunks.length - 1]
        }
        return null 
      }}
      onSaveDialog={async () => {
        if (agreementId) {
          const input: UpdateAgreementInput = {
            id: agreementId,
            agreementTypes: [agreementType!]
          }
          const agreement = await userStore.updateAgreement(input)
          onUpdateAgreement(agreement!)
        } else {
          const input: CreateAgreementInput = {
            userId: userStore.user!.id, 
            accountId: accountId, 
            agreementTypes: [agreementType!]
          }
          const agreement = await userStore.createAgreement(input)
          onCreateAgreement(agreement!)
        }
      }}
      onSaveWithFile={async (key: string, fileUrl: string) => {
        if (agreementId) {
          const input: UpdateAgreementInput = {
            id: agreementId,
            agreementTypes: [agreementType!], 
            agreementKey: key
          }
          const agreement = await userStore.updateAgreement(input)
          onUpdateAgreement(agreement!)
        } else {
          const input: CreateAgreementInput = {
            userId: userStore.user!.id, 
            accountId: accountId, 
            agreementTypes: [agreementType!],
            agreementKey: key 
          }
          const agreement = await userStore.createAgreement(input)
          onCreateAgreement(agreement!)
        }
      }}
      onDeleteFile={async (key: string, fileUrl: string) => {
        const agreement = await userStore.deleteAgreement(agreementId!)
        await onDeleteAgreement(agreement!)
      }} 
      onClose={onClose}
      uploadDisabled={ isLoading || !agreementType }
      uploadButtonText="Upload"
    >
      <FormValidator autoComplete="off" name="agreementForm" id="agreementForm">
        <TextFieldValidator 
          type="text"
          validators={{ required: true }}
          name="agreementType"
          label="Agreement Type"
          fullWidth
          required
          // margin="dense"
          autocompleteOptions={{
            freeSolo: false,
            options: agreementTypes,
            value: agreementType ?? null,
            getOptionLabel: (option: AgreementType) => accountStore.agreementTypeToString(option),
            getOptionSelected: (option: AgreementType, value: AgreementType) => option === value,
            onChange: async (event: any, value: any, reason: any) => {
              setAgreementType(value)
            }
          }}
        />
      </FormValidator>
    </FileDialog>
  )
}

export default AgreementDialog
