import { Box, Button, Card, CardActionArea, CardActions, CardContent, CardMedia, Divider, Grid, Theme, Typography, createStyles, makeStyles, useMediaQuery, useTheme } from "@material-ui/core"
import Stack from "../../components/Stack"
import DashboardPanel from "../../components/panel/DashboardPanel"
import { Note, People } from "@material-ui/icons"
import { blueGrey } from "@material-ui/core/colors"
import ControlTower, {Routes} from "../../components/ControlTower"
import FindTalentMapImage from "../../images/FindTalentMap.png"

interface ServiceOption {
  title: string 
  description: string 
  actionButtonTitle: string 
  icon: React.ReactNode
  color: string,
  route: Routes
}

interface ServiceSection {
  title: string
  options: ServiceOption[] 
}

const useStyles = makeStyles((theme: Theme) => createStyles({
  card: {
    height: "100%"
  },
  thumbnail: {
    width: "100%",
    height: 125
  },
  description: {
    // minHeight: 75 
  }
}))

const AvailableServices = () => {
  const theme = useTheme() 
  const classes = useStyles()
  const isMedium = useMediaQuery(theme.breakpoints.down('md'))
  
  const selfServiceOptions: ServiceOption[] = [
    {
      title: "Self-service Job Posts",
      description: "Post jobs to the GovGig job board and find candidates.",
      actionButtonTitle: "Post Jobs",
      icon: <Note color="inherit" />,
      color: "hsl(238, 37%, 26%)",
      route: Routes.jobPostCreate
    }
  ]
  
  const agentServiceOptions: ServiceOption[] = [
    {
      title: "Recruiting or Staffing Services Request",
      description: "GovGig posts your open roles on your behalf and finds you candidates quickly.",
      actionButtonTitle: "Create Request",
      icon: <People color="inherit" />,
      color: "hsl(222, 33%, 27%)",
      route: Routes.serviceRequestCreate
    }
  ]

  const sections: ServiceSection[] = [
    {
      title: "Self-service",
      options: [...selfServiceOptions]
    },
    {
      title: "Agent Services",
      options: [...agentServiceOptions]
    }
  ]

  const renderOptions = (options: ServiceOption[]) => {
    return options.map(option => {
      return (
        <Grid key={option.title} item xs={12} md={4}>
          <Box
            bgcolor={theme.palette.common.white}
            sx={{
              width: "100%",
              height: "100%",
              minHeight: "100%",
              borderRadius: theme.spacing(1),
              overflow: "hidden",
              boxShadow: 'rgba(0, 0, 0, 0.15) 0px 5px 15px 0px',
              // paddingTop: theme.spacing(1),
              paddingBottom: theme.spacing(3),
              // paddingLeft: theme.spacing(2),
              // paddingRight: theme.spacing(2) 
            }}
          >
            <Grid container direction="column" justifyContent="space-between">
              <Grid item>
                <Stack
                  direction="column"
                  alignItems="center"
                  justifyContent="flex-start"
                  sx={{
                    backgroundColor: option.color,
                    color: theme.palette.common.white,
                    height: "125px"
                  }}
                >
                  <Box
                    pt={3}
                    // pl={3}
                    
                  >
                    {option.icon}
                  </Box>
                  <Box
                    // bgcolor={theme.palette.primary.main}
                    // py={2}
                    // mb={2}
                    pb={2}
                    px={2}
                    sx={{
                      width: "100%",
                      height: "100%",
                      // color: theme.palette.common.white 
                    }}
                  >
                    <Typography variant="h6" align="center" color="inherit">{option.title}</Typography>
                  </Box>
                  
                </Stack>
              </Grid>
              <Grid item>
                <Stack
                  direction="column"
                  justifyContent="flex-end"
                  alignItems="center"
                  spacing={2}
                  sx={{
                    pt: 2, 
                    px: 3,
                    pb: 3
                  }}
                >
                  <Typography 
                    align="center"
                    className={classes.description}
                  >
                    {option.description}
                  </Typography>
                </Stack>
              </Grid>
              <Grid item>
                <Stack
                  direction="row"
                  justifyContent="center"
                  sx={{
                    width: "100%"
                  }}
                >
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => {
                      ControlTower.route(option.route)
                    }}
                  >
                    {option.actionButtonTitle}
                  </Button>
                </Stack>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      )
    })
  } 

  // const renderOptionsSection = (section: ServiceSection) => {
  //   const {
  //     title, 
  //     options 
  //   } = section 
  //   return (
  //     <Stack
  //       direction="column"
  //       spacing={1}
  //       sx={{
  //         width: "100%",
  //         height: "100%"
  //       }}
  //     >
  //       {/* <Typography>{title}</Typography> */}
  //       { renderOptions(options) }
  //     </Stack>
  //   )
  // }

  const renderFindTalentCard = () => {
    return (
      <Grid item xs={12} md={4}>
        <Card
          className={classes.card}
        >
          <CardMedia 
            className={classes.thumbnail}
            image={FindTalentMapImage}
          />
          <CardContent>
            {/* <Typography variant="h6" align="center">Find Talent</Typography> */}
            <Typography 
              align="center"
              className={classes.description}
            >
              Search our growing talent map of over 8,000 pre-vetted, pre-qualified personnel.
            </Typography>
          </CardContent>
          <CardActions>
            <Stack
              direction="column"
              justifyContent="flex-start"
              alignItems="center"
              sx={{
                width: "100%",
                height: "100%",
                pb: 2 
              }}
            >
              <Button
                variant="contained"
                color="secondary"
                onClick={() => {
                  ControlTower.route(Routes.findTalent)
                }}
              >
                Find Talent 
              </Button>
            </Stack>
          </CardActions>
        </Card>
      </Grid>
    )
  }

  return (
    <DashboardPanel
      title="Available Services"
      elevation={0}
      backgroundColor={blueGrey[50]}
    >
      <Grid 
        container 
        direction="row"
        spacing={5}
      >
        { sections.map(section => {
          return renderOptions(section.options)
        }) }
        { renderFindTalentCard() }
      </Grid>
    </DashboardPanel>
  )
}

export default AvailableServices 
