import { createStyles, Theme, withStyles, WithStyles, withTheme, WithTheme } from '@material-ui/core/styles'
import { Link, Typography } from "@material-ui/core";
import React from "react";
import Tracking from "./Tracking";
import ControlTower from './ControlTower';
import classNames from 'classnames';

const styles = (theme: Theme) => createStyles({
  linkStyle: {
    textDecoration: 'none',
    cursor: "pointer"
  },
  linkStyleLight: {
    color: theme.palette.common.white,
  }, 
  linkStyleDark: {
    color: theme.palette.primary.main,
  },
  linkText: {
    fontWeight: 'bold',
    lineHeight: 1.3
  }
})

interface IActionLinkProps {
  text: string
  alignText?: 'center' | 'left' | 'right'
  variation?: 'light' | 'dark'
  tracking: string
  click?: Function
  route?: string
  textStyle?: string
  linkStyle?: string
}

class ActionLink extends React.Component<WithStyles<typeof styles> & WithTheme & IActionLinkProps> {
  render() {
    const { text, alignText, tracking, click, classes, route, variation, textStyle, linkStyle } = this.props
    let linkStyles = [classes.linkStyle]
    let textStyles = [classes.linkText]
    if (variation) {
      if (variation === 'light') {
        linkStyles.push(classes.linkStyleLight)
      } else if (variation === 'dark') {
        linkStyles.push(classes.linkStyleDark)
      }
    }
    if (textStyle) {
      textStyles.push(textStyle)
    }
    if (linkStyle) {
      linkStyles.push(linkStyle)
    }
    return (
      <div>
        <Link
          aria-label={text}
          className={classNames(linkStyles)}
          onClick={() => {
            Tracking.event({
              action: `${tracking}`,
              label: text
            })
            if (route) {
              ControlTower.route(route)
            } else if (click) {
              click() 
            }
          }}
        >
          <Typography align={alignText} variant="body1" color="inherit" className={classNames(textStyles)}>
            {text}&#8203;
          </Typography>
        </Link>
      </div>
    )
  }
}

export default withTheme((withStyles(styles)(ActionLink)))
