import { useEffect, useState } from "react"
import ControlTower, { Routes } from "../../components/ControlTower"
import DashboardPanel from "../../components/panel/DashboardPanel"
import ServiceRequest, { ServiceRequestStatus } from "../../model/ServiceRequest"
import { useStores } from "../../stores/StoreProvider"
import { when } from "mobx"
import * as APITypes from "../../API"
import { ServiceRequestResult } from "../../stores/JobStore"
import { DataGrid, GridRowData, GridRowsProp, GridColDef, GridRowParams, MuiEvent, GridOverlay, GridRenderCellParams } from '@mui/x-data-grid';
import { Box, Theme, Typography, createStyles, makeStyles } from "@material-ui/core"
import ServiceRequestTypeChip from "./ServiceRequestTypeChip"
import LoadingPanel from "../../components/panel/LoadingPanel"
import SideDrawer from "../../components/page/SideDrawer"
import JobPost from "../../model/JobPost"
import JobCandidate from "../../model/JobCandidate"
import ManageServiceRequestPage from "../../pages/serviceRequests/ManageServiceRequestPage"
import ManageJobPage from "../../pages/manageJobs/ManageJobPage"

interface IRowViewModel {
  id: string 
  name: string 
  serviceRequestTypeString: string 
  serviceRequestType: string 
  company?: string 
  // project?: string 
  // location?: string 
  createdAt: string 
  updatedAt: string 
  status: string 
}

const useStyles = makeStyles((theme: Theme) => createStyles({
  dataGrid: {
    border: "1px solid rgba(224, 224, 224, 1)",
    "& .MuiDataGrid-columnsContainer": {
      borderBottom: "1px solid rgba(224, 224, 224, 1)",
    },
    "& .MuiDataGrid-cell": {
      borderBottom: "1px solid rgba(224, 224, 224, 1)",
    },
    "& .MuiDataGrid-iconSeparator": {
      color: "1px solid rgba(224, 224, 224, 1)"
    }
  }
}))


const ServiceRequestsList = ({
  compact, 
  limit,
  showNavButton,
  handleNavButton 
}: {
  compact?: boolean 
  limit?: number // Show only the top X listings 
  showNavButton?: boolean 
  handleNavButton?: () => void 
}) => {
  const { accountStore, jobStore, progress, userStore } = useStores()
  const classes = useStyles()
  
  const progressName = "ServiceRequestsList"
  const [isLoading, setIsLoading] = useState(true)
  const [serviceRequests, setServiceRequests] = useState<ServiceRequest[]>([])
  // const [serviceRequestsVisible, setServiceRequestsVisible] = useState<ServiceRequest[]>([])
  // const [rows, setRows] = useState<GridRowsProp>([])
  // const [columns, setColumns] = useState<GridColDef[]>([])
  // const [nextToken, setNextToken] = useState<string>()
  const [serviceRequest, setServiceRequest] = useState<ServiceRequest>()
  const [jobPost, setJobPost] = useState<JobPost>()

  useEffect(() => {
    progress.show(progressName) 
    const fetchServiceRequests = async (): Promise<ServiceRequest[]> => {
      // const todaysISODate = getISODateToday()
      const filter: APITypes.ModelServiceRequestFilterInput = {
        
      }
      
      let result: ServiceRequestResult | null 
      let nextToken: string | undefined
      let serviceRequests: ServiceRequest[] = []
      let hasReachedLimit = false 
      do {
        if (userStore.isAdminOrAgent) {
          result = await jobStore.listServiceRequests(filter, limit, nextToken)
        } else if (accountStore.account) {
          const accountId = accountStore.account.id 
          result = await jobStore.listServiceRequestsByAccount(accountId, filter, limit, nextToken)
        } else {
          result = null 
        }
        if (result?.serviceRequests) {
          serviceRequests.push(...result!.serviceRequests)
        }
        nextToken = result?.nextToken ?? undefined
        if (limit && serviceRequests.length >= limit) {
          hasReachedLimit = true 
        }
      } while (result && nextToken && hasReachedLimit === false)
      return serviceRequests
    }

    const init = async () => {
      const serviceRequests = await fetchServiceRequests()
      console.debug('serviceRequests', serviceRequests.length)
      serviceRequests.sort((a: ServiceRequest, b: ServiceRequest) => {
        return b.updatedAt.localeCompare(a.updatedAt)
      })
      setServiceRequests(serviceRequests)

      setIsLoading(false)
      progress.hide(progressName)
    }

    when(
      () => accountStore.isLoading === false 
        && jobStore.isLoading === false
        && userStore.isLoading === false, 
      () => {
        init() 
      }
    )
  }, [
    accountStore, 
    jobStore, 
    progress, 
    userStore
  ])

  const renderServiceRequestLargeDrawer = () => {
    return (
      <SideDrawer 
        size="large"
        title={'Manage Service Request'} 
        isOpen={serviceRequest !== undefined} 
        onClose={() => {
          setServiceRequest(undefined)
        }}        
      >
        {serviceRequest && 
          <ManageServiceRequestPage
            embed 
            serviceRequest={serviceRequest}
            onClickJobPost={(jobPost: JobPost) => {
              setJobPost(jobPost)
            }}
            onUpdateServiceRequest={async (serviceRequest: ServiceRequest) => {
              // await this.handleUpdateServiceRequest(serviceRequest)
              const serviceRequestsCopy: ServiceRequest[] = Object.assign([], serviceRequests)
              const index: number = serviceRequestsCopy.findIndex((checkServiceRequest: ServiceRequest) => checkServiceRequest.id === serviceRequest.id)
              if (index !== -1) {
                serviceRequestsCopy[index] = serviceRequest 
                setServiceRequests(serviceRequestsCopy)
              }
              setServiceRequest(serviceRequest)
            }}
          /> 
        }
        { serviceRequest && renderJobPostLargeDrawer() }
      </SideDrawer>
    )
  }

  const renderJobPostLargeDrawer = () => {
    return (
      <SideDrawer 
        size="large"
        title={'Manage Job Post'} 
        isOpen={jobPost !== undefined} 
        onClose={() => {
          setJobPost(undefined)
        }}        
      >
        {jobPost && 
          <ManageJobPage
            embed 
            jobPostId={jobPost!.id}
            onClickJobCandidate={(jobCandidate: JobCandidate) => {
              // setJobCandidate(jobCandidate)
            }}
            onUpdateJobPost={async (jobPost: JobPost) => {
              // await this.handleUpdateJobPost(jobPost)
              // const jobPostsCopy: JobPost[] = Object.assign([], jobPosts)
              // const index: number = jobPostsCopy.findIndex((checkJobPost: JobPost) => checkJobPost.id === jobPost.id)
              // if (index !== -1) {
              //   jobPostsCopy[index] = jobPost 
              //   setJobPosts(jobPostsCopy)
              // }
            }}
          /> 
        }
      </SideDrawer>
    )
  }

  const renderServiceRequestType = (params: GridRenderCellParams) => {
    const serviceRequestType = params.row["serviceRequestType"] as APITypes.ServiceRequestType
    return (
      <ServiceRequestTypeChip serviceRequestType={serviceRequestType} />
    )
  }

  const makeColumns = () => {
    let columns: GridColDef[] = []
    columns.push({
      field: "name",
      headerName: "Title",
      width: 325
    })
    columns.push({
      field: "serviceRequestTypeString",
      headerName: "Type",
      width: 200,
      renderCell: renderServiceRequestType
    })
    if (userStore.isAdminOrAgent) {
      columns.push({
        field: "company",
        headerName: "Company",
        width: 250
      })
    }
    // columns.push({
    //   field: "project",
    //   headerName: "Project/Contract",
    //   width: 250
    // })
    // columns.push({
    //   field: "location",
    //   headerName: "Location",
    //   width: 200
    // })
    columns.push({
      field: "createdAt",
      headerName: "Created",
      type: "date",
      width: 150,
      valueGetter: ({ value }) => value && new Date(value as string),
    })
    columns.push({
      field: "updatedAt",
      headerName: "Updated",
      type: "date",
      width: 150,
      valueGetter: ({ value }) => value && new Date(value as string),
    })
    columns.push({
      field: "status",
      headerName: "Status",
      // type: "date",
      width: 200,
      // valueGetter: ({ value }) => value && new Date(value as string),
    })

    // setColumns(columns)
    return columns 
  }

  const makeRows = () => {
    const rows: GridRowData[] = serviceRequests.map((serviceRequest: ServiceRequest) => {
      const row: IRowViewModel = {
        id: serviceRequest.id,
        name: serviceRequest.name,
        serviceRequestTypeString: serviceRequest.requestTypeToString(),
        serviceRequestType: serviceRequest.serviceRequestType,
        // project: "", // TODO: Add project name 
        // location: serviceRequest.location?.name,
        createdAt: serviceRequest.createdAt,
        updatedAt: serviceRequest.updatedAt,
        status: serviceRequest.status
      }
      if (userStore.isAdminOrAgent) {
        row["company"] = serviceRequest.account?.name
      }
      return row 
    })

    // setRows(rows)
    return rows 
  }

  return (
    <DashboardPanel
      title="Service Requests"
      elevation={0}
      showNavButton={showNavButton}
      handleNavButton={handleNavButton}
    >
      <Box 
        display="flex" 
        flexGrow={1}
        // height="100%"
        width="100%" 
        height={ compact ? "400px" : "650px" } 
      >
        <DataGrid 
          // autoHeight 
          // page={page}
          // pageSize={5}
          loading={isLoading}
          autoPageSize
          pagination
          rows={makeRows()}
          columns={makeColumns()}
          onRowClick={(params: GridRowParams, event: MuiEvent<any>, details?: any) => {
            const { id } = params 
            const serviceRequest = serviceRequests.find(serviceRequest => serviceRequest.id === id)
            if (serviceRequest!.status === ServiceRequestStatus.Draft) {
              const pathname = `${Routes.serviceRequestCreate}?serviceRequestId=${id}`
              ControlTower.route(pathname)
            } else {
              setServiceRequest(serviceRequest!)  
            }
           }}
          components={{
            NoRowsOverlay: () => {
              return (
                <GridOverlay>
                  <Typography>No service requests. Please use the Create Request button to start a new service request.</Typography>
                </GridOverlay>
              )
            },
            LoadingOverlay: () => {
              return (
                <Box
                  pt={10}
                >
                  <LoadingPanel />
                </Box>
              )
            } 
          }}
          className={classes.dataGrid}
        />
      </Box>
      { renderServiceRequestLargeDrawer() }
    </DashboardPanel>
  )
}

export default ServiceRequestsList
