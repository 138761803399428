import { 
  Container,
  // Stack, 
} from "@material-ui/core"
import IndustryCard, { IIndustry } from "./IndustryCard";
import Stack from "../Stack";

const IndustryList = ({
  industries
}: {
  industries: IIndustry[]
}) => {
  return (
    <Container
      maxWidth="sm"
    >
      <Stack
        direction="column"
        spacing={3}
      >
        { industries.map((industry, index) => {
          return (
            <IndustryCard 
              key={`industry/${index}`}
              name={industry.name}
              // description=""
              route={industry.route}
              icon={industry.icon}
            />
          )
        })}
      </Stack>
    </Container>
  )
}

export default IndustryList
