import JobPost from "./JobPost";
import Location from "./Location";

class Contract {
  id: string
  createdAt: string
  updatedAt: string
  accountId: string
  name: string
  number: string
  issuedBy: string
  locationId: string
  location?: Location
  startDate: string
  endDate: string
  perDiem: number
  jobPosts: JobPost[]
  value: string 
  category: string 

  constructor (data: any) {
    this.id = data.id
    this.createdAt = data.createdAt
    this.updatedAt = data.updatedAt
    this.accountId = data.accountId
    this.name = data.name
    this.number = data.number
    this.issuedBy = data.issuedBy
    this.locationId = data.locationId
    this.location = data.location ? new Location(data.location) : undefined
    this.startDate = data.startDate
    this.endDate = data.endDate
    this.perDiem = data.perDiem
    this.jobPosts = []
    if (data.jobPosts && data.jobPosts.items) {
      this.loadJobPosts(data.jobPosts.items)
    }
    this.value = data.value 
    this.category = data.category
  }

  loadJobPosts(items: any[]) {
    this.jobPosts = items.map((item: any) => new JobPost(item))
  }

  isActive(): boolean {
    // TODO: Determine if the contract is still active. 
    return true 
  }
}

export default Contract