/* eslint-disable @typescript-eslint/no-unused-vars */
import * as React from 'react'
import {createStyles, isWidthDown, Theme, withStyles, WithStyles, withWidth, WithWidth} from "@material-ui/core";
import LocationAPI from "../../apis/LocationAPI";
import ReactMapGL, {Marker, NavigationControl} from "react-map-gl"
import BluePin from '../../images/map/blue-pin.svg'
import BluePinHighlighted from '../../images/map/blue-pin-highlighted.svg'
import {inject, observer} from "mobx-react";
import {makeObservable, observable} from "mobx";
import {MapEvent} from "react-map-gl/src/components/interactive-map";
import {LocationOpportunityPosts} from "../../stores/OpportunityStore";

const styles = (theme: Theme) => createStyles({
  marker: {
    zIndex: 900,
    cursor: "pointer"
  },
  markerTop: {
    zIndex: 1000,
    cursor: "pointer"
  },
  markerLabel: {
    position: "absolute",
    top: "7%",
    width: 50,
    fontFamily: [
      'Roboto',
      'sans-serif'
    ].join(','),
    fontSize: 24,
    fontWeight: 600,
    color: theme.palette.primary.contrastText,
    textAlign: "center"
  },
  markerLabelSm: {
    position: "absolute",
    top: "8%",
    width: 24,
    fontFamily: [
      'Roboto',
      'sans-serif'
    ].join(','),
    fontSize: 11,
    fontWeight: 800,
    color: theme.palette.primary.contrastText,
    textAlign: "center"
  }
})

interface IJobMapProps {
  locationAPI?: LocationAPI,
  viewport: any,
  locationOpportunityPosts?: LocationOpportunityPosts[]
  selectedLocationId?: string
  onViewportChange(viewport: any, map: any): void
  onClickLocation(locationId?: string): void
  splitLocationIdDigit?: number
}

@inject("locationAPI")
@observer
class OpportunityMap extends React.Component<WithStyles<typeof styles> & IJobMapProps & WithWidth> {

  @observable locationOpportunityPosts?: LocationOpportunityPosts[] = []
  @observable selectedLocationId?: string

  mapRef: any

  constructor(props: any) {
    super(props);
    makeObservable(this);
  }

  componentDidMount() {
    this.locationOpportunityPosts = this.props.locationOpportunityPosts
    this.selectedLocationId = this.props.selectedLocationId
  }

  componentDidUpdate(prevProps: any) {
    if (this.props.locationOpportunityPosts !== prevProps.locationOpportunityPosts) {
      this.locationOpportunityPosts = this.props.locationOpportunityPosts
    }
    if (this.props.selectedLocationId !== prevProps.selectedLocationId) {
      this.selectedLocationId = this.props.selectedLocationId
    }
  }


  render() {
    const { locationAPI, viewport, width } = this.props

    const height = (isWidthDown('xs', width)) ? "calc(100vh - 182px)" : "calc(100vh - 210px)"

    if (locationAPI!.credentials) {
      return (
        <ReactMapGL
          {...viewport}
          ref={map => this.mapRef = map}
          width="100%"
          height={height}
          transformRequest={locationAPI!.transformRequest()}
          mapStyle={locationAPI!.mapName}
          onViewportChange={this.onViewportChange}
          onClick={this.onClick}
        >
          {this.renderMarkers()}
          <div style={{ position: "absolute", left: 20, top: 20 }}>
            {/* react-map-gl v5 doesn't support dragging the compass to change bearing */}
            <NavigationControl showCompass={false} />
          </div>
        </ReactMapGL>
      )
    }

    return null
  }

  renderMarkers = () => {
    const { classes, splitLocationIdDigit } = this.props

    const markers: any = []

    if (this.mapRef) {
      // const map = this.mapRef.getMap()
      // const zoom = map.getZoom()
      
      if (this.locationOpportunityPosts && this.locationOpportunityPosts.length > 0) {
        this.locationOpportunityPosts.forEach((item: LocationOpportunityPosts) => {
          const location = item.location
          const count = item.opportunityPosts.length
          let marker

          if (this.selectedLocationId) {
            let getSelectedLocationId = this.selectedLocationId.substring(0, splitLocationIdDigit);
            let getLocationId = location.id.substring(0, splitLocationIdDigit);
            if(getSelectedLocationId === getLocationId) {
              this.selectedLocationId = location.id;
            }
          }
          
          if (this.selectedLocationId === location.id) {
            marker = <Marker longitude={location.longitude} latitude={location.latitude}
                             className={classes.marker} offsetLeft={-26} offsetTop={-63}
                             key={location.id}>
                      <div className={classes.markerTop} onClick={(event: any) => this.onClickLocation(event, location.id)}>
                        <img src={BluePinHighlighted} alt=""/>
                        <div className={classes.markerLabel}>{count > 0 ? count : ""}</div>
                      </div>
                    </Marker>
          } else if (count > 0) {
            marker = <Marker longitude={location.longitude} latitude={location.latitude}
                             className={classes.marker} offsetLeft={-12} offsetTop={-24}
                             key={location.id}>
                        <div className={classes.marker} onClick={(event: any) => this.onClickLocation(event, location.id)}>
                          <img src={BluePin} alt=""/>
                          <div className={classes.markerLabelSm}>{count}</div>
                        </div>
                      </Marker>

          }
          // Check for dups
          if (marker && markers.findIndex((marker: any) => marker.key === location.id) < 0) {
            markers.push(marker)
          }
        })
      }
    }

    return markers
  }

  onViewportChange = (viewport: any) => {
    if (this.mapRef) {
      const map = this.mapRef.getMap()
      const bounds = map.getBounds()
      this.props.onViewportChange(viewport, bounds)
    }
  }

  onClick = (evt: MapEvent) => {
    if (this.props.onClickLocation) {
      this.props.onClickLocation()
    }
  }

  onClickLocation = (event: any, locationId: string) => {
    // console.log(`onClickLocation: ${locationId}`)
    if (this.props.onClickLocation) {
      this.props.onClickLocation(locationId)
    }
    event.stopPropagation()
  }

}

export default withStyles(styles)(withWidth()(OpportunityMap))