import {
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormGroup,
  InputAdornment,
  Theme,
  withStyles,
  WithStyles
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import {computed, makeObservable, observable} from 'mobx';
import {inject, observer} from 'mobx-react';
import * as React from 'react';
import {
  CreateProfileInput,
  CreateUserInput,
  ModelUserFilterInput,
  ProfileStatus,
  UpdateProfileInput,
  UpdateUserInput,
  UserRole,
  UserStatus
} from '../../../API';
import IconicButton from '../../../components/controls/IconicButton';
import FormGroupSpacer from '../../../components/form/FormGroupSpacer';
import FormValidator from '../../../components/form/FormValidator';
import ProgressButton from '../../../components/form/ProgressButton';
import StateSelector from '../../../components/form/StateSelector';
import TextFieldValidator from '../../../components/form/TextFieldValidator';
import Notify from '../../../components/notify/Notify';
import User from '../../../model/User';
import UserStore from '../../../stores/UserStore';
import EmailChangeDialog from '../../profile/change-dialogs/EmailChangeDialog';
import PasswordChangeDialog from '../../profile/change-dialogs/PasswordChangeDialog';
import SimpleSelect from "../../../components/controls/SimpleSelect";
import AccountStore, {AccountStoreConstants} from "../../../stores/AccountStore";
import Account from "../../../model/Account";
import AccountFilter from "../../../components/filter/AccountFilter";
import CancelButton from "../../../components/form/CancelButton";
import {getErrorMessage, phoneToE164Format, phoneToNationalFormat} from "../../../stores/StoreUtilities";
import DialogButton from "../../../components/form/DialogButton";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import PhoneChangeDialog from "../../profile/change-dialogs/PhoneChangeDialog";
import Logger from "../../../components/Logger";
import ProfileStore from "../../../stores/ProfileStore";
import Visible from "../../../components/Visible";
import IndustrySelector from "../../../components/form/IndustrySelector";
import Profile from "../../../model/Profile";
import isEqual from "lodash.isequal";

const styles = (theme: Theme) => createStyles({
  content: {
    display: "flex",
    flexGrow: 1,
    flexDirection: "column",
    justifyItems: 'center',
    alignItems: "center",
  },
  wrapper: {
    maxWidth: theme.breakpoints.values.sm,
  },
  formGroupRow: {
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "nowrap",
    flexDirection: "row"
  },
  formGroupField: {
    flexGrow: 1,
  },
  postalCodeField: {
    flexGrow: 0,
    width: 100
  },
  stateSelector: {
    minWidth: 60
  },
  progressButton: {
    minWidth: 80
  },
  accountFilter: {
    width: "100%"
  },
  fieldButton: {
    color: theme.palette.secondary.main,
    paddingLeft: 0,
    paddingTop: 0,
    marginTop: 0,
    marginRight: theme.spacing(2)
  },
})

class UserSettingsViewModel {
  @observable id: string = ''
  @observable account?: Account
  @observable firstName: string = ''
  @observable lastName: string = ''
  @observable email: string = ''
  @observable phone: string = ''
  @observable address: string = ''
  @observable city: string = ''
  @observable state: string = ''
  @observable postalCode: string = ''
  @observable role?: UserRole
  @observable userStatus: UserStatus = UserStatus.Invited
  @observable profile?: Profile
  @observable industries: string[] = []

  constructor(user?: User) {
    makeObservable(this)
    if (user) {
      this.id = user.id
      this.account = user.account
      this.firstName = user.firstName
      this.lastName = user.lastName
      this.email = user.email
      this.phone = phoneToNationalFormat(user.phone)
      this.address = user.address
      this.city = user.city
      this.state = user.state
      this.postalCode = user.postalCode
      this.role = user.role
      this.userStatus = user.userStatus
      this.profile = user.profile
      this.industries = user.profile ? user.profile.industries : []
    }
  }
}

export interface IUserSettingsDialogProps {
  open?: boolean
  user?: User
  accounts?: Account[]
  onClose?(): void
  onCreate?: (user: User) => any
  onUpdate?: (user: User) => any

  userStore?: UserStore
  accountStore?: AccountStore
  profileStore?: ProfileStore
  notify?: Notify
}

@inject('userStore', 'accountStore', "profileStore", 'notify')
@observer
class UserSettingsDialog extends React.Component<WithStyles<typeof styles> & IUserSettingsDialogProps> {
  @observable openDialog: React.ReactNode = null
  @observable viewModel?: UserSettingsViewModel
  @observable isProcessing: boolean = false

  @computed get isUpdating() {
    return Boolean(this.props.user)
  }
  @computed get isCreating() {
    return Boolean(!this.props.user)
  }

  constructor(
    props: WithStyles<typeof styles> & IUserSettingsDialogProps
  ) {
    super(props);
    makeObservable(this);
  }

  async componentDidMount() {
    const { accountStore, accounts, user, profileStore } = this.props

    this.viewModel = new UserSettingsViewModel(user)
    if (!this.viewModel.account) {
      if (accounts && accounts.length > 0) {
        this.viewModel.account = accounts[0]
      } else {
        this.viewModel.account = accountStore?.account
      }
    }
    if (!this.viewModel.role) {
      if (this.viewModel.account && this.viewModel.account.id === AccountStoreConstants.PRIMARY_ACCOUNT_ID) {
        this.viewModel.role = UserRole.Agent
      } else {
        this.viewModel.role = UserRole.Employer
      }
    }

    if (user && (user.isApplicant || user.isCandidate) && !user.profile) {
      this.viewModel!.profile = await profileStore!.getUserProfile(user.id)
      if (this.viewModel!.profile) {
        this.viewModel.industries = this.viewModel!.profile.industries ?? []
      }
    }

  }

  handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const name = event.target.name
    this.viewModel![name] = event.target.value
  }

  handleEmailChange = async (value: any) => {
    const { userStore, notify } = this.props

    this.viewModel!.email = value

    // Save User.email immediately to keep it in sync with Cognito
    const updateUserInput: UpdateUserInput = {
      id: this.viewModel!.id,
      email: this.viewModel!.email
    }
    const user = await userStore!.updateUser(updateUserInput)
      .catch((error: Error) => {
        notify!.show("error", error.message)
      })
    if (user) {
      this.openDialog = null
    }
  }

  handlePhoneChange = async (value: any) => {
    const { userStore, notify } = this.props

    this.viewModel!.phone = phoneToNationalFormat(value)

    // Save User.phone immediately to keep it in sync with Cognito
    const updateUserInput: UpdateUserInput = {
      id: this.viewModel!.id,
      phone: value
    }
    const user = await userStore!.updateUser(updateUserInput)
      .catch((error: Error) => {
        notify!.show("error", error.message)
      })
    if (user) {
      this.openDialog = null
    }
  }

  handleIndustriesChange = (industries: string[]) => {
    this.viewModel!.industries =industries
  }


  handleDialogClose = () => {
    this.openDialog = null
  }

  handleSubmit = async () => {
    this.isProcessing = true
    const { userStore, accountStore, notify, onClose } = this.props
    const createUserInput: CreateUserInput = {
      accountId: this.viewModel!.account!.id,
      firstName: this.viewModel!.firstName,
      lastName: this.viewModel!.lastName,
      email: this.viewModel!.email.toLowerCase(),
      phone: this.viewModel!.phone ? phoneToE164Format(this.viewModel!.phone) : undefined,
      address: this.viewModel!.address,
      city: this.viewModel!.city,
      state: this.viewModel!.state,
      postalCode: this.viewModel!.postalCode,
      role: this.viewModel!.role
    }
    // Don't update userStatus if not set
    if (this.viewModel!.userStatus) {
      createUserInput.userStatus = this.viewModel!.userStatus
    }
    if (this.isUpdating) {
      const updateUserInput: UpdateUserInput = {
        ...createUserInput,
        accountId: undefined,
        id: this.props.user!.id,
      }
      const user = await userStore!.updateUser(updateUserInput)
      if (user) {
        await this.updateRole(user, this.props.user)
        if (this.viewModel!.profile && (user.isApplicant || user.isCandidate)) {
          await this.updateProfile(this.viewModel!.profile)
        }
        this.isProcessing = false
        this.viewModel = undefined
        if (this.props.onUpdate) {
          this.props.onUpdate(user)
        }
        this.props.notify!.show('success', 'Successfully updated user')
      }
      if (onClose) {
        onClose()
      }
    } else if (this.isCreating) {
      // Check for existing user
      const filter: ModelUserFilterInput = {
        email: {eq: createUserInput.email}
      }
      const existingUsers = await accountStore!.listUsers(filter)
      if (existingUsers.length > 0) {
        notify!.show("error", "A user with this email already exists")
        this.isProcessing = false
        return
      }

      const user = await userStore!.createUser(createUserInput, false)
      if (user) {
        await this.updateRole(user, this.props.user)

        if (user.isApplicant || user.isCandidate) {
          await this.createProfile(user)
        }

        this.isProcessing = false
        this.viewModel = undefined
        if (this.props.onCreate && user) {
          this.props.onCreate(user)
        }
        this.props.notify!.show('success', 'Successfully created user')
      }
      if (onClose) {
        onClose()
      }
    }
  }

  createProfile = async (user: User) => {
    const { profileStore } = this.props

    const input: CreateProfileInput = {
      active: true,
      userId: user.id,
      nickname: user.nickname,
      profileStatus: ProfileStatus.Pending,
      alias: profileStore!.generateAlias(user),
      industries: this.viewModel!.industries ?? []
    }

    const profile = await profileStore!.createProfile(input)
      .catch((error: any) => {
        Logger.debug("createProfile error", getErrorMessage(error))
      });

    return profile
  }

  updateRole = async (updatedUser: User, prevUser?: User) => {
    const { userStore } = this.props
    if (updatedUser.role === UserRole.Admin && (!prevUser || prevUser.role !== UserRole.Admin)) {
      await userStore!.addUserToGroup(updatedUser.id, UserRole.Admin)
    } else if (updatedUser.role !== UserRole.Admin && prevUser && prevUser.role === UserRole.Admin ) {
      await userStore!.removeUserFromGroup(updatedUser.id, UserRole.Admin)
    }
  }

  onChangeAccount = (account?: Account) => {
    if (account) {
      this.viewModel!.account = account
    }
  }

  private async updateProfile(profile: Profile) {
    const { profileStore } = this.props

    if (!isEqual(profile.industries, this.viewModel!.industries)) {
      const update: UpdateProfileInput = {
        id: profile.id,
        industries: this.viewModel!.industries
      }
      const updatedProfile = await profileStore!.updateProfile(update)
      return updatedProfile
    }

    return undefined
  }

  onResendInvite = async () => {
    const { userStore, notify } = this.props
    if (this.viewModel!.userStatus === UserStatus.Registered || this.viewModel!.userStatus === UserStatus.Suspended) {
      return
    }

    // Set to Inactive, then Invited to cause resend
    const update: UpdateUserInput = {
      id: this.viewModel!.id,
      userStatus: UserStatus.Inactive,
    }

    let user = await userStore!.updateUser(update)
      .catch((err: Error) => {
        notify!.show("error", err.message)
      })

    if (user) {
      update.userStatus = UserStatus.Invited
      user = await userStore!.updateUser(update)
        .catch((err: Error) => {
          notify!.show("error", err.message)
        })

      if (user) {
        notify!.show("success", "Email invitation sent!")
      }
    }
  }

  get registerUrl() {
    const { user } = this.props
    if (user) {
      const url = new URL(window.location.href)
      return `${url.origin}/register/${user!.accountId}/${user!.id}/${user!.role}/${user!.email}`
    } else {
      return ""
    }
  }

  render() {
    const {
      accounts,
      user,
      classes,
      userStore,
      open,
      notify,
      onClose,
    } = this.props;
    const { viewModel } = this

    if (!viewModel) {
      return null
    }

    const isAdminOrAgent = userStore!.isAdminOrAgent

    const addressFieldsRequired = viewModel.role && ![UserRole.Agent, UserRole.Admin, UserRole.Employer].includes(viewModel.role)
    let roles: UserRole[] = []
    if (viewModel.account!.id === AccountStoreConstants.PRIMARY_ACCOUNT_ID) {
      if (userStore!.isAdmin) {
        roles = [UserRole.Admin, UserRole.Agent, UserRole.Applicant, UserRole.Candidate, UserRole.Employer]
      } else if (userStore!.isAgent) {
        roles = [UserRole.Agent, UserRole.Applicant, UserRole.Candidate, UserRole.Employer]
      }else {
        roles = [viewModel.role!]
      }
    } else {
      roles = [viewModel.role!]
    }

    let statuses: UserStatus[] = []
    if (!user || user.userStatus === UserStatus.Inactive || user.userStatus === UserStatus.Invited) {
      statuses = [UserStatus.Inactive, UserStatus.Invited]
    } else if (user.userStatus === UserStatus.Registered || user.userStatus === UserStatus.Suspended) {
      statuses = [UserStatus.Registered, UserStatus.Suspended]
    }

    const isSelf = this.viewModel && this.viewModel.id === userStore!.user!.id

    return (
      <div>
        <Dialog open={!this.openDialog && Boolean(open)} fullWidth maxWidth="sm">
          <DialogTitle>User Settings</DialogTitle>
          <FormValidator onSubmit={this.handleSubmit} autoComplete="off" name="userSettingsForm" id="userSettingsForm">
            <DialogContent className={classes.content}>
              <div className={classes.wrapper}>
                {accounts && this.isCreating &&
                <AccountFilter
                  accounts={accounts}
                  value={viewModel.account}
                  onSelectAccount={this.onChangeAccount}
                  variant="standard"
                  className={classes.accountFilter}
                />
                }
                <TextFieldValidator
                  type="text"
                  validators={{ required: true }}
                  name="firstName"
                  label="First Name"
                  value={viewModel.firstName}
                  onChange={this.handleChange}
                  fullWidth
                />
                <TextFieldValidator
                  type="text"
                  validators={{ required: true }}
                  name="lastName"
                  label="Last Name"
                  value={viewModel.lastName}
                  onChange={this.handleChange}
                  fullWidth
                />
                <TextFieldValidator
                  type="text"
                  validators={{ required: true, isEmail: null }}
                  name="email"
                  label="Email"
                  value={viewModel.email}
                  fullWidth
                  disabled={this.isUpdating}
                  {...this.isUpdating && isSelf
                    ? { // updating
                      InputProps: {
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconicButton onClick={() => {
                              this.openDialog = (
                                <EmailChangeDialog
                                  oldEmail={viewModel.email}
                                  onChange={this.handleEmailChange}
                                  onClose={this.handleDialogClose}
                                />
                              )
                            }}>
                              <EditIcon />
                            </IconicButton>
                          </InputAdornment>
                        ),
                      }
                    }
                    : { // creating
                      onChange: this.handleChange,
                    }}
                />
                {this.isUpdating && (viewModel.userStatus === UserStatus.Inactive || viewModel.userStatus === UserStatus.Invited) &&
                  <React.Fragment>
                    <DialogButton variant="tertiary" onClick={this.onResendInvite} customClassName={classes.fieldButton}>
                      Resend Invitation
                    </DialogButton>
                    <CopyToClipboard
                      text={this.registerUrl}
                      onCopy={() => notify!.show('success', 'Copied invitation link to clipboard!')}>
                      <DialogButton variant="tertiary" customClassName={classes.fieldButton}>
                        Copy Invitation
                      </DialogButton>
                    </CopyToClipboard>
                  </React.Fragment>
                }
                {this.isUpdating && viewModel.userStatus !== UserStatus.Invited && (
                  <TextFieldValidator
                    type="password"
                    label="Password"
                    value="**********"
                    fullWidth
                    disabled={this.isUpdating}
                    {...this.isUpdating && isSelf
                      ? { // updating
                        InputProps: {
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconicButton onClick={() => {
                                this.openDialog = <PasswordChangeDialog onClose={this.handleDialogClose} />
                              }}>
                                <EditIcon />
                              </IconicButton>
                            </InputAdornment>
                          ),
                        }
                      } : null }
                  />
                )}
                <TextFieldValidator
                  type="text"
                  validators={{ required: addressFieldsRequired, isMobilePhone: null }}
                  name="phone"
                  label="Phone"
                  value={viewModel.phone}
                  fullWidth
                  disabled={this.isUpdating}
                  {...this.isUpdating && isSelf
                    ? { // updating
                      InputProps: {
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconicButton onClick={() => {
                              this.openDialog = (
                                <PhoneChangeDialog
                                  oldPhone={viewModel.phone}
                                  onChange={this.handlePhoneChange}
                                  onClose={this.handleDialogClose}
                                />
                              )
                            }}>
                              <EditIcon />
                            </IconicButton>
                          </InputAdornment>
                        ),
                      }
                    }
                    : { // creating
                      onChange: this.handleChange,
                    }}
                />
                <TextFieldValidator
                  type="text"
                  validators={{ required: false }}
                  name="address"
                  label="Address"
                  value={viewModel.address}
                  onChange={this.handleChange}
                  fullWidth
                />
                <FormGroup row >
                  <TextFieldValidator
                    type="text"
                    validators={{ required: addressFieldsRequired }}
                    name="city"
                    label="City"
                    value={viewModel.city}
                    onChange={this.handleChange}
                    className={classes.formGroupField}
                  />
                  <FormGroupSpacer />
                  <StateSelector
                    isRequired={addressFieldsRequired}
                    value={viewModel.state}
                    onChange={this.handleChange}
                    className={classes.stateSelector}
                  />
                  <FormGroupSpacer />
                  <TextFieldValidator
                    type="text"
                    validators={{ required: addressFieldsRequired, isPostalCode: null }}
                    name="postalCode"
                    label="ZIP"
                    value={viewModel.postalCode}
                    onChange={this.handleChange}
                    className={classes.postalCodeField}
                  />
                </FormGroup>
                <FormGroup row>
                  <SimpleSelect
                    placeholderText={"Role"}
                    selections={roles}
                    value={viewModel.role}
                    valueName={"role"}
                    elementId={"role"}
                    onChange={this.handleChange}
                    disabled={isSelf}
                    fullWidth={false}
                    className={classes.formGroupField}
                  />
                  <FormGroupSpacer />
                  <SimpleSelect
                    placeholderText={"Status"}
                    selections={statuses}
                    value={viewModel.userStatus}
                    valueName={"userStatus"}
                    elementId={"userStatus"}
                    onChange={this.handleChange}
                    disabled={(!userStore!.isAdminOrAgent && !userStore!.isEmployer) || isSelf}
                    fullWidth={false}
                    className={classes.formGroupField}
                  />
                </FormGroup>
                <Visible if={isAdminOrAgent && (viewModel.role === UserRole.Applicant || viewModel.role === UserRole.Candidate)} >
                  <IndustrySelector industries={viewModel.account!.industries}
                                    value={viewModel.industries} onChange={this.handleIndustriesChange} required/>
                </Visible>
              </div>
            </DialogContent>
            <DialogActions>
              <CancelButton onClick={() => { if (onClose) { onClose()}}} />
              <ProgressButton variant="contained" color="secondary"
                              type="submit" className={classes.progressButton} processing={this.isProcessing}>
                {this.isCreating ? 'Create' : 'Save'}
              </ProgressButton>
            </DialogActions>
          </FormValidator>
        </Dialog>
        {this.openDialog}
      </div>
    )
  }
}

export default withStyles(styles)(UserSettingsDialog)
