
export enum QuestionType {
  CertificationQuestion = "CertificationQuestion",
  EducationQuestion = "EducationQuestion",
  ExperienceQuestion = "ExperienceQuestion",
  LocationQuestion = "LocationQuestion",
  SecurityClearanceQuestion = "SecurityClearanceQuestion",
  YesNoQuestion = "YesNoQuestion"
}

class Question {
  name: string
  type: string
  required: boolean

  constructor (data: any) {
    this.name = data.name
    this.type = data.type
    this.required = data.required ?? false
  }
}

export default Question

export class EducationQuestion extends Question {
  degree: string

  constructor(data: any) {
    super(data)
    this.degree = data.education ?? ""
  }
}

export class CertificationQuestion extends Question {
  certification: string

  constructor(data: any) {
    super(data)
    this.certification = data.certification ?? ""
  }
}

export class ExperienceQuestion extends Question {
  minYears: number
  jobTitle: string

  constructor(data: any) {
    super(data)
    this.minYears = data.minYears ?? 0
    this.jobTitle = data.jobTitle ?? ""
  }
}

export class LocationQuestion extends Question {
  location: string

  constructor(data: any) {
    super(data)
    this.location = data.location ?? ""
  }
}

export class SecurityClearanceQuestion extends Question {
  clearance: string

  constructor(data: any) {
    super(data)
    this.clearance = data.clearance
  }
}

export class YesNoQuestion extends Question {
  question: string
  label: string

  constructor(data: any) {
    super(data)
    this.question = data.question ?? ""
    this.label = data.label ?? ""
  }
}
