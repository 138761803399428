import { blueGrey, grey, orange } from "@material-ui/core/colors"
import { createTheme } from '@material-ui/core/styles'

export const rawTheme = createTheme({
  palette: {
    type: "light",
    background: {
      default: '#E7E9EB', // '#fff', // '#EDEEF2', // '#dedada', // '#fff', // '#E1E3E5', // '#E7E1E1', // '#dedada', // blueGrey[50], // off white
      paper: '#fff' // pure white
    },
    primary: {
      main: '#0d346a', // darkish blue
      100: '#d4e3f9',
      200: '#90b8f1',
      500: '#2e507e',
      600: '#203857',
      700: '#152438'
    },
    secondary: {
      main: '#0393ee', // brightish blue
      light: '#29a8fc' //'#5dbdfc' // 
    },
    action: {
      active: '#0393ee',
      selected: '#0393ee',
      hover: '#0393ee'
    },
    warning: {
      main: orange[500],
      dark: orange[900],
      light: orange[50]
    },
    divider: grey[200], // '#D72322' // red
    text: {
      primary: '#333',
      secondary: '#777'
    },
    grey: {
      50: '#f5f7f8',
      100: '#F3F5F7',
      200: '#f5f7f9',
      300: '#e7e9eb',
      400: '#dbdee1',
      500: '#c0c5ca'
    }
  },
  typography: {
    // useNextVariants: true,
    button: {
      textTransform: 'initial',
      fontFamily: [
        'Roboto',
        'sans-serif'
      ].join(','),
      fontSize: "16px !important",
      fontWeight: 400,
      lineHeight: 1.5,
      letterSpacing: "0.00938em"
    },
    h1: {
      fontFamily: [
        'Montserrat',
        'sans-serif'
      ].join(',')
    },
    h2: {
      fontFamily: [
        'Montserrat',
        'sans-serif'
      ].join(',')
    },
    h3: {
      fontFamily: [
        'Montserrat',
        'sans-serif'
      ].join(',')
    },
    h4: {
      fontFamily: [
        'Montserrat',
        'sans-serif'
      ].join(',')
    },
    h5: {
      fontFamily: [
        'Montserrat',
        'sans-serif'
      ].join(',')
    },
    h6: {
      fontFamily: [
        'Montserrat',
        'sans-serif'
      ].join(','),
      fontSize: 16
    },
    body1: {
      fontFamily: [
        'Open Sans',
        'Roboto',
        'sans-serif'
      ].join(','),
      fontSize: 16
    },
    body2: {
      fontFamily: [
        'Open Sans',
        'Roboto',
        'sans-serif'
      ].join(','),
      fontSize: 14
    }
  },
  shape: {
    borderRadius: 10
  }
})

const theme = {
  ...rawTheme,
  typography: {
    ...rawTheme.typography,
    h1: {
      ...rawTheme.typography.h1,
      fontWeight: 800,
      fontSize: 44,
      [rawTheme.breakpoints.down('sm')]: {
        fontSize: 32
      },
    },
    h2: {
      ...rawTheme.typography.h2,
      fontWeight: 700,
      fontSize: 32,
      [rawTheme.breakpoints.down('sm')]: {
        fontSize: 24
      },
    },
    h3: {
      ...rawTheme.typography.h3,
      fontWeight: 600,
      fontSize: 22,
      [rawTheme.breakpoints.down('sm')]: {
        fontSize: 18
      },
    },
    h4: {
      ...rawTheme.typography.h4,
      fontWeight: 300,
      fontSize: 20,
      [rawTheme.breakpoints.down('sm')]: {
        fontSize: 18
      }
    },
    h5: {
      ...rawTheme.typography.h5,
      fontWeight: 800,
      fontSize: 16,
      [rawTheme.breakpoints.down('sm')]: {
        fontSize: 14
      },
      textTransform: 'uppercase'
    }
  },
  overrides: {
    // How to override the Box background color: 
    // MuiCssBaseline: {
    //   "@global": {
    //     ".MuiBox-root": {
    //       backgroundColor: blueGrey[50]
    //     }
    //   }
    // },
    // How to change the tabs background color: 
    // MuiTabs: {
    //   root: {
    //     backgroundColor: rawTheme.palette.common.white
    //   }
    // },
    MuiMenuItem: {
      root: {
        "&:hover": {
          backgroundColor: rawTheme.palette.secondary.main
        }
      }
    },
    MuiDialog: {
      paperFullWidth: {
        [rawTheme.breakpoints.down('xs')]: {
          margin: 0,
          width: 'calc(100% - 32px) !important',
        }
      }
    },
    MuiDialogTitle: {
      root: {
        [rawTheme.breakpoints.down('xs')]: {
          paddingLeft: rawTheme.spacing(2),
        },
        [rawTheme.breakpoints.up('sm')]: {
          paddingLeft: rawTheme.spacing(3),
        },
        backgroundColor: rawTheme.palette.primary.main,
        color: rawTheme.palette.common.white
      }
    },
    MuiDialogContent: {
      root: {
        [rawTheme.breakpoints.down('xs')]: {
          paddingLeft: rawTheme.spacing(2),
          paddingRight: rawTheme.spacing(2),
        },
        [rawTheme.breakpoints.up('sm')]: {
          paddingLeft: rawTheme.spacing(3),
          paddingRight: rawTheme.spacing(3),
        },
      }
    },
    MuiDialogActions: {
      root: {
        justifyContent: "flex-end",
        alignItems: "center",
        [rawTheme.breakpoints.down('xs')]: {
          paddingLeft: rawTheme.spacing(2),
          paddingRight: rawTheme.spacing(2),
          paddingTop: rawTheme.spacing(1),
          paddingBottom: rawTheme.spacing(1),
        },
        [rawTheme.breakpoints.up('sm')]: {
          paddingLeft: rawTheme.spacing(3),
          paddingRight: rawTheme.spacing(3),
          paddingTop: rawTheme.spacing(2),
          paddingBottom: rawTheme.spacing(2),
        },
        backgroundColor: blueGrey[100]
      }
    },
    // MuiButton: {
    //   root: {
    //     boxShadow: 'rgba(0, 0, 0, 0.25) 0px 5px 7px 0px'
    //   }
    // },
    MuiPaper: {
      elevation1: {
        boxShadow: 'rgba(0, 0, 0, 0.15) 0px 5px 10px 0px'
      }, 
      elevation2: {
        boxShadow: 'rgba(149, 157, 165, 0.5) 0px 0px 17px'
      }
      // root: {

      // }
    },
    MuiTableCell: {
      root: {
        borderBottom: '1px solid #ececec',
      },
    },
    MuiButton: {
      root: {
        fontFamily: [
          'Open Sans',
          'Roboto',
          'sans-serif'
        ].join(','),
        fontSize: 16
      }
    },
    MuiLink: {
      root: {
        fontSize: 16 
      }
    }
  }
}

export default theme 
