import { useState } from "react"
import SimpleForm from "../../components/form/SimpleForm"
import DialogPanel from "../../components/panel/DialogPanel"
import DialogPanelButtons from "../../components/panel/DialogPanelButtons"
import ServiceRequest from "../../model/ServiceRequest"
import SectionPanel from "../../components/panel/SectionPanel"
import DialogPanelInstructions from "../../components/panel/DialogPanelInstructions"
import { Box, Button, Grid } from "@material-ui/core"
import Add from "@material-ui/icons/Add"
import JobPostMiniCard from "../../components/jobPosts/JobPostMiniCard"
import ServiceRequestJobList from "../../views/serviceRequests/ServiceRequestJobList"
import RequestMeetingSection from "../../components/marketing/sections/RequestMeetingSection"

const ServiceRequestJobsListForm = ({
  serviceRequest,
  onClickBack,
  onClickAddJob,
  onEditJobPost,
  onClickNext
}: {
  serviceRequest: ServiceRequest
  onClickBack: () => void 
  onClickAddJob: () => void 
  onEditJobPost: (jobPostId: string) => void 
  onClickNext: () => Promise<void>
}) => {
  const [isProcessing, setIsProcessing] = useState(false)
  
  const renderJobPostsSection = () => {
    return (
      <SectionPanel
        title="Jobs"
        elevation={0}
        button={
          <Button
            variant="contained"
            color="secondary"
            onClick={onClickAddJob}
            startIcon={<Add />}
          >
            Add Job
          </Button>
        }
      >
        <ServiceRequestJobList 
          serviceRequest={serviceRequest}
          onClickEditJobPost={onEditJobPost}
        />
      </SectionPanel>
    )
  }

  return (
    <DialogPanel>
      <DialogPanelInstructions 
        title="Position Information" 
        instructions={[
          "Add more positions below.",
          "Or, press Next to continue to the next step."
        ]}        
      />
      { renderJobPostsSection() }
      <DialogPanelButtons
        isProcessing={isProcessing}
        buttonDisabled={false}
        // onClickNext={() => {
        //   setStep(RequestStep.EnterJob)
        // }} 
        onClickBack={onClickBack} 
        onClickNext={async () => {
          setIsProcessing(true)
          onClickNext()
        }}
      />
      <RequestMeetingSection />
    </DialogPanel>
  )
}

export default ServiceRequestJobsListForm 
