import {Storage} from "aws-amplify";
import {endOfMonth, endOfYear} from "date-fns";
import {makeObservable, observable} from "mobx";
import * as APITypes from "../API";
import {AddressType, SecurityStatus, WorkSetting} from "../API";
import GovGigAPI from "../apis/GovGigAPI";
import AppConfig from "../AppConfig";
import degreeOptionData from "../data/degrees.json";
import Certification from "../model/Certification";
import Education from "../model/Education";
import Experience from "../model/Experience";
import Location from "../model/Location";
import Profile from "../model/Profile";
import ProfileLocation from "../model/ProfileLocation";
import ProfileService from "../model/ProfileService";
import User from "../model/User";
import {createUUID, getISODateFromDate, getISODateToday, isoToLocalDate} from './StoreUtilities';
import ResourceCache from "./ResourceCache";
import CacheStore from "./CacheStore";
import {LngLat, LngLatBounds, LngLatLike} from "mapbox-gl";
import get from "lodash.get";
import { IFileStorageLevel } from "../components/file/FileDialog";

export interface IProfileSearchOptions {
  industry?: string
  workSetting?: WorkSetting
  securityClearance?: string
  securityStatus?: SecurityStatus
  certificationType?: string
}


export default class ProfileStore {
  govGigAPI: GovGigAPI
  resourceCache: ResourceCache
  profileCache?: ProfilesCacheStore
  locationProfilesCache?: LocationProfilesCacheStore

  @observable isLoading: boolean = false

  constructor(options: any) {
    makeObservable(this);
    this.govGigAPI = (options && options.govGigAPI) ? options.govGigAPI : null
    this.resourceCache = (options && options.resourceCache) ? options.resourceCache : null
    if (this.resourceCache) {
      console.log(`ProfileStore resourceCache initialized`)
    } else {
      console.log(`ProfileStore resourceCache not initialized`)
    }
    this.profileCache = new ProfilesCacheStore(this)
    this.locationProfilesCache = new LocationProfilesCacheStore(this)
  }

  // Profile methods

  listProfiles = async (filter?: APITypes.ModelProfileFilterInput): Promise<Profile[]> => {
    let profiles: Profile[] = []

    let data
    let nextToken: string | undefined

    do {
      data = await this.govGigAPI.listProfiles(filter, 1000, nextToken)
      if (data && data.items) {
        data.items.forEach((item: any) => {
          const profile = new Profile(item)
          this.resourceCache.loadServicesForProfile(profile)
          this.resourceCache.loadLocationsForProfile(profile)
          profiles.push(profile)
        })
        nextToken = data.nextToken ?? undefined
      }
    } while (data && nextToken)

    return profiles
  }

  listProfilesByLocation = async (locationId: string, filter?: APITypes.ModelProfileServiceFilterInput): Promise<Profile[]> => {
    let profiles: Profile[] = []

    const data = await this.govGigAPI.listProfilesByLocation(locationId, filter)
    if (data && data.items) {
      data.items.forEach((item: any) => {
        if (item.profile) {
          const profile = new Profile(item.profile)
          this.resourceCache.loadServicesForProfile(profile)
          this.resourceCache.loadLocationsForProfile(profile)
          profiles.push(profile)
        }
      })
    }

    return profiles
  }



  listProfilesByService = async (serviceId: string, filter?: APITypes.ModelProfileServiceFilterInput): Promise<Profile[]> => {
    let profiles: Profile[] = []

    const data = await this.govGigAPI.listProfilesByService(serviceId, filter)
    if (data && data.items) {
      data.items.forEach((item: any) => {
        if (item.profile) {
          const profile = new Profile(item.profile)
          this.resourceCache.loadServicesForProfile(profile)
          this.resourceCache.loadLocationsForProfile(profile)
          profiles.push(profile)
        }
      })
    }

    return profiles
  }

  // Find in cache
  findProfilesByService = async (serviceId: string, options: IProfileSearchOptions = {}) => {
    const key = `${serviceId ?? ''}/${options.industry ?? ''}`
    let profiles = await this.profileCache!.get(key) ?? []

    if (options.workSetting || options.securityClearance || options.certificationType) {
      profiles = profiles.filter((profile: Profile) => {
        return ((!options.workSetting || profile.workSettings.includes(options.workSetting!)) &&
          (!options.securityClearance || (profile.securityClearance && profile.securityClearance.startsWith(options.securityClearance))) &&
          (!options.certificationType || profile.findCertification(options.certificationType))
        )
      })
    }

    return profiles
  }

  // Find in cache
  findProfilesByServiceAndLocation = async (serviceId: string, locationId: string) => {
    const profiles = await this.profileCache!.get(serviceId)

    if (profiles) {
      return profiles.filter((profile: Profile) => {
        return profile.profileLocations.findIndex((pl: ProfileLocation) => pl.locationId === locationId) >= 0
      })
    } else {
      return []
    }
  }

  // 80467.2 is 50 miles in meters 
  listProfilesByLocationRadius = async (locationId: string, serviceId?: string, radius: number = 80467.2) => {
    const profiles: Profile[] = []

    const location = this.resourceCache.getLocation(locationId, true)
    if (location) {
      let locationProfiles
      if (location.addressType === AddressType.State) {
        locationProfiles = await this.listLocationProfilesByState(location.state, serviceId)
      } else {
        const lngLat: LngLat = new LngLat(location.longitude, location.latitude)
        const bounds: LngLatBounds = lngLat.toBounds(radius)

        locationProfiles = await this.listLocationProfilesByBounds(bounds, serviceId)
      }
      locationProfiles.forEach((locationProfiles: LocationProfiles) => {
        // Filter duplicates
        locationProfiles.profiles.forEach((p: Profile) => {
          if (profiles.findIndex((item: Profile) => item.id === p.id) < 0) {
            profiles.push(p)
          }
        })
      })
    }

    return profiles
  }

  listLocationProfilesByBounds = async (bounds: LngLatBounds, serviceId?: string, options: IProfileSearchOptions = {}) => {
    const locations = this.getLocationsInBounds(bounds)

    const locationProfiles: LocationProfiles[] = []

    if (serviceId) {
      // Cache profiles for this service (in the ProfileStore)
      const cache = await this.findProfilesByService(serviceId, options)

      // Group profiles by location (LocationProfile)
      locations.forEach((location: Location) => {
        const profiles = cache.filter((p: Profile) => {
          return p.profileLocations.findIndex((pl: ProfileLocation) => pl.locationId === location.id) >= 0
        })
        if (profiles.length > 0) {
          locationProfiles.push(new LocationProfiles(location, profiles))
        }
      })
    } else {
      const promises: Promise<Profile[] | undefined>[] = []
      locations.forEach((location: Location) => {
        const key = location.id
        promises.push(this.locationProfilesCache!.get(key))
      })

      const profiles = await Promise.all(promises)

      for (let i = 0; i < profiles.length; i++) {
        locationProfiles.push(new LocationProfiles(locations[i], profiles[i] ?? []))
      }
    }

    return locationProfiles
  }

  getLocationsInBounds = (bounds: LngLatBounds) => {
    const locations: Location[] = []

    this.resourceCache!.locations.forEach((location: Location) => {
      const lnglat: LngLatLike = {lng: location.longitude, lat: location.latitude}
      if (bounds.contains(lnglat)) {
        locations.push(location)
      }
    })

    return locations
  }

  listLocationProfilesByState = async (state: string, serviceId?: string, options: IProfileSearchOptions = {}) => {
    const locations = this.getLocationsInState(state)

    const locationProfiles: LocationProfiles[] = []

    if (serviceId) {
      // Cache profiles for this service (in the ProfileStore)
      const cache = await this.findProfilesByService(serviceId, options)

      // Group profiles by location (LocationProfile)
      locations.forEach((location: Location) => {
        const profiles = cache.filter((p: Profile) => {
          return p.profileLocations.findIndex((pl: ProfileLocation) => pl.locationId === location.id) >= 0
        })
        if (profiles.length > 0) {
          locationProfiles.push(new LocationProfiles(location, profiles))
        }
      })
    } else {
      const promises: Promise<Profile[] | undefined>[] = []
      locations.forEach((location: Location) => {
        const key = location.id
        promises.push(this.locationProfilesCache!.get(key))
      })

      const profiles = await Promise.all(promises)

      for (let i = 0; i < profiles.length; i++) {
        locationProfiles.push(new LocationProfiles(locations[i], profiles[i] ?? []))
      }
    }

    return locationProfiles
  }

  listLocationProfilesByCountry = async (country: string, serviceId?: string, options: IProfileSearchOptions = {}) => {
    const locations = this.getLocationsInCountry(country)

    const locationProfiles: LocationProfiles[] = []

    if (serviceId) {
      // Cache profiles for this service (in the ProfileStore)
      const cache = await this.findProfilesByService(serviceId, options)

      // Group profiles by location (LocationProfile)
      locations.forEach((location: Location) => {
        const profiles = cache.filter((p: Profile) => {
          return p.profileLocations.findIndex((pl: ProfileLocation) => pl.locationId === location.id) >= 0
        })
        if (profiles.length > 0) {
          locationProfiles.push(new LocationProfiles(location, profiles))
        }
      })
    } else {
      const promises: Promise<Profile[] | undefined>[] = []
      locations.forEach((location: Location) => {
        const key = location.id
        promises.push(this.locationProfilesCache!.get(key))
      })

      const profiles = await Promise.all(promises)

      for (let i = 0; i < profiles.length; i++) {
        locationProfiles.push(new LocationProfiles(locations[i], profiles[i] ?? []))
      }
    }

    return locationProfiles
  }

  listLocationProfilesByService = async (serviceId: string, options: IProfileSearchOptions = {}) => {
    const locationProfiles: LocationProfiles[] = []

    if (serviceId) {
      // Cache profiles for this service (in the ProfileStore)
      const cache = await this.findProfilesByService(serviceId, options)

      // Group profiles by location (LocationProfile)
      cache.forEach((profile: Profile) => {
        profile.profileLocations.forEach((profileLocation: ProfileLocation) => {
          if (profileLocation.location) {
            let locationProfile = locationProfiles.find((locationProfile: LocationProfiles) => locationProfile.location.id === profileLocation.locationId)
            if (!locationProfile) {
              locationProfile = new LocationProfiles(profileLocation.location, [profile])
              locationProfiles.push(locationProfile)
            } else {
              locationProfile.profiles.push(profile)
            }
          }
        })
      })
    }

    return locationProfiles
  }

  getLocationsInState = (state: string) => {
    // Make sure we have the full/proper state name
    const stateName = this.resourceCache!.getStateNameFromAbbr(state)
    const locations: Location[] = []

    this.resourceCache!.locations.forEach((location: Location) => {
      if (location.state === stateName) {
        locations.push(location)
      }
    })

    return locations
  }

  getLocationsInCountry = (country: string) => {
    // Make sure we have the full/proper state name
    const locations: Location[] = []

    this.resourceCache!.locations.forEach((location: Location) => {
      if (location.country === country) {
        locations.push(location)
      }
    })

    return locations
  }

  // listProfilesByLocationAndService = async (locationId: string, serviceId: string, availableDate?: string): Promise<Profile[]> => {
  //   let profiles: Profile[] = []

  //   const data = await this.govGigAPI.listProfilesByLocation(locationId)
  //   if (data && data.items) {
  //     data.items.forEach((item: any) => {
  //       if (item.profile.profileStatus === ProfileStatus.Accepted) {
  //         if (!availableDate || !item.profile.availableDate || item.profile.availableDate <= availableDate) {
  //           const index = item.profile.profileServices.items.findIndex((svc: any) => svc.serviceId === serviceId)
  //           if (index >= 0) {
  //             const profile = new Profile(item.profile)
  //             this.resourceCache.loadServicesForProfile(profile)
  //             this.resourceCache.loadLocationsForProfile(profile)
  //             profiles.push(profile)
  //           }
  //         }
  //       }
  //     })
  //   }

  //   return profiles
  // }

  /**
   * List Profile entries by searching first on locationId, then filtering by serviceId.
   */
  listProfilesByServiceAndLocation = async (serviceId: string, locationId: string, filter?: APITypes.ModelProfileServiceFilterInput): Promise<Profile[]> => {
    let profiles: Profile[] = []

    const data = await this.govGigAPI.listProfilesByService(serviceId, filter)
    // Filter by locationId
    if (data && data.items) {
      data.items.forEach((item: any) => {
        const foundProfile = get(item, "profile")
        const foundProfileLocations = get(item, "profile.profileLocations.items")
        if (foundProfile && foundProfileLocations && foundProfile.profileStatus === APITypes.ProfileStatus.Accepted) {
          const index = foundProfileLocations.findIndex((loc: any) => loc.locationId === locationId)
          if (index >= 0) {
            const profile = new Profile(foundProfile)
            this.resourceCache.loadServicesForProfile(profile)
            this.resourceCache.loadLocationsForProfile(profile)
            profiles.push(profile)
          }
        }
      })
    }

    return profiles
  }

  getUserProfile = async (userId?: string): Promise<Profile | undefined> => {
    if (!userId) {
      return undefined
    }

    const data = await this.govGigAPI.listProfilesByUser(userId)
    if (data && data.items && data.items.length > 0) {
      const profile = new Profile(data.items[0])
      this.resourceCache.loadServicesForProfile(profile)
      this.resourceCache.loadLocationsForProfile(profile)
      return profile
    } else {
      return undefined
    }
  }

  getAliasProfile = async (alias?: string): Promise<Profile | undefined> => {
    if (!alias) {
      return undefined
    }

    const data = await this.govGigAPI.listProfilesByAlias(alias)
    if (data && data.items && data.items.length > 0) {
      const profile = new Profile(data.items[0])
      this.resourceCache.loadServicesForProfile(profile)
      this.resourceCache.loadLocationsForProfile(profile)
      return profile
    } else {
      return undefined
    }
  }

  getProfile = async (profileId: string): Promise<Profile | undefined> => {
    const data = await this.govGigAPI.getProfile(profileId)
    if (data) {
      const profile = new Profile(data)
      this.resourceCache.loadServicesForProfile(profile)
      this.resourceCache.loadLocationsForProfile(profile)
      // Get certifications.  For some reason getting too many related items in this query causes a timeout.
      const certifications = await this.govGigAPI.listCertificationsByProfile(profileId)
      if (certifications && certifications.items) {
        profile.loadCertifications(certifications.items as any[])
      }
      return profile
    } else {
      return undefined
    }
  }

  async createProfile(input: APITypes.CreateProfileInput) {
    const data = await this.govGigAPI!.createProfile(input)
    if (data) {
      const profile = new Profile(data)
      return profile
    } else {
      return undefined
    }
  }

  async updateProfile(input: APITypes.UpdateProfileInput) {
    const data = await this.govGigAPI!.updateProfile(input)
    if (data) {
      const profile = new Profile(data)
      this.resourceCache.loadServicesForProfile(profile)
      this.resourceCache.loadLocationsForProfile(profile)
      return profile
    } else {
      return undefined
    }
  }

  // TOOD: Move these file functions out of this ProfileStore, as they are not just used for profiles. 
  async uploadFile(key: string, level: "private" | "protected" | "public", file: File) {
    await Storage.put(key, file,
      { contentType: file.type, level }
    );
    const url = Storage.get(key, { download: false });

    return url;
  }

  async removeFile(key: string, level: "private" | "protected" | "public" = 'public') {
    await Storage.remove(key, { level })
  }

  async getFileURL(key: string, level: IFileStorageLevel) {
    const url = String(await Storage.get(key, { level: level, download: false }))
    return url 
  }

  // Experience methods

  getExperience = async (experienceId: string): Promise<Experience | undefined> => {
    const data = await this.govGigAPI.getExperience(experienceId)
    if (data) {
      return new Experience(data)
    } else {
      return undefined
    }
  }

  async createExperience(input: APITypes.CreateExperienceInput) {
    const data = await this.govGigAPI!.createExperience(input)
    if (data) {
      return new Experience(data)
    } else {
      return undefined
    }
  }

  async updateExperience(input: APITypes.UpdateExperienceInput) {
    const data = await this.govGigAPI!.updateExperience(input)
    if (data) {
      const updatedExperience = new Experience(data)
      return updatedExperience
    } else {
      return undefined
    }
  }

  deleteExperience = async (experienceId: string): Promise<Experience | undefined> => {
    const data = await this.govGigAPI.deleteExperience(experienceId)
    if (data) {
      return new Experience(data)
    } else {
      return undefined
    }
  }

  // Education methods

  getEducation = async (educationId: string): Promise<Education | undefined> => {
    const data = await this.govGigAPI.getEducation(educationId)
    if (data) {
      return new Education(data)
    } else {
      return undefined
    }
  }

  async createEducation(input: APITypes.CreateEducationInput) {
    const data = await this.govGigAPI!.createEducation(input)
    if (data) {
      return new Education(data)
    } else {
      return undefined
    }
  }

  async updateEducation(input: APITypes.UpdateEducationInput) {
    const data = await this.govGigAPI!.updateEducation(input)
    if (data) {
      const updatedEducation = new Education(data)
      return updatedEducation
    } else {
      return undefined
    }
  }

  deleteEducation = async (educationId: string): Promise<Education | undefined> => {
    const data = await this.govGigAPI.deleteEducation(educationId)
    if (data) {
      return new Education(data)
    } else {
      return undefined
    }
  }

  // Certification methods

  getCertification = async (certificationId: string): Promise<Certification | undefined> => {
    const data = await this.govGigAPI.getCertification(certificationId)
    if (data) {
      return new Certification(data)
    } else {
      return undefined
    }
  }

  async createCertification(input: APITypes.CreateCertificationInput) {
    const data = await this.govGigAPI!.createCertification(input)
    if (data) {
      return new Certification(data)
    } else {
      return undefined
    }
  }

  async updateCertification(input: APITypes.UpdateCertificationInput) {
    const data = await this.govGigAPI!.updateCertification(input)
    if (data) {
      const updatedCertification = new Certification(data)
      return updatedCertification
    } else {
      return undefined
    }
  }

  async deleteCertification(certId: string) {
    const data = await this.govGigAPI!.deleteCertification(certId)
    if (data) {
      const updatedCertification = new Certification(data)
      return updatedCertification
    } else {
      return undefined
    }
  }

  // DegreeOptions methods

  getDegreeOptions = () => {
    return degreeOptionData
  }

  // ProfileLocation methods

  getProfileLocation = async (profileLocationId: string): Promise<ProfileLocation | undefined> => {
    const data = await this.govGigAPI.getProfileLocation(profileLocationId)
    if (data) {
      return new ProfileLocation(data)
    } else {
      return undefined
    }
  }

  async createProfileLocation(input: APITypes.CreateProfileLocationInput) {
    const data = await this.govGigAPI!.createProfileLocation(input)
    if (data) {
      await this.resourceCache!.activateLocation(input.locationId)
      return new ProfileLocation(data)
    } else {
      return undefined
    }
  }

  async updateProfileLocation(input: APITypes.UpdateProfileLocationInput) {
    const data = await this.govGigAPI!.updateProfileLocation(input)
    if (data) {
      await this.resourceCache!.activateLocation(input.locationId)
      const updatedProfileLocation = new ProfileLocation(data)
      return updatedProfileLocation
    } else {
      return undefined
    }
  }

  async deleteProfileLocation(id: string) {
    const data = await this.govGigAPI!.deleteProfileLocation(id)
    if (data) {
      const updatedProfileLocation = new ProfileLocation(data)
      return updatedProfileLocation
    } else {
      return undefined
    }
  }

  // ProfileService methods

  getProfileService = async (profileServiceId: string): Promise<ProfileService | undefined> => {
    const data = await this.govGigAPI.getProfileService(profileServiceId)
    if (data) {
      return new ProfileService(data)
    } else {
      return undefined
    }
  }

  async createProfileService(input: APITypes.CreateProfileServiceInput) {
    if (input.serviceId && !input.title) {
      // Add title
      const service = this.resourceCache.getService(input.serviceId)
      if (service) {
        input.title = service.name
      }
    }
    const data = await this.govGigAPI!.createProfileService(input)
    if (data) {
      return new ProfileService(data)
    } else {
      return undefined
    }
  }

  async updateProfileService(input: APITypes.UpdateProfileServiceInput) {
    if (input.serviceId && !input.title) {
      // Add title
      const service = this.resourceCache.getService(input.serviceId)
      if (service) {
        input.title = service.name
      }
    }
    const data = await this.govGigAPI!.updateProfileService(input)
    if (data) {
      const updatedProfileService = new ProfileService(data)
      return updatedProfileService
    } else {
      return undefined
    }
  }

  async deleteProfileService(id: string) {
    const data = await this.govGigAPI!.deleteProfileService(id)
    if (data) {
      const updatedProfileService = new ProfileService(data)
      return updatedProfileService
    } else {
      return undefined
    }
  }

  generateAlias(user: User) {
    return `${user.firstName}${String(user.lastName).slice(0, 1)}${createUUID().slice(0, 4)}`.replace(/ /g, '').toLowerCase();
  }

  async parseResume(resumeKey: string, level: "private" | "protected" | "public" = 'public') {
    if (!/.pdf|.docx/i.test(resumeKey)) {
      console.error('')
      throw new Error('Can only parse .docx or .pdf resumes.')
    }
    const resumeBinary = await Storage.get(resumeKey, { level, download: true }) as any
    // affinda parsing
    const API_URL = 'https://resume-parser.affinda.com/public/api/v1'
    const headers = {
      'Authorization': `Bearer ${AppConfig.affinda.apiToken}`,
      'Accept': 'application/json',
    }
    const formData = new FormData()
    formData.append('file', resumeBinary.Body)
    formData.append('fileName', 'resume.pdf')
    let res = await fetch(`${API_URL}/documents`, { method: 'POST', headers, body: formData })
    if (res.status !== 200 && res.status !== 201) {
      throw new Error('Couldn\'t parse resume.')
    }
    const id = (await res.json()).identifier

    // poll API for status of processing
    let fetching = false
    let parsedResume: object | null = null
    const retries = 10
    const everyMs = 1000

    let startTime = Date.now()
    let tries = 1;

    while (parsedResume == null && tries <= retries) {
      const elaspedMs = (Date.now() - startTime)
      if (!fetching && elaspedMs >= everyMs) {
        startTime = Date.now()
        tries += 1
        fetching = true
        res = await fetch(`${API_URL}/documents/${id}`, { headers })
        if (res.status === 200) {
          const payload = (await res.json()) as any
          if (payload.data) {
            parsedResume = payload.data
          }
        }
        fetching = false
      }
    }

    return parsedResume
  }

  async updateProfileForResume(profileId: string, resumeKey: string) {
    const profile = await this.getProfile(profileId)
    if (!profile) { throw new Error(`No profile for id ${profileId}`)}

    const resume = await this.parseResume(resumeKey) as any
    if (!resume) {
      return
    }

    const updatePromises: Promise<any>[] = []

    // profile
    updatePromises.push(this.updateProfile({
      id: profileId,
      resumeKey,
      about: profile.about ? undefined : resume.summary,
    }))

    // education
    if ((!profile.education || profile.education.length === 0) && resume.education?.length > 0) {
      for (const eRaw of resume.education) {
        updatePromises.push(this.createEducation({
          profileId,
          userId: profile.userId,
          school: eRaw.organization,
          degree: eRaw.accreditation.education,
          ...eRaw.dates && {
            startDate: eRaw.dates.startDate,
            endDate: this.parseEndDate(eRaw.dates.completionDate),
          },
        }))
      }
    }

    // work experiences
    if ((!profile.experiences || profile.experiences.length === 0) && resume.workExperience?.length > 0) {
      for (const expRaw of resume.workExperience) {
        console.log(`${expRaw.jobTitle}: ${expRaw.dates?.startDate} - ${expRaw.dates?.endDate} (${this.parseEndDate(expRaw.dates?.endDate)})`)
        updatePromises.push(this.createExperience({
          profileId,
          userId: profile.userId,
          jobTitle: expRaw.jobTitle,
          jobDescription: this.parseText(expRaw.jobDescription),
          employerName: expRaw.organization,
          ...expRaw.location && {
            employerLocation: expRaw.location.formatted,
          },
          ...expRaw.dates && {
            startDate: expRaw.dates.startDate,
            endDate: this.parseEndDate(expRaw.dates?.endDate),
          },
        }))
      }
    }

    // certs (DISABLED PENDING FURTHER CONSIDERATION)
    // if ((!profile.certifications || profile.certifications.length === 0) && resume.certifications?.length > 0) {
    //   for (const certRaw of resume.certifications) {
    //     updatePromises.push(this.createCertification({
    //       profileId,
    //       userId: profile.userId,
    //       certificationType: certRaw,
    //     }))
    //   }
    // }

    // Make most requests at once for max concurrency
    await Promise.all(updatePromises)

    return await this.getProfile(profileId)
  }

  private parseText(value: string) {
    // Replace linefeeds with double linefeeds
    const regex = /\n/
    return value.replace(regex, "\n\n")
  }

  private parseEndDate(value: string) {
    if (value) {
      if (value === getISODateToday()) {
        // Leave endDate as null if it is the current date
        return null
      } else {
        const date = isoToLocalDate(value)
        if (date) {
          if (date.getMonth() === 0 && date.getDate() === 1) {
            // Push to end of year
            const endDate = endOfYear(date)
            return getISODateFromDate(endDate)
          } else if (date.getDate() === 1) {
            // Push to end of month
            const endDate = endOfMonth(date)
            return getISODateFromDate(endDate)
          }
        }
      }
    }

    return value
  }

  // admin only tools
  async clearAbout(profile: Profile) {
    await this.updateProfile({ id: profile.id, about: '', resumeKey: '' })
  }

  async clearEducation(profile: Profile) {
    await Promise.all((profile.education || []).map(({ id }) => this.deleteEducation(id)))
  }

  async clearExperience(profile: Profile) {
    await Promise.all((profile.experiences || []).map(({ id }) => this.deleteExperience(id)))
  }

  async clearCertifications(profile: Profile) {
    await Promise.all((profile.certifications || []).map(({ id }) => this.deleteCertification(id)))
  }

  async clearProfile(profile: Profile) {
    await Promise.all([
      this.clearAbout(profile),
      this.clearEducation(profile),
      this.clearExperience(profile),
      this.clearCertifications(profile),
    ] as Promise<any>[])

    console.log(`Cleared data for profile: ${profile!.id}`)
  }

  async clearProfileLocations(profile: Profile) {
    await Promise.all((profile.profileLocations || []).map(({ id }) => this.deleteProfileLocation(id)))
  }

  async clearProfileServices(profile: Profile) {
    await Promise.all((profile.profileServices || []).map(({ id }) => this.deleteProfileService(id)))
  }

  async clearProfileStorage(profile: Profile) {
    const promises: Promise<any>[] = []
    const key = `users/${profile.userId}/profile/`
    const list = await Storage.list(key)
    if (list) {
      list.results.forEach((result: any) => {
        if (result.size > 0) {
          promises.push(Storage.remove(result.key))
        }
      })
    }
    await Promise.all(promises)
  }

  async deleteProfile(profile: Profile) {
    await Promise.all([
      this.clearAbout(profile),
      this.clearEducation(profile),
      this.clearExperience(profile),
      this.clearCertifications(profile),
      this.clearProfileLocations(profile),
      this.clearProfileServices(profile)
    ] as Promise<any>[])

    await this.clearProfileStorage(profile)
    await this.govGigAPI.deleteProfile(profile.id)

    console.log(`Deleted profile: ${profile!.id}`)
  }
}

export class LocationProfiles {
  location: Location
  profiles: Profile[]

  constructor (location: Location, profiles: Profile[]) {
    this.location = location
    this.profiles = profiles
  }
}

const CACHE_EXPIRE_SECS = 300

// This caches Profile searches based on serviceId
class ProfilesCacheStore extends CacheStore<Profile[]> {

  constructor (profileStore: ProfileStore) {
    super(CACHE_EXPIRE_SECS, async (key: string): Promise<Profile[] | undefined> => {
      const parts = key.split('/')
      const serviceId = parts[0]
      const industry = parts[1]
      let filter: APITypes.ModelProfileServiceFilterInput | undefined
      if (industry) {
        filter = {
          industries: {contains: industry}
        }
      }
      const profiles = await profileStore.listProfilesByService(serviceId, filter)
      // Filter out only accepted filters
      const filtered = profiles.filter((profile: Profile) => profile.profileStatus === APITypes.ProfileStatus.Accepted)
      return filtered
    })
  }

  removeKey = (key: string) => {
    this.remove(key)
  }
}

// This caches Profile searches based on locationId
class LocationProfilesCacheStore extends CacheStore<Profile[]> {

  constructor (profileStore: ProfileStore) {
    super(CACHE_EXPIRE_SECS, async (key: string): Promise<Profile[] | undefined> => {
      // The key here is the locationId.
      // At some point we may want to support a key that contains the locationId and/or serviceId.
      const profiles = await profileStore.listProfilesByLocation(key)
      // Filter out only accepted filters
      const filtered = profiles.filter((profile: Profile) => profile.profileStatus === APITypes.ProfileStatus.Accepted)
      console.debug(`ProfileCacheStore.get(${key}) = ${filtered.length}`)
      return filtered
    })
  }

  removeKey = (key: string) => {
    this.remove(key)
  }
}


