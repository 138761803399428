import React, { useEffect, useState } from "react"
import { RouteComponentProps, useLocation } from "@reach/router"
import Page from "../../components/page/Page"
import PageTitle from "../../components/page/PageTitle"
import { createMachine } from 'xstate'
import { 
  Box, 
  Button, 
  CircularProgress, 
  createStyles, 
  Divider, 
  Grid, 
  makeStyles, 
  Paper, 
  Theme, 
  Typography
} from "@material-ui/core"
import ActionButton from "../../components/controls/ActionButton"
import ControlTower, { Routes } from "../../components/ControlTower"
import AuthButton from "../../components/login/AuthButton"
import { useStores } from "../../stores/StoreProvider"
import { useMachine } from '@xstate/react';
import JobPostForm, { JobPostFormCloseReason } from "../../components/jobPosts/JobPostForm"
import JobPost from "../../model/JobPost"
import { getISODateToday } from "../../stores/StoreUtilities"
import JobPostDescriptionForm from "../../components/jobPosts/JobPostDescriptionForm"
import JobPostSelectProductOptions from "./JobPostSelectProductOptions"
import JobPostPage from "../jobPost/JobPostPage"
import TwoPanelSectionContainer from "../../components/page/TwoPanelSectionContainer"
import JobPostQuestionsForm from "../../components/jobPosts/JobPostQuestionsForm";
import Logger from "../../components/Logger";
import {JobPostStatus} from "../../API";
import config from 'react-global-configuration';
import parseHTML from "html-react-parser";
import Stack from "../../components/Stack"
// import Hero from "../../views/marketing/Hero"
// import MarginRow from "../../components/page/MarginRow"
// import ForEmployers from "../../views/marketing/Employers/ForEmployers"
import Section, { SectionBackgroundColors } from "../../components/marketing/sections/Section"
import ImageGroup from "../../components/marketing/ImageGroup"
import TitleGroup from "../../components/marketing/TitleGroup"
import MapCandidates from '../../images/MapCandidates.png'
// import WhatIsGovGig from "../../views/marketing/WhatIsGovGig"
import TitleSection from "../../components/marketing/sections/TitleSection"
import GovGigIndustryList from "../../views/marketing/GovGigIndustryList"
import EmployerHowGovGigWorksSection from "../../views/marketing/Employers/EmployerHowGovGigWorksSection"
import EmployerOptionsSection from "../../views/marketing/Employers/EmployerOptionsSection"
import EmployerTestimonialsSection from "../../views/marketing/Employers/EmployerTestimonialsSection"
import EmployerFAQ from "../../views/marketing/Employers/EmployerFAQ"
import EmployerProcessSection from "../../views/marketing/Employers/EmployerProcessSection"
import EmployerContactUsSection from "../../views/marketing/Employers/EmployerContactUsSection"

enum CreateJobPostStates {
  loading = 'loading',
  logInOrSignUp = 'logInOrSignUp',
  jobPostInfo = 'jobPostInfo',
  jobPostDescription = 'jobPostDescription',
  jobPostQuestions = 'jobPostQuestions',
  priceSelect = 'priceSelect'
}

enum CreateJobPostActions {
  NEEDS_LOGIN_OR_SIGNUP = 'NEEDS_LOGIN_OR_SIGNUP',
  ADD_JOB_POST_INFO = 'ADD_JOB_POST_INFO',
  SAVE_JOB_POST_INFO = 'SAVE_JOB_POST_INFO',
  SAVE_JOB_POST_DESCRIPTION = 'SAVE_JOB_POST_DESCRIPTION',
  SKIP_JOB_POST_DESCRIPTION = 'SKIP_JOB_POST_DESCRIPTION',
  SAVE_JOB_POST_QUESTIONS = 'SAVE_JOB_POST_QUESTIONS',
  SKIP_JOB_POST_QUESTIONS = 'SKIP_JOB_POST_QUESTIONS',
  SELECT_PRICE = 'SELECT_PRICE'
}

const stateMachine = createMachine({
  id: 'createJobPost',
  initial: CreateJobPostStates.loading,
  states: {
    [CreateJobPostStates.loading]: {
      on: { 
        [CreateJobPostActions.ADD_JOB_POST_INFO]: CreateJobPostStates.jobPostInfo, 
        [CreateJobPostActions.NEEDS_LOGIN_OR_SIGNUP]: CreateJobPostStates.logInOrSignUp,
        [CreateJobPostActions.SELECT_PRICE]: CreateJobPostStates.priceSelect 
      }
    },
    [CreateJobPostStates.logInOrSignUp]: {
      on: {
        [CreateJobPostActions.ADD_JOB_POST_INFO]: CreateJobPostStates.jobPostInfo
      }
    },
    [CreateJobPostStates.jobPostInfo]: {
      on: {
        [CreateJobPostActions.SAVE_JOB_POST_INFO]: CreateJobPostStates.jobPostDescription
      }
    },
    [CreateJobPostStates.jobPostDescription]: {
      on: {
        [CreateJobPostActions.SAVE_JOB_POST_DESCRIPTION]: CreateJobPostStates.jobPostQuestions,
        [CreateJobPostActions.SKIP_JOB_POST_DESCRIPTION]: CreateJobPostStates.jobPostQuestions
      }
    },
    [CreateJobPostStates.jobPostQuestions]: {
      on: {
        [CreateJobPostActions.SAVE_JOB_POST_QUESTIONS]: CreateJobPostStates.priceSelect,
        [CreateJobPostActions.SKIP_JOB_POST_QUESTIONS]: CreateJobPostStates.priceSelect
      }
    },
    [CreateJobPostStates.priceSelect]: {
      on: {
        [CreateJobPostActions.ADD_JOB_POST_INFO]: CreateJobPostStates.jobPostInfo, 
      }
    }
  }
})

const useStyles = makeStyles((theme: Theme) => createStyles({
  progress: {
    backgroundColor: theme.palette.common.white,  // "#d72222",
    border: 0,
    borderRadius: 3,
    boxShadow: '0 3px 5px 2px rgba(0, 0, 0, 0.05)',
    height: 3,
    padding: '0 30px',
  },
  panel: {
    padding: theme.spacing(2)
  },
  promo: {
    backgroundColor: 'hsl(219, 97%, 87%)'
  },
  divider: {
    backgroundColor: "#ccc"
  }
}));

interface IJobPostCreatePageProps {
  jobPostId?: string // Passed via the router, if included in the URL. 
}

const JobPostCreatePage: React.FC<RouteComponentProps & IJobPostCreatePageProps> = ({ 
  jobPostId 
}) => {
  const title = "Create Self-Service Job Post(s)"
  const progressName = "CreateJobPost"
  
  // TODO: Implement Stepper to show user where they are at in the process. 
  // const [jobPostCreationProgress, setJobPostCreationProgress] = useState<number>(50)

  const [isAuthVisible, setIsAuthVisible] = useState(false)
  const { accountStore, jobStore, progress, userStore } = useStores()
  const [jobPost, setJobPost] = useState<JobPost>() // Current working job post.
  
  const classes = useStyles()
  const location = useLocation()
  const [currentState, send, service] = useMachine(stateMachine)
  
  useEffect(() => {
    const subscription = service.subscribe((state) => {
      // simple state logging
      console.log("Current State: ", JSON.stringify(state.value, null, 2));
    });
  
    return subscription.unsubscribe;
  }, [
    service // note: service should never change
  ]); 

  useEffect(() => {
    progress.show(progressName)

    // Loading

    const createJobPost = (): JobPost => {
      const status = JobPostStatus.Cart
      const jobPost = new JobPost({
        accountId: userStore.user!.accountId,
        industries: userStore.user!.account?.industries,
        status
      })
      return jobPost
    }

    const loadJobPost = async (): Promise<JobPost> => {
      // If a jobPostId is passed in via the URL, then load it. 
      if (jobPostId) {
        // TODO: Check to make sure the user has permission to edit the job post. 
        const jobPost = await jobStore.getJobPost(jobPostId)
        return jobPost!
      } else {
        return createJobPost()
      }
    }

    const init = async () => {
      if (jobStore.isLoading === true && userStore.isLoading === true) {
        return 
      }

      if (userStore.user === undefined) {
        progress.hide(progressName)
        send(CreateJobPostActions.NEEDS_LOGIN_OR_SIGNUP)
      } else {
        const jobPost = await loadJobPost()
        setJobPost(jobPost)
        
        const search = new URLSearchParams(location.search)
        let step = search.get('step')
        if (step === CreateJobPostStates.priceSelect) {
          send(CreateJobPostActions.SELECT_PRICE)
        } else {
          send(CreateJobPostActions.ADD_JOB_POST_INFO)
        }
        progress.hide(progressName)
        
        // TODO: Future, handle any step? 
        // if (step) {
        //   url += `?step=${step}`
        // } else {
        //   url += `?step=${CreateJobPostStates.jobPostInfo}`
        // }
        // ControlTower.route(url)
      }
    }
      
    init() 
  }, [
    accountStore, 
    accountStore.isLoading,
    jobStore,
    jobStore.isLoading,
    jobPostId,
    progress, 
    send, 
    userStore,
    userStore.isLoading, 
    userStore.isAuthenticated,
    location.search,
    isAuthVisible
  ])

  const handleDeleteJobPost = async () => {
    progress!.show('JobPostCreatePage')

    try {
      if (jobPost && jobPost.id) {
        await jobStore!.deleteJobPost(jobPost.id, userStore)
      }
      ControlTower.route(Routes.manageJobs)
    } catch (error) {
      const message = 'Could not delete the job post.'
      Logger.error(message, error)
    }
    progress!.hide('JobPostCreatePage')
  }

  const renderLoading = () => {
    return (
      <CircularProgress /> 
    )
  }

  const renderActionButtons = () => {
    return (
      <Box
        pt={3}
      >
        <Typography gutterBottom variant="h6" color="primary">To get started:</Typography>
        <Grid container direction="row" alignItems="center" spacing={2}>
          <Grid item>
            <ActionButton
              showFowardArrow
              size="large"
              text="Sign Up"
              tracking="Create-Job-Post-Sign-Up"
              click={() => {
                // setIsAuthVisible(true)
                ControlTower.route(Routes.signup)
              }}
            />
          </Grid>
          <Grid item>
            <ActionButton
              variant="outlined"
              size="large"
              text="Log In"
              tracking="Create-Job-Post-Log-In"
              click={() => {
                setIsAuthVisible(true)
                // ControlTower.route(Routes.login)
              }}
            />
          </Grid>
        </Grid>
        {isAuthVisible &&
          <AuthButton
            open={true}
            onLogin={() => {
              setIsAuthVisible(false)
            }}
            onClose={() => {
              setIsAuthVisible(false)
            }} 
          />
        }
      </Box>
    )
  }

  const renderLogInOrSignUp = () => {
    return (
      <Stack
        direction="column"
      >
        {/* <Hero /> */}
        <Section
          backgroundColor={SectionBackgroundColors[0]}
        >
          <ImageGroup
            imageSrc={MapCandidates}
            imageTitle="Find Talent Map"
            alignment="imageRight"
          >
            <TitleGroup
              title="The most unique pool of qualified candidates."
              body="Employers, it only takes minutes to sign up, create your job post, and start finding candidates."
              alignment="left"
              titleVariant="h1"
            >
              { renderActionButtons() }
            </TitleGroup>
          </ImageGroup>
        </Section>
        <TitleSection
          backgroundColor={
            // "#123" // For testing layout. 
            SectionBackgroundColors[200]
          }
          title="Our Experts, Your Industry"
          subtitle="GovGig's job board, recruiting, and direct staffing services focus on these industries:"
        >
          <GovGigIndustryList /> 
        </TitleSection>
        <EmployerHowGovGigWorksSection />
        <EmployerOptionsSection />
        <EmployerFAQ />
        <EmployerTestimonialsSection />
        <EmployerProcessSection />
        <EmployerContactUsSection />
        {/* <ForEmployers /> */}
      </Stack>
    )
    // return (
    //   <TwoPanelSectionContainer
    //     title="Get Started"
    //     // note=""
    //   >
    //     <Grid 
    //       container 
    //       direction="column" 
    //       justifyContent="center"
    //       alignItems="center"  
    //     >
    //       <Grid item>
    //         <Box width="200px" py={5}>
    //           <Paper className={ classes.panel }>
    //             <Grid container direction="column" alignItems="center" spacing={2}>
    //               <Grid item>
    //                 <Typography variant="h6" color="primary">To get started:</Typography>
    //               </Grid>
    //               <Grid item>
    //                 <ActionButton
    //                   text="Log In"
    //                   tracking="Create-Job-Post-Log-In"
    //                   click={() => {
    //                     setIsAuthVisible(true)
    //                     // ControlTower.route(Routes.login)
    //                   }}
    //                 />
    //               </Grid>
    //               <Grid item>
    //                 <Typography>Or</Typography>
    //               </Grid>
    //               <Grid item>
    //                 <ActionButton
    //                   text="Sign Up"
    //                   tracking="Create-Job-Post-Sign-Up"
    //                   click={() => {
    //                     // setIsAuthVisible(true)
    //                     ControlTower.route(Routes.signup)
    //                   }}
    //                 />
    //               </Grid>
    //             </Grid>
    //           </Paper>
    //         </Box>
    //       </Grid>
    //     </Grid>
    //     {isAuthVisible &&
    //       <AuthButton
    //         open={true}
    //         onLogin={() => {
    //           setIsAuthVisible(false)
    //         }}
    //         onClose={() => {
    //           setIsAuthVisible(false)
    //         }} 
    //       />
    //     }
    //   </TwoPanelSectionContainer>
    // )
  }

  const renderJobPostForm = () => {
    return (
      <TwoPanelSectionContainer
        title="Enter Job Post Info"
        note="Note that you can still edit the info later after checkout."
        rightPanel={ jobPost && jobPost.id ? renderJobPostPreview() : renderInstructions() }
      >
        <Grid container justifyContent="center" spacing={3}>
          <Grid item xs={12}>
            <JobPostForm
              jobPost={jobPost!}
              renderAsDialog={false}
              didClose={(reason: JobPostFormCloseReason) => {
                if (reason === JobPostFormCloseReason.CancelForm) {
                  ControlTower.route(Routes.manageJobs)
                }
              }}
              didCreate={(jobPost: JobPost) => {
                setJobPost(jobPost)
                send(CreateJobPostActions.SAVE_JOB_POST_INFO)
              }} 
              didDelete={(jobPost: JobPost) => {
                // For now they can delete it in the cart checkout page. 
              }} 
              didEdit={async (jobPost: JobPost) => {
                setJobPost(jobPost)
                send(CreateJobPostActions.SAVE_JOB_POST_INFO)
              }}
            />
          </Grid>
        </Grid>
      </TwoPanelSectionContainer>
    )
  }

  const renderInstructions = () => {
    return (
      <Stack direction="column" spacing={2} sx={{ p: 3 }} wrap="nowrap">
        <Typography>Enter the details for your self-service job post to put on the GovGig job board.</Typography>
        <Typography>After you purchase and check out, you can publish the job post to make it go live.</Typography>
        <Typography>When a job post is live, you can then invite candidates to apply.</Typography>
        <Box
          sx={{
            pt: 2 
          }}
        >
          <Divider className={classes.divider} />
        </Box>
        <Stack 
          direction="column"
          justifyContent="center"
          alignItems="center"
          spacing={2}
          wrap="nowrap" 
          sx={{ pt: 2 }}
        >
          
          <Typography>If you would rather have GovGig handle posting your jobs and finding candidates on your behalf, feel free to use our Recruiting & Staffing Services instead.</Typography>
          <Button 
            size="small" 
            variant="outlined" 
            color="primary"
            onClick={() => {
              ControlTower.route(Routes.serviceRequestCreate)
            }}
          >
            Use Recruiting Services
          </Button>
        </Stack>
      </Stack>
    )
  }

  const renderJobPostPreview = () => {
    if (!(jobPost && jobPost.id)) {
      return null 
    }
    return (
      <React.Fragment>
        { jobPost && jobPost.id && 
          <Box>
            <Typography align="center" variant="h6">Job Post Preview</Typography>
            <JobPostPage
              hideButtons={true}
              embed
              jobPostId={jobPost.id}
            />
          </Box>
        }
      </React.Fragment>
    )
  }

  const renderJobPostDescriptionForm = () => {
    return (
      <TwoPanelSectionContainer
        title="Enter Job Post Long-form Description"
        note="Note that you can still edit the description later after checkout."
        rightPanel={ renderJobPostPreview() }
      >
        <JobPostDescriptionForm
          jobPost={jobPost!}
          renderAsDialog={false}
          didCancel={() => {
            handleDeleteJobPost()
          }}
          didClose={() => {
            send(CreateJobPostActions.SKIP_JOB_POST_DESCRIPTION)
          }}
          didEdit={async (jobPost: JobPost) => {
            setJobPost(jobPost)
            send(CreateJobPostActions.SAVE_JOB_POST_DESCRIPTION)
          }}
        />
      </TwoPanelSectionContainer>
    )
  }

  const renderJobPostQuestionsForm = () => {
    return (
      <TwoPanelSectionContainer
        title="Enter Job Post Screening Questions"
        note="Note that you can still edit the questions later after checkout."
        rightPanel={ renderJobPostPreview() }
      >
        <JobPostQuestionsForm
          jobPost={jobPost!}
          renderAsDialog={false}
          didCancel={() => {
            handleDeleteJobPost()
          }}
          didClose={() => {
            send(CreateJobPostActions.SKIP_JOB_POST_QUESTIONS)
          }}
          didEdit={(jobPost: JobPost) => {
            setJobPost(jobPost)
            send(CreateJobPostActions.SAVE_JOB_POST_QUESTIONS)
          }}
        />
      </TwoPanelSectionContainer>
    )
  }

  const renderProductOptions = () => {
    const couponPromo = config.get("couponPromo")

    return (
      <TwoPanelSectionContainer
        title="Select Pricing Option(s)"
        rightPanel={ renderJobPostPreview() }
      >
        <Grid container direction="column" alignItems="stretch" spacing={2}>
          {couponPromo &&
            <Grid item xs={12}>
              <Paper
                elevation={1}
              >
                <Box
                  sx={{
                    // border: '1px solid #ccc',
                    borderRadius: 10,
                    p: 3,
                    mt: 1,
                    // color: '#fff'
                  }}
                  className={classes.promo}
                >
                  <Typography variant="body1" align="center" color="primary">{parseHTML(couponPromo)}</Typography>
                </Box>
              </Paper>
            </Grid>
          }
          <Grid item>
            { jobPost && 
              <JobPostSelectProductOptions 
                jobPost={jobPost}
                onContinue={() => {
                  const accountId = jobPost.accountId // Assume the account in the flow is the same as the account for the job post. 
                  ControlTower.route(`${Routes.jobPostPaymentCart}/?accountId=${accountId}`)
                }}
                onAddJobPost={() => {
                  const status = JobPostStatus.Cart
                  const jobPost = new JobPost({
                    accountId: userStore.user!.accountId,  
                    openDate: getISODateToday(),
                    status // Cart status
                  })
                  setJobPost(jobPost)
                  send(CreateJobPostActions.ADD_JOB_POST_INFO)
                }}
              />
            }
          </Grid>
        </Grid>
      </TwoPanelSectionContainer>
    )
  }

  return (
    <Page
      title={title}
    >
      <Box 
        sx={{
          p: currentState.matches(CreateJobPostStates.logInOrSignUp) ? 0 : 2,
          width: '100%' 
        }}
      >
        <PageTitle title={title} />
        <Box>
          {/* TODO: Use Stepper to show user where they are at in the process: 
          <Box
            sx={{
              py: 2
            }}
          >
            <LinearProgress 
              color="secondary" 
              variant="determinate" 
              value={jobPostCreationProgress} 
              className={classes.progress} 
            />
          </Box> */}
          { currentState.matches(CreateJobPostStates.loading) && 
            renderLoading() 
          }
          { currentState.matches(CreateJobPostStates.logInOrSignUp) && 
            renderLogInOrSignUp() 
          }
          { currentState.matches(CreateJobPostStates.jobPostInfo) && 
            renderJobPostForm() 
          }
          { currentState.matches(CreateJobPostStates.jobPostDescription) &&
            renderJobPostDescriptionForm() 
          }
          { currentState.matches(CreateJobPostStates.jobPostQuestions) &&
            renderJobPostQuestionsForm()
          }
          { currentState.matches(CreateJobPostStates.priceSelect) && 
            renderProductOptions() 
          }
        </Box>
      </Box>
    </Page>
  )
}

export default JobPostCreatePage 
