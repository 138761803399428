import React, { useState } from "react";
import {
  Button,
  ListItemText,
  Menu,
  MenuItem,
  MenuProps,
} from "@material-ui/core";
import {withStyles} from "@material-ui/core/styles";
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

// This menu approach is based on documentation here: https://v4.mui.com/components/menus/#customized-menus 

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
  },
})((props: MenuProps) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    '&:focus': {
      backgroundColor: theme.palette.primary.main,
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: theme.palette.common.white,
      },
    },
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1)
  },
}))(MenuItem);

export interface IMenuItem { 
  label: string 
  value: any 
  selected: boolean 
}

const MenuButton = ({
  label,
  icon,
  menuItems,
  onClickMenuItem
}: {
  label?: string
  icon?: React.ReactElement
  menuItems: IMenuItem[]
  onClickMenuItem: (e: any, menuItem: IMenuItem) => void 
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (e: any, menuItem: IMenuItem) => {
    handleClose() 
    onClickMenuItem(e, menuItem)
  }

  return (
    <div>
      <Button
        variant="text"
        aria-controls="customized-menu"
        aria-haspopup="true"
        color="primary"
        onClick={handleClick}
      >
        {label}
        {icon ? icon : <ArrowDropDownIcon />}
      </Button>
      <StyledMenu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {menuItems.map((menuItem: IMenuItem) => {
          return (
            <StyledMenuItem
              key={`${menuItem.label}`}
              selected={menuItem.selected}
              onClick={(e: any) => {
                e.preventDefault()
                handleMenuItemClick(e, menuItem)
              }}
            >
              <ListItemText primary={menuItem.label} />
            </StyledMenuItem>
          )
        })}
      </StyledMenu>
    </div>
  );
}

export default MenuButton
