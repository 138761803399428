import * as React from 'react'
import {
  Theme,
  createStyles,
  WithStyles,
  withStyles
} from '@material-ui/core';
import { inject, observer } from "mobx-react";
import {JobPostStatus, SubjectType, UpdateJobPostInput} from '../../API';
import { makeObservable, observable } from 'mobx';
import UserStore from '../../stores/UserStore';
import Notify from '../notify/Notify';
import Progress from '../Progress';
import JobPost from '../../model/JobPost';
import {ActivityType} from "../../model/UserActivity";
import JobStore from "../../stores/JobStore";
import {getISODateTodayAddDays} from '../../stores/StoreUtilities';
import AddButton from "../AddButton";

const styles = (theme: Theme) => createStyles({
  simpleSelect: {
    width: 120
  }
})

interface IJobPostStatusSelectorProps {
  jobPost: JobPost
  onChange(jobPost: JobPost): void

  jobStore?: JobStore
  notify?: Notify
  progress?: Progress
  userStore?: UserStore
}

@inject("accountStore", 'jobStore', "notify", "progress", "userStore")
@observer
class JobPostStatusSelector extends React.Component<WithStyles<typeof styles> & IJobPostStatusSelectorProps> {

  @observable selection?: JobPostStatus

  constructor(props: any) {
    super(props)
    makeObservable(this)
  }

  render() {
    const {jobPost } = this.props
    return (
      <AddButton
        text={`Status: ${jobPost.status}`}
        buttonColor="secondary"
        buttonVariant="contained"
        buttonSize="medium"
        icon='downarrow'
        options={Object.values(JobPostStatus)}
        getOptionLabel={(option: any) => option}
        click={this.onChange}
      />
    )
  }

  // render() {
  //   const { jobStore, jobPost, classes } = this.props
  //   return (
  //     <Box
  //       bgcolor="common.white"
  //       sx={{
  //         borderRadius: 10,
  //         border: `1px solid ${grey[300]}`,
  //         p: 1,
  //         boxShadow: 'rgba(0, 0, 0, 0.15) 0px 1px 3px 0px'
  //       }}
  //     >
  //       <FormValidator name="jobPostStatusForm">
  //         <SimpleSelect
  //           placeholderText={"Job Post Status"}
  //           className={classes.simpleSelect}
  //           selections={jobStore!.getJobPostStatusOptions()}
  //           value={jobPost.status}
  //           valueName={"status"}
  //           elementId={"jobPostStatus"}
  //           onChange={this.onChange}
  //         />
  //       </FormValidator>
  //     </Box>
  //   )
  // }

  onChange = async (statusName: string) => {
    const { userStore, jobStore, jobPost, notify, progress } = this.props

    progress!.show('JobPostStatusSelector')

    let update 

    try {
      const input: UpdateJobPostInput = {
        id: jobPost.id,
        status: JobPostStatus[statusName]
      }

      if (input.status === JobPostStatus.Closed || input.status === JobPostStatus.Hired) {
        // Note, set it to yesterday so that it will not be picked up on the job board.
        input['closedDate'] = getISODateTodayAddDays(-1)
      } else {
        input['closedDate'] = null
      }
      if (input.status === JobPostStatus.Draft) {
        input['openDate'] = null
      }
      
      update = await jobStore!.updateJobPost(input)
    } catch (error) {
      notify!.show("error", "Error updating job post status")
    }

    if (update) {
      userStore!.logUserActivity(userStore!.user!.id, SubjectType.JobPost, jobPost.id, ActivityType.JobPostStatusUpdated,
        `${userStore!.user!.fullName} updated the job post '${jobPost.fullName}' status to '${statusName}'`)

      this.props.onChange(update)
    }

    progress!.hide('JobPostStatusSelector')
  }
}

export default withStyles(styles)(JobPostStatusSelector)
