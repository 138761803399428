import { 
  Box, 
  // Stack, 
  // ToggleButton, 
  // ToggleButtonGroup, 
  Typography, 
  // useTheme 
} from "@material-ui/core";
import { 
  ToggleButton,
  ToggleButtonGroup 
} from "@material-ui/lab";
import React from "react";
import Product from "../../../model/stripe/Product";
import Price from "../../../model/stripe/Price";
import { numberToMoneyFormat } from "../../../stores/StoreUtilities";
import Stack from "../../Stack";

const PricingToggle = ({ 
  product 
}: {
  product: Product
}) => {
  console.log(product)
  const price = product.prices.find(price => price.id === product.defaultPriceId)
  const [selectedPrice, setSelectedPrice] = React.useState<Price | null>(price ?? null)

  // const theme = useTheme()

  const handleSelectPrice = (
    event: React.MouseEvent<HTMLElement>,
    newPriceId: string | null,
  ) => {
    const price = product.prices.find(price => price.id === newPriceId)
    setSelectedPrice(price ?? null);
  };

  const renderToggleGroup = () => {
    return (
      <ToggleButtonGroup
        value={selectedPrice?.id}
        exclusive
        color="primary"
        size="large"
        aria-label={`${product.name} Pricing`}
        onChange={handleSelectPrice}
        // sx={{
        //   boxShadow: 'rgba(0, 0, 0, 0.1) 0px 1px 5px 0px'
        // }}
      >
        {product.prices.map((price: Price) => {
          return (
            <ToggleButton 
              key={`price.${price.id}`} 
              value={price.id}
              // sx={{
              //   width: {
              //     lg: 75
              //   },
              //   fontWeight: 'bold'
              // }}
            >
              {price.quantity}
            </ToggleButton>
          )
        })}
      </ToggleButtonGroup>
    )
  }

  return (
    <Box textAlign="center">
      <Box>
        <Typography gutterBottom variant="h6" align="center" color="primary">
          {product.unitLabel}
        </Typography>
        <Box
          sx={{
            height: 50 
          }}
        >
          { product.prices.length > 1 ? 
              renderToggleGroup() 
            :
              <Stack 
                direction="column" 
                justifyContent="center" 
                alignItems="center"
                sx={{
                  height: '100%'
                }}  
              >
                <Typography
                  // sx={{
                  //   color: theme.palette.secondary.main,
                  //   fontWeight: 'bold' 
                  // }}
                >
                  1 or more
                </Typography>
              </Stack>
          }
        </Box>
        { product.productExpiry && 
          <Typography 
            align="center" 
            // sx={{ 
            //   pt: 1, 
            //   fontSize: 12 
            // }}
          >
            ({product.productExpiry})
          </Typography>
        }
      </Box>
      <Box 
        sx={{ 
          py: 2,
          height: 75 
        }}
      >
      { selectedPrice && selectedPrice.unitPrice > 0 ?  
        <React.Fragment>
          <Typography 
            variant="h6" 
            align="center" 
            color="primary" 
            // sx={{ 
            //   fontWeight: 600 
            // }}
          >
            { numberToMoneyFormat(selectedPrice.unitPrice) }
          </Typography>
          <Typography 
            variant="h6" 
            align="center" 
            // sx={{ 
            //   fontWeight: 300 
            // }}
          >
            {selectedPrice.frequency}
          </Typography>
        </React.Fragment>
        :
          null 
      }
      </Box>
    </Box>
  )
}

export default PricingToggle
