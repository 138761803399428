import { 
  Box, 
  Divider, 
  Grid, 
  Paper, 
  Typography 
} from "@material-ui/core"
import { Skeleton } from "@material-ui/lab"
import React from "react"
import { numberToMoneyFormatOrLoading } from "../../stores/StoreUtilities"

const LineItem = ({ 
  label, 
  value 
}: { 
  label: string
  value: string
}) => {
  return (
    <Grid container direction="row" justifyContent="space-between">
      <Grid item>
        <Typography>
          {label}
        </Typography>
      </Grid>
      <Grid item>
        <Typography>
          {value}
        </Typography>
      </Grid>
    </Grid>
  )
}

const StripeOrderSummary = ({
  isLoading, 
  subtotal,
  couponDiscountAmount,
  amountDue,
  children 
}: {
  isLoading: boolean 
  subtotal?: number 
  couponDiscountAmount?: number 
  amountDue?: number 
  children?: React.ReactNode
}) => {

  const renderOrderDetails = () => {
    return (
      <Grid container direction="column" spacing={2}>
        <Grid item>
          <Divider />
        </Grid>
        <Grid item>
          <LineItem 
            label="Subtotal"
            value={numberToMoneyFormatOrLoading(subtotal)}
          />
        </Grid> 
        { children && 
          <Grid item>
            { children }
          </Grid>
        }
        { (amountDue && amountDue > 0) ?
            <Grid item>
              <LineItem 
                label="Coupon Discount"
                value={numberToMoneyFormatOrLoading(couponDiscountAmount)}
              />
            </Grid>
          : 
            null 
        }
        <Grid item>
          <Divider />
        </Grid>
        <Grid item>
          <LineItem 
            label="Billed Today (USD)"
            value={numberToMoneyFormatOrLoading(amountDue)}
          />
        </Grid>
      </Grid>
    )
  }

  return (
    <Paper>
        <Box p={2}>
          <Box pb={2}>
            <Typography variant="h6" gutterBottom color="primary">Order Summary</Typography>
          </Box>
          { isLoading ? 
            <Box>
              <Skeleton variant="text" />
              <Skeleton variant="text" />
              <Skeleton variant="text" />
            </Box>
            :   
            renderOrderDetails()
          }
          
        </Box>
    </Paper>
  )
}

export default StripeOrderSummary
