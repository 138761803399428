import React from "react";
import { Avatar, Box, createStyles, Grid, isWidthDown, Theme, Typography, withStyles, WithStyles, WithTheme, withTheme, withWidth, WithWidth } from "@material-ui/core";
import { blueGrey, green } from "@material-ui/core/colors";
import classnames from 'classnames'
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import { v4 as uuid } from 'uuid';

const styles = (theme: Theme) => createStyles({
  avatar: {
    width: theme.spacing(7),
    height: theme.spacing(7),
    boxShadow: '0px 1px 12px rgba(0, 0, 0, 0.25)'
  },
  darkGrey: {
    backgroundColor: blueGrey[700]
  },
  primary: {
    backgroundColor: theme.palette.primary.main 
  },
  secondary: {
    backgroundColor: theme.palette.secondary.main 
  },
  green: {
    backgroundColor: green[700]
  },
  heading: {
    fontWeight: 'bold'
  },
  arrow: {
    height: '47px', 
    width: 'auto',
    color: theme.palette.primary.main,
    [theme.breakpoints.down('sm')]: {
      transform: 'rotate(90deg)'
    }
  }
})

export interface IExplainerStep {
  avatarIcon: any
  avatarColor: 'primary' | 'secondary' | 'darkGrey' | 'green'
  heading?: string 
  text: string 
}

interface IExplainerStepsListProps {
  steps: IExplainerStep[] 
  title?: string 
  subtitle?: string
}

class ExplainerStepsList extends React.Component<WithStyles<typeof styles> & IExplainerStepsListProps & WithTheme & WithWidth> {

  render() {
    const { classes, steps, title, subtitle, width } = this.props 
    const isSmall = isWidthDown('sm', width)
    return (
      <Box px={{ xs: 2, sm: 3 }} pt={{ xs: 2, sm: 3 }} pb={{ xs: 5, sm: 7 }} style={{ background: "linear-gradient(145deg, rgba(25,53,106,0.1) 25%, rgba(215,35,34,0.1) 100%)" }} borderRadius={10} boxShadow="0px 5px 15px 0px rgba(0, 0, 0, 0.15)">
        <Box pb={2}>
          <Typography gutterBottom variant="h5" align="center">
            {title}
          </Typography>
          { subtitle && 
            <Typography gutterBottom variant="h6" align="center">
              {subtitle}
            </Typography>
          }
        </Box>
        <Grid 
          container
          justifyContent={ isSmall ? "center" : "space-around" }
          alignItems={ isSmall ? "center" : "flex-start" }
          direction={ isSmall ? 'column' : 'row' }
          wrap="nowrap"
          spacing={ isSmall ? 0 : 3 }
        >
          {steps.map((step, index, steps) => {
            return (
              <React.Fragment key={uuid()}>
                {this.renderStep(step)}
                {index < steps.length - 1 && 
                  <Grid item>
                    <Box my={3}>
                      <PlayArrowIcon className={ classes.arrow } />
                    </Box>
                  </Grid> 
                }
              </React.Fragment>
            )
          })}
        </Grid> 
        {this.props.children}
      </Box>
    )
  }

  renderStep = (step: IExplainerStep) => {
    const { classes } = this.props 

    return (
      <Grid item xs={12} md={3}>
        <Box px={2} py={2} bgcolor="#fff" borderRadius={10}>
          <Grid container direction="column" justifyContent="center" alignItems="center" spacing={2}>
            <Grid item>
              <Avatar variant="rounded" className={ this.avatarClasses(step) }>
                { step.avatarIcon }
              </Avatar>
            </Grid>
            <Grid item>
              { step.heading && 
                <Typography variant='h6' color="primary" className={classes.heading}>
                  { step.heading }
                </Typography>
              }
            </Grid>
            <Grid item>
              <Box>
                <Typography variant='body1' align="center">
                  { step.text }
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Grid>
    )    
  }

  avatarClasses(step: IExplainerStep): any {
    const { classes } = this.props 
    let customColorClass = classes.darkGrey
    switch(step.avatarColor) {
      case 'primary': 
        customColorClass = classes.primary
        break 
      case 'secondary': 
        customColorClass = classes.secondary 
        break 
      case 'green': 
        customColorClass = classes.green
    }
    return classnames(classes.avatar, customColorClass)
  }
}

export default withTheme(withStyles(styles)(withWidth()(ExplainerStepsList)))
