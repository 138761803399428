import * as React from "react";
import {
  Card,
  CardActionArea,
  CardContent,
  createStyles,
  Grid,
  Theme,
  Typography,
  withStyles,
  WithStyles
} from "@material-ui/core";
import {inject, observer} from "mobx-react";
import {humanizeString, isoToLocalDate} from "../../stores/StoreUtilities";
import UserStore from "../../stores/UserStore";
import StarIcon from '@material-ui/icons/Star';
import PublishIcon from '@material-ui/icons/Publish';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import { RateReviewOutlined, ThumbUp, ThumbUpOutlined, ThumbDown, ThumbDownOutlined, ThumbsUpDownOutlined } from '@material-ui/icons';
import Visible from "../../components/Visible";
import JobPost from "../../model/JobPost";
import JobInterest from "../../model/JobInterest";
import {JobInterestStatus, WorkSetting} from "../../API";
import {format} from "date-fns";
import ResourceCache from "../../stores/ResourceCache";
import LocationOnIcon from '@material-ui/icons/LocationOn';
import LockIcon from '@material-ui/icons/Lock';
import ScheduleIcon from '@material-ui/icons/Schedule';
import BusinessIcon from '@material-ui/icons/Business';

const styles = (theme: Theme) => createStyles({
  card: {
    fontSize: 12,
    // padding: '8px !important', // overriding CardContent root setting
    paddingTop: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    paddingRight: theme.spacing(2),
  },
  status: {
    fontWeight: 200,
    color: theme.palette.text.secondary,
    maxHeight: 18,
    marginTop: theme.spacing(1)
  },
  saved: {
    textAlign: "right",
    color: theme.palette.text.secondary,
    maxHeight: 18
  },
  icon: {
    width: 20,
    height: 20
  },
  property: {
    display: "flex",
    flexDirection: "row",
    paddingBottom: "3px"
  },
  propIcon: {
    color: theme.palette.text.secondary,
    width: 18,
    height:18,
    paddingTop: 2,
    marginRight: theme.spacing(1)
  },
  body: {
  },
  title: {
    fontWeight: 600,
    lineHeight: 1.2,
    paddingTop: 4,
    paddingBottom: 4
  },
  account: {
    fontWeight: 600,
    // paddingTop: theme.spacing(0.5),
    // paddingBottom: theme.spacing(0.5)
  },
  summary: {
    maxHeight: 80,
    overflowY: "hidden",
  },
  hiringImmediately: {
    color: theme.palette.success.dark,
    fontWeight: 600
  },
  hiringDefault: {
    color: theme.palette.text.primary
  }
})

interface IJobBoardSearchCardProps {
  jobPost: JobPost
  jobInterest?: JobInterest
  onClick?: any
  userStore?: UserStore
  resourceCache?: ResourceCache
}

@inject("userStore", "resourceCache")
@observer
class JobBoardSearchCard extends React.Component<WithStyles<typeof styles> & IJobBoardSearchCardProps> {

  render() {
    const { classes, jobPost, jobInterest, userStore, resourceCache } = this.props

    let status
    let statusIcon
    if (jobInterest) {
      if (jobInterest.status === JobInterestStatus.Applied) {
        statusIcon = <PublishIcon className={classes.icon}/>
      } else if (jobInterest.status === JobInterestStatus.Invited) {
        statusIcon = <MailOutlineIcon className={classes.icon}/>
      } else if (jobInterest.status === JobInterestStatus.Accepted) {
        statusIcon = <ThumbUpOutlined className={classes.icon}/>
      } else if (jobInterest.status === JobInterestStatus.Reviewing) {
        statusIcon = <RateReviewOutlined className={classes.icon}/>
      } else if (jobInterest.status === JobInterestStatus.Rejected) {
        statusIcon = <ThumbDownOutlined className={classes.icon}/>
      } else if (jobInterest.status === JobInterestStatus.Contacted) {
        statusIcon = <ThumbsUpDownOutlined className={classes.icon}/>
      } else if (jobInterest.status === JobInterestStatus.Hired) {
        statusIcon = <ThumbUp className={classes.icon}/>
      } else if (jobInterest.status === JobInterestStatus.Declined) {
        statusIcon = <ThumbDown className={classes.icon}/>
      } else {
        statusIcon = <StarIcon className={classes.icon}/>
      }
      status = jobInterest.statusText
    } else {
      const postDate = isoToLocalDate(jobPost.openDate)
      status = `Posted on ${format(postDate, "M/d/yyyy")}`
      // saveIcon = <StarBorderIcon className={classes.icon}/>
    }

    const securityClearance = jobPost.securityClearance
    const hiringPeriod = jobPost.hiringPeriod
    const hiringClassname = hiringPeriod === "Immediately" ? classes.hiringImmediately : classes.hiringDefault

    return (
      <Card>
        <CardActionArea onClick={this.props.onClick ? () => this.props.onClick(jobPost) : undefined}>
          <CardContent className={classes.card}>
            <Grid container className={classes.body}>
              <Grid item xs={12}>
                <Typography variant="h6" color="primary" className={classes.title}>
                  {jobPost.title}{jobPost.openings > 1 && ` [${jobPost.openings}]`}
                </Typography>
              </Grid>
              <Visible if={userStore!.isAdminOrAgent}>
                <Grid item xs={12} className={classes.property}>
                  <BusinessIcon className={classes.propIcon}/>
                  <Typography variant='body2' className={classes.account}>{jobPost.account?.name}</Typography>
                </Grid>
              </Visible>
              <Grid item xs={12} className={classes.property}>
                <LocationOnIcon className={classes.propIcon}/>
                <Typography noWrap={true} variant='body2'>
                  {jobPost.locationDescription}
                </Typography>
              </Grid>
              {securityClearance &&
                <Grid item xs={12} className={classes.property}>
                  <LockIcon className={classes.propIcon}/>
                  <Typography noWrap={true} variant='body2'>{securityClearance}</Typography>
                </Grid>
              }
              <Grid item xs={12} className={classes.property}>
                <ScheduleIcon className={classes.propIcon}/>
                <Typography variant='body2' className={hiringClassname}>{humanizeString(jobPost.employmentType)} Hire {hiringPeriod}</Typography>
              </Grid>
              <Grid item xs={12} className={classes.summary}>
                <em><Typography variant='body2'>{jobPost.summary}</Typography></em>
              </Grid>
              {status &&
                <Grid item xs={10} className={classes.status}>
                  {status}
                </Grid>
              }
              <Grid item xs={2} className={classes.saved}>
                {statusIcon}
              </Grid>
            </Grid>
          </CardContent>
        </CardActionArea>
      </Card>

    )
  }
}

export default withStyles(styles)(JobBoardSearchCard)
