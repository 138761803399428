/* eslint-disable @typescript-eslint/no-unused-vars */
import * as React from "react";
import { ReactNode } from "react";
import Page from "../../components/page/Page";
import { RouteComponentProps } from "@reach/router";
import { inject, observer } from "mobx-react";
import {
  Box,
  createStyles,
  alpha,
  Grid,
  Hidden,
  isWidthDown,
  Theme,
  Typography,
  withStyles,
  WithStyles,
  withTheme,
  WithTheme,
  withWidth,
  Card,
} from "@material-ui/core";
import UserStore from "../../stores/UserStore";
import Progress from "../../components/Progress";
import { autorun, makeObservable, observable, when } from "mobx";
import ServiceGroup from "../../model/ServiceGroup";
import ResourceCache, {
  LocationOption,
  SelectOption,
} from "../../stores/ResourceCache";

import OpportunityPost from "../../model/opportunity/OpportunityPost";
import AccountStore from "../../stores/AccountStore";
import { AddressType, SubjectType, WorkSetting } from "../../API";
import Notify from "../../components/notify/Notify";
import DialogButton from "../../components/form/DialogButton";
import Confirm from "../../components/confirm/Confirm";
import ControlTower, { Routes } from "../../components/ControlTower";
import OpportunityMap from "../opportunity/OpportunityMap";
import { LngLatBounds } from "mapbox-gl";
import FilterBar from "../../components/filter/FilterBar";

// start filter components files
import TitleFilter from "../../components/filter/Opportunity/TitleFilter";
import TypeFilter from "../../components/filter/Opportunity/TypeFilter";
import PlaceFilter from "../../components/filter/Opportunity/PlaceFilter";
import AgencyFilter from "../../components/filter/Opportunity/AgencyFilter";
import NaicsFilter from "../../components/filter/Opportunity/NaicsFilter";
import SetAsideTypeFilter from "../../components/filter/Opportunity/SetAsideTypeFilter";
// end filter components files

import { WithWidth } from "@material-ui/core/withWidth";
import { ActivityType } from "../../model/UserActivity";
import { grey } from "@material-ui/core/colors";
import OpportunityStore, {
  IOpportunitySearchOptions,
  LocationOpportunityPosts,
} from "../../stores/OpportunityStore";
import Visible from "../../components/Visible";
import { createUUID } from "../../stores/StoreUtilities";
import SearchIcon from "@material-ui/icons/Search";
import Column, { IRow } from "../../components/panel/Column";
import { Agency } from "../../model/opportunity/Agency";
import Naics from "../../model/opportunity/Naics";

import OpportunityFilter from "../../model/opportunity/OpportunityFilter";
import OpportunityPostSearchCard from "./OpportunityPostSearchCard";
import OpportunitiesInfo from "./OpportunitiesInfo";

const splitLocationIdDigit = 4;

const styles = (theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      width: "100%",
      justifyContent: "top",
      alignItems: "center",
      overflow: "hidden",
    },
    title: {
      paddingTop: theme.spacing(0.5),
      paddingLeft: theme.spacing(1),
    },
    filterButtonOff: {
      backgroundColor: grey[400], // alpha(theme.palette.secondary.main, 0.3),
      color: "#fff",
      borderWidth: 0,
      marginTop: 0,
      marginRight: 8,
      paddingLeft: 8,
      boxShadow: "rgba(0, 0, 0, 0.25) 0px 1px 3px 0px",
      "&:hover": {
        borderWidth: 0,
        backgroundColor: alpha(theme.palette.secondary.light, 0.8),
      },
    },
    filterButtonOn: {
      backgroundColor: theme.palette.secondary.main,
      color: "#fff",
      borderWidth: 0,
      marginTop: 0,
      marginRight: 8,
      paddingLeft: 8,
      boxShadow: "rgba(0, 0, 0, 0.25) 0px 1px 3px 0px",
      "&:hover": {
        borderWidth: 0,
        backgroundColor: alpha(theme.palette.secondary.light, 0.8),
      },
    },
    content: {
      display: "flex",
    },
    opportunityBoard: {
      display: "flex",
      flexDirection: "column",
    },
    cardspace: {
      width: "100%",
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(2),
    },
    card: {
      width: 308,
    },
    opportunityPost: {
      flexGrow: 1,
      width: "100%",
    },
    noResults: {
      display: "flex",
      flexGrow: 0,
      flexDirection: "row",
      justifyContent: "center",
      color: theme.palette.text.primary,
      width: "100%",
      height: "fit-content",
    },
    map: {
      flexGrow: 0,
      width: "100%",
      padding: 0,
      [theme.breakpoints.down("xs")]: {
        height: "calc(100vh - 182px)",
        width: "100%",
        marginTop: theme.spacing(0),
      },
      [theme.breakpoints.up("sm")]: {
        height: "calc(100vh - 210px)",
        width: "100%",
        marginTop: theme.spacing(1),
        borderRadius: 10,
      },
      marginBottom: 12,
      overflow: "hidden",
    },
    filterbarMain: {
      marginBottom: "8px",
    },
  });

enum View {
  Map,
  List,
  OpportunityPost,
}

interface IOpportunityPageProps {
  userStore?: UserStore;
  resourceCache?: ResourceCache;
  accountStore?: AccountStore;
  opportunityStore?: OpportunityStore;
  progress?: Progress;
  notify?: Notify;
  confirm?: Confirm;
  location?: any;
}

@inject(
  "userStore",
  "resourceCache",
  "accountStore",
  "opportunityStore",
  "progress",
  "notify",
  "confirm"
)
@observer
class OpportunitysPage extends React.Component<
  WithStyles<typeof styles> &
    RouteComponentProps &
    IOpportunityPageProps &
    WithTheme &
    WithWidth
> {
  @observable serviceGroups?: ServiceGroup[] = [];
  // start filter
  @observable opportunityTitleFilter?: any;
  @observable opportunityTypeFilter?: SelectOption;
  @observable locationFilter?: LocationOption;
  @observable agencyFilter?: Agency;
  @observable naicsFilter?: Naics;
  @observable setAsideTypeFilter?: SelectOption;
  // end  filter
  @observable opportunityPosts: OpportunityPost[] = [];
  @observable opportunityPost?: OpportunityPost;
  @observable selectOpportunityPost?: OpportunityPost;
  @observable showResults = false;
  @observable isMapOn: boolean = false;
  @observable isCardspaceOn: boolean = true;
  @observable showMap: boolean = true;
  @observable locationOpportunityPosts: LocationOpportunityPosts[] = [];
  @observable view: View = View.Map;
  @observable viewport = {
    // Geographic center of continental US
    latitude: 39.828303,
    longitude: -98.579476,
    zoom: 2,
  };
  @observable bounds?: LngLatBounds;
  @observable industryFilter?: string;
  @observable isLoading = true;
  @observable nextApiCallLoader = true;
  @observable opportunityStore?: OpportunityStore;

  defaultSearchRadius = 50 * 1609.34; // 50 miles in meters

  constructor(
    props: WithStyles<typeof styles> &
      RouteComponentProps &
      IOpportunityPageProps &
      WithTheme &
      WithWidth
  ) {
    super(props);
    makeObservable(this);
  }

  componentDidMount() {
    const { userStore, opportunityStore, resourceCache, progress, location } =
      this.props;

    this.isLoading = true;
    progress!.show("JobsPage");
    when(
      // once
      () =>
        !userStore!.isLoading &&
        !opportunityStore!.isLoading &&
        !resourceCache!.isLoading,
      // then
      async () => {
        if (this.isWidthSM()) {
          this.viewport.zoom = 2;
        }
        if (this.locationFilter) {
          this.updateViewport();
        } else {
          this.viewport = {
            // Geographic center of continental US
            latitude: 39.828303,
            longitude: -98.579476,
            zoom: 2,
          };
          if (this.isWidthSM()) {
            this.viewport.zoom = 2;
          }
        }

        // Wait for map to load
        // TODO: Investigate other options based on map loading status
        setTimeout(() => {
          this.isMapOn = true;
        }, 2000);
        this.isLoading = false;
        progress!.hide("JobsPage");

        resourceCache!.getPscCategories();
      }
    );
  }


  render() {
    const { classes } = this.props;
    const title = "Opportunities & Awards";

    return (
      <div className="opportunity_main_wrapper">
        <Page title={title} hideFooter={true}>
          {this.isLoading === false && (
            <Box px={{ xs: 0, sm: 1.5 }} pt={1} width={"100%"}>
              <div className={classes.title}>
                <Grid container alignItems="center" spacing={2}>
                  <Grid item>
                    <Typography variant="h3" color="primary">
                      {title}
                    </Typography>
                  </Grid>
                  <Hidden xsDown>
                    <Grid item>
                      <Typography variant="body2">
                        Find relevant contacting opportunities that match your
                        criteria.
                      </Typography>
                    </Grid>
                  </Hidden>
                </Grid>
              </div>

              {this.renderFilterBar()}

              {this.isMapOn && this.renderMap()}
              {!this.isMapOn &&
                !this.opportunityPost &&
                this.renderCardspace()}
            </Box>
          )}
        </Page>
      </div>
    );
  }

  renderMap = () => {
    const { classes } = this.props;

    return (
      <div className={`${classes.content} opportunity_content_main`}>
        {this.isCardspaceOn && this.renderCardspace()}
        {this.showMap && (
          <div className={`${classes.map} opportunity_map_main`}>
            <OpportunityMap
              viewport={this.viewport}
              locationOpportunityPosts={this.locationOpportunityPosts}
              selectedLocationId={this.locationFilter ? this.locationFilter.id : undefined}
              onViewportChange={this.onViewportChange}
              onClickLocation={this.onClickLocation}
              splitLocationIdDigit={splitLocationIdDigit}
            />
          </div>
        )}
      </div>
    );
  };

  renderCardspace = () => {
    const { classes } = this.props;

    return (
      <div className={`${classes.cardspace} opportunity_cardspace_main`}>
        {this.showResults && this.opportunityPosts.length > 0 && (
          <>
            <div className="rowOpportunity">
              <p className="opportunity_heading">
                <span>
                  Opportunities Found
                  {this.nextApiCallLoader ? (
                    <span className="visually-hidden opportunity_visually_hidden">
                      Loading
                      <div className ="opportunity-loader--ellipsis">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                      </div>
                    </span>
                  ) : null}
                </span>
                <span>{this.opportunityPosts.length} </span>
              </p>
              <div className="opportunity_cardspace_scroll">
                {this.selectOpportunityPost ? (
                  <OpportunitiesInfo mockData={this.selectOpportunityPost} />
                ) : null}

                <OpportunityPostSearchCard
                  mockData={this.opportunityPosts}
                  onClickOpportunityPost={this.onClickOpportunityPost}
                />
              </div>
            </div>
          </>
        )}
        {this.showResults && this.opportunityPosts.length === 0 && (
          <div className={classes.noResults}>{this.renderNoResults()}</div>
        )}
      </div>
    );
  };

  renderFilterBar = () => {
    const { classes, userStore } = this.props;
    

    let viewButton;

    // viewButton = (
    //   <DialogButton
    //     variant="secondary"
    //     size="large"
    //     customClassName={classes.filterButtonOn}
    //   >
    //     <SearchIcon />
    //     &nbsp;&nbsp;Search
    //   </DialogButton>
    // );

    const buttons: [ReactNode] = [viewButton];

    return (
      // filter bar
      <Box py={1} className={`${classes.filterbarMain} filterbar_main`}>
        <FilterBar
          searchResultCount={this.opportunityPosts.length}
          buttons={buttons}
        >
          <TitleFilter
            value={this.opportunityTitleFilter}
            onSearchTitleOpportunity={this.onSearchTitleOpportunity}
          />
          <TypeFilter
            value={this.opportunityTypeFilter}
            onSelectTypeOpportunity={this.onSelectTypeOpportunity}
          />
          <PlaceFilter
            value={this.locationFilter}
            onSelectLocation={this.onSelectLocation}
          />
          <AgencyFilter
            value={this.agencyFilter}
            onSelectAgency={this.onSelectAgency}
          />
          <NaicsFilter
            value={this.naicsFilter}
            onSelectNaics={this.onSelectNaics}
          />
          <SetAsideTypeFilter
            value={this.setAsideTypeFilter}
            onSelectSetAsideTypeOpportunity={
              this.onSelectSetAsideTypeOpportunity
            }
          />
        </FilterBar>
      </Box>
    );
  };

  renderNoResults = () => {
    const { userStore } = this.props;

    if (this.locationFilter) {
      return (
        <Box
          borderRadius={10}
          bgcolor="grey.500"
          boxShadow={0}
          mb={3}
          px={1}
          pt={1}
          pb={1}
        >
          <Grid container direction="column" spacing={2}>
            <Grid item>
              <Typography variant="h6" align="center" gutterBottom={true}>
                Sorry, no opportunity matched your search.
                <br />
                Please try a different search.
              </Typography>
            </Grid>
            {!userStore!.isAuthenticated && [
              <Grid item>
                <Typography variant="h6" align="center">
                  If you haven't joined GovGig, we encourage you to apply so
                  employers can find you!
                </Typography>
              </Grid>,
              <Grid item xs={12} style={{ textAlign: "center" }}>
                <DialogButton
                  variant="primary"
                  onClick={() => ControlTower.route(Routes.apply)}
                >
                  Apply
                </DialogButton>
              </Grid>,
            ]}
          </Grid>
        </Box>
      );
    } else {
      return (
        <Box
          borderRadius={10}
          bgcolor="grey.500"
          boxShadow={0}
          px={1}
          pt={1}
          pb={1}
          width={"100%"}
        >
          <Typography variant="h6" align="center">
            search and Location to view opportunities
          </Typography>
        </Box>
      );
    }
  };

  // start filter
  onSearchTitleOpportunity = async (value?: string) => {
    if (value) {
      this.opportunityTitleFilter = value;
    } else {
      this.opportunityTitleFilter = undefined;
    }
    this.refreshMap();
  };

  onSelectTypeOpportunity = (value?: SelectOption) => {
    if (value) {
      this.opportunityTypeFilter = value;
    } else {
      this.opportunityTypeFilter = undefined;
    }
    this.refreshMap();
  };

  onSelectLocation = (value?: LocationOption) => {
    // Temporarily hide the map to force a call to onViewportChange
    const restoreMap = this.isMapOn
    this.isMapOn = false

    if (value) {
      this.locationFilter = value;
    } else {
      this.locationFilter = undefined
    }
    this.locationOpportunityPosts = []
    this.isMapOn = true;
    setTimeout(() => {this.updateViewport()}, 200);
  }


  onSelectAgency = (value?: Agency) => {
    if (value) {
      this.agencyFilter = value;
    } else {
      this.agencyFilter = undefined;
    }
    this.refreshMap();
  };

  onSelectNaics = (value?: Naics) => {
    if (value) {
      this.naicsFilter = value;
    } else {
      this.naicsFilter = undefined;
    }
    this.refreshMap();
  };

  onSelectSetAsideTypeOpportunity = (value?: SelectOption) => {
    if (value) {
      this.setAsideTypeFilter = value;
    } else {
      this.setAsideTypeFilter = undefined;
    }
    this.refreshMap();
  };
  // end filter

  refreshMap = () => {
    if (this.isMapOn && this.bounds) {
      this.onViewportChange(this.viewport, this.bounds);
    } else {
      this.searchOpportunityBoard();
    }
  };

  onClickLocation = async (locationId?: string) => {
    const { resourceCache, opportunityStore } = this.props;
    this.selectOpportunityPost = undefined;
    if (locationId) {
      this.locationFilter = resourceCache!.getLocationOption(locationId)
      if(!this.locationFilter) {
        let addressInfo = await opportunityStore!.getAddress(locationId);
        if(addressInfo && addressInfo?.id) {
          this.locationFilter = addressInfo;
        }
      }
    } else {
      this.locationFilter = undefined;
    }
    this.searchOpportunities(true);
  };

  onViewportChange = async (viewport: any, bounds: LngLatBounds) => {
    const { opportunityStore, resourceCache } = this.props;
    this.viewport = viewport;
    this.bounds = bounds;

    const serviceId = undefined;
    const locationId = this.locationFilter ? this.locationFilter?.id : "";
    const title = this.opportunityTitleFilter
      ? this.opportunityTitleFilter
      : "";
    const type = this.opportunityTypeFilter
      ? this.opportunityTypeFilter.value
      : "";
    const agency = this.agencyFilter ? this.agencyFilter.cgac_agency_code : "";
    const naics = this.naicsFilter ? this.naicsFilter.naics_code : "";
    const setAsideType = this.setAsideTypeFilter
      ? this.setAsideTypeFilter.value
      : "";

    let stateSearch;
    let countrySearch;
    let anywhereSearch;
    let remoteSearch;
    if (this.locationFilter) {
      const location = resourceCache!.getLocation(this.locationFilter!.id);
      if (location?.addressType === AddressType.State) {
        stateSearch = location.state;
      } else if (location?.addressType === AddressType.Country) {
        countrySearch = location.country;
      } else if (location?.addressType === AddressType.World) {
        if (location.name === "Remote") {
          remoteSearch = true;
        } else {
          anywhereSearch = true;
        }
      }
    }

    let location_type = "";
    let location_value: any = "";
    if (stateSearch) {
      location_type = "state";
      location_value = stateSearch;
    } else if (countrySearch) {
      location_type = "country";
      location_value = countrySearch;
    } else if (anywhereSearch || remoteSearch) {
      location_type = "anywhere";
      location_value = "";
    } else {
      location_type = "bounds";
      location_value = bounds;
    }

    let filter: OpportunityFilter = {
      title: title,
      type: type,
      agency: agency,
      naics: naics,
      set_aside_type: setAsideType,
      location_id: locationId,
      location_type: location_type,
      location_value: location_value,
      nextPage: false,
      limit: 400,
      Id: "",
      createdAt: "",
      naicsCode: "",
    };
    // console.log('rkk search filter :::', filter)

    let locationOpportunityPosts;
    let options: IOpportunitySearchOptions = {
      industry: this.industryFilter,
      workSetting: remoteSearch ? WorkSetting.Remote : undefined,
    };

    if (stateSearch) {
      locationOpportunityPosts =
        await opportunityStore!.listLocationOpportunityPostsByState(
          stateSearch,
          serviceId,
          options
        );
    } else if (countrySearch) {
      locationOpportunityPosts =
        await opportunityStore!.listLocationOpportunityPostsByCountry(
          countrySearch,
          serviceId,
          options
        );
    } else if (anywhereSearch || remoteSearch) {
      locationOpportunityPosts =
        await opportunityStore!.listLocationOpportunityPostsAnywhere(
          serviceId,
          options
        );
    } else {
      let response =
        await opportunityStore!.listLocationOpportunityPostsByBounds(
          options,
          filter,
          splitLocationIdDigit
        );
      this.locationOpportunityPosts = response.locationOpportunityPosts;
      filter = response.params;
      this.searchOpportunities(false);

      if (filter.nextPage === true) {
        setTimeout(() => {
          this.nextApiCall(options, filter);
        }, 200);
      } else {
        this.nextApiCallLoader = false;
      }
    }
  };

  nextApiCall = async (options: any, filter: any) => {
    const { opportunityStore, resourceCache } = this.props;
    let res = await opportunityStore!.listLocationOpportunityPostsByBounds(
      options,
      filter,
      splitLocationIdDigit
    );
    this.locationOpportunityPosts = res.locationOpportunityPosts;
    filter = res.params;
    this.searchOpportunities(false);

    if (filter.nextPage === true) {
      setTimeout(() => {
        this.nextApiCall(options, filter);
      }, 200);
    } else {
      this.nextApiCallLoader = false;
    }
  };

  searchOpportunities = async (markerShowLists: boolean) => {
    const { resourceCache } = this.props;
    this.showResults = false;

    let locationId = this.locationFilter ? this.locationFilter?.id : "";
    let opportunityPosts: OpportunityPost[] = [];

    if (opportunityPosts.length === 0) {
      // If no opportunity for the selected location, then show all opportunity in view
      this.locationOpportunityPosts.forEach(
        (locationOpportunityPosts: LocationOpportunityPosts) => {
          opportunityPosts = [
            ...opportunityPosts,
            ...locationOpportunityPosts.opportunityPosts,
          ];
        }
      );
    }

    this.showResults = true;
    

    if (markerShowLists && locationId) {
      let getLocationId = locationId.substring(0, splitLocationIdDigit);
      const filteredTitle = opportunityPosts.filter(
        (item) =>
          item?.location &&
          item?.location?.id &&
          item?.location?.id.toLowerCase().includes(getLocationId)
      );
      opportunityPosts = filteredTitle;
    }

    opportunityPosts.sort((a: OpportunityPost, b: OpportunityPost) => {
      return b.index > a.index ? -1 : 1;
    });
   
    this.opportunityPosts = opportunityPosts;
    this.showResults = true;
  };

  //////////////////////////////////////////////////////////////////////
  searchOpportunityBoard = async () => {
    console.log("rkk searchOpportunityBoard");
  };

  onClickOpportunityPost = async (opportunityPost: OpportunityPost) => {
    const { resourceCache, userStore, opportunityStore } = this.props;

    let agencyInfo = resourceCache!.getAgencyOption(opportunityPost.agencyCode);
    if (agencyInfo && agencyInfo.agency_name) {
      opportunityPost.agency = agencyInfo;
    }

    let naicsInfo = resourceCache!.getNaicsOption(opportunityPost.naicsCode);
    if (naicsInfo && naicsInfo.naics_title) {
      opportunityPost.naics = naicsInfo;
    }

    let pscCategoryInfo = resourceCache!.getPscCategoriesInfo(
      opportunityPost.classificationCode
    );
    if (pscCategoryInfo && pscCategoryInfo.name) {
      opportunityPost.psc = pscCategoryInfo;
    }

    this.selectOpportunityPost = opportunityPost ?? undefined;

    // if detail API is required then we can call detail API here

    // Scroll top
    const container = document.querySelector("#opportunityinfo_div_main");
    if (container) {
      container.scrollIntoView({ behavior: "smooth" });
    }
  };


  // get zoom. lat, lng according from locationid
  updateViewport = () => {
    const { resourceCache } = this.props;
    if (this.locationFilter) {
      const location = resourceCache!.getLocation(this.locationFilter!.id);
      if (location) {
        let zoom = 8;
        switch (location.addressType) {
          case AddressType.World:
            zoom = 1;
            break;
          case AddressType.Country:
            zoom = 3;
            break;
          case AddressType.State:
            zoom = 5;
            break;
          case AddressType.County:
            zoom = 7;
            break;
          case AddressType.City:
            zoom = 8;
            break;
          default:
            zoom = 9;
            break;
        }
        // console.log(`updateViewport: (${location.latitude}, ${location.longitude}), ${zoom}`)
        this.viewport = {
          latitude: location.latitude,
          longitude: location.longitude,
          zoom: zoom,
        };
      }
      this.searchOpportunities(true);
    } else {
      this.viewport = {
        latitude: 39.828303,
        longitude: -98.579476,
        zoom: 2,
      };
      setTimeout(() => {this.refreshMap()}, 200)
    }
  };

  isWidthXS = (): boolean => {
    return isWidthDown("xs", this.props.width);
  };

  isWidthSM = (): boolean => {
    return isWidthDown("sm", this.props.width);
  };
}

export default withTheme(withStyles(styles)(withWidth()(OpportunitysPage)));
