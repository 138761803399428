import Account from "./Account";
import {UserRole, UserStatus} from "../API";
import Agreement from "./Agreement";
import Profile from "./Profile";

class User {
  id: string
  createdAt: string
  updatedAt: string
  active: boolean
  userStatus: UserStatus
  accountId: string
  account?: Account
  firstName: string
  lastName: string
  email: string
  phone: string
  role: UserRole
  address: string
  city: string
  state: string
  postalCode: string
  country: string
  industry?: string
  lastActiveAt: string
  agreements: Agreement[]
  profiles: Profile[]

  constructor (data: any) {
    this.id = data.id
    this.createdAt = data.createdAt
    this.updatedAt = data.updatedAt
    this.active = data.active
    this.userStatus = data.userStatus ? data.userStatus : UserStatus.Registered
    this.accountId = data.accountId
    this.account = data.account ? new Account(data.account) : undefined
    this.firstName = data.firstName
    this.lastName = data.lastName
    this.email = data.email
    this.phone = data.phone
    this.role = data.role
    this.address = data.address
    this.city = data.city
    this.state = data.state
    this.postalCode = data.postalCode
    this.country = data.country
    this.industry = data.industry
    this.lastActiveAt = data.lastActiveAt
    this.agreements = []
    if (data.agreements && data.agreements.items) {
      this.loadAgreements(data.agreements.items)
    }
    this.profiles = []
    if (data.profiles && data.profiles.items) {
      this.loadProfiles(data.profiles.items)
    }
  }

  loadAgreements(items: any[]) {
    this.agreements = items.map((item: any) => new Agreement(item))
  }

  loadProfiles(items: any[]) {
    this.profiles = items.map((item: any) => new Profile(item))
  }

  get profile(): Profile | undefined {
    if (this.profiles && this.profiles.length > 0) {
      return this.profiles[0]
    } else {
      return undefined
    }
  }

  set profile(value: Profile | undefined) {
    if (this.profiles && this.profiles.length > 0) {
      if (value) {
        this.profiles[0] = value
      } else {
        this.profiles.splice(0, 1)
      }
    } else if (value) {
      this.profiles = [value]
    }
  }

  get fullName(): string {
    return `${this.firstName} ${this.lastName}`
  }

  get nickname(): string {
    return `${this.firstName} ${this.lastName.substr(0, 1)}`
  }

  get isActive(): boolean {
    return this.active
  }

  get isApplicant(): boolean {
    return this.role === UserRole.Applicant || this.role === UserRole.Student
  }

  get isStudent(): boolean {
    return this.role === UserRole.Student
  }

  get isCandidate(): boolean {
    return this.role === UserRole.Candidate
  }

  get isEmployer(): boolean {
    return this.role === UserRole.Employer
  }

  get isAdmin(): boolean {
    return this.role === UserRole.Admin
  }

  get isAgent(): boolean {
    return this.role === UserRole.Agent
  }

  get isAdminOrAgent(): boolean {
    return this.role === UserRole.Admin || this.role === UserRole.Agent
  }

  get agreement(): Agreement | undefined {
    return this.agreements.length > 0 ? this.agreements[0] : undefined
  }

  set agreement(agreement: Agreement | undefined) {
    if (agreement) {
      this.agreements[0] = agreement 
    }
  }
}

export default User
