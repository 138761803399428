import {
  alpha,
  Box,
  Button,
  createStyles,
  Grid,
  Theme,
  Typography,
  WithStyles,
  withStyles,
  WithTheme,
  withTheme,
  withWidth,
  WithWidth
} from "@material-ui/core";
import {makeObservable, observable} from "mobx";
import {inject, observer} from "mobx-react";
import React from "react";
import DialogButton from "../../components/form/DialogButton";
import Notify from "../../components/notify/Notify";
import ProfileHeroImage from "../../images/Profile_Hero_Image_New.jpg";
import Profile, {ProfileAvailabilityOptions} from "../../model/Profile";
import UserStore from "../../stores/UserStore";
import AccountStore from "../../stores/AccountStore";
import JobCandidate from "../../model/JobCandidate";
import JobPost from "../../model/JobPost";
import Account from "../../model/Account";
import JobStore from "../../stores/JobStore";
import ProfileNameView from "../../components/profile/ProfileNameView";
import Stack from "../../components/Stack";
import ScheduleIcon from '@material-ui/icons/Schedule';
import PersonIcon from '@material-ui/icons/Person';
import BusinessIcon from '@material-ui/icons/Business';
import LockIcon from '@material-ui/icons/Lock';
import ResourceCache from "../../stores/ResourceCache";
import { Add } from "@material-ui/icons";
import ProfileCredentialsView from "../../views/profiles/ProfileCredentialsView";
// import JobPickerDialog from "./job-picker/JobPickerDialog";
// import UserActivity, {ActivityType} from "../../model/UserActivity";
// import Logger from "../../components/Logger";
// import JobCandidateInviteDialog from "../../components/jobPosts/JobCandidateInviteDialog";
// import LinkIcon from '@material-ui/icons/Link';
// import MailOutlineIcon from '@material-ui/icons/MailOutline';
// import StarBorderIcon from '@material-ui/icons/StarBorder';
// import StarIcon from '@material-ui/icons/Star';
// import SendIcon from '@material-ui/icons/Send';
// import {CopyToClipboard} from 'react-copy-to-clipboard';
// import {
//   CreateJobCandidateInput,
//   CreateUserActivityInput,
//   JobCandidateStatus,
//   ProfileStatus,
//   SubjectType
// } from "../../API";

const styles = (theme: Theme) => createStyles({
  panel: {
    paddingTop: theme.spacing(2),
    paddingRight: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    paddingLeft: theme.spacing(3),
    background: `darkblue url(${ProfileHeroImage}) no-repeat center center`,
    backgroundSize: 'cover',
    color: theme.palette.common.white,
    borderRadius: '10px 10px 0px 0px',
    height: "100%"
  },
  actionButton: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
    marginTop: 0,
    marginLeft: 0,
    border: 'none',
    boxShadow: 'rgba(0, 0, 0, 0.25) 0px 1px 3px 0px',
    "&:hover": {
      color: theme.palette.secondary.contrastText,
      backgroundColor: theme.palette.secondary.light,
      border: 'none',
    },
    "&:disabled": {
      backgroundColor: alpha(theme.palette.secondary.main, 0.5),
      color: alpha(theme.palette.secondary.contrastText, 0.5),
    },
  },
  actionButtonSubmit: {
    minWidth: 80,
  },
  actionButtonShare: {
    // marginLeft: '1rem',
    borderRadius: 50,
    padding: '.5rem',
    minWidth: 'fit-content',
  },
  actionButtonShareIcon: {
  },
  availableNow: {
    color: theme.palette.success.light
  },
  availableSoon: {
    color: theme.palette.warning.main
  },
  availableLater: {
    color: theme.palette.error.main
  },
  unavailable: {
    color: theme.palette.text.secondary
  }
})

export interface IProfileHeroProps {
  // isSaved?: boolean
  profile?: Profile
  onChange?: (profile: Profile) => any
  account?: Account
  
  // When shown in context of reviewing the Candidate after Interested or Invite JobCandidate status: 
  jobCandidate?: JobCandidate
  // jobCandidateId?: string

  // When shown in context of searching for Candidates prior to engaging with them:
  jobPostId?: string

  accountStore?: AccountStore
  jobStore?: JobStore
  notify?: Notify
  userStore?: UserStore
  resourceCache?: ResourceCache
}

@inject(
  "userStore", 
  "accountStore", 
  "jobStore", 
  "notify", 
  "resourceCache"
)
@observer
class ProfileHero extends React.Component<WithStyles<typeof styles> & WithTheme & WithWidth & IProfileHeroProps> {

  @observable jobPickerDialogOpen: boolean = false
  @observable invitePending: boolean = false
  @observable inviteDialogOpen: boolean = false
  // @observable jobCandidate?: JobCandidate
  @observable jobPost?: JobPost
  // @observable isSaved: boolean = false

  constructor(props: WithStyles<typeof styles> & WithTheme & WithWidth & IProfileHeroProps) {
    super(props)
    makeObservable(this)
  }

  componentDidMount() {
    // this.isSaved = Boolean(this.props.isSaved || this.props.jobCandidateId)
    // if (this.props.jobCandidateId) {
    //   this.loadJobCandidate()
    // }
  }

  componentDidUpdate(prevProps: Readonly<WithStyles<typeof styles> & WithTheme & WithWidth & IProfileHeroProps>, prevState: Readonly<{}>, snapshot?: any) {
    // this.isSaved = Boolean(this.props.isSaved || this.props.jobCandidateId)
  }

  // render

  // renderInviteButton() {
  //   const { classes, userStore } = this.props

  //   let actionButton = null

  //   const sharedProps = {
  //     variant: 'secondary',
  //     customClassName: `${classes.actionButton} ${classes.actionButtonSubmit}`,
  //   }

  //   if (userStore!.isAdminOrAgent || userStore!.isEmployer) {
  //     const disabled = (this.jobCandidate !== undefined && this.jobCandidate.status !== JobCandidateStatus.Interested)
  //     actionButton =
  //       <DialogButton  {...sharedProps} onClick={this.onInvite} disabled={disabled}>
  //         <SendIcon />&nbsp;{disabled ? "Invited" : "Invite"}
  //       </DialogButton>
  //   }

  //   if (actionButton) {
  //     return (
  //       <Grid item>
  //         {actionButton}
  //       </Grid>
  //     )
  //   } else {
  //     return null
  //   }
  // }

  // renderActionButton() {
  //   const { classes, profile, userStore } = this.props

  //   let actionButton = null

  //   const sharedProps = {
  //     variant: 'secondary',
  //     customClassName: `${classes.actionButton} ${classes.actionButtonSubmit}`,
  //   }

  //   if (profile?.profileStatus === ProfileStatus.Accepted && (userStore!.isAdminOrAgent || userStore!.isEmployer)) {
  //     if (this.isSaved) {
  //       actionButton =
  //         <DialogButton  {...sharedProps} disabled>
  //           <StarIcon />&nbsp;Saved
  //         </DialogButton>
  //     } else {
  //       actionButton =
  //         <DialogButton {...sharedProps} onClick={this.onSave}>
  //           <StarBorderIcon />&nbsp;Save
  //         </DialogButton>
  //     }
  //   }

  //   if (actionButton) {
  //     return (
  //       <Grid item>
  //         {actionButton}
  //       </Grid>
  //     )
  //   } else {
  //     return null 
  //   }
  // }

  renderShareButton(IconComp: any, props: object = {}) {
    const { classes } = this.props
    return (
      <DialogButton
        variant="secondary"
        customClassName={`${classes.actionButton} ${classes.actionButtonShare}`}
        {...props}
      >
        <IconComp className={classes.actionButtonShareIcon} />
      </DialogButton>
    )
  }

  // renderButtons() {
  //   return (
  //     <>
  //       {profile?.alias && userStore!.isAuthenticated && (
  //         <Grid item>
  //           <Box px={2} py={1} bgcolor="rgba(13, 52, 106, 0.3)" boxShadow='rgba(0, 0, 0, 0.15) 0px 5px 15px 0px' borderRadius={10}>
  //             <Grid container alignItems="center" wrap="nowrap" spacing={2}>
  //               {this.renderInviteButton()}
  //               {this.renderActionButton()}
  //               <Grid item>
  //                 <CopyToClipboard
  //                   text={this.profileUrl}
  //                   onCopy={() => notify!.show('success', 'Copied profile link to clipboard!')}>
  //                   {this.renderShareButton(LinkIcon)}
  //                 </CopyToClipboard>
  //               </Grid>
  //               {userStore!.isAuthenticated &&
  //                 <Grid item>
  //                   <a href={`mailto:${profile!.user?.email}`}>
  //                     {this.renderShareButton(MailOutlineIcon)}
  //                   </a>
  //                 </Grid>
  //               }
  //             </Grid>
  //           </Box>
  //         </Grid>
  //       )} 
  //     </>
  //   )
  // }

  renderCard(
    icon: React.ReactNode, 
    title: string,
    value: string,
    instructions: string, // Future TODO: Could provide helper text in an info popup, with a help (?) button. 
    textColor: string 
  ) {
    const { theme } = this.props 
    return (
      <Box
        bgcolor={
          // alpha(theme.palette.primary.main, 0.45)
          'hsla(0, 0%, 10%, 70%)'
        }
        color="#fff"
        pt={2}
        pb={2}
        pl={3}
        pr={4}
        borderRadius={10}
        height="100%"
        width="100%"
      >
        <Grid 
          container
          direction="column"
          spacing={2}
        >
          <Grid item>
            <Stack
              spacing={1}
              alignItems="center"
            >
              <Box
                px={1}
                pt={1}
                pb={0.5}
                bgcolor={alpha(theme.palette.primary.main, 0.5)}
                borderRadius={10}
              >
                {icon}
              </Box>
              <Typography variant="body1">
                {title}
              </Typography>
            </Stack>
          </Grid>
          <Grid item>
            <Box
              // pt={2}
              // pl={2}
              sx={{
                color: textColor
              }}
            >
              <Typography 
                variant="h6"
                color="inherit"
                // align="center"
              >
                <b>
                  {value}
                </b>
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>
    )
  }

  render() {
    const {
      classes,
      profile,
      jobCandidate,
      onChange,
      userStore,
      theme 
    } = this.props

    if (!profile) {
      return null
    }

    let experience: string | undefined = undefined
    if (profile!.federalExperience && profile!.totalExperience) {
      const federalYears: string = `${Math.round(profile!.federalExperience * 100) / 100} year${profile!.federalExperience >= 2 ? "s" : ""}`
      const totalYears: string = `${Math.round(profile!.totalExperience * 100) / 100} year${profile!.totalExperience >= 2 ? "s" : ""}`
      experience = `${federalYears} Federal (${totalYears} total)`
      // experience = federalYears
    }

    let availability = profile.availability
    let availabilityTextColor
    if (availability === ProfileAvailabilityOptions[0]) {
      availabilityTextColor = theme.palette.success.light
    } else if (availability === ProfileAvailabilityOptions[4]) {
      availabilityTextColor = theme.palette.error.main
    } else if (availability === ProfileAvailabilityOptions[5]) {
      availabilityTextColor = theme.palette.text.secondary
    } else {
      availabilityTextColor = theme.palette.warning.main
    }

    const isProfileEditable = userStore!.isProfileEditable(profile)

    let clearance
    if (profile.securityClearance) {
      clearance = profile.securityClearance
      clearance += ` (${profile.securityStatus})`
    } else if (isProfileEditable) {
      clearance = 'None'
    }

    return (
      <div className={classes.panel}>
        <Grid 
          container 
          direction="column"
          spacing={1}
        >
          <Grid item xs={12}>
            <Box
              // pb={0.5}
            >
              <PersonIcon 
                fontSize="large"
              />
            </Box>
          </Grid>
          <Grid item>
            <Stack
              direction="row"
              alignItems="center"
              spacing={0}
            >
              <Box
                sx={{
                  color: "#fff",
                  pb: 1
                }}
              >
                <ProfileNameView
                  size="large"
                  profile={profile}
                  jobCandidate={jobCandidate}
                  textColor="inherit"
                  textVariant="h2"
                  onUpdateProfile={async (updatedProfile: Profile) => {
                    if (onChange) {
                      await onChange(updatedProfile)
                    }
                  }}
                />
              </Box>
              {/* <Box
                sx={{
                  pb: 1
                }}
              >
                <ProfileCredentialsView 
                  profile={profile} 
                  textVariant="h2"
                  onChange={async (updatedProfile: Profile) => {
                    if (onChange) {
                      await onChange(updatedProfile)
                    }
                  }}  
                />
              </Box> */}
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <Grid 
              container 
              direction="row"
              spacing={2}
            >
              { availability &&
                <Grid 
                  item 
                  // xs={12} 
                  // sm={4} 
                  // lg={3}
                >
                  {this.renderCard(
                    <ScheduleIcon color="inherit" />,
                    "Availability",
                    availability,
                    isProfileEditable ? "Edit in personal info section" : "",
                    availabilityTextColor
                  )}
                </Grid>
              }
              { experience &&
                <Grid 
                  item 
                  // xs={12} 
                  // sm={4} 
                  // lg={3}
                >
                  {this.renderCard(
                    <BusinessIcon color="inherit" />,
                    "Experience",
                    experience,
                    "",
                    ""
                  )}
                </Grid>
              }
              { clearance &&
                <Grid 
                  item 
                  // xs={12} 
                  // sm={4} 
                  // lg={3}
                >
                  {this.renderCard(
                    <LockIcon color="inherit" />,
                    "Security Clearance",
                    clearance,
                    "",
                    ""
                  )}
                </Grid>
              }
            </Grid>
          </Grid>
        </Grid>
      </div>
    )
  }

  get profileUrl () {
    const { profile } = this.props
    const url = new URL(window.location.href)
    return `${url.origin}/profile/${profile!.alias}`
  }

  // loadJobCandidate = async () => {
  //   const { jobCandidateId, jobStore, profile } = this.props
  //   if (jobCandidateId) {
  //     this.jobCandidate = await jobStore!.getJobCandidate(jobCandidateId)
  //     if (this.jobCandidate) {
  //       this.jobCandidate.profile = profile
  //       if (!this.jobPost) {
  //         this.jobPost = await jobStore!.getJobPost(this.jobCandidate.jobPostId)
  //       }
  //       this.jobCandidate.jobPost = this.jobPost
  //       this.isSaved = true
  //     }
  //   }
  // }
}

export default withTheme(withStyles(styles)(withWidth()(ProfileHero)))
