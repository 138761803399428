import InvoiceItem from "./InvoiceItem";

export class CustomField {
  name: string
  value: string

  constructor(data: any) {
    this.name = data.name
    this.value = data.value
  }
}

class Invoice {
  customer: string
  collection_method: string
  auto_advance: boolean
  custom_fields: CustomField[]
  items: InvoiceItem[]
  discounts: any[]

  constructor (data: any) {
    this.customer = data.customer
    this.collection_method = data.collection_method
    this.auto_advance = data.auto_advance
    if (data.custom_field) {
      this.custom_fields = data.custom_fields.map((field: any) => {
        return new CustomField(field)
      })
    } else {
      this.custom_fields = []
    }
    if (data.items) {
      this.items = data.items.map((item: any) => {
        return new InvoiceItem(item)
      })
    } else {
      this.items = []
    }
    this.discounts = data.discounts
  }
}

export default Invoice
