import {makeObservable, observable} from "mobx";
import GovGigAPI from "../apis/GovGigAPI";
import * as APITypes from "../API"
import {
  AddressType,
  JobPostStatus,
  UpdateJobCandidateInput,
  UpdateJobPostInput,
  WorkSetting
} from "../API"
import Location from "../model/Location";
import JobPost from "../model/JobPost";
import CacheStore from "./CacheStore";
import ResourceCache, { LocationOption } from "./ResourceCache";
import {LngLat, LngLatBounds, LngLatLike} from "mapbox-gl";
import Contract from "../model/Contract";
import UserStore from "./UserStore";
import JobCandidate from "../model/JobCandidate";
import JobInterest from "../model/JobInterest";
import UserActivity from "../model/UserActivity";
import {getISODateToday} from "./StoreUtilities";
import {NAICS} from "../model/Industry";
import {matchSorter} from "match-sorter";
import Logger from "../components/Logger";
import ServiceRequest from "../model/ServiceRequest";

export interface IJobSearchOptions {
  industry?: string
  workSetting?: WorkSetting
}

export interface ServiceRequestResult {
  serviceRequests: ServiceRequest[] 
  nextToken?: string 
}

class JobStore {
  govGigAPI: GovGigAPI
  resourceCache: ResourceCache
  jobPostStatusOptions: string[] = []
  jobPostsCache?: JobPostCacheStore

  defaultSearchRadius = 50 * 1609.34 // 50 miles in meters

  @observable isLoading = true

  constructor(options: any) {
    makeObservable(this);
    this.govGigAPI = (options && options.govGigAPI) ? options.govGigAPI : null
    this.resourceCache = (options && options.resourceCache) ? options.resourceCache : null
    this.jobPostsCache = new JobPostCacheStore(this)
  }

  async init() {
    this.isLoading = false
  }

  // Contract methods

  listContracts = async (filter?: APITypes.ModelContractFilterInput): Promise<Contract[]> => {
    let contracts: Contract[] = []

    const data = await this.govGigAPI.listContracts(filter)
    if (data && data.items) {
      contracts = data.items.map((item: any) => {
        const contract = new Contract(item)
        this.attachLocationAndServiceDataToContract(contract)
        return contract
      })
    }

    return contracts
  }

  listContractsByAccount = async (accountId: string, filter?: APITypes.ModelContractFilterInput): Promise<Contract[]> => {
    let contracts: Contract[] = []

    const data = await this.govGigAPI.listContractsByAccount(accountId, filter)
    if (data && data.items) {
      contracts = data.items.map((item: any) => {
        const contract = new Contract(item)
        this.attachLocationAndServiceDataToContract(contract)
        return contract
      })
    }

    return contracts
  }

  getContract = async (contractId: string): Promise<Contract | undefined> => {
    const data = await this.govGigAPI.getContract(contractId)
    if (data) {
      const contract = new Contract(data)
      this.attachLocationAndServiceDataToContract(contract)
      return contract
    }

    return undefined
  }

  async createContract(input:  APITypes.CreateContractInput): Promise<Contract | undefined> {
    const data = await this.govGigAPI!.createContract(input)
    if (data) {
      const contract = new Contract(data)
      await this.resourceCache!.activateLocation(contract.locationId)
      this.attachLocationAndServiceDataToContract(contract)
      return contract
    } else {
      return undefined
    }
  }

  async updateContract(input:  APITypes.UpdateContractInput): Promise<Contract | undefined> {
    const data = await this.govGigAPI!.updateContract(input)
    if (data) {
      const contract = new Contract(data)
      await this.resourceCache!.activateLocation(contract.locationId)
      this.attachLocationAndServiceDataToContract(contract)
      return contract
    } else {
      return undefined
    }
  }

  deleteContract = async (contractId: string): Promise<Contract | undefined> => {
    const data = await this.govGigAPI.deleteContract(contractId)
    if (data) {
      return new Contract(data)
    } else {
      return undefined
    }
  }

  attachLocationAndServiceDataToContract = (contract: Contract) => {
    if (contract.locationId) {
      const locationId = contract.locationId
      const location = this.resourceCache.getLocation(locationId)
      contract.location = location
    }
    contract.jobPosts.forEach(jobPost => {
      this.attachLocationAndServiceDataToJobPost(jobPost)
    })
  }

  // Service Request methods 

  listServiceRequests = async (filter?: APITypes.ModelServiceRequestFilterInput, limit?: number, nextToken?: string): Promise<ServiceRequestResult> => {
    let serviceRequests: ServiceRequest[] = []

    const data = await this.govGigAPI.listServiceRequests(filter, limit, nextToken)
    if (data && data.items) {
      serviceRequests = data.items.map((item: any) => {
        const serviceRequest = new ServiceRequest(item)
        this.attachLocationAndServiceDataToServiceRequest(serviceRequest)
        return serviceRequest
      })
    }

    let newNextToken 
    if (data && data.nextToken) {
      newNextToken = data.nextToken
    }

    return {
      serviceRequests,
      nextToken: newNextToken
    }
  }

  listServiceRequestsByAccount = async (accountId: string, filter?: APITypes.ModelServiceRequestFilterInput, limit?: number, nextToken?: string): Promise<{ serviceRequests: ServiceRequest[], nextToken?: string }> => {
    let serviceRequests: ServiceRequest[] = []

    const data = await this.govGigAPI.listServiceRequestsByAccount(accountId, filter, limit, nextToken)
    if (data && data.items) {
      serviceRequests = data.items.map((item: any) => {
        const serviceRequest = new ServiceRequest(item)
        this.attachLocationAndServiceDataToServiceRequest(serviceRequest)
        return serviceRequest
      })
    }

    let newNextToken 
    if (data && data.nextToken) {
      newNextToken = data.nextToken
    }

    return {
      serviceRequests,
      nextToken: newNextToken
    }
  }

  getServiceRequest = async (serviceRequestId: string): Promise<ServiceRequest | undefined> => {
    const data = await this.govGigAPI.getServiceRequest(serviceRequestId)
    if (data) {
      const serviceRequest = new ServiceRequest(data)
      this.attachLocationAndServiceDataToServiceRequest(serviceRequest)
      return serviceRequest
    }

    return undefined
  }

  async createServiceRequest(input:  APITypes.CreateServiceRequestInput): Promise<ServiceRequest | undefined> {
    const data = await this.govGigAPI!.createServiceRequest(input)
    if (data) {
      const serviceRequest = new ServiceRequest(data)
      this.attachLocationAndServiceDataToServiceRequest(serviceRequest)
      return serviceRequest
    } else {
      return undefined
    }
  }

  async updateServiceRequest(input:  APITypes.UpdateServiceRequestInput): Promise<ServiceRequest | undefined> {
    const data = await this.govGigAPI!.updateServiceRequest(input)
    if (data) {
      const serviceRequest = new ServiceRequest(data)
      this.attachLocationAndServiceDataToServiceRequest(serviceRequest)
      return serviceRequest
    } else {
      return undefined
    }
  }

  deleteServiceRequest = async (serviceRequestId: string): Promise<ServiceRequest | undefined> => {
    const data = await this.govGigAPI.deleteServiceRequest(serviceRequestId)
    if (data) {
      return new ServiceRequest(data)
    } else {
      return undefined
    }
  }

  attachLocationAndServiceDataToServiceRequest = (serviceRequest: ServiceRequest) => {
    serviceRequest.jobPosts.forEach(jobPost => {
      this.attachLocationAndServiceDataToJobPost(jobPost)

      const contract = jobPost.contract
      if (contract && contract.locationId) {
        const location = this.resourceCache.getLocation(contract.locationId)
        contract.location = location!
      }
    })
  }

  // JobPost methods

  findJobPosts = async (serviceId?: string, locationId?: string, options: IJobSearchOptions = {}) => {
    let jobPosts: JobPost[] = []

    const todaysISODate = getISODateToday()

    let filter: APITypes.ModelJobPostFilterInput = {
      and: [
        {openDate: {le: todaysISODate}},
        { or: [
            {closedDate: {attributeExists: false}},
            {closedDate: {ge: todaysISODate}}
        ]}
      ]
    }

    if (locationId) {
      let stateSearch
      let countrySearch
      let anywhereSearch
      let remoteSearch
      const location = this.resourceCache.getLocation(locationId)
      if (location?.addressType === AddressType.State) {
        stateSearch = location.state
      } else if (location?.addressType === AddressType.Country) {
        countrySearch = location.country
      } else if (location?.addressType === AddressType.World) {
        if (location.name === 'Remote') {
          options.workSetting = WorkSetting.Remote
          remoteSearch = true
        } else {
          anywhereSearch = true
        }
      }

      if (options.industry) {
        filter["and"]!.push({industries: {contains: options.industry}})
      }
      if (options.workSetting) {
        filter["and"]!.push({workSettings: {contains: options.workSetting}})
      }

      if (stateSearch) {
        jobPosts = await this.listJobPostsByState(stateSearch, serviceId, options)
      } else if (countrySearch) {
        jobPosts = await this.listJobPostsByCountry(countrySearch, serviceId, options)
      } else if (anywhereSearch) {
        if (serviceId) {
          jobPosts = await this.listJobPostsByService(serviceId, filter)
        } else {
          jobPosts = await this.listJobPosts(filter)
        }
      } else if (remoteSearch) {
        if (serviceId) {
          jobPosts = await this.listJobPostsByService(serviceId, filter)
        } else {
          jobPosts = await this.listJobPosts(filter)
        }
      } else {
        jobPosts = await this.listJobPostsByLocationRadius(locationId, this.defaultSearchRadius, serviceId, options)
      }
    } else if (serviceId) {
      jobPosts = await this.listJobPostsByService(serviceId, filter)
    } else {
      jobPosts = await this.listJobPosts(filter)
    }

    // Logger.debug("JobsSearch.loadJobPosts", JSON.stringify(jobPosts))
    let service
    if (serviceId) {
      service = this.resourceCache.getService(serviceId)
    }
    if (service) {
      // Sort by match ranking
      jobPosts = matchSorter(jobPosts, service.name,
        {keys: ['title'],
          baseSort: (a, b) => b.item.createdAt.localeCompare(a.item.createdAt),
          threshold: matchSorter.rankings.NO_MATCH
        })
    } else {
      // Sort by selected location and then date created
      jobPosts.sort((a: JobPost, b: JobPost) => {
        if (locationId) {
          if (a.locationId === b.locationId) {
            return b.createdAt.localeCompare(a.createdAt)
          } else if (a.locationId === locationId) {
            return -1
          } else if (b.locationId === locationId) {
            return 1
          } else {
            return b.createdAt.localeCompare(a.createdAt)
          }
        } else {
          return b.createdAt.localeCompare(a.createdAt)
        }
      })
    }

    return jobPosts
  }

  listJobPosts = async (filter?: APITypes.ModelJobPostFilterInput, limit?: number): Promise<JobPost[]> => {
    let jobPosts: JobPost[] = []

    const data = await this.govGigAPI.listJobPosts(filter, limit)
    if (data && data.items) {
      jobPosts = data.items.map((item: any) => {
        const jobPost = new JobPost(item)
        this.attachLocationAndServiceDataToJobPost(jobPost)
        return jobPost
      })
    }

    return jobPosts
  }

  listJobPostsAndCandidates = async (industry?: string): Promise<JobPost[]> => {
    let jobPosts: JobPost[] = []

    let filter: APITypes.ModelJobPostFilterInput | undefined
    if (industry && industry !== NAICS.Construction) {
      filter = {
        or: [
          {industries: {contains: industry}}
        ]
      }
    } else if (industry === NAICS.Construction) {
      filter = {
        or: [
          {industries: {attributeExists: false}},
          {industries: {contains: industry}}
        ]
      }
    }

    let data
    let nextToken: string | undefined

    do {
      data = await this.govGigAPI.listJobPostsAndCandidates(filter, 1000, nextToken)
      if (data && data.items) {
        data.items.forEach((item: any) => {
          const jobPost = new JobPost(item)
          this.attachLocationAndServiceDataToJobPost(jobPost)
          jobPosts.push(jobPost)
        })
        nextToken = data.nextToken ?? undefined
      }
    } while (data && nextToken)

    return jobPosts
  }

  listJobPostsByContract = async (contractId: string, filter?: APITypes.ModelJobPostFilterInput): Promise<JobPost[]> => {
    let jobPosts: JobPost[] = []

    const data = await this.govGigAPI.listJobPostsByContract(contractId, filter)
    if (data && data.items) {
      jobPosts = data.items.map((item: any) => {
        const jobPost = new JobPost(item)
        this.attachLocationAndServiceDataToJobPost(jobPost)
        return jobPost
      })
    }

    return jobPosts
  }

  listJobPostsByServiceRequest = async (serviceRequestId: string, filter?: APITypes.ModelJobPostFilterInput): Promise<JobPost[]> => {
    let jobPosts: JobPost[] = []

    const data = await this.govGigAPI.listJobPostsByServiceRequest(serviceRequestId, filter)
    if (data && data.items) {
      jobPosts = data.items.map((item: any) => {
        const jobPost = new JobPost(item)
        this.attachLocationAndServiceDataToJobPost(jobPost)
        return jobPost
      })
    }

    return jobPosts
  }

  listJobPostsByService = async (serviceId: string, filter?: APITypes.ModelJobPostFilterInput): Promise<JobPost[]> => {
    let jobPosts: JobPost[] = []

    const data = await this.govGigAPI.listJobPostsByService(serviceId, filter)
    if (data && data.items) {
      jobPosts = data.items.map((item: any) => {
        const jobPost = new JobPost(item)
        this.attachLocationAndServiceDataToJobPost(jobPost)
        return jobPost
      })
    }

    return jobPosts
  }

  listJobPostsByLocation = async (locationId: string, filter?: APITypes.ModelJobPostFilterInput): Promise<JobPost[]> => {
    let jobPosts: JobPost[] = []

    const data = await this.govGigAPI.listJobPostsByLocation(locationId, filter)
    if (data && data.items) {
      jobPosts = data.items.map((item: any) => {
        const jobPost = new JobPost(item)
        this.attachLocationAndServiceDataToJobPost(jobPost)
        return jobPost
      })
    }

    return jobPosts
  }

  listJobPostsByServiceAndLocation = async (serviceId: string, locationId: string, filter?: APITypes.ModelJobPostFilterInput): Promise<JobPost[]> => {
    let jobPosts: JobPost[] = []

    const data = await this.govGigAPI.listJobPostsByServiceAndLocation(serviceId, locationId, filter)
    if (data && data.items) {
      jobPosts = data.items.map((item: any) => {
        const jobPost = new JobPost(item)
        this.attachLocationAndServiceDataToJobPost(jobPost)
        return jobPost
      })
    }

    return jobPosts
  }

  listJobPostsByAccount = async (accountId: string, filter?: APITypes.ModelJobPostFilterInput): Promise<JobPost[]> => {
    let jobPosts: JobPost[] = []

    let data
    let nextToken: string | undefined

    do {
      data= await this.govGigAPI.listJobPostsByAccount(accountId, filter, 1000, nextToken)
      if (data && data.items) {
        data.items.forEach((item: any) => {
          const jobPost = new JobPost(item)
          this.attachLocationAndServiceDataToJobPost(jobPost)
          jobPosts.push(jobPost)
        })
        nextToken = data.nextToken ?? undefined
      }
    } while (data && nextToken)


    return jobPosts
  }

  listJobPostsAndCandidatesByAccount = async (accountId: string, filter?: APITypes.ModelJobPostFilterInput): Promise<JobPost[]> => {
    let jobPosts: JobPost[] = []

    let data
    let nextToken: string | undefined

    do {
      data = await this.govGigAPI.listJobPostsAndCandidatesByAccount(accountId, filter, 1000, nextToken)
      if (data && data.items) {
        data.items.forEach((item: any) => {
          const jobPost = new JobPost(item)
          this.attachLocationAndServiceDataToJobPost(jobPost)
          jobPosts.push(jobPost)
        })
        nextToken = data.nextToken ?? undefined
      }
    } while (data && nextToken)

    return jobPosts
  }

  listJobPostsByLocationRadius = async (locationId: string, radius: number, serviceId?: string, options: IJobSearchOptions = {}) => {
    const jobPosts: JobPost[] = []

    const location = this.resourceCache.getLocation(locationId, true)
    if (location) {
      // console.log(`listJobPostsByLocationRadius: ${location.name}`)
      const lngLat: LngLat = new LngLat(location.longitude, location.latitude)
      const bounds: LngLatBounds = lngLat.toBounds(radius)

      const locationJobPosts = await this.listLocationJobPostsByBounds(bounds, serviceId, options)
      locationJobPosts.forEach((locJobPosts: LocationJobPosts) => {
        // console.log(`${locJobPosts.location.name}: ${locJobPosts.jobPosts.length}`)
        jobPosts.push(...locJobPosts.jobPosts)
      })
    }

    return jobPosts
  }

  listJobPostsByState = async (state: string, serviceId?: string, options: IJobSearchOptions = {}) => {
    const jobPosts: JobPost[] = []

    if (state) {
      const locationJobPosts = await this.listLocationJobPostsByState(state, serviceId, options)
      locationJobPosts.forEach((locJobPosts: LocationJobPosts) => {
        jobPosts.push(...locJobPosts.jobPosts)
      })
    }

    return jobPosts
  }

  listJobPostsByCountry = async (country: string, serviceId?: string, options: IJobSearchOptions = {}) => {
    const jobPosts: JobPost[] = []

    if (country) {
      const locationJobPosts = await this.listLocationJobPostsByCountry(country, serviceId, options)
      locationJobPosts.forEach((locJobPosts: LocationJobPosts) => {
        jobPosts.push(...locJobPosts.jobPosts)
      })
    }

    return jobPosts
  }

  listLocationJobPostsByBounds = async (bounds: LngLatBounds, serviceId?: string, options: IJobSearchOptions = {}) => {
    const { industry } = options
    const key = `${serviceId ?? ''}/${industry ?? ''}`
    const jobPosts = await this.jobPostsCache!.get(key)

    const locationJobPosts: LocationJobPosts[] = []

    if (jobPosts && jobPosts.length > 0) {
      for (const jobPost of jobPosts) {
        if (jobPost.location && (!options.workSetting || jobPost.workSettings.includes(options.workSetting))) {
          if (bounds.contains(jobPost.location.lngLat)) {
            let found = locationJobPosts.find((locationJobPost: LocationJobPosts) => locationJobPost?.location.comparesTo(jobPost.location))
            if (!found) {
              found = new LocationJobPosts(jobPost.location, [])
              locationJobPosts.push(found)
            }
            found.jobPosts.push(jobPost)
          }
        }
      }
    }

    return locationJobPosts
  }

  getLocationsInBounds = (bounds: LngLatBounds) => {
    const locations: Location[] = []

    this.resourceCache!.locations.forEach((location: Location) => {
      const lnglat: LngLatLike = {lng: location.longitude, lat: location.latitude}
      if (bounds.contains(lnglat)) {
        locations.push(location)
      }
    })

    return locations
  }

  listLocationJobPostsByState = async (state: string, serviceId?: string, options: IJobSearchOptions = {}) => {
    const { industry } = options
    const key = `${serviceId ?? ''}/${industry ?? ''}`
    const jobPosts = await this.jobPostsCache!.get(key)

    const locationJobPosts: LocationJobPosts[] = []

    if (jobPosts && jobPosts.length > 0) {
      for (const jobPost of jobPosts) {
        if (jobPost.location && jobPost.location.state === state &&
          (!options.workSetting || jobPost.workSettings.includes(options.workSetting))) {
          let found = locationJobPosts.find((locationJobPost: LocationJobPosts) => locationJobPost?.location.comparesTo(jobPost.location))
          if (!found) {
            found = new LocationJobPosts(jobPost.location, [])
            locationJobPosts.push(found)
          }
          found.jobPosts.push(jobPost)
        }
      }
    }

    return locationJobPosts
  }

  listLocationJobPostsByCountry = async (country: string, serviceId?: string, options: IJobSearchOptions = {}) => {
    const { industry } = options
    const key = `${serviceId ?? ''}/${industry ?? ''}`
    const jobPosts = await this.jobPostsCache!.get(key)

    const locationJobPosts: LocationJobPosts[] = []

    if (jobPosts && jobPosts.length > 0) {
      for (const jobPost of jobPosts) {
        if (jobPost.location && jobPost.location.country === country  &&
           (!options.workSetting || jobPost.workSettings.includes(options.workSetting))) {
          let found = locationJobPosts.find((locationJobPost: LocationJobPosts) => locationJobPost?.location.comparesTo(jobPost.location))
          if (!found) {
            found = new LocationJobPosts(jobPost.location, [])
            locationJobPosts.push(found)
          }
          found.jobPosts.push(jobPost)
        }
      }
    }

    return locationJobPosts
  }

  listLocationJobPostsAnywhere = async (serviceId?: string, options: IJobSearchOptions = {}) => {
    const { industry } = options
    const key = `${serviceId ?? ''}/${industry ?? ''}`
    const jobPosts = await this.jobPostsCache!.get(key)

    const locationJobPosts: LocationJobPosts[] = []

    if (jobPosts && jobPosts.length > 0) {
      for (const jobPost of jobPosts) {
        if (jobPost.location && (!options.workSetting || jobPost.workSettings.includes(options.workSetting))) {
          let found = locationJobPosts.find((locationJobPost: LocationJobPosts) => locationJobPost?.location.comparesTo(jobPost.location))
          if (!found) {
            found = new LocationJobPosts(jobPost.location, [])
            locationJobPosts.push(found)
          }
          found.jobPosts.push(jobPost)
        }
      }
    }

    return locationJobPosts
  }

  getLocationsInState = (state: string) => {
    // Make sure we have the full/proper state name
    const stateName = this.resourceCache!.getStateNameFromAbbr(state)
    const locations: Location[] = []

    this.resourceCache!.locations.forEach((location: Location) => {
      if (location.state === stateName) {
        locations.push(location)
      }
    })

    return locations
  }

  /**
   * If the user has selected "Remote" as an option, then try to get the "Anywhere" LocationOption to show by default. 
   * @param workSettings The work setting options selected, "In Person," "Anywhere," and/or "Hybrid"
   * @param locationId The locationId already selected by the user as an override to the default for a work setting. 
   * @returns The LocationOption for "Anywhere," or null 
   */
  getLocationOptionForWorkSettings = (workSettings: WorkSetting[], locationId?: string): LocationOption | null => {
    let locationOption: LocationOption | null = null 
    if (workSettings.indexOf(WorkSetting.InPerson) < 0 && workSettings.indexOf(WorkSetting.Hybrid) < 0) {
      let location
      if (locationId) {
        location = this.resourceCache!.getLocation(locationId)
        if (location && location.addressType !== AddressType.Country && location.addressType !== AddressType.World) {
          location = undefined
        }
      }
      if (!location) {
        const options = this.resourceCache!.matchLocationOptions("Anywhere", true)
        if (options && options.length > 0) {
          locationOption = options[0]
        }
      }
    }
    return locationOption
  }

  getJobPostStatusOptions = () => {
    if (this.jobPostStatusOptions.length === 0) {
      this.jobPostStatusOptions = Object.values(JobPostStatus)
    }

    return this.jobPostStatusOptions
  }

  getJobPost = async (jobPostId: string): Promise<JobPost | undefined> => {
    const data = await this.govGigAPI.getJobPost(jobPostId)
    if (data) {
      const jobPost = new JobPost(data)
      this.attachLocationAndServiceDataToJobPost(jobPost)
      return jobPost
    }

    return undefined
  }

  async createJobPost(input: APITypes.CreateJobPostInput): Promise<JobPost | undefined> {
    if (input.status) {
      input.statusId = JobPost.convertStatusToStatusId(input.status)
    }
    const data = await this.govGigAPI!.createJobPost(input)
    if (data) {
      const jobPost = new JobPost(data)
      await this.resourceCache!.activateLocation(jobPost.locationId)
      await this.resourceCache!.activateService(jobPost.serviceId)
      // TODO: await this.resourceCache!.activateCertificationType()
      this.attachLocationAndServiceDataToJobPost(jobPost)
      return jobPost
    } else {
      return undefined
    }
  }

  async updateJobPost(input: APITypes.UpdateJobPostInput): Promise<JobPost | undefined> {
    if (input.status) {
      input.statusId = JobPost.convertStatusToStatusId(input.status)
    }
    const data = await this.govGigAPI!.updateJobPost(input)
    if (data) {
      const jobPost = new JobPost(data)
      await this.resourceCache!.activateLocation(jobPost.locationId)
      await this.resourceCache!.activateService(jobPost.serviceId)
      this.attachLocationAndServiceDataToJobPost(jobPost)
      return jobPost
    } else {
      return undefined
    }
  }

  deleteJobPostHelper = async (jobPostId: string, userStore: UserStore) => {
    // Remove JobCandidate records.
    const jobCandidates = await this.listJobCandidatesByJobPost(jobPostId)
    let jobCandidatePromises = jobCandidates.map((jobCandidate: JobCandidate) => {
      return this.deleteJobCandidate(jobCandidate.id)
    })
    await Promise.all(jobCandidatePromises)

    // Remove JobInterest records.
    const jobInterests = await this.listJobInterestsByJobPost(jobPostId)
    const jobInterestPromises = jobInterests.map((jobInterest: JobInterest) => {
      return this.deleteJobInterest(jobInterest.id)
    })
    await Promise.all(jobInterestPromises)

    try {
      const userActivity = await userStore.listUserActivityBySubject(jobPostId)
      const userActivityPromises = userActivity.map((userActivity: UserActivity) => {
        return userStore.deleteUserActivity(userActivity.id)
      })
      await Promise.all(userActivityPromises)
    } catch (err) {
      Logger.error(`Error listing or deleting JobPost UserActvity`, err)
    }
  }

  deleteJobPost = async (jobPostId: string, userStore: UserStore): Promise<JobPost | undefined> => {
    // Delete JobCandidate and JobInterest records.
    await this.deleteJobPostHelper(jobPostId, userStore)

    const data = await this.govGigAPI.deleteJobPost(jobPostId)
    if (data) {
      const jobPost = new JobPost(data)
      this.attachLocationAndServiceDataToJobPost(jobPost)
      return jobPost
    } else {
      return undefined
    }
  }

  isJobPostUnpublished = (jobPost: JobPost): boolean => {
    const isUnpublished = (jobPost.status === JobPostStatus.Draft || jobPost.status === JobPostStatus.Cart)
    return isUnpublished  
  }

  attachLocationAndServiceDataToJobPost = (jobPost: JobPost) => {
    if (jobPost) {
      const locationId = jobPost.locationId
      const location = this.resourceCache.getLocation(locationId)
      jobPost.location = location
      const serviceId = jobPost.serviceId
      const service = this.resourceCache.getService(serviceId)
      jobPost.service = service
      if (!jobPost.title && service) {
        jobPost.title = service.name
      }
      const contract = jobPost.contract
      if (contract && contract.locationId) {
        const location = this.resourceCache.getLocation(contract.locationId)
        contract.location = location 
      }
    }
  }

  // JobInterest methods

  listJobInterestsByUser = async (userId: string, filter?: APITypes.ModelJobInterestFilterInput): Promise<JobInterest[]> => {
    let jobInterests: JobInterest[] = []

    const data = await this.govGigAPI.listJobInterestsByUser(userId, filter)
    if (data && data.items) {
      jobInterests = data.items.map((item: any) => {
        return new JobInterest(item)
      })
    }

    return jobInterests
  }

  listJobInterestsByJobPost = async (jobPostId: string, filter?: APITypes.ModelJobInterestFilterInput): Promise<JobInterest[]> => {
    let jobInterests: JobInterest[] = []

    const data = await this.govGigAPI.listJobInterestsByJobPost(jobPostId, filter)
    if (data && data.items) {
      jobInterests = data.items.map((item: any) => {
        return new JobInterest(item)
      })
    }

    return jobInterests
  }

  getJobInterest = async (jobInterestId: string): Promise<JobInterest | undefined> => {
    const data = await this.govGigAPI.getJobInterest(jobInterestId)
    if (data) {
      return new JobInterest(data)
    }

    return undefined
  }

  async createJobInterest(input:  APITypes.CreateJobInterestInput): Promise<JobInterest | undefined> {
    input['origin'] = APITypes.RecordUpdateOrigin.User
    const data = await this.govGigAPI!.createJobInterest(input)
    if (data) {
      return new JobInterest(data)
    } else {
      return undefined
    }
  }

  async updateJobInterest(input:  APITypes.UpdateJobInterestInput): Promise<JobInterest | undefined> {
    input['origin'] = APITypes.RecordUpdateOrigin.User
    const data = await this.govGigAPI!.updateJobInterest(input)
    if (data) {
      return new JobInterest(data)
    } else {
      return undefined
    }
  }

  deleteJobInterest = async (jobInterestId: string): Promise<JobInterest | undefined> => {
    const data = await this.govGigAPI.deleteJobInterest(jobInterestId)
    if (data) {
      return new JobInterest(data)
    } else {
      return undefined
    }
  }

  // JobCandidate methods

  listJobCandidatesByJobPost = async (jobPostId: string, filter?: APITypes.ModelJobCandidateFilterInput): Promise<JobCandidate[]> => {
    let jobCandidates: JobCandidate[] = []

    const data = await this.govGigAPI.listJobCandidatesByJobPost(jobPostId, filter)
    if (data && data.items) {
      jobCandidates = data.items.map((item: any) => {
        return new JobCandidate(item)
      })
    }

    return jobCandidates
  }

  listJobCandidatesByAccount = async (accountId: string, filter?: APITypes.ModelJobCandidateFilterInput): Promise<JobCandidate[]> => {
    let jobCandidates: JobCandidate[] = []

    const data = await this.govGigAPI.listJobCandidatesByAccount(accountId, filter)
    if (data && data.items) {
      jobCandidates = data.items.map((item: any) => {
        return new JobCandidate(item)
      })
    }

    return jobCandidates
  }

  listJobCandidatesByProfile = async (profileId: string, filter?: APITypes.ModelJobCandidateFilterInput): Promise<JobCandidate[]> => {
    let jobCandidates: JobCandidate[] = []

    const data = await this.govGigAPI.listJobCandidatesByProfile(profileId, filter)
    if (data && data.items) {
      jobCandidates = data.items.map((item: any) => {
        const jobCandidate = new JobCandidate(item)
        this.attachLocationAndServiceDataToJobPost(jobCandidate.jobPost!)
        return jobCandidate
      })
    }

    return jobCandidates
  }

  findJobCandidateForJobPost = async (accountId: string, jobPostId: string, profileId: string): Promise<JobCandidate | undefined> => {
    const filter: APITypes.ModelJobCandidateFilterInput = {
      jobPostId: {eq: jobPostId},
      profileId: {eq: profileId}
    }

    const jobCandidates = await this.listJobCandidatesByAccount(accountId, filter)
    if (jobCandidates && jobCandidates.length > 0) {
      return jobCandidates[0]
    } else {
      return undefined
    }
  }

  getJobCandidate = async (jobCandidateId: string): Promise<JobCandidate | undefined> => {
    const data = await this.govGigAPI.getJobCandidate(jobCandidateId)
    if (data) {
      return new JobCandidate(data)
    }

    return undefined
  }

  async createJobCandidate(input:  APITypes.CreateJobCandidateInput): Promise<JobCandidate | undefined> {
    input['origin'] = APITypes.RecordUpdateOrigin.User
    const data = await this.govGigAPI!.createJobCandidate(input)
    if (data) {
      return new JobCandidate(data)
    } else {
      return undefined
    }
  }

  async updateJobCandidate(input:  APITypes.UpdateJobCandidateInput): Promise<JobCandidate | undefined> {
    input['origin'] = APITypes.RecordUpdateOrigin.User
    const data = await this.govGigAPI!.updateJobCandidate(input)
    if (data) {
      const jobCandidate = new JobCandidate(data)
      this.attachLocationAndServiceDataToJobPost(jobCandidate.jobPost!)
      return jobCandidate
    } else {
      return undefined
    }
  }

  deleteJobCandidate = async (jobCandidateId: string): Promise<JobCandidate | undefined> => {
    const data = await this.govGigAPI.deleteJobCandidate(jobCandidateId)
    if (data) {
      return new JobCandidate(data)
    } else {
      return undefined
    }
  }

  unlockJobCandidate = async (jobCandidate: JobCandidate) : Promise<JobCandidate | null> => {
    let jobPost
    if (jobCandidate.jobPost) {
      jobPost = jobCandidate.jobPost
    } else {
      jobPost = await this.getJobPost(jobCandidate.id)
        .catch((err: Error) => {
          throw new Error(`Unable to unlock job candidate (${err.message}_`)
        })
    }

    if (!jobPost) {
      return null
    }

    if (!jobPost.unlocksAvailable) {
      throw new Error("No more unlocks are available for this job post")
    }

    const jobCandidateInput: UpdateJobCandidateInput = {
      id: jobCandidate.id,
      unlocked: true
    }
    const updatedJobCandidate = await this.updateJobCandidate(jobCandidateInput)
    if (updatedJobCandidate) {
      const jobPostInput: UpdateJobPostInput = {
        id: jobPost.id,
        unlocksAvailable: jobPost.unlocksAvailable! - 1
      }
      const updatedJobPost = await this.updateJobPost(jobPostInput)
      if (updatedJobPost) {
        updatedJobCandidate.jobPost = updatedJobPost
        return updatedJobCandidate
      }
    }

    return null
  }
}

export default JobStore

export class LocationJobPosts {
  location: Location
  jobPosts: JobPost[]

  constructor (location: Location, jobPosts: JobPost[]) {
    this.location = location
    this.jobPosts = jobPosts
  }
}

const CACHE_EXPIRE_SECS = 300

// This caches JobPost searches based on serviceId/industry
class JobPostCacheStore extends CacheStore<JobPost[]> {

  constructor (jobStore: JobStore) {
    super(CACHE_EXPIRE_SECS, async (key: string): Promise<JobPost[] | undefined> => {
      const parts = key.split('/')
      const serviceId = parts[0]
      const industry = parts[1]
      // At some point we may want to support a key that contains the locationId and/or serviceId.
      let jobPosts
      const todaysISODate = getISODateToday()
      let filter: APITypes.ModelJobPostFilterInput = {
        and: [
          {openDate: {le: todaysISODate}},
          { or: [
            {closedDate: {attributeExists: false}},
            {closedDate: {ge: todaysISODate}}
          ]}
        ]
      }
      if (serviceId === "") {
        if (industry) {
          filter["and"]!.push({
            industries: {contains: industry}
          })
        }
        jobPosts = await jobStore.listJobPosts(filter)
      } else {
        jobPosts = await jobStore.listJobPostsByService(serviceId, filter)
      }
      return jobPosts
    })
  }

  removeKey = (key: string) => {
    this.remove(key)
  }
}

