import {
  Box,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  isWidthUp,
  TextareaAutosize,
  Theme,
  Typography,
  withStyles,
  WithStyles,
  WithTheme,
  withTheme,
  withWidth,
  WithWidth
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import { makeObservable, observable, when } from 'mobx';
import { inject, observer } from "mobx-react";
import React from "react";
import { UpdateProfileInput } from "../../API";
import IconicButton from "../../components/controls/IconicButton";
import DialogButton from "../../components/form/DialogButton";
import FormValidator from "../../components/form/FormValidator";
import Notify from "../../components/notify/Notify";
import TextBlock from "../../components/TextBlock";
import Profile from "../../model/Profile";
import ProfileStore from "../../stores/ProfileStore";
import UserStore from "../../stores/UserStore";
import ProfileCard from "./ProfileCard";
import UploadResumeDialog from "./upload-resume/UploadResumeDialog";
import {grey} from "@material-ui/core/colors";
import CancelButton from "../../components/form/CancelButton";
import JobCandidate from "../../model/JobCandidate";
import Stack from "../../components/Stack";

const styles = (theme: Theme) => createStyles({
  textarea: {
    fontFamily: [
      'Roboto',
      'sans-serif'
    ].join(','),
    minWidth: "100%",
    maxWidth: "100%",
    padding: 8,
    fontSize: 16
  },
  publicNotice: {
    marginTop: theme.spacing(1),
    padding: theme.spacing(1),
    backgroundColor: grey[500],
    color: theme.palette.primary.contrastText,
    borderRadius: 10,
    textAlign: "center"
  }
})

class ProfileAboutViewModel {
  @observable about: string

  constructor(profile: Profile) {
    makeObservable(this);
    this.about = profile!.about ? profile!.about : ""
  }
}

export interface IProfileAboutProps {
  isOpen?: boolean
  userStore?: UserStore
  notify?: Notify
  profile?: Profile
  jobCandidate?: JobCandidate
  onChange?: (profile: Profile) => any
  onClose?: any
  profileStore?: ProfileStore
}

@inject("userStore", "profileStore", 'notify')
@observer
class ProfileAbout extends React.Component<WithStyles<typeof styles> & WithTheme & WithWidth & IProfileAboutProps> {

  @observable viewModel?: ProfileAboutViewModel
  @observable isFormOpen: boolean = false
  @observable shouldAutofillProfile: boolean = false

  componentDidMount() {
    when(
      () => Boolean(this.props.profileStore && this.props.profile),
      () => {
        this.shouldAutofillProfile = this.props.profile!.isEmpty
      }
    )
  }

  constructor(
    props: WithStyles<typeof styles> & WithTheme & WithWidth & IProfileAboutProps
  ) {
    super(props);
    makeObservable(this);
  }

  componentDidUpdate(prevProps: any) {
    if (prevProps.isOpen !== this.props.isOpen) {
      if (this.props.isOpen) {
        this.handleEdit()
      } else {
        this.isFormOpen = false
      }
    }
  }

  render() {
    const { classes, profile, jobCandidate, userStore, onChange, width, theme } = this.props

    if (!profile) {
      return null
    }

    const isAuthenticated = userStore!.isAuthenticated
    const user = userStore!.user 
    const isUnlocked = profile.getIsUnlocked(user, jobCandidate)
    
    let editButton
    if (onChange) {
      editButton =
        <IconicButton onClick={this.handleEdit}>
          <EditIcon />
        </IconicButton>
    }

    const about = profile.about ? profile.about :
      (onChange) ? "Add a brief personal statement describing yourself professionally." : ""

    return (
      <ProfileCard
        title="About"
        // button={editButton}
        // overrides={{ titleBar: classes.titleBar }}
      >
        <Grid 
          container 
          direction="row"
          justifyContent="space-between"
          spacing={5}
        >
          <Grid 
            item 
            xs={12} 
            sm={8}
          >
            <Stack
              justifyContent="space-between"
              wrap="nowrap"
              spacing={2}
              sx={{
                width: "100%"
              }}
            >
              <Typography component="div" gutterBottom>
                <TextBlock>{about}</TextBlock>
              </Typography>
              {editButton}
            </Stack>
          </Grid>
          <Grid 
            item 
            xs={12} 
            sm={4}
          >
            <Stack
              justifyContent={ isWidthUp("sm", width) ? "flex-end" : "flex-start"}
              sx={{
                width: "100%"
              }}
              spacing={2}
            >
              <Box
                sx={{
                  border: `1px solid ${grey[300]}`,
                  borderRadius: theme.spacing(1),
                  paddingTop: theme.spacing(2), 
                  paddingBottom: theme.spacing(2), 
                  paddingLeft: theme.spacing(3),
                  paddingRight: theme.spacing(3)
                }}
              >
                <Typography gutterBottom variant="h6">Resume</Typography>
                {isAuthenticated && isUnlocked &&
                  <UploadResumeDialog {...{ profile, onChange }} />
                }
                {(!isAuthenticated || !isUnlocked) &&
                  <div style={{ backgroundColor: grey[500], height: 260 }}>
                    <Typography variant="body1" className={classes.publicNotice}>
                      Resume available when this candidate is unlocked.
                    </Typography>
                  </div>
                }
              </Box>
            </Stack>
          </Grid>
        </Grid>

        {this.isFormOpen && this.renderAboutForm()}

      </ProfileCard>
    )
  }

  renderAboutForm() {
    const viewModel = this.viewModel!
    const { classes } = this.props

    return (
      <Dialog
        open={this.isFormOpen}
        onClose={this.handleClose}
        fullWidth
        maxWidth="sm"
      >
        <FormValidator onSubmit={this.onSubmit} autoComplete="off" name="personalInfoForm" id="personalInfoForm">
        <DialogTitle id="form-dialog-title" >
          About
        </DialogTitle>
        <DialogContent >
          <DialogContentText>
            Edit your personal statement below.
          </DialogContentText>
          <TextareaAutosize
            name="about"
            value={viewModel.about}
            onChange={this.handleChange}
            minRows={5}
            className={classes.textarea}
          />
        </DialogContent>
        <DialogActions>
          <CancelButton onClick={this.handleClose} />
          <DialogButton variant="primary">
            Save
          </DialogButton>
        </DialogActions>
        </FormValidator>
      </Dialog>
    )
  }

  handleEdit = (): void => {
    if (this.props.profile) {
      this.viewModel = new ProfileAboutViewModel(this.props.profile)
      this.isFormOpen = true
    }
  }

  handleClose = () => {
    this.isFormOpen = false
    if (this.props.onClose) {
      this.props.onClose()
    }
  }

  handleChange = (event: any) => {
    const name = event.target.name
    this.viewModel![name] = event.target.value
  }

  onSubmit = async () => {
    const { profile, profileStore } = this.props
    const viewModel = this.viewModel!

    if (this.props.onChange) {
      // Update profile
      const updateProfileInput: UpdateProfileInput = {
        id: profile!.id,
        about: viewModel.about
      }

      let updatedProfile = await profileStore!.updateProfile(updateProfileInput)

      if (updatedProfile) {
        this.props.onChange(updatedProfile)
        this.handleClose()
      }
    }
  }
}

export default withTheme(withStyles(styles)(withWidth()(ProfileAbout)))
