import * as React from "react";
import {
  Box,
  createStyles,
  Grid,
  Paper,
  Theme,
  Typography,
  withStyles,
  WithStyles
} from "@material-ui/core";
import {
  humanizeString,
  isoToLocalDateString
} from "../../stores/StoreUtilities";
import JobPost from "../../model/JobPost";
import ActionLink from "../ActionLink";
import {inject, observer} from "mobx-react";
import UserStore from "../../stores/UserStore";
import {makeObservable, observable, when} from "mobx";
import Visible from "../Visible";
import Notify from "../notify/Notify";
import Stack from "../Stack";
import {JobPostStatus} from "../../API";
import ResourceCache from "../../stores/ResourceCache";
import BusinessIcon from "@material-ui/icons/Business";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import LockIcon from "@material-ui/icons/Lock";
import ScheduleIcon from "@material-ui/icons/Schedule";
import FolderOpenIcon from '@material-ui/icons/FolderOpen';
import JobPostTypeChip from "../../views/jobPosts/JobPostTypeChip";
import EditButton from "../controls/EditButton";

const styles = (theme: Theme) => createStyles({
  card: {
  },
  heading: {
    color: theme.palette.primary.main,
    fontWeight: 'bold'
  },
  property: {
    display: "flex",
    flexDirection: "row",
    paddingBottom: "3px"
  },
  propIcon: {
    color: theme.palette.text.secondary,
    width: 18,
    height:18,
    paddingTop: 2,
    marginRight: theme.spacing(1)
  },
  hiringImmediately: {
    color: theme.palette.success.dark,
    fontWeight: 600
  },
  hiringDefault: {
    color: theme.palette.text.primary
  },
  status: {
    fontWeight: 200,
    color: theme.palette.text.secondary,
    maxHeight: 18,
  },
})

interface IJobPostMiniCardProps {
  jobPost: JobPost
  onClickTitle?(jobPost: JobPost): void 
  elevation?: number
  onEdit?(): void
  children?: React.ReactNode
  
  userStore?: UserStore
  resourceCache?: ResourceCache
  notify?: Notify
}

@inject('userStore', 'resourceCache', 'notify')
@observer
class JobPostMiniCard extends React.PureComponent<WithStyles<typeof styles> & IJobPostMiniCardProps> {

  @observable isJobPostEditable: boolean = false 

  constructor(props: WithStyles<typeof styles> & IJobPostMiniCardProps) {
    super(props)
    makeObservable(this)
  }

  componentDidMount() {
    const { jobPost, userStore } = this.props 
    when(
      // once 
      () => userStore!.isLoading === false || userStore!.isPublic === true,
      // then 
      () => {
        this.isJobPostEditable = userStore!.isJobPostEditable(jobPost)
      }
    )
  }

  render() {
    const { jobPost, elevation, classes } = this.props

    return (
      <Paper elevation={elevation} className={classes.card}>
        <Box 
          py={ elevation === 0 ? 0 : 1.5 } 
          pl={ elevation === 0 ? 0 : 2 } 
          pr={ elevation === 0 ? 0 : 2 } 
          bgcolor="common.white" 
          borderRadius={10}
        >
          <Grid container direction="column" spacing={0}>
            <Grid item xs={12}>
              <JobPostTypeChip jobPost={jobPost} />
            </Grid>
            <Grid item xs={12}>
              { this.renderContent() }
            </Grid>
            { this.props.children ?
              <Grid item xs={12}>
                {this.props.children}
              </Grid>
            : null }
          </Grid>
        </Box>
      </Paper>
    )
  }

  renderContent = () => {
    const {
      classes,
      jobPost,
      userStore,
      resourceCache,
      onClickTitle,
      onEdit
    } = this.props

    let statusDate = ""
    if (jobPost.status === JobPostStatus.Cart || jobPost.status === JobPostStatus.Draft) {
      statusDate = `Created ${isoToLocalDateString(jobPost.createdAt, "M/d/yyyy h:mm a")}`
    } else {
      statusDate = `${jobPost.status} ${isoToLocalDateString(jobPost.updatedAt, "M/d/yyyy h:mm a")}`
    }

    const securityClearance = jobPost.securityClearance
    const hiringPeriod = jobPost.hiringPeriod
    const hiringClassname = hiringPeriod === "Immediately" ? classes.hiringImmediately : classes.hiringDefault
    
    return (
      <Box>
        <Grid container direction="column">
          <Grid item xs={12}>
            { onClickTitle ? 
              <Stack justifyContent="space-between" alignItems="flex-start" sx={{ minWidth: "100%" }} wrap="nowrap" spacing={1}>
                <Box pb={0.5}>
                  <ActionLink
                    text={`${jobPost.title}${jobPost.openings > 1 ? ` [${jobPost.openings}]` : ''}`}
                    alignText="left"
                    variation="dark"
                    tracking="JobPost-Title"
                    click={onClickTitle}
                  />
                </Box>
                { onEdit && 
                  <EditButton 
                    onClick={() => {
                      if (this.isJobPostEditable === false) {
                        return 
                      } else {
                        onEdit()
                      }
                    }}
                  /> 
                }
              </Stack>
              :
              <Typography gutterBottom variant="h3" className={classes.heading}>
                {jobPost.title}{jobPost.openings > 1 && ` [${jobPost.openings}]`}
              </Typography>
            }
          </Grid>
          <Visible if={userStore!.isAuthenticated && userStore!.user?.isAdminOrAgent === true}>
            <Grid item className={classes.property}>
              <BusinessIcon className={classes.propIcon}/>
              <Typography variant="body2">
                {jobPost.account?.name}
              </Typography>
            </Grid>
          </Visible>
          <Grid item className={classes.property}>
            <LocationOnIcon className={classes.propIcon}/>
            <Typography variant="body2">
              <b>
                {jobPost.locationDescription}
              </b>
            </Typography>
          </Grid>
          {hiringPeriod !== "Soon" &&
            <Grid item className={classes.property}>
              <ScheduleIcon className={classes.propIcon}/>
              <Typography variant="body2" className={hiringClassname}>
                {humanizeString(jobPost.employmentType)} Hire {hiringPeriod}
              </Typography>
            </Grid>
          }
          {securityClearance &&
            <Grid item className={classes.property}>
              <LockIcon className={classes.propIcon}/>
              <Typography noWrap={true} variant='body2'>{securityClearance}</Typography>
            </Grid>
          }
          <Grid item className={classes.property}>
            <FolderOpenIcon className={classes.propIcon}/>
            <Typography variant="body2">
              {jobPost.contract?.name}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body2" className={classes.status}>
              {statusDate}
            </Typography>
          </Grid>
        </Grid>
      </Box>
    )
  }
}

export default withStyles(styles)(JobPostMiniCard)
