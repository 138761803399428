import { alpha, Button, isWidthDown, WithWidth, withWidth } from '@material-ui/core'
import IconButton from '@material-ui/core/IconButton'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles'
import AccountCircleIcon from '@material-ui/icons/AccountCircle'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import MenuIcon from '@material-ui/icons/Menu'
import { makeObservable, observable } from "mobx"
import { inject, observer } from "mobx-react"
import * as React from 'react'
import UserStore from "../../stores/UserStore"
import ControlTower, { Routes } from "../ControlTower"
import AuthButton from "../login/AuthButton"
import Tracking, { TrackingCodes } from "../Tracking"
import PopperMenuItem from './PopperMenuItem'
import config from 'react-global-configuration';
import classNames from 'classnames'

const styles = (theme: Theme) => createStyles({
  activeButton: {
    margin: theme.spacing(1),
    fontWeight: 600,
    color: theme.palette.primary.main,
    backgroundColor: alpha(theme.palette.primary.main, 0.1),
    fontSize: 16,
    padding: theme.spacing(2),
    // border: `2px solid ${theme.palette.secondary.main}`,
    // borderRadius: 0,
  },
  button: {
    margin: theme.spacing(1),
    color: theme.palette.primary.main,
    fontWeight: 400,
    fontSize: 16,
    padding: theme.spacing(2)
  },
  emphasizedButton: {
    margin: theme.spacing(1),
    color: theme.palette.common.white,
    backgroundColor: theme.palette.secondary.main, 
    fontWeight: 400,
    fontSize: 16,
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    boxShadow: 'rgba(0, 0, 0, 0.2) 0px 3px 1px -2px',
    '&:hover': {
      // color:  theme.palette.action.hover,
      backgroundColor: theme.palette.secondary.dark
    },
  },
  menuPaper: {
    // width: '100%',
  },
  menuIcon: {
    color: theme.palette.primary.main
  },
  menuItem: { // TODO: Fix pseudo selector styles, note mobile scenarios when testing! 
    color: theme.palette.primary.main,
    // '&:hover': {
    //   color: theme.palette.primary.contrastText,
    //   backgroundColor: theme.palette.action.hover
    // },
    // '&:focus': {
    //   color: theme.palette.primary.contrastText,
    //   backgroundColor: theme.palette.action.selected
    // },
    // '&:active': {
    //   color: theme.palette.primary.contrastText,
    //   backgroundColor: theme.palette.action.active
    // }
  },
  activeMenuItem: {
    fontWeight: 900,
    color: theme.palette.text.primary,
    // '&:hover': {
    //   color: theme.palette.secondary.main,
    //   backgroundColor: theme.palette.action.hover
    // },
    // '&:focus': {
    //   color: theme.palette.primary.contrastText,
    //   backgroundColor: theme.palette.action.selected
    // },
    // '&:active': {
    //   color: theme.palette.primary.contrastText,
    //   backgroundColor: theme.palette.action.active
    // }
  },
  spacingLeft: {
    marginLeft: theme.spacing(5)
  }
})

interface ISimpleMenuProps {
  userStore?: UserStore
}

@inject("userStore")
@observer
class SimpleMenu extends React.Component<WithStyles<typeof styles> & WithWidth & ISimpleMenuProps> {
  @observable anchorEl: Element | null = null
  @observable showAuth = false

  handleClick = (event: any) => {
    this.anchorEl = event.currentTarget
  }

  handleClose = () => {
    this.anchorEl = null
  }

  onCloseAuth = () => {
    this.showAuth = false
  }

  constructor(props: WithStyles<typeof styles> & WithWidth & ISimpleMenuProps) {
    super(props);
    makeObservable(this);
  }

  /**
   * 
   * @param label The text that shows up for the button. 
   * @param tracking The analytics tracking code. 
   * @param route The route to take the user to when they click on the button. 
   * @param otherProps Any other props to pass through to the button. 
   * @param renderArrow Show a dropdown arrow. 
   * @param isEmphasized Show the button contained and with color, instead of just a text button. 
   * @param spacingLeft Put some extra spacing to the left of the button. 
   * @returns 
   */
  getMenuItemButton = (
    label: string | React.ReactNode, 
    tracking: string, 
    route: Routes | undefined, 
    otherProps: any = {}, 
    renderArrow: boolean = false,
    isEmphasized: boolean = false,
    spacingLeft: boolean = false 
  ) => {
    const { classes, width } = this.props
    const isXS = isWidthDown('sm', width)
    const currentPath = ControlTower.currentRoute
    const isActive = (
      (route === Routes.home && currentPath === route) // home route special case
      || (route !== Routes.home && route && String(currentPath).includes(route))
      && (route !== Routes.join)
    )

    const Comp: any = isWidthDown('sm', width) ? MenuItem : Button

    const classname = isActive ? classes.activeButton : isEmphasized && !isXS ? classes.emphasizedButton : classes.button
    const combined = spacingLeft ? classNames(classname, classes.spacingLeft) : classname 

    return (
      <Comp
        key={tracking}
        button
        color="primary"
        aria-label={label}
        className={combined}
        {...otherProps}
        onClick={async (e: React.MouseEvent) => {
          Tracking.event({ category: 'Navigation', action: tracking })
          if (otherProps.onClick) {
            await otherProps.onClick(e)
          }
          if (route) {
            ControlTower.route(route)
          }
        }}
      >
        {label} {renderArrow && <ArrowDropDownIcon />}
      </Comp>
    )
  }

  render() {
    const { classes, width, userStore } = this.props

    const isSmallScreen = isWidthDown('md', width) // For iPad and smaller. 

    // buttons

    const manageButton = userStore!.isAdminOrAgent ? (
      <PopperMenuItem
        key='Manage'
        button={(props: any) => this.getMenuItemButton('Manage', TrackingCodes.Recruiting, undefined, props, true)}
        menuList={[
          { label: 'Accounts', to: Routes.manageAccounts, tracking: TrackingCodes.RecruitingEmployers },
          { label: 'Applicants', to: Routes.manageApplicants, tracking: TrackingCodes.RecruitingApplicants },
          { label: 'Candidates', to: Routes.manageCandidates, tracking: TrackingCodes.RecruitingCandidates },
          { label: 'People', to: Routes.managePeople, tracking: TrackingCodes.RecruitingPeople },
        ]}
        onClick={async (e) => {
          this.handleClose()
        }}
      />
    ) : null 
    
    const accountButton = (
      <PopperMenuItem
        key='Account'
        button={(props) => this.getMenuItemButton('Account', TrackingCodes.Account, undefined, props, true)}
        menuList={[
          { label: 'Settings', to: Routes.accountSettings, tracking: TrackingCodes.AccountSettings },
          { label: "Agreements", to: Routes.accountAgreements, tracking: TrackingCodes.AccountAgreements },
          { label: 'People', to: Routes.accountPeople, tracking: TrackingCodes.AccountPeople },
          { label: 'Billing', to: Routes.accountBilling, tracking: TrackingCodes.AccountBilling },
        ]}
        onClick={async (e) => {
          this.handleClose()
        }}
      />
    )

    // const contractsButton = this.getMenuItemButton('Contracts', TrackingCodes.Contracts, Routes.contracts)
    const findJobsButton = this.getMenuItemButton('Find Jobs', TrackingCodes.FindJobs, Routes.findJobs)
    const findOpportunityButton = this.getMenuItemButton('Find Opportunity', TrackingCodes.FindOpportunity, Routes.findOpportunity)
    const myJobsButton = this.getMenuItemButton('My Jobs', TrackingCodes.MyJobs, Routes.myJobs)
    const findCandidatesButton = this.getMenuItemButton('Find Talent', TrackingCodes.SearchCandidates, Routes.findTalent)
    const dashboardButton = this.getMenuItemButton("Dashboard", TrackingCodes.Dashboard, Routes.dashboard)
    
    // const serviceRequestsButton = this.getMenuItemButton("Service Requests", TrackingCodes.ServiceRequests, Routes.serviceRequests)
    const serviceRequestsButton = (
      <PopperMenuItem
        key='Service Requests'
        button={(props) => this.getMenuItemButton("Service Requests", TrackingCodes.ServiceRequests, undefined, props, true)}
        menuList={[
          { label: 'See All', to: Routes.serviceRequests, tracking: TrackingCodes.ServiceRequestsList },
          { label: 'Create Request', to: Routes.serviceRequestCreate, tracking: TrackingCodes.ServiceRequestCreate }
        ]}
        onClick={async (e) => {
          this.handleClose()
        }}
      />
    )

    // const manageJobsButton = this.getMenuItemButton('Manage Jobs', TrackingCodes.ManageJobs, Routes.manageJobs)
    const manageJobsButton = (
      <PopperMenuItem
        key='Jobs'
        button={(props) => this.getMenuItemButton("Jobs", TrackingCodes.ManageJobs, undefined, props, true)}
        menuList={[
          { label: 'See All', to: Routes.manageJobs, tracking: TrackingCodes.ManageJobsList },
          { label: 'Create Job Posts', to: Routes.jobPostCreate, tracking: TrackingCodes.JobPostsCreate },
          { label: "Find Jobs", to: Routes.findJobs, tracking: TrackingCodes.FindJobs },
          { label: 'Job Groups', to: Routes.jobGroups, tracking: TrackingCodes.RecruitingEmployers }
        ]}
        onClick={async (e) => {
          this.handleClose()
        }}
      />
    )

    const profileButton = this.getMenuItemButton('Profile', TrackingCodes.Profile, Routes.profile)
    const userButton = userStore!.isAuthenticated
      ? (
        <PopperMenuItem
          key='User'
          button={(props) => this.getMenuItemButton(
            isSmallScreen ? 'User' : <AccountCircleIcon />,
            TrackingCodes.Account,
            undefined,
            props,
            true
          )}
          menuList={[
            { label: 'Settings', to: Routes.settings, tracking: TrackingCodes.Settings },
            <MenuItem
              key='Log Out'
              onClick={async () => {
                Tracking.event({ category: 'Navigation', action: 'Log Out' })
                this.handleClose()
                // ControlTower.route(`${Routes.home}?logout=true`)
                console.log("SimpleMenu sign out")
                userStore!.signOut()
                .then(() => {
                  // Reload without search param
                  const homeUrl = config.get("homeUrl")
                  ControlTower.route(homeUrl)
                })
              }}>
              Log Out
            </MenuItem>,
          ]}
          onClick={async (e) => {
            this.handleClose()
          }}
        />
      )
      : this.getMenuItemButton('Log In', TrackingCodes.SignIn, undefined, {
          onClick: () => {
            Tracking.event({ category: 'Navigation', action: 'Log In' })
            const currentRoute = ControlTower.currentRoute
            const isLoginPage = currentRoute === Routes.home || currentRoute === Routes.login
            if (!isLoginPage) {
              this.showAuth = true
            }
            this.handleClose()
          }
        }, false, false, isSmallScreen ? false : true) 

    const joinButton = this.getMenuItemButton('Sign Up', TrackingCodes.Join, Routes.join, undefined, false, true)
    const postJobsButton = this.getMenuItemButton('Post Jobs', TrackingCodes.JobPostsCreate, Routes.jobPostCreate)

  // menu item configurations
    let menuItems = []
    if (userStore!.isAdminOrAgent) {
      menuItems = [
        // contractsButton,
        dashboardButton,
        serviceRequestsButton,
        manageJobsButton,
        findCandidatesButton,
        findJobsButton,
        manageButton,
        userButton,
      ]
    } else if (userStore!.isApplicant || userStore!.isCandidate) {
      menuItems = [
        findJobsButton,
        myJobsButton,
        findOpportunityButton,
        profileButton,
        userButton,
      ]
    } else if (userStore!.isEmployer) {
      menuItems = [
        // contractsButton,
        dashboardButton,
        serviceRequestsButton,
        manageJobsButton,
        // postJobsButton,
        findCandidatesButton,
        accountButton,
        userButton,
      ]
    } else {
      // unauthenticated visitor
      menuItems = [
        this.getMenuItemButton('Find Jobs', TrackingCodes.FindJobs, Routes.findJobs),
        this.getMenuItemButton('Find Talent', TrackingCodes.FindTalent, Routes.findTalent),
        findOpportunityButton,
        postJobsButton,
        userButton,
        joinButton,
      ]
    }

    return (
      <div>
        <div>
          {isSmallScreen
            ? <React.Fragment>
              <IconButton
                color="inherit"
                aria-label="Menu"
                aria-owns={this.anchorEl ? 'simple-menu' : undefined}
                aria-haspopup="true"
                onClick={this.handleClick}
              >
                <MenuIcon className={classes.menuIcon} />
              </IconButton>
              <Menu
                id="simple-menu"
                classes={{ paper: classes.menuPaper }}
                anchorEl={this.anchorEl}
                open={Boolean(this.anchorEl)}
                onClose={this.handleClose}
              >
                {menuItems}
              </Menu>
            </React.Fragment>
            : menuItems
          }
        </div>

        {!userStore!.isAuthenticated && this.showAuth && (
          <AuthButton
            open={true}
            onLogin={this.onCloseAuth}
            onClose={this.onCloseAuth} />
        )}
      </div>
    );
  }

  onAcademy = () => {
    const academyUrl = config.get("academyUrl")
    if (academyUrl) {
      ControlTower.open(academyUrl, "_self")
    }
  }
}

export default withStyles(styles)(withWidth()(SimpleMenu))
