import Service from "./Service";


export class ServiceGroup {
  id: string
  createdAt: string
  updatedAt: string
  name: string
  services: Service[]
  industries: string[]
  default: boolean

  constructor(data: any) {
    this.id = data.id
    this.createdAt = data.createdAt
    this.updatedAt = data.updatedAt
    this.name = data.name
    this.services = []
    if (data.services && data.services.items) {
      this.loadServices(data.services.items)
    }
    this.industries = data.industries ?? []
    this.default = data.default
  }

  loadServices(services: any[]) {
    this.services = services.map((item: any) => {
      const service = new Service(item)
      return service
    })
  }
}

export default ServiceGroup