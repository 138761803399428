import { Paper, RootRef, Theme, Typography, WithStyles, withStyles } from '@material-ui/core';
import { amber, blueGrey, orange, yellow } from '@material-ui/core/colors';
import { observer } from 'mobx-react';
import React from 'react';
import Dropzone from 'react-dropzone';

const styles = (theme: Theme) => ({
  dropzoneRoot: {
    padding: '1rem',
    height: '100%',
    maxHeight: '10rem',
    backgroundColor: amber[50], // 'rgba(0,0,0,0.05)',
    cursor: "pointer"
  },
  dropzoneText: {
    color: theme.palette.primary.main // theme.palette.text.secondary,
  },
})

interface IFileDropZone {
  classes: object
  text: string
  accept: string[]
  onDrop: (files: File[]) => any
  onFileValidator: (isValid: boolean) => any
}

@observer
class FileDropZone extends React.Component<WithStyles & IFileDropZone> {
  render() {
    const {
      classes,
      text,
      accept,
      onDrop,
      onFileValidator,
    } = this.props

    return (
      <Dropzone {...{ maxFiles: 1, accept, onDrop, }}>
        {({ fileRejections, getRootProps, getInputProps }) => {
          const { ref, ...rootProps } = getRootProps();

          onFileValidator(fileRejections && fileRejections.length > 0);

          return (
            <RootRef rootRef={ref}>
              <Paper {...rootProps as any} className={classes.dropzoneRoot}>
                <input {...getInputProps()} />
                <Typography align="center" className={classes.dropzoneText}>
                  {text}
                </Typography>
              </Paper>
            </RootRef>
          );
        }}
      </Dropzone>
    );
  }
}

export default withStyles(styles)(FileDropZone)