import * as React from "react";
import {
  Box,
  DialogContentText,
  Grid,
  Paper,
  Typography
} from "@material-ui/core";
import JobPost from "../../model/JobPost";
import ProfileStore from "../../stores/ProfileStore";
import FormValidator from "../../components/form/FormValidator";
import { makeObservable, observable, when } from "mobx";
import {UpdateJobPostInput, WorkSetting} from "../../API";
import Logger from "../Logger";
import AccountStore from "../../stores/AccountStore";
import Confirm from "../confirm/Confirm";
import { inject, observer } from "mobx-react";
import Progress from "../Progress";
import JobStore from "../../stores/JobStore";
import AppDialog from "../dialog/AppDialog";
import DialogButton from "../form/DialogButton";
import Question, {
  CertificationQuestion, EducationQuestion,
  ExperienceQuestion,
  LocationQuestion,
  QuestionType, SecurityClearanceQuestion, YesNoQuestion
} from "../../model/Question";
import ExperienceQuestionCard from "./screening/ExperienceQuestionCard";
import LocationQuestionCard from "./screening/LocationQuestionCard";
import AddButton from "../AddButton";
import {humanizeString} from "../../stores/StoreUtilities";
import CertificationQuestionCard from "./screening/CertificationQuestionCard";
import EducationQuestionCard from "./screening/EducationQuestionCard";
import YesNoQuestionCard from "./screening/YesNoQuestionCard";
import SecurityClearanceQuestionCard from "./screening/SecurityClearanceQuestionCard";
import ResourceCache from "../../stores/ResourceCache";

export class JobPostQuestionsViewModel {
  @observable id: string
  @observable questions: Question[] = []
  @observable industry?: string

  constructor(jobPost: JobPost, resourceCache?: ResourceCache) {
    makeObservable(this)
    this.id = jobPost.id
    if (jobPost.questions.length > 0) {
      this.questions = jobPost.questions
    } else {
      this.questions = this.getDefaultQuestions(jobPost, resourceCache)
    }
    this.industry = jobPost.industries && jobPost.industries.length === 1 ? jobPost.industries[0] : undefined
  }

  getDefaultQuestions(jobPost: JobPost, resourceCache?: ResourceCache) {
    const questions: Question[] = [
      new ExperienceQuestion({
        type: QuestionType.ExperienceQuestion,
        name: humanizeString(QuestionType.ExperienceQuestion),
        enabled: false,
        required: false,
        minYears: 0,
        jobTitle: jobPost.service?.name ?? ""
      }),
    ]

    // if (jobPost.location && jobPost.location.name !== "Anywhere") {
      questions.push(new LocationQuestion({
        type: QuestionType.LocationQuestion,
        name: humanizeString(QuestionType.LocationQuestion),
        enabled: false,
        required: false,
        location: jobPost.locationDescription
      }))
    // }

    if (jobPost.securityClearance) {
      questions.push(new SecurityClearanceQuestion({
        type: QuestionType.SecurityClearanceQuestion,
        name: humanizeString(QuestionType.SecurityClearanceQuestion),
        enabled: false,
        required: false,
        clearance: jobPost.securityClearance
      }))
    }

    return questions
  }
}

export interface IJobPostQuestionsFormDelegate {
  didEdit(jobPost: JobPost): void
  didClose(): void
  didCancel?(): void
}

interface IJobPostQuestionsFormProps extends IJobPostQuestionsFormDelegate {
  jobPost: JobPost
  isFormOpen?: boolean
  renderAsDialog: boolean 

  // Stores: 
  accountStore?: AccountStore
  jobStore?: JobStore
  confirm?: Confirm
  progress?: Progress
  profileStore?: ProfileStore
  resourceCache?: ResourceCache
}

@inject("accountStore", "jobStore", "profileStore", "resourceCache", "progress", "confirm")
@observer
class JobPostQuestionsForm extends React.Component<IJobPostQuestionsFormProps> {
  @observable isProcessing = false
  @observable message = ""
  @observable questions: any = [

  ]
  @observable data: any = {}
  @observable viewModel?: JobPostQuestionsViewModel

  constructor(props: any) {
    super(props)
    makeObservable(this)
  }

  componentDidMount() {
    const { resourceCache } = this.props
    when(
      // once...
      () => this.props.jobPost !== undefined,
      // ... then
      () => {
        this.viewModel = new JobPostQuestionsViewModel(this.props.jobPost!, resourceCache)
        this.questions = this.props.jobPost!.questions
      }
    )
  }

  componentDidUpdate(prevProps: any) {
    const { resourceCache } = this.props
    if (prevProps.jobPost !== this.props.jobPost) {
      if (this.props.jobPost) {
        this.viewModel = new JobPostQuestionsViewModel(this.props.jobPost!, resourceCache)
        this.questions = this.props.jobPost!.questions
      } else {
        this.viewModel = undefined
        this.questions = {}
      }
    }
  }

  renderFormContent() {
    return (
      <Box style={{minHeight:250}}>
        {this.renderQuestions()}
      </Box>
    )
  }

  renderQuestions() {
    const viewModel = this.viewModel!

    const cards:  JSX.Element[] = []

    viewModel.questions.forEach((question: Question) => {
      let card
      switch (question.type) {
        case QuestionType.CertificationQuestion:
          card = <CertificationQuestionCard question={question as CertificationQuestion}
                                         industry={viewModel.industry}
                                         onChange={this.handleQuestionChange}
                                         onDelete={this.handleDeleteQuestion}/>
          break;
        case QuestionType.EducationQuestion:
          card = <EducationQuestionCard question={question as EducationQuestion}
                                            onChange={this.handleQuestionChange}
                                            onDelete={this.handleDeleteQuestion}/>
          break;
        case QuestionType.ExperienceQuestion:
          card = <ExperienceQuestionCard question={question as ExperienceQuestion}
                                         onChange={this.handleQuestionChange}
                                         onDelete={this.handleDeleteQuestion}/>
          break;
        case QuestionType.LocationQuestion:
          card = <LocationQuestionCard question={question as LocationQuestion}
                                       onChange={this.handleQuestionChange}
                                       onDelete={this.handleDeleteQuestion}/>
          break;
        case QuestionType.YesNoQuestion:
          card = <YesNoQuestionCard question={question as YesNoQuestion}
                                       onChange={this.handleQuestionChange}
                                       onDelete={this.handleDeleteQuestion}/>
          break;
        case QuestionType.SecurityClearanceQuestion:
          card = <SecurityClearanceQuestionCard question={question as SecurityClearanceQuestion}
                                    onChange={this.handleQuestionChange}
                                    onDelete={this.handleDeleteQuestion}/>
          break;
      }
      if (card) {
        cards.push(card)
      }
    })

    return cards
  }

  handleQuestionChange = (question: Question) => {
    const index = this.viewModel!.questions.findIndex((q: Question) => q.name === question.name)
    if (index >= 0) {
      this.viewModel!.questions[index] = question
    } else {
      this.viewModel!.questions.push(question)
    }
  }

  onChange = (question: Question) => {
    this.viewModel!.questions[0] = question
  }

  renderAsDialog() {
    const { isFormOpen } = this.props 

    return (
      <AppDialog
        title='Job Screening Questions'
        dialogId='create-job-questions-dialog'
        formId='create-job-questions-form'
        isLoading={false} 
        isOpen={ isFormOpen ? isFormOpen : false }
        maxWidth='md'
        scroll='paper'
        onCancel={() => {
          this.handleCloseJobPostQuestionsForm()
        }} 
        onSubmit={async () => {
          await this.onSubmitJobPostQuestionsForm()
          this.handleCloseJobPostQuestionsForm()
        }}
      >
        <DialogContentText>
          Add screening questions for candidates applying to this job.
        </DialogContentText>
        <Box mt={1}>
          <DialogContentText color="error">
            {this.message}
          </DialogContentText>
        </Box>
        {this.renderFormContent()}
        <AddButton
          text="Add Question"
          buttonColor="secondary"
          buttonVariant="contained"
          buttonSize="small"
          icon='add'
          options={Object.values(QuestionType)}
          getOptionLabel={(option: any) => humanizeString(option)}
          click={this.handleAddQuestion}
        />
      </AppDialog>
    )
  }

  renderAsForm() {
    return (
      <Paper>
        <Box p={2}>
          <DialogContentText>
            Add screening questions for candidates applying to this job.
          </DialogContentText>
          <AddButton
            text="Add Question"
            buttonColor="secondary"
            buttonVariant="contained"
            buttonSize="small"
            icon='add'
            options={Object.values(QuestionType)}
            getOptionLabel={(option: any) => humanizeString(option)}
            click={this.handleAddQuestion}
          />
          <Box mt={1}>
            <Typography color="error">
              {this.message}
            </Typography>
          </Box>
          <FormValidator
            onSubmit={() => {
              this.onSubmitJobPostQuestionsForm()
            }}
            autoComplete="off" 
            name="job-post-description-form" 
            id="job-post-description-form"
          >
            {this.renderFormContent()}
            <Box pt={1}>
              <Grid container justifyContent="flex-end">
                <Grid item>
                  <Box mr={2}>
                    <DialogButton
                      variant="secondary"
                      type="button"
                      onClick={this.handleCancelJobPostQuestionsForm}
                    >
                      Cancel
                    </DialogButton>
                  </Box>
                </Grid>
                <Grid item>
                  <Box mr={2}>
                    <DialogButton 
                      variant="secondary" 
                      type="button"
                      onClick={this.handleCloseJobPostQuestionsForm}
                    >
                      Skip for now
                    </DialogButton>
                  </Box>
                </Grid>
                <Grid item>
                  <DialogButton 
                    variant="primary" 
                    type="submit"
                  >
                    Next 
                  </DialogButton>
                </Grid>
              </Grid>
            </Box>
          </FormValidator>
        </Box>
      </Paper>
    )
  }

  render() {
    const { 
      renderAsDialog
    } = this.props

    if (this.viewModel === undefined) {
      return null
    }

    return (
      <React.Fragment>
        { renderAsDialog ?
            this.renderAsDialog() 
          :
            this.renderAsForm() 
        }
      </React.Fragment>
    )
  }

  handleCancelJobPostQuestionsForm = () => {
    if (this.props.didCancel) {
      this.props.didCancel()
    }
  }

  handleCloseJobPostQuestionsForm = () => {
    this.message = ""
    this.props.didClose()
  }

  handleAddQuestion = (option: string) => {
    const { jobPost } = this.props
    const matches = this.viewModel!.questions.filter((q: Question) => q.type === option)

    let question: Question | undefined
    switch (option) {
      case QuestionType.CertificationQuestion:
        question = new CertificationQuestion({
          type: option,
          name: humanizeString(option),
          enabled: false,
          required: false,
          certification: "",
        })
        break
      case QuestionType.EducationQuestion:
        question = new EducationQuestion({
          type: option,
          name: humanizeString(option),
          enabled: false,
          required: false,
          degree: "",
        })
        break
      case QuestionType.ExperienceQuestion:
        question = new ExperienceQuestion({
          type: option,
          name: humanizeString(option),
          enabled: false,
          required: false,
          minYears:0,
          jobTitle: jobPost.title
        })
        break
      case QuestionType.LocationQuestion:
        question = new LocationQuestion({
          type: option,
          name: humanizeString(option),
          enabled: false,
          required: false,
          location: jobPost.location && matches.length === 0 ? jobPost.location.name : ""
        })
        break
      case QuestionType.YesNoQuestion:
        question = new YesNoQuestion({
          type: option,
          name: humanizeString(option),
          enabled: false,
          required: false,
          question: "",
          label: ""
        })
        break
      case QuestionType.SecurityClearanceQuestion:
        question = new SecurityClearanceQuestion({
          type: option,
          name: humanizeString(option),
          enabled: false,
          required: false
        })
        break
    }

    if (question !== undefined) {
      // Add number to duplicate names
      if (matches.length > 0) {
        question.name = this.generateUniqueName(question.name, matches)
      }
      this.viewModel!.questions.push(question)
    }
  }

  generateUniqueName = (name: string, matches: Question[]) => {
    // Add a number to the name based on the highest existing question with the same base name
    // (e.g. Education Question 2)
    matches.sort((a: Question, b: Question) => a.name.localeCompare(b.name))
    const lastQuestion = matches[matches.length-1]
    const result = /([0-9]+)$/g.exec(lastQuestion.name)
    let newName
    if (result) {
      const lastNum = parseInt(result[0])
      newName = `${name} ${lastNum+1}`
    } else {
      newName = `${name} 2`
    }
    return newName
  }

  handleDeleteQuestion = (option: string) => {
    const index = this.viewModel!.questions.findIndex((q: Question) => q.name === option)
    if (index >= 0) {
      this.viewModel!.questions.splice(index, 1)
    }
  }

  onEditorChange = (content: any, editor: any) => {
    // Logger.info(content, editor)
    this.questions = content
  }

  handleChangeJobPostQuestionsFormField = (event: React.ChangeEvent<HTMLInputElement>) => {
    const name = event.target.name
    this.viewModel![name] = event.target.value
  }

  onSubmitJobPostQuestionsForm = async () => {
    this.isProcessing = true
    this.props.progress!.show('JobPostQuestionsForm')
    this.message = ""

    await this.saveJobPost()
    
    this.isProcessing = false
    this.props.progress!.hide('JobPostQuestionsForm')
  }

  saveJobPost = async () => {
    if (this.viewModel!.id) {
      const input: UpdateJobPostInput = {
        id: this.viewModel!.id,
        questions: JSON.stringify(this.viewModel!.questions)
      }
      try {
        const jobPost = await this.props.jobStore!.updateJobPost(input)
        this.props.didEdit(jobPost!)
      } catch (error) {
        const message = 'Could not update the job post.'
        Logger.error(message, error)
        this.message = message
        return
      }
    } else {
      this.props.jobPost.questions = this.viewModel!.questions
      this.props.didEdit(this.props.jobPost)
    }
  }
}

export default JobPostQuestionsForm


