import * as React from "react";
import {
  Box,
  Card, CardActionArea,
  CardContent,
  createStyles,
  Grid,
  Theme,
  Typography,
  withStyles,
  WithStyles
} from "@material-ui/core";
import {inject, observer} from "mobx-react";
import {grey} from "@material-ui/core/colors";
import UserStore from "../../stores/UserStore";
import StarIcon from "@material-ui/icons/Star";
import ResourceCache, {ServiceOption} from "../../stores/ResourceCache";
import ProfileNameView from "../../components/profile/ProfileNameView";
import {ProfileAvailabilityOptions} from "../../model/Profile";
import LockIcon from "@material-ui/icons/Lock";
import DateRangeIcon from '@material-ui/icons/DateRange';
import ScheduleIcon from "@material-ui/icons/Schedule";
import WorkOutlineIcon from '@material-ui/icons/WorkOutline';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import {isoDateToWhen} from "../../stores/StoreUtilities";

const styles = (theme: Theme) => createStyles({
  card: {
    fontSize: 12,
    // padding: '8px !important', // overriding CardContent root setting
    paddingTop: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  header: {
    fontWeight: 200,
    color: theme.palette.text.secondary,
    borderBottom: `1px solid ${grey[300]}`,
    paddingBottom: 4,
    marginBottom: 2
  },
  body: {
  },
  property: {
    display: "flex",
    flexDirection: "row",
    paddingBottom: "3px"
  },
  propIcon: {
    color: theme.palette.text.secondary,
    width: 18,
    height:18,
    paddingTop: 2,
    marginRight: theme.spacing(1)
  },
  bold: {
    display: "flex",
    flexDirection: "row",
    fontWeight: 600,
    padding: "2px 0"
  },
  summary: {
    maxHeight: 80,
    overflowY: "hidden"
  },
  saved: {
    textAlign: "right",
    color: theme.palette.primary.main,
    maxHeight: 18,
    paddingTop: 4
  },
  icon: {
    width: 20,
    height: 20
  },
  availableNow: {
    display: "flex",
    flexDirection: "row",
    color: theme.palette.success.dark,
    fontWeight: 600
  },
  availableSoon: {
    display: "flex",
    flexDirection: "row",
    color: theme.palette.warning.main
  },
  availableLater: {
    display: "flex",
    flexDirection: "row",
    color: theme.palette.error.dark
  },
  unavailable: {
    display: "flex",
    flexDirection: "row",
    color: theme.palette.text.secondary
  }
})

interface IProfileSearchCardProps {
  profile: any
  service?: ServiceOption
  certificationType?: string
  onClick?: any
  isSaved?: boolean
  userStore?: UserStore
  resourceCache?: ResourceCache
}

@inject("userStore", "resourceCache")
@observer
class ProfileSearchCard extends React.Component<WithStyles<typeof styles> & IProfileSearchCardProps> {

  render() {
    const { classes, profile, isSaved, service, certificationType, resourceCache } = this.props

    const title = service?.name

    let availability = profile.availability
    let availabilityClassName
    if (availability === ProfileAvailabilityOptions[0]) {
      availabilityClassName = classes.availableNow
    } else if (availability === ProfileAvailabilityOptions[4]) {
      availabilityClassName = classes.availableLater
    } else if (availability === ProfileAvailabilityOptions[5]) {
      availabilityClassName = classes.unavailable
    } else {
      availabilityClassName = classes.availableSoon
    }
    const lastActiveAt = profile.user ? isoDateToWhen(profile.user!.lastActiveAt) : ""

    let experience: string | undefined = undefined
    if (profile!.totalExperience) {
      experience =  `${Math.round(profile!.federalExperience * 100) / 100} yr${profile!.federalExperience !== 1 ? "s" : ""} federal experience (${Math.round(profile!.totalExperience * 100 / 100)} total)`
    }

    const securityClearance = profile.securityClearance

    const savedIcon = isSaved ? <StarIcon className={classes.icon}/> : null
    let certification
    if (certificationType && profile.findCertification(certificationType)) {
      certification = certificationType
    }

    return (
      <Card>
        <CardActionArea onClick={this.props.onClick ? () => this.props.onClick(profile) : undefined}>
          <CardContent className={classes.card}>
            <Grid container className={classes.body}>
              <Grid item xs={11}>
                <Box
                  pb={0.5}
                >
                  <ProfileNameView 
                    profile={profile}
                    textColor="primary"
                  />
                </Box>
              </Grid>
              <Grid item xs={1} className={classes.saved}>
                {savedIcon}
              </Grid>
              <Grid item xs={12} className={classes.bold}>
                {title}
              </Grid>
              {experience &&
                <Grid item xs={12} className={classes.bold}>
                  <WorkOutlineIcon className={classes.propIcon}/>
                  <Typography noWrap={true} variant='body2'>{experience}</Typography>
                </Grid>
              }
              {securityClearance &&
                <Grid item xs={12} className={classes.property}>
                  <LockIcon className={classes.propIcon}/>
                  <Typography noWrap={true} variant='body2'>{securityClearance}</Typography>
                </Grid>
              }
              {certification &&
                <Grid item xs={12} className={classes.property}>
                  <VerifiedUserIcon className={classes.propIcon}/>
                  <Typography noWrap={true} variant='body2'>{certification}</Typography>
                </Grid>
              }
              {availability &&
                <Grid item xs={12} className={availabilityClassName}>
                  <DateRangeIcon className={classes.propIcon}/>
                  <Typography noWrap={true} variant='body2'>Availability: {availability}</Typography>
                </Grid>
              }
              {lastActiveAt &&
                <Grid item xs={12} className={classes.property}>
                  <ScheduleIcon className={classes.propIcon}/>
                  <Typography noWrap={true} variant='body2'>Last active: {lastActiveAt}</Typography>
                </Grid>
              }
              <Grid item xs={12} className={classes.summary}>
                <Typography variant='body2'>{profile.about}</Typography>
              </Grid>
            </Grid>
          </CardContent>
        </CardActionArea>
      </Card>

    )
  }

  // renderServices = () => {
  //   const { profile } = this.props
  //
  //   profile.services.sort((a: Service, b: Service) => a.name.localeCompare(b.name))
  //
  //   let services = ""
  //   profile.services.forEach((service: Service) => {
  //
  //   })
  // }
}

export default withStyles(styles)(ProfileSearchCard)
