import React from "react";
import {
  alpha,
  Box, 
  Button, 
  Grid, 
  GridDirection, 
  GridItemsAlignment, 
  Hidden, 
  isWidthDown,
  withWidth,
  WithWidth
} from "@material-ui/core";
import { createStyles, Theme, WithStyles, WithTheme } from "@material-ui/core";
import { withStyles, withTheme } from "@material-ui/core/styles";
import FormValidator from "../form/FormValidator";
import MenuIcon from '@material-ui/icons/Menu';
import DialogButton from "../form/DialogButton";
import SideDrawer from "../page/SideDrawer";
import Progress from "../Progress";
import { inject, observer } from "mobx-react";

const styles = (theme: Theme) => createStyles({
  filterBar: {
    height: 56
  },
  filterButtonOff: {
    backgroundColor: theme.palette.secondary.main,
    color: '#fff',
    borderWidth: 0,
    minWidth: 40,
    marginTop: 0,
    marginRight: 8,
    paddingLeft: 0,
    paddingRight: 0,
    boxShadow: 'rgba(0, 0, 0, 0.25) 0px 1px 3px 0px',
    "&:hover": {
      borderWidth: 0,
      backgroundColor: alpha(theme.palette.secondary.light, 0.8),
    },
    transform: "rotate(0)"
  },
  filterButtonOn: {
    backgroundColor: theme.palette.secondary.main,
    color: '#fff',
    borderWidth: 0,
    minWidth: 40,
    marginTop: 0,
    marginRight: 8,
    paddingLeft: 0,
    paddingRight: 0,
    boxShadow: 'rgba(0, 0, 0, 0.25) 0px 1px 3px 0px',
    "&:hover": {
      borderWidth: 0,
      backgroundColor: alpha(theme.palette.secondary.light, 0.8),
    },
    transform: "rotate(-180deg)"
  },
  filterField: {
    flexGrow: 1
  },
  drawer: {
    padding: theme.spacing(2) 
  }
})

export interface IFilterBarProps {
  buttons?: React.ReactNode[]
  searchResultCount?: number 

  progress?: Progress
}

@inject("progress")
@observer
class FilterBar extends React.Component<WithStyles<typeof styles> & WithTheme & IFilterBarProps & WithWidth> {

  state = {
    isFilterOpen: false,
  }

  renderDrawer() {
    const { classes, progress, searchResultCount, theme } = this.props 
    return (
      <SideDrawer
        isOpen={this.state.isFilterOpen} 
        title="Filter" 
        size="small"
        onClose={() => {
          this.toggleFilter()
        }}      
      >
        <Box
          className={classes.drawer}
        >
          { this.renderFields("column", "stretch") }
          <Box
            sx={{
              paddingTop: theme.spacing(2)
            }}
          >
            <Button
              variant="contained"
              color="secondary"
              onClick={() => {
                this.toggleFilter()
              }}
              disabled={progress!.isVisible || (searchResultCount ? searchResultCount === 0 : true)}
            >
              {`View Results ${searchResultCount ? searchResultCount : 0}`}
            </Button>
          </Box>
        </Box>
      </SideDrawer>
    )
  }

  renderFields(direction: GridDirection, alignItems: GridItemsAlignment) {
    const { classes } = this.props 
    return (
      <Grid 
        container 
        spacing={2} 
        wrap="nowrap"
        justifyContent="flex-start" 
        alignItems={alignItems}
        direction={direction}
      >
        {React.Children.map(this.props.children, (child: any, index: number) => {
          if (child !== null) {
            return (
              <Grid item key={`filter-${index}`} className={classes.filterField}>
                {child}
              </Grid>
            )
          }
        })}
      </Grid>
    )
  }

  renderButtons() {
    const { buttons, classes, width } = this.props 
    const isSmall = isWidthDown('sm', width)

    return (
      <Grid 
        container 
        direction="row" 
        justifyContent={ isSmall ? "flex-start" : "flex-end" } 
        alignItems="center" 
        spacing={2}
        wrap="nowrap"
      >
        <Hidden mdUp>
          <Grid item>
            <DialogButton 
              variant='secondary' 
              size="large" 
              onClick={this.toggleFilter} 
              customClassName={this.state.isFilterOpen ? classes.filterButtonOn : classes.filterButtonOff}
            >
              <MenuIcon />
            </DialogButton>
          </Grid>
        </Hidden>
        {buttons && buttons.map((button: React.ReactNode, index: number) => {
          return (
            <Grid item key={`button-${index}`}>
              <Box>
                {button}
              </Box>
            </Grid>
          )
        })}
      </Grid>
    )
  }

  render() {
    const { classes } = this.props
    
    return (
      <Box borderRadius={10} bgcolor="grey.500" p={1} className={`${classes.filterBar} filter_grid_wrapper`}>
        <FormValidator name="filterForm">
          <Grid 
            container 
            justifyContent="space-between" 
            alignItems="center" 
            wrap="wrap"
            spacing={2}
          >
            <Hidden smDown>
              <Grid item md={9}>
                {this.renderFields("row", "center")}
              </Grid>
            </Hidden>
            <Grid item md={3}>
              {this.renderButtons()}
            </Grid>
          </Grid>
          { this.renderDrawer() }
        </FormValidator>
      </Box>
    )
  }

  toggleFilter = () => {
    this.setState({isFilterOpen: !this.state.isFilterOpen})
  }
}

export default withTheme(withStyles(styles)(withWidth()(FilterBar)))