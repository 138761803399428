import { Box, Typography, useMediaQuery, useTheme } from "@material-ui/core"

const PageTitle = ({
  title,
  align, 
  variant
}: {
  title: string 
  align?: "left" | "center" | "right"
  variant?: "h1" | "h2" | "h3"
}) => {
  const theme = useTheme()
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'))
  
  return (
    <Box 
      pb={2} 
      pt={1.5}
      px={ isSmallScreen ? 2 : 3 }
    >
      <Typography align={ align ?? "left" } variant={ variant ?? "h3" } color="primary">
        {title}
      </Typography>
    </Box>
  )
}

export default PageTitle 
