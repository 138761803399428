export class PcsCategory {
  id: string
  createdAt: string
  name: string
  psccode: string

  constructor(data: any) {
    this.id = data.Id
    this.createdAt = data.createdAt
    this.name = data.name
    this.psccode = data.psccode
  }
}

export default PcsCategory