import * as React from "react";

interface ITextBlockProps {
  children: any
}

class TextBlock extends React.Component<ITextBlockProps> {
  render() {
    const { children } = this.props

    if (typeof children === 'string') {
      return children.split('\n').map((p, idx) => <p key={idx}>{p}</p>)
    } else {
      return children
    }
  }
}

export default TextBlock