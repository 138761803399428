import * as React from 'react'
import {createStyles, Theme, withStyles, WithStyles} from "@material-ui/core/styles";
import AccountStore from "../../stores/AccountStore";
import UserStore from "../../stores/UserStore";
import {inject, observer} from "mobx-react";
import {makeObservable, observable} from "mobx";
import Agreement from "../../model/Agreement";
import Account from "../../model/Account";
import User from "../../model/User";
import {AgreementType, ModelAgreementFilterInput} from "../../API";
import {getISODateToday, isoToLocalDate} from "../../stores/StoreUtilities";
import {format} from "date-fns";
import ExclusiveMasterServiceAgreement20231012 from './ExclusiveMasterServiceAgreement20231012';

const styles = (theme: Theme) => createStyles({
})

interface IMasterServiceAgreementProps {
  accountId?: string
  accountStore?: AccountStore
  userStore?: UserStore
}

@inject("userStore", "accountStore")
@observer
class MasterServiceAgreement extends React.Component<WithStyles<typeof styles> & IMasterServiceAgreementProps> {

  @observable agreement?: Agreement
  @observable account?: Account
  @observable user?: User

  constructor(props: any) {
    super(props)
    makeObservable(this);
  }

  async componentDidMount() {
    const {accountStore, userStore} = this.props

    let accountId = this.props.accountId
    if (!accountId && accountStore!.account) {
      accountId = accountStore!.account.id
    }

    if (accountId && accountStore) {
      const filter: ModelAgreementFilterInput = {
        agreementTypes: {contains: AgreementType.ExclusiveRecruitment}
      }
      const agreements = await accountStore.listAgreementsByAccount(accountId, filter)
      if (agreements.length > 0) {
        this.agreement = agreements[0]
      }
      if (this.agreement) {
        this.account = await accountStore.getAccount(this.agreement.accountId)
        this.user = await userStore!.getUser(this.agreement.userId)
      }
    }
  }

  componentDidUpdate(prevProps: any) {
    if (this.props.accountId !== prevProps.accountId) {
      this.componentDidMount()
    }
  }

  render() {

    const accountName = this.account ? this.account.name : "You"
    const updatedOn = this.agreement ? this.agreement.updatedAt.substring(0, 10) : getISODateToday()
    const acceptedBy = this.user ? this.user.fullName : undefined
    const acceptedOn = this.agreement ? format(isoToLocalDate(this.agreement.updatedAt), "MMMM d, yyyy") : undefined

    if (updatedOn >= "2023-10-12") {
      return <ExclusiveMasterServiceAgreement20231012 accountName={accountName} acceptedBy={acceptedBy} acceptedOn={acceptedOn} />
    } else {
      return null
    }
  }
}

export default withStyles(styles)(MasterServiceAgreement)
