import { useEffect, useState } from "react"
import { when } from "mobx"
import { useStores } from "../../stores/StoreProvider"
import Agreement from "../../model/Agreement"
import Stack from "../../components/Stack"
import { Box, Button, Card, CardContent, Typography, useTheme } from "@material-ui/core"
import { OpenInNew, PostAdd } from "@material-ui/icons"
import { AgreementType, ModelAgreementFilterInput } from "../../API"
import ControlTower, { Routes } from "../../components/ControlTower"
import IconicButton from "../../components/controls/IconicButton"
import LoadingPanel from "../../components/panel/LoadingPanel"
import AgreementDialog from "./AgreementDialog"
import FilePreview from "../../components/file/FilePreview"
import ActionLink from "../../components/ActionLink"
import { isoToLocalDateString } from "../../stores/StoreUtilities"
import CardValue from "../../components/CardValue"
import EditButton from "../../components/controls/EditButton"

interface AgreementInfo {
  id: string 
  title: string 
  route?: string 
  agreementKey?: string,
  updatedDate: string, 
  createdDate: string 
}

const AgreementsList = ({
  accountId 
}: {
  accountId: string 
}) => {
  
  const { accountStore, profileStore, progress, userStore } = useStores()
  const theme = useTheme()

  const progressName = "AgreementsList"
  const [isLoading, setIsLoading] = useState(true)
  const [agreements, setAgreements] = useState<Agreement[]>([])
  const [agreementInfo, setAgreementInfo] = useState<AgreementInfo[]>([])
  const [isAgreementDialogOpen, setIsAgreementDialogOpen] = useState(false)
  const [agreementId, setAgreementId] = useState<string>()
  
  useEffect(() => {
    const loadAgreements = async (accountId: string) => { 
      const filter: ModelAgreementFilterInput = {
        or: [
          {agreementTypes: {contains: AgreementType.MasterServicesAndNonSolicitation}},
          {agreementTypes: {contains: AgreementType.ConfidentialityAndNonDisclosure}},
          {agreementTypes: {contains: AgreementType.StaffingAgreement}},
          {agreementTypes: {contains: AgreementType.ProfessionalServicesAgreement}},
          {agreementTypes: {contains: AgreementType.ExclusiveRecruitment}},
        ]
  
      }
      const agreements = await accountStore!.listAgreementsByAccount(accountId, filter)
      setAgreements(agreements)
    }

    when(
      () => accountStore.isLoading === false && userStore.isLoading === false, 
      async () => {
        progress.show(progressName)
        await loadAgreements(accountId)
        progress.hide(progressName)
        setIsLoading(false)
      }
    )
  }, [
    accountId,
    accountStore, 
    userStore 
  ])

  // const renderAgreementPreview = (agreementInfo?: AgreementInfo) => {
  //   if (!agreementInfo?.agreementKey) {
  //     return null 
  //   }
  //   return (
  //     <Box>
  //       <FilePreview 
  //         title={agreementInfo.title}
  //         level={"private"}
  //         fileKey={agreementInfo?.agreementKey}
  //         onClickEmpty={() => {
  //           setIsAgreementDialogOpen(true)
  //         }}
  //       />
  //     </Box>
  //   )
  // }

  const renderAgreementDialog = (agreementInfo?: AgreementInfo) => {
    let isOpen = false 
    if (isAgreementDialogOpen && agreementId && agreementInfo?.id === agreementId) {
      isOpen = true 
    } else if (isAgreementDialogOpen) {
      isOpen = true 
    }

    return (
      <AgreementDialog 
        isOpen={ isOpen }
        accountId={accountId}
        agreementId={agreementId}
        onClose={() => {
          setAgreementId(undefined)
          setIsAgreementDialogOpen(false)
        }} 
        onUpdateAgreement={async (updatedAgreement: Agreement) => {
          const foundIndex = agreements.findIndex(checkAgreement => checkAgreement.id === updatedAgreement.id)
          if (foundIndex !== -1) {
            const agreementsCopy = Object.assign([], agreements)
            agreementsCopy.splice(foundIndex, 1, updatedAgreement)
            setAgreements(agreementsCopy)
          }
        }} 
        onCreateAgreement={async (createdAgreement: Agreement) => {
          const agreementsCopy = Object.assign([], agreements)
          agreementsCopy.push(createdAgreement)
          // TODO: Sort the agreements? 
          setAgreements(agreementsCopy)
        }}
        onDeleteAgreement={async (deletedAgreement: Agreement) => {
          const agreementsCopy = Object.assign([], agreements)
          const foundIndex = agreements.findIndex(checkAgreement => checkAgreement.id === deletedAgreement.id)
          if (foundIndex !== -1) {
            agreementsCopy.splice(foundIndex, 1)
            setAgreements(agreementsCopy)
          }
        }}  
      />
    )
  }

  const handleClickAgreement = async (agreementInfo: AgreementInfo) => {
    let url 
    if (agreementInfo.agreementKey) {
      url = await profileStore!.getFileURL(agreementInfo.agreementKey, "private")
    } else {
      url = agreementInfo.route
    }
    ControlTower.open(url!)
  }

  const renderAgreementCard = (agreementInfo: AgreementInfo) => {
    return (
      <Card
        key={agreementInfo.id}
      >
        <CardContent>
          <Box
            px={2}
          >
            <Stack 
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              sx={{
                paddingTop: theme.spacing(1),
                // paddingBottom: agreementInfo.agreementKey ? theme.spacing(2) : 0 
              }}
              wrap="nowrap"
            >
              <Box>
                <ActionLink 
                  text={agreementInfo.title}
                  tracking="Agreement-Title"
                  click={async () => {
                    await handleClickAgreement(agreementInfo)
                  }}
                />
              </Box>
              <Box>
                <Stack
                  spacing={1}
                >
                  { agreementInfo.agreementKey && 
                    <EditButton 
                      onClick={() => {
                        setAgreementId(agreementInfo.id)
                        setIsAgreementDialogOpen(true)
                      }}                      
                    />
                  }
                  <IconicButton
                    onClick={async () => {
                      await handleClickAgreement(agreementInfo)
                    }}
                    >
                    <OpenInNew />
                  </IconicButton>
                </Stack>
              </Box>
            </Stack>
            <Stack 
              sx={{ 
                // paddingBottom: agreementInfo.agreementKey ? theme.spacing(2) : 0 
              }}
            >
              <CardValue label="Updated date" labelWidth={120}>{agreementInfo.updatedDate}</CardValue>
            </Stack>
            {/* { renderAgreementPreview(agreementInfo) } */}
            { renderAgreementDialog(agreementInfo) }
          </Box>
        </CardContent>
      </Card>
    )
  }

  const renderAgreement = (agreement: Agreement) => {
    if (agreement.agreementKey) {
      const agreementType = agreement.agreementTypes[0]
      const title = accountStore.agreementTypeToString(agreementType)
      return renderAgreementCard({
        id: agreement.id, 
        title, 
        agreementKey: agreement.agreementKey,
        updatedDate: isoToLocalDateString(agreement.updatedAt),
        createdDate: isoToLocalDateString(agreement.createdAt)
      })
    } else {
      return agreement.agreementTypes.map(agreementType => {
        const title = accountStore.agreementTypeToString(agreementType)
        if (agreementType === AgreementType.ExclusiveRecruitment) {
          return renderAgreementCard({
            id: agreement.id, 
            title, 
            route: `${Routes.msaExclusive}?accountId=${accountId}`,
            updatedDate: isoToLocalDateString(agreement.updatedAt),
            createdDate: isoToLocalDateString(agreement.createdAt)
          })
        } else if (agreementType === AgreementType.MasterServicesAndNonSolicitation) {
          return renderAgreementCard({
            id: agreement.id, 
            title, 
            route: `${Routes.msa}?accountId=${accountId}`,
            updatedDate: isoToLocalDateString(agreement.updatedAt),
            createdDate: isoToLocalDateString(agreement.createdAt)
          })
        } else if (agreementType === AgreementType.BackgroundCheck) {
          return renderAgreementCard({
            id: agreement.id, 
            title, 
            route: `${Routes.nda}?accountId=${accountId}`,
            updatedDate: isoToLocalDateString(agreement.updatedAt),
            createdDate: isoToLocalDateString(agreement.createdAt)
          })
        } else {
          // Unhandled agreement type for now. 
          return null 
        }   
      })
    }
  }

  const renderAgreements = () => {
    if (agreements.length === 0) {
      return (
        <Typography>No agreements found.</Typography>
      )
    } else {
      return agreements.map(agreement => {
        return renderAgreement(agreement)
      }) 
    }
  }

  const renderLoading = () => {
    return (
      <LoadingPanel />
    )
  }

  return (
    <Stack
      direction="column"
      sx={{
        paddingTop: 2, 
        paddingBottom: 3
      }}
      spacing={2}
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={1}
        sx={{
          paddingBottom: theme.spacing(2)
        }}
      >
        <Box>
          <Typography variant="h3">
            Agreements
          </Typography>
        </Box>
        { userStore.isAdminOrAgent && 
          <Button
            variant="contained"
            color="secondary"
            startIcon={<PostAdd />}
            onClick={() => {
              setAgreementId(undefined)
              setIsAgreementDialogOpen(true)
            }}
          >
            Upload 
          </Button>
        }
      </Stack>
      { isLoading ? renderLoading() : renderAgreements() }
      { renderAgreementDialog() }
    </Stack>
  )
}

export default AgreementsList
