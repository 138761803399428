import { SectionBackgroundColors } from '../../../components/marketing/sections/Section'
import TitleSection from '../../../components/marketing/sections/TitleSection'
import { 
  Box, 
  Container, 
  // Stack, 
  Typography, 
  useTheme
} from '@material-ui/core'
import Stack from '../../../components/Stack'

const QandA = ({
  question, 
  answer 
}: {
  question: string, 
  answer: string | React.ReactNode
}) => {
  return (
    <Stack direction="column" spacing={1}>
      <Typography>
        {`Q: ${question}`}
      </Typography>
      { typeof answer === 'string' ? 
        <Typography>
          {`A: ${answer}`}
        </Typography>
      :
        <Stack direction='row'>
          <Typography>A:</Typography>
          <Box>
            {answer}
          </Box>
        </Stack>
      }
    </Stack>
  )
}

const EmployerFAQ = () => {
  const theme = useTheme()
  return (
    <TitleSection
      title='Frequently Asked Questions'
      backgroundColor={SectionBackgroundColors[300]}
    >
      <Container
        maxWidth="md"
      >
        <Box
          bgcolor='#fff'
          sx={{
            // backgroundColor: "#fff",
            px: 5,
            py: 5, 
            borderRadius: theme.spacing(1)
          }}
        >
          <Stack spacing={5}>
            <QandA
              question='How does GovGig work for Contractors?'
              answer={
                <ul>
                    <li>Register with GovGig to see the full details of the talent available to you.</li>
                    <li>Select the Talent Categories you are looking and zoom into a specific location (map).</li>
                    <li>Browse the profiles, view the credentials, read the history and scan the certifications for the qualified professionals.</li>
                    <li>Choose your compensation plan for the Candidates you are interested in.</li>
                    <li>Make connections to the Candidate and sell them on your opportunity.</li>
                    <li>Hire them and get your project onto the fast track of success and profitability!</li>
                  </ul>
              }
            />
            <QandA
              question='What kinds of candidates are in GovGig?'
              answer='Candidates who have worked in the Federal contracting profession in the past, and who have obtained the required certifications and background checks and are in good standing. These include Safety Officers, Project Managers, Executives, and other professionals.'
            />
            <QandA
              question='What kinds of qualifications do candidates have?'
              answer='Our vetted candidates are ensured to meet the specifications of your contract. Typically, our professionals have one or multiple University degrees with several years of experience and have the necessary certificates required by most Federal contracts. If a candidate does not have normative degrees, they will often have ample acceptable experience to match required specifications.'
            />
          </Stack>
        </Box>
      </Container>
    </TitleSection>
  )
}

export default EmployerFAQ
