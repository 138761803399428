import { useEffect, useState } from "react";
import { Box, Button, Grid } from "@material-ui/core"
import Product from "../../../model/stripe/Product"
import Stack from "../../Stack"
import PricingCard from "./PricingCard"
import CheckIcon from '@material-ui/icons/Check';
import get from "lodash.get";
import * as APITypes from "../../../API"

const PricingList = ({ 
  products, 
  dense,
  onSelectProduct 
}: {
  products: Product[]
  dense?: boolean
  onSelectProduct?(product: Product): void 
}) => {
  const [selectedProduct, setSelectedProduct] = useState<Product>()

  useEffect(() => {
    const selfService = products.find(product => get(product.metadata, 'jobPostType') === APITypes.JobPostType.SelfService)
    if (selfService) {
      setSelectedProduct(selfService)
      if (onSelectProduct) {
        onSelectProduct(selfService)
      }
    }
  }, [
    products 
  ])

  return (
    <Box
      sx={{
        // px: 2
      }}
    >
      <Grid 
        container 
        // direction={{ xs: "column", md: "row" }} 
        justifyContent="center" 
        alignItems="stretch" 
        // spacing={{ 
        //   xs: 3, 
        //   md: 2, 
        //   lg: 10 
        // }}
        spacing={2}
      >
        {products.map((product: Product, index: number) => {
          return (
            <Grid 
              key={`product.${product.id}`} 
              item 
              xs={12}
              md={6}
              // lg={3}
            >
              <PricingCard 
                index={index}
                product={product} 
                dense={dense}
              >
                <Box
                  sx={{
                    pt: 2, 
                    pb: 2
                  }}
                >
                  <Stack justifyContent="center">
                    <Button
                      startIcon={ selectedProduct && selectedProduct.id === product.id ? <CheckIcon /> : null }
                      variant="contained"
                      color="secondary"
                      onClick={() => {
                        setSelectedProduct(product)
                        if (onSelectProduct) {
                          onSelectProduct(product)
                        }
                      }}
                    >
                      { selectedProduct && selectedProduct.id === product.id ? "Selected" : "Select"} 
                    </Button>
                  </Stack>
                </Box>
              </PricingCard>
            </Grid>
          )
        })}
      </Grid>
    </Box>
  )
}

export default PricingList
