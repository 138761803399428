import { Box, Container } from "@material-ui/core"
import FormValidator from "./FormValidator"
import ProgressButton from "./ProgressButton"
import Stack from "../Stack"
import DialogPanelButtons from "../panel/DialogPanelButtons"

const SimpleForm = ({
  formId, 
  onSubmit, 
  children,
  buttonArea 
}: {
  formId: string 
  onSubmit: () => Promise<void> 
  children?: React.ReactNode
  buttonArea: React.ReactNode 
}) => {
  return (
    <FormValidator 
      id={formId}
      name={formId}
      onSubmit={onSubmit}
      autoComplete="off"
    >
      <Container
        maxWidth="sm"
      >
        <Stack
          direction="column"
          spacing={2}
        >
          {children}
        </Stack>
      </Container>
      { buttonArea }
    </FormValidator>
  )
}

export default SimpleForm
