import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Typography,
  withStyles,
  WithStyles
} from '@material-ui/core';
import { createStyles, Theme } from '@material-ui/core/styles';
import { Storage } from 'aws-amplify';
import { makeObservable, observable } from 'mobx';
import { inject, observer } from 'mobx-react';
import React, { ReactNode } from 'react';
import DocumentServiceAPI from '../../apis/DocumentServiceAPI';
import AddButton from '../../components/AddButton';
import CancelButton from '../../components/form/CancelButton';
import ProgressButton from '../../components/form/ProgressButton';
import Notify from '../../components/notify/Notify';
import Profile from '../../model/Profile';
import ProfileStore from '../../stores/ProfileStore';
import { getExt } from '../../stores/StoreUtilities';
import FileDropZone from './FileDropZone';
import Stack from '../../components/Stack';
import LoadingPanel from '../panel/LoadingPanel';
import DialogLoadingProgress from '../dialog/DialogLoadingProgress';
import CardValue from '../CardValue';
import IconicButton from '../controls/IconicButton';
import { Delete } from '@material-ui/icons';
import Confirm from '../confirm/Confirm';

const styles = (theme: Theme) => createStyles({
  dialogContentRoot: {
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2)
  },
  resumeButtons: {
    display: "flex",
    flexDirection: "row",
    justifyItems: "space-between",
  },
  progressButton: {
    minWidth: 80
  },
  dropZone: {
    width: "100%",
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
    border: "1px #ccc solid",
    borderRadius: theme.spacing(1),
    padding: theme.spacing(2),
    // paddingRight: theme.spacing(2)
  },
  dialogActionsLeft: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    textAlign: "left",
  },
  dialogActionsRight: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
  }
});

type IFileType = 'image/jpeg' | 'image/png' | 'application/pdf' | 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' | 'application/msword'
export type IKeyGenerator = (file?: File, ext?: string) => string
export type IResourceModificationHandler = (key: string, fileUrl: string) => Promise<void>
export type IFileStorageLevel = "private" | "protected" | "public"

export interface IFileDialogProps {
  fileKey: string | null 
  title?: string
  description?: ReactNode
  dropzoneText?: string
  acceptedFileTypes?: IFileType[]
  acceptedFileTypesText?: string 
  uploadDisabled?: boolean
  uploadButtonText?: ReactNode
  open: boolean
  isLoading: boolean 
  fileUploadOptional?: boolean 

  level: IFileStorageLevel
  keyGenerator: IKeyGenerator
  fileNameGetter(): string | null 
  children?: React.ReactNode
  
  onSaveWithFile: IResourceModificationHandler
  onDeleteFile: IResourceModificationHandler
  
  onSaveDialog(): Promise<void> // Save dialog in the case of no file updates. 
  onClose: () => any // (profile?: Profile) => any

  profileStore?: ProfileStore
  notify?: Notify
  documentServiceAPI?: DocumentServiceAPI
  confirm?: Confirm
}

@inject('profileStore', 'notify', 'documentServiceAPI', 'confirm')
@observer
class FileDialog extends React.Component<WithStyles<typeof styles> & IFileDialogProps> {
  @observable stagedFile: File | null = null;
  @observable isUploading = false;
  @observable isProcessing = false;
  @observable uploadError = '';
  @observable isInvalidFile = false;
  @observable isRemovingFile: boolean = false

  constructor(props: WithStyles<typeof styles> & IFileDialogProps) {
    super(props);
    makeObservable(this);
  }

  // helpers

  _handleSave = async () => {
    const { onSaveDialog, onClose } = this.props 
    this.isProcessing = true  
    if (this.stagedFile) {
      await this._handleUpload()
    } else {
      await onSaveDialog()
      onClose() 
    }
    this.isProcessing = false 
  }

  _clear = () => {
    this.stagedFile = null
    this.isUploading = false
    this.uploadError = ''
    this.isInvalidFile = false
  }

  _handleDrop = (acceptedTypes: string[], files: File[]) => {
    if (files.length > 0) {
      const file = files[0]
      if (acceptedTypes.includes(file.type)) {
        this.stagedFile = file
      } else {
        this.isInvalidFile = true
      }
    }
  }

  _handleUpload = async () => {
    const { 
      keyGenerator, 
      profileStore, 
      documentServiceAPI, 
      notify, 
      onSaveWithFile, 
      onClose, 
      level 
    } = this.props;

    let updatedResource

    this.isUploading = true

    if (!this.stagedFile || !profileStore) {
      // // update
      // const key = keyGenerator()
      // const url = String(await Storage.get(key, { level }))
      // updatedResource = await onSaveWithFile(key, url)
      // if (onClose) { onClose(updatedResource) }
      throw new Error("Could not upload file")
    }
    
    // } else {
      // create
      try {
        let ext = `.${getExt(this.stagedFile.name)}`

        // upload file to s3
        let key = keyGenerator(this.stagedFile, ext)
        let url = String(await profileStore.uploadFile(key, level, this.stagedFile))

        // TODO: This part only pertains to resume uploads, move it out of the dialog. 
        if (/\.docx|\.doc/ig.test(ext)) {
          // docx, needs to be converted
          try {
            const pdf = await documentServiceAPI!.convertDocToPDF(key, `${key.replace(ext, '')}.pdf`)
            if (pdf) {
              key = pdf.key
              url = String(await Storage.get(key, { level }))
            }
          } catch (err: any) {
            console.error(err)
            notify!.show('error', `Could not convert resume to pdf: ${err.message}`)
          }
        }

        await onSaveWithFile(key, url)

        onClose()

        this._clear()
      } catch (err: any) {
        this.uploadError = err.message
      }
    // }
    this.isUploading = false
  }

  _handleRemoveFile = async () => {
    const { 
      profileStore, 
      onDeleteFile, 
      keyGenerator, 
      level, 
      onClose 
    } = this.props

    if (!profileStore) { return }

    this.isRemovingFile = true
    try {
      const key = keyGenerator()
      const url = String(await Storage.get(key, { level }))

      await profileStore.removeFile(key, level)
      await onDeleteFile(key, url)
      onClose() 
    } catch (err) {
      console.error(err)
      throw new Error("Could not remove file")
    }
    this.isRemovingFile = false
  }

  renderFileName() {
    const {
      fileNameGetter
    } = this.props 

    let fileName

    if (this.stagedFile) {
      fileName = this.stagedFile.name 
    } else if (fileNameGetter) {
      fileName = fileNameGetter()
    }

    if (!fileName) {
      return null 
    }

    return (
      <CardValue label='File Name'>
        <Typography>{fileName}</Typography>  
      </CardValue>
    )
  }

  isSaveDisabled = () => {
    const {
      isLoading,
      uploadDisabled,
      fileKey,
      fileUploadOptional
    } = this.props

    if (fileKey) {
      // There's an existing key, no staged file needed.  
      return isLoading || this.isProcessing || this.isInvalidFile || uploadDisabled || this.isUploading 
    } else {
      if (fileUploadOptional && !this.stagedFile) {
        return isLoading || this.isProcessing || this.isInvalidFile || this.isUploading || uploadDisabled 
      } else {
        return isLoading || this.isProcessing || !this.stagedFile || this.isInvalidFile || uploadDisabled || this.isUploading 
      }
    }
  }

  isSaveProcessing = () => {
    const {
      keyGenerator
    } = this.props 
    // uploadDisabled || this.isUploading || (!keyGenerator() && (!this.stagedFile || this.isInvalidFile))
    if (this.stagedFile) {
      return this.isProcessing || this.isUploading || (!keyGenerator() && (!this.stagedFile || this.isInvalidFile))
    } else {
      return this.isProcessing
    }

    return false 
  }

  render() {
    const {
      classes,
      confirm,
      open,
      isLoading,
      keyGenerator,
      onDeleteFile,
      onClose,
      title = 'Add File',
      uploadDisabled, 
      uploadButtonText = 'Upload', 
      description,
      dropzoneText = 'Click here to browse for a file, or you can drag and drop a file here. Accepted types are JPEG, PNG, and PDF.',
      acceptedFileTypes = ['image/jpeg', 'image/png', 'application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'],
      acceptedFileTypesText = 'Upload file (.jpg, .png or .pdf)',
      children 
    } = this.props;

    return (
      <Dialog 
        open={open}
        fullWidth
      >
        <DialogTitle>
          {title}
        </DialogTitle>
        <DialogLoadingProgress isLoading={ isLoading || this.isUploading } />
        <DialogContent className={classes.dialogContentRoot}>
          { description && 
            <DialogContentText component="div">
              {description}
            </DialogContentText>
          }
          {children}
          <div className={classes.dropZone}>
            <Stack 
              direction="column"
              spacing={2}
              sx={{ 
                width: "100%" 
              }}
            >
              <Box>
                <Typography variant="body1" color="textPrimary" gutterBottom>
                  {acceptedFileTypesText}:
                </Typography>
                <FileDropZone
                  text={ dropzoneText }
                  accept={acceptedFileTypes}
                  onDrop={this._handleDrop.bind(this, acceptedFileTypes)}
                  onFileValidator={(valid: any) => this.isInvalidFile = valid}
                />
              </Box>
              { this.renderFileName() }
            </Stack>
          </div>
          {this.uploadError && (
            <Typography style={{ color: 'red' }}>
              Error: {this.uploadError}
            </Typography>
          )}
          {this.isInvalidFile && (
            <Typography color="error">
              Invalid file type.
            </Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Grid container justifyContent="space-between">
            <Grid item xs={4}>
              <AddButton
                icon="remove"
                tracking="removeFile"
                text={this.isRemovingFile ? 'Deleting...' : 'Delete'}
                click={() => {
                  confirm!.show(
                    "Delete File",
                    "Would you like to delete this file (this operation cannot be undone)?",
                    [ "Delete", "Cancel"],
                    () => {
                      // TODO: Make the confirm step easier to write. 
                      return new Promise((resolve, reject) => {
                        this._handleRemoveFile().then(() => { 
                          resolve(true) 
                        }).catch(() => {
                          reject(false)
                        })
                      })
                    }
                  )
                }}
                disabled={this.isRemovingFile}
              />
            </Grid>
            <Grid item xs={8}>
              <Stack justifyContent="flex-end">
                <CancelButton 
                  onClick={() => {
                    this._clear()
                    onClose()
                  }}
                />
                {/* <ProgressButton
                  variant="contained" 
                  size="medium" 
                  color="secondary"
                  type="submit" 
                  className={classes.progressButton}
                  onClick={this._handleUpload}
                  disabled={ isLoading || !this.stagedFile || this.isInvalidFile || uploadDisabled || this.isUploading }
                  processing={ uploadDisabled || this.isUploading || (!keyGenerator() && (!this.stagedFile || this.isInvalidFile)) }>
                  {uploadButtonText}
                </ProgressButton> */}
                <ProgressButton
                  variant="contained" 
                  size="medium" 
                  color="secondary"
                  type="submit"
                  className={classes.progressButton}
                  onClick={this._handleSave}
                  disabled={ this.isSaveDisabled() }
                  processing={ this.isSaveProcessing() }
                >
                  { this.stagedFile ? "Upload" : "Save" } 
                </ProgressButton>
              </Stack>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog >
    );
  }
}

export default withStyles(styles)(FileDialog);