import { Box, Grid } from "@material-ui/core"
import ServiceRequest from "../../model/ServiceRequest"
import JobPostMiniCard from "../../components/jobPosts/JobPostMiniCard"
import JobPost from "../../model/JobPost"

const ServiceRequestJobList = ({
  serviceRequest,
  onClickEditJobPost
}: {
  serviceRequest: ServiceRequest
  onClickEditJobPost: (jobPostId: string) => void 
}) => {
  const jobPosts = serviceRequest.jobPosts
  return (
    <Box
      width="100%"
    >
      <Grid 
        container 
        direction="row"
        justifyContent="flex-start"
        spacing={3}
      >
        { jobPosts.map((jobPost: JobPost) => {
          return (
            <Grid 
              key={jobPost.id}
              item
              xs={12}
              md={6}
            >
              <JobPostMiniCard 
                jobPost={jobPost} 
                onEdit={() => {
                  onClickEditJobPost(jobPost.id)
                }}
                onClickTitle={() => {
                  onClickEditJobPost(jobPost.id)
                }}
              />
            </Grid>
          )
        })}
      </Grid>
    </Box>
  )
}

export default ServiceRequestJobList 
