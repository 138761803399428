import { Box, createStyles, Grid, Theme, Typography, withStyles, WithStyles, withTheme, WithTheme, withWidth, WithWidth } from "@material-ui/core";
import { grey } from "@material-ui/core/colors";
import AddIcon from '@material-ui/icons/Add';
import { format } from 'date-fns';
import { makeObservable, observable } from "mobx";
import { inject, observer } from "mobx-react";
import React from 'react';
import IconicButton from "../../components/controls/IconicButton";
import DatePickerValidator from "../../components/form/DatePickerValidator";
import FormValidator from "../../components/form/FormValidator";
import Notify from "../../components/notify/Notify";
import Certification from "../../model/Certification";
import JobCandidate from "../../model/JobCandidate";
import Profile from "../../model/Profile";
import ProfileStore from "../../stores/ProfileStore";
import {
  createUUID,
  getErrorMessage,
  getISODateFromDateString,
  getISODateTimeFromDate,
  isoToLocalDate, isoToLocalDateString
} from "../../stores/StoreUtilities";
import UserStore from "../../stores/UserStore";
// import DocPreview from './doc-preview/DocumentPreview';
// import ProfileFileUploadDialog, { IDialogProps } from './doc-preview/ProfileFileUploadDialog';
import ProfileCard from "./ProfileCard";
import CertificationFilter from "../../components/filter/CertificationFilter";
import Stack from "../../components/Stack";
import FileDialog from "../../components/file/FileDialog";
import FilePreviewWithDialog from "../../components/file/FilePreviewWithDialog";
// import FileDialog from "../../components/dialog/FileDialog";
// import DocumentPreview from "../../components/DocumentPreview";

const styles = (theme: Theme) => createStyles({
  certTile: {
    // maxWidth: '15rem',
    borderColor: grey[300],
    borderWidth: 1,
    borderStyle: 'solid',
    borderRadius: theme.spacing(1),
    paddingTop: theme.spacing(2), 
    paddingBottom: theme.spacing(2), 
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    // margin: 0
    // padding: '.5rem',
    // margin: '.25rem',
  },
  publicNotice: {
    // marginTop: theme.spacing(0),
    padding: theme.spacing(1),
    backgroundColor: grey[500],
    color: theme.palette.primary.contrastText,
    borderRadius: 10,
    textAlign: "center"
  }
})

export interface IProfileCertificationsProps {
  profileStore?: ProfileStore
  userStore?: UserStore
  notify?: Notify
  profile?: Profile
  jobCandidate?: JobCandidate
  onChange?: (profile: Profile) => any
}

@inject('profileStore', 'userStore', 'notify')
@observer
class ProfileCertifications extends React.Component<WithStyles<typeof styles> & WithTheme & WithWidth & IProfileCertificationsProps> {
  @observable isLoading = false 
  @observable isAddDialogOpen = false
  @observable isEditDialogOpen = false
  @observable certTypeListOpen = false
  @observable isSavingCert = false
  @observable anchorEl: any

  // Other cert related fields
  @observable certificationId = ''
  @observable certificationType = ''
  @observable certificationIssueDate = ''
  @observable certificationExpDate = ''

  constructor(
    props: WithStyles<typeof styles> & WithTheme & WithWidth & IProfileCertificationsProps
  ) {
    super(props);
    makeObservable(this);
  }

  _handleSaveCert = async (key?: string, fileUrl?: string, certificationId?: string) => {
    const { profile, profileStore, notify } = this.props
    if (!profile || !profileStore || !notify) { return }
    try {

      this.isSavingCert = true
      let cert = (profile!.certifications || []).find(c => c.id === certificationId)
      const updateInput = {
        userId: profile.userId,
        profileId: profile.id,
        certificateKey: key,
        certificationType: this.certificationType,
        issuedAt: this.certificationIssueDate ? getISODateFromDateString(this.certificationIssueDate) : null,
        expiresAt: this.certificationExpDate ? getISODateFromDateString(this.certificationExpDate) : null,
        updatedAt: getISODateTimeFromDate(new Date()),
      }
      if (cert) {
        // modifiy existing cert
        cert = await profileStore.updateCertification({
          ...updateInput,
          id: cert.id,
        })
      } else {
        // create new cert
        cert = await profileStore.createCertification({
          ...updateInput,
        })
      }

      await this._refreshProfileCerts()
    } catch (err) {
      notify.show('error', getErrorMessage(err))
    }

    this.isSavingCert = false
  }

  _handleAddCert = () => {
    this.certificationId = ''
    this.certificationType = ''
    this.certificationIssueDate = ''
    this.certificationExpDate = ''
    this.isAddDialogOpen = true
  }

  _handleOpenEditCert = (certification: Certification) => {
    this.certificationId = certification.id 
    this.certificationType = certification.certificationType
    this.certificationIssueDate = certification.issuedAt ? isoToLocalDateString(certification.issuedAt, "MM/dd/yyyy") : ''
    this.certificationExpDate = certification.expiresAt ? isoToLocalDateString(certification.expiresAt, "MM/dd/yyyy") : ''
    this.isEditDialogOpen = true 
  }

  _handleRemoveCert = async (certificationId: string) => {
    const { profileStore, notify } = this.props
    if (!profileStore || !notify) { return }
    try {
      await profileStore.deleteCertification(certificationId)
      await this._refreshProfileCerts()
      this._handleClose()
    } catch (err) {
      notify.show('error', getErrorMessage(err))
    }
  }

  _refreshProfileCerts = async () => {
    const { profile, profileStore, onChange } = this.props
    if (onChange) {
      const freshProfile = await profileStore!.getProfile(profile!.id)
      onChange(freshProfile!)
    }
  }

  _handleClose = (profile?: Profile) => {
    if (this.isAddDialogOpen) { this.isAddDialogOpen = false }
    if (this.isEditDialogOpen) { this.isEditDialogOpen = false }
    
    this.certificationId = ''
    this.certificationType = ''
    this.certificationIssueDate = ''
    this.certificationExpDate = ''

    if (profile && this.props.onChange) {
      this.props.onChange(profile)
    }
  }

  fileFolder = () => {
    const { profile } = this.props
    return `users/${profile!.userId}/profile/certifications`
  }

  renderStaticFields = () => {
    return (
      <FormValidator autoComplete="off" name="editForm" id="editForm">
        <CertificationFilter
          name="certification"
          label="Certification"
          variant="standard"
          value={this.certificationType}
          freeSolo={true}
          fullWidth
          required
          helperText="Choose from the pop-up list or enter another certification name"
          onSelectCertificationType={(value?: string) => {
            this.certificationType = value!
          }}
        />
        <DatePickerValidator
          disableToolbar fullWidth autoOk required
          variant="inline"
          format="MM/dd/yyyy"
          placeholder="MM/dd/yyyy"
          margin="none"
          id="issuedAt"
          label="Issue Date *"
          name="issuedAt"
          value={this.certificationIssueDate}
          onChange={({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => this.certificationIssueDate = value}
        />
        <DatePickerValidator
          disableToolbar fullWidth autoOk
          variant="inline"
          format="MM/dd/yyyy"
          placeholder="MM/dd/yyyy"
          margin="none"
          id="expiresAt"
          label="Expiration Date"
          name="expiresAt"
          value={this.certificationExpDate}
          onChange={({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => this.certificationExpDate = value}
        />
      </FormValidator>
    )
  }

  renderAddDialog = () => {
    const { profile } = this.props 
    const level = 'public'

    return ( 
      <FileDialog
        fileKey={null}
        fileUploadOptional
        isLoading={this.isLoading}
        title='Add Certification'
        uploadButtonText='Upload'
        uploadDisabled={ this.isSavingCert || !this.certificationType || !this.certificationIssueDate }
        open={this.isAddDialogOpen}
        onSaveWithFile={this._handleSaveCert}
        onClose={this._handleClose} 
        keyGenerator={(file?: File) => file ? `${this.fileFolder()}/${createUUID()}-${file.name}` : ''}
        fileNameGetter={() => {
          return null 
        }}
        level={level}
        description={this.renderFileDialogDescription()}
        onSaveDialog={async () => {
          // TODO: The dialog should validate requred fields automatically. We could use AppDialog instead. 
          if (this.certificationType && this.certificationIssueDate) {
            await this._handleSaveCert()
          }
        }}
        onDeleteFile={async (key: string, fileUrl: string) => {
          // No operation needed here b/c we haven't saved a file yet. 
        }}
      >
        { this.renderStaticFields() }
      </FileDialog>
    )
  }

  renderFileDialogDescription = () => {
    return (
      <>
        <Typography gutterBottom>
          Add relevant certifications applicable to your field. You can optionally upload an image or file of your certification. 
        </Typography>
        <ul>
          <li>
            For the Construction industry, examples include Construction Quality Management (CQM-C) and OSHA 30-Hour.
          </li>
          <li>
            For the Information Technology industry, an example is Certified Information Systems Security Professional (CISSP).
          </li>
          <li>
            For the Physical Security industry, an example is Physical Security Professional (PSP).
          </li>
        </ul>
      </>
    )
  }

  renderFilePreview = (certification: Certification) => {
    const { profile } = this.props 

    const level = "public"

    return (
      <FilePreviewWithDialog 
        onClickOpenDialog={() => {
          this._handleOpenEditCert(certification)
        }}
        filePreviewProps={{
          level: level,
          fileKey: certification.certificateKey,
          emptyLabel: "Add Certification Image or PDF",
          label: "View Certification",
          onClickEmpty: () => { this.isEditDialogOpen = true }
        }}
        fileDialogProps={{
          fileKey: certification.certificateKey ?? null, 
          fileUploadOptional: true, 
          isLoading: this.isLoading,
          title: 'Edit Certification',
          uploadButtonText: 'Upload',
          uploadDisabled: this.isSavingCert || !this.certificationType || !this.certificationIssueDate,
          open: this.isEditDialogOpen && this.certificationId === certification.id,
          onSaveDialog: async () => { 
            if (this.certificationType && this.certificationIssueDate) {
              await this._handleSaveCert(undefined, undefined, certification.id)
            }
          },
          onSaveWithFile: (key: string, fileUrl: string) => this._handleSaveCert(key, fileUrl, certification.id),
          onDeleteFile: () => this._handleRemoveCert(certification.id),
          onClose: () => this._handleClose(), 
          keyGenerator: (file?: File) => file ? `${this.fileFolder()}/${createUUID()}-${file.name}` : '',
          fileNameGetter: () => {
            if (certification.certificateKey) {
              const chunks = certification.certificateKey.split(`${this.fileFolder()}/`)
              return chunks[chunks.length - 1]
            }
            return null 
          },
          level: level,
          description: this.renderFileDialogDescription(),
          children: this.renderStaticFields()
        }}
      />
    )
  }

  renderCertification = (certification: Certification, index: number) => {
    const {
      classes,
      jobCandidate, 
      profile, 
      userStore
    } = this.props 

    if (!profile) {
      return null 
    }

    const user = userStore!.user
    const showPreview = profile.getIsUnlocked(user, jobCandidate)

    return (
      <Grid 
        item 
        key={index} 
        xs={12}
        md={4}
      >
        <Box
          className={classes.certTile} 
          sx={{
            height: "100%",
            width: "100%"
          }}
        >
          <Stack
            direction="column" 
            justifyContent="space-between"
            alignItems="flex-start"
            spacing={2}
            wrap="nowrap"
          >
            <Stack
              direction="column"
              spacing={2}
              sx={{
                height: "100%"
              }}
            >
              <Typography 
                variant="body2"
                align="left"
              >
                {certification.issuedAt ? format(isoToLocalDate(certification.issuedAt), 'M/d/yy') : ''}
                {certification.expiresAt ? ` - ${format(isoToLocalDate(certification.expiresAt), 'M/d/yy')}` : ''}
              </Typography>
              <Box
                sx={{
                  minHeight: "70px"
                }}
              >
                <Typography 
                  variant="h6"
                  align="left"
                >
                  {certification.certificationType}
                </Typography>
              </Box>
            </Stack>
            { showPreview ?  
              this.renderFilePreview(certification)
              : 
              <Box 
                bgcolor={grey[500]}
                sx={{ 
                  height: 194 
                }}
              >
                <Typography variant="body1" className={classes.publicNotice}>
                  You can view this certificate file when the candidate is unlocked.
                </Typography>
              </Box>
            }
          </Stack>
        </Box>
      </Grid>
    )
  }

  render() {
    const { classes, profile, userStore, onChange } = this.props
    
    if (!profile) {
      return null
    }

    const isAuthenticated = userStore!.isAuthenticated
    
    let addButton
    if (onChange) {
      addButton =
        <IconicButton onClick={this._handleAddCert}>
          <AddIcon />
        </IconicButton>
    }

    const showInstructions = profile!.certifications.length === 0 && onChange 
    
    return (
      <Box mb={1}>
        <ProfileCard
          title="Certifications"
          button={addButton}
        >
          { this.renderAddDialog() }

          <Grid 
            container
            direction="row"
            spacing={2}
          >
            {showInstructions &&
              <Grid item xs={12}>
                <Typography gutterBottom>
                  Add relevant certifications applicable to your field. You can optionally upload an image of your certification. 
                </Typography>
              </Grid>
            }
            {isAuthenticated && profile!.certifications.map((certification, index) => {
              return this.renderCertification(certification, index)
            })}
            {!isAuthenticated && profile!.certifications.length > 0 &&
              <Grid item xs={12} className={classes.publicNotice}>
                <Typography variant="body1">
                  {profile!.certifications.length} items available when you join or login...
                </Typography>
              </Grid>
            }
          </Grid>
        </ProfileCard>
      </Box>
    )
  }
}

export default withTheme(withStyles(styles)(withWidth()(ProfileCertifications)))
