import Location from "aws-sdk/clients/location";
import awsconfig from '../aws-exports';
import {Auth} from "aws-amplify";
import {ICredentials, Signer} from "@aws-amplify/core";
import Geohash from "latlon-geohash";
import {LngLatBounds} from "mapbox-gl";
import {differenceInMilliseconds} from "date-fns";
import Logger from "../components/Logger";

class LocationAPI {

  mapName: string = "EsriStreetMap"
  placeIndex: string = "EsriPlaceIndex"
  credentials?: ICredentials

  init = async () => {
    try {
      this.credentials = await Auth.currentCredentials();
      if (this.credentials && this.credentials.expiration) {
        const expires = new Date(this.credentials.expiration)
        Logger.debug(`Location credentials initialized (expire at ${expires.toLocaleString()})`)
        const timeLeft = differenceInMilliseconds(expires, new Date())
        // Set to renew
        setTimeout(this.init, timeLeft)
      } else {
        // Set to renew after an hour time period. 
        setTimeout(this.init, 60 * 60000)
      }
    } catch (error) {
      Logger.error('Could not init LocationAPI', error)
    }
  }

  createClient = async () => {
    const credentials = await Auth.currentCredentials();
    const client = new Location({
      credentials,
      region: awsconfig.aws_project_region,
    });
    return client;
  }

  transformRequest = () => (url: string, resourceType: string) => {
    // Resolve to an AWS URL
    if (resourceType === "Style" && !url?.includes("://")) {
      url = `https://maps.geo.${awsconfig.aws_project_region}.amazonaws.com/maps/v0/maps/${url}/style-descriptor`;
    }

    // Only sign AWS requests (with the signature as part of the query string)
    if (url?.includes("amazonaws.com") && this.credentials) {
      return {
        url: Signer.signUrl(url, {
          access_key: this.credentials.accessKeyId,
          secret_key: this.credentials.secretAccessKey,
          session_token: this.credentials.sessionToken,
        })
      };
    }

    // Don't sign
    return { url: url || "" };
  };

  geoHashBoundsCalculate = (bounds: LngLatBounds): string[] => {
    // This determines up to a 9x9 hash grid based on the center of the view bounds and including up to
    // the 8 adjacent cells if they are in view.  It returns from 1-9 geohashes

    const geohashes: string[] = []

    const precision = 3
    const north = bounds.getNorth()
    const east = bounds.getEast()
    const south = bounds.getSouth()
    const west = bounds.getWest()
    const center = bounds.getCenter()

    const geohash = this.geoHashEncode(center.lat, center.lng, precision)
    geohashes.push(geohash)

    const hashBounds = this.geoHashBounds(geohash)

    // West
    if (hashBounds.sw.lon > west) {
      const westHash = this.geoHashAdjacent(geohash, "W")
      geohashes.push(westHash)

      // Northwest
      if (hashBounds.ne.lat < north) {
        const nwHash = this.geoHashAdjacent(westHash, "N")
        geohashes.push(nwHash)
      }

      // Southwest
      if (hashBounds.sw.lat > south) {
        const swHash = this.geoHashAdjacent(westHash, "S")
        geohashes.push(swHash)
      }
    }

    // East
    if (hashBounds.ne.lon < east) {
      const eastHash = this.geoHashAdjacent(geohash, "E")
      geohashes.push(eastHash)

      // NorthEast
      if (hashBounds.ne.lat < north) {
        const neHash = this.geoHashAdjacent(eastHash, "N")
        geohashes.push(neHash)
      }

      // SouthEast
      if (hashBounds.sw.lat > south) {
        const seHash = this.geoHashAdjacent(eastHash, "S")
        geohashes.push(seHash)
      }
    }

    // North
    if (hashBounds.ne.lat < north) {
      const northHash = this.geoHashAdjacent(geohash, "N")
      geohashes.push(northHash)
    }

    // South
    if (hashBounds.sw.lat > south) {
      const southHash = this.geoHashAdjacent(geohash, "S")
      geohashes.push(southHash)
    }

    return geohashes
  }

  geoHashEncode = (lat: number, lon: number, precision?: number) => {
    return Geohash.encode(lat, lon, precision)
  }

  geoHashDecode = (geohash: string) => {
    return Geohash.decode(geohash)
  }

  geoHashBounds = (geohash: string) => {
    return Geohash.bounds(geohash)
  }

  geoHashAdjacent = (geohash: string, direction: string) => {
    return Geohash.adjacent(geohash, direction)
  }

  geoHashNeighbours = (geohash: string) => {
    return Geohash.neighbours(geohash)
  }
}

export default LocationAPI