import * as React from 'react'
import Page from '../../components/page/Page'
import {RouteComponentProps} from "@reach/router";
import MarginRow from "../../components/page/MarginRow";
import {inject, observer} from "mobx-react";
import {
  Box,
  createStyles,
  Grid,
  Theme,
  Typography,
  withStyles,
  WithStyles,
  withTheme,
  WithTheme,
  withWidth,
  WithWidth
} from "@material-ui/core";
import UserStore from "../../stores/UserStore";
import AccountStore from "../../stores/AccountStore";
import {makeObservable, observable, when} from "mobx";
import Progress from "../../components/Progress";
import flag from '../../images/flag.jpg'

const styles = (theme: Theme) => createStyles({
  root: {
    width: '100%',
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.main,
    backgroundImage: `linear-gradient(145deg, rgba(25,53,106,0.95) 0%, rgba(215,35,34,0.95) 100%), url(${flag})`,
    backgroundSize: 'cover',
  },
  marginRow: {
    height: '100%'
  },
  content: {
    flexGrow: 1,
    justifyContent: 'space-around',
    alignItems: 'center',
    overflow: "hidden",
    [theme.breakpoints.down('xs')]: {
      paddingTop: theme.spacing(5),
    },
    [theme.breakpoints.up('sm')]: {
      height: "100%",
    },
    width: '100%',
  },

})

interface IAccountPendingPageProps {
  userStore?: UserStore
  accountStore?: AccountStore
  progress?: Progress
}

@inject("userStore", "accountStore", "progress")
@observer
class AccountPendingPage extends React.Component<WithStyles<typeof styles> & RouteComponentProps & IAccountPendingPageProps & WithTheme & WithWidth> {

  @observable isLoading = true

  constructor(
    props: WithStyles<typeof styles> & RouteComponentProps & IAccountPendingPageProps & WithTheme & WithWidth
  ) {
    super(props);
    makeObservable(this);
  }

  componentDidMount () {
    const { userStore, progress } = this.props

    this.isLoading = true
    progress!.show("FindJobs")
    when(
      // once
      () => !userStore!.isLoading,
      // then
      async () => {
        this.isLoading = false
        progress!.hide("FindJobs")
      }
    )
  }


  render() {
    const { classes } = this.props

    return (
      <Page title="Account Pending">
        <Box className={classes.root}>
        <MarginRow 
          // style={{height: '100%'}} // FIXME: Do we need this style setting? 
        >
          <Box height={"100%"} >
            <Grid container direction="column" justifyContent="center" className={classes.content}>
              <Grid item>
                <Box pl={1} mt={-2}>
                  <Typography variant="h1" align="center">
                    Welcome to GovGig!
                  </Typography>
                </Box>
                <Box pl={1} pt={1}>
                  <Typography variant="body1" align="center">
                    For security, all new accounts must be reviewed.  You will be contacted soon regarding activation.
                  </Typography>
                </Box>
                <Box pl={1} pt={1}>
                  <Typography variant="body1" align="center">
                    If you have any questions, please email <a href="mailto:staffing@govgig.us" style={{color: "white"}}>staffing@govgig.us</a>.
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </MarginRow>
        </Box>
      </Page>
    )
  }
}

export default withTheme((withStyles(styles)(withWidth()(AccountPendingPage))))

