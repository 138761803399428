import {
  Box,
  Button,
  createStyles,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormGroup,
  Grid,
  Paper,
  TextField,
  Theme,
  Typography,
  withStyles,
  WithStyles,
  withWidth
} from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import { WithWidth } from '@material-ui/core/withWidth';
import { RouteComponentProps } from '@reach/router';
import { Auth } from "aws-amplify";
import { observable, makeObservable, computed } from "mobx";
import { inject, observer } from 'mobx-react';
import React from 'react';
import {
  AgreementType,
  CreateAgreementInput, SubjectType,
  UpdateUserInput,
  UserRole, UserStatus
} from "../../API";
import Confirm from "../../components/confirm/Confirm";
import ControlTower, { Routes } from '../../components/ControlTower';
import CheckboxValidator from "../../components/form/CheckboxValidator";
import DialogButton from "../../components/form/DialogButton";
import FormValidator from "../../components/form/FormValidator";
import ProgressButton from "../../components/form/ProgressButton";
import TextFieldValidator from "../../components/form/TextFieldValidator";
import Logger from "../../components/Logger";
import Notify from "../../components/notify/Notify";
import MarginRow from '../../components/page/MarginRow';
import Page from "../../components/page/Page";
import Tracking from "../../components/Tracking";
import {getErrorMessage, getISODateTime, phoneToE164Format} from '../../stores/StoreUtilities';
import UserStore, { CognitoAttribute, UserStoreConstants } from "../../stores/UserStore";
import {ActivityType} from "../../model/UserActivity";
import AccountStore from '../../stores/AccountStore';

const styles = (theme: Theme) => createStyles({
  submitButtonContainer: {
    paddingTop: theme.spacing(2),
  },
  submitButton: {
    color: "#fff",
  },
  formGroupTerms: {
    display: "flex",
    justifyContent: "flex-start",
    flexWrap: "nowrap",
    margin: 0,
    padding: 0,
    maxHeight: 40,
  },
  termsCheckbox: {
    flex: 0,
    width: 80,
  },
  termsLabel: {
    flex: 1,
    alignSelf: "center",
    color: '#252525',
    fontSize: '13px',
    paddingTop: 0,
    lineHeight: 1.2,
  },
  firstName: {
    [theme.breakpoints.up('sm')]: {
      paddingRight: theme.spacing(2)
    }
  },
  formGroupRow: {
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "nowrap"
  },
  formGroupField: {
    flexGrow: 1,
  },
  formGroupSpacing: {
    flexGrow: 0,
    width: 10,
  },
  link: {
    color: theme.palette.primary.main,
  },
  continueButton: {
    marginBottom: 20,
    color: "#FFF"
  },
  progressButton: {
    color: theme.palette.primary.main,
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  instructions: {
    marginTop: 10
  },
  stepContent: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    width: "100%"
  },
  terms: {
    height: "calc(50vh)",
    width: "100%",
    marginBottom: theme.spacing(1)
  },
  iframe: {
    width: "100%",
    height: "100%",
    border: "none",
    overflowY: "scroll"
  },
  dialogTitle: {
    padding: theme.spacing(2), 
    borderRadius: "10px 10px 0px 0px"
  },
  message: {
    color: theme.palette.error.main,
    fontSize: 18,
    marginTop: 10
  },
});


interface IRegisterPageProps {
  accountId?: string
  userId?: string
  role?: UserRole 
  email?: string
  userStore?: UserStore
  accountStore?: AccountStore
  govGigAPI?: any
  notify?: Notify
  confirm?: Confirm
}

interface ICognitoError {
  __type: string;
  code: string;
  message: string;
}

enum ApplyStep {
  Promo,
  Application,
  TermsOfUse,
  PrivacyPolicy,
  Verification
}

@inject("accountStore", "userStore", "profileStore", "govGigAPI", "notify", "confirm")
@observer
class RegisterPage extends React.Component<WithStyles<typeof styles> & RouteComponentProps & IRegisterPageProps & WithWidth, {}> {
  @observable accountId?: string
  @observable userId?: string
  @observable role?: UserRole 
  @observable email?: string 

  @observable step = ApplyStep.Application
  @observable showTerms = false
  @observable showPrivacy = false
  @observable isSubmitted = false
  @observable forgotPassword = false
  @observable showTermsOfUse = false
  @observable isProcessing = false
  @observable verifyAttr = CognitoAttribute.EMAIL

  private cognitoUser?: any
  
  private _message = ""

  constructor(
    props: WithStyles<typeof styles> & RouteComponentProps & IRegisterPageProps & WithWidth
  ) {
    super(props);
    makeObservable(this);
  }

  componentDidMount () {
    const { accountId, userId, role, email } = this.props
    if (accountId && userId && role && email) {
      this.accountId = accountId
      this.userId = userId
      this.role = role 
      this.email = email
    } else {
      this.error = "Invalid registration link"
    }
  }

  set message(msg: string) {
    this._message = msg
    if (msg !== "") {
      this.props.notify!.show("info", msg)
    } else {
      this.props.notify!.close()
    }
  }

  get message() {
    return this._message
  }

  private _error = ""
  set error(err: string) {
    this._error = err
    if (err !== "") {
      this.props.notify!.show("error", err)
    } else {
      this.props.notify!.close()
    }
  }
  get error() {
    return this._error
  }

  state = {
    termsCheckbox: false,
    privacyCheckbox: false,
    firstName: "",
    lastName: "",
    password: "",
    phone: "",
    verificationCode: ""
  }

  @computed 
  get validInfo(): boolean {
    return this.accountId !== undefined
      && this.userId !== undefined
      && this.email !== undefined
      && this.role !== undefined
  }

  render() {

    return (
      <Page title="Apply">
        <MarginRow>
          <Box 
            py={3}
            px={1}
          >
            <Grid container justifyContent='center'>
              <Grid item xs={12} sm={6}>
                { this.validInfo ?
                  this.renderForms() : 
                  this.renderWarning()
                }
              </Grid>
            </Grid>
          </Box>
        </MarginRow>
      </Page>
    )
  }

  renderForms = () => {
    const { classes } = this.props;
    return (
      <Paper elevation={3}>
        <Grid container justifyContent="center">
          <Grid item xs={12}>
            <DialogTitle
              className={ classes.dialogTitle }
            >
              Complete GovGig Registration
            </DialogTitle>
          </Grid>
          {this.step === ApplyStep.Application && this.renderSignupForm()}
          {this.step === ApplyStep.TermsOfUse && this.renderTermsOfUse()}
          {this.step === ApplyStep.PrivacyPolicy && this.renderPrivacyPolicy()}
          {this.step === ApplyStep.Verification && this.renderVerifyForm()}
        </Grid>
      </Paper>
    )
  }

  renderWarning = () => {
    const { classes } = this.props

    return (
      <Paper>
        <DialogTitle
          id="login-dialog-title"
          className={ classes.dialogTitle }
        >
          Complete GovGig Registration
        </DialogTitle>
        <DialogContent>
          <Box pb={1}>
            <DialogContentText className={classes.message}>
              {"Invalid registration link"}
            </DialogContentText>
            <Typography gutterBottom>
              If you were invited to join GovGig, please click on the invitation link you should have received via email.
            </Typography>
            <Typography gutterBottom>
              If you did not receive an invite, or have another issue, please contact GovGig.
            </Typography>
          </Box>
        </DialogContent>
      </Paper>
    )
  }

  renderSignupForm = () => {
    const { classes } = this.props;

    return (
      <div className={classes.stepContent}>
        <FormValidator 
          id="registerSignUpForm"
          name="registerSignUpForm"
          onSubmit={this.handleSignUp}
          autoComplete="on"
        >
          <FormGroup row classes={{ row: classes.formGroupRow }}>
            <TextFieldValidator
              margin="normal"
              name="firstName"
              label="First Name"
              type="text"
              value={this.state.firstName}
              validators={{ required: true }}
              onChange={this.changeHandler}
              className={classes.formGroupField}
            />
            <div className={classes.formGroupSpacing} />
            <TextFieldValidator
              margin="normal"
              name="lastName"
              label="Last Name"
              type="text"
              value={this.state.lastName}
              validators={{ required: true }}
              onChange={this.changeHandler}
              className={classes.formGroupField}
            />
          </FormGroup>
          <TextFieldValidator
            margin="normal"
            name="email"
            label="Email Address"
            type="email"
            validators={{ required: true, isEmail: null }}
            value={this.email}
            onChange={this.changeHandler}
            fullWidth
            className={classes.formGroupField}
            disabled
          />
          <TextFieldValidator
            margin="normal"
            name="password"
            label="Password"
            type="password"
            validators={{ required: true, isStrongPassword: 3 }}
            value={this.state.password}
            autoComplete="new-password"
            onChange={this.changeHandler}
            fullWidth
            helperText="8+ chars + 1 digit or symbol"
            className={classes.formGroupField}
          />
          <TextFieldValidator
            margin="dense"
            name="phone"
            label="Mobile Phone"
            type="text"
            validators={{ required: true, isMobilePhone: null }}
            onChange={this.changeHandler}
            fullWidth
            value={this.state.phone}
          />
          <div className={classes.submitButtonContainer}>
            <ProgressButton fullWidth={true} variant="contained" size="large" color="secondary"
              type="submit" className={classes.submitButton} processing={this.isProcessing}>
              Continue
            </ProgressButton>
            <Button fullWidth variant="text" color="primary" type="button" onClick={this.handleCancel}>Cancel</Button>
          </div>
        </FormValidator>
      </div>
    )
  }

  renderVerifyForm() {
    const { classes } = this.props;

    return (
      <div className={classes.stepContent}>
        <FormValidator 
          id="registerSignUpVerificationCodeForm"
          name="registerSignUpVerificationCodeForm"
          onSubmit={this.handleVerify} 
          autoComplete="on"
        >
          <Typography variant="body2" paragraph={true} className={classes.instructions}>
            Please check your email for the verification code.
          </Typography>
          <TextFieldValidator
            margin="dense"
            name="verificationCode"
            label="Verification Code"
            type="text"
            value={this.state.verificationCode}
            validators={{ required: true, matches: "^\\d{6}$" }}
            onChange={this.changeHandler}
            fullWidth
          />
          <DialogButton variant="tertiary" onClick={this.onResendCode}>
            Resend verification code
          </DialogButton>
          <div className={classes.submitButtonContainer}>
            <Button fullWidth={true} variant="contained" color="secondary"
              type="submit" style={{ color: "#fff" }} disabled={this.isProcessing}>
              Continue
              {this.isProcessing && <CircularProgress size={24} className={classes.progressButton} />}
            </Button>
            <Button fullWidth variant="text" color="primary" type="button" onClick={this.handleCancel}>Cancel</Button>
          </div>
        </FormValidator>
      </div>)
  }

  renderTermsOfUse() {
    const { classes } = this.props

    return (
      <div className={classes.stepContent}>
        <FormValidator 
          id="registerSignUpTermsOfUseForm"
          name="registerSignUTermsOfUseForm"
          onSubmit={this.handleTermsOfUse} 
          autoComplete="on"
        >
          <Typography variant="body2" paragraph={true} className={classes.instructions}>
            Please review and accept the GovGig Terms of Use.
          </Typography>
          <div className={classes.terms}>
            <iframe src={Routes.terms} title="Terms Of Service" className={classes.iframe}>
            </iframe>
          </div>
          {this.renderTermsCheckbox("termsCheckbox", "I accept the GovGig.us Terms of Use.", true)}
          <div className={classes.submitButtonContainer}>
            <Button fullWidth={true} variant="contained" color="secondary"
              type="submit" style={{ color: "#fff" }}>
              Continue
            </Button>
            <Button fullWidth variant="text" color="primary" type="button" onClick={this.handleCancel}>Cancel</Button>
          </div>
        </FormValidator>
      </div>
    )
  }

  renderPrivacyPolicy() {
    const { classes } = this.props

    return (
      <div className={classes.stepContent}>
        <FormValidator 
          id="registerSignUpPrivacyPolicyForm"
          name="registerSignUpPrivacyPolicyForm"
          onSubmit={this.handlePrivacyPolicy} 
          autoComplete="on"
        >
          <Typography variant="body2" paragraph={true} className={classes.instructions}>
            Please review and accept the GovGig Privacy Policy.
          </Typography>
          <div className={classes.terms}>
            <iframe src={Routes.privacy} title="Privacy Policy" className={classes.iframe}>
            </iframe>
          </div>
          {this.renderTermsCheckbox("privacyCheckbox", "I accept the GovGig.us Privacy Policy.", true)}
          <div className={classes.submitButtonContainer}>
            <ProgressButton fullWidth={true} variant="contained" size="large" color="secondary"
              type="submit" className={classes.submitButton} processing={this.isProcessing}>
              Continue
            </ProgressButton>
            <Button fullWidth variant="text" color="primary" type="button" onClick={this.handleCancel}>Cancel</Button>
          </div>
        </FormValidator>
      </div>
    )
  }

  renderTermsCheckbox = (name: string, label: any, required: boolean) => {
    const { classes } = this.props

    return (
      <FormGroup row classes={{ row: classes.formGroupTerms }}>
        <div className={classes.termsCheckbox}>
          <CheckboxValidator name={name} color="primary"
            value="checked" required={required}
            checked={this.state[name]}
            onChange={this.changeHandler} />
        </div>
        <div className={classes.termsLabel}>
          {label}
        </div>
      </FormGroup>
    )
  }

  scrollToTop() {
    window.scrollTo({top: 0, behavior: 'smooth'})
  }

  handleSignUp = async () => {
    const values = this.state
    const { userStore } = this.props

    this.message = ""
    this.error = ""

    this.isProcessing = true

    // Check for an existing Cognito user by email
    this.cognitoUser = await Auth.signIn(this.email!, values.password)
      .catch((err: any) => {
        if (err.code === UserStoreConstants.NOT_AUTHORIZED_EXCEPTION) {
          this.error = "A user with this email already exists. If you are attempting to register again, be sure to use the same password."
        } else if (err.code !== UserStoreConstants.USER_NOT_FOUND_EXCEPTION && err.message !== UserStoreConstants.USER_NOT_FOUND) {
          this.error = err.message
          Tracking.event({ action: "SignIn Error", label: this.error })
        }
      })

    if (this.cognitoUser) {
      // The username/password already exists.  Verify that the registration process was completed.
      const user = await userStore!.getUser(this.cognitoUser.username)
      if (user) {
        // TODO: Check for necessary agreements
        if (user.agreements && user.agreements.length >= 2) {
          this.error = "You have already registered.  Please login to use GovGig."
          Auth.signOut()
          this.isProcessing = false 
          return
        }
      }

      // Send to TermsOfService step
      this.userId = this.cognitoUser.username
      this.step = ApplyStep.TermsOfUse
      this.isProcessing = false
      return
    }

    if (this.error) {
      this.isProcessing = false
      return
    }

    // Sign up and send email verification

    this.cognitoUser =
      await userStore!.signUp(this.userId!, values.password, this.email!, undefined, this.accountId, this.role!)
        .catch(async (result: ICognitoError) => {
          Logger.debug(`SignUp.signUp(${this.userId}) error`, result)
          if (result.message === UserStoreConstants.USER_ALREADY_EXISTS) {
            // Try completing verification
            await userStore!.resendSignUp(this.userId!)
              .catch((error: ICognitoError) => {
                this.error = result.message
              })
          } else {
            this.error = result.message
          }
        });

    this.isProcessing = false

    if (!this.error) {
      this.isSubmitted = true
      this.step = ApplyStep.Verification
      this.scrollToTop()
    }

  }

  handleVerify = async () => {
    const values = this.state
    this.message = ""
    this.error = ""
    const { accountStore, userStore } = this.props

    if (this.forgotPassword) {
      // Handle verification code for password reset
      userStore!.forgotPasswordSubmit(this.email!, this.state.verificationCode, this.state.password)
        .then((result: any) => {
          Logger.debug("forgotPasswordSubmit succeeded")
        })
        .catch((err: any) => {
          this.error = err.message
          Tracking.event({ action: "Forgot Password Error", label: this.error })
        })
    } else {
      // Handle verification code for signup
      this.isProcessing = true

      await userStore!.confirmSignUp(this.userId!, values.verificationCode)
        .catch((err: Error) => {
          this.error = getErrorMessage(err)
          this.isProcessing = false
        })

      if (this.error) {
        return
      }
    }

    Logger.debug("signInUser")
    await userStore!.signIn(this.userId!, this.state.password)
      .catch((error: any) => {
        this.isProcessing = false
        Logger.debug("RegisterPage.handleVerify signIn error", error)
        if (error.code === UserStoreConstants.NOT_AUTHORIZED_EXCEPTION && !this.forgotPassword) {
          // Password doesn't match original.  Reset password
          userStore!.forgotPassword(this.email!).then((result: any) => {
            this.forgotPassword = true
            this.state.verificationCode = ""
            this.error = "Password does not match. Please check your email for a password reset verification code"
          })
            .catch((forgotPasswordErr: any) => {
              this.message = forgotPasswordErr.message
            })
        } else if (error.message !== UserStoreConstants.USER_NOT_FOUND) {
          this.error = error.message
        }
      });

    if (this.error) {
      return
    }

    const user = userStore!.user! 
    let account = user.account
    if (!account) {
      account = await accountStore!.getAccount(user.accountId)
    }
    accountStore!.init(account)

    this.step = ApplyStep.TermsOfUse
    this.scrollToTop()

    this.isProcessing = false
  }

  handleTermsOfUse = () => {
    this.step = ApplyStep.PrivacyPolicy
    this.scrollToTop()
  }

  handlePrivacyPolicy = async () => {
    await this.updateUser()
  }

  handleCancel = () => {
    ControlTower.route(Routes.home)
  }

  changeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const name = event.target.name
    if (name === "eligibleCheckbox" || name === "backgroundCheckbox" ||
      name === "experienceCheckbox" || name === "communicationCheckbox") {
      this.setState({
        [name]: event.target.checked
      })
    } else if (name === "state") {
      this.setState({ addressState: event.target.value })
    } else if (name === "email") {
      this.setState({
        [name]: event.target.value.toLowerCase()
      });
    } else {
      if (this.state[name] !== undefined) {
        this.setState({
          [name]: event.target.value
        });
      }
    }
    // Clear message
    this.message = ""
    this.error = ""
  };

  onResendCode = () => {
    const { userStore } = this.props
    this.message = ""
    this.error = ""

    userStore!.resendSignUp(this.userId!)
      .then((result: any) => {
        this.message = "Verification code requested"
      })
      .catch((err: Error) => {
        this.error = err.message
      })
  }

  updateUser = async () => {
    const { userStore, notify } = this.props

    this.isProcessing = true

    const input: UpdateUserInput = {
      id: this.userId!,
      active: true,
      userStatus: UserStatus.Registered,
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      phone: phoneToE164Format(this.state.phone),
      country: "US",
      lastActiveAt: getISODateTime()
    }

    Logger.debug("RegisterPage.updateUser", input)
    const user = await userStore!.updateUser(input)

    if (!user) {
      this.error = "Error updating user"
      this.isProcessing = false
      return
    }

    // Record Agreements
    const agreementInput: CreateAgreementInput = {
      userId: user.id,
      accountId: user.accountId,
      agreementTypes: [
        AgreementType.PrivacyPolicy,
        AgreementType.TermsOfUse
      ]
    }
    const agreement = await userStore!.createAgreement(agreementInput)

    if (!agreement) {
      this.error = "Error saving agreement"
      this.isProcessing = false
      return
    }

    userStore!.logUserActivity(user.id, SubjectType.User, user.id, ActivityType.UserRegistered, user.email)

    this.isProcessing = false
    notify!.show("success", "Welcome to GovGig!")
    userStore!.routeInitial()
  }

}

export default withWidth()(withStyles(styles)(RegisterPage));
