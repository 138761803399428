
class Answer {
  name: string
  type: string
  required: boolean
  value: string
  pass: boolean
  label: string

  constructor (data: any) {
    this.name = data.name
    this.type = data.type
    this.required = data.required ?? false
    this.value = data.value
    this.pass = data.pass ?? false
    this.label = data.label
  }
}

export default Answer