
import { Box, Container, useTheme } from "@material-ui/core"
import { ReactNode } from "react"

const MarginRow = ({ 
  backgroundColor, 
  background, 
  children, 
  maxWidth 
}: {
  backgroundColor?: string 
  background?: string 
  children: any
  maxWidth?: 'xs' | 'sm' | 'md' | 'lg'  
}) => {
  const theme = useTheme()
  return (
    <Box
      bgcolor={ backgroundColor }
      // m={0}
      // p={0}
      height={"100%"}
      // flex={1}
      // style={{
      //   backgroundColor: backgroundColor,
      //   background: background,
      // }}
      // height="100vh"
      // flexGrow={1}
    >
      <Container
        maxWidth="lg"
      >
        {children}
      </Container>
      {/* <Box 
        sx={{ 
          mx: { 
            lg: "auto"
          },
          px: {
            xs: theme.spacing(1),
            lg: theme.spacing(0) 
          },
          width: {
            lg: '100%'
          },
          maxWidth: maxWidth ? theme.breakpoints.values[maxWidth] : {
            lg: theme.breakpoints.values.lg
          },
        }}
      >
        {children}
      </Box> */}
    </Box> 
  )
  // return (
  //   <Box
  //     sx={{
  //       // backgroundColor: backgroundColor,
  //       // background: background 
  //     }}
  //   >
  //     <Box 
  //       sx={{ 
  //         mx: { 
  //           lg: "auto"
  //         },
  //         px: {
  //           xs: theme.spacing(1),
  //           lg: theme.spacing(0) 
  //         },
  //         width: {
  //           lg: '100%'
  //         },
  //         maxWidth: maxWidth ? theme.breakpoints.values[maxWidth] : {
  //           lg: theme.breakpoints.values.lg
  //         },
  //       }}
  //     >
  //       {children}
  //     </Box>
  //   </Box>
  // )
}

export default MarginRow