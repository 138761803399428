import * as React from 'react'
import {makeObservable, observable, when} from "mobx";
import {inject, observer} from "mobx-react";
// Routing 
import ControlTower, { Routes } from '../../components/ControlTower';
import {RouteComponentProps} from "@reach/router";
// UI
import {
  Box,
  createStyles,
  Grid,
  Tab,
  Tabs,
  Theme,
  Typography,
  withStyles,
  WithStyles,
  withTheme,
  WithTheme
} from "@material-ui/core";
// Stores
import AccountStore from "../../stores/AccountStore";
import ProfileStore from "../../stores/ProfileStore";
import UserStore from "../../stores/UserStore";
// Components
import Confirm from "../../components/confirm/Confirm";
import Notify from "../../components/notify/Notify";
import Page from '../../components/page/Page'
import Progress from "../../components/Progress";
// Models
import JobCandidate from '../../model/JobCandidate';
import Profile from '../../model/Profile';
import User from '../../model/User';
import Logger from '../../components/Logger';
import MarginRow from '../../components/page/MarginRow';
import UserCRMActionsPanel from '../../components/user/UserCRMActionsPanel';
import UserActivityList from '../../components/userActivity/UserActivityList';
import JobCandidateList from '../../components/jobCandidates/JobCandidateList';
import {SubjectType} from "../../API";
import ProfilePage from "../profile/ProfilePage";
import NoteActivityPanel from "../../components/userActivity/NoteActivityPanel";
import {getISODateTime} from "../../stores/StoreUtilities";
import UserActivity from "../../model/UserActivity";
import JobStore from "../../stores/JobStore";
import ProfileNameView from '../../components/profile/ProfileNameView';
import JobCandidateStatusFlowCard from '../../components/jobCandidates/JobCandidateStatusFlowCard';
import JobCandidateNotes from "../../components/jobCandidates/JobCandidateNotes";

const styles = (theme: Theme) => createStyles({
  avatar: {
    backgroundColor: theme.palette.primary.main,
    borderWidth: 2,
    fontSize: 14,
    width: theme.spacing(4),
    height: theme.spacing(4)
  },
  tabs: {
    flexGrow: 1,
    backgroundColor: theme.palette.common.white
  },
  tab: {
    fontSize: 15,
    fontWeight: 600,
    textTransform: 'capitalize'
  },
})

interface IManageCandidatePageProps {
  location?: any 
  tab?: string
  embed?: boolean
  profileId?: string
  notesOnly?: boolean
  
  // Pass in JobCandidate when the user is reviewing this Candidate for a specific JobPost. 
  jobCandidate?: JobCandidate
  onUpdateJobCandidate?(jobCandidate: JobCandidate): void 
  onCreateJobCandidate?(newJobCandidate: JobCandidate): void 

  userStore?: UserStore
  profileStore?: ProfileStore
  accountStore?: AccountStore
  jobStore?: JobStore
  progress?: Progress
  notify?: Notify
  confirm?: Confirm
}

@inject("userStore", "profileStore", "accountStore", "jobStore", "progress", "notify", "confirm")
@observer
class ManageCandidatePage extends React.Component<WithStyles<typeof styles> & RouteComponentProps & IManageCandidatePageProps & WithTheme> {
  
  @observable isLoading = true
  @observable profile?: Profile
  @observable user?: User
  @observable tab: string = "activity"
  @observable notesUpdateAt = getISODateTime()
  
  constructor(
    props: WithStyles<typeof styles> & RouteComponentProps & IManageCandidatePageProps & WithTheme
  ) {
    super(props);
    makeObservable(this);
  }

  componentDidMount() {
    const { accountStore, userStore, profileStore, progress, tab } = this.props
    
    progress!.show("ManageCandidatePage")
    when(
      // once
      () => !accountStore!.isLoading && !userStore!.isLoading && !profileStore!.isLoading,
      // then 
      async () => {
        this.tab = tab ?? "activity"
        // if (!embed) {
        //   const searchParams = new URLSearchParams(this?.props?.location?.search)
        //   const jobCandidateId = searchParams.get("jobCandidateId") ?? undefined
        //   if (jobCandidateId) {
        //     await this.loadJobCandidate(jobCandidateId)
        //   }
        // }
        await this.loadProfile()
        progress!.hide("ManageCandidatePage")
        this.isLoading = false 
      }
    )
  }

  componentDidUpdate(prevProps: any) {
    if (this.props.tab && this.props.tab !== prevProps.tab) {
      this.tab = this.props.tab
    }
  }

  render() {
    const { embed } = this.props

    const title = "Manage Candidate"

    const content = this.isLoading === false ? this.renderContent() : null

    if (embed) {
      return content
    } else {
      return (
        <Page title={title}>
          <Box paddingTop={1} width={'100%'}>
            <MarginRow>
              <Box pl={1} pt={1}>
                <Typography variant="h3">
                  {title}
                </Typography>
              </Box>
            </MarginRow>
            {content}
          </Box>
        </Page>
      )
    }
  }

  renderContent = () => {
    const { 
      classes, 
      embed, 
      jobCandidate, 
      onUpdateJobCandidate,
      onCreateJobCandidate,
      userStore 
    } = this.props

    let tabNames: string[]
    if (userStore!.isEmployer) {
      tabNames = ["profile", "notes", "activity"]
    } else {
      tabNames = ["profile", "notes", "activity", "jobs"]
    }
    const tabName = this.tab !== undefined ? this.tab : tabNames[0]
    let tabValue = tabNames.findIndex(name => tabName === name)
    if (tabValue < 0) {
      tabValue = 0
    }

    if (!this.profile || !this.user) {
      return null
    }

    return (
      <MarginRow>
        <Box 
          py={2} 
          px={{ xs: 1, sm: 3 }} 
          mt={ embed ? 0 : 1 } 
          mb={ embed ? 0 : 2 } 
          bgcolor="common.white" 
          borderRadius={ embed ? 0 : 10 }
        >
          <Grid container justifyContent="space-between" alignItems="center" wrap="nowrap">
            <Grid item>
              <ProfileNameView 
                size="large"
                profile={this.profile}
                jobCandidate={this.props.jobCandidate}
                showAvatar
                showUnlock={false}
                textColor="primary"
                textVariant="h4"
                onUpdateJobCandidate={this.props.onUpdateJobCandidate}
              />
            </Grid>
            <Grid item>
              <Grid container direction="row" spacing={1}>
                { (userStore!.isAdminOrAgent || (jobCandidate && jobCandidate.unlocked === true)) &&
                  <Grid item>
                    <UserCRMActionsPanel
                      user={this.user!}
                      onClickContact={() => {}}
                    />
                  </Grid>
                }
                <Grid item>
                  <NoteActivityPanel
                    subject={jobCandidate ? SubjectType.JobCandidate : SubjectType.Profile}
                    subjectId={jobCandidate ? jobCandidate.id : this.profile!.id}
                    user={this.user!}
                    onAddNote={this.handleAddNote}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <JobCandidateStatusFlowCard
            jobCandidate={jobCandidate}
            jobPost={jobCandidate?.jobPost}
            profile={this.profile!}
            onUpdateJobCandidate={(updatedJobCandidate: JobCandidate) => onUpdateJobCandidate && onUpdateJobCandidate(updatedJobCandidate)}
            onCreateJobCandidate={(newJobCandidate: JobCandidate) => onCreateJobCandidate && onCreateJobCandidate(newJobCandidate)}
          />
        </Box>
        <Box 
          px={{ xs: 1, sm: 3 }} 
          mb={0} 
          bgcolor="common.white" 
          borderRadius={ embed ? 0 : "10px 10px 0px 0px" }
        >
          <Tabs
            value={tabValue}
            onChange={(event: any, value: any) => {
              // Navigate to tab to allow a direct url to each tab
              const tabName = tabNames[value]
              if (embed) {
                this.tab = tabName
              } else {
                let searchParams = new URLSearchParams()
                searchParams.append('profileId', this.profile!.id)
                // if (this.jobCandidate) {
                //   searchParams.append('jobCandidateId', this.jobCandidate.id)
                // }
                ControlTower.route(`${Routes.manageCandidate}/${tabName}?${searchParams.toString()}`)
              }
            }}
            variant="fullWidth"
            indicatorColor="secondary"
            textColor="secondary"
            className={ classes.tabs }
          >
            {tabNames.map((tabName) => {
              return <Tab key={tabName} className={classes.tab} label={tabName} />
            })}
          </Tabs>
          </Box>
          <Box px={{ xs: 1, sm: 3 }} mb={2} bgcolor="grey.50" borderRadius="0px 0px 10px 10px">
            {tabName === "profile" &&
              <Box py={1}>
                <ProfilePage 
                  embed={true}
                  profileId={this.profile!.id}
                  jobCandidate={this.props.jobCandidate}
                  onUpdateProfile={async (updatedProfile: Profile) => {
                    this.profile = updatedProfile
                  }}
                />
              </Box>
            }
            {tabName === "notes" &&
            <Box mt={2}>
              {!jobCandidate &&
                <UserActivityList user={this.user!} subject={SubjectType.Profile} subjectId={this.profile?.id} notesOnly={true} updatedAt={this.notesUpdateAt}/>
              }
              {jobCandidate &&
                <UserActivityList subject={SubjectType.JobCandidate} subjectId={jobCandidate.id} notesOnly={true} updatedAt={this.notesUpdateAt}/>
              }
            </Box>
            }
            {tabName === "activity" &&
              <Box py={2}>
                {!jobCandidate &&
                  <UserActivityList user={this.user!} subject={SubjectType.Profile} subjectId={this.profile?.id}/>
                }
                {jobCandidate &&
                  <UserActivityList subject={SubjectType.JobCandidate} subjectId={jobCandidate.id}/>
                }
              </Box>
            }
            {tabName === "jobs" && 
              <Box py={3}>
                { embed ? 
                  <JobCandidateList 
                    showInColumns
                    profile={this.profile!} 
                    // onClickFindJobs={() => {
                    //   // TODO: Open a side drawer to find jobs for the candidate. 
                    //   const route = Routes.findJobs
                    //   ControlTower.route(route)
                    // }}
                  />
                :
                  <JobCandidateList 
                    showInColumns
                    profile={this.profile!} 
                    // onClickFindJobs={() => {
                    //   // TODO: Open a side drawer to find jobs for the candidate. 
                    //   const route = Routes.findJobs
                    //   ControlTower.route(route)
                    // }}
                    onClickManageJobs={() => {
                      const route = Routes.manageJobs
                      ControlTower.route(route)
                    }}
                  />
                }
              </Box>
            }
          </Box>
        
      </MarginRow>
    )
  }

  // Loading 
  
  loadProfile = async () => {
    const { location, profileStore, profileId } = this.props
    let profId
    if (profileId) {
      profId = profileId
    } else if  (location) {
      profId = new URLSearchParams(location.search).get("profileId")
    }

    if (!profId) {
      const message = 'Could not load user profile.'
      Logger.error(message, new Error(message))
      return 
    }

    const profile = await profileStore!.getProfile(profId)
    if (profile) {
      this.profile = profile
      if (profile.user) {
        this.user = profile.user
      }
    }
  }

  handleAddNote = (note: UserActivity) => {
    this.notesUpdateAt = note.updatedAt
  }
}

export default withTheme((withStyles(styles)(ManageCandidatePage)))
