import * as React from 'react'
import {createStyles, Theme, withStyles, WithStyles, withTheme, WithTheme} from "@material-ui/core/styles";
import {RouteComponentProps} from "@reach/router";
import Page from "../../components/page/Page";
import MarginRow from "../../components/page/MarginRow";
import MasterServiceAgreement from "./MasterServiceAgreement";
import {observer} from "mobx-react";
import {makeObservable, observable} from "mobx";

const styles = (theme: Theme) => createStyles({

})

interface IMasterServiceAgreementPageProps {
  location?: any
}

@observer
class MasterServiceAgreementPage extends React.Component<WithStyles<typeof styles> & RouteComponentProps & WithTheme & IMasterServiceAgreementPageProps> {
  @observable accountId?: string

  constructor(props: any) {
    super(props)
    makeObservable(this);
  }

  componentDidMount() {
    const { location } = this.props
    window.addEventListener('scroll', this.handleScroll)

    if (location) {
      const searchParams = new URLSearchParams(location.search)
      this.accountId = searchParams.get("accountId") ?? undefined
    }
  }
  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll)
  }

  handleScroll = (event: any) => {
    const bottom = Math.ceil(window.innerHeight + window.scrollY) >= document.documentElement.scrollHeight

    if (bottom) {
      if (window.location !== window.parent.location) {
        // In iframe
        window.parent.postMessage("at-bottom", '*');
      }
    }
  }

  render() {
    return (
      <Page title="Master Services & Non-Solicitation Agreement" hideHeader={true} hideFooter={true}>
        <MarginRow>
            <MasterServiceAgreement accountId={this.accountId}/>
        </MarginRow>
      </Page>
    )
  }
}

export default withTheme((withStyles(styles)(MasterServiceAgreementPage)))

