import * as React from 'react'
import {makeObservable, observable, reaction, when} from "mobx";
import {inject, observer} from "mobx-react";
// Routing 
import ControlTower, { Routes } from '../../components/ControlTower';
import { RouteComponentProps } from "@reach/router";
// UI
import {
  Box,
  createStyles,
  Grid,
  Tab,
  Tabs,
  Theme,
  Typography,
  withStyles,
  WithStyles,
  withTheme,
  WithTheme
} from "@material-ui/core";
// Stores
import AccountStore from "../../stores/AccountStore";
import UserStore from "../../stores/UserStore";
// Components
import Confirm from "../../components/confirm/Confirm";
import Notify from "../../components/notify/Notify";
import Page from '../../components/page/Page'
import Progress from "../../components/Progress";
import MarginRow from '../../components/page/MarginRow';
import Logger from '../../components/Logger';
import UserActivityList from '../../components/userActivity/UserActivityList';
import JobCandidateList from '../../components/jobCandidates/JobCandidateList';
import JobPostMiniCard from '../../components/jobPosts/JobPostMiniCard';
import JobPostStatusSelector from '../../components/jobPosts/JobPostStatusSelector';
// Models
import JobPost from '../../model/JobPost';
import {JobPostStatus, SubjectType} from '../../API';
import JobPostPage from '../jobPost/JobPostPage';
import SideDrawer from '../../components/page/SideDrawer';
import CandidatesPage from '../candidates/FindTalentPage';
import NoteActivityPanel from "../../components/userActivity/NoteActivityPanel";
import UserActivity from "../../model/UserActivity";
import {getISODateTime} from "../../stores/StoreUtilities";
import JobStore from "../../stores/JobStore";
import JobCandidate from '../../model/JobCandidate';
import JobPostPublish from '../../components/jobPosts/JobPostPublish';
import Stack from '../../components/Stack';
import JobPostButtons from "../../components/jobPosts/JobPostButtons";
import PageHeadingPanel from '../../components/panel/PageHeadingPanel';
import PageTabsPanel from '../../components/panel/PageTabsPanel';
import PageTabPanel from '../../components/panel/PageTabPanel';

const styles = (theme: Theme) => createStyles({
  jobPostButtons: {
    padding: 2
  },
  tabs: {
    // flexGrow: 1,
    // backgroundColor: theme.palette.grey[100]
  },
  tab: {
    fontSize: 15,
    fontWeight: 600,
    textTransform: 'capitalize'
  },
})

interface IManageJobPageProps {
  location?: any 
  tab?: string
  embed?: boolean 
  jobPostId?: string
  onClickJobCandidate?(jobCandidate: JobCandidate): void 
  onUpdateJobPost?(jobPost: JobPost): void 

  userStore?: UserStore
  accountStore?: AccountStore
  jobStore?: JobStore
  progress?: Progress
  notify?: Notify
  confirm?: Confirm
}

@inject("userStore", "accountStore", "jobStore", "progress", "notify", "confirm")
@observer
class ManageJobPage extends React.Component<WithStyles<typeof styles> & RouteComponentProps & IManageJobPageProps & WithTheme> {
  
  @observable isLoading = true
  @observable jobPost?: JobPost
  @observable isFindTalentOpen: boolean = false
  @observable notesUpdateAt = getISODateTime()
  @observable selectedTabIndex: number = 0 
  @observable isJobPostPublishDialogOpen: boolean = false 

  tabNames: string[] = ["candidates", "activity", "notes", "posting"] // TODO: messages, notes

  constructor(
    props: WithStyles<typeof styles> & RouteComponentProps & IManageJobPageProps & WithTheme
  ) {
    super(props);
    makeObservable(this);
  }

  componentDidMount() {
    const { accountStore, userStore, progress } = this.props
    
    progress!.show("ManageJobPage")
    when(
      // once
      () => !accountStore!.isLoading && !userStore!.isLoading,
      // then 
      async () => {
        await this.loadJobPost()
        this.loadSelectedTab()
        progress!.hide("ManageJobPage")
        this.isLoading = false 
      }
    )

    reaction(() => this.props.jobPostId, async (jobPostId) => {
      await this.loadJobPost()
    })
  }

  componentDidUpdate(prevProps: any) {
    if (prevProps.tab !== this.props.tab) {
      this.loadSelectedTab()
    }
  }

  render() {
    const { embed } = this.props 
    const title = "Manage Job"

    if (embed) {
      return (
        <React.Fragment>
          {this.isLoading === false ? this.renderContent() : null}
        </React.Fragment>
      )
    } else {
      return (
        <Page title={title}>
          <Box paddingTop={1} width={'100%'}>
            <MarginRow>
              <Box px={1} py={1}>
                <Typography variant="h3">
                  {title}
                </Typography>
              </Box>
            </MarginRow>
            {this.isLoading === false ? this.renderContent() : null}
          </Box>
        </Page>
      )
    }
  }

  renderContent = () => {
    const { classes, embed, onUpdateJobPost, userStore } = this.props

    if (this.jobPost === undefined) {
      return null 
    }

    const jobPost = this.jobPost! 
    const tabName = this.tabNames[this.selectedTabIndex]
    const isAdminOrAgent = userStore!.isAdminOrAgent

    return (
      <MarginRow>
        <PageHeadingPanel>
          <Grid container justifyContent="space-between" spacing={1}>
            <Grid item>
              <JobPostMiniCard 
                jobPost={this.jobPost!}
                elevation={0}
              />
            </Grid>
            <Grid item>
              <Stack spacing={1}>
                <JobPostButtons
                  jobPost={jobPost}
                  className={classes.jobPostButtons}
                  // onEdit={this.props.onEdit}
                />
                { isAdminOrAgent && jobPost.status !== JobPostStatus.Draft &&
                  <Grid item>
                    <JobPostStatusSelector
                      jobPost={jobPost}
                      onChange={(jobPost: JobPost) => {
                        this.jobPost = jobPost
                        onUpdateJobPost && onUpdateJobPost(jobPost)
                      }}
                    />
                  </Grid>
                }
                <JobPostPublish
                  jobPost={jobPost}
                  isOpen={this.isJobPostPublishDialogOpen}
                  onClickPublish={() => {
                    this.isJobPostPublishDialogOpen = true 
                  }}
                  onUpdateJobPost={async (jobPost: JobPost) => {
                    this.jobPost = jobPost 
                    // await this.populateJobPost(this.jobPost!)
                    onUpdateJobPost && await onUpdateJobPost(jobPost)
                    this.isJobPostPublishDialogOpen = false 
                  }}
                  onCancel={() => {
                    this.isJobPostPublishDialogOpen = false 
                  }}
                />
                <NoteActivityPanel
                  subject={SubjectType.JobPost}
                  subjectId={this.jobPost!.id}
                  user={userStore!.user!}
                  onAddNote={this.handleAddNote}
                />
              </Stack>
            </Grid>
          </Grid>
        </PageHeadingPanel>
        <PageTabsPanel>
          <Tabs
            value={this.selectedTabIndex}
            onChange={(event: any, value: any) => {
              if (embed === undefined || embed === false) {
                const tabName = this.tabNames[value]
                // Navigate to tab to allow a direct url to each tab
                let searchParams = new URLSearchParams()
                searchParams.append('jobPostId', this.jobPost!.id)
                ControlTower.route(`${Routes.manageJob}/${tabName}?${searchParams.toString()}`)
              } else {
                this.selectedTabIndex = value 
              }
            }}
            indicatorColor="secondary"
            textColor="secondary"
            className={ classes.tabs }
            variant="scrollable"
            scrollButtons="auto"
          >
            {this.tabNames.map((tabName) => {
              return <Tab key={tabName} className={classes.tab} label={tabName} />
            })}
          </Tabs>
        </PageTabsPanel>
        <PageTabPanel>
          {tabName === 'candidates' && 
            <JobCandidateList 
              jobPost={ this.jobPost }
              showInColumns
              onClickFindTalent={() => {
                this.isFindTalentOpen = true 
              }}
              onClickJobCandidate={this.props.onClickJobCandidate}
            />
          }
          {tabName === 'activity' && 
            <UserActivityList subject={SubjectType.JobPost} subjectId={this.jobPost!.id}/>
          }
          {tabName === 'notes' &&
            <UserActivityList subject={SubjectType.JobPost} subjectId={this.jobPost!.id} notesOnly={true} updatedAt={this.notesUpdateAt}/>
          }
          {tabName === 'posting' &&
            <JobPostPage 
              embed 
              jobPostId={this.jobPost!.id} 
              onUpdateJobPost={(jobPost: JobPost) => {
                this.jobPost = jobPost
                onUpdateJobPost && onUpdateJobPost(jobPost)
              }}
            />
          }
        </PageTabPanel>
        { this.renderFindTalent() }
      </MarginRow>
    )
  }

  renderFindTalent = () => {
    if (this.jobPost === undefined) {
      return null
    }

    if (!this.isFindTalentOpen) {
      return null 
    }

    return (
      <React.Fragment>
        <SideDrawer
          title={"Find Talent"}
          isOpen={this.isFindTalentOpen}
          onClose={async () => {
            // Update JobPost
            await this.populateJobPost(this.jobPost!)
            this.isFindTalentOpen = false
          }}
          size="large" 
        >
          <Box pt={1} pb={2}>
            <CandidatesPage 
              embed={true} 
              jobPostId={this.jobPost?.id}
              
            />
          </Box>
        </SideDrawer>
      </React.Fragment>
    )
  }

  // Loading 

  loadSelectedTab = () => {
    const { embed, tab } = this.props 
    if (embed === undefined || embed === false) {
      const tabName = tab !== undefined ? tab : this.tabNames[0]
      let tabIndex = this.tabNames.findIndex(name => tabName === name)
      if (tabIndex < 0) {
        tabIndex = 0
      }
      this.selectedTabIndex = tabIndex
    }
  }

  loadJobPost = async () => {
    const { jobStore, location, notify } = this.props

    let jobPostId
    if (this.props.jobPostId) {
      jobPostId = this.props.jobPostId
    } else {
      jobPostId = new URLSearchParams(location.search).get("jobPostId")
    }

    if (jobPostId) {
      const jobPost = await jobStore!.getJobPost(jobPostId)

      if (!jobPost) {
        notify!.show('error', 'Could not fetch job post')
        return 
      }

      await this.populateJobPost(jobPost)

      this.jobPost = jobPost
    } else {
      const message = 'Could not load job post.'
      Logger.error(message, new Error(message))
      return 
    }
  }

  populateJobPosts = async (jobPosts: JobPost[]) => {
    const promises = jobPosts.map(jobPost => {
      return this.populateJobPost(jobPost)    
    })

    await Promise.all(promises)
  }

  populateJobPost = async (jobPost: JobPost) => {
    const { jobStore } = this.props

    // Need to fetch candidates to get the related profile/user info.
    const jobCandidates = await jobStore!.listJobCandidatesByJobPost(jobPost.id)

    if (jobCandidates && jobCandidates.length > 0) {
      jobCandidates.forEach((jobCandidate: JobCandidate) => {
        jobCandidate.jobPost = jobPost
      })
      jobPost.jobCandidates = jobCandidates.sort(JobCandidate.compareFn)
    }
  }

  handleAddNote = (note: UserActivity) => {
    this.notesUpdateAt = note.updatedAt
  }

}

export default withTheme((withStyles(styles)(ManageJobPage)))
