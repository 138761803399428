import React from "react";
import {
  TextField,
  withWidth,
  WithWidth
} from "@material-ui/core";
import { createStyles, Theme, WithStyles, WithTheme } from "@material-ui/core";
import { withStyles, withTheme } from "@material-ui/core/styles";
import { Autocomplete } from "@material-ui/lab";
import ProfileStore from "../../stores/ProfileStore";
import { makeObservable, observable } from "mobx";
import { inject, observer } from "mobx-react";
import UserStore from "../../stores/UserStore";
import AccountStore from "../../stores/AccountStore";
import Progress from "../Progress";
import JobPost from "../../model/JobPost";

const styles = (theme: Theme) => createStyles({
  filterTextField: {
    // width: 308,
    // width: "100%",
    flexGrow: 1, 
    backgroundColor: theme.palette.background.paper,
    borderRadius: 10
  }
})

export interface IJobPostFilterProps {
  jobPosts: JobPost[]
  onSelectJobPost: (jobPost?: JobPost) => any 
  value?: JobPost
  accountStore?: AccountStore
  profileStore?: ProfileStore
  progress?: Progress
  userStore?: UserStore 
}

@inject("accountStore", "profileStore", "userStore", "progress")
@observer
class JobPostFilter extends React.Component<WithStyles<typeof styles> & WithTheme & IJobPostFilterProps & WithWidth> {

  @observable jobPostFilter?: JobPost 

  constructor(props: any) {
    super(props)
    makeObservable(this)
  }

  componentDidMount() {
    const { value } = this.props
    this.jobPostFilter = value
  }

  componentDidUpdate(prevProps: any) {
    if (prevProps.value !== this.props.value) {
      this.jobPostFilter = this.props.value
    }
  }

  render() {
    const { classes, jobPosts } = this.props

    return (
      <Autocomplete
        autoSelect
        includeInputInList
        options={jobPosts}
        getOptionLabel={(option: JobPost) => option?.service?.name ?? ""}
        getOptionSelected={(option: JobPost, value: JobPost) => option.id === value.id}
        groupBy={(option: JobPost) => option.contract!.name}
        onChange={this.onSelectJobPost}
        value={this.jobPostFilter ?? null}
        renderInput={(params: any) =>
          <div ref={params.InputProps.ref}>
            <TextField
              {...params}
              className={classes.filterTextField}
              variant="outlined"
              size="small"
              name="jobPostFilter"
              label="JobPost"
            />
          </div>
        }
      />
    )
  }

  onSelectJobPost = (event: any, value: any, reason: any) => {
    const jobPost = value as JobPost 
    if (jobPost) {
      this.jobPostFilter = jobPost  
    } else {
      this.jobPostFilter = undefined
    }
    this.props.onSelectJobPost(this.jobPostFilter)
  }
}

export default withTheme(withStyles(styles)(withWidth()(JobPostFilter)))