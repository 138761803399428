import {
  createStyles, TextField, Theme, WithStyles, WithTheme, withWidth,
  WithWidth
} from "@material-ui/core";
import { withStyles, withTheme } from "@material-ui/core/styles";
import { Autocomplete } from "@material-ui/lab";
import { makeObservable, observable } from "mobx";
import { inject, observer } from "mobx-react";
import React from "react";
import Account from "../../model/Account";
import AccountStore from "../../stores/AccountStore";
import ProfileStore from "../../stores/ProfileStore";
import UserStore from "../../stores/UserStore";
import Progress from "../Progress";

const styles = (theme: Theme) => createStyles({
  filterTextField: {
    // width: 308,
    // width: "100%",
    flexGrow: 1, 
    backgroundColor: theme.palette.background.paper,
    borderRadius: 10
  }
})

export interface IAccountFilterProps {
  accounts: Account[]
  value?: Account
  onSelectAccount: (account?: Account) => any 
  className?: any
  variant?: 'filled' | 'outlined' | 'standard'

  accountStore?: AccountStore
  profileStore?: ProfileStore
  progress?: Progress
  userStore?: UserStore 
}

@inject("accountStore", "profileStore", "userStore", "progress")
@observer
class AccountFilter extends React.Component<WithStyles<typeof styles> & WithTheme & IAccountFilterProps & WithWidth> {

  @observable accountFilter?: Account

  constructor(props: any) {
    super(props)
    makeObservable(this)
  }

  componentDidMount() {
    const { value } = this.props
    this.accountFilter = value
  }

  componentDidUpdate(prevProps: any) {
    if (prevProps.value !== this.props.value) {
      this.accountFilter = this.props.value
    }
  }

  render() {
    const { accounts, classes, className, variant="outlined" } = this.props

    return (
      <Autocomplete
        autoSelect
        includeInputInList
        options={accounts}
        getOptionLabel={(option: Account) => option.name}
        getOptionSelected={(option: Account, value: Account) => option.id === value.id}
        onChange={this.onSelectAccount}
        value={this.accountFilter ?? null}
        renderInput={(params: any) =>
          <div ref={params.InputProps.ref}>
            <TextField
              {...params}
              className={className ?? classes.filterTextField}
              variant={variant}
              size="small"
              name="accountFilter"
              label="Account"
            />
          </div>
        }
      />
    )
  }

  onSelectAccount = (event: any, value: any, reason: any) => {
    const account = value as Account 
    if (account) {
      this.accountFilter = account  
    } else {
      this.accountFilter = undefined
    }
    this.props.onSelectAccount(this.accountFilter)
  }
}

export default withTheme(withStyles(styles)(withWidth()(AccountFilter)))