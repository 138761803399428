import * as React from 'react'
import Page from '../../components/page/Page'
import {Box, createStyles, Theme, Typography, withStyles, WithStyles, withTheme, WithTheme} from "@material-ui/core";
import {RouteComponentProps} from "@reach/router";
import MarginRow from "../../components/page/MarginRow";
import {inject, observer} from "mobx-react";
import UserStore from "../../stores/UserStore";
import { observable, when, makeObservable } from "mobx";
import Progress from "../../components/Progress";
import ControlTower, {Routes} from "../../components/ControlTower";
import ContractsList from './ContractsList';

const styles = (theme: Theme) => createStyles({
  root: {
    flexGrow: 1,
    width: "100%",
    justifyContent: 'top',
    alignItems: 'center',
    overflow: "hidden"
  },
  navigation: {
  },
  content: {
    overflow: "hidden"
  },
  tabs: {
    flexGrow: 1
  },
  tab: {
    fontSize: 15,
    fontWeight: 600,
  },
  hiddenTab: {
    display: "none"
  }
})

interface IContractsPageProps {
  userStore?: UserStore
  progress?: Progress
  tab?: string
}

@inject("userStore", "progress")
@observer
class ContractsPage extends React.Component<WithStyles<typeof styles> & RouteComponentProps & IContractsPageProps & WithTheme> {
  tabNames: string[] = ["active", "completed"]

  @observable isLoading = true

  constructor(
    props: WithStyles<typeof styles> & RouteComponentProps & IContractsPageProps & WithTheme
  ) {
    super(props);
    makeObservable(this);
  }

  componentDidMount () {
    const { userStore, progress } = this.props

    this.isLoading = true
    progress!.show("ContractsPage")
    when(
      // once
      () => userStore!.isLoading === false,
      // then 
      () => {
        this.isLoading = false
        progress!.hide("ContractsPage")
      }
    )
  }

  render() {
    const { classes } = this.props

    if (this.isLoading) {
      return null
    }

    const title = "Job Groups"

    const tabName = (this.props.tab !== undefined) ? this.props.tab : this.tabNames[0]
    let tabValue = this.tabNames.findIndex(name => tabName === name)
    if (tabValue < 0) {
      tabValue = 0
    }

    return (
      <Page title={title}>
        <MarginRow>
          <div className={ classes.root}>
            <Box pl={1} pt={1.5}>
              <Typography variant="h3">
                {title}
              </Typography>
            </Box>
            <ContractsList />
          </div>
        </MarginRow>
      </Page>
    )
  }

  handleChange = (event: any, value: any) => {
    // Navigate to tab to allow a direct url to each tab. 
    const tabName = this.tabNames[value]
    ControlTower.route(`${Routes.jobGroups}/${tabName}`)
  }
}

export default withTheme((withStyles(styles)(ContractsPage)))
