import { 
  Box,
  Grid, 
  Typography, 
  useMediaQuery,
  useTheme
} from "@material-ui/core"
import { IRouteData } from "../../RoutesConfig"
import Stack from "../Stack"
import ActionButton from "../controls/ActionButton"
import ControlTower from "../ControlTower"

const TitleGroup = ({
  superTitle,
  title, 
  subtitle,
  body, 
  alignment,
  titleVariant,
  primaryRoute,
  primaryRouteTarget,
  secondaryRoute,
  children 
}: {
  superTitle?: string 
  title: string 
  subtitle?: string 
  body?: string  
  alignment?: 'left' | 'center' 
  titleVariant?: 'h1' | 'h2' | 'h3'
  primaryRoute?: IRouteData
  primaryRouteTarget?: React.HTMLAttributeAnchorTarget
  secondaryRoute?: IRouteData 
  children?: React.ReactNode 
}) => {
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('md'));

  const renderRoutes = () => {
    return (
      <Stack direction="row" spacing={2}>
        { primaryRoute && 
          <Grid item>
            <ActionButton 
              tracking={primaryRoute.title}
              text={primaryRoute.title}
              showFowardArrow
              click={() => {
                ControlTower.route((primaryRoute.pathname ?? primaryRoute.href)!)
              }}
            />
          </Grid> 
        }
        { secondaryRoute && 
          <Grid item>
            <ActionButton
              tracking={secondaryRoute.title}
              text={secondaryRoute.title}
              variant="outlined"
              click={() => {
                ControlTower.route((secondaryRoute.pathname ?? secondaryRoute.href)!)
              }}
            />
          </Grid>
        }
      </Stack>
    )
  }

  return (
    <Grid 
      container 
      direction="column" 
      alignItems={ isSmall ? 'center' : (alignment && alignment === 'left') ? 'flex-start' : 'center' }
    >
      {superTitle && 
        <Grid item>
          <Typography 
            gutterBottom 
            align={ isSmall ? 'center' : (alignment && alignment === 'left') ? 'left' : 'center' }
            variant="h5"
          >
            {superTitle}
          </Typography>
        </Grid>
      }
      <Grid item>
        <Box
          pb={1}
        >
          <Typography 
            gutterBottom 
            align={ isSmall ? 'center' : (alignment && alignment === 'left') ? 'left' : 'center' }
            variant={ titleVariant ? "h2" : "h3" } 
            color="primary" 
          >
            {title}
          </Typography>
        </Box>
      </Grid>
      {subtitle && 
        <Grid item>
          <Typography 
            gutterBottom 
            align={ isSmall ? 'center' : (alignment && alignment === 'left') ? 'left' : 'center' }
            variant="h6"
          >
            {subtitle}
          </Typography>
        </Grid>
      }
      {body && 
        <Grid item>
          <Typography 
            gutterBottom 
            align={ isSmall ? 'center' : (alignment && alignment === 'left') ? 'left' : 'center' }
            variant="body1"
          >
            {body}
          </Typography>
        </Grid>
      }
      { (primaryRoute || secondaryRoute) && 
        <Grid item>
          { renderRoutes() }
        </Grid>
      }
      {children && 
        <Grid item>
          {children}
        </Grid>
      }
    </Grid>
  )
}

export default TitleGroup
