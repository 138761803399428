import React from "react";
import {
  TextField,
  withWidth,
  WithWidth
} from "@material-ui/core";
import { createStyles, Theme, WithStyles, WithTheme } from "@material-ui/core";
import { withStyles, withTheme } from "@material-ui/core/styles";
import { Autocomplete } from "@material-ui/lab";
import ProfileStore from "../../stores/ProfileStore";
import { makeObservable, observable } from "mobx";
import { inject, observer } from "mobx-react";
import Account from "../../model/Account";
import UserStore from "../../stores/UserStore";
import AccountStore from "../../stores/AccountStore";
import Progress from "../Progress";
import Contract from "../../model/Contract";

const styles = (theme: Theme) => createStyles({
  filterTextField: {
    // width: 308,
    // width: "100%",
    flexGrow: 1, 
    backgroundColor: theme.palette.background.paper,
    borderRadius: 10
  }
})

export interface IContractFilterProps {
  accounts?: Account[]
  contracts: Contract[]
  onSelectContract: (contract?: Contract) => any 
  value?: Contract 

  accountStore?: AccountStore
  profileStore?: ProfileStore
  progress?: Progress
  userStore?: UserStore 
}

@inject("accountStore", "profileStore", "userStore", "progress")
@observer
class ContractFilter extends React.Component<WithStyles<typeof styles> & WithTheme & IContractFilterProps & WithWidth> {

  @observable contractFilter?: Contract 

  constructor(props: any) {
    super(props)
    makeObservable(this)
  }

  componentDidMount() {
    const { value } = this.props 
    if (value) {
      this.contractFilter = value 
    }
  }

  componentDidUpdate(prevProps: any) {
    if (prevProps.value !== this.props.value) {
      this.contractFilter = this.props.value
    }
  }


  render() {
    const { accounts, classes, contracts } = this.props

    return (
      <Autocomplete
        autoSelect
        includeInputInList
        options={contracts}
        getOptionLabel={(option: Contract) => option.name}
        getOptionSelected={(option: Contract, value: Contract) => option.id === value.id}
        groupBy={accounts ? (option: Contract) => { 
          const account = accounts.find(account => account.id === option.accountId)
          return account!.name 
        } : undefined}
        onChange={this.onSelectContract}
        value={this.contractFilter ?? null}
        renderInput={(params: any) =>
          <div ref={params.InputProps.ref}>
            <TextField
              {...params}
              className={classes.filterTextField}
              variant="outlined"
              size="small"
              name="contractFilter"
              label="Job Group"
            />
          </div>
        }
      />
    )
  }

  onSelectContract = (event: any, value: any, reason: any) => {
    const contract = value as Contract 
    if (contract) {
      this.contractFilter = contract  
    } else {
      this.contractFilter = undefined
    }
    this.props.onSelectContract(this.contractFilter)
  }
}

export default withTheme(withStyles(styles)(withWidth()(ContractFilter)))