import {createMachine} from 'xstate'
import {useMachine} from '@xstate/react';
import {CreateUserActivityInput, JobCandidateStatus, SubjectType, UpdateJobCandidateInput} from '../../API'
import {useEffect, useState} from 'react';
import JobCandidate from '../../model/JobCandidate';
import {useStores} from '../../stores/StoreProvider';
import {
  Box,
  Button,
  ButtonGroup,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Link,
  makeStyles,
  Paper,
  Theme,
  Typography,
  useTheme
} from '@material-ui/core';
import {
  LockOpen,
  Phone,
  ThumbDown,
  ThumbDownOutlined,
  ThumbsUpDown,
  ThumbsUpDownOutlined,
  ThumbUp,
  ThumbUpOutlined
} from '@material-ui/icons';
import {phoneToNationalFormat} from '../../stores/StoreUtilities';
import ModalDialog from "../ModalDialog";
import CardValue from "../CardValue";
import FormValidator from "../form/FormValidator";
import CancelButton from "../form/CancelButton";
import ProgressButton from "../form/ProgressButton";
import JobInterestApplyDialog from "../jobPosts/JobInterestApplyDialog";
import {ActivityType} from "../../model/UserActivity";

const useStyles = makeStyles((theme: Theme) => createStyles({
  boxSx: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  buttonStyle: {
    height: 36,
    maxHeight: 36
  },
  iconButtonStyle: {
    height: 36,
    maxHeight: 36,
    paddingRight: 4
  },
  form: {
    overflowY: "auto",
    display: "flex",
    flexDirection: "column"
  },
  progressButton: {
    minWidth: 80
  },
  link: {
    cursor: "pointer"
  },
  stepText: {
    fontWeight: 700
  }
}))

enum JobCandidateActions {
  INTERESTED = 'INTERESTED',
  INVITE = 'INVITE',
  APPLY = 'APPLY',
  REVIEW = 'REVIEW',
  ACCEPT = 'ACCEPT',
  REJECT = 'REJECT',
  UNLOCK = 'UNLOCK',
  CONTACT = 'CONTACT',
  HIRE = 'HIRE',
  DECLINE = 'DECLINE'
}

const stateMachine = createMachine({
  id: 'createJobPost',
  initial: 'loading',
  states: {
    loading: {
      on: { 
        [JobCandidateActions.INTERESTED]: JobCandidateStatus.Interested,
        [JobCandidateActions.INVITE]: JobCandidateStatus.Invited,
        [JobCandidateActions.APPLY]: JobCandidateStatus.Applied,
        [JobCandidateActions.REVIEW]: JobCandidateStatus.Reviewing,
        [JobCandidateActions.ACCEPT]: JobCandidateStatus.Accepted,
        [JobCandidateActions.REJECT]: JobCandidateStatus.Rejected,
        [JobCandidateActions.UNLOCK]: JobCandidateStatus.Unlocked,
        [JobCandidateActions.CONTACT]: JobCandidateStatus.Contacted,
        [JobCandidateActions.HIRE]: JobCandidateStatus.Hired,
        [JobCandidateActions.DECLINE]: JobCandidateStatus.Declined
      }
    },
    [JobCandidateStatus.Interested]: {
      on: {
        [JobCandidateActions.INVITE]: JobCandidateStatus.Invited
      }
    },
    [JobCandidateStatus.Invited]: {
      on: {
        [JobCandidateActions.APPLY]: JobCandidateStatus.Applied
      }
    },
    [JobCandidateStatus.Applied]: {
      on: {
        [JobCandidateActions.ACCEPT]: JobCandidateStatus.Accepted,
        [JobCandidateActions.REVIEW]: JobCandidateStatus.Reviewing,
        [JobCandidateActions.REJECT]: JobCandidateStatus.Rejected,
        [JobCandidateActions.UNLOCK]: JobCandidateStatus.Unlocked,
        [JobCandidateActions.CONTACT]: JobCandidateStatus.Contacted,
        [JobCandidateActions.HIRE]: JobCandidateStatus.Hired,
        [JobCandidateActions.DECLINE]: JobCandidateStatus.Declined
      }
    },
    [JobCandidateStatus.Accepted]: {
      on: {
        [JobCandidateActions.ACCEPT]: JobCandidateStatus.Accepted,
        [JobCandidateActions.REVIEW]: JobCandidateStatus.Reviewing,
        [JobCandidateActions.REJECT]: JobCandidateStatus.Rejected,
        [JobCandidateActions.UNLOCK]: JobCandidateStatus.Unlocked,
        [JobCandidateActions.CONTACT]: JobCandidateStatus.Contacted,
        [JobCandidateActions.HIRE]: JobCandidateStatus.Hired,
        [JobCandidateActions.DECLINE]: JobCandidateStatus.Declined
      }
    },
    [JobCandidateStatus.Reviewing]: {
      on: {
        [JobCandidateActions.ACCEPT]: JobCandidateStatus.Accepted,
        [JobCandidateActions.REVIEW]: JobCandidateStatus.Reviewing,
        [JobCandidateActions.REJECT]: JobCandidateStatus.Rejected,
        [JobCandidateActions.UNLOCK]: JobCandidateStatus.Unlocked,
        [JobCandidateActions.CONTACT]: JobCandidateStatus.Contacted,
        [JobCandidateActions.HIRE]: JobCandidateStatus.Hired,
        [JobCandidateActions.DECLINE]: JobCandidateStatus.Declined
      }
    },
    [JobCandidateStatus.Rejected]: {
      on: {
        [JobCandidateActions.ACCEPT]: JobCandidateStatus.Accepted,
        [JobCandidateActions.REVIEW]: JobCandidateStatus.Reviewing,
        [JobCandidateActions.REJECT]: JobCandidateStatus.Rejected,
        [JobCandidateActions.DECLINE]: JobCandidateStatus.Declined
      }
    },
    [JobCandidateStatus.Unlocked]: {
      on: {
        [JobCandidateActions.ACCEPT]: JobCandidateStatus.Accepted,
        [JobCandidateActions.REVIEW]: JobCandidateStatus.Reviewing,
        [JobCandidateActions.REJECT]: JobCandidateStatus.Rejected,
        [JobCandidateActions.CONTACT]: JobCandidateStatus.Contacted,
        [JobCandidateActions.HIRE]: JobCandidateStatus.Hired,
        [JobCandidateActions.DECLINE]: JobCandidateStatus.Declined
      }
    },
    [JobCandidateStatus.Contacted]: {
      on: {
        [JobCandidateActions.ACCEPT]: JobCandidateStatus.Accepted,
        [JobCandidateActions.REVIEW]: JobCandidateStatus.Reviewing,
        [JobCandidateActions.REJECT]: JobCandidateStatus.Rejected,
        [JobCandidateActions.CONTACT]: JobCandidateStatus.Contacted,
        [JobCandidateActions.HIRE]: JobCandidateStatus.Hired,
        [JobCandidateActions.DECLINE]: JobCandidateStatus.Declined
      }
    },
    [JobCandidateStatus.Hired]: {
      on: {
        [JobCandidateActions.CONTACT]: JobCandidateStatus.Hired,
        [JobCandidateActions.DECLINE]: JobCandidateStatus.Declined
      }
    },
    [JobCandidateStatus.Declined]: {
      on: {
      }
    }
  }
})

const JobCandidateStatusFlow = ({
  jobCandidate,
  onClickProfile,
  onUpdateJobCandidate,
  elevation
}: {
  jobCandidate: JobCandidate
  onClickProfile?(): void
  onUpdateJobCandidate(jobCandidate: JobCandidate): void
  elevation?: number 
}) => {
  const [isProcessing, setIsProcessing] = useState(false)
  const [isApplyDialogOpen, setIsApplyDialogOpen] = useState(false)
  const [isContactDialogOpen, setIsContactDialogOpen] = useState(false)
  const [isUnlockDialogOpen, setIsUnlockDialogOpen] = useState(false)
  const [statusStep, setStatusStep] = useState<StatusStep>(1)
  const [message, setMessage] = useState<any>("")
  const [currentState, send, service] = useMachine(stateMachine)
  const { jobStore, userStore, notify, progress } = useStores()
  const progressName = "JobCandidateStatusFlow"
  const theme = useTheme()
  const classes = useStyles()

  useEffect(() => {
    const subscription = service.subscribe((state) => {
      // simple state logging
      // console.log("Current State: ", JSON.stringify(state.value, null, 2));
    });
  
    return subscription.unsubscribe;
  }, [
    service // note: service should never change
  ]); 

  useEffect(() => {
    progress.show(progressName)

    const status = jobCandidate.status
    switch(status) {
      case JobCandidateStatus.Interested: 
        send(JobCandidateActions.INTERESTED)
        break
      case JobCandidateStatus.Invited: 
        send(JobCandidateActions.INVITE)
        break
      case JobCandidateStatus.Applied: 
        send(JobCandidateActions.APPLY)
        break
      case JobCandidateStatus.Accepted: 
        send(JobCandidateActions.ACCEPT)
        break;
      case JobCandidateStatus.Reviewing: 
        send(JobCandidateActions.REVIEW)
        break
      case JobCandidateStatus.Rejected: 
        send(JobCandidateActions.REJECT)
        break
      case JobCandidateStatus.Unlocked:
        send(JobCandidateActions.UNLOCK)
        break
      case JobCandidateStatus.Contacted:
        send(JobCandidateActions.CONTACT)
        break
      case JobCandidateStatus.Hired:
        send(JobCandidateActions.HIRE)
        break
      case JobCandidateStatus.Declined: 
        send(JobCandidateActions.DECLINE)
        break
    }

    progress.hide(progressName)
  })

  useEffect(() => {
    switch (currentState.value) {
      case JobCandidateStatus.Interested:
        setStatusStep(StatusStep.Initiate)
        if (userStore.isAdminOrAgent) {
          setMessage(<span>Next Step: Invite candidate to apply | <Link onClick={onApply} className={classes.link}>Apply for candidate</Link></span>)
        } else {
          setMessage("Next Step: Invite candidate to apply.")
        }
        break
      case JobCandidateStatus.Invited:
        setStatusStep(StatusStep.Review)
        if (userStore.isAdminOrAgent) {
          setMessage(<span>Next Step: Review profile and wait for candidate to apply | <Link onClick={onApply} className={classes.link}>Apply for candidate</Link></span>)
        } else {
          setMessage("Next Step: Review profile and wait for candidate to apply.")
        }
        break
      case JobCandidateStatus.Applied:
        setStatusStep(StatusStep.Review)
        setMessage("Next Step: Review candidate answers and profile and select thumbs up/down/undecided.")
        break
      case JobCandidateStatus.Accepted:
        setStatusStep(jobCandidate.unlocked ? StatusStep.Decide : StatusStep.Contact)
        setMessage("Next Step: Unlock the candidate to contact and reveal their name and resume.")
        break
      case JobCandidateStatus.Reviewing:
        setStatusStep(StatusStep.Review)
        setMessage("Next Step: Review candidate answers and profile to decide thumbs up or down.")
        break
      case JobCandidateStatus.Rejected:
        setStatusStep(StatusStep.Decide)
        setMessage("Candidate has not been accepted.")
        break
      case JobCandidateStatus.Unlocked:
        setStatusStep(StatusStep.Contact)
        setMessage("Next Step: Contact the client to interview and make a hiring decision.")
        break
      case JobCandidateStatus.Contacted:
        setStatusStep(StatusStep.Decide)
        setMessage("Next Step: Contact the client to interview and make a hiring decision.")
        break
      case JobCandidateStatus.Hired:
        setStatusStep(StatusStep.Done)
        setMessage("Congratulations, this candidate has been hired!")
        break
      case JobCandidateStatus.Declined:
        setStatusStep(StatusStep.Done)
        setMessage("This candidate has been declined for hiring.")
        break
      default:
        setStatusStep(StatusStep.Done)
        setMessage("")
        break
    }
  }, [currentState.value])

  const updateStatus = async (status: JobCandidateStatus) => {
    progress!.show(progressName)
    setIsProcessing(true)
    
    const input: UpdateJobCandidateInput = {
      id: jobCandidate.id,
      status: status
    }

    const update = await jobStore!.updateJobCandidate(input)
      .catch ((error: Error) => {
        notify!.show("error", "Error updating candidate status")
      })

    if (update) {
      const activity: CreateUserActivityInput = {
        userId: userStore.user!.id,
        subjectType: SubjectType.JobCandidate,
        subjectId: jobCandidate.id,
        activityType: ActivityType.JobCandidateStatusUpdated,
        actorId: userStore.user!.id,
        actorName: userStore.user!.fullName,
        accountId: userStore.user!.accountId,
        details: `Status changed to ${status}`
      }
      userStore.createUserActivity(activity)
      onUpdateJobCandidate(update)
    }

    progress!.hide(progressName)
    setIsProcessing(false)
  }

  const onApply = async () => {
    setIsApplyDialogOpen(true)
  }

  const unlockCandidate = async () => {
    progress!.show(progressName)
    setIsProcessing(true)

    const update = await jobStore!.unlockJobCandidate(jobCandidate)
      .catch ((error: Error) => {
        notify!.show("error", error.message)
      })

    if (update) {
      await updateStatus(JobCandidateStatus.Unlocked)
      send(JobCandidateActions.UNLOCK)
      onUpdateJobCandidate(update)
      setIsUnlockDialogOpen(false)
    }

    progress!.hide(progressName)
    setIsProcessing(false)
  }

  const isActionDisabled = (action: string) => {
    return (currentState.nextEvents.findIndex((a: string) => a === action) < 0)
  }

  const finalStyle = {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText
  }

  const renderStepNumber = (step: number, active: boolean) => {
    let bgColor = "text.disabled"
    if (active) {
      bgColor = "secondary.main"
    } else if (step === StatusStep.Decide && statusStep === StatusStep.Done) {
      if (currentState.value === JobCandidateStatus.Declined) {
        bgColor = "error.dark"
      } else if (currentState.value === JobCandidateStatus.Hired) {
        bgColor = "success.dark"
      }
    }
    return (
      <Box width={21} height={21} borderRadius={10} bgcolor={bgColor} color="info.contrastText" textAlign="center">
        <Typography variant="body2" className={classes.stepText}>{step}</Typography>
      </Box>
    )
  }

  const renderInitiateStep = () => {
    // TODO: Should we check to make sure the job post is published first? 
    // const isUnpublished = jobStore.isJobPostUnpublished(jobCandidate.jobPost!)
    // const isDisabled = isUnpublished
    if (currentState.matches(JobCandidateStatus.Interested)) {
      return (
        <Box sx={{textAlign: "center"}}>
          <Button
            variant="contained"
            color="secondary"
            className={classes.buttonStyle}
            onClick={async () => {
              await updateStatus(JobCandidateStatus.Invited)
              // notify.show("success", 'Invited Candidate to Apply')
              send(JobCandidateActions.INVITE)
            }}
            // disabled={isDisabled}
          >
            Invite
          </Button>
        </Box>
      )
    } else if (currentState.matches(JobCandidateStatus.Invited)) {
      return (
        <Box sx={{textAlign: "center"}}>
          <Button
            variant="outlined"
            color="secondary"
            className={classes.buttonStyle}
            onClick={() => { }}
            disabled={true}
          >
            Invited
          </Button>
        </Box>
      )
    } else {
      // Assume applied?
      return (
        <Box sx={{textAlign: "center"}}>
          <Button
            variant="outlined"
            color="secondary"
            className={classes.buttonStyle}
            onClick={() => { }}
            disabled={true}
          >
            Applied
          </Button>
        </Box>
      )
    }
  }

  const renderReviewStep = () => {
    if (currentState.matches(JobCandidateStatus.Applied) || currentState.matches(JobCandidateStatus.Reviewing)) {
      return (
        <Box sx={{textAlign: "center"}}>
          <Button
            variant="contained"
            color="secondary"
            className={classes.buttonStyle}
            onClick={async () => {
              if (onClickProfile) {
                onClickProfile()
              }
            }}
          >
            Profile
          </Button>
        </Box>
      )
    } else {
      return (
        <Box sx={{textAlign: "center"}}>
          <Button
            variant="outlined"
            color="secondary"
            className={classes.buttonStyle}
            onClick={() => {
              if (onClickProfile) {
                onClickProfile()
              }
            }}
            disabled={!onClickProfile}
          >
            Profile
          </Button>
        </Box>
      )
    }
  }

  const renderRateStep = () => {

    let isAccepted = false
    let isReviewing = false
    let isRejected = false

    switch (currentState.value) {
      case JobCandidateStatus.Accepted:
      case JobCandidateStatus.Unlocked:
      case JobCandidateStatus.Contacted:
      case JobCandidateStatus.Hired:
        isAccepted = true
        break
      case JobCandidateStatus.Reviewing:
        isReviewing = true
        break
      case JobCandidateStatus.Rejected:
      case JobCandidateStatus.Declined:
        isRejected = true
        break
    }

    return (
      <ButtonGroup>
        <Button
          variant={isAccepted ? "contained" : "outlined"}
          color="secondary"
          className={classes.iconButtonStyle}
          disabled={isActionDisabled(JobCandidateActions.ACCEPT)}
          onClick={async () => {
            await updateStatus(JobCandidateStatus.Accepted)
            send(JobCandidateActions.ACCEPT)
          }}
          startIcon={ isAccepted ? <ThumbUp/> : <ThumbUpOutlined/> }
        > </Button>
        <Button
          variant={isReviewing ? "contained" : "outlined"}
          color="secondary"
          className={classes.iconButtonStyle}
          disabled={isActionDisabled(JobCandidateActions.REVIEW)}
          onClick={async () => {
            await updateStatus(JobCandidateStatus.Reviewing)
            send(JobCandidateActions.REVIEW)
          }}
          startIcon={ isReviewing ?  <ThumbsUpDown/> : <ThumbsUpDownOutlined/> }
        > </Button>
        <Button
          variant={isRejected ? "contained" : "outlined"}
          color="secondary"
          style={isRejected ? finalStyle : undefined}
          className={classes.iconButtonStyle}
          disabled={isActionDisabled(JobCandidateActions.REJECT)}
          onClick={async () => {
            await updateStatus(JobCandidateStatus.Rejected)
            send(JobCandidateActions.REJECT)
          }}
          startIcon={ isRejected ? <ThumbDown/> : <ThumbDownOutlined/> }
        > </Button>
      </ButtonGroup>
    )
  }

  const renderContactStep = () => {
    if (!jobCandidate.unlocked) {
      return (
        <Box sx={{textAlign: "center"}}>
          <Button
            variant={currentState.matches(JobCandidateStatus.Accepted) || currentState.matches(JobCandidateStatus.Reviewing) ? "contained" : "outlined"}
            color="secondary"
            className={classes.buttonStyle}
            disabled={isActionDisabled(JobCandidateActions.UNLOCK)}
            onClick={() => {
              setIsUnlockDialogOpen(true)
              // await unlockCandidate()
              // await updateStatus(JobCandidateStatus.Contacted)
              // send(JobCandidateActions.UNLOCK)
            }}
            startIcon={<LockOpen/>}
          >
            Unlock
          </Button>
        </Box>
      )
    } else {
      return (
        <Box sx={{textAlign: "center"}}>
          <Button
            variant={(currentState.matches(JobCandidateStatus.Hired) || currentState.matches(JobCandidateStatus.Declined) || currentState.matches(JobCandidateStatus.Contacted)) ? "outlined" : "contained"}
            color="secondary"
            className={classes.buttonStyle}
            disabled={isActionDisabled(JobCandidateActions.CONTACT)}
            onClick={async () => {
              if (!currentState.matches(JobCandidateStatus.Contacted) &&
                !currentState.matches(JobCandidateStatus.Hired) &&
                !currentState.matches(JobCandidateStatus.Declined)) {
                await updateStatus(JobCandidateStatus.Contacted)
                send(JobCandidateActions.CONTACT)
              }
              setIsContactDialogOpen(true)
            }}
            startIcon={<Phone/>}
          >
            Contact
          </Button>
        </Box>
      )
    }
  }

  const renderUnlockDialog = () => {
    if (!isUnlockDialogOpen) {
      return null
    }
    const jobPost = jobCandidate!.jobPost!
    return (
      <Dialog
        open={isUnlockDialogOpen}
        onClose={() => setIsUnlockDialogOpen(false)}
        fullWidth
        maxWidth="sm"
        scroll="paper"
      >
        <FormValidator onSubmit={unlockCandidate} autoComplete="off" name="unlockForm" id="unlockForm" className={classes.form}>
          <DialogTitle id="form-dialog-title">Unlock Candidate Contact Info</DialogTitle>
          <DialogContent dividers>
            <DialogContentText>Unlock the Candidate's contact information for this Job Post.</DialogContentText>
            <DialogContentText>{`You have ${jobPost.unlocksAvailable} unlocks remaining for this job post.`}</DialogContentText>
            {/* <DialogContentText className={classes.error}>
              {error}
            </DialogContentText> */}
            <Grid container direction="column">
              <Grid item>
                <CardValue label="Candidate">
                  {jobCandidate?.profile?.initials}
                </CardValue>
              </Grid>
              <Grid item>
                <CardValue label="Job Title">
                  {jobPost.title}
                </CardValue>
              </Grid>
              <Grid item>
                <CardValue label="Location">
                  {jobPost.location!.name}
                </CardValue>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Grid container xs={8} justifyContent="flex-end">
              <CancelButton onClick={() => setIsUnlockDialogOpen(false)} />
              <ProgressButton variant="contained" size="small" color="primary"
                              type="submit" className={classes.progressButton} processing={isProcessing}
                              disabled={jobPost.unlocksAvailable < 1 || isProcessing}>
                Unlock
              </ProgressButton>
            </Grid>
          </DialogActions>
        </FormValidator>
      </Dialog>
    )
  }

  const renderContactDialog = () => {
    if (!jobCandidate.profile || !jobCandidate.profile.user) {
      return null
    }

    const user = jobCandidate.profile.user

    const labelStyle = { flex: "0 0 100px" }
    return (
      <ModalDialog
        title={user.fullName}
        maxWidth="sm"
        open={isContactDialogOpen}
        onClose={() => setIsContactDialogOpen(false) }
      >
        <Grid container direction="column" justifyContent="flex-start">
          <CardValue label="Email">
            {user.email}
          </CardValue>
          <CardValue label="Phone">
            {phoneToNationalFormat(user.phone)}
          </CardValue>
        </Grid>
      </ModalDialog>
    )
  }

  const renderDecideStep = () => {
    return (
      <ButtonGroup>
        <Button
          variant={currentState.matches(JobCandidateStatus.Hired) ? "contained" : "outlined"}
          color="secondary"
          className={classes.buttonStyle}
          style={currentState.matches(JobCandidateStatus.Hired) ? finalStyle : undefined}
          disabled={isActionDisabled(JobCandidateActions.HIRE)}
          onClick={async () => {
            await updateStatus(JobCandidateStatus.Hired)
            send(JobCandidateActions.HIRE)
          }}
          // startIcon={ isSmall === false && <Check /> }
        >
          {currentState.matches(JobCandidateStatus.Hired) ? "Hired" : "Hire"}
        </Button>
        <Button
          variant={currentState.matches(JobCandidateStatus.Declined) ? "contained" : "outlined"}
          color="secondary"
          className={classes.buttonStyle}
          style={currentState.matches(JobCandidateStatus.Declined) ? finalStyle : undefined}
          disabled={isActionDisabled(JobCandidateActions.DECLINE)}
          onClick={async () => {
            await updateStatus(JobCandidateStatus.Declined)
            send(JobCandidateActions.DECLINE)
          }}
          // startIcon={ isSmall === false && <Clear /> }
        >
          {currentState.matches(JobCandidateStatus.Declined) ? "Declined" : "Decline"}
        </Button>
      </ButtonGroup>
    )
  }

  const renderApplyDialog = () => {
    const jobPost = jobCandidate!.jobPost!
    return (
      <JobInterestApplyDialog
        isOpen={isApplyDialogOpen}
        jobPost={jobPost}
        onSubmit={async () => {
          await updateStatus(JobCandidateStatus.Applied)
          send(JobCandidateActions.APPLY)
          setIsApplyDialogOpen(false)
        }}
        onClose={() => {
          setIsApplyDialogOpen(false)
        }}
      />
    )
  }

  enum StatusStep {
    Initiate= 1,
    Review,
    Rate,
    Contact,
    Decide,
    Done
  }

  // let statusStep: StatusStep
  // let message



  return (
    <Paper
      // bgcolor={theme.palette.grey[300]}
      elevation={ elevation }
    >
      {/* <Box
        sx={{
          pb: 2 
        }}
      >
        { renderContactButton() }
      </Box> */}
      <Box
        sx={{
          // backgroundColor: 
          p: 2,
          borderRadius: 10,
          border: (elevation !== undefined && elevation === 0) ? `1px solid ${theme.palette.grey[300]}` : null,
          // boxShadow: `rgba(0,0,0,0.15) 0px 1px 5px 0px`
        }}
      >
        <Grid
          container 
          justifyContent="space-between"
          alignItems="flex-start"
          spacing={2}
        >
          <Grid item xs={12} md={2}>
            <Box className={classes.boxSx}>
              { renderStepNumber(1, statusStep === StatusStep.Initiate)}
              <Typography gutterBottom align="center" variant="h6" noWrap
                          color={(statusStep === StatusStep.Initiate) ? "secondary" : "textSecondary"}>
                Initiate
              </Typography>
              { renderInitiateStep() }
            </Box>
          </Grid>
          <Grid item xs={12} md={2}>
            <Box className={classes.boxSx}>
              { renderStepNumber(2, statusStep === StatusStep.Review)}
              <Typography gutterBottom align="center" variant="h6" noWrap
                          color={(statusStep === StatusStep.Review) ? "secondary" : "textSecondary"}>
                Review
              </Typography>
              { renderReviewStep() }
            </Box>
          </Grid>
          <Grid item xs={12} md={3}>
            <Box className={classes.boxSx}>
              { renderStepNumber(3, (statusStep === StatusStep.Review && !isActionDisabled(JobCandidateActions.ACCEPT)))}
              <Typography gutterBottom align="center" variant="h6" noWrap
                          color={((statusStep === StatusStep.Review && !isActionDisabled(JobCandidateActions.ACCEPT))) ? "secondary" : "textSecondary"}>
                Rate
              </Typography>
              { renderRateStep() }
            </Box>
          </Grid>
          <Grid item xs={12} md={2}>
            <Box className={classes.boxSx}>
              { renderStepNumber(4, statusStep === StatusStep.Contact)}
              <Typography gutterBottom align="center" variant="h6" noWrap
                          color={(statusStep === StatusStep.Contact) ? "secondary" : "textSecondary"}>

              Contact
              </Typography>
              { renderContactStep() }
            </Box>
          </Grid>
          <Grid item xs={12} md={3}>
            <Box className={classes.boxSx}>
              { renderStepNumber(5, statusStep === StatusStep.Decide)}
              <Typography gutterBottom align="center" variant="h6" noWrap
                          color={(statusStep === StatusStep.Decide) ? "secondary" : "textSecondary"}>
                Decide
              </Typography>
              { renderDecideStep() }
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body2" color="textPrimary">
              {message}
            </Typography>
          </Grid>
        </Grid>
      </Box>
      {isContactDialogOpen && renderContactDialog()}
      {isUnlockDialogOpen && renderUnlockDialog()}
      {isApplyDialogOpen && renderApplyDialog()}
    </Paper>
  )
}

export default JobCandidateStatusFlow
