/* eslint-disable @typescript-eslint/no-unused-vars */
import * as React from "react";
import {
  Box,
  Checkbox,
  createStyles,
  DialogContentText,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  InputAdornment,
  LinearProgress,
  Paper,
  Theme,
  Tooltip,
  Typography,
  withStyles,
  WithStyles,
  withWidth,
  WithWidth
} from "@material-ui/core";
import {
  DatePeriods,
  durationBetweenISODates,
  getErrorMessage,
  getISODateFromDateString,
  humanizeString,
  isoToLocalDateString,
  moneyToNumberFormat,
  numberToMoneyFormat,
  periodToISODate
} from "../../stores/StoreUtilities";
import JobPost from "../../model/JobPost";
import FormValidator from "../../components/form/FormValidator";
import TextFieldValidator from "../../components/form/TextFieldValidator";
import DatePickerValidator from "../../components/form/DatePickerValidator";
import {makeObservable, observable} from "mobx";
import {
  AddressType,
  CreateJobPostInput,
  EmploymentType,
  JobPostStatus,
  JobPostType,
  SubjectType,
  UpdateJobPostInput,
  WorkSetting
} from "../../API";
import Logger from "../Logger";
import AccountStore from "../../stores/AccountStore";
import Confirm from "../confirm/Confirm";
import {inject, observer} from "mobx-react";
import Progress from "../Progress";
import Visible from "../Visible";
import Contract from "../../model/Contract";
import Account from "../../model/Account";
import AddButton from "../AddButton";
import ResourceCache, {LocationOption, ServiceOption} from "../../stores/ResourceCache";
import {ActivityType} from "../../model/UserActivity";
import UserStore from "../../stores/UserStore";
import PlaceFilter from "../filter/PlaceFilter";
import JobStore from "../../stores/JobStore";
import AppDialog from "../dialog/AppDialog";
import DialogButton from "../form/DialogButton";
import JobPostDescriptionForm from "./JobPostDescriptionForm";
import JobPostQuestionsForm from "./JobPostQuestionsForm";
import Question from "../../model/Question";
import CancelButton from "../form/CancelButton";
import ContractForm from "../../pages/contracts/ContractForm";
import FileCopyIcon from '@material-ui/icons/FileCopy';
import Notify from "../notify/Notify";
import AddIcon from "@material-ui/icons/Add";
import IndustrySelector from "../form/IndustrySelector";
import intersection from "lodash.intersection";
import ServiceFilter from "../filter/ServiceFilter";
import ServiceGroup from "../../model/ServiceGroup";
import WorkSettingSelector from "../form/WorkSettingSelector";

const styles = (theme: Theme) => createStyles({
  progressButton: {
    minWidth: 80
  },
  employmentType: {
    minWidth: 273
  },
  securityClearance: {

  },
  hiringDate: {
    minWidth: 273
  },
  formGroupRow: {
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "nowrap",
    marginTop: theme.spacing(1)
  },
  copyIcon: {
    float: "right",
    padding: 0,
    color: theme.palette.secondary.main
  },
  formGroup: {
    marginBottom: 0,
    paddingBottom: 0
  },
  fieldLabel: {
    fontSize: 12,
    fontWeight: 400,
    color: theme.palette.text.secondary,
    paddingTop: theme.spacing(1),
    paddingBottom: 0
  },
  shaded: {
    backgroundColor: theme.palette.background.default
  },
  placeFilter: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    borderRadius: 10
  }
})

export class JobPostViewModel {
  @observable id: string 
  @observable accountId: string
  @observable account?: Account
  @observable contractId: string
  @observable contract?: Contract 
  @observable locationId: string
  @observable locationOption?: LocationOption
  @observable serviceId: string
  @observable serviceOption?: ServiceOption
  @observable serviceGroup?: ServiceGroup
  @observable industryOptions?: string[]
  @observable industries?: string[]
  @observable title: string
  @observable summary: string
  @observable description: string
  @observable hiringDate?: string
  @observable hiringPeriod: string | null 
  @observable startDate: string
  @observable endDate: string
  @observable duration: string 
  @observable openDate: string
  @observable closedDate: string
  @observable employmentType: EmploymentType
  @observable annualSalaryLow: string
  @observable status: JobPostStatus
  @observable annualSalaryHigh: string
  @observable isExpanded: boolean = false
  @observable openings: string
  @observable unlocksAvailable: string
  @observable questions: Question[]
  @observable securityClearance: string
  @observable workSettings?: WorkSetting[]

  constructor(jobPost: JobPost) {
    makeObservable(this)
    this.id = jobPost.id
    this.accountId = jobPost.accountId
    this.account = undefined
    this.contractId = jobPost.contractId
    this.contract = undefined 
    this.locationId = jobPost.locationId
    this.locationOption = undefined
    this.serviceId = jobPost.serviceId
    this.serviceOption = undefined
    this.serviceGroup = undefined
    this.industryOptions = []
    this.industries = jobPost.industries ?? []
    this.title = jobPost.title
    this.summary = jobPost.summary ? jobPost.summary : ''
    this.description = jobPost.description ?? ''
    this.hiringDate = jobPost.hiringDate
    this.hiringPeriod = jobPost.hiringPeriod
    this.startDate = isoToLocalDateString(jobPost.startDate)
    this.endDate = isoToLocalDateString(jobPost.endDate) 
    this.duration = (jobPost.startDate && jobPost.endDate) ? durationBetweenISODates(jobPost.startDate, jobPost.endDate) : ''
    this.openDate = isoToLocalDateString(jobPost.openDate)
    this.closedDate = isoToLocalDateString(jobPost.closedDate)
    this.employmentType = jobPost.employmentType
    this.annualSalaryLow = jobPost.annualSalaryLow ? numberToMoneyFormat(jobPost.annualSalaryLow, 0) : ''
    this.annualSalaryHigh = jobPost.annualSalaryHigh ? numberToMoneyFormat(jobPost.annualSalaryHigh, 0) : ''
    this.status = jobPost.status
    this.openings = String(jobPost.openings)
    this.unlocksAvailable = String(jobPost.unlocksAvailable ?? 0)
    this.questions = jobPost.questions ?? []
    this.securityClearance = jobPost.securityClearance
    this.workSettings = jobPost.workSettings ?? [WorkSetting.InPerson]
  }
}

export enum JobPostFormCloseReason {
  SaveJobPost,
  CancelDialog,
  CancelForm,
  DeleteJobPost
}

export interface IJobPostFormDelegate {
  didCreate(jobPost: JobPost): void 
  didDelete(jobPost: JobPost): void 
  didEdit(jobPost: JobPost): Promise<void> 
  didClose(reason: JobPostFormCloseReason): void
}

interface IJobPostFormProps extends IJobPostFormDelegate {
  jobPost: JobPost
  isFormOpen?: boolean
  renderAsDialog: boolean 
  
  // Stores: 
  accountStore?: AccountStore
  jobStore?: JobStore
  userStore?: UserStore
  confirm?: Confirm
  notify?: Notify
  progress?: Progress
  resourceCache?: ResourceCache
}

@inject("accountStore", "jobStore", "userStore", "profileStore", "resourceCache", "progress", "confirm", "notify")
@observer
class JobPostForm extends React.Component<WithStyles<typeof styles> & IJobPostFormProps & WithWidth> {
  @observable isLoading = true 
  @observable isProcessing = false
  @observable accounts: Account[] = []
  @observable contracts: Contract[] = []
  @observable viewModel?: JobPostViewModel
  @observable message = ""
  @observable showJobPostDescriptionDialog = false
  @observable showJobPostQuestionsDialog = false
  @observable showContractForm = false 
  @observable contract?: Contract
  @observable industryFilter?: string

  employmentTypes: EmploymentType[] = [EmploymentType.Contract, EmploymentType.FullTime, EmploymentType.PartTime, EmploymentType.Temporary, EmploymentType.Internship]

  constructor(props: WithStyles<typeof styles> & IJobPostFormProps & WithWidth) {
    super(props);
    makeObservable(this);
  }

  async componentDidMount() {
    const { 
      jobPost, 
      resourceCache, 
    } = this.props

    if (jobPost) {
      this.viewModel = new JobPostViewModel(jobPost)
      this.viewModel.locationOption = resourceCache!.getLocationOption(this.viewModel.locationId)
      this.viewModel.serviceOption = resourceCache!.getServiceOption(this.viewModel.serviceId)
      if (this.viewModel.serviceOption) {
        this.viewModel.serviceGroup = resourceCache!.getServiceGroupByName(this.viewModel.serviceOption.group)
      }
      this.handleChangeWorkSettings(this.viewModel.workSettings ?? [])

      // Accounts 
      await this.loadAccounts() 
      this.viewModel.account = this.accounts.find((a: Account) => a.id === this.viewModel!.accountId)
      if (this.viewModel.account) {
        if (this.viewModel.serviceOption) {
          this.viewModel.industryOptions = this.viewModel.serviceOption.industries
        } else {
          this.viewModel.industryOptions = this.viewModel.account.industries
        }
        this.handleChangeIndustries(this.viewModel.industries ?? [])
      }
      
      // Contracts 
      await this.loadContracts(this.viewModel.accountId)
      
      // When editing an existing job post, the contract will have already been selected.  
      if (this.viewModel.contractId) {
        this.viewModel.contract = this.contracts.find(contract => contract.id === this.viewModel!.contractId)
      }
    } else {
      this.viewModel = undefined
    }
    this.isLoading = false 
  }

  componentDidUpdate(prevProps: any) {
    if (prevProps.jobPost.id !== this.props.jobPost.id) {
      this.isLoading = true
      this.componentDidMount()
    }
  }

  async loadAccounts() {
    const { accountStore, userStore } = this.props
    if (userStore!.user?.isAdminOrAgent) {
      const accounts: Account[] = await accountStore!.listAccounts();
      let filteredAccounts = accounts.filter((account) => {return account.industries.length > 0});
      const sorted = filteredAccounts.sort((a: Account, b: Account) => a.name.localeCompare(b.name))
      this.accounts = sorted 
    } else if (accountStore!.account) {
      this.accounts = [accountStore!.account]
    } else if (userStore!.user?.account) {
      this.accounts = [userStore!.user?.account]
    }
  }

  async loadContracts(accountId: string) {
    const { jobStore } = this.props
    let contracts: Contract[]
    contracts = await jobStore!.listContractsByAccount(accountId)
    this.contracts = contracts.sort((a: Contract, b: Contract) => a.name.localeCompare(b.name))
  }

  renderFormContent() {
    const { 
      classes, 
      resourceCache, 
      userStore,
      jobPost
    } = this.props

    const viewModel = this.viewModel!
    const isAdminOrAgent = userStore!.isAdminOrAgent
    let showJobCategory = false

    if (viewModel.serviceOption) {
      const service = resourceCache!.getService(viewModel.serviceOption.id)
      if (service && !service.active) {
        showJobCategory = true
      }
    }

    return (
      <Box>
        <Grid container spacing={1}>
          {isAdminOrAgent &&
            <Grid item xs={12} sm={6}>
              <TextFieldValidator
                type="text"
                validators={{ required: true }}
                name="account"
                label="Account"
                fullWidth
                required
                margin="dense"
                autocompleteOptions={{
                  freeSolo: false,
                  options: this.accounts,
                  value: viewModel.account ?? null,
                  getOptionLabel: (option: Account) => option?.name,
                  getOptionSelected: (option: Account, value: Account) => option.id === value.id,
                  onChange: this.handleChangeAccountSelection
                }}
              />
            </Grid>
          }
          <Grid item xs={12} sm={6}>
            <TextFieldValidator
              type="text"
              validators={{ required: true }}
              name="contract"
              label="Job Group"

              required
              margin="dense"
              autocompleteOptions={{
                freeSolo: false,
                options: this.contracts,
                value: viewModel.contract ?? null,
                getOptionLabel: (option: Contract) => option?.name,
                getOptionSelected: (option: Contract, value: Contract) => option.id === value.id,
                onChange: this.handleChangeContractSelection
              }}
              InputProps={{
                endAdornment: <InputAdornment position="end">
                  <IconButton style={{padding:0}} onClick={() => {
                    const account = viewModel.account!
                    const contract = new Contract({
                      accountId: account.id
                    })
                    this.contract = contract
                    this.showContractForm = true
                  }}>
                    <AddIcon />
                  </IconButton>
                </InputAdornment>,
              }}
            />
          </Grid>
        </Grid>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={6}>
            <IndustrySelector industries={viewModel.industryOptions} value={viewModel.industries}
                              onChange={this.handleChangeIndustries} required/>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextFieldValidator
              type="text"
              className={classes.securityClearance}
              name="securityClearance"
              label="Security Clearance"
              margin="normal"
              InputLabelProps={{ shrink: viewModel.securityClearance !== undefined }}
              autocompleteOptions={{
                freeSolo: false,
                options: resourceCache!.listSecurityClearances(),
                value: viewModel.securityClearance ?? null,
                onChange: (event: any, value: string, reason: any) => {
                  viewModel.securityClearance = value
                }
              }}
            />
          </Grid>
        </Grid>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={6}>
            <ServiceFilter
              freeSolo={true}
              variant="standard"
              margin="dense"
              required={true}
              value={viewModel.serviceOption ?? undefined}
              industry={this.industryFilter}
              onSelectService={this.handleSelectService}
              helperText="Select a job title"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Visible if={showJobCategory}>
              <TextFieldValidator
                type="text"
                validators={{ required: true }}
                name="serviceGroup"
                label="Job Category *"
                fullWidth
                margin="dense"
                autocompleteOptions={{
                  freeSolo: false,
                  options: resourceCache!.getServiceGroups(this.industryFilter),
                  value: viewModel.serviceGroup ?? null,
                  getOptionLabel: (group: ServiceGroup) => group?.name,
                  getOptionSelected: (group: ServiceGroup, value: ServiceGroup) => group.name === value.name,
                  onChange: (event: any, value: ServiceGroup, reason: any) => {
                    if (value) {
                      viewModel.serviceGroup = value
                      const industries = intersection(value.industries, viewModel.account?.industries)
                      viewModel.industryOptions = industries
                      viewModel.industries = industries
                      // Update temporary Service when the group is changed
                      const service = resourceCache!.getService(viewModel.serviceId)
                      if (service && service.active === false) {
                        service.serviceGroupId = value.id
                        service.serviceGroup = value
                        service.industries = value.industries
                        if (viewModel.serviceOption) {
                          viewModel.serviceOption.group = value.name
                          viewModel.serviceOption.industries = value.industries
                        }
                      }
                    } else {
                      viewModel.serviceGroup = undefined
                    }
                  }
                }}
              />
            </Visible>
          </Grid>
        </Grid>
        <Grid container spacing={1} className={classes.shaded}>
          <Grid item xs={12} sm={6}>
            <WorkSettingSelector value={viewModel.workSettings}
                                 onChange={this.handleChangeWorkSettings} required/>
          </Grid>
          <Grid item xs={12} sm={6}>
            <PlaceFilter
              variant="standard"
              value={viewModel.locationOption}
              required={true}
              className={classes.placeFilter}
              onSelectLocation={(value?: LocationOption) => {
                if (value) {
                  viewModel.locationOption = value
                  viewModel.locationId = value.id
                } else {
                  viewModel.locationOption = undefined
                  viewModel.locationId = ''
                }
              }}
            />
          </Grid>
        </Grid>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={6}>
            <TextFieldValidator
              type="text"
              validators={{ required: true }}
              className={classes.employmentType}
              required
              name="employmentType"
              label="Employment Type"
              margin="normal"
              InputLabelProps={{ shrink: viewModel.employmentType !== undefined }}
              autocompleteOptions={{
                freeSolo: false,
                options: this.employmentTypes,
                getOptionLabel: (option: string) => option ? humanizeString(option) : null,
                value: viewModel.employmentType ?? null,
                onChange: (event: any, value: EmploymentType, reason: any) => {
                  viewModel.employmentType = value
                }
              }}
            />
          </Grid>
          <Visible if={viewModel.employmentType === EmploymentType.FullTime}>
            <Grid item xs={6} sm={3}>
              <TextFieldValidator
                type="text"
                validators={{ required: false, isCurrency: true }}
                name="annualSalaryLow"
                label="Annual&nbsp;Salary&nbsp;(Low)"
                margin="normal"
                value={viewModel.annualSalaryLow}
                onChange={this.handleChangeJobPostFormField}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={6} sm={3}>
              <TextFieldValidator
                type="text"
                validators={{ required: false, isCurrency: true }}
                name="annualSalaryHigh"
                label="Annual&nbsp;Salary&nbsp;(High)"
                margin="normal"
                value={viewModel.annualSalaryHigh}
                onChange={this.handleChangeJobPostFormField}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
          </Visible>
        </Grid>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={6}>
            <TextFieldValidator
              type="text"
              validators={{ required: true }}
              className={classes.hiringDate}
              required
              name="hiringPeriod"
              label="Expected Hiring Date"
              helperText="When do you expect to hire?"
              margin="dense"
              InputLabelProps={{ shrink: viewModel.hiringPeriod !== undefined }}
              autocompleteOptions={{
                freeSolo: false,
                options: DatePeriods,
                value: viewModel.hiringPeriod,
                onChange: (event: any, value: string, reason: any) => {
                  viewModel.hiringPeriod = value
                }
              }}
            />
          </Grid>
          <Grid item xs={6} sm={3}>
            <DatePickerValidator
              disableToolbar
              variant="inline"
              format="MM/dd/yyyy"
              placeholder="MM/dd/yyyy"
              fullWidth
              margin="dense"
              autoOk
              id="date-picker-inline"
              label="Starting Date"
              name="startDate"
              value={viewModel.startDate}
              onChange={this.handleChangeJobPostFormField}
            />
          </Grid>
          <Grid item xs={6} sm={3}>
            <DatePickerValidator
              disableToolbar
              variant="inline"
              format="MM/dd/yyyy"
              placeholder="MM/dd/yyyy"
              fullWidth
              margin="dense"
              autoOk
              id="date-picker-inline"
              label="Est. Ending Date"
              name="endDate"
              value={viewModel.endDate}
              onChange={this.handleChangeJobPostFormField}
            />
          </Grid>
        </Grid>
        <Box pt={2}>
          <TextFieldValidator
            type="text"
            validators={{ required: true }}
            name="summary"
            label="Summary *"
            value={viewModel.summary}
            onChange={this.handleChangeJobPostFormField}
            fullWidth
            multiline
            rows={4}
            variant="outlined"
          />
        </Box>
        { viewModel.id &&
          <Grid container spacing={1}>
            <Grid item xs={6} sm={3}>
              <DatePickerValidator
                disableToolbar
                variant="inline"
                format="MM/dd/yyyy"
                placeholder="MM/dd/yyyy"
                margin="dense"
                fullWidth
                autoOk
                id="date-picker-inline"
                label="Publish Date"
                name="openDate"
                value={viewModel.openDate}
                // required
                onChange={this.handleChangeJobPostFormField}
                disabled={ userStore!.isAdminOrAgent ? false : true }
              />
            </Grid>
            <Grid item xs={6} sm={3}>
              <DatePickerValidator
                disableToolbar
                variant="inline"
                format="MM/dd/yyyy"
                placeholder="MM/dd/yyyy"
                margin="dense"
                fullWidth
                autoOk
                id="date-picker-inline"
                label="Close Date"
                name="closedDate"
                value={viewModel.closedDate}
                onChange={this.handleChangeJobPostFormField}
                disabled={ userStore!.isAdminOrAgent ? false : true}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextFieldValidator
                type="number"
                validators={{ required: true, minValue: 0 }}
                name="unlocksAvailable"
                label="Candidate Unlocks Available"
                value={viewModel.unlocksAvailable}
                onChange={this.handleChangeJobPostFormField}
                fullWidth
                margin="dense"
                InputLabelProps={{ shrink: true }}
                style={{paddingTop:0}}
                disabled={ userStore!.isAdminOrAgent ? false : true}
              />
            </Grid>
          </Grid>
        }
        {/* { userStore?.isAdminOrAgent &&
        } */}
        {/* TODO: Hide number of openings for now. Circle back after MVP.
        <Grid container justifyContent="space-between" wrap="nowrap">
          <TextFieldValidator
            type="number"
            validators={{ required: true, minValue: 1 }}
            name="openings"
            label="Openings"
            value={viewModel.openings}
            onChange={this.handleChangeJobPostFormField}
            InputLabelProps={{ shrink: true }}
          />
        </Grid> */}
      </Box>
    )
  }

  renderOptionalButtons() {
    const viewModel = this.viewModel!

    return (
      <Box
        sx={{
          mt: 2,
          mb: 1 
        }}
      >
        <Grid container justifyContent="center" spacing={2}>
          <Grid item>
            <AddButton
              text={viewModel.description ? "Edit Job Description" : "Add Job Description"}
              tracking="AddJobPostDescription"
              buttonColor="primary"
              buttonVariant={viewModel.description ? "outlined" : "contained"}
              icon={viewModel.description ? "checkmark" : "add"}
              buttonSize="small"
              click={() => this.showJobPostDescriptionDialog = true}
            />
          </Grid>
          <Grid item>
            <AddButton
              text={viewModel.questions ? "Edit Screening Questions" : "Add Screening Questions"}
              tracking="AddJobPostScreeningQuestions"
              buttonColor="primary"
              buttonVariant={viewModel.questions.length > 0 ? "outlined" : "contained"}
              icon={viewModel.questions.length > 0 ? "checkmark" : "add"}
              buttonSize="small"
              click={() => this.showJobPostQuestionsDialog = true}
            />
          </Grid>
          {/* TODO: Implement add-on options for Job Post: 
          <Grid item>
            <AddButton
              text="Add Options"
              tracking="AddJobPostOptions"
              buttonColor="primary"
              buttonVariant="outlined"
              buttonSize="small"
              click={() => { }}
            />
          </Grid> */}
        </Grid>

      </Box>
    )
  }

  renderAsDialog() {
    const { isFormOpen, userStore, classes } = this.props

    const isAdminOrAgent = userStore!.isAdminOrAgent

    return (
      <AppDialog
        title='Job Posting' 
        dialogId='job-post-dialog' 
        formId='job-post-form' 
        isLoading={this.isLoading} 
        isOpen={ isFormOpen ? isFormOpen : false }
        maxWidth="md"
        scroll="paper"
        onCancel={(event: object, reason: string) => {
          // Prevent closing on background click
          if (reason !== "backdropClick") {
            this.handleCloseJobPostForm(JobPostFormCloseReason.CancelDialog)
          }
        }}
        onSubmit={async () => {
          await this.onSubmitJobPostForm()
        }}
        secondaryLabel="Delete"
        secondaryAction={async () => {
          const content = 'Do you want to delete this job post? (The operation cannot be undone.)'
          this.props.confirm!.show('Delete', content, ['Delete', 'Cancel'], async () => {
            await this.handleDeleteJobPost()
            return true 
          })
        }}
      >
        <DialogContentText>
          Edit the information about your job post here.
          {isAdminOrAgent && this.viewModel?.id &&
            <IconButton
              color="inherit"
              aria-label="Duplicate Job Post"
              className={classes.copyIcon}
              onClick={this.handleCopy}
            >
              <Tooltip title="Duplicate Job Post" placement="bottom-end">
                <FileCopyIcon/>
              </Tooltip>
            </IconButton>
          }
        </DialogContentText>
        <Box mt={0}>
          <DialogContentText color="error">
            {this.message}
          </DialogContentText>
        </Box>
        {this.renderFormContent()}
        {this.renderOptionalButtons()}
      </AppDialog>
    )
  }

  renderAsForm() {
    return (
      <Paper>
        <Box 
          sx={{ 
            flexGrow: 1, 
            height: 3 
          }}
        >
          { this.isLoading && 
            <LinearProgress 
              style={{ 
                height: 3, 
                backgroundColor: "#d72222" 
              }}
              // TODO: MUI 5.
              // sx={{
              //   height: 3,
              //   backgroundColor: "#d72222" // Logo red
              // }}
            />
          }
        </Box>
        <Box p={2}>
          <Box mt={1}>
            <Typography color="error">
              {this.message}
            </Typography>
          </Box>
          <FormValidator 
            onSubmit={() => {
              this.onSubmitJobPostForm()
            }} 
            autoComplete="off" 
            name="job-post-form" 
            id="job-post-form"
            density="normal"
          >
            {this.renderFormContent()}
            <Box pt={1}>
              <Grid container justifyContent="flex-end">
                <Grid item>
                  <CancelButton onClick={() => this.handleCloseJobPostForm(JobPostFormCloseReason.CancelForm)}
                  >
                    Cancel
                  </CancelButton>
                </Grid>
                <Grid item>
                  <DialogButton 
                    variant="primary" 
                    type="submit"
                  >
                    Next 
                  </DialogButton>
                </Grid>
              </Grid>
            </Box>
          </FormValidator>
        </Box>
      </Paper>
    )
  }

  render() {
    const { 
      renderAsDialog
    } = this.props
    
    if (this.viewModel === undefined) {
      return null 
    }

    return (
      <React.Fragment>
        { renderAsDialog ?
            this.renderAsDialog() 
          :
            this.renderAsForm() 
        }
        {this.showJobPostDescriptionDialog &&
          <JobPostDescriptionForm
            jobPost={this.getJobPostFromViewModel()}
            isFormOpen={this.showJobPostDescriptionDialog}
            renderAsDialog={true}
            didClose={() => {
              this.showJobPostDescriptionDialog = false
            }}
            didEdit={(jobPost: JobPost) => {
              this.viewModel!.description = jobPost.description
              this.showJobPostDescriptionDialog = false
            }}
          />
        }
        {this.showJobPostQuestionsDialog &&
          <JobPostQuestionsForm
            jobPost={this.getJobPostFromViewModel()}
            isFormOpen={this.showJobPostQuestionsDialog}
            renderAsDialog={true}
            didClose={() => {
              this.showJobPostQuestionsDialog = false
            }}
            didEdit={(jobPost: JobPost) => {
              this.viewModel!.questions = jobPost.questions
              this.showJobPostQuestionsDialog = false
            }}
          />
        }
        {this.showContractForm && 
          <ContractForm 
            accounts={ undefined } // Pass undefined here b/c we want the account to be tied to the account for this job post.
            contract={this.contract}
            isFormOpen={this.showContractForm} 
            didClose={() => {
              // this.stateService.send(ManageJobsActions.CLOSE) 
              
              this.showContractForm = false 
            }}
            didCreate={async (contract: Contract) => {
              // this.contracts.push(contract)
              // this.contract = contract 
              this.isLoading = true 
              await this.loadContracts(this.viewModel!.accountId)
              this.handleChangeContractSelection(undefined, contract, undefined)
              this.isLoading = false 
            }}
            didDelete={(contract: Contract) => {
              const index = this.contracts.findIndex(checkContract => checkContract.id === contract.id)
              this.contracts.splice(index, 1)
            }}
            didEdit={(contract: Contract) => {
              const index = this.contracts.findIndex(checkContract => checkContract.id === contract.id)
              if (index !== -1) {
                this.contracts[index] = contract
              }
            }}
          />
        }
      </React.Fragment>
    )
  }

  handleCloseJobPostForm = (reason: JobPostFormCloseReason) => {
    this.message = ""
    this.props.didClose(reason)
  }

  handleCopy = () => {
    const { notify } = this.props
    this.viewModel!.id = ""
    this.viewModel!.hiringDate = undefined
    this.viewModel!.hiringPeriod = null 
    notify!.show("success", "Job Post has been duplicated")
  }

  handleChangeJobPostFormField = (event: React.ChangeEvent<HTMLInputElement>) => {
    const name = event.target.name
    this.viewModel![name] = event.target.value
  }

  handleChangeIndustries = (industries: string[]) => {
    this.viewModel!.industries =industries

    if (industries.length === 1) {
      this.industryFilter = industries[0]
    } else if (this.viewModel!.industryOptions?.length === 1) {
      this.industryFilter = this.viewModel!.industryOptions[0]
    } else {
      this.industryFilter = ""
    }
  }

  handleChangeWorkSettings = (workSettings: WorkSetting[]) => {
    const { jobStore } = this.props
    
    this.viewModel!.workSettings = workSettings
    
    const locationOption: LocationOption | null = jobStore!.getLocationOptionForWorkSettings(workSettings, this.viewModel!.locationId)
    if (locationOption) {
      this.viewModel!.locationId = locationOption.id
      this.viewModel!.locationOption = locationOption
    }
  }

  handleChangeAccountSelection = async (event: any, value: any, reason: any) => {
    const viewModel = this.viewModel!
    const account = value as Account
    viewModel.account = account
    if(account) {
      viewModel.accountId = account?.id
      viewModel.contract = undefined
      viewModel.contractId = ''
      if (viewModel.serviceOption) {
        viewModel.industryOptions = intersection(account.industries, viewModel.serviceOption.industries)
        if (viewModel.industryOptions.length === 0) {
          // No overlap of account and service industries
          viewModel.industryOptions = account.industries
          this.handleChangeIndustries(viewModel.industryOptions)
          viewModel.serviceOption = undefined
        } else {
          viewModel.industries = intersection(viewModel.industryOptions, viewModel.industries)
        }
      } else {
        viewModel.industryOptions = account.industries
        viewModel.industries = viewModel.industryOptions
      }
      await this.loadContracts(account?.id)
    } else {
      viewModel.industryOptions = [];
      viewModel.industries = viewModel.industryOptions
    }
  }

  handleChangeContractSelection = (event: any, value: any, reason: any) => {
    const { resourceCache } = this.props
    const viewModel = this.viewModel! 
    const contract = value as Contract 
    if (contract) {
      viewModel.contract = contract 
      viewModel.contractId = contract.id
      
      // Populate job post fields with contract info 
      if (contract.locationId) {
        viewModel.locationId = contract.locationId
        viewModel.locationOption = resourceCache!.getLocationOption(contract.locationId)
      }
      if (contract.startDate) {
        viewModel.startDate = isoToLocalDateString(contract.startDate)
      }
      if (contract.endDate) {
        viewModel.endDate = isoToLocalDateString(contract.endDate)
      }

    } else {
      viewModel.contract = undefined
      viewModel.contractId = ''
    }
  }

  handleSelectService = (value?: ServiceOption) => {
    const { resourceCache } = this.props
    const viewModel = this.viewModel!
    if (value) {
      viewModel.serviceOption = value
      viewModel.serviceId = value.id
      if (viewModel.serviceOption) {
        viewModel.serviceGroup = resourceCache!.getServiceGroupByName(viewModel.serviceOption.group)
      }
      const industries = intersection(value.industries, viewModel.account?.industries)
      viewModel.industryOptions = industries
      viewModel.industries = industries
      viewModel.title = value.name
    } else {
      viewModel.serviceOption = undefined
      viewModel.serviceGroup = undefined
      viewModel.serviceId = ''
      viewModel.industryOptions = viewModel.account?.industries
      // this.handleChangeIndustries([])
    }
  }

  handleDeleteJobPost = async () => {
    const { jobStore, didDelete, progress, userStore } = this.props

    this.isProcessing = true 
    progress!.show('JobPostForm')
    this.message = ""
    try {
      if (this.viewModel!.id) {
        const jobPost = await jobStore!.deleteJobPost(this.viewModel!.id, userStore!)
        if (jobPost) {
          userStore!.logUserActivity(userStore!.user!.id, SubjectType.JobPost, this.viewModel!.id, ActivityType.JobPostDeleted,
            `${userStore!.user!.fullName} deleted the job post '${jobPost.fullName}'`)
        }
        await didDelete(jobPost!)
      }
      this.handleCloseJobPostForm(JobPostFormCloseReason.DeleteJobPost)
    } catch (error) {
      const message = 'Could not delete the job post.'
      Logger.error(message, error)
      this.message = message
    }
    this.isProcessing = false 
    progress!.hide('JobPostForm')
  }


  onSubmitJobPostForm = async () => {
    this.isProcessing = true
    this.props.progress!.show('JobPostForm') 
    this.message = ""

    let jobPost

    if (this.viewModel!.id) {
      jobPost = await this.editJobPost()
    } else {
      jobPost = await this.createJobPost()
    }
    if (jobPost) {
      this.isProcessing = false
      this.props.progress!.hide('JobPostForm')
      this.handleCloseJobPostForm(JobPostFormCloseReason.SaveJobPost)
    }
  }

  editJobPost = async () => {
    const { jobPost } = this.props
    const viewModel = this.viewModel!

    // let hiringDate
    // if ((!jobPost || jobPost.hiringDate === null || jobPost.hiringDate === undefined) && viewModel.hiringDate) {
    //   hiringDate = periodToISODate(viewModel.hiringDate)
    // } else if (viewModel.hiringDate) {
    //   hiringDate = getISODateFromDateString(viewModel.hiringDate)
    // } else {
    //   hiringDate = null
    // }

    const input: UpdateJobPostInput = {
      id: viewModel.id, 
      accountId: viewModel.accountId, 
      contractId: viewModel.contractId,
      locationId: viewModel.locationId,
      serviceId: viewModel.serviceId,
      industries: viewModel.industries ?? [],
      securityClearance: viewModel.securityClearance,
      workSettings: viewModel.workSettings,
      title: viewModel.title,
      summary: viewModel.summary,
      description: viewModel.description,
      hiringDate: viewModel.hiringPeriod ? periodToISODate(viewModel.hiringPeriod) : null,
      startDate: viewModel.startDate ? getISODateFromDateString(viewModel.startDate) : null,
      endDate: viewModel.endDate ? getISODateFromDateString(viewModel.endDate) : null,
      openDate: viewModel.openDate ? getISODateFromDateString(viewModel.openDate) : null,
      closedDate: viewModel.closedDate ? getISODateFromDateString(viewModel.closedDate) : null,
      employmentType: viewModel.employmentType,
      annualSalaryLow: moneyToNumberFormat(viewModel.annualSalaryLow, 0),
      annualSalaryHigh: moneyToNumberFormat(viewModel.annualSalaryHigh, 0),
      openings: parseInt(viewModel.openings),
      unlocksAvailable: parseInt(viewModel.unlocksAvailable),
      questions: viewModel.questions.length > 0 ? JSON.stringify(viewModel.questions) : null
    }
    try {
      const jobPost = await this.props.jobStore!.updateJobPost(input)
      jobPost!.contract = this.contracts!.find(contract => contract.id === jobPost!.contractId)
      if (this.accounts) {
        jobPost!.account = this.accounts!.find(account => account.id === jobPost!.accountId)
      }
      await this.props.didEdit(jobPost!)
      return jobPost
    } catch (error) {
      const message = 'Could not update the job post.'
      Logger.error(message, error)
      this.message = message
      return 
    }
  }

  createJobPost = async () => {
    const { userStore, renderAsDialog } = this.props
    const viewModel = this.viewModel!

    let status: JobPostStatus
    if (userStore!.isAdminOrAgent && renderAsDialog) {
      status = JobPostStatus.Draft
    } else {
      status = JobPostStatus.Cart
    }

    const input: CreateJobPostInput = {
      accountId: viewModel.accountId, 
      contractId: viewModel.contractId,
      locationId: viewModel.locationId,
      serviceId: viewModel.serviceId,
      industries: viewModel.industries,
      securityClearance: viewModel.securityClearance,
      workSettings: viewModel.workSettings,
      title: viewModel.title,
      summary: viewModel.summary,
      description: viewModel.description,
      hiringDate: viewModel.hiringPeriod ? periodToISODate(viewModel.hiringPeriod) : null,
      startDate: viewModel.startDate ? getISODateFromDateString(viewModel.startDate) : null,
      endDate: viewModel.endDate ? getISODateFromDateString(viewModel.endDate) : null,
      // We don't want the Job Post to be listed yet, or start out closed. 
      // We want the users to use the proper publishing tools in the UI. 
      // openDate: viewModel.openDate ? getISODateFromDateString(viewModel.openDate) : null, 
      // closedDate: viewModel.closedDate ? getISODateFromDateString(viewModel.closedDate) : null,
      employmentType: viewModel.employmentType,
      annualSalaryLow: moneyToNumberFormat(viewModel.annualSalaryLow, 0),
      annualSalaryHigh: moneyToNumberFormat(viewModel.annualSalaryHigh, 0),
      status,
      unlocksAvailable: parseInt(viewModel.unlocksAvailable),
      questions: viewModel.questions.length > 0 ? JSON.stringify(viewModel.questions) : null
    }
    if (userStore!.isAdminOrAgent) {
      input['jobPostType'] = JobPostType.RecruitingServices
    } else {
      input['jobPostType'] = JobPostType.SelfService
    }
    try {
      const jobPost = await this.props.jobStore!.createJobPost(input)
      jobPost!.contract = this.contracts!.find(contract => contract.id === jobPost!.contractId)
      if (this.accounts) {
        jobPost!.account = this.accounts!.find(account => account.id === jobPost!.accountId)
      }
      if (jobPost) {
        userStore!.logUserActivity(userStore!.user!.id, SubjectType.JobPost, jobPost.id, ActivityType.JobPostCreated,
          `${userStore!.user!.fullName} created job post for '${jobPost.fullName}'`)
        this.props.didCreate(jobPost!)
        return jobPost
      }
    } catch (error) {
      const message = 'Could not create the job post.'
      Logger.error(message, error)
      userStore!.logUserActivity(userStore!.user!.id, SubjectType.JobPost, "", ActivityType.Error,
        `${message} '${viewModel.title}'. ${getErrorMessage(error)}`)
      this.message = message
      return 
    }
  }

  getJobPostFromViewModel = () => {
    const {resourceCache} = this.props
    const vm = this.viewModel!
    const jobPost = new JobPost(
      {
        id: vm.id,
        accountId: vm.accountId,
        contractId: vm.contractId,
        locationId: vm.locationId,
        location: vm.locationId ? resourceCache!.getLocation(vm.locationId) : undefined,
        serviceId: vm.serviceId,
        service: vm.serviceId ? resourceCache!.getService(vm.serviceId) : undefined,
        industries: vm.industries,
        summary: vm.summary,
        description: vm.description,
        startDate: vm.startDate,
        endDate: vm.endDate,
        openDate: vm.openDate,
        closedDate: vm.closedDate,
        employmentType: vm.employmentType,
        annualSalaryLow: moneyToNumberFormat(vm.annualSalaryLow, 0),
        annualSalaryHigh: moneyToNumberFormat(vm.annualSalaryHigh, 0),
        jobCandidates: [],
        jobInterests: [],
        status: vm.status,
        openings: vm.openings,
        questions: JSON.stringify(vm.questions),
        workSettings: vm.workSettings,
        securityClearance: vm.securityClearance
      }
    )
    return (jobPost)
  }
}

export default withStyles(styles)(withWidth()(JobPostForm))