import * as React from 'react';
import classNames from 'classnames';
import {createStyles, Theme, withStyles, WithStyles} from "@material-ui/core";
import {inject, observer} from "mobx-react";
import Notify from "./Notify"
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import WarningIcon from '@material-ui/icons/Warning';
import Slide from "@material-ui/core/Slide/Slide";

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon,
};

const variantAutoHideDuration = {
  success: 3000,
  info: undefined,
  warning: undefined,
  error: undefined,
}

const styles = (theme: Theme) => createStyles({
  snackBar: {
    [theme.breakpoints.down('sm')]: {
      // top: 57,
      // right: 0,
      width: "calc(100vw - 12px)",
      marginTop: 54,
      paddingLeft: 6,
      paddingRight: 8,
    },
    [theme.breakpoints.up('sm')]: {
      // top: 75,
      // right: 7
      marginTop: 39,
      paddingLeft: 0,
      paddingRight: 0,
    },
    flexWrap: "nowrap",
  },
  success: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.success.dark,
    fontWeight: 600,
    padding: "0 10px 0 10px"
  },
  info: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.info.dark,
    fontWeight: 600,
    padding: "0 10px 0 10px"
  },
  warning: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.warning.dark, // amber[700],
    fontWeight: 600,
    padding: "0 10px 0 10px"
  },
  error: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.error.dark,
    fontWeight: 600,
    padding: "0 10px 0 10px"
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 1.0,
    marginRight: theme.spacing(1)
  },
  message: {
    display: 'flex',
    alignItems: 'flex-start',
    paddingRight: 25,
    lineHeight: 1.3
  },
  closeBtn: {
    color: theme.palette.common.white,
    cursor: 'pointer',
    // [theme.breakpoints.down('sm')]: {
    //   padding: "3px 6px 3px 5px",
    // },
    // [theme.breakpoints.up('sm')]: {
    //   padding: "3px 8px 3px 10px",
    // },
    // position: "absolute",
    // top: -5,
    // right: 0
  }
})

interface INotifyBarProps {
  notify?: Notify
}

function Transition(props: any) {
  const nodeRef = React.createRef();

  return <Slide {...props} direction="down" nodeRef={nodeRef}/>;
}

@inject("notify")
@observer
class NotifyBar extends React.Component<WithStyles<typeof styles> & INotifyBarProps> {

  onClose = (event: object, reason?: string) => {
    const {notify} = this.props
    
    if (notify && (!reason || reason !== "clickaway")) {
      notify.close()
    }
  }

  render() {
    const {notify, classes} = this.props
    const autoHideDuration = variantAutoHideDuration[notify!.variant]

    return (
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={notify!.open}
        autoHideDuration={autoHideDuration}
        onClose={this.onClose}
        className={classes.snackBar}
        TransitionComponent={Transition}
      >
        {this.snackBarContent()}
      </Snackbar>
    )
  }

  snackBarContent = () => {
    const { notify, classes } = this.props;

    const Icon = variantIcon[notify!.variant];

    return (
      <SnackbarContent
        className={classes[notify!.variant]}
        aria-describedby="client-snackbar"
        message={
          <div id="client-snackbar" className={classes.message}>
            <Icon className={classNames(classes.icon, classes.iconVariant)} />
            {notify!.message}
          </div>
        }
        action={[
          <IconButton
            key="close"
            aria-label="Close"
            color="inherit"
            className={classes.closeBtn}
            onClick={this.onClose}
            size="medium"
          >
            <CloseIcon className={classes.icon} />
          </IconButton>,
        ]}
      />
    );
  }
}

export default withStyles(styles)(NotifyBar)
