import { 
  Box,
  Grid, useMediaQuery, useTheme
} from '@material-ui/core'
import Section from './Section'
import TitleGroup from '../TitleGroup'

const TitleSection = ({
  superTitle,
  title, 
  subtitle,
  body, 
  titleAlignment,
  backgroundColor,
  background,
  children
}: {
  superTitle?: string 
  title: string 
  subtitle?: string 
  body?: string  
  titleAlignment?: 'left' | 'center' | 'right' 
  backgroundColor?: string 
  background?: string 
  children: React.ReactNode 
}) => {
  const theme = useTheme();
  const isExtraSmall = useMediaQuery(theme.breakpoints.down('sm'));

  const directionForAlignment = () => {
    if (titleAlignment === 'left') {
      return 'row'
    } else if (titleAlignment === 'right') {
      return 'row-reverse'
    }
    
    return 'column'
  }
  
  const direction = isExtraSmall ? 'column' :  directionForAlignment() 
  return (
    <Section
      backgroundColor={backgroundColor}
      background={background}
    >
      <Grid
        container
        direction={ direction }
        justifyContent="center"
        alignItems='center'
        wrap="nowrap"
        spacing={ isExtraSmall ? 1 : 5 }
        // spacing={0}
        // style={{
        //   height: "100%",
        //   flexGrow: 1
        // }}
      >
        <Grid 
          item
          xs={ direction === "column" ? undefined : 4 }
        >
          <TitleGroup 
            superTitle={superTitle}
            title={title}
            subtitle={subtitle}
            body={body}
            alignment={ titleAlignment === 'left' || titleAlignment === 'right' ? 'left' : 'center' }
            titleVariant='h2'
          />
        </Grid>
        <Grid 
          item
          xs={ direction === "column" ? undefined : 8 }
        >
          {children}
        </Grid>
      </Grid>
    </Section>
  )
}

export default TitleSection
