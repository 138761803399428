import Agency from "./Agency";
import Naics from "./Naics";
import OpportunityLocation from "./OpportunityLocation";
import PcsCategory from "./PcsCategory";


class OpportunityPost {
  index: number
  id: string
  title: string
  type: string
  archiveDate: string
  award: any
  classificationCode: string
  psc: PcsCategory
  fullParentPathCode: string
  agencyCode: string
  agency: Agency
  naicsCode: string
  naics: Naics
  noticeId: string
  officeAddress: any
  postedDate: string
  responseDeadLine: string
  typeOfSetAside: string
  locationId: string
  uiLink: string
  placeOfPerformance?: any
  location?: OpportunityLocation
  
  constructor (data: any) {
    this.index = data.index
    this.id = data.id
    this.title = data.title
    this.type = data.type
    this.archiveDate = data.archiveDate
    this.award = data.award
    this.classificationCode = data.classificationCode
    this.psc = data.psc
    this.fullParentPathCode = data.fullParentPathCode
    this.agencyCode = data.agencyCode
    this.agency = data.agency
    this.naicsCode = data.naicsCode
    this.naics = data.naics
    this.noticeId = data.noticeId
    this.officeAddress = data.officeAddress
    this.postedDate = data.postedDate
    this.responseDeadLine = data.responseDeadLine
    this.typeOfSetAside = data.typeOfSetAside
    this.locationId = data.locationId
    this.uiLink = data.uiLink
    this.placeOfPerformance = data.placeOfPerformance
    this.location = data.location ? new OpportunityLocation(data.location) : undefined
  }

}

export default OpportunityPost