import { FormEvent, useEffect, useState } from "react"
import Profile from "../../model/Profile"
import { useStores } from "../../stores/StoreProvider"
import { when } from "mobx"
import { Box, Button, DialogContentText, Typography } from "@material-ui/core"
import { Add } from "@material-ui/icons"
import Stack from "../../components/Stack"
import EditButton from "../../components/controls/EditButton"
import AppDialog from "../../components/dialog/AppDialog"
import DialogPanelInstructions from "../../components/panel/DialogPanelInstructions"
import TextFieldValidator from "../../components/form/TextFieldValidator"
import InstructionsPanel from "../../components/page/InstructionsPanel"
import * as APITypes from "../../API"

const ProfileCredentialsView = ({
  profile, 
  textVariant,
  textColor,
  onChange
}: {
  profile?: Profile 
  textVariant?: 'h2' | 'h4' | 'body1'
  textColor?: 'primary' | 'secondary' | 'inherit'
  onChange?(updatedProfile: Profile): Promise<void> 
}) => {

  const { profileStore, userStore, progress } = useStores()

  const title = "ProfileCredentialsView"
  const [isProfileEditable, setIsProfileEditable] = useState(false)
  const [showDialog, setShowDialog] = useState(false)
  const [credentials, setCredentials] = useState<string | null>(null)
  
  useEffect(() => {
    progress.show(title)

    when(
      // once...
      () => userStore!.isLoading === false,
      // ... then
      async () => {
        if (profile) {
          const isProfileEditable = userStore!.isProfileEditable(profile)
          setIsProfileEditable(isProfileEditable)

          setCredentials(profile?.credentials ?? null)
        }
        progress.hide(title)
      }
    )
  }, [
    profile, 
    userStore,
    progress 
  ])

  const renderString = () => {
    if (!profile) {
      return null 
    }

    return (
      <Typography variant={ textVariant } color={ textColor }>
        { profile.credentials }
      </Typography>
    )
  }

  const renderEditable = () => {
    if (profile!.credentials) {
      // String w/ edit button 
      return (
        <Stack 
          alignItems="center"
          spacing={1}
          wrap="nowrap"
        >
          {renderString()}
          { onChange && 
            <EditButton 
              size={ textVariant === "h4" || textVariant === "h2" ? "medium" : "small" }
              onClick={() => {
                setShowDialog(true)
              }}            
            />
          }
        </Stack>
      )
    } else {
      // Button 
      if (onChange) {
        return (
          <Box
            sx={{
              // px: 2
            }}
          >
            <Button
              variant="contained"
              color="secondary"
              startIcon={<Add />}
              onClick={() => {
                setShowDialog(true)
              }}
            >
              Credentials
            </Button>
          </Box>
        )
      } else {
        return null 
      }
    }
  }

  const renderDialog = () => {
    return (
      <AppDialog 
        title="Edit Profile Credentials"
        dialogId={""} 
        formId={""} 
        isLoading={false} 
        isOpen={showDialog} 
        onCancel={() => {
          setShowDialog(false)
        }} 
        onSubmit={async (event: FormEvent<HTMLFormElement>) => {
          try {
            const updateProfileInput: APITypes.UpdateProfileInput = {
              id: profile!.id, 
              credentials: credentials
            }
            const updatedProfile = await profileStore.updateProfile(updateProfileInput)
            await onChange!(updatedProfile!)
          } catch (error) {
            console.error("Could not save profile", error)
          }

          setShowDialog(false)
        }} 
      >
        <InstructionsPanel 
          text={[
            "Enter your credentials, separated by commas, as you would like them to appear after your name in your profile.",
            "Example: PMP, CQCM"
          ]}
        />
        <Stack
          direction="column"
          sx={{
            pt: 2, 
            pb: 3 
          }}
        >
          <TextFieldValidator 
            type="text"
            label="Credentials"
            placeholder="Enter your credentials to show after your profile name"
            fullWidth
            value={ credentials ?? "" }
            // validators={{ maxLength: 15 }}
            onChange={(event: any) => {
              const { value } = event.target 
              if (value.length < 15) {
                setCredentials(value)
              } 
            }}
          />
        </Stack>
      </AppDialog>
    )
  }

  return (
    <Box>
      { isProfileEditable ? 
        renderEditable() 
      : 
        renderString()
      }
      { renderDialog() }
    </Box>
  )
}

export default ProfileCredentialsView
