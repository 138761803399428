import { 
  Box, 
  Grid, 
  Paper, 
  Table, 
  TableBody, 
  TableCell, 
  TableContainer, 
  TableHead, 
  TableRow,  
  Typography, 
  useTheme
} from "@material-ui/core"
import { Skeleton } from "@material-ui/lab"
import React from "react";
import CardValue from "../../components/CardValue";
import ControlTower from "../../components/ControlTower";
import Price from "../../model/stripe/Price";
import { numberToMoneyFormatOrLoading } from "../../stores/StoreUtilities";
import { StripeOrderItem } from "./StripePaymentCart"
import AddButton from "../../components/AddButton";

const StripeOrderItems = ({
  isLoading,
  orderItems,
  onDelete
}: {
  isLoading: boolean 
  orderItems: StripeOrderItem[]
  onDelete(orderItem: StripeOrderItem): void
}) => {
  const theme = useTheme()

  const renderView = (orderItem: StripeOrderItem) => {
    const { data, view } = orderItem
    return (
      <React.Fragment>
        <Grid container justifyContent="space-between" alignItems="center" spacing={1}>
          <Grid item xs={12} lg={10}>
            <Grid container direction="column" spacing={1}>
              <Grid item>
                {view}
              </Grid>
              { data.product && 
                <Grid item>
                  <CardValue
                    label="Description"
                  >
                    {data.product.name}
                  </CardValue>
                </Grid>
              }
              {/* { data.price && 
                <Grid item>
                  <CardValue
                    label="Price"
                  >
                    {data.price.unitPrice}
                  </CardValue>
                </Grid>
              } */}
              { (data.product === undefined || data.price === undefined) &&
                <Grid item>
                  <Box pt={2}>
                    <Typography color="error">Please press the "+ Price" button to select a product/price in order to purchase and be able to publish this Job Post.</Typography>
                  </Box>
                </Grid>
              }
            </Grid>
          </Grid>
          <Grid item xs={12} lg={2}>
            <Grid container direction="column" spacing={1}>
              <Grid item>
                <AddButton
                  text="Delete"
                  tracking="deleteJobPostCard"
                  buttonColor="primary"
                  buttonVariant="outlined"
                  icon="remove"
                  click={() => { onDelete(orderItem) }}
                />
              </Grid>
              { (data.product === undefined || data.price === undefined) &&
                <Grid item>
                  <AddButton
                    text="Price"
                    tracking="priceJobPost"
                    buttonColor="secondary"
                    buttonVariant="contained"
                    icon="add"
                    click={() => {
                      ControlTower.route(data.editRoute)
                    }}
                  />
                </Grid>
              }
              {/*<Button*/}
              {/*  variant="contained"*/}
              {/*  color="secondary"*/}
              {/*  onClick={() => {*/}
              {/*    ControlTower.route(data.editRoute)*/}
              {/*  }}*/}
              {/*>*/}
              {/*  Edit*/}
              {/*</Button>*/}
            </Grid>
          </Grid>
        </Grid>
      </React.Fragment>
    )
  }

  const renderOrderItems = () => {
    return (
      <TableContainer component={Box}>
        <Table 
          // className={classes.table} 
          aria-label="simple table"
        >
          <TableHead>
            <TableRow>
              <TableCell>
                Item 
              </TableCell>
              <TableCell>
                Unit Price  
              </TableCell>
              <TableCell align="center">
                Quantity 
              </TableCell>
              <TableCell align="right">
                Total   
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            { orderItems && orderItems.map((orderItem: StripeOrderItem, index: number) => {
              const { data } = orderItem
              const { product, quantity } = data 
              let baseUnitPrice
              if (product) {
                const price: Price | undefined = product.getPriceForQuantity(quantity)
                if (price) {
                  baseUnitPrice = price.unitPrice
                }
              }
              const unitPrice = data.unitPrice
              return (
                <TableRow key={`orderItem/${data.name}/${index}`}>
                  <TableCell component="th" scope="row">
                    {renderView(orderItem)}
                  </TableCell>
                  <TableCell>
                    { baseUnitPrice && unitPrice && (baseUnitPrice - unitPrice) > 0 && 
                      <Box>
                        <s>{numberToMoneyFormatOrLoading(baseUnitPrice)}</s>
                      </Box>
                    }
                    <Box
                      sx={{
                        color: theme.palette.primary.main 
                      }}
                    >
                      {numberToMoneyFormatOrLoading(data.unitPrice)}
                    </Box>
                  </TableCell>
                  <TableCell align="center">
                    {data.quantity} 
                  </TableCell>
                  <TableCell align="right">
                    {numberToMoneyFormatOrLoading(data.amountDue)}
                  </TableCell> 
                </TableRow>
              )
            })
            }
          </TableBody>
        </Table>
      </TableContainer>
    )
  }

  return (
    <Paper>
      <Box p={2}>
          <Box pb={2}>
            <Typography variant="h6" gutterBottom color="primary">Order Item(s)</Typography>
          </Box>
          { isLoading ? 
            <Box>
              <Skeleton variant="text" />
              <Skeleton variant="text" />
              <Skeleton variant="text" />
            </Box>
            :   
            renderOrderItems()
          }
          
        </Box>
    </Paper>
  )
}

export default StripeOrderItems
