/* eslint-disable @typescript-eslint/no-unused-vars */
import React from "react";
import {
  InputAdornment,
  TextField,
  withWidth,
  WithWidth
} from "@material-ui/core";
import { createStyles, Theme, WithStyles, WithTheme } from "@material-ui/core";
import { withStyles, withTheme } from "@material-ui/core/styles";
import ProfileStore from "../../../stores/ProfileStore";
import { makeObservable, observable } from "mobx";
import { inject, observer } from "mobx-react";
import UserStore from "../../../stores/UserStore";
import AccountStore from "../../../stores/AccountStore";
import Progress from "../../Progress";
import SearchIcon from "@material-ui/icons/Search";


const styles = (theme: Theme) => createStyles({
  filterTextField: {
    width: "100%",
    flexGrow: 1, 
    backgroundColor: theme.palette.background.paper,
    borderRadius: 10
  }
})

export interface IOpportunityTitleFilterProps {
  onSearchTitleOpportunity: (val:string) => void;
  value?: String
  accountStore?: AccountStore
  profileStore?: ProfileStore
  progress?: Progress
  userStore?: UserStore 
}

@inject("accountStore", "profileStore", "userStore", "progress")
@observer
class TitleFilter extends React.Component<WithStyles<typeof styles> & WithTheme & IOpportunityTitleFilterProps & WithWidth> {

  @observable titleFilter?: any

  constructor(props: any) {
    super(props)
    makeObservable(this)
  }

  componentDidMount() {
    const { value } = this.props
    this.titleFilter = value
  }

  componentDidUpdate(prevProps: any) {
    if (prevProps.value !== this.props.value) {
      this.titleFilter = this.props.value
    }
  }

  render() {
    const { classes } = this.props

    return (
      <div >
            <TextField
              className={classes.filterTextField}
              variant="outlined"
              size="small"
              name="titleFilter"
              label="Title"
              value={this.titleFilter ?? null}
              onChange={this.handleChange}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                 )
                }}
            />
          </div>
    )
  }

  handleChange = (e: any) => {
    if (e.target.value) {
      this.titleFilter = e.target.value  
    } else {
      this.titleFilter = null
    }
    this.props.onSearchTitleOpportunity(this.titleFilter)
  }

}

export default withTheme(withStyles(styles)(withWidth()(TitleFilter)))