import { Box, Typography, useTheme } from "@material-ui/core"
import Stack from "../../components/Stack"
import ServiceRequest from "../../model/ServiceRequest"
import ServiceRequestTypeChip from "./ServiceRequestTypeChip"
import CardValue from "../../components/CardValue"
import LocationOn from "@material-ui/icons/LocationOn"
import { durationBetweenISODates } from "../../stores/StoreUtilities"
import Schedule from "@material-ui/icons/Schedule"
import JobPost from "../../model/JobPost"
import Contract from "../../model/Contract"
import { useEffect, useState } from "react"
import { when } from "mobx"
import { useStores } from "../../stores/StoreProvider"

const ServiceRequestCard = ({
  serviceRequest
}: {
  serviceRequest: ServiceRequest
}) => {
  const theme = useTheme()
  const { jobStore } = useStores()

  const [contracts, setContracts] = useState<Contract[]>([])

  useEffect(() => {
    const init = async () => {
      const contracts: Contract[] = []
      serviceRequest.jobPosts.forEach(jobPost => {
        const found = contracts.find(checkContract => checkContract.id === jobPost.contractId)
        if (!found) {
          contracts.push(jobPost.contract!)
        } 
      })
      setContracts(contracts)
    }

    when(
      () => jobStore.isLoading === false, 
      () => {
        init() 
      }
    )
  }, [

  ])
  
  const renderContract = (contract: Contract) => {
    const duration = durationBetweenISODates(contract.startDate, contract.endDate)
    return (
      <Box
        bgcolor="hsl(218, 0%, 98%)"
        borderRadius={theme.spacing(1)}
        p={2}
      >
        <Stack
          direction="column"
          spacing={1}
        >
          <Typography variant="body1">Job Group (Contract or Project)</Typography>
          { duration && <CardValue icon={<Schedule />}>{duration}</CardValue> }
          { contract.location && <CardValue icon={<LocationOn />}>{contract.location!.name}</CardValue> }
          <CardValue label="Name">{ contract.name === "" || !contract.name ? "Not set" : contract.name }</CardValue>
          <CardValue label="Category">{ contract.category === "" || !contract.category ? "Not set" : contract.category }</CardValue>
          <CardValue label="Value">{ contract.value === "" || !contract.value ? "Not set" : contract.value }</CardValue>
        </Stack>
      </Box>
    )
  }

  const renderContracts = () => {
    return (
      <Stack
        direction="column"
        spacing={2}
      >
        { contracts.map((contract: Contract) => {
          return (
            <Box key={contract.id}>
              { renderContract(contract) }
            </Box>
          )
        }) }
      </Stack>
    )
  }

  return (
    <Stack
      direction="column"
      spacing={1}
    >
      <Typography variant="h6">{serviceRequest.name}</Typography>
      <ServiceRequestTypeChip serviceRequestType={serviceRequest.serviceRequestType} />
      { renderContracts() }
    </Stack>
  )
}

export default ServiceRequestCard 
