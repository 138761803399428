import { Box, createStyles, Grid, Theme } from "@material-ui/core";
import { withStyles, WithStyles, withTheme, WithTheme } from "@material-ui/core/styles";
import { RouteComponentProps } from "@reach/router";
import { formatISO } from 'date-fns';
import SearchBar from "material-ui-search-bar";
import {autorun, makeObservable, observable, when} from "mobx";
import { inject, observer } from "mobx-react";
import * as React from 'react';
import {ModelUserFilterInput, ProfileStatus, UpdateUserInput, UserRole} from "../../API";
import TextFieldValidator from "../../components/form/TextFieldValidator";
import KanbanBoard, { IKanbanColumn, IKanbanRow } from "../../components/kanban/KanbanBoard";
import Notify from "../../components/notify/Notify";
import Progress from "../../components/Progress";
import Location from "../../model/Location";
import ServiceGroup from "../../model/ServiceGroup";
import User from "../../model/User";
import AccountStore from "../../stores/AccountStore";
import ProfileStore from "../../stores/ProfileStore";
import UserStore from "../../stores/UserStore";
import ApplicantCard from './ApplicantCard';
import FilterBar from "../../components/filter/FilterBar";
import ServiceFilter from "../../components/filter/ServiceFilter";
import LocationFilter from "../../components/filter/LocationFilter";
import {LocationOption, ServiceOption} from "../../stores/ResourceCache";
import SideDrawer from "../../components/page/SideDrawer";
import ProfilePage from "../profile/ProfilePage";
import * as APITypes from '../../API'

const styles = (theme: Theme) => createStyles({
  content: {
    flexGrow: 1,
    width: "100%",
  },
  searchbar: {
    display: "flex",
    flexGrow: 2,
    width: '100%',
    height: 38,
  },
  cardspace: {
    maxHeight: 'calc(100% - 76px)', // accommodate header
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    padding: 3, // card shadow was being cut off on left and bottom of cardspace, card size reduced by 3 to accommodate
  },
  card: {
    // minWidth: 567, // removing minWidth allows cards to be squished, if desired
  },
  searchIcon: {
    color: theme.palette.secondary.main
  },
  searchFilter: {
    flexGrow: 1
  },
  filter: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "left",
  },
  filterControl: {
    textAlign: "left",
  },
  sortFilter: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: 10,
    width: 200,
    height: 40,
  },
})

enum Columns {
  Pending,
  Submitted,
  Reviewing,
  Verifying,
  Declined,
  Accepted,
  Academy,
  Future,
  Inactive
}

interface IApplicantBoardProps {
  userStore?: UserStore
  accountStore?: AccountStore
  profileStore?: ProfileStore
  progress?: Progress
  notify?: Notify
}

enum SortOptions {
  Experience = "Experience",
  Name = "Name",
  Updated = "Updated"
}

@inject("userStore", "accountStore", "profileStore", "progress", "notify")
@observer
class ApplicantBoard extends React.Component<WithStyles<typeof styles> & RouteComponentProps & IApplicantBoardProps & WithTheme> {
  @observable usersUnfiltered: User[] = []
  @observable users: User[] = []
  @observable serviceGroups?: ServiceGroup[] = []
  @observable locations?: Location[] = []
  search: string = ""
  @observable sortBy?: any = { field: "default", asc: true }
  @observable sortFilter = SortOptions.Updated
  @observable serviceFilter?: ServiceOption
  @observable locationFilter?: LocationOption
  @observable columns: IKanbanColumn[] = []
  @observable applicant?: User
  @observable industryFilter?: string

  constructor(
    props: WithStyles<typeof styles> & RouteComponentProps & IApplicantBoardProps & WithTheme
  ) {
    super(props);
    makeObservable(this);
  }

  componentDidMount() {
    const { accountStore, userStore, progress } = this.props

    when(
      // once...
      () => !accountStore!.isLoading && accountStore!.account !== undefined,
      // ... then
      async () => {
        this.industryFilter = userStore!.industry
        progress!.show("ApplicantBoard")
        await this.loadUsers()
        progress!.hide("ApplicantBoard")
      }
    )

    autorun(
      async () => {
        if (!userStore!.isLoading && userStore!.industry !== this.industryFilter) {
          progress!.show("ApplicantBoard")
          this.industryFilter = userStore!.industry
          this.filterUsers()
          progress!.hide("ApplicantBoard")
        }
      }
    )
  }

  render() {
    const { classes } = this.props
    return (
      <Box paddingTop={1} width={'100%'}>

        {this.renderFilterBar()}

        <Grid container spacing={1} justifyContent={"flex-start"} className={classes.cardspace}>
          <KanbanBoard
            columns={this.columns}
            options={{
              columnWidth: 312,
              columnHeight: "calc(100vh - 280px)",
              renderCard: (row: IKanbanRow) => {
                return <ApplicantCard user={row} onClick={this.onClickApplicant} onChange={this.onChangeApplicant}/>
              }
            }}
            onDrop={this.handleUpdateUserStatus}
          />
        </Grid>
        {this.renderDrawer()}
      </Box>
    )
  }

  renderFilterBar = () => {
    const {classes } = this.props

    return (
      <Box py={1}>
        <FilterBar
          searchResultCount={ this.users.length }
        >
          <div className={classes.searchFilter} key={'searchFilter'}>
            <SearchBar className={classes.searchbar} value={this.search} onChange={this.onSearchChange}
                       classes={{icon: classes.searchIcon}} placeholder={"Search by applicant name"}
                       onRequestSearch={this.onRequestSearch} onCancelSearch={this.onCancelSearch}/>
          </div>

          <ServiceFilter
            value={this.serviceFilter}
            onSelectService={this.onSelectService}
          />

          <LocationFilter
            value={this.locationFilter}
            onSelectLocation={this.onSelectLocation}
          />

          <div className={classes.filter}>
            <div className={classes.filterControl}>
              <TextFieldValidator
                type="text"
                className={classes.sortFilter}
                variant="outlined"
                size="small"
                name="sortFilter"
                label="Sort By"
                onChange={this.onChangeSort}
                autocompleteOptions={{
                  options: [...Object.values(SortOptions)],
                  value: this.sortFilter
                }}
              />
            </div>
          </div>
        </FilterBar>
      </Box>
    )
  }

  renderDrawer = () => {
    if (!this.applicant || !this.applicant.profile) {
      return null
    }

    return (
      <SideDrawer
        title={"Applicant Profile"}
        isOpen={true}
        onClose={() => {
          this.handleUpdateApplicant(this.applicant!)
          this.applicant = undefined
        }}
        size="large"
      >
        <Box px={2} py={2} pt={0}>
          <ProfilePage profileId={this.applicant!.profile.id!} embed={true}/>
        </Box>

      </SideDrawer>
      )
  }

  loadUsers = async () => {
    const { accountStore } = this.props

    let filter: Array<ModelUserFilterInput | null> | null = []

    filter?.push({
      role: { eq: UserRole.Applicant }
    })

    const users = await accountStore!.listApplicantsByAccount(accountStore!.account!.id,
      { and: filter } as ModelUserFilterInput)
    this.sortUsers(users)
    this.usersUnfiltered = users
    this.filterUsers()
  }

  handleUpdateUserStatus = async (
    row: IKanbanRow, 
    sourceIndex: number, 
    sourceColumnId: string, 
    destinationIndex: number, 
    destinationColumnId: string
  ) => {
    const { userStore } = this.props 
    this.props.progress!.show('ApplicantBoard')
    try {
      const sourceColumn: IKanbanColumn = this.columns.find(column => column.id === sourceColumnId)!
      const destinationColumn: IKanbanColumn = this.columns.find(column => column.id === destinationColumnId)!

      sourceColumn.rows.splice(sourceIndex, 1);
      destinationColumn.rows.splice(destinationIndex, 0, row);

      const currentUser = row as User
      const profileStatus = ProfileStatus[destinationColumn.id]
      if (!profileStatus) {
        throw new Error('Column is not a valid profile status')
      }
      const input: APITypes.UpdateProfileInput = { 
        id: currentUser.profile!.id, 
        profileStatus 
      }
      const updatedProfile = await this.props.profileStore!.updateProfile(input)

      if (updatedProfile) {
        // Update user role if needed
        const newRole = updatedProfile.profileStatus === ProfileStatus.Accepted ? UserRole.Candidate : UserRole.Applicant
  
        if (updatedProfile.user && updatedProfile.user!.role !== newRole) {
          console.log(`Updating user role to: ${newRole}`)
          const updateUserInput: UpdateUserInput = {
            id: updatedProfile.user!.id,
            role: newRole
          }
  
          const updatedUser = await userStore!.updateUser(updateUserInput)
          if (updatedUser) {
            updatedProfile.user = updatedUser
          }
        }
      }

      const userProfile = this.usersUnfiltered.find(u => u.id === row.id)?.profile
      if (userProfile) {
        userProfile.profileStatus = profileStatus
        userProfile.updatedAt = formatISO(Date.now())
      }
      this.filterUsers()
    } catch (err: any) {
      this.props.notify!.show('error', `Could not update profile status: ${err.message}`)
    }
    this.props.progress!.hide('ApplicantBoard')
  }

  handleUpdateApplicant = async (applicant: User) => {
    const { userStore } = this.props
    const index = this.usersUnfiltered.findIndex((user: User) => applicant.id === user.id)
    if (index >= 0) {
      const updatedApplicant = await userStore!.getUserAndProfile(applicant.id)
      if (updatedApplicant) {
        this.usersUnfiltered[index] = updatedApplicant
        this.filterUsers()
      }
    }
  }

  onSearchChange = (value: string) => {
    this.search = value
  }

  onChangeSort = (event: any) => {
    this.sortFilter = event.target.value
    this.filterUsers()
  }

  onSelectService = (value?: ServiceOption) => {
    if (value) {
      this.serviceFilter = value
    } else {
      this.serviceFilter = undefined
    }
    this.filterUsers()
  }

  onSelectLocation = (value?: LocationOption) => {
    if (value) {
      this.locationFilter = value
    } else {
      this.locationFilter = undefined
    }
    this.filterUsers()
  }

  filterUsers = () => {
    let filteredUsers: User[] = []
    if (this.search === "" && !this.serviceFilter && !this.locationFilter) {
      filteredUsers = this.usersUnfiltered
    } else {
      const search = this.search!.toLowerCase().replace(/\s{2,}/g, " ")
      this.usersUnfiltered.forEach((user: User) => {
        const profile = user.profile
        if (profile) {
          if ((search === "" || this.searchName(user.fullName, search)) &&
            (!this.serviceFilter || profile.hasService(this.serviceFilter.id)) &&
            (!this.locationFilter || profile.hasLocation(this.locationFilter.id))
          ) {
            filteredUsers.push(user)
          }
        }
      })
    }

    if (this.industryFilter) {
      filteredUsers = filteredUsers.filter((u: User) => {
        return u.profile && u.profile.industries.indexOf(this.industryFilter!) >= 0
      })
    }

    if (this.sortFilter === SortOptions.Updated) {
      filteredUsers.sort((a: User, b: User) => {
        const aa = a.profile ? a.profile.updatedAt : a.updatedAt
        const bb = b.profile ? b.profile.updatedAt : b.updatedAt
        return bb.localeCompare(aa)
      })
    } else if (this.sortFilter === SortOptions.Experience) {
      filteredUsers.sort((a: User, b: User) => {
        const aa = a.profile?.federalExperience ?? 0
        const bb = b.profile?.federalExperience ?? 0
        return bb - aa
      })
    } else {
      filteredUsers.sort((a: User, b: User) => a.fullName.localeCompare(b.fullName))
    }

    this.users = filteredUsers

    let columns: IKanbanColumn[] = [
      { id: "Pending", title: "Pending", rows: [] },
      { id: "Submitted", title: "Submitted", rows: [] },
      { id: "Reviewing", title: "Reviewing", rows: [] },
      { id: "Verifying", title: "Verifying", rows: [] },
      { id: "Declined", title: "Declined", rows: [] },
      { id: "Accepted", title: "Accepted", rows: [] },
      { id: "Academy", title: "Academy", rows: [] },
      { id: "Future", title: "Future", rows: [] },
      { id: "Inactive", title: "Inactive", rows: [] },
    ]

    filteredUsers.forEach((user: User) => {
      const profile = user.profile
      if (profile) {
        const status = profile.profileStatus
        switch (status) {
          case ProfileStatus.Pending:
            columns[Columns.Pending].rows.push(user)
            break
          case ProfileStatus.Submitted:
            columns[Columns.Submitted].rows.push(user)
            break
          case ProfileStatus.Reviewing:
            columns[Columns.Reviewing].rows.push(user)
            break
          case ProfileStatus.Verifying:
            columns[Columns.Verifying].rows.push(user)
            break
          case ProfileStatus.Declined:
            columns[Columns.Declined].rows.push(user)
            break
          case ProfileStatus.Accepted:
            columns[Columns.Accepted].rows.push(user)
            break
          case ProfileStatus.Academy:
            columns[Columns.Academy].rows.push(user)
            break
          case ProfileStatus.Future:
            columns[Columns.Future].rows.push(user)
            break
          case ProfileStatus.Inactive:
            // columns[Columns.Inactive].rows.push(user)
            break
        }
      }
    })

    this.columns = columns
  }

  searchName = (userFullName: string, search: string) => {
    return (
      userFullName.toLowerCase().indexOf(search) >= 0
    )
  }

  sortUsers = (users: User[]) => {
    if (this.sortBy.field === "default") {
      if (this.sortBy.asc) {
        users.sort((a: User, b: User) => {
          if (a.lastName === b.lastName) {
            return a.firstName.localeCompare(b.firstName)
          } else {
            return a.lastName.localeCompare(b.lastName)
          }
        })
      } else {
        users.sort((a: User, b: User) => {
          if (a.lastName === b.lastName) {
            return b.firstName.localeCompare(a.firstName)
          } else {
            return b.lastName.localeCompare(a.lastName)
          }
        })
      }
    }
  }

  onRequestSearch = () => {
    this.search = this.search ? this.search.trim() : ""
    this.filterUsers()

    // this.loadUsers() // server-side filtering
  }

  onCancelSearch = () => {
    this.search = ""
    this.filterUsers()

    // this.loadUsers() // server-side filtering
  }

  onClickApplicant = (user: User) => {
    this.applicant = user
    // ControlTower.route(`${Routes.profile}/${user.profile?.id}`)
  }

  onChangeApplicant = (applicant: User) => {
    const index = this.usersUnfiltered.findIndex((user: User) => applicant.id === user.id)
    if (index >= 0) {
      this.usersUnfiltered[index] = applicant
      this.filterUsers()
    }

  }
}

export default withTheme((withStyles(styles)(ApplicantBoard)))


