import { IRouteData } from "../RoutesConfig"

export class RoutesConfigHelpers {
  static getRouteForPathname = (baseURL: string, pathname: string, routes: any): IRouteData | undefined => {
    // Chop off the initial "/" in the pathname. 
    const cleanPathname = new URL(pathname, baseURL).pathname  
    const matches: IRouteData[] = []
    Object.values(routes).forEach((routeData) => {
      const route = routeData as IRouteData
      // Chop off the initial "/" in the path. 
      const checkCleanPathname = route.pathname ? new URL(route.pathname, baseURL).pathname.split("/:")[0] : undefined // route.path ? route.path.split("/")[1] : ""
      if (checkCleanPathname === "/" && cleanPathname === "/") {
        // Special case 
        matches.unshift(route)
      } else if (checkCleanPathname && checkCleanPathname !== "/") {
        if (cleanPathname === checkCleanPathname) {
          matches.unshift(route)
        } else if (cleanPathname.includes(checkCleanPathname)) {
          matches.push(route)
        }
      } 
    })

    // Assume the first value is the correct match. 
    const route: IRouteData | undefined = matches.length > 0 ? matches[0] : undefined

    return route
  }

  static routeExistsForPathname = (baseURL: string, pathname: string, routes?: any): boolean => {
    const routeMatch = RoutesConfigHelpers.getRouteForPathname(baseURL, pathname, routes)
    if (routeMatch) {
      return true
    }

    return false
  }

  static routeRequiresLogin = (route: IRouteData): boolean => {
    // Check if the route has any requirements
    const routeRequirements = route.routeRequirements
    if (routeRequirements && routeRequirements.isAuthenticated) {
      return true 
    }

    return false
  }

  static roleMeetsRouteRequirements = (route: IRouteData, role: string): boolean => {
    // Check if the route has any role requirements
    const routeRequirements = route.routeRequirements
    if (routeRequirements && routeRequirements.roles) {
      if (!routeRequirements.roles.find((r: string) => r === role)) {
        return false
      }
    }
    
    return true
  }

  static isCurrentRoute(pathname: string, route: IRouteData) {
      const cleanPathname = pathname.split("?")[0].split('/')[1]
      const checkCleanPathname = route.pathname ? route.pathname.split("/")[1] : route.href! 
      
      if (cleanPathname === "" && checkCleanPathname === "") {
        return true 
      } else if (checkCleanPathname !== "" && String(cleanPathname).includes(checkCleanPathname)) {
        return true 
      }
      return false 
    }
}