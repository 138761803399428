import { API } from "aws-amplify"
import {getErrorMessage} from "../stores/StoreUtilities";
import Account from "../model/Account";
import Logger from "../components/Logger";
import Invoice from "../model/stripe/Invoice";
import User from "../model/User";

class BillingAPI {

  getProducts = async () => {
    try {
      const response = await API.get("BillingAPI", "/products", {})
      return response
    } catch (err) {
      const message = getErrorMessage(err)
      Logger.error("BillingAPI.getProducts error", message)
      throw new Error(message)
    }
  }

  getPrices = async () => {
    try {
      const response = await API.get("BillingAPI", "/prices", {})
      return response
    } catch (err) {
      const message = getErrorMessage(err)
      Logger.error("BillingAPI.getPrices error", message)
      throw new Error(message)
    }
  }

  getPlans = async () => {
    try {
      const response = await API.get("BillingAPI", "/plans", {})
      return response
    } catch (err) {
      const message = getErrorMessage(err)
      Logger.error("BillingAPI.getPlans error", message)
      throw new Error(message)
    }
  }

  getCoupons = async () => {
    try {
      const response = await API.get("BillingAPI", "/coupons", {})
      return response
    } catch (err) {
      const message = getErrorMessage(err)
      Logger.error("BillingAPI.getCoupons error", message)
      throw new Error(message)
    }
  }

  getCoupon = async (id: string) => {
    try {
      const response = await API.get("BillingAPI", `/coupons/${id}`, {})
      return response
    } catch (err) {
      const message = getErrorMessage(err)
      Logger.error("BillingAPI.getCoupon error", message)
      throw new Error(message)
    }
  }

  getCustomer = async (id: string) => {
    try {
      const response = await API.get("BillingAPI", `/customers/${id}`, {})
      return response
    } catch (err) {
      const message = getErrorMessage(err)
      Logger.error("BillingAPI.getCustomer error", message)
      throw new Error(message)
    }
  }

  createInvoice = async (user: User, account: Account, invoice: Invoice, source?: string) => {
    const init = {
      body: {
        user: {
          id: user.id,
          firstName: user.firstName,
          lastName: user.lastName,
          email: user.email,
          phone: user.phone
        },
        account: {
          id: account.id,
          name: account.name,
          address: account.address,
          city: account.city,
          state: account.state,
          postalCode: account.postalCode
        },
        invoice,
        source
      }
    }
    try {
      const response = await API.post("BillingAPI", "/invoices", init)
      return response
    } catch (err) {
      const message = this.getStripeErrorMessage(err)
      Logger.debug("BillingAPI.createInvoice error", message, init.body)
      throw new Error(message)
    }
  }

  deleteSource = async (customerId: string, sourceId: string) => {
    try {
      const response = await API.del("BillingAPI", `/sources/${customerId}/${sourceId}`, {})
      return response
    } catch (err) {
      const message = getErrorMessage(err)
      Logger.error("BillingAPI.deleteSource error", message)
      throw new Error(message)
    }

  }

  getCharges = async (customerId: string) => {
    try {
      const response = await API.get("BillingAPI", `/charges/${customerId}`, {})
      return response
    } catch (err) {
      const message = getErrorMessage(err)
      Logger.error("BillingAPI.getCharges error", message)
      throw new Error(message)
    }
  }

  getStripeErrorMessage = (err: any) => {
    let message = "Error"

    if (err.response && err.response.data && err.response.data.code) {
      const code = err.response.data.code
      if (code === 'card_declined') {
        message = "Card was declined"
        const decline_code = err.response.data.decline_code
        if (decline_code === 'insufficient_funds') {
          message += " - insufficient funds."
        } else if (decline_code === 'lost_card') {
          message += " - lost card."
        } else if (decline_code === 'stolen_card') {
          message += " - stolen card."
        }
      } else if (code === 'expired_card') {
        message = "Card is expired."
      } else if (code === 'processing_error') {
        message = "Card processing error."
      } else {
        message = `Card failed - ${code}.`
      }
    }
    else {
      message = getErrorMessage(err)
    }
    return message
  }


}

export default BillingAPI
