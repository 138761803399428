import { Box, Chip, Theme, createStyles, makeStyles } from "@material-ui/core"
import JobPost from "../../model/JobPost"
import * as APITypes from "../../API"
import classNames from "classnames"

const useStyles = makeStyles((theme: Theme) => createStyles({
  chips: {
    color: '#fff',
    fontWeight: 500,
    fontSize: 12, 
    // backgroundColor: '#2F733A' // Green?
    // backgroundColor: theme.palette.primary[200]
    // background: 'linear-gradient(to right, hsla(215, 35%, 37%, 1.0) 0%, hsla(215, 50%, 37%, 0.80) 100%)'
  },
  color1: {
    // background: 'linear-gradient(to right, hsla(215, 35%, 37%, 1.0) 0%, hsla(215, 50%, 37%, 0.80) 100%)'
    background: 'linear-gradient(to right, hsl(218, 33%, 27%) 0%, hsl(218, 33%, 47%) 100%)'
  },
  color2: {
    background: 'linear-gradient(to right, hsl(187, 35%, 37%) 0%, hsl(187, 35%, 57%) 100%)'
  },
  color3: {
    background: 'linear-gradient(to right, hsl(238, 37%, 37%) 0%, hsl(238, 37%, 57%) 100%)'
  }
}))

const JobPostTypeChip = ({
  jobPost
}: {
  jobPost: JobPost
}) => {
  const classes = useStyles() 
  if (jobPost.jobPostType === APITypes.JobPostType.RecruitingServices) {
    const combinedClassNames = classNames(classes.chips, classes.color1)
    return (
      <Box
        sx={{
          pb: 1,
          height: 32 
        }}
      >
        <Chip label={'Recruiting Services'} size="small" className={ combinedClassNames } />
      </Box>
    )
  } else if (jobPost.jobPostType === APITypes.JobPostType.StaffingServices) {
    const combinedClassNames = classNames(classes.chips, classes.color2)
    return (
      <Box
        sx={{
          pb: 1,
          height: 32 
        }}
      >
        <Chip label={'Staffing Services'} size="small" className={ combinedClassNames } />
      </Box>
    )
  } else if (jobPost.jobPostType === APITypes.JobPostType.SelfService) {
    const combinedClassNames = classNames(classes.chips, classes.color3)
    return (
      <Box
        sx={{
          pb: 1,
          height: 32 
        }}
      >
        <Chip label={'Self-service'} size="small" className={ combinedClassNames } />
      </Box>
    )
  } else {
    return null 
  }
}

export default JobPostTypeChip 
