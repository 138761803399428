import { Box, Grid, Typography, useMediaQuery, useTheme } from "@material-ui/core"
import { blueGrey } from "@material-ui/core/colors"
import { ReactNode } from "react"
import Stack from "../Stack"

const ImageGroup = ({
  imageSrc,
  imageTitle,
  alignment, 
  children 
}: {
  imageSrc?: string 
  imageTitle: string 
  alignment: 'imageLeft' | 'imageRight'
  children: ReactNode
}) => {
  const theme = useTheme();
  const isExtraSmall = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <Grid 
      container 
      direction={ isExtraSmall ? 'column' : (alignment === 'imageRight' ? 'row' : 'row-reverse') } 
      justifyContent="center"
      alignItems={ isExtraSmall ? "center" : "center" } 
    >
      <Grid 
        item 
        xs={12} 
        sm={7} 
      >
        <Box
          pb={{ 
            xs: 5, 
            lg: 0 
          }} 
          pr={{ 
            xs: 2, 
            lg: alignment === 'imageRight' ? 7 : 1 
          }}
          pl={{ 
            xs: 2, 
            lg: alignment === 'imageRight' ? 1 : 7 
          }}
        >
          {children}
        </Box>
      </Grid>
      <Grid 
        item 
        xs={12} 
        sm={5} 
      >
        <Box
          px={{ 
            xs: 2, 
            lg: 1 
          }} 
        >
          { imageSrc ? 
            <img 
              src={ imageSrc } 
              alt={ imageTitle } 
              width="100%" 
              height="auto" 
              style={{ 
                borderRadius: 10, 
                // borderWidth: 3, 
                // borderColor: '#6079A4', 
                // borderStyle: 'solid' 
                boxShadow: 'rgba(50, 57, 67, 0.25) 0px 5px 30px 0px'
              }} 
            />
          : 
            <Box
              bgcolor={blueGrey[300]}
              color={theme.palette.common.white}
              borderRadius={theme.spacing(1)}
              px={3}
              sx={{
                height: {
                  xs: "150px",
                  sm: "300px"
                },
                width: {
                  xs: "250px", 
                  sm: "400px"
                }
              }}
              boxShadow={`rgba(0, 0, 0, 0.15) 0px 5px 15px 0px`}
            >
              <Stack
                justifyContent="center"
                alignItems="center"
                sx={{
                  height: "100%"
                }}
              >
                <Typography>
                  {imageTitle}
                </Typography>
              </Stack>
            </Box>
          }
        </Box>
      </Grid>
    </Grid>
  )
}

export default ImageGroup
