import { API } from "aws-amplify"
import {getErrorMessage} from "../stores/StoreUtilities";
import Logger from "../components/Logger";

class AuthAPI {

  getAlias = async (alias: string) => {
    try {
      const response = await API.get("AuthAPI", `/alias/${alias}`, {})
      return response
    } catch (err) {
      const message = getErrorMessage(err)
      Logger.error("AuthAPI.getAliasStatus error", message)
      throw new Error(message)
    }
  }
}
export default AuthAPI