import * as React from 'react'
import {inject, observer} from "mobx-react";
import {autorun, makeObservable, observable} from "mobx";
import {Box, createStyles, Grid, Theme, Typography} from "@material-ui/core";
import AccountStore, {AccountStoreConstants} from "../../stores/AccountStore";
import {withStyles, WithStyles, withTheme, WithTheme} from "@material-ui/core/styles";
import {RouteComponentProps} from "@reach/router";
import User from "../../model/User";
import * as APITypes from "../../API";
import SearchBar from "material-ui-search-bar";
import ProfileStore from "../../stores/ProfileStore";
import CandidateCard from "./CandidateCard";
import Progress from "../../components/Progress";
import FilterBar from "../../components/filter/FilterBar";
import ServiceFilter from "../../components/filter/ServiceFilter";
import {LocationOption, ServiceOption} from "../../stores/ResourceCache";
import SideDrawer from "../../components/page/SideDrawer";
import ManageCandidatePage from "../manageCandidates/ManageCandidatePage";
import UserStore from "../../stores/UserStore";
import AddButton from '../../components/AddButton';
import Profile from '../../model/Profile';
import Stack from '../../components/Stack';
import PlaceFilter from "../../components/filter/PlaceFilter";
import {UserStatus} from "../../API";

const styles = (theme: Theme) => createStyles({
  content: {
    flexGrow: 1,
    width: "100%",
    // padding: 3,
    overflow: 'visible',
  },
  searchbar: {
    display: "flex",
    flexGrow: 2,
    width: '100%',
    height: 38
  },
  cardspace: {
    // width: "100%",
    overflow: 'visible',
    paddingBottom: theme.spacing(3), // card shadow was being cut off on left and bottom of cardspace, card size reduced by 3 to accommodate
    paddingLeft: theme.spacing(1), 
    paddingRight: theme.spacing(1),
    paddingTop: theme.spacing(1)
  },
  card: {
    // minWidth: 567, // removing minWidth allows cards to be squished, if desired
    minHeight: "100%"
  },
  searchIcon: {
    color: theme.palette.secondary.main
  },
  searchFilter: {
    flexGrow: 1,
    // width: 294
  },
  filter: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "left"
  },
  filterControl: {
    textAlign: "left"
  },
  statusFilter: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: 10,
  },
  locationFilter: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: 10,
  },
  serviceFilter: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: 10,
  },
  numFound: {
    flexGrow: 1,
    width: "100%",
    padding: 3,
    color: "initial",
  },
})

interface ICandidateListProps {
  accountStore?: AccountStore
  userStore?: UserStore
  profileStore?: ProfileStore
  progress?: Progress
}

@inject("accountStore", "userStore", "profileStore", "progress")
@observer
class CandidateList extends React.Component<WithStyles<typeof styles> & RouteComponentProps & ICandidateListProps & WithTheme> {
  @observable users: User[] = []
  @observable search: string = ""
  @observable sortBy?: any = {field: "default", asc: true}
  @observable serviceFilter?: ServiceOption
  @observable locationFilter?: LocationOption
  @observable candidate?: User
  @observable industryFilter?: string

  constructor(
    props: WithStyles<typeof styles> & RouteComponentProps & ICandidateListProps & WithTheme
  ) {
    super(props);
    makeObservable(this);
  }

  componentDidMount() {
    const { userStore } = this.props
    this.industryFilter = userStore!.industry

    autorun(
      async () => {
        if (!userStore!.isLoading && userStore!.industry !== this.industryFilter) {
          this.industryFilter = userStore!.industry
          this.onSearch()
        }
      }
    )
  }

  render() {
    const { classes, progress } = this.props
    return (
    <Box pt={1} pb={3} width={'100%'} className={classes.content}>

      {this.renderFilterBar()}

      <div className={classes.numFound}>
        { progress!.isVisible && "Loading..."}
        {!progress!.isVisible && `${this.users.length} Candidates found`}
      </div>
      { this.users.length === 0 && 
        <Box
          sx={{
            p: 3 
          }}
        >
          <Stack direction="column" justifyContent="center" alignItems="center" spacing={2}>
            <Typography variant="body1">
              Enter a first name or last name or email in the search box above, or select a job title and/or location.<br/>
              <em>Note, name searching is case-sensitive and you can only search for first or last name, but not both.</em>
            </Typography>
            <Typography variant="body1">Then, press the Search button.</Typography>
          </Stack>
        </Box>
      }
      <Grid container spacing={2} justifyContent="flex-start" alignItems="stretch" className={classes.cardspace}>
        {this.users.map((user: any, index: number) => {
          return (
          <Grid item key={index} xs={12} md={6} lg={4} className={classes.card}>
            <CandidateCard user={user} onCardClick={this.onClickCandidate} onChange={this.onChangeCandidate}/>
          </Grid>
          )
        })}
      </Grid>
      {this.renderDrawer()}
    </Box>
    )
  }

  renderFilterBar = () => {
    const {classes } = this.props

    return (
      <Box py={1}>
        <FilterBar
          searchResultCount={this.users.length}
        >
          <div className={classes.searchFilter} key={'searchFilter'}>
            <SearchBar className={classes.searchbar} value={this.search} onChange={this.onSearchChange}
                       classes={{icon: classes.searchIcon}} placeholder={"Search by first, last or email"}
                       onRequestSearch={this.onRequestSearch} onCancelSearch={this.onCancelSearch}/>
          </div>

          <ServiceFilter
            value={this.serviceFilter}
            onSelectService={this.onSelectService}
          />

          <PlaceFilter
            value={this.locationFilter}
            onSelectLocation={this.onSelectLocation}
          />

          <AddButton
            icon="search"
            text="Search"
            tracking="searchPeople"
            buttonColor="secondary"
            buttonVariant="contained"
            buttonSize="medium"
            click={this.onSearch}
            key="searchButton"
            // disabled={ (this.locationFilter === undefined && this.serviceFilter === undefined) && this.search === "" }
          />

        </FilterBar>
      </Box>
    )
  }

  renderDrawer = () => {
    if (!this.candidate || !this.candidate.profile) {
      return null
    }

    return (
      <SideDrawer
        title={"Manage Candidate"}
        isOpen={true}
        onClose={() => {
          this.onChangeCandidate(this.candidate!)
          this.candidate = undefined
        }}
        size="large"
      >
        <Box px={2} py={2} pt={0}>
          <ManageCandidatePage 
            embed={true}
            profileId={this.candidate!.profile.id!} 
            tab="profile" 
          />
        </Box>
      </SideDrawer>
    )
  }

  onSearch = async () => {
    const { accountStore, progress, profileStore } = this.props

    progress!.show("CandidateList")

    let users: User[] = []
    let profiles: Profile[] = []

    if (this.locationFilter && this.serviceFilter) {
      profiles = await profileStore!.listProfilesByLocationRadius(this.locationFilter.id, this.serviceFilter.id)
    } else if (this.serviceFilter) {
      profiles = await profileStore!.listProfilesByService(this.serviceFilter.id)
    } else if (this.locationFilter) {
      profiles = await profileStore!.listProfilesByLocationRadius(this.locationFilter.id)
    }
    // else {
    //   const filter: ModelProfileFilterInput = {
    //     profileStatus: {eq: ProfileStatus.Accepted}
    //   }
    //   profiles = await profileStore!.listProfiles(filter)
    // }

    if (this.industryFilter) {
      // Filter by industry
      profiles = profiles.filter((p: Profile) => p.industries.indexOf(this.industryFilter!) >= 0)
    }

    if (this.search === "") {
      // Handle search with no search term entered into the search box. 
      profiles.forEach(profile => {
        const user = profile.user
        if (user && user.role === APITypes.UserRole.Candidate) {
          user.profile = profile 
          users.push(user)
        }
      })
    } else {
      // Handle search with search term entered. 
      if (profiles.length > 0) {
        // We have a location and/or service filter applied, get users who match search term.  
        profiles.forEach(profile => {
          const user = profile.user
          const search = this.search!.toLowerCase().replace(/\s{2,}/g, " ")
          if (user && user.role === APITypes.UserRole.Candidate
            && (this.searchName(user.fullName, search)
            || this.searchEmail(user.email, this.search))) {
            user.profile = profile 
            users.push(user)
          }
        })
      } else if (this.search) {
        // No location or service filter, grab users who match the search term. 
        const filter: APITypes.ModelUserFilterInput | undefined = {
          and: [
            {
              role: { eq: APITypes.UserRole.Candidate },
              userStatus: { eq: UserStatus.Registered }
            },
            {
              or: [
                {firstName: {beginsWith: this.search}},
                {lastName: {beginsWith: this.search}},
                {email: {beginsWith: this.search.toLowerCase()}}
              ]
            }
          ]
        }
        const accountId = AccountStoreConstants.PRIMARY_ACCOUNT_ID
        users = await accountStore!.listCandidatesByAccount(accountId, filter)

        if (this.industryFilter) {
          // Filter by industry
          users = users.filter((u: User) => {
            return u.profile && u.profile.industries.indexOf(this.industryFilter!) >= 0
          })
        }
      }
    }

    this.users = users
    if (users.length > 0) {
      this.sortUsers()
    } 

    progress!.hide("CandidateList")
  }

  onSearchChange = (value: string) => {
    this.search = value ? value.trim() : ""
  }

  onSelectService = (value?: ServiceOption) => {
    if (value) {
      this.serviceFilter = value
    } else {
      this.serviceFilter = undefined
    }
  }

  onSelectLocation = (value?: LocationOption) => {
    if (value) {
      this.locationFilter = value
    } else {
      this.locationFilter = undefined
    }
  }

  searchName = (userFullName: string, search: string) => {
    const index = userFullName.toLowerCase().indexOf(search.toLowerCase())
    const isFound = index >= 0
    return isFound
  }

  searchEmail = (email: string, search:string) => {
    const index = email.toLowerCase().indexOf(search.toLowerCase())
    const isFound = index >= 0
    return isFound
  }

  sortUsers = () => {
    if (this.sortBy.field === "default") {
      if (this.sortBy.asc) {
        this.users.sort((a: User, b: User) => {
          if (a.lastName === b.lastName) {
            return a.firstName.localeCompare(b.firstName)
          } else {
            return a.lastName.localeCompare(b.lastName)
          }
        })
      } else {
        this.users.sort((a: User, b: User) => {
          if (a.lastName === b.lastName) {
            return b.firstName.localeCompare(a.firstName)
          } else {
            return b.lastName.localeCompare(a.lastName)
          }
        })
      }
    }
  }

  onRequestSearch = () => {
    this.search = this.search ? this.search.trim() : ""
    this.onSearch()
  }

  onCancelSearch = () => {
    this.search = ""
  }

  onClickCandidate= (user: User) => {
    this.candidate = user
  }

  onChangeCandidate = async (user: User) => {
    const {userStore} = this.props
    // Reload user
    const updatedUser = await userStore!.getUserAndProfile(user.id)
    if (updatedUser) {
      const index = this.users.findIndex((u: User) => u.id === user.id)
      if (index >= 0) {
        if (updatedUser.userStatus === UserStatus.Inactive) {
          this.users.splice(index, 1)
          this.users = [...this.users]
        } else {
          this.users[index] = updatedUser
        }
      }
    }
  }
}

export default withTheme((withStyles(styles)(CandidateList)))


