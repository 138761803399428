import * as React from 'react'
import {createStyles, Theme, withStyles, WithStyles} from "@material-ui/core/styles";
import ControlTower, {Routes} from "../../components/ControlTower";
import {Link} from "@material-ui/core";

const styles = (theme: Theme) => createStyles({
    link: {
        textDecoration: 'none',
        color: theme.palette.action.active,
        cursor: "pointer"
    },
})

class PrivacyPolicyCCPA extends React.Component<WithStyles<typeof styles>> {

  render() {
    const {classes} = this.props

    const backLink = (document.referrer.endsWith(Routes.privacy)) ?
      <p><Link onClick={ControlTower.back} className={classes.link}>Back</Link></p> : null

    return (
      <div>
        {backLink}
        <h1>GovGig.us California Privacy Notice</h1>
        <p>Last Updated: March 18, 2022</p>

        <p>This California Privacy Notice is part of the Terms of Use agreement between you and Watermark Solutions LLC, DBA GovGig (“Company”). This privacy notice supplements the information contained in Company’s <a href={Routes.privacy}>Privacy Policy</a> and applies solely to residents of the State of California using the Site. Company adopts this policy to comply with the California Consumer Privacy Act.</p>

        <h3>Information we collect</h3>
        <p><u>Personal information</u></p>
        <p>Company may collect:</p>
        <ul>
          <li>Identifiers such as name, alias, email address, phone number, username, unique personal identifier, IP address, device id, account name and similar identifiers.</li>
          <li>Personal information categories such as name, address, phone number, education, employment, employment history, financial information (credit card, debit card, bank account information).</li>
          <li>Commercial information, including services on the Site you view or purchase, and details of services to which you subscribe.</li>
          <li>Internet data including login history, search history, browsing activity, browser type and version, device information, cookie data, browser plugin types and version, and operating system of your device.</li>
          <li>Geolocation data including time zone, and location (latitude/longitude).</li>
          <li>Employment information including job searches, job applications, information provided in your resume or job seeker profile including work history and education.</li>
          <li>Inferences drawn from your personal information and activity on the Site that reflect your preferences.</li>
        </ul>

        <p><u>Non-personal information</u></p>
        <p>Company may collect, use and share data or aggregated data that has all personally identifying information removed. Such de-identified or aggregated data may be derived from your personal data but is not considered personal information under CCPA because it does not directly or indirectly disclose your identity.</p>

        <h3>Information we do not collect</h3>
        <p>Company does not ask you for protected classifications under California or federal law. Protected classifications of data include age (40+ years), race, color, ancestry, national origin, citizenship, religion, creed, marital status, disability (physical or mental), gender identity, gender expression, pregnancy or childbirth, sexual orientation, genetic information, and veteran or military status. You are responsible for assessing information you provide in your profile or resume that are protected and are not necessary for your application or search.</p>
        <p>Company does not collect biometric information, sensory information or non-public education information.</p>

        <h3>Sources of Personal Data</h3>
        <p>Personal Data includes data that you provide directly to Company when you create or update an account or use the services of the Site. It also includes data that you provide indirectly, such as your activity on the Site. We also receive data from third parties such as analytics or advertising providers that can be Personal Data.</p>

        <h3>Sale of Personal Data</h3>
        <p>Company does not sell your information to third parties. Company provides your information to its affiliates on your behalf when you apply for a job or post your information on the Site. Company does not receive payment for providing your information to affiliates. Company receives payment when a job seeker is placed with an affiliate as an employee or contractor. This may constitute a “sale” of personal information under the CCPA.</p>

        <h3>Your rights under the CCPA</h3>
        <p>The CCPA provides California residents with specific rights concerning personal information.</p>

        <p><u>Access to information about you and its use by Compan</u></p>
        <p>If you are a California resident and the CCPA does not recognize an exception to you or your personal information, you may request that Company provide to you information about Personal Data that Company has and how it is used. Company will provide to you, after it has received and verified your request, (a) the categories of information Company collects about you (b) the categories of sources of that information (c) the purpose for collecting the data (d) the categories of third parties the data has been shared with, (e) the specific data collected (f) the personal information categories for which your information was sold or disclosed to third parties.</p>

        <p><u>Deleting your personal information</u></p>
        <p>If you are a California resident and the CCPA does not recognize an exception to you or your personal information, you may request that Company delete your personal information. When Company receives and validates your request, your account on the Site will be deleted and your data deleted unless an exception applies.
          Your request may be denied under certain circumstances, including but not limited to when it is necessary to Company or a Company affiliate to complete the transaction for which your information was collected, or is necessary to Company to comply with law or court order.
          Anonymized and aggregated data are not considered personal information under CCPA.
        </p>

        <p><u>Exercising your rights</u></p>
        <p>Company will acknowledge your request within 10 business days and will provide information about how your request will be processed. Requests will ordinarily be fulfilled within forty-five (45) days. If more time is required, you will be notified in writing. Any disclosure requests will only cover the 12-month period preceding the date of your request. Company will provide notice of reasons it cannot comply with your request, if applicable.</p>

        <p><u>Opting out from the sale of Personal Information</u></p>
        <p>Requests to opt out of the sale of personal information can be made by email to <a href="mailto:ccpa@govgig.us">ccpa@govgig.us</a>. You are not required to create an account to exercise your right to opt out. We will only use personal information you provide with such requests to review and respond to such requests. The services of the Site cannot be performed without providing your Personal Data to affiliates of Company; therefore, when you request to opt out, your account and all personal data will be deleted.</p>

        <h3>Non-Discrimination</h3>
        <p>Company will not discriminate against you for exercising any of your CCPA rights, including denial of service, different pricing or discounts, or quality of goods or services.</p>

        <h3>Affiliates</h3>
        <p>Where Company has provided your personal information on your behalf to an affiliate, and you seek to access or delete your personal information, Company will provide affiliate contact information so that you may directly contact them.</p>

        <h3>Changes to Privacy Notice</h3>
        <p>Company reserves the right to change this Privacy Notice at any time. You will be notified of material changes by Company posting notice on the Site or issuing email to registered users of the Site prior to such changes. Company will update the effective date of such changes on the Site. You acknowledge that you are responsible for maintaining a current email with your account on Site and to review this Privacy Notice on the Site periodically. If you object to any changes to the Privacy Notice or any terms of service, you can stop using the Site and delete your account. Your continued use of the site indicates your acceptance of any changes to this Privacy Notice.</p>

        <h3>Contact Information</h3>
        <p><a href="mailto:ccpa@govgig.us">ccpa@govgig.us</a></p>

        <p>Mailing address:</p>
        <p>
          Watermark Solutions LLC, DBA GovGig<br/>
          19255 Powder Hill Pl NE, Suite 102<br />
          Poulsbo, WA 98370<br />
          USA
        </p>
        <p>Attn: CCPA</p>
      </div>
    )
  }
}

export default withStyles(styles)(PrivacyPolicyCCPA)