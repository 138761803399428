/* eslint-disable @typescript-eslint/no-unused-vars */
import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { Auth } from "aws-amplify";

const API_BASE_URL = "https://37zjo588e1.execute-api.us-west-2.amazonaws.com/dev/"; // Replace with your API base URL

interface ApiResponse<T> {
  data: T;
  status: number;
  statusText: string;
}

const fetchData = async <T>(url: string, config?: AxiosRequestConfig): Promise<{ data: T | null; loading: boolean; error: unknown | null }> => {
  try {
    // let accessToken = `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`;
    // const accessToken = `${(await Auth.currentSession()).getIdToken().getJwtToken()}`;
    // let config ={headers: {
    //   'Authorization': `Bearer ${accessToken}`,
    // }}
    // const response: AxiosResponse<T> = await axios.get<T>(API_BASE_URL + url, config);

    const response: AxiosResponse<T> = await axios.get<T>(API_BASE_URL + url);
    const apiResponse: ApiResponse<T> = {
      data: response.data,
      status: response.status,
      statusText: response.statusText
    };
    return { data: apiResponse.data, loading: false, error: null };
  } catch (error) {
    return { data: null, loading: false, error };
  }
};

export default fetchData;
