import {
  Box,
  createStyles,
  Grid,
  isWidthDown,
  Theme,
  Typography,
  withStyles,
  WithStyles,
  WithTheme,
  withTheme,
  withWidth,
  WithWidth
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import {makeObservable, observable} from "mobx";
import {inject, observer} from "mobx-react";
import React from "react";
import {ProfileStatus} from "../../API";
import CardValue from "../../components/CardValue";
import IconicButton from "../../components/controls/IconicButton";
import TextBlock from "../../components/TextBlock";
import Profile from "../../model/Profile";
import ProfileStore from "../../stores/ProfileStore";
import UserStore from "../../stores/UserStore";
import ProfileCandidateStatusDialog from "../../components/profile/ProfileCandidateStatusDialog";

const styles = (theme: Theme) => createStyles({
  container: {
    flex: 1
  },
  progressButton: {
    minWidth: 80
  }
})

export interface IProfileCandidateStatusProps {
  profile?: Profile
  onChange?: (profile: Profile) => any
  userStore?: UserStore
  profileStore?: ProfileStore
}

class ProfileCandidateStatusViewModel {
  @observable profileStatus: ProfileStatus
  @observable statusMessage?: string
  @observable notes?: string

  constructor (profile: Profile) {
    makeObservable(this);
    this.profileStatus = profile.profileStatus
    this.statusMessage = profile.statusMessage
    this.notes = profile.notes
  }
}

@inject("userStore", "profileStore")
@observer
class ProfileCandidateStatus extends React.Component<WithStyles<typeof styles> & WithTheme & WithWidth & IProfileCandidateStatusProps> {
  @observable viewModel?: ProfileCandidateStatusViewModel
  @observable isFormOpen: boolean = false
  @observable isProcessing = false

  constructor(
    props: WithStyles<typeof styles> & WithTheme & WithWidth & IProfileCandidateStatusProps
  ) {
    super(props);
    makeObservable(this);
  }

  render() {
    const { width, onChange, profile, userStore } = this.props

    if (!profile || !profile.user || !userStore!.isAdminOrAgent || profile.profileStatus === ProfileStatus.Accepted) {
      return null
    }

    let editButton
    if (onChange) {
      editButton =
        <IconicButton onClick={this.handleEdit}>
          <EditIcon />
        </IconicButton>
    }
    const labelWidth = 80 

    return (
      <Box my={2} px={3} py={3} bgcolor="warning.light" borderColor="warning.light" border={0} borderRadius={10} boxShadow='rgba(0, 0, 0, 0.15) 0px 5px 15px 0px'>
        <Grid container justifyContent="space-between">
          <Grid item>
            <Typography variant="h3">Candidate Status</Typography>
          </Grid>
          <Grid item>
            <Box>
              {editButton}
            </Box>
          </Grid>
        </Grid>
        <Grid
          container
          direction={isWidthDown('xs', width) ? "column" : "row"}
          justifyContent="flex-start"
          alignItems="flex-start"
          spacing={3}
        >
          <Grid item xs={12} sm={6}>
            <Grid container direction="column" justifyContent="flex-start">
              <Grid item container>
                <CardValue label="Status" labelWidth={labelWidth}>
                  {profile.profileStatus}
                </CardValue>
              </Grid>
              <Grid item container>
                <CardValue label="Message" labelWidth={labelWidth} wrapText>
                  <TextBlock>{profile.statusMessage}</TextBlock>
                </CardValue>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6}>
            <CardValue label="Notes" labelWidth={labelWidth} wrapText>
              <TextBlock>{profile.notes}</TextBlock>
            </CardValue>
          </Grid>
        </Grid>
        {this.isFormOpen &&
          <ProfileCandidateStatusDialog 
            profile={profile}
            onChange={this.handleUpdate}
            onClose={this.handleClose}
          />
        }
      </Box>
    )
  }

  handleUpdate = (profile: Profile) => {
    this.props.onChange!(profile)
  }

  handleEdit = (): void => {
    if (this.props.profile) {
      this.isFormOpen = true
    }
  }

  handleClose = () => {
    this.isFormOpen = false
  }

}

export default withTheme(withStyles(styles)(withWidth()(ProfileCandidateStatus)))
