import * as React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { createStyles, withStyles, Theme, WithStyles } from '@material-ui/core/styles'
import { RouteComponentProps } from '@reach/router'
import DialogButton from '../form/DialogButton'
import {inject, observer} from "mobx-react";
import { observable, makeObservable } from "mobx";
import FormValidator from "../../components/form/FormValidator";
import TextFieldValidator from "../../components/form/TextFieldValidator";
import {UserStoreConstants} from "../../stores/UserStore";
import Confirm from "../confirm/Confirm";
import {Box, Button, Container, Divider, Grid, Typography} from "@material-ui/core";
import CancelButton from '../form/CancelButton';
import ProgressButton from "../form/ProgressButton";
import Stack from '../Stack';
// import logo from "../../images/GovGig_Logo_Square_512x512.png"
import config from 'react-global-configuration';
import ControlTower from '../ControlTower';

const styles = (theme: Theme) => createStyles({
  message: {
    color: 'red',
    marginTop: 10
  },
  signUp: {
    fontWeight: "bold"
  },
  submitButton: {
    color: "#fff",
  },
  container: {
    height: "100%",
    flexGrow: 1,
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  logo: {
    width: "150px",
    height: "150px"
  }
})

interface ILoginDialogProps {
    onLogin: any,
    onClose: any,
    onShowPasswordAssist: any
    onShowSignUp: any
    userStore?: any
    accountStore?: any
    confirm?: Confirm
    embed?: boolean 
}

@inject("userStore", "accountStore", "confirm")
@observer
class LoginDialog extends React.Component<WithStyles<typeof styles> & RouteComponentProps & ILoginDialogProps> {
  @observable values = {
    username: "",
    password: "",
    newPassword: "",
    // userId: "",
    // phone: "",
    // code: ""
  }
  @observable message = ""
  @observable newPasswordRequired = false
  @observable isProcessing = false

  // private cognitoUser: any

  onClose = () => {
    if (this.props.onClose) {
      this.message = ""
      this.props.onClose()
    }
  };

  onLogin = () => {
    this.props.onLogin()
  }

  onSubmit = async () => {
    const { userStore, accountStore } = this.props

    this.isProcessing = true
    this.message = ""

    const password = this.values.password
    userStore!.signIn(this.values.username, password)
      .then(async (user: any) => {
        if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
          if (this.values.newPassword) {
            userStore!.completeNewPassword(user, this.values.newPassword)
              .then((data: any) => {
                // Login with the new password
                userStore!.signIn(this.values.username, this.values.newPassword)
                  .then(async (user2: any) => {
                    if (userStore!.user && userStore!.user.account) {
                      await accountStore!.init(userStore!.user.account)
                    }
                    this.isProcessing = false
                    if (this.props.onLogin) {
                      this.message = "";
                      this.onLogin()
                    }
                  })
                  .catch((err: any) => {
                    this.message = err.message
                    this.isProcessing = false
                  });
              })
              .catch((err: any) => {
                this.message = err.message
                this.isProcessing = false
              });
          } else {
            // Prompt for new password
            this.newPasswordRequired = true
            this.message = "A new password is required"
            this.isProcessing = false
          }
        } else {
          // userStore!.cognitoUser = user
          if (userStore!.user && userStore!.user.account) {
            await accountStore!.init(userStore!.user.account)
          }
          if (this.props.onLogin) {
            this.message = "";
            this.onLogin()
          }
          this.isProcessing = false
        }
    })
    .catch((err: any) => {
      if (err.code === UserStoreConstants.USER_NOT_FOUND_EXCEPTION) {
          this.message = "Incorrect username or password."
      } else if (err.message === UserStoreConstants.USER_NOT_CONFIRMED ||
        err.message === UserStoreConstants.EMAIL_VERIFICATION_PENDING ||
        err.message === UserStoreConstants.PHONE_VERIFICATION_PENDING) {
        // Signup is incomplete
        this.message = "Please complete your sign up."
      } else {
        this.message = err.message;
      }
      this.isProcessing = false
    });
  }

  onForgotPassword = () => {
    if (this.props.onShowPasswordAssist) {
      this.message = "";
      this.props.onShowPasswordAssist()
    }
  }

  // onResendCode = () => {
  //   const { userStore } = this.props
  //   this.message = ""

  //   const attr = (this.values.phone) ? CognitoAttribute.PHONE_NUMBER : CognitoAttribute.EMAIL
  //   userStore.verifyUserAttribute(this.cognitoUser, attr)
  //     .then((data: any) => {
  //       console.log(data)
  //       this.message = "Verification email has been resent"
  //     })
  //     .catch((err: any) => {
  //       this.message = err.message
  //     });
  // }

  // onShowSignUp = () => {
  //   if (this.props.onShowSignUp) {
  //     this.message = "";
  //     this.props.onShowSignUp()
  //   }
  // }

  onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const name = event.target.name
    this.values[name] = event.target.value
  }

  constructor(props: WithStyles<typeof styles> & RouteComponentProps & ILoginDialogProps) {
    super(props);
    makeObservable(this);
  }

  renderEmbedded = () => {
    const { classes } = this.props 
    return (
      <Container maxWidth="sm" className={classes.container}>
        <Box
          bgcolor="#fff"
          borderRadius={10}
          px={3}
          py={5}
          my={5}
        >
          <Grid 
            container 
            direction="column" 
            justifyContent="flex-start"
            alignItems="center"
          >
            {/* <Grid item>
              <img src={logo} className={classes.logo} />
            </Grid> */}
            <Grid item>
              <Typography
                variant="h3"
              >
                Log In
              </Typography>
            </Grid>
          </Grid>
          <FormValidator 
            onSubmit={this.onSubmit} 
            autoComplete="off" 
            name="loginForm" 
            id="loginForm"
          >
            <TextFieldValidator
              autoFocus
              margin="dense"
              name="username"
              id="usernameInput"
              label="Email"
              type="email"
              validators={{required:true}}
              value={this.values.username}
              onChange={this.onChange}
              fullWidth
              autoCorrect="off"
              autoCapitalize="off"
              autoComplete="off"
            />
            <TextFieldValidator
              margin="dense"
              name="password"
              id="passwordInput"
              label="Password"
              type="password"
              validators={{required:true}}
              value={this.values.password}
              onChange={this.onChange}
              fullWidth
            />
            {this.newPasswordRequired &&
              <TextFieldValidator
                margin="dense"
                name="newPassword"
                label="New Password"
                type="password"
                validators={{required:true, isStrongPassword:3}}
                value={this.values.newPassword}
                onChange={this.onChange}
                fullWidth
                helperText="Please enter a new password of at least 8 characters with at least one digit or symbol."
              />            
            }
            <Typography className={classes.message}>
              {this.message}
            </Typography>
            <Box
              pt={2}
              pb={2}
            >
              <ProgressButton 
                variant="contained" 
                size="medium" 
                color="secondary"
                type="submit" 
                processing={this.isProcessing}
                fullWidth
              >
                Log In
              </ProgressButton>
            </Box>
            <Box pt={1} px={1}>
              <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={3}>
                <Button variant="text" color="primary" onClick={this.onForgotPassword}>
                  Forgot&nbsp;Password
                </Button>
                <Stack spacing={1} alignItems="center">
                  <Typography variant="body2">Need an account?</Typography>
                  <Button 
                    variant="text" 
                    color="primary"
                    onClick={() => {
                      this.props.onShowSignUp()
                    }}
                  >
                    Sign Up
                  </Button>
                </Stack>
              </Stack>
            </Box>
            <Box pt={5}>
              <Divider />
              <Box pt={3}>
                <Stack direction="row" justifyContent="center" alignItems="center" spacing={2}>
                  <Typography variant="body2">Academy student?</Typography>
                  <Button 
                    variant="contained" 
                    color="secondary"
                    onClick={() => {
                      const academyUrl = config.get("academyUrl")
                      if (academyUrl) {
                        ControlTower.open(academyUrl, "_self")
                      }
                    }}
                  >
                    Academy Login
                  </Button>
                </Stack>
              </Box>
            </Box>
          </FormValidator>
        </Box>
      </Container>
    )
  }

  renderDialog() {
    const { classes } = this.props
  
    return (
      <Dialog
        id="loginDialog"
        open={true}
        onClose={this.onClose}
        scroll="paper"
        maxWidth="xs"
        fullWidth
        aria-labelledby="login-dialog-title">
          <FormValidator 
            onSubmit={this.onSubmit} 
            autoComplete="off" 
            name="loginForm" 
            id="loginForm"
          >
            <DialogTitle
              id="login-dialog-title"
            >
              <Grid container direction="row" justifyContent="space-between">
                <Grid item>
                  Log In
                </Grid>
                <Grid item>
                </Grid>
              </Grid>
            </DialogTitle>
            <DialogContent>
            <TextFieldValidator
                autoFocus
                margin="dense"
                name="username"
                id="usernameInput"
                label="Email"
                type="email"
                validators={{required:true}}
                value={this.values.username}
                onChange={this.onChange}
                fullWidth
                autoCorrect="off"
                autoCapitalize="off"
                autoComplete="off"
              />
            <TextFieldValidator
                margin="dense"
                name="password"
                id="passwordInput"
                label="Password"
                type="password"
                validators={{required:true}}
                value={this.values.password}
                onChange={this.onChange}
                fullWidth
              />
              {this.newPasswordRequired &&
                <TextFieldValidator
                  margin="dense"
                  name="newPassword"
                  label="New Password"
                  type="password"
                  validators={{required:true, isStrongPassword:3}}
                  value={this.values.newPassword}
                  onChange={this.onChange}
                  fullWidth
                  helperText="Please enter a new password of at least 8 characters with at least one digit or symbol."
                />            
              }
              <DialogContentText className={classes.message}>
                {this.message}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Grid container>
                <Grid item xs={4}>
                  <Grid container justifyContent="flex-start">
                    <Grid item>
                      <DialogButton variant="tertiary" onClick={this.onForgotPassword}>
                        Forgot&nbsp;Password?
                      </DialogButton>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={8}>
                  <Grid container  justifyContent="flex-end">
                    <CancelButton onClick={this.onClose} />
                    <ProgressButton variant="contained" size="medium" color="secondary"
                                    type="submit" className={classes.submitButton} processing={this.isProcessing}>
                      Log In
                    </ProgressButton>
                  </Grid>
                </Grid>
              </Grid>
            </DialogActions>
          </FormValidator>
        </Dialog>
    );
  }

  render() {
    const { embed } = this.props 
    if (embed) {
      return this.renderEmbedded()
    } else {
      return this.renderDialog()
    }
  }
}

export default withStyles(styles)(LoginDialog)
