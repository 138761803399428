import { Box, Button, Divider, Typography, useTheme } from "@material-ui/core"
import Stack from "../../Stack"
import RequestMeetingButton from "../../controls/RequestMeetingButton"

const RequestMeetingSection = () => {
  const theme = useTheme()

  return (
    <Stack 
      direction="column"
      sx={{
        mt: 2, 
        borderTop: `1px solid ${theme.palette.grey[400]}`,
        py: 2, 
      }} 
    >
      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={2}
      >
        <Typography>Do you have questions and want to talk to a live person?</Typography>
        <Box
          sx={{
          }}
        >
          <RequestMeetingButton
            color="inherit"
            size="medium"
            showFowardArrow={false}
          />
        </Box>
      </Stack>
    </Stack>
  )
}

export default RequestMeetingSection
