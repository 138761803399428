import {AddressType} from "../API";
import {LngLatLike} from "mapbox-gl";


export class Location {
  id: string
  createdAt: string
  updatedAt: string
  active: boolean
  organizationName: string
  region: string
  name: string
  addressType: AddressType
  address: string
  city: string
  state: string
  postalCode: string
  country: string
  latitude: number
  longitude: number
  aliases: string[]

  constructor(data: any) {
    this.id = data.id
    this.createdAt = data.createdAt
    this.updatedAt = data.updatedAt
    this.active = data.active !== undefined ? data.active : true
    this.organizationName = data.organizationName
    this.region = data.region
    this.name = data.name
    this.addressType = data.addressType
    this.address = data.address
    this.city = data.city
    this.state = data.state
    this.postalCode = data.postalCode
    this.country = data.country
    this.latitude = data.latitude
    this.longitude = data.longitude
    this.aliases = data.aliases ?? []

    if (!this.addressType) {
      if (this.address) {
        this.addressType = AddressType.Street
      } else if (this.city && this.state) {
        this.addressType = AddressType.City
      } else if (this.state) {
        this.addressType = AddressType.State
      } else if (this.country) {
        this.addressType = AddressType.Country
      }
    }
  }

  get shortName() : string {
    return this.name
  }

  get longName() : string {
    if (this.aliases && this.aliases.length > 0) {
      return this.aliases[0]
    } else {
      return this.name
    }
  }

  get lngLat() : LngLatLike {
    return {lng: this.longitude, lat: this.latitude}
  }

  comparesTo(loc?: Location, precision: number = 4) : boolean {
    return loc !== undefined && this.id.startsWith(loc.id.substring(0, precision))
  }

}


export default Location