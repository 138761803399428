import * as React from 'react'
import Page from '../../components/page/Page'
import {Box, createStyles, Grid, Tab, Tabs, Theme, withStyles, WithStyles, withTheme, WithTheme} from "@material-ui/core";
import {RouteComponentProps} from "@reach/router";
import {inject, observer} from "mobx-react";
import UserStore from "../../stores/UserStore";
import { observable, when, makeObservable } from "mobx";
import Progress from "../../components/Progress";
import ControlTower, {Routes} from "../../components/ControlTower";
import ApplicantBoard from "./ApplicantBoard";
import CandidateList from "./CandidateList";
import EmployerList from "./EmployerList";
import PeopleList from "./PeopleList";
import ContractsList from "../contracts/ContractsList";
// import AccountBilling from "../settings/account-billing/AccountBilling";
// import AccountPage from '../accounts/AccountPage';

const styles = (theme: Theme) => createStyles({
  root: {
    flexGrow: 1,
    width: "100%",
    justifyContent: 'top',
    alignItems: 'center',
    overflow: "hidden",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  navigation: {
  },
  content: {
    overflow: "hidden"
  },
  tabs: {
    flexGrow: 1
  },
  tab: {
    fontSize: 15,
    fontWeight: 600,
  },
  hiddenTab: {
    display: "none"
  }
})

interface IAdminPageProps {
  userStore?: UserStore
  progress?: Progress
  tab?: string
}

@inject("userStore", "progress")
@observer
class AdminPage extends React.Component<WithStyles<typeof styles> & RouteComponentProps & IAdminPageProps & WithTheme> {
  tabNames: string[] = [
    "accounts", 
    // "job-groups", 
    "applicants", 
    "candidates", 
    "people", 
    // "billing"
  ]

  @observable isLoading = true

  constructor(
    props: WithStyles<typeof styles> & RouteComponentProps & IAdminPageProps & WithTheme
  ) {
    super(props);
    makeObservable(this);
  }

  componentDidMount () {
    const { userStore, progress } = this.props

    this.isLoading = true
    progress!.show("AdminPage")
    when(
      () => !userStore!.isLoading,
      () => {
        this.isLoading = false
        progress!.hide("AdminPage")
      }
    )
  }

  render() {
    const { classes, userStore } = this.props

    if (this.isLoading) {
      return null
    }

    const isAdminOrAgent = userStore!.isAdminOrAgent
    const isEmployer = userStore!.isEmployer

    const title = "Admin"

    const tabName = (this.props.tab !== undefined) ? this.props.tab : this.tabNames[0]
    let tabValue = this.tabNames.findIndex(name => tabName === name)
    if (tabValue < 0) {
      tabValue = 0
    }

    return (
      <Page title={title} hideFooter={true}>
        <Box px={1} paddingTop={1} width={'100%'}>
            <Grid container className={classes.navigation}>
              <Grid item xs={12}>
                <Box bgcolor="common.white" borderRadius="10px 10px 0px 0px">
                  <Tabs
                    value={tabValue}
                    onChange={this.handleChange}
                    variant="scrollable"
                    indicatorColor="secondary"
                    textColor="secondary"
                    className={ classes.tabs }
                    scrollButtons="on"
                  >
                    <Tab className={classes.tab} label={isAdminOrAgent ? "Accounts" : "Account"}/>
                    {/* <Tab className={classes.tab} label="Job Groups"/> */}
                    <Tab className={isAdminOrAgent ? classes.tab : classes.hiddenTab} label="Applicants"/>
                    <Tab className={isAdminOrAgent ? classes.tab : classes.hiddenTab} label="Candidates" />
                    <Tab className={classes.tab} label="People"/>
                    {/* <Tab className={isEmployer ? classes.tab : classes.hiddenTab} label="Billing" /> */}
                  </Tabs>
                </Box>
              </Grid>
            </Grid>
            <Grid container className={classes.content}>
              {tabValue === 0 && isAdminOrAgent && <EmployerList />}
              {/* {tabValue === 1 && <ContractsList />} */}
              {tabValue === 1 && <ApplicantBoard />}
              {tabValue === 2 && <CandidateList />}
              {tabValue === 3 && <PeopleList />}
            </Grid>
        </Box>
      </Page>
    )
  }

  handleChange = (event: any, value: any) => {
    // Navigate to tab to allow a direct url to each tab
    const tabName = this.tabNames[value]
    ControlTower.route(`${Routes.manage}/${tabName}`)
  }
}

export default withTheme((withStyles(styles)(AdminPage)))
