import Contract from "./Contract";
import Location from "./Location";
import Service from "./Service";
import {EmploymentType, JobPostStatus, JobPostType, WorkSetting} from "../API";
import Account from "./Account";
import JobCandidate from "./JobCandidate";
import JobInterest from "./JobInterest";
import Question from "./Question";
import {isoDateToPeriod, numberToMoneyFormat} from "../stores/StoreUtilities";
import ServiceRequest from "./ServiceRequest";

class JobPost {
  id: string
  createdAt: string
  updatedAt: string
  accountId: string
  account?: Account
  contractId: string
  contract?: Contract
  serviceRequestId: string 
  serviceRequest?: ServiceRequest
  locationId: string
  location?: Location
  serviceId: string
  service?: Service
  industries: string[]
  title: string
  summary: string
  description: string
  startDate: string
  endDate: string
  openDate: string
  closedDate: string
  employmentType: EmploymentType
  annualSalaryLow: number
  annualSalaryHigh: number
  jobCandidates: JobCandidate[]
  jobInterests: JobInterest[]
  status: JobPostStatus
  openings: number
  invoiceId: string
  jobPostType: JobPostType
  agentId: string
  hiringDate: string
  unlocksAvailable: number
  featured: boolean
  questions: Question[] // TODO: Define type
  workSettings: WorkSetting[]
  securityClearance: string

  constructor (data: any) {
    this.id = data.id
    this.createdAt = data.createdAt
    this.updatedAt = data.updatedAt
    this.accountId = data.accountId
    this.account = data.account ? new Account(data.account) : undefined
    this.contractId = data.contractId
    this.contract = data.contract ? new Contract(data.contract) : undefined
    this.serviceRequestId = data.serviceRequestId
    this.serviceRequest = data.serviceRequest ? new ServiceRequest(data.serviceRequest) : undefined 
    this.locationId = data.locationId
    this.location = data.location ? new Location(data.location) : undefined
    this.serviceId = data.serviceId
    this.service = data.service ? new Service(data.service) : undefined
    this.industries = data.industries ?? []
    this.title = data.title
    this.summary = data.summary
    this.description = data.description
    this.startDate = data.startDate
    this.endDate = data.endDate
    this.openDate = data.openDate
    this.closedDate = data.closedDate
    this.employmentType = data.employmentType === EmploymentType.Direct ? EmploymentType.FullTime : EmploymentType[data.employmentType]
    this.annualSalaryLow = data.annualSalaryLow
    this.annualSalaryHigh = data.annualSalaryHigh
    this.jobCandidates = []
    if (data.jobCandidates && data.jobCandidates.items) {
      this.loadJobCandidates(data.jobCandidates.items)
    }
    this.jobInterests = []
    if (data.jobInterests && data.jobInterests.items) {
      this.loadJobInterests(data.jobInterests.items)
    }
    this.status = data.status ? JobPostStatus[data.status] : this.convertStatusIdToStatus(data.statusId)
    this.openings = data.openings ?? 1
    this.invoiceId = data.invoiceId
    this.jobPostType = data.jobPostType
    this.agentId = data.agentId
    this.hiringDate = data.hiringDate
    this.unlocksAvailable = data.unlocksAvailable ? data.unlocksAvailable : JobPost.defaultUnlocksAvailable
    this.featured = data.featured
    this.questions = data.questions ? JSON.parse(data.questions) : []
    this.workSettings = data.workSettings ?? [WorkSetting.InPerson]
    this.securityClearance = data.securityClearance
  }

  static defaultUnlocksAvailable = 10

  // TODO: Remove this after status migration complete
  private convertStatusIdToStatus(statusId?: string) {
    let status : JobPostStatus

    if (!statusId) {
      status = JobPostStatus.Draft
    } else {
      switch (statusId) {
        case "68d794de-7c71-4aa4-835f-dc2e76edef00":
          status = JobPostStatus.Cart
          break
        case "59c11e05-71a6-445d-921c-3748615a6f31":
          status = JobPostStatus.Draft
          break
        case "09b4b342-777e-4485-8901-fa5a9f21b8a2":
          status = JobPostStatus.Posted
          break
        case "d4cb5e37-1724-49cd-afa6-1cb4f58e7fd7":
        case "291f3a97-0c8d-4fe9-80c7-7ceb30cd4e4c":
        case "3491f5e0-ec03-4952-9271-1d803040cec4":
          status = JobPostStatus.Pending
          break
        case "81b8f705-c3a7-4214-bbeb-81b6f2b09da5":
          status = JobPostStatus.Hired
          break
        case "b32a68a7-3ed2-4560-b961-3879a65edf0e":
          status = JobPostStatus.Closed
          break
        default:
          status = JobPostStatus.Draft
          break
      }
    }

    return status
  }

  // TODO: Remove this after status migration complete
  static convertStatusToStatusId(status: JobPostStatus) {
    let statusId
    switch (status) {
      case JobPostStatus.Cart:
        statusId = "68d794de-7c71-4aa4-835f-dc2e76edef00"
        break
      case JobPostStatus.Draft:
        statusId = "59c11e05-71a6-445d-921c-3748615a6f31"
        break
      case JobPostStatus.Posted:
        statusId = "09b4b342-777e-4485-8901-fa5a9f21b8a2"
        break
      case JobPostStatus.Pending:
        statusId = "3491f5e0-ec03-4952-9271-1d803040cec4" // Hiring
        break
      case JobPostStatus.Hired:
        statusId = "81b8f705-c3a7-4214-bbeb-81b6f2b09da5"
        break
      case JobPostStatus.Closed:
        statusId = "b32a68a7-3ed2-4560-b961-3879a65edf0e"
        break
      default:
        statusId = "59c11e05-71a6-445d-921c-3748615a6f31" // Draft
        break
    }

    return statusId
  }

  get fullName(): string {
    if (this.account && this.contract && this.service) {
      return `${this.account.name}/${this.contract.name}/${this.title ?? this.service.name}`
    } else if (this.account && this.service) {
      return `${this.account.name}/${this.title ?? this.service.name}`
    } else if (this.title) {
      return this.title
    } else if (this.service) {
      return this.service.name
    } else {
      return this.id
    }
  }

  get salaryRange(): string {
    let salaryRange = ""
    if (this.employmentType === EmploymentType.FullTime) {
      if (this.annualSalaryLow !== undefined) {
        salaryRange = `${numberToMoneyFormat(this.annualSalaryLow, 0)}`
      } else {
        salaryRange = '$0'
      }
      if (this.annualSalaryHigh !== undefined && this.annualSalaryHigh !== null && this.annualSalaryHigh !== 0) {
        salaryRange += ` - ${numberToMoneyFormat(this.annualSalaryHigh, 0)}`
      } else {
        salaryRange += '+'
      }
    }

    return salaryRange
  }

  get hiringPeriod(): string | null {
    return isoDateToPeriod(this.hiringDate)
  }

  get locationDescription(): string {
    let location = ""
    if (this.location) {
      if (this.workSettings.includes(WorkSetting.Remote)) {
        if (this.workSettings.includes(WorkSetting.Hybrid)) {
          location = `Remote/Hybrid - `
        } else {
          location = `Remote - `
        }
      } else if (this.workSettings.includes(WorkSetting.Hybrid)) {
        location = `Hybrid - `
      }
      location += this.location.shortName
    }

    return location
  }

  private loadJobCandidates(items: any[]) {
    this.jobCandidates = items.map((item: any) => new JobCandidate(item))
    this.jobCandidates.sort(JobCandidate.compareFn)
  }

  private loadJobInterests(items: any[]) {
    this.jobInterests = items.map((item: any) => new JobInterest(item))
  }

  jobPostTypeToString(): string {
    let typeName = ""
    switch (this.jobPostType) {
      case (JobPostType.RecruitingServices): 
        typeName = "Recruiting Services"
        break
      case (JobPostType.StaffingServices): 
        typeName = "Staffing Services"
        break
      case (JobPostType.SelfService): 
        typeName = "Self-service"
        break 
    }
    return typeName 
  }
}

export default JobPost