import * as React from "react";
import {
  Card,
  CardContent,
  createStyles,
  Grid,
  Theme,
  Typography,
  withStyles,
  WithStyles
} from "@material-ui/core";
import CardValue from "../../components/CardValue";
import { phoneToNationalFormat } from "../../stores/StoreUtilities";
import { makeObservable, observable } from "mobx";
import { observer } from "mobx-react";
import ActionLink from "../../components/ActionLink";
import Account from "../../model/Account";
import Stack from "../../components/Stack";

const styles = (theme: Theme) => createStyles({
  container: {
    minHeight: "100%"
  },
  card: {
    fontSize: 12,
    padding: '16px !important', // overriding CardContent root setting
  },
  name: {
    fontSize: 20,
    paddingBottom: '6px',
    color: theme.palette.secondary.main,
    fontWeight: "bold",
  },
  link: {
    color: theme.palette.secondary.main,
  },
  button: {
    marginLeft: theme.spacing(1)
  }
})

interface IAccountCardProps {
  account: any
  onNameClick?: () => void
}

@observer
class AccountCard extends React.Component<WithStyles<typeof styles> & IAccountCardProps> {
  @observable isFormOpen: boolean = false
  @observable dataChanged: boolean = false

  constructor(props: any) {
    super(props)
    makeObservable(this)
  }
  
  render() {
    const { classes, account, onNameClick } = this.props

    const phoneNumber = account.phone ? phoneToNationalFormat(account.phone) : ""
    const webUrl = account.webUrl ? this.urlRemoveEndSlash(this.urlRemoveProtocol(account.webUrl)) : ""

    return (
      <Card className={classes.container}>
        <CardContent className={classes.card}>
          {/* {editButton} */}
          <Grid container>
            <Grid item xs={9}>
              <Typography component="div" variant='body2'>
                <ActionLink
                  text={account.name}
                  alignText="left"
                  tracking=""
                  click={onNameClick ? onNameClick : undefined}
                  textStyle={classes.name}
                  linkStyle={classes.link}
                />
              </Typography>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12}>
              <Stack direction="column" justifyContent="flex-start" spacing={1}>
                <CardValue label="Address">
                  {account.address} <br/>{account.city}, {account.state} {account.postalCode}
                </CardValue>
                <CardValue label="Phone">
                  {phoneNumber}
                </CardValue>
                <CardValue label="Web">
                  {webUrl}
                </CardValue>
                <CardValue label="Status">
                  {account.accountStatus}
                </CardValue>
              </Stack>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    )
  }

  urlRemoveProtocol = (webUrl: string) => {
    const L = webUrl.length
    if (webUrl.slice(0, L>=8?8:0) === "https://") {
      return webUrl.slice(8)
    } if (webUrl.slice(0, L>=7?7:0) === "http://") {
      return webUrl.slice(7)
    }
    return webUrl
  }
  
  urlRemoveEndSlash = (webUrl: string) => {
    return /.*\/$/.test(webUrl) ? webUrl.slice(0, webUrl.length - 1) : webUrl
  }
}

export default withStyles(styles)(AccountCard)