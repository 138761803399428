// import BulletItem from "../../components/marketing/BulletItem"
import ImageGroup from "../../../components/marketing/ImageGroup"
import TitleGroup from "../../../components/marketing/TitleGroup"
import TitleSection from "../../../components/marketing/sections/TitleSection"
// import { FlashOn, People } from "@material-ui/icons"

import PostJobs from "../../../images/PostJobs.png"
import InviteCandidate from "../../../images/InviteCandidate.png"
import ReviewCandidatesAndHire from "../../../images/ReviewCandidatesAndHire.png"

import { 
  // Avatar, 
  // Box, 
  // Grid, 
  // Stack, 
  // Typography, 
  useTheme 
} from "@material-ui/core"
// import { blueGrey } from "@material-ui/core/colors"
import Stack from "../../../components/Stack"

const EmployerHowGovGigWorksSection = () => {
  const theme = useTheme()

  // Older version of this content, for reference. 
  // const renderV1 = () => {
  //   return (
  //     <Stack direction="column" rowGap={7}>
  //       <Box p={2}>
  //         <Typography gutterBottom align="center" variant="h2" color="primary.main" >
  //           How GovGig Works 
  //         </Typography>
  //         <Typography gutterBottom align="center" variant="body1">
  //           GovGig is the place for employers, contractors, and recruiters to display and promote your job openings to qualified and pre-vetted Federal construction candidates who are looking for work. We have a couple of options, depending on your needs: 
  //         </Typography>
  //       </Box>
  //       <Grid
  //         container 
  //         direction="row"
  //         justifyContent="space-between"
  //         spacing={5}
  //         sx={{ 
  //           p: 3
  //         }}
  //       >
  //         <Grid item xs={12} md={4}>
  //           <Stack spacing={2}>
  //           <Avatar
  //               sx={{ 
  //                 backgroundColor: blueGrey[700], 
  //                 color: 'common.white',
  //                 height: 50, 
  //                 width: 50  
  //               }}
  //             >
  //               <People fontSize="large" />
  //             </Avatar>
  //             <Typography gutterBottom variant="h3" color="primary.main">
  //               Self-Service Job Posts 
  //             </Typography>
  //             <Typography gutterBottom variant="body1">
  //               Take control to easily add job posts to the GovGig Job Board and manage your candidate flow.   
  //             </Typography>
  //           </Stack>
  //         </Grid>
  //         <Grid item xs={12} md={8}>
  //           <Stack 
  //             direction="column" 
  //             spacing={3} 
  //             sx={{
  //               backgroundColor: theme.palette.common.white,
  //               p: 3,
  //               borderRadius: 3
  //             }}
  //           >
  //             <Typography gutterBottom variant="body1">When you choose the Self-Service option, you manage your job posts. The GovGig Job Board is different from other job boards because it:</Typography>
  //             <BulletItem
  //               text="Specializes in Federal construction staffing."
  //               icon="Check"
  //             />
  //             <BulletItem
  //               text="Only admits pre-vetted and pre-qualified candidates."
  //               icon="Check"
  //             />
  //             <BulletItem
  //               text="Is fairly priced and ensures there are no hidden costs."
  //               icon="Check"
  //             />
  //             <BulletItem
  //               text="Saves you time and money with our qualified candidates versus the pool of unqualified candidates on those other general platforms."
  //               icon="Check"
  //             />
  //           </Stack>
  //         </Grid>
  //       </Grid>
  //       <Grid
  //         container 
  //         direction="row"
  //         justifyContent="space-between"
  //         spacing={5}
  //         sx={{ 
  //           p: 3
  //         }}
  //       >
  //         <Grid item xs={12} md={4}>
  //           <Stack spacing={2}>
  //             <Avatar
  //               sx={{ 
  //                 backgroundColor: blueGrey[700], 
  //                 color: 'common.white',
  //                 height: 50, 
  //                 width: 50  
  //               }}
  //             >
  //               <FlashOn fontSize="large" />
  //             </Avatar>
  //             <Typography gutterBottom variant="h3" color="primary.main">
  //               Recruiting Services 
  //             </Typography>
  //             <Typography gutterBottom variant="body1">
  //               If you need a viable candidate fast, consider GovGig’s Recruiting Services, where we manage the recruiting process on your behalf.
  //             </Typography>
  //           </Stack>
  //         </Grid>
  //         <Grid item xs={12} md={8}>
  //           <Stack 
  //             direction="column" 
  //             spacing={3} 
  //             sx={{
  //               backgroundColor: theme.palette.common.white,
  //               p: 3,
  //               borderRadius: 3
  //             }}
  //           >
  //             <Typography gutterBottom variant="body1">When you choose the Recruiting Services option, the GovGig recruiting team helps to:</Typography>
  //             <BulletItem
  //               text="Quickly identify, attract and screen qualified Candidates who hit all position requirements."
  //               icon="Check"
  //             />
  //             <BulletItem
  //               text="Verify the Candidate resumes and information."
  //               icon="Check"
  //             />
  //             <BulletItem
  //               text="Set up interviews between Candidates and your hiring manager."
  //               icon="Check"
  //             />
  //           </Stack>
  //         </Grid>
  //       </Grid>
  //     </Stack>
  //   )
  // }

  return (
    <TitleSection
      title="How GovGig Works"
      subtitle="GovGig is the place for employers, contractors, and recruiters to display and promote your job openings to qualified and pre-vetted candidates who are looking for work."
    >
      <Stack
        direction='column'
        spacing={5}
        sx={{
          width: '100%'
        }}
      >
        <ImageGroup
          imageSrc={PostJobs}
          imageTitle="Image: Job board and map"
          alignment="imageRight"
        >
          <TitleGroup
            // superTitle="Step 1"
            title="1. Post jobs to the GovGig job board."
            body="Quickly post jobs to our community so your job can be seen by the pool of professional candidates."
            alignment="left"
          />
        </ImageGroup>
        <ImageGroup
          imageSrc={InviteCandidate}
          imageTitle="Image: Search candidates and invite"
          alignment="imageLeft"
        >
          <TitleGroup
            // superTitle="Step 2"
            title="2. Search on candidates and invite them to apply."
            body="Find talent in our focused, pre-screened collection of candidates. Unlike general job boards, our candidates are specific to your industry."
            alignment="left"
          />
        </ImageGroup>
        <ImageGroup
          imageSrc={ReviewCandidatesAndHire}
          imageTitle="Image: Review a candidate and select one to hire"
          alignment="imageRight"
        >
          <TitleGroup
            // superTitle="Step 3"
            title="3. Review candidates and select the candidates to hire."
            body="Select the excellent candidates that meet your qualifications and hire them, so you can quickly ramp up your projects."
            alignment="left"
          />
        </ImageGroup>
      </Stack>
    </TitleSection>
  )
}

export default EmployerHowGovGigWorksSection
