import * as React from 'react'
import {ReactNode} from 'react'
import Page from '../../components/page/Page'
import {RouteComponentProps} from "@reach/router";
import {inject, observer} from "mobx-react";
import {
  Box,
  createStyles,
  alpha,
  Grid,
  Hidden,
  isWidthDown,
  Theme,
  Typography,
  withStyles,
  WithStyles,
  withTheme,
  WithTheme,
  withWidth
} from "@material-ui/core";
import UserStore from "../../stores/UserStore";
import Progress from "../../components/Progress";
import {autorun, makeObservable, observable, when} from "mobx";
import ServiceGroup from "../../model/ServiceGroup";
import ResourceCache, {LocationOption, ServiceOption} from "../../stores/ResourceCache";
import JobPost from "../../model/JobPost";
import AccountStore from "../../stores/AccountStore";
import {AddressType, CreateJobInterestInput, JobInterestStatus, SubjectType, WorkSetting} from "../../API";
import JobPostSearchCard from "./JobPostSearchCard";
import JobPostDescriptionCard from "../../components/jobPosts/JobPostDescriptionCard";
import JobInterest from "../../model/JobInterest";
import Notify from "../../components/notify/Notify";
import JobInterestApplyDialog from "../../components/jobPosts/JobInterestApplyDialog";
import DialogButton from "../../components/form/DialogButton";
import StarIcon from "@material-ui/icons/Star";
import RoomIcon from '@material-ui/icons/Room';
import Confirm from "../../components/confirm/Confirm";
import ControlTower, {Routes} from "../../components/ControlTower";
import JobMap from "./JobMap";
import {LngLatBounds} from "mapbox-gl";
import FilterBar from "../../components/filter/FilterBar";
import ServiceFilter from "../../components/filter/ServiceFilter";
import PlaceFilter from "../../components/filter/PlaceFilter";
import {WithWidth} from "@material-ui/core/withWidth";
import ListIcon from "@material-ui/icons/ViewStream";
import JobPostHero from '../../components/jobPosts/JobPostHero';
import {ActivityType} from "../../model/UserActivity";
import {grey} from "@material-ui/core/colors";
import JobStore, {IJobSearchOptions, LocationJobPosts} from "../../stores/JobStore";
import Visible from "../../components/Visible";
import JobBoardSearchCard from "./JobBoardSearchCard";
import {createUUID} from "../../stores/StoreUtilities";
import SearchIcon from "@material-ui/icons/Search";
import Column, {IRow} from '../../components/panel/Column';
import {matchSorter} from 'match-sorter'
import {NAICS} from "../../model/Industry";
import Service from "../../model/Service";

const styles = (theme: Theme) => createStyles({
  root: {
    flexGrow: 1,
    width: "100%",
    justifyContent: 'top',
    alignItems: 'center',
    overflow: "hidden"
  },
  title: {
    paddingTop: theme.spacing(0.5),
    paddingLeft: theme.spacing(1)
  },
  filterButtonOff: {
    backgroundColor: grey[400], // alpha(theme.palette.secondary.main, 0.3),
    color: '#fff',
    borderWidth: 0,
    marginTop: 0,
    marginRight: 8,
    paddingLeft: 8,
    boxShadow: 'rgba(0, 0, 0, 0.25) 0px 1px 3px 0px',
    "&:hover": {
      borderWidth: 0,
      backgroundColor: alpha(theme.palette.secondary.light, 0.8),
    }
  },
  filterButtonOn: {
    backgroundColor: theme.palette.secondary.main,
    color: '#fff',
    borderWidth: 0,
    marginTop: 0,
    marginRight: 8,
    paddingLeft: 8,
    boxShadow: 'rgba(0, 0, 0, 0.25) 0px 1px 3px 0px',
    "&:hover": {
      borderWidth: 0,
      backgroundColor: alpha(theme.palette.secondary.light, 0.8),
    }
  },
  content: {
    display: "flex",
    flexDirection:"row"
  },
  jobBoard: {
    display: "flex",
    flexDirection:"column"
  },
  cardspace: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(2) 
  },
  card: {
    width: 308
  },
  jobPost: {
    flexGrow: 1,
    width: "100%"
  },
  noResults: {
    display: "flex",
    flexGrow: 0,
    flexDirection: "row",
    justifyContent: "center",
    color: theme.palette.text.primary,
    width: "100%",
    height: "fit-content"
  },
  map: {
    flexGrow: 0,
    width: "100%",
    padding: 0,
    [theme.breakpoints.down('xs')]: {
      height: "calc(100vh - 182px)",
      width: "100%",
      marginTop: theme.spacing(0),
    },
    [theme.breakpoints.up('sm')]: {
      height: "calc(100vh - 210px)",
      width: "100%",
      marginTop: theme.spacing(1),
      borderRadius: 10,
    },
    marginBottom: 12,
    overflow: "hidden",
  },
})

enum View {
  Map,
  List,
  JobPost
}

interface IJobsPageProps {
  userStore?: UserStore
  resourceCache?: ResourceCache
  accountStore?: AccountStore
  jobStore?: JobStore
  progress?: Progress
  notify?: Notify
  confirm?: Confirm
  location?: any
}

@inject("userStore", "resourceCache", "accountStore", "jobStore", "progress", "notify", "confirm")
@observer
class JobsPage extends React.Component<WithStyles<typeof styles> & RouteComponentProps & IJobsPageProps & WithTheme & WithWidth> {
  @observable serviceGroups?: ServiceGroup[] = []
  @observable serviceFilter?: ServiceOption
  @observable locationFilter?: LocationOption
  @observable jobPosts: JobPost[] = []
  @observable jobPost?: JobPost
  @observable jobInterests: JobInterest[] = []
  @observable jobInterest?: JobInterest
  @observable showResults = false
  @observable isJobInterestApplyDialogOpen: boolean = false
  @observable myJobsOn: boolean = false
  @observable isMapOn: boolean = false
  @observable isJobBoardOn: boolean = false
  @observable isCardspaceOn: boolean = true
  @observable showMap: boolean = true
  @observable locationJobPosts: LocationJobPosts[] = []
  @observable view: View = View.Map
  @observable viewport = {
    // Geographic center of continental US
    latitude: 39.828303,
    longitude: -98.579476,
    zoom: 2,
  }
  @observable bounds?: LngLatBounds
  @observable industryFilter?: string
  @observable isLoading = true

  defaultSearchRadius = 50 * 1609.34 // 50 miles in meters

  constructor(
    props: WithStyles<typeof styles> & RouteComponentProps & IJobsPageProps & WithTheme & WithWidth
  ) {
    super(props);
    makeObservable(this);
  }

  componentDidMount () {
    const {userStore, jobStore, resourceCache, progress, location} = this.props

    this.isLoading = true
    progress!.show("JobsPage")
    when(
      // once
      () => !userStore!.isLoading && !jobStore!.isLoading && !resourceCache!.isLoading,
      // then 
      async () => {
        this.industryFilter = userStore!.industry
        if (location && location.search) {
          const searchParams = new URLSearchParams(location.search)
          const serviceId = searchParams.get("serviceId")
          if (serviceId) {
            this.serviceFilter = resourceCache!.getServiceOption(serviceId)
          }
          const locationId = searchParams.get("locationId")
          if (locationId) {
            this.locationFilter = resourceCache!.getLocationOption(locationId)
          }
        }
        if (location && location.pathname === Routes.myJobs) {
          this.myJobsOn = true
        }
        this.updateView(View.Map)
        this.isMapOn = false
        if (this.isWidthSM()) {
          this.viewport.zoom = 2
        }
        if (this.locationFilter) {
          this.updateViewport()
        } else {
          this.viewport = {
            // Geographic center of continental US
            latitude: 39.828303,
            longitude: -98.579476,
            zoom: 2
          }
          if (this.isWidthSM()) {
            this.viewport.zoom = 2
          }
        }
        await this.loadJobInterests()
        // Wait for map to load
        // TODO: Investigate other options based on map loading status
        setTimeout(() => {
          this.isMapOn = true
        }, 500)
        // await this.searchJobs()
        this.isLoading = false
        progress!.hide("JobsPage")
      }
    )

    autorun(
      () => {
        if (!userStore!.isLoading && userStore!.industry !== this.industryFilter) {
          this.industryFilter = userStore!.industry
          this.refreshMap()
        }
      }
    )
  }

  componentDidUpdate(prevProps: any) {
    if (prevProps.width !== this.props.width) {
      if (isWidthDown('xs', this.props.width)) {
        this.isCardspaceOn = !this.isMapOn
      } else {
        this.isCardspaceOn = true
      }
    }
  }

  render() {
    const { classes } = this.props

    const title = this.myJobsOn ? "My Jobs" : "Find Jobs"

    return (
      <Page title={title} hideFooter={true}>
        { this.isLoading === false &&  
          <Box px={{ xs: 0, sm: 1.5 }} pt={1} width={'100%'}>
            <div className={classes.title}>
              <Grid
                container
                alignItems='center'
                spacing={2}
              >
                <Grid item>
                  <Typography variant="h3" color="primary">
                    {title}
                  </Typography>
                </Grid>
                <Hidden xsDown>
                  <Grid item>
                    <Typography variant="body2">
                      {this.myJobsOn ?
                        "Jobs I have saved or have applied for." :
                        "Search for jobs available by job title and/or location."
                      }
                    </Typography>
                  </Grid>
                </Hidden>
              </Grid>
            </div>

            {this.renderFilterBar()}

            {this.isMapOn &&
              this.renderMap()
            }
            {!this.isMapOn && this.isCardspaceOn && !this.jobPost &&
              this.renderCardspace()
            }
            {this.isJobBoardOn && !this.jobPost &&
              this.renderJobBoard()
            }
            {(this.isJobBoardOn || this.isCardspaceOn) && !this.isMapOn && this.jobPost &&
              <div className={classes.jobPost}>
                {this.renderJobPost()}
              </div>
            }
            {this.jobPost &&
              <JobInterestApplyDialog
                jobPost={this.jobPost}
                isOpen={this.isJobInterestApplyDialogOpen}
                onSubmit={this.onApplySubmit}
                onClose={this.onApplyClose}
              />
            }
          </Box>
        }
      </Page>
    )
  }

  renderMap = () => {
    const { classes } = this.props

    return (
      <div className={classes.content}>
        {this.isCardspaceOn && this.renderCardspace()}
        {this.jobPost &&
          <div className={classes.jobPost}>
            {this.renderJobPost()}
          </div>
        }
        {this.showMap &&
          <div className={classes.map}>
            <JobMap
              viewport={this.viewport}
              locationJobPosts={this.locationJobPosts}
              selectedLocationId={this.locationFilter ? this.locationFilter.id : undefined}
              onViewportChange={this.onViewportChange}
              onClickLocation={this.onClickLocation}
            />
          </div>
        }
      </div>
    )
  }

  renderCardspace = () => {
    const {classes} = this.props

    return (
      <div className={classes.cardspace}>
        {this.showResults && this.jobPosts.length > 0 &&
          <Column
            // index={0}
            // draggable={false}
            column={{
              id: "Results", 
              title: "Jobs Found", 
              rows: this.jobPosts
            }}
            options={{
              // columnWidth: 316,
              renderCard: (row: IRow) => {
                return (
                  <JobPostSearchCard
                    jobPost={row as JobPost}
                    key={row.id}
                    jobInterest={((row as JobPost).jobInterests.length > 0) ? (row as JobPost).jobInterests[0] : undefined}
                    onClick={this.onClickJobPost}
                  />
                )
              }
            }}
          />
        }
        {this.showResults && this.jobPosts.length === 0 &&
          <div className={classes.noResults}>
            {this.renderNoResults()}
          </div>
        }
      </div>
    )
  }

  renderJobBoard = () => {
    const { classes } = this.props

    return (
      <div className={classes.jobBoard}>
        <Visible if={this.showResults}>
          <Grid container direction="column" spacing={2}>
            { this.jobPosts.map(jobPost => {
              return (
                <Grid item xs={12} key={jobPost.id}>
                  <JobBoardSearchCard
                    jobPost={jobPost}
                    jobInterest={jobPost.jobInterests.length > 0 ? jobPost.jobInterests[0] : undefined}
                    onClick={() => this.onClickJobPost(jobPost)}
                  />
                </Grid>
              )
            }) }
          </Grid>
        </Visible>

      </div>
    )
  }

  renderFilterBar = () => {
    const {classes, userStore} = this.props
    /*
      isMapOn    JobPost     View         Button
      -------    -------     ------       -----------
                             JobBoard     Show Map
                    X        JobPost      Show List
         X                   List+Map     Show List
         X          X        List+JobPost Show Map
     */

    let viewButton
    if ((!this.isMapOn && !this.jobPost) || (this.isMapOn && this.jobPost)) {
      viewButton =
        <DialogButton variant='secondary' size="large" onClick={this.onSelectMap} customClassName={classes.filterButtonOn}>
          <RoomIcon />&nbsp;&nbsp;Show Map
        </DialogButton>
    } else {
      viewButton =
        <DialogButton variant='secondary' size="large" onClick={this.onSelectJobBoard} customClassName={classes.filterButtonOn}>
          <ListIcon />&nbsp;&nbsp;Show List
        </DialogButton>
    }

    const buttons: [ReactNode] = [viewButton]

    if (userStore!.isCandidate || userStore!.isApplicant) {
      buttons.push(
        <DialogButton variant='secondary' size="large" onClick={this.onSelectMyJobs} customClassName={classes.filterButtonOn}>
          {this.myJobsOn &&
            <React.Fragment>
              <SearchIcon/>&nbsp;&nbsp;Find Jobs
            </React.Fragment>
          }
          {!this.myJobsOn &&
            <React.Fragment>
              <StarIcon />&nbsp;&nbsp;My Jobs
            </React.Fragment>
          }
        </DialogButton>
      )
    }

    return (
      <Box py={1}>
        <FilterBar
          searchResultCount={this.jobPosts.length}
          buttons={buttons}
        >
          <ServiceFilter
            freeSolo={false}
            value={this.serviceFilter}
            onSelectService={this.onSelectService}
          />
          <PlaceFilter
            value={this.locationFilter}
            onSelectLocation={this.onSelectLocation}
          />
        </FilterBar>
      </Box>
    )
  }

  renderJobPost = () => {
    const { userStore } = this.props
    if (!this.jobPost) {
      return null
    }

    return (
      <React.Fragment>
        <Box
          borderRadius={10}
          bgcolor="grey.500"
          boxShadow={0}
          my={2}
          ml={0}
          // mx={{ xs: 0, sm: 1 }}
          px={{ xs: 1, sm: 2 }}
          pb={2}
        >
          <Grid container direction="column" spacing={2} wrap="nowrap">
            {/*{!userStore!.isAuthenticated &&*/}
            {/*  <Grid item>*/}
            {/*    <CallToActionPanel prompt="Join or log in to see the full job description, save, and apply for jobs."/>*/}
            {/*  </Grid>*/}
            {/*}*/}
            <Grid item>
              <Box pt={1}>
                <JobPostHero 
                  jobPost={this.jobPost}
                  jobInterest={this.jobInterest}
                  onApply={this.onApply}
                  onSave={this.onSave}
                />
              </Box>
            </Grid>
            <Grid item>
              <JobPostDescriptionCard
                jobPost={this.jobPost}
                elevation={1}
              />
            </Grid>
          </Grid>
        </Box>
      </React.Fragment>
      )
  }

  renderNoResults = () => {
    const { userStore } = this.props

    if (this.locationFilter) {
      return (
        <Box
          borderRadius={10}
          bgcolor="grey.500"
          boxShadow={0}
          mb={3} 
          px={1} 
          pt={1} 
          pb={1}
        >
          <Grid container direction="column" spacing={2}>
            <Grid item>
              <Typography variant="h6" align="center" gutterBottom={true}>
                Sorry, no job posts matched your search.<br/>Please try a different search.
              </Typography>
            </Grid>
            {!userStore!.isAuthenticated &&
            [<Grid item>
              <Typography variant="h6" align="center">
                If you haven't joined GovGig, we encourage you to apply so employers
                can find you!
              </Typography>
            </Grid>,
              <Grid item xs={12} style={{textAlign: "center"}}>
                <DialogButton variant="primary" onClick={() => ControlTower.route(Routes.apply)}>
                  Apply
                </DialogButton>
              </Grid>]
            }
          </Grid>
        </Box>
      )
    } else {
      return (
        <Box
          borderRadius={10}
          bgcolor="grey.500"
          boxShadow={0}
          px={1} 
          pt={1} 
          pb={1}
          width={"100%"}
        >
          <Typography variant="h6" align="center">
            Select a Job Title and Location to view jobs
          </Typography>
        </Box>
      )
    }

  }

  onSelectService = (value?: ServiceOption) => {
    if (value) {
      this.serviceFilter = value
    } else {
      this.serviceFilter = undefined
    }
    this.refreshMap()
  }

  refreshMap = () => {
    if (this.isMapOn && this.bounds) {
      this.onViewportChange(this.viewport, this.bounds)
    } else {
      this.searchJobBoard()
    }
  }

  onSelectLocation = (value?: LocationOption) => {
    // Temporarily hide the map to force a call to onViewportChange
    const restoreMap = this.isMapOn
    this.isMapOn = false

    if (value) {
      this.locationFilter = value
      this.updateViewport()
    } else {
      this.locationFilter = undefined
    }
    this.locationJobPosts = []
    // this.searchJobs()
    if (restoreMap) {
      setTimeout(() => {this.updateView(View.Map)}, 200)
    } else {
      this.searchJobBoard()
    }
  }

  onSelectMap = () => {
    if (this.jobPost && this.isWidthSM()) {
      this.updateView(View.List)
    } else if (this.isMapOn && this.jobPost) {
      this.updateView(View.Map)
    } else {
      this.updateView((this.isMapOn) ? View.List : View.Map)
    }
  }

  onSelectJobBoard = () => {
    this.updateView(View.List)
  }

  onSelectMyJobs = () => {
    // this.myJobsOn = !this.myJobsOn
    // this.searchJobs()
    if (this.myJobsOn) {
      ControlTower.route(Routes.findJobs)
    } else {
      ControlTower.route(Routes.myJobs)
    }
  }

  onClickJobPost = async (jobPost: JobPost) => {
    const { userStore, jobStore } = this.props


    this.jobInterest = jobPost.jobInterests.find(jobInterest => jobInterest.jobPostId === jobPost.id)
    // Load full job post
    this.jobPost = await jobStore!.getJobPost(jobPost.id) ?? jobPost

    if (userStore!.isCandidate || userStore!.isApplicant) {
      userStore!.logUserActivity(userStore!.user!.id, SubjectType.JobPost, this.jobPost.id, ActivityType.JobPostViewed,
        `${userStore!.user!.fullName} viewed the job post '${this.jobPost.fullName}'`)
    }

    this.updateView(View.JobPost)
  }

  onClickLocation = (locationId?: string) => {
    const { resourceCache } = this.props

    if (locationId) {
      this.locationFilter = resourceCache!.getLocationOption(locationId)
      if (this.isWidthSM()) {
        this.updateView(View.List)
      }
    } else {
      this.locationFilter = undefined
    }
    this.searchJobs()
  }

  updateViewport = () => {
    const { resourceCache } = this.props
    if (this.locationFilter) {
      const location = resourceCache!.getLocation(this.locationFilter!.id)
      if (location) {
        let zoom = 8
        switch (location.addressType) {
          case AddressType.World:
            zoom = 1
            break;
          case AddressType.Country:
            zoom = 3
            break
          case AddressType.State:
            zoom = 5
            break
          case AddressType.County:
            zoom = 7
            break
          case AddressType.City:
            zoom = 8
            break
          default:
            zoom = 9
            break
        }
        // console.log(`updateViewport: (${location.latitude}, ${location.longitude}), ${zoom}`)
        this.viewport = {
          latitude: location.latitude,
          longitude: location.longitude,
          zoom: zoom
        }
      }
    }
  }

  updateView = (view: View) => {
    if (this.isWidthSM()) {
      if (view === View.Map) {
        this.isCardspaceOn = false
        this.jobPost = undefined
        this.isMapOn = true
      } else if (view === View.List) {
        this.isMapOn = false
        this.jobPost = undefined
        this.isCardspaceOn = true
        this.isJobBoardOn = false
      } else if (view === View.JobPost) {
        this.isMapOn = false
        this.isCardspaceOn = true
      }
    } else {
      if (view === View.Map) {
        this.isCardspaceOn = true
        this.jobPost = undefined
        this.showMap = true
        this.isMapOn = true
        this.isJobBoardOn = false
      } else if (view === View.List) {
        this.jobPost = undefined
        this.showMap = false
        this.isCardspaceOn = false
        this.isMapOn = false
        this.isJobBoardOn = true
      } else if (view === View.JobPost) {
        this.showMap = false
        this.isCardspaceOn = this.isMapOn
        this.isJobBoardOn = !this.isMapOn
      }
    }
    this.view = view
  }

  onViewportChange = async (viewport: any, bounds: LngLatBounds) => {
    const { jobStore, resourceCache } = this.props

    this.viewport = viewport
    this.bounds = bounds

    const serviceId = this.serviceFilter ? this.serviceFilter.id : undefined

    let stateSearch
    let countrySearch
    let anywhereSearch
    let remoteSearch
    if (this.locationFilter) {
      const location = resourceCache!.getLocation(this.locationFilter.id)
      if (location?.addressType === AddressType.State) {
        stateSearch = location.state
      } else if (location?.addressType === AddressType.Country) {
        countrySearch = location.country
      } else if (location?.addressType === AddressType.World) {
        if (location.name === 'Remote') {
          remoteSearch = true
        } else {
          anywhereSearch = true
        }
      }
    }

    let locationJobPosts
    let options: IJobSearchOptions = {
      industry: this.industryFilter,
      workSetting: remoteSearch ? WorkSetting.Remote : undefined
    }
    if (stateSearch) {
      locationJobPosts = await jobStore!.listLocationJobPostsByState(stateSearch, serviceId, options)
    } else if (countrySearch) {
      locationJobPosts = await jobStore!.listLocationJobPostsByCountry(countrySearch, serviceId, options)
    } else if (anywhereSearch || remoteSearch) {
      locationJobPosts = await jobStore!.listLocationJobPostsAnywhere(serviceId, options)
    } else {
      locationJobPosts = await jobStore!.listLocationJobPostsByBounds(bounds, serviceId, options)
    }

    // if (this.locationFilter) {
    //   // Make sure what is currently selected in the location filter is in the search results
    //   const found = this.locationJobPosts.find((item: LocationJobPosts) => item.location.id === this.locationFilter!.id)
    //   if (!found) {
    //     // console.log(`onViewPortChange: current location not found`)
    //     let jobPosts: JobPost[]
    //     const todaysISODate = getISODateToday()
    //     const filter: ModelJobPostFilterInput = {
    //       and: [
    //         {openDate: {le: todaysISODate}},
    //         {or: [
    //           {closedDate: {attributeType: ModelAttributeTypes._null}},
    //           {closedDate: {attributeExists: false}},
    //         ]}
    //       ]
    //     }
    //     if (serviceId) {
    //       jobPosts = await jobStore!.listJobPostsByServiceAndLocation(serviceId, this.locationFilter.id, filter)
    //     } else {
    //       jobPosts = await jobStore!.listJobPostsByLocation(this.locationFilter.id, filter)
    //     }
    //     const location = resourceCache!.getLocation(this.locationFilter.id)
    //     if (location) {
    //       // console.log(`onViewportChange: Added current location with ${jobPosts.length} job posts`)
    //       locationJobPosts.push(new LocationJobPosts(location!, jobPosts))
    //     }
    //   } else {
    //     // console.log(`onViewportChange: current location found with ${found.jobPosts.length} job posts`)
    //   }
    // }
    this.locationJobPosts = locationJobPosts

    this.searchJobs()
  }

  isWidthXS = (): boolean => {
    return isWidthDown('xs', this.props.width)
  }

  isWidthSM = (): boolean => {
    return isWidthDown('sm', this.props.width)
  }

  searchJobs = async () => {
    const { resourceCache } = this.props

    this.jobPost = undefined
    this.showResults = false

    let serviceId = (this.serviceFilter) ? this.serviceFilter.id : ""
    let locationId = (this.locationFilter) ? this.locationFilter.id : ""
    let jobPosts: JobPost[] = []

    if (this.myJobsOn) {
      // Search JobInterests
      this.jobInterests.forEach((jobInterest: JobInterest) => {
        const jobPost = jobInterest.jobPost
        if (jobPost) {
          if (!serviceId || serviceId === jobPost.serviceId) {
            if (!locationId || locationId === jobPost.locationId) {
              if (jobPost.jobInterests.length === 0) {
                jobPost.jobInterests.push(jobInterest)
              }
              jobPosts.push(jobPost)
            }
          }
        }
      })
      this.showResults = true
    } else {
      let locationId = (this.locationFilter) ? this.locationFilter.id : ""

      if (locationId && this.locationJobPosts) {
        let location = resourceCache!.getLocation(locationId)
        if (location?.addressType !== AddressType.State && location?.addressType !== AddressType.Country &&
            location?.addressType !== AddressType.World) {
          const found = this.locationJobPosts.find((item: LocationJobPosts) => item.location.id === locationId)
          if (found) {
            jobPosts = found.jobPosts
          }
        }
      }

      if (jobPosts.length === 0) {
        // If no job posts for the selected location, then show all job posts in view
        this.locationJobPosts.forEach((locationJobPosts: LocationJobPosts) => {
          jobPosts = [...jobPosts, ...locationJobPosts.jobPosts]
        })
      }

      jobPosts.forEach((jobPost: JobPost) => {
        if (this.jobInterests.length > 0) {
          const jobInterest = this.getJobInterestByJobPostId(jobPost.id)
          if (jobInterest) {
            jobPost.jobInterests.push(jobInterest)
          }
        }
      })
      this.showResults = true
    }

    if (!this.myJobsOn) {
      // Sort by title and createdAt
      // matchSorter(jobPosts, )

      if (this.serviceFilter) {
        jobPosts = matchSorter(jobPosts, this.serviceFilter.name,
          {keys: ['title'],
                   baseSort: (a, b) => b.item.createdAt.localeCompare(a.item.createdAt),
                   threshold: matchSorter.rankings.NO_MATCH
          })
      } else {
        jobPosts.sort((a: JobPost, b: JobPost) => {
          if (locationId) {
            if (a.locationId === b.locationId) {
              return b.createdAt.localeCompare(a.createdAt)
            } else if (a.locationId === locationId) {
              return -1
            } else if (b.locationId === locationId) {
              return 1
            } else {
              return 0
            }
          } else {
            return b.createdAt.localeCompare(a.createdAt)
          }
        })
      }
    }

    this.jobPosts = jobPosts
    this.showResults = true
  }

  searchJobBoard = async () => {
    const {jobStore} = this.props

    const locationId = this.locationFilter ? this.locationFilter.id : undefined
    const serviceId = this.serviceFilter ? this.serviceFilter.id : undefined
    const industry = this.industryFilter ? this.industryFilter : undefined

    let jobPosts: JobPost[] = []

    let options: IJobSearchOptions = {
      industry: industry
    }

    this.jobPosts = await jobStore!.findJobPosts(serviceId, locationId, options)
  }

  loadJobInterests = async () => {
    const { jobStore, userStore } = this.props

    if (userStore!.isCandidate || userStore!.isApplicant) {
      this.jobInterests = await jobStore!.listJobInterestsByUser(userStore!.user!.id)
      // Sort newest created first
      this.jobInterests.sort((a: JobInterest, b: JobInterest) => b.createdAt.localeCompare(a.createdAt))

      // Load corresponding JobPosts
      if (this.jobInterests.length > 0) {
        let promises = this.jobInterests.map((jobInterest: JobInterest) => {
          return jobStore!.getJobPost(jobInterest.jobPostId)
            .then((jobPost: JobPost | undefined) => {
              if (jobPost) {
                jobInterest.jobPost = jobPost
              }
            })
        })
        await Promise.all(promises)
      }
    }
  }

  getJobInterestByJobPostId = (jobPostId: string) => {
    return this.jobInterests.find((j: JobInterest) => j.jobPostId === jobPostId)
  }

  onSave = async (jobPost: JobPost) => {
    const { userStore, jobStore, notify, confirm } = this.props

    if (!(userStore?.isCandidate || userStore!.isApplicant)) {
      return
    }

    let jobInterest: JobInterest | undefined | void = jobPost.jobInterests.length > 0 ? jobPost.jobInterests[0] : undefined
    if (!jobInterest) {
      // Create new jobInterest
      const input: CreateJobInterestInput = {
        userId: userStore!.user!.id,
        jobPostId: jobPost.id,
        status: JobInterestStatus.Interested,
        ranking: 0
      }

      jobInterest = await jobStore!.createJobInterest(input)
        .catch((err: Error) => {
            notify!.show("error", "Error saving job interest")
        })

      if (jobInterest) {
        this.jobInterests.push(jobInterest)
        jobPost.jobInterests.push(jobInterest)
        this.jobInterest = jobInterest
      }
    } else {
      // Delete JobInterest
      if (jobInterest.status !== JobInterestStatus.Interested) {
        confirm!.show("Confirm Remove Job",
          `Please confirm you want to remove this ${jobInterest.status} status job post from My Jobs.`,
          ["Confirm","Cancel"], async () => {
           this.deleteJobInterest(jobInterest, jobPost);
           return true
        })
      } else {
        await this.deleteJobInterest(jobInterest, jobPost);
      }
    }
  }

  private async deleteJobInterest(jobInterest: JobInterest | void, jobPost: JobPost) {
    const { jobStore, notify } = this.props

    if (!jobInterest) {
      return
    }

    const deleted = await jobStore!.deleteJobInterest(jobInterest.id)
      .catch((err: Error) => {
        notify!.show("error", "Error deleting job interest")
      })

    if (deleted && jobInterest.id) {
      jobPost.jobInterests = []
      const jobInterestId = jobInterest.id
      this.jobInterests.splice(this.jobInterests.findIndex((j: JobInterest) => j.id === jobInterestId), 1)
      this.jobInterest = undefined
    }
  }

  onApply = async (jobPost: JobPost) => {
    const { jobStore } = this.props

    // if (!(userStore?.isCandidate || userStore!.isApplicant)) {
    //   return
    // }

    // Get jobPost questions
    const fullJobPost = await jobStore!.getJobPost(jobPost.id)
    if (fullJobPost) {
      this.jobPost!.questions = fullJobPost.questions
    }

    this.isJobInterestApplyDialogOpen = true
  }

  onApplySubmit = (jobPost: JobPost) => {
    const { userStore } = this.props

    this.jobPost = jobPost
    this.jobInterest = jobPost.jobInterests.length > 0 ? jobPost.jobInterests[0] : undefined

    userStore!.logUserActivity(userStore!.user!.id, SubjectType.JobPost, jobPost.id, ActivityType.JobCandidateApplied,
      `${userStore!.user!.fullName} applied to the job post '${jobPost.fullName}'`)

    this.isJobInterestApplyDialogOpen = false
  }

  onApplyClose = () => {
    this.isJobInterestApplyDialogOpen = false
  }
}

export default withTheme(withStyles(styles)(withWidth()(JobsPage)))
