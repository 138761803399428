import * as React from "react";
import {
  Box,
  createStyles,
  Grid,
  Theme,
  Typography,
  withStyles,
  WithStyles,
  withTheme,
  WithTheme,
  withWidth,
  WithWidth
} from "@material-ui/core";
import { RouteComponentProps } from "@reach/router";
import Page from "../../components/page/Page";
import MarginRow from "../../components/page/MarginRow";
import { makeObservable, observable, when } from "mobx";
import { inject, observer } from "mobx-react";
import AccountStore from "../../stores/AccountStore";
import Progress from "../../components/Progress";
import JobPost from "../../model/JobPost";
import JobPostForm from "../../components/jobPosts/JobPostForm";
import JobPostDescriptionCard from "../../components/jobPosts/JobPostDescriptionCard";
import JobPostDescriptionForm from "../../components/jobPosts/JobPostDescriptionForm";
import UserStore from "../../stores/UserStore";
import ControlTower, { Routes } from "../../components/ControlTower";
import Logger from "../../components/Logger";
import Account from "../../model/Account";
import Contract from "../../model/Contract";
import ProfileHeroImage from "../../images/Profile_Hero_Image_New.jpg";
import Notify from "../../components/notify/Notify";
import Confirm from "../../components/confirm/Confirm";
import JobInterest from "../../model/JobInterest";
import {CreateJobInterestInput, JobInterestStatus, SubjectType} from "../../API";
import JobInterestApplyDialog from "../../components/jobPosts/JobInterestApplyDialog";
import JobPostHero from "../../components/jobPosts/JobPostHero";
// import CallToActionPanel from "../../components/CallToActionPanel";
import {ActivityType} from "../../model/UserActivity";
import JobStore from "../../stores/JobStore";
import Stack from "../../components/Stack";
import ActionButton from "../../components/controls/ActionButton";

const styles = (theme: Theme) => createStyles({
  panel: {
    paddingTop: theme.spacing(7),
    paddingBottom: theme.spacing(2),
    background: `darkblue url(${ProfileHeroImage}) no-repeat center center`,
    backgroundSize: 'cover',
    color: theme.palette.common.white,
    borderRadius: '10px 10px 0px 0px',
    [theme.breakpoints.down('xs')]: {
      paddingRight: theme.spacing(2),
      paddingLeft: theme.spacing(2),
    },
    [theme.breakpoints.up('sm')]: {
      paddingRight: theme.spacing(3),
      paddingLeft: theme.spacing(3),
    }
  },
  card: {
    fontSize: 12,
    padding: '16px !important', // overriding CardContent root setting
    "&:hover $button": {
      visibility: "visible",
    },
  },
  icon: {
    fontSize: 45,
    height: 45,
    paddingRight: theme.spacing(2),
  },
  name: {
    fontSize: 20,
    color: theme.palette.secondary.main,
  },
  title: {
    paddingTop: '3px',
    paddingBottom: '6px',
  },
  button: {
    flex: 0,
    height: 0,
    textAlign: "right",
    visibility: "hidden",
  },
})

interface IJobPostPageProps {
  hideButtons?: boolean 
  embed?: boolean
  jobPostId?: string 
  onUpdateJobPost?(jobPost: JobPost): void
  
  accountStore?: AccountStore
  jobStore?: JobStore
  confirm?: Confirm
  location?: any 
  notify?: Notify
  userStore?: UserStore
  progress?: Progress
}

@inject('accountStore', 'jobStore', 'notify', 'progress', 'userStore')
@observer
class JobPostPage extends React.Component<WithStyles<typeof styles> & RouteComponentProps & IJobPostPageProps & WithTheme & WithWidth> {
  
  @observable isLoading = true
  @observable jobPost?: JobPost
  @observable jobInterest?: JobInterest
  @observable accounts?: Account[]
  @observable contracts?: Contract[]
  
  @observable isJobPostFormOpen: boolean = false
  @observable isJobPostDescriptionFormOpen: boolean = false 
  @observable isJobInterestApplyDialogOpen: boolean = false 

  constructor(props: any) {
    super(props);
    makeObservable(this);
  }

  componentDidMount () {
    const { accountStore, jobStore, userStore, progress, location } = this.props

    progress!.show("JobPostPage")
    
    when(
      // once
      () => {
        // Logged-in user & account loads.
        const isLoaded = (accountStore!.isLoading === false && 
            jobStore!.isLoading === false && userStore!.isLoading === false)
        // Public info loads.
        const isPublic = (userStore!.isPublic === true) 
        return isLoaded || isPublic
      },
      // then 
      async () => {
        let apply
        if (location) {
          const searchParams = new URLSearchParams(location.search)
          apply = searchParams.get("apply")
        }
        if (userStore!.isPublic === false) {
          await this.loadAccounts()
        }
        await this.loadJobPost()
        this.isJobInterestApplyDialogOpen = apply === "1"
        this.isLoading = false

        progress!.hide("JobPostPage")
      }
    )
  }
  
  render() {
    const { embed } = this.props 

    if (embed) {
      return (
        <React.Fragment>
          {this.isLoading === false ? this.renderContent() : null}
        </React.Fragment>
      )
    } else {
      return (
        <Page title="Job Post!">
          <MarginRow>
            {this.isLoading === false ? this.renderContent() : null}
          </MarginRow>
        </Page>
      )
    }
  }

  renderContent = () => {
    const { jobStore, userStore, hideButtons } = this.props

    // Lock down job posts when un-published and in certain cases. 
    let shouldShow = true 
    
    let jobPost: JobPost | undefined = undefined
    
    if (this.jobPost) {
      jobPost = this.jobPost 
      const isUnpublished = jobStore!.isJobPostUnpublished(jobPost)
      if (isUnpublished) {
        if (userStore!.isCandidate || userStore!.isApplicant) {
          // Don't show unpublished job posts to candidates. 
          shouldShow = false 
        } else if (userStore!.isEmployer && jobPost.accountId !== userStore!.user!.accountId) {
          // Don't show unpublished job posts to other employers. 
          shouldShow = false 
        }
      }
    } else {
      shouldShow = false 
    }

    if (!shouldShow) {
      return (
        <Box mt={{ xs: 3, sm: 5 }} mb={{ xs: 5, sm: 7 }}>
          <Stack direction="column" alignItems="center" spacing={2}>
            <Typography variant="h1" color="primary">Job Post Unavailable</Typography>
            <Typography>Sorry, this job post is not available. Head over to Find Jobs to search for open job posts.</Typography>
            <ActionButton
              text="Find Jobs"
              tracking="JobPost-Unavailable-FindJobs"
              click={() => {
                ControlTower.route(Routes.findJobs)
              }}
            />
          </Stack>
        </Box>
      ) 
    }

    return (
      <Box mt={{ xs: 1, sm: 2 }} mb={{ xs: 2, sm: 3 }}>
      {/* <Box my={{ xs: 2, sm: 3 }} px={{ xs: 1, sm: 2 }} pb={3} bgcolor="grey.500" borderRadius={10}> */}
        <Grid container direction="column" spacing={2} wrap="nowrap">
          {/*{!userStore!.isAuthenticated &&*/}
          {/*  <Grid item>*/}
          {/*    <CallToActionPanel prompt="Join or log in to see the full job description, save, and apply for jobs."/>*/}
          {/*  </Grid>*/}
          {/*}*/}
          <Grid item xs={12}>
            <JobPostHero 
              hideButtons={hideButtons}
              jobPost={jobPost!} 
              jobInterest={this.jobInterest}
              onApply={this.onApply}
              onSave={this.onSave}
              onEdit={() => {
                this.isJobPostFormOpen = true
              }}
            />
          </Grid>
          { jobPost && 
            <Grid item xs={12}>
              <JobPostDescriptionCard
                hideButtons={hideButtons}
                jobPost={jobPost} 
                elevation={1}
                onEdit={() => {
                  this.isJobPostDescriptionFormOpen = true 
                }}
              /> 
            </Grid>
          }
        </Grid>
        {this.isJobPostFormOpen &&
          <JobPostForm
            // accounts={this.accounts}
            jobPost={jobPost!}
            isFormOpen={this.isJobPostFormOpen}
            renderAsDialog={true}
            didClose={() => {
              this.isJobPostFormOpen = false
            }}
            didCreate={(jobPost: JobPost) => {
              // N/A - JobPost is already created when viewing it on this job post page.
            }}
            didDelete={(jobPost: JobPost) => {
              // TODO: Figure out what to do upon delete.
              // Route back to Contract page?
            }}
            didEdit={async (jobPost: JobPost) => {
              const { onUpdateJobPost } = this.props
              this.jobPost = jobPost
              if (onUpdateJobPost) {
                onUpdateJobPost(jobPost)
              }
            }}
          />
        }
        {this.isJobPostDescriptionFormOpen &&
          <JobPostDescriptionForm
            jobPost={jobPost!}
            isFormOpen={this.isJobPostDescriptionFormOpen}
            renderAsDialog={true}
            didClose={() => {
              this.isJobPostDescriptionFormOpen = false
            }}
            didEdit={(jobPost: JobPost) => {
              this.jobPost = jobPost
            }}
          />
        }
        {this.isJobInterestApplyDialogOpen &&
          <JobInterestApplyDialog
            jobPost={jobPost!}
            isOpen={this.isJobInterestApplyDialogOpen}
            onSubmit={this.onApplySubmit}
            onClose={this.onApplyClose}
          />
        }
      </Box>
    )
  }

  // Loading 

  loadAccounts = async () => {
    const { accountStore, userStore } = this.props

    if (userStore!.user?.isAdminOrAgent) {
      const accounts: Account[] = await accountStore!.listAccounts()
      const sorted = accounts.sort((a: Account, b: Account) => a.name.localeCompare(b.name))
      this.accounts = sorted  
    } 
  }

  loadJobPost = async () => {
    const { jobStore, userStore, jobPostId } = this.props

    try {
      const jobPost = await jobStore!.getJobPost(jobPostId!)

      if (jobPost) {
        if (userStore!.isCandidate || userStore!.isApplicant) {
          userStore!.logUserActivity(userStore!.user!.id, SubjectType.JobPost, jobPost.id, ActivityType.JobPostViewed,
            `${userStore!.user!.fullName} viewed the job post '${jobPost.fullName}'`)

          if (jobPost.jobInterests.length === 0) {
            // Load JobInterests which are not available by the public getJobPost
            jobPost.jobInterests = await jobStore!.listJobInterestsByJobPost(jobPost.id)
            if (jobPost.jobInterests.length > 0) {
              this.jobInterest = jobPost.jobInterests[0]
            }
          }
        }
        this.jobPost = jobPost
      }
    } catch(error) {
      Logger.error('Could not load job post!', error)
      ControlTower.route(Routes.home)
    }
  }

  // Actions 

  onSave = async (jobPost: JobPost) => {
    const { userStore, jobStore, notify, confirm } = this.props

    if (!(userStore?.isCandidate || userStore!.isApplicant)) {
      return
    }

    let jobInterest: JobInterest | undefined | void = jobPost.jobInterests.length > 0 ? jobPost.jobInterests[0] : undefined
    if (!jobInterest) {
      // Create new jobInterest
      const input: CreateJobInterestInput = {
        userId: userStore!.user!.id,
        jobPostId: jobPost.id,
        status: JobInterestStatus.Interested,
        ranking: 0
      }

      jobInterest = await jobStore!.createJobInterest(input)
        .catch((err: Error) => {
            notify!.show("error", "Error saving job interest")
        })

      if (jobInterest) {
        jobPost.jobInterests.push(jobInterest)
        this.jobInterest = jobInterest
      }
    } else {
      // Delete JobInterest
      if (jobInterest.status !== JobInterestStatus.Interested) {
        confirm!.show("Confirm Remove Job",
          `Please confirm you want to remove this ${jobInterest.status} status job post from My Jobs.`,
          ["Confirm","Cancel"], async () => {
           await this.deleteJobInterest(jobInterest, jobPost);
           return true
        })
      } else {
        await this.deleteJobInterest(jobInterest, jobPost);
      }
    }
  }

  private async deleteJobInterest(jobInterest: JobInterest | void, jobPost: JobPost) {
    const { jobStore, notify } = this.props

    if (!jobInterest) {
      return
    }

    const deleted = await jobStore!.deleteJobInterest(jobInterest.id)
      .catch((err: Error) => {
        notify!.show("error", "Error deleting job interest")
      })

    if (deleted && jobInterest.id) {
      jobPost.jobInterests = []
      this.jobInterest = undefined
    }
  }

  onApply = (jobPost: JobPost) => {
    // const { userStore } = this.props

    // if (!(userStore?.isCandidate || userStore?.isApplicant)) {
    //   return
    // }

    this.isJobInterestApplyDialogOpen = true
  }

  onApplySubmit = (jobPost: JobPost) => {
    const { userStore } = this.props

    this.jobPost = jobPost

    userStore!.logUserActivity(userStore!.user!.id, SubjectType.JobPost, jobPost.id, ActivityType.JobCandidateApplied,
      `${userStore!.user!.fullName} applied to the job post '${jobPost.fullName}'`)

    this.jobInterest = jobPost.jobInterests.length > 0 ? jobPost.jobInterests[0] : undefined
    this.isJobInterestApplyDialogOpen = false
  }

  onApplyClose = () => {
    this.isJobInterestApplyDialogOpen = false
  }
}

export default withTheme(withStyles(styles)(withWidth()(JobPostPage)))
