import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import {createStyles, Theme, withStyles, WithStyles} from '@material-ui/core/styles';
import {RouteComponentProps} from '@reach/router';
import {makeObservable, observable} from "mobx";
import {inject, observer} from "mobx-react";
import * as React from 'react';
import CancelButton from '../../components/form/CancelButton';
import FormValidator from '../../components/form/FormValidator';
import ProgressButton from '../../components/form/ProgressButton';
import TextFieldValidator from '../../components/form/TextFieldValidator';
import Notify from '../../components/notify/Notify';
import ProfileStore from "../../stores/ProfileStore";
import {AgencyType, UpdateExperienceInput} from "../../API";
import Experience, {FederalAgency} from "../../model/Experience";

const styles = (theme: Theme) => createStyles({
  submitButton: {
    color: "#fff",
  },
  instructions: {
    marginBottom: 0
  }

})

interface IAgencyDialogProps {
  experience: Experience
  agencyType?: AgencyType
  onClose: any
  onChange: any
  profileStore?: ProfileStore
  notify?: Notify
}

@inject("profileStore", "notify")
@observer
class AgencyDialog extends React.Component<WithStyles<typeof styles> & RouteComponentProps & IAgencyDialogProps> {
  @observable values = {
    agencyType: "",
    agencyName: ""
  }

  constructor(
    props: WithStyles<typeof styles> & RouteComponentProps & IAgencyDialogProps
  ) {
    super(props);
    makeObservable(this);
  }

  componentDidMount() {
    const {experience, agencyType} = this.props
    if (agencyType) {
      this.values.agencyType = agencyType
    }
    this.values.agencyName = experience.agencyName
  }

  @observable message = ""
  @observable isProcessing: boolean = false

  onClose = () => {
    if (this.props.onClose) {
      this.message = ""
      this.props.onClose();
    }
  };

  onSubmit = async () => {
    const { experience, profileStore, notify, onChange } = this.props

    this.message = ""
    this.isProcessing = true

    const input: UpdateExperienceInput = {
      id: experience.id,
      agencyType: AgencyType[this.values.agencyType],
      agencyName: this.values.agencyName
    }
    const exp = await profileStore!.updateExperience(input)
      .catch((err: Error) => {
        notify!.show("error", "Unable to set federal experience")
      })
    this.isProcessing = false

    if (exp && onChange) {
      onChange(exp)
    }

  }

  handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const name = event.target.name
    this.values[name] = event.target.value
  }

  render() {

    return (
      <Dialog
        id="agencyDialog"
        open={true}
        onClose={this.onClose}
        scroll="paper"
        maxWidth="xs"
        fullWidth
        aria-labelledby="agency-dialog">
        <FormValidator onSubmit={this.onSubmit} autoComplete="off">
          <DialogTitle id="agency-dialog">Agency</DialogTitle>
          <DialogContent>
            <DialogContentText color="error">
              {this.message}
            </DialogContentText>
            <TextFieldValidator
              type="text"
              margin="normal"
              name="agencyType"
              label="Agency Type"
              autocompleteOptions={{
                options: Object.values(AgencyType),
                value: this.values.agencyType,
                onChange: (event: any, value: AgencyType, reason: string) => {
                  this.values.agencyType = value
                }
              }}
            />
            <TextFieldValidator
              type="text"
              validators={{required: this.values.agencyType !== AgencyType.None}}
              name="agencyName"
              label="Agency Name"
              fullWidth
              helperText="Government agency or department name (e.g. NAVFAC, USACE)"
              autocompleteOptions={{
                freeSolo: true,
                options: this.values.agencyType === AgencyType.Federal ? Object.values(FederalAgency) : [],
                value: this.values.agencyName,
                onChange: (event: any, value: string, reason: string) => {
                  this.values.agencyName = value
                }
              }}

            />
          </DialogContent>
          <DialogActions>
            <CancelButton onClick={this.onClose} />
            <ProgressButton variant="contained" color="primary"
                            type="submit" processing={this.isProcessing}
                            disabled={!this.values.agencyName && this.values.agencyType !== AgencyType.None}
                            onClick={this.onSubmit}>
              Submit
            </ProgressButton>
          </DialogActions>
        </FormValidator>
      </Dialog>
    );
  }
}

export default withStyles(styles)(AgencyDialog)