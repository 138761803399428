import {
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormGroup,
  Grid,
  isWidthDown, TextareaAutosize,
  Theme, Typography,
  withStyles,
  WithStyles,
  WithTheme,
  withTheme,
  withWidth,
  WithWidth
} from "@material-ui/core";
import { grey } from "@material-ui/core/colors";
import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";
import { parse } from "date-fns";
import endOfMonth from 'date-fns/endOfMonth';
import { makeObservable, observable } from "mobx";
import { inject, observer } from "mobx-react";
import React from "react";
import { CreateEducationInput, UpdateEducationInput } from "../../API";
import AddButton from "../../components/AddButton";
import CardValue from "../../components/CardValue";
import IconicButton from "../../components/controls/IconicButton";
import CancelButton from "../../components/form/CancelButton";
import DatePickerValidator from "../../components/form/DatePickerValidator";
import FormGroupSpacer from "../../components/form/FormGroupSpacer";
import FormValidator from "../../components/form/FormValidator";
import ProgressButton from "../../components/form/ProgressButton";
import TextFieldValidator from "../../components/form/TextFieldValidator";
import Notify from "../../components/notify/Notify";
import TextBlock from "../../components/TextBlock";
import Visible from "../../components/Visible";
import Education from "../../model/Education";
import Profile from "../../model/Profile";
import ProfileStore from "../../stores/ProfileStore";
import {
  getErrorMessage,
  isoToLocalDateString
} from "../../stores/StoreUtilities";
import UserStore from "../../stores/UserStore";
import ProfileCard from "./ProfileCard";

const styles = (theme: Theme) => createStyles({
  container: {
    flex: 1
  },
  educationContainer: {
  },
  form: {
    overflowY: "auto",
    display: "flex",
    flexDirection: "column"
  },
  formGroupRow: {
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "nowrap"
  },
  progressButton: {
    minWidth: 80
  },
  schoolTitle: {
    fontFamily: [
      'Montserrat',
      'sans-serif'
    ].join(','),
    fontWeight: 800,
    fontSize: 20,
    color: theme.palette.secondary.dark
  },
  label: {
    color: theme.palette.text.secondary,
    fontSize: 12,
    marginTop: theme.spacing(1),
    marginBottom: 4,
    marginLeft: 0,
  },
  textarea: {
    fontFamily: [
      'Roboto',
      'sans-serif'
    ].join(','),
    minWidth: "100%",
    maxWidth: "100%",
    padding: 8,
    fontSize: 16
  },
  publicNotice: {
    marginTop: theme.spacing(1),
    padding: theme.spacing(1),
    backgroundColor: grey[500],
    color: theme.palette.primary.contrastText,
    borderRadius: 10,
    textAlign: "center"
  }
})

export interface IProfileEducationProps {
  profile?: Profile
  isOpen?: boolean
  onChange?: (profile: Profile) => any
  onClose?: any
  profileStore?: ProfileStore
  userStore?: UserStore
  notify?: Notify
}

class ProfileEducationViewModel {
  @observable id: string
  @observable school: string
  @observable degree: string
  @observable field: string
  @observable startDate: string
  @observable endDate: string
  @observable description: string

  constructor (e: Education) {
    makeObservable(this);
    this.id = e.id
    this.school = e.school
    this.degree = e.degree
    this.field = e.field
    this.startDate = (e.startDate) ? isoToLocalDateString(e.startDate, "MM/yyyy") : ""
    this.endDate = (e.endDate) ? isoToLocalDateString(e.endDate, "MM/yyyy") : ""
    this.description = e.description
  }
}

@inject("userStore", "profileStore", "notify")
@observer
class ProfileEducation extends React.Component<WithStyles<typeof styles> & WithTheme & WithWidth & IProfileEducationProps> {
  @observable educations: Education[] = []
  @observable viewModel?: ProfileEducationViewModel
  @observable isFormOpen: boolean = false
  @observable isProcessing = false

  constructor(
    props: WithStyles<typeof styles> & WithTheme & WithWidth & IProfileEducationProps
  ) {
    super(props);
    makeObservable(this);
  }

  componentDidMount() {
    this.loadEducations()
  }

  componentDidUpdate(prevProps: any) {
    if (prevProps.profile !== this.props.profile) {
      this.componentDidMount()
    }
    if (prevProps.isOpen !== this.props.isOpen) {
      if (this.props.isOpen) {
        this.handleAdd()
      } else {
        this.isFormOpen = false
      }
    }
  }

  render() {
    const { onChange, profile, width } = this.props

    let addButton
    if (onChange) {
      addButton =
        <IconicButton onClick={this.handleAdd}>
          <AddIcon />
        </IconicButton>
    }

    if (!profile) {
      return null;
    }

    return (
      <ProfileCard
      title="Education"
      button={addButton}>
        <Grid
          container
          direction={isWidthDown('xs', width) ? "column" : "row"}
          justifyContent="flex-start"
          alignItems="flex-start"
          spacing={3}
        >
          {profile &&
            <Grid item xs={12}>
              {this.renderStaticFields()}
            </Grid>
          }
        </Grid>
        {this.isFormOpen && this.renderForm()}

      </ProfileCard>
    )
  }

  renderStaticFields() {
    const {width, classes, onChange, userStore} = this.props

    // NOTE: The profile is used here rather than the viewModel because we don't want the
    // form changes to affect the static fields until the user presses Save

    const isAuthenticated = userStore!.isAuthenticated
    const instructions = this.educations.length === 0 && onChange ? "Add education." : ""
    const labelWidth = 120 

    return (
      <div>
        <Grid
          container
          spacing={isWidthDown('xs', width) ? 2 : 0}
        >
          {instructions &&
            <Grid item xs={12}>
              <Typography gutterBottom>
                {instructions}
              </Typography>
            </Grid>
          }
          {isAuthenticated &&
            <Grid item xs={12}>
              {this.educations.map((e: Education) => {
                return <Grid container direction="row" justifyContent="flex-start"
                             className={classes.educationContainer} key={e.id}>
                  {onChange &&
                  <Grid item container justifyContent="flex-end" style={{position: "relative", top: 36, right: 0}}>
                    <IconicButton onClick={() => {
                      this.handleEdit(e)
                    }}>
                      <EditIcon/>
                    </IconicButton>
                  </Grid>
                  }
                  {!onChange &&
                  <Grid container>&nbsp;</Grid>
                  }
                  <Grid item xs={12} sm={12}>
                    <Typography className={classes.schoolTitle}>
                      {e.school}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <CardValue label="Degree:" labelWidth={labelWidth}>
                      {e.degree}
                    </CardValue>
                    <Visible if={e.field ? true : false}>
                      <CardValue label="Field:" labelWidth={labelWidth}>
                        {e.field}
                      </CardValue>
                    </Visible>
                    <Visible if={e.startDate ? true : false}>
                      <CardValue label="When:" labelWidth={labelWidth}>
                        {isoToLocalDateString(e.startDate, "yyyy")} - {isoToLocalDateString(e.endDate, "yyyy")}
                      </CardValue>
                    </Visible>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography component="div" variant="body2">
                      <TextBlock>{e.description}</TextBlock>
                    </Typography>
                  </Grid>
                </Grid>
              })}
            </Grid>
          }
          {!isAuthenticated && this.educations.length > 0 &&
            <Grid item xs={12} className={classes.publicNotice}>
              <Typography variant="body1">
                {this.educations.length} items available when you join or login...
              </Typography>
            </Grid>
          }
        </Grid>
      </div>
    )
  }

  renderForm() {
    const { classes } = this.props
    const vm = this.viewModel!

    return (
      <Dialog
        open={this.isFormOpen}
        onClose={this.handleClose}
        fullWidth
        maxWidth="sm"
        scroll="paper"
      >
        <FormValidator onSubmit={this.onSubmit} autoComplete="off" name="editForm" id="editForm" className={classes.form}>
          <DialogTitle id="form-dialog-title">Education</DialogTitle>
          <DialogContent dividers>
            <TextFieldValidator
              type="text"
              validators={{required: true}}
              required
              name="school"
              label="School"
              value={vm.school}
              onChange={this.handleChange}
              fullWidth
            />
            <TextFieldValidator
              type="text"
              validators={{required: true}}
              name="degree"
              label="Degree"
              onChange={this.handleChange}
              fullWidth
              autocompleteOptions={{
                ...this.props.profileStore?.getDegreeOptions(),
                value: vm.degree
              }}
            />
            <TextFieldValidator
              type="text"
              validators={{required: false}}
              name="field"
              label="Field of Study"
              value={vm.field}
              onChange={this.handleChange}
              fullWidth
            />
            <FormGroup row classes={{row: classes.formGroupRow}}>
              <DatePickerValidator
                disableToolbar={false}
                variant="inline"
                format="MM/yyyy"
                placeholder="MM/yyyy"
                margin="none"
                fullWidth
                autoOk
                id="startDate"
                label="Start Date"
                name="startDate"
                value={vm.startDate}
                onChange={this.handleChange}
              />
              <FormGroupSpacer/>
              <DatePickerValidator
                disableToolbar={false}
                variant="inline"
                format="MM/yyyy"
                placeholder="MM/yyyy"
                margin="none"
                fullWidth
                autoOk
                id="endDate"
                label="End Date"
                name="endDate"
                value={vm.endDate}
                onChange={this.handleChange}
              />
            </FormGroup>

            <div className={classes.label}>Description</div>
            <TextareaAutosize
              name="description"
              value={vm.description}
              onChange={this.handleChange}
              minRows={5}
              rowsMax={10}
              className={classes.textarea}
            />
          </DialogContent>
          <DialogActions>
            <Grid container justifyContent="space-between" spacing={1}>
              <Grid item>
                {vm.id &&
                  <AddButton
                    text="Delete"
                    tracking="deleteEducation" // available for google analytics
                    buttonColor="primary"
                    buttonVariant="outlined"
                    icon="remove"
                    click={() => this.handleDelete(vm.id)}
                  />
                }
              </Grid>
              <Grid item>
                <Grid container>
                  <CancelButton onClick={this.handleClose} />
                  <ProgressButton variant="contained" size="medium" color="secondary"
                                  type="submit" className={classes.progressButton} processing={this.isProcessing}>
                    Save
                  </ProgressButton>
                </Grid>
              </Grid>
            </Grid>
          </DialogActions>
        </FormValidator>
      </Dialog>

    )
  }

  handleChange = (event: any) => {
    const name = event.target.name
    this.viewModel![name] = event.target.value
  }

  handleAdd = (): void => {
    const { profile } = this.props

    if (profile) {
      const education = new Education({
        userId: profile.userId,
        profileId: profile.id,
        school: "",
        description: "",
        degree: "",
        field: "",
        startDate: "",
        endDate: "",
      })

      this.viewModel = new ProfileEducationViewModel(education)
      this.isFormOpen = true
    }
  }

  handleEdit = (education: Education): void => {
    if (this.props.profile) {
      this.viewModel = new ProfileEducationViewModel(education)
      this.isFormOpen = true
    }
  }

  handleDelete = async (educationId: string) => {
    const { profileStore, profile, onChange, notify } = this.props

    try {
      await profileStore!.deleteEducation(educationId)
      const index = profile!.education.findIndex((e: Education) => e.id === educationId)
      if (index >= 0) {
        profile!.education.splice(index, 1)
      }
      this.loadEducations()
      if (onChange) {
        onChange(profile!)
      }
      this.isProcessing = false
      this.handleClose()
    } catch (error) {
      notify!.show("error", getErrorMessage(error))
    }
  }

  handleClose = () => {
    this.isFormOpen = false
    if (this.props.onClose) {
      this.props.onClose()
    }
  }

  onSubmit = async () => {
    const {profile, profileStore, onChange, notify} = this.props
    const vm = this.viewModel!

    if (onChange) {
      this.isProcessing = true

      try {
        if (!vm.id) {
          const input: CreateEducationInput = {
            userId: profile!.userId,
            profileId: profile!.id,
            degree: vm.degree,
            field: vm.field,
            school: vm.school,
            startDate: vm.startDate ? this.getISODateFromMonthYearString(vm.startDate): null,
            endDate: vm.endDate ? this.getISODateFromMonthYearString(vm.endDate, true) : null,
            description: vm.description,
          }

          let education = await profileStore?.createEducation(input)

          if (education) {
            profile!.education.push(education)
            this.loadEducations()
            onChange(profile!)
            this.isProcessing = false
            this.handleClose()
          }
        } else {
          const input: UpdateEducationInput = {
            id: vm.id,
            degree: vm.degree,
            field: vm.field,
            school: vm.school,
            startDate: this.getISODateFromMonthYearString(vm.startDate),
            endDate: vm.endDate ? this.getISODateFromMonthYearString(vm.endDate, true) : null,
            description: vm.description,
          }

          let education = await profileStore?.updateEducation(input)

          if (education) {
            const index = profile!.education.findIndex((e: Education) => e.id === vm.id)
            if (index >= 0) {
              profile!.education[index] = education
            }
            this.loadEducations()
            onChange(profile!)
            this.isProcessing = false
            this.handleClose()
          }
        }
      } catch (error) {
        notify!.show("error", getErrorMessage(error))
        this.isProcessing = false
      }

      this.isProcessing = false
    }
  }

  loadEducations = () => {
    const { profile } = this.props

    if (!profile) {
      return
    }

    let educations: Education[] = []

    if (profile!.education && profile!.education.length > 0) {
      profile!.education.forEach((education: Education) => {
        educations.push(education)
      })
      // Sort in reverse startDate order
      educations.sort((a: Education, b: Education) => {
        if (b.startDate && !a.startDate){
          return 1
        } else if (!b.startDate && a.startDate) {
          return -1
        } else if (!b.startDate && !a.startDate) {
          return 0
        } else {
          return b.startDate.localeCompare(a.startDate)
        }
      })
    }

    this.educations = educations
  }

  getISODateFromMonthYearString(input: string | null | undefined, monthEnd: boolean = false) {
    if (input) {
      let date = parse(input, "MM/yyyy", new Date())
      if (monthEnd) {
        date = endOfMonth(date)
        date.setMinutes(-date.getTimezoneOffset())
      }
      return date.toISOString().substr(0, 10)
    } else {
      return ""
    }
  }
}

export default withTheme(withStyles(styles)(withWidth()(ProfileEducation)))
