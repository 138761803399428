import { API } from "aws-amplify";

const DocumentServiceAPIName = 'DocumentService'

class DocumentServiceAPI {

  private async headers(overrides: any = {}) {
    return {
      'Content-Type' : 'application/json',
      ...overrides
    }
  }

  async convertDocToPDF(
    docKey: string,
    newPDFKey?: string, level: 'public' | 'protected' | 'private' = 'public'
  ): Promise<{ key: string, url: string }> {
    if (!newPDFKey) {
      newPDFKey = String(docKey).substring(String(docKey).lastIndexOf('.') + 1).concat('.pdf')
    }
    return await API.post(
      DocumentServiceAPIName,
      '/v1/convert-to-pdf', {
      headers: await this.headers(),
      body: { docKey, newPDFKey, level },
    });
  }
}

export default DocumentServiceAPI
