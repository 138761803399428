import { Button } from "@material-ui/core"
import Stack from "../Stack"
import ProgressButton from "../form/ProgressButton"

const DialogPanelButtons = ({
  buttonTitle, 
  buttonDisabled,
  backButtonTitle, 
  isProcessing,
  onClickNext, 
  onClickBack,
  onClickSkip
}: {
  buttonTitle?: string  
  buttonDisabled: boolean 
  backButtonTitle?: string  
  isProcessing: boolean 
  onClickNext?: () => Promise<void>
  onClickBack?: () => void
  onClickSkip?: () => void 
}) => {
  return (
    <Stack 
      direction="row"
      justifyContent="space-between"
      sx={{
        pt: 3 
      }}
    >
      { onClickBack && 
        <Button
          variant="contained"
          color="inherit"
          onClick={onClickBack}
        >
          { backButtonTitle ?? "Back" }
        </Button>
      }
      <Stack spacing={2}>
        { onClickSkip && 
          <Button
            variant="contained"
            color="secondary"
            onClick={onClickSkip}
          >
            Skip 
          </Button>
        }
        <ProgressButton
          type="submit"
          variant="contained"
          color="secondary"
          disabled={buttonDisabled || isProcessing}
          onClick={onClickNext}
          processing={isProcessing}
        >
          { buttonTitle ?? "Next" }
        </ProgressButton>
      </Stack>
    </Stack>
  )
}

export default DialogPanelButtons
