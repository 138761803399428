import * as React from "react";
import { createStyles, Theme, WithStyles, withStyles } from '@material-ui/core/styles'
import {inject} from "mobx-react";
import {IFieldValidator} from "./FormValidator";
import {
  KeyboardDatePicker,
  KeyboardDatePickerProps,
  MuiPickersUtilsProvider
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import {FormControlProps} from "@material-ui/core/FormControl";
import {TextField} from "@material-ui/core";

const styles = (theme: Theme) => createStyles({

})

// TODO: When needed
// interface IDatePickerValidations {
//   required?: boolean
//   minDate?: string
//   maxDate?: string
// }

interface IDatePickerValidatorProps {
  error?: boolean
  helperText?: React.ReactNode
  label?: React.ReactNode
  inputRef?: any
  required?: boolean
  // validators?: IDatePickerValidations
  errorMessages?: any
  onBlur?: any // React.EventHandler<any>
  onChange?: any // React.EventHandler<any>
  formvalidator?: any
  validate?: any
  value: string | null
  format: string
}

export type DatePickeralidatorProps = KeyboardDatePickerProps & IDatePickerValidatorProps & IFieldValidator


@inject("formvalidator")
class DatePickerValidator extends React.Component<WithStyles<typeof styles> & DatePickeralidatorProps > {

  state = {
    isValid: true,
    date: null,
    errorMessage: ""
  }

  componentDidMount() {
    const {formvalidator} = this.props as IDatePickerValidatorProps
    if (formvalidator) {
      formvalidator.attachToForm(this)
    }
    this.setState({isValid: true, date: null, errorMessage: ""})
  }


  render() {
    const {
      inputRef,
      error,
      onBlur,
      onChange,
      value,
      required,
      ...rest
    } = this.props as IDatePickerValidatorProps;

    return (
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardDatePicker
          {...rest}
          value={this.state.date}
          inputValue={value || ""}
          onBlur={this.onBlur}
          onChange={this.onChange}
          error={!this.state.isValid || this.props.error}
          helperText={((!this.state.isValid || this.props.error) && this.state.errorMessage) || this.props.helperText}
          TextFieldComponent={TextField}
        />
      </MuiPickersUtilsProvider>
    )
  }

  private onBlur = (event: React.FocusEvent<HTMLInputElement>): void => {
    const { value } = event.currentTarget;
    const { props } = this
    const { onBlur } = props as FormControlProps

    this.validate(value)

    if (onBlur) {
      onBlur(event);
    }
  }

  private onChange = (date: any, value: string | null | undefined): void => {
    const { props } = this
    const { onChange } = props // as FormControlProps

    console.log(`onChange(${date?.toLocaleString()}, ${value})`)

    if (date && !isNaN(date)) {
      this.setState({date: date})
    }

    if (!this.state.isValid) {
      this.validate(value)
    }

    if (onChange) {
      const event = {
        target: {
          name: props.name,
          value
        }
      }
      onChange(event);
    }
  }

  validate = (value: string | null | undefined): boolean => {
    const { required } = this.props
    let isValid = true
    let errorMessage = ""
    if (required && value === "") {
      isValid = false
      errorMessage = `${this.props.label} is required`
    }

    this.setState({isValid, errorMessage})

    return isValid
  }

}

export default withStyles(styles)(DatePickerValidator)