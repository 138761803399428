import * as React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { createStyles, withStyles, Theme, WithStyles } from '@material-ui/core/styles'
import { RouteComponentProps } from '@reach/router'
import DialogButton from '../form/DialogButton'
import { observable, makeObservable } from "mobx";
import {inject, observer} from "mobx-react";
import FormValidator from "../../components/form/FormValidator";
import TextFieldValidator from "../../components/form/TextFieldValidator";
import Tracking from "../Tracking";
import {Box, Container, Grid, Typography} from "@material-ui/core";
import CancelButton from '../form/CancelButton';
import UserStore, {CognitoAttribute} from "../../stores/UserStore";
import get from "lodash.get";
import Notify from "../notify/Notify";

const styles = (theme: Theme) => createStyles({
  message: {
    color: theme.palette.info.dark,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  container: {
    height: "100%",
    flexGrow: 1,
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
})

interface IPasswordAssistDialogProps {
  onShowLogin: any,
  onClose: any,
  userStore?: UserStore
  notify?: Notify
  embed?: boolean 
}

@inject("userStore", "notify")
@observer
class PasswordAssistDialog extends React.Component<WithStyles<typeof styles> & RouteComponentProps & IPasswordAssistDialogProps> {
  @observable values = {
    email: "",
    code: "",
    newPassword: ""
  }

  @observable email = ""
  @observable message = ""
  @observable reset = false

  onClose = () => {
    if (this.props.onClose) {
      this.message = ""
      this.reset = false
      this.props.onClose();
    }
  };

  onSubmit = () => {
    const { userStore, notify } = this.props
    this.message = ""

    if (!this.reset) {
      userStore!.forgotPassword(this.values.email)
        .then((data: any) => {
          Tracking.event({action: "Password reset verification sent"})
          const delivery = (get(data, "CodeDeliveryDetails.AttributeName") === CognitoAttribute.PHONE_NUMBER) ? "phone" : "email"
          this.message = `Please check your ${delivery} for a password reset verification code.`
          this.reset = true
        })
        .catch((err: any) => {
            this.message = err.message;
         })
    } else {
      userStore!.forgotPasswordSubmit(this.values.email, this.values.code, this.values.newPassword)
        .then((data:any) => {
          Tracking.event({action: "Password reset"})
          notify!.show("success", "Your password has been updated")

          if (this.props.onShowLogin) {
            this.message = ""
            this.reset = false
            this.props.onShowLogin();
          }
        })
        .catch((err:any) => {
          this.message = err.message
        });

    }
  }

  onResendCode = () => {
    const { userStore } = this.props
    this.message = ""

    userStore!.forgotPassword(this.values.email)
      .then((data: any) => {
        console.log(data)
        Tracking.event({action: "Password reset verification resent"})
        const delivery = (get(data, "CodeDeliveryDetails.AttributeName") === CognitoAttribute.PHONE_NUMBER) ? "phone" : "email"
        this.message = `Please check your ${delivery} for a password reset verification code.`
      })
      .catch((err:any) => {
        this.message = err.message
      });
  }

  onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const name = event.target.name
    this.values[name] = event.target.value
  }

  constructor(
    props: WithStyles<typeof styles> & RouteComponentProps & IPasswordAssistDialogProps
  ) {
    super(props);
    makeObservable(this);
  }

  renderEmbedded() {
    const { classes } = this.props 
    return (
      <Container maxWidth="sm" className={classes.container}>
        <Box
          bgcolor="#fff"
          borderRadius={10}
          px={3}
          py={5}
          my={5}
        >
          <Grid 
            container 
            direction="column" 
            justifyContent="flex-start"
            alignItems="center"
          >
            {/* <Grid item>
              <img src={logo} className={classes.logo} />
            </Grid> */}
            <Grid item>
              <Typography
                variant="h3"
              >
                Password Assistance
              </Typography>
            </Grid>
          </Grid>
          <FormValidator 
            onSubmit={this.onSubmit} 
            autoComplete="off" 
            name="passwordForm" 
            id="passwordForm"
          >
            {/* <DialogTitle id="password-assist">Password Assistance</DialogTitle> */}
            <DialogContent>
              <DialogContentText>
                Enter the email address associated with your GovGig account.
              </DialogContentText>
              <TextFieldValidator
                autoFocus
                margin="dense"
                name="email"
                label="Email Address"
                type="email"
                validators={{required:true, isEmail:null}}
                value={this.values.email}
                onChange={this.onChange}
                fullWidth
              />
              <DialogContentText className={classes.message} variant="body2">
                {this.message}
              </DialogContentText>
              {this.reset &&
                <TextFieldValidator
                  autoFocus
                  margin="dense"
                  name="code"
                  label="Verification Code"
                  type="text"
                  validators={{required:true, matches:"^\\d{6}$"}}
                  value={this.values.code}
                  onChange={this.onChange}
                  fullWidth />
              }
              {this.reset &&
                <TextFieldValidator
                  margin="dense"
                  name="newPassword"
                  label="New Password"
                  type="password"
                  validators={{required:true, isStrongPassword:3}}
                  value={this.values.newPassword}
                  onChange={this.onChange}
                  autoComplete="new-password"
                  fullWidth />
              }
            </DialogContent>
            <Grid container>
              <Grid container justifyContent="flex-start">
                {this.reset &&
                  <DialogButton variant="tertiary" onClick={this.onResendCode}>
                    Resend verification code
                  </DialogButton>
                }
              </Grid>
              <Grid container justifyContent="flex-end">
                <CancelButton onClick={this.onClose} /> 
                <DialogButton variant="primary">
                  Continue
                </DialogButton>
              </Grid>
            </Grid>
          </FormValidator>
        </Box>
      </Container>
    )
  }

  renderDialog() {
    const { classes } = this.props

    return (
      <Dialog
        id="passwordAssistDialog"
        open={true}
        onClose={this.onClose}
        scroll="paper"
        maxWidth="xs"
        fullWidth
        aria-labelledby="password-assist-dialog-title">
        <FormValidator 
          onSubmit={this.onSubmit} 
          autoComplete="off" 
          name="passwordForm" 
          id="passwordForm"
        >
          <DialogTitle id="password-assist">Password Assistance</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Enter the email address associated with your GovGig account.
            </DialogContentText>
            <TextFieldValidator
              autoFocus
              margin="dense"
              name="email"
              label="Email Address"
              type="email"
              validators={{required:true, isEmail:null}}
              value={this.values.email}
              onChange={this.onChange}
              fullWidth
            />
            <DialogContentText className={classes.message} variant="body2">
              {this.message}
            </DialogContentText>
            {this.reset &&
              <TextFieldValidator
                autoFocus
                margin="dense"
                name="code"
                label="Verification Code"
                type="text"
                validators={{required:true, matches:"^\\d{6}$"}}
                value={this.values.code}
                onChange={this.onChange}
                fullWidth />
            }
            {this.reset &&
              <TextFieldValidator
                margin="dense"
                name="newPassword"
                label="New Password"
                type="password"
                validators={{required:true, isStrongPassword:3}}
                value={this.values.newPassword}
                onChange={this.onChange}
                autoComplete="new-password"
                fullWidth />
            }
          </DialogContent>
          <DialogActions>
            <Grid container>
              <Grid container justifyContent="flex-start">
                {this.reset &&
                  <DialogButton variant="tertiary" onClick={this.onResendCode}>
                    Resend verification code
                  </DialogButton>
                }
              </Grid>
              <Grid container justifyContent="flex-end">
                <CancelButton onClick={this.onClose} /> 
                <DialogButton variant="primary">
                  Continue
                </DialogButton>
              </Grid>
            </Grid>

          </DialogActions>
        </FormValidator>
      </Dialog>
    );
  }

  render() {
    const { embed } = this.props 
    if (embed) {
      return this.renderEmbedded()
    } else {
      return this.renderDialog()
    }
  }
}

export default withStyles(styles)(PasswordAssistDialog)
