// import {AddressType} from "../../API";
import {LngLatLike} from "mapbox-gl";


export class OpportunityLocation {
  id: string
  Label: string
  Municipality: string
  Neighborhood: string
  PostalCode: string
  Region: string
  SubRegion: string
  Interpolated: boolean
  Country: string
  Geometry: any
  latitude: number
  longitude: number
  // addressType: AddressType


  constructor(data: any) {
    this.id = data.id
    this.Label = data.Label
    this.Municipality = data.Municipality
    this.Neighborhood = data.Neighborhood
    this.PostalCode = data.PostalCode
    this.Region = data.Region
    this.SubRegion = data.SubRegion
    this.Interpolated = data.Interpolated
    this.Country = data.Country
    this.Geometry = data.Geometry
    this.latitude = data.latitude
    this.longitude = data.longitude

    // if (!this.addressType) {
    //   if (this.address) {
    //     this.addressType = AddressType.Street
    //   } else if (this.city && this.state) {
    //     this.addressType = AddressType.City
    //   } else if (this.state) {
    //     this.addressType = AddressType.State
    //   } else if (this.country) {
    //     this.addressType = AddressType.Country
    //   }
    // }
  }

  // get shortName() : string {
  //   return this.name
  // }

  // get longName() : string {
  //   if (this.aliases && this.aliases.length > 0) {
  //     return this.aliases[0]
  //   } else {
  //     return this.name
  //   }
  // }

  get lngLat() : LngLatLike {
    return {lng: this.longitude, lat: this.latitude}
  }

  // comparesTo(loc?: OpportunityLocation, precision: number = 5) : boolean {
  //   if(loc !== undefined && loc?.PostalCode && this.PostalCode) {
  //     return loc !== undefined  && this.PostalCode.startsWith(loc.PostalCode.substring(0, precision))
  //   } else {
  //     return false
  //   }
  // }

  comparesTo(loc?: OpportunityLocation, precision: number = 4) : boolean {
    return loc !== undefined && this.id.startsWith(loc.id.substring(0, precision))
  }
}


export default OpportunityLocation