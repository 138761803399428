import * as React from 'react'
import {createStyles, Theme, withStyles, WithStyles} from "@material-ui/core/styles";
import {Routes} from "../../components/ControlTower";
import {Box} from "@material-ui/core";
import Logo from "../../images/header_logo.png";

const styles = (theme: Theme) => createStyles({
  link: {
    textDecoration: 'none',
    color: theme.palette.action.active
  },
})

class TermsOfUseUS extends React.Component<WithStyles<typeof styles>> {

  render() {

    return (
      <div>
        <Box display="flex" mt={2} justifyContent="center">
          <img src={Logo} alt="logo"/>
        </Box>

        <h1>TERMS OF USE</h1>

        <p>Last Updated: March 18, 2022</p>

        <p>These Terms of Use for the GovGig.us site (hereafter “Site”) establish a binding agreement between you and Watermark Solutions LLC, DBA GovGig, a Washington limited liability company (hereafter “Company”). Your use of the GovGig.us site manifests your acceptance of these Terms of Use and Privacy Policy.</p>
        <p>The following terminology applies to these Terms of Use (the “Agreement”), Privacy Policy and where applicable Privacy Notice: "Client", "You" and "Your" refers to you, the person accessing this website and accepting the Company's terms and conditions. "The Company", "Ourselves", "We", "Our" and "Us", refers to Company. "Party", "Parties", or "Us", refers to both the Client and Company, or either the Client or Company. Any use of the above terminology or other words in the singular, plural, capitalization and/or he/she or they, are taken as interchangeable and therefore as referring to same. </p>

        <h3>Consent to receive electronic communications</h3>
        <p>By providing your email to Company, you consent to receiving communications by email. By providing your telephone number to Company, you consent to receiving messages via SMS. You may revoke your consent to electronic communications at any time from your Site settings.</p>

        <h3>Eligibility</h3>
        <p>You must be at least eighteen (18) years of age or the age of majority in the jurisdiction where you reside to use the Site. You may not use the Site if your use of the Site has been previously terminated or suspended by Company.</p>

        <h3>Privacy Notice</h3>
        <p>Please refer to the <a href={Routes.privacy} >GovGig.us privacy policy</a> which also governs your use of the Site.</p>

        <h3>Cookies</h3>
        <p>We employ the use of cookies. By using the Site you consent to the use of cookies in accordance with GovGig.us's privacy policy. Most of the modern-day interactive web sites use cookies to enable us to retrieve user details for each visit. Cookies are used in some areas of our site to enable the functionality of this area and ease of use for those people visiting. Some of our affiliate / advertising partners may also use cookies.</p>

        <h3>License</h3>
        <p>Subject to your compliance with these Terms of Use, applicable Terms of Service and your payment of any fees, Company grants you a limited, non-exclusive, non-transferrable, non-sublicenseable license to access and make personal and non-commercial use of the Site. This license transfers no ownership or intellectual property interest or title in the services or materials used in connection with the Site.</p>
        <p>All content available through the Site such as text, graphics, logos, button icons, images, digital downloads and software is property of Company or licensed to Company and is protected by US and international copyright law. The compilation of all content included in or made available through the service is the exclusive property of Company and protected by US and international copyright law.</p>
        <p>Graphics, logos, scripts, icons and service names on the Site are trademarks or trade dress of Company in the US. Company trademarks and trade dress may not be used in connection with any product or service that is not Company’s in any manner that is likely to cause confusion among customers or in any manner that disparages or discredits Company or GovGig.us. All other trademarks not owned by Company that appear on the Site are the property of their respective owners, who may or may not be affiliated with, connected to or sponsored by Company.</p>
        <p>Your license does not grant any license for resale or commercial use of the GovGig.us service, any use of bots, scraping, data mining or similar data gathering tools.</p>
        <p>All rights not explicitly granted to you are reserved to Company.</p>

        <p>You may view and/or print pages from the Site for your own personal use subject to restrictions set in these terms and conditions. You agree that you will not:</p>
        <ol>
          <li>Republish material from the Site</li>
          <li>Sell, rent or sub-license material from the Site</li>
          <li>Reproduce, duplicate or copy material from the Site</li>
          <li>Redistribute content from the Site (unless content is specifically made for redistribution).</li>
        </ol>

        <h3>Hyperlinking to our Content</h3>
        <ol>
          <li>The following organizations may link to our Web site without prior written approval:</li>
            <ol>
              <li>Government agencies;</li>
              <li>Search engines;</li>
              <li>News organizations;</li>
              <li>Online directory distributors when they list us in the directory may link to our Web site in the same manner as they hyperlink to the Web sites of other listed businesses; and</li>
              <li>Systemwide Accredited Businesses except soliciting non-profit organizations, charity shopping malls, and charity fundraising groups which may not hyperlink to our Web site.</li>
            </ol>
          <li>These organizations may link to our home page, to publications or to other Web site information so long as the link: (a) is not in any way misleading; (b) does not falsely imply sponsorship, endorsement or approval of the linking party and its products or services; and (c) fits within the context of the linking party's site.</li>
          <li>We may consider and approve in our sole discretion other link requests from the following types of organizations:</li>
            <ol>
              <li>commonly known consumer and/or business information sources such as Chambers of Commerce, American Automobile Association, AARP and Consumers Union;</li>
              <li>dot.com community sites;</li>
              <li>associations or other groups representing charities, including charity giving sites,</li>
              <li>online directory distributors;</li>
              <li>internet portals;</li>
              <li>accounting, law and consulting firms whose primary clients are businesses; and</li>
              <li>educational institutions and trade associations.</li>
            </ol>
        </ol>

        <p>We will approve link requests from these organizations if we determine that: (a) the link would not reflect unfavorably on us or our accredited businesses (for example, trade associations or other organizations representing inherently suspect types of business, such as work-at-home opportunities, shall not be allowed to link); (b)the organization does not have an unsatisfactory record with us; (c) the benefit to us from the visibility associated with the hyperlink outweighs the absence of GovGig.us; and (d) where the link is in the context of general resource information or is otherwise consistent with editorial content in a newsletter or similar product furthering the mission of the organization.</p>
        <p>These organizations may link to our home page, to publications or to other Web site information so long as the link: (a) is not in any way misleading; (b) does not falsely imply sponsorship, endorsement or approval of the linking party and its products or services; and (c) fits within the context of the linking party's site.</p>
        <p>If you are among the organizations listed in paragraph 2 above and are interested in linking to our website, you must notify us by sending an e-mail to info@GovGig.us. Please include your name, your organization name, contact information (such as a phone number and/or e-mail address) as well as the URL of your site, a list of any URLs from which you intend to link to our Web site, and a list of the URL(s) on our site to which you would like to link. Allow 2-3 weeks for a response.</p>

        <p>Approved organizations may hyperlink to our Web site as follows:</p>
        <ol>
          <li>By use of our corporate name; or</li>
          <li>By use of the uniform resource locator (Web address) being linked to; or</li>
          <li>By use of any other description of our Web site or material being linked to that makes sense within the context and format of content on the linking party's site.</li>
        </ol>

        <p>No use of GovGig.us's logo or other artwork will be allowed for linking absent a trademark license agreement.</p>
        <p>Company reserves the right at any time and in its sole discretion to request that you remove all links or any particular link to the Site. You agree to immediately remove all links to the Site upon such request.</p>

        <h3>Iframes</h3>
        <p>Without prior approval and express written permission from the Company, you may not create frames around our Web pages or use other techniques that alter in any way the visual presentation or appearance of our Web site.</p>

        <h3>Removal of links from our website</h3>
        <p>If you find any link on our Web site or any linked web site objectionable for any reason, you may contact us about this. We will consider requests to remove links but will have no obligation to do so or to respond directly to you.
          Whilst we endeavor to ensure that the information on this website is correct, we do not warrant its completeness or accuracy; nor do we commit to ensuring that the website remains available or that the material on the website is kept up to date.
        </p>
        <p>Company has no responsibility or liability for any content you post on the Site. You agree to indemnify and defend us against all claims arising out of or based upon content you post on the Site. No link(s) may appear on any content you post or within any context containing content or materials that may be interpreted as libelous, obscene or criminal, or which infringes, otherwise violates, or advocates the infringement or other violation of, any third party rights. </p>

        <h3>No Refunds for Paid Services</h3>
        <p>You may cancel your subscription to paid services at any time. You accept that you will not receive a refund of prepaid fees upon such cancellation. In the event that Company suspends, terminates or limits your access to the Site, you agree that you will receive no refund of prepaid subscription fees.</p>

        <h3>Claims of Copyright Infringement and Related Issues</h3>
        <p>We respect the intellectual property rights of others. Anyone who believes their work has been reproduced in a way that constitutes copyright infringement may notify our agent by providing the following information:</p>
        <ol>
          <li>Identification of the copyrighted work that you claim has been infringed, or, if multiple copyrighted works at a single online site are covered by a single notification, a representative list of such works at the site;</li>
          <li>Identification of the material that you claim is infringing and needs to be removed, including a description of where it is located so that the copyright agent can locate it;</li>
          <li>Your address, telephone number, and, if available, e-mail address, so that the copyright agent may contact you about your complaint;</li>
          <li>A signed statement that the above information is accurate; that you have a good faith belief that the identified use of the material is not authorized by the copyright owner, its agents, or the law; and, under penalty of perjury, that you are the copyright owner or are authorized to act on the copyright owner’s behalf in this situation.</li>
        </ol>

        <p>Upon obtaining such knowledge we will act expeditiously to remove, or disable access to, the material. Please be aware that there are substantial penalties for false claims. If a notice of copyright infringement has been wrongly filed against you, you may submit a counter notification to our agent. A valid counter notification is written communication that incorporates the following elements:</p>
        <ol>
          <li>A physical or electronic signature of the poster;</li>
          <li>Identification of the material that has been removed or to which access has been disabled and the location at which the material appeared before it was removed or access to it was disabled;</li>
          <li>A statement under penalty of perjury that you have a good faith belief that the material was removed or disabled as a result of mistake or misidentification;</li>
          <li>Your name, address, and telephone number; a statement that you consent to the jurisdiction of federal district court for the judicial district in which your address is located, or if your address is located outside of the U.S., for any judicial district in which the service provider (Company) may be found; and that you will accept service of process from the complainant.</li>
        </ol>

        <p>Notices of the foregoing copyright issues should be sent as follows:</p>
        <p>By mail:</p>
        <p>
          Watermark Solutions LLC, DBA GovGig<br/>
          19255 Powder Hill Pl NE, Suite 102<br />
          Poulsbo, WA 98370<br />
          USA
        </p>
        <p>Attn: DMCA Agent</p>
        <p>By email:</p>
        <p><a href="mailto:dmca@govgig.us">dmca@govgig.us</a></p>

        <p>If you give notice of copyright infringement by e-mail, an agent may begin investigating the alleged copyright infringement; however, we must receive your signed statement by mail or as an attachment to your e-mail before we are required to take any action.</p>
        <p>This information should not be construed as legal advice. We recommend you seek independent legal counsel before filing a notification or counter-notification. For further information about the DMCA, please visit the website of the United States Copyright Office at: http://www.copyright.gov.</p>

        <h3>Third Party Links and Services</h3>
        <p>The Site may contain links to third-party websites, advertisers, products, or services that are not owned nor controlled by Company. Company does not endorse or assume any responsibility for such third-party sites, information, materials, products or services. If you access a third-party website from the Site or use a third-party service, you do so at your own risk and you agree that that this Agreement and Privacy Policy do not apply to your use of such third-party websites. You relieve Company from any and all liability from your use of third-party websites, services or content, and agree that Company shall not be responsible for any loss or data relating to your use of such third-party websites, services or content.</p>

        <h3>Site Policies, Modification, Severability</h3>
        <p>We reserve the right to make changes to the GovGig.us site, Privacy Policies and these Terms of Use at any time. If any of these terms or conditions is deemed invalid, void or otherwise unenforceable, that condition shall be severable and shall not affect the validity and enforceability of any other terms or conditions.</p>

        <h3>Indemnity</h3>
        <p>To the maximum extent permitted by law, you agree to defend, indemnify and hold harmless Company and its subsidiaries and their respective directors, officers, board members, employees, agents, successors and assigns, from and against any and all claims, damages, obligations, losses, liabilities, costs and expenses (including but not limited to all legal fees and expenses) arising from your use of the Site and GovGig.us services, your violation of any term of this Agreement or any other agreement you have with Company, your violation of any third-party rights, including without limitation any right of privacy or intellectual property rights, your violation of any applicable law, rule or regulation, any other party’s access and use of the Site using your account or log-in information, or your intentional or willful misconduct, or negligence.</p>

        <h3>Disclaimer of Warranty</h3>
        <p>YOU EXPRESSLY ACKNOWLEDGE AND AGREE THAT USE OF THE GOVGIG.US SITE IS AT YOUR SOLE RISK AND THAT THE ENTIRE RISK AS TO SATISFACTORY QUALITY, PERFORMANCE, ACCURACY AND EFFORT IS WITH YOU. TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, THE GOVGIG.US SITE IS PROVIDED “AS IS,” WITH ALL FAULTS AND WITHOUT WARRANTY OF ANY KIND, AND COMPANY AND COMPANY'S SUBSIDIARIES AND AFFILIATES (COLLECTIVELY REFERRED TO AS “COMPANY” FOR THE PURPOSES OF THIS AGREEMENT) HEREBY DISCLAIM ALL WARRANTIES AND CONDITIONS WITH RESPECT TO THE GOVGIG.US SITE, EITHER EXPRESS, IMPLIED OR STATUTORY, INCLUDING, BUT NOT LIMITED TO, THE IMPLIED WARRANTIES AND/OR CONDITIONS OF MERCHANTABILITY, OF SATISFACTORY QUALITY, OF FITNESS FOR A PARTICULAR PURPOSE, OF ACCURACY, OF QUIET ENJOYMENT, AND NON-INFRINGEMENT OF THIRD PARTY RIGHTS. COMPANY DOES NOT GUARANTEE ANY RESULTS FROM USING THE SITE OR ITS SERVICES. COMPANY DOES NOT WARRANT AGAINST INTERFERENCE WITH YOUR ENJOYMENT OF THE GOVGIG.US SITE, THAT THE FUNCTIONS CONTAINED IN THE GOVGIG.US SITE WILL MEET YOUR REQUIREMENTS, THAT THE OPERATION OF THE GOVGIG.US SITE WILL BE UNINTERRUPTED OR ERROR-FREE, OR THAT DEFECTS IN THE GOVGIG.US SITE WILL BE CORRECTED. NO ORAL OR WRITTEN INFORMATION OR ADVICE GIVEN BY COMPANY OR A COMPANY AUTHORIZED REPRESENTATIVE SHALL CREATE A WARRANTY. SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF IMPLIED WARRANTIES OR LIMITATION ON APPLICABLE STATUTORY RIGHTS OF A CONSUMER, SO THE ABOVE EXCLUSION AND LIMITATIONS MAY NOT APPLY TO YOU.</p>

        <h3>Limitation of Liability</h3>
        <p>TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT SHALL COMPANY, ITS AFFILIATES OR LICENSEES, BE LIABLE FOR ANY SPECIAL, INCIDENTAL, INDIRECT, OR CONSEQUENTIAL DAMAGES WHATSOEVER (INCLUDING, WITHOUT LIMITATION, DAMAGES FOR LOSS OF BUSINESS PROFITS, BUSINESS INTERRUPTION, LOSS OF BUSINESS INFORMATION, OR ANY OTHER PECUNIARY LOSS) ARISING OUT OF THE USE OF OR INABILITY TO USE THE GOVGIG.US SITE OR THE PROVISION OF OR FAILURE TO PROVIDE SUPPORT SERVICES, EVEN IF COMPANY HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. IF ANY SUCH WARRANTY OR GUARANTEE CANNOT BE EXCLUDED BY LAW, THEN TO THE EXTENT PERMITTED BY LAW, COMPANY’S LIABILITY FOR BREACH OF ANY SUCH WARRANTY OR GUARANTEE IS LIMITED, AT COMPANY’S OPTION, TO THE RESUPPLY OF SERVICES OR PAYMENT OF THE COST OF RESUPPLY OF THE SERVICES. BECAUSE SOME STATES AND JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR LIMITATION OF LIABILITY, THE ABOVE LIMITATION MAY NOT APPLY TO YOU.</p>

        <h3>Governing Law and Forum</h3>
        <p>These Terms of Use and Privacy Policy shall be governed by and construed in accordance with the laws of the State of Washington, without respect to its conflict-of-law principles, as applied to such agreements between Washington residents made and to be performed entirely within the State of Washington. Each of the parties hereto (i) irrevocably consents to the exclusive jurisdiction and venue of any court within King County, State of Washington, in connection with any matter based upon or arising out of this Agreement, and (ii) agrees that process may be served upon them in any manner authorized by the laws of the State of Washington for such persons, and (iii) waives any right to, and covenants not to assert or plead, any objection which they might otherwise have to such jurisdiction, venue or process.</p>

        <h3>Assignment</h3>
        <p>This Agreement is personal to you and you may not transfer, assign, lease, sublet or delegate your rights and obligations without the written consent of Company. Company may assign this Agreement or delegate its obligations without restrictions provided that any such successor shall be bound by the terms of this Agreement.</p>

        <h3>Relationship of the Parties</h3>
        <p>Nothing in this agreement shall be construed as making either party the partner, joint venturer, employer, contractor or employee of the other. Neither party shall hold itself out to any third party as having any authority to make statements, representations or commitments of any kind, or take any action, that shall be binding on the other, except as provided in this Agreement or authorized in writing by the partner to be so bound.</p>

        <h3>Entire Agreement</h3>
        <p>This agreement, together with the Privacy Policy, and any amendments shall constitute the entire agreement between you and Company. You acknowledge that you have not relied on any statement, promise or representation made or given by or on behalf of Company that is not set out in this Agreement or document expressly referred to in this Agreement.</p>

        <h3>No Waiver</h3>
        <p>No failure by Company to exercise or delay exercise any rights herein shall operate as a waiver of such rights, nor shall any partial exercise of any right by Company preclude any future exercise of that right or any other right.</p>

        <h3>Severability</h3>
        <p>If you have any queries regarding any of our terms, please contact us at info@govgig.us or via post at:
        </p>
        <p>
          Watermark Solutions LLC, DBA GovGig<br/>
          19255 Powder Hill Pl NE, Suite 102<br />
          Poulsbo, WA 98370<br />
          USA
        </p>
      </div>
    )
  }
}

export default withStyles(styles)(TermsOfUseUS)
