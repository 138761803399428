import React from "react";
import { MobXProviderContext } from 'mobx-react'
import Confirm from "../components/confirm/Confirm";
import UserStore from "./UserStore";
import Notify from "../components/notify/Notify";
import Alert from "../components/alert/Alert";
import Progress from "../components/Progress";
import PrivacyPolicy from "../components/page/PrivacyPolicy";
import TermsOfUse from "../components/page/TermsOfUse";
import AccountStore from "./AccountStore";
import JobStore from "./JobStore";
import OpportunityStore from "./OpportunityStore";
import ResourceCache from "./ResourceCache";
import ProfileStore from "./ProfileStore";
// import DocumentStore from "./DocumentStore"

/*
  As a workaround for using MobX with functional components, we can create a hook for those 
  components to access what the MobX Provider passes down. 
  In the future, we'll probably want to use the React Context API directly, 
  instead of MobX's wrapper. 
  https://mobx-react.js.org/recipes-migration 
*/ 
interface IResources {
  confirm: Confirm
  notify: Notify
  alert: Alert
  progress: Progress
  privacyPolicy: PrivacyPolicy
  termsOfUse: TermsOfUse
  accountStore: AccountStore
  jobStore: JobStore
  OpportunityStore: OpportunityStore
  userStore: UserStore
  profileStore: ProfileStore
  resourceCache: ResourceCache
  // documentStore: DocumentStore
}

export function useStores(): IResources {
  return React.useContext(MobXProviderContext) as IResources
}