import React from "react";
import {
  createStyles, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, 
  Theme, Typography,
  withStyles,
  WithStyles,
  withTheme,
  WithTheme, withWidth,
  WithWidth
} from "@material-ui/core";
import {makeObservable, observable} from "mobx";
import { inject, observer } from "mobx-react";
import JobPost from "../../model/JobPost";
import FormValidator from "../form/FormValidator";
import CardValue from "../CardValue";
import ProfileStore from "../../stores/ProfileStore";
import {durationBetweenISODates, humanizeString} from "../../stores/StoreUtilities";
import ProgressButton from "../form/ProgressButton";
import AccountStore from "../../stores/AccountStore";
import CancelButton from "../form/CancelButton";
import JobStore from "../../stores/JobStore";
import Profile from "../../model/Profile";
import Stack from "../Stack";

const styles = (theme: Theme) => createStyles({
  form: {
    overflowY: "auto",
    display: "flex",
    flexDirection: "column"
  },
  progressButton: {
    minWidth: 80
  },
  error: {
    color: theme.palette.error.main
  },
  label: {
    color: theme.palette.text.secondary,
    fontSize: 15,
    marginTop: theme.spacing(1),
    marginBottom: 4,
    marginLeft: 0,
  },
  textarea: {
    fontFamily: [
      'Roboto',
      'sans-serif'
    ].join(','),
    minWidth: "100%",
    maxWidth: "100%",
    padding: 8,
    fontSize: 16
  },
})

export interface IJobCandidateInviteDialogProps {
  isOpen: boolean 
  // jobCandidate: JobCandidate
  jobPost: JobPost
  profile: Profile
  // onSubmit?(jobPost: JobPost, jobCandidate: JobCandidate): void
  onSubmit(): Promise<void>
  onClose?(): void

  profileStore?: ProfileStore
  accountStore?: AccountStore
  jobStore?: JobStore
}

@inject("profileStore", "accountStore", "jobStore")
@observer
class JobCandidateInviteDialog extends React.Component<WithStyles<typeof styles> & WithTheme & WithWidth & IJobCandidateInviteDialogProps> {

  @observable message = ""
  @observable error = ""
  @observable isProcessing = false

  constructor(
    props: WithStyles<typeof styles> & WithTheme & WithWidth & IJobCandidateInviteDialogProps
  ) {
    super(props);
    makeObservable(this);
  }

  render() {
    const { 
      classes, 
      // jobCandidate, 
      jobPost,
      profile, 
      isOpen 
    } = this.props

    return (
      <Dialog
        open={isOpen}
        onClose={this.onClose}
        fullWidth
        maxWidth="sm"
        scroll="paper"
      >
        <FormValidator onSubmit={this.onSubmit} autoComplete="off" name="inviteForm" id="inviteForm" className={classes.form}>
          <DialogTitle id="form-dialog-title">Invite Job Candidate to Apply</DialogTitle>
          <DialogContent dividers>
            <DialogContentText className={classes.error}>
              {this.error}
            </DialogContentText>
            <Grid container direction="column">
              <Grid item>
                <CardValue label="Candidate">
                  {profile.initials}
                </CardValue>
              </Grid>
              <Grid item>
                <CardValue label="Job Title">
                  {jobPost.title}
                </CardValue>
              </Grid>
              <Grid item>
                <CardValue label="Location">
                  {jobPost.location!.name}
                </CardValue>
              </Grid>
              <Grid item>
                <CardValue label="Date(s)">
                  {durationBetweenISODates(jobPost.startDate, jobPost.endDate)}
                </CardValue>
              </Grid>
              <Grid item>
                <CardValue label="Employment Type">
                  {humanizeString(jobPost.employmentType)}
                </CardValue>
              </Grid>
              <Grid item>
                <div className={classes.label}>Job Summary</div>
                <Typography variant="body1" style={{overflow: 'hidden', textOverflow: 'ellipses'}}>
                  {jobPost.summary}
                </Typography>
              </Grid>
              {/*<Grid item>*/}
              {/*  <div className={classes.label}>Message</div>*/}
              {/*  <TextareaAutosize*/}
              {/*      name="message"*/}
              {/*      value={this.message}*/}
              {/*      onChange={this.onChangeMessage}*/}
              {/*      minRows={5}*/}
              {/*      className={classes.textarea}*/}
              {/*    />*/}
              {/*</Grid>*/}
            </Grid>
          </DialogContent>
          <DialogActions>
            <Stack justifyContent="flex-end">
              <CancelButton onClick={this.onClose} />
              <ProgressButton variant="contained" size="small" color="primary"
                              type="submit" className={classes.progressButton} processing={this.isProcessing}>
                Invite
              </ProgressButton>
            </Stack>
          </DialogActions>
        </FormValidator>
      </Dialog>
    )
  }

  onChangeMessage = (event: any) => {
    this.message = event.target.value
  }

  onSubmit = async () => {
    const { 
      onSubmit, 
      // jobPost, 
      // jobCandidate, 
      // jobStore 
    } = this.props

    this.isProcessing = true
    this.error = ""

    await onSubmit()

    // const input: UpdateJobCandidateInput = {
    //   id: jobCandidate.id,
    //   status: JobCandidateStatus.Invited,
    //   // message: this.message
    // }

    // const updated = await jobStore!.updateJobCandidate(input)
    //   .catch((err: Error) => {
    //     this.error = "Error inviting job candidate"
    //   })

    this.isProcessing = false

    // if (updated) {
    //   if (onSubmit) {
    //     onSubmit(jobPost, updated)
    //   }
    // }
  }

  onClose = () => {
    const { onClose } = this.props
    if (onClose) {
      onClose()
    }
  }

}

export default withTheme(withStyles(styles)(withWidth()(JobCandidateInviteDialog)))
