import {Box, IconButton, Typography} from "@material-ui/core";
import * as React from "react";
import {useEffect, useState} from "react";
import DeleteIcon from '@material-ui/icons/DeleteForever';
// import DeleteIcon from '@material-ui/icons/DeleteForeverOutlined';
// import DeleteIcon from '@material-ui/icons/DeleteOutlineOutlined';

interface IQuestionCardProps {
  name: string
  children: any
  onDelete?: any
}
const QuestionCard = (props: IQuestionCardProps) => {
  const { onDelete } = props
  const [name, setName] = useState("")

  useEffect(() => {
    setName(props.name)
  }, [props.name])
  
  return (
    <Box
      bgcolor="common.white"
      borderRadius={10}
      boxShadow='rgba(0,0,0,0.25) 0px 1px 5px 0px'
      px={2} py={1} mb={2}
      display="flex"
      flexDirection="row"
    >
      <Box display="flex" flexDirection="column" flexGrow={1}>
        <Typography variant="h5" style={{paddingTop:2, textTransform:'none'}}>
          {onDelete &&
            <IconButton
              aria-label="Delete"
              color="primary"
              style={{float:"right", display:"inline-block", padding:"0 0 8px 0"}}
              onClick={(event: any) => onDelete(name)}
            ><DeleteIcon/></IconButton>
          }
          {name}
        </Typography>
        {props.children}
      </Box>
    </Box>
  )
}

export default QuestionCard