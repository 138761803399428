import * as React from 'react'
import {
  FormControl,
  InputLabel,
  Select,
  Theme,
  createStyles,
  WithStyles,
  withStyles,
  FormHelperText
} from '@material-ui/core';
import { inject } from "mobx-react";
import { IFieldValidator } from '../form/FormValidator';

const styles = (theme: Theme) => createStyles({
  formControl: {
    display: "flex"
  }
})

interface ISimpleSelectProps {
  placeholderText: string
  valueName: string // The name of the value.
  value?: string // The selected value. 
  elementId: string // The CSS id for the input. 
  selections: string[] // The possible selection values. 
  formvalidator?: any
  onChange(event: any): void // The handler called when the value changes. 
  className?: string
  disabled?: boolean
  fullWidth?: boolean
}

@inject("formvalidator")
class SimpleSelect extends React.Component<WithStyles<typeof styles> & ISimpleSelectProps & IFieldValidator> {

  state = {
    value: '',
    isValid: true,
  };

  componentDidMount() {
    const { formvalidator, value } = this.props as ISimpleSelectProps
    if (formvalidator) {
      formvalidator.attachToForm(this)
    }

    this.setState({ value })
  }

  handleChange = (event: any) => {
    this.setState({ value: event.target.value });
    this.validate(event.target.value)
    this.props.onChange(event)
  };

  validate = (value: string): boolean => {
    const { selections } = this.props 
    const isValid = (selections.indexOf(value) >= 0)
    this.setState({ isValid })
    return isValid
  }

  render() {
    const { className, classes, elementId, placeholderText, selections, valueName, disabled, fullWidth } = this.props

    return (
      <FormControl
        className={className ? className : classes.formControl}>
        <InputLabel htmlFor={`${elementId}`}>{placeholderText}</InputLabel>
        <Select
          fullWidth={fullWidth === undefined ? true : fullWidth}
          native
          value={this.state.value}
          onChange={this.handleChange}
          disabled={disabled}
          inputProps={{
            name: `${valueName}`,
            id: `${elementId}`,
          }}
        >
          <option value="" />
          {selections.map((selection) => <option key={selection} value={selection}>{selection}</option>)}
        </Select>
        <FormHelperText error={!this.state.isValid}>
          {this.state.isValid ? "" : "Required"}
        </FormHelperText>
      </FormControl>
    ) 
  }
}

export default withStyles(styles)(SimpleSelect)
