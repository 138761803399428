import * as React from "react";
import {
  Box,
  createStyles,
  alpha,
  Grid,
  Theme,
  withStyles,
  WithStyles,
  withWidth,
  WithWidth
} from "@material-ui/core";
import IconicButton from "../../components/controls/IconicButton";
import EditIcon from "@material-ui/icons/Edit";
import JobPost from "../../model/JobPost";
import {inject, observer} from "mobx-react";
import UserStore from "../../stores/UserStore";
import {makeObservable, observable, when} from "mobx";
import {JobInterestStatus} from "../../API";
import StarBorderIcon from '@material-ui/icons/StarBorder';
import StarIcon from '@material-ui/icons/Star';
import PublishIcon from '@material-ui/icons/Publish';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import LinkIcon from '@material-ui/icons/Link';
import DialogButton from "../form/DialogButton";
import JobInterest from "../../model/JobInterest";
import {CopyToClipboard} from "react-copy-to-clipboard";
import Notify from "../notify/Notify";
import config from "react-global-configuration";
import slugify from "slugify";

const styles = (theme: Theme) => createStyles({
  container: {
    // px={2} py={1} bgcolor={"rgba(21, 36, 56, 0.5)"} boxShadow='rgba(0, 0, 0, 0.25) 0px 1px 3px 0px' borderRadius={10}
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    backgroundColor: 'rgba(21, 36, 56, 0.5)',
    boxShadow: 'rgba(0, 0, 0, 0.25) 0px 1px 3px 0px',
    borderRadius: 10
  },
  buttonGroupLabel: {
    color: theme.palette.common.white,
    // fontWeight: 'bold'
  },
  header: {
    fontWeight: 200,
    color: theme.palette.text.secondary,
    maxHeight: 26
  },
  buttons: {
    textAlign: "right",
    maxHeight: 26
  },
  heading: {
    color: theme.palette.primary.main,
    fontWeight: 'bold'
  },
  iconButton: {
    backgroundColor: theme.palette.secondary.main,
    color: '#fff',
    // marginLeft: theme.spacing(1),
    boxShadow: 'rgba(0, 0, 0, 0.25) 0px 1px 3px 0px',
  },
  applyButton: {
    backgroundColor: theme.palette.secondary.main,
    color: '#fff',
    borderWidth: 0,
    marginTop: 0,
    // marginLeft: theme.spacing(1),
    boxShadow: 'rgba(0, 0, 0, 0.25) 0px 1px 3px 0px',
    "&:hover": {
      borderWidth: 0,
      backgroundColor: alpha(theme.palette.secondary.light, 0.8),
    },
    "&:disabled": {
      backgroundColor: alpha(theme.palette.secondary.main, 0.8),
      color: alpha(theme.palette.secondary.contrastText, 0.8),
    },
  },

})

interface IJobPostButtonsProps {
  jobPost: JobPost
  jobInterest?: JobInterest
  onEdit?(jobPost: JobPost): void
  onApply?(jobPost: JobPost): void
  onSave?(jobPost: JobPost): void
  className?: string
  userStore?: UserStore
  notify?: Notify
}

@inject('userStore', 'notify')
@observer
class JobPostButtons extends React.Component<WithStyles<typeof styles> & IJobPostButtonsProps & WithWidth> {

  @observable isJobPostEditable: boolean = false 

  constructor(props: any) {
    super(props)
    makeObservable(this)
  }

  componentDidMount() {
    const { userStore } = this.props 
    when(
      // once 
      () => userStore!.isLoading === false || userStore!.isPublic === true,
      // then 
      () => {
        this.loadJobPostEditable()
      }
    )
  }

  render() {
    const { onApply, onSave, onEdit, jobPost, jobInterest, classes, className, userStore, notify } = this.props

    const candidateButtons = []
    const shareButtons = []
    const isCandidate = userStore!.isCandidate
    const isApplicant = userStore!.isApplicant
    const isEmployer = userStore!.isEmployer
    const isAdminOrAgent = userStore!.isAdminOrAgent

    if (onApply) {
      if (!jobInterest || jobInterest.status === JobInterestStatus.Interested) {
        candidateButtons.push(
          <DialogButton key="apply" variant='secondary' onClick={() => onApply!(jobPost)} customClassName={classes.applyButton}>
            <PublishIcon />&nbsp;&nbsp;Apply
          </DialogButton>
        )
      } else if (jobInterest && jobInterest.status === JobInterestStatus.Invited) {
        candidateButtons.push(
          <DialogButton key="invited" variant='secondary' onClick={() => onApply!(jobPost)} customClassName={classes.applyButton}>
            <MailOutlineIcon />&nbsp;&nbsp;Invited to Apply
          </DialogButton>
        )
      } else if (jobInterest && jobInterest.status === JobInterestStatus.Applied) {
        candidateButtons.push(
          <DialogButton key="applied" variant='secondary' onClick={() => onApply!(jobPost)} disabled>
            <PublishIcon />&nbsp;&nbsp;Applied
          </DialogButton>
        )
      }
    }

    if (onSave && (isCandidate || isApplicant)) {
      if (jobInterest !== undefined) {
        candidateButtons.push(
          <IconicButton 
            key="unfavorite" 
            onClick={() => onSave!(jobPost)} 
            className={classes.iconButton} 
            // disabled={jobInterest.status !== JobInterestStatus.Interested}
          >
            <StarIcon />
          </IconicButton>
        )
      } else {
        candidateButtons.push(
          <IconicButton key="favorite" onClick={() => onSave!(jobPost)} className={classes.iconButton}>
            <StarBorderIcon />
          </IconicButton>
        )
      }
    }

    const publicJobLink = `${config.get('homeUrl')}/jobs/${slugify(jobPost.title, {remove: /[*+~.()'"!:@]/g})}/${jobPost.id}`

    // Link button
    shareButtons.push(
      <CopyToClipboard
        key="copy"
        text={publicJobLink}
        onCopy={() => notify!.show('success', 'Copied job link to clipboard!')}
      >
        <IconicButton className={classes.iconButton}>
          <LinkIcon />
        </IconicButton>
      </CopyToClipboard>
    )

    const subject = `GovGig ${jobPost.title} Job Post`
    const body = publicJobLink

    shareButtons.push(
      <a href={`mailto:staffing@govgig.us?subject=${subject}&body=${body}`}>
        <IconicButton className={classes.iconButton}>
          <MailOutlineIcon />
        </IconicButton>
      </a>
    )

    return (
      <Box className={className ?? classes.container}>
        <Grid container alignItems="center" spacing={1}>
          { (onSave || onApply) ?
            <Grid item>
              <Box borderRight='1px solid rgba(255, 255, 255, 0.25)' pr={2} mr={1}>
                <Grid container direction="column" justifyContent="center" alignItems="center" spacing={1}>
                  <Grid item>
                    <Grid container alignItems="center" spacing={1}>
                      {candidateButtons.map((candidateButton, index) => {
                        return (
                          <Grid item key={`candidateButton-${index}`}>
                            {candidateButton}
                          </Grid>
                        )
                      })}
                    </Grid>
                  </Grid>
                  {/* <Grid item>
                    <Typography variant="body2" className={classes.buttonGroupLabel}>
                      Engage 
                    </Typography>
                  </Grid> */}
                </Grid>
              </Box>
            </Grid>
          : 
            null
          } 
          { onEdit && (isEmployer || isAdminOrAgent) && 
            <Grid item>
              <Box borderRight='1px solid rgba(255, 255, 255, 0.25)' pr={2} mr={1}>
                <Grid container direction="column" justifyContent="center" alignItems="center" spacing={1}>
                  <Grid item>
                    <IconicButton key="edit" onClick={() => onEdit!(jobPost)}>
                      <EditIcon />
                    </IconicButton>
                  </Grid>
                  {/* <Grid item>
                    <Typography variant="body2" className={classes.buttonGroupLabel}>
                      Edit
                    </Typography>
                  </Grid> */}
                </Grid>
              </Box>
            </Grid>
          }
          <Grid item>
            <Grid container direction="column" justifyContent="center" alignItems="center" spacing={1}>
              <Grid item>
                <Grid container spacing={1}>
                  {shareButtons.map((shareButton, index) => {
                    return (
                      <Grid item key={`shareButton-${index}`}>
                        {shareButton}
                      </Grid>
                    )
                  })}
                </Grid>
              </Grid>
              {/* <Grid item>
                <Typography variant="body2" className={classes.buttonGroupLabel}>
                  Share 
                </Typography>
              </Grid> */}
            </Grid>
          </Grid>
        </Grid>
      </Box>
    )
  }

  loadJobPostEditable = () => {
    const { jobPost, userStore } = this.props
    this.isJobPostEditable = userStore!.isJobPostEditable(jobPost)
  }
}

export default withStyles(styles)(withWidth()(JobPostButtons))
