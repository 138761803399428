import * as React from 'react'
import {createStyles, Theme, withStyles, WithStyles, withTheme, WithTheme} from "@material-ui/core/styles";
import {RouteComponentProps} from "@reach/router";
import Page from "../../components/page/Page";
import MarginRow from "../../components/page/MarginRow";
import PrivacyPolicyUS from "./PrivacyPolicyUS";
import PrivacyPolicyCCPA from "./PrivacyPolicyCCPA";

const styles = (theme: Theme) => createStyles({

})

interface IPrivacyPolicyPageProps {
  option?: string
}

class PrivacyPolicyPage extends React.Component<WithStyles<typeof styles> & RouteComponentProps & WithTheme & IPrivacyPolicyPageProps> {

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll)
  }
  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll)
  }

  handleScroll = (event: any) => {
    const bottom = Math.ceil(window.innerHeight + window.scrollY) >= document.documentElement.scrollHeight

    if (bottom) {
      if (window.location !== window.parent.location) {
        // In iframe
        window.parent.postMessage("at-bottom", '*');
      }
    }
  }

  render() {
    const { option } = this.props

    return (
      <Page title="Privacy Policy" hideHeader={true} hideFooter={true}>
        <MarginRow>
          {option === undefined &&
            <PrivacyPolicyUS/>
          }
          {option === "ccpa" &&
            <PrivacyPolicyCCPA/>
          }
        </MarginRow>
      </Page>
    )
  }
}

export default withTheme((withStyles(styles)(PrivacyPolicyPage)))

