import * as React from "react";
import {
  Box,
  Button,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent, DialogTitle,
  Grid, TextareaAutosize,
  Theme,
  withStyles,
  WithStyles,
  withWidth,
  WithWidth
} from "@material-ui/core";
import {inject, observer} from "mobx-react";
import {makeObservable, observable} from "mobx";
import User from "../../model/User";
import UserStore from "../../stores/UserStore";
// import IconicButton from "../controls/IconicButton";
import NoteIcon from '@material-ui/icons/NoteAddOutlined';
import FormValidator from "../form/FormValidator";
import CancelButton from "../form/CancelButton";
import ProgressButton from "../form/ProgressButton";
import {CreateUserActivityInput, SubjectType} from "../../API";
import UserActivity, {ActivityType} from "../../model/UserActivity";
// import AddButton from "../AddButton";

const styles = (theme: Theme) => createStyles({
  callToAction: {
    color: theme.palette.primary.contrastText
  },
  textarea: {
    fontFamily: [
      'Roboto',
      'sans-serif'
    ].join(','),
    minWidth: "100%",
    maxWidth: "100%",
    marginTop: theme.spacing(1),
    padding: theme.spacing(1),
    fontSize: 16
  },
  progressButton: {
    minWidth: 80
  }
})

interface INoteActivityPanelProps {
  subject: SubjectType
  subjectId: string
  user: User
  onAddNote?(note: UserActivity): void

  userStore?: UserStore
}

@inject("userStore")
@observer
class NoteActivityPanel extends React.Component<WithStyles<typeof styles> & INoteActivityPanelProps & WithWidth> {

  @observable isDialogOpen: boolean = false
  @observable isProcessing = false
  @observable note = ""

  constructor(props: any) {
    super(props)
    makeObservable(this)
  }

  render() {
    const { classes } = this.props
    return (
      <Box
        // color='#fff'
        // borderRadius={10}
        // bgcolor="primary.500"
        // boxShadow='rgba(0, 0, 0, 0.15) 0px 5px 15px 0px'
        // px={1}
        // py={1}
        // width={76}
      >
        <Grid container justifyContent="center" spacing={2} className={classes.callToAction}>
          <Grid item>
            <Button
              variant="contained"
              color="secondary"
              startIcon={<NoteIcon />}
              onClick={this.handleClick}
            >
              Add Note
            </Button>
            {/* <IconicButton
              label={"Add Note"}
              labelColor='inherit'
              color="primary"
              onClick={this.handleClick}
            >
              <NoteIcon />
            </IconicButton> */}
          </Grid>
        </Grid>
        {this.renderNoteDialog()}
      </Box>
    )
  }

  handleClick = () => {
    this.isDialogOpen = true
  }

  renderNoteDialog = () => {
    const { classes } = this.props

    return (
      <Dialog
        open={this.isDialogOpen}
        maxWidth="sm"
        fullWidth
        onClose={() => {this.isDialogOpen = false }}
      >
        <FormValidator onSubmit={this.handleSubmit} autoComplete="off" name="editForm" id="editForm">
          <DialogTitle id="form-dialog-title">Add Note</DialogTitle>
          <DialogContent>
            <TextareaAutosize
              name="note"
              value={this.note}
              onChange={this.handleChange}
              minRows={10}
              className={classes.textarea}
            />

          </DialogContent>
          <DialogActions>
            <CancelButton onClick={this.handleClose} />
            <ProgressButton variant="contained" size="small" color="primary"
                            type="submit" className={classes.progressButton} processing={this.isProcessing}>
              Save
            </ProgressButton>
          </DialogActions>
        </FormValidator>
      </Dialog>
    )
  }

  handleChange = (event: any) => {
    const name = event.target.name
    if (name === "note") {
      this.note = event.target.value
    }
  }

  handleClose = () => {
    this.isDialogOpen = false
  }

  handleSubmit = async () => {
    const { userStore, user, subject, subjectId, onAddNote } = this.props

    if (this.note.length > 0) {
      const createInput: CreateUserActivityInput = {
        userId: user.id,
        subjectType: subject,
        subjectId: subjectId,
        activityType: ActivityType.NoteAdded,
        actorId: userStore!.user!.id,
        actorName: userStore!.user!.fullName,
        accountId: userStore!.user!.accountId,
        details: this.note
      }

      const activity = await userStore!.createUserActivity(createInput)

      if (activity && onAddNote) {
        onAddNote(activity)
      }

      this.isDialogOpen = false
    }

    // this.isDialogOpen = false
  }
}

export default withStyles(styles)(withWidth()(NoteActivityPanel))
