import {Box, Button, createStyles, Grid, isWidthDown, Tab, Tabs, Theme, Typography, withStyles, WithStyles, WithWidth, withWidth} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import {NavigateFn, RouteComponentProps, WindowLocation} from '@reach/router';
import {makeObservable, observable, when} from 'mobx';
import {inject, observer} from 'mobx-react';
import * as React from 'react';
import CardValue from '../../components/CardValue';
import IconicButton from '../../components/controls/IconicButton';
import UserStore from '../../stores/UserStore';
import ProfileCard from '../profile/ProfileCard';
import {phoneToNationalFormat} from "../../stores/StoreUtilities";
import Agreement from "../../model/Agreement";
import Account from "../../model/Account";
import AccountStore from "../../stores/AccountStore";
import {AgreementType, ModelAgreementFilterInput} from "../../API";
import ControlTower, {Routes} from "../../components/ControlTower";
import ActionLink from "../../components/ActionLink";
import AccountSettingsDialog from '../settings/account-settings/AccountSettingsDialog';
import Page from '../../components/page/Page';
import MarginRow from '../../components/page/MarginRow';
import PageHeadingPanel from '../../components/panel/PageHeadingPanel';
import PageTabsPanel from '../../components/panel/PageTabsPanel';
import PageTabPanel from '../../components/panel/PageTabPanel';
import Notify from '../../components/notify/Notify';
import Logger from '../../components/Logger';
import PageTitle from '../../components/page/PageTitle';
import Stack from '../../components/Stack';
import { ListAlt, People, Person } from '@material-ui/icons';
import AgreementsList from '../../views/agreements/AgreementsList';
import ContractsList from '../contracts/ContractsList';
import PeopleList from '../manage/PeopleList';
import AccountBilling from '../settings/account-billing/AccountBilling';
import Industry, { NAICS } from '../../model/Industry';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';

const styles = (theme: Theme) => createStyles({
  hideOverflow: {
    overflowX: "hidden"
  },
  tab: {
    fontSize: 15,
    fontWeight: 600,
    textTransform: 'capitalize'
  },
})

interface IAccountPageProps {
  accountId?: string 
  embed?: boolean 
  location?: any 
  onCreateAccount?(account: Account): Promise<void>
  onUpdateAccount?(account: Account): Promise<void> 

  accountStore?: AccountStore
  notify?: Notify
  userStore?: UserStore
  route?: string
  tab?: string
}

enum TabNames {
  Settings = "Settings",
  Agreements = "Agreements",
  Billing = "Billing",
  People = "People"
}

@inject('userStore', 'accountStore', 'notify')
@observer
class AccountPage extends React.Component<WithStyles<typeof styles> & WithWidth & RouteComponentProps & IAccountPageProps> {
  
  @observable isLoading = true
  @observable accountSettingsOpen = false
  @observable account?: Account
  @observable agreements: Agreement[] = []
  @observable selectedTabIndex: number = 0

  tabNames: string[] = [TabNames.Settings, TabNames.Agreements, TabNames.People, TabNames.Billing]

  constructor(
    props: WithStyles<typeof styles> & WithWidth & RouteComponentProps & IAccountPageProps
  ) {
    super(props)
    makeObservable(this)
  }

  componentDidMount() {
    const {userStore, accountStore} = this.props

    when(
      // once
      () => accountStore!.isLoading === false 
        && userStore!.isLoading === false 
        && userStore!.user !== undefined,
      // then
      async () => {
        // this.account = userStore!.user!.account
        await this.loadAccount()
        if (this.account) {
          await this.loadAgreements(this.account.id)
        }
        this.isLoading = false 
      }
    )
  }

  componentDidUpdate(prevProps: any, prevState: any): void {
    const { tab } = this.props 
    const { prevTab } = prevProps

    if (tab !== prevTab) {
      if (tab) {
        const tabName = tab.charAt(0).toUpperCase() + tab.slice(1)
        const foundIndex = this.tabNames.findIndex(checkTabName => checkTabName === tabName)
        if (foundIndex !== -1) {
          this.selectedTabIndex = foundIndex
        }
      }
    }
  }

  async loadAgreements(accountId: string) {
    const { accountStore } = this.props

    const filter: ModelAgreementFilterInput = {
      or: [
        {agreementTypes: {contains: AgreementType.MasterServicesAndNonSolicitation}},
        {agreementTypes: {contains: AgreementType.ConfidentialityAndNonDisclosure}},
        {agreementTypes: {contains: AgreementType.StaffingAgreement}},
        {agreementTypes: {contains: AgreementType.ProfessionalServicesAgreement}},
        {agreementTypes: {contains: AgreementType.ExclusiveRecruitment}},
      ]

    }
    this.agreements = await accountStore!.listAgreementsByAccount(accountId, filter)
  }

  handleClose = () => {
    this.accountSettingsOpen = false
  }

  renderStaticFields() {
    const { classes, width } = this.props

    const labelWidth = 170
    const account = this.account

    if (!account) {
      return null
    }

    return (
      <div>
        <Grid
          container
          spacing={isWidthDown('xs', width) ? 0 : 5}
        >
          <Grid item xs={12} sm={6}>
            <Stack direction="column" justifyContent="flex-start" spacing={1}>
              <CardValue label="Company" labelWidth={labelWidth}>
                {account.name}
              </CardValue>
              <CardValue label="Address" labelWidth={labelWidth}>
                {account.address}
              </CardValue>
              <CardValue label="City, State, ZIP" labelWidth={labelWidth}>
                {account.city}, {account.state} {account.postalCode}
              </CardValue>
              <CardValue label="Phone" labelWidth={labelWidth}>
                {phoneToNationalFormat(account.phone)}
              </CardValue>
              {account.webUrl && 
                <CardValue label="Web Address" labelWidth={labelWidth}>
                  <a href={account.webUrl} target="_blank" rel="noreferrer" >{account.webUrl}</a>
                </CardValue>
              }
              <CardValue label="Industries" wrapText labelWidth={labelWidth}>
                {account.industries.map((industry) => {
                  return Industry.stringForNAICSCode(industry as NAICS)
                }).join(", ")}
              </CardValue>
            </Stack>
          </Grid>
          <Grid item xs={12} sm={6} className={classes.hideOverflow}>
            {/*<Grid container direction="column">*/}
            {/*  <CardValue label="DUNS Number" labelWidth={labelWidth}>*/}
            {/*    {account.dunsNumber}*/}
            {/*  </CardValue>*/}
            {/*  <CardValue label="FEIN" labelWidth={labelWidth}>*/}
            {/*    {account.fein}*/}
            {/*  </CardValue>*/}
            {/*  <CardValue label="NAICS Code" labelWidth={labelWidth}>*/}
            {/*    {account.naicsCode}*/}
            {/*  </CardValue>*/}
            {/*  <CardValue label="SIC Code" labelWidth={labelWidth}>*/}
            {/*    {account.sicCode}*/}
            {/*  </CardValue>*/}
            {/*  <CardValue label="CAGE Code" labelWidth={labelWidth}>*/}
            {/*    {account.cageCode}*/}
            {/*  </CardValue>*/}
            {/*  <CardValue label="Socio-Economic Status" labelWidth={labelWidth}>*/}
            {/*    {account.socioEconomicDesignation}*/}
            {/*  </CardValue>*/}
            {/*</Grid>*/}
          </Grid>
        </Grid>
      </div>
    )
  }

  renderSettings = () => {
    const { onCreateAccount, onUpdateAccount } = this.props 
    return (
      <Grid
        container
        justifyContent="flex-start"
        alignItems="flex-start"
        spacing={3}
      >
        <Grid item xs={12}>
          <ProfileCard title="Account Settings" button={
            <IconicButton onClick={() => this.accountSettingsOpen = true}>
              <EditIcon />
            </IconicButton>
          }>
            {this.renderStaticFields()}
          </ProfileCard>
        </Grid>

        {this.account && 
          <AccountSettingsDialog
            account={this.account}
            open={this.accountSettingsOpen}
            onClose={this.handleClose}
            onUpdateAccount={async (updatedAccount: Account) => {
              this.account = updatedAccount
              onUpdateAccount && await onUpdateAccount(updatedAccount)
            }}
            onCreateAccount={async (createdAccount: Account) => {
              console.error("Created an account when should update")
              throw new Error("Tried to create new account when updating existing account")
              // this.account = createdAccount 
              // await onCreateAccount(createdAccount)
            }}
          />
        }
      </Grid>
    )
  }

  tabIndexMatch = (tabName: TabNames) => {
    return this.selectedTabIndex === this.tabNames.findIndex(checkTabName => checkTabName === tabName)
  }

  renderContent() {
    const { accountId, classes, embed, userStore } = this.props

    return (
      <MarginRow>
        <PageHeadingPanel>
          <Stack
            direction="row"
            justifyContent="space-between"
            spacing={1}
          >
            <PageTitle 
              title={this.account!.name}  
              variant="h2"        
            />
            <Stack
              spacing={1}
            >
              {/* <Button
                variant="contained"
                color="secondary"
                startIcon={<People />}
              >
                People 
              </Button> */}
              <Button
                variant="contained"
                color="secondary"
                startIcon={<ListAlt />}
                onClick={() => {
                  ControlTower.route(`${Routes.manageJobs}?accountId=${this.account!.id}`)
                }}
              >
                Manage Jobs
              </Button>
            </Stack>
          </Stack>
        </PageHeadingPanel>
        <PageTabsPanel>
          <Tabs
            value={this.selectedTabIndex}
            onChange={(event: any, value: any) => {
              if (embed === undefined || embed === false) {
                const tabName = this.tabNames[value]
                // Navigate to tab to allow a direct url to each tab
                let searchParams = new URLSearchParams()
                searchParams.append('accountId', this.account!.id)
                const route = userStore!.isAdminOrAgent ? `${Routes.account}/${tabName.toLowerCase()}?${searchParams.toString()}` : `${Routes.account}/${tabName.toLowerCase()}`
                ControlTower.route(route)
              } else {
                this.selectedTabIndex = value 
              }
            }}
            indicatorColor="secondary"
            textColor="secondary"
            variant="scrollable"
            scrollButtons="auto"
          >
            {this.tabNames.map((tabName) => {
              return <Tab key={tabName} className={classes.tab} label={tabName} />
            })}
          </Tabs>
        </PageTabsPanel>
        <PageTabPanel>
          { this.tabIndexMatch(TabNames.Settings) && this.renderSettings() }
          { this.tabIndexMatch(TabNames.Agreements) && <AgreementsList accountId={this.account!.id} /> }
          { this.tabIndexMatch(TabNames.People) && <PeopleList accountId={this.account?.id} /> }
          { this.tabIndexMatch(TabNames.Billing) && <AccountBilling /> }
        </PageTabPanel>
      </MarginRow>
    )
  }

  render() {
    const { embed } = this.props 
    const title = "Account Detail"

    if (embed) {
      return (
        <React.Fragment>
          {this.isLoading === false ? this.renderContent() : null}
        </React.Fragment>
      )
    } else {
      return (
        <Page title={title}>
          <Box paddingTop={1} width={'100%'}>
            <MarginRow>
              <Box px={1} py={1}>
                <Typography variant="h3" color="primary">
                  {title}
                </Typography>
              </Box>
            </MarginRow>
            {this.isLoading === false ? this.renderContent() : null}
          </Box>
        </Page>
      )
    }
  }

  loadAccount = async () => {
    const { accountStore, location, notify, userStore } = this.props

    let accountId
    if (userStore!.isAdminOrAgent) {
      if (this.props.accountId) {
        accountId = this.props.accountId
      } else {
        accountId = new URLSearchParams(location.search).get("accountId")
      } 
    } else {
      accountId = accountStore!.account!.id 
    }
    
    if (accountId) {
      const account = await accountStore!.getAccount(accountId)

      if (!account) {
        notify!.show('error', 'Could not fetch job post')
        return 
      }

      this.account = account
    } else {
      const message = 'Could not load job post.'
      Logger.error(message, new Error(message))
      return 
    }
  }
}

export default withStyles(styles)(withWidth()(AccountPage))
