import * as React from "react";
import {inject} from "mobx-react";
import Checkbox, {CheckboxProps} from "@material-ui/core/Checkbox";
import {Box, createStyles, FormControl, FormControlLabel, FormControlLabelProps, FormHelperText, Theme, withStyles, WithStyles} from "@material-ui/core";

const styles = (theme: Theme) => createStyles({
  root: {
    height: 56
  },
  helperText: {
    position: "relative",
    top: -8
  },
  checkbox: {
    // marginLeft: theme.spacing(1),
    // textAlign: "center"
  }
})

interface ICheckboxValidatorProps {
  onChange?: React.EventHandler<any>
  checked?: boolean
  required: boolean
  formvalidator?: any
  label?: string
  labelPlacement?: FormControlLabelProps['labelPlacement']
}

type CheckboxValidatorProps = CheckboxProps | ICheckboxValidatorProps

@inject("formvalidator")
class CheckboxValidator extends React.Component<WithStyles<typeof styles> & CheckboxValidatorProps> {

  state = {
    checked: false,
    isValid: true // Start valid until onChange() or validate() event
  }

  componentDidMount() {
    const {formvalidator} = this.props as ICheckboxValidatorProps
    if (formvalidator) {
      formvalidator.attachToForm(this)
    }
    this.setState({checked: this.props.checked ?? false})
  }

  componentDidUpdate(prevProps: any) {
    if (this.props.checked !== prevProps.checked) {
      this.setState({checked: this.props.checked})
    }
  }

  private onChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { checked } = event.currentTarget;
    const { props } = this
    const { onChange } = props as ICheckboxValidatorProps

    this.setState({checked, isValid: !this.props.required || checked})

    if (onChange) {
      onChange(event);
    }
  }

  validate = (value: boolean): boolean => {
      // Ignore value because only checked matters
      const isValid = !this.props.required || this.state.checked
      this.setState({isValid: isValid})
      return isValid
  }

  render() {
    const {
      onChange,
      checked,
      classes,
      ...rest
    } = this.props

    const { label, labelPlacement } = this.props as ICheckboxValidatorProps

    return (
      <FormControl
        className={classes.root}
      >
        <FormControlLabel
          control={
            <Checkbox
              checked={this.state.checked}
              {...rest}
              className={classes.checkbox}
              onChange={this.onChange}
            />
          }
          label={label}
          labelPlacement={labelPlacement}
        />

        <FormHelperText error={!this.state.isValid} className={classes.helperText}>
          {this.state.isValid ? "" : "Required"}
        </FormHelperText>
      </FormControl>
    )
  }

}

export default withStyles(styles)(CheckboxValidator)