import { 
  Box, 
  Grid, 
  // Stack, 
  Typography 
} from "@material-ui/core"
import { Cancel, CheckCircle } from "@material-ui/icons"
import { IRouteData } from "../../RoutesConfig"
import ActionLink from "../ActionLink"
import React from "react"
import Stack from "../Stack"

interface IBulletItem {
  text: string
  icon: 'Cancel' | 'Check'
  route?: IRouteData
}

const BulletItem = (props: IBulletItem) => {
  return (
    <Grid item>
      <Grid container wrap="nowrap" spacing={1}>
        <Grid item>
          {props.icon === 'Check' && 
            <Box color="green">
              <CheckCircle fontSize="medium" color="inherit" />
            </Box> 
          }
          {props.icon === 'Cancel' && 
            <Box color="grey.700">
              <Cancel color="inherit" />
            </Box> 
          }
        </Grid>
        <Grid item>
          <Stack>
            <Typography variant="body1">
              {props.text}
            </Typography>
            { props.route && 
              <React.Fragment>
                &nbsp;
                <ActionLink
                  // color="primary"
                  text={props.route.title}
                  tracking={props.route.title}
                  route={(props.route.pathname ?? props.route.href)!}
                />

              </React.Fragment>
            }
          </Stack>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default BulletItem