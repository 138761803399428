import * as React from "react";
import {
  Box,
  Button,
  createStyles,
  Grid,
  Theme,
  withStyles,
  WithStyles,
  withWidth,
  WithWidth
} from "@material-ui/core";
import {inject, observer} from "mobx-react";
import {makeObservable, observable} from "mobx";
import User from "../../model/User";
import UserStore from "../../stores/UserStore";
// import IconicButton from "../controls/IconicButton";
import PhoneIcon from '@material-ui/icons/Phone';
import CardValue from "../CardValue";
import { phoneToNationalFormat } from "../../stores/StoreUtilities";
import ModalDialog from "../ModalDialog";

const styles = (theme: Theme) => createStyles({
  callToAction: {
    color: theme.palette.primary.contrastText
  }
})

interface IUserCRMActionsPanelProps {
  user: User
  onClickContact(): void 

  // TODO: Future... 
  // didClickAddNote(): void 
  // didClickAddMessage(): void 

  userStore?: UserStore
}

@inject("userStore")
@observer
class UserCRMActionsPanel extends React.Component<WithStyles<typeof styles> & IUserCRMActionsPanelProps & WithWidth> {

  @observable isDialogOpen: boolean = false 

  constructor(props: any) {
    super(props)
    makeObservable(this)
  }

  render() {
    const { classes } = this.props
    return (
      <Box
        // color='#fff'
        // borderRadius={10}
        // bgcolor="primary.500"
        // boxShadow='rgba(0, 0, 0, 0.15) 0px 5px 15px 0px'
        // px={1} 
        // py={1}
      >
        <Grid container justifyContent="center" spacing={2} className={classes.callToAction}>
          <Grid item>
            <Button
              variant="contained"
              color="secondary"
              startIcon={<PhoneIcon />}
              onClick={this.handleClick}
            >
              Contact
            </Button>
            {/* <IconicButton 
              label={"Contact"}
              labelColor='inherit'
              color="primary"
              onClick={this.handleClick}
            >
              <PhoneIcon />
            </IconicButton> */}
            {/* { isUnlocked === false && 
              <Button
                variant="contained"
                color="secondary"
                onClick={async () => {
                  // await updateStatus(JobCandidateStatus.Rejected)
                  // notify.show("success", 'Accepted Candidate Application')
                  // send(JobCandidateActions.REJECT)
                }}
                startIcon={ <LockOpen /> }
              >
                Unlock Profile
              </Button>
            } */}
          </Grid>
        </Grid>
        {this.renderUserInfoDialog()}
      </Box>
    )
  }

  handleClick = () => {
    this.isDialogOpen = true 
  }
  
  renderUserInfoDialog = () => {
    const { user } = this.props 
    return (
      <ModalDialog
        title={user.fullName}
        maxWidth="sm"
        open={this.isDialogOpen}
        onClose={() => {this.isDialogOpen = false }}
      >
        <Grid container direction="column" justifyContent="flex-start">
          <CardValue label="Email">
            {user.email}
          </CardValue>
          <CardValue label="Phone">
            {phoneToNationalFormat(user.phone)}
          </CardValue>
        </Grid>
      </ModalDialog>
    )
  }
}

export default withStyles(styles)(withWidth()(UserCRMActionsPanel))
