import * as React from "react";
import {
  Box,
  createStyles,
  alpha,
  Grid,
  Theme,
  Typography,
  withStyles,
  WithStyles,
  withWidth,
  WithWidth
} from "@material-ui/core";
import {inject, observer} from "mobx-react";
import DialogButton from "./form/DialogButton";
import ControlTower, {Routes} from "./ControlTower";
import {makeObservable, observable} from "mobx";
import AuthButton from "./login/AuthButton";

const styles = (theme: Theme) => createStyles({
  callToAction: {
    color: theme.palette.primary.contrastText
  },
  join: {
    backgroundColor: theme.palette.secondary.main,
    boxShadow: 'rgba(0, 0, 0, 0.15) 0px 5px 15px 0px',
    "&:hover": {
      backgroundColor: alpha(theme.palette.secondary.main, 0.8),
    }
  },
  logIn: {
    backgroundColor: theme.palette.primary[600],
    color: theme.palette.common.white,
    boxShadow: 'rgba(0, 0, 0, 0.15) 0px 5px 15px 0px',
    "&:hover": {
      backgroundColor: theme.palette.primary[500],
    }
  }
})

interface ICallToActionPanelProps {
  prompt: string
  onLogin?: any
}

@inject("userStore")
@observer
class CallToActionPanel extends React.Component<WithStyles<typeof styles> & ICallToActionPanelProps & WithWidth> {

  @observable isAuthVisible: boolean = false

  constructor(props: any) {
    super(props)
    makeObservable(this)
  }

  render() {
    const { classes, prompt } = this.props
    return (
      <Box
        borderRadius={10}
        bgcolor="primary.700"
        boxShadow='rgba(0, 0, 0, 0.25) 0px 5px 15px 0px'
        px={1} pt={2} pb={3}
      >
        <Grid container direction="column" spacing={2} className={classes.callToAction}>
          <Grid item xs={12}>
            <Typography variant="h6" align="center">
              {prompt}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Grid container direction="row" justifyContent="center" spacing={2}>
              <Grid item>
                <DialogButton variant="primary" onClick={() => ControlTower.route(Routes.join)} customClassName={ classes.join }>
                  Join
                </DialogButton>
              </Grid>
              <Grid item>
                <DialogButton variant="tertiary" onClick={() => this.isAuthVisible = true} customClassName={ classes.logIn }>
                  Log In
                </DialogButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {this.isAuthVisible &&
          <AuthButton
            open={true}
            onLogin={this.handleOnLogin}
            onClose={() => this.isAuthVisible = false} 
          />
        }
      </Box>
    )
  }

  handleOnLogin = () => {
    const { onLogin } = this.props
    this.isAuthVisible = false
    if (onLogin) {
      onLogin()
    }
  }
}

export default withStyles(styles)(withWidth()(CallToActionPanel))
