
class CertificationType {
  id: string
  createdAt: string
  updatedAt: string
  name: string
  active: boolean
  industries: string[]

  constructor(data: any) {
    this.id = data.id
    this.createdAt = data.createdAt
    this.updatedAt = data.updatedAt
    this.name = data.name
    this.active = data.active
    this.industries = data.industries ?? []
  }
}

export default CertificationType