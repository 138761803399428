import * as React from "react";
import {Avatar, makeStyles} from "@material-ui/core";
import JobCandidate from "../../model/JobCandidate";
import {JobCandidateStatus, UserRole} from "../../API";
import classnames from "classnames";
import Answer from "../../model/Answer";

const useStyles = makeStyles((theme) => ({
  avatar: {
    borderWidth: 2,
    fontSize: 14,
    width: 34,
    height: 34
  },
  avatarActive: {
    backgroundColor: theme.palette.primary.main,
  },
  avatarApplied: {
    backgroundColor: theme.palette.secondary.main,
  },
  avatarHired: {
    backgroundColor: theme.palette.success.dark,
  },
  avatarRejected: {
    backgroundColor: theme.palette.error.dark,
  },
  avatarInactive: {
    backgroundColor: theme.palette.action.disabled,
  },
  avatarPending: {
    backgroundColor: theme.palette.warning.main,
    color: theme.palette.primary.contrastText
  }
}));

interface IJobCandidateAvatar {
  jobCandidate: JobCandidate
}

const JobCandidateAvatar = (props: IJobCandidateAvatar) => {
  const { jobCandidate } = props
  const classes = useStyles()

  let className
  if (jobCandidate.status === JobCandidateStatus.Applied && jobCandidate.application) {
    let isApplicant
    if (jobCandidate.profile && jobCandidate.profile.user && jobCandidate.profile.user.role === UserRole.Candidate) {
      isApplicant = false
    } else if (jobCandidate.profile && jobCandidate.profile.profileStatus) {
      isApplicant = jobCandidate.profile.inReview()
    } else {
      isApplicant = jobCandidate.application.find((a: Answer) => a.name === "GovGig Candidate" && a.value === "false")
    }
    if (isApplicant) {
      className = classes.avatarPending
    }
  }

  if (!className) {
    switch (jobCandidate.status) {
      case JobCandidateStatus.Applied:
        className = classes.avatarApplied
        break
      case JobCandidateStatus.Accepted:
      case JobCandidateStatus.Reviewing:
      case JobCandidateStatus.Contacted:
        className = classes.avatarActive
        break
      case JobCandidateStatus.Hired:
        className = classes.avatarHired
        break
      case JobCandidateStatus.Rejected:
      case JobCandidateStatus.Declined:
        className = classes.avatarRejected
        break
      default:
        className = classes.avatarInactive
        break
    }
  }

  // console.debug("JobCandidateAvatar", {
  //   key: jobCandidate.id
  // })

  return (
    <Avatar key={jobCandidate.id} className={classnames([classes.avatar,className])}>
      {jobCandidate.profile?.initials}
    </Avatar>
  )

}

export default JobCandidateAvatar