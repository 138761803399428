import { API, Auth } from "aws-amplify";

const AdminAPIName = 'AdminQueries'

class AdminAPI {

  async addUserToGroup(userName: string, groupName: string) {
    let path = '/addUserToGroup';
    let init = {
      body: {
        "username" : userName,
        "groupname": groupName
      },
      headers: {
        'Content-Type' : 'application/json',
        Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
      }
    }
    return await API.post(AdminAPIName, path, init);
  }

  async removeUserFromGroup(userName: string, groupName: string) {
    let path = '/removeUserFromGroup';
    let init = {
      body: {
        "username" : userName,
        "groupname": groupName
      },
      headers: {
        'Content-Type' : 'application/json',
        Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
      }
    }
    return await API.post(AdminAPIName, path, init);
  }

  async getUser(userName: string) {
    let path = `/getUser?username=${userName}`;
    let init = {
      headers: {
        'Content-Type' : 'application/json',
        Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
      }
    }
    return await API.get(AdminAPIName, path, init);
  }

}

export default AdminAPI
