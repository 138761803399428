/* eslint-disable @typescript-eslint/no-unused-vars */
import React from "react";
import {
  TextField,
  withWidth,
  WithWidth
} from "@material-ui/core";
import { createStyles, Theme, WithStyles, WithTheme } from "@material-ui/core";
import { withStyles, withTheme } from "@material-ui/core/styles";
import { Autocomplete } from "@material-ui/lab";
import ProfileStore from "../../../stores/ProfileStore";
import { makeObservable, observable } from "mobx";
import { inject, observer } from "mobx-react";
import UserStore from "../../../stores/UserStore";
import AccountStore from "../../../stores/AccountStore";
import Progress from "../../Progress";

import {SelectOption} from "../../../stores/ResourceCache";

const typeOptions:SelectOption[] = [ 
	{ 
		label: "8A", 
		value: "8A"
	}, 
	{ 
		label: "HZC", 
		value: "HZC" 
	},
  { 
		label: "SDVOSBC", 
		value: "SDVOSBC" 
	}  
] 

const styles = (theme: Theme) => createStyles({
  filterTextField: {
    width: "100%",
    flexGrow: 1, 
    backgroundColor: theme.palette.background.paper,
    borderRadius: 10
  }
})

export interface ISetAsideTypeFilterProps {
  onSelectSetAsideTypeOpportunity: (val:SelectOption) => void;
  value?: SelectOption
  accountStore?: AccountStore
  profileStore?: ProfileStore
  progress?: Progress
  userStore?: UserStore 
}

@inject("accountStore", "profileStore", "userStore", "progress")
@observer
class SetAsideTypeFilter extends React.Component<WithStyles<typeof styles> & WithTheme & ISetAsideTypeFilterProps & WithWidth> {

  @observable setAsideTypeFilter?: any

  constructor(props: any) {
    super(props)
    makeObservable(this)
  }

  componentDidMount() {
    const { value } = this.props
    this.setAsideTypeFilter = value
  }

  componentDidUpdate(prevProps: any) {
    if (prevProps.value !== this.props.value) {
      this.setAsideTypeFilter = this.props.value
    }
  }


  render() {
    const { classes } = this.props

    return (
      <Autocomplete
        autoSelect
        options={typeOptions}
        getOptionLabel={(option: SelectOption) => option?.label ?? ""}
        getOptionSelected={(option: SelectOption, value: SelectOption) => option.value === this.setAsideTypeFilter}
        onChange={this.onSelectOpportunityType}
        value={this.setAsideTypeFilter ?? null}
        renderInput={(params: any) =>
          <div ref={params.InputProps.ref}>
            <TextField
              {...params}
              className={classes.filterTextField}
              variant="outlined"
              size="small"
              name="setAsideTypeFilter"
              label="Set-Aside Type"
            />
          </div>
        }
      />
    )
  }

  onSelectOpportunityType = (event: any, e: any, reason: any) => {
    if (e && e.value) {
      this.setAsideTypeFilter = e
    } else {
      this.setAsideTypeFilter = null
    }
    this.props.onSelectSetAsideTypeOpportunity(this.setAsideTypeFilter)
  }

}

export default withTheme(withStyles(styles)(withWidth()(SetAsideTypeFilter)))