import {
  Box,
  createStyles,
  Grid,
  Theme,
  Typography,
  withStyles,
  WithStyles,
  WithWidth,
  withWidth
} from '@material-ui/core';
import { RouteComponentProps } from '@reach/router';
import { makeObservable, observable } from 'mobx';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import MarginRow from "../../components/page/MarginRow";
import Page from '../../components/page/Page';
import UserStore from '../../stores/UserStore';
import UserSettings from './user-settings/UserSettings';

const styles = (theme: Theme) => createStyles({
  content: {
    display: "flex",
    flexGrow: 1,
    flexDirection: "column",
    alignItems: "center",
  },
})

interface ISettingsPageProps {
  userStore?: UserStore
}

@inject('userStore')
@observer
class SettingsPage extends React.Component<WithStyles<typeof styles> & WithWidth & RouteComponentProps & ISettingsPageProps> {

  @observable isAddUserOpen: boolean = false

  constructor(props: WithStyles<typeof styles> & WithWidth & RouteComponentProps & ISettingsPageProps) {
    super(props)
    makeObservable(this)
  }

  render() {
    const { userStore } = this.props

    const user = userStore!.user!

    if (!user) {
      return null
    }

    return (
      <Page hideFooter>
        <MarginRow>
          <Box pl={1} pt={1}>
            <Typography variant="h3">
              Settings
            </Typography>
          </Box>
          <Grid container justifyContent="center">
            <UserSettings user={userStore!.user!} />
          </Grid>
        </MarginRow>
      </Page>
    )
  }
}

export default withStyles(styles)(withWidth()(SettingsPage))
