import * as React from "react";
import {
  Box,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormGroup,
  Grid,
  InputAdornment,
  Theme,
  withStyles,
  WithStyles,
  withWidth,
  WithWidth
} from "@material-ui/core";
import { durationBetweenISODates, getISODateFromDateString, isoToLocalDateString } from "../../stores/StoreUtilities";
import Contract from "../../model/Contract";
import ResourceCache from "../../stores/ResourceCache";
import FormValidator from "../../components/form/FormValidator";
import TextFieldValidator from "../../components/form/TextFieldValidator";
import DatePickerValidator from "../../components/form/DatePickerValidator";
import FormGroupSpacer from "../../components/form/FormGroupSpacer";
import DialogButton from "../../components/form/DialogButton";
import ProgressButton from "../../components/form/ProgressButton";
import { makeObservable, observable } from "mobx";
import Confirm from "../../components/confirm/Confirm";
import AccountStore from "../../stores/AccountStore";
import Logger from "../../components/Logger";
import { CreateContractInput, SubjectType, UpdateContractInput } from "../../API";
import { inject, observer } from "mobx-react";
import Progress from "../../components/Progress";
import Account from "../../model/Account";
import {LocationOption} from "../../stores/ResourceCache";
import CancelButton from "../../components/form/CancelButton";
import UserStore from "../../stores/UserStore";
import { ActivityType } from "../../model/UserActivity";
import PlaceFilter from "../../components/filter/PlaceFilter";
import JobStore from "../../stores/JobStore";
import Stack from "../../components/Stack";

const styles = (theme: Theme) => createStyles({
  progressButton: {
    minWidth: 80
  }
})

export class ContractViewModel {
  @observable id: string
  @observable accountId: string
  @observable account?: Account 
  @observable name: string
  @observable number: string
  @observable locationId: string
  @observable locationOption?: LocationOption
  @observable startDate: string
  @observable endDate: string
  @observable duration: string
  @observable perDiem: string
  @observable isActive: boolean
  @observable isExpanded: boolean = false
  @observable value: string 
  @observable category: string 

  constructor(contract: Contract) {
    makeObservable(this)
    this.id = contract.id
    this.accountId = contract.accountId
    this.account = undefined
    this.name = contract.name
    this.number = contract.number
    this.locationId = contract.locationId
    this.locationOption = undefined
    this.startDate = isoToLocalDateString(contract.startDate)
    this.endDate = isoToLocalDateString(contract.endDate)
    this.duration = (contract.startDate && contract.endDate) ? durationBetweenISODates(contract.startDate, contract.endDate) : ''
    this.perDiem = contract.perDiem ? contract.perDiem.toString() : ''
    this.isActive = contract.isActive()
    this.value = contract.value 
    this.category = contract.category 
  }
}

export interface IContractFormDelegate {
  didCreate(contract: Contract): Promise<void>
  didDelete(contract: Contract): void 
  didEdit(contract: Contract): void 
  didClose(): void 
}

interface IContractFormProps extends IContractFormDelegate {
  accounts?: Account[]
  contract?: Contract
  isFormOpen: boolean

  accountStore?: AccountStore
  jobStore?: JobStore
  confirm?: Confirm
  progress?: Progress
  resourceCache?: ResourceCache
  userStore?: UserStore
}

@inject("accountStore", "jobStore", "resourceCache", "progress", "confirm", "userStore")
@observer
class ContractForm extends React.Component<WithStyles<typeof styles> & IContractFormProps & WithWidth> {
  @observable isProcessing = false
  @observable viewModel?: ContractViewModel
  @observable message = ""

  constructor(props: any) {
    super(props);
    makeObservable(this);
  }

  componentDidMount() {
    const { accounts, contract, resourceCache } = this.props
    if (contract) {
      this.viewModel = new ContractViewModel(contract)
      this.viewModel.locationOption = resourceCache!.getLocationOption(this.viewModel.locationId)
      if (accounts) {
        this.viewModel.account = accounts.find(account => account.id === contract.accountId)
      } 
    } else {
      this.viewModel = undefined
    }
  }

  componentDidUpdate(prevProps: any) {
    if (prevProps.contract !== this.props.contract) {
      this.componentDidMount()
    }
  }

  render() {
    const { accounts, classes } = this.props

    if (this.viewModel === undefined) {
      return null
    }

    const viewModel = this.viewModel

    return (
      <Dialog
        open={this.props.isFormOpen}
        onClose={this.handleCloseContractForm}
        fullWidth
        maxWidth="sm"
      >
        <FormValidator onSubmit={this.onSubmitContractForm} autoComplete="off" name="contractInfoForm" id="contractInfoForm">
          <DialogTitle id="form-dialog-title">Job Group (Contract or Project)</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Job Groups are used to group related job posts by job type, location, division, department, contract, project, etc.
            </DialogContentText>
            <Stack direction="column" spacing={2} sx={{ pb: 2 }}>
              { accounts ? 
                <TextFieldValidator
                  type="text"
                  validators={{ required: true }}
                  name="account"
                  label="Account *"
                  fullWidth
                  helperText="Account/Employer"
                  autocompleteOptions={{
                    freeSolo: false,
                    options: accounts,
                    value: viewModel.account ?? null,
                    getOptionLabel: (option: Account) => option?.name,
                    getOptionSelected: (option: Account, value: Account) => option.id === value.id,
                    onChange: (event: any, value: Account, reason: any) => {
                      if (value) {
                        viewModel.account = value
                        viewModel.accountId = value.id
                      } else {
                        viewModel.account = undefined
                        viewModel.accountId = ''
                      }
                    }
                  }}
                />
              : null}
              <TextFieldValidator
                type="text"
                validators={{ required: true }}
                name="name"
                label="Group Name (such as Contract or Project Name) *"
                value={viewModel.name}
                onChange={this.handleChangeContractFormField}
                fullWidth
              />
              {/*<TextFieldValidator*/}
              {/*  type="text"*/}
              {/*  validators={{ required: false }}*/}
              {/*  name="number"*/}
              {/*  label="Contract Number"*/}
              {/*  value={viewModel.number}*/}
              {/*  onChange={this.handleChangeContractFormField}*/}
              {/*  fullWidth*/}
              {/*/>*/}
              {/* <h4>Optional Details</h4> */}
              <TextFieldValidator
                type="text"
                // validators={{ required: true }}
                name="projectCategory"
                label="Work Category"
                placeholder="Power Infrastructure, Water Infrastructure, etc."
                value={viewModel.category}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  // event.target.name
                  const value = event.target.value 
                  this.viewModel!.category = value 
                }}
                fullWidth
              />
              <TextFieldValidator
                type="text"
                // validators={{ required: true }}
                name="projectValue"
                label="Value"
                placeholder="Dollar value of the project or contract"
                value={viewModel.value}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  // event.target.name
                  const value = event.target.value 
                  this.viewModel!.value = value 
                }}
                fullWidth
                InputProps={{
                  startAdornment: <InputAdornment position="start">$</InputAdornment>
                }}
              />
              <PlaceFilter
                variant="standard"
                value={this.viewModel.locationOption}
                onSelectLocation={(value?: LocationOption) => {
                  if (value) {
                    viewModel.locationOption = value
                    viewModel.locationId = value.id
                  } else {
                    viewModel.locationOption = undefined
                    viewModel.locationId = ''
                  }
                }}
              />
              <FormGroup row>
                <Grid container justifyContent="space-between" wrap="nowrap">
                  <DatePickerValidator
                    disableToolbar
                    variant="inline"
                    format="MM/dd/yyyy"
                    placeholder="MM/dd/yyyy"
                    margin="none"
                    fullWidth
                    autoOk
                    id="date-picker-inline"
                    label="Starting Date"
                    name="startDate"
                    value={viewModel.startDate}
                    onChange={this.handleChangeContractFormField}
                  />
                  <FormGroupSpacer />
                  <DatePickerValidator
                    disableToolbar
                    variant="inline"
                    format="MM/dd/yyyy"
                    placeholder="MM/dd/yyyy"
                    margin="none"
                    fullWidth
                    autoOk
                    id="date-picker-inline"
                    label="Est. End Date"
                    name="endDate"
                    value={viewModel.endDate}
                    onChange={this.handleChangeContractFormField}
                  />
                </Grid>
              </FormGroup>
              {/*<TextFieldValidator*/}
              {/*  type="text"*/}
              {/*  validators={{ required: false, isFloat: null }}*/}
              {/*  name="perDiem"*/}
              {/*  label="Per Diem / Subsistence Allowence"*/}
              {/*  value={viewModel.perDiem}*/}
              {/*  onChange={this.handleChangeContractFormField}*/}
              {/*  fullWidth*/}
              {/*/>*/}
            </Stack>
            <Box mt={1}>
              <DialogContentText color="error">
                {this.message}
              </DialogContentText>
            </Box>
          </DialogContent>
          <DialogActions>
            <Grid container justifyContent="space-between">
              <Grid item>
                <DialogButton variant="secondary" onClick={() => {
                  const content = 'Do you want to delete this job group? (This will delete all job posts in this group cannot be undone.)'
                  this.props.confirm!.show('Delete', content, ['Delete', 'Cancel'], () => {
                    this.handleDeleteContract()
                    return true
                  })
                }}>
                  Delete
                </DialogButton>
              </Grid>
              <Grid item>
                <Grid container>
                  <Grid item>
                    <CancelButton onClick={this.handleCloseContractForm} />
                  </Grid>
                  <Grid item>
                    <ProgressButton variant="contained" size="medium" color="secondary"
                      type="submit" className={classes.progressButton} processing={this.isProcessing}>
                      Save
                    </ProgressButton>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </DialogActions>
        </FormValidator>
      </Dialog>
    )
  }

  handleChangeContractFormField = (event: React.ChangeEvent<HTMLInputElement>) => {
    const name = event.target.name
    this.viewModel![name] = event.target.value
  }

  handleDeleteContract = async () => {
    const { jobStore, didDelete, progress, userStore } = this.props

    this.isProcessing = true 
    progress!.show('ContractForm')
    this.message = ""
    const contractViewModel = this.viewModel!

    try {
      let contract = await jobStore!.getContract(contractViewModel.id)
      const promises = contract!.jobPosts.map(jobPost => {
        return jobStore!.deleteJobPost(jobPost.id, userStore!)
      })
      await Promise.all(promises)
      
      await jobStore!.deleteContract(contractViewModel.id)

      userStore!.logUserActivity(userStore!.user!.id, SubjectType.Contract, contractViewModel.id, ActivityType.ContractDeleted,
        `${userStore!.user!.fullName} deleted the job group '${contractViewModel.name}'`)
      
      contract!.jobPosts.forEach(jobPost => {
        userStore!.logUserActivity(userStore!.user!.id, SubjectType.JobPost, jobPost.id, ActivityType.JobPostDeleted,
          `${userStore!.user!.fullName} deleted the job post '${jobPost.fullName}'`)
      })
      
      didDelete(contract!)
      this.handleCloseContractForm()
    } catch (error) {
      const message = 'Could not delete the job group.'
      Logger.error(message, error)
      this.message = message
    }
    this.isProcessing = false 
    progress!.hide('ContractForm')
  }

  handleCloseContractForm = () => {
    this.message = ""
    this.props.didClose() 
  }

  onSubmitContractForm = async () => {
    this.isProcessing = true 
    this.props.progress!.show('ContractForm')
    this.message = ""

    const viewModel = this.viewModel!

    if (viewModel.id) {
      await this.editContract()
    } else {
      await this.createContract()
    }
    this.isProcessing = false 
    this.props.progress!.hide('ContractForm')
    this.handleCloseContractForm()
  }

  editContract = async () => {
    const viewModel = this.viewModel!
    const input: UpdateContractInput = {
      id: viewModel.id, 
      accountId: viewModel.accountId, 
      name: viewModel.name,
      number: viewModel.number ?? null,
      locationId: viewModel.locationId ?? null,
      startDate: viewModel.startDate ? getISODateFromDateString(viewModel.startDate) : null,
      endDate: viewModel.endDate ? getISODateFromDateString(viewModel.endDate) : null,
      perDiem: viewModel.perDiem ? parseFloat(viewModel.perDiem) : null,
      value: viewModel.value ?? null,
      category: viewModel.category ?? null 
    }
    let contract 
    try {
      contract = await this.props.jobStore!.updateContract(input)
      if (contract) {
        this.props.didEdit(contract)
      } else {
        throw new Error('Unable to update job group record.')
      }
    } catch (error) {
      const message = 'Could not update the job group.'
      Logger.error(message, error)
      this.message = message
      return 
    }
  }

  createContract = async () => {
    const { userStore } = this.props

    const viewModel = this.viewModel!
    const input: CreateContractInput = {
      accountId: viewModel.accountId, 
      name: viewModel.name, 
      number: viewModel.number ?? null,
      locationId: viewModel.locationId ?? null,
      startDate: viewModel.startDate ? getISODateFromDateString(viewModel.startDate) : null,
      endDate: viewModel.endDate ? getISODateFromDateString(viewModel.endDate) : null,
      perDiem: viewModel.perDiem ? parseFloat(viewModel.perDiem) : null,
      value: viewModel.value ?? null,
      category: viewModel.category ?? null 
    }
    let contract 
    try {
      contract = await this.props.jobStore!.createContract(input)
      if (contract) {
        userStore!.logUserActivity(userStore!.user!.id, SubjectType.Contract, contract.id, ActivityType.ContractAdded,
          `${userStore!.user!.fullName} created the job group '${contract.name}'`)
        
        await this.props.didCreate(contract)
      } else {
        throw new Error('Unable to create job group record.')
      }
    } catch (error) {
      const message = 'Could not create the job group.'
      Logger.error(message, error)
      this.message = message
      return 
    }
  }
}

export default withStyles(styles)(withWidth()(ContractForm))
