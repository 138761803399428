import * as React from 'react'
import {createStyles, Theme, withStyles, WithStyles} from "@material-ui/core/styles";
import Logo from "../../images/header_logo.png"
import {Box} from "@material-ui/core";
import CheckBoxIcon from '@material-ui/icons/CheckBox';

const styles = (theme: Theme) => createStyles({
  background: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(1)
  },
  link: {
    textDecoration: 'none',
    color: theme.palette.action.active
  },
})

interface IExclusiveMasterServiceAgreement20231012Props {
  accountName: string
  acceptedBy?: string
  acceptedOn?: string
}

class ExclusiveMasterServiceAgreement20231012 extends React.Component<WithStyles<typeof styles> & IExclusiveMasterServiceAgreement20231012Props> {

  render() {
    const {accountName, acceptedBy, acceptedOn, classes} = this.props

    return (
      <Box className={ classes.background }>
        <Box display="flex" mt={2} justifyContent="center">
          <img src={Logo} alt="logo"/>
        </Box>

        <h1>MASTER SERVICES & NON-SOLICITATION AGREEMENT</h1>

        <p style={{textAlign: "center"}}>
          <b>CAREFULLY READ THIS SCROLLWRAP AGREEMENT.  BY CLICKING ON THE “I ACCEPT” CHECKBOX, YOU ARE CONSENTING TO BIND CLIENT AS A PARTY TO ALL TERMS OF THIS AGREEMENT.  FURTHER, YOU REPRESENT BEING DULY AUTHORIZED TO BIND CLIENT TO THE AGREEMENT AND TO ACCEPT THE AGREEMENT ON CLIENT’S BEHALF.</b>
        </p>

        <p>Updated: October 12, 2023</p>

        <p style={{textIndent:36}}>This Exclusive Master Services Agreement (“Agreement”) is made between Watermark Solutions, LLC dba GovGig located at 19255 Powder Hill Pl NE, Suite 102, Poulsbo, WA 98370 (hereinafter “GovGig”) and You, together with Your affiliates and subsidiaries (hereinafter “You,” “Your,” or “Client”) (each a “Party,” and collectively, the “Parties”).</p>

        <ol>
          <li><b>Relationship of the Parties.</b>
            Each Party shall act as an independent contractor and not as an agent for, partner of or joint venture with the other Party hereto.  Neither Party hereto shall have any authority to create any obligation for the other except to the extent set forth herein and in duly executed written supplements hereto.
          </li>
          <li><b>Scope of Services Provided.</b>
            Client shall retain GovGig as its exclusive supplier for its direct hiring needs for the duration of the Agreement; however, candidates not referred by GovGig are not subject to the terms of this Agreement.  This Agreement is, in no way, meant to imply or otherwise state that GovGig is assuming the role of and/or replacing any of Client’s organic hiring efforts.  It is Client’s intention to utilize GovGig as its primary and exclusive outside resource/agency for hiring to augment its own internal processes for the duration of this Agreement.  GovGig will, at Client’s request, provide direct hire placement options of personnel in support of Client operations.  Personnel provided by GovGig will meet the requirements of the request, including any due diligence, qualifications, and background checks, as specified by the Parties.  A “Contract Resource” shall mean a GovGig derived candidate provided to Client by GovGig for the purposes of direct employment with Client.  This Agreement is non-exclusive in nature insofar as it does not imply, state, or otherwise suggest that Client must use GovGig, and only GovGig, as its sole outside agency/resource for purposes of personnel acquisition, staffing, and/or hiring.  Client is not obligated to request any services from GovGig, and GovGig is not obligated to accept any request for services from Client.
          </li>
          <li><b>Non-Solicitation.</b>
            The services offered by GovGig include providing access to professionals who may not be direct employees of GovGig and who could be solicited individually by any member of the public for those services.  The list of professionals and the services each is willing to offer is proprietary information, the disclosure of which could fiscally and professionally injure GovGig.  In return for being made privy to the details of the identities of the individuals, Client agrees it will not disclose to any individual or entity the contents of the list and services available which will be disclosed by GovGig.  Client acknowledges the individuals who have agreed to offer services through GovGig and who are part of the list to be disclosed are under obligation to not accept direct employment with Client within two years of the signing of this document by Client.  Individual(s) may, however, accept such employment with Client if expressly authorized in writing by GovGig prior to such acceptance of employment.  Client therefore agrees to honor that commitment by refraining from soliciting any of the individuals to provide any of the services that are offered through GovGig during that two-year time period.
          </li>
          <li><b>Term; Termination.</b>
            This Agreement will remain in effect until terminated by either Party: (i) upon ten (10) business days’ written notice, or (ii) immediately upon written notice if the other Party breaches any of its obligations under this Agreement; provided, that the breaching Party will have ten (10) business days to cure the breach. 
          </li>
          <li><b>Requests for Contract Resources.</b>
            Client may authorize GovGig to provide a Contract Resource by email, and Client’s assignment of work to any provided Contract Resource shall be deemed to be Client’s confirmation and approval of GovGig’s tender of such Contract Resource, even if a written order for such employee was not provided by Client.  The assignment of a Contract Resource will commence on the date indicated by Client, or the date that Client assigns work to such Contract Resource, whichever is earlier.  Such assignment shall be confirmed in a Rate Sheet or other written document delivered by GovGig to Client.  If a Contract Resource continues to render services after the expiration date of the assignment term, that event will be deemed an extension of the original term of service.  Client may request the removal of any Contract Resource at any time without incurring any subsequent liability or costs. 
          </li>
          <li><b>Contract Resource Selection.</b>
            Contract Resources shall be selected by GovGig in its judgment based on its diligent and reasonable understanding of Client’s technical requirements as specified in the Order.  Because these requirements are subject to interpretation, final acceptance of any Contract Resource shall be Client’s responsibility.  Unless otherwise specified, Client will furnish all materials, supplies, facilities, space, and equipment that may be required for personnel to perform their assigned duties. 
          </li>
          <li><b>Labor Rates.</b>
            Labor rates will be established and negotiated between GovGig and Client on a per proposal basis.  These rates will be based on geographical location, labor category, industry market rates, and any applicable contract rates.  Labor rates indicated on proposals will be all inclusive insofar as they include all GovGig personnel wages, burden, taxes, and fees.
          </li>
          <ol type="a">
            <li>Per diem for personnel operating primarily remotely will only apply when working on the project site. This cost will be a separate line item on invoices and will be specifically annotated as to which day(s) the individual(s) was/were on site. Parking will be available on site or at the job trailer for personnel. Lodging expenses will be addressed on a case-by-case basis and will likewise be a separate line item on invoices. All travel expenses will be in accordance with the cost allowability guidelines in FAR 31.205-46.</li>
            <li>Total duration (weeks/months) of services and support will (1) be evaluated biweekly (every 2 weeks) to determine a sufficient level of response, and (2) require no less than 20 hours per week for each staffing position requested.</li>
            <li>Specific requirements when additional duties, outside the typical/normal scope of responsibilities for on-site project staff, are to be performed by a single individual (i.e., “dual-hat”, “triple-hat”, or other duties as required by project specifications), discussions and negotiations will be conducted prior to issuance of a final proposal and commencement of work.</li>
          </ol>
          <li><b>Billing and Payment.</b>
            For each Contract Resource placed by GovGig, Client will pay GovGig a recruitment fee in the amount of fifteen (15) percent of either the Contract Resource’s equivalent salary based on 2,080 hours per calendar year at the individual’s hourly rate, or other finalized employment agreement document between Client and Contract Resource.  Client shall pay all invoices within thirty (30) calendar days after the date of each invoice in U.S. Dollars.  Late payments shall bear interest at 1.5% per month.  GovGig reserves the right immediately to withdraw any and all Contract Resources and to cease providing services if any invoice is not paid when due.  Recruiting fee example: $120,000 base annual salary x 15% fee = $18,000.00.
          </li>
          <li><b>Warranties by GovGig.</b>
            If Contract Resource is a direct hire by Client, and the Contract Resource resigns or is terminated for performance, GovGig will, at the discretion and request of Client, provide up to three (3) Contract Resource replacement options, within four (4) months of the initial direct hire, at no additional charge.  If Client does not desire to replace the original Contract Resource, Client may suspend services for that particular placement/instance/project at its discretion without incurring any additional costs, fees, or penalties.  This singular suspension of services will not affect other services being provided under the terms of this Agreement.  As a follow-on to the example given in the previous section, GovGig will prorate the 15% fee over the 4-month warranty period such that the $18,000.00 fee will be payable in 4 monthly installments of $4,500.00 each.  As such, if a Contract Resource is employed by Client and resigns or is terminated for performance within the 4-month warranty period, GovGig will not invoice Client until a suitable replacement Contract Resource has been hired by Client.  Other than as expressly set forth herein, GovGig makes no representations or warranties of any kind, either express or implied.  Client understands and agrees that GovGig does not guarantee to provide Client any specific deliverable, result, or outcome.
          </li>
          <li><b>Taxes.</b>
            Each Party shall be responsible for its respective present and future taxes, duties, tariffs, fees, imports, and other charges, including, but not limited to, income, excise, import, purchase, sales, use, turnover, added value, gross receipts, gross wages, and similar assessments imposed upon such party by any taxing authority as a result of the performance of the Party's duties and responsibilities hereunder.
          </li>
          <li><b>Proprietary Information.</b>
            The Non‐Disclosure Agreement entered into by the Parties (“NDA”) is included with this Agreement.  In addition to any obligations under the NDA, any Contract Resource resumes provided by GovGig to Client will be deemed to be the confidential and/or proprietary information of GovGig and not subject to disclosure to any third party without GovGig’s prior, written consent.  The only exceptions to such disclosure are resumes and associated qualification and certification documents of Contract Resources presented to MEC with the express intent to submit such documents to the Federal Government for purposes of executing a Federal contract.  Notwithstanding any other provision of this Agreement, the non‐disclosure obligations of the receiving party shall survive expiration and/or termination of this Agreement for the period specifically set forth in the NDA, and any other inconsistency between this Agreement and the NDA shall be resolved by giving precedence to the terms of this Agreement.
          </li>
          <li><b>Dispute Resolution.</b>
            In the event of any dispute between GovGig and Client arising out of or in connection with this Agreement, the Parties agree to first make a good faith effort to resolve the dispute informally.  Negotiations shall take place between the designated principals of each Party.  If GovGig and Client are unable to resolve the dispute through negotiations within forty-five (45) calendar days, then either Party may give written notice within ten (10) business days thereafter that it elects to proceed with non-binding mediation pursuant to the commercial mediation rules of the American Arbitration Association.  In the event that mediation is not invoked by the parties or that the mediation is unsuccessful in resolving the dispute, then GovGig and Client agree to arbitrate in accordance with commercial arbitration rules of the American Arbitration Association (provided, however that only one arbitrator shall preside). Venue for any mediation or arbitration shall exclusively reside in the State of Washington.  The award rendered by the arbitrator shall be final and judgment may be entered upon it in accordance with the applicable law of any court having jurisdiction.  The prevailing party shall be entitled to its reasonable costs, expenses, and attorneys’ fees incurred in the course of any dispute, claim, or legal proceeding.  
          </li>
          <li><b>No Third-Party Beneficiaries.</b>
            This Agreement has been entered into solely for the benefit of the Parties and is not intended to create any legal, equitable, or beneficial interest in any third party.
          </li>
          <li><b>Modifications and Waivers.</b>
            This Agreement shall not be amended or modified, nor shall any waiver of any right under this Agreement be effective, unless it is set forth in a document executed by duly authorized representatives of both GovGig and Client.  Any forbearance or delay on the part of either Party in enforcing any provision of this Agreement or any of its rights hereunder will not be construed as a waiver of such provision.  The waiver of any breach of any term, covenant, or condition in this Agreement shall not be deemed to be a waiver of such term, covenant, or condition or any subsequent breach of the same.
          </li>
          <li><b>Trademark and Logo Usage.</b>
            Client hereby grants to GovGig the express right to use Client's company logo in marketing, sales, public relations, and other communications, whether printed or electronic, in order to identify Client as the recipient of GovGig’s professional services.  GovGig hereby grants to Client the express right to likewise use GovGig's logo to identify GovGig as a provider of services to Client.  Other than as expressly stated herein, neither Party shall use the other Party's marks, logos, drawings, or specifications without the prior written permission of the other Party. These mutual permissions shall remain in effect unless and until 1) revoked by either Party by means of a thirty (30) day written notice whereupon those permissions shall be considered revoked at the end of said thirty (30) days, or 2) this Agreement is wholly terminated in accordance with the above terms and conditions.
          </li>
          <li><b>Severability.</b>
            If any provision of this Agreement is held unenforceable for any reason, such provision will be reformed only to the extent necessary to make it enforceable, and such holding will not impair the validity, legality, or enforceability of the remaining provisions.
          </li>
          <li><b>Applicable Law.</b>
            This Agreement will be governed by the laws of the State of Washington without regard to conflict of law principles which might require the application of the laws of another jurisdiction.
          </li>
          <li><b>Notice.</b>
            All notices and other communications in connection with this Agreement will be in writing sent to the Parties’ addresses set forth above (or to such other address such Party specifies) and shall be deemed to have been sufficiently given when personally delivered or mailed, first class, postage prepaid to said address and to the attention of the undersigned.
          </li>
          <li><b>Entire Agreement.</b>
            This Agreement, including any Orders: (i) constitutes the entire agreement between the Parties with respect to the subject matter hereof and supersedes any previous or contemporaneous agreements, arrangements, or understandings, whether oral or written, and (ii) may be signed in counterparts.
          </li>
          <li><b>Acceptance.</b>
            GovGig and Client desire to conduct business electronically.  As such, this Agreement is executed using scrollwrap procedures.   BY CLICKING ON THE “I ACCEPT” CHECKBOX, YOU ARE CONSENTING TO BIND CLIENT AS A PARTY TO ALL TERMS OF THIS AGREEMENT. FURTHER, YOU REPRESENT BEING DULY AUTHORIZED TO BIND CLIENT TO THE AGREEMENT AND TO ACCEPT THE AGREEMENT ON CLIENT’S BEHALF.
          </li>
        </ol>

        {acceptedBy && acceptedOn &&
          <Box display="flex" alignItems="bottom" pl={2}>
            <CheckBoxIcon/> &nbsp;<p>I, <b>{acceptedBy}</b> accepted this agreement on <b>{acceptedOn}</b></p>
          </Box>
        }

      </Box>
    )
  }
}

export default withStyles(styles)(ExclusiveMasterServiceAgreement20231012)
