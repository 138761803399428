import { Box, Container, useMediaQuery, useTheme } from "@material-ui/core"
// import Script from "next/script"
import IframeResizer from 'iframe-resizer-react'

const Testimonials = () => {
  const theme = useTheme()
  const isSmall = useMediaQuery(theme.breakpoints.down('md'))
  return (
    <Box
      // bgcolor='#fff'
      sx={{
        // backgroundColor: "#fff",
        // p: 2, 
        // borderRadius: theme.spacing(1)
      }}
    >
      {/* 
        To show only 1 testimonial, use this code: 
        (For more, we can show the "wall of love," see block of code below.) 
      */}
      {/* <IframeResizer
        // log // Note: You can turn this log variable on to show all the frame calculations.  
        src="https://embed-v2.testimonial.to/t/-NUyExTefS-rdNL4lswh?design=left-aligned&darkFont=yes&bgColor=ffffff&borderColor=ABB8C3&starColor=FDCC0D&shadowSize=shadow-none&showBorder=no&showPadding=no&borderRadius=rounded-lg&borderWidth=border" 
        style={{ 
          border: "none",
          width: isSmall ? 300 : theme.breakpoints.values.md,
          minWidth: "100%",
          minHeight: "100%"
        }} 
      /> */}

      {/* As we have more reviews, we can show a "wall of love": 
        Docs: https://help.testimonial.to/en/articles/6223121-embed-a-wall-of-love 
      */}
      <IframeResizer 
        src="https://embed-v2.testimonial.to/w/govgig-marketing?theme=light&card=large&loadMore=on&initialCount=5&hideDate=on&tag=all" 
        style={{ 
          border: "none",
          width: isSmall ? 300 : theme.breakpoints.values.md,
          minWidth: "100%",
          minHeight: "100%"
        }} 
      />
    </Box>
  )
}

export default Testimonials
