import {
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormGroup,
  Grid,
  InputAdornment,
  isWidthDown,
  Theme, Typography,
  withStyles,
  WithStyles,
  withTheme,
  WithTheme,
  withWidth,
  WithWidth
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import { observable, makeObservable } from "mobx";
import { inject, observer } from "mobx-react";
import React from "react";
import {UpdateProfileInput, UpdateUserInput} from "../../API";
import CardValue from "../../components/CardValue";
import IconicButton from "../../components/controls/IconicButton";
import FormGroupSpacer from "../../components/form/FormGroupSpacer";
import FormValidator from "../../components/form/FormValidator";
import ProgressButton from "../../components/form/ProgressButton";
import StateSelector from "../../components/form/StateSelector";
import TextFieldValidator from "../../components/form/TextFieldValidator";
import Logger from "../../components/Logger";
import Notify from "../../components/notify/Notify";
import Profile, {ProfileAvailabilityOptions} from "../../model/Profile";
import User from "../../model/User";
import ProfileStore from "../../stores/ProfileStore";
import {
  phoneToNationalFormat
} from "../../stores/StoreUtilities";
import UserStore from "../../stores/UserStore";
import EmailChangeDialog from "./change-dialogs/EmailChangeDialog";
import PasswordChangeDialog from "./change-dialogs/PasswordChangeDialog";
import ProfileCard from "./ProfileCard";
import Visible from "../../components/Visible";
import CancelButton from "../../components/form/CancelButton";
import JobCandidate from "../../model/JobCandidate";
import SimpleSelect from "../../components/controls/SimpleSelect";

const styles = (theme: Theme) => createStyles({
  container: {
    flex: 1
  },
  IconicButton: {
    padding: theme.spacing(1)
  },
  label: {
    width: 150,
    fontWeight: 'bold',
    color: theme.palette.primary.main
  },
  formDialog: {

  },
  formGroupRow: {
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "nowrap"
  },
  formGroupField: {
    flexGrow: 1,
  },
  postalCodeField: {
    flexGrow: 0,
    width: 100
  },
  stateSelector: {
    minWidth: 60
  },
  progressButton: {
    minWidth: 80
  },
  availabilitySelect: {
    width: 480
  }
})

export interface IProfilePersonalInfoProps {
  profile?: Profile
  jobCandidate?: JobCandidate
  onChange?: any
  profileStore?: ProfileStore
  userStore?: UserStore
  notify?: Notify
}

class ProfilePersonalInfoViewModel {
  @observable firstName: string
  @observable lastName: string
  @observable email: string
  @observable phone: string
  @observable address: string
  @observable city: string
  @observable state: string
  @observable postalCode: string
  @observable country: string
  @observable availability: string
  @observable desiredRate: string

  constructor(profile: Profile) {
    makeObservable(this);
    const user = profile.user!
    this.firstName = user!.firstName
    this.lastName = user!.lastName
    this.email = user!.email
    this.phone = user!.phone
    this.address = user!.address
    this.city = user!.city
    this.state = user!.state
    this.country = user!.country ?? "US"
    this.postalCode = user!.postalCode
    this.availability = profile!.availability ?? ""
    this.desiredRate = profile!.desiredRate ? profile.desiredRate : ""
  }
}

@inject("userStore", "profileStore", "notify")
@observer
class ProfilePersonalInfo extends React.Component<WithStyles<typeof styles> & WithTheme & WithWidth & IProfilePersonalInfoProps> {
  @observable viewModel?: ProfilePersonalInfoViewModel
  @observable open: boolean = true
  @observable isFormOpen: boolean = false
  @observable isProcessing = false
  @observable showEmailChange: boolean = false
  @observable showPasswordChange: boolean = false

  instructions = "Your contact information is only made available to GovGig agents and employers when you apply for specific Job Posts in GovGig."


  private updateEmail = async () => {
    const { userStore, notify } = this.props
    const userId = userStore!.user && userStore!.user.id;

    const input: UpdateUserInput = {
      id: userId!,
      email: this.viewModel!.email.toLowerCase(),
    }

    await userStore!.updateUser(input)
      .catch((err: Error) => {
        notify!.show("error", "Unable to update user email")
      })
  }

  onChangeEmailOpen = () => {
    this.showEmailChange = true
    this.open = false
  }

  onEmailChange = (newEmail: string) => {
    this.viewModel!.email = newEmail
    this.showEmailChange = false
    this.open = true
    this.updateEmail()
  }

  onEmailChangeClose = () => {
    this.showEmailChange = false
    this.open = true
  }

  onChangePasswordOpen = () => {
    this.showPasswordChange = true
    this.open = false
  }

  onPasswordChangeClose = () => {
    this.showPasswordChange = false
    this.open = true
  }

  constructor(
    props: WithStyles<typeof styles> & WithTheme & WithWidth & IProfilePersonalInfoProps
  ) {
    super(props);
    makeObservable(this);
  }

  render() {
    const { width, profile, jobCandidate, onChange, userStore } = this.props

    const user = userStore!.user 
    if (!profile || !profile.user || !profile.getIsUnlocked(user, jobCandidate) || !userStore!.isProfileEditable(profile)) {
      return null
    }

    let editButton
    if (onChange) {
      editButton = <IconicButton onClick={this.handleEdit}><EditIcon /></IconicButton>
    }

    return (
      <ProfileCard
        title="Personal Information"
        button={editButton}
      >
        <Grid
          container
          direction={isWidthDown('xs', width) ? "column" : "row"}
          justifyContent="flex-start"
          alignItems="flex-start"
          spacing={3}
        >
          <Grid item xs={12}>
            <Typography variant="body1">
              {this.instructions}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            {this.renderStaticFields()}
          </Grid>
        </Grid>
        {this.isFormOpen && this.renderPersonalInfoForm()}
      </ProfileCard>
    )
  }

  renderStaticFields() {
    const { width, profile, userStore } = this.props

    // NOTE: The profile is used here rather than the viewModel because we don't want the
    // form changes to affect the static fields until the user presses Save

    const isEmployer = userStore!.isEmployer
    const user = profile!.user!
    const labelWidth = 120 

    return (
      <div>
        <Grid
          container
          spacing={isWidthDown('xs', width) ? 0 : 5}
        >
          <Grid item xs={12} sm={6}>
            <Grid container direction="column" justifyContent="flex-start">
              <CardValue label="First Name" labelWidth={labelWidth}>
                {user.firstName}
              </CardValue>
              <CardValue label="Last Name" labelWidth={labelWidth}>
                {user.lastName}
              </CardValue>
              <CardValue label="Email" labelWidth={labelWidth}>
                {user.email}
              </CardValue>
              <CardValue label="Phone" labelWidth={labelWidth}>
                {phoneToNationalFormat(user.phone)}
              </CardValue>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6}>
            <CardValue label="Availablity" labelWidth={labelWidth}>
              {profile!.availability ?? ""}
            </CardValue>
            <Visible if={!isEmployer}>
              <CardValue label="Desired Rate" labelWidth={labelWidth}>
                {profile!.desiredRate}
              </CardValue>
              <CardValue label="Address" labelWidth={labelWidth}>
                {user.address}
              </CardValue>
              <CardValue label="City, State, ZIP" labelWidth={labelWidth}>
                {user.city}, {user.state} {user.postalCode}
              </CardValue>
            </Visible>
          </Grid>
        </Grid>
      </div>
    )
  }

  renderPersonalInfoForm() {
    const { classes, /* width */ } = this.props
    const viewModel = this.viewModel!

    return (
      <React.Fragment>
        <Dialog
          open={this.open}
          onClose={this.handleClose}
          fullWidth
          maxWidth="sm"
        >
          <FormValidator onSubmit={this.onSubmit} autoComplete="off" name="personalInfoForm" id="personalInfoForm">
            <DialogTitle id="form-dialog-title">Personal Information</DialogTitle>
            <DialogContent>
              <DialogContentText>
                {this.instructions}
              </DialogContentText>
              <TextFieldValidator
                type="text"
                validators={{ required: true }}
                name="firstName"
                label="First Name"
                value={viewModel.firstName}
                onChange={this.handleChange}
                fullWidth
              />
              <TextFieldValidator
                type="text"
                validators={{ required: true }}
                name="lastName"
                label="Last Name"
                value={viewModel.lastName}
                onChange={this.handleChange}
                fullWidth
              />
              <TextFieldValidator
                type="text"
                validators={{ required: true, isEmail: null }}
                name="email"
                label="Email"
                value={viewModel.email}
                onChange={this.handleChange}
                fullWidth disabled
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconicButton onClick={this.onChangeEmailOpen}>
                        <EditIcon />
                      </IconicButton>
                    </InputAdornment>
                  ),
                }}
              />
              <TextFieldValidator
                type="password"
                label="Password"
                value="**********"
                fullWidth disabled
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconicButton onClick={this.onChangePasswordOpen}>
                        <EditIcon />
                      </IconicButton>
                    </InputAdornment>
                  ),
                }}
              />
              <TextFieldValidator
                type="text"
                validators={{ required: true, isMobilePhone: null }}
                name="phone"
                label="Phone"
                value={viewModel.phone}
                onChange={this.handleChange}
                fullWidth
              />
              <TextFieldValidator
                type="text"
                validators={{ required: false }}
                name="address"
                label="Address"
                value={viewModel.address}
                onChange={this.handleChange}
                fullWidth
              />
              <FormGroup row classes={{ row: classes.formGroupRow }}>
                <TextFieldValidator
                  type="text"
                  validators={{ required: true }}
                  name="city"
                  label="City"
                  value={viewModel.city}
                  onChange={this.handleChange}
                  className={classes.formGroupField}
                />
                <FormGroupSpacer />
                <StateSelector
                  value={viewModel.state}
                  onChange={this.handleChange}
                  className={classes.stateSelector}
                />
                <FormGroupSpacer />
                <TextFieldValidator
                  type="text"
                  validators={{ required: false, isPostalCode: null }}
                  name="postalCode"
                  label="ZIP"
                  value={viewModel.postalCode}
                  onChange={this.handleChange}
                  className={classes.postalCodeField}
                />
              </FormGroup>
              <FormGroup row classes={{ row: classes.formGroupRow }}>
                <SimpleSelect
                  className={classes.availabilitySelect}
                  fullWidth={true}
                  placeholderText="Availability to start work after hire"
                  selections={ProfileAvailabilityOptions}
                  value={viewModel.availability}
                  valueName={"availability"}
                  elementId={"availability"}
                  onChange={this.handleChange}
                />
                <FormGroupSpacer />
                <TextFieldValidator
                  type="text"
                  name="desiredRate"
                  label="Desired Hourly Rate or Annual Salary"
                  value={viewModel.desiredRate}
                  onChange={this.handleChange}
                  fullWidth
                  // required
                  helperText="This is only visible to GovGig agents"
                />
              </FormGroup>
            </DialogContent>
            <DialogActions>
              <CancelButton onClick={this.handleClose} />
              <ProgressButton variant="contained" size="medium" color="secondary"
                type="submit" className={classes.progressButton} processing={this.isProcessing}>
                Save
              </ProgressButton>
            </DialogActions>
          </FormValidator>
        </Dialog>
        {this.showEmailChange &&
          <EmailChangeDialog oldEmail={this.viewModel!.email} onClose={this.onEmailChangeClose} onChange={this.onEmailChange} />
        }
        {this.showPasswordChange &&
          <PasswordChangeDialog onClose={this.onPasswordChangeClose} />
        }
      </React.Fragment>
    )
  }

  handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const name = event.target.name
    this.viewModel![name] = event.target.value
  }

  handleEdit = (): void => {
    if (this.props.profile) {
      this.viewModel = new ProfilePersonalInfoViewModel(this.props.profile)
      this.isFormOpen = true
    }
  }

  handleClose = () => {
    this.isFormOpen = false
  }

  onSubmit = async () => {
    const { notify, profile, profileStore, userStore } = this.props
    const viewModel = this.viewModel!

    if (this.props.onChange === undefined) {
      this.handleClose()
      Logger.error("No onChange prop present")
      notify!.show("error", "Could not update profile personal info")
      return 
    }

    this.isProcessing = true

    let updatedUser: User | null 
    
    try {
      const updateUserInput: UpdateUserInput = {
        id: profile!.user!.id,
        firstName: viewModel.firstName,
        lastName: viewModel.lastName,
        email: viewModel.email,
        address: viewModel.address,
        city: viewModel.city,
        state: viewModel.state,
        postalCode: viewModel.postalCode,
        country: viewModel.country
      }
      updatedUser = await userStore!.updateUser(updateUserInput)
      if (!updatedUser) {
        throw new Error('Could not update user')
      }
    } catch (error) {
      this.isProcessing = false
      this.handleClose()
      const reason = 'Could not update user record'
      Logger.error(reason, error)
      notify!.show("error", reason)
      return 
    }
    
    let updatedProfile: Profile | undefined

    try {
      // Update profile after updating the user, since some profile fields depend on the user record. 
      // For example, the nickname and alias depend on the user's name. 
      const updateProfileInput: UpdateProfileInput = {
        id: profile!.id,
        nickname: updatedUser!.nickname,
        alias: profileStore!.generateAlias(updatedUser!),
        availability: viewModel.availability ?? null,
        desiredRate: viewModel.desiredRate,
        city: viewModel.city,
        state: viewModel.state,
        country: viewModel.country
      }
      updatedProfile = await profileStore!.updateProfile(updateProfileInput)
      if (!updatedProfile) {
        throw new Error('Could not update profile')
      }
    } catch (error) {
      this.isProcessing = false
      this.handleClose()
      const reason = 'Could not update profile record'
      Logger.error(reason, error)
      notify!.show("error", reason)
      return 
    }

    updatedProfile.user = updatedUser
    this.isProcessing = false
    this.handleClose()
    this.props.onChange(updatedProfile)
  }
}

export default withTheme(withStyles(styles)(withWidth()(ProfilePersonalInfo)))
