import { IRouteData } from "../../RoutesConfig"
import { 
  Box,
  Paper, 
  // Stack, 
  Typography,
  useMediaQuery,
  useTheme
} from "@material-ui/core"
import { blueGrey } from "@material-ui/core/colors"
import ActionButton from "../controls/ActionButton"
import Stack from "../Stack"

export interface IIndustry {
  name: string 
  // description: string 
  route?: IRouteData
  icon: React.ReactNode
  // imageSrc: string | StaticImageData
}

const IndustryCard = (industry: IIndustry) => {
  const { 
    name, 
    // description, 
    route,
    icon  
    // imageSrc 
  } = industry 

  const theme = useTheme()
  const isExtraSmall = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <Paper
      // sx={{
      //   // px: 2,
      //   // py: 1
      //   p: 0, 
      //   m: 0,
      //   width: "100%"
      // }}
    >
      <Stack
        direction="row"
        alignItems="center"
        spacing={2}
        sx={{
          width: "100%",
          px: 2 
        }}
      >
        <Box
          pl={3}
          pr={3}
          pt={3}
          pb={2}
          // mt={0.5}
          // sx={{
          //   color: theme => theme.palette.primary.main,
          //   backgroundColor: blueGrey[100],
          //   borderTopLeftRadius: theme => theme.spacing(1),
          //   borderBottomLeftRadius: theme => theme.spacing(1)
          // }}
          bgcolor={blueGrey[100]}
          borderRadius={10}
        >
          {icon}
        </Box>
        <Stack
          justifyContent={ isExtraSmall ? "flex-start" : "space-between" }
          spacing={2}
          direction={ isExtraSmall ? 'column' : 'row' }
          alignItems={ isExtraSmall ? 'flex-start' : 'center' }
          sx={{
            width: "100%",
            py: {
              xs: 2
            },
            pl: 0,
            pr: 2
          }}
        >
          {/* <Image src={imageSrc} />  */}
          <Typography 
            // pb={0.5}
          >
            {name}
          </Typography>
          {/* <Typography>
            {description}
          </Typography> */}
          { route && 
            <Box
              pr={2}
            >
              <ActionButton 
                showFowardArrow
                // route={route}
                size="small"
                tracking=""
                text={route.title}
                click={() => {

                }}
              /> 
            </Box>
          }
        </Stack>

      </Stack>
    </Paper>
  )
}

export default IndustryCard
