import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core'
import { red } from "@material-ui/core/colors"
import { makeObservable, observable } from 'mobx'
import { inject, observer } from 'mobx-react'
import React from 'react'
import AddButton from '../../components/AddButton'
import Confirm from '../../components/confirm/Confirm'
import Notify from '../../components/notify/Notify'
import Profile from '../../model/Profile'
import ProfileStore from '../../stores/ProfileStore'
import UserStore from '../../stores/UserStore'

const styles = (theme: Theme) => createStyles({
  root: {
    backgroundColor: red[500],
    '&:hover': {
      backgroundColor: red[700],
    },
  },
})

interface IProfileClearButton {
  userStore?: UserStore
  profileStore?: ProfileStore
  confirm?: Confirm
  notify?: Notify
  profile: Profile
  onChange?: (profile: Profile) => any
}

@inject('userStore', 'profileStore', 'notify', 'confirm')
@observer
class ProfileClearButton extends React.Component<WithStyles<typeof styles> & IProfileClearButton> {

  @observable isClearing: boolean = false

  constructor(props: any) {
    super(props)
    makeObservable(this)
  }

  // helpers

  handleClearProfile = async () => {
    const {
      profile,
      userStore,
      profileStore,
      notify,
      confirm,
      onChange,
    } = this.props

    confirm!.show(
      'Confirm Clear',
      'Are you sure that you want to clear your profile? All data will be lost.',
      ['Clear', 'Cancel'],
      async () => {
        this.isClearing = true
        try {
          await profileStore!.clearProfile(profile)
          const clearedProfile = await userStore!.getProfile(profile!.id)
          if (onChange) {
            onChange(clearedProfile!)
          }
          return true
        } catch (err: any) {
          notify!.show('error', `Couldn't clear profile: ${err.message}`)
          this.isClearing = false
          return false
        }
      }
    )
  }


  // render

  render() {
    const {
      classes,
    } = this.props

    return (
      <AddButton
        classes={{ button: classes.root }}
        icon="remove"
        text={ this.isClearing ? 'Clearing... ' : 'Clear Profile' }
        tracking="profileClear"
        buttonVariant="contained"
        click={this.handleClearProfile}
        disabled={this.isClearing}
      />
    )
  }
}

export default withStyles(styles)(ProfileClearButton)