import {
  addDays,
  addSeconds,
  differenceInCalendarDays,
  differenceInCalendarMonths, differenceInDays,
  format, isBefore, isSameDay,
  startOfToday
} from "date-fns";
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import { v4 as uuid } from 'uuid';

// UUID 

export function createUUID() {
  return uuid()
}

export function isUUID(s: string) {
  return /[0-9A-F]{8}-[0-9A-F]{4}-[4][0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}/i.test(s)
}

// Date & Time 

export function getISODateFromDate(date: Date): string {
  const adjDate = new Date(date)
  // Adjust by time zone offset to make sure the date string doesn't shift
  adjDate.setMinutes(-adjDate.getTimezoneOffset())
  return adjDate.toISOString().substr(0, 10)
}

export function getISODateFromDateString(date: string | null | undefined): string {
  if (date) {
    const adjDate = new Date(date)
    return adjDate.toISOString().substr(0, 10)
  } else {
    return ""
  }
}

export function getISODateTimeFromDate(date: Date): string {
  return date.toISOString()
}

export function getISODateToday(): string {
  const date = new Date()
  return getISODateFromDate(date)
}

export function getISODateTodayAddDays(days: number): string {
  let date = new Date()
  date = addDays(date, days)
  return getISODateFromDate(date)
}

export function getISODateTime(): string {
  const date = new Date()
  return date.toISOString()
}

export function durationBetweenISODates(isoStartDate: string, isoEndDate?: string): string {
  const startString = isoToLocalDateString(isoStartDate)
  const startDate = isoToLocalDate(isoStartDate)
  if (isoEndDate) {
    const endString = isoToLocalDateString(isoEndDate)
    const endDate = isoToLocalDate(isoEndDate)
    let differenceMonths = differenceInCalendarMonths(endDate, startDate)
    let differenceString 
    if (differenceMonths === 0) {
      let differenceDays = differenceInCalendarDays(endDate, startDate)
      if (differenceDays === 1) {
        differenceString = `1 Day`
      } else {
        differenceString = `${differenceDays} Days`
      }
    } else if (differenceMonths === 1) {
      differenceString = `1 Month`
    } else {
      differenceString = `${differenceMonths} Months`
    }
    return `${startString} - ${endString} (${differenceString})`
  } else {
    return `${startString}`
  }
}

export const DatePeriods: string[] = ["Immediately", "In 1-2 Weeks", "In 2-4 Weeks", "In 4 weeks or more"]

export function periodToISODate(period: string) {
  let date

  switch (period) {
    case DatePeriods[0]:
      date = getISODateToday()
      break
    case DatePeriods[1]:
      date = getISODateTodayAddDays(7)
      break
    case DatePeriods[2]:
      date = getISODateTodayAddDays(14)
      break
    case DatePeriods[3]:
      date = getISODateTodayAddDays(28)
      break
    default:
      date = period
      break
  }

  return date
}


export function isoDateToPeriod(isoDate: string): string | null {
  if (!isoDate) {
    return null 
  }
  
  const date = isoToLocalDate(isoDate)
  const today = startOfToday()
  const days = differenceInDays(date, today)
  
  let period
  
  if (isBefore(date, today) || days < 7) {
    // The hiring date has already passed, or is coming up quickly. 
    period = DatePeriods[0]
  } else if (days < 14) {
    period = DatePeriods[1]
  } else if (days < 28) {
    period = DatePeriods[2]
  } else if (days >= 28) {
    period = DatePeriods[3]
  } else {
    period = null
  }

  return period
}

export function isoDateToWhen(isoDate: string) {
  if (!isoDate) {
    return ""
  }

  const date = isoToLocalDate(isoDate)
  const today = startOfToday()
  const days = differenceInDays(date, today)

  let when
  if (isSameDay(date, today)) {
    when = `Today ${format(date, "h:mm aa")}`
  } else if (days <= 1) {
    when = `Yesterday ${format(date, "h:mm aa")}`
  } else if (days < 7) {
    when = format(date, "eeee h:mm aa")
  } else {
    when = format(date, "M/d/yyyy h:mm aa")
  }

  return when
}

// export function getExpireDate(minutes: number) : string {
//   const date = new Date()
//   const expireDate = addMinutes(date, minutes)
//   return getISODateTimeFromDate(expireDate)
// }
//
export function isoToLocalDate(isoDate: string) {
  const date = new Date(isoDate)
  if (isoDate && isoDate.length <= 10) {
    // Adjust for time zone if date only
    date.setMinutes(date.getTimezoneOffset()) // This should be automatic with UTC
  }
  return date
}

export function isoToLocalDateString(isoDate?: string, dateFormat: string = "MM/dd/yyyy") {
  if (isoDate) {
    const date = new Date(isoDate)
    if (isoDate.length <= 10) {
      // Adjust for time zone if date only
      date.setMinutes(date.getTimezoneOffset()) // This should be automatic with UTC
    }
    return format(date, dateFormat)
  } else {
    return ""
  }
}

export function isoToLocalDateTime(isoDateTime: string) {
  const date = new Date(isoDateTime)
  return date
}

export function secondsToDuration(seconds: number) {
  let result
  if (!seconds) {
    result = "0:00"
  } else {
    const time = addSeconds(new Date(0), seconds)
    if (seconds > 3600) {
      result = format(time, "h:m:ss")
    } else {
      result = format(time, "m:ss")
    }
  }
  return result
}

export function timestampToISOString(timestamp: number): string {
  if (timestamp) {
    const date = new Date(timestamp * 1000)
    return date.toISOString()
  } else {
    return ""
  }
}

export class DateRange {
  start: Date;
  end: Date;

  constructor(start: Date, end?: Date) {
    this.start = start
    this.end = end ?? new Date()
  }
}
export function mergeOverlappingDateRanges(
  dateRanges: DateRange[],
): DateRange[] {
  const sorted = dateRanges.sort(
    // By start, ascending
    (a, b) => a.start.getTime() - b.start.getTime(),
  );

  const ret = sorted.reduce((acc: DateRange[], curr: DateRange): DateRange[] => {
    // Skip the first range
    if (acc.length === 0) {
      return [curr];
    }

    const prev = acc.pop();

    if (curr.end <= prev!.end) {
      // Current range is completely inside previous
      return [...acc, prev!]
    }

    // Merges overlapping (<) and contiguous (==) ranges
    if (curr.start <= prev!.end) {
      // Current range overlaps previous
      return [...acc, { start: prev!.start, end: curr.end }];
    }

    // Ranges do not overlap
    return [...acc, prev!, curr]
  }, [] as DateRange[]);

  return ret;
}

// Error

export function getErrorMessage(err: any) {
  if (err.response && err.response.data && err.response.data.details && err.response.data.details.raw && err.response.data.details.raw.message) {
    return err.response.data.details.raw.message
  } else if (err.response && err.response.data && err.response.data.message) {
    return err.response.data.message
  } else if (err.message) {
    return err.message
  } else {
    return "Unknown error"
  }
}

// Phone 

export function phoneToE164Format(phone: string): string | null {
  const phoneNumber = parsePhoneNumberFromString(phone, "US")
  if (phoneNumber && phoneNumber.isValid()) {
    return phoneNumber.number.toString()
  } else {
    return null
  }
}

export function phoneToNationalFormat(phone: string): string {
  if (phone) {
    const phoneNumber = parsePhoneNumberFromString(phone, "US")
    if (phoneNumber && phoneNumber.isValid()) {
      return phoneNumber.formatNational()
    }
  }

  return phone
}

// Money 

export function numberToMoneyFormatOrLoading(input?: number): string {
  // return `${ input ? numeral(input).format('$0,0.00') : '...' }`
  return `${ input !== undefined ? numberToMoneyFormat(input) : '...' }`
}

export function numberToMoneyFormat(value: number, minimumFractionDigits: number = 2): string {
  // TODO: May want to use a library called "numeral". 
  // return `${ value ? numeral(value).format('$0,0.00') : '...' }`
  
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits
  })

  if (value === -0.00) {
    value = 0.00
  }
  return formatter.format(value)
}

export function moneyToNumberFormat(text: string, fractionDigits: number = 2): number {
  // TODO: May want to use a library called "numeral". 
  // numeral(text).value()

  let result = 0

  if (text) {
    const cleaned = text.replace(/[^\d.-]/g, '')
    const value = Number(cleaned)
    if (!isNaN(value)) {
      if (fractionDigits === 0) {
        result = Math.trunc(value)
      } else {
        result = Math.round(value * (10 ^ fractionDigits)) / (10 ^ fractionDigits)
      }
    }
  }

  return result
}

// String 

export function undefineEmptyStrings(map: object) {
  Object.keys(map).forEach(key => {
    const value = map[key]
    if (typeof value === "string" && value.length === 0) {
      map[key] = undefined
    }
  })
}

export function humanizeString(input: string): string {
  if (typeof input !== 'string') {
    throw new TypeError('Expected a string');
  }

  input = decamelize(input, ' ');
  input = titleCase(input)

  return input;
}

export function decamelize(text: string, separator: string = '_'): string {
  return text
    .replace(/([\p{Lowercase_Letter}\d])(\p{Uppercase_Letter})/gu, `$1${separator}$2`)
    .replace(/(\p{Uppercase_Letter}+)(\p{Uppercase_Letter}\p{Lowercase_Letter}+)/gu, `$1${separator}$2`)
    .toLowerCase();
}

export function titleCase(str: string): string {
  return str.toLowerCase().split(' ').map(function (word) {
    return (word.charAt(0).toUpperCase() + word.slice(1));
  }).join(' ');
}

export function getExt(filename: string) {
  return filename.substr(filename.lastIndexOf('.')+1)
}

export function charCount(s: string, c: string) {
  let count = 0;
  c = c.charAt(0);
  for(let i = 0; i < s.length; ++i) {
    if(c === s.charAt(i)) {
      ++count;
    }
  }
  return count;
}

// Truncate to the number of words to show before we show an ellipses.
export function truncateString(input: string, wordsLimit: number) {
  let truncated = input.split(" ").splice(0, wordsLimit).join(" ")
  truncated = `${truncated} ...` // Add ellipses to the end.
  return truncated 
}
