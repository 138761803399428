import ControlTower, { Routes } from '../ControlTower'
import ActionButton from './ActionButton'

const RequestMeetingButton = (props: any) => {
  return (
    <ActionButton
      {...props}
      showFowardArrow={props.showFowardArrow}
      text="Schedule a Meeting"
      click={() => {
        ControlTower.open(Routes.requestMeeting)
      }}
    />
  )
}

export default RequestMeetingButton
