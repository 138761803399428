import * as React from 'react'
import Page from '../components/page/Page'
import { RouteComponentProps } from '@reach/router'
import ControlTower, {Routes} from "../components/ControlTower";
import { observable, when, makeObservable } from "mobx";
import Logger from "../components/Logger";
import {inject, observer} from "mobx-react";
import UserStore from "../stores/UserStore";
import AccountStore from "../stores/AccountStore";
import Progress from "../components/Progress";
import { Theme, WithStyles, createStyles, withStyles } from '@material-ui/core';
import LoginDialog from '../components/login/LoginDialog';
import Tracking from '../components/Tracking';
import config from "react-global-configuration";
import PasswordAssistDialog from '../components/login/PasswordAssistDialog';

const styles = (theme: Theme) => createStyles({

})

enum LoginPageState {
  login = "login",
  passwordAssist = "passwordAssist"
}

interface ILoginPageProps {
  userStore?: UserStore
  accountStore?: AccountStore
  progress?: Progress
  route?: string
}

@inject("userStore", "accountStore", "progress")
@observer
class LoginPage extends React.Component<WithStyles<typeof styles> & RouteComponentProps & ILoginPageProps> {
  @observable isLoading = true

  @observable mode = LoginPageState.login

  @observable values = {
    username: "",
    password: "",
    newPassword: ""
  }

  @observable message = ""
  @observable newPasswordRequired = false
  @observable isProcessing = false

  constructor(props: WithStyles<typeof styles> & RouteComponentProps & ILoginPageProps) {
    super(props);
    makeObservable(this);
  }

  componentDidMount(): void {
    const { userStore, accountStore, location, progress } = this.props

    // TODO: This can probably be simplified now that authenticated user checking is in App.tsx
    when (
      () => userStore!.isLoading === false && userStore!.isAuthenticated === false,
      async () => {
        console.log("LoginPage looking for current authenticated user")
        // userStore!.isLoading = true
        this.isLoading = true
        const cognitoUser = await userStore!.currentAuthenticatedUser()
        if (cognitoUser) {
          progress!.show("LoginPage")
          console.log("initializing current user")
          // Load and initialize User
          await userStore!.initSession(cognitoUser)
            .then(async (result: any) => {
              console.log("Reloaded user from cache")
              const account = userStore!.user!.account
              if (account) {
                await accountStore!.init(account)
              }
              this.isLoading = false
              progress!.hide("HomePage")
            })
            .catch(async (reason: any) => {
              this.isLoading = false
              console.log("Unable to load user from cache")
              progress!.hide("LoginPage")
            })
        } else {
          console.log("No existing authenticated user found")
          // userStore!.isLoading = false
          this.isLoading = false
        }
        Logger.info("App started")
      }
    )

    when(
      () => userStore!.isLoading === false 
        && accountStore!.isLoading === false 
        && userStore!.isAuthenticated === true,
      async () => {
        console.log("LoginPage completed loading user")
        if (this.props.route) {
          console.log(`LoginPage routing to ${this.props.route}`)
          ControlTower.route(this.props.route)
        } else {
          console.log("LoginPage routing to initial page")
          userStore!.routeInitial()
        }
        
        progress!.hide("LoginPage")
        this.isLoading = false
      }
    )
  }

  onLogin = () => {
    const { userStore } = this.props 
  
    Tracking.event({category: 'LoginDialog', action: 'Login'})
    
    userStore!.routeInitial()
  }

  render() {
    const { classes } = this.props 
    return (
      <Page>
        { this.mode === LoginPageState.login && 
          <LoginDialog 
            embed 
            onLogin={this.onLogin} 
            onClose={undefined} 
            onShowPasswordAssist={() => {
              this.mode = LoginPageState.passwordAssist
              Tracking.pageView("PasswordAssistDialogEmbed")
            }} 
            onShowSignUp={() => {
              ControlTower.route(Routes.join)
            }}        
          />
        }
        { this.mode === LoginPageState.passwordAssist && 
          <PasswordAssistDialog
            embed 
            onClose={() => {
              this.mode = LoginPageState.login
            }}
            onShowLogin={() => {
              this.mode = LoginPageState.login
            }}
          />
        }
      </Page>
    )
  }
}

export default withStyles(styles)(LoginPage)