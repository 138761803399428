import * as React from 'react'
import {createStyles, Theme, withStyles, WithStyles, withTheme, WithTheme} from "@material-ui/core/styles";
import {RouteComponentProps} from "@reach/router";
import Page from "../../components/page/Page";
import MarginRow from "../../components/page/MarginRow";
import TermsOfUseUS from "./TermsOfUseUS";

const styles = (theme: Theme) => createStyles({

})

class TermsOfUsePage extends React.Component<WithStyles<typeof styles> & RouteComponentProps & WithTheme> {

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll)
  }
  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll)
  }

  handleScroll = (event: any) => {
    const bottom = Math.ceil(window.innerHeight + window.scrollY) >= document.documentElement.scrollHeight

    if (bottom) {
      if (window.location !== window.parent.location) {
        // In iframe
        window.parent.postMessage("at-bottom", '*');
      }
    }
  }

  render() {
    return (
      <Page title="Terms of Use" hideHeader={true} hideFooter={true}>
        <MarginRow>
          <TermsOfUseUS/>
        </MarginRow>
      </Page>
    )
  }
}

export default withTheme((withStyles(styles)(TermsOfUsePage)))

