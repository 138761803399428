import * as React from "react";
import {
  Box,
  createStyles,
  Grid,
  Paper,
  Theme,
  Typography,
  withStyles,
  WithStyles,
  withWidth,
  WithWidth
} from "@material-ui/core";
import IconicButton from "../../components/controls/IconicButton";
import EditIcon from "@material-ui/icons/Edit";
import JobPost from "../../model/JobPost";
import parseHTML from 'html-react-parser';
import { inject, observer } from "mobx-react";
import UserStore from "../../stores/UserStore";
import { makeObservable, observable, when } from "mobx";
import { grey } from "@material-ui/core/colors";

const styles = (theme: Theme) => createStyles({
  container: {
  },
  heading: {
    color: theme.palette.primary.main, 
    fontWeight: 'bold'
  }
})

interface IJobPostDescriptionCardProps {
  hideButtons?: boolean 
  jobPost: JobPost 
  elevation?: number 
  onEdit?(): void  
  truncate?: boolean // Only show 10 words of the discription, then truncate with ellipses (...). 

  userStore?: UserStore
}

@inject('userStore')
@observer
class JobPostDescriptionCard extends React.Component<WithStyles<typeof styles> & IJobPostDescriptionCardProps & WithWidth> {
  
  @observable isJobPostEditable: boolean = false
  
  constructor(props: any) {
    super(props)
    makeObservable(this)
  }

  componentDidMount() {
    const { jobPost, userStore } = this.props 
    when(
      // once 
      () => userStore!.isLoading === false || userStore!.isPublic === true,
      // then 
      () => {
        this.isJobPostEditable = userStore!.isJobPostEditable(jobPost)
      }
    )
  }

  render() {
    const { classes, elevation } = this.props
    return (
      <div className={classes.container}>
        <Paper elevation={ elevation !== undefined ? elevation : 3 }>
          <Box py={3} px={{ xs: 2, sm: 3 }} border={elevation !== undefined && elevation === 0 ? `1px solid ${grey[300]}` : 'none'} borderRadius={10}>
            <Grid container>
              <Grid item xs={12}>
                { this.renderHeading() }
              </Grid>
              <Grid item xs={12}>
                { this.renderBody() }
              </Grid>
            </Grid>
          </Box>
        </Paper>
      </div>
    )
  }

  renderHeading = () => {
    const { classes, hideButtons } = this.props
    return (
      <Grid container direction="row" justifyContent="space-between" wrap="nowrap">
        <Grid item xs={11}>
          <Typography gutterBottom variant="h4" className={classes.heading}>
            Job Description
          </Typography>
        </Grid>
        { hideButtons !== true && this.renderEditButton() }
      </Grid>
    )
  }

  renderBody = () => {
    const { jobPost, truncate } = this.props 

    let editMessage
    if (this.isJobPostEditable === true) {
      editMessage = <Typography>
        Placeholder for the full description of the job post.
      </Typography>
    } 

    let jobDescription
    if (jobPost.description && jobPost.description !== '') {
      let jobDescriptionHTML: string = jobPost.description

      if (truncate) {
        const parser = new DOMParser()
        const doc: HTMLDocument = parser.parseFromString(jobDescriptionHTML, 'text/html')
        const element: Element | null = doc!.firstElementChild
        if (element && element.textContent) {
          const wordsToShow = 10 // The number of words to show before we truncate. 
          let text = element.textContent.split(" ").splice(0, wordsToShow).join(" ")
          text = `${text}...` // Add ellipses to the end. 
          
          jobDescription = <Typography>
            {text}
          </Typography>
        }
      } else {
        jobDescription = <Typography component="div">
          { parseHTML(jobDescriptionHTML) }
        </Typography>
      }

    }

    return (
      <Box>
        <Grid container alignItems="flex-start">
          { jobDescription ? jobDescription : editMessage }
        </Grid>
      </Box>
    )
  }

  renderEditButton = () => {
    const { onEdit } = this.props 

    if (this.isJobPostEditable === false) {
      return null 
    }

    return (
      <Grid item>
        <IconicButton onClick={() => {
          if (onEdit) {
            onEdit()
          }
        }}>
          <EditIcon />
        </IconicButton>
      </Grid>
    )
  }
}

export default withStyles(styles)(withWidth()(JobPostDescriptionCard))
