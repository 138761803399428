import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles';
import { RouteComponentProps } from '@reach/router';
import { Auth } from 'aws-amplify';
import { observable, makeObservable } from "mobx";
import { inject, observer } from "mobx-react";
import * as React from 'react';
import CancelButton from '../../../components/form/CancelButton';
import DialogButton from '../../../components/form/DialogButton';
import FormValidator from '../../../components/form/FormValidator';
import TextFieldValidator from '../../../components/form/TextFieldValidator';
import Notify from '../../../components/notify/Notify';

const styles = (theme: Theme) => createStyles({
})

interface IPasswordChangeDialogProps {
  onClose: any
  notify?: Notify
}

@inject("notify")
@observer
class PasswordChangeDialog extends React.Component<WithStyles<typeof styles> & RouteComponentProps & IPasswordChangeDialogProps> {
  @observable values = {
    oldPassword: "",
    newPassword: ""
  }

  @observable message = ""

  onClose = () => {
    if (this.props.onClose) {
      this.message = ""
      this.props.onClose();
    }
  };

  onSubmit = () => {
    const { notify } = this.props

    this.message = ""

    Auth.currentAuthenticatedUser()
      .then(user => {
        Auth.changePassword(user, this.values.oldPassword, this.values.newPassword)
          .then(data => {
            notify!.show("success", "Password changed!")
            if (this.props.onClose) {
              this.message = ""
              this.props.onClose();
            }
          })
          .catch(err => {
            this.message = err.message
          });
      })
      .catch(err => {
        this.message = err.message
      });
  }

  onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const name = event.target.name
    this.values[name] = event.target.value
  }

  constructor(
    props: WithStyles<typeof styles> & RouteComponentProps & IPasswordChangeDialogProps
  ) {
    super(props);
    makeObservable(this);
  }

  render() {

    return (
      <Dialog
        id="passwordResetDialog"
        open={true}
        onClose={this.onClose}
        scroll="paper"
        maxWidth="xs"
        fullWidth
        aria-labelledby="password-change-dialog-title">
        <FormValidator onSubmit={this.onSubmit} autoComplete="off">
          <DialogTitle id="password-change-dialog-title">Change Password</DialogTitle>
          <DialogContent>
            <DialogContentText color="error">
              {this.message}
            </DialogContentText>
            <TextFieldValidator
              autoFocus
              margin="dense"
              name="oldPassword"
              label="Old Password"
              type="password"
              validators={{ required: true }}
              value={this.values.oldPassword}
              onChange={this.onChange}
              fullWidth
            />
            <TextFieldValidator
              margin="dense"
              name="newPassword"
              label="New Password"
              type="password"
              validators={{ required: true, isStrongPassword: 3 }}
              value={this.values.newPassword}
              onChange={this.onChange}
              fullWidth
            />
          </DialogContent>
          <DialogActions>
            <CancelButton onClick={this.onClose} />
            <DialogButton variant="primary">
              Save
            </DialogButton>
          </DialogActions>
        </FormValidator>
      </Dialog>
    );
  }
}

export default withStyles(styles)(PasswordChangeDialog)