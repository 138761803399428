import * as React from "react";
import {
  Box,
  createStyles,
  Grid,
  Paper,
  Theme,
  Typography,
  withStyles,
  WithStyles,
  withTheme,
  WithTheme
} from "@material-ui/core";
import { RouteComponentProps } from "@reach/router";
import JobPost from "../../model/JobPost";
import ProfileHeroImage from "../../images/Profile_Hero_Image_New.jpg";
import JobInterest from "../../model/JobInterest";
import JobPostButtons from "../../components/jobPosts/JobPostButtons";
import JobPostInfo from "../../components/jobPosts/JobPostInfo";

const styles = (theme: Theme) => createStyles({
  panel: {
    paddingTop: theme.spacing(10),
    paddingBottom: theme.spacing(2),
    background: `darkblue url(${ProfileHeroImage}) no-repeat center center`,
    backgroundSize: 'cover',
    color: theme.palette.common.white,
    borderRadius: '10px 10px 0px 0px',
    [theme.breakpoints.down('xs')]: {
      paddingRight: theme.spacing(2),
      paddingLeft: theme.spacing(2),
    },
    [theme.breakpoints.up('sm')]: {
      paddingRight: theme.spacing(3),
      paddingLeft: theme.spacing(3),
    }
  }
})

interface IJobPostHeroProps {
  hideButtons?: boolean 
  jobPost: JobPost
  jobInterest?: JobInterest
  onEdit?(jobPost: JobPost): void
  onApply?(jobPost: JobPost): void
  onSave?(jobPost: JobPost): void
}

class JobPostHero extends React.Component<WithStyles<typeof styles> & RouteComponentProps & IJobPostHeroProps & WithTheme> {
  
  render() {
    const { classes, jobInterest, jobPost, hideButtons } = this.props 

    return (
      <Paper elevation={1}>
        <div className={classes.panel}>
          <Grid container justifyContent="space-between" alignItems="flex-end" spacing={2}>
            <Grid item>
              <Grid container direction="column">
                <Grid item component={Typography} gutterBottom variant="h2" color="inherit">
                  {jobPost.title}{jobPost.openings > 1 && ` [${jobPost.openings}]`}
                </Grid>
              </Grid>
            </Grid>
            { hideButtons !== true && 
              <Grid item>
                <JobPostButtons 
                  jobPost={jobPost}
                  jobInterest={jobInterest}
                  onApply={this.props.onApply}
                  onEdit={this.props.onEdit}
                  onSave={this.props.onSave}
                />
              </Grid>
            }
          </Grid>
        </div>
        <Box pt={1} pb={2} px={{ xs: 2, sm: 3 }} borderRadius="0px 0px 10px 10px">
          <JobPostInfo 
            jobPost={jobPost}
          />
        </Box>
      </Paper>
    )
  }
}

export default withTheme(withStyles(styles)(JobPostHero))
