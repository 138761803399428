import { Box, LinearProgress } from "@material-ui/core"

const DialogLoadingProgress = ({
  isLoading 
}: {
  isLoading: boolean 
}) => {
  return (
    <Box 
      sx={{ 
        flexGrow: 1, 
        height: 3 
      }}
    >
      { isLoading && 
        <LinearProgress 
          style={{ 
            height: 3, 
            backgroundColor: "#d72222" 
          }}
          // TODO: MUI 5.
          // sx={{
          //   height: 3,
          //   backgroundColor: "#d72222" // Logo red
          // }}
        />
      }
    </Box>
  )
}

export default DialogLoadingProgress
