const AppConfig = {
  env: process.env.REACT_APP_ENV,
  nodeEnv: process.env.NODE_ENV,
  version: process.env.REACT_APP_VERSION,
  commitId: process.env.REACT_APP_COMMIT_ID,
  build: process.env.REACT_APP_JOB_ID,
  stripe: {
    apiKey: process.env.REACT_APP_STRIPE_KEY
  },
  rollbar: {
    accessToken: process.env.REACT_APP_ROLLBAR_ACCESS_TOKEN
  },
  google: {
    trackingId: process.env.REACT_APP_GOOGLE_TRACKING_ID,
    options: {
    }
  },
  tawkTo: {
    url: process.env.REACT_APP_TAWK_TO_URL
  },
  checkBuildInterval: 900000, // 15 minutes
  branch: process.env.REACT_APP_BRANCH,
  affinda: {
    apiToken: process.env.REACT_APP_AFFINDA_API_TOKEN
  },
  tinymce: {
    apiKey: process.env.REACT_APP_TINYMCE_API_KEY
  },
  domain: process.env.REACT_APP_DOMAIN ?? "govgig.us",
  academyUrl: process.env.REACT_APP_ACADEMY_URL,
  homeUrl: process.env.REACT_APP_HOME_URL,
  newsUrl: process.env.REACT_APP_NEWS_URL,
  couponPromo: process.env.REACT_APP_COUPON_PROMO
};

export default AppConfig;