/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Box,
  createStyles,
  Grid, 
  Paper,
  Theme, 
  Typography,
  WithStyles, 
  withStyles
} from "@material-ui/core";
import ProgressButton from "../../components/form/ProgressButton";
import {getErrorMessage, numberToMoneyFormat} from "../../stores/StoreUtilities";
import { 
  CardNumberElement,
  CardExpiryElement,
  CardCVCElement,
  injectStripe, 
  ReactStripeElements
} from 'react-stripe-elements'
import React from "react";
import {inject} from "mobx-react";
import Alert from "../../components/alert/Alert";
import AccountStore from "../../stores/AccountStore";
import UserStore from "../../stores/UserStore";
import {SubjectType} from "../../API";
import {ActivityType} from "../../model/UserActivity";

const styles = (theme: Theme) => createStyles({
  content: {
    width: '100%'
  },
  subtitle: {
    paddingBottom: 10,
  },
  textField: {
    // color: theme.palette.text.primary,
    width: '100%',
    border: 'none',
    borderBottomWidth: 1,
    borderBottomStyle: "solid",
    borderBottomColor: theme.palette.text.secondary,
    fontSize: '18px',
    padding: 5
    // backgroundColor: "transparent"
  },
  paymentField: {
    // color: theme.palette.text.primary,
    // fontSize: 16,
    // fontWeight: 400,
    // borderBottom: '1px solid rgba(0,0,0,0.54)',
    borderBottomWidth: 1,
    borderBottomStyle: "solid",
    borderBottomColor: theme.palette.text.secondary,
    padding: 5
  },
  fieldLabel: {
    fontSize: 12,
    fontWeight: 400,
    // fontFamily: theme.typography.fontFamily,
    color: theme.palette.text.secondary
  },
  label: {
    color: theme.palette.text.secondary,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  fieldSet: {
    // paddingTop: theme.spacing(1),
    // paddingBottom: theme.spacing(1)
  },
  fieldCheckbox: {
    // paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(2)
  },
  buttonSet: {
    justifyContent: "center",
    width: "100%",
    padding: "20px 0 0 0",
    marginTop: 5,
    height: 49,
    textAlign: "center"
  },
  progressButton: {
    color: theme.palette.primary.main,
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  dialogActions: {
    justifyContent: "center",
  },

})

// const CARD_OPTIONS = {
//   iconStyle: 'solid',
//   style: {
//     base: {
//       iconColor: '#c4f0ff',
//       color: '#fff',
//       fontWeight: 500,
//       fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
//       fontSize: '16px',
//       fontSmoothing: 'antialiased',
//       ':-webkit-autofill': {
//         color: '#fce883',
//       },
//       '::placeholder': {
//         color: '#87BBFD',
//       },
//     },
//     invalid: {
//       iconColor: '#FFC7EE',
//       color: '#FFC7EE',
//     },
//   },
// };

interface IPaymentForm {
  stripe?: any 
  amountDue?: number
  source?: any
  isLoading: boolean 
  confirmSubmit?(): boolean
  onPayment?(token: any): void
  onCancel?(): void

  alert?: Alert
  accountStore?: AccountStore
  userStore?: UserStore
}

@inject("alert", "accountStore", "userStore")
class StripePaymentForm extends React.Component<WithStyles<typeof styles> & IPaymentForm & ReactStripeElements.InjectedStripeProps> {
  
  state = {
    name: undefined,
    source: undefined,
    useSource: "new",
    isPaying: false
  }

  componentDidMount() {
    const {
      amountDue, 
      source
    } = this.props 

    if (amountDue === undefined || amountDue === 0) {
      this.setState({useSource: "none"})
    } else if (source) {
      this.setState({source: source, useSource: "existing"})
    }
  }

  componentDidUpdate(prevProps: any) {
    const { source, amountDue } = this.props

    if (amountDue !== prevProps.amountDue) {
      if (amountDue === undefined || amountDue === 0) {
        this.setState({useSource: "none"})
      } else if (source) {
        this.setState({source: source, useSource: "existing"})
      } else {
        this.setState({useSource: "new"})
      }
    }
  }

  handleSubmit = async (ev: any) => {
    const { userStore } = this.props
    ev.preventDefault();
    
    this.setState({isPaying: true})
    
    if (this.state.useSource === "new") {
      // Within the context of `Elements`, this call to createToken knows which Element to
      // tokenize, since there's only one in this group.
      try {
        this.props.stripe.createToken({name: this.state.name}).then(async (response: any) => {
          // console.log('stripe.createToken response:', response);
          if (response.token) {
            if (this.props.onPayment) {
              await this.props.onPayment(response.token)
              this.setState({isPaying: false})
            }
          } else if (response.error) {
            this.setState({isPaying: false})
            this.props.alert!.show("Error", response.error.message)
            userStore!.logUserActivity(userStore!.user!.id, SubjectType.Invoice, "NA", ActivityType.Error,
              `Create token error: ${response.error.message}`)
          }
        });
      } catch (err) {
        userStore!.logUserActivity(userStore!.user!.id, SubjectType.Invoice, "NA", ActivityType.Error,
          `Create token error: ${getErrorMessage(err)}`)
        this.setState({isPaying: false})
      }
    } else {
      // Pay with card on file or none due
      if (this.props.onPayment) {
        await this.props.onPayment(null)
        this.setState({isPaying: false})
      }
    }
  };

  handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const name = event.target.name
    this.setState({
      [name]: event.target.value
    })
  };

  onCancel = (event: any) => {
    if (this.props.onCancel) {
      this.props.onCancel()
    }
  }

  renderCreditCardForm() {
    const { classes, stripe } = this.props

    return (
      <Paper>
        <Box p={2}>
          <Typography variant="h6" gutterBottom color="primary">Enter Your Payment Info</Typography>
          <form onSubmit={this.handleSubmit}>
            {/* <PaymentElement /> */}
            {/* <CardElement /> */}
            {/* <button disabled={!stripe}>Submit</button> */}
            <Grid container className={classes.content} spacing={2}>
              <Grid item xs={12} className={classes.fieldSet}>
                <label className={classes.fieldLabel}>
                  Name on Card
                  <input 
                    type="text" 
                    name="name" 
                    placeholder="Enter the name on your card"
                    className={classes.textField} 
                    onChange={this.handleChange} 
                  />
                </label>
              </Grid>
              <Grid item xs={12} className={classes.fieldSet}>
                <label className={classes.fieldLabel}>
                  Card Number
                  <CardNumberElement 
                    // options={{ style: elementStyle }} 
                    className={classes.paymentField} 
                  />
                </label>
              </Grid>
              <Grid item xs={6} className={classes.fieldSet}>
                <label className={classes.fieldLabel}>
                  Expiration
                  <CardExpiryElement 
                    // options={{ style: elementStyle }} 
                    className={classes.paymentField} 
                  />
                </label>
              </Grid>
              <Grid item xs={6} className={classes.fieldSet}>
                <label className={classes.fieldLabel}>
                  CVC
                  <CardCVCElement
                    className={classes.paymentField} 
                  />
                </label>
              </Grid>
              <Grid item xs={12}>
                <Grid container direction="row" justifyContent="flex-end">
                  <Grid item> 
                    <ProgressButton 
                      variant="contained" 
                      color="secondary"
                      type="submit" 
                      processing={this.state.isPaying}
                      onClick={this.handleSubmit}
                      disabled={!stripe || this.props.amountDue === undefined || this.state.isPaying} 
                    >
                      {`Purchase for ${ this.props.amountDue ? numberToMoneyFormat(this.props.amountDue) : '...' }`} 
                    </ProgressButton>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </form>
        </Box>
      </Paper>
    )
  }

  renderNoAmountDue() {
    const { stripe } = this.props 

    return (
      <Paper>
        <Box p={2}>
          <Typography variant="h6" gutterBottom color="primary">Complete Checkout</Typography>
          <Typography gutterBottom>No payment due. Press "Finish" below to complete the process.</Typography>
          <Box
            sx={{
              pt: 1
            }}
          >
            <ProgressButton
              // type="submit"
              variant="contained"
              color="secondary"
              processing={this.state.isPaying}
              disabled={!stripe || this.props.amountDue === undefined || this.state.isPaying} 
              onClick={this.handleSubmit}
              //   async () => {
              //   this.setState({isPaying: true})
              //   if (this.props.onPayment) {
              //     await this.props.onPayment(undefined)
              //   }
              //   this.setState({isPaying: false})
              // }
            >
              Finish 
            </ProgressButton>
          </Box>
        </Box>
      </Paper>
    )
  }

  render() {
    const { amountDue } = this.props 

    if (amountDue === undefined) {
      return null 
    }
    return (
      <React.Fragment>
        { amountDue === 0 ? 
            this.renderNoAmountDue()
          :
            this.renderCreditCardForm() 
        }
      </React.Fragment>
    )
  }
}

export default withStyles(styles)(injectStripe(StripePaymentForm));
