/* eslint-disable @typescript-eslint/no-unused-vars */
import GovGigAPI from "../apis/GovGigAPI";
import OpportunitiesAPI from "../apis/OpportunitiesAPI";
import Location from "../model/Location";
import {ServiceGroup} from "../model/ServiceGroup";
import Service from "../model/Service";
import Profile from "../model/Profile";
import ProfileLocation from "../model/ProfileLocation";
import ProfileService from "../model/ProfileService";
import Logger from "../components/Logger";
import {makeObservable, observable} from "mobx";
import {CreateCertificationTypeInput, CreateLocationInput, CreateServiceInput} from "../API";
import Geohash from "latlon-geohash";
import CertificationType from "../model/CertificationType";
import Agency from "../model/opportunity/Agency";
import Naics from "../model/opportunity/Naics";
import PcsCategory from "../model/opportunity/PcsCategory";

export default class ResourceCache {
  @observable isLoading: boolean = false
  govGigAPI: GovGigAPI
  opportunitiesAPI: OpportunitiesAPI
  locations: Location[] = []
  locationMap = {}
  locationOptions: LocationOption[] = []
  serviceGroups: ServiceGroup[] = []
  services: Service[] = []
  serviceMap = {}
  serviceOptions: ServiceOption[] = []
  certificationTypes: CertificationType[] = []
  agencyTypes: Agency[] = []
  naicsTypes: Naics[] = []
  pscCategories: PcsCategory[] = []

  constructor(options: any) {
    this.govGigAPI = (options && options.govGigAPI) ? options.govGigAPI : null
    makeObservable(this);
    this.opportunitiesAPI = (options && options.opportunitiesAPI) ? options.opportunitiesAPI : null
  }

  async init() {
    if (!this.isLoading) {
      this.isLoading = true

      const promises : Promise<any>[] = []
      promises.push(this.listLocations())
      promises.push(this.listServiceGroups())
      await Promise.all(promises)
      this.getLocationOptions()
      this.getServiceOptions()
      this.listCertificationTypes()
      this.isLoading = false
      Logger.debug("ResourceCache initialized")
    }
  }

  listLocations = async () => {

    if (this.locations.length === 0) {
      let locations: Location[] = []
      let data
      let nextToken: string | undefined

      do {
        data = await this.govGigAPI.listLocations(undefined, 1000, nextToken)
        if (data && data.items) {
          data.items.forEach((item: any) => {
            locations.push(new Location(item))
          })
          nextToken = data.nextToken ?? undefined
        }
      } while (data && nextToken)
      this.locations = locations
      
      // Build locationMap
      this.locationMap = {}
      this.loadLocationMap(this.locations)
    }

    return this.locations
  }

  loadLocationMap = (locations: Location[]) => {
    locations.forEach((location: Location) => {
      this.locationMap[location.id] = location
    })
  }

  getLocation = (id: string, create: boolean = false): Location | undefined => {
    let location = this.locationMap[id]
    if (!location && create) {
      // Create a location from the geohash
      const point = Geohash.decode(id)
      location = new Location({
        id: id,
        active: false,
        organizationName: "Geohash",
        name: `${point.lat}, ${point.lon}`,
        region: 'Somewhere',
        latitude: point.lat,
        longitude: point.lon
      })
      this.addLocation(location)
    }
    return location
  }

  addLocation = (location: Location) => {
    if (!this.locationMap[location.id]) {
      this.locationMap[location.id] = location
      this.locations.push(location)
    } else {
      this.locationMap[location.id] = location
      const index = this.locations.findIndex((l: Location) => l.id === location.id)
      if (index >= 0) {
        this.locations[index] = location
      }
    }

    if (!this.getLocationOption(location.id)) {
      // Add Location Option
      const locationOption = new LocationOption(location.id, location.name, location.region ?? location.country)
      this.locationOptions.push(locationOption)
    }
  }

  createLocation = async (input: CreateLocationInput) => {
    const data = await this.govGigAPI.createLocation(input)
    if (data) {
      Logger.info(`Created Location: ${input.name}`)
      const location = new Location(data)
      this.addLocation(location)
      return location
    } else {
      return undefined
    }
  }

  activateLocation = async (locationId: string | undefined | null) => {
    // This creates an active Location from a temporary (!active) one.
    if (!locationId) {
      return undefined
    }
    const location = this.getLocation(locationId)
    if (location && !location.active) {
      const input: CreateLocationInput = {
        ...location,
        active: true
      }
      return await this.createLocation(input)
    } else {
      return location
    }
  }


  /**
   * Load the Location objects for each ProfileLocation in the Profile.
   * @param profile The profile object. 
   */
  loadLocationsForProfile = (profile: Profile) => {
    if (profile.profileLocations && profile.profileLocations.length > 0) {
      profile.profileLocations.forEach((profileLocation: ProfileLocation) => {
        profileLocation.location = this.locationMap[profileLocation.locationId]
      })

      profile!.profileLocations.sort((a: ProfileLocation, b:ProfileLocation) => {
        if (a.location && b.location) {
          const aState = a.location.state ?? a.location.country
          const bState = b.location.state ?? b.location.country
          if (aState !== bState) {
            return aState.localeCompare(bState)
          } else {
            return a.location.name.localeCompare(b.location.name)
          }
        } else {
          return 0
        }
      })
    }
  }

  getLocationOptions() {
    if (this.locationOptions.length === 0) {
      const locationOptions: LocationOption[] = []

      this.locations.forEach((location: Location) => {
        if (location.active) {
          // const group = location.organizationName === "Places" ? "Places" : location.state ?? location.country ?? ""
          const group = location.state ?? location.country ?? ""
          locationOptions.push(new LocationOption(location.id, location.name, group))
          location.aliases.forEach((alias: string) => {
            locationOptions.push(new LocationOption(location.id, alias, group))
          })
        }
      })

      locationOptions.sort((a: LocationOption, b: LocationOption) => {
        const groupCompare = a.group.localeCompare(b.group)
        return (groupCompare === 0) ? a.name.localeCompare(b.name) : groupCompare
      })

      this.locationOptions = locationOptions
    }

    return this.locationOptions
  }

  getLocationOption(locationId: string) {
    return this.locationOptions.find((option: LocationOption) => option.id === locationId)
  }

  matchLocationOptions(text: string, exact: boolean = false): LocationOption[] {
    let options: LocationOption[] = []

    if (text && text.length > 0) {
      const searchFor = text.toLocaleLowerCase()

      this.locationOptions.forEach((option: LocationOption) => {
        if (option.name &&
           ((exact && option.name.toLocaleLowerCase() === searchFor) ||
            (!exact && option.name.toLocaleLowerCase().indexOf(searchFor) >= 0))) {
          options.push(option)
        }
      })
    }

    return options
  }

  // ServiceGroup / Service methods

  listServiceGroups = async (): Promise<ServiceGroup[] | undefined> => {
    if (this.serviceGroups.length === 0) {
      const serviceGroupData = await this.govGigAPI.listServiceGroups()
      if (serviceGroupData && serviceGroupData.items) {
        this.serviceGroups = serviceGroupData.items.map((item: any) => new ServiceGroup(item))
        this.serviceGroups.sort((a: ServiceGroup, b: ServiceGroup) => a.name.localeCompare(b.name))

        // Build serviceMap
        this.serviceMap = {}
        this.serviceGroups.forEach((group: ServiceGroup) => {
          group.services.forEach((service: Service) => {
            this.serviceMap[service.id] = service
            this.services.push(service)
          })
          group.services.sort((a: Service, b: Service) => a.name.localeCompare(b.name))
        })

        this.services.sort((a: Service, b: Service) => a.name.localeCompare(b.name))
        Logger.info(`Loaded ${this.services.length} Services`)
      }
    }

    return this.serviceGroups
  }

  getServiceGroups = (industry?: string): ServiceGroup[] => {
    if (!industry) {
      return [...this.serviceGroups]
    } else {
      return this.serviceGroups.filter((group: ServiceGroup) => group.industries.indexOf(industry) >= 0)
    }
  }

  getServices = (): Service[] => {
    return this.services
  }

  getServiceGroup = (id: string): ServiceGroup | undefined => {
    return this.serviceGroups.find((serviceGroup: ServiceGroup) => serviceGroup.id === id)
  }

  getService = (id: string): Service | undefined => {
    return this.serviceMap[id]
  }
  
  addService = (service: Service) => {
    if (!service.serviceGroupId) {
      service.serviceGroupId = 'other'
    }
    if (!service.serviceGroup) {
      service.serviceGroup = this.getServiceGroup(service.serviceGroupId) ?? this.getServiceGroup('other')
    }

    if (service.serviceGroup && !service.serviceGroup.services.find((s: Service) => s.id === service.id)) {
      // Add the service to the service group
      service.serviceGroup.services.push(service)
      service.serviceGroup.services.sort((a: Service, b: Service) => a.name.localeCompare(b.name))
    }

    if (!this.serviceMap[service.id]) {
      this.serviceMap[service.id] = service
      this.services.push(service)
    } else {
      this.serviceMap[service.id] = service
      const index = this.services.findIndex((l: Service) => l.id === service.id)
      if (index >= 0) {
        this.services[index] = service
      }
    }

    // Clear ServiceOptions to repopulate when requested next
    this.serviceOptions = []
    this.getServiceOptions()

    return service
  }

  createService = async (input: CreateServiceInput) => {
    const data = await this.govGigAPI.createService(input)
    if (data) {
      Logger.info(`Created Service: ${input.name}`)
      const service = new Service(data)
      this.addService(service)
      return service
    } else {
      return undefined
    }
  }

  activateService = async (serviceId: string | undefined | null) => {
    // This creates an active Service from a temporary (!active) one.
    if (!serviceId) {
      return undefined
    }
    let service = this.getService(serviceId)
    if (service && !service.active) {
      const input: CreateServiceInput = {
        id: service.id,
        name: service.name,
        active: true,
        serviceGroupId: service.serviceGroupId,
        industries: service.industries
      }
      service = await this.createService(input)
    } else {
      return service
    }
  }

  /**
   * For a given profile, load the Service object for each ProfileService. 
   * @param profile The profile object. 
   */
  loadServicesForProfile = (profile: Profile) => {
    if (profile.profileServices && profile.profileServices.length > 0) {
      profile.profileServices.forEach((profileService: ProfileService) => {
        profileService.service = this.serviceMap[profileService.serviceId]
      })
      profile!.profileServices.sort((a: ProfileService, b:ProfileService) => {
        return (a.service && b.service) ? a.service.name!.localeCompare(b.service.name!) : 0
      })
    }
  }

 
  getServiceOptions(industry?: string) {
    if (this.serviceOptions.length === 0) {
      const serviceOptions: ServiceOption[] = []

      this.serviceGroups.forEach((group: ServiceGroup) => {
        group.services.forEach((service: Service) => {
          serviceOptions.push(new ServiceOption(service.id, service.name, group.name, service.industries))
        })
      })

      serviceOptions.sort((a: ServiceOption, b: ServiceOption) => {
        const groupCompare = a.group.localeCompare(b.group)
        return (groupCompare === 0) ? a.name.localeCompare(b.name) : groupCompare
      })
      this.serviceOptions = serviceOptions
    }

    if (!industry) {
      return this.serviceOptions
    } else {
      return this.serviceOptions.filter((option: ServiceOption) => option.industries.includes(industry))
    }
  }

  getServiceOption(serviceId: string) {
    return this.serviceOptions.find((option: ServiceOption) => option.id === serviceId)
  }

  getServiceOptionByName(name: string) {
    const value = name.toLowerCase()
    return this.serviceOptions.find((option: ServiceOption) => option.name.toLowerCase() === value)
  }

  getServiceGroupByName(name: string) {
    const value = name.toLowerCase()
    return this.serviceGroups.find((group: ServiceGroup) => group.name.toLowerCase() === value)
  }
  
  // CertificationTypes

  listCertificationTypes = async () => {
    if (this.certificationTypes.length === 0) {
      const data = await this.govGigAPI.listCertificationTypes()
      if (data && data.items) {
        this.certificationTypes = data.items.map((item: any) => new CertificationType(item))
        this.certificationTypes.sort((a: CertificationType, b: CertificationType) => a.name.localeCompare(b.name))
      }
    }

    return this.certificationTypes
  }

  getCertificationTypes(industry?: string) {
    if (!industry) {
      return this.certificationTypes
    } else {
      return this.certificationTypes.filter((option: CertificationType) => option.industries.includes(industry))
    }
  }

  getCertificationTypeByName(name: string) {
    return this.certificationTypes.find((item: CertificationType) => item.name === name)
  }
  
  getCertificationType(id: string) {
    return this.certificationTypes.find((item: CertificationType) => item.id === id)
  }

  addCertificationType = (certificationType: CertificationType) => {
    const index = this.certificationTypes.findIndex((l: CertificationType) => l.id === certificationType.id)
    if (index >= 0) {
      this.certificationTypes[index] = certificationType
    } else {
      this.certificationTypes.push(certificationType)
    }

    return certificationType
  }

  createCertificationType = async (input: CreateCertificationTypeInput) => {
    const data = await this.govGigAPI.createCertificationType(input)
    if (data) {
      const certificationType = new CertificationType(data)
      this.addCertificationType(certificationType)
      return certificationType
    } else {
      return undefined
    }
  }

  activateCertificationType = async (certificationTypeId: string | undefined | null) => {
    // This creates an active CertificationType from a temporary (!active) one.
    if (!certificationTypeId) {
      return undefined
    }
    let certificationType = this.getCertificationType(certificationTypeId)
    if (certificationType && !certificationType.active) {
      const input: CreateCertificationTypeInput = {
        id: certificationType.id,
        name: certificationType.name,
        active: true,
        industries: certificationType.industries
      }
      certificationType = await this.createCertificationType(input)
    } else {
      return certificationType
    }
  }

  listSecurityClearances(): string[] {
    return [
      "Top Secret/SCI/ESP",
      "Top Secret/SCI",
      "Top Secret",
      "Secret",
      "Confidential",
      "Public Trust",
      "DOE Q or L"
    ]
  }

  // State & Country functions

  stateAbbrs = {"AZ":"Arizona","AL":"Alabama","AK":"Alaska","AR":"Arkansas","CA":"California","CO":"Colorado","CT":"Connecticut","DC":"District of Columbia","DE":"Delaware","FL":"Florida","GA":"Georgia","HI":"Hawaii","ID":"Idaho","IL":"Illinois","IN":"Indiana","IA":"Iowa","KS":"Kansas","KY":"Kentucky","LA":"Louisiana","ME":"Maine","MD":"Maryland","MA":"Massachusetts","MI":"Michigan","MN":"Minnesota","MS":"Mississippi","MO":"Missouri","MT":"Montana","NE":"Nebraska","NV":"Nevada","NH":"New Hampshire","NJ":"New Jersey","NM":"New Mexico","NY":"New York","NC":"North Carolina","ND":"North Dakota","OH":"Ohio","OK":"Oklahoma","OR":"Oregon","PA":"Pennsylvania","RI":"Rhode Island","SC":"South Carolina","SD":"South Dakota","TN":"Tennessee","TX":"Texas","UT":"Utah","VT":"Vermont","VA":"Virginia","WA":"Washington","WV":"West Virginia","WI":"Wisconsin","WY":"Wyoming","AB":"Alberta","BC":"British Columbia","MB":"Manitoba","NB":"New Brunswick","NF":"Newfoundland","NT":"Northwest Territory","NS":"Nova Scotia","NU":"Nunavut","ON":"Ontario","PE":"Prince Edward Island","QC":"Quebec","SK":"Saskatchewan","YT":"Yukon"};

  stateNames = {"arizona":"AZ","alabama":"AL","alaska":"AK","arkansas":"AR","california":"CA","colorado":"CO","connecticut":"CT","districtofcolumbia":"DC","delaware":"DE","florida":"FL","georgia":"GA","hawaii":"HI","idaho":"ID","illinois":"IL","indiana":"IN","iowa":"IA","kansas":"KS","kentucky":"KY","louisiana":"LA","maine":"ME","maryland":"MD","massachusetts":"MA","michigan":"MI","minnesota":"MN","mississippi":"MS","missouri":"MO","montana":"MT","nebraska":"NE","nevada":"NV","newhampshire":"NH","newjersey":"NJ","newmexico":"NM","newyork":"NY","northcarolina":"NC","northdakota":"ND","ohio":"OH","oklahoma":"OK","oregon":"OR","pennsylvania":"PA","rhodeisland":"RI","southcarolina":"SC","southdakota":"SD","tennessee":"TN","texas":"TX","utah":"UT","vermont":"VT","virginia":"VA","washington":"WA","westvirginia":"WV","wisconsin":"WI","wyoming":"WY","alberta":"AB","britishcolumbia":"BC","manitoba":"MB","newbrunswick":"NB","newfoundland":"NF","northwestterritory":"NT","novascotia":"NS","nunavut":"NU","ontario":"ON","princeedwardisland":"PE","quebec":"QC","saskatchewan":"SK","yukon":"YT"}
  
  getStateAbbrFromName(input: string) {
    let foundAbbr
    
    if (input) {
      const strInput = input.trim();
      if (strInput.length === 2) {
        // already abbr, check if it's valid
        const upStrInput = strInput.toUpperCase();
        foundAbbr = this.stateAbbrs[upStrInput] ? upStrInput : undefined;
      } else {
        const strStateToFind = strInput.toLowerCase().replace(/ /g, '');
        foundAbbr = this.stateNames[strStateToFind];
      }
    }
    
    return foundAbbr;
  }

  getStateNameFromAbbr(input: string) {
    let foundFullName
    
    if (input) {
      var strInput = input.trim();
      if (strInput.length !== 2) {
        // already full name, return formatted fullname
        foundFullName = this.stateAbbrs[this.getStateAbbrFromName(strInput)];
      } else {
        var strStateToFind = strInput.toLowerCase().replace(/ /g, '');
        foundFullName = this.stateAbbrs[strStateToFind];
      }
    }

    return foundFullName ;
  }
  
  countryAbbrs = {"CA": "Canada", "US": "United States"}
  
  countryNames = {"canada": "CA", "unitedstates": "US", "usa": "US", "unitedstatesofamerica": "US"}

  getCountryAbbrFromName(input: string) {
    let foundAbbr

    if (input) {
      const strInput = input.trim();
      if (strInput.length === 2) {
        // already abbr, check if it's valid
        const upStrInput = strInput.toUpperCase();
        foundAbbr = this.countryAbbrs[upStrInput] ? upStrInput : undefined;
      } else {
        const strCountryToFind = strInput.toLowerCase().replace(/ /g, '');
        foundAbbr = this.countryNames[strCountryToFind];
      }
    }

    return foundAbbr;
  }

  getCountryNameFromAbbr(input: string) {
    let foundFullName

    if (input) {
      var strInput = input.trim();
      if (strInput.length !== 2) {
        // already full name, return formatted fullname
        foundFullName = this.countryAbbrs[this.getCountryAbbrFromName(strInput)];
      } else {
        var strCountryToFind = strInput.toLowerCase().replace(/ /g, '');
        foundFullName = this.countryAbbrs[strCountryToFind];
      }
    }

    return foundFullName ;
  }
  
  // for opportunity agency list api
  async getAgencyOptions() {
    if (this.agencyTypes.length === 0) {
      const data:any = await this.opportunitiesAPI.getAgencyLists();
      if(data && data.data) {
        this.agencyTypes = data.data.map((item: any) => new Agency(item))
        this.agencyTypes.sort((a: Agency, b: Agency) => a.agency_display_name.localeCompare(b.agency_display_name))
      }
    }
    return this.agencyTypes;
  }

  getAgencyOption(code: string) {
    return this.agencyTypes.find((option: Agency) => option.cgac_agency_code === code)
  }

  // for opportunity naics list api
  async getNaicsOptions() {
    if (this.naicsTypes.length === 0) {
      const data:any = await this.opportunitiesAPI.getNaicsCodeLists();
      if(data && data.data) {
        this.naicsTypes = data.data.map((item: any) => new Naics(item))
        this.naicsTypes.sort((a: Naics, b: Naics) => a.naics_title.localeCompare(b.naics_title))
      }
    }
    return this.naicsTypes;
  }

  getNaicsOption(code: string) {
    return this.naicsTypes.find((option: Naics) => option.naics_code === code)
  }


  // for opportunity psc code list api
  async getPscCategories() {
    if (this.pscCategories.length === 0) {
      const data:any = await this.opportunitiesAPI.getPscCategories();
      if(data && data.data) {
        this.pscCategories = data.data.map((item: any) => new PcsCategory(item))
        this.pscCategories.sort((a: PcsCategory, b: PcsCategory) => a.name.localeCompare(b.name))
      }
    }
    return this.pscCategories;
  }

  getPscCategoriesInfo(code: string) {
    return this.pscCategories.find((option: PcsCategory) => option.psccode === code)
  }

  
}

export class LocationOption {
  id: string
  name: string
  group: string

constructor(id: string, name: string, group: string) {
    this.id = id
    this.name = name
    this.group = group
  }
}

export class ServiceOption {
  id: string
  name: string
  group: string
  industries: string[]

  constructor(id: string, name: string, group: string, industries: string[]) {
    this.id = id
    this.name = name
    this.group = group
    this.industries = industries
  }
}


export class SelectOption {
  label: string
  value: string

  constructor(label: string, value: string) {
    this.label = label
    this.value = value
  }
}
