import React from "react";
import {
  TextField,
  withWidth,
  WithWidth
} from "@material-ui/core";
import { createStyles, Theme, WithStyles, WithTheme } from "@material-ui/core";
import { withStyles, withTheme } from "@material-ui/core/styles";
import { Autocomplete } from "@material-ui/lab";
import { makeObservable, observable, when } from "mobx";
import { inject, observer } from "mobx-react";
import UserStore from "../../stores/UserStore";
import AccountStore from "../../stores/AccountStore";
import Progress from "../Progress";
import ResourceCache, {ServiceOption} from "../../stores/ResourceCache";
import {createUUID} from "../../stores/StoreUtilities";
import Service from "../../model/Service";
import {NAICS} from "../../model/Industry";
import TextFieldValidator from "../form/TextFieldValidator";
import Account from "../../model/Account";

const styles = (theme: Theme) => createStyles({
  filterTextField: {
    // width: 308,
    flexGrow: 1, 
    backgroundColor: theme.palette.background.paper,
    borderRadius: 10
  }
})

export interface IServiceFilterProps {
  onSelectService: (serviceOption?: ServiceOption) => any
  value?: ServiceOption
  industry?: string
  freeSolo?: boolean
  variant?: 'filled' | 'outlined' | 'standard'
  formvalidator?: any
  required?: boolean
  margin?: 'dense' | 'none' | 'normal'
  helperText?: React.ReactNode

  accountStore?: AccountStore
  resourceCache?: ResourceCache
  progress?: Progress
  userStore?: UserStore
}

@inject("accountStore", "resourceCache", "userStore", "progress", "formvalidator")
@observer
class ServiceFilter extends React.Component<WithStyles<typeof styles> & WithTheme & IServiceFilterProps & WithWidth> {

  @observable isLoading: boolean = true 
  @observable serviceFilter?: ServiceOption
  @observable newOptions: ServiceOption[] = []
  @observable isValid: boolean = true
  @observable industry?: string

  constructor(props: any) {
    super(props)
    makeObservable(this)
  }

  componentDidMount() {
    const { resourceCache, userStore, value, industry, formvalidator } = this.props
    if (formvalidator) {
      formvalidator.attachToForm(this)
    }

    when(
      // once...
      () => resourceCache!.isLoading === false,
      // ... then
      async () => {
        this.industry = industry !== undefined ? industry : userStore!.industry
        this.serviceFilter = value
        this.isLoading = false 
      }
    )
  }

  componentDidUpdate(prevProps: any) {
    const { userStore, value, industry } = this.props

    if (prevProps.value !== value) {
      this.serviceFilter = this.props.value
    }
    const newIndustry = industry !== undefined ? industry : userStore!.industry
    if (this.industry !== newIndustry) {
      this.industry = newIndustry
    }
  }

  render() {
    const { classes, freeSolo, helperText, required, resourceCache } = this.props

    if (this.isLoading === true) {
      return null 
    }

    const variant = this.props.variant ?? 'outlined'
    const margin = this.props.margin ?? 'none'

    return (
      <TextFieldValidator
        type="text"
        helperText={helperText}
        validators={{ required: required === true }}
        name="serviceFilter"
        label="Job Title"
        className={classes.filterTextField}
        fullWidth
        required={required === true}
        variant={variant}
        margin={margin}
        size="small"
        autocompleteOptions={{
          freeSolo: freeSolo,
          options: resourceCache!.getServiceOptions(this.industry),
          value: this.serviceFilter ?? null,
          groupBy: (option: ServiceOption) => option.group,
          getOptionLabel: (option: ServiceOption) => option?.name ?? option ?? '',
          getOptionSelected: (option: ServiceOption, value: ServiceOption) => value && option.name === value.name,
          onChange: this.onSelectService
        }}
      />
    )
  }

  validate = (value: string): boolean => {
    const isRequired = this.props.required ?? true
    this.isValid = !isRequired || (this.serviceFilter !== undefined)
    return this.isValid
  }

  onSelectService = (event: any, value: any, reason: string) => {
    const { userStore, resourceCache } = this.props

    if (value instanceof ServiceOption) {
      this.serviceFilter = value
    } else if (value) {
      // Search for existing service option
      const serviceOption = resourceCache!.getServiceOptionByName(value)
      if (serviceOption) {
        this.serviceFilter = serviceOption
      } else {
        const industries = userStore!.industry ? [userStore!.industry] : [NAICS.Construction, NAICS.InformationTechnology, NAICS.SecurityServices]
        const service = new Service({
          id: createUUID(),
          name: value,
          active: false,
          // TODO: Does this work for agents and admins?: 
          serviceGroupId: userStore!.industry === NAICS.InformationTechnology ? 'it' : userStore!.industry === NAICS.SecurityServices ? "security-services" : 'other',
          industries
        })
        resourceCache!.addService(service)
        this.serviceFilter = resourceCache!.getServiceOption(service.id)
      }
    } else {
      this.serviceFilter = undefined
    }
    this.props.onSelectService(this.serviceFilter)
  }

  getServiceOption = (option: ServiceOption) => {
    if (option.id === "new") {
      // Create a new temporary Service
      option.id = createUUID()
    }
  }
}

export default withTheme(withStyles(styles)(withWidth()(ServiceFilter)))