import {
  Card,
  CardContent,
  createStyles,
  Grid,
  Theme,
  Typography,
  withStyles,
  WithStyles
} from "@material-ui/core";
import { format, isToday, isYesterday } from "date-fns";
import { inject, observer } from "mobx-react";
import * as React from "react";
import Profile from "../../model/Profile";
import ProfileStore from "../../stores/ProfileStore";
import { isoToLocalDateTime } from "../../stores/StoreUtilities";
import ActionLink from "../../components/ActionLink";
import IconicButton from "../../components/controls/IconicButton";
import EditIcon from "@material-ui/icons/Edit";
import User from "../../model/User";
import {makeObservable, observable} from "mobx";
import ProfileCandidateStatusDialog from "../../components/profile/ProfileCandidateStatusDialog";
import TextBlock from "../../components/TextBlock";

const styles = (theme: Theme) => createStyles({
  container: {
    minHeight: "100%"
  },
  card: {
    fontSize: 12,
    padding: '8px !important', // overriding CardContent root setting
  },
  icon: {
    fontSize: 45,
    height: 45,
    paddingRight: theme.spacing(2),
  },
  name: {
    fontSize: 15,
    fontWeight: 600,
    paddingBottom: theme.spacing(0)
  },
  title: {
    paddingTop: '3px',
    paddingBottom: '6px',
  },
  label: {
    fontSize: 11,
    fontWeight: 200,
    // marginTop: 4,
  },
  fedExperience: {
    color: theme.palette.secondary.dark
  },
  message: {
    paddingTop: '3px',
    maxHeight: 130,
    overflowY: "hidden"
  },
  notes: {
    paddingTop: '3px',
    maxHeight: 130,
    overflowY: "hidden"
  },
  body: {
  },
  fields: {

  },
  editButton: {
    height: 24,
    width: 24
  },
  editIcon: {
    height: 18,
    width: 18
  }
})

interface IApplicantCardProps {
  user: any
  profileStore?: ProfileStore
  onClick?(user: User): void
  onChange?(user: User): void
}

@inject("profileStore")
@observer
class ApplicantCard extends React.Component<WithStyles<typeof styles> & IApplicantCardProps> {

  @observable user?: User
  @observable isFormOpen = false

  constructor(props: any) {
    super(props)
    makeObservable(this)
  }

  componentDidMount() {
    this.user = this.props.user
  }

  componentDidUpdate(prevProps: any) {
    if (this.props.user !== prevProps.user) {
      this.componentDidMount()
    }
  }

  render() {
    const { classes } = this.props
    const user = this.user
    if (!user) {
      return null
    }

    const profile: Profile | undefined = user.profile
    const profileMessage = profile?.statusMessage ?? ""
    const profileNotes = profile?.notes ?? ""
    const federalExperience = profile?.federalExperience ? `${Math.round(profile!.federalExperience * 100) / 100} years` : ``

    return (
      <Card className={classes.container}>
        <CardContent className={classes.card}>
          <Grid container className={classes.body}>
            <Grid item xs={12}>
              <Grid container direction="row">
                <Grid item xs={11}>
                  <ActionLink
                    text={`${user.fullName}`}
                    alignText="left"
                    variation="dark"
                    textStyle={classes.name}
                    tracking="Applicant-Title"
                    click={this.onCardClick}
                  />
                </Grid>
                <Grid item xs={1}>
                  <IconicButton onClick={this.onEdit} className={classes.editButton}>
                    <EditIcon  className={classes.editIcon}/>
                  </IconicButton>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Typography noWrap={true} variant='body2' className={classes.label}>Updated {this.formatDate(profile?.updatedAt)}</Typography>
            </Grid>
            {federalExperience &&
              <Grid item xs={12}>
                <Typography noWrap={true} variant='body2' className={classes.fedExperience}>
                  {federalExperience} federal experience
                </Typography>
              </Grid>
            }
            {profileNotes &&
            <Grid item xs={12} className={classes.notes}>
              <Typography variant='body2' className={classes.label}>Notes</Typography>
              {/*<Typography variant='body2' className={classes.notes}>{profileNotes}</Typography>*/}
              <TextBlock>{profileNotes}</TextBlock>
            </Grid>
            }
            {profileMessage &&
            <Grid item xs={12} className={classes.message}>
              <Typography variant='body2' className={classes.label}>Message</Typography>
              {/*<Typography variant='body2' className={classes.message}>{profileMessage}</Typography>*/}
              <TextBlock>{profileMessage}</TextBlock>
            </Grid>
            }
          </Grid>
        </CardContent>
        {this.isFormOpen &&
          <ProfileCandidateStatusDialog profile={user.profile}
                                        onChange={this.onChange}
                                        onClose={this.onClose}/>
        }
      </Card>
    )
  }

  formatDate(isoDateTime: string | undefined) {
    let result = ""
    if (isoDateTime) {
      let date = isoToLocalDateTime(isoDateTime)
      if (isToday(date)) {
        result = format(date, "h:mm aa")
      } else if (isYesterday(date)) {
        result = "Yesterday"
      } else {
        result = format(date, "M/d/yy")
      }
    }

    return result
  }

  onCardClick = async () => {
    if (this.props.onClick) {
      this.props.onClick(this.user!)
    }
  }

  onEdit = () => {
    this.isFormOpen = true
  }

  onChange = (profile: Profile) => {
    this.user!.profile = profile
    if (this.props.onChange) {
      this.props.onChange(this.user!)
    }
    this.isFormOpen = false
  }

  onClose = () => {
    this.isFormOpen = false
  }
}

export default withStyles(styles)(ApplicantCard)