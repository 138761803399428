import React from "react";
import { Button, Typography } from "@material-ui/core";
import { createStyles, Theme, withStyles, WithStyles, withTheme, WithTheme } from '@material-ui/core/styles';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import SearchIcon from '@material-ui/icons/Search';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import Menu from '@material-ui/core/Menu'
import MenuItem from "@material-ui/core/MenuItem";
import {makeObservable, observable} from "mobx";
import {observer} from "mobx-react";

const styles = (theme: Theme) => createStyles({
  container: {

  },
  button: {
    // backgroundColor: theme.palette.common.white
    // border: 'none',
    boxShadow: 'rgba(0, 0, 0, 0.25) 0px 1px 3px 0px',
  },
  menuPaper: {
    width: '100%',
  },
})

interface IAddButtonProps {
  text: string
  tracking?: string
  disabled?: boolean
  buttonColor?: 'inherit' | 'primary' | 'secondary' | 'default'
  buttonSize?: 'small' | 'medium' | 'large'
  buttonVariant?: 'text' | 'outlined' | 'contained'
  click: Function
  icon?: 'add' | 'remove' | 'search' | 'checkmark' | 'downarrow'
  options?: string[]
  getOptionLabel?(option: any): string
}

@observer
class AddButton extends React.Component<WithStyles<typeof styles> & WithTheme & IAddButtonProps> {
  @observable anchorEl: Element | null = null

  constructor(props: WithStyles<typeof styles> & WithTheme & IAddButtonProps) {
    super(props)
    makeObservable(this)
  }

  render() {
    const {
      classes,
      disabled = false,
      text,
      // tracking,
      buttonColor,
      buttonSize,
      buttonVariant,
      icon = 'add',
      options
    } = this.props

    return (
      <div className={classes.container}>
        <Button
          className={classes.button}
          size={buttonSize ?? "medium"}
          key="0"
          variant={buttonVariant ?? "contained"}
          color={buttonColor ?? "default"}
          aria-label={text}
          startIcon={{
            add: <AddCircleIcon fontSize="small" />,
            remove: <RemoveCircleIcon fontSize="small" />,
            search: <SearchIcon fontSize="small" />,
            checkmark: <CheckCircleIcon fontSize="small" />,
            downarrow: <ArrowDropDownIcon fontSize="large"/>
          }[icon]}
          onClick={this.handleClick}
          {...{ disabled }}
        >
          {text}
        </Button>
        {options && this.renderMenu()}
      </div>
    )
  }

  handleClick = (event: any) => {
    const { options, click } = this.props
    // Tracking.event({
    //   action: `${tracking}`,
    //   label: text
    // })
    if (options) {
      this.anchorEl = event.currentTarget
    } else {
      click()
    }
  }

  handleClose = () => {
    this.anchorEl = null
  }

  renderMenu = () => {
    const { options, getOptionLabel, click } = this.props

    if (options && options.length > 0) {
      return (
        <Menu
          id="add-menu"
          anchorEl={this.anchorEl}
          open={Boolean(this.anchorEl)}
          style={{ marginTop: '3rem' }}
          onClose={this.handleClose}
        >
          {options.map((option, index) => (
            <MenuItem
              key={option}
              onClick={(event) => {
                click(option)
                this.handleClose()
              }}
            >
              {getOptionLabel ? getOptionLabel(option) : option}
            </MenuItem>
          ))}

        </Menu>
      )
    }
  }
}

export default withTheme((withStyles(styles)(AddButton)))
