/* eslint-disable @typescript-eslint/no-unused-vars */
import { 
  Button, 
  Grid, 
  Typography
} from "@material-ui/core"
import get from "lodash.get"
import React, { useEffect, useState } from "react"
import FormValidator from "../../components/form/FormValidator"
import TextFieldValidator from "../../components/form/TextFieldValidator"
import Logger from "../../components/Logger"
import TwoPanelSectionContainer from "../../components/page/TwoPanelSectionContainer"
import Money from "../../model/Money"
import StripeOrderItemData from "../../model/stripe/StripeOrderItemData"
import { useStores } from "../../stores/StoreProvider"
import StripeElementProvider from "./StripeElementProvider"
import StripeOrderItems from "./StripeOrderItems"
import StripeOrderSummary from "./StripeOrderSummary"
import StripePaymentForm from "./StripePaymentForm"
import config from 'react-global-configuration';
import parseHTML from "html-react-parser";
import {SubjectType} from "../../API";
import {ActivityType} from "../../model/UserActivity";

export interface StripeOrderItem {
  data: StripeOrderItemData
  view: React.ReactNode
}

const progressName = 'StripePaymentCart'

const StripePaymentCart = ({
  isLoading,
  title, 
  note, 
  orderItems,
  children, 
  didCreateInvoice,
  didDeleteItem
}: {
  isLoading: boolean
  title: string 
  note?: string  
  orderItems: StripeOrderItem[]
  children: React.ReactNode
  didCreateInvoice(invoiceResult: any): void
  didDeleteItem(orderItem: StripeOrderItem): void
}) => {
  const [amountDue, setAmountDue] = useState<number>()
  const [subtotal, setSubtotal] = useState<number>()

  const [coupon, setCoupon] = useState() 
  const [couponCode, setCouponCode] = useState<string>()
  const [couponDiscountAmount, setCouponDiscountAmount] = useState<number>(0)

  const { accountStore, notify, progress, userStore } = useStores()

  useEffect(() => {
    progress.show(progressName)

    const checkIsReadyForPayment = () => {
      let isReady = true 
      
      if (orderItems.length === 0) {
        isReady = false 
      }
      
      orderItems.forEach(orderItem => {
        const { data } = orderItem
        if (data.unitPrice === undefined) {
          isReady = false 
        }
      })

      return isReady
    }

    const loadSummary = () => {
      const isReady = checkIsReadyForPayment()
      if (!isReady) {
        return 
      }

      let runningTotal: Money = Money.of(0, 'USD')
      
      orderItems.forEach(orderItem => {
        const { data } = orderItem
        const { quantity, unitPrice } = data 

        // Calculate the amount due for the unit.  
        const unitPriceMoney = Money.of(unitPrice!, 'USD') 
        const amountDue = unitPriceMoney.multiply(quantity)

        runningTotal = runningTotal.add(amountDue)
        data.amountDue = amountDue.toNumber()
      })

      setSubtotal(runningTotal.toNumber())
      
      if (coupon) {
        const amountOff = get(coupon, 'amount_off')
        const percentOff = get(coupon, 'percent_off')
        if (amountOff) {
          let discount = Money.of(amountOff, 'USD')
          discount = discount.divide(100)
          if (discount.greaterThanOrEqual(runningTotal)) {
            runningTotal = Money.of(0, 'USD')
            setCouponDiscountAmount(runningTotal.toNumber())
          } else {
            runningTotal = runningTotal.subtract(discount)
            setCouponDiscountAmount(discount.toNumber())
          }
        } else if (percentOff) {
          let discountPercentage = percentOff / 100.0 
          const discount = runningTotal.multiply(discountPercentage)
          setCouponDiscountAmount(discount.toNumber())
          runningTotal = runningTotal.subtract(discount)
        }
      }

      setAmountDue(runningTotal.toNumber())
    }

    const init = async () => {
      if (accountStore.isLoading === false && userStore.isLoading === false) {
        try {
          await loadSummary()
        } catch (error) {
          Logger.error('error', JSON.stringify(error))
        }
        progress.hide(progressName)
      }
    }

    init() 
  },[
    accountStore,
    accountStore.isLoading,
    coupon,
    orderItems, 
    progress,
    userStore,
    userStore.isLoading
  ])

  const onSubmitCouponCode = async () => {
    if (couponCode) {
      const coupon = await accountStore.getCoupon(couponCode)
        .catch((err: any) => {
          notify!.show("error", "Error getting coupon")
        })
     
      if (coupon) {
        if (coupon.max_redemptions && coupon.times_redeemed >= coupon.max_redemptions) {
          // notify!.show("error", "This coupon is used up and cannot be applied")
          notify!.show("error", "Invalid Coupon: Coupon has previously been redeemed.")
          setCouponCode(undefined)
        } else if (coupon.redeem_by) {
          const now = new Date()
          if (now.getTime() / 1000 > coupon.redeem_by) {
            // notify!.show("error", "This coupon has expired and cannot be applied")
            notify!.show("error", "Invalid Coupon: Coupon has expired.")
            setCouponCode(undefined)
          } else {
            setCoupon(coupon)
            notify!.show("success", `${coupon.name} coupon applied!`)
          }
        } else {
          setCoupon(coupon)
          notify!.show("success", `${coupon.name} coupon applied!`)
        }
      } else {
        notify!.show("warning", "This coupon code is not available")
      }
    }
  }

  const onChangeCouponCode = (event: any) => {
    const value = event.target.value
    setCouponCode(value)
  }

  const onPayment = async (token: any) => {
    const tokenId = token ? token.id : null
    const user = userStore.user!
    const orderItemData = orderItems.map(orderItem => orderItem.data)

    notify!.close()

    if (amountDue !== undefined) {
      let invoiceResult = await accountStore!.createInvoice(user, couponCode, orderItemData, tokenId)
        .catch((err: Error) => {
          notify!.show("error", err.message)
          userStore!.logUserActivity(userStore!.user!.id, SubjectType.Invoice, "NA", ActivityType.Error,
            `Create invoice error: ${err.message}`)
        })

      if (invoiceResult) {
        didCreateInvoice(invoiceResult)
        userStore!.logUserActivity(userStore!.user!.id, SubjectType.Invoice, invoiceResult?.id, ActivityType.InvoiceCreated,
          `Job Post invoice created.`)
      }
    } 
  }
  
  const renderOrderItemsPanel = () => {
    return (
      <StripeOrderItems 
        isLoading={isLoading}
        orderItems={orderItems}
        onDelete={didDeleteItem}
      />
    )
  }

  const renderPaymentForm = () => {
    return (
      <StripePaymentForm 
        isLoading={isLoading}
        amountDue={amountDue}
        onPayment={onPayment}
      />
    )
  }

  const renderCouponForm = () => {
    const couponPromo = config.get("couponPromo")

    return (
      <FormValidator
        onSubmit={onSubmitCouponCode} 
        autoComplete="off" 
        name="couponForm" 
        id="couponForm"
      >
        {couponPromo &&
          <Typography variant="body1" color="primary">{parseHTML(couponPromo)}</Typography>
        }
        <Grid container alignItems="center" spacing={1}>
          <Grid item>
            <TextFieldValidator
              margin="dense"
              name="couponCode"
              label="Coupon Code"
              type="text"
              variant="outlined"
              validators={{required:false}}
              onChange={onChangeCouponCode}
              value={couponCode ? couponCode : ""}
              fullWidth
              placeholder=""
            />
          </Grid>
          <Grid item>
            <Button
              type="submit"
              variant="contained"
              color="secondary"
            >
              Apply 
            </Button>
          </Grid>
        </Grid>
      </FormValidator>
    )
  }

  const renderOrderSummaryPanel = () => {
    return (
      <Grid container direction="column" spacing={2}>
        <Grid item>
          <StripeOrderSummary 
            isLoading={isLoading}
            amountDue={amountDue}
            couponDiscountAmount={couponDiscountAmount}
            subtotal={subtotal}
          >
            { (amountDue && amountDue > 0) ? renderCouponForm() : null }
          </StripeOrderSummary>
        </Grid>
        <Grid item>
          { renderPaymentForm() }
        </Grid>
      </Grid>
    )
  }

  return (
    <StripeElementProvider>
      <TwoPanelSectionContainer
        title={title}
        note={note}
        rightPanel={ renderOrderSummaryPanel() }
      >
        { children }
        { renderOrderItemsPanel() }
      </TwoPanelSectionContainer>
    </StripeElementProvider>
  )
}

export default StripePaymentCart
