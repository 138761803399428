import JobPost from "./JobPost";
import Location from "./Location";
import * as APITypes from "../API"
import Account from "./Account";

export enum ServiceRequestStatus {
  Draft = "Draft",
  Submitted = "Submitted",
  MeetingScheduled = "Meeting Scheduled", 
  AgreementPending = "Agreement Pending", 
  AgreementSent = "Agreement Sent", 
  AgreementAccepted = "Agreement Accepted", 
  RequestApproved = "Request Approved",
  RequestDeclined = "Request Declined",
  JobsPosted = "Job(s) Posted",
  CandidatesApplied = "Candidate(s) Applied",
  CandidatesInterviewing = "Candidate(s) Interviewing",
  OffersExtended = "Offer(s) Extended", 
  CandidatesHired = "Candidate(s) Hired",
  Closed = "Closed"
}

class ServiceRequest {
  id: string
  createdAt: string
  updatedAt: string
  accountId: string
  account?: Account  
  serviceRequestType: APITypes.ServiceRequestType 
  name: string
  status: string 
  completedDate: string 
  jobPosts: JobPost[]

  constructor (data: any) {
    this.id = data.id
    this.createdAt = data.createdAt
    this.updatedAt = data.updatedAt
    this.accountId = data.accountId
    this.account = data.account ? new Account(data.account) : undefined
    this.serviceRequestType = APITypes.ServiceRequestType[data.serviceRequestType]
    this.name = data.name
    this.status = data.status 
    this.completedDate = data.completedDate
    this.jobPosts = []
    if (data.jobPosts && data.jobPosts.items) {
      this.loadJobPosts(data.jobPosts.items)
    }
  }

  loadJobPosts(items: any[]) {
    this.jobPosts = items.map((item: any) => new JobPost(item))
  }

  isActive(): boolean {
    // TODO: Determine if the contract is still active. 
    return true 
  }

  requestTypeToString(): string {
    let typeName = ""
    switch (this.serviceRequestType) {
      case (APITypes.ServiceRequestType.RecruitingServices): 
        typeName = "Recruiting Services"
        break
      case (APITypes.ServiceRequestType.StaffingServices): 
        typeName = "Staffing Services"
        break
      case (APITypes.ServiceRequestType.ProfessionalServices): 
        typeName = "Professional Services"
        break
      case (APITypes.ServiceRequestType.SelfService): 
        typeName = "Self-service"
        break 
    }
    return typeName 
  }
}

export default ServiceRequest