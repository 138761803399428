import { configure } from "mobx";
import { Provider } from "mobx-react";
import React from 'react';
import ReactDOM from 'react-dom';
import config from "react-global-configuration";
import AdminAPI from "./apis/AdminAPI";
import DocumentServiceAPI from "./apis/DocumentServiceAPI";
import GovGigAPI from "./apis/GovGigAPI";
import App from './App';
import Alert from "./components/alert/Alert";
import Confirm from "./components/confirm/Confirm";
import Logger from "./components/Logger";
import Notify from "./components/notify/Notify";
import PrivacyPolicy from "./components/page/PrivacyPolicy";
import TermsOfUse from "./components/page/TermsOfUse";
import Progress from "./components/Progress";
import Tracking from "./components/Tracking";
import './index.css';
import * as serviceWorker from './serviceWorker';
import AccountStore from "./stores/AccountStore";
import ProfileStore from "./stores/ProfileStore";
import UserStore from "./stores/UserStore";
import LocationAPI from "./apis/LocationAPI";
import ResourceCache from "./stores/ResourceCache";
import JobStore from "./stores/JobStore";
import OpportunityStore from "./stores/OpportunityStore";
import BillingAPI from "./apis/BillingAPI";
import AuthAPI from "./apis/AuthAPI"
import OpportunitiesAPI from "./apis/OpportunitiesAPI";

Logger.init()

// Migrating from MobX 5 to 6, turning off @action enforcement for now. 
// In the future, we'll want to turn on computedRequiresReaction, reactionRequiresObservable and observableRequiresReaction and enforceActions: "observed". 
// See docs here: https://mobx.js.org/migrating-from-4-or-5.html
configure({ enforceActions: "never" });

const govGigAPI = new GovGigAPI()

const opportunitiesAPI = new OpportunitiesAPI()

const adminAPI = new AdminAPI()

const locationAPI = new LocationAPI()

const billingAPI = new BillingAPI()

const authAPI = new AuthAPI()

const resourceCache = new ResourceCache({
  govGigAPI,
  opportunitiesAPI
})

const accountStore = new AccountStore({
  govGigAPI,
  resourceCache,
  billingAPI
})

const jobStore = new JobStore({
  govGigAPI,
  resourceCache
})

const opportunityStore = new OpportunityStore({
  govGigAPI,
  opportunitiesAPI,
  resourceCache,
  locationAPI
})

const userStore = new UserStore({
  govGigAPI,
  adminAPI,
  locationAPI
})

const profileStore = new ProfileStore({
  govGigAPI,
  resourceCache
})

const resources = {
  confirm: new Confirm(),
  notify: new Notify(),
  alert: new Alert(),
  progress: new Progress(),
  privacyPolicy: new PrivacyPolicy(),
  termsOfUse: new TermsOfUse(),
  resourceCache,
  accountStore,
  jobStore,
  userStore,
  opportunityStore,
  profileStore,
  documentServiceAPI: new DocumentServiceAPI(),
  govGigAPI,
  adminAPI,
  locationAPI,
  authAPI
}

const trackingId = config.get("google.trackingId")
if (trackingId && trackingId !== "" && trackingId !== "NONE") {
  Tracking.initialize(trackingId, config.get("google.options"))
}

ReactDOM.render(
  <React.StrictMode>
    <Provider {...resources}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
