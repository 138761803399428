import * as React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { createStyles, withStyles, Theme, WithStyles } from '@material-ui/core/styles'
import { RouteComponentProps } from '@reach/router'
import DialogButton from '../form/DialogButton'
import {inject, observer} from "mobx-react";
import Confirm from "./Confirm";
import ProgressButton from '../form/ProgressButton';

const styles = (theme: Theme) => createStyles({
  dialogActions: {
    justifyContent: "space-evenly",
    paddingBottom: "16px"
  }
})

interface IConfirmDialog {
  confirm?: Confirm
}

@inject("confirm")
@observer
class ConfirmDialog extends React.Component<WithStyles<typeof styles> & RouteComponentProps & IConfirmDialog> {

  onCancel = () => {
    if (this.props.confirm) {
      this.props.confirm.cancel();
    }
  };

  onSubmit = (event: any) => {
    if (this.props.confirm) {
      this.props.confirm.confirm()
    }
  }

  render() {
    const { classes, confirm } = this.props

    const title = (confirm && confirm.title) ? confirm.title : "Confirm"
    let content
    if (confirm) {
      if (typeof confirm.content === "string") {
        content = <DialogContentText>
          {confirm.content}
        </DialogContentText>
      } else {
        content = confirm.content
      }
    }
    const confirmLabel = (confirm && confirm.buttons && confirm.buttons[0]) ? confirm.buttons[0] : "Confirm"
    const cancelLabel = (confirm && confirm.buttons && confirm.buttons[1]) ? confirm.buttons[1] : "Cancel"

    let dialog = null
    if (confirm && confirm.open) {
      dialog = <Dialog
        id="confirmDialog"
        open={confirm.open}
        onClose={this.onCancel}
        scroll="paper"
        maxWidth="xs"
        fullWidth
        aria-labelledby="confirm-title">
        <DialogTitle id="confirm-dialog-title">{title}</DialogTitle>
        <DialogContent>
          {content}
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          {(!confirm.buttons || confirm.buttons[1]) &&
            <DialogButton variant="secondary" onClick={this.onCancel}>
              {cancelLabel}
            </DialogButton>
          }
          <ProgressButton 
            variant="contained" 
            size="medium" 
            color="secondary"
            type="submit"  
            onClick={this.onSubmit}
            processing={ confirm.isProcessing }
          >
            {confirmLabel}
          </ProgressButton>
        </DialogActions>
      </Dialog>
    }

    return (dialog)
  }
}

export default withStyles(styles)(ConfirmDialog)
