import { Box } from "@material-ui/core"
import FilePreview from "./FilePreview"
import FileDialog, { IFileDialogProps } from "./FileDialog"
import { IFilePreviewProps } from "./FilePreview"
import { useEffect, useState } from "react"
import IconicButton from "../controls/IconicButton"
import { Add, Edit } from "@material-ui/icons"
import Stack from "../Stack"
import AddButton from "../AddButton"

const FilePreviewWithDialog = ({
  filePreviewProps, 
  fileDialogProps, 
  buttonText,
  buttonTracking,
  onClickOpenDialog
}: {
  filePreviewProps: IFilePreviewProps 
  fileDialogProps: IFileDialogProps
  buttonText?: string 
  buttonTracking?: string 
  onClickOpenDialog(): void  
}) => {
  // const [isDialogOpen, setIsDialogOpen] = useState(false)

  // useEffect(() => {
  //   if (isDialogOpen !== fileDialogProps.open) {
  //     setIsDialogOpen(fileDialogProps.open)
  //   }
  // }, [
  //   fileDialogProps.open
  // ])

  const renderFilePreview = () => {
    const {
      onClickEmpty,
      ...rest
    } = filePreviewProps 
    return (
      <FilePreview 
        onClickEmpty={() => {
          onClickOpenDialog()
        }}
        {...rest}
      />
    )
  }

  const renderFileDialog = () => {
    const {
      // open, 
      // onSave, 
      // onDelete, 
      // onClose, 
      ...rest
    } = fileDialogProps
    
    return (
      <FileDialog 
        // open={open}
        // onSave={async (key: string, fileUrl: string) => {
        //   await onSave(key, fileUrl)
        // }}
        // onDelete={async (key: string, fileUrl: string) => {
        //   onDelete && await onDelete(key, fileUrl)
        // }}
        // onClose={(updatedResource?: any) => {
        //   setIsDialogOpen(false)
        // }}
        { ...rest }
      />
    )
  }

  const renderButton = () => {
    if (buttonText) {
      return (
        <AddButton
          text={buttonText}
          tracking={buttonTracking!}
          buttonColor="secondary"
          buttonVariant="contained"
          click={() => {
            // setIsDialogOpen(true)
            onClickOpenDialog()
          }}
        />
      )
    } else {
      return (
        <Box
          sx={{ 
            // position: "relative", 
            // top: 18, 
            // right: -12 
          }}
        >
          <IconicButton onClick={() => {
            // setIsDialogOpen(true)
            // if (onOpen) { onOpen() }
            onClickOpenDialog()
          }}>
            { filePreviewProps.fileKey ? <Edit /> : <Add />}
          </IconicButton>
        </Box>
      )
    }
  }

  return (
    <Box>
      <Stack 
        direction="row"
        justifyContent="space-between"
        spacing={1}
        wrap="nowrap"
      >
        { renderFilePreview() }
        { renderButton() }
      </Stack>
      { renderFileDialog() }
    </Box>
  )
}

export default FilePreviewWithDialog 