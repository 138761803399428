import { Box } from "@material-ui/core"
import { ReactNode } from "react"
import MarginRow from "../../page/MarginRow"

export const SectionBackgroundColors = {
  0: '#FFF',
  50: '#FCFCFD',
  100: '#F9F9FB',
  200: '#F3F3F7',
  300: '#EDEDF3',
  400: '#dbdee1',
  500: '#c0c5ca',
  600: 'hsl(210, 9%, 67%)',
  700: 'hsl(210, 9%, 57%)'
}

interface ISection {
  backgroundColor?: string 
  background?: string 
  children: ReactNode
}

const Section = ({ backgroundColor, background, children }: ISection) => {
  return (
    <MarginRow
      backgroundColor={backgroundColor}
      background={background}
    >
      <Box
        pt={{ 
          xs: 7, 
          lg: 9 
        }}
        pb={{ 
          xs: 10, 
          lg: 12 
        }}
      >
        {children}
      </Box>
    </MarginRow>
  )
}

export default Section 