import React from "react"
import { 
  Box, 
  createStyles,
  Divider, 
  makeStyles, 
  Paper, 
  Theme, 
  // Stack, 
  Typography,
  useMediaQuery,
  useTheme, 
  // useTheme 
} from "@material-ui/core"
import Product from "../../../model/stripe/Product"
import ActionButton from "../../controls/ActionButton"
// import RoutesConfig from "../../../RoutesConfig"
import { Check } from '@material-ui/icons';
import PricingToggle from "./PricingToggle"
import ControlTower from "../../ControlTower";
import config from 'react-global-configuration';
import Stack from "../../Stack";


const useStyles = makeStyles((theme: Theme) => createStyles({
  title: {
    fontWeight: 'bold',
    fontSize: '20px'
  },
  titleBox0: {
    backgroundColor: 'hsla(220, 37%, 27%, 1.0)'
  },
  titleBox1: {
    backgroundColor: 'hsla(240, 37%, 27%, 1.0)'
  }
}))

export interface IPricingCardProps {
  product: Product
  index: number
  dense?: boolean
  children: React.ReactNode 
}

const PricingCard = ({ 
  product,
  index,
  dense,
  children 
}: IPricingCardProps) => {
  // const theme = useTheme()
  // const gradient = 'linear-gradient(to bottom, hsla(218,81%,100%,1.0) 77%, hsla(218,17%,93%,1.0) 100%)'
  // const gradientFeature = 'linear-gradient(to bottom, hsla(218,71%,99%,1.0) 77%, hsla(218,77%,93%,1.0) 100%)'
  const classes = useStyles()
  const theme = useTheme()
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'))

  const renderProductDescription = () => {
    return (
      <Box
        sx={{
          pt: product.superTitle ? 0 : 3.5,
          pb: dense ? 1 : 2,
          color: "#fff",
          px: 2 
        }}
        className={ index === 0 ? classes.titleBox0 : classes.titleBox1 }
      >
        { product.superTitle && 
          <Typography 
            variant="h5" 
            align="center" 
            color="inherit"
            // sx={{
            //   pb: 1 
            // }}
          >
            {product.superTitle}
          </Typography>
        }
        <Box
          sx={{
            height: isSmall ? 75 : 50
          }}
        >
          <Typography 
            gutterBottom 
            variant={ dense ? "h6" : "h3" }
            align="center" 
            color="inherit"
            className={classes.title}
            // sx={{
            //   height: {
            //     xs: 50, 
            //     md: 75,
            //     lg: 75
            //   }
            // }}
          >
            {product.name}
          </Typography>
        </Box>
        <Box
          sx={{
            height: isSmall ? 100 : 75 
          }}
        >
          <Typography 
            variant="body1" 
            align="center"
            // sx={{ 
            //   height: {
            //     xs: 50,
            //     md: 75,
            //     lg: 75
            //   } 
            // }}
          >
            {product.description}
          </Typography>
        </Box>
      </Box>
    )
  }

  const renderPricingOptions = () => {
    return (
      <Box
        sx={{ 
          textAlign: "center",
          pt: 2,
          pb: 3
        }}
      >
        <PricingToggle product={product} />
        {/* <ActionButton 
          route={{
            title: "Get Started", 
            href: RoutesConfig.docsSignUp.href
          }}
        /> */}
      </Box>
    )
  }

  const renderCustomPricingOption = () => {
    return (
      <Box
        sx={{ 
          textAlign: "center"
        }}
      >
        <Box 
          sx={{
            height: {
              md: 110,
              lg: 110
            },
            pb: 2
          }}
        >
          <img alt={product.name} src={product.imageUrls[0]} /> 
        </Box>
        <Box
          sx={{
            pb: 7 
          }}
        >
          <Typography variant="h5" align="center" color="primary">
            Custom
          </Typography>
        </Box>
        <Box sx={{ pb: 3 }}>
          <ActionButton 
            text="Contact Us"
            tracking="Contact-Us"
            click={() => {
              const homeUrl = config.get("homeUrl")
              ControlTower.open(`${homeUrl}/contact`, "_self")
            }}
            // route={{
            //   title: "Contact Us", 
            //   pathname: RoutesConfig.contact.pathname
            // }}
          />
        </Box>
      </Box>
    )
  }

  const renderFeatures = () => {
    return (
      <Box
        sx={{
          px: 3, 
          // MUI 5: 
          // px: {
          //   xs: 3, 
          //   md: 1,
          //   lg: 2
          // },
          // pt: {
          //   xs: 2,
          //   md: 1
          // },
          // pb: {
          //   xs: 3, 
          //   md: 2
          // },
          // backgroundColor: theme.palette.grey[50],
          borderRadius: 1,
          height: isSmall ? 250 : 200
        }}
      >
        { product.includes && 
          <Typography 
            variant="h6" 
            align="center" 
            // pb={2} 
            // sx={{ 
            //   fontWeight: 500 
            // }}
          >
            {product.includes}:
          </Typography>
        }
        {product.features.length > 0 && 
          <Stack direction="column" alignItems="stretch" spacing={1}>
            {product.features.map((feature, index) => {
              return (
                <React.Fragment>
                  <Divider 
                  />
                  <Stack 
                    key={`feature.${index}`} 
                    direction="row" 
                    spacing={1} 
                    justifyContent="flex-start" 
                    alignItems="flex-start"
                    wrap="nowrap"
                    sx={{
                      p: 1
                    }}
                  >
                    <Box
                      color="green"
                    >
                      <Check 
                        color="inherit"
                        // sx={{
                        //   color: "green" 
                        // }}
                      />
                    </Box>
                    <Typography 
                      variant="body2" 
                      // sx={{ 
                      //   fontWeight: 400 
                      // }}
                    >
                      {feature}
                    </Typography>
                  </Stack>
                </React.Fragment>
              )
            })}
          </Stack>
        }
      </Box>
    )
  }

  return (
    <Paper
      elevation={2}
      // sx={{
      //   px: 2,
      //   pt: 2, 
      //   pb: 3,
      //   minHeight: "100%",
      //   background: product.superTitle ? gradientFeature : gradient 
      // }}
      style={{
        minHeight: '100%',
        overflow: "hidden"
      }}
    >
      { renderProductDescription() }
      {product.prices.length > 0 && 
        renderPricingOptions() 
      }
      {product.imageUrls.length > 0 && 
        renderCustomPricingOption()
      }
      { renderFeatures() }
      { children }
    </Paper>
  )
}

export default PricingCard
