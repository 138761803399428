import * as React from 'react'
import {makeObservable, observable, when} from "mobx";
import {inject, observer} from "mobx-react";
// Routing 
import {RouteComponentProps} from "@reach/router";
// UI
import {
  Box,
  createStyles,
  Grid,
  Theme,
  Typography,
  withStyles,
  WithStyles,
  withTheme,
  WithTheme
} from "@material-ui/core";
// Stores
import AccountStore from "../../stores/AccountStore";
import ProfileStore from "../../stores/ProfileStore";
import UserStore from "../../stores/UserStore";
// Components
import Confirm from "../confirm/Confirm";
import Notify from "../notify/Notify";
import Progress from "../Progress";
// Models
import User from '../../model/User';
import Logger from '../Logger';
import UserActivity, {ActivityType} from '../../model/UserActivity';
import UserActivityCard from './UserActivityCard';
import { grey } from '@material-ui/core/colors';
import {ModelUserActivityFilterInput, SubjectType} from '../../API';

const styles = (theme: Theme) => createStyles({
  avatar: {
    backgroundColor: theme.palette.primary.main,
    borderWidth: 2,
    fontSize: 14,
    width: theme.spacing(4),
    height: theme.spacing(4)
  },
  content: {
    overflow: "hidden"
  },
  border: {
    border: `1px solid ${grey[300]}`
  },
  tabs: {
    flexGrow: 1
  },
  tab: {
    fontSize: 15,
    fontWeight: 600,
    textTransform: 'capitalize'
  },
})

interface IUserActivityListProps {
  // When looking up by user: 
  user?: User
  // When looking up by subject: 
  subject?: SubjectType
  subjectId?: string
  notesOnly?: boolean
  updatedAt?: string

  userStore?: UserStore
  profileStore?: ProfileStore
  accountStore?: AccountStore
  progress?: Progress
  notify?: Notify
  confirm?: Confirm
}

@inject("userStore", "profileStore", "accountStore", "progress", "notify", "confirm")
@observer
class UserActivityList extends React.Component<WithStyles<typeof styles> & RouteComponentProps & IUserActivityListProps & WithTheme> {
  
  @observable isLoading = true
  @observable userActivity: UserActivity[] = []
  
  constructor(
    props: WithStyles<typeof styles> & RouteComponentProps & IUserActivityListProps & WithTheme
  ) {
    super(props);
    makeObservable(this);
  }

  componentDidMount() {
    const { accountStore, userStore, profileStore, progress } = this.props
    
    progress!.show("UserActivityList")
    when(
      // once
      () => !accountStore!.isLoading && !userStore!.isLoading && !profileStore!.isLoading,
      // then 
      async () => {
        await this.loadUserActivity()
        progress!.hide("UserActivityList")
        this.isLoading = false 
      }
    )
  }

  componentDidUpdate(prevProps: any) {
    if (this.props.updatedAt && this.props.updatedAt !== prevProps.updatedAt) {
      this.loadUserActivity()
    }
  }

  render() {
    return (
      <Box minHeight="100px" width="100%">
        <Grid container>
          {this.userActivity.length > 0 && 
            this.userActivity.map(userActivity => {
              return (
                <Grid item key={userActivity.id} xs={12}>
                  <Box py={1}>
                    <UserActivityCard 
                      userActivity={userActivity}
                      elevation={1}
                      onChange={this.onChangeActivity}
                    />
                  </Box>
                </Grid>
              )
            }) 
          }
          {this.isLoading === false && this.userActivity.length === 0 && 
            <Grid item>
              <Box py={2}>
                <Typography>
                  No activity has been recorded yet.
                </Typography>
              </Box>
            </Grid>
          }
        </Grid>
      </Box>
    )
  }

  // Loading 

  loadUserActivity = async () => {
    const { notify, subject, subjectId, user, userStore, notesOnly } = this.props

    let userActivity 
    try {
      let filter: ModelUserActivityFilterInput | undefined
      if (user) {
        if (userStore!.isEmployer) {
          filter = {
            accountId: { eq: userStore!.user!.accountId }
          }
        }
        if (notesOnly) {
          filter = {
            activityType: { eq: ActivityType.NoteAdded }
          }
        } else {
          filter = {
            activityType: { ne: ActivityType.NoteAdded }
          }
        }
        userActivity = await userStore!.listUserActivityByUser(user.id, filter)
      } else if (subject && subjectId) {
        if (notesOnly) {
          filter = {
            activityType: { eq: ActivityType.NoteAdded }
          }
        } else {
          filter = {
            activityType: { ne: ActivityType.NoteAdded }
          }
        }
        userActivity = await userStore!.listUserActivityBySubject(subjectId, filter)
      } else {
        throw new Error('No subject or user specified')
      }
    } catch (error) {
      Logger.error('Could not load user activity', error)
      notify!.show('error', 'Could not load user activity')
      return 
    }

    this.userActivity = userActivity
  }

  onChangeActivity = (userActivity: UserActivity) => {
    // Just reload for simplicity
    this.loadUserActivity()
  }
}

export default withTheme((withStyles(styles)(UserActivityList)))
