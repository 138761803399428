import {JobInterestStatus} from "../API";
import JobPost from "./JobPost";
import {isoToLocalDate} from "../stores/StoreUtilities";
import {format} from "date-fns";
import Answer from "./Answer";

class JobInterest {
  id: string
  createdAt: string
  updatedAt: string
  userId: string
  jobPostId: string
  jobPost?: JobPost
  status: JobInterestStatus
  ranking: number
  notes: string
  message: string
  unlocked: boolean
  application: Answer[]

  constructor(data: any) {
    this.id = data.id
    this.createdAt = data.createdAt
    this.updatedAt = data.updatedAt
    this.userId = data.userId
    this.jobPostId = data.jobPostId
    this.jobPost = data.jobPost ? new JobPost(data.jobPost) : undefined
    this.status = JobInterestStatus[data.status]
    this.ranking = data.ranking
    this.notes = data.notes
    this.message = data.message
    this.unlocked = data.unlocked ?? false 
    this.application = this.loadApplication(data)
  }
  
  loadApplication(data: any) {
    if (data.application) {
      let rawData
      if (Array.isArray(data.application)) {
        // Assume data is JSON, i.e. from DynamoDB via AWS SDK directly. 
        rawData = data.application
      } else {
        // Assume typeof data.application === 'string', i.e. from AppSync. 
        rawData = JSON.parse(data.application)
      } 
      return rawData.map((answerData: any) => new Answer(answerData))
    } else {
      return []
    }
  }

  get canApply() : boolean {
    return this.status === JobInterestStatus.Interested || this.status === JobInterestStatus.Invited
  }
  
  get statusText() : string {
    const formattedDate = format(isoToLocalDate(this.updatedAt), "M/d/yyyy")
    let text = ""
    if (this.status === JobInterestStatus.Interested) {
      text = `Saved on ${formattedDate}`
    } else if (this.status === JobInterestStatus.Invited) {
      text = `Invited to apply on ${formattedDate}`
    } else if (this.status === JobInterestStatus.Applied) {
      text = `Applied on ${formattedDate}`
    } else if (this.status === JobInterestStatus.Accepted) {
      text = `Accepted on ${formattedDate}`
    } else if (this.status === JobInterestStatus.Reviewing) {
      text = `Reviewing on ${formattedDate}`
    } else if (this.status === JobInterestStatus.Rejected) {
      text = `Declined on ${formattedDate}`
    } else if (this.status === JobInterestStatus.Contacted) {
      text = `Considering on ${formattedDate}`
    } else if (this.status === JobInterestStatus.Hired) {
      text = `Hired on ${formattedDate}`
    } else if (this.status === JobInterestStatus.Declined) {
      text = `Declined on ${formattedDate}`
    } else {
      text = String(this.status)
    }
    return text
  }
}

export default JobInterest