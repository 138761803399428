import * as React from "react";
import {
  Box,
  Card,
  CardContent,
  createStyles,
  Grid,
  isWidthDown,
  Theme,
  Typography,
  withStyles,
  WithStyles,
  WithWidth,
  withWidth
} from "@material-ui/core";
import { observer} from "mobx-react";
import Charge from "../../../model/stripe/Charge";
import {isoToLocalDate, numberToMoneyFormat} from "../../../stores/StoreUtilities";
import {format} from "date-fns";
import ControlTower from "../../../components/ControlTower";
import DialogButton from "../../../components/form/DialogButton";

const styles = (theme: Theme) => createStyles({
  card: {
    width: '100%',
    // height: 60,
    // padding: 0
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1)
  },
  content: {
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.background.paper,
    // padding: "0px 10px",
    // display: "flex",
    // flexGrow: 1
  },
  label: {
    color: theme.palette.text.secondary,
    fontSize: 12,
    marginTop: theme.spacing(1),
    marginBottom: 0,
    marginLeft: 0,
  },
  value: {
    fontSize: 16,
    fontWeight: 800,
    maxHeight: 26,
    overflow: "hidden",
  },
  titleButtons: {
    marginTop: theme.spacing(2),
    // marginTop: 18,
    [theme.breakpoints.up("lg")]: {
      textAlign: "right"
    }
  },

})

interface IAccountChargeCardProps {
  charge: Charge
}

@observer
class AccountChargeCard extends React.Component<WithStyles<typeof styles> & IAccountChargeCardProps & WithWidth> {

  render() {
    const { classes, charge, width } = this.props

    const date = format(isoToLocalDate(charge.created), "M/d/yyyy")
    const description = charge.description ?? ""
    const amount = numberToMoneyFormat(charge.amount)

    const isSmall = isWidthDown("sm", width)

    return (
      <Card className={classes.card}>
        <CardContent className={classes.content}>
            <Grid 
              container 
              justifyContent={ isSmall ? "center" : 'space-between' } 
              direction={ isSmall ? "column" : "row" }
            >
              <Grid item xs={12} lg={2}>
                <Typography className={classes.label}>
                  Date
                </Typography>
                <Typography className={classes.value}>
                  {date}
                </Typography>
              </Grid>
              <Grid item xs={12} lg={5}>
                <Typography className={classes.label}>
                  Description
                </Typography>
                <Typography className={classes.value}>
                  {description}
                </Typography>
              </Grid>

              <Grid item xs={12} lg={3}>
                <Typography className={classes.label}>
                  Amount
                </Typography>
                <Typography className={classes.value}>
                  {amount}
                </Typography>
              </Grid>

              <Grid item xs={12} lg={2}>
                <Box className={classes.titleButtons}>
                  <DialogButton variant="secondary" onClick={this.onView}>Receipt</DialogButton>
                </Box>
              </Grid>

            </Grid>
        </CardContent>
      </Card>
    )
  }

  onView = () => {
    const { charge } = this.props
    ControlTower.open(charge.receipt_url, "_blank")
  }

}

export default withStyles(styles)(withWidth()(AccountChargeCard))
