import * as React from "react";
import {
  Box,
  createStyles,
  alpha,
  Grid,
  isWidthDown,
  Theme,
  withStyles,
  WithStyles,
  withWidth,
  WithWidth
} from "@material-ui/core";
import CardValue from "../../components/CardValue";
import {durationBetweenISODates, humanizeString} from "../../stores/StoreUtilities";
import JobPost from "../../model/JobPost";
import {inject, observer} from "mobx-react";
import UserStore from "../../stores/UserStore";
import {makeObservable, observable, when} from "mobx";
import Visible from "../Visible";
import ResourceCache from "../../stores/ResourceCache";

const styles = (theme: Theme) => createStyles({
  header: {
    fontWeight: 200,
    color: theme.palette.text.secondary,
    maxHeight: 26
  },
  buttons: {
    textAlign: "right",
    maxHeight: 26
  },
  heading: {
    color: theme.palette.primary.main,
    fontWeight: 'bold'
  },
  iconButton: {
    marginLeft: theme.spacing(1)
  },
  applyButton: {
    backgroundColor: alpha(theme.palette.secondary.light, 0.3),
    borderWidth: 0,
    marginTop: 0,
    marginLeft: theme.spacing(1),
    "&:hover": {
      borderWidth: 0,
      backgroundColor: alpha(theme.palette.secondary.light,0.5),
    },
    "&:disabled": {
      backgroundColor: alpha(theme.palette.secondary.main, 0.5),
      color: alpha(theme.palette.secondary.contrastText, 0.5),
    },
  },

})

interface IJobPostInfoProps {
  jobPost: JobPost
  
  userStore?: UserStore
  resourceCache?: ResourceCache
}

@inject('userStore', 'resourceCache')
@observer
class JobPostInfo extends React.Component<WithStyles<typeof styles> & IJobPostInfoProps & WithWidth> {

  @observable isJobPostEditable: boolean = false 

  constructor(props: any) {
    super(props)
    makeObservable(this)
  }

  componentDidMount() {
    const { userStore } = this.props 
    when(
      // once 
      () => userStore!.isLoading === false || userStore!.isPublic === true,
      // then 
      () => {
        // this.loadJobPostEditable()
      }
    )
  }

  render() {
    const {
      jobPost,
      width,
      userStore
    } = this.props
    const isSmall = isWidthDown('sm', width)
    const salaryRange = jobPost.salaryRange
    const securityClearance = jobPost.securityClearance
    const hiringPeriod = jobPost.hiringPeriod
    const duration = durationBetweenISODates(jobPost.startDate, jobPost.endDate)
    const labelWidth = 100 

    return (
      <Box>
        <Grid container direction={ isSmall ? 'column' : 'row' } spacing={2} wrap="nowrap">
          <Grid item xs={12} lg={5}>
            <Grid container direction="column" spacing={1}>
              <Grid item>
                <CardValue label="Location" labelWidth={labelWidth} maxTextWidth={isSmall ? 200 : undefined}>
                  {jobPost.locationDescription}
                </CardValue>
              </Grid>
              <Grid item>
                <CardValue label="Job Type" labelWidth={labelWidth}>
                  {humanizeString(jobPost.employmentType)} Hire {hiringPeriod}
                </CardValue>
              </Grid>
              <Visible if={securityClearance !== undefined}>
                <Grid item>
                  <CardValue label="Clearance" labelWidth={labelWidth}>
                    {securityClearance}
                  </CardValue>
                </Grid>
              </Visible>
              <Visible if={duration !== ""}>
                <Grid item>
                  <CardValue label="Date(s)" labelWidth={labelWidth}>
                    {duration}
                  </CardValue>
                </Grid>
              </Visible>
              <Visible if={salaryRange !== undefined}>
                <Grid item>
                  <CardValue label="Salary" labelWidth={labelWidth}>
                    {salaryRange}
                  </CardValue>
                </Grid>
              </Visible>
              <Visible if={userStore!.isAdminOrAgent}>
                <Grid item>
                  <CardValue label="Employer" labelWidth={labelWidth}>
                    {jobPost.account?.name}
                  </CardValue>
                </Grid>
              </Visible>
            </Grid>
          </Grid>
          <Grid item xs={12} lg={7}>
            <CardValue label="Summary" labelWidth={labelWidth} wrapText={true}>
              {jobPost.summary.toString()}
            </CardValue>
          </Grid>
        </Grid>
      </Box>
    )
  }
}

export default withStyles(styles)(withWidth()(JobPostInfo))
