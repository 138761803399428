import * as React from 'react'
import UserStore from "../../stores/UserStore";
import {inject, observer} from "mobx-react";
import {RouteComponentProps} from "@reach/router";
import {createStyles, Theme, WithStyles, WithTheme} from "@material-ui/core/styles";
import {Box, Grid, Paper, Typography, WithWidth, isWidthDown, withStyles, withTheme, withWidth} from "@material-ui/core";
import MarginRow from "../../components/page/MarginRow";
import Page from "../../components/page/Page";

import candidateImage from '../../images/JobSeekerApply_All.png'
import employerImage from '../../images/EmployerApply_All.png';
import academyImage from '../../images/academy_govgig_screenshot_2_480x270.jpg'

import constructionCandidateImage from '../../images/JobSeekerApply_Construction.png'
import constructionEmployerImage from '../../images/EmployerApply_Construction.png';

import itCandidateImage from "../../images/ForJobSeekersCard.png";
import itEmployerImage from "../../images/ForEmployersCard.png"

import ActionButton from "../../components/controls/ActionButton";
import ControlTower, {Routes} from "../../components/ControlTower";
import config from "react-global-configuration";
import { when } from 'mobx';
import { NAICS } from '../../model/Industry';
import theme from '../../styles/theme';

const styles = (theme: Theme) => createStyles({
  root: {
    flexGrow: 1,
    justifyContent: 'top',
    alignItems: 'center',
  },
  content: {
    // marginTop: theme.spacing(2),
    // marginLeft: theme.spacing(1),
    // marginBottom: theme.spacing(2)
  },
  panel: {
    marginTop: theme.spacing(3),
    // height: 400,
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(5),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3)
  },
  image: {
    width: 'auto',
    height: 150,
    // maxWidth: "100%",
    marginTop: 7,
    flex: 1,
    borderRadius: theme.spacing(2)
  },
  actionButton: {
    textAlign: "center"
  },
  title: {
    fontSize: "32px"
  }
})

interface IJoinPageProps {
  userStore?: UserStore
  location?: any
}

@inject("userStore")
@observer
class JoinPage extends React.Component<WithStyles<typeof styles> & RouteComponentProps & IJoinPageProps & WithTheme & WithWidth> {
  
  imageForJobSeeker = () => {
    const { userStore } = this.props 
    const industry = userStore!.industry
    if (industry === NAICS.Construction) {
      return constructionCandidateImage
    } else if (industry === NAICS.InformationTechnology) {
      return itCandidateImage 
    } else {
      return candidateImage
    }
  }

  imageForEmployer = () => {
    const { userStore } = this.props 
    const industry = userStore!.industry
    if (industry === NAICS.Construction) {
      return constructionEmployerImage
    } else if (industry === NAICS.InformationTechnology) {
      return itEmployerImage 
    } else {
      return employerImage
    }
  }

  render() {
    const { classes, userStore, width } = this.props

    const academySignupRoute = `${config.get("academyUrl")}/signup`

    const isXS = isWidthDown('sm', width)

    return (
      <Page title="Sign Up">
        <Box bgcolor="grey.50" width="100%">
          <MarginRow>
            <Box mt={3} mb={5}>
              <Grid container direction="column" spacing={2} justifyContent="center" className={classes.content}>
                <Grid item xs={12}>
                  <Box textAlign="center">
                    <Typography color="primary" variant="h1" className={classes.title}>
                      GovGig Sign Up
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Box textAlign="center">
                    <Typography color="textPrimary" variant="h6">
                      Sign up to the community of quality candidates and excellent employers, connecting for work on Federal projects.
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Grid container justifyContent="center" alignItems="stretch" spacing={5}>
                    <Grid item xs={12} md={6}>
                      <Paper elevation={1} className={classes.panel}>
                        <Grid container direction="column" alignItems="center" spacing={2}>
                          <Grid item>
                            <Typography variant="h5" align="center" color="textSecondary">For Job Seekers</Typography>
                          </Grid>
                          <Grid item>
                            <img className={classes.image} src={ this.imageForJobSeeker() } alt="GovGig for Job Seekers"/>
                          </Grid>
                          <Grid item>
                            <Box
                              sx={{
                                height: 57
                              }}
                            >
                              <Typography variant="body1" align="center">
                                Are you looking for a role now or for the future?
                              </Typography>
                            </Box>
                          </Grid>
                          <Grid item className={classes.actionButton}>
                            <ActionButton
                              text={"Job Seeker Sign Up"}
                              tracking={"Join-Apply"}
                              click={() => ControlTower.route(Routes.apply)}
                            />
                          </Grid>
                        </Grid>
                      </Paper>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Paper elevation={1} className={classes.panel}>
                        <Grid container direction="column" alignItems="center" spacing={2}>
                          <Grid item >
                            <Typography variant="h5" align="center" color="textSecondary">For Employers</Typography>
                          </Grid>
                          <Grid item>
                            <img className={classes.image} src={ this.imageForEmployer() } alt="GovGig for Employers"/>
                          </Grid>
                          <Grid item>
                          <Box
                              sx={{
                                height: 57
                              }}
                            >
                              <Typography variant="body1" align="center">
                                Are you an employer in need of candidates?
                              </Typography>
                            </Box>
                          </Grid>
                          <Grid item className={classes.actionButton}>
                            <ActionButton
                              text={"Employer Sign Up"}
                              tracking={"Join-SignUp"}
                              click={() => ControlTower.route(Routes.signup)}
                            />
                          </Grid>
                        </Grid>
                      </Paper>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Grid container justifyContent="center" alignItems="center">
                    <Grid item xs={12} md={12}>
                      <Paper elevation={1} className={classes.panel}>
                        <Grid container direction={ isXS ? "column" : "row"} alignItems="center" spacing={2} wrap="nowrap">
                          <Grid item xs={12} md={3}>
                            <Typography variant="h5" align="center" color="textSecondary">For Training</Typography>
                          </Grid>
                          <Grid item xs={12} md={3}>
                            <img className={classes.image} src={academyImage} alt="GovGig Academy"/>
                          </Grid>
                          <Grid item xs={12} md={3}>
                            <Box
                              sx={{
                                height: 57,
                                marginBottom: theme.spacing(2)
                              }}
                            >
                              <Typography variant="body1" align="center">
                                Are you a looking for federal construction training or to provide training in your company?
                              </Typography>
                            </Box>
                          </Grid>
                          <Grid item className={classes.actionButton} xs={12} md={3}>
                            <ActionButton
                              text={"Academy Sign Up"}
                              tracking={"Join-SignUp"}
                              click={() => ControlTower.route(academySignupRoute)}
                            />
                          </Grid>
                        </Grid>
                      </Paper>
                    </Grid>                  
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </MarginRow>
        </Box>
      </Page>
    )
  }
}

export default withTheme((withStyles(styles)(withWidth()(JoinPage))))


