import * as React from "react";
import {
  Box,
  createStyles,
  Grid,
  Theme,
  Typography,
  withStyles,
  WithStyles,
  withWidth,
  WithWidth
} from "@material-ui/core";
import JobCandidate from "../../model/JobCandidate";
import JobPost from "../../model/JobPost";
import JobCandidateCard from "./JobCandidateCard";
import AddButton from "../AddButton";
import { inject, observer } from "mobx-react";
import { makeObservable, observable, reaction, when } from "mobx";
import UserStore from "../../stores/UserStore";
import AccountStore from "../../stores/AccountStore";
import Progress from "../Progress";
import Notify from "../notify/Notify";
import Logger from "../Logger";
import ControlTower, { Routes } from "../ControlTower";
import Profile from "../../model/Profile";
import {JobPostStatus, JobPostType, SubjectType} from "../../API";
import {ActivityType} from "../../model/UserActivity";
import JobStore from "../../stores/JobStore";
import JobPostMiniCard from "../jobPosts/JobPostMiniCard";
import InstructionsPanel from "../page/InstructionsPanel";

const styles = (theme: Theme) => createStyles({
  
})

interface IJobCandidateListProps {
  jobPost?: JobPost // Passed in to list job candidate records related to a job post. 
  profile?: Profile // Passed in to list job candidate records related to a candidate profile.  
  onClickFindTalent?: Function
  onClickFindJobs?: Function 
  onClickManageJobs?: Function 
  onClickJobCandidate?(jobCandidate: JobCandidate): void
  showInColumns?: boolean

  accountStore?: AccountStore
  jobStore?: JobStore
  // confirm?: Confirm
  notify?: Notify
  progress?: Progress
  userStore?: UserStore
}

@inject('accountStore', 'jobStore', 'userStore', 'notify', 'progress')
@observer 
class JobCandidateList extends React.Component<WithStyles<typeof styles> & IJobCandidateListProps & WithWidth> {

  @observable isLoading: boolean = true 
  @observable jobCandidates: JobCandidate[] = []

  constructor(props: any) {
    super(props)
    makeObservable(this)
  }

  componentDidMount() {
    const { accountStore, userStore, progress } = this.props
    
    progress!.show("JobCandidateList")
    when(
      // once
      () => !accountStore!.isLoading && !userStore!.isLoading,
      // then 
      async () => {
        await this.loadJobCandidates()
        progress!.hide("JobCandidateList")
        this.isLoading = false 
      }
    )

    // When the job candidates are modified outside of this component 
    // (for example, in the Find Talent side drawer), we need to react and update this list. 
    reaction(() => this.props.jobPost?.jobCandidates, (jobCandidates) => {
      this.loadJobCandidates()
    })
  }

  render() {
    const { jobPost, profile } = this.props

    if (this.isLoading === true) {
      return null 
    }

    return (
      <React.Fragment>
        { jobPost && this.renderJobCandidatesForJobPost() }
        { profile && this.renderJobCandidatesForProfile() }
      </React.Fragment>
    )
  }

  renderJobCandidatesForJobPost() {
    const { onClickJobCandidate, jobPost, userStore } = this.props

    let instructions = []
    
    if (jobPost!.jobPostType === JobPostType.SelfService) {
      if (jobPost!.status === JobPostStatus.Draft) {
        instructions.push('Publish your job post by using the "Publish" button.')
      }
      instructions.push('To add candidates for a published job post, please click the "Find Talent" button.')

    } else if (jobPost!.jobPostType === JobPostType.RecruitingServices || jobPost!.jobPostType === JobPostType.StaffingServices) {
      instructions.push("For Recruiting or Staffing Services job posts, GovGig will take care of publishing and managing all the details.")
      instructions.push("When GovGig publishes your job post, our Agents will save and invite relevant candidates who will show up here on this candidates list.")
    }

    return (
      <Box>
        <Box
          sx={{
            pb: 3 
          }}
        >
          <InstructionsPanel
            text={instructions}
          />
        </Box>
        <Box pb={2}>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>
              <Typography variant="h3" color="primary">
                Candidates
              </Typography>
            </Grid>
            <Grid item>
              { this.renderFindTalentButton() }
            </Grid>
          </Grid>
        </Box>
        <Grid container spacing={3}>
          {this.jobCandidates.length === 0 ? 
            <Grid item>
              <Typography variant="body1">
                No candidates saved or invited to this job post yet.
              </Typography>
            </Grid>
          : null}
          {this.jobCandidates.map((jobCandidate: JobCandidate) => {
            return (
              <Grid 
                item 
                key={jobCandidate.id} 
                xs={12} 
                // lg={ showInColumns ? 4 : 12 }
              >
                <JobCandidateCard 
                  jobCandidate={jobCandidate}
                  // jobPost={jobPost}
                  elevation={1}
                  onUpdate={this.handleUpdateJobCandidate}
                  onDelete={this.handleDeleteJobCandidate}
                  onClickTitle={() => {
                    if (onClickJobCandidate) {
                      onClickJobCandidate(jobCandidate) 
                      return 
                    }
                    // Show manage candidate page for candidate activity, notes, messages, contact info, etc. 
                    const profileId = jobCandidate.profileId
                    let searchParams = new URLSearchParams()
                    searchParams.append('profileId', profileId)
                    searchParams.append('jobCandidateId', jobCandidate.id)
                    const tab = 'activity'
                    const route = `${Routes.manageCandidate}/${tab}?${searchParams.toString()}`
                    ControlTower.route(route)
                  }}
                />
              </Grid>
            )
          })}
        </Grid>
      </Box>
    )
  }

  renderJobCandidatesForProfile() {
    const { onClickJobCandidate, onClickManageJobs, showInColumns } = this.props 
    return (
      <Box>
        <Box pb={2}>
          <Grid container justifyContent="space-between">
            <Grid item>
              <Typography variant="h3" color="primary">
                Jobs
              </Typography>
            </Grid>
            { onClickManageJobs && 
              <Grid item>
                { this.renderManageJobs() }
              </Grid>
            }
          </Grid>
        </Box>
        <Grid container spacing={3}>
          { this.isLoading === false && this.jobCandidates.length === 0 && 
            <Grid item>
              <Typography variant="body1">
                To add this candidate to job posts, please click the "Manage Jobs" button.
              </Typography>
            </Grid>
          }
          {this.jobCandidates.length > 0 && 
            this.jobCandidates.map(jobCandidate => {
              return (
                <Grid item key={jobCandidate.id} xs={12} md={ showInColumns ? 4 : 12 }>
                  <JobPostMiniCard 
                    jobPost={jobCandidate.jobPost!}
                    elevation={1}
                    onClickTitle={() => {
                      if (onClickJobCandidate) {
                        onClickJobCandidate(jobCandidate) 
                        return 
                      }
                      // Show manage job page, which will show activity, messages, etc. related to the job post. 
                      let searchParams = new URLSearchParams()
                      searchParams.append('jobPostId', jobCandidate.jobPostId)
                      const route = `${Routes.manageJob}/activity?${searchParams.toString()}`
                      ControlTower.route(route)
                    }}
                  />
                </Grid>
              )
            }) 
          }
        </Grid>
      </Box>
    )
  }

  renderFindTalentButton = () => {
    const { 
      onClickFindTalent, 
      jobPost, 
      jobStore, 
      // userStore 
    } = this.props
    if (!jobPost) {
      return null
    }
    let isDisabled = jobStore!.isJobPostUnpublished(jobPost)
    // TODO: Allow Employers to also manage Recruiting Services invites. 
    // https://trello.com/c/xe5HVIVN
    // if (userStore!.isEmployer && jobPost.jobPostType === JobPostType.RecruitingServices) {
    //   isDisabled = true 
    // }
    return (
      <AddButton
        text="Find Talent"
        tracking="employerFindTalentForJob"
        buttonColor="secondary"
        buttonVariant="contained"
        buttonSize="medium"
        icon='search'
        click={() => {
          if (onClickFindTalent) {
            onClickFindTalent()
          } 
        }} 
        disabled={isDisabled}
      />
    )
  }

  renderFindJobs = () => {
    const { onClickFindJobs } = this.props 
    return (
      <AddButton
        text="Add to Jobs"
        tracking="employerFindJobsForCandidate"
        buttonColor="secondary"
        buttonVariant="contained"
        buttonSize="small"
        icon='search'
        click={() => {
          if (onClickFindJobs) {
            onClickFindJobs()
          }
        }} 
      />
    )
  }

  renderManageJobs = () => {
    const { onClickManageJobs } = this.props 

    return (
      <AddButton
        text="Manage Jobs"
        tracking="employerManageJobsForCandidate"
        buttonColor="secondary"
        buttonVariant="contained"
        buttonSize="small"
        icon='search'
        click={() => {
          if (onClickManageJobs) {
            onClickManageJobs()
          }
        }} 
      />
    )
  }

  loadJobCandidates = async () => {
    const { jobStore, jobPost, notify, profile } = this.props
  
    let jobCandidates
    try {
      if (jobPost) {
        jobCandidates = await jobStore!.listJobCandidatesByJobPost(jobPost.id)
      } else if (profile) {
        jobCandidates = await jobStore!.listJobCandidatesByProfile(profile.id)
      }

      if (!jobCandidates) {
        throw new Error('Job candidates undefined')
      } else if (jobPost) {
        // Attach JobPost
        jobCandidates.forEach((jobCandidate: JobCandidate) => jobCandidate.jobPost = jobPost)
      }

      const sorted = jobCandidates.sort(JobCandidate.compareFn)

      this.jobCandidates = sorted 
    } catch (error) {
      Logger.error('Could not fetch job candidates', error)
      notify!.show('error', 'Could not fetch job candidates')
    }
  }

  handleUpdateJobCandidate = async (jobCandidate: JobCandidate) => {
    const { userStore } = this.props

    const index = this.jobCandidates.findIndex(checkJobCandidate => checkJobCandidate.id === jobCandidate.id)
    if (index !== -1) {
      if (jobCandidate.profile && jobCandidate.jobPost) {
        userStore!.logUserActivity(jobCandidate.profile.userId, SubjectType.JobPost, jobCandidate.jobPostId, ActivityType.JobCandidateStatusUpdated,
          `${userStore!.user!.fullName} updated the job post '${jobCandidate.jobPost?.fullName}' status for '${jobCandidate.profile.name}' to ${jobCandidate.status}`)
      }

      this.jobCandidates[index] = jobCandidate
    }
  }

  handleDeleteJobCandidate = async (jobCandidate: JobCandidate) => {
    const index = this.jobCandidates.findIndex(checkJobCandidate => checkJobCandidate.id === jobCandidate.id)
    if (index !== -1) {
      this.jobCandidates.splice(index, 1)
    }
  }
}

export default withStyles(styles)(withWidth()(JobCandidateList))
