// import RoutesConfig from "@/RoutesConfig"
// import ActionButton from "@/components/controls/ActionButton"
// import ExplainerStepsList from "@/components/marketing/ExplainerStepsList"
// import Section from "@/components/marketing/Section"
import { AssignmentInd, HowToReg, PostAdd } from "@material-ui/icons"
import { Box } from "@material-ui/core"
import Section from "../../../components/marketing/sections/Section"
import ExplainerStepsList from "../../../components/marketing/ExplainerStepsList"
import ActionButton from "../../../components/controls/ActionButton"
import ControlTower, { Routes } from "../../../components/ControlTower"

const EmployerProcessSection = () => {
  return (
    <Section
      // background='linear-gradient(145deg, hsl(218, 37%, 37%) 25%, hsl(0, 37%, 37%) 100%)'
      background="linear-gradient(145deg, rgba(25,53,106,0.25) 25%, rgba(215,35,34,0.25) 100%)"
      // backgroundColor={SectionBackgroundColors[50]}
    >
      <ExplainerStepsList
        // title="Simple Employer Process"
        title="Get Started"
        subtitle="In minutes, you can sign up, post your open jobs, and invite candidates."
        steps={[
          {
            avatarIcon: <HowToReg />,
            avatarColor: 'primary',
            heading: "Sign Up",
            text: "Join GovGig with our quick sign-up process."
          }, 
          {
            avatarIcon: <PostAdd />,
            avatarColor: 'secondary',
            heading: "Post Jobs",
            text: "Post your open roles to our job board."
          }, 
          {
            avatarIcon: <AssignmentInd />,
            avatarColor: 'green',
            heading: "Invite & Hire",
            text: "Invite candidates to apply, review them and hire!"
          }
          // {
          //   avatarIcon: <ContactPhone />,
          //   avatarColor: 'primary',
          //   heading: "2. Connect",
          //   text: "Talk to an agent, so we learn about your staffing goals."
          // }, 
          // {
          //   avatarIcon: <AssignmentInd />,
          //   avatarColor: 'green',
          //   heading: "3. Post Jobs & Hire",
          //   text: "Post your open jobs, invite candidates, and hire!"
          // }
        ]}
      >
        <Box pt={7} textAlign="center">
          <ActionButton 
            // route={{
            //   // title: "Sign Up to Staff in Minutes",
            //   title: "Employer Sign Up",
            //   href: RoutesConfig.jobsAppTalentSignup.href
            // }}
            text="Employer Sign Up"
            tracking="Employer-Process-Sign-Up"
            click={() => {
              ControlTower.route(Routes.signup)
            }}
            showFowardArrow
            size="large"
          />
        </Box>
      </ExplainerStepsList>
    </Section>
  )
}

export default EmployerProcessSection
