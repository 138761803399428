import {
  createStyles,
  Theme,
  withStyles,
  WithStyles,
  withTheme,
  WithTheme,
  withWidth,
  WithWidth
} from "@material-ui/core";
import { makeObservable, observable, reaction } from "mobx";
import { inject, observer } from "mobx-react";
import React from "react";
import SelectionsDialog, { ListHeaderViewModel, ListItemViewModel, ListSectionViewModel, SelectionsDialogViewModel } from "../../../components/selection/SelectionsDialog";
import Account from "../../../model/Account";
import AccountStore from "../../../stores/AccountStore";
import ResourceCache from "../../../stores/ResourceCache";
import JobStore from "../../../stores/JobStore";

const styles = (theme: Theme) => createStyles({

})

export interface IJobPickerDialogProps {
  accountStore?: AccountStore
  jobStore?: JobStore
  resourceCache?: ResourceCache
  isDialogOpen: boolean
  account: Account
  onClose: any
  onSubmitSelections: any
  isProcessing: boolean
}

@inject("accountStore", "jobStore", "resourceCache")
@observer
class JobPickerDialog extends React.Component<WithStyles<typeof styles> & WithTheme & WithWidth & IJobPickerDialogProps> {
  @observable viewModel?: SelectionsDialogViewModel

  constructor(
    props: WithStyles<typeof styles> & WithTheme & WithWidth & IJobPickerDialogProps
  ) {
    super(props);
    makeObservable(this);
  }

  componentDidMount() {
    reaction(() => this.props.isDialogOpen, this.loadViewModel)
  }

  render() {
    if (!this.viewModel) {
      return null
    }
    return (
      <SelectionsDialog
        viewModel={this.viewModel}
        onClose={this.props.onClose}
        onSubmitSelections={this.props.onSubmitSelections}
        isProcessing={this.props.isProcessing}
      />
    )
  }

  loadViewModel = async () => {
    const { accountStore, jobStore, account, resourceCache } = this.props

    // let selectedServiceIds: string[] = []
    let sections: ListSectionViewModel[] = []

    const contracts = await jobStore!.listContractsByAccount(account.id)
    await Promise.all(contracts.map(async (contract) => {
      let items: ListItemViewModel[] = []
      await Promise.all(contract.jobPosts.map(async (jobPost) => {
        const service = await resourceCache!.getService(jobPost.serviceId)
        if (!service) { return }
        const item = new ListItemViewModel({
          id: jobPost.id,
          title: service.name,
          isCheckable: true,
          isChecked: false,
          // isChecked: selectedServiceIds.includes(service.id)
        })
        items.push(item)
      }))
      items.sort((a: ListItemViewModel, b: ListItemViewModel) => a.title.localeCompare(b.title))

      const section = new ListSectionViewModel({
        header: new ListHeaderViewModel({
          id: contract.id,
          title: contract.name,
          isCheckable: false,
          isExpandable: true
        }),
        items
      })
      sections.push(section)
    }))

    sections.sort((a: ListSectionViewModel, b: ListSectionViewModel) => a.header.title.localeCompare(b.header.title))

    let helpText
    if (contracts.length === 0) {
      if (accountStore?.isPrimary) {
        helpText = "No contracts found. Please add a contract or select another account."
      } else {
        helpText = "No contracts found. Please add a contract"
      }
    } else {
      helpText = "Select a contract and job post for this candidate."
    }

    this.viewModel = new SelectionsDialogViewModel({
      isDialogOpen: this.props.isDialogOpen,
      title: "Save Candidate",
      helpText: helpText,
      sections
    })
  }
}

export default withTheme(withStyles(styles)(withWidth()(JobPickerDialog)))