import { navigate, RouteComponentProps } from "@reach/router";
import { inject } from "mobx-react";
import * as React from "react";
import LoadingPage from "../pages/LoadingPage";
import LoginPage from "../pages/LoginPage";
import NotFoundPage from "../pages/NotFoundPage";
import UserStore from "../stores/UserStore";
import Tracking from "./Tracking";

export enum Routes {
  account = "/account",
  accountAgreements = "/account/agreements",
  accountBilling = "/account/billing",
  accountPending = "/account-pending",
  accountPeople = "/account/people",
  accountSettings = "/account/settings",
  apply = "/apply",
  betaSignup = "/betasignup",
  // candidates = "/talent", // Use /find-talent instead.
  dashboard = "/dashboard",
  findJobs = "/find-jobs",
  findOpportunity = "/find-opportunity",
  findTalent = "/find-talent",
  index = "/index.html",
  // jobs = "/jobs", // Use /find-jobs instead 
  job = "/job",
  jobGroups = "/job-groups",
  jobPostCreate = "/create-job-posts",
  jobPostPaymentCart = "/job-post-payment-cart",
  join = "/join",
  login = "/login",
  loading = "/loading",
  manage = "/manage",
  manageAccounts = "/manage/accounts",
  manageApplicants = "/manage/applicants",
  manageCandidates = "/manage/candidates",
  manageCandidate = "/manage-candidate",
  manageJobs = "/manage-jobs",
  manageJob = "/manage-job",
  managePeople = "/manage/people",
  msa = "/msa",
  myJobs = "/my-jobs",
  msaExclusive = "/msaExclusive",
  nda = "/nda",
  notFound = "/404",
  privacy = "/privacy",
  privacyCCPA = "/privacy/ccpa",
  profile = "/profile",
  register = "/register",
  requestMeeting = "https://meetings.hubspot.com/julia-keys/julia-keys-30-min-meeting-",
  serviceRequests = "/serviceRequests", 
  serviceRequestCreate = "/create-service-request",
  settings = "/settings",
  signup = "/signup",
  styleGuide = "/styleguide",
  terms = "/terms",
  home = "/", // Note, "home" needs to be last, per Control Tower helper functions. 
}

/**
 * undefined -> allows unauthenticated access
 * isAuthenticated -> requires authentication
 * roles["Admin","Agent","Employer"] -> requires one of the listed roles
 */
const RouteRequirements = {
  [Routes.manage]: { isAuthenticated: true, roles: ["Admin", "Agent"] },
  [Routes.jobGroups]: { isAuthenticated: true, roles: ["Admin", "Agent", "Employer"] },
  [Routes.dashboard] : { isAuthenticated: true, roles: ["Admin", "Agent", "Employer"] },
  [Routes.jobPostPaymentCart]: { isAuthenticated: true, roles: ["Admin", "Agent", "Employer"] },
  [Routes.manageCandidate]: { isAuthenticated: true, roles: ["Admin", "Agent"] },
  [Routes.manageJob]: { isAuthenticated: true, roles: ["Admin", "Agent", "Employer"] },
  [Routes.manageJobs]: { isAuthenticated: true, roles: ["Admin", "Agent", "Employer"] },
  [Routes.settings]: { isAuthenticated: true, roles: ["Admin", "Agent", "Applicant", "Candidate", "Employer", "Student"] },
  [Routes.serviceRequestCreate]: { isAuthenticated: true, roles: ["Admin", "Agent", "Employer"] },
  [Routes.serviceRequests]: { isAuthenticated: true, roles: ["Admin", "Agent", "Employer"] }
}

interface IControlTowerProps {
  userStore?: UserStore
  children?: any
}

@inject("userStore")
class ControlTower extends React.Component<IControlTowerProps & RouteComponentProps> {

  private lastNavigatedRoute = "";

  static route = (to: string, options?: any) => {
    console.log("ControlTower.route to:", to)
    if (to.startsWith("http")) {
      window.location.href = to
    } else {
      navigate(to, options)
    }
  }

  static back = () => {
    window.history.back()
  }

  static open = (to: string, target: string = "_blank") => {
    window.open(to, target)
  }

  static get currentRoute(): string {
    return window.location.pathname
  }

  render() {
    const route = "/" + this.props["*"]

    const { userStore } = this.props

    const search = this.props.location && this.props.location.search ? this.props.location.search : ""

    console.debug("ControlTower", {
      route, 
      search
    })

    if (userStore && userStore!.isLoading) {
      console.log(`Routing ${route} to LoadingPage`)
      return <LoadingPage userStore={userStore} route={route + search} />
    } else if (userStore && !userStore.isAuthenticated && (this.routeRequiresLogin(route) || route === Routes.login)) {
      console.log(`Routing ${route} to LoginPage for authentication`)
      return <LoginPage route={route + search} />
    } else if (userStore && !this.hasRequiredRole(route)) {
      console.log(`Route ${route} requires role`)
      return <NotFoundPage />
    } else if (this.routeExists(route)) {
      console.log(`Routing to ${route}`)
      this.lastNavigatedRoute = route;
      Tracking.pageView(route)
      return (<React.Fragment>{this.props.children}</React.Fragment>)
    } else {
      console.log(`Route ${route} not found`)
      this.lastNavigatedRoute = Routes.notFound;
      return <NotFoundPage />
    }
  }

  private routeExists = (route: string): boolean => {
    const routeMatch = this.findRouteMatch(route)
    if (routeMatch) {
      return true
    }

    return false
  }

  private routeRequiresLogin = (route: string): boolean => {
    // Check if the route has any requirements
    // const routeMatch = Object.values(Routes).find(value => route.startsWith(value))
    const routeMatch = this.findRouteMatch(route)
    if (routeMatch) {
      const routeRequirements = RouteRequirements[routeMatch]
      if (routeRequirements && routeRequirements.isAuthenticated) {
        // console.log(`${route} requires login`)
        return true
      }
    }

    return false
  }

  private hasRequiredRole = (route: string): boolean => {
    const { userStore } = this.props
    // Check if the route has any role requirements
    const routeMatch = this.findRouteMatch(route)
    if (routeMatch) {
      const routeRequirements = RouteRequirements[routeMatch]
      console.debug("hasRequiredRole", {
        routeMatch,
        routeRequirements
      })
      if (routeRequirements && routeRequirements.roles) {
        if (userStore!.user) {
          const role = userStore!.user.role
          if (!routeRequirements.roles.find((r: string) => r === role)) {
            return false
          }
        } else {
          // if no user loaded, then assume the requirement isn't met
          return false
        }
      }
    }
    return true
  }

  private findRouteMatch = (route: string): Routes | null => {
    const matches: Routes[] = []
    Object.values(Routes).forEach(checkRoute => {
      const cleanPathname = route 
      const checkCleanPathname = checkRoute.split("/:")[0] // : undefined // route.path ? route.path.split("/")[1] : ""
      if (checkCleanPathname === "/" && cleanPathname === "/") {
        // Special case 
        matches.unshift(checkRoute)
      } else if (checkCleanPathname && checkCleanPathname !== "/") {
        if (cleanPathname === checkCleanPathname) {
          matches.unshift(checkRoute)
        } else if (cleanPathname.includes(checkCleanPathname)) {
          matches.push(checkRoute)
        }
      } 
    })

    console.debug("findRouteMatch", {
      matches 
    })

    return matches.length > 0 ? matches[0] : null
  }
}

export default ControlTower
