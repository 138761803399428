import { useEffect, useState } from "react"
import SimpleTable, { ITableRow } from "../../components/page/SimpleTable"
import DashboardPanel from "../../components/panel/DashboardPanel"
import JobPost from "../../model/JobPost"
import { useStores } from "../../stores/StoreProvider"
import { when } from "mobx"
import * as APITypes from "../../API"
import { getISODateToday, humanizeString, isoToLocalDateString } from "../../stores/StoreUtilities"
import { Box, Grid } from "@material-ui/core"
import JobPostTypeChip from "./JobPostTypeChip"
import SideDrawer from "../../components/page/SideDrawer"
import ManageJobPage from "../../pages/manageJobs/ManageJobPage"
import JobCandidate from "../../model/JobCandidate"
import ManageCandidatePage from "../../pages/manageCandidates/ManageCandidatePage"

const JobPostsList = ({
  showNavButton,
  handleNavButton 
}: {
  showNavButton?: boolean 
  handleNavButton?: () => void 
}) => {
  // const router = useRouter()
  const { accountStore, jobStore, progress, userStore } = useStores()

  const progressName = "JobPostsList"
  const [jobPosts, setJobPosts] = useState<JobPost[]>()
  const [showDialog, setShowDialog] = useState(false)
  const [jobPost, setJobPost] = useState<JobPost>()
  const [jobCandidate, setJobCandidate] = useState<JobCandidate>()

  useEffect(() => {
    progress.show(progressName) 
    const init = async () => {
      let accountId 
      let jobPosts: JobPost[] = []
      const todaysISODate = getISODateToday()
      const filter: APITypes.ModelJobPostFilterInput = {
        or: [
          { status: {eq: APITypes.JobPostStatus.Draft} },
          { and: [
            {openDate: {le: todaysISODate}},
            { or: [
              {closedDate: {attributeExists: false}},
              {closedDate: {ge: todaysISODate}}
            ]},
            { status: {ne: APITypes.JobPostStatus.Closed} }
          ]}
        ]
      }
      if (userStore.isAdminOrAgent) {
        jobPosts = await jobStore.listJobPosts(filter, 100)
      } else if (accountStore.account) {
        accountId = accountStore.account.id 
        jobPosts = await jobStore.listJobPostsByAccount(accountId, filter)
      }
      console.debug('jobposts', jobPosts.length)
      jobPosts.sort((a: JobPost, b: JobPost) => {
        return b.updatedAt.localeCompare(a.updatedAt)
      })
      setJobPosts(jobPosts.slice(0, 5))
      progress.hide(progressName)
    }

    when(
      () => accountStore.isLoading === false 
        && jobStore.isLoading === false
        && userStore.isLoading === false, 
      () => {
        init() 
      }
    )
  }, [
    accountStore, 
    jobStore, 
    progress, 
    userStore
  ])
  
  const renderTable = () => {
    let columns
    if (userStore.isAdminOrAgent) {
      columns = [
        "Type",
        "Company",
        "Location",
        "Employment Type",
        "Urgency",
        "Service Request",
        "Status"
      ]
    } else {
      columns = [
        "Type",
        "Location",
        "Employment Type",
        "Urgency",
        "Service Request",
        "Status"
      ]
    }
    return (
      <SimpleTable 
        head={{
          id: "heading",
          name: "Title",
          cells: columns
        }}
        data={ jobPosts ? jobPosts.map((jobPost: JobPost) => {
          const {
            title, 
            location,
            employmentType,
            serviceRequest,
            // status,
            account
          } = jobPost
          const hiringPeriod = jobPost.hiringPeriod
          let statusDate = ""
          if (jobPost.status === APITypes.JobPostStatus.Cart) {
            statusDate = `Cart ${isoToLocalDateString(jobPost.createdAt, "M/d/yyyy h:mm a")}`
          } else if (jobPost.status === APITypes.JobPostStatus.Draft) {
            statusDate = `Draft ${isoToLocalDateString(jobPost.updatedAt, "M/d/yyyy h:mm a")}`
          } else {
            statusDate = `${jobPost.status} ${isoToLocalDateString(jobPost.updatedAt, "M/d/yyyy h:mm a")}`
          }
          let dataColumns 
          if (userStore.isAdminOrAgent) {
            dataColumns = [
              <JobPostTypeChip jobPost={jobPost} />,
              account!.name, 
              location!.name, 
              humanizeString(employmentType),
              hiringPeriod,
              serviceRequest?.name ?? "",
              statusDate
            ]
          } else {
            dataColumns = [
              <JobPostTypeChip jobPost={jobPost} />,
              location!.name, 
              humanizeString(employmentType),
              hiringPeriod,
              serviceRequest?.name ?? "",
              statusDate
            ]
          }
          const jobPostData = {
            id: jobPost.id,
            name: title,
            cells: dataColumns
          }
          return jobPostData
        }): []
        }
        onClickRow={(row: ITableRow) => {
          // const contentBlock = contentBlocks?.find(checkContentBlock => checkContentBlock.id === row.id)
          // if (onClickContentBlock) {
          //   onClickContentBlock(contentBlock!)
          // } else {
          //   const url = `${RoutesConfig.contentBlock.pathname!}/${contentBlock!.id}`
          //   router.push(url)
          // }
          const jobPostId = row.id
          const jobPost = jobPosts?.find(jobPost => jobPost.id === jobPostId)
          setJobPost(jobPost)
        }}
      
      />
    )
  }

  const renderJobPostLargeDrawer = () => {
    return (
      <SideDrawer 
        size="large"
        title={'Manage Job Post'} 
        isOpen={jobPost !== undefined} 
        onClose={() => {
          setJobPost(undefined)
        }}        
      >
        {jobPost && 
          <ManageJobPage
            embed 
            jobPostId={jobPost!.id}
            onClickJobCandidate={(jobCandidate: JobCandidate) => {
              setJobCandidate(jobCandidate)
            }}
            onUpdateJobPost={async (jobPost: JobPost) => {
              // await this.handleUpdateJobPost(jobPost)
              const jobPostsCopy: JobPost[] = Object.assign([], jobPosts)
              const index: number = jobPostsCopy.findIndex((checkJobPost: JobPost) => checkJobPost.id === jobPost.id)
              if (index !== -1) {
                jobPostsCopy[index] = jobPost 
                setJobPosts(jobPostsCopy)
              }
            }}
          /> 
        }
      </SideDrawer>
    )
  }

  const renderManageCandidateDrawer = () => {
    if (jobCandidate === undefined) {
      return null
    }

    return (
      <SideDrawer
        title={"Manage Candidate"}
        isOpen={jobCandidate !== undefined}
        onClose={async () => {
          setJobCandidate(undefined) 
        }}
        size="large"
      >
        <Box pt={0} pb={2}>
          { jobCandidate && 
            <ManageCandidatePage 
              embed={true} 
              profileId={jobCandidate!.profileId} 
              tab="profile"
              jobCandidate={jobCandidate}
              onUpdateJobCandidate={(updatedJobCandidate: JobCandidate) => {
                setJobCandidate(updatedJobCandidate)
                const jobPostCopy = Object.assign({}, jobPost)
                const jobCandidateIndex = jobPostCopy.jobCandidates.findIndex(checkJobCandidate => checkJobCandidate.id === updatedJobCandidate.id)
                if (jobCandidateIndex > -1) {
                  jobPostCopy.jobCandidates[jobCandidateIndex] = updatedJobCandidate
                  setJobPost(jobPostCopy)
                }
              }}
            />
          }
        </Box>
      </SideDrawer>
    )
  }

  return (
    <DashboardPanel
      title="Job Posts"
      elevation={0}
      showNavButton={showNavButton}
      handleNavButton={handleNavButton}
    >
      { renderTable() }
      { renderJobPostLargeDrawer() }
      { renderManageCandidateDrawer() }
    </DashboardPanel>
  )
}

export default JobPostsList
