import * as React from "react";
import Answer from "../../model/Answer";
import {Chip, makeStyles} from "@material-ui/core";
import PassIcon from '@material-ui/icons/Check';
import FailIcon from '@material-ui/icons/Clear';
import InfoIcon from '@material-ui/icons/Remove';

const useStyles = makeStyles((theme) => ({
  pass: {
    color: theme.palette.success.dark,
    borderColor: theme.palette.success.dark,
    paddingTop: 2,
    marginRight:8,
    marginBottom:8
  },
  passIcon: {
    paddingBottom: 2
  },
  fail: {
    color: theme.palette.error.main,
    borderColor: theme.palette.error.main,
    paddingTop: 2,
    marginRight:8,
    marginBottom:8
  },
  failIcon: {
    paddingBottom: 2
  },
  info : {
    color: theme.palette.text.secondary,
    borderColor: theme.palette.text.secondary,
    paddingTop: 2,
    marginRight:8,
    marginBottom:8
  },
  infoIcon: {
    width: 13,
    paddingBottom: 2
  }
}));

interface IAnswerChip {
  answer: Answer
}

const AnswerChip = (props: IAnswerChip) => {
  const { answer } = props

  const classes = useStyles();

  let icon
  let classNames

  if (answer.pass) {
    icon = <PassIcon/>
    classNames = {colorPrimary: classes.pass, icon: classes.passIcon}
  } else {
    if (answer.required) {
      icon = <FailIcon/>
      classNames = {colorPrimary: classes.fail, icon: classes.failIcon}
    } else {
      icon = <InfoIcon/>
      classNames = {colorPrimary: classes.info, icon: classes.infoIcon}
    }
  }
  return (
    <Chip icon={icon} label={answer.label} size="small" color="primary"
          variant="outlined" classes={classNames}/>
  )
}

export default AnswerChip