import { Box, Grid, Typography, useMediaQuery, useTheme } from "@material-ui/core"

const TwoPanelSectionContainer = ({
  title,
  note, 
  children,
  rightPanel  
}: {
  title: string 
  note?: string 
  children: React.ReactNode
  rightPanel?: React.ReactNode 
}) => {
  const theme = useTheme()
  const isMedium = useMediaQuery(theme.breakpoints.down('md'))
  return (
    <Box
      bgcolor="grey.200"
      borderRadius={10}      
      sx={{
        py: 3,
        px: 1,
        width: '100%'
      }}
    >
      <Grid container justifyContent={ rightPanel ? "space-between" : "center"} spacing={3}>
        <Grid 
          item 
          xs={12}
          md={12}
          lg={7}
        >
          <Box
            sx={{
              pl: 2,
              pr: isMedium === false ? 0 : 2
            }}
          >
            <Typography align="center" color="primary" variant="h5" gutterBottom>{ title }</Typography>
            { note && 
              <Box sx={{ pb: 2 }}>
                <Typography align="center" variant="body2" gutterBottom>{ note }</Typography>
              </Box>
            }
            { children }
          </Box>
        </Grid>
        { rightPanel && 
          <Grid item xs={12} md={12} lg={5}>
            <Box
              sx={{
                pl: isMedium === false ? 3 : 2,
                pr: 2,
                my: 3, 
                borderLeft: isMedium === false ? "1px solid #ccc" : undefined 
              }}
            >
              { rightPanel }
            </Box>
          </Grid>
        } 
      </Grid>
    </Box>
  )
}

export default TwoPanelSectionContainer
