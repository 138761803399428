import { Box, createStyles, Grid, makeStyles, Theme, Typography, useMediaQuery, useTheme } from "@material-ui/core"
import React from "react"
import { Virtuoso } from "react-virtuoso"

const useStyles = makeStyles((theme: Theme) => createStyles({
  column: {
    height: '100%',
    backgroundColor: theme.palette.grey[500],
    borderRadius: 10,
    [theme.breakpoints.up('sm')]: {
      marginRight: theme.spacing(2),
    }
  },
  listContainer: {
  },
  virtualList: {
    marginBottom: theme.spacing(2)
  },
  row: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  columnHeader: {
    padding: theme.spacing(1)
  },
  title: {
    paddingLeft: theme.spacing(1),
  },
  count: {
    textAlign: "right",
    paddingRight: theme.spacing(1)
  },
}))

export interface IRow {
  id: string 
}

export interface IColumn {
  id: string
  title: string
  rows: IRow[]
}

export interface IColumnOptions {
  columnWidth?: any
  renderCard: (
    row: IRow
  ) => React.ReactElement<HTMLElement>
}

const Column = ({
  column, 
  options 
}: {
  column: IColumn 
  options: IColumnOptions 
}) => {
  const classes = useStyles(options)
  const theme = useTheme()
  const isSmall = useMediaQuery(theme.breakpoints.down('xs'))
  
  const renderVirtualList = () => {
    return (
      <Virtuoso
        data={column.rows}
        style={{ 
          width: isSmall ? "100%" : options.columnWidth ? options.columnWidth : 360, 
          height: isSmall ? "calc(100vh - 182px)" : "calc(100vh - 270px)"
        }}
        className={classes.virtualList}
        itemContent={(index, item) => {
          return (
            <Grid key={item.id} item xs={12} className={classes.row}>
              {options.renderCard && options.renderCard(item)}
            </Grid>
          )
        }}
      />
    )
  }

  return (
    <Box 
      className={classes.column} 
    >
      <Grid container direction="row" justifyContent="space-between" alignItems="center" className={classes.columnHeader}>
        <Grid item xs={9}>
          <Typography variant="h6" className={classes.title}>{column.title}</Typography>
        </Grid>
        <Grid item xs={3} className={classes.count}>
          <Typography variant="body2">{column.rows.length}</Typography>
        </Grid>
      </Grid>
      <Box
        className={classes.listContainer}
      >
        {renderVirtualList()}
      </Box>
    </Box>
  )
}

export default Column 
