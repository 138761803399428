import React from "react";
import {
  TextField,
  withWidth,
  WithWidth
} from "@material-ui/core";
import { createStyles, Theme, WithStyles, WithTheme } from "@material-ui/core";
import { withStyles, withTheme } from "@material-ui/core/styles";
import { Autocomplete } from "@material-ui/lab";
import { makeObservable, observable } from "mobx";
import ResourceCache from "../../stores/ResourceCache";
import {inject, observer} from "mobx-react";

const styles = (theme: Theme) => createStyles({
  filterTextField: {
    // maxWidth: 200,
    flexGrow: 1, 
    backgroundColor: theme.palette.background.paper,
    borderRadius: 10
  }
})

export interface ISecurityClearanceFilterProps {
  onSelectSecurityClearance: (securityClearance?: string) => any
  value?: string
  variant?: 'filled' | 'outlined' | 'standard'

  resourceCache?: ResourceCache
}

@inject("resourceCache")
@observer
class SecurityClearanceFilter extends React.Component<WithStyles<typeof styles> & WithTheme & ISecurityClearanceFilterProps & WithWidth> {

  @observable securityClearanceFilter?: string

  constructor(props: any) {
    super(props)
    makeObservable(this)
  }

  componentDidMount() {
    const { value } = this.props
    this.securityClearanceFilter = value
  }

  render() {
    const { classes, resourceCache } = this.props

    const variant = this.props.variant ?? 'outlined'
    const margin = 'none'

    const securityClearances = resourceCache!.listSecurityClearances()

    return (
      <Autocomplete
        autoSelect
        includeInputInList
        options={securityClearances}
        onChange={this.onSelectSecurityClearance}
        value={this.securityClearanceFilter ?? null}
        renderInput={(params: any) =>
          <div ref={params.InputProps.ref}>
            <TextField
              {...params}
              className={classes.filterTextField}
              variant={variant}
              margin={margin}
              size="small"
              name="securityClearance"
              label="Security Clearance"
            />
          </div>
        }
      />
    )
  }

  onSelectSecurityClearance = (event: any, value: any, reason: string) => {
    this.securityClearanceFilter = value
    this.props.onSelectSecurityClearance(this.securityClearanceFilter)
  }
}

export default withTheme(withStyles(styles)(withWidth()(SecurityClearanceFilter)))