import { useEffect, useState } from "react"
import TextFieldValidator from "../../components/form/TextFieldValidator"
import Contract from "../../model/Contract"
import { Box, IconButton, InputAdornment } from "@material-ui/core"
import Account from "../../model/Account"
import ContractForm from "../../pages/contracts/ContractForm"
import Add from "@material-ui/icons/Add"
import { useStores } from "../../stores/StoreProvider"
import { when } from "mobx"

const ContractField = ({
  value,
  account,
  required, 
  onSelectContract, 
}: {
  value: Contract | undefined 
  account: Account
  required: boolean 
  onSelectContract: (contract: Contract) => void 
}) => {
  const { accountStore, jobStore, userStore } = useStores()
  
  const [isLoading, setIsLoading] = useState(true)
  const [contracts, setContracts] = useState<Contract[]>([])
  const [contract, setContract] = useState<Contract>()
  const [showContractForm, setShowContractForm] = useState(false)

  useEffect(() => {
    const init = async () => {
      const contracts = await jobStore.listContractsByAccount(account.id)
      setContracts(contracts)
      setIsLoading(false)
    }

    when(
      () => accountStore.isLoading === false 
        && jobStore.isLoading === false 
        && userStore.isLoading === false, 
      () => {
        init() 
      }
    )
  }, [
    accountStore, 
    jobStore, 
    userStore,
  ])

  const handleChangeContractSelection = (event: any, value: any, reason: any) => {
    const selection = value as Contract
    onSelectContract(selection)
  }

  return (
    <Box>
      <TextFieldValidator
        type="text"
        validators={{ required: required }}
        name="contract"
        label="Job Group (Contract or Project)"
        margin="dense"
        autocompleteOptions={{
          freeSolo: false,
          options: contracts,
          value: value ?? null,
          getOptionLabel: (option: Contract) => option?.name,
          getOptionSelected: (option: Contract, value: Contract) => option.id === value.id,
          onChange: handleChangeContractSelection
        }}
        InputProps={{
          endAdornment: 
          <InputAdornment position="end">
            <IconButton 
              onClick={() => {
              const contract = new Contract({
                accountId: account.id
              })
              setContract(contract)
              setShowContractForm(true)
            }}>
              <Add />
            </IconButton>
          </InputAdornment>,
        }}
      />
      <ContractForm 
        accounts={ undefined } // Pass undefined here b/c we want the account to be tied to the account for this job post.
        contract={contract}
        isFormOpen={showContractForm} 
        didClose={() => {
          setShowContractForm(false)
        }}
        didCreate={async (contract: Contract) => {
          // this.contracts.push(contract)
          // this.contract = contract 
          setIsLoading(true)
          const contractsCopy = Object.assign([], contracts)
          contractsCopy.push(contract)
          setContracts(contractsCopy)
          // await this.loadContracts(this.viewModel!.accountId)
          handleChangeContractSelection(undefined, contract, undefined)
          setIsLoading(false) 
        }}
        didDelete={(contract: Contract) => {
          const index = contracts.findIndex(checkContract => checkContract.id === contract.id)
          if (index !== -1) {
            const contractsCopy = Object.assign([], contracts)
            contractsCopy.splice(index, 1)
            setContracts(contractsCopy)
          }
        }}
        didEdit={(contract: Contract) => {
          const index = contracts.findIndex(checkContract => checkContract.id === contract.id)
          if (index !== -1) {
            const contractsCopy: Contract[] = Object.assign([], contracts)
            contractsCopy[index] = contract
            setContracts(contractsCopy)
          }
        }}
      />
    </Box>
  )
}

export default ContractField