import React from "react";
import {alpha, Grid, IconButton, Typography} from "@material-ui/core";
import {createStyles, Theme, WithStyles, WithTheme} from "@material-ui/core";
import {withStyles, withTheme} from "@material-ui/core/styles";
import classNames from 'classnames';

const styles = (theme: Theme) => createStyles({
  editButton: {
    padding: 4,
    color: '#fff',
    boxShadow: 'rgba(0, 0, 0, 0.25) 0px 1px 3px 0px',
    backgroundColor: theme.palette.secondary.light,
    "&:hover": {
      backgroundColor: alpha(theme.palette.secondary.light, 0.7),
    },
    "&:disabled": {
      backgroundColor: alpha(theme.palette.secondary.light, 0.1),
    }
  },
})

export interface IIconicButtonProps {
  className?: string
  onClick?: any
  disabled?: boolean
  label?: string 
  color?: 'inherit' | 'primary' | 'secondary'
  labelColor?: 'textPrimary' | 'textSecondary' | 'inherit'
}

class IconicButton extends React.Component<WithStyles<typeof styles> & WithTheme & IIconicButtonProps> {

  render() {
    const { label } = this.props
    
    return (
      <React.Fragment>
        { label ? this.renderButtonWithLabel() : this.renderButton() }
      </React.Fragment>
    )
  }

  renderButton = () => {
    const { classes, className, color, onClick, disabled } = this.props
    let styles = [classes.editButton]
    if (className) {
      styles.push(className)
    }
    return (
      <IconButton
        aria-label="Edit"
        color={ color ? color : 'primary' }
        className={classNames(styles)}
        onClick={onClick}
        disabled={disabled}
      >
        {this.props.children}
      </IconButton>
    )
  }

  renderButtonWithLabel = () => {
    const { label, labelColor } = this.props 
    return (
      <Grid container direction="column" justifyContent="center" alignItems="center" spacing={1}>
        <Grid item>
          {this.renderButton()}
        </Grid>
        <Grid item>
          <Typography variant="body2" color={ labelColor ? labelColor : 'textPrimary' }>
            {label}
          </Typography>
        </Grid>
      </Grid>
    )
  }
}

export default withTheme((withStyles(styles)(IconicButton)))