import * as React from "react";
import { createStyles, Theme, WithStyles, withStyles } from '@material-ui/core/styles'
import TextField, {TextFieldProps} from "@material-ui/core/TextField";
import {IFieldValidator} from "./FormValidator";
import {inject} from "mobx-react";
import {FormControlProps} from "@material-ui/core/FormControl";
import {Menu, MenuItem} from "@material-ui/core";


const styles = (theme: Theme) => createStyles({
  root: {
    marginBottom: theme.spacing(1)
  }
})

interface ITextMenuFieldValidatorProps {
  error?: boolean
  helperText?: React.ReactNode
  label?: React.ReactNode
  required?: boolean
  onBlur?: any // React.EventHandler<any>
  onChange?: any // React.EventHandler<any>
  formvalidator?: any
  validate?: any
  className?: string,
  values: string[]
}

export type TextMenuFieldValidatorProps = TextFieldProps & ITextMenuFieldValidatorProps & IFieldValidator

@inject("formvalidator")
class TextMenuFieldValidator extends React.Component<WithStyles<typeof styles> & TextMenuFieldValidatorProps > {

  state = {
    isValid: true,
    errorMessage: "",
    anchorEl: null
  }

  componentDidMount() {
    const {formvalidator} = this.props as ITextMenuFieldValidatorProps
    if (formvalidator) {
      formvalidator.attachToForm(this)
    }
  }

  render() {
    const {
      className,
      error,
      onBlur,
      onChange,
      values,
      ...rest
    } = this.props as ITextMenuFieldValidatorProps;

    return (
      // <TextField
      //   {...rest}
      //   className={this.props.className ?? this.props.classes.field}
      //   onBlur={this.onBlur}
      //   onChange={this.onChange}
      //   error={!this.state.isValid || this.props.error}
      //   helperText={((!this.state.isValid || this.props.error) && this.state.errorMessage) || this.props.helperText}
      // />

      <div>
        <TextField {...rest}
                   className={this.props.className ?? this.props.classes.root}
                   onBlur={this.onBlur}
                   onChange={this.onChange}
                   error={!this.state.isValid || this.props.error}
                   onClickCapture={(e) => this.setState({ anchorEl: e.currentTarget})}
                   helperText={((!this.state.isValid || this.props.error) && this.state.errorMessage) || this.props.helperText}
                   InputLabelProps={{ shrink:  typeof this.props.value === 'string' && this.props.value !== "" }}
        />
        <Menu
          keepMounted
          open={Boolean(this.state.anchorEl)}
          disableBackdropClick={false}
          onClose={() => this.setState({anchorEl: null}) }
          anchorEl={this.state.anchorEl}
          style={{ marginTop: '3rem' }}>
          {values.map((value, i) => {
            return <MenuItem
              key={i}
              onClick={() => { this.onClickMenuItem(value) }}>
              {value}
            </MenuItem>
          })}
        </Menu>
      </div>
    )
  }

  private onBlur = (event: React.FocusEvent<HTMLInputElement>): void => {
    const {value} = event.target;
    const {props} = this
    const {onBlur} = props as FormControlProps

    this.validate(value)

    if (onBlur) {
      onBlur(event);
    }
  }

  private onClickMenuItem(value: string) {
    const { onChange } = this.props
    this.validate(value)
    if (onChange) {
      onChange({target: {name: this.props.name, value: value}} as React.ChangeEvent<HTMLInputElement>)
    }
    this.setState({anchorEl: null})
  }

  private onChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const {value} = event.target;
    const {props} = this
    const {onChange} = props as FormControlProps

    if (!this.state.isValid) {
      this.validate(value)
    }

    if (onChange) {
      onChange(event);
    }
  }

  validate = (value: string): boolean => {
    const { required } = this.props
    let isValid = true
    let errorMessage = ""
    if (required && value === "") {
      isValid = false
      errorMessage = `${this.props.label} is required`
    }

    this.setState({isValid, errorMessage})

    return isValid
  }
}

export default withStyles(styles)(TextMenuFieldValidator)


/*

<TextField fullWidth required
          label="Certification Type"
          onChange={({ target: { value } }) => this.certificationType = value}
          onClickCapture={(e) => this.anchorEl = e.currentTarget}
          value={this.certificationType}
        />
        <Menu
          keepMounted
          open={Boolean(this.anchorEl)}
          disableBackdropClick={false}
          onClose={() => this.anchorEl = null}
          anchorEl={this.anchorEl}
          style={{ marginTop: '3rem' }}>
          {Object.values(Certification.types).map(({ label }, i) => {
            return (
              <MenuItem
                key={i}
                onClick={() => {
                  this.certificationType = label
                  this.anchorEl = null
                }}>
                {label}
              </MenuItem>
            )
          })}
        </Menu>
 */