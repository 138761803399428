

function buildCertType(label: string) {
  return { label }
}

export class Certification {
  id: string
  createdAt: string
  updatedAt: string
  userId: string
  profileId: string
  certificationType: string
  issuedAt: string
  expiresAt: string
  certificateKey: string

  constructor(data: any) {
    this.id = data.id
    this.createdAt = data.createdAt
    this.updatedAt = data.updatedAt
    this.userId = data.userId
    this.profileId = data.profileId
    this.certificationType = data.certificationType
    this.issuedAt = data.issuedAt
    this.expiresAt = data.expiresAt
    this.certificateKey = data.certificateKey
  }
}

export default Certification