/* eslint-disable @typescript-eslint/no-unused-vars */
import React from "react";
import {
  TextField,
  withWidth,
  WithWidth
} from "@material-ui/core";
import { createStyles, Theme, WithStyles, WithTheme } from "@material-ui/core";
import { withStyles, withTheme } from "@material-ui/core/styles";
import { Autocomplete } from "@material-ui/lab";
import { makeObservable, observable, when } from "mobx";
import { inject, observer } from "mobx-react";
import UserStore from "../../../stores/UserStore";
import AccountStore from "../../../stores/AccountStore";
import Progress from "../../Progress";
import ResourceCache from "../../../stores/ResourceCache";
import Naics from "../../../model/opportunity/Naics";

const styles = (theme: Theme) => createStyles({
  filterTextField: {
    width: "100%",
    flexGrow: 1, 
    backgroundColor: theme.palette.background.paper,
    borderRadius: 10
  }
})

export interface INaicsFilterProps {
  onSelectNaics: (Naics?: Naics) => any
  value?: Naics
  accountStore?: AccountStore
  progress?: Progress
  userStore?: UserStore
  resourceCache?: ResourceCache
}

@inject("accountStore", "userStore", "resourceCache", "progress")
@observer
class NaicsFilter extends React.Component<WithStyles<typeof styles> & WithTheme & INaicsFilterProps & WithWidth> {

  @observable isLoading: boolean = true 
  @observable naicsFilter?: Naics
  @observable Agencys: Naics[] = []

  constructor(props: any) {
    super(props)
    makeObservable(this)
  }

  componentDidMount() {
    const { resourceCache, value } = this.props
    when(
      // once...
      () => resourceCache!.isLoading === false,
      // ... then
      async () => {
        this.naicsFilter = value
        this.isLoading = false
        this.Agencys = (await resourceCache!.getNaicsOptions())
      }
    )
  }

  componentDidUpdate(prevProps: any) {
    if (prevProps.value !== this.props.value) {
      this.naicsFilter = this.props.value
    }
  }

  render() {
    const { classes, resourceCache } = this.props

    if (this.isLoading === true) {
      return null 
    }

    return (
      <Autocomplete
        autoSelect
        includeInputInList
        options={this.Agencys}
        getOptionLabel={(option: Naics) => option.naics_code+'-'+option.naics_title ?? option}
        getOptionSelected={(option: Naics, value: Naics) => value && option.naics_title === value.naics_title}
        // groupBy={(option: Agency) => option.group}
        onChange={this.onSelectNaics}
        value={this.naicsFilter ?? null}
        renderInput={(params: any) =>
          <div ref={params.InputProps.ref}>
            <TextField
              {...params}
              className={classes.filterTextField}
              variant="outlined"
              size="small"
              name="naicsFilter"
              label="NAICS Code"
            />
          </div>
        }
      />
    )
  }

  onSelectNaics = (event: any, value: any, reason: string) => {
    if (value) {
      this.naicsFilter = value
    } else {
      this.naicsFilter = undefined
    }
    this.props.onSelectNaics(this.naicsFilter)
  }
}

export default withTheme(withStyles(styles)(withWidth()(NaicsFilter)))