import { GraphQLResult, GRAPHQL_AUTH_MODE } from '@aws-amplify/api/lib/types/index';
import { API, Auth, graphqlOperation } from "aws-amplify";
import { print as gqlToString } from "graphql";
import * as APITypes from "../API";
import {
  ModelCertificationFilterInput,
  ModelEducationFilterInput,
  ModelExperienceFilterInput,
  ModelProfileFilterInput,
  ModelProfileLocationFilterInput,
  ModelProfileServiceFilterInput, ModelSortDirection,
  UserRole
} from "../API";
import Logger from "../components/Logger";
import { getErrorMessage } from "../stores/StoreUtilities";
import * as CustomMutations from './CustomMutations';
import * as CustomQueries from './CustomQueries';

class GovGigAPI {

  // Account methods

  async listAccounts(filter?: APITypes.ModelAccountFilterInput) {
    const query = gqlToString(CustomQueries.listAccounts)
    const variables = { "filter": filter, "limit": 10000 }
    const data = await this.makeQuery(query, variables)
      .catch(err => {
        Logger.error("GovGigAPI.listAccounts error", err.message, variables)
        throw err
      })
    if (data as APITypes.ListAccountsQuery && (data as APITypes.ListAccountsQuery).listAccounts) {
      return (data as APITypes.ListAccountsQuery).listAccounts
    } else {
      return null
    }
  }

  async getAccount(id: string) {
    const query = gqlToString(CustomQueries.getAccount)
    const variables = { "id": id }
    const data = await this.makeQuery(query, variables)
      .catch(err => {
        Logger.error("GovGigAPI.getAccount error", err.message, variables)
        throw err
      })
    if (data as APITypes.GetAccountQuery && (data as APITypes.GetAccountQuery).getAccount) {
      return (data as APITypes.GetAccountQuery).getAccount
    } else {
      return null
    }
  }

  async createAccount(input: APITypes.CreateAccountInput) {
    const query = gqlToString(CustomMutations.createAccount)
    const variables = { input }
    try {
      const data = await this.makeQuery(query, variables)
      if (data as APITypes.CreateAccountMutation && (data as APITypes.CreateAccountMutation).createAccount) {
        const accountData = (data as APITypes.CreateAccountMutation).createAccount
        return accountData
      } else {
        throw new Error(`Call to create account does not contain data: ${data}`)
      }
    } catch (e) {
      Logger.error("GovGigAPI.createAccount error", getErrorMessage(e), input)
      return null
    }
  }

  async updateAccount(input: APITypes.UpdateAccountInput) {
    const query = gqlToString(CustomMutations.updateAccount)
    const variables = { "input": input }
    const data = await this.makeQuery(query, variables)
      .catch(err => {
        Logger.error("GovGigAPI.updateAccount error", err.message, input)
        throw err
      })
    if (data as APITypes.UpdateAccountMutation) {
      return (data as APITypes.UpdateAccountMutation).updateAccount
    } else {
      return null
    }
  }

  async deleteAccount(id: string) {
    const query = gqlToString(CustomMutations.deleteAccount)
    const input: APITypes.DeleteAccountInput = {
      id
    }
    const data = await this.makeQuery(query, { input })
      .catch(err => {
        Logger.error("GovGigAPI.deleteAccount error", err.message, input)
        throw err
      })
    if (data) {
      return (data as APITypes.DeleteAccountMutation).deleteAccount
    }
    return null
  }

  // User methods

  async listUsers(filter?: APITypes.ModelUserFilterInput, limit?: number, nextToken?: string) {
    const query = gqlToString(CustomQueries.listUsers)
    const variables = { "filter": filter, "limit": limit ?? 10000, "nextToken": nextToken }
    const data = await this.makeQuery(query, variables)
      .catch(err => {
        Logger.error("GovGigAPI.listUsers error", err.message, variables)
        throw err
      })
    if (data as APITypes.ListUsersQuery && (data as APITypes.ListUsersQuery).listUsers) {
      return (data as APITypes.ListUsersQuery).listUsers
    } else {
      return null
    }
  }

  async listUsersByAccount(accountId: string, filter?: APITypes.ModelUserFilterInput, limit?: number, nextToken?: string) {
    const query = gqlToString(CustomQueries.listUsersByAccount)
    const variables = { "accountId": accountId, "filter": filter, "limit": limit ?? 10000, "nextToken": nextToken }
    const data = await this.makeQuery(query, variables)
      .catch(err => {
        Logger.error("GovGigAPI.listUsersByAccount error", err.message, variables)
        throw err
      })
    if (data as APITypes.ListUsersByAccountQuery && (data as APITypes.ListUsersByAccountQuery).listUsersByAccount) {
      return (data as APITypes.ListUsersByAccountQuery).listUsersByAccount
    } else {
      return null
    }
  }

  async listApplicantsByAccount(accountId: string, filter?: APITypes.ModelUserFilterInput, limit?: number, nextToken?: string) {
    const query = gqlToString(CustomQueries.listApplicantsByAccount)
    const variables = { "accountId": accountId, "filter": filter, "limit": limit ?? 10000, "nextToken": nextToken }
    const data = await this.makeQuery(query, variables)
      .catch(err => {
        Logger.error("GovGigAPI.listApplicantsByAccount error", err.message, variables)
        throw err
      })
    if (data as APITypes.ListUsersByAccountQuery && (data as APITypes.ListUsersByAccountQuery).listUsersByAccount) {
      return (data as APITypes.ListUsersByAccountQuery).listUsersByAccount
    } else {
      return null
    }
  }

  async listCandidatesByAccount(accountId: string, filter?: APITypes.ModelUserFilterInput, limit?: number, nextToken?: string) {
    const query = gqlToString(CustomQueries.listCandidatesByAccount)
    const variables = { "accountId": accountId, "filter": filter, "limit": limit ?? 10000, "nextToken": nextToken }
    const data = await this.makeQuery(query, variables)
      .catch(err => {
        Logger.error("GovGigAPI.listApplicantsByAccount error", err.message, variables)
        throw err
      })
    if (data as APITypes.ListUsersByAccountQuery && (data as APITypes.ListUsersByAccountQuery).listUsersByAccount) {
      return (data as APITypes.ListUsersByAccountQuery).listUsersByAccount
    } else {
      return null
    }
  }

  // async listUsersByRoleAndAccount(role: string, accountId: string, filter?: APITypes.ModelUserFilterInput) {
  //   const query = gqlToString(CustomQueries.listUsersByRoleAndAccount)
  //   const variables = { "role": role, "accountId": accountId, "filter": filter, "limit": 10000 }
  //   const data = await this.makeQuery(query, variables)
  //     .catch(err => {
  //       Logger.error("GovGigAPI.listUsersByRoleAndAccount error", err.message, variables)
  //       throw err
  //     })
  //   if (data as APITypes.ListUsersByRoleAndAccountQuery && (data as APITypes.ListUsersByRoleAndAccountQuery).listUsersByRoleAndAccount) {
  //     return (data as APITypes.ListUsersByRoleAndAccountQuery).listUsersByRoleAndAccount
  //   } else {
  //     return null
  //   }
  // }

  async getUser(userId: string) {
    const query = gqlToString(CustomQueries.getUser)
    const variables = { "id": userId }
    const data = await this.makeQuery(query, variables)
      .catch(err => {
        Logger.error("GovGigAPI.getUser error", err.message, variables)
        throw err
      })
    if (data as APITypes.GetUserQuery && (data as APITypes.GetUserQuery).getUser) {
      return (data as APITypes.GetUserQuery).getUser
    } else {
      return null
    }
  }

  async getUserAndProfile(userId: string) {
    const query = gqlToString(CustomQueries.getUserAndProfile)
    const variables = { "id": userId }
    const data = await this.makeQuery(query, variables)
      .catch(err => {
        Logger.error("GovGigAPI.getUserAndProfile error", err.message, variables)
        throw err
      })
    if (data as APITypes.GetUserQuery && (data as APITypes.GetUserQuery).getUser) {
      return (data as APITypes.GetUserQuery).getUser
    } else {
      return null
    }
  }

  async createUser(input: APITypes.CreateUserInput) {
    const query = gqlToString(CustomMutations.createUser)
    const variables = { input }
    try {
      const data = await this.makeQuery(query, variables)

      if (data as APITypes.CreateUserMutation && (data as APITypes.CreateUserMutation).createUser) {
        const userData = (data as APITypes.CreateUserMutation).createUser
        return userData
      } else {
        throw new Error(`Call to create user does not contain data: ${data}`)
      }
    } catch (e) {
      Logger.error("GovGigAPI.createUser error", getErrorMessage(e), input)
      return null
    }
  }

  async updateUser(input: APITypes.UpdateUserInput) {
    if (input.email) {
      input.email = input.email.toLowerCase()
    }
    const query = gqlToString(CustomMutations.updateUser)
    const variables = { "input": input }
    const data = await this.makeQuery(query, variables)
      .catch(err => {
        Logger.error("GovGigAPI.updateUser error", err.message, input)
        throw err
      })
    if (data as APITypes.UpdateUserMutation) {
      return (data as APITypes.UpdateUserMutation).updateUser
    } else {
      return null
    }
  }

  async deleteUser(id: string) {
    const query = gqlToString(CustomMutations.deleteUser)
    const input: APITypes.DeleteUserInput = {
      id
    }
    const data = await this.makeQuery(query, { input })
      .catch(err => {
        Logger.error("GovGigAPI.deleteUser error", err.message, input)
        throw err
      })
    if (data) {
      return (data as APITypes.DeleteUserMutation).deleteUser
    }
    return null
  }


  // Profile methods

  async getUserProfiles(userId: string) {
    const query = gqlToString(CustomQueries.getUserProfiles)
    const variables = { "id": userId }
    const data = await this.makeQuery(query, variables)
      .catch(err => {
        Logger.error("GovGigAPI.getUserProfile error", err.message, variables)
        throw err
      })
    if (data as APITypes.GetUserQuery && (data as APITypes.GetUserQuery).getUser) {
      return (data as APITypes.GetUserQuery).getUser
    } else {
      return null
    }
  }

  async listProfiles(filter?: ModelProfileFilterInput, limit?: number, nextToken?: string) {
    const role = await this.checkRole()
    const query = (role && (role === UserRole.Admin || role === UserRole.Agent)) ?
      gqlToString(CustomQueries.listProfilesPrivate) :
      gqlToString(CustomQueries.listProfilesPublic)
    const variables = { "filter": filter, "limit": limit ?? 10000, "nextToken": nextToken }
    const data = await this.makeQuery(query, variables)
      .catch(err => {
        Logger.error("GovGigAPI.listProfiles error", err.message, variables)
        throw err
      })
    if (data as APITypes.ListProfilesQuery && (data as APITypes.ListProfilesQuery).listProfiles) {
      return (data as APITypes.ListProfilesQuery).listProfiles
    } else {
      return null
    }

  }

  async listProfilesByUser(userId: string, filter?: ModelProfileFilterInput) {
    const query = gqlToString(CustomQueries.listProfilesByUser)
    const variables = { "userId": userId, "filter": filter, "limit": 10000 }
    const data = await this.makeQuery(query, variables)
      .catch(err => {
        Logger.error("GovGigAPI.listProfilesByUser error", err.message, variables)
        throw err
      })
    if (data as APITypes.ListProfilesByUserQuery && (data as APITypes.ListProfilesByUserQuery).listProfilesByUser) {
      return (data as APITypes.ListProfilesByUserQuery).listProfilesByUser
    } else {
      return null
    }

  }

  async listProfilesByAlias(alias: string, filter?: ModelProfileFilterInput) {
    const query = gqlToString(CustomQueries.listProfilesByAlias)
    const variables = { "alias": alias, "filter": filter, "limit": 10000 }
    const data = await this.makeAPIKeyQuery(query, variables)
      .catch(err => {
        Logger.error("GovGigAPI.listProfilesByAlias error", err.message, variables)
        throw err
      })
    if (data as APITypes.ListProfilesByAliasQuery && (data as APITypes.ListProfilesByAliasQuery).listProfilesByAlias) {
      return (data as APITypes.ListProfilesByAliasQuery).listProfilesByAlias
    } else {
      return null
    }

  }

  async listProfilesByService(serviceId: string, filter?: ModelProfileServiceFilterInput) {
    let query
    const role = await this.checkRole()
    if (!role) {
      query = gqlToString(CustomQueries.listPublicProfilesByService)
    } else if (role === UserRole.Admin || role === UserRole.Agent) {
      query = gqlToString(CustomQueries.listProfilesByServicePrivate)
    } else {
      query = gqlToString(CustomQueries.listProfilesByService)
    }
    const variables = { "serviceId": serviceId, "filter": filter, "limit": 10000 }
    const data = role ? await this.makeQuery(query, variables): await this.makeAPIKeyQuery(query, variables)
      .catch(err => {
        Logger.error("GovGigAPI.listProfilesByService error", err.message, variables)
        throw err
      })
    if (data as APITypes.ListProfilesByServiceQuery && (data as APITypes.ListProfilesByServiceQuery).listProfilesByService) {
      return (data as APITypes.ListProfilesByServiceQuery).listProfilesByService
    } else {
      return null
    }
  }

  async listProfilesByLocation(locationId: string, filter?: ModelProfileLocationFilterInput) {
    const role = await this.checkRole()
    let query
    if (!role) {
      query = gqlToString(CustomQueries.listPublicProfilesByLocation)
    } else if (role === UserRole.Admin || role === UserRole.Agent) {
      query = gqlToString(CustomQueries.listProfilesByLocationPrivate)
    } else {
      query = gqlToString(CustomQueries.listProfilesByLocation)
    }
    const variables = { "locationId": locationId, "filter": filter, "limit": 10000 }
    const data = role ? await this.makeQuery(query, variables): await this.makeAPIKeyQuery(query, variables)
      .catch(err => {
        Logger.error("GovGigAPI.listProfilesByLocation error", err.message, variables)
        throw err
      })
    if (data as APITypes.ListProfilesByLocationQuery && (data as APITypes.ListProfilesByLocationQuery).listProfilesByLocation) {
      return (data as APITypes.ListProfilesByLocationQuery).listProfilesByLocation
    } else {
      return null
    }
  }

  async getProfile(profileId: string) {

    let query: string
    const variables = { "id": profileId }
    let data

    if (await this.checkAuthentication()) {
      query = gqlToString(CustomQueries.getProfile)
      console.log("getProfile")
      data = await this.makeQuery(query, variables)
        .catch(err => {
          Logger.error("GovGigAPI.getProfile error", err.message, variables)
          // Ignore and fall back to public view
        })
    }

    if (!data) {
      // Fall back to public view
      query = gqlToString(CustomQueries.getPublicProfile)
      console.log("getPublicProfile")
      data = await this.makeAPIKeyQuery(query, variables)
        .catch(err => {
          Logger.error("GovGigAPI.getPublicProfile error", err.message, variables)
          throw err
        })
    }

    if (data as APITypes.GetProfileQuery && (data as APITypes.GetProfileQuery).getProfile) {
      return (data as APITypes.GetProfileQuery).getProfile
    } else {
      return null
    }
  }

  async createProfile(input: APITypes.CreateProfileInput) {
    const query = gqlToString(CustomMutations.createProfile)
    const variables = { input }
    try {
      const data = await this.makeQuery(query, variables)

      if (data as APITypes.CreateProfileMutation && (data as APITypes.CreateProfileMutation).createProfile) {
        const profileData = (data as APITypes.CreateProfileMutation).createProfile
        return profileData
      } else {
        throw new Error(`Call to create profile does not contain data: ${data}`)
      }
    } catch (e) {
      Logger.error("GovGigAPI.createProfile error", getErrorMessage(e), input)
      return null
    }
  }

  async updateProfile(input: APITypes.UpdateProfileInput) {
    const query = gqlToString(CustomMutations.updateProfile)
    const variables = { "input": input }
    const data = await this.makeQuery(query, variables)
      .catch(err => {
        Logger.error("GovGigAPI.updateProfile error", err.message, input)
        throw err
      })
    if (data as APITypes.UpdateProfileMutation) {
      return (data as APITypes.UpdateProfileMutation).updateProfile
    } else {
      return null
    }
  }

  async deleteProfile(id: string) {
    const query = gqlToString(CustomMutations.deleteProfile)
    const input: APITypes.DeleteProfileInput = {
      id
    }
    const data = await this.makeQuery(query, { input })
      .catch(err => {
        Logger.error("GovGigAPI.deleteProfile error", err.message, input)
        throw err
      })
    if (data) {
      return (data as APITypes.DeleteProfileMutation).deleteProfile
    }
    return null
  }

  // Experience methods

  async listExperiencesByProfile(profileId: string, filter?: ModelExperienceFilterInput) {
    const query = gqlToString(CustomQueries.listExperiencesByProfile)
    const variables = { "profileId": profileId, "filter": filter, "limit": 10000 }
    const data = await this.makeQuery(query, variables)
      .catch(err => {
        Logger.error("GovGigAPI.listExperiencesByProfile error", err.message, variables)
        throw err
      })
    if (data as APITypes.ListExperiencesByProfileQuery && (data as APITypes.ListExperiencesByProfileQuery).listExperiencesByProfile) {
      return (data as APITypes.ListExperiencesByProfileQuery).listExperiencesByProfile
    } else {
      return null
    }

  }

  async getExperience(experienceId: string) {
    const query = gqlToString(CustomQueries.getExperience)
    const variables = { "id": experienceId }
    const data = await this.makeQuery(query, variables)
      .catch(err => {
        Logger.error("GovGigAPI.getExperience error", err.message, variables)
        throw err
      })
    if (data as APITypes.GetExperienceQuery && (data as APITypes.GetExperienceQuery).getExperience) {
      return (data as APITypes.GetExperienceQuery).getExperience
    } else {
      return null
    }
  }

  async createExperience(input: APITypes.CreateExperienceInput) {
    const query = gqlToString(CustomMutations.createExperience)
    const variables = { input }
    try {
      const data = await this.makeQuery(query, variables)

      if (data as APITypes.CreateExperienceMutation && (data as APITypes.CreateExperienceMutation).createExperience) {
        const experienceData = (data as APITypes.CreateExperienceMutation).createExperience
        return experienceData
      } else {
        throw new Error(`Call to create experience does not contain data: ${data}`)
      }
    } catch (e) {
      Logger.error("GovGigAPI.createExperience error", getErrorMessage(e), input)
      return null
    }
  }

  async updateExperience(input: APITypes.UpdateExperienceInput) {
    const query = gqlToString(CustomMutations.updateExperience)
    const variables = { "input": input }
    const data = await this.makeQuery(query, variables)
      .catch(err => {
        Logger.error("GovGigAPI.updateExperience error", err.message, input)
        throw err
      })
    if (data as APITypes.UpdateExperienceMutation) {
      return (data as APITypes.UpdateExperienceMutation).updateExperience
    } else {
      return null
    }
  }

  async deleteExperience(id: string) {
    const query = gqlToString(CustomMutations.deleteExperience)
    const input: APITypes.DeleteExperienceInput = {
      id
    }
    const data = await this.makeQuery(query, { input })
      .catch(err => {
        Logger.error("GovGigAPI.deleteExperience error", err.message, input)
        throw err
      })
    if (data) {
      return (data as APITypes.DeleteExperienceMutation).deleteExperience
    }
    return null
  }

  // Education methods

  async listEducationByProfile(profileId: string, filter?: ModelEducationFilterInput) {
    const query = gqlToString(CustomQueries.listEducationByProfile)
    const variables = { "profileId": profileId, "filter": filter, "limit": 10000 }
    const data = await this.makeQuery(query, variables)
      .catch(err => {
        Logger.error("GovGigAPI.listEducationByProfile error", err.message, variables)
        throw err
      })
    if (data as APITypes.ListEducationByProfileQuery && (data as APITypes.ListEducationByProfileQuery).listEducationByProfile) {
      return (data as APITypes.ListEducationByProfileQuery).listEducationByProfile
    } else {
      return null
    }

  }

  async getEducation(educationId: string) {
    const query = gqlToString(CustomQueries.getEducation)
    const variables = { "id": educationId }
    const data = await this.makeQuery(query, variables)
      .catch(err => {
        Logger.error("GovGigAPI.getEducation error", err.message, variables)
        throw err
      })
    if (data as APITypes.GetEducationQuery && (data as APITypes.GetEducationQuery).getEducation) {
      return (data as APITypes.GetEducationQuery).getEducation
    } else {
      return null
    }
  }

  async createEducation(input: APITypes.CreateEducationInput) {
    const query = gqlToString(CustomMutations.createEducation)
    const variables = { input }
    try {
      const data = await this.makeQuery(query, variables)

      if (data as APITypes.CreateEducationMutation && (data as APITypes.CreateEducationMutation).createEducation) {
        const educationData = (data as APITypes.CreateEducationMutation).createEducation
        return educationData
      } else {
        throw new Error(`Call to create education does not contain data: ${data}`)
      }
    } catch (e) {
      Logger.error("GovGigAPI.createEducation error", getErrorMessage(e), input)
      return null
    }
  }

  async updateEducation(input: APITypes.UpdateEducationInput) {
    const query = gqlToString(CustomMutations.updateEducation)
    const variables = { "input": input }
    const data = await this.makeQuery(query, variables)
      .catch(err => {
        Logger.error("GovGigAPI.updateEducation error", err.message, input)
        throw err
      })
    if (data as APITypes.UpdateEducationMutation) {
      return (data as APITypes.UpdateEducationMutation).updateEducation
    } else {
      return null
    }
  }

  async deleteEducation(id: string) {
    const query = gqlToString(CustomMutations.deleteEducation)
    const input: APITypes.DeleteEducationInput = {
      id
    }
    const data = await this.makeQuery(query, { input })
      .catch(err => {
        Logger.error("GovGigAPI.deleteEducation error", err.message, input)
        throw err
      })
    if (data) {
      return (data as APITypes.DeleteEducationMutation).deleteEducation
    }
    return null
  }

  // Certification methods

  async listCertificationsByProfile(profileId: string, filter?: ModelCertificationFilterInput) {
    const query = gqlToString(CustomQueries.listCertificationsByProfile)
    const variables = { "profileId": profileId, "filter": filter, "limit": 10000 }
    const data = await this.makeAPIKeyQuery(query, variables)
      .catch(err => {
        Logger.error("GovGigAPI.listCertificationsByProfile error", err.message, variables)
        throw err
      })
    if (data as APITypes.ListCertificationsByProfileQuery && (data as APITypes.ListCertificationsByProfileQuery).listCertificationsByProfile) {
      return (data as APITypes.ListCertificationsByProfileQuery).listCertificationsByProfile
    } else {
      return null
    }

  }

  async getCertification(certificationId: string) {
    const query = gqlToString(CustomQueries.getCertification)
    const variables = { "id": certificationId }
    const data = await this.makeQuery(query, variables)
      .catch(err => {
        Logger.error("GovGigAPI.getCertification error", err.message, variables)
        throw err
      })
    if (data as APITypes.GetCertificationQuery && (data as APITypes.GetCertificationQuery).getCertification) {
      return (data as APITypes.GetCertificationQuery).getCertification
    } else {
      return null
    }
  }

  async createCertification(input: APITypes.CreateCertificationInput) {
    const query = gqlToString(CustomMutations.createCertification)
    const variables = { input }
    try {
      const data = await this.makeQuery(query, variables)

      if (data as APITypes.CreateCertificationMutation && (data as APITypes.CreateCertificationMutation).createCertification) {
        const certificationData = (data as APITypes.CreateCertificationMutation).createCertification
        return certificationData
      } else {
        throw new Error(`Call to create certification does not contain data: ${data}`)
      }
    } catch (e) {
      Logger.error("GovGigAPI.createCertification error", getErrorMessage(e), input)
      return null
    }
  }

  async updateCertification(input: APITypes.UpdateCertificationInput) {
    const query = gqlToString(CustomMutations.updateCertification)
    const variables = { "input": input }
    const data = await this.makeQuery(query, variables)
      .catch(err => {
        Logger.error("GovGigAPI.updateCertification error", err.message, input)
        throw err
      })
    if (data as APITypes.UpdateCertificationMutation) {
      return (data as APITypes.UpdateCertificationMutation).updateCertification
    } else {
      return null
    }
  }

  async deleteCertification(id: string) {
    const query = gqlToString(CustomMutations.deleteCertification)
    const input: APITypes.DeleteCertificationInput = {
      id
    }
    const data = await this.makeQuery(query, { input })
      .catch(err => {
        Logger.error("GovGigAPI.deleteCertification error", err.message, input)
        throw err
      })
    if (data) {
      return (data as APITypes.DeleteCertificationMutation).deleteCertification
    }
    return null
  }
  
  // CertificationType methods

  async listCertificationTypes(filter?: APITypes.ModelCertificationTypeFilterInput) {
    const query = gqlToString(CustomQueries.listCertificationTypes)
    const variables = { "filter": filter, "limit": 10000 }
    const data = await this.makeAPIKeyQuery(query, variables)
      .catch(err => {
        Logger.error("GovGigAPI.listCertificationTypes error", err.message, variables)
        throw err
      })
    if (data as APITypes.ListCertificationTypesQuery && (data as APITypes.ListCertificationTypesQuery).listCertificationTypes) {
      return (data as APITypes.ListCertificationTypesQuery).listCertificationTypes
    } else {
      return null
    }
  }

  async getCertificationType(id: string) {
    const query = gqlToString(CustomQueries.getCertificationType)
    const variables = { "id": id }
    const data = await this.makeAPIKeyQuery(query, variables)
      .catch(err => {
        Logger.error("GovGigAPI.getCertificationType error", err.message, variables)
        throw err
      })
    if (data as APITypes.GetCertificationTypeQuery && (data as APITypes.GetCertificationTypeQuery).getCertificationType) {
      return (data as APITypes.GetCertificationTypeQuery).getCertificationType
    } else {
      return null
    }
  }

  async createCertificationType(input: APITypes.CreateCertificationTypeInput) {
    const query = gqlToString(CustomMutations.createCertificationType)
    const variables = { input }
    try {
      const data = await this.makeQuery(query, variables)
      if (data as APITypes.CreateCertificationTypeMutation && (data as APITypes.CreateCertificationTypeMutation).createCertificationType) {
        const CertificationTypeData = (data as APITypes.CreateCertificationTypeMutation).createCertificationType
        return CertificationTypeData
      } else {
        throw new Error(`Call to create CertificationType does not contain data: ${data}`)
      }
    } catch (e) {
      Logger.error("GovGigAPI.createCertificationType error", getErrorMessage(e), input)
      return null
    }
  }

  async updateCertificationType(input: APITypes.UpdateCertificationTypeInput) {
    const query = gqlToString(CustomMutations.updateCertificationType)
    const variables = { "input": input }
    const data = await this.makeQuery(query, variables)
      .catch(err => {
        Logger.error("GovGigAPI.updateCertificationType error", err.message, input)
        throw err
      })
    if (data as APITypes.UpdateCertificationTypeMutation) {
      return (data as APITypes.UpdateCertificationTypeMutation).updateCertificationType
    } else {
      return null
    }
  }

  async deleteCertificationType(id: string) {
    const query = gqlToString(CustomMutations.deleteCertificationType)
    const input: APITypes.DeleteCertificationTypeInput = {
      id
    }
    const data = await this.makeQuery(query, { input })
      .catch(err => {
        Logger.error("GovGigAPI.deleteCertificationType error", err.message, input)
        throw err
      })
    if (data) {
      return (data as APITypes.DeleteCertificationTypeMutation).deleteCertificationType
    }
    return null
  }

  // Agreement methods

  async getAgreement(agreementId: string) {
    const query = gqlToString(CustomQueries.getAgreement)
    const variables = { "id": agreementId }
    const data = await this.makeQuery(query, variables)
      .catch(err => {
        Logger.error("GovGigAPI.getAgreement error", err.message, variables)
        throw err
      })
    if (data as APITypes.GetAgreementQuery && (data as APITypes.GetAgreementQuery).getAgreement) {
      return (data as APITypes.GetAgreementQuery).getAgreement
    } else {
      return null
    }
  }

  async listAgreementsByAccount(accountId: string, filter?: APITypes.ModelAgreementFilterInput) {
    const query = gqlToString(CustomQueries.listAgreementsByAccount)
    const variables = { "accountId": accountId, "filter": filter, "limit": 10000 }
    const data = await this.makeQuery(query, variables)
      .catch(err => {
        Logger.error("GovGigAPI.listAgreementsByAccount error", err.message, variables)
        throw err
      })
    if (data as APITypes.ListAgreementsByAccountQuery && (data as APITypes.ListAgreementsByAccountQuery).listAgreementsByAccount) {
      return (data as APITypes.ListAgreementsByAccountQuery).listAgreementsByAccount
    } else {
      return null
    }
  }

  async createAgreement(input: APITypes.CreateAgreementInput) {
    const query = gqlToString(CustomMutations.createAgreement)
    const variables = { input }
    try {
      const data = await this.makeQuery(query, variables)
      if (data as APITypes.CreateAgreementMutation && (data as APITypes.CreateAgreementMutation).createAgreement) {
        const AgreementData = (data as APITypes.CreateAgreementMutation).createAgreement
        return AgreementData
      } else {
        throw new Error(`Call to create Agreement does not contain data: ${data}`)
      }
    } catch (e) {
      Logger.error("GovGigAPI.createAgreement error", getErrorMessage(e), input)
      return null
    }
  }

  async updateAgreement(input: APITypes.UpdateAgreementInput) {
    const query = gqlToString(CustomMutations.updateAgreement)
    const variables = { "input": input }
    const data = await this.makeQuery(query, variables)
      .catch(err => {
        Logger.error("GovGigAPI.updateAgreement error", err.message, input)
        throw err
      })
    if (data as APITypes.UpdateAgreementMutation) {
      return (data as APITypes.UpdateAgreementMutation).updateAgreement
    } else {
      return null
    }
  }

  async deleteAgreement(id: string) {
    const query = gqlToString(CustomMutations.deleteAgreement)
    const input: APITypes.DeleteAgreementInput = {
      id
    }
    const data = await this.makeQuery(query, { input })
      .catch(err => {
        Logger.error("GovGigAPI.deleteAgreement error", err.message, input)
        throw err
      })
    if (data) {
      return (data as APITypes.DeleteAgreementMutation).deleteAgreement
    }
    return null
  }
  
  // Location methods

  async listLocations(filter?: APITypes.ModelLocationFilterInput, limit?: number, nextToken?: string) {
    const query = gqlToString(CustomQueries.listLocations)
    const variables = { "filter": filter, "limit": limit ?? 10000, "nextToken": nextToken  }
    const data = await this.makeAPIKeyQuery(query, variables)
      .catch(err => {
        Logger.error("GovGigAPI.listLocations error", err.message, variables)
        throw err
      })
    if (data as APITypes.ListLocationsQuery && (data as APITypes.ListLocationsQuery).listLocations) {
      return (data as APITypes.ListLocationsQuery).listLocations
    } else {
      return null
    }
  }

  async getLocation(id: string) {
    const query = gqlToString(CustomQueries.getLocation)
    const variables = { "id": id }
    const data = await this.makeAPIKeyQuery(query, variables)
      .catch(err => {
        Logger.error("GovGigAPI.getLocation error", err.message, variables)
        throw err
      })
    if (data as APITypes.GetLocationQuery && (data as APITypes.GetLocationQuery).getLocation) {
      return (data as APITypes.GetLocationQuery).getLocation
    } else {
      return null
    }
  }

  async createLocation(input: APITypes.CreateLocationInput) {
    const query = gqlToString(CustomMutations.createLocation)
    const variables = { input }
    try {
      const data = await this.makeQuery(query, variables)
      if (data as APITypes.CreateLocationMutation && (data as APITypes.CreateLocationMutation).createLocation) {
        const locationData = (data as APITypes.CreateLocationMutation).createLocation
        return locationData
      } else {
        throw new Error(`Call to create location does not contain data: ${data}`)
      }
    } catch (e) {
      Logger.error("GovGigAPI.createLocation error", getErrorMessage(e), input)
      return null
    }
  }

  async updateLocation(input: APITypes.UpdateLocationInput) {
    const query = gqlToString(CustomMutations.updateLocation)
    const variables = { "input": input }
    const data = await this.makeQuery(query, variables)
      .catch(err => {
        Logger.error("GovGigAPI.updateLocation error", err.message, input)
        throw err
      })
    if (data as APITypes.UpdateLocationMutation) {
      return (data as APITypes.UpdateLocationMutation).updateLocation
    } else {
      return null
    }
  }

  async deleteLocation(id: string) {
    const query = gqlToString(CustomMutations.deleteLocation)
    const input: APITypes.DeleteLocationInput = {
      id
    }
    const data = await this.makeQuery(query, { input })
      .catch(err => {
        Logger.error("GovGigAPI.deleteLocation error", err.message, input)
        throw err
      })
    if (data) {
      return (data as APITypes.DeleteLocationMutation).deleteLocation
    }
    return null
  }
  
  // ServiceGroup methods

  async listServiceGroups(filter?: APITypes.ModelServiceGroupFilterInput) {
    const query = gqlToString(CustomQueries.listServiceGroups)
    const variables = { "filter": filter, "limit": 10000 }
    const data = await this.makeAPIKeyQuery(query, variables)
      .catch(err => {
        Logger.error("GovGigAPI.listServiceGroups error", err.message, variables)
        throw err
      })
    if (data as APITypes.ListServiceGroupsQuery && (data as APITypes.ListServiceGroupsQuery).listServiceGroups) {
      return (data as APITypes.ListServiceGroupsQuery).listServiceGroups
    } else {
      return null
    }
  }

  async getServiceGroup(id: string) {
    const query = gqlToString(CustomQueries.getServiceGroup)
    const variables = { "id": id }
    const data = await this.makeAPIKeyQuery(query, variables)
      .catch(err => {
        Logger.error("GovGigAPI.getServiceGroup error", err.message, variables)
        throw err
      })
    if (data as APITypes.GetServiceGroupQuery && (data as APITypes.GetServiceGroupQuery).getServiceGroup) {
      return (data as APITypes.GetServiceGroupQuery).getServiceGroup
    } else {
      return null
    }
  }

  async createServiceGroup(input: APITypes.CreateServiceGroupInput) {
    const query = gqlToString(CustomMutations.createServiceGroup)
    const variables = { input }
    try {
      const data = await this.makeQuery(query, variables)
      if (data as APITypes.CreateServiceGroupMutation && (data as APITypes.CreateServiceGroupMutation).createServiceGroup) {
        const serviceGroupData = (data as APITypes.CreateServiceGroupMutation).createServiceGroup
        return serviceGroupData
      } else {
        throw new Error(`Call to create serviceGroup does not contain data: ${data}`)
      }
    } catch (e) {
      Logger.error("GovGigAPI.createServiceGroup error", getErrorMessage(e), input)
      return null
    }
  }

  async updateServiceGroup(input: APITypes.UpdateServiceGroupInput) {
    const query = gqlToString(CustomMutations.updateServiceGroup)
    const variables = { "input": input }
    const data = await this.makeQuery(query, variables)
      .catch(err => {
        Logger.error("GovGigAPI.updateServiceGroup error", err.message, input)
        throw err
      })
    if (data as APITypes.UpdateServiceGroupMutation) {
      return (data as APITypes.UpdateServiceGroupMutation).updateServiceGroup
    } else {
      return null
    }
  }

  async deleteServiceGroup(id: string) {
    const query = gqlToString(CustomMutations.deleteServiceGroup)
    const input: APITypes.DeleteServiceGroupInput = {
      id
    }
    const data = await this.makeQuery(query, { input })
      .catch(err => {
        Logger.error("GovGigAPI.deleteServiceGroup error", err.message, input)
        throw err
      })
    if (data) {
      return (data as APITypes.DeleteServiceGroupMutation).deleteServiceGroup
    }
    return null
  }

  // Service methods

  async listServices(filter?: APITypes.ModelServiceFilterInput) {
    const query = gqlToString(CustomQueries.listServices)
    const variables = { "filter": filter, "limit": 10000 }
    const data = await this.makeAPIKeyQuery(query, variables)
      .catch(err => {
        Logger.error("GovGigAPI.listServices error", err.message, variables)
        throw err
      })
    if (data as APITypes.ListServicesQuery && (data as APITypes.ListServicesQuery).listServices) {
      return (data as APITypes.ListServicesQuery).listServices
    } else {
      return null
    }
  }

  async getService(id: string) {
    const query = gqlToString(CustomQueries.getService)
    const variables = { "id": id }
    const data = await this.makeAPIKeyQuery(query, variables)
      .catch(err => {
        Logger.error("GovGigAPI.getService error", err.message, variables)
        throw err
      })
    if (data as APITypes.GetServiceQuery && (data as APITypes.GetServiceQuery).getService) {
      return (data as APITypes.GetServiceQuery).getService
    } else {
      return null
    }
  }

  async createService(input: APITypes.CreateServiceInput) {
    const query = gqlToString(CustomMutations.createService)
    const variables = { input }
    try {
      const data = await this.makeQuery(query, variables)
      if (data as APITypes.CreateServiceMutation && (data as APITypes.CreateServiceMutation).createService) {
        const serviceData = (data as APITypes.CreateServiceMutation).createService
        return serviceData
      } else {
        throw new Error(`Call to create service does not contain data: ${data}`)
      }
    } catch (e) {
      Logger.error("GovGigAPI.createService error", getErrorMessage(e), input)
      return null
    }
  }

  async updateService(input: APITypes.UpdateServiceInput) {
    const query = gqlToString(CustomMutations.updateService)
    const variables = { "input": input }
    const data = await this.makeQuery(query, variables)
      .catch(err => {
        Logger.error("GovGigAPI.updateService error", err.message, input)
        throw err
      })
    if (data as APITypes.UpdateServiceMutation) {
      return (data as APITypes.UpdateServiceMutation).updateService
    } else {
      return null
    }
  }

  async deleteService(id: string) {
    const query = gqlToString(CustomMutations.deleteService)
    const input: APITypes.DeleteServiceInput = {
      id
    }
    const data = await this.makeQuery(query, { input })
      .catch(err => {
        Logger.error("GovGigAPI.deleteService error", err.message, input)
        throw err
      })
    if (data) {
      return (data as APITypes.DeleteServiceMutation).deleteService
    }
    return null
  }

  // ProfileLocation methods

  async getProfileLocation(profileLocationId: string) {
    const query = gqlToString(CustomQueries.getProfileLocation)
    const variables = { "id": profileLocationId }
    const data = await this.makeQuery(query, variables)
      .catch(err => {
        Logger.error("GovGigAPI.getProfileLocation error", err.message, variables)
        throw err
      })
    if (data as APITypes.GetProfileLocationQuery && (data as APITypes.GetProfileLocationQuery).getProfileLocation) {
      return (data as APITypes.GetProfileLocationQuery).getProfileLocation
    } else {
      return null
    }
  }

  async createProfileLocation(input: APITypes.CreateProfileLocationInput) {
    const query = gqlToString(CustomMutations.createProfileLocation)
    const variables = { input }
    try {
      const data = await this.makeQuery(query, variables)
      if (data as APITypes.CreateProfileLocationMutation && (data as APITypes.CreateProfileLocationMutation).createProfileLocation) {
        const ProfileLocationData = (data as APITypes.CreateProfileLocationMutation).createProfileLocation
        return ProfileLocationData
      } else {
        throw new Error(`Call to create ProfileLocation does not contain data: ${data}`)
      }
    } catch (e) {
      Logger.error("GovGigAPI.createProfileLocation error", getErrorMessage(e), input)
      return null
    }
  }

  async updateProfileLocation(input: APITypes.UpdateProfileLocationInput) {
    const query = gqlToString(CustomMutations.updateProfileLocation)
    const variables = { "input": input }
    const data = await this.makeQuery(query, variables)
      .catch(err => {
        Logger.error("GovGigAPI.updateProfileLocation error", err.message, input)
        throw err
      })
    if (data as APITypes.UpdateProfileLocationMutation) {
      return (data as APITypes.UpdateProfileLocationMutation).updateProfileLocation
    } else {
      return null
    }
  }

  async deleteProfileLocation(id: string) {
    const query = gqlToString(CustomMutations.deleteProfileLocation)
    const input: APITypes.DeleteProfileLocationInput = {
      id
    }
    const data = await this.makeQuery(query, { input })
      .catch(err => {
        Logger.error("GovGigAPI.deleteProfileLocation error", err.message, input)
        throw err
      })
    if (data) {
      return (data as APITypes.DeleteProfileLocationMutation).deleteProfileLocation
    }
    return null
  }

  // ProfileService methods

  async getProfileService(profileServiceId: string) {
    const query = gqlToString(CustomQueries.getProfileService)
    const variables = { "id": profileServiceId }
    const data = await this.makeQuery(query, variables)
      .catch(err => {
        Logger.error("GovGigAPI.getProfileService error", err.message, variables)
        throw err
      })
    if (data as APITypes.GetProfileServiceQuery && (data as APITypes.GetProfileServiceQuery).getProfileService) {
      return (data as APITypes.GetProfileServiceQuery).getProfileService
    } else {
      return null
    }
  }

  async createProfileService(input: APITypes.CreateProfileServiceInput) {
    const query = gqlToString(CustomMutations.createProfileService)
    const variables = { input }
    try {
      const data = await this.makeQuery(query, variables)

      if (data as APITypes.CreateProfileServiceMutation && (data as APITypes.CreateProfileServiceMutation).createProfileService) {
        const profileServiceData = (data as APITypes.CreateProfileServiceMutation).createProfileService
        return profileServiceData
      } else {
        throw new Error(`Call to create profileService does not contain data: ${data}`)
      }
    } catch (e) {
      Logger.error("GovGigAPI.createProfileService error", getErrorMessage(e), input)
      return null
    }
  }

  async updateProfileService(input: APITypes.UpdateProfileServiceInput) {
    const query = gqlToString(CustomMutations.updateProfileService)
    const variables = { "input": input }
    const data = await this.makeQuery(query, variables)
      .catch(err => {
        Logger.error("GovGigAPI.updateProfileService error", err.message, input)
        throw err
      })
    if (data as APITypes.UpdateProfileServiceMutation) {
      return (data as APITypes.UpdateProfileServiceMutation).updateProfileService
    } else {
      return null
    }
  }

  async deleteProfileService(id: string) {
    const query = gqlToString(CustomMutations.deleteProfileService)
    const input: APITypes.DeleteProfileServiceInput = {
      id
    }
    const data = await this.makeQuery(query, { input })
      .catch(err => {
        Logger.error("GovGigAPI.deleteProfileService error", err.message, input)
        throw err
      })
    if (data) {
      return (data as APITypes.DeleteProfileServiceMutation).deleteProfileService
    }
    return null
  }
  
  // Contract
  
  async listContracts(filter?: APITypes.ModelContractFilterInput) {
    const query = gqlToString(CustomQueries.listContracts)
    const variables = { "filter": filter, "limit": 10000 }
    const data = await this.makeQuery(query, variables)
      .catch(err => {
        Logger.error("GovGigAPI.listContracts error", err.message, variables)
        throw err
      })
    if (data as APITypes.ListContractsQuery && (data as APITypes.ListContractsQuery).listContracts) {
      return (data as APITypes.ListContractsQuery).listContracts
    } else {
      return null
    }
  }

  async listContractsByAccount(accountId: string, filter?: APITypes.ModelContractFilterInput) {
    const query = gqlToString(CustomQueries.listContractsByAccount)
    const variables = { "accountId": accountId, "filter": filter, "limit": 10000 }
    const data = await this.makeQuery(query, variables)
      .catch(err => {
        Logger.error("GovGigAPI.listContractsByAccount error", err.message, variables)
        throw err
      })
    if (data as APITypes.ListContractsByAccountQuery && (data as APITypes.ListContractsByAccountQuery).listContractsByAccount) {
      return (data as APITypes.ListContractsByAccountQuery).listContractsByAccount
    } else {
      return null
    }
  }

  async getContract(ContractId: string) {
    const query = gqlToString(CustomQueries.getContract)
    const variables = { "id": ContractId }
    const data = await this.makeQuery(query, variables)
      .catch(err => {
        Logger.error("GovGigAPI.getContract error", err.message, variables)
        throw err
      })
    if (data as APITypes.GetContractQuery && (data as APITypes.GetContractQuery).getContract) {
      return (data as APITypes.GetContractQuery).getContract
    } else {
      return null
    }
  }

  async createContract(input: APITypes.CreateContractInput) {
    const query = gqlToString(CustomMutations.createContract)
    const variables = { input }
    try {
      const data = await this.makeQuery(query, variables)

      if (data as APITypes.CreateContractMutation && (data as APITypes.CreateContractMutation).createContract) {
        const ContractData = (data as APITypes.CreateContractMutation).createContract
        return ContractData
      } else {
        throw new Error(`Call to create Contract does not contain data: ${data}`)
      }
    } catch (e) {
      Logger.error("GovGigAPI.createContract error", getErrorMessage(e), input)
      return null
    }
  }

  async updateContract(input: APITypes.UpdateContractInput) {
    const query = gqlToString(CustomMutations.updateContract)
    const variables = { "input": input }
    const data = await this.makeQuery(query, variables)
      .catch(err => {
        Logger.error("GovGigAPI.updateContract error", err.message, input)
        throw err
      })
    if (data as APITypes.UpdateContractMutation) {
      return (data as APITypes.UpdateContractMutation).updateContract
    } else {
      return null
    }
  }

  async deleteContract(id: string) {
    const query = gqlToString(CustomMutations.deleteContract)
    const input: APITypes.DeleteContractInput = {
      id
    }
    const data = await this.makeQuery(query, { input })
      .catch(err => {
        Logger.error("GovGigAPI.deleteContract error", err.message, input)
        throw err
      })
    if (data) {
      return (data as APITypes.DeleteContractMutation).deleteContract
    }
    return null
  }

  // ServiceRequest
  
  async listServiceRequests(filter?: APITypes.ModelServiceRequestFilterInput, limit?: number, nextToken?: string) {
    const query = gqlToString(CustomQueries.listServiceRequests)
    
    // const variables = { "filter": filter, "limit": limit ?? 10000, "nextToken": nextToken }
    const variables = { "limit": limit ?? 10000, "nextToken": nextToken }
    if(filter?.hasOwnProperty('length')) {
      variables['filter'] = filter;
    } 

    const data = await this.makeQuery(query, variables)
      .catch(err => {
        Logger.error("GovGigAPI.listServiceRequests error", err.message, variables)
        throw err
      })
    if (data as APITypes.ListServiceRequestsQuery && (data as APITypes.ListServiceRequestsQuery).listServiceRequests) {
      return (data as APITypes.ListServiceRequestsQuery).listServiceRequests
    } else {
      return null
    }
  }

  async listServiceRequestsByAccount(accountId: string, filter?: APITypes.ModelServiceRequestFilterInput, limit?: number, nextToken?: string) {
    const query = gqlToString(CustomQueries.listServiceRequestsByAccount)
    const variables = { "accountId": accountId, "filter": filter, "limit": limit ?? 10000, "nextToken": nextToken }
    const data = await this.makeQuery(query, variables)
      .catch(err => {
        Logger.error("GovGigAPI.listServiceRequestsByAccount error", err.message, variables)
        throw err
      })
    if (data as APITypes.ListServiceRequestsByAccountQuery && (data as APITypes.ListServiceRequestsByAccountQuery).listServiceRequestsByAccount) {
      return (data as APITypes.ListServiceRequestsByAccountQuery).listServiceRequestsByAccount
    } else {
      return null
    }
  }

  async getServiceRequest(serviceRequestId: string) {
    const query = gqlToString(CustomQueries.getServiceRequest)
    const variables = { "id": serviceRequestId }
    const data = await this.makeQuery(query, variables)
      .catch(err => {
        Logger.error("GovGigAPI.getServiceRequest error", err.message, variables)
        throw err
      })
    if (data as APITypes.GetServiceRequestQuery && (data as APITypes.GetServiceRequestQuery).getServiceRequest) {
      return (data as APITypes.GetServiceRequestQuery).getServiceRequest
    } else {
      return null
    }
  }

  async createServiceRequest(input: APITypes.CreateServiceRequestInput) {
    const query = gqlToString(CustomMutations.createServiceRequest)
    const variables = { input }
    try {
      const data = await this.makeQuery(query, variables)

      if (data as APITypes.CreateServiceRequestMutation && (data as APITypes.CreateServiceRequestMutation).createServiceRequest) {
        const ServiceRequestData = (data as APITypes.CreateServiceRequestMutation).createServiceRequest
        return ServiceRequestData
      } else {
        throw new Error(`Call to create ServiceRequest does not contain data: ${data}`)
      }
    } catch (e) {
      Logger.error("GovGigAPI.createServiceRequest error", getErrorMessage(e), input)
      return null
    }
  }

  async updateServiceRequest(input: APITypes.UpdateServiceRequestInput) {
    const query = gqlToString(CustomMutations.updateServiceRequest)
    const variables = { "input": input }
    const data = await this.makeQuery(query, variables)
      .catch(err => {
        Logger.error("GovGigAPI.updateServiceRequest error", err.message, input)
        throw err
      })
    if (data as APITypes.UpdateServiceRequestMutation) {
      return (data as APITypes.UpdateServiceRequestMutation).updateServiceRequest
    } else {
      return null
    }
  }

  async deleteServiceRequest(id: string) {
    const query = gqlToString(CustomMutations.deleteServiceRequest)
    const input: APITypes.DeleteServiceRequestInput = {
      id
    }
    const data = await this.makeQuery(query, { input })
      .catch(err => {
        Logger.error("GovGigAPI.deleteServiceRequest error", err.message, input)
        throw err
      })
    if (data) {
      return (data as APITypes.DeleteServiceRequestMutation).deleteServiceRequest
    }
    return null
  }

  // JobPost methods

  async listJobPosts(filter?: APITypes.ModelJobPostFilterInput, limit?: number) {
    const query = gqlToString(CustomQueries.listJobPosts)
    const variables = { "filter": filter, "limit": limit ?? 10000 }
    const data = await this.makeAPIKeyQuery(query, variables)
      .catch(err => {
        Logger.error("GovGigAPI.listJobPosts error", err.message, variables)
        throw err
      })
    if (data as APITypes.ListJobPostsQuery && (data as APITypes.ListJobPostsQuery).listJobPosts) {
      return (data as APITypes.ListJobPostsQuery).listJobPosts
    } else {
      return null
    }
  }

  async listJobPostsAndCandidates(filter?: APITypes.ModelJobPostFilterInput, limit?: number, nextToken?: string) {
    const query = gqlToString(CustomQueries.listJobPostsAndCandidates)
    const variables = { "filter": filter, "limit": limit ?? 10000, "nextToken": nextToken }
    const data = await this.makeQuery(query, variables)
      .catch(err => {
        Logger.error("GovGigAPI.listJobPostsAndCandidates error", err.message, variables)
        throw err
      })
    if (data as APITypes.ListJobPostsQuery && (data as APITypes.ListJobPostsQuery).listJobPosts) {
      return (data as APITypes.ListJobPostsQuery).listJobPosts
    } else {
      return null
    }
  }


  async listJobPostsByContract(contractId: string, filter?: APITypes.ModelJobPostFilterInput) {
    const query = gqlToString(CustomQueries.listJobPostsByContract)
    const variables = { "contractId": contractId, "filter": filter, "limit": 10000 }
    const data = await this.makeAPIKeyQuery(query, variables)
      .catch(err => {
        Logger.error("GovGigAPI.listJobPostsByContract error", err.message, variables)
        throw err
      })
    if (data as APITypes.ListJobPostsByContractQuery && (data as APITypes.ListJobPostsByContractQuery).listJobPostsByContract) {
      return (data as APITypes.ListJobPostsByContractQuery).listJobPostsByContract
    } else {
      return null
    }
  }

  async listJobPostsByServiceRequest(serviceRequestId: string, filter?: APITypes.ModelJobPostFilterInput) {
    const query = gqlToString(CustomQueries.listJobPostsByServiceRequest)
    const variables = { "serviceRequestId": serviceRequestId, "filter": filter, "limit": 10000 }
    const data = await this.makeAPIKeyQuery(query, variables)
      .catch(err => {
        Logger.error("GovGigAPI.listJobPostsByServiceRequest error", err.message, variables)
        throw err
      })
    if (data as APITypes.ListJobPostsByServiceRequestQuery && (data as APITypes.ListJobPostsByServiceRequestQuery).listJobPostsByServiceRequest) {
      return (data as APITypes.ListJobPostsByServiceRequestQuery).listJobPostsByServiceRequest
    } else {
      return null
    }
  }

  async listJobPostsByLocation(locationId: string, filter?: APITypes.ModelJobPostFilterInput) {
    const query = gqlToString(CustomQueries.listJobPostsByLocation)
    const variables = { "locationId": locationId, "filter": filter, "limit": 10000 }
    const data = await this.makeAPIKeyQuery(query, variables)
      .catch(err => {
        Logger.error("GovGigAPI.listJobPostsByLocation error", err.message, variables)
        throw err
      })
    if (data as APITypes.ListJobPostsByLocationQuery && (data as APITypes.ListJobPostsByLocationQuery).listJobPostsByLocation) {
      return (data as APITypes.ListJobPostsByLocationQuery).listJobPostsByLocation
    } else {
      return null
    }
  }

  async listJobPostsByService(serviceId: string, filter?: APITypes.ModelJobPostFilterInput) {
    const query = gqlToString(CustomQueries.listJobPostsByService)
    const variables = { "serviceId": serviceId, "filter": filter, "limit": 10000 }
    const data = await this.makeAPIKeyQuery(query, variables)
      .catch(err => {
        Logger.error("GovGigAPI.listJobPostsByService error", err.message, variables)
        throw err
      })
    if (data as APITypes.ListJobPostsByServiceQuery && (data as APITypes.ListJobPostsByServiceQuery).listJobPostsByService) {
      return (data as APITypes.ListJobPostsByServiceQuery).listJobPostsByService
    } else {
      return null
    }
  }

    async listJobPostsByServiceAndLocation(serviceId: string, locationId: string, filter?: APITypes.ModelJobPostFilterInput) {
      const query = gqlToString(CustomQueries.listJobPostsByService)
      const locationCondition: APITypes.ModelStringKeyConditionInput = { beginsWith: locationId }
      const variables = { "serviceId": serviceId, "locationId": locationCondition, "filter": filter, "limit": 10000 }
      const data = await this.makeAPIKeyQuery(query, variables)
        .catch(err => {
          Logger.error("GovGigAPI.listJobPostsByServiceAndLocation error", err.message, variables)
          throw err
        })
      if (data as APITypes.ListJobPostsByServiceQuery && (data as APITypes.ListJobPostsByServiceQuery).listJobPostsByService) {
        return (data as APITypes.ListJobPostsByServiceQuery).listJobPostsByService
      } else {
        return null
      }
    }

  async listJobPostsByAccount(accountId: string, filter?: APITypes.ModelJobPostFilterInput, limit?: number, nextToken?: string) {
    const query = gqlToString(CustomQueries.listJobPostsByAccount)
    const variables = { "accountId": accountId, "filter": filter, "limit": limit ?? 10000, "nextToken": nextToken}
    const data = await this.makeQuery(query, variables)
      .catch(err => {
        Logger.error("GovGigAPI.listJobPostsByAccount error", err.message, variables)
        throw err
      })
    if (data as APITypes.ListJobPostsByAccountQuery && (data as APITypes.ListJobPostsByAccountQuery).listJobPostsByAccount) {
      return (data as APITypes.ListJobPostsByAccountQuery).listJobPostsByAccount
    } else {
      return null
    }
  }

  async listJobPostsAndCandidatesByAccount(accountId: string, filter?: APITypes.ModelJobPostFilterInput, limit?: number, nextToken?: string) {
    const query = gqlToString(CustomQueries.listJobPostsAndCandidatesByAccount)
    const variables = { "accountId": accountId, "filter": filter, "limit": limit ?? 10000, "nextToken": nextToken }
    const data = await this.makeQuery(query, variables)
      .catch(err => {
        Logger.error("GovGigAPI.listJobPostsByAccount error", err.message, variables)
        throw err
      })
    if (data as APITypes.ListJobPostsByAccountQuery && (data as APITypes.ListJobPostsByAccountQuery).listJobPostsByAccount) {
      return (data as APITypes.ListJobPostsByAccountQuery).listJobPostsByAccount
    } else {
      return null
    }
  }
  async getJobPost(jobPostId: string) {
    let query: string 
    const variables = { "id": jobPostId }
    let data 

    query = gqlToString(CustomQueries.getJobPost)
    data = await this.makeQuery(query, variables)
      .catch(err => {
        // console.debug(`getJobPost failed: ${err.message}`)
        // Ignore and fall back to public view
      })

    if (!data) {
      // Fall back to public view
      query = gqlToString(CustomQueries.getPublicJobPost)
      data = await this.makeAPIKeyQuery(query, variables)
        .catch(err => {
          Logger.error("GovGigAPI.getPublicJobPost error", err.message, variables)
          throw err
        })
    }

    if (data as APITypes.GetJobPostQuery && (data as APITypes.GetJobPostQuery).getJobPost) {
      return (data as APITypes.GetJobPostQuery).getJobPost
    } else {
      return null
    }
  }

  async createJobPost(input: APITypes.CreateJobPostInput) {
    const query = gqlToString(CustomMutations.createJobPost)
    const variables = { input }
    try {
      const data = await this.makeQuery(query, variables)

      if (data as APITypes.CreateJobPostMutation && (data as APITypes.CreateJobPostMutation).createJobPost) {
        const JobPostData = (data as APITypes.CreateJobPostMutation).createJobPost
        return JobPostData
      } else {
        throw new Error(`Call to create JobPost does not contain data: ${data}`)
      }
    } catch (e) {
      Logger.error("GovGigAPI.createJobPost error", getErrorMessage(e), input)
      throw e
    }
  }

  async updateJobPost(input: APITypes.UpdateJobPostInput) {
    const query = gqlToString(CustomMutations.updateJobPost)
    const variables = { "input": input }
    const data = await this.makeQuery(query, variables)
      .catch(err => {
        Logger.error("GovGigAPI.updateJobPost error", err.message, input)
        throw err
      })
    if (data as APITypes.UpdateJobPostMutation) {
      return (data as APITypes.UpdateJobPostMutation).updateJobPost
    } else {
      return null
    }
  }

  async deleteJobPost(id: string) {
    const query = gqlToString(CustomMutations.deleteJobPost)
    const input: APITypes.DeleteJobPostInput = {
      id
    }
    const data = await this.makeQuery(query, { input })
      .catch(err => {
        Logger.error("GovGigAPI.deleteJobPost error", err.message, input)
        throw err
      })
    if (data) {
      return (data as APITypes.DeleteJobPostMutation).deleteJobPost
    }
    return null
  }

  // JobInterest methods

  async listJobInterestsByUser(userId: string, filter?: APITypes.ModelJobInterestFilterInput) {
    const query = gqlToString(CustomQueries.listJobInterestsByUser)
    const variables = { "userId": userId, "filter": filter, "limit": 10000 }
    const data = await this.makeQuery(query, variables)
      .catch(err => {
        Logger.error("GovGigAPI.listJobInterestsByUser error", err.message, variables)
        throw err
      })
    if (data as APITypes.ListJobInterestsByUserQuery && (data as APITypes.ListJobInterestsByUserQuery).listJobInterestsByUser) {
      return (data as APITypes.ListJobInterestsByUserQuery).listJobInterestsByUser
    } else {
      return null
    }
  }

  async listJobInterestsByJobPost(jobPostId: string, filter?: APITypes.ModelJobInterestFilterInput) {
    const query = gqlToString(CustomQueries.listJobInterestsByJobPost)
    const variables = { "jobPostId": jobPostId, "filter": filter, "limit": 10000 }
    const data = await this.makeQuery(query, variables)
      .catch(err => {
        Logger.error("GovGigAPI.listJobInterestsByJobPost error", err.message, variables)
        throw err
      })
    if (data as APITypes.ListJobInterestsByJobPostQuery && (data as APITypes.ListJobInterestsByJobPostQuery).listJobInterestsByJobPost) {
      return (data as APITypes.ListJobInterestsByJobPostQuery).listJobInterestsByJobPost
    } else {
      return null
    }
  }

  async getJobInterest(JobInterestId: string) {
    const query = gqlToString(CustomQueries.getJobInterest)
    const variables = { "id": JobInterestId }
    const data = await this.makeQuery(query, variables)
      .catch(err => {
        Logger.error("GovGigAPI.getJobInterest error", err.message, variables)
        throw err
      })
    if (data as APITypes.GetJobInterestQuery && (data as APITypes.GetJobInterestQuery).getJobInterest) {
      return (data as APITypes.GetJobInterestQuery).getJobInterest
    } else {
      return null
    }
  }
  
  async createJobInterest(input: APITypes.CreateJobInterestInput) {
    const query = gqlToString(CustomMutations.createJobInterest)
    const variables = { input }
    try {
      const data = await this.makeQuery(query, variables)

      if (data as APITypes.CreateJobInterestMutation && (data as APITypes.CreateJobInterestMutation).createJobInterest) {
        const JobInterestData = (data as APITypes.CreateJobInterestMutation).createJobInterest
        return JobInterestData
      } else {
        throw new Error(`Call to create JobInterest does not contain data: ${data}`)
      }
    } catch (e) {
      Logger.error("GovGigAPI.createJobInterest error", getErrorMessage(e), input)
      return null
    }
  }

  async updateJobInterest(input: APITypes.UpdateJobInterestInput) {
    const query = gqlToString(CustomMutations.updateJobInterest)
    const variables = { "input": input }
    const data = await this.makeQuery(query, variables)
      .catch(err => {
        Logger.error("GovGigAPI.updateJobInterest error", err.message, input)
        throw err
      })
    if (data as APITypes.UpdateJobInterestMutation) {
      return (data as APITypes.UpdateJobInterestMutation).updateJobInterest
    } else {
      return null
    }
  }

  async deleteJobInterest(id: string) {
    const query = gqlToString(CustomMutations.deleteJobInterest)
    const input: APITypes.DeleteJobInterestInput = {
      id
    }
    const data = await this.makeQuery(query, { input })
      .catch(err => {
        Logger.error("GovGigAPI.deleteJobInterest error", err.message, input)
        throw err
      })
    if (data) {
      return (data as APITypes.DeleteJobInterestMutation).deleteJobInterest
    }
    return null
  }

  // JobCandidate

  async listJobCandidatesByJobPost(jobPostId: string, filter?: APITypes.ModelJobCandidateFilterInput) {
    const query = gqlToString(CustomQueries.listJobCandidatesByJobPost)
    const variables = { "jobPostId": jobPostId, "filter": filter, "limit": 10000 }
    const data = await this.makeQuery(query, variables)
      .catch(err => {
        Logger.error("GovGigAPI.listJobCandidatesByJobPost error", err.message, variables)
        throw err
      })
    if (data as APITypes.ListJobCandidatesByJobPostQuery && (data as APITypes.ListJobCandidatesByJobPostQuery).listJobCandidatesByJobPost) {
      return (data as APITypes.ListJobCandidatesByJobPostQuery).listJobCandidatesByJobPost
    } else {
      return null
    }
  }

  async listJobCandidatesByAccount(accountId: string, filter?: APITypes.ModelJobCandidateFilterInput) {
    const query = gqlToString(CustomQueries.listJobCandidatesByAccount)
    const variables = { "accountId": accountId, "filter": filter, "limit": 10000 }
    const data = await this.makeQuery(query, variables)
      .catch(err => {
        Logger.error("GovGigAPI.listJobCandidatesByAccount error", err.message, variables)
        throw err
      })
    if (data as APITypes.ListJobCandidatesByAccountQuery && (data as APITypes.ListJobCandidatesByAccountQuery).listJobCandidatesByAccount) {
      return (data as APITypes.ListJobCandidatesByAccountQuery).listJobCandidatesByAccount
    } else {
      return null
    }
  }

  async listJobCandidatesByProfile(profileId: string, filter?: APITypes.ModelJobCandidateFilterInput) {
    const query = gqlToString(CustomQueries.listJobCandidatesByProfile)
    const variables = { "profileId": profileId, "filter": filter, "limit": 10000 }
    const data = await this.makeQuery(query, variables)
      .catch(err => {
        Logger.error("GovGigAPI.listJobCandidatesByProfile error", err.message, variables)
        throw err
      })
    if (data as APITypes.ListJobCandidatesByProfileQuery && (data as APITypes.ListJobCandidatesByProfileQuery).listJobCandidatesByProfile) {
      return (data as APITypes.ListJobCandidatesByProfileQuery).listJobCandidatesByProfile
    } else {
      return null
    }
  }

  async getJobCandidate(jobCandidateId: string) {
    const query = gqlToString(CustomQueries.getJobCandidate)
    const variables = { "id": jobCandidateId }
    const data = await this.makeQuery(query, variables)
      .catch(err => {
        Logger.error("GovGigAPI.getJobCandidate error", err.message, variables)
        throw err
      })
    if (data as APITypes.GetJobCandidateQuery && (data as APITypes.GetJobCandidateQuery).getJobCandidate) {
      return (data as APITypes.GetJobCandidateQuery).getJobCandidate
    } else {
      return null
    }
  }

  async createJobCandidate(input: APITypes.CreateJobCandidateInput) {
    const query = gqlToString(CustomMutations.createJobCandidate)
    const variables = { input }
    try {
      const data = await this.makeQuery(query, variables)

      if (data as APITypes.CreateJobCandidateMutation && (data as APITypes.CreateJobCandidateMutation).createJobCandidate) {
        const JobCandidateData = (data as APITypes.CreateJobCandidateMutation).createJobCandidate
        return JobCandidateData
      } else {
        throw new Error(`Call to create JobCandidate does not contain data: ${data}`)
      }
    } catch (e) {
      Logger.error("GovGigAPI.createJobCandidate error", getErrorMessage(e), input)
      return null
    }
  }

  async updateJobCandidate(input: APITypes.UpdateJobCandidateInput) {
    const query = gqlToString(CustomMutations.updateJobCandidate)
    const variables = { "input": input }
    const data = await this.makeQuery(query, variables)
      .catch(err => {
        Logger.error("GovGigAPI.updateJobCandidate error", err.message, input)
        throw err
      })
    if (data as APITypes.UpdateJobCandidateMutation) {
      return (data as APITypes.UpdateJobCandidateMutation).updateJobCandidate
    } else {
      return null
    }
  }

  async deleteJobCandidate(id: string) {
    const query = gqlToString(CustomMutations.deleteJobCandidate)
    const input: APITypes.DeleteJobCandidateInput = {
      id
    }
    const data = await this.makeQuery(query, { input })
      .catch(err => {
        Logger.error("GovGigAPI.deleteJobCandidate error", err.message, input)
        throw err
      })
    if (data) {
      return (data as APITypes.DeleteJobCandidateMutation).deleteJobCandidate
    }
    return null
  }

  // UserActivity 

  async listUserActivityByUser(userId: string, filter?: APITypes.ModelUserActivityFilterInput) {
    const query = gqlToString(CustomQueries.listUserActivityByUser)
    const variables = { "userId": userId, "filter": filter, "limit": 1000, "sortDirection": ModelSortDirection.DESC }
    const data = await this.makeQuery(query, variables)
      .catch(err => {
        Logger.error("GovGigAPI.listUserActivityByUser error", err.message, variables)
        throw err
      })
    if (data as APITypes.ListUserActivityByUserQuery && (data as APITypes.ListUserActivityByUserQuery).listUserActivityByUser) {
      return (data as APITypes.ListUserActivityByUserQuery).listUserActivityByUser
    } else {
      return null
    }
  }

  async listUserActivityBySubject(subjectId: string, filter?: APITypes.ModelUserActivityFilterInput, sortDirection?: ModelSortDirection) {
    const query = gqlToString(CustomQueries.listUserActivityBySubject)
    const variables = { "subjectId": subjectId, "filter": filter, "limit": 1000, "sortDirection": sortDirection ?? ModelSortDirection.DESC }
    const data = await this.makeQuery(query, variables)
      .catch(err => {
        Logger.error("GovGigAPI.listUserActivityBySubject error", err.message, variables)
        throw err
      })
    if (data as APITypes.ListUserActivityBySubjectQuery && (data as APITypes.ListUserActivityBySubjectQuery).listUserActivityBySubject) {
      return (data as APITypes.ListUserActivityBySubjectQuery).listUserActivityBySubject
    } else {
      return null
    }
  }

  async getUserActivity(userActivityId: string) {
    const query = gqlToString(CustomQueries.getUserActivity)
    const variables = { "id": userActivityId }
    const data = await this.makeQuery(query, variables)
      .catch(err => {
        Logger.error("GovGigAPI.getUserActivity error", err.message, variables)
        throw err
      })
    if (data as APITypes.GetUserActivityQuery && (data as APITypes.GetUserActivityQuery).getUserActivity) {
      return (data as APITypes.GetUserActivityQuery).getUserActivity
    } else {
      return null
    }
  }
  
  async createUserActivity(input: APITypes.CreateUserActivityInput) {
    const query = gqlToString(CustomMutations.createUserActivity)
    const variables = { input }
    try {
      const data = await this.makeQuery(query, variables)

      if (data as APITypes.CreateUserActivityMutation && (data as APITypes.CreateUserActivityMutation).createUserActivity) {
        const UserActivityData = (data as APITypes.CreateUserActivityMutation).createUserActivity
        return UserActivityData
      } else {
        throw new Error(`Call to create UserActivity does not contain data: ${data}`)
      }
    } catch (e) {
      Logger.error("GovGigAPI.createUserActivity error", getErrorMessage(e), input)
      return null
    }
  }

  async updateUserActivity(input: APITypes.UpdateUserActivityInput) {
    const query = gqlToString(CustomMutations.updateUserActivity)
    const variables = { "input": input }
    const data = await this.makeQuery(query, variables)
      .catch(err => {
        Logger.error("GovGigAPI.updateUserActivity error", err.message, input)
        throw err
      })
    if (data as APITypes.UpdateUserActivityMutation) {
      return (data as APITypes.UpdateUserActivityMutation).updateUserActivity
    } else {
      return null
    }
  }

  async deleteUserActivity(id: string) {
    const query = gqlToString(CustomMutations.deleteUserActivity)
    const input: APITypes.DeleteUserActivityInput = {
      id
    }
    const data = await this.makeQuery(query, { input })
      .catch(err => {
        Logger.error("GovGigAPI.deleteUserActivity error", err.message, input)
        throw err
      })
    if (data) {
      return (data as APITypes.DeleteUserActivityMutation).deleteUserActivity
    }
    return null
  }

  async listCourses(filter?: APITypes.ModelCourseFilterInput) {
    const query = gqlToString(CustomQueries.listCourses)
    const variables = {"filter": filter, "limit": 1000}
    const data = await this.makeAPIKeyQuery(query, variables)
      .catch(err => {
        // Logger.error("GovGig.listCourses error", err.message, variables)
        throw err
      })
    if (data as APITypes.ListCoursesQuery && (data as APITypes.ListCoursesQuery).listCourses) {
      return (data as APITypes.ListCoursesQuery).listCourses
    } else {
      return null
    }
  }

  async getCourse(id: string) {
    const query = gqlToString(CustomQueries.getCourse)
    const variables = {"id": id}
    const data = await this.makeAPIKeyQuery(query, variables)
      .catch(err => {
        // Logger.error("GovGig.getCourse error", err.message, variables)
        throw err
      })

    if (data as APITypes.GetCourseQuery && (data as APITypes.GetCourseQuery).getCourse) {
      return (data as APITypes.GetCourseQuery).getCourse
    } else {
      return null
    }
  }

  // Helper methods

  async makeQuery(query: string, variables?: {}) {
    try {
      // await this.checkAuthentication()
      const operation = graphqlOperation(query, variables)
      // console.log(JSON.stringify(operation))
      const result = await API.graphql(operation)
      if (result as GraphQLResult) {
        const data = (result as GraphQLResult).data
        return data
      } else {
        return null
      }
    } catch (err: any) {
      // console.log("makeQuery error", err)
      if (err.message) {
        throw err
      } else if (err.errors && err.errors.length > 0) {
        throw new Error(err.errors[0].message)
      } else {
        throw new Error(`Unknown error: ${err}`)
      }
    }
  }

  async makeAPIKeyQuery(query: string, variables?: {}) {
    try {
      // await this.checkAuthentication()
      const result = await API.graphql({
        query: query,
        variables,
        authMode: GRAPHQL_AUTH_MODE.API_KEY
      })
      if (result as GraphQLResult) {
        const data = (result as GraphQLResult).data
        return data
      } else {
        return null
      }
    } catch (err: any) {
      // console.log("makeQuery error", err)
      if (err.message) {
        throw err
      } else if (err.errors && err.errors.length > 0) {
        throw new Error(err.errors[0].message)
      } else {
        throw new Error(`Unknown error: ${err}`)
      }
    }
  }

  async getAuthenticatedRole(): Promise<UserRole | undefined> {
    let role

    try {
      const cognitoUser = await Auth.currentAuthenticatedUser()
      if (cognitoUser) {
        const attributes = await Auth.userAttributes(cognitoUser)
        const attribute = attributes.find(a => a.getName() === "custom:role")
        if (attribute) {
          return UserRole[attribute.getValue()]
        }
      }
    } catch (err) {
      // Logger.debug("checkAuthentication err: " + err.message)
    }

    return role
  }


  async checkAuthentication(): Promise<boolean> {
    try {
      const cognitoUser = await Auth.currentAuthenticatedUser()
      if (cognitoUser) {
        return true
      }
    } catch (err) {
      // Logger.debug("checkAuthentication err: " + err.message)
    }

    return false
  }

  async checkRole(): Promise<UserRole | null> {
    try {
      const cognitoUser = await Auth.currentAuthenticatedUser()
      if (cognitoUser) {
        const attributes = await Auth.userAttributes(cognitoUser)
        const attribute = attributes.find(a => a.getName() === "custom:role")
        if (attribute) {
          return UserRole[attribute.getValue()]
        } else {
          return null
        }
      }
    } catch (err) {
      // Logger.debug("checkAuthentication err: " + err.message)
    }

    return null
  }
}

export default GovGigAPI