import * as React from "react";
import {Card, CardContent, CardHeader, createStyles, Theme, withStyles, WithStyles, withTheme} from "@material-ui/core";
import CardValue from "../../components/CardValue";

const styles = (theme: Theme) => createStyles({
  card: {
    width: '100%',
    height: 145,
    padding: 0
  },
  content: {
    padding: "5px 15px",
    display: "flex"
  },
  left: {
    flexGrow: 1
  },
  right: {
    flexShrink: 0,
    alignSelf: "center",
    paddingTop: 10
  },
  header: {
    padding: theme.spacing(2),
    paddingBottom: 0
  },
  title: {
    color: theme.palette.primary.main,
    fontSize: 16,
    fontWeight: 700
  }

})

interface IStyleCardProps {

}

class StyleCard extends React.Component<WithStyles<typeof styles> & IStyleCardProps> {

  onClickCard = (event: any) => {
    event.stopPropagation()
  }

  render() {
    const { classes } = this.props
    const labelWidth = 120

    return (
      <Card className={classes.card}>
        <CardHeader title="Card Header" classes={{root: classes.header, title: classes.title}}/>
        <CardContent className={classes.content}>
          <div className={classes.left}>
            <CardValue label="Account Name" labelWidth={labelWidth}>
              Heroic Development LLC
            </CardValue>
            <CardValue label="Contact Name" labelWidth={labelWidth}>
              Brett Eddy
            </CardValue>
            <CardValue label="Contact Email" labelWidth={labelWidth}>
              brett.eddy@heroicdevelopment.com
            </CardValue>
            <CardValue label="Contact Phone" labelWidth={labelWidth}>
              360-555-1212
            </CardValue>
          </div>
          <div className={classes.right}>
          </div>
        </CardContent>
      </Card>
    )
  }
}

export default withTheme((withStyles(styles)(StyleCard)))