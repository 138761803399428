import { Theme, createStyles, makeStyles } from "@material-ui/core"
import IconicButton from "./IconicButton"
import Edit from "@material-ui/icons/Edit"

const useStyles = makeStyles((theme: Theme) => createStyles({
  editButtonSmall: {
    width: 24,
    height: 24
  },
  editButtonMedium: {
    width: 32,
    height: 32
  },
  editButtonLarge: {
    width: 48,
    height: 48
  },
}))

const EditButton = ({
  size,
  onClick
}: {
  size?: "small" | "medium" | "large"
  onClick: () => void 
}) => {
  const classes = useStyles() 

  const buttonClass = () => {
    if (size === "small") {
      return classes.editButtonSmall
    } else if (size === "large") {
      return classes.editButtonLarge 
    } else {
      return classes.editButtonMedium 
    }
  }

  return (
    <IconicButton onClick={onClick} className={buttonClass()}>
      <Edit fontSize={ size ?? "medium"} />
    </IconicButton>  
  )
}

export default EditButton
