import React from "react";
import {
  TextField,
  withWidth,
  WithWidth
} from "@material-ui/core";
import { createStyles, Theme, WithStyles, WithTheme } from "@material-ui/core";
import { withStyles, withTheme } from "@material-ui/core/styles";
import { Autocomplete } from "@material-ui/lab";
import ProfileStore from "../../stores/ProfileStore";
import { makeObservable, observable } from "mobx";
import { inject, observer } from "mobx-react";
import UserStore from "../../stores/UserStore";
import AccountStore from "../../stores/AccountStore";
import Progress from "../Progress";
import { JobPostType } from "../../API";

const styles = (theme: Theme) => createStyles({
  filterTextField: {
    // width: 308,
    // width: "100%",
    flexGrow: 1, 
    // flex: 1, 
    backgroundColor: theme.palette.background.paper,
    borderRadius: 10
  }
})

export interface IJobPostTypeFilterProps {
  jobPostTypes: JobPostType[]
  onSelectJobPostType: (jobPostType?: JobPostType) => any 
  value?: JobPostType
  accountStore?: AccountStore
  profileStore?: ProfileStore
  progress?: Progress
  userStore?: UserStore 
}

@inject("accountStore", "profileStore", "userStore", "progress")
@observer
class JobPostTypeFilter extends React.Component<WithStyles<typeof styles> & WithTheme & IJobPostTypeFilterProps & WithWidth> {

  @observable jobPostTypeFilter?: JobPostType 

  constructor(props: WithStyles<typeof styles> & WithTheme & IJobPostTypeFilterProps & WithWidth) {
    super(props)
    makeObservable(this)
  }

  componentDidMount() {
    const { value } = this.props
    this.jobPostTypeFilter = value
  }

  componentDidUpdate(prevProps: any) {
    if (prevProps.value !== this.props.value) {
      this.jobPostTypeFilter = this.props.value
    }
  }

  render() {
    const { classes, jobPostTypes } = this.props

    return (
      <Autocomplete
        autoSelect
        includeInputInList
        options={jobPostTypes}
        getOptionLabel={(option: JobPostType) => {
          if (option === JobPostType.RecruitingServices) {
            return "Recruiting Services"
          } else if (option === JobPostType.SelfService) {
            return "Self Service"
          } else if (option === JobPostType.StaffingServices) {
            return "Staffing Services"
          } else {
            return ""
          }
        }}
        getOptionSelected={(option: JobPostType, value: JobPostType) => option === value}
        onChange={this.onSelectJobPostType}
        value={this.jobPostTypeFilter ?? null}
        renderInput={(params: any) =>
          <div ref={params.InputProps.ref}>
            <TextField
              {...params}
              className={classes.filterTextField}
              variant="outlined"
              size="small"
              name="jobPostTypeFilter"
              label="Job Post Type"
            />
          </div>
        }
      />
    )
  }

  onSelectJobPostType = (event: any, value: any, reason: any) => {
    const jobPostType = value as JobPostType 
    if (jobPostType) {
      this.jobPostTypeFilter = jobPostType  
    } else {
      this.jobPostTypeFilter = undefined
    }
    this.props.onSelectJobPostType(this.jobPostTypeFilter)
  }
}

export default withTheme(withStyles(styles)(withWidth()(JobPostTypeFilter)))