import { 
  Box, 
  Grid, 
  Typography 
} from "@material-ui/core"
import get from "lodash.get"
import { useEffect, useState } from "react"
import Page from "../../components/page/Page"
import TwoPanelSectionContainer from "../../components/page/TwoPanelSectionContainer"
import JobPost from "../../model/JobPost"
import Price from "../../model/stripe/Price"
import Product from "../../model/stripe/Product"
import { useStores } from "../../stores/StoreProvider"
import StripePaymentCart, { StripeOrderItem } from "./StripePaymentCart"
import * as APITypes from '../../API'
import { RouteComponentProps, useLocation } from "@reach/router"
import React from "react"
import PageTitle from "../../components/page/PageTitle"
import ControlTower, { Routes } from "../../components/ControlTower"
import Logger from "../../components/Logger"
import {durationBetweenISODates, getISODateToday, humanizeString} from "../../stores/StoreUtilities"
import LocationOnIcon from '@material-ui/icons/LocationOn';
import ActionButton from "../../components/controls/ActionButton"
import AddButton from "../../components/AddButton"
import CardValue from "../../components/CardValue"
import StripeOrderItemData from "../../model/stripe/StripeOrderItemData"
import FilterBar from "../../components/filter/FilterBar"
import AccountFilter from "../../components/filter/AccountFilter"
import ContractFilter from "../../components/filter/ContractFilter"
import Account from "../../model/Account"
import Contract from "../../model/Contract"
import {JobPostStatus} from "../../API";
import { ServiceRequestStatus } from "../../model/ServiceRequest"
import { when } from "mobx"
import { observer } from "mobx-react"

const progressName = 'JobPostPaymentCart'

const title = "Job Post(s) Cart"

const JobPostPaymentCartPage: React.FC<RouteComponentProps> = observer(() => {
  const [isLoading, setIsLoading] = useState(true)
  
  const [accountFilter, setAccountFilter] = useState<Account>()
  const [accountId, setAccountId] = useState<string>()
  const [accounts, setAccounts] = useState<Account[]>()
  const [contractFilter, setContractFilter] = useState<Contract>()
  const [contracts, setContracts] = useState<Contract[]>()

  const [jobPosts, setJobPosts] = useState<JobPost[]>([]) // List of all Cart job posts. 
  const [products, setProducts] = useState<Product[]>([]) 
  
  const [isPaid, setIsPaid] = useState(false)

  const { accountStore, progress, userStore, jobStore } = useStores()
  const location = useLocation()

  useEffect(() => {
    progress.show(progressName)

    const loadAccounts = async (): Promise<Account[]> => {
      if (userStore.user!.isAdminOrAgent) {
        const accounts: Account[] = await accountStore.listAccounts()
        const sorted = accounts.sort((a: Account, b: Account) => a.name.localeCompare(b.name))
        return sorted  
      } else {
        return []
      }
    }

    const loadContracts = async (accountId: string) => {
      const contracts = await jobStore.listContractsByAccount(accountId)
  
      const sorted = contracts
        .sort((a: Contract, b: Contract) => {
        if (accounts) {
          // Order the contracts by alphabetical account name.
          const accountA = accounts.find(account => account.id === a.accountId)
          const accountB = accounts.find(account => account.id === b.accountId)
          return accountA!.name.localeCompare(accountB!.name)
        } else {
          return a.accountId.localeCompare(b.accountId)
        }
      })
  
      return sorted 
    }

    const loadAccountId = (): string => {
      let accountId 

      if (userStore.isAdminOrAgent) {
        const searchParams = new URLSearchParams(location.search)
        const searchAccountId = searchParams.get("accountId")
        if (accountFilter) {
          accountId = accountFilter.id 
        } else if (searchAccountId) {
          accountId = searchAccountId
        } else {
          accountId = userStore.user!.accountId  
        }
      } else {
        accountId = userStore.user!.accountId
      }

      return accountId 
    }

    const loadProducts = async (): Promise<Product[]> => {
      const products: Product[] = await accountStore.listProductsByProductLineAndProductType('GovGigJobs', 'JobPost')
      return products 
    }

    const loadJobPosts = async (accountId: string): Promise<JobPost[]> => {
      let jobPosts: JobPost[]
      
      const status = JobPostStatus.Cart

      let filter: APITypes.ModelJobPostFilterInput
      
      if (contractFilter) {
        filter = {
          and: [
            {
              status: {
                eq: status
              }
            },
            {
              contractId: {
                eq: contractFilter.id 
              }
            }
          ]
        }
      } else {
        filter = {
          and: [
            {
              status: {
                eq: status
              }
            }
          ]
        }
      }


      jobPosts = await jobStore.listJobPostsByAccount(accountId, filter)

      return jobPosts
    }

    const init = async () => {
      try {
        if (!userStore!.isEmployer && !userStore!.isAdminOrAgent) {
          return
        }
        Logger.info("Loading account")
        const accountId = await loadAccountId()
        setAccountId(accountId)

        if (userStore.isAdminOrAgent) {
          if (!accounts) {
            const accounts = await loadAccounts()
            setAccounts(accounts)
          }
          const account = accounts && accounts.find(account => account.id === accountId)
          setAccountFilter(account)

          const contracts = await loadContracts(accountId)
          setContracts(contracts)
        }

        if (products.length === 0) {
          const products = await loadProducts()
          setProducts(products)
        }

        const jobPosts = await loadJobPosts(accountId)
        setJobPosts(jobPosts)

        setIsLoading(false)
      } catch (error) {
        Logger.error('error', JSON.stringify(error))
      }
      progress.hide(progressName)
    }

    when(
      () => accountStore.isLoading === false && jobStore.isLoading === false && userStore.isLoading === false,
      () => {
        init() 
      }
    )
  }, [
    accounts,
    accountFilter,
    accountStore, 
    contractFilter,
    isPaid, 
    jobStore,
    location.search,
    products,
    progress, 
    userStore
  ])

  const updateJobPostToPaid = async (jobPost: JobPost, invoiceResult: any, serviceRequestId: string): Promise<JobPost> => {
    const status = JobPostStatus.Draft

    const input: APITypes.UpdateJobPostInput = {
      id: jobPost.id, 
      status, // Move to Draft status (user still needs to publish when ready)
      invoiceId: invoiceResult.id, // Record the Stripe invoice id 
      serviceRequestId
    }

    const updatedJobPost = await jobStore.updateJobPost(input)

    return updatedJobPost!
  }

  const handleDeleteItem = async (orderItem: StripeOrderItem) => {
    const index = jobPosts.findIndex((jobPost: JobPost) => jobPost.id === orderItem.data.id)
    if (index >= 0) {
      const jobPost = jobPosts[index]
      await jobStore.deleteJobPost(jobPost.id, userStore)
      jobPosts.splice(index, 1)
      setJobPosts([...jobPosts])
    }
  }

  const handleInvoice = async (invoiceResult: any) => {

    const date = getISODateToday()

    const createServiceRequestInput: APITypes.CreateServiceRequestInput = {
      accountId: accountId!, 
      serviceRequestType: APITypes.ServiceRequestType.SelfService,
      name: `Self-service Job Post(s) ${date}`,
      status: ServiceRequestStatus.Submitted
    }

    const createdServiceRequest = await jobStore.createServiceRequest(createServiceRequestInput)

    const promises = jobPosts.map(jobPost => {
      return updateJobPostToPaid(jobPost, invoiceResult, createdServiceRequest!.id)
    })

    const updatedJobPosts = await Promise.all(promises)
    setJobPosts(updatedJobPosts)

    setIsPaid(true)
  }

  const renderJobPost = (jobPost: JobPost) => {
    return (
      <Box 
        // p={1}
        sx={{
          // borderRight: "1px solid #ccc",
          // pr: 3
        }}
      >
        <Grid container direction="column" alignItems="flex-start" spacing={1}>
          <Grid item>
            <Typography variant="h4">
              {jobPost.title}
            </Typography>
          </Grid>
          <Grid item>
            <Grid container direction="row" alignItems="center" spacing={1}>
              <Grid item>
                <CardValue
                  label="Location"
                >
                  <LocationOnIcon color="primary" fontSize="small" />
                </CardValue>
              </Grid>
              <Grid item>
                <Typography>
                  {jobPost.location!.name}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <CardValue
              label="Date(s)"
              // wrapLabelAndValue
              wrapText
            >
              {durationBetweenISODates(jobPost.startDate, jobPost.endDate)}
            </CardValue>
          </Grid>
          <Grid item>
            <CardValue
              label="Type"
              // wrapLabelAndValue
              wrapText
            >
              {humanizeString(jobPost.employmentType)} Hire
            </CardValue>
          </Grid>
        </Grid>
      </Box>
    )
  }

  const productForJobPostType = (jobPostType: string): Product | undefined => {
    let product 
    products.forEach(checkProduct => {
      const checkJobPostType = get(checkProduct.metadata, 'jobPostType')
      if (checkJobPostType === jobPostType) {
        product = checkProduct
      }
    })
    return product 
  }

  const priceForJobPost = (product: Product, jobPost: JobPost): Price | undefined => {
    let price 

    // Note that if there's no jobPostType, then we don't know the price yet. 
    // It's possible the user did not select a product for this JobPost before. 
    // So we'll need to direct them to select a product for this JobPost. 
    const jobPostType = jobPost.jobPostType

    // Note that this filter should be very quick for now. 
    // But if the user is adding a ton of job posts at once, 
    // we'll want a different method of figuring out how many are self-service. 
    const selfServiceJobPosts = jobPosts.filter(checkJobPost => checkJobPost.jobPostType === 'SelfService')

    if (jobPostType === 'SelfService') {
      // The price may be different for 1, 2, 5, 10 units, etc. 
      price = product.getPriceForQuantity(selfServiceJobPosts.length)
    } else if (jobPostType === 'RecruitingServices') {
      price = product.getPriceForQuantity(1)
    }
    
    return price 
  }

  const renderOrderItems = (): StripeOrderItem[] => {
    const orderItems: StripeOrderItem[] = []

    jobPosts.forEach((jobPost) => {
      const jobPostType = jobPost.jobPostType

      const product = productForJobPostType(jobPostType)
      let price: Price | undefined
      if (product) {
        price = priceForJobPost(product, jobPost)
      }

      const name = jobPost.title
      let description = name 
      if (product) {
        description += `, ${product?.name}`
      }

      let editRoute = `${Routes.jobPostCreate}/${jobPost.id}`

      if (!jobPost.jobPostType) {
        editRoute += '?step=priceSelect'
      }

      const orderItemData: StripeOrderItemData = {
        id: jobPost.id,
        name,
        description,
        editRoute,
        quantity: 1,
        unitPrice: price?.unitPrice, 
        amountDue: price?.unitPrice,
        product, 
        price
      }

      const orderItem: StripeOrderItem = {
        data: orderItemData,
        view: renderJobPost(jobPost)
      }

      orderItems.push(orderItem)
    })

    return orderItems
  }

  const renderEmptyCart = () => {
    return (
      <TwoPanelSectionContainer
        title="Empty Cart"
      >
        <Box p={2}>
          <Grid container direction="column" alignItems="center" spacing={2}>
            {/* <Grid item>
              <Typography>Head over to Manage Jobs to publish and track your Job Posts.</Typography>
            </Grid> */}
            <Grid item>
              <Grid container spacing={2}>
                <Grid item>
                  <ActionButton
                    color="inherit"
                    size="small"
                    text={"Manage Jobs"}
                    tracking={"Manage-Jobs-Purchase-Success"}
                    click={() => {
                      ControlTower.route(Routes.manageJobs)
                    }}
                  />
                </Grid>
                <Grid item>
                  <ActionButton
                    size="small"
                    text={"Create Job Post(s)"}
                    tracking={"Cart-Create-Job-Posts"}
                    click={() => {
                      ControlTower.route(Routes.jobPostCreate)
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </TwoPanelSectionContainer>
    )
  }

  const renderCart = () => {
    return (
      <StripePaymentCart 
        isLoading={isLoading} 
        title="Job Post(s) in Cart"
        note="If you need to make any changes, they will be indicated below. Note that you can still edit job post details later after checkout."
        orderItems={ renderOrderItems() }
        didCreateInvoice={handleInvoice}
        didDeleteItem={handleDeleteItem}
      >
        <Grid container justifyContent="flex-end">
          <Grid item>
            <Box pb={2}>
              <AddButton
                key="addJobButton"
                text="Add Job Post(s)"
                tracking="employerAddJobToContract"
                buttonColor="secondary"
                buttonVariant="contained"
                buttonSize="small"
                click={() => {
                  ControlTower.route(Routes.jobPostCreate)
                }}
                icon="add"
              />
            </Box>
          </Grid>
        </Grid>
      </StripePaymentCart>
    )
  }

  const renderSuccess = () => {
    return (
      <TwoPanelSectionContainer
        title="Success!"
        // note="When you're ready, your Job Post(s) can be published to the GovGig Job Board."
      >
        <Box p={2}>
          <Grid container direction="column" alignItems="center" spacing={2}>
            <Grid item>
              <Typography>Head over to Manage Jobs to publish and track your Job Posts.</Typography>
            </Grid>
            <Grid item>
              <Grid container spacing={2}>
                <Grid item>
                  <ActionButton
                    size="small"
                    text={"Manage Jobs"}
                    tracking={"Manage-Jobs-Purchase-Success"}
                    click={() => {
                      ControlTower.route(Routes.manageJobs)
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </TwoPanelSectionContainer>
    )
  }

  const renderFilterArea = () => {
    if (!userStore.user?.isAdminOrAgent) {
      return null 
    }
    return (
      <Box
        sx={{
          pb: 2 
        }}
      >
        <FilterBar

        >
          <AccountFilter
            key="accountFilter"
            value={accountFilter}
            accounts={accounts!}
            onSelectAccount={async (account?: Account) => {
              setAccountFilter(account)
            }}
          /> 
          <ContractFilter
            key="contractFilter"
            value={contractFilter}
            accounts={accounts!}
            contracts={contracts!}
            onSelectContract={async (contract?: Contract) => {
              setContractFilter(contract) 
            }}
          />
        </FilterBar>
      </Box>
    )
  }

  return (
    <Page
      title={title}
    >
      <Box 
        sx={{
          p: 2,
          width: '100%',
          minHeight: '100%'
        }}
      >
        <PageTitle title={title} />
        { renderFilterArea() }
        { isLoading === false && isPaid === false && jobPosts.length === 0 && 
          renderEmptyCart() 
        }
        { isLoading === false && isPaid === false && jobPosts.length > 0 && 
          renderCart()
        }
        { isLoading === false && isPaid === true && 
          renderSuccess()
        }
      </Box>
    </Page>
  )
})

export default JobPostPaymentCartPage
