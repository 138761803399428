import {
  Box, createStyles, Grid, isWidthDown, Theme, withWidth, WithWidth
} from "@material-ui/core";
import { withStyles, WithStyles, withTheme, WithTheme } from "@material-ui/core/styles";
import { RouteComponentProps } from "@reach/router";
import SearchBar from "material-ui-search-bar";
import { makeObservable, observable, when } from "mobx";
import { inject, observer } from "mobx-react";
import * as React from 'react';
import {ModelUserFilterInput, UpdateUserInput, UserRole, UserStatus} from "../../API";
import AccountFilter from "../../components/filter/AccountFilter";
import FilterBar from "../../components/filter/FilterBar";
import TextFieldValidator from "../../components/form/TextFieldValidator";
import Notify from "../../components/notify/Notify";
import Progress from "../../components/Progress";
import Account from "../../model/Account";
import User from "../../model/User";
import AccountStore from "../../stores/AccountStore";
import { phoneToNationalFormat } from "../../stores/StoreUtilities";
import UserStore from "../../stores/UserStore";
import PeopleCard from './PeopleCard';
import UserSettingsDialog from "../settings/user-settings/UserSettingsDialog";
import AddButton from "../../components/AddButton";

const styles = (theme: Theme) => createStyles({
  content: {
    flexGrow: 1,
    width: "100%"
  },
  searchbar: {
    display: "flex",
    flexGrow: 2,
    width: '100%',
    height: 38
  },
  cardspace: {
    // width: "100%",
    overflowY: "auto",
    paddingBottom: theme.spacing(3), // card shadow was being cut off on left and bottom of cardspace, card size reduced by 3 to accommodate
    paddingLeft: theme.spacing(1), 
    paddingRight: theme.spacing(1),
    paddingTop: theme.spacing(1)
  },
  searchIcon: {
    color: theme.palette.secondary.main
  },
  searchFilter: {
    flexGrow: 1
  },
  form: {
    overflowY: "auto",
    display: "flex",
    flexDirection: "column",
  },
  name: {
    fontSize: 20,
    fontWeight: 600,
    color: theme.palette.primary.main,
  },
  accountFilter: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: 10,
  },
  roleFilter: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: 10,
  },
  statusFilter: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: 10,
  },
  numFound: {
    flexGrow: 1,
    width: "100%",
    padding: 3,
    color: "initial",
  },
})

class PeopleCardViewModel {
  @observable id: string
  @observable firstName: string
  @observable lastName: string
  @observable address: string
  @observable city: string
  @observable state: string
  @observable postalCode: string
  @observable email: string
  @observable phone: string
  @observable role: string
  @observable userStatus: UserStatus

  constructor (u: User) {
    makeObservable(this);

    this.id = u.id
    this.firstName = u.firstName
    this.lastName = u.lastName
    this.address = u.address
    this.city = u.city
    this.state = u.state
    this.postalCode = u.postalCode
    this.email = u.email
    this.phone = phoneToNationalFormat(u.phone)
    this.role = u.role.toString()
    this.userStatus = u.userStatus
  }
}

type Filter = 'accountFilter' | 'roleFilter' | 'statusFilter'

interface IPeopleListProps {
  visibleFilters?: Filter[] // TODO: Still needed? 
  // onlyMyAccounts?: boolean

  accountId?: string 
  
  accountStore?: AccountStore
  userStore?: UserStore
  notify?: Notify
  progress?: Progress
}

@inject("accountStore", "userStore", "notify", "progress")
@observer
class PeopleList extends React.Component<WithStyles<typeof styles> & RouteComponentProps & IPeopleListProps & WithTheme & WithWidth> {
  // @observable usersUnfiltered: User[] = []
  @observable users: User[] = []
  @observable search: string = ""
  @observable sortBy?: any = {field: "default", asc: true}
  @observable accounts: Account[] = []
  @observable accountFilter?: Account
  @observable roleFilter?: string
  @observable statusFilter: UserStatus | null = null
  @observable user?: User

  @observable viewModel?: PeopleCardViewModel
  @observable isFormOpen: boolean = false
  @observable isProcessing: boolean = false
  @observable searchTip: string = "(Enter a case-sensitive search string or other filters)"

  constructor(props: any) {
    super(props)
    makeObservable(this);
  }

  componentDidMount() {
    const { accountStore, progress } = this.props

    when(
      () => !accountStore!.isLoading && accountStore!.account !== undefined,
      async () => {
        progress!.show("PeopleList")
        await this.loadAccounts()
        progress!.hide("PeopleList")
      }
    )
  }

  // async componentDidUpdate(prevProps: any, prevState: any) {
  //   const { accountId, progress } = this.props 
  //   const { prevAccountId } = prevProps 
  //   if (accountId !== prevAccountId) {
  //     if (this.accounts.length === 1 && this.accounts[0].id !== accountId) {
  //       progress!.show("PeopleList")
  //       await this.loadAccounts()
  //       progress!.hide("PeopleList")
  //     }
  //   }
  // }

  render() {
    const {
      accountId, 
      classes,
      userStore,
      width
    } = this.props

    const isExtraSmall = isWidthDown("xs", width)

    return (
    <Box paddingTop={1} paddingBottom={3} width={'100%'}>
      {this.renderFilterBar()}

      <div className={classes.numFound} key={'content'}>
        {this.users.length} People found{ this.accountFilter ? `, ${this.accountFilter.name}` : null }&nbsp;{this.searchTip}
      </div>
      <Grid container spacing={2} wrap={isExtraSmall ? "nowrap" : "wrap"} direction={isExtraSmall ? "column" : "row"} justifyContent="flex-start" alignItems="stretch" className={classes.cardspace} key={'grid'}>
        {this.users.map((user: any) => {
          return (
          <Grid item xs={12} md={6} lg={4} key={user.id}>
            <PeopleCard user={user} handleEdit={this.handleEdit}/>
          </Grid>
          )
        })}
        {this.isFormOpen &&
          <UserSettingsDialog
            open={true}
            onCreate={this.handleCreate}
            onUpdate={this.handleUpdate}
            onClose={this.handleClose}
            user={this.user}
            accounts={this.accountFilter ? [this.accountFilter] :
              userStore!.isAdminOrAgent && !accountId ? this.accounts : undefined
            }
          />

        }
      </Grid>
    </Box>
    )
  }

  renderFilterBar = () => {
    const {
      classes,
      visibleFilters = ['accountFilter', 'roleFilter', 'statusFilter'],
      // accountStore,
      accountId,
      userStore
    } = this.props

    // const showRoleFilter = !onlyMyAccounts || accountStore!.isPrimary

    return (
      <Box py={1}>
        <FilterBar
          searchResultCount={this.users.length}
          buttons={[
            <AddButton
              text="Add Person"
              tracking="settingsAddPerson"
              buttonColor="secondary"
              buttonVariant="contained"
              buttonSize="medium"
              click={() => this.isFormOpen = true}
              key="addButton"
            />
          ]}
        >
          <div className={classes.searchFilter} key={'searchFilter'}>
            <SearchBar className={classes.searchbar} value={this.search} onChange={this.onSearchChange}
                       classes={{icon: classes.searchIcon}} placeholder={"Search by first, last or email"}
                       onRequestSearch={this.onRequestSearch} onCancelSearch={this.onCancelSearch}/>
          </div>

          {visibleFilters.includes('accountFilter') && !accountId && userStore!.isAdminOrAgent && 
            <AccountFilter
              accounts={this.accounts}
              value={this.accountFilter}
              onSelectAccount={this.onChangeAccount}
              key="accountFilter"
            />
          }

          {visibleFilters.includes('roleFilter') && !accountId && userStore!.isAdminOrAgent && (
            <div key={'roleFilter'}>
              <TextFieldValidator
                type="text"
                className={classes.roleFilter}
                variant="outlined"
                size="small"
                name="roleFilter"
                label="Role"
                value={this.roleFilter}
                autocompleteOptions={{
                  options: [...Object.values(UserRole)],
                  onChange: this.onSelectRole
                }}
              />
            </div>
          )}

          {visibleFilters.includes('statusFilter') && (
            <div key="statusFilter">
              <TextFieldValidator
                type="text"
                className={classes.statusFilter}
                variant="outlined"
                size="small"
                name="statusFilter"
                label="Status"
                autocompleteOptions={{
                  options: [...Object.keys(UserStatus)],
                  onChange: this.onSelectStatus,
                  value: this.statusFilter
                }}
              />
            </div>
          )}

          <AddButton
            icon="search"
            text="Search"
            tracking="searchPeople"
            buttonColor="secondary"
            buttonVariant="contained"
            buttonSize="medium"
            click={this.onSearch}
            key="searchButton"
          />

        </FilterBar>
      </Box>
    )
  }

  statusToString(status: boolean | null) {
    return status === null ? "All" : status ? "Active" : "Inactive"
  }

  async loadAccounts () {
    const { accountId, accountStore, userStore } = this.props

    let accounts: Account[] = []
    if (accountId) {
      const account = await accountStore!.getAccount(accountId)
      accounts = [account!]

      this.accountFilter = account! 
    } else {
      if (userStore!.isAdminOrAgent) {
        accounts = await accountStore!.listAccounts()
        accounts.sort((a: Account, b: Account) => a.name.localeCompare(b.name))
      } else if (userStore!.user!.account) {
        // Only allow employers to access their own account
        accounts = [userStore!.user!.account]
      }

      if (window.location) {
        const searchParams = new URLSearchParams(window.location?.search)
        const accountId = searchParams.get("accountId")
        if (accountId) {
          this.accountFilter = this.accounts.find(a => a.id === accountId)
        }
        // TODO: Switch to using accountId param
        const defaultAccountFilterName = searchParams.get("acctname")
        if (defaultAccountFilterName) {
          this.accountFilter = this.accounts.find(a => a.name === defaultAccountFilterName)
        }
      }
    }

    this.accounts = accounts

    if (this.accountFilter) {
      this.onSearch()
    }
  }

  onSearch = async () => {
    const { accountStore, progress } = this.props

    progress!.show("PeopleList")

    this.searchTip = ""

    let users: User[] = []

    let filter: ModelUserFilterInput | undefined = {
      and: []
    }

    if (this.search.length > 0) {
      filter.and!.push(
        {or: [
            {firstName: {beginsWith: this.search}},
            {lastName: {beginsWith: this.search}},
            {email: {beginsWith:this.search.toLowerCase()}}
          ]}
      )
    }

    if (this.roleFilter) {
      filter.and!.push(
        {role: {eq: UserRole[this.roleFilter]}}
      )
    }

    if (this.statusFilter) {
      filter.and!.push(
        {userStatus: {eq: UserStatus[this.statusFilter]}}
      )
    }

    if (filter.and!.length === 0) {
      filter = undefined
    } else if (filter.and!.length === 1) {
      filter = filter.and![0]!
    }

    if (this.accountFilter) {
      users = await accountStore!.listUsersByAccount(this.accountFilter.id, filter)
    } else if (filter) {
      users = await accountStore!.listUsers(filter)
    } else {
      this.searchTip = "(Enter a  case-sensitive search string or other filters)"
    }

    this.users = users
    if (users.length > 0) {
      this.sortUsers()
    }

    progress!.hide("PeopleList")
  }

  onSearchChange = (value: string) => {
    this.search = value
  }

  onChangeAccount = (account?: Account) => {
    this.accountFilter = account
  }

  onSelectRole = (event: any, value: any, reason: string) => {
    this.roleFilter = value
  }

  onSelectStatus = (event: any, value: any, reason: string) => {
    this.statusFilter = value
  }

  // searchText = (text: string, search: string) => {
  //   return (
  //     text ? text.toLowerCase().indexOf(search) >= 0 : false
  //   )
  // }

  sortUsers = () => {
    if (this.sortBy.field === "default") {
      if (this.sortBy.asc) {
        this.users.sort((a: User, b: User) => {
          if (a.lastName === b.lastName) {
            return a.firstName.localeCompare(b.firstName)
          } else {
            return a.lastName.localeCompare(b.lastName)
          }
        })
      } else {
        this.users.sort((a: User, b: User) => {
          if (a.lastName === b.lastName) {
            return b.firstName.localeCompare(a.firstName)
          } else {
            return b.lastName.localeCompare(a.lastName)
          }
        })
      }
    }
  }

  onRequestSearch = () => {
    this.search = this.search ? this.search.trim() : ""
    this.onSearch()
  }

  onCancelSearch = () => {
    this.search = ""
  }

  handleCreate = (user: User) => {
    this.users.push(user)
    this.sortUsers()
  }

  handleUpdate = (user: User) => {
    const updatedUserIndex = this.users.findIndex((u: User) => u.id === user.id)
    if (updatedUserIndex >= 0) {
      this.users[updatedUserIndex] = user
    }
  }

  handleEdit = (user: any): void => {
    if (user) {
      this.user = user
      this.isFormOpen = true
    }
  }

  handleClose = () => {
    this.isFormOpen = false
    this.user = undefined
  }
}

export default withTheme(withStyles(styles)(withWidth()(PeopleList)))


