import { createStyles, Paper, Theme, Typography, withStyles, WithStyles, WithTheme, withTheme, withWidth, WithWidth } from "@material-ui/core";
import React, { ReactNode } from "react";

const styles = (theme: Theme) => createStyles({
  container: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    color: theme.palette.primary.main
  },
  panel: {
    padding: theme.spacing(3)
  },
  titleBar: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    paddingBottom: theme.spacing(2)
  },
  title: {
    flex: 1
  },
  button: {
    flex: 0,
    width: 50,
    textAlign: "right"
  }
})

export interface IProfileCardProps {
  title: string | ReactNode
  button?: any
  overrides?: any
}

class ProfileCard extends React.Component<WithStyles<typeof styles> & WithTheme & WithWidth & IProfileCardProps> {
  render() {
    const {
      classes,
      title,
      button,
      overrides
    } = this.props

    const containerClassNme = (overrides && overrides.container) ? overrides.container : classes.container
    const titleBarClassName = (overrides && overrides.titleBar) ? overrides.titleBar : classes.titleBar

    return (
      <div className={containerClassNme}>
        <Paper elevation={1} className={classes.panel}>
          <div className={titleBarClassName}>
            <div className={classes.title}>
              <Typography variant="h3">
                {title}
              </Typography>
            </div>
            <div className={classes.button}>
              {button}
            </div>
          </div>
          {this.props.children}
        </Paper>
      </div>
    )
  }
}

export default withTheme(withStyles(styles)(withWidth()(ProfileCard)))
