import {ProfileStatus, SecurityStatus, WorkSetting} from "../API";
import Certification from "./Certification";
import Education from "./Education";
import Experience from "./Experience";
import JobCandidate from "./JobCandidate";
import ProfileLocation from "./ProfileLocation";
import ProfileService from "./ProfileService";
import User from "./User";
import {isoToLocalDate} from "../stores/StoreUtilities";
import {differenceInDays, startOfToday} from "date-fns";
import {NAICS} from "./Industry";

export enum RateRanges {
  $40_50K = "$40-50k",
  $50_60K = "$50-60k",
  $60_70K = "$60-70k",
  $70_80K = "70-80k",
  $80_90K = "$80-90k",
  $90_100K = "$90-100k",
  $100_110K = "$100-110k",
  $110_120K = "$110-120k",
  $120_130K = "$120-130k",
  $130_140K = "$130-140k",
  $140_150K = "$140-150k",
  $150_160K = "$150-160k",
  $160_170K = "$160-170k",
  $170_180K = "$170-180k",
  $180_190K = "$180-190k",
  $190_200K = "$190-200k"
}

export enum ProfileStatusMessages {
  Pending = "Please complete your GovGig profile and then submit it for review by an Agent. Your profile will not be visible to Employers until you are approved to be a Candidate.",
  Submitted = "Your GovGig profile has been submitted and is waiting for review by an Agent.",
  Reviewing = "Your GovGig profile is being reviewed by an Agent.",
  Verifying = "Your background is being verified by an Agent.",
  Accepted = "Your GovGig profile has been accepted!",
  Declined = "Your GovGig profile has been declined. You are welcome to resubmit it in the future.",
  Approved = "Your GovGig profile has been approved!", // Deprecated
  Rejected = "Your GovGig profile has been rejected.", // Deprecated
  Academy = "Your GovGig profile has insufficient qualifications for GovGig jobs. We recommend you enroll in GovGig Academy courses to prepare you for federal construction jobs.",
  Future = "GovGig does not currently have any job titles to match your profile. We will notify you when we have added one(s) that match your profile.",
  Inactive = "Your GovGig profile has been marked as inactive due to an incomplete profile. You are welcome to resubmit it in the future."
}

export const ProfileAvailabilityOptions : string[] = [
  "Immediate",
  "In 1-2 weeks",
  "In 2-4 weeks",
  "In 4 or more weeks",
  "In the future",
  "Unavailable"
]

class Profile {
  id: string
  createdAt: string
  updatedAt: string
  alias?: string
  active: boolean
  userId: string
  user?: User
  nickname: string
  initials: string
  profileServices: ProfileService[]
  profileLocations: ProfileLocation[]
  availability?: string
  availableDate?: string
  desiredRate?: string
  about?: string
  resumeKey?: string
  profileStatus: ProfileStatus
  statusMessage?: string
  experiences: Experience[]
  certifications: Certification[]
  education: Education[]
  notes?: string
  city?: string
  state?: string
  country?: string
  federalExperience: number
  totalExperience: number
  industries: string[]
  securityClearance?: string
  securityStatus: SecurityStatus
  workSettings: WorkSetting[]
  credentials?: string 


  constructor(data: any) {
    this.id = data.id
    this.createdAt = data.createdAt
    this.updatedAt = data.updatedAt
    this.alias = data.alias
    this.active = data.active
    this.userId = data.userId
    this.user = data.user ? new User(data.user) : undefined
    this.nickname = data.nickname
    this.initials = this.getInitials()
    this.profileServices = []
    if (data.profileServices && data.profileServices.items) {
      this.loadProfileServices(data.profileServices.items)
    }
    this.profileLocations = []
    if (data.profileLocations && data.profileLocations.items) {
      this.loadProfileLocations(data.profileLocations.items)
    }
    this.availability = data.availability
    this.availableDate = data.availableDate
    // Compute availability from availableDate if not set
    if (!this.availability && this.availableDate) {
      const days = differenceInDays(isoToLocalDate(this.availableDate), startOfToday())
      if (days <= 0) {
        this.availability = ProfileAvailabilityOptions[0]
      } else if (days <= 14) {
        this.availability = ProfileAvailabilityOptions[1]
      } else if (days <= 28) {
        this.availability = ProfileAvailabilityOptions[2]
      } else if (days <= 56) {
        this.availability = ProfileAvailabilityOptions[3]
      } else {
        this.availability = ProfileAvailabilityOptions[4]
      }
    }
    this.desiredRate = data.desiredRate
    this.about = data.about
    this.resumeKey = data.resumeKey
    this.profileStatus = ProfileStatus[data.profileStatus]
    this.statusMessage = data.statusMessage
    this.certifications = []
    this.experiences = []
    this.education = []
    if (data.experiences && data.experiences.items) {
      this.loadExperiences(data.experiences.items)
    }
    if (data.certifications && data.certifications.items) {
      this.loadCertifications(data.certifications.items)
    }
    if (data.education && data.education.items) {
      this.loadEducation(data.education.items)
    }
    this.notes = data.notes
    this.city = data.city
    this.state = data.state
    this.country = data.country
    this.federalExperience = data.federalExperience ?? 0
    this.totalExperience = data.totalExperience ?? 0
    this.industries = data.industries ?? [NAICS.Construction]
    this.securityClearance = data.securityClearance
    this.securityStatus = SecurityStatus[data.securityStatus] ?? undefined
    this.workSettings = data.workSettings ?? []
    this.credentials = data.credentials 
  }

  get name() {
    if (this.user) {
      return this.user.fullName
    } else if (this.initials) {
      return this.initials
    } else {
      return "No name"
    }
  }

  private getInitials() {
    let initials = ""
    if (this.nickname) {
      const parts = this.nickname.split(' ')
      if (parts.length > 0) {
        initials += parts[0].substr(0, 1)
      }
      if (parts.length > 1) {
        initials += parts[1].substr(0, 1)
      }
    } else {
      initials = "??"
    }
    return initials.toUpperCase()
  }

  get isEmpty() {
    return Boolean(
      !this.about
      && !this.resumeKey
      && (!this.education || this.education.length === 0)
      && (!this.experiences || this.experiences.length === 0)
      && (!this.certifications || this.certifications.length === 0)
    )
  }

  loadProfileServices(items: any[]) {
    this.profileServices = items.map((item: any) => new ProfileService(item))
  }

  loadProfileLocations(items: any[]) {
    this.profileLocations = items.map((item: any) => new ProfileLocation(item))
  }

  loadExperiences(items: any[]) {
    this.experiences = items.map((item: any) => new Experience(item))
  }

  loadCertifications(items: any[]) {
    this.certifications = items.map((item: any) => new Certification(item))
  }

  loadEducation(items: any[]) {
    this.education = items.map((item: any) => new Education(item))
  }

  get statusMessageOrDefault(): string {
    return this.statusMessage ?? ProfileStatusMessages[this.profileStatus]
  }

  hasService(serviceId: string) : boolean {
    return this.profileServices && this.profileServices.findIndex((item: ProfileService) => serviceId === item.serviceId) >= 0
  }
  
  getProfileService(serviceId: string) : ProfileService | undefined {
    let profileService
    if (this.profileServices) {
      profileService = this.profileServices!.find((item: ProfileService) => serviceId === item.serviceId)
    }
    return profileService
  }

  hasLocation(locationId: string) : boolean {
    return this.profileLocations && this.profileLocations.findIndex((item: ProfileLocation) => locationId === item.locationId) >= 0
  }

  getProfileLocation(locationId: string) : ProfileLocation | undefined {
    let profileLocation
    if (this.profileLocations) {
      profileLocation = this.profileLocations!.find((item: ProfileLocation) => locationId === item.locationId)
    }
    return profileLocation
  }

  getIsUnlocked(user?: User, jobCandidate?: JobCandidate): boolean {
    let isUnlocked = false 
    if (user) {
      if (user.isAdminOrAgent) {
        isUnlocked = true
      } else if (user.isEmployer && jobCandidate) {
        isUnlocked = jobCandidate.unlocked
      } else if ((user.isCandidate || user.isApplicant) && user.id === this.userId) {
        isUnlocked = true 
      }
    }
    return isUnlocked
  }

  canSubmit() : boolean {
    return (this.profileStatus !== ProfileStatus.Submitted && this.profileStatus !== ProfileStatus.Reviewing &&
      this.profileStatus !== ProfileStatus.Verifying && this.profileStatus !== ProfileStatus.Accepted)
  }

  inReview() : boolean {
    return (this.profileStatus === ProfileStatus.Pending || this.profileStatus === ProfileStatus.Submitted ||
      this.profileStatus === ProfileStatus.Reviewing || this.profileStatus === ProfileStatus.Verifying)
  }

  findCertification(certificationType: string): Certification | undefined {
    const search = certificationType.toLowerCase()
    return (this.certifications.find((c: Certification) => {
      const index = c.certificationType.toLowerCase().indexOf(search)
      return index >= 0
    }))
  }
}

export default Profile