import { Box, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, LinearProgress, PropTypes } from "@material-ui/core"
import CancelButton from "../form/CancelButton"
import FormValidator from "../form/FormValidator"
import CloseIcon from '@material-ui/icons/Close';
import { useRef, useState } from "react";
import ProgressButton from "../form/ProgressButton";
import Logger from "../Logger";
import DialogLoadingProgress from "./DialogLoadingProgress";

const AppDialog = ({
  title,
  submitLabel, 
  secondaryLabel, 
  dialogId, 
  formId, 
  shouldHideActionButtons,  
  isLoading, 
  isOpen,
  maxWidth, 
  scroll, 
  secondaryAction,
  secondaryColor,
  secondaryIcon,
  onCancel,
  onSubmit, 
  children,
}: {
  title: string
  submitLabel?: string 
  secondaryLabel?: string 
  dialogId: string 
  formId: string  
  shouldHideActionButtons?: boolean 
  isLoading: boolean 
  isOpen: boolean 
  maxWidth?: 'sm' | 'md' | 'lg'
  scroll?: 'body' | 'paper'
  secondaryAction?(): Promise<void>
  secondaryColor?: PropTypes.Color 
  secondaryIcon?: React.ReactNode
  onCancel(event?: object, reason?: string): void
  onSubmit(event: React.FormEvent<HTMLFormElement>): Promise<void>
  children: React.ReactNode
}) => {
  const ariaLabel = `${dialogId}-title`
  const formRef = useRef<HTMLFormElement>(null)
  const [isProcessing, setIsProcessing] = useState(false)
  const [isProcessingSecondary, setIsProcessingSecondary] = useState(false)
  
  return (
    <Dialog
      id={dialogId}
      open={isOpen}
      onClose={onCancel}
      maxWidth={ maxWidth ? maxWidth : 'sm' }
      fullWidth
      aria-labelledby={ariaLabel}
      scroll={ scroll ? scroll : 'body' }
    >
      <DialogTitle id={ariaLabel}>
        {title}
        <IconButton
          aria-label="close"
          onClick={onCancel}
          style={{
            position: 'absolute',
            right: 8,
            top: 4,
            color: '#fff'
          }}
          // TODO: MUI 5.
          // sx={{
          //   position: 'absolute',
          //   right: 8,
          //   top: 8,
          //   color: (theme: Theme) => theme.palette.grey[500],
          // }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogLoadingProgress isLoading={isLoading} />
      <DialogContent>
        <FormValidator
          key={`appdialog/form/${formId}`} 
          autoComplete="off" 
          name={formId}
          id={formId}
          ref={formRef}
          onSubmit={(e: React.FormEvent<HTMLFormElement>) => {
            setIsProcessing(true)
            onSubmit(e)
              .then(() => {
                setIsProcessing(false)
              })
              .catch((error) => {
                Logger.error('Error on dialog form submit', error)
                setIsProcessing(false)
              })
          }}
        >
          {children}
        </FormValidator>
      </DialogContent>
      { (shouldHideActionButtons === undefined || shouldHideActionButtons === false) && 
        <DialogActions>
          <Grid container justifyContent={ secondaryAction ? "space-between" : "flex-end" }>
            { secondaryAction && secondaryLabel && 
              <Grid item>
                <ProgressButton 
                  variant="contained"
                  color={ secondaryColor ?? "default" } 
                  processing={isProcessingSecondary}
                  startIcon={secondaryIcon}
                  onClick={async () => {
                    setIsProcessingSecondary(true)
                    await secondaryAction() // TODO: Test by deleting a job post. 
                    setIsProcessingSecondary(false)
                  }}
                >
                  {secondaryLabel}
                </ProgressButton>
              </Grid>
            }
            <Grid item>
              <Grid container>
                <Grid item>
                  <CancelButton 
                    onClick={onCancel} 
                  />
                </Grid>
                <Grid item>
                  <ProgressButton
                    variant="contained"
                    size="medium" 
                    color="secondary" 
                    type="submit"
                    processing={isProcessing}
                    onClick={(
                      event: React.MouseEvent<HTMLButtonElement, MouseEvent>
                    ) => {
                      event.preventDefault()

                      // https://stackoverflow.com/a/69085585/396110
                      // https://stackoverflow.com/questions/70666338/react-dynamic-form-input-useref-typeerror

                      // In order to make the dialog title bar and buttons 
                      // not scroll with the dialog content,
                      // we need to use this workaround to have a hidden button
                      // that will trigger the form submit. 
                      // We then virtually click the hidden button via this DialogButton. 
                      // The reason we need to do this is because 
                      // DialogContent needs to be separate element from DialogActions in 
                      // order for the "sticky" header/footer and scrolling to work. 

                      formRef.current!.onSubmit()
                    }}
                  >
                    {submitLabel ?? "Save"}
                  </ProgressButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </DialogActions>
      }
    </Dialog>
  )
}

export default AppDialog
