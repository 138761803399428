import * as React from 'react'
import {Box, createStyles, Grid, isWidthDown, Theme, withStyles, WithStyles, withTheme, WithTheme, WithWidth, withWidth} from "@material-ui/core";
import {inject, observer} from "mobx-react";
import UserStore from "../../../stores/UserStore";
import AccountStore from "../../../stores/AccountStore";
import Account from "../../../model/Account"
import {makeObservable, observable, when} from "mobx";
import Progress from "../../../components/Progress";
import AccountChargeCard from "./AccountChargeCard";
import Charge from "../../../model/stripe/Charge";
import {RouteComponentProps} from "@reach/router";

const styles = (theme: Theme) => createStyles({
  cardspace: {
    paddingBottom: theme.spacing(3), 
    paddingLeft: theme.spacing(1), 
    paddingRight: theme.spacing(1),
    paddingTop: theme.spacing(1)
  },
  card: {
    width: "100%",
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1)
  },
})

interface IAccountBillingProps {
  userStore?: UserStore
  accountStore?: AccountStore
  progress?: Progress
}

@inject("userStore", "accountStore", "progress")
@observer
class AccountBilling extends React.Component<WithStyles<typeof styles> & IAccountBillingProps & WithTheme & WithWidth> {

  @observable account?: Account
  @observable charges: Charge[] = []
  @observable isLoading = true

  constructor(
    props: WithStyles<typeof styles> & RouteComponentProps & IAccountBillingProps & WithTheme & WithWidth
  ) {
    super(props);
    makeObservable(this);
  }

  componentDidMount () {
    const { progress } = this.props
    this.isLoading = true
    progress!.show("AccountBilling")
    const { userStore, accountStore} = this.props
    when(
      () => !userStore!.isLoading && !accountStore!.isLoading,
      () => {
        this.account = accountStore!.account
        this.loadCharges()
      }
    )
  }

  render() {
    const { classes, width } = this.props

    if (this.isLoading) {
      return null
    }

    const isSmall = isWidthDown('sm', width)

    return (
      <Box paddingTop={1} width={'100%'}>
        <Grid container direction={ isSmall ? "column" : "row" } spacing={2} className={classes.cardspace}>
          {this.charges.map((charge: Charge) => {
            return <Grid key={charge.id} item xs={12} className={classes.card}>
              <AccountChargeCard charge={charge!}/>
            </Grid>
          })}
        </Grid>
      </Box>
    )
  }

  private async loadCharges() {
    const { accountStore, progress } = this.props

    this.charges = await accountStore!.listCharges(this.account!.id)
    // this.charges.push(new Charge({id: 'charge-1', amount: 10000, created: 1600899319, receipt_number: 'receipt-1'}))
    this.isLoading = false
    progress!.hide("AccountBilling")
  }


}

export default withTheme((withStyles(styles)(withWidth()(AccountBilling))))