import * as React from 'react'
// UI
import {
  Box,
  createStyles, alpha,
  Grid,
  Paper, TextareaAutosize,
  Theme,
  Typography,
  withStyles,
  WithStyles,
  withTheme,
  WithTheme
} from "@material-ui/core";
// Models
import UserActivity, {ActivityType} from '../../model/UserActivity';
import { grey } from '@material-ui/core/colors';
import {isoDateToWhen, isoToLocalDateString} from '../../stores/StoreUtilities';
import {CreateUserActivityInput, SubjectType, UpdateUserActivityInput} from '../../API';
import ControlTower, { Routes } from '../../components/ControlTower';
import IconicButton from "../controls/IconicButton";
import LaunchIcon from '@material-ui/icons/Launch';
import DoneIcon from '@material-ui/icons/Done';
import {makeObservable, observable} from "mobx";
import {inject, observer} from "mobx-react";
import UserStore from "../../stores/UserStore";
import EditIcon from "@material-ui/icons/Edit";

const styles = (theme: Theme) => createStyles({
  border: {
    border: `1px solid ${grey[300]}`
  },
  note: {
    backgroundColor: alpha(theme.palette.secondary.light, 0.1)
  },
  activityDetails: {
    paddingTop: 4
  },
  activityDate: {
    paddingTop: 6
  },
  textarea: {
    fontFamily: [
      'Roboto',
      'sans-serif'
    ].join(','),
    minWidth: "100%",
    maxWidth: "100%",
    padding: 8,
    fontSize: 16,
    borderColor: grey[400],
  },

})

interface IUserActivityCardProps {
  userActivity: UserActivity
  elevation: number
  onChange?(userActivity: UserActivity): void

  userStore?: UserStore
}

@inject("userStore")
@observer
class UserActivityCard extends React.Component<WithStyles<typeof styles> & IUserActivityCardProps & WithTheme> {
  @observable details: string = ""
  @observable isUpdated: boolean = false
  @observable isEditing: boolean = false

  constructor(
    props: WithStyles<typeof styles> & IUserActivityCardProps & WithTheme
  ) {
    super(props);
    makeObservable(this)
  }

  componentDidMount() {
    const { userActivity } = this.props
    this.details = userActivity.details
    if (!userActivity.id) {
      this.isEditing = true
    }
  }

  render() {
    const { classes, elevation, userActivity } = this.props

    const boxClassName = userActivity.activityType === ActivityType.NoteAdded ? classes.note : undefined

    const when = isoDateToWhen(userActivity.createdAt)
    let content
    if (userActivity.actorName) {
      if (userActivity.updatedAt > userActivity.createdAt) {
        content = <p><b>{userActivity.actorName}</b> - {when} (edited {isoDateToWhen(userActivity.updatedAt)})<br/>{userActivity.details}</p>
      } else {
        content = <p><b>{userActivity.actorName}</b> - {when}<br/>{userActivity.details}</p>
      }
    } else {
      content = <p><b>{when}</b><br/>{userActivity.details}</p>
    }

    return (
      <Paper elevation={ elevation } className={ elevation === 0 ? classes.border : undefined }>
        <Box p={ 2 } className={boxClassName}>
          <Box display="flex" flexGrow={1} justifyContent="space-between">
            <Box display="flex" flexGrow={1}>
              {!this.isEditing &&
                content
              }
              {this.isEditing &&
                <TextareaAutosize
                  name="details"
                  value={this.details}
                  onChange={this.handleChange}
                  minRows={1}
                  className={classes.textarea}
                  placeholder="Add a note"
                />
              }
            </Box>
            <Box display="flex" flexGrow={0} height={32}>
              {this.renderButton()}
            </Box>
          </Box>
        </Box>
      </Paper>
    )
  }

  // Show a button link to the subject (JobPost, Profile, etc.).
  renderButton = () => {
    const { userActivity } = this.props

    let button
    if (this.isEditing) {
      button = <IconicButton
        onClick={this.handleSave}
        disabled={!this.isUpdated}>
        <DoneIcon/>
      </IconicButton>
    } else if (userActivity.activityType === ActivityType.NoteAdded) {
      button = <IconicButton
        onClick={this.handleEdit}>
        <EditIcon />
      </IconicButton>

    } else {
      switch (userActivity.subjectType) {
        case SubjectType.JobCandidate:
          break;
        case SubjectType.JobInterest:
          break;
        case SubjectType.JobPost:
          button = <IconicButton
            onClick={() => {
              const route = `${Routes.manageJob}/posting?jobPostId=${userActivity.subjectId}`
              ControlTower.open(route, "_blank")
            }}><LaunchIcon/></IconicButton>
          break;
        case SubjectType.Profile:
          button = <IconicButton
            onClick={() => {
              const route = `${Routes.profile}/${userActivity.subjectId}`
              ControlTower.open(route, "_blank")
            }}><LaunchIcon/></IconicButton>
          break;
      }
    }

    return (
      button
    )
  }

  handleChange = (event: any) => {
    this.details = event.target.value
    this.isUpdated = true
  }

  handleEdit = () => {
    const index = this.details.indexOf(': ')
    if (index >= 0) {
      this.details = this.details.substring(index + 2)
    }
    this.isEditing = true
    this.isUpdated = false
  }

  handleSave = async () => {
    const { userActivity, userStore, onChange } = this.props
    let activity
    if (!userActivity.id) {
      const createInput: CreateUserActivityInput = {
        userId: userActivity.userId,
        subjectType: userActivity.subjectType,
        subjectId: userActivity.subjectId,
        activityType: userActivity.activityType,
        actorId: userActivity.actorId,
        actorName: userStore!.user!.fullName,
        accountId: userActivity.accountId,
        details: this.details
      }

      activity = await userStore!.createUserActivity(createInput)
    } else if (!this.details || this.details.length === 0) {
      // Delete note
      activity = await userStore!.deleteUserActivity(userActivity.id)
    } else {
      const updateInput: UpdateUserActivityInput = {
        id: userActivity.id,
        userId: userActivity.userId,
        subjectType: userActivity.subjectType,
        subjectId: userActivity.subjectId,
        activityType: userActivity.activityType,
        actorId: userActivity.actorId,
        actorName: userActivity.actorName,
        accountId: userActivity.accountId,
        details: this.details
      }

      activity = await userStore!.updateUserActivity(updateInput)
    }

    if (activity && onChange) {
      if (userActivity.id) {
        this.isEditing = false
      } else {
        this.details = ""
      }
      this.isUpdated = false
      onChange(activity)
    }
  }
}

export default withTheme((withStyles(styles)(UserActivityCard)))
