import { createStyles, Theme, withStyles, WithStyles, withTheme, WithTheme } from '@material-ui/core/styles'
import { Button, Typography } from "@material-ui/core";
import React from "react";
import Tracking from "../Tracking";
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';

const styles = (theme: Theme) => createStyles({
  mainButton: {
    backgroundColor: theme.palette.secondary.main,
  },
  altButton: {
    backgroundColor: "white",
  },
  mainText: {
    color: "white",
  },
  altText: {
    color: theme.palette.secondary.main,
  },
})

interface IActionButtonProps {
  text: string
  tracking: string
  click: Function
  expanded?: boolean
  color?: "inherit" | "default" | "primary" | "secondary"
  size?: "small" | "medium" | "large"
  variant?: 'text' | 'outlined' | 'contained'
  showFowardArrow?: boolean 
}

class ActionButton extends React.Component<WithStyles<typeof styles> & WithTheme & IActionButtonProps> {
  render() {
    const { classes, text, tracking, click, color, expanded, size, variant, showFowardArrow } = this.props

    // const textSize = 'body1'
    // if (size) {
    //   if (size === 'large') {

    //   } else if (size === 'small') {

    //   }
    // }
    return (
      <Button
        style={expanded ? { paddingTop: '15px', paddingBottom: '15px', paddingLeft: '30px', paddingRight: '30px' } : {}}
        size={ size ? size : "medium" }
        key="0"
        variant={ variant ?? "contained" }
        color={color ? color : "secondary"}
        aria-label={text}
        endIcon={ showFowardArrow ? <ArrowForwardIosIcon className={color ? classes.altText : classes.mainText}/> : null }
        onClick={() => {
          Tracking.event({
            action: `${tracking}`,
            label: text
          })
          click()
        }}
        // className={color ? classes.altButton : classes.mainButton}
      >
        <Typography 
          // variant={ size ? size === 'large' ? 'h6' : 'h4' : 'h4'} 
          // className={color ? classes.altText : classes.mainText}
        >
          {text}
        </Typography>
      </Button>
    )
  }
}

export default withTheme((withStyles(styles)(ActionButton)))
