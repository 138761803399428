import { Box, Grid, GridSize, GridSpacing, GridWrap } from "@material-ui/core"
import React from "react"

/**
 * Note that this component is an intermediate replacement for 
 * the MUI v5 Stack component until we can bring in Stack from MUI v5. 
 */
const Stack = ({
  direction, 
  justifyContent,
  alignItems,
  spacing,
  wrap,
  sx,
  xs,
  children 
}: {
  direction?: 'row' | 'column'
  justifyContent?: 'center' | 'flex-start' | 'space-between' | 'flex-end'
  alignItems?: 'center' | 'stretch' | 'flex-start'
  spacing?: GridSpacing
  wrap?: GridWrap
  sx?: any 
  xs?: GridSize 
  children: React.ReactNode
}) => {
  return (
    <Box
      sx={sx}
    >
      <Grid 
        container 
        direction={direction} 
        justifyContent={justifyContent}
        alignItems={alignItems}
        spacing={spacing}
        wrap={wrap}
      >
        { React.Children.map(children, (child: React.ReactNode, index: number) => {
          if (child !== null) {
            return (
              <Grid item key={`stack-${index}`} xs={xs}>
                {child}
              </Grid>
            )
          }
        })}
      </Grid>
    </Box>
  )
}

export default Stack 
