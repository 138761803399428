import React from "react";
import {
  createStyles,
  Theme,
  withStyles,
  WithStyles,
  withTheme,
  WithTheme,
  withWidth,
  WithWidth
} from "@material-ui/core";
import { inject, observer } from "mobx-react";
import ResourceCache from "../../stores/ResourceCache";
import SelectionsDialog, { ListHeaderViewModel, ListItemViewModel, ListSectionViewModel, SelectionsDialogViewModel } from "./SelectionsDialog";
import Profile from "../../model/Profile";
import { observable, reaction, makeObservable } from "mobx";
import intersection from "lodash.intersection"

const styles = (theme: Theme) => createStyles({

})

export interface IPositionsDialogProps {
  isDialogOpen: boolean 
  profile?: Profile
  onClose: any
  onSubmitSelections: any 
  isProcessing: boolean 
  resourceCache?: ResourceCache
}

@inject("resourceCache")
@observer
class PositionsDialog extends React.Component<WithStyles<typeof styles> & WithTheme & WithWidth & IPositionsDialogProps> {
  @observable viewModel?: SelectionsDialogViewModel

  constructor(
    props: WithStyles<typeof styles> & WithTheme & WithWidth & IPositionsDialogProps
  ) {
    super(props);
    makeObservable(this);
  }

  componentDidMount() {
    reaction(() => this.props.isDialogOpen, this.loadViewModel)
  }

  render() {
    if (!this.viewModel) {
      return null 
    }
    return (
      <SelectionsDialog 
        viewModel={this.viewModel} 
        onClose={this.props.onClose}
        onSubmitSelections={this.props.onSubmitSelections}
        isProcessing={this.props.isProcessing}
      /> 
    )
  }

  loadViewModel = () => {
    const { resourceCache, profile } = this.props
    let industries: string[] = []

    let selectedServiceIds: string[] = []
    if (profile) {
      profile.profileServices.forEach(profileService => {
        if (profileService) {
          selectedServiceIds.push(profileService.serviceId)
        }
      })

      if (profile.industries) {
        industries = profile.industries
      }
    }

    let sections: ListSectionViewModel[] = []

    const serviceGroups = resourceCache!.serviceGroups
    serviceGroups.forEach(serviceGroup => {
      let items: ListItemViewModel[] = []
      serviceGroup.services.forEach(service => {
        // Intersect profile.industries with service.industries
        if (industries.length === 0 || intersection(industries, service.industries).length > 0) {
          const item = new ListItemViewModel({
            id: service.id,
            title: service.name,
            isCheckable: true,
            isChecked: selectedServiceIds.includes(service.id)
          })
          items.push(item)
        } else {
          console.log(`Filtered ${service.name}`)
        }
      })

      if (items.length > 0) {
        // Items should already be sorted.
        // items.sort((a: ListItemViewModel, b: ListItemViewModel) => a.title.localeCompare(b.title))
        const section = new ListSectionViewModel({
          header: new ListHeaderViewModel({
            id: serviceGroup.id,
            title: serviceGroup.name,
            isCheckable: false,
            isExpandable: true
          }),
          items
        })
        sections.push(section)
      }
    })

    sections.sort((a: ListSectionViewModel, b: ListSectionViewModel) => a.header.title.localeCompare(b.header.title))

    this.viewModel = new SelectionsDialogViewModel({
      isDialogOpen: this.props.isDialogOpen,
      title: "Job Titles",
      helpText: "Select the job titles you are seeking below.",
      sections
    })
  }
}

export default withTheme(withStyles(styles)(withWidth()(PositionsDialog)))