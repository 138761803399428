import * as React from "react";
import CircularProgress from '@material-ui/core/CircularProgress';
import {Button, createStyles, Theme, withStyles, WithStyles} from "@material-ui/core";
import {ButtonProps} from "@material-ui/core/Button";

const styles = (theme: Theme) => createStyles({
  progressButton: {
    color: theme.palette.secondary.main,
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
})

interface IProgressButtonProps {
  classes: any
  processing: boolean
  fullWidth?: boolean
  children?: any
  onClick?: React.MouseEventHandler<HTMLButtonElement> | undefined
}

export type ProgressButtonProps = ButtonProps | IProgressButtonProps

class ProgressButton extends React.Component<WithStyles<typeof styles> & ProgressButtonProps> {

  state = {
    processing: false
  }

  componentDidUpdate(prevProps: any) {
    const processing = (this.props as IProgressButtonProps).processing
    if (processing !== prevProps.processing) {
      this.setState({processing})
    }
  }

  render() {
    const {
      classes,
      processing,
      children,
      ...rest
    } = this.props as IProgressButtonProps

    return (
      <Button 
        disabled={processing} 
        {...rest}
        onClick={this.props.onClick}
      >
        {children}
        {this.state.processing && <CircularProgress size={24} className={classes.progressButton} />}
      </Button>    
    )
  }
}

export default withStyles(styles)(ProgressButton)
