import * as React from "react";
import {
  Box,
  DialogContentText,
  Grid,
  Paper,
  Typography
} from "@material-ui/core";
import JobPost from "../../model/JobPost";
import ProfileStore from "../../stores/ProfileStore";
import FormValidator from "../../components/form/FormValidator";
import { makeObservable, observable, when } from "mobx";
import { UpdateJobPostInput } from "../../API";
import Logger from "../Logger";
import AccountStore from "../../stores/AccountStore";
import Confirm from "../confirm/Confirm";
import { inject, observer } from "mobx-react";
import Progress from "../Progress";
import { Editor } from "@tinymce/tinymce-react";
import config from 'react-global-configuration';
import JobStore from "../../stores/JobStore";
import AppDialog from "../dialog/AppDialog";
import DialogButton from "../form/DialogButton";

export class JobPostDescriptionViewModel {
  @observable id: string
  @observable description: string

  constructor(jobPost: JobPost) {
    makeObservable(this)
    this.id = jobPost.id
    this.description = jobPost.description ?? ''
  }
}

export interface IJobPostDescriptionFormDelegate {
  didEdit(jobPost: JobPost): void
  didClose(): void
  didCancel?(): void
}

interface IJobPostDescriptionFormProps extends IJobPostDescriptionFormDelegate {
  jobPost: JobPost
  isFormOpen?: boolean
  renderAsDialog: boolean 

  // Stores: 
  accountStore?: AccountStore
  jobStore?: JobStore
  confirm?: Confirm
  progress?: Progress
  profileStore?: ProfileStore
}

@inject("accountStore", "jobStore", "profileStore", "progress", "confirm")
@observer
class JobPostDescriptionForm extends React.Component<IJobPostDescriptionFormProps> {
  @observable isProcessing = false
  @observable message = ""
  @observable description: string = ''
  @observable viewModel?: JobPostDescriptionViewModel

  constructor(props: any) {
    super(props)
    makeObservable(this)
  }

  componentDidMount() {
    when(
      // once...
      () => this.props.jobPost !== undefined,
      // ... then
      () => {
        this.viewModel = new JobPostDescriptionViewModel(this.props.jobPost!)
        this.description = this.props.jobPost!.description
      }
    )
  }

  componentDidUpdate(prevProps: any) {
    if (prevProps.jobPost !== this.props.jobPost) {
      if (this.props.jobPost) {
        this.viewModel = new JobPostDescriptionViewModel(this.props.jobPost!)
        this.description = this.props.jobPost!.description
      } else {
        this.viewModel = undefined
        this.description = ''
      }
    }
  }

  renderFormContent() {
    const viewModel = this.viewModel!

    const editorKey = config.get("tinymce.apiKey")

    return (
      <Grid container direction="column">
        <Grid item>
          <Editor
            id="description"
            apiKey={editorKey}
            init={{
              plugins: 'lists', // 'link',
              menubar: '',
              toolbar: 'undo redo | styleselect | bold italic forecolor | outdent indent bullist',
              skin: "oxide",
              height: '65vh',
              width: '100%',
              keep_styles: false, 
              // See docs for valid_elements: https://www.tiny.cloud/docs/configure/content-filtering/#exampleusingvalid_elements
              valid_elements : 'a[href|target=_blank],strong/b,div[align],br,p,ul,li,h2,h3,h4,h5,h6,h1',
              style_formats: [
                { title: 'Headings', items: [
                  // TODO: Determine if h1 is bad for SEO, disallow?
                  { title: 'Heading 1', format: 'h1' },
                  { title: 'Heading 2', format: 'h2' },
                  { title: 'Heading 3', format: 'h3' },
                  { title: 'Heading 4', format: 'h4' },
                  { title: 'Heading 5', format: 'h5' },
                  { title: 'Heading 6', format: 'h6' }
                ]},
                { title: 'Inline', items: [
                  { title: 'Bold', format: 'bold' },
                  { title: 'Italic', format: 'italic' },
                  { title: 'Underline', format: 'underline' },
                  { title: 'Strikethrough', format: 'strikethrough' },
                  { title: 'Superscript', format: 'superscript' },
                  { title: 'Subscript', format: 'subscript' },
                  { title: 'Code', format: 'code' }
                ]},
                { title: 'Blocks', items: [
                  { title: 'Paragraph', format: 'p' },
                  { title: 'Blockquote', format: 'blockquote' },
                  { title: 'Div', format: 'div' },
                  { title: 'Pre', format: 'pre' }
                ]},
                { title: 'Align', items: [
                  { title: 'Left', format: 'alignleft' },
                  { title: 'Center', format: 'aligncenter' },
                  { title: 'Right', format: 'alignright' },
                  { title: 'Justify', format: 'alignjustify' }
                ]}
              ],
            }}
            initialValue={(viewModel.description && viewModel.description !== '') ? viewModel.description : ''}
            disabled={false}
            onEditorChange={this.onEditorChange}
          />
        </Grid>
      </Grid>
    )
  }

  renderAsDialog() {
    const { isFormOpen } = this.props 

    return (
      <AppDialog
        title='Job Description' 
        dialogId='create-job-description-dialog' 
        formId='create-job-description-form' 
        isLoading={false} 
        isOpen={ isFormOpen ? isFormOpen : false }
        maxWidth='md'
        onCancel={() => {
          this.handleCloseJobPostDescriptionForm()
        }} 
        onSubmit={async () => {
          await this.onSubmitJobPostDescriptionForm()
          this.handleCloseJobPostDescriptionForm()
        }}
      >
        <DialogContentText>
          Edit the full job description.
        </DialogContentText>
        <Box mt={1}>
          <DialogContentText color="error">
            {this.message}
          </DialogContentText>
        </Box>
        {this.renderFormContent()}
      </AppDialog>
    )
  }

  renderAsForm() {
    return (
      <Paper>
        <Box p={2}>
          <Box mt={1}>
            <Typography color="error">
              {this.message}
            </Typography>
          </Box>
          <FormValidator 
            onSubmit={() => {
              this.onSubmitJobPostDescriptionForm()
            }} 
            autoComplete="off" 
            name="job-post-description-form" 
            id="job-post-description-form"
          >
            {this.renderFormContent()}
            <Box pt={1}>
              <Grid container justifyContent="flex-end">
                <Grid item>
                  <Box mr={2}>
                    <DialogButton
                      variant="secondary"
                      type="button"
                      onClick={this.handleCancelJobPostDescriptionForm}
                    >
                      Cancel
                    </DialogButton>
                  </Box>
                </Grid>
                <Grid item>
                  <Box mr={2}>
                    <DialogButton 
                      variant="secondary" 
                      type="button"
                      onClick={this.handleCloseJobPostDescriptionForm}
                    >
                      Skip for now
                    </DialogButton>
                  </Box>
                </Grid>
                <Grid item>
                  <DialogButton 
                    variant="primary" 
                    type="submit"
                  >
                    Next 
                  </DialogButton>
                </Grid>
              </Grid>
            </Box>
          </FormValidator>
        </Box>
      </Paper>
    )
  }

  render() {
    const { 
      renderAsDialog
    } = this.props

    if (this.viewModel === undefined) {
      return null
    }

    return (
      <React.Fragment>
        { renderAsDialog ?
            this.renderAsDialog() 
          :
            this.renderAsForm() 
        }
      </React.Fragment>
    )
  }

  handleCancelJobPostDescriptionForm = () => {
    if (this.props.didCancel) {
      this.props.didCancel()
    }
  }

  handleCloseJobPostDescriptionForm = () => {
    this.message = ""
    this.props.didClose()
  }

  onEditorChange = (content: any, editor: any) => {
    // Logger.info(content, editor)
    this.description = content 
  }

  handleChangeJobPostDescriptionFormField = (event: React.ChangeEvent<HTMLInputElement>) => {
    const name = event.target.name
    this.viewModel![name] = event.target.value
  }

  onSubmitJobPostDescriptionForm = async () => {
    this.isProcessing = true
    this.props.progress!.show('JobPostDescriptionForm')
    this.message = ""

    await this.saveJobPost()
    
    this.isProcessing = false
    this.props.progress!.hide('JobPostDescriptionForm')
  }

  saveJobPost = async () => {
    if (this.viewModel!.id) {
      const input: UpdateJobPostInput = {
        id: this.viewModel!.id,
        description: this.description
      }
      try {
        const jobPost = await this.props.jobStore!.updateJobPost(input)
        this.props.didEdit(jobPost!)
      } catch (error) {
        const message = 'Could not update the job post.'
        Logger.error(message, error)
        this.message = message
        return
      }
    } else {
      this.props.jobPost.description = this.description
      this.props.didEdit(this.props.jobPost)
    }
  }
}

export default JobPostDescriptionForm
