import React, {useEffect, useRef, useState} from 'react';
import {
  alpha,
  Box, Button,
  createStyles, FormControlLabel, FormLabel,
  IconButton,
  makeStyles,
  Paper,
  TextareaAutosize,
  TextField,
  Theme, Typography
} from "@material-ui/core";
import JobCandidate from "../../model/JobCandidate";
import theme from "../../styles/theme";
import {useStores} from "../../stores/StoreProvider";
import SendIcon from '@material-ui/icons/Send';
import MenuIcon from '@material-ui/icons/MoreVert';
import UserActivity, {ActivityType} from "../../model/UserActivity";
import {
  CreateUserActivityInput,
  ModelSortDirection,
  ModelUserActivityFilterInput,
  SubjectType,
  UpdateUserActivityInput
} from "../../API";
import {isoDateToWhen} from "../../stores/StoreUtilities";
import MenuButton, {IMenuItem} from "../controls/MenuButton";

const useStyles = makeStyles((theme: Theme) => createStyles({
  notesFrame: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    height: 120,
    maxHeight: 120,
    backgroundColor: theme.palette.background.paper,
    borderRadius: 10,
    border: `1px solid ${theme.palette.grey[300]}`,
  },
  notesList: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    padding: 8,
    overflow: "scroll",
  },
  textFrame: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    paddingTop: 0,
    marginTop: theme.spacing(1),
    height: 30,
    maxHeight: 30,
  },
  buttonFrame: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
    paddingTop: 4,
    marginTop: theme.spacing(1),
    height: 30,
    maxHeight: 30,
  },
  noteMenu: {
    display: "flex",
    flexGrow: 0,
    alignItems: "top",
    alignContent: "top",
    width: 20,
    marginRight: 8,
    textAlign: "right",
  },
  iconButton: {
    color: alpha(theme.palette.secondary.main, 1),
    marginRight: 6,
    padding: 0,
    width: 16,
    "&:hover": {
      color: alpha(theme.palette.secondary.main, 1),
    },
  },
  sendButton: {
    color: theme.palette.secondary.main,
    paddingRight: 4
  },
  fieldLabel: {
    fontSize: 12,
    fontWeight: 400,
    color: theme.palette.text.secondary,
  }
}))

const JobCandidateNotes = ({
  jobCandidate,
  elevation
}: {
  jobCandidate: JobCandidate
  elevation?: number
}) => {
  const [activityList, setActivityList] = useState<UserActivity[]>([])
  const [text, setText] = useState<string>("")
  const [editId, setEditId] = useState<string>()
  const [editText, setEditText] = useState<string>("")
  const [deleteId, setDeleteId] = useState<string>()
  const [deleteText, setDeleteText] = useState<string>("")

  const { userStore, notify } = useStores()
  const classes = useStyles();
  const scrollRef = useRef(null)

  useEffect(() => {
    loadActivity()
  }, [jobCandidate])

  useEffect(() => {
    if (scrollRef.current) {
      (scrollRef.current as Element).scrollIntoView({ behavior: 'auto', block: 'nearest', inline: 'start' });
    }
  }, [activityList])

  const handleAddNote = async () => {
    const input: CreateUserActivityInput = {
      userId: userStore.user!.id,
      subjectType: SubjectType.JobCandidate,
      subjectId: jobCandidate.id,
      activityType: ActivityType.NoteAdded,
      actorId: userStore.user!.id,
      actorName: userStore.user!.fullName,
      accountId: jobCandidate.accountId,
      details: text
    }
    const activity = await userStore!.createUserActivity(input)
      .catch((err: any) => {
        notify.show("error", err.message)
      })

    if (activity) {
      setActivityList([...activityList, activity])
      setText("")
    }
  }

  const loadActivity = async () => {
    const filter: ModelUserActivityFilterInput = {
      activityType: { eq: ActivityType.NoteAdded }
    }

    const activity = await userStore.listUserActivityBySubject(jobCandidate.id, filter, ModelSortDirection.ASC)
    setActivityList(activity)
  }

  const renderNote = (a: UserActivity) => {
    const when = isoDateToWhen(a.createdAt)
    let content
    if (a.actorName) {
      if (a.updatedAt > a.createdAt) {
        content = <p><b>{a.actorName}</b> - {when} (edited {isoDateToWhen(a.updatedAt)})<br/>{a.details}</p>
      } else {
        content = <p><b>{a.actorName}</b> - {when}<br/>{a.details}</p>
      }
    } else {
      content = <p><b>{when}</b><br/>{a.details}</p>
    }
    let menu
    if (a.actorId === userStore!.user!.id) {
      menu = <MenuButton
              icon={<MenuIcon className={classes.iconButton}/>}
              menuItems={[
                {label:"Edit", value:a.id, selected:false},
                {label:"Delete", value:a.id, selected:false}
              ]}
              onClickMenuItem={onClickMenuItem}
            />
    }

    return (
      <Box display="flex" flexGrow={1} justifyContent="space-between">
        <Box display="flex" flexGrow={1} px={1}>
          {content}
        </Box>
        {menu &&
          <Box className={classes.noteMenu}>
            {menu}
          </Box>
        }
      </Box>
    )
  }

  const onClickMenuItem = (e: any, menuItem: IMenuItem) => {
    const note = activityList.find((a: UserActivity) => a.id === menuItem.value)
    if (note) {
      if (menuItem.label === "Edit") {
        setEditId(note.id)
        setEditText(note.details)
      } else if (menuItem.label === "Delete") {
        setDeleteId(note.id)
        setDeleteText(note.details)
      }
    }
  }

  const onCancel = () => {
    console.log(`onCancel`)
    setEditId(undefined)
    setEditText("")
    setDeleteId(undefined)
    setDeleteText("")
  }

  const onSave = async () => {
    const index = activityList.findIndex((a: UserActivity) => a.id === editId)
    if (index >= 0) {
      const note = activityList[index]
      const update: UpdateUserActivityInput = {
        id: note.id,
        details: editText
      }
      const activity = await userStore.updateUserActivity(update)
      if (activity) {
        const newList = [...activityList]
        newList.splice(index, 1, activity)
        setActivityList(newList)
      }

      setEditId(undefined)
      setEditText("")
    }
  }

  const onDelete = async () => {
    const index = activityList.findIndex((a: UserActivity) => a.id === deleteId)
    if (index >= 0) {
      const newList = [...activityList]
      newList.splice(index, 1)
      setActivityList(newList)

      setDeleteId(undefined)
    }
  }

  if (deleteId) {
    return (
      <Paper elevation={elevation}>
        <Box className={classes.notesFrame} p={1}>
          <Typography className={classes.fieldLabel} variant="body2">Confirm Delete</Typography>
          <Typography variant="body1">{deleteText}</Typography>
        </Box>
        <Box className={classes.buttonFrame}>
          <Button name="cancel" size="small" variant="outlined" color="primary" onClick={onCancel}>
            Cancel
          </Button>
          <Button name="save" size="small" variant="contained" color="primary" onClick={onDelete} style={{marginLeft:8}}>
            Delete
          </Button>
        </Box>
      </Paper>
    )
  } else if (editId) {
    return (
      <Paper elevation={elevation}>
        <Box className={classes.notesFrame} p={1}>
          <TextField
            name="textEdit"
            variant="standard"
            size="small"
            multiline={true}
            minRows={4}
            maxRows={4}
            label="Edit Note"
            value={editText}
            onChange={(event: any) => {
              setEditText(event.target.value)
            }}
            InputProps={{
              disableUnderline: true
            }}
            />
        </Box>
        <Box className={classes.buttonFrame}>
          <Button name="cancel" size="small" variant="outlined" color="primary" onClick={onCancel}>
            Cancel
          </Button>
          <Button name="save" size="small" variant="contained" color="primary" onClick={onSave} style={{marginLeft:8}}>
            Save
          </Button>
        </Box>
      </Paper>
    )
  } else {
    return (
      <Box>
        <Box className={classes.notesFrame}>
          <Box className={classes.notesList}>
            {activityList.map((a: UserActivity) => renderNote(a))}
            <span ref={scrollRef}/>
          </Box>
        </Box>
        <Box className={classes.textFrame}>
          <Box
            sx={{
              display: "flex",
              flexGrow: 1,
              px: 0,
              // overflow: "scroll-x"
            }}
          >
            <TextareaAutosize name="note" value={text}
                              style={{width:"100%",
                                fontSize:15,
                                fontFamily:"Roboto, sans-serif",
                                minHeight: 32,
                                padding: 6,
                                paddingLeft: 8,
                                borderRadius: 10,
                                border: `1px solid ${theme.palette.grey[300]}`
                              }}
                              minRows={1}
                              placeholder="Note"
                              onChange={(event: any) => setText(event.target.value)}
                              onKeyPress={(event:any) => {
                                if (event.key === 'Enter') {
                                  event.preventDefault()
                                  handleAddNote()
                                }
                              }}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              flexGrow: 0,
              justifyContent: "flex-end",
              textAlign: "right",
              p: 0,
              ml: 0.5,
              width: 32
            }}
          >
            <IconButton className={classes.sendButton} disabled={text.length === 0}
                        onClick={() => handleAddNote()}>
              <SendIcon/>
            </IconButton>
          </Box>
        </Box>
      </Box>
    )
  }

}

export default JobCandidateNotes