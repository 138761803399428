import React from "react";
import {createStyles, Grid, Theme, Typography, withStyles, WithStyles, withTheme, WithTheme,} from "@material-ui/core";
import Profile from "../../model/Profile";
import {observer} from "mobx-react";
import {makeObservable, observable} from "mobx";

const styles = (theme: Theme) => createStyles({
  container: {
    flex: 1,
    paddingLeft: theme.spacing(3),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  IconicButton: {
    padding: theme.spacing(1)
  },
  label: {
    width: 150,
    fontWeight: 'bold',
    color: theme.palette.primary.main
  },
  heading: {
    color: theme.palette.error.dark,
    fontWeight: 'bold'
  }
})

export interface IProfileErrorsProps {
  profile?: Profile
  errors?: any
}

@observer
class ProfileErrors extends React.Component<WithStyles<typeof styles> & WithTheme & IProfileErrorsProps> {
  @observable errors = []

  constructor(props: any) {
    super(props);
    makeObservable(this);
  }

  componentDidMount() {
    this.errors = this.props.errors ?? []
  }

  componentDidUpdate(prevProps: any) {
    if (this.props.errors !== prevProps.errors) {
      this.errors = this.props.errors ?? []
    }
  }

  render() {
    const {
      classes,
      profile
    } = this.props

    if (!profile || !this.errors || this.errors.length === 0) {
      return null
    }

    return (
      <div className={classes.container}>
        <Typography variant="h6" className={classes.heading}>Your profile has been saved, but the following information must be completed before it can be submitted for review:</Typography>
        <Grid container direction="column" spacing={1}>
          {this.errors.map((error: any, index: number) => <Grid item key={`error${index}`}>{error}</Grid>)}
        </Grid>
      </div>
    )
  }

}

export default withTheme(withStyles(styles)(ProfileErrors))