import { Box, Grid, useTheme } from "@material-ui/core"
import Page from "../../components/page/Page"
import PageTitle from "../../components/page/PageTitle"
import ServiceRequest from "../../model/ServiceRequest"
import JobPost from "../../model/JobPost"
import ServiceRequestTypeChip from "../../views/serviceRequests/ServiceRequestTypeChip"
import MarginRow from "../../components/page/MarginRow"
import Stack from "../../components/Stack"
import CardValue from "../../components/CardValue"
import { isoToLocalDateString } from "../../stores/StoreUtilities"
import ServiceRequestJobList from "../../views/serviceRequests/ServiceRequestJobList"
import ServiceRequestStatusSelector from "../../views/serviceRequests/ServiceRequestStatusSelector"
import { useStores } from "../../stores/StoreProvider"

const ManageServiceRequestPage = ({
  embed,
  serviceRequest,
  onClickJobPost,
  onUpdateServiceRequest
}: {
  embed?: boolean
  serviceRequest: ServiceRequest
  onClickJobPost: (jobPost: JobPost) => void 
  onUpdateServiceRequest: (serviceRequest: ServiceRequest) => Promise<void> 
}) => {
  const theme = useTheme()
  const { userStore } = useStores()

  const renderContent = () => {
    return (
      <MarginRow>
        <Box
          py={{
            xs: 2, 
            md: 3
          }} 
          bgcolor="common.white" 
          // border={0} 
          // borderColor="grey.400" 
          borderRadius={ embed ? 0 : 10 }
          mb={2}
        >
          <Grid container justifyContent="space-between" spacing={2}>
            <Grid item>
              <PageTitle
                title={serviceRequest.name}
              />
              <Stack 
                direction="column"
                spacing={1}
                sx={{
                  paddingLeft: theme.spacing(3), 
                  paddingRight: theme.spacing(3) 
                }}
              >
                <ServiceRequestTypeChip serviceRequestType={serviceRequest.serviceRequestType} />
                <CardValue label="Created">{ isoToLocalDateString(serviceRequest.createdAt) }</CardValue>
                <CardValue label="Updated">{ isoToLocalDateString(serviceRequest.updatedAt) }</CardValue>
                <CardValue label="Status">{ serviceRequest.status }</CardValue>
              </Stack>
            </Grid>
            { userStore.isAdminOrAgent && 
              <Grid item>
                <Box
                  pl={3}
                  pr={3}
                >
                  <ServiceRequestStatusSelector 
                    variant="outlined"
                    serviceRequest={serviceRequest}
                    onUpdateServiceRequest={onUpdateServiceRequest}
                  />
                </Box>
              </Grid>
            }
          </Grid>
        </Box>
        <Box px={{ xs: 1, sm: 3}} mb={2} bgcolor="grey.50" borderRadius={ embed ? 0 : "0px 0px 10px 10px" }>
          <ServiceRequestJobList 
            serviceRequest={serviceRequest} 
            onClickEditJobPost={(jobPostId: string) => {
              const jobPost = serviceRequest.jobPosts.find(jobPost => jobPost.id === jobPostId)
              onClickJobPost(jobPost!)
            }}           
          />
        </Box>
      </MarginRow>
    )
  }

  if (embed) {
    return renderContent() 
  }
  return (
    <Page>
      {renderContent()}
    </Page>
  )
}

export default ManageServiceRequestPage
