import * as React from "react";
import Answer from "../../model/Answer";
import {Box, Grid} from "@material-ui/core";
import AnswerChip from "./AnswerChip";

interface IAnswerChipArray {
  answers: Answer[]
}

const AnswerChipArray = (props: IAnswerChipArray) => {
  const { answers } = props

  return (
    <Box pt={1} pb={0}>
      <Grid
        container
        direction="row" 
      >
        {answers.map((a: Answer, index: number) => {
          return (
            <React.Fragment
              key={`answer/${index}`}
            >
              <Grid item >
                <AnswerChip answer={a}/>
              </Grid>
            </React.Fragment>
          )
        })}
      </Grid>
    </Box>
  )
}

export default AnswerChipArray