import * as React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { createStyles, withStyles, Theme, WithStyles } from '@material-ui/core/styles'
import { RouteComponentProps } from '@reach/router'
import {inject, observer} from "mobx-react";
import { observable, makeObservable } from "mobx";
import FormValidator from "../../components/form/FormValidator";
import TextFieldValidator from "../../components/form/TextFieldValidator";
import DialogButton from "../../components/form/DialogButton";
import {Grid, Typography} from "@material-ui/core";
import Confirm from "../../components/confirm/Confirm";
import CancelButton from '../../components/form/CancelButton';

const styles = (theme: Theme) => createStyles({
  message: {
    color: 'red',
    marginTop: 10
  },
  dialogPaper: {
    margin: 10
  },
  dialogContent: {
    padding: "0 20px 10px 20px",
  },
  dialogActions: {
    justifyContent: "flex-start",
    paddingBottom: 0,
    paddingLeft: 20
  },
  dialogTitle: {
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1)
  },
  signUp: {
    fontWeight: "bold"
  }
})

interface IStyleDialogProps {
  onLogin?: any,
  onClose?: any,
  onShowPasswordAssist?: any
  onShowSignUp?: any
  userStore?: any
  confirm?: Confirm
}

@inject()
@observer
class StyleDialog extends React.Component<WithStyles<typeof styles> & RouteComponentProps & IStyleDialogProps> {
  @observable values = {
    username: "",
    password: "",
    newPassword: "",
    userId: "",
    phone: "",
    code: ""
  }
  @observable message = ""

  constructor(props: WithStyles<typeof styles> & RouteComponentProps & IStyleDialogProps) {
    super(props);
    makeObservable(this);
  }

  render() {
    const { classes } = this.props

    return (
      <Dialog
        id="loginDialog"
        open={true}
        onClose={this.onClose}
        scroll="paper"
        maxWidth="xs"
        fullWidth
        classes={{paper: classes.dialogPaper}}
        aria-labelledby="login-dialog-title">
        <FormValidator onSubmit={this.onClose} autoComplete="off" name="loginForm" id="loginForm">
          <DialogTitle
            id="login-dialog-title"
            className={ classes.dialogTitle }
          >
            <Grid container direction="row" justifyContent="space-between">
              <Grid item>
                <Typography variant="h3">Log In</Typography>
              </Grid>
              <Grid item>
                <DialogButton customClassName={ classes.signUp } variant="tertiary" onClick={this.onEvent}>
                  Customer&nbsp;Sign&nbsp;Up?
                </DialogButton>
              </Grid>
            </Grid>
          </DialogTitle>
          <DialogContent className={classes.dialogContent}>
            <TextFieldValidator
              autoFocus
              margin="dense"
              name="username"
              id="usernameInput"
              label="Email"
              type="email"
              validators={{required:true}}
              value={this.values.username}
              onChange={this.onChange}
              fullWidth
              autoCorrect="off"
              autoCapitalize="off"
              autoComplete="off"
            />
            <TextFieldValidator
              margin="dense"
              name="password"
              id="passwordInput"
              label="Password"
              type="password"
              validators={{required:true}}
              value={this.values.password}
              onChange={this.onChange}
              fullWidth
            />
            <DialogContentText className={classes.message}>
              {this.message}
            </DialogContentText>
          </DialogContent>
          <DialogActions className={classes.dialogActions}>
            <DialogButton variant="primary">
              Log In
            </DialogButton>
            <CancelButton onClick={this.onClose} />
          </DialogActions>
          <DialogActions className={classes.dialogActions}>
            <DialogButton variant="tertiary" onClick={this.onEvent}>
              Forgot&nbsp;Password?
            </DialogButton>
          </DialogActions>
        </FormValidator>
      </Dialog>
    )
  }

  onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const name = event.target.name
    this.values[name] = event.target.value
  }

  onEvent = (event: React.ChangeEvent<HTMLInputElement>) => {
    // Ignore
  }

  onClose = () => {
    if (this.props.onClose) {
      this.props.onClose()
    }
  };
}

export default withStyles(styles)(StyleDialog)
