export class Agency {
  id: string
  createdAt: string
  agency_display_name: string
  agency_name: string
  cgac_agency_code: string
  order: number

  constructor(data: any) {
    this.id = data.Id
    this.createdAt = data.createdAt
    this.agency_display_name = data.agency_display_name
    this.agency_name = data.agency_name
    this.cgac_agency_code = data.cgac_agency_code
    this.order = data.order
  }
}

export default Agency