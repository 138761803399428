import { useEffect, useState } from "react"
import JobCandidate from "../../model/JobCandidate"
import Profile from "../../model/Profile"
import { useStores } from "../../stores/StoreProvider"
import ProfileCard from "./ProfileCard"
import { observer } from "mobx-react"
import { DialogContentText, Grid, Typography, useTheme } from "@material-ui/core"
import IconicButton from "../../components/controls/IconicButton"
import Logger from "../../components/Logger"
import { SecurityStatus, UpdateProfileInput } from "../../API"
import { Autocomplete } from "@material-ui/lab"
import TextFieldValidator from "../../components/form/TextFieldValidator"
import { Edit } from "@material-ui/icons"
import TextMenuFieldValidator from "../../components/form/TextMenuFieldValidator"
import Stack from "../../components/Stack"
import AppDialog from "../../components/dialog/AppDialog"
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';

const ProfileSecurityClearance = observer(({
  profile,
  jobCandidate,
  onChange,
  onDelete 
}: {
  profile?: Profile
  jobCandidate?: JobCandidate
  onChange?: (profile: Profile) => any
  onDelete?: (profile: Profile) => any
}) => {
  const NoClearance = "None"
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const [isProcessing, setIsProcessing] = useState(false)
  const [isDeleting, setIsDeleting] = useState(false)
  const [securityClearance, setSecurityClearance] = useState<string>()
  const [securityStatus, setSecurityStatus] = useState<SecurityStatus>()

  const {
    confirm,
    notify,
    userStore,
    profileStore,
    resourceCache
  } = useStores()

  const securityClearances = resourceCache.listSecurityClearances()

  const theme = useTheme()

  useEffect(() => {
    if (profile?.securityClearance) {
      setSecurityClearance(profile.securityClearance)
    } else {
      setSecurityClearance(undefined)
    }
    if (profile?.securityStatus) {
      setSecurityStatus(profile.securityStatus)
    } else {
      setSecurityStatus(undefined)
    }
  }, [
    profile 
    // profile?.securityClearance,
    // profile?.securityStatus 
  ])

  const _handleAddSecurityClearance = () => {
    setIsDialogOpen(true)
  }

  const handleClose = () => {
    setIsDialogOpen(false)
  }

  const _clear = () => {
    // this.stagedFile = null
    // this.isUploading = false
    // this.uploadError = ''
    // this.isInvalidFile = false
  }

  const onSubmit = async () => {
    if (onChange === undefined) {
      handleClose()
      Logger.error("No onChange prop present")
      notify!.show("error", "Could not update profile security clearance")
      return 
    }

    setIsProcessing(true)
    
    let updatedProfile: Profile | undefined

    try {
      // Update profile after updating the user, since some profile fields depend on the user record. 
      // For example, the nickname and alias depend on the user's name. 
      const updateProfileInput: UpdateProfileInput = {
        id: profile!.id,
        securityClearance: securityClearance,
        securityStatus: securityStatus
      }
      updatedProfile = await profileStore!.updateProfile(updateProfileInput)
      if (!updatedProfile) {
        throw new Error('Could not update profile')
      }
    } catch (error) {
      setIsProcessing(false)
      handleClose()
      const reason = 'Could not update profile record'
      Logger.error(reason, error)
      notify!.show("error", reason)
      return 
    }

    setIsProcessing(false)
    handleClose()
    onChange(updatedProfile)
  }

  const handleDelete = async () => {
    if (onDelete === undefined) {
      handleClose()
      Logger.error("No onChange prop present")
      notify!.show("error", "Could not delete security clearance")
      return 
    }

    setIsDeleting(true)
    
    let updatedProfile: Profile | undefined

    try {
      // Update profile after updating the user, since some profile fields depend on the user record. 
      // For example, the nickname and alias depend on the user's name. 
      const updateProfileInput: UpdateProfileInput = {
        id: profile!.id,
        securityClearance: null,
        securityStatus: null
      }
      updatedProfile = await profileStore!.updateProfile(updateProfileInput)
      if (!updatedProfile) {
        throw new Error('Could not update profile')
      }
    } catch (error) {
      setIsDeleting(false)
      handleClose()
      const reason = 'Could not update profile record'
      Logger.error(reason, error)
      notify!.show("error", reason)
      return 
    }

    setIsDeleting(false)
    handleClose()
    onDelete(updatedProfile)
  }

  // const onSelectSecurityClearance = (event: any, value: any, reason: string) => {
  //   // const value = event.target.value 
  //   setSecurityClearance(value)
  // }

  const renderSecurityClearanceForm = () => {
    return (
      <AppDialog
        title='Edit Security Clearance' 
        dialogId='profile-security-clearance-dialog' 
        formId='profile-security-clearance-dialog-form' 
        isLoading={ profile ? false : true } 
        isOpen={ isDialogOpen }
        maxWidth="md"
        scroll="paper"
        onCancel={(event: object, reason: string) => {
          // Prevent closing on background click
          if (reason !== "backdropClick") {
            handleClose()
          }
        }}
        onSubmit={async () => {
          await onSubmit()
          handleClose()
        }}
        secondaryLabel="Delete"
        secondaryColor={"default"}
        secondaryIcon={<RemoveCircleIcon />}
        secondaryAction={async () => {
          const content = 'Do you want to delete this security clearance? (The operation cannot be undone.)'
          return new Promise(resolve => {
            confirm.show('Delete', content, ['Delete', 'Cancel'], () => {
              return handleDelete().then(() => {
                resolve() 
                return true 
              })
            })
          })
        }}
      >
        {/* {children} */}

        <DialogContentText>
          <Stack
            spacing={1}
          >
            <Typography>
              Please select your U.S. Federal Government security clearance level and if it is active. 
            </Typography>
            <Typography>
              If your clearance is active or current, select "Active."
            </Typography>
            <Typography>
              If you had a job in the past two years that required the use of a security clearance, select "Inactive."
            </Typography>
          </Stack>
        </DialogContentText>

        {/* {error && (
          <Typography style={{ color: 'red' }}>
            Error: {error}
          </Typography>
        )} */}

        {/* <SimpleSelect
          placeholderText={"Role"}
          selections={securityClearances}
          value={securityClearance}
          valueName={"role"}
          elementId={"role"}
          onChange={onSelectSecurityClearance}
          // disabled={isSelf}
          fullWidth={false}
          // className={classes.formGroupField}
        /> */}

        <Autocomplete
          autoSelect
          includeInputInList
          options={securityClearances}
          onChange={(event: any, value: any, reason: string) =>{
            setSecurityClearance(value)
          }}
          value={securityClearance ?? null}
          renderInput={(params: any) =>
            <div ref={params.InputProps.ref}>
              <TextFieldValidator
                {...params}
                // className={classes.filterTextField}
                // variant={variant}
                // margin={margin}
                // size="small"
                name="securityClearance"
                label="Security Clearance *"

                type="text"
                validators={{ required: true }}
                // name="firstName"
                // label="First Name"
                value={securityClearance}
                // onChange={this.handleChange}
                fullWidth
              />
            </div>
          }
        />

        {/* <SimpleSelect
          placeholderText={"Role"}
          selections={securityClearances}
          value={securityClearance}
          valueName={"role"}
          elementId={"role"}
          onChange={(event: any) => {

          }}
          // disabled={isSelf}
          fullWidth={false}
          // className={classes.formGroupField}
          validators={{ required: true }}
        /> */}

        <TextMenuFieldValidator
          type="text"
          name="securityStatus"
          label="Status"
          helperText="Please select Active or Inactive"
          value={ securityStatus ?? "" }
          onChange={(event: any) => {
            const value = event.target.value 
            setSecurityStatus(value)
          }}
          fullWidth
          required
          values={Object.values(SecurityStatus)}
        />
      </AppDialog>
    )
  }

  const renderLocked = () => {
    return (
      <Typography>Profile must be unlocked to view their security clearance details.</Typography>
    )
  }

  const renderUnlocked = () => {
    const instructions = profile?.securityClearance === undefined && onChange ? "Edit your security clearance level and status, such as an active Top Secret clearance." : undefined
    let clearance 
    if (profile?.securityClearance) {
      clearance = profile.securityClearance
      clearance += ` (${profile.securityStatus})`
    }
    return (
      <>
        { instructions &&
          <Grid item xs={12}>
            <Typography gutterBottom>
              {instructions}
            </Typography>
          </Grid>
        }
        { clearance && 
          <Grid item xs={12}>
            <Typography variant="body1">
              {clearance}
            </Typography>
          </Grid>
        }
      </>
    )
  }

  if (!profile) {
    return null
  }

  const user = userStore.user   
  const isUnlocked = profile.getIsUnlocked(user, jobCandidate)
  
  let editButton
  if (onChange) {
    editButton =
      <IconicButton 
        onClick={_handleAddSecurityClearance}
      >
        <Edit />
      </IconicButton>
  }
  
  return (
    <ProfileCard
      title="Security Clearance"
      button={editButton}
    >
      { onChange && renderSecurityClearanceForm() }

      <Grid 
        container
      >
       { isUnlocked ? renderUnlocked() : renderLocked() } 
      </Grid>
    </ProfileCard>
  )
})

export default ProfileSecurityClearance
