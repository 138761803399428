import * as React from 'react'
import { RouteComponentProps } from '@reach/router'
import {createStyles, withStyles, Theme, WithStyles, withTheme, WithTheme} from '@material-ui/core/styles'
import MarginRow from "../../components/page/MarginRow";
import Page from "../../components/page/Page";
import Progress from "../../components/Progress";
import {Box, Button, Grid, Typography} from "@material-ui/core";
import StyleDialog from "./StyleDialog";
import {observer} from "mobx-react";
import { observable, makeObservable } from "mobx";
import StyleCard from "./StyleCard";

const styles = (theme: Theme) => createStyles({
  root: {
    flexGrow: 1,
    justifyContent: 'top',
    alignItems: 'center',
  },
  content: {
    display: 'flex',
    flexGrow: 1,
    width: "100%",
    flex: "auto",
    overflowY: "auto",
  },
  item: {
    justifyContent: 'top',
    alignItems: 'center'
  },
  title: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  section: {
    marginBottom: theme.spacing(2)
  }
})

interface IStyleGuideProps {
  progress?: Progress
  route?: string
}

@observer
class StyleGuide extends React.Component<WithStyles<typeof styles> & RouteComponentProps & IStyleGuideProps & WithTheme> {
  @observable isStyleDialogOpen = false

  constructor(
    props: WithStyles<typeof styles> & RouteComponentProps & IStyleGuideProps & WithTheme
  ) {
    super(props);
    makeObservable(this);
  }

  render() {
    const { classes } = this.props
    const title = "Style Guide"

    return (
      <Page title={title}>
        <MarginRow>
          <Grid container spacing={1} className={classes.title}>
            <Grid item xs={12}>
              <Typography variant="h1">Style Guide</Typography>
            </Grid>
          </Grid>

          <section className={classes.section}>
            <Typography variant="h2">Palette</Typography>
            <hr/>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={4}>
                <Box bgcolor="primary.dark" color="primary.contrastText" p={2}>
                  primary.dark
                </Box>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Box bgcolor="primary.main" color="secondary.contrastText" p={2}>
                  primary.main
                </Box>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Box bgcolor="primary.light" color="error.contrastText" p={2}>
                  primary.light
                </Box>
              </Grid>

              <Grid item xs={12} sm={4}>
                <Box bgcolor="secondary.dark" color="secondary.contrastText" p={2}>
                  secondary.dark
                </Box>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Box bgcolor="secondary.main" color="secondary.contrastText" p={2}>
                  secondary.main
                </Box>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Box bgcolor="secondary.light" color="secondary.contrastText" p={2}>
                  secondary.light
                </Box>
              </Grid>

              <Grid item xs={12} sm={4}>
                <Box bgcolor="error.main" color="error.contrastText" p={2}>
                  error.main
                </Box>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Box bgcolor="warning.main" color="warning.contrastText" p={2}>
                  warning.main
                </Box>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Box bgcolor="info.main" color="info.contrastText" p={2}>
                  info.main
                </Box>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Box bgcolor="success.main" color="success.contrastText" p={2}>
                  success.main
                </Box>
              </Grid>

              <Grid item xs={12} sm={4}>
                <Box bgcolor="background.default" color="success.contrastText" p={2}>
                  background.default
                </Box>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Box bgcolor="background.paper" color="success.contrastText" p={2}>
                  background.paper
                </Box>
              </Grid>

              <Grid item xs={12} sm={4}>
                <Box bgcolor="background.paper" color="text.primary" p={2}>
                  text.primary
                </Box>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Box bgcolor="background.paper" color="text.secondary" p={2}>
                  text.secondary
                </Box>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Box bgcolor="background.paper" color="text.disabled" p={2}>
                  text.disabled
                </Box>
              </Grid>
            </Grid>
          </section>

          <section className={classes.section}>
            <Typography variant="h2">Typography</Typography>
            <hr/>
            <Grid container direction="row">
              <Grid item xs={6}>
                <Typography variant="h1">Heading 1</Typography>
                <Typography variant="h2">Heading 2</Typography>
                <Typography variant="h3">Heading 3</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="h4">Heading 4</Typography>
                <Typography variant="h5">Heading 5</Typography>
                <Typography variant="h6">Heading 6</Typography>
                <Typography variant="subtitle1">Subtitle 1</Typography>
                <Typography variant="subtitle2">Subtitle 2</Typography>
                <Typography variant="body1">Body 1</Typography>
                <Typography variant="body2">Body 2</Typography>
                <Typography variant="button">Button</Typography><br/>
                <Typography variant="caption">Caption</Typography><br/>
                <Typography variant="overline">Overline</Typography>
              </Grid>
            </Grid>
          </section>

          <section className={classes.section}>
            <Typography variant="h2">Components</Typography>
            <hr/>
            <Grid container direction="row">
              <Grid item xs={6}>
                <Button color="primary" variant="contained" onClick={this.showStyleDialog}>Style Dialog</Button>
                {this.isStyleDialogOpen &&
                  <StyleDialog onClose={this.onCloseStyleDialog}/>
                }
              </Grid>
              <Grid item xs={6}>
                <StyleCard />
              </Grid>
            </Grid>
          </section>
        </MarginRow>
      </Page>
    )

  }

  showStyleDialog = () => {
    this.isStyleDialogOpen = true
  }

  onCloseStyleDialog = () => {
    this.isStyleDialogOpen = false
  }
}

export default withTheme((withStyles(styles)(StyleGuide)))