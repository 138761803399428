import { Box, Drawer, Grid, LinearProgress, Typography } from "@material-ui/core";
import AppBar from '@material-ui/core/AppBar';
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import { inject, observer } from "mobx-react";
import * as React from 'react';
import UserStore from "../../stores/UserStore";
import Progress from "../Progress";
import {makeObservable, observable} from "mobx";
import IconicButton from "../controls/IconicButton";
import CloseIcon from '@material-ui/icons/Close';

const drawerWidth = 360;

const styles = (theme: Theme) => createStyles({
  appBar: {
    top: 0,
    backgroundColor: theme.palette.primary.main,
    height: 76,
    maxHeight: 76,
    width: drawerWidth,
  },
  appBarLarge: {
    top: 0,
    backgroundColor: theme.palette.primary.main,
    height: 76,
    maxHeight: 76,
    [theme.breakpoints.down('xs')]: {
      width: 360,
    },
    [theme.breakpoints.up('sm')]: {
      width: "90%",
      maxWidth: theme.breakpoints.values.lg
    },
  },
  toolBar: {
    minHeight: 76,
    height: 76
  },
  drawer: {
    width: "100%",
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: theme.palette.grey[200]
  },
  drawerPaperLarge: {
    [theme.breakpoints.down('xs')]: {
      width: 360,
    },
    [theme.breakpoints.up('sm')]: {
      width: "90%",
      maxWidth: theme.breakpoints.values.lg
    },
    backgroundColor: theme.palette.grey[100]
  },
  closeButton: {
    color: theme.palette.common.white 
  },
  progressWrapper: {
    flexGrow: 1
  },
  progress: {
    height: 3,
    backgroundColor: "#d72222" // Logo red
  },
})

interface ISideDrawerProps {
  anchor?: 'right' | 'left' | 'top' | 'bottom'
  title: string 
  isOpen: boolean
  onClose(): void
  size: 'large' | 'small' // If set as large, renders an overlay of 90% width of the page and 360px for mobile. Otherwise is small, 360px for all sizes.

  userStore?: UserStore
  progress?: Progress
}

@inject("userStore", "progress")
@observer
class SideDrawer extends React.Component<WithStyles<typeof styles> & ISideDrawerProps> {
  @observable isOpen: boolean = false // Needs this fake observable variable for MobX to not complain. 

  constructor(props: WithStyles<typeof styles> & ISideDrawerProps) {
    super(props);
    makeObservable(this);
  }

  render() {
    const { anchor, classes, isOpen, size, onClose, progress, title } = this.props

    return (
      <React.Fragment>
        <Drawer 
          className={classes.drawer}
          classes={{
            paper: size === 'large' ? classes.drawerPaperLarge : classes.drawerPaper,
          }}
          anchor={ anchor ? anchor : "right" }
          open={isOpen} 
          onClose={onClose}
        >
          <AppBar position="fixed" className={ size === 'large' ? classes.appBarLarge : classes.appBar }>
            <Toolbar className={classes.toolBar}>
              <Grid container justifyContent="space-between" alignItems="center">
                <Grid item>
                  <Typography variant="h6">
                    {title}
                  </Typography>
                </Grid>
                <Grid item>
                  <IconicButton
                    onClick={onClose}
                  >
                    <CloseIcon className={classes.closeButton} />
                  </IconicButton> 
                </Grid>
              </Grid>
            </Toolbar>
            <div className={classes.progressWrapper}>
              {<LinearProgress hidden={!progress!.isVisible} className={classes.progress} />}
            </div>
          </AppBar>
          <Box mt={"76px"}>
            {React.Children.map(this.props.children, (child: any, index: number) => {
              // TODO: Render children as a grid. 
              return child 
            })}
          </Box>
        </Drawer>
      </React.Fragment>
    )
  }
}

export default withStyles(styles)(SideDrawer)
