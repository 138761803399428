import { Divider, Grid, isWidthDown, withWidth, WithWidth } from "@material-ui/core";
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles';
import { format } from 'date-fns';
import { observable, makeObservable } from "mobx";
import { inject, observer } from "mobx-react";
import * as React from 'react';
import config from 'react-global-configuration';
import ControlTower from "../ControlTower";
import MarginRow from "../page/MarginRow";
import PrivacyPolicy from "./PrivacyPolicy";
import TermsOfUse from "./TermsOfUse";
import logo from '../../images/logo_wo_tagline_white_640x192.png'
import ActionLink from "../ActionLink";

const styles = (theme: Theme) => createStyles({
  container: {
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(5),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    width: '100%',
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary[700],
    borderTop: "2px solid",
    borderTopColor: theme.palette.divider
  },
  link: {
    textDecoration: 'none',
    "&:hover": {
      textDecoration: 'none',
    }
  },
  linkText: {
    color: theme.palette.common.white,
    fontSize: "18px !important",
    fontWeight: 400,
    letterSpacing: 0.5,
    textDecoration: 'none',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: 0,
  },
  divider: {
    backgroundColor:  theme.palette.primary[600],
    margin: 8
  }
})

interface IFooterProps {
  privacyPolicy?: PrivacyPolicy
  termsOfUse?: TermsOfUse
}

@inject("privacyPolicy", "termsOfUse")
@observer
class Footer extends React.Component<WithStyles<typeof styles> & WithWidth & IFooterProps & WithWidth> {
  @observable showPrivacyPolicy: boolean = false
  @observable showTermsOfUse: boolean = false

  constructor(props: WithStyles<typeof styles> & WithWidth & IFooterProps) {
    super(props);
    makeObservable(this);
  }

  render() {
    const { classes, width } = this.props
    const homeUrl = config.get("homeUrl")
    const academyUrl = `${homeUrl}/academy`
    const talentUrl = `${homeUrl}/talent`
    const jobsUrl = `${homeUrl}/jobs`
    const newsUrl = config.get("newsUrl")
    const copyright = new DOMParser().parseFromString(`&copy; ${format(Date.now(), 'yyyy')} GovGig`,
                                                'text/html').body.textContent ?? ""
    const version = config.get("version")

    return (
      <div className={classes.container}>
        <MarginRow>
          <Grid container
            direction={isWidthDown('sm', width) ? "column" : "row"}
            justifyContent="center"
            alignItems={isWidthDown('sm', width) ? "flex-start" : "center"}
            spacing={2}
          >
            <Grid item xs={12} sm={4}>
              <a className={classes.linkText} href={homeUrl}>
                <img src={logo} alt="logo" height="47px" width="auto" style={{ marginTop: -3, marginBottom: 3 }}/>
              </a>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Grid container direction="column" spacing={1}>
                <Grid item>
                  <ActionLink text="Academy" route={academyUrl} tracking="Footer-Academy"
                              variation="light" linkStyle={classes.link} textStyle={classes.linkText}/>
                </Grid>
                <Grid item>
                  <ActionLink text="Talent" route={talentUrl} tracking="Footer-Talent"
                              variation="light" linkStyle={classes.link} textStyle={classes.linkText}/>
                </Grid>
                <Grid item>
                  <ActionLink text="Jobs" route={jobsUrl} tracking="Footer-Jobs"
                              variation="light" linkStyle={classes.link} textStyle={classes.linkText}/>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Grid container direction="column" spacing={1}>
                <Grid item>
                  <ActionLink text="Contact Us" tracking="Footer-Contact-Us"
                              variation="light" linkStyle={classes.link} textStyle={classes.linkText}
                              click={this.onClickContact}/>
                </Grid>
                <Grid item>
                  <ActionLink text="Terms of Use" tracking="Footer-Terms-of-Use"
                              variation="light" linkStyle={classes.link} textStyle={classes.linkText}
                              click={this.onClickTermsOfUse}/>
                </Grid>
                <Grid item>
                  <ActionLink text="Privacy Policy" tracking="Footer-Privacy-Policy"
                              variation="light" linkStyle={classes.link} textStyle={classes.linkText}
                              click={this.onClickPrivacyPolicy}/>
                </Grid>
                <Grid item>
                  <ActionLink text="News" route={newsUrl} tracking="Footer-News"
                              variation="light" linkStyle={classes.link} textStyle={classes.linkText}/>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={10}>
              <Divider variant="middle" className={classes.divider}/>
            </Grid>
            <Grid item xs={12} sm={4} style={{paddingTop:0}}>
              <ActionLink text={copyright}
                          route={homeUrl} tracking="Footer-Copyright"
                          variation="light" linkStyle={classes.link} textStyle={classes.linkText}/>
            </Grid>
            <Grid item xs={12} sm={4} style={{paddingTop:0}}>
              <ActionLink text={`Version ${version}`} route={homeUrl} tracking="Footer-Version"
                          variation="light" linkStyle={classes.link} textStyle={classes.linkText}/>
            </Grid>
            <Grid item xs={12} sm={4} style={{paddingTop:0}}>
              {/* <ActionLink text={`Built By Heroic Development`}
                          route={"https://www.heroicdevelopment.com"} tracking="Footer-Built-By"
                          variation="light" linkStyle={classes.link} textStyle={classes.linkText}/> */}
            </Grid>
          </Grid>
        </MarginRow>
      </div>
    )
  }

  onClickContact = () => {
    const homeUrl = config.get("homeUrl")
    ControlTower.open(`${homeUrl}/contact`, "_self")
  }

  onClickPrivacyPolicy = () => {
    const { privacyPolicy } = this.props
    if (privacyPolicy) {
      privacyPolicy.show()
    }
  }

  onClickTermsOfUse = () => {
    const { termsOfUse } = this.props
    if (termsOfUse) {
      termsOfUse.show()
    }
  }
}

export default withStyles(styles)(withWidth()(Footer))
