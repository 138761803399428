import config from 'react-global-configuration';
import ActionLink from "../../../components/ActionLink"
import ControlTower, { Routes } from "../../../components/ControlTower"
import Section, { SectionBackgroundColors } from "../../../components/marketing/sections/Section"
import TitleGroup from "../../../components/marketing/TitleGroup"

const EmployerContactUsSection = () => {
  return (
    <Section
      backgroundColor={SectionBackgroundColors[100]}
    >
      <TitleGroup
        title="Have Questions?"
        subtitle="If you have more questions, or want to explore a recruiting solution for your business, please reach out!"
        alignment="center"
      >
        <ActionLink 
          text="Contact Us"
          tracking="Employer-Contact-Us"
          click={() => {
            const homeUrl = config.get("homeUrl")
            ControlTower.open(`${homeUrl}/contact`, "_self")
          }}
        />
      </TitleGroup>
    </Section>
  )
}

export default EmployerContactUsSection
