/* eslint-disable @typescript-eslint/no-unused-vars */
import { API, Auth } from "aws-amplify";
import fetchData from "../axios/axiosHandler";
import * as APITypes from "../API";

class OpportunitiesAPI {
    
    async getAgencyLists() {
        const { data, loading, error } = await fetchData("getAgency");
        if(error){
            return error
        } else if(data){
            return data
        }
    }
    
    getNaicsCodeLists= async()=>{
        const { data, loading, error } = await fetchData("getNaicsCode");
        if(error){
            return error
        } else if(data){
            return data
        }
    }

    getPscCategories= async()=>{
        const { data, loading, error } = await fetchData("getProductService");
        if(error){
            return error
        } else if(data){
            return data
        }
    }
    
    getOpportunities= async(filter?: APITypes.ModelJobPostFilterInput, params?: any)=>{
        let callFunction = 'getOpportunities';
        let param:any = {
            limit: params.limit, 
            Id: params.Id, 
            createdAt: params.createdAt, 
            naicsCode: params.naicsCode, 
        }
        // console.log('rkk api param ::', param)
        var queryString = Object.keys(param).map((key) => {
            return encodeURIComponent(key)+'='+encodeURI(param[key])
        }).join('&');
        
        const { data, error, loading } = await fetchData(callFunction+'?'+queryString.trim());
        if(error){
            return error
        } else if(data) {
            return data
        }
    }
    
}

export default OpportunitiesAPI