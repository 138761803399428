import HardwareIcon from '@material-ui/icons/Gavel';
import ComputerIcon from '@material-ui/icons/Computer';
import IndustryList from '../../components/marketing/IndustryList';

const GovGigIndustryList = () => {
  return (
    <IndustryList
      industries={[
        {
          name: 'Construction',
          // route: {
          //   title: "Learn More",
          //   pathname: "/industries/construction"
          // },
          icon: <HardwareIcon />
        },
        {
          name: 'Information Technology',
          // route: {
          //   title: "Learn More",
          //   pathname: "/industries/information-technology"
          // },
          icon: <ComputerIcon />
        },
      ]}
    />
  )
}

export default GovGigIndustryList
