import { useEffect, useState } from "react"
import SimpleForm from "../form/SimpleForm"
import DialogPanel from "./DialogPanel"
import DialogPanelButtons from "./DialogPanelButtons"
import DialogPanelInstructions from "./DialogPanelInstructions"
import { Box, Button, FormGroup, Theme, Typography, createStyles, makeStyles, useTheme } from "@material-ui/core"
import CheckboxValidator from "../form/CheckboxValidator"
import { Routes } from "../ControlTower"
import Stack from "../Stack"
import * as APITypes from "../../API"
import { useStores } from "../../stores/StoreProvider"
import RequestMeetingSection from "../marketing/sections/RequestMeetingSection"

const useStyles = makeStyles((theme: Theme) => createStyles({
  formGroupTerms: {
    display: "flex",
    justifyContent: "flex-start",
    flexWrap: "nowrap",
    margin: 0,
    padding: 0,
    maxHeight: 40,
  },
  termsCheckbox: {
    flex: 0,
    width: 32,
    maxWidth: 32
  },
  termsLabel: {
    flex: 1,
    alignSelf: "center",
    color: '#252525',
    fontSize: '13px',
    paddingTop: 0,
    lineHeight: 1.2,
  },
  terms: {
    height: "calc(50vh)",
    width: "100%",
    marginBottom: theme.spacing(1)
  },
  iframe: {
    width: "100%",
    height: "100%",
    // border: "none",
    overflowY: "scroll",
    border: "1px solid #eee",
    borderRadius: theme.spacing(1)
  }
}))

const DialogAgreementPanel = ({
  formId, 
  checkboxId, 
  agreementId, 
  agreementTitle, 
  agreementRoute, 
  agreementType, 
  instructions, 
  onClickBack,
  onClickNext, 
  onClickSkip
}: {
  formId: string 
  checkboxId: string 
  agreementId: string 
  agreementTitle: string 
  agreementType: APITypes.AgreementType, 
  agreementRoute: Routes
  instructions: string[]
  onClickBack: () => void 
  onClickNext: () => Promise<void>
  onClickSkip?: () => void 
}) => {
  const classes = useStyles() 
  const { accountStore, userStore } = useStores()
  const theme = useTheme()
  
  const [isProcessing, setIsProcessing] = useState(false)
  const [isChecked, setIsChecked] = useState(false)
  const [atBottom, setAtBottom] = useState(false)

  useEffect(() => {
    const handleAtBottom = (event: any) => {
      if (event?.data === 'at-bottom') {
        console.log(`Signup atBottom = true`)
        setAtBottom(true)
      }
    }

    window.addEventListener('message', handleAtBottom)

    return () => window.removeEventListener('message', handleAtBottom)
  }, [

  ])
  
  const renderTermsCheckbox = (name: string, label: any, required: boolean) => {
    return (
      <Stack
        direction="row"
        alignItems="center"
        wrap="nowrap"
        spacing={1}
      >
        <CheckboxValidator 
          name={name} 
          color="primary"
          value="checked" 
          required={required}
          checked={isChecked}
          disabled={!atBottom}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            const checked = event.target.checked 
            console.debug("renderTermsCheckbox", checked)
            setIsChecked(checked)
          }} 
        />
        <Typography>
          {label}
        </Typography>
      </Stack>
    )
  }

  const printFrame = (id: string) => {
    const frame = window.frames[id]
    if (frame) {
      const contentWindow = frame.contentWindow
      if (contentWindow) {
        contentWindow.focus();
        contentWindow.print();
      }
    }

    return false;
  };
  
  return (
    <DialogPanel>
      <DialogPanelInstructions 
        title={agreementTitle} 
        instructions={instructions}      
      />
      <SimpleForm 
        formId={formId} 
        onSubmit={async () => {
          setIsProcessing(true)

          const user = userStore.user! 
          const agreement = user.agreements.length > 0 ? user.agreements[0] : undefined

          if (agreement) {
            const input: APITypes.UpdateAgreementInput = {
              id: agreement.id,
              agreementTypes: [
                agreementType,
                ...agreement.agreementTypes
              ]
            }
            const updatedAgreement = await userStore.updateAgreement(input)
            user.agreement = updatedAgreement
          } else {
            const input: APITypes.CreateAgreementInput = {
              userId: user.id, 
              accountId: accountStore.account!.id, 
              agreementTypes: [
                agreementType
              ]
            }
            const createdAgreement = await userStore.createAgreement(input)
            user.agreement = createdAgreement
          }

          await onClickNext() 

          setIsProcessing(false)
        }} 
        buttonArea={
          <DialogPanelButtons
            isProcessing={isProcessing}
            buttonDisabled={isChecked === false}
            // Note, the form onSubmit is called instead, don't need onClickNext here: 
            // onClickNext={() => {
            //   setStep(RequestStep.EnterJob)
            // }} 
            onClickBack={onClickBack} 
            onClickSkip={onClickSkip}
          />
        }
      >
        <div className={classes.terms}>
          <iframe 
            id={agreementId} 
            src={agreementRoute} 
            title={agreementTitle} 
            className={classes.iframe}
          />
        </div>
        <Box
          sx={{
            paddingLeft: theme.spacing(2), 
            paddingRight: theme.spacing(2)
          }}
        >
          <Stack 
            direction="row"
            justifyContent="space-between" 
            alignItems="center"
            wrap="nowrap"
            spacing={2}
          >
            { renderTermsCheckbox(
                checkboxId, 
                `I ACCEPT the ${agreementTitle}. (You must scroll to the bottom)`, 
                true
              ) 
            }
            <Button 
              variant="outlined" 
              type="button"
              color="primary" 
              style={{ height:32 }} 
              size="small"
              onClick={() => printFrame(agreementId)}
            >
              Print
            </Button>
          </Stack>
        </Box>
      </SimpleForm>
      <RequestMeetingSection />
    </DialogPanel>
  )
}

export default DialogAgreementPanel
