import * as React from 'react';
import { createStyles, Theme, WithStyles, withStyles } from '@material-ui/core/styles'
import {Box, Checkbox, FormControlLabel, FormGroup, FormHelperText, Typography} from "@material-ui/core";
import {NAICS} from "../../model/Industry";
import {inject} from "mobx-react";
import {humanizeString} from "../../stores/StoreUtilities";

const styles = (theme: Theme) => createStyles({
  formGroup: {
    marginBottom: 0,
    paddingBottom: 0
  },
  fieldLabel: {
    // fontSize: 12,
    // fontWeight: 400,
    // color: theme.palette.text.secondary,
    paddingTop: 0,
    paddingBottom: 0
  }
})


interface IIndustrySelectorProps {
  industries?: string[]
  value?: string[]
  required?: boolean
  onChange?(value: string[]): any
  formvalidator?: any
  disabled?: boolean
}

@inject("formvalidator")
class IndustrySelector extends React.Component<WithStyles<typeof styles> & IIndustrySelectorProps>{

  state = {
    industries: [] as string[],
    value: [] as string[],
    isValid: true,
  };

  componentDidMount() {
    const { industries, value, formvalidator } = this.props
    if (formvalidator) {
      formvalidator.attachToForm(this)
    }

    this.setState({industries: industries ?? Object.values(NAICS),
                         value: value ?? []})
  }

  componentDidUpdate(prevProps: any) {
    if (prevProps.industries !== this.props.industries) {
      this.setState({industries: this.props.industries ?? []})
    }
    if (prevProps.value !== this.props.value) {
      this.setState({value: this.props.value ?? []})
    }
  }

  validate = (value: string): boolean => {
    const isRequired = this.props.required ?? false
    const isValid = !isRequired || (value.length > 0)
    this.setState({isValid})
    return isValid
  }

  render() {
    const { classes, disabled } = this.props

    const industries = this.props.industries ?? Object.values(NAICS)
    const textColor = this.state.isValid === false ? "error" : undefined

    return (
      <FormGroup className={classes.formGroup}>
        <Typography variant="body2" color={textColor} className={classes.fieldLabel}>Industries</Typography>
        <Box flexDirection="row">
          {industries.map((industry: string) => {
            const key = Object.keys(NAICS)[Object.values(NAICS).indexOf(industry as NAICS)]
            return (
              <FormControlLabel value={industry} label={humanizeString(key)} key={industry}
                                control={
                                  <Checkbox name="industry" value={industry} disabled={disabled}
                                            checked={this.state.value!.indexOf(industry) >= 0}
                                            onClick={this.onClick}/>}
              />
            )
          })}
        </Box>
        <FormHelperText error={!this.state.isValid}>
          {this.state.isValid ? "" : "Required"}
        </FormHelperText>
      </FormGroup>
    )
  }

  onClick = (event: any) => {
    const {checked, value} = event.target
    let industries: string[] = [...this.state.value]
    if (checked) {
      industries.push(value)
    } else {
      industries.splice(industries.indexOf(value), 1)
    }
    const isValid = !this.props.required || industries.length > 0
    this.setState({value: industries, isValid})

    if (this.props.onChange) {
      this.props.onChange(industries)
    }
  }
}

export default withStyles(styles)(IndustrySelector)