import CssBaseline from '@material-ui/core/CssBaseline';
// Styles
import { MuiThemeProvider } from '@material-ui/core/styles';
import { Router } from "@reach/router";
// AWS
import {Amplify, API, Storage} from 'aws-amplify';
import { inject } from "mobx-react";
import React from 'react';
// Config
import config, { Options } from 'react-global-configuration';
import './App.css';
import AppConfig from './AppConfig';
import aws_exports from './aws-exports';
import ControlTower, { Routes } from "./components/ControlTower";
import PrivacyPolicyDialog from "./components/page/PrivacyPolicyDialog";
import TermsOfUseDialog from "./components/page/TermsOfUseDialog";
import AdminPage from "./pages/manage/AdminPage";
import ApplyPage from "./pages/apply/ApplyPage";
import FindTalentPage from "./pages/candidates/FindTalentPage";
import ContractsPage from './pages/contracts/ContractsPage';
import JobPostPage from './pages/jobPost/JobPostPage';
import FindJobsPage from "./pages/jobs/FindJobsPage";
import JoinPage from './pages/join/JoinPage';
import PrivacyPolicyPage from "./pages/legal/PrivacyPolicyPage";
import TermsOfUsePage from "./pages/legal/TermsOfUsePage";
import MasterServiceAgreementPage from "./pages/legal/MasterServiceAgreementPage";
import LoadingPage from "./pages/LoadingPage";
import ManageCandidatePage from './pages/manageCandidates/ManageCandidatePage';
import ManageJobPage from './pages/manageJobs/ManageJobPage';
import ManageJobsPage from './pages/manageJobs/ManageJobsPage';
import NotFoundPage from "./pages/NotFoundPage";
import ProfilePage from "./pages/profile/ProfilePage";
import SettingsPage from './pages/settings/SettingsPage';
import AccountPendingPage from "./pages/signup/AccountPendingPage";
import SignupPage from "./pages/signup/SignupPage";
import StyleGuide from "./pages/style/StyleGuide";
import AccountStore from "./stores/AccountStore";
import UserStore from "./stores/UserStore";
import LocationAPI from "./apis/LocationAPI";
import RegisterPage from "./pages/register/RegisterPage";
import ResourceCache from "./stores/ResourceCache";
import LoginPage from "./pages/LoginPage";
import JobStore from "./stores/JobStore";
import OpportunityStore from './stores/OpportunityStore';
import JobPostCreatePage from './pages/jobPostCreate/JobPostCreatePage';
import JobPostPaymentCartPage from './pages/jobPostPaymentCart/JobPostPaymentCartPage';
import theme from './styles/theme';
import ConfidentialityAgreementPage from "./pages/legal/ConfidentialityAgreementPage";
import DashboardPage from './pages/dashboard/DashboardPage';
import ServiceRequestsPage from './pages/serviceRequests/ServiceRequestsPage';
import ServiceRequestCreatePage from './pages/serviceRequestCreate/ServiceRequestCreatePage';
import ExclusiveMasterServiceAgreementPage from './pages/legal/ExclusiveMasterServiceAgreementPage';
import AccountPage from './pages/accounts/AccountPage';
import FindOpportunityPage from './pages/opportunity/FindOpportunityPage';

// Amplify.Logger.LOG_LEVEL = "DEBUG"

config.set(AppConfig, { freeze: false, assign: false } as Options);

Amplify.configure(aws_exports);

// TODO: Remove cookieStorage for now, as it appears to accumulate a large size of cookie headers which cause site loading issues, see notes: https://trello.com/c/sjKuKIQi
// Enable cookie storage for cross-domain auth
// const authConfig = {
//   identityPoolId: aws_exports.aws_cognito_identity_pool_id,
//   region: aws_exports.aws_project_region,
//   userPoolId: aws_exports.aws_user_pools_id,
//   userPoolWebClientId: aws_exports.aws_user_pools_web_client_id,
//   cookieStorage: {
//     domain: AppConfig.domain,
//     path: "/",
//     expires: 1,
//     secure: AppConfig.domain !== "localhost",
//     sameSite: 'lax'
//   }
// }
// Auth.configure(authConfig)
API.configure(aws_exports)
Storage.configure(aws_exports)

interface IApp {
  resourceCache?: ResourceCache
  userStore?: UserStore
  accountStore?: AccountStore
  jobStore?: JobStore
  opportunityStore?: OpportunityStore
  locationAPI?: LocationAPI
}

@inject('resourceCache', 'userStore', 'accountStore', 'jobStore', 'opportunityStore', 'locationAPI' )
class App extends React.Component<IApp> {
  
  componentDidMount() {
    this.startup()
  }

  async startup() {
    const { resourceCache, userStore, accountStore, jobStore, opportunityStore, locationAPI } = this.props

    if (resourceCache) {
      await resourceCache.init()
    }

    let account
    if (userStore) {
      const user = await userStore.reloadAuthenticatedUser()
      if (user && user.account) {
        account = user.account
      }
    } 

    await accountStore!.init(account)
    await jobStore!.init()
    await opportunityStore!.init()

    if (locationAPI) {
      await locationAPI.init()
    }
  }

  render() {
    return (
      <MuiThemeProvider theme={theme}>
        <CssBaseline />
        <Router>
          <ControlTower path={Routes.home}>
            <AccountPage path={`${Routes.account}/:tab`} />
            <AccountPage path={`${Routes.account}`} />
            <AccountPendingPage path={Routes.accountPending} />
            <AdminPage path={`${Routes.manage}/:tab`} />
            <AdminPage path={Routes.manage} />
            <ApplyPage path={Routes.apply} />
            <FindTalentPage path={Routes.findTalent} />
            <ConfidentialityAgreementPage path={Routes.nda} />
            <ContractsPage path={Routes.jobGroups} />
            <ContractsPage path={`${Routes.jobGroups}/:tab`} />
            <DashboardPage path={Routes.dashboard} /> 
            <ExclusiveMasterServiceAgreementPage path={Routes.msaExclusive} />
            <FindOpportunityPage path={Routes.findOpportunity} />
            <FindJobsPage path={Routes.findJobs} />
            <FindJobsPage path={Routes.myJobs} />
            <JobPostPage path={`${Routes.job}/:jobPostId`} />
            <JobPostCreatePage path={`${Routes.jobPostCreate}`} />
            <JobPostCreatePage path={`${Routes.jobPostCreate}/:jobPostId`} />
            <JobPostPaymentCartPage path={`${Routes.jobPostPaymentCart}`} />
            <JoinPage path={Routes.join} />
            <JoinPage path={Routes.index} />
            <LoginPage path={Routes.home} />
            <LoginPage path={Routes.login} />
            <ManageCandidatePage path={`${Routes.manageCandidate}/:tab`} />
            <ManageJobPage path={`${Routes.manageJob}/:tab`} />
            <ManageJobPage path={`${Routes.manageJob}`} />
            <ManageJobsPage path={Routes.manageJobs} />
            <MasterServiceAgreementPage path={Routes.msa} />
            <SettingsPage path={Routes.settings} />
            <SettingsPage path={`${Routes.settings}/:tab`} />
            <PrivacyPolicyPage path={`${Routes.privacy}/:option`} />
            <PrivacyPolicyPage path={Routes.privacy} />
            <ProfilePage path={Routes.profile + "/:profileId"} />
            <ProfilePage path={Routes.profile} />
            <RegisterPage path={`${Routes.register}/:accountId/:userId/:role/:email`}/>
            <RegisterPage path={`${Routes.register}/*`}/>
            <ServiceRequestCreatePage path={Routes.serviceRequestCreate} />
            <ServiceRequestsPage path={Routes.serviceRequests} />
            <SignupPage path={Routes.signup} />
            <StyleGuide path={Routes.styleGuide} />
            <TermsOfUsePage path={Routes.terms} />
          </ControlTower>
          <NotFoundPage default />
          <LoadingPage path={Routes.loading} />
        </Router>
        <PrivacyPolicyDialog />
        <TermsOfUseDialog />
        {/*<TawkTo url={config.get("tawkTo.url")} />*/}
      </MuiThemeProvider>
    );
  }
}

export default App;
