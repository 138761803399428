import Price from "./Price"
import get from 'lodash.get'

class Product {
  id: string 
  name: string 
  description: string 
  defaultPriceId: string 
  prices: Price[] = []
  unitLabel: string 
  imageUrls: string[] = []
  features: string[] = []

  superTitle?: string
  productLine: string // Ex. "GovGigJobs", "GovGigDocs"
  productType: string // Ex. "JobPost"
  productExpiry: string 
  includes: string 
  
  metadata: any 

  constructor(data: any) {
    this.id = data.id 
    this.name = data.name 
    this.description = data.description 
    this.unitLabel = data.unit_label 
    this.defaultPriceId = data.default_price
    this.imageUrls = data.images 

    this.loadFeatures(data) 
    
    // Related to features on price list:
    this.superTitle = get(data, 'metadata[superTitle]')
    this.productLine = get(data, 'metadata[productLine]')
    this.productType = get(data, 'metadata[productType]')
    this.productExpiry = get(data, 'metadata[productExpiry]')
    this.includes = get(data, 'metadata[includes]') 
    
    this.metadata = data.metadata
  }
  
  // TODO: Looks like there's a features list built in to the product entity in Stripe, need to switch to use that list instead of a metadata list?
  loadFeatures(data: any) {
    for (let x = 1; x <= 3; x++) {
      const feature = get(data, `metadata[feature_${x}]`)
      if (feature) {
        this.features.push(feature)
      }
    }
  }

  addPrice(price: Price) {
    this.prices.push(price)
    this.prices.sort((a, b) => a.quantity - b.quantity)
  }

  /**
   * Get the unit price for a given quantity of units. 
   * 
   * @param quantity The quantity of units. 
   * @returns A string representing the price, which includes the currency symbol, ex. '$499.00'
   */
  getPriceForQuantity(quantity: number): Price | undefined {
    let price 

    this.prices.forEach(checkPrice => {
      if (checkPrice.quantity === quantity) {
        price = checkPrice
      } else if (checkPrice.quantity < quantity) {
        price = checkPrice
      }
    })

    return price 
  }
}

export default Product 

/*
Example product listings: 
{
  "id": "prod_MGltVZzjcSC6uM",
  "object": "product",
  "active": true,
  "attributes": [],
  "created": 1660850767,
  "default_price": "price_1LYEM0GEKsOeGTHni5yT3Q33",
  "description": "Utilize GovGig Agents to manage recruiting activity on your behalf for your Job Post(s).",
  "images": [],
  "livemode": false,
  "metadata": {
    "jobPostType": "RecruitingServices",
    "productLine": "GovGigJobs",
    "productType": "JobPost"
  },
  "name": "Job Post(s) - Recruiting Services",
  "package_dimensions": null,
  "shippable": null,
  "statement_descriptor": null,
  "tax_code": "txcd_10103001",
  "type": "service",
  "unit_label": "Job Post",
  "updated": 1660856619,
  "url": null
},
{
  "id": "prod_MEUoUp8JZmBHfK",
  "object": "product",
  "active": true,
  "attributes": [],
  "created": 1660325957,
  "default_price": "price_1LW1pKGEKsOeGTHnoPyIKVT9",
  "description": "Publish Job Post(s) to the GovGig Job Board.",
  "images": [],
  "livemode": false,
  "metadata": {
    "feature_1": "Put your job post(s) in front of our qualified Candidate pool",
    "feature_2": "Manage Candidate applications to your job post(s)",
    "feature_3": "Job post(s) show for 30 days (can be renewed as an add-on)",
    "jobPostType": "SelfService",
    "presentationOrder": "1",
    "productLine": "GovGigJobs",
    "productType": "JobPost"
  },
  "name": "Job Post(s) - Self-Service",
  "package_dimensions": null,
  "shippable": null,
  "statement_descriptor": null,
  "tax_code": "txcd_10103001",
  "type": "service",
  "unit_label": "Job Post(s)",
  "updated": 1662159513,
  "url": null
},
*/
