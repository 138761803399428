/* eslint-disable @typescript-eslint/no-unused-vars */
import { observable, makeObservable } from "mobx";
import GovGigAPI from "../apis/GovGigAPI";
import * as APITypes from "../API"
import Account from "../model/Account";
import Tracking from "../components/Tracking";
import User from "../model/User";
import Logger from "../components/Logger";
import ResourceCache from "./ResourceCache";
import {AgreementType, ModelAccountFilterInput, UserRole} from "../API";
import socioEconomicDesignations from "../data/socioEconomicDesignations.json";
import Invoice from "../model/stripe/Invoice";
import BillingAPI from "../apis/BillingAPI";
import Charge from "../model/stripe/Charge";
import get from "lodash.get";
import Product from "../model/stripe/Product";
import Price from "../model/stripe/Price";
import StripeOrderItemData from "../model/stripe/StripeOrderItemData";
import InvoiceItem from "../model/stripe/InvoiceItem";
import Agreement from "../model/Agreement";

export const AccountStoreConstants = {
  PRIMARY_ACCOUNT_ID: "primary"
}

// TODO: Move to schema? 
export enum PricingAgreementType {
  Standard = "Standard", 
  Exclusive = "Exclusive"
}

class AccountStore {
  govGigAPI: GovGigAPI
  resourceCache: ResourceCache
  billingAPI: BillingAPI

  @observable account?: Account
  @observable isLoading = true 

  constructor(options: any) {
    makeObservable(this);
    this.govGigAPI = (options && options.govGigAPI) ? options.govGigAPI : null
    this.resourceCache = (options && options.resourceCache) ? options.resourceCache : null
    this.billingAPI = (options && options.billingAPI) ? options.billingAPI : null 
  }

  async init(account?: Account) {
    // this.isLoading = true 
    this.account = account
    this.isLoading = false
    console.log("Account inited")
  }

  get isPrimary() {
    return this.account && this.account.id === AccountStoreConstants.PRIMARY_ACCOUNT_ID
  }

  // Deprecated? 
  // async loadAccount(accountId: string) {
  //   if (!this.account || this.account.id !== accountId) {
  //     console.log(`loadingAccount(${accountId})`)
  //     this.isLoading = true
  //     const accountData = await this.govGigAPI.getAccount(accountId)
  //       .catch((err: Error) => {
  //         console.log(`getAccount error: ${err.message}`)
  //         ControlTower.route(Routes.notFound)
  //       })

  //     if (accountData) {
  //       const account = new Account(accountData)
  //       await this.init(account)
  //       return account
  //     }
  //   } else {
  //     return this.account
  //   }
  // }

  listAccounts = async (industry?: string): Promise<Account[]> => {
    let accounts: Account[] = []

    let filter: ModelAccountFilterInput | undefined

    if (industry) {
      filter = {
        industries: {contains: industry}
      }
    }

    const data = await this.govGigAPI.listAccounts(filter)
    if (data && data.items) {
      accounts = data.items.map((item: any) => {
        return new Account(item)
      })
    }

    return accounts
  }

  getAccount = async (accountId: string): Promise<Account | undefined> => {
    let account: Account | undefined

    if (accountId === this.account!.id) {
      account = this.account
    }

    if (!account) {
      const data = await this.govGigAPI.getAccount(accountId)
      if (data) {
        account = new Account(data)
      }
    }

    return account
  }

  async createAccount(input:  APITypes.CreateAccountInput) {
    const account = await this.govGigAPI!.createAccount(input)
    if (account) {
      Tracking.event({action: "Create Account"})
      this.isLoading = false
      return new Account(account)
    } else {
      return null
    }
  }

  async updateAccount(input:  APITypes.UpdateAccountInput) {
    const result = await this.govGigAPI!.updateAccount(input)
    if (result) {
      Tracking.event({action: "Create Account"})
      const account = new Account(result)
      if (this.account && this.account.id === account.id) {
        if (!account.users || account.users.length === 0) {
          account.users = this.account!.users
        }
        this.account = account
      }
      return account
    } else {
      return null
    }
  }

  listUsers = async (filter?: APITypes.ModelUserFilterInput): Promise<User[]> => {
    let users: User[] = []

    let data
    let nextToken: string | undefined

    do {
      data = await this.govGigAPI.listUsers(filter, 1000, nextToken)
      if (data && data.items) {
        data.items.forEach((item: any) => {
          // Filter out Academy Students
          if (item.role !== UserRole.Student) {
            const user = new User(item)
            if (!user.account) {
              Logger.warning(`No account found for user: ${user.email}`)
            } else {
              users.push(user)
            }
          }
        })
        nextToken = data.nextToken ?? undefined
      }
    } while (data && nextToken)

    return users
  }

  listUsersByAccount = async (accountId: string, filter?: APITypes.ModelUserFilterInput): Promise<User[]> => {
    let users: User[] = []

    let data
    let nextToken: string | undefined

    do {
      data = await this.govGigAPI.listUsersByAccount(accountId, filter, 1000, nextToken)
      if (data && data.items) {
        data.items.forEach((item: any) => {
          // Filter out Academy Students
          if (item.role !== UserRole.Student) {
            const user = new User(item)
            if (!user.account) {
              Logger.warning(`No account found for user: ${user.email}`)
            } else {
              users.push(user)
            }
          }
        })
        nextToken = data.nextToken ?? undefined
      }
    } while (data && nextToken)

    return users
  }

  listApplicantsByAccount = async (accountId: string, filter?: APITypes.ModelUserFilterInput): Promise<User[]> => {
    let users: User[] = []

    let data
    let nextToken: string | undefined

    do {
      data = await this.govGigAPI.listApplicantsByAccount(accountId, filter, 1000, nextToken)
      if (data && data.items) {
        data.items.forEach((item: any) => {
          const user = new User(item)
          if (user.profile) {
            user.profile.user = user
          }
          users.push(user)
        })
        nextToken = data.nextToken ?? undefined
      }
    } while (data && nextToken)

    return users
  }

  listCandidatesByAccount = async (accountId: string, filter?: APITypes.ModelUserFilterInput): Promise<User[]> => {
    let users: User[] = []

    let data
    let nextToken: string | undefined

    do {
      data = await this.govGigAPI.listCandidatesByAccount(accountId, filter, 1000, nextToken)
      if (data && data.items) {
        data.items.forEach((item: any) => {
          const user = new User(item)
          if (user.profile) {
            user.profile.user = user
          }
          users.push(user)
        })
        nextToken = data.nextToken ?? undefined
      }
    } while (data && nextToken)

    return users
  }

  getSocioEconomicDesignationOptions() {
    return socioEconomicDesignations
  }
  
  // Agreement methods

  listAgreementsByAccount = async (accountId: string, filter?: APITypes.ModelAgreementFilterInput): Promise<Agreement[]> => {
    let agreements: Agreement[] = []

    const data = await this.govGigAPI.listAgreementsByAccount(accountId, filter)
    if (data && data.items) {
      agreements = data.items.map((item: any) => {
        const agreement = new Agreement(item)
        return agreement
      })
    }

    return agreements
  }

  getPricingAgreementType = async (accountId: string): Promise<PricingAgreementType | null> => {
    let pricingAgreementType: PricingAgreementType | null = null 
    const agreements = await this.listAgreementsByAccount(accountId)
    // setAgreements(agreements)

    let hasMasterServicesAgremement = false 
    // let hasConfidentialityAgreement = false 
    let hasExclusiveAgreement = false 
    if (agreements && agreements.length > 0) {
      agreements.forEach((agreement: Agreement) => {
        if (agreement.hasAgreementType(APITypes.AgreementType.MasterServicesAndNonSolicitation)) {
          hasMasterServicesAgremement = true 
        } 
        // if (agreement.hasAgreementType(APITypes.AgreementType.ConfidentialityAndNonDisclosure)) {
        //   hasConfidentialityAgreement = true 
        // }
        if (agreement.hasAgreementType(APITypes.AgreementType.ExclusiveRecruitment)) {
          hasExclusiveAgreement = true 
        }
      })
    }
    
    if (hasMasterServicesAgremement && !hasExclusiveAgreement) {
      pricingAgreementType = PricingAgreementType.Standard
    } else if (hasMasterServicesAgremement && hasExclusiveAgreement) {
      pricingAgreementType = PricingAgreementType.Exclusive
    } else if (hasExclusiveAgreement) {
      pricingAgreementType = PricingAgreementType.Exclusive
    }

    return pricingAgreementType
  }

  agreementTypeToString(agreementType: AgreementType): string {
    let typeName = ""
    switch (agreementType) {
      case (AgreementType.BackgroundCheck): 
        typeName = "Background Check"
        break
      case (AgreementType.ConfidentialityAndNonDisclosure): 
        typeName = "Confidentiality & Non-Disclosure Agreement"
        break
      case (AgreementType.ElectronicCommunication): 
        typeName = "Electronic Communication"
        break
      case (AgreementType.ExclusiveRecruitment): 
        typeName = "Exclusive Master Services & Non-Solicitation Agreement"
        break 
      case (AgreementType.FederalExperience): 
        typeName = "Federal Experience"
        break 
      case (AgreementType.I9Eligible): 
        typeName = "I-9 Eligible"
        break 
      case (AgreementType.MasterServicesAndNonSolicitation): 
        typeName = "Master Services & Non-Solicitation Agreement"
        break 
      case (AgreementType.PrivacyPolicy): 
        typeName = "Privacy Policy"
        break 
      case (AgreementType.TermsOfUse): 
        typeName = "Terms of Use"
        break 
      case (AgreementType.StaffingAgreement): 
        typeName = "Staffing Agreement"
        break 
      case (AgreementType.ProfessionalServicesAgreement): 
        typeName = "Professional Services Agreement"
        break 
    }
    return typeName 
  }

  // Billing Related Methods

  getCoupon = async (id: string) => {
    const result = await this.billingAPI.getCoupon(id)
    return result
  }

  listProducts = async (): Promise<Product[]> => {
    const result = await this.billingAPI.getProducts()
    .catch((err: any) => {
      console.error("Error getting Stripe products", err)
    })

    const products = result.data.map((productData: any) => new Product(productData))
    await this.attachPricesToProducts(products)
    return products 
  }

  /**
   * 
   * @param productLine Example: 'GovGigJobs', 'GovGigDocs'
   * @param productType Example: 'JobPost' 
   * @returns 
   */
  listProductsByProductLineAndProductType = async (productLine: string, productType: string): Promise<Product[]> => {
    const result = await this.billingAPI.getProducts()
      .catch((err: any) => {
        console.error("Error getting Stripe products", err)
      })
  
    const productsData: any[] = [] 
    
    result.data.forEach((productData: any) => {
      const isProductLine = get(productData, 'metadata[productLine]') === productLine
      const isProductType = get(productData, 'metadata[productType]') === productType
      if (isProductLine && isProductType) {
        productsData.push(productData)
      }
    })
    
    productsData.sort((a, b) => {
      const orderA = get(a, 'metadata[presentationOrder]')
      const orderB = get(b, 'metadata[presentationOrder]')
      return orderA - orderB 
    })
  
    const products = productsData.map(productData => new Product(productData))
    await this.attachPricesToProducts(products)
    return products 
  }

  attachPricesToProducts = async (products: Product[]) => {
    const result = await this.billingAPI.getPrices()
      .catch((err: any) => {
        console.error("Error getting Stripe prices", err)
      })

    result.data.forEach((priceData: any) => {
      const price = new Price(priceData)
      const product = products.find(product => product.id === price.productId)
      if (product) {
        product.addPrice(price)
      }
    })
  }

  createInvoice = async (user: User, couponId: string | undefined, orderItemData: StripeOrderItemData[], tokenId: string | undefined) => {
    const items = orderItemData.map(orderItem => {
      return new InvoiceItem({
        customer: this.account!.customerId,
        unit_amount: Math.round(orderItem.unitPrice! * 100),
        quantity: orderItem.quantity,
        currency: "usd",
        description: orderItem.description
      })
    })
    const invoice = new Invoice({
      customer: this.account!.customerId,
      collection_method: "charge_automatically",
      auto_advance: true,
      items
    })

    if (couponId) {
      invoice.discounts = [
        {coupon: couponId}
      ]
    }

    const result = await this.billingAPI.createInvoice(user, this.account!, invoice, tokenId)

    if (!this.account!.customerId && result && result.customer) {
      const input: APITypes.UpdateAccountInput = {
        id: this.account!.id,
        customerId: result.customer
      }
      const account = await this.updateAccount(input)
      if (account) {
        this.account!.customerId = account.customerId
      }
    }
    // console.log(`createInvoice = ${JSON.stringify(result)}`)

    return (result)
  }

  getCustomer = async (id: string) => {
    const result = await this.billingAPI.getCustomer(id)
    return result
  }

  deleteSource = async (sourceId: string) => {
    if (this.account!.customerId) {
      return await this.billingAPI.deleteSource(this.account!.customerId, sourceId)
    } else {
      return null
    }
  }

  // Charges methods

  listCharges = async (accountId: string) => {
    let charges: Charge[] = []

    const account = await this.getAccount(accountId)
    if (account && account.customerId) {
      const result = await this.billingAPI.getCharges(account.customerId)
      if (result && result.data) {
        charges = result.data.map((item: any) => {
          return new Charge(item)
        })
      }
    }

    return charges
  }
}

export default AccountStore

