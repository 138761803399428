import { Box, createStyles, makeStyles, Paper, Theme, Typography } from "@material-ui/core"
import Stack from "../Stack"

const useStyles = makeStyles((theme: Theme) => createStyles({
  panel: {
    backgroundColor: 'hsl(219, 30%, 90%)',
    boxShadow: 'rgba(0, 0, 0, 0.15) 0px 5px 15px 0px'
  },
  title: {
    fontWeight: 'bold'
  }
}))

const InstructionsPanel = ({
  text
}: {
  text: string[]
}) => {
  const classes = useStyles()

  return (
    <Paper
      className={classes.panel}
    >
      <Box
        width="100%"
        p={2}
        // bgcolor={theme.palette.grey[50]}
        borderRadius={10}
      >
        <Typography gutterBottom variant="h6" color="primary" className={classes.title}>Instructions</Typography>
        <Stack direction="column" spacing={1}>
          { text.map((text, index) => {
            return <Typography key={`instruction/${index}`} variant="body1">{text}</Typography>
          })}
        </Stack>
      </Box>
    </Paper>
  )
}

export default InstructionsPanel
