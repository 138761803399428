export class Naics {
  id: string
  createdAt: string
  naics_title: string
  naics_code: string

  constructor(data: any) {
    this.id = data.Id
    this.createdAt = data.createdAt
    this.naics_title = data.naics_title
    this.naics_code = data.naics_code
  }
}

export default Naics