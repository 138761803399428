import React from "react";
import {
  TextField,
  withWidth,
  WithWidth
} from "@material-ui/core";
import { createStyles, Theme, WithStyles, WithTheme } from "@material-ui/core";
import { withStyles, withTheme } from "@material-ui/core/styles";
import { Autocomplete } from "@material-ui/lab";
import { makeObservable, observable, when } from "mobx";
import { inject, observer } from "mobx-react";
import UserStore from "../../stores/UserStore";
import AccountStore from "../../stores/AccountStore";
import Progress from "../Progress";
import ResourceCache, {LocationOption} from "../../stores/ResourceCache";

const styles = (theme: Theme) => createStyles({
  filterTextField: {
    // width: 308,
    // width: "100%",
    flexGrow: 1, 
    backgroundColor: theme.palette.background.paper,
    borderRadius: 10
  }
})

export interface ILocationFilterProps {
  onSelectLocation: (locationOption?: LocationOption) => any
  value?: LocationOption
  accountStore?: AccountStore
  progress?: Progress
  userStore?: UserStore
  resourceCache?: ResourceCache
}

@inject("accountStore", "userStore", "resourceCache", "progress")
@observer
class LocationFilter extends React.Component<WithStyles<typeof styles> & WithTheme & ILocationFilterProps & WithWidth> {

  @observable isLoading: boolean = true 
  @observable locationFilter?: LocationOption

  constructor(props: any) {
    super(props)
    makeObservable(this)
  }

  componentDidMount() {
    const { resourceCache, value } = this.props
    when(
      // once...
      () => resourceCache!.isLoading === false,
      // ... then
      async () => {
        this.locationFilter = value
        this.isLoading = false
      }
    )
  }

  componentDidUpdate(prevProps: any) {
    if (prevProps.value !== this.props.value) {
      this.locationFilter = this.props.value
    }
  }

  render() {
    const { classes, resourceCache } = this.props

    if (this.isLoading === true) {
      return null 
    }

    return (
      <Autocomplete
        autoSelect
        includeInputInList
        options={resourceCache!.getLocationOptions()}
        getOptionLabel={(option: LocationOption) => option.name ?? option}
        getOptionSelected={(option: LocationOption, value: LocationOption) => value && option.name === value.name}
        groupBy={(option: LocationOption) => option.group}
        onChange={this.onSelectLocation}
        value={this.locationFilter ?? null}
        renderInput={(params: any) =>
          <div ref={params.InputProps.ref}>
            <TextField
              {...params}
              className={classes.filterTextField}
              variant="outlined"
              size="small"
              name="locationFilter"
              label="Location"
            />
          </div>
        }
      />
    )
  }

  onSelectLocation = (event: any, value: any, reason: string) => {
    if (value) {
      this.locationFilter = value
    } else {
      this.locationFilter = undefined
    }
    this.props.onSelectLocation(this.locationFilter)
  }
}

export default withTheme(withStyles(styles)(withWidth()(LocationFilter)))