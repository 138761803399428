import * as React from 'react'
import {inject, observer} from "mobx-react";
import {makeObservable, observable, when} from "mobx";
import {Box, createStyles, Grid, Theme, Typography} from "@material-ui/core";
import AccountStore from "../../stores/AccountStore";
import {withStyles, WithStyles, withTheme, WithTheme} from "@material-ui/core/styles";
import {RouteComponentProps} from "@reach/router";
import Contract from '../../model/Contract';
import AddButton from '../../components/AddButton';
import UserStore from '../../stores/UserStore';
import Progress from '../../components/Progress';
import {durationBetweenISODates} from '../../stores/StoreUtilities';
import IconicButton from '../../components/controls/IconicButton';
import EditIcon from "@material-ui/icons/Edit";
import Confirm from '../../components/confirm/Confirm';
import JobPost from '../../model/JobPost';
import ContractForm from './ContractForm';
import JobCandidate from "../../model/JobCandidate";

import Notify from "../../components/notify/Notify";
import ControlTower, {Routes} from "../../components/ControlTower";
import Account from '../../model/Account'
import FilterBar from '../../components/filter/FilterBar';
import AccountFilter from '../../components/filter/AccountFilter';
import CardValue from '../../components/CardValue';
import ActionButton from '../../components/controls/ActionButton';
import JobStore from "../../stores/JobStore";

const styles = (theme: Theme) => createStyles({

})

interface IContractsListProps {
  accountStore?: AccountStore
  jobStore?: JobStore
  userStore?: UserStore
  progress?: Progress
  confirm?: Confirm
  notify?: Notify
}

@inject("accountStore", "jobStore", "userStore", "progress", "confirm", "notify")
@observer
class ContractsList extends React.Component<WithStyles<typeof styles> & RouteComponentProps & IContractsListProps & WithTheme> {
  @observable isLoading: boolean = true 
  @observable isProcessing = false
  
  // Account 
  @observable accounts: Account[] = []
  @observable accountFilter?: Account 

  // Contracts
  @observable contracts: Contract[] = []
  @observable contract?: Contract
  @observable isContractJobListExpanded: Map<string, boolean> = new Map()
  @observable isContractFormOpen: boolean = false 
  
  // JobPosts
  @observable jobPost?: JobPost
  @observable isJobPostCandidateListExpanded: Map<string, boolean> = new Map()
  @observable isJobPostFormOpen: boolean = false 
  @observable isJobPostDescriptionExpanded: Map<string, boolean> = new Map()
  @observable isJobPostDescriptionFormOpen: boolean = false

  // JobCandidates
  @observable jobCandidate?: JobCandidate
  @observable isJobCandidateInviteDialogOpen: boolean = false

  constructor(
    props: WithStyles<typeof styles> & RouteComponentProps & IContractsListProps & WithTheme
  ) {
    super(props);
    makeObservable(this);
  }

  componentDidMount() {
    const { userStore, accountStore, progress } = this.props
    progress!.show('ContractsList')
    when(
      // once...
      () => userStore!.isLoading === false &&
        accountStore!.isLoading === false &&
        accountStore!.account !== undefined && 
        userStore!.user !== undefined,
      // ... then
      async () => {
        // TODO: Handle deeplink into contracts for an account. 
        // if (location && location.search) {
        //   const searchParams = new URLSearchParams(location.search)
        //   const accountId = searchParams.get("accountId")
        // }
        await this.loadAccounts() 
        await this.loadContracts() 
        progress!.hide('ContractsList')
        this.isLoading = false 
      }
    )
  }

  render() {
    const { userStore } = this.props 

    if (this.isLoading === true) {
      return null 
    }
    
    return (
      <Box pt={1} pb={2} width={'100%'}>
        <Grid container direction="column" spacing={2}>
          <Grid item>
            {this.renderFilterControlsArea()}
          </Grid>
          <Grid item>
            <Grid container direction="column" justifyContent="flex-start">
              { this.contracts.length === 0 ? 
                this.renderInstructions()
              : this.renderContractsList() }
            </Grid>
          </Grid>
        </Grid>
        <ContractForm 
          accounts={ userStore!.user?.isAdminOrAgent ? this.accounts : undefined }
          contract={this.contract}
          isFormOpen={this.isContractFormOpen} 
          didClose={() => {
            this.contract = undefined
            this.isContractFormOpen = false 
          }}
          didCreate={async (contract: Contract) => {
            // Note that the add contract form has an account selection box (for an admin),
            // and that account could differ for the current account filter. 
            if (this.accountFilter && this.accountFilter.id === contract.accountId) {
              this.contracts.push(contract)
            } else {
              await this.loadContracts()
            }
          }}
          didDelete={(contract: Contract) => {
            const index = this.contracts.findIndex(checkContract => checkContract.id === contract.id)
            this.contracts.splice(index, 1)
          }}
          didEdit={(contract: Contract) => {
            if (this.accountFilter && this.accountFilter.id === contract.accountId) {
              const index = this.contracts.findIndex(checkContract => checkContract.id === contract.id)
              if (index !== -1) {
                this.contracts[index] = contract
              }
            } else {
              this.loadContracts()
            }
          }}
        />
      </Box>
    )
  }

  renderFilterControlsArea = () => {
    const { userStore } = this.props
    return (
      <FilterBar
        searchResultCount={this.contracts.length}
        buttons={[
          <AddButton
            text="Add Job Group"
            tracking="employerAddContract"
            buttonColor="primary"
            buttonVariant="contained"
            buttonSize="medium"
            click={this.handleAddContract}
          />
        ]}
      >
        { userStore!.user?.isAdminOrAgent ?
          <AccountFilter
            key="accountFilter"
            value={this.accountFilter}
            accounts={this.accounts!}
            onSelectAccount={(account?: Account) => {
              this.accountFilter = account
              this.loadContracts() 
            }}
          /> 
        : null }
      </FilterBar>
    )
  }

  renderInstructions = () => {
    return (
      <Grid item>
        <Box px={{ xs: 1, sm: 2 }}>
          <Typography>
            Enter and manage your contracts here. You can add a new contract by pressing the "Add Contract" button. After you enter details for a contract and save, you will be able to add a list of jobs associated with the contract.
          </Typography>
        </Box>
      </Grid>
    )
  }

  renderContractsList = () => {
    const contractList = this.contracts.map((contract: Contract, index: number, array: Contract[]) => {
      return (
        <Box key={contract.id} px={1}>
          {this.renderContract(contract)}
        </Box>
      )
    })
    return contractList
  }

  renderContract = (contract: Contract) => {
    return (
      <Box pt={2} pb={3} mb={2} bgcolor="common.white" px={{ xs: 1, sm: 2 }} borderRadius={10} boxShadow={'rgba(0, 0, 0, 0.15) 0px 5px 10px 0px'}>
        <Grid item>
          <Grid container direction="column" spacing={2}>
            <Grid item>
              <Grid container justifyContent="space-between" wrap="nowrap">
                <Grid item>
                  <Grid container direction="column" spacing={1}>
                    <Grid item>
                      <Typography variant="h5">
                        {contract.name} 
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Grid container direction="column" justifyContent="flex-start">
                        <Grid item>
                          <CardValue label="Number">
                            {contract.number}
                          </CardValue>
                        </Grid>
                        <Grid item>
                          <CardValue label="Location">
                            {contract.location?.name}
                          </CardValue>
                        </Grid>
                        <Grid item>
                          <CardValue label="Date(s)">
                            {durationBetweenISODates(contract.startDate, contract.endDate)}
                          </CardValue>
                        </Grid>
                      </Grid>
                    </Grid> 
                    <Grid item>
                      <ActionButton
                        size="small"
                        text={"Manage Jobs"}
                        tracking={"Manage-Jobs"}
                        click={() => {
                          let searchParams = new URLSearchParams()
                          searchParams.append('accountId', contract.accountId)
                          searchParams.append('contractId', contract.id)
                          ControlTower.route(`${Routes.manageJobs}?${searchParams.toString()}`)
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <IconicButton onClick={() => this.handleEditContract(contract)}>
                    <EditIcon />
                  </IconicButton>  
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    )
  }

  // Loading

  loadAccounts = async () => {
    const { accountStore, userStore } = this.props 

    if (userStore!.user?.isAdminOrAgent) {
      const accounts: Account[] = await accountStore!.listAccounts()
      const sorted = accounts.sort((a: Account, b: Account) => a.name.localeCompare(b.name))
      this.accounts = sorted 
    } 
  }

  loadContracts = async () => {
    const { jobStore, userStore } = this.props
    
    let accountFilter: Account 
    if (userStore!.user?.isAdminOrAgent && this.accountFilter) {
      accountFilter = this.accountFilter
    } else if (userStore!.user?.account) {
      this.accountFilter = userStore!.user.account
      accountFilter = this.accountFilter
    }

    // TODO: Ability to filter on completed contracts. 
    // let filter: APITypes.ModelContractFilterInput
    // if (contractStatus === 'Completed') {
    //   filter = {
    //     endDate: {lt: getISODateToday()}
    //   }
    // } else {
    //   filter = {
    //     endDate: {ge: getISODateToday()}
    //   }
    // }
    this.props.progress!.show("ContractList")
    const promises: Promise<void | JobCandidate[]>[] = []
    const contracts = await jobStore!.listContractsByAccount(accountFilter!.id)
    // const contracts = await accountStore!.listContractsByAccount(accountId, filter)
    contracts.forEach(contract => {
      contract.jobPosts.forEach((jobPost: JobPost) => {
        promises.push(jobStore!.listJobCandidatesByJobPost(jobPost.id)
          .then((jobCandidates: void | JobCandidate[]) => {
            if (jobCandidates) {
              jobPost.jobCandidates = jobCandidates
            }
          })
        )
      })
    })

    await Promise.all(promises)
    this.contracts = contracts
    this.props.progress!.hide("ContractList")
  }

  // Contracts Actions 

  handleAddContract = () => {
    const { userStore } = this.props
    const accountId = this.accountFilter ? this.accountFilter!.id : userStore!.user!.accountId
    const contract = new Contract({
      accountId
    })
    this.contract = contract
    this.isContractFormOpen = true
  }

  handleEditContract = (contract: Contract) => {
    this.contract = contract
    this.isContractFormOpen = true 
  }
}

export default withTheme((withStyles(styles)(ContractsList)))


