import * as React from 'react';
import Button from '@material-ui/core/Button';
import {createStyles, Theme, withStyles, WithStyles} from '@material-ui/core/styles'
import {RouteComponentProps} from '@reach/router'
import LoginDialog from './LoginDialog'
import {inject, observer} from "mobx-react";
import {makeObservable, observable} from "mobx";
import UserStore from "./../../stores/UserStore"
import ControlTower, {Routes} from "../../components/ControlTower";
import Tracking from "../Tracking";
import PasswordAssistDialog from "./PasswordAssistDialog";
import {AccountStatus} from "../../API";
import config from "react-global-configuration";

const styles = (theme: Theme) => createStyles({
  button: {
    margin: theme.spacing(1),
    color: theme.palette.primary.main,
    fontWeight: 400,
    fontSize: 16,
    padding: 0
  },
})

interface IAuthButtonProps {
  open?: boolean
  onLogin?: any
  onClose?: any
  userStore?: UserStore
}

@inject("userStore")
@observer
class AuthButton extends React.Component<WithStyles<typeof styles> & RouteComponentProps & IAuthButtonProps> {

  @observable open = false
  @observable mode = "login"
  @observable email = ""
  @observable isAuthenticated = false

  constructor(props: any) {
    super(props)
    makeObservable(this);
    this.open = props.open
  }

  onClickOpen = async () => {
    if (this.props.userStore!.isAuthenticated) {
      // Navigate to home page on logout
      await this.props.userStore!.signOut()
      this.isAuthenticated = false
      ControlTower.route(`${Routes.home}?logout=true`)
      Tracking.event({action: 'Logout'})
    } else {
      const currentRoute = ControlTower.currentRoute
      const isLoginPage = currentRoute === Routes.home || currentRoute === Routes.login
      if (isLoginPage === false) {
        this.open = true
        this.mode = "login"
        this.isAuthenticated = false
        Tracking.modalView("LoginDialog")
      }
    }
  }

  onShowLogin = () => {
    this.open = true
    this.mode = "login"
    this.isAuthenticated = false
    Tracking.modalView("LoginDialog")
  }

  onShowPasswordAssist = () => {
    this.open = true
    this.mode = "passwordAssist"
    this.isAuthenticated = false
    Tracking.modalView("PasswordAssistDialog")
  }

  onShowSignUp = () => {
    this.open = true
    this.mode = "signUp"
    this.isAuthenticated = false
    Tracking.modalView("CustomerSignupDialog")
  }

  onLogin = () => {
    const {onLogin, userStore} = this.props

    if (userStore!.user && userStore!.user.account && userStore!.user.account.accountStatus === AccountStatus.Pending) {
      userStore!.signOut()
      ControlTower.route(Routes.accountPending)
      return
    }
    
    Tracking.event({category: 'LoginDialog', action: 'Login'})
    
    if (onLogin) {
      onLogin()
    }

    userStore!.routeInitial()

    this.mode = ""
    this.isAuthenticated = true
    this.open = false
  }

  onClose = () => {
    if (this.props.onClose) {
      this.props.onClose()
    }
    this.open = false
    this.mode = ""
    this.isAuthenticated = false
  }

  render() {
    const { classes } = this.props

    let dialog = null

    if (this.open) {
      if (this.mode === "login") {
        dialog = 
          <LoginDialog 
            onLogin={this.onLogin} 
            onClose={this.onClose} 
            onShowPasswordAssist={this.onShowPasswordAssist}
            onShowSignUp={this.onShowSignUp}
          />
      } else if (this.mode === "passwordAssist") {
        dialog =
          <PasswordAssistDialog
            onClose={this.onClose}
            onShowLogin={this.onShowLogin}
          />
      }
    }

    return (
      <div>
        {!this.props.open &&
          <Button color="primary" className={classes.button}
            onClick={this.onClickOpen}>
            {this.props.userStore!.isAuthenticated ? "Log Out" : "Log In"}
          </Button>
        }
        {dialog}
      </div>
    );
  }
}

export default withStyles(styles)(AuthButton)
