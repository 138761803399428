import { Box, Paper, Theme, createStyles, makeStyles, useMediaQuery, useTheme } from "@material-ui/core"
import Stack from "../Stack"

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    overflow: "hidden"
  }
}))

const DialogPanel = ({
  elevation, 
  backgroundColor, 
  children 
}: {
  elevation?: number 
  backgroundColor?: string 
  children: React.ReactNode 
}) => {
  const classes = useStyles()
  const theme = useTheme()
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'))
  return (
    <Paper
      elevation={ elevation ?? 0 }
      className={classes.root}
    >
      <Box
        bgcolor={backgroundColor}
        sx={{
          py: 2,
          px: isSmallScreen ? 2 : 3
        }}
        >
        <Stack
          direction="column"
          justifyContent="flex-start"
          spacing={3}
          wrap="nowrap"
        >
          {children}
        </Stack>
      </Box>
    </Paper>
  )
}

export default DialogPanel 
