import * as React from "react";
import {Box, createStyles, Grid, isWidthDown, Theme, Typography, withStyles, WithStyles, WithWidth, withWidth} from "@material-ui/core";

const styles = (theme: Theme) => createStyles({
  container: {
  },
  label: {
    fontFamily: [
      'Roboto',
      'sans-serif'
    ].join(','),
    fontWeight: 300,
    color: theme.palette.text.secondary,
    fontSize: 15
  },
  value: {
    fontFamily: [
      'Roboto',
      'sans-serif'
    ].join(','),
    fontSize: 15,
    fontWeight: 500,
    color: theme.palette.text.primary,
    textOverflow: "ellipses"
  }
})

interface ICardValueProps {
  icon?: React.ReactNode
  label?: string
  children: any
  labelWidth?: number
  wrapText?: boolean
  maxTextWidth?: number | string
}

class CardValue extends React.Component<WithStyles<typeof styles> & ICardValueProps & WithWidth> {

  render() {
    const { 
      classes, 
      icon, 
      label, 
      labelWidth, 
      children, 
      wrapText, 
      maxTextWidth 
    } = this.props

    let textStyle
    if (maxTextWidth) {
      textStyle = {
        maxWidth: maxTextWidth,
        overflow: 'hidden',
        textOverflow: 'ellipses'
      }
    }

    const isExtraSmall = isWidthDown('sm', this.props.width)

    return (
      <Box>
        <Grid
          container
          direction={ isExtraSmall ? "column" : "row" }
        >
          { icon && 
            <Grid item>
              {icon}
            </Grid>
          }
          { label && 
            <Grid 
              item 
              className={classes.label} 
            >
              <Box
                width={ labelWidth ? labelWidth : '100px' }
              >
                <Typography noWrap>
                  {label}
                </Typography>
              </Box>
            </Grid>
          }
          <Grid 
            item 
            className={classes.value}
          >
            <Typography 
              component={ typeof children === 'string' ? 'p' : 'div' }
              // noWrap={wrapText === undefined || wrapText === false} 
              style={textStyle}
            >
              {children}
            </Typography>
          </Grid>
        </Grid>
      </Box>
    )

  }
}

export default withStyles(styles)(withWidth()(CardValue))

