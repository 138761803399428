import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles';
import { RouteComponentProps } from '@reach/router';
import { Auth } from 'aws-amplify';
import { observable, makeObservable } from "mobx";
import { inject, observer } from "mobx-react";
import * as React from 'react';
import CancelButton from '../../../components/form/CancelButton';
import DialogButton from '../../../components/form/DialogButton';
import FormValidator from '../../../components/form/FormValidator';
import ProgressButton from '../../../components/form/ProgressButton';
import TextFieldValidator from '../../../components/form/TextFieldValidator';
import Notify from '../../../components/notify/Notify';
import Visible from '../../../components/Visible';
import AuthAPI from "../../../apis/AuthAPI";

const styles = (theme: Theme) => createStyles({
  submitButton: {
    color: "#fff",
  },
  instructions: {
    marginBottom: 0
  }

})

interface IEmailChangeDialogProps {
  onClose: any
  onChange: any
  oldEmail: string
  notify?: Notify
  authAPI?: AuthAPI
}

@inject("notify", "authAPI")
@observer
class EmailChangeDialog extends React.Component<WithStyles<typeof styles> & RouteComponentProps & IEmailChangeDialogProps> {
  @observable values = {
    oldEmail: "",
    newEmail: "",
    code: ""
  }

  constructor(
    props: WithStyles<typeof styles> & RouteComponentProps & IEmailChangeDialogProps
  ) {
    super(props);
    makeObservable(this);
  }

  componentDidMount() {
    this.values.oldEmail = this.props.oldEmail
  }

  @observable message = ""
  @observable confirming: boolean = false
  @observable isProcessing: boolean = false

  onClose = () => {
    if (this.props.onClose) {
      this.message = ""
      this.props.onClose();
    }
  };

  onSubmit = async () => {
    const { notify, authAPI, onChange } = this.props

    this.message = ""

    if (!this.confirming) {
      this.isProcessing = true

      const alias = await authAPI?.getAlias(this.values.newEmail)
        .catch(err => {
          this.message = err.message
        })

      if (alias && alias.status === "VERIFIED") {
        this.message = "The new email is already in use."
      }

      if (this.message) {
        this.isProcessing = false
        return
      }

      Auth.currentAuthenticatedUser()
        .then(user => {
          Auth.updateUserAttributes(user, { email: this.values.newEmail.toLowerCase() })
            .then(data => {
              this.confirming = true
              this.isProcessing = false
            })
            .catch(err => {
              this.message = err.message
              this.isProcessing = false
            });
        })
        .catch(err => {
          this.message = err.message
        });
    } else {
      this.isProcessing = true
      Auth.verifyCurrentUserAttributeSubmit('email', this.values.code)
        .then(result => {
          this.isProcessing = false
          notify!.show("success", "Email address updated!")
          if (onChange) {
            onChange(this.values.newEmail.toLowerCase())
          }
        })
        .catch(err => {
          this.isProcessing = false
          this.message = err.message
        });
    }
  }

  onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const name = event.target.name
    this.values[name] = event.target.value
  }

  onResendCode = () => {
    const { notify } = this.props
    this.message = ""

    Auth.currentAuthenticatedUser()
      .then(user => {
        Auth.verifyCurrentUserAttribute("email")
          .then(data => {
            this.confirming = true
            this.isProcessing = false
            notify!.show("success", "Confirmation email resent!")
          })
          .catch(err => {
            this.message = err.message
            this.isProcessing = false
          });
      })
      .catch(err => {
        this.message = err.message
      });
  }

  render() {
    const { classes } = this.props

    return (
      <Dialog
        id="emailResetDialog"
        open={true}
        onClose={this.onClose}
        scroll="paper"
        maxWidth="xs"
        fullWidth
        aria-labelledby="email-change-dialog-title">
        <FormValidator onSubmit={this.onSubmit} autoComplete="off">
          <DialogTitle id="email-change-dialog-title">Change Email</DialogTitle>
          <DialogContent>
            <DialogContentText color="error">
              {this.message}
            </DialogContentText>
            <TextFieldValidator
              autoFocus
              margin="dense"
              name="oldEmail"
              label="Old Email"
              type="email"
              validators={{ required: true }}
              value={this.values.oldEmail}
              disabled={true}
              fullWidth
            />
            <TextFieldValidator
              margin="dense"
              name="newEmail"
              label="New Email"
              type="email"
              validators={{ required: true, isEmail: true }}
              value={this.values.newEmail}
              onChange={this.onChange}
              disabled={this.confirming}
              fullWidth
            />
            <Visible if={this.confirming}>
              <DialogContentText className={classes.instructions}>
                Please check your new email for a confirmation code.
              </DialogContentText>
              <TextFieldValidator
                margin="dense"
                name="code"
                label="Confirmation Code"
                type="text"
                value={this.values.code}
                validators={{ required: true, matches: "^\\d{6}$" }}
                onChange={this.onChange}
                fullWidth
              />
              <DialogButton variant="tertiary" onClick={this.onResendCode}>
                Resend confirmation code
              </DialogButton>
            </Visible>
          </DialogContent>
          <DialogActions>
            <CancelButton onClick={this.onClose} />
            <ProgressButton variant="contained" color="primary"
              type="submit" processing={this.isProcessing}
              onClick={this.onSubmit}>
              {this.confirming ? "Confirm" : "Next"}
            </ProgressButton>
          </DialogActions>
        </FormValidator>
      </Dialog>
    );
  }
}

export default withStyles(styles)(EmailChangeDialog)