import Service from "./Service";
import {NAICS} from "./Industry";

export class ProfileService {
  id: string
  createdAt: string
  updatedAt: string
  userId: string
  profileId: string
  serviceId: string
  service?: Service
  industries: string[]

  constructor(data: any) {
    this.id = data.id
    this.createdAt = data.createdAt
    this.updatedAt = data.updatedAt
    this.userId = data.userId
    this.profileId = data.profileId
    this.serviceId = data.serviceId
    this.service = data.service ? new Service(data.service) : undefined
    this.industries = data.industries ?? []
  }
}

export default ProfileService