import {
  Avatar,
  Button,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Link,
  makeStyles,
  Theme,
  Typography
} from "@material-ui/core"
import classnames from "classnames"
import JobCandidate from "../../model/JobCandidate"
import Profile from "../../model/Profile"
import ActionLink from "../ActionLink"
import LockIcon from '@material-ui/icons/Lock';
import React, {useEffect, useState} from "react"
import {Skeleton} from "@material-ui/lab"
import Stack from "../Stack"
import {LockOpen} from "@material-ui/icons"
import {JobCandidateStatus, UpdateJobCandidateInput, UpdateJobPostInput, UserRole} from "../../API"
import {useStores} from "../../stores/StoreProvider"
import {when} from "mobx"
import FormValidator from "../form/FormValidator"
import CardValue from "../CardValue"
import CancelButton from "../form/CancelButton"
import ProgressButton from "../form/ProgressButton"
import Answer from "../../model/Answer";
import ProfileCredentialsView from "../../views/profiles/ProfileCredentialsView"

const useStyles = makeStyles((theme: Theme) => createStyles({
  avatar: {
    backgroundColor: theme.palette.primary.main,
    borderWidth: 2,
  },
  avatarLocked: {
    backgroundColor: theme.palette.grey[300],
    borderWidth: 2,
  },
  avatarTextLarge: {
    fontSize: 24,
  },
  avatarTextSmall: {
    fontSize: 14,
    lineHeight: 21 
  },
  avatarActive: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText
  },
  avatarApplied: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText
  },
  avatarHired: {
    backgroundColor: theme.palette.success.dark,
    color: theme.palette.primary.contrastText
  },
  avatarRejected: {
    backgroundColor: theme.palette.error.dark,
    color: theme.palette.error.contrastText
  },
  avatarInactive: {
    backgroundColor: theme.palette.action.disabled,
    color: theme.palette.primary.contrastText
  },
  avatarPending: {
    backgroundColor: theme.palette.warning.main,
    color: theme.palette.primary.contrastText
  },
  large: {
    fontSize: 24,
    width: 60,
    height: 60,
    fontWeight: 'bold',
  },
  small: {
    fontSize: 14,
    width: 34,
    height: 34,
  },
  form: {
    overflowY: "auto",
    display: "flex",
    flexDirection: "column"
  },
  progressButton: {
    minWidth: 80
  },
  error: {
    color: theme.palette.error.main
  },
}))

const ProfileNameView = ({
  profile,
  jobCandidate,
  size,
  onClickTitle,
  onUpdateJobCandidate,
  showAvatar,
  showUnlock,
  textColor, 
  textVariant,
  onUpdateProfile
}: {
  profile: Profile
  jobCandidate?: JobCandidate
  size?: 'large' | 'small'
  onClickTitle?(): void
  onUpdateJobCandidate?(jobCandidate: JobCandidate): void 
  showAvatar?: boolean 
  showUnlock?: boolean 
  textColor?: 'primary' | 'secondary' | 'inherit'
  textVariant?: 'h2' | 'h4' | 'body1'
  onUpdateProfile?(updatedProfile: Profile): Promise<void>
}) => {
  // const theme = useTheme()
  const classes = useStyles()
  const { jobStore, userStore, progress } = useStores()

  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [isProcessing, setIsProcessing] = useState<boolean>(false)
  const [isUnlocked, setIsUnlocked] = useState<boolean>(false)
  const [fullName, setFullName] = useState<string>()
  const [initials, setInitials] = useState<string>()

  const [isUnlockDialogOpen, setIsUnlockDialogOpen] = useState(false)
  // const [error, setError] = useState("")

  useEffect(() => {
    progress.show("ProfileName")

    when(
      // once...
      () => !userStore!.isLoading,
      // ... then
      async () => {
        // Load profile
        const isUnlocked = profile.getIsUnlocked(userStore.user, jobCandidate)

        let fullName 

        if (isUnlocked) {
          fullName = profile.user?.fullName ?? profile.nickname
        }
        
        const initials = profile.initials
        
        setFullName(fullName)
        setInitials(initials)
        setIsUnlocked(isUnlocked)

        setIsLoading(false)
        progress.hide("ProfileName")
      }
    )
  }, [
    profile, 
    jobCandidate,
    userStore,
    progress 
  ])

  const renderAvatar = () => {
    if (isLoading) {
      const dimension = size && size === 'large' ? 44 : 24
      return (
        <Skeleton variant="circle" width={dimension} height={dimension} />
      )
    }

    const status = jobCandidate ? jobCandidate.status : JobCandidateStatus.Interested

    let statusClassName

    if (jobCandidate) {
      if (jobCandidate.status === JobCandidateStatus.Applied && jobCandidate.application) {
        let isApplicant
        if (jobCandidate.profile && jobCandidate.profile.user && jobCandidate.profile.user.role === UserRole.Candidate) {
          isApplicant = false
        } else if (jobCandidate.profile && jobCandidate.profile.profileStatus) {
          isApplicant = jobCandidate.profile.inReview()
        } else {
          isApplicant = jobCandidate.application.find((a: Answer) => a.name === "GovGig Candidate" && a.value === "false")
        }
        if (isApplicant) {
          statusClassName = classes.avatarPending
        }
      }
    }

    if (!statusClassName) {
      switch (status) {
        case JobCandidateStatus.Applied:
          statusClassName = classes.avatarApplied
          break
        case JobCandidateStatus.Accepted:
        case JobCandidateStatus.Reviewing:
        case JobCandidateStatus.Contacted:
          statusClassName = classes.avatarActive
          break
        case JobCandidateStatus.Hired:
          statusClassName = classes.avatarHired
          break
        case JobCandidateStatus.Rejected:
        case JobCandidateStatus.Declined:
          statusClassName = classes.avatarRejected
          break
        default:
          statusClassName = classes.avatarInactive
          break
      }
    }

    let classArray = [statusClassName]
    if (size) {
      if (size === 'large') {
        classArray.push(classes.large)
      } else if (size === 'small') {
        classArray.push(classes.small)
      }
    }
    return (
      <Link 
        onClick={() => {
          if (onClickTitle) onClickTitle()
        }}
        style={{
          cursor: onClickTitle ? "pointer" : undefined
        }}
      >
        <Avatar className={classnames(classArray)}>
          { isUnlocked ? 
            <Typography className={ size === "large" ? classes.avatarTextLarge : classes.avatarTextSmall}>
              {initials}
            </Typography>
          : 
            <LockIcon fontSize={ size === "large" ? "large" : "small" } />
          }
        </Avatar>
      </Link>
    )
  }

  const renderLink = () => {
    const text = isUnlocked ? fullName! : initials!
    return (
      <ActionLink
        text={ text }
        alignText="left"
        variation="dark"
        tracking="JobCandidate-Title"
        click={onClickTitle}
      />
    )
  }

  const renderName = () => {
    if (isLoading) {
      return (
        <Skeleton variant="text" width={100} />
      )
    }

    return (
      <React.Fragment>
          <Stack direction="column" spacing={ textVariant === "h2" ? 1 : 0 }>
            { onClickTitle ? 
              renderLink()
            : 
              <Typography variant={ textVariant } color={ textColor }>
                { isUnlocked ? fullName : initials }
              </Typography>
            }
            <ProfileCredentialsView 
              profile={profile} 
              textVariant={ textVariant === "h2" ? "h4" : "body1" }
              textColor={ textColor }
              onChange={ onUpdateProfile ? async (updatedProfile: Profile) => {
                await onUpdateProfile(updatedProfile)
              } : undefined }  
            />
            { isUnlocked === false && showUnlock && jobCandidate && 
              <Button
                variant="contained"
                color="secondary"
                onClick={async () => {
                  setIsUnlockDialogOpen(true)
                }}
                startIcon={ <LockOpen /> }
              >
                Unlock Profile
              </Button>
            }
          </Stack>
        
      </React.Fragment>
    )
  }
  
  const onSubmitUnlock = async () => {
    setIsProcessing(true)

    const input: UpdateJobCandidateInput = {
      id: jobCandidate!.id,
      unlocked: true 
    }
    const updatedJobCandidate = await jobStore.updateJobCandidate(input)
    
    const jobPost = updatedJobCandidate!.jobPost
    const unlocksAvailable = jobPost!.unlocksAvailable - 1 
    if (unlocksAvailable < 0) {
      // setError('No more unlocks remaining. Please contact GovGig to request more.')
      throw new Error('No more unlocks')
    }

    // TODO: Update Job Post. 
    const jobPostInput: UpdateJobPostInput = {
      id: jobCandidate!.jobPostId,
      unlocksAvailable: unlocksAvailable
    }
    const updatedJobPost = await jobStore.updateJobPost(jobPostInput)
    updatedJobCandidate!.jobPost = updatedJobPost

    if (onUpdateJobCandidate) {
      onUpdateJobCandidate(updatedJobCandidate!)
    }

    setIsProcessing(false)
    setIsUnlockDialogOpen(false)
  }

  const renderUnlockDialog = () => {
    if (!isUnlockDialogOpen) {
      return null 
    }
    const jobPost = jobCandidate!.jobPost! 
    return (
      <Dialog
        open={isUnlockDialogOpen}
        onClose={() => setIsUnlockDialogOpen(false)}
        fullWidth
        maxWidth="sm"
        scroll="paper"
      >
        <FormValidator onSubmit={onSubmitUnlock} autoComplete="off" name="unlockForm" id="unlockForm" className={classes.form}>
          <DialogTitle id="form-dialog-title">Unlock Candidate Contact Info</DialogTitle>
          <DialogContent dividers>
            <DialogContentText>Unlock the Candidate's contact information for this Job Post.</DialogContentText>
            <DialogContentText>{`You have ${jobPost.unlocksAvailable} unlocks remaining for this job post.`}</DialogContentText>
            {/* <DialogContentText className={classes.error}>
              {error}
            </DialogContentText> */}
            <Grid container direction="column">
              <Grid item>
                <CardValue label="Candidate">
                  {profile.initials}
                </CardValue>
              </Grid>
              <Grid item>
                <CardValue label="Job Title">
                  {jobPost.title}
                </CardValue>
              </Grid>
              <Grid item>
                <CardValue label="Location">
                  {jobPost.location!.name}
                </CardValue>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Grid container xs={8} justifyContent="flex-end">
              <CancelButton onClick={() => setIsUnlockDialogOpen(false)} />
              <ProgressButton variant="contained" size="small" color="primary"
                              type="submit" className={classes.progressButton} processing={isProcessing}
                              disabled={jobPost.unlocksAvailable < 1 || isProcessing}>
                Unlock 
              </ProgressButton>
            </Grid>
          </DialogActions>
        </FormValidator>
      </Dialog>
    )
  }
  
  return (
    <React.Fragment>
      <Grid container direction="row" spacing={1} justifyContent="flex-start" alignItems="center" wrap="nowrap">
        { showAvatar &&  
          <Grid item>
            { renderAvatar() }
          </Grid>
        }
        <Grid item >
          { renderName() }
        </Grid>
      </Grid>
      { renderUnlockDialog() }
    </React.Fragment>
  )
}

export default ProfileNameView
