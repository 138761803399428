import { RouteComponentProps } from "@reach/router"
import AgreementPage from "../../components/page/AgreementPage"
import ExclusiveMasterServiceAgreement from "./ExclusiveMasterServiceAgreement"

const ExclusiveMasterServiceAgreementPage = ({

}: RouteComponentProps) => {
  return (
    <AgreementPage
      title="Exclusive Master Services & Non-Solicitation Agreement"
    >
      <ExclusiveMasterServiceAgreement />
    </AgreementPage>
  )
}

export default ExclusiveMasterServiceAgreementPage 
