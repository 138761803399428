import { Box, useTheme } from "@material-ui/core";
import { DraggableProvided, DraggableStateSnapshot } from "react-beautiful-dnd";
import { IKanbanOptions, IKanbanRow } from "./KanbanBoard";

const KanbanCard = ({
  innerRef,
  row, 
  virtualStyle, 
  options, 
  provided,
  snapshot
}: {
  innerRef: any 
  row: IKanbanRow 
  virtualStyle?: any // Optional styling to pass in to the cards. 
  options: IKanbanOptions
  provided: DraggableProvided
  snapshot: DraggableStateSnapshot
}) => {
  const theme = useTheme()

  // Create the spacing and other styling of cards on the Kanban board. 
  const getStyle = (options: any) => {
    const { draggableStyle, virtualStyle, isDragging } = options 
    const combined = {
      ...virtualStyle,
      ...draggableStyle
    };

    const grid = theme.spacing(1);

    const result = {
      ...combined,
      marginBottom: grid
    };

    if (combined.height) {
      result['height'] = isDragging ? combined.height : combined.height - grid
    }

    if (combined.left) {
      result['left'] = isDragging ? combined.left : combined.left + grid
    }

    if (draggableStyle.width) {
      result['width'] = isDragging ? draggableStyle.width : `calc(${combined.width} - ${grid * 2}px)`
    }
    
    // console.debug("KanbanCard", result)

    return result;
  }

  return (
    <div
      ref={innerRef}
      {...provided.draggableProps}
      {...provided.dragHandleProps}
      style={getStyle({
        draggableStyle: provided.draggableProps.style,
        virtualStyle,
        isDragging: snapshot.isDragging
      })}
    >
      <Box
        sx={{
          paddingLeft: theme.spacing(1),
          paddingRight: theme.spacing(1)
        }}
      >
        {options.renderCard(row)}
      </Box>
    </div>
  )
}

export default KanbanCard
