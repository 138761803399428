import * as React from 'react';
import { createStyles, Theme, WithStyles, withStyles } from '@material-ui/core/styles'
import {Box, Checkbox, FormControlLabel, FormGroup, FormHelperText, Typography} from "@material-ui/core";
import {inject} from "mobx-react";
import {humanizeString} from "../../stores/StoreUtilities";
import {WorkSetting} from "../../API";

const styles = (theme: Theme) => createStyles({
  formGroup: {
    marginTop: 0,
    paddingTop: 0,
    marginBottom: 0,
    paddingBottom: 0
  },
  fieldLabel: {
    // fontSize: 12,
    // fontWeight: 400,
    // color: theme.palette.text.secondary,
    paddingTop: 0,
    paddingBottom: 0
  },
  helperText: {
    marginTop: 0 
  }
})


interface IWorkSettingSelectorProps {
  value?: WorkSetting[]
  required?: boolean
  onChange?(value: string[]): any
  formvalidator?: any
}

@inject("formvalidator")
class WorkSettingSelector extends React.Component<WithStyles<typeof styles> & IWorkSettingSelectorProps>{

  state = {
    value: [] as string[],
    isValid: true,
  };

  componentDidMount() {
    const { value, formvalidator } = this.props
    if (formvalidator) {
      formvalidator.attachToForm(this)
    }
    this.setState({value: this.props.value ?? []})
  }

  componentDidUpdate(prevProps: any) {
    if (prevProps.value !== this.props.value) {
      this.setState({value: this.props.value ?? []})
    }
  }

  validate = (value: string): boolean => {
    const isRequired = this.props.required ?? false
    const isValid = !isRequired || (value.length > 0)
    this.setState({isValid})
    return isValid
  }

  render() {
    const { classes } = this.props

    const workSettings = Object.values(WorkSetting)
    const textColor = this.state.isValid === false ? "error" : undefined

    return (
      <FormGroup className={classes.formGroup}>
        <Typography variant="body2" color={textColor} className={classes.fieldLabel}>Work Setting Option(s)</Typography>
        <Box flexDirection="row">
          {workSettings.map((workSetting: string) => {
            const key = Object.keys(WorkSetting)[Object.values(WorkSetting).indexOf(workSetting as WorkSetting)]
            return (
              <FormControlLabel value={workSetting} label={humanizeString(key)} key={key} 
                                control={
                                  <Checkbox name="workSetting" value={workSetting}
                                            checked={this.state.value!.indexOf(workSetting) >= 0}
                                            onClick={this.onClick}/>}
              />
            )
          })}
        </Box>
        <FormHelperText error={!this.state.isValid} className={classes.helperText}>
          {this.state.isValid ? "" : "Required"}
        </FormHelperText>
      </FormGroup>
    )
  }

  onClick = (event: any) => {
    const {checked, value} = event.target
    let workSettings: string[] = [...this.state.value]
    if (checked) {
      workSettings.push(value)
    } else {
      workSettings.splice(workSettings.indexOf(value), 1)
    }
    const isValid = !this.props.required || workSettings.length > 0
    this.setState({value: workSettings, isValid})

    if (this.props.onChange) {
      this.props.onChange(workSettings)
    }
  }
}

export default withStyles(styles)(WorkSettingSelector)