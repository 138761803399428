// import { Box, Typography, useTheme } from "@material-ui/core"
// import { useState } from "react"
import JobPost from "../../model/JobPost"
import JobPostPage from "../../pages/jobPost/JobPostPage"
import AppDialog from "../dialog/AppDialog"
import InstructionsPanel from "../page/InstructionsPanel"
import * as APITypes from '../../API'
import { useStores } from "../../stores/StoreProvider"
import { getISODateToday, getISODateTodayAddDays } from "../../stores/StoreUtilities"
import { Box, Button, CircularProgress } from "@material-ui/core"
import React, { useEffect, useState } from "react"
import PublishIcon from '@material-ui/icons/Publish';
import {JobPostType} from "../../API";

const JobPostPublish = ({
  jobPost,
  isOpen, 
  onClickPublish,
  onUpdateJobPost,
  onCancel
}: {
  jobPost?: JobPost
  isOpen: boolean 
  onClickPublish(): void 
  onUpdateJobPost(jobPost: JobPost): Promise<void>
  onCancel(): void
}) => {
  // const theme = useTheme()
  const { 
    // accountStore, 
    jobStore,
    userStore 
  } = useStores()

  const [showPublishButton, setShowPublishButton] = useState(false)

  useEffect(() => {
    const update = async () => {
      let showPublishButton = false 
      if (jobPost && jobPost.status === APITypes.JobPostStatus.Draft) {
        if (userStore!.isAdminOrAgent) {
          // Show publish button always for Admin/Agent. 
          showPublishButton = true 
        } else if (jobPost.jobPostType === APITypes.JobPostType.SelfService) {
          // Show the publish button to the Employer if not a Recruiting Service job post. 
          showPublishButton = true 
        }
      }
      setShowPublishButton(showPublishButton)
    }

    update() 
  }, 
  [
    jobPost,
    jobStore,
    userStore
  ])

  const handlePublish = async () => {
    const status = APITypes.JobPostStatus.Posted

    // TODO: Populate open/closed date: 
    const input: APITypes.UpdateJobPostInput = {
      id: jobPost!.id,
      status: status,
      openDate: getISODateToday(),
      closedDate: (jobPost!.jobPostType === JobPostType.RecruitingServices || jobPost!.jobPostType === JobPostType.StaffingServices) ? null : getISODateTodayAddDays(30)
    }

    const updatedJobPost = await jobStore.updateJobPost(input)
    await onUpdateJobPost(updatedJobPost!)
  }

  if (showPublishButton === false) {
    return null 
  }

  return (
    <React.Fragment>
      <Button
        variant="contained"
        color="secondary"
        size="medium"
        onClick={() => {
          onClickPublish()
        }}
        startIcon={ <PublishIcon /> }
      >
        Publish 
      </Button>
      <AppDialog 
        title={'Publish Job Post'} 
        dialogId={'publish-job-post'} 
        formId={'publish-job-post'} 
        isLoading={ jobPost === undefined } 
        isOpen={isOpen} 
        submitLabel="Publish"
        scroll="paper"
        onCancel={onCancel} 
        onSubmit={async () => {
          await handlePublish()
        }}
      >
        <InstructionsPanel
          text={[
            "Review your Job Post below and press the 'Publish' button to publish the job post so that it will be visible on the GovGig job board for Candidates to find in their job searches."
          ]}
        />
        { jobPost ? 
          <JobPostPage
            jobPostId={jobPost.id}
            embed 
            // onUpdateJobPost={onUpdateJobPost}
          />
        :
          <Box textAlign="center">
            <CircularProgress />
          </Box>
        }
      </AppDialog>
    </React.Fragment>
  )
}

export default JobPostPublish