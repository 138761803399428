import * as React from 'react';
import { createStyles, Theme, WithStyles, withStyles } from '@material-ui/core/styles'
import DialogButton from './DialogButton';
import { Box } from '@material-ui/core';

const styles = (theme: Theme) => createStyles({
    // closeButton: {
    //   marginRight: theme.spacing(1)
    // }
})

interface ICancelButtonProps {
    onClick(): void
}
  
class CancelButton extends React.Component<WithStyles<typeof styles> & ICancelButtonProps> {
  render() {
    return (
      <Box mr={2}>
        <DialogButton variant="secondary" onClick={this.props.onClick}>
          Cancel
        </DialogButton>
      </Box>
    )    
  }
}

export default withStyles(styles)(CancelButton)
