import { Box, Grid, InputAdornment, Typography, useTheme } from "@material-ui/core"
import SimpleForm from "../../components/form/SimpleForm"
import DialogPanelButtons from "../../components/panel/DialogPanelButtons"
import DatePickerValidator from "../../components/form/DatePickerValidator"
import { useEffect, useState } from "react"
import IndustrySelector from "../../components/form/IndustrySelector"
import TextFieldValidator from "../../components/form/TextFieldValidator"
import { useStores } from "../../stores/StoreProvider"
import ServiceFilter from "../../components/filter/ServiceFilter"
import { LocationOption, ServiceOption } from "../../stores/ResourceCache"
import WorkSettingSelector from "../../components/form/WorkSettingSelector"
import PlaceFilter from "../../components/filter/PlaceFilter"
import { DatePeriods, getISODateFromDateString, humanizeString, isoToLocalDateString, moneyToNumberFormat, numberToMoneyFormat, periodToISODate } from "../../stores/StoreUtilities"
import Stack from "../../components/Stack"
import JobPost from "../../model/JobPost"
import ServiceRequest from "../../model/ServiceRequest"
import { CreateJobPostInput, EmploymentType, JobPostStatus, JobPostType, ServiceRequestType, UpdateJobPostInput, WorkSetting } from "../../API"
import { when } from "mobx"
import ContractField from "../../views/contracts/ContractField"
import Contract from "../../model/Contract"
import Account from "../../model/Account"
import * as APITypes from "../../API"
import DialogPanel from "../../components/panel/DialogPanel"
import DialogPanelInstructions from "../../components/panel/DialogPanelInstructions"
import LoadingPanel from "../../components/panel/LoadingPanel"
import RequestMeetingSection from "../../components/marketing/sections/RequestMeetingSection"

const ServiceRequestJobForm = ({
  serviceRequest,
  jobPostId, 
  account, 
  onClickBack,
  onUpdateJobPost,
  onCreateJobPost
}: {
  serviceRequest: ServiceRequest
  jobPostId?: string 
  account: Account
  onClickBack: () => void 
  onUpdateJobPost: (jobPost: JobPost) => void 
  onCreateJobPost: (jobPost: JobPost) => void 
}) => {
  const [isLoading, setIsLoading] = useState(true)
  const [isProcessing, setIsProcessing] = useState(false)
  const { resourceCache } = useStores()
  const employmentTypes: EmploymentType[] = [EmploymentType.Contract, EmploymentType.FullTime, EmploymentType.PartTime, EmploymentType.Temporary, EmploymentType.Internship]
  const [message, setMessage] = useState<string>()
  const theme = useTheme()
  const { accountStore, jobStore, userStore } = useStores()

  const [jobPost, setJobPost] = useState<JobPost>()
  const [industryOptions, setIndustryOptions] = useState<string[]>()
  const [industryFilter, setIndustryFilter] = useState<string>() 
  
  // Job post details: 
  const [contract, setContract] = useState<Contract>()
  const [serviceOption, setServiceOption] = useState<ServiceOption>() 
  const [locationOption, setLocationOption] = useState<LocationOption>()
  const [workSettings, setWorkSettings] = useState<WorkSetting[]>([])
  const [industries, setIndustries] = useState<string[]>([])
  const [securityClearance, setSecurityClearance] = useState<string>()
  const [employmentType, setEmploymentType] = useState<EmploymentType>()
  const [annualSalaryLow, setAnnualSalaryLow] = useState<string>()
  const [annualSalaryHigh, setAnnualSalaryHigh] = useState<string>()
  const [hiringPeriod, setHiringPeriod] = useState<string | null>()
  const [startDate, setStartDate] = useState<string>()
  const [endDate, setEndDate] = useState<string>()
  const [summary, setSummary] = useState<string>() 

  useEffect(() => {
    const init = async () => {
      if (jobPostId) {
        const jobPost = await jobStore.getJobPost(jobPostId)
        setJobPost(jobPost)

        setContract(jobPost!.contract)
        if (jobPost!.serviceId) {
          setServiceOption(resourceCache.getServiceOption(jobPost!.serviceId))
        }
        if (jobPost!.locationId) {
          setLocationOption(resourceCache.getLocationOption(jobPost!.locationId))
        }
        setWorkSettings(jobPost!.workSettings)
        setIndustries(jobPost!.industries)
        setSecurityClearance(jobPost!.securityClearance)
        setEmploymentType(jobPost!.employmentType)
        setAnnualSalaryLow(jobPost!.annualSalaryLow ? numberToMoneyFormat(jobPost!.annualSalaryLow) : "")
        setAnnualSalaryHigh(jobPost!.annualSalaryHigh ? numberToMoneyFormat(jobPost!.annualSalaryHigh) : "")
        setHiringPeriod(jobPost!.hiringPeriod)
        setStartDate(isoToLocalDateString(jobPost!.startDate))
        setEndDate(isoToLocalDateString(jobPost!.endDate))
        setSummary(jobPost!.summary)
      }

      const industryOptions = account.industries
      if (industryOptions.length === 1) {
        // If an account only has one industry, set that industry automatically and only show positions relevant to that industry.
        setIndustries(industryOptions)
        setIndustryFilter(industryOptions[0])
      }
      setIndustryOptions(industryOptions)
      
      setIsLoading(false)
    }

    when(
      () => accountStore.isLoading === false 
        && jobStore.isLoading === false 
        && userStore.isLoading === false, 
      () => {
        init() 
      }
    )
  }, [
    accountStore, 
    jobStore, 
    userStore, 
    jobPostId
  ])

  const renderContent = () => {
    return (
      <SimpleForm
        buttonArea={
          <DialogPanelButtons
            isProcessing={isProcessing}
            buttonDisabled={false}
            // onClickNext={() => {
            //   setStep(RequestStep.EnterJob)
            // }} 
            onClickBack={onClickBack} 
          />
        } 
        formId={"jobForm"} 
        onSubmit={async () => {
          setIsProcessing(true)
          // setStep(RequestStep.EnterJob)
          const fields = {
            accountId: serviceRequest.accountId,
            serviceRequestId: serviceRequest.id,
            contractId: contract ? contract.id : null, 
            serviceId: serviceOption!.id,
            workSettings: workSettings,
            locationId: locationOption!.id, 
            employmentType: employmentType!,
            annualSalaryLow: moneyToNumberFormat(annualSalaryLow!, 0),
            annualSalaryHigh: moneyToNumberFormat(annualSalaryHigh!, 0),
            securityClearance: securityClearance,
            summary: summary ?? "",  
            industries: industries,
            hiringDate: periodToISODate(hiringPeriod!),
            startDate: startDate ? getISODateFromDateString(startDate) : null,
            endDate: endDate ? getISODateFromDateString(endDate) : null,
            jobPostType: serviceRequest.serviceRequestType === ServiceRequestType.RecruitingServices ? JobPostType.RecruitingServices : JobPostType.StaffingServices,
            status: JobPostStatus.Draft
          }
          if (jobPost) {
            const input: UpdateJobPostInput = {
              id: jobPost.id, 
              ...fields 
            }

            const updatedJobPost = await jobStore.updateJobPost(input)
            
            setJobPost(updatedJobPost)
            setIsProcessing(false)
            onUpdateJobPost(updatedJobPost!)
          } else {
            const input: CreateJobPostInput = {
              ...fields 
            }

            const createdJobPost = await jobStore.createJobPost(input)
            setJobPost(createdJobPost)
            setIsProcessing(false)
            onCreateJobPost(createdJobPost!)
          }
        }} 
      >
        <ContractField 
          value={contract} 
          account={account}
          required={false} 
          onSelectContract={(contract: Contract) => {
            setContract(contract)

            if (contract) {
              // Populate job post fields with contract info 
              if (contract.locationId) {
                const locationOption = resourceCache.getLocationOption(contract.locationId) 
                setLocationOption(locationOption)
              }
              if (contract.startDate) {
                const startDate = isoToLocalDateString(contract.startDate)
                setStartDate(startDate)
              }
              if (contract.endDate) {
                const endDate = isoToLocalDateString(contract.endDate)
                setEndDate(endDate)
              }
            }
          }}        
        />
        { industryOptions && industryOptions.length > 1 && 
          <Box
            p={2}
            borderRadius={theme.spacing(1)}
            // bgcolor="hsl(218, 50%, 98%)"
            border={`1px solid ${theme.palette.grey[400]}`}
          >
            <IndustrySelector 
              // required
              disabled={industryOptions?.length === 1}
              industries={industryOptions} 
              value={industries} 
              onChange={(industries: string[]) => {
                setIndustries(industries)

                if (industries.length === 1) {
                  setIndustryFilter(industries[0])
                } else if (industryOptions?.length === 1) {
                  setIndustryFilter(industryOptions[0])
                } else {
                  setIndustryFilter("")
                }
                
              }} 
            />
          </Box>
        }
        <ServiceFilter
          freeSolo={true}
          variant="standard"
          margin="dense"
          required={true}
          value={serviceOption ?? undefined}
          industry={industryFilter}
          onSelectService={(serviceOption?: ServiceOption) => {
            setServiceOption(serviceOption)
          }}
          helperText="Select a job title"
        />
        <Box
          p={2}
          borderRadius={theme.spacing(1)}
          // bgcolor="hsl(218, 50%, 98%)"
          border={`1px solid ${theme.palette.grey[400]}`}
        >
          <Stack 
            direction="column"
            spacing={1}
          >
            <WorkSettingSelector 
              value={workSettings}
              onChange={(values: string[]) => {
                setWorkSettings(values as WorkSetting[])

                const anywhereLocationOption: LocationOption | null = jobStore!.getLocationOptionForWorkSettings(values as WorkSetting[], locationOption?.id)
                if (anywhereLocationOption) {
                  setLocationOption(anywhereLocationOption)
                }
              }} 
              required
            />
            <PlaceFilter
              variant="standard"
              value={locationOption}
              required
              // className={classes.placeFilter}
              onSelectLocation={(value?: LocationOption) => {
                if (value) {
                  setLocationOption(value)
                } else {
                  setLocationOption(undefined)
                }
              }}
            />
          </Stack>
        </Box>
        <TextFieldValidator
          type="text"
          validators={{ required: true }}
          // className={classes.employmentType}
          required
          name="employmentType"
          label="Employment Type"
          margin="normal"
          InputLabelProps={{ shrink: employmentType !== undefined }}
          autocompleteOptions={{
            freeSolo: false,
            options: employmentTypes,
            getOptionLabel: (option: string) => option ? humanizeString(option) : null,
            value: employmentType ?? null,
            onChange: (event: any, value: EmploymentType, reason: any) => {
              setEmploymentType(value)
            }
          }}
        />
        { employmentType === EmploymentType.FullTime && serviceRequest.serviceRequestType !== APITypes.ServiceRequestType.StaffingServices && 
          <Grid container spacing={1}>
            <Grid item xs={12} sm={6}>
              <TextFieldValidator
                fullWidth
                type="text"
                validators={{ required: false, isCurrency: true }}
                name="annualSalaryLow"
                label="Annual&nbsp;Salary&nbsp;(Low)"
                margin="normal"
                value={annualSalaryLow ?? ""}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  const value = event.target.value 
                  setAnnualSalaryLow(value)
                }}
                // InputLabelProps={{ shrink: true }}
                InputProps={{
                  startAdornment: <InputAdornment position="start">$</InputAdornment>
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextFieldValidator
                fullWidth
                type="text"
                validators={{ required: false, isCurrency: true }}
                name="annualSalaryHigh"
                label="Annual&nbsp;Salary&nbsp;(High)"
                margin="normal"
                value={annualSalaryHigh ?? ""}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  const value = event.target.value 
                  setAnnualSalaryHigh(value)
                }}
                // InputLabelProps={{ shrink: true }}
                InputProps={{
                  startAdornment: <InputAdornment position="start">$</InputAdornment>
                }}
              />
            </Grid>
          </Grid>
        }
        <TextFieldValidator
          type="text"
          name="securityClearance"
          label="Security Clearance"
          margin="normal"
          InputLabelProps={{ shrink: securityClearance !== undefined }}
          autocompleteOptions={{
            freeSolo: false,
            options: resourceCache!.listSecurityClearances(),
            value: securityClearance ?? null,
            onChange: (event: any, value: string, reason: any) => {
              setSecurityClearance(value)
            }
          }}
        />
        <TextFieldValidator
          type="text"
          // validators={{ required: true }}
          name="summary"
          label="Summary of other requirements"
          value={summary ?? ""}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            const value = event.target.value
            setSummary(value)
          }}
          fullWidth
          multiline
          minRows={4}
          variant="outlined"
        />
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <TextFieldValidator
              type="text"
              validators={{ required: true }}
              // className={classes.hiringDate}
              required
              name="hiringDate"
              label="Expected Hiring Date"
              helperText="When do you expect to hire?"
              margin="dense"
              InputLabelProps={{ shrink: hiringPeriod !== undefined }}
              autocompleteOptions={{
                freeSolo: false,
                options: DatePeriods,
                value: hiringPeriod ?? null,
                onChange: (event: any, value: string, reason: any) => {
                  setHiringPeriod(value) 
                }
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <DatePickerValidator
              disableToolbar
              variant="inline"
              format="MM/dd/yyyy"
              placeholder="MM/dd/yyyy"
              fullWidth
              margin="dense"
              autoOk
              id="date-picker-inline"
              label="Starting Date"
              name="startDate"
              value={startDate ?? ""}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                const value = event.target.value
                setStartDate(value)
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <DatePickerValidator
              disableToolbar
              variant="inline"
              format="MM/dd/yyyy"
              placeholder="MM/dd/yyyy"
              fullWidth
              margin="dense"
              autoOk
              id="date-picker-inline"
              label="Est. Ending Date"
              name="endDate"
              value={endDate ?? ""}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                const value = event.target.value
                setEndDate(value)
              }}
            />
          </Grid>
        </Grid>
      </SimpleForm>
    )
  }

  return (
    <DialogPanel>
      <DialogPanelInstructions 
        title="Enter Position Information"
        instructions={[
          "Enter the information for a job you need filled.",
          "You can add more positions after this one."
        ]}
      />
      { message && 
        <Box>
          <Typography color="error">
            {message}
          </Typography>
        </Box>
      }
      { isLoading ? <LoadingPanel /> : renderContent() }
      <RequestMeetingSection />
    </DialogPanel>
  )
}

export default ServiceRequestJobForm 
