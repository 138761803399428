import { observable, makeObservable } from "mobx";
import Tracking from "../Tracking";

class PrivacyPolicy {
  @observable open: boolean = false

  onClose: any

  show = (onClose?: any) => {
    this.open = true
    this.onClose = onClose
    Tracking.modalView("PrivacyPolicyDialog")
  }

  close = () => {
    this.open = false
    if (this.onClose) {
      this.onClose()
    }
  }

  constructor() {
    makeObservable(this);
  }
}

export default PrivacyPolicy