interface ICacheItem {
  item: any
  expires: number
}

type CacheResolver = (key: string) => Promise<any | undefined>

class CacheStore<CacheType> {

  private _expireSecs: number = 60
  private _resolver?: CacheResolver
  protected _cache = {}
  
  constructor (expireSecs: number, resolver?: CacheResolver) {
    this._expireSecs = expireSecs
    this._resolver = resolver
  }
  
  put = (key: string, item: CacheType) => {
    this._cache[key] = { item, expires: this.getExpires()}
  }
  
  get = async (key: string): Promise<CacheType | undefined> => {
    const cacheItem = this._cache[key]
    if (cacheItem && !this.isExpired(cacheItem)) {
      return cacheItem.item
    } else {
      return await this.resolve(key)
    }
  }
  
  resolve = async (key: string): Promise<CacheType | undefined> => {
    if (this._resolver) {
      const item = await this._resolver(key)
      if (item) {
        this._cache[key] = {item, expires: this.getExpires()}
      }
      return item
    } else {
      return undefined
    }
  }

  remove = (key: string) => {
    if (this._cache[key]) {
      this._cache[key] = undefined
    }
  }

  removeAll = () => {
    this._cache = {}
  }

  removeExpired = () => {
    // TODO
  }

  private getExpires(): number {
    const now = new Date().getTime()
    return now + this._expireSecs * 1000
  }

  private isExpired = (cacheItem: ICacheItem) => {
    const now = new Date().getTime()
    return (cacheItem.expires <= now)
  }

}

export default CacheStore