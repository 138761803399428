import React, { useState } from "react";
import { Box, createStyles, Grid, makeStyles, Theme, Typography } from "@material-ui/core";
import { DraggableProvided, DraggableRubric, DraggableStateSnapshot, Droppable, DroppableProvided, DroppableStateSnapshot } from 'react-beautiful-dnd';
import { IKanbanColumn, IKanbanOptions } from "./KanbanBoard";
import KanbanRow from "./KanbanRow";
import KanbanCard from "./KanbanCard";
import classNames from "classnames";
import { useEffect } from "react";
import { Virtuoso } from 'react-virtuoso'

const useStyles = makeStyles((theme: Theme) => createStyles({
  column: {
    height: '100%',
    marginRight: theme.spacing(1),
    backgroundColor: theme.palette.grey[500],
    borderRadius: 10
  },
  columnDraggingOver: {
    backgroundColor: "lightblue"
  },
  columnDraggingFrom: {
    backgroundColor: "red"
  },
  list: {
    transition: 'background-color 0.2s ease'
  },
  columnHeader: {
    padding: theme.spacing(0.5)
  },
  title: {
    paddingLeft: theme.spacing(1),
  },
  count: {
    textAlign: "right",
    paddingRight: theme.spacing(1)
  },
}))

interface IKanbanColumnProps {
  column: IKanbanColumn
  options: IKanbanOptions
}

const HeightPreservingItem = React.memo(({ children, ...props }) => {
  const [size, setSize] = useState(0)
  const knownSize = props['data-known-size']
  useEffect(() => {
    setSize((prevSize) => {
      return knownSize === 0 ? prevSize : knownSize
    })
  }, [knownSize])
  return (
    <div
      {...props}
      className="height-preserving-container"
      // check styling in the style tag below
      style={{ 
        // @ts-ignore:next-line
        "--child-height": `${size}px`
      }}
    >
      {children}
    </div>
  )
})

const KanbanColumn = React.memo(({
  column, 
  options
}: IKanbanColumnProps) => {  
  const classes = useStyles({ column, options })

  const renderVirtualList = (
    provided: DroppableProvided,
    snapshot: DroppableStateSnapshot
  ) => {
    return (
      <Virtuoso
        components={{
          Item: HeightPreservingItem,
        }}
        scrollerRef={provided.innerRef as any}
        data={column.rows}
        style={{ 
          width: options.columnWidth, 
          height: options.columnHeight 
        }}
        itemContent={(index, item) => {
          return (
            <KanbanRow 
              index={index} 
              data={{
                column, 
                options 
              }}
            />
          )
        }}
      />
    )
  }

  return (
    <Droppable 
      droppableId={column.id}
      isDropDisabled={column.isDropDisabled}
      mode="virtual"
      direction="vertical"
      renderClone={(
        provided: DraggableProvided,
        snapshot: DraggableStateSnapshot,
        rubric: DraggableRubric,
      ) => {
        // While the user is dragging an item, we can render a clone of the item. 
        // It is required to implement this function for virtual lists. 
        const row = column.rows[rubric.source.index]
        return (
          <KanbanCard 
            innerRef={provided.innerRef}
            row={row}
            options={options}
            provided={provided}
            snapshot={snapshot}
          />
        )
      }}
    >
      {(
        provided: DroppableProvided,
        snapshot: DroppableStateSnapshot,
      ) => {
        const columnClassNames = [classes.column]
        if (snapshot.isDraggingOver) {
          columnClassNames.push(classes.columnDraggingOver)
        }

        return (
          <div 
            ref={provided.innerRef}
            className={classNames(columnClassNames)} 
          >
            <Grid container direction="row" justifyContent="space-between" alignItems="center" className={classes.columnHeader}>
              <Grid item xs={9}>
                <Typography variant="h6" className={classes.title}>{column.title}</Typography>
              </Grid>
              <Grid item xs={3} className={classes.count}>
                <Typography variant="body2">{column.rows.length}</Typography>
              </Grid>
            </Grid>
            <Box
              className={classes.list}
            >
              {renderVirtualList(provided, snapshot)}
            </Box>
            {/* {provided.placeholder} // Apparently not used for virtual list approach. */}
          </div>
        )
      }}
    </Droppable>
  )
})

export default KanbanColumn
