import {
  Box,
  Button,
  createStyles, Dialog, DialogContent, DialogTitle,
  FormGroup,
  Grid,
  Paper,
  TextField,
  Theme,
  Typography,
  withStyles,
  WithStyles,
  withWidth
} from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import Hidden from "@material-ui/core/Hidden";
import { WithWidth } from '@material-ui/core/withWidth';
import { RouteComponentProps } from '@reach/router';
import { Auth } from "aws-amplify";
import { observable, makeObservable } from "mobx";
import { inject, observer } from 'mobx-react';
import React from 'react';
import {
  AgreementType,
  CreateAgreementInput,
  CreateProfileInput, CreateProfileLocationInput,
  CreateUserInput,
  ProfileStatus,
  UserRole, UserStatus
} from "../../API";
import Confirm from "../../components/confirm/Confirm";
import ControlTower, { Routes } from '../../components/ControlTower';
import CheckboxValidator from "../../components/form/CheckboxValidator";
import DialogButton from "../../components/form/DialogButton";
import FormValidator from "../../components/form/FormValidator";
import ProgressButton from "../../components/form/ProgressButton";
import TextFieldValidator from "../../components/form/TextFieldValidator";
import Logger from "../../components/Logger";
import Notify from "../../components/notify/Notify";
import MarginRow from '../../components/page/MarginRow';
import Page from "../../components/page/Page";
import Tracking from "../../components/Tracking";
import User from "../../model/User";
import { AccountStoreConstants } from "../../stores/AccountStore";
import ProfileStore from '../../stores/ProfileStore';
import {createUUID, getErrorMessage, phoneToNationalFormat} from '../../stores/StoreUtilities';
import { CognitoAttribute, UserStoreConstants } from "../../stores/UserStore";
import ApplyPromo from "./ApplyPromo";
import {isWidthUp} from "@material-ui/core/withWidth/withWidth";
import Visible from "../../components/Visible";
import ResourceCache, {LocationOption} from "../../stores/ResourceCache";
import PlaceFilter from "../../components/filter/PlaceFilter";
import IndustrySelector from "../../components/form/IndustrySelector";

const styles = (theme: Theme) => createStyles({
  container: {
    fontFamily: theme.typography.body2.fontFamily,
    paddingTop: theme.spacing(2)
  },
  leftPanel: {
    [theme.breakpoints.up('md')]: {
      paddingRight: theme.spacing(2),
      paddingBottom: '25px'
    },
    [theme.breakpoints.down('md')]: {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      paddingBottom: '10px'
    },
  },
  rightPanel: {
    [theme.breakpoints.up('md')]: {
      paddingBottom: '25px',
    },
    [theme.breakpoints.down('md')]: {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      paddingBottom: '20px'
    },
    [theme.breakpoints.down('xs')]: {
      paddingBottom: 10,
    },

  },
  signUpContainer: {
    [theme.breakpoints.down('xs')]: {
      padding: 10,
    },
    [theme.breakpoints.up('sm')]: {
      padding: "30px 30px 0px 30px",
    },
  },
  submitButtonContainer: {
    paddingTop: theme.spacing(2),
  },
  submitButton: {
    color: "#fff",
  },
  formTitle: {
    [theme.breakpoints.down('xs')]: {
      marginTop: 0,
      marginBottom: 0,
      paddingTop: 0
    },
    [theme.breakpoints.up('sm')]: {
      marginBottom: 0
    }
  },
  formSubTitle: {
    color: '#181818',
    letterSpacing: '0.75px',
    fontWeight: 400,
    [theme.breakpoints.down('xs')]: {
      display: "none"
    },
  },
  form: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    [theme.breakpoints.down('md')]: {
      marginBottom: "50px"
    },
    [theme.breakpoints.down('xs')]: {
      marginBottom: "0px",
    },
  },
  formGroupTerms: {
    display: "flex",
    justifyContent: "flex-start",
    flexWrap: "nowrap",
    margin: 0,
    padding: 0,
    maxHeight: 40,
  },
  termsCheckbox: {
    flex: 0,
    width: 80,
  },
  termsLabel: {
    flex: 1,
    alignSelf: "center",
    color: '#252525',
    fontSize: '13px',
    paddingTop: 0,
    lineHeight: 1.2,
  },
  firstName: {
    [theme.breakpoints.up('sm')]: {
      paddingRight: theme.spacing(2)
    }
  },
  formGroupRow: {
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "nowrap"
  },
  formGroupField: {
    flexGrow: 1,
  },
  formGroupSpacing: {
    flexGrow: 0,
    width: 10,
  },
  stateSelectorField: {
    top: 5
  },
  zipCodeField: {
    width: 100
  },
  countryField: {
    width: 100
  },
  link: {
    color: theme.palette.primary.main,
  },
  continueContainer: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  continueButton: {
    marginBottom: 20,
    color: "#FFF"
  },
  progressButton: {
    color: theme.palette.primary.main,
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  instructions: {
    marginTop: 10
  },
  stepContent: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    width: "100%"
  },
  terms: {
    height: "calc(50vh)",
    width: "100%",
    marginBottom: theme.spacing(1)
  },
  iframe: {
    width: "100%",
    height: "100%",
    border: "none",
    overflowY: "scroll"
  }

});


interface IApplyPageProps {
  promo?: string
  userStore?: any
  accountStore?: any
  profileStore?: ProfileStore
  govGigAPI?: any
  notify?: Notify
  confirm?: Confirm
  location?: any
  resourceCache?: ResourceCache
  renderAsDialog?: boolean
  onApply?: any
  onClose?: any
}

interface ICognitoError {
  __type: string;
  code: string;
  message: string;
}

enum ApplyStep {
  Promo,
  Application,
  TermsOfUse,
  PrivacyPolicy,
  Verification
}

@inject("userStore", "accountStore", "profileStore", "govGigAPI", "notify", "confirm", "resourceCache")
@observer
class ApplyPage extends React.Component<WithStyles<typeof styles> & RouteComponentProps & IApplyPageProps & WithWidth, {}> {
  private userId = "";

  @observable step = ApplyStep.Application
  @observable isContinued = false
  @observable showTerms = false
  @observable showPrivacy = false
  @observable isSubmitted = false
  @observable forgotPassword = false
  @observable showTermsOfUse = false
  @observable isProcessing = false
  @observable verifyAttr = CognitoAttribute.EMAIL
  @observable locationOption?: LocationOption

  private cognitoUser?: any
  private user?: User
  private disableTextVerification = true

  
  constructor(
    props: WithStyles<typeof styles> & RouteComponentProps & IApplyPageProps & WithWidth
  ) {
    super(props);
    makeObservable(this);
  }
    
  private _message = ""
  set message(msg: string) {
    this._message = msg
    if (msg !== "") {
      this.props.notify!.show("info", msg)
    } else {
      this.props.notify!.close()
    }
  }
  get message() {
    return this._message
  }

  private _error = ""
  set error(err: string) {
    this._error = err
    if (err !== "") {
      this.props.notify!.show("error", err)
    } else {
      this.props.notify!.close()
    }
  }
  get error() {
    return this._error
  }

  state = {
    user: User,
    termsCheckbox: false,
    privacyCheckbox: false,
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    phone: "",
    addressLine1: "",
    addressCity: "",
    addressState: "",
    addressPostalCode: "",
    addressCountry: "",
    industries: [] as string[],
    addressError: false,
    verificationCode: "",
    accountId: AccountStoreConstants.PRIMARY_ACCOUNT_ID,
    role: UserRole.Applicant,
    communicationCheckbox: false
  }

  render() {
    const { renderAsDialog } = this.props
    if (renderAsDialog) {
      return this.renderAsDialog()
    } else {
      return this.renderAsPage()
    }
  }

  renderAsPage() {
    const { classes, width } = this.props;

    let promoContent = <ApplyPromo onJoin={this.handleContinue} />

    return (
      <Page title="Apply">
        <MarginRow>
          <Grid
            className={classes.container}
            container
            direction="row"
            justifyContent="center"
            alignItems="stretch"
          >
            <Hidden xsDown>
              <Visible if={this.step === ApplyStep.Application || isWidthUp('lg', width)}>
                <Grid item lg={6} className={classes.leftPanel}>
                  {promoContent}
                </Grid>
              </Visible>
              <Grid item xs={12} lg={6} className={classes.rightPanel}>
                <Paper elevation={3} className={classes.signUpContainer}>
                  <Grid container justifyContent="center">
                    <Grid item xs={12}>
                      <Typography align="center" variant="h3" color="primary" className={classes.formTitle}>
                        Apply Now
                      </Typography>
                      <Typography
                        align="center"
                        gutterBottom={true}
                        variant="subtitle1"
                        className={classes.formSubTitle}>
                        to get hired
                      </Typography>
                    </Grid>
                    {this.step === ApplyStep.Application && (
                      <Grid item xs={12} sm={12}>
                        {this.renderSignupForm()}
                      </Grid>
                    )
                    }
                    {this.step === ApplyStep.TermsOfUse && this.renderTermsOfUse()}
                    {this.step === ApplyStep.PrivacyPolicy && this.renderPrivacyPolicy()}
                    {this.step === ApplyStep.Verification && this.renderVerifyForm()}
                  </Grid>
                </Paper>
              </Grid>
            </Hidden>
            <Hidden smUp>
              {!this.isContinued &&
                <React.Fragment>
                  <Grid item xs={12} className={classes.leftPanel}>
                    {promoContent}
                  </Grid>
                  <Grid item xs={12} className={classes.continueContainer}>
                    <Button variant="contained" color="primary" size="large" fullWidth
                      className={classes.continueButton} onClick={this.handleContinue}>
                      Continue
                  </Button>
                  </Grid>
                </React.Fragment>
              }
              {this.isContinued &&
                <React.Fragment>
                  <Grid item xs={12} md={6} className={classes.rightPanel}>
                    <Paper elevation={3} className={classes.signUpContainer}>
                      <Grid container justifyContent="center">
                        <Grid item xs={12}>
                          <Typography align="center" variant="h3" color="primary" className={classes.formTitle}>
                            Apply Now
                        </Typography>
                          <Typography
                            align="center"
                            gutterBottom={true}
                            variant="subtitle1"
                            className={classes.formSubTitle}>
                            to get hired
                        </Typography>
                        </Grid>
                        {this.step === ApplyStep.Application && (
                          <div>
                            {this.renderSignupForm()}
                          </div>
                        )
                        }
                        {this.step === ApplyStep.TermsOfUse && this.renderTermsOfUse()}
                        {this.step === ApplyStep.PrivacyPolicy && this.renderPrivacyPolicy()}
                        {this.step === ApplyStep.Verification && this.renderVerifyForm()}
                      </Grid>
                    </Paper>
                  </Grid>
                </React.Fragment>
              }
            </Hidden>
          </Grid>
        </MarginRow>
      </Page>
    )
  }

  renderAsDialog = () => {
    return (
      <Dialog
        open={true}
        onClose={this.handleClose}
        fullWidth
        maxWidth="sm"
        scroll="paper"
      >
        <DialogTitle id="form-dialog-title">Sign up for a GovGig Account</DialogTitle>
        <DialogContent dividers>
          {this.step === ApplyStep.Application && this.renderSignupForm() }
          {this.step === ApplyStep.TermsOfUse && this.renderTermsOfUse()}
          {this.step === ApplyStep.PrivacyPolicy && this.renderPrivacyPolicy()}
          {this.step === ApplyStep.Verification && this.renderVerifyForm()}
        </DialogContent>
      </Dialog>
    )
  }

  renderSignupForm = () => {
    const { classes } = this.props;

    return <div>
      <Grid item xs={12} sm={12}>
        <FormValidator 
          id="applicantSignUpForm"
          name="applicantSignUpForm"
          className={classes.form} 
          onSubmit={this.handleSignUp}
          autoComplete="on"
        >
          <FormGroup row classes={{ row: classes.formGroupRow }}>
            <TextFieldValidator
              margin="normal"
              name="firstName"
              label="First Name"
              type="text"
              value={this.state.firstName}
              validators={{ required: true }}
              onChange={this.changeHandler}
              className={classes.formGroupField}
            />
            <div className={classes.formGroupSpacing} />
            <TextFieldValidator
              margin="normal"
              name="lastName"
              label="Last Name"
              type="text"
              value={this.state.lastName}
              validators={{ required: true }}
              onChange={this.changeHandler}
              className={classes.formGroupField}
            />
          </FormGroup>
          <FormGroup row classes={{ row: classes.formGroupRow }}>
            <TextFieldValidator
              margin="normal"
              name="email"
              label="Email Address"
              type="email"
              validators={{ required: true, isEmail: null }}
              value={this.state.email}
              onChange={this.changeHandler}
              className={classes.formGroupField}
            />
            <div className={classes.formGroupSpacing} />
            <TextFieldValidator
              margin="normal"
              name="password"
              label="Password"
              type="password"
              validators={{ required: true, isStrongPassword: 3 }}
              value={this.state.password}
              autoComplete="new-password"
              onChange={this.changeHandler}
              helperText="8+ chars + 1 digit or symbol"
              className={classes.formGroupField}
            />
          </FormGroup>
          <TextFieldValidator
            margin="dense"
            name="phone"
            label="Mobile Phone"
            helperText="Numbers only"
            type="text"
            validators={{ required: true, isMobilePhone: null }}
            onChange={this.changeHandler}
            fullWidth
            value={this.state.phone}
          />
          {/*<TextFieldValidator*/}
          {/*  margin="dense"*/}
          {/*  name="addressLine1"*/}
          {/*  label="Street Address"*/}
          {/*  type="text"*/}
          {/*  validators={{ required: true }}*/}
          {/*  onChange={this.changeHandler}*/}
          {/*  fullWidth*/}
          {/*  value={this.state.addressLine1}*/}
          {/*/>*/}
          <PlaceFilter
            variant="standard"
            value={this.locationOption}
            error={this.state.addressError}
            required
            label="City, State, Country (or ZIP/Postal Code)"
            placeholder="Search for city, state, country (or ZIP/Postal Code)"
            helperText="Enter the city, ZIP, state, country where you live or are looking to work. You can add all locations you are willing to work when you set up your profile."
            onSelectLocation={this.handleSelectLocation}
          />
          <Box py={2}>
            <IndustrySelector value={this.state.industries} onChange={this.handleChangeIndustries} required/>
          </Box>
          {/*<FormGroup row classes={{ row: classes.formGroupRow }}>*/}
          {/*  <TextFieldValidator*/}
          {/*    margin="dense"*/}
          {/*    name="addressCity"*/}
          {/*    label="City"*/}
          {/*    type="text"*/}
          {/*    validators={{ required: true }}*/}
          {/*    onChange={this.changeHandler}*/}
          {/*    value={this.state.addressCity}*/}
          {/*    className={classes.formGroupField}*/}
          {/*  />*/}
          {/*  <div className={classes.formGroupSpacing} />*/}
          {/*  <StateSelector*/}
          {/*    value={this.state.addressState}*/}
          {/*    onChange={this.changeHandler}*/}
          {/*    className={classes.stateSelectorField}*/}
          {/*  />*/}
          {/*  <div className={classes.formGroupSpacing} />*/}
          {/*  <TextFieldValidator*/}
          {/*    margin="dense"*/}
          {/*    name="addressPostalCode"*/}
          {/*    label="ZIP"*/}
          {/*    type="text"*/}
          {/*    validators={{ required: true, isPostalCode: null }}*/}
          {/*    onChange={this.changeHandler}*/}
          {/*    value={this.state.addressPostalCode}*/}
          {/*    className={classes.zipCodeField}*/}
          {/*  />*/}
          {/*  <div className={classes.formGroupSpacing} />*/}
          {/*  <TextFieldValidator*/}
          {/*    margin="dense"*/}
          {/*    name="addressCountry"*/}
          {/*    label="Country"*/}
          {/*    type="text"*/}
          {/*    validators={{ required: true }}*/}
          {/*    onChange={this.changeHandler}*/}
          {/*    value={this.state.addressCountry}*/}
          {/*    className={classes.countryField}*/}
          {/*  />*/}
          {/*</FormGroup>*/}
          {this.renderTermsCheckbox("communicationCheckbox", "I give permission to communicate with me via email, text or phone.", true)}
          <div className={classes.submitButtonContainer}>
            <ProgressButton fullWidth={true} variant="contained" size="large" color="secondary"
              type="submit" className={classes.submitButton} processing={this.isProcessing}>
              Continue
            </ProgressButton>
            <Button fullWidth variant="text" color="primary" type="button" onClick={this.handleCancel}>Cancel</Button>
          </div>
        </FormValidator>
      </Grid>
    </div>
  }

  renderVerifyForm() {
    const { classes } = this.props;

    return (
      <div className={classes.stepContent}>
        <FormValidator 
          id="applicantSignUpVerificationCodeForm"
          name="applicantSignUpVerificationCodeForm"
          onSubmit={this.handleVerify} 
          autoComplete="on"
        >
          <Typography variant="body2" paragraph={true} className={classes.instructions}>
            Please check your {this.verifyAttr === CognitoAttribute.EMAIL ? "email" : "phone"} for the verification code.
          </Typography>
          <TextFieldValidator
            margin="dense"
            name="verificationCode"
            label="Verification Code"
            type="text"
            value={this.state.verificationCode}
            validators={{ required: true, matches: "^\\d{6}$" }}
            onChange={this.changeHandler}
            fullWidth
          />
          {(this.verifyAttr === CognitoAttribute.EMAIL) &&
            <Box my={1}>
              <DialogButton variant="tertiary" onClick={this.onResendEmailCode}>
                Resend email to {this.state.email}
              </DialogButton>
            </Box>
          }
          {!this.disableTextVerification &&
            <DialogButton variant="tertiary" onClick={this.onSendTextCode}>
              {this.verifyAttr === CognitoAttribute.EMAIL ?
                `Verify by text message to ${phoneToNationalFormat(this.state.phone)}` :
                `Resend text message to ${phoneToNationalFormat(this.state.phone)}`
              }
            </DialogButton>
          }
          <div className={classes.submitButtonContainer}>
            <Button fullWidth={true} variant="contained" color="secondary"
              type="submit" style={{ color: "#fff" }} disabled={this.isProcessing}>
              Continue
              {this.isProcessing && <CircularProgress size={24} className={classes.progressButton} />}
            </Button>
            <Button fullWidth variant="text" color="primary" type="button" onClick={this.handleBack}>
              Back
            </Button>
          </div>
        </FormValidator>
      </div>)
  }

  renderTermsOfUse() {
    const { classes } = this.props

    return (
      <div className={classes.stepContent}>
        <FormValidator 
          id="applicantSignUpTermsOfUseForm"
          name="applicantSignUpTermsOfUseForm"
          onSubmit={this.handleTermsOfUse} 
          autoComplete="on"
        >
          <Typography variant="body2" paragraph={true} className={classes.instructions}>
            Please review and accept the GovGig Terms of Use.
          </Typography>
          <div className={classes.terms}>
            <iframe src={Routes.terms} title="Terms Of Service" className={classes.iframe}>
            </iframe>
          </div>
          {this.renderTermsCheckbox("termsCheckbox", "I accept the GovGig.us Terms of Use.", true)}
          <div className={classes.submitButtonContainer}>
            <Button fullWidth={true} variant="contained" color="secondary"
              type="submit" style={{ color: "#fff" }}>
              Continue
            </Button>
            <Button fullWidth variant="text" color="primary" type="button" onClick={this.handleCancel}>Cancel</Button>
          </div>
        </FormValidator>
      </div>
    )
  }

  renderPrivacyPolicy() {
    const { classes } = this.props

    return (
      <div className={classes.stepContent}>
        <FormValidator 
          id="applicantSignUpPrivacyPolicyForm"
          name="applicantSignUpPrivacyPolicyForm"
          onSubmit={this.handlePrivacyPolicy} 
          autoComplete="on"
        >
          <Typography variant="body2" paragraph={true} className={classes.instructions}>
            Please review and accept the GovGig Privacy Policy.
          </Typography>
          <div className={classes.terms}>
            <iframe src={Routes.privacy} title="Privacy Policy" className={classes.iframe}>
            </iframe>
          </div>
          {this.renderTermsCheckbox("privacyCheckbox", "I accept the GovGig.us Privacy Policy.", true)}
          <div className={classes.submitButtonContainer}>
            <ProgressButton fullWidth={true} variant="contained" size="large" color="secondary"
              type="submit" className={classes.submitButton} processing={this.isProcessing}>
              Continue
            </ProgressButton>
            <Button fullWidth variant="text" color="primary" type="button" onClick={this.handleCancel}>Cancel</Button>
          </div>
        </FormValidator>
      </div>
    )
  }

  renderTermsCheckbox = (name: string, label: any, required: boolean) => {
    const { classes } = this.props

    return (
      <FormGroup row classes={{ row: classes.formGroupTerms }}>
        <div className={classes.termsCheckbox}>
          <CheckboxValidator name={name} color="primary"
            value="checked" required={required}
            checked={this.state[name]}
            onChange={this.changeHandler} />
        </div>
        <div className={classes.termsLabel}>
          {label}
        </div>
      </FormGroup>
    )
  }

  scrollToTop() {
    window.scrollTo({top: 0, behavior: 'smooth'})
  }

  handleContinue = () => {
    this.isContinued = true
    this.scrollToTop()
  }

  handleSignUp = async () => {
    const values = this.state
    const { userStore } = this.props

    this.message = ""
    this.error = ""

    if (!values.communicationCheckbox) {
      this.message = "You must agree to the terms before applying."
      return
    }

    this.isProcessing = true

    this.userId = createUUID();

    // Check for an existing Cognito user by email
    this.cognitoUser = await Auth.signIn(values.email, values.password)
      .catch((err: any) => {
        if (err.code === UserStoreConstants.NOT_AUTHORIZED_EXCEPTION) {
          this.error = "A user with this email already exists. If you are attempting to signup again, be sure to use the same password."
        } else if (err.code !== UserStoreConstants.USER_NOT_FOUND_EXCEPTION && err.message !== UserStoreConstants.USER_NOT_FOUND) {
          this.error = err.message
          Tracking.event({ action: "SignIn Error", label: this.error })
        }
      })

    if (this.cognitoUser) {
      // The username/password already exists.  Verify that the application process was completed.
      const user = await userStore!.getUser(this.cognitoUser.username)
      if (user) {
        this.error = "You have already signed up.  Please login to use GovGig."
        Auth.signOut()
      } else {
        // Send to TermsOfService step
        this.userId = this.cognitoUser.username
        this.step = ApplyStep.TermsOfUse
        this.isProcessing = false
        return
      }
    }

    if (this.error) {
      this.isProcessing = false
      return
    }

    // Sign up and send email verification

    this.cognitoUser =
      await userStore!.signUp(this.userId, values.password, values.email, undefined, values.accountId, values.role)
        .catch((result: ICognitoError) => {
          Logger.debug(`ApplyPage.signUp(${this.userId}) error`, result)
          this.error = result.message
        });

    this.isProcessing = false

    if (!this.error) {
      this.isSubmitted = true
      this.step = ApplyStep.Verification
      this.scrollToTop()
    }

  }

  handleVerify = async () => {
    const values = this.state
    this.message = ""
    this.error = ""
    const userStore = this.props.userStore

    if (this.forgotPassword) {
      // Handle verification code for password reset
      userStore!.forgotPasswordSubmit(this.state.email, this.state.verificationCode, this.state.password)
        .then((result: any) => {
          Logger.debug("forgotPasswordSubmit succeeded")
        })
        .catch((err: any) => {
          this.error = err.message
          Tracking.event({ action: "Forgot Password Error", label: this.error })
        })
    } else if (this.cognitoUser) {
      // Handle verification code for signup
      this.isProcessing = true

      if (this.verifyAttr === CognitoAttribute.EMAIL) {
        await userStore!.confirmSignUp(this.userId, values.verificationCode, {forceAliasCreation: false})
          .catch((err: Error) => {
            this.error = getErrorMessage(err)
            this.isProcessing = false
          })
      } else {
        await userStore!.verifyUserAttributeSubmit(this.cognitoUser, this.verifyAttr, values.verificationCode)
          .catch((err: Error) => {
            this.error = getErrorMessage(err)
            this.isProcessing = false
          })
      }

      if (this.error) {
        return
      }
    }

    await userStore!.signIn(this.userId, this.state.password)
      .catch((error: any) => {
        this.isProcessing = false
        Logger.debug("ApplyPage verify signIn error", error)
        if (error.code === UserStoreConstants.NOT_AUTHORIZED_EXCEPTION && !this.forgotPassword) {
          // Password doesn't match original.  Reset password
          userStore!.forgotPassword(this.state.email).then((result: any) => {
            this.forgotPassword = true
            this.state.verificationCode = ""
            this.error = "Password does not match. Please check your email for a password reset verification code"
          })
            .catch((forgotPasswordErr: any) => {
              this.message = forgotPasswordErr.message
            })
        } else if (error.message !== UserStoreConstants.USER_NOT_FOUND) {
          this.error = error.message
        }
      });

    if (this.error) {
      return
    }

    this.step = ApplyStep.TermsOfUse
    this.scrollToTop()

    this.isProcessing = false
  }

  handleTermsOfUse = () => {
    this.step = ApplyStep.PrivacyPolicy
    this.scrollToTop()
  }

  handlePrivacyPolicy = async () => {
    await this.createUser()
  }

  handleCancel = () => {
    if (this.props.renderAsDialog && this.props.onClose) {
      this.props.onClose()
    } else {
      ControlTower.route(Routes.home)
    }
  }

  handleBack = () => {
    // Return to signup form
    this.step = ApplyStep.Application
  }

  handleClose = () => {
    if (this.props.onClose) {
      this.props.onClose()
    }
  }

  changeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const name = event.target.name
    if (name === "communicationCheckbox") {
      this.setState({
        [name]: event.target.checked
      })
    } else if (name === "state") {
      this.setState({ addressState: event.target.value })
    } else if (name === "email") {
      this.setState({
        [name]: event.target.value.toLowerCase()
      });
    } else {
      if (this.state[name] !== undefined) {
        this.setState({
          [name]: event.target.value
        });
      }
    }
    // Clear message
    this.message = ""
    this.error = ""
  };

  handleSelectLocation = async (value?: LocationOption) => {
    const { resourceCache } = this.props
    const location = value ? resourceCache?.getLocation(value!.id) : undefined
    if (location && location.city && location.state && location.country) {
      this.setState({
        addressLine1: location.address,
        addressCity: location.city,
        addressState: resourceCache!.getStateAbbrFromName(location.state),
        addressPostalCode: location.postalCode,
        addressCountry: resourceCache!.getCountryAbbrFromName(location.country),
        addressError: false
      })
    } else {
      this.setState({
        addressLine1: "",
        addressCity: "",
        addressState: "",
        addressPostalCode: "",
        addressCountry: "",
        addressError: true
      })
    }

    this.locationOption = value
  }

  handleChangeIndustries = (industries: string[]) => {
    this.setState({industries: industries})
  }

  onResendEmailCode = async () => {
    const { userStore } = this.props
    this.message = ""
    this.error = ""
    const values = this.state

    if (this.verifyAttr === CognitoAttribute.EMAIL) {
      userStore!.resendSignUp(this.userId)
        .then((result: any) => {
          this.message = "Verification email requested"
        })
        .catch((err: Error) => {
          this.error = err.message
        })
    } else {
      // Create new sign-up without phone number to trigger email verification
      this.cognitoUser =
        await userStore!.signUp(this.userId, values.password, values.email, undefined, values.accountId, values.role)
          .then((result: any) => {
            this.message = "Verification email requested"
            this.verifyAttr = CognitoAttribute.EMAIL
          })
          .catch((result: ICognitoError) => {
            Logger.debug(`ApplyPage.signUp(${this.userId}) error`, result)
            this.error = result.message
          });
    }
  }

  onSendTextCode = async () => {
    const { userStore } = this.props
    const values = this.state

    if (this.verifyAttr === CognitoAttribute.PHONE_NUMBER) {
      Auth.signIn(this.userId, values.password)
        .then(async (congnitoUser: any) => {
          this.cognitoUser = congnitoUser
          await userStore!.verifyUserAttribute(this.cognitoUser, CognitoAttribute.PHONE_NUMBER)
            .then((verify: any) => {
              this.message = "Verification text requested"
              Logger.debug(`ApplyPage verifyUserAttribute(PHONE_NUMBER) = ${verify}`)
            })
            .catch((result: ICognitoError) => {
              Logger.debug(`ApplyPage.verify phone number error`, result)
              this.error = result.message
            });
        })
    } else {
      // Create new sign-up to trigger phone verification
      // Note: For phone verification, email is automatically verified in PreSignupLambda
      this.userId = createUUID();
      await userStore!.signUp(this.userId, values.password, values.email, values.phone, values.accountId, values.role)
        .then(async (result: any) => {
          this.verifyAttr = CognitoAttribute.PHONE_NUMBER
          // Call onSendTextCode again to send the verification text
          await this.onSendTextCode()
        })
        .catch((result: ICognitoError) => {
          Logger.debug(`ApplyPage.signUp(${this.userId}) error`, result)
          this.error = result.message
          Auth.signOut()
        });
    }
  }

  createUser = async () => {
    const { userStore, accountStore, notify } = this.props

    this.isProcessing = true

    const input: CreateUserInput = {
      id: this.userId,
      accountId: this.state.accountId,
      active: true,
      userStatus: UserStatus.Registered,
      email: this.state.email,
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      phone: this.state.phone,
      role: this.state.role,
      address: this.state.addressLine1 ?? null,
      city: this.state.addressCity,
      state: this.state.addressState,
      postalCode: this.state.addressPostalCode,
      country: this.state.addressCountry ?? null,
      industry: this.state.industries.length === 1 ? this.state.industries[0] : null
    }

    Logger.debug("ApplyPage.createUser", input)
    const user = await userStore!.createUser(input)

    if (!user) {
      this.error = "Error creating user"
      this.isProcessing = false
      return
    }

    accountStore!.init(user.account)

    const profile = await this.createProfile()

    if (!profile) {
      this.error = "Error creating profile"
      this.isProcessing = false
      return
    }

    // Record Agreements
    const agreementInput: CreateAgreementInput = {
      userId: user.id,
      accountId: user.accountId,
      agreementTypes: [
        AgreementType.ElectronicCommunication,
        AgreementType.PrivacyPolicy,
        AgreementType.TermsOfUse
      ]
    }

    const agreement = await userStore!.createAgreement(agreementInput)

    if (!agreement) {
      this.error = "Error saving agreement"
      this.isProcessing = false
      return
    }

    // Force industry update
    userStore!.getIndustry()

    this.isProcessing = false

    if (this.props.renderAsDialog && this.props.onApply) {
      this.props.onApply()
    } else {
      notify!.show("success", "Welcome to GovGig!")
      ControlTower.route(Routes.profile)
    }
  }

  createProfile = async () => {
    const { userStore, profileStore } = this.props
    const user = userStore!.user

    if (!user) {
      return
    }

    const input: CreateProfileInput = {
      active: true,
      userId: user.id,
      nickname: user.nickname,
      profileStatus: ProfileStatus.Pending,
      alias: profileStore!.generateAlias(user),
      industries: this.state.industries
    }

    const profile = await userStore!.createProfile(input)
      .catch((error: any) => {
        Logger.debug("ApplyPage.createProfile error", getErrorMessage(error))
        this.error = getErrorMessage(error)
      });

    // Add ProfileLocation
    if (this.locationOption) {
      const profileLocationInput: CreateProfileLocationInput = {
        userId: user.id,
        profileId: profile.id,
        locationId: this.locationOption.id
      }

      await profileStore!.createProfileLocation(profileLocationInput)
    }

    return profile
  }
}

export default withWidth()(withStyles(styles)(ApplyPage));
