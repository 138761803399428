import {
  Box,
  Checkbox,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  Grid,
  isWidthDown,
  TextareaAutosize,
  Theme,
  Tooltip,
  Typography,
  WithStyles,
  WithTheme,
  withWidth,
  WithWidth
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";
import {differenceInDays, parse} from "date-fns";
import endOfMonth from 'date-fns/endOfMonth';
import {makeObservable, observable} from "mobx";
import {inject, observer} from "mobx-react";
import React from "react";
import {AgencyType, CreateExperienceInput, UpdateExperienceInput, UpdateProfileInput} from "../../API";
import AddButton from "../../components/AddButton";
import CardValue from "../../components/CardValue";
import IconicButton from "../../components/controls/IconicButton";
import DatePickerValidator from "../../components/form/DatePickerValidator";
import FormGroupSpacer from "../../components/form/FormGroupSpacer";
import FormValidator from "../../components/form/FormValidator";
import ProgressButton from "../../components/form/ProgressButton";
import TextFieldValidator from "../../components/form/TextFieldValidator";
import Notify from "../../components/notify/Notify";
import TextBlock from "../../components/TextBlock";
import Visible from "../../components/Visible";
import Experience, {FederalAgency} from "../../model/Experience";
import Profile from "../../model/Profile";
import ProfileStore from "../../stores/ProfileStore";
import {
  DateRange,
  getErrorMessage,
  isoToLocalDateString, mergeOverlappingDateRanges,
  moneyToNumberFormat,
  numberToMoneyFormat
} from "../../stores/StoreUtilities";
import UserStore from "../../stores/UserStore";
import ProfileCard from "./ProfileCard";
import {grey} from "@material-ui/core/colors";
import ResourceCache from "../../stores/ResourceCache";
import CancelButton from "../../components/form/CancelButton";
import {withStyles, withTheme} from "@material-ui/core/styles";
import AgencyDialog from "./AgencyDialog.";
import {NAICS} from "../../model/Industry";

const styles = (theme: Theme) => createStyles({
  container: {
    flex: 1
  },
  experienceContainer: {
  },
  editButton: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "right",
    width: 36,
    position: "relative",
    top: 0,
    right: -12
  },
  form: {
    overflowY: "auto",
    display: "flex",
    flexDirection: "column"
  },
  formGroupRow: {
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "nowrap"
  },
  formGroupField: {
    flexGrow: 1,
  },
  progressButton: {
    minWidth: 80
  },
  jobTitle: {
    fontFamily: [
      'Montserrat',
      'sans-serif'
    ].join(','),
    fontWeight: 800,
    fontSize: 20,
    color: theme.palette.text.secondary
  },
  fedJobTitle: {
    fontFamily: [
      'Montserrat',
      'sans-serif'
    ].join(','),
    fontWeight: 800,
    fontSize: 20,
    color: theme.palette.secondary.dark
  },
  fedValue: {
    color: theme.palette.secondary.dark
  },
  fedCheckbox: {
    color: theme.palette.secondary.dark,
    paddingRight: 4,
    paddingTop: 0,
    paddingBottom: 0
  },
  optionalFields: {
    backgroundColor: theme.palette.background.default,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(3)
  },
  fedTotal: {
    fontSize: 18,
    color: theme.palette.secondary.dark
  },
  allTotal: {
    fontSize: 18,
    color: theme.palette.text.secondary
  },
  label: {
    color: theme.palette.text.secondary,
    fontSize: 12,
    marginTop: theme.spacing(1),
    marginBottom: 4,
    marginLeft: 0,
  },
  textarea: {
    fontFamily: [
      'Roboto',
      'sans-serif'
    ].join(','),
    minWidth: "100%",
    maxWidth: "100%",
    padding: 8,
    fontSize: 16
  },
  publicNotice: {
    marginTop: theme.spacing(1),
    padding: theme.spacing(1),
    backgroundColor: grey[500],
    color: theme.palette.primary.contrastText,
    borderRadius: 10,
    textAlign: "center"
  }
})

export interface IProfileWorkExperienceProps {
  profile?: Profile
  isOpen?: boolean
  onChange?: (profile: Profile) => any
  onClose?: any
  profileStore?: ProfileStore
  resourceCache?: ResourceCache
  userStore?: UserStore
  notify?: Notify
}

class ProfileWorkExperienceViewModel {
  @observable id: string
  @observable jobTitle: string
  @observable jobDescription: string
  @observable agencyType: AgencyType
  @observable agencyName: string
  @observable employerName: string
  @observable employerLocation: string
  @observable startDate: string | null
  @observable endDate: string | null
  @observable projectNo: string
  @observable projectName: string
  @observable projectDollarAmount: string
  @observable projectLocation: string
  @observable isProject: boolean

  constructor (e: Experience) {
    makeObservable(this);
    this.id = e.id
    this.jobTitle = e.jobTitle
    this.jobDescription = e.jobDescription
    this.agencyType = e.agencyType
    this.agencyName = e.agencyName
    this.projectLocation = e.projectLocation
    this.employerName = e.employerName
    this.employerLocation = e.employerLocation
    this.startDate = (e.startDate) ? isoToLocalDateString(e.startDate, "MM/yyyy") : null
    this.endDate = (e.endDate) ? isoToLocalDateString(e.endDate, "MM/yyyy") : null
    this.projectNo = e.projectNo
    this.projectName = e.projectName
    this.projectDollarAmount = numberToMoneyFormat(e.projectDollarAmount, 0)
    this.isProject = e.agencyType !== null && e.agencyType !== undefined && e.agencyType !== AgencyType.None
  }
}

@inject("userStore", "profileStore", "resourceCache", "notify")
@observer
class ProfileWorkExperience extends React.Component<WithStyles<typeof styles> & WithTheme & WithWidth & IProfileWorkExperienceProps> {
  @observable experiences: Experience[] = []
  @observable viewModel?: ProfileWorkExperienceViewModel
  @observable isFormOpen: boolean = false
  @observable isProcessing = false
  @observable fedYears = 0
  @observable totalYears = 0
  @observable agencyDialog: any
  @observable isFederalChecked: boolean = false
  @observable isOtherChecked: boolean = false
  // @observable employerOptions: any

  constructor(
    props: WithStyles<typeof styles> & WithTheme & WithWidth & IProfileWorkExperienceProps
  ) {
    super(props);
    makeObservable(this);
  }

  componentDidMount() {
    this.loadExperiences()
  }

  componentDidUpdate(prevProps: any) {
    if (prevProps.profile !== this.props.profile) {
      this.componentDidMount()
    }
    if (prevProps.isOpen !== this.props.isOpen) {
      if (this.props.isOpen) {
        this.handleAdd()
      } else {
        this.isFormOpen = false
      }
    }
  }

  render() {
    const { onChange, profile, width } = this.props

    let addButton
    if (onChange) {
      addButton =
        <IconicButton onClick={this.handleAdd}>
          <AddIcon />
        </IconicButton>
    }

    if (!profile) {
      return null
    }

    return (
      <ProfileCard
      title="Work Experience"
      button={addButton}>
        <Grid
          container
          direction={isWidthDown('xs', width) ? "column" : "row"}
          justifyContent="flex-start"
          alignItems="flex-start"
          spacing={3}
        >
          {profile &&
            <Grid item xs={12}>
              {this.renderStaticFields()}
            </Grid>
          }
        </Grid>
        {this.isFormOpen && this.renderForm()}

      </ProfileCard>
    )
  }

  renderStaticFields() {
    const {width, classes, onChange, userStore, profile} = this.props

    // NOTE: The profile is used here rather than the viewModel because we don't want the
    // form changes to affect the static fields until the user presses Save

    const isAuthenticated = userStore!.isAuthenticated
    const isXS = isWidthDown('xs', width)
    const instructions = this.experiences.length === 0 && onChange ? "Add government and other related experience for the last 5 or more years." : ""
    const isConstruction = profile!.industries.includes(NAICS.Construction)
    const labelWidth = 150 

    return (
      <div>
        <Grid
          container
          spacing={isXS ? 2 : 0}
        >
          {instructions &&
          <Grid item xs={12}>
            <Typography gutterBottom>
              {instructions}
            </Typography>
          </Grid>
          }
          {this.totalYears > 0 && (
            <Grid item xs={12}>
              <Grid className={classes.fedTotal} component={isXS ? 'div' : 'span'}>
                Federal Experience: { `${Math.round(this.fedYears * 100) / 100} years` }
              </Grid>
              {!isXS && <span>&nbsp;&nbsp;/&nbsp;&nbsp;</span>}
              <Grid className={classes.allTotal} component={isXS ? 'div' : 'span'}>
                Total Experience: { `${Math.round(this.totalYears * 100) / 100} years` }
              </Grid>
            </Grid>
          )}
          {isAuthenticated &&
            <Grid item xs={12}>
              {this.experiences.map((e: Experience) => (
                <Grid container direction="row" justifyContent="flex-start"
                      className={classes.experienceContainer} key={e.id} id={e.id}>
                  {onChange &&
                  <Grid item container justifyContent="flex-end" style={{position: "relative", top: 36, right: 0}}>
                    <IconicButton onClick={() => {
                      this.handleEdit(e)
                    }}>
                      <EditIcon/>
                    </IconicButton>
                  </Grid>
                  }
                  {!onChange &&
                  <Grid container>&nbsp;</Grid>
                  }
                  <Grid item xs={12} sm={12}>
                    <Typography className={e.agencyType === AgencyType.Federal ? classes.fedJobTitle : classes.jobTitle }>
                      {e.jobTitle}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} style={{ marginBottom: isXS ? '1rem' : undefined }}>
                    <CardValue label="Employer" wrapText={true} labelWidth={labelWidth}>
                      {e.employerName} {e.employerLocation ? ` (${e.employerLocation})` : ''}
                    </CardValue>
                    <CardValue label="When" labelWidth={labelWidth}>
                      {isoToLocalDateString(e.startDate, "MMM yyyy")} - {e.endDate ? isoToLocalDateString(e.endDate, "MMM yyyy") : "Present"}  ({this.renderDuration(e)})
                    </CardValue>
                    <Visible if={e.isProject}>
                      <CardValue label="Agency" labelWidth={labelWidth}>
                        <span className={e.agencyType === AgencyType.Federal ? classes.fedValue : undefined}>{e.agencyName}</span>
                      </CardValue>
                      {e.projectName &&
                        <CardValue label="Project Name" wrapText={true} labelWidth={labelWidth}>
                          <span className={e.agencyType === AgencyType.Federal ? classes.fedValue : undefined}>
                            {e.projectName}
                          </span>
                        </CardValue>
                      }
                      {e.projectLocation &&
                        <CardValue label="Project Location" wrapText={true} labelWidth={labelWidth}>
                          <span className={e.agencyType === AgencyType.Federal ? classes.fedValue : undefined}>
                            {e.projectLocation}
                          </span>
                        </CardValue>
                      }
                      {e.projectDollarAmount > 0 &&
                        <CardValue label="Project $ Amount" labelWidth={labelWidth}>
                          <span className={e.agencyType === AgencyType.Federal ? classes.fedValue : undefined}>
                            {e.projectDollarAmount > 0 ? numberToMoneyFormat(e.projectDollarAmount, 0) : ""}
                          </span>
                        </CardValue>
                      }
                    </Visible>
                    <Visible if={onChange !== undefined && !e.agencyType}>
                      <CardValue label="Agency" labelWidth={labelWidth}>
                        <Visible if={isConstruction === true}>
                          <Tooltip title="Naval Facilities Engineering Systems Command">
                            <FormControlLabel style={{marginRight: 8}}
                                              control={
                                                <Checkbox name={FederalAgency.NAVFAC} className={classes.fedCheckbox} value="checkedG"
                                                          onChange={(event: any) => this.handleFederalCheckbox(event, e)}/>
                                              }
                                              label={
                                                  <Typography className={classes.fedValue}>{FederalAgency.NAVFAC}</Typography>
                                              }
                            />
                          </Tooltip>
                          <Tooltip title="U.S. Army Corps of Engineers">
                            <FormControlLabel style={{marginRight: 8}}
                                              control={
                                                <Checkbox name={FederalAgency.USACE} className={classes.fedCheckbox} value="checkedG"
                                                          onChange={(event: any) => this.handleFederalCheckbox(event, e)}/>
                                              }
                                              label={
                                                <Typography className={classes.fedValue}>{FederalAgency.USACE}</Typography>
                                              }
                            />
                          </Tooltip>
                          <Tooltip title="Air Force Civil Engineer Center">
                            <FormControlLabel style={{marginRight: 8}}
                                              control={
                                                <Checkbox name={FederalAgency.AFCEC} className={classes.fedCheckbox} value="checkedG"
                                                          onChange={(event: any) => this.handleFederalCheckbox(event, e)}/>
                                              }
                                              label={
                                                <Typography className={classes.fedValue}>{FederalAgency.AFCEC}</Typography>
                                              }
                            />
                          </Tooltip><br/>
                        </Visible>
                        <Tooltip title="Federal Government Agency">
                          <FormControlLabel style={{marginRight: 8}}
                                            control={
                                              <Checkbox name="isFederal" className={classes.fedCheckbox} value="checkedG"
                                                        checked={this.isFederalChecked}
                                                        onChange={(event: any) => this.handleFederalCheckbox(event, e)}/>
                                            }
                                            label={
                                              <Typography className={classes.fedValue}>Federal</Typography>
                                            }
                          />
                        </Tooltip>
                        <Tooltip title="Other Government Agency">
                          <FormControlLabel style={{marginRight: 8}}
                                            control={
                                              <Checkbox name="isOther" className={classes.fedCheckbox} value="checkedG"
                                                        checked={this.isOtherChecked}
                                                        onChange={(event: any) => this.handleFederalCheckbox(event, e)}/>
                                            }
                                            label={
                                              <Typography className={classes.fedValue}>Other</Typography>
                                            }
                          />
                        </Tooltip>
                        <Tooltip title="No Government Agency">
                          <FormControlLabel style={{marginRight: 0}}
                                            control={
                                              <Checkbox name="isNone" className={classes.fedCheckbox} value="checkedG"
                                                        onChange={(event: any) => this.handleFederalCheckbox(event, e)}/>
                                            }
                                            label={
                                              <Typography className={classes.fedValue}>None</Typography>
                                            }
                          />
                        </Tooltip>
                      </CardValue>
                    </Visible>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography variant="body2" component="div">
                      <TextBlock>{e.jobDescription}</TextBlock>
                    </Typography>
                  </Grid>
                </Grid>
              ))}
            </Grid>
          }
          {!isAuthenticated && this.experiences.length > 0 &&
            <Grid item xs={12} className={classes.publicNotice}>
              <Typography variant="body1">
                {this.experiences.length} items available when you join or login...
              </Typography>
            </Grid>
          }
        </Grid>
        {this.agencyDialog}
      </div>
    )
  }

  renderDuration(experience: Experience) {
    let result
    const years = experience.years

    if (years < (11.5/12)) {
      result = `${Math.round(years * 12)} mo`
    } else if (years < (12.5/12)) {
      result = "1 yr"
    } else {
      result = `${Math.round(years * 100) / 100} yr`
    }

    return result
  }

  handleFederalCheckbox = async (event: any, e: Experience) => {
    const name = event.target.name
    let update

    if (name === FederalAgency.NAVFAC) {
      update = {id: e.id, agencyType: AgencyType.Federal, agencyName: name}
    } else if (name === FederalAgency.USACE) {
      update = {id: e.id, agencyType: AgencyType.Federal, agencyName: name}
    } else if (name === FederalAgency.AFCEC) {
      update = {id: e.id, agencyType: AgencyType.Federal, agencyName: name}
    } else if (name === "isFederal") {
      this.agencyDialog = <AgencyDialog experience={e} agencyType={AgencyType.Federal}
                                        onChange={this.onChangeAgency}
                                        onClose={() => {
                                          this.isFederalChecked = false
                                          this.agencyDialog = undefined
                                        }}
      />
    } else if (name === "isOther") {
      this.agencyDialog = <AgencyDialog experience={e}
                                        onChange={this.onChangeAgency}
                                        onClose={() => {
                                          this.isOtherChecked = false
                                          this.agencyDialog = undefined
                                        }}
      />
    } else if (name === "isNone") {
      update = {id: e.id, agencyType: AgencyType.None}
    }

    if (update) {
      this.updateExperience(update)
    }
  }

  updateExperience = async (input: UpdateExperienceInput) => {
    const { profileStore, notify } = this.props

    const exp = await profileStore!.updateExperience(input)
      .catch((_err: Error) => {
        notify!.show("error", "Unable to set federal experience")
      })

    if (exp) {
      this.onChangeAgency(exp)
    }
  }

  onChangeAgency = async (experience: Experience) => {
    const {profile, onChange} = this.props

    this.agencyDialog = undefined
    this.isProcessing = true

    const index = profile!.experiences.findIndex((e: Experience) => e.id === experience.id)
    if (index >= 0) {
      profile!.experiences[index] = experience
    }
    this.loadExperiences()
    const update = await this.updateProfile()
    this.isProcessing = false
    if (onChange) {
      if (update) {
        onChange(update)
      } else {
        onChange(profile!)
      }
    }
  }

  renderForm() {
    const { classes } = this.props
    const vm = this.viewModel!
    // let maxDate = new Date()
    // const minDate = addYears(maxDate, -75)
    let today = new Date()
    let maxStartDate = today
    let minStartDate: Date | undefined
    if (vm.endDate) {
      const endDate = this.getDateFromMonthYearString(vm.endDate, true)
      if (endDate && !isNaN(endDate.getTime())) {
        maxStartDate = endDate
      }
    }
    let maxEndDate = today
    let minEndDate: Date | undefined
    if (vm.startDate) {
      const startDate = this.getDateFromMonthYearString(vm.startDate)
      if (startDate && !isNaN(startDate.getTime())) {
        minEndDate = startDate
      }
    }
    console.log(`StartDate: ${minStartDate ? minStartDate.toLocaleDateString() : "any"} - ${maxStartDate.toLocaleDateString()}`)
    console.log(`EndDate: ${minEndDate ? minEndDate.toLocaleDateString() : "any"} - ${maxEndDate.toLocaleDateString()}`)

    return (
      <Dialog
        open={true}
        onClose={this.handleClose}
        fullWidth
        maxWidth="sm"
        scroll="paper"
      >
        <FormValidator onSubmit={this.onSubmit} autoComplete="off" name="editForm" id="editForm" className={classes.form}>
          <DialogTitle id="form-dialog-title">Work Experience</DialogTitle>
          <DialogContent dividers>
            <TextFieldValidator
              type="text"
              validators={{required: true}}
              required
              name="jobTitle"
              label="Job Title"
              value={vm.jobTitle}
              onChange={this.handleChange}
              fullWidth
            />
            <TextFieldValidator
              type="text"
              validators={{required: true}}
              required
              name="employerName"
              label="Employer Name"
              value={vm.employerName}
              onChange={this.handleChange}
              fullWidth
              // autocompleteOptions={this.employerOptions}
            />
            <TextFieldValidator
              type="text"
              validators={{required: false}}
              name="employerLocation"
              label="Employer Location (City, State)"
              value={vm.employerLocation}
              onChange={this.handleChange}
              fullWidth
            />
            <FormGroup row classes={{row: classes.formGroupRow}}>
              <DatePickerValidator
                disableToolbar={false}
                variant="inline"
                format="MM/yyyy"
                placeholder="MM/yyyy"
                margin="none"
                fullWidth
                autoOk
                required
                id="startDate"
                label="Start Date *"
                name="startDate"
                value={vm.startDate}
                minDate={minStartDate}
                maxDate={maxStartDate}
                onChange={this.handleChange}
              />
              <FormGroupSpacer/>
              <DatePickerValidator
                disableToolbar={false}
                variant="inline"
                format="MM/yyyy"
                placeholder="MM/yyyy"
                margin="none"
                fullWidth
                autoOk
                id="endDate"
                label="End Date"
                name="endDate"
                value={vm.endDate}
                minDate={minEndDate}
                maxDate={maxEndDate}
                onChange={this.handleChange}
              />
            </FormGroup>

            <div className={classes.label}>Job Description</div>
            <TextareaAutosize
              name="jobDescription"
              value={vm.jobDescription}
              onChange={this.handleChange}
              minRows={5}
              rowsMax={10}
              className={classes.textarea}
            />
            <Box
              py={1}
              sx={{
                marginRight: 0
              }}
            >
              <FormControlLabel 
                control={
                  <Checkbox name="isProject" color="secondary" checked={vm.isProject} value="checkedG" onChange={this.handleChange}/>
                }
                label={
                  <Typography>
                    This is work experience related to a Federal or other government agency (e.g. NAVFAC, USACE, SBA, DHS, DOE, etc.)
                  </Typography>
                }
              />
            </Box>
            <Visible if={vm.isProject}>
              <div className={classes.optionalFields}>
                <TextFieldValidator
                  type="text"
                  margin="normal"
                  name="agencyType"
                  label="Agency Type"
                  autocompleteOptions={{
                    options: Object.values(AgencyType),
                    value: vm.agencyType,
                    onChange: (event: any, value: AgencyType, _reason: string) => {
                        vm.agencyType = value
                    }
                  }}
                />
                <TextFieldValidator
                  type="text"
                  validators={{required: false}}
                  // required={vm.isProject}
                  name="agencyName"
                  label="Agency Name"
                  fullWidth
                  helperText="Government agency or department name (e.g. NAVFAC, USACE)"
                  autocompleteOptions={{
                    freeSolo: true,
                    options: vm.agencyType === AgencyType.Federal ? Object.values(FederalAgency) : [],
                    value: vm.agencyName,
                    onChange: (event: any, value: string, _reason: string) => {
                      vm.agencyName = value
                    }
                  }}
                />
                <TextFieldValidator
                  type="text"
                  validators={{required: false}}
                  // required={vm.isProject}
                  name="projectName"
                  label="Project Name (optional)"
                  value={vm.projectName}
                  onChange={this.handleChange}
                  fullWidth
                />
                <TextFieldValidator
                  type="text"
                  validators={{required: false}}
                  // required={vm.isProject}
                  name="projectLocation"
                  label="Project Location (optional)"
                  value={vm.projectLocation}
                  onChange={this.handleChange}
                  fullWidth
                  helperText="Facility name, city, state"
                  // TODO: Fix bug with autocomplete and freeSolo option
                  // autocompleteOptions= {{
                  //   freeSolo: true,
                  //   options: resourceCache!.getLocationAliases(),
                    // getOptionLabel: (option: LocationOption) => option && option.name ? option.name : option ? option : "",
                    // getOptionSelected: (option: LocationOption, value: LocationOption) => option && option.name ? option.name === value.name : option ? option === value : false,
                    // groupBy: (option: LocationOption) => option.group,
                  // }}
                />
                <TextFieldValidator
                  type="text"
                  validators={{required: false}}
                  name="projectDollarAmount"
                  label="Project $ Amount (optional)"
                  value={vm.projectDollarAmount}
                  onChange={this.handleChange}
                  fullWidth
                />
              </div>
            </Visible>
          </DialogContent>
          <DialogActions>
            <Grid container>
              <Grid container xs={4} justifyContent="flex-start">
                {vm.id &&
                  <AddButton
                    text="Delete"
                    tracking="deleteWorkExperience" // for google analytics
                    buttonColor="primary"
                    buttonVariant="outlined"
                    icon="remove"
                    click={() => this.handleDelete(vm.id)}
                  />
                }
              </Grid>
              <Grid container xs={8} justifyContent="flex-end">
                <CancelButton onClick={this.handleClose} />
                <ProgressButton variant="contained" size="medium" color="secondary"
                                type="submit" className={classes.progressButton} processing={this.isProcessing}>
                  Save
                </ProgressButton>
              </Grid>
            </Grid>
          </DialogActions>
        </FormValidator>
      </Dialog>

    )
  }

  handleChange = (event: any) => {
    const name = event.target.name
    if (name === "isProject") {
      this.viewModel![name] = event.target.checked
      if (event.target.checked && (!this.viewModel!.agencyType || this.viewModel!.agencyType === AgencyType.None)) {
        // Default to Federal
        this.viewModel!.agencyType = AgencyType.Federal
      }
    } else {
      this.viewModel![name] = event.target.value
    }
  }

  handleAdd = (): void => {
    const { profile } = this.props

    if (profile) {
      const experience= new Experience({
        userId: profile.userId,
        profileId: profile.id,
        jobTitle: "",
        jobDescription: "",
        agencyType: "None",
        agencyName: "",
        employerName: "",
        employerLocation: "",
        startDate: "",
        endDate: "",
        projectNo: "",
        projectName: "",
        projectLocation: "",
        projectDollarAmount: 0,
      })

      this.viewModel = new ProfileWorkExperienceViewModel(experience)
      this.isFormOpen = true
    }
  }

  handleEdit = (experience: Experience): void => {
    if (this.props.profile) {
      this.viewModel = new ProfileWorkExperienceViewModel(experience)
      this.isFormOpen = true
    }
  }

  handleDelete = async (experienceId: string) => {
    const { profileStore, profile, onChange, notify } = this.props

    try {
      await profileStore!.deleteExperience(experienceId)
      const index = profile!.experiences.findIndex((e: Experience) => e.id === experienceId)
      if (index >= 0) {
        profile!.experiences.splice(index, 1)
      }
      this.loadExperiences()
      if (onChange) {
        onChange(profile!)
      }
      this.isProcessing = false
      this.handleClose()
    } catch (error) {
      notify!.show("error", getErrorMessage(error))
    }
  }

  handleClose = () => {
    this.isFormOpen = false
    if (this.props.onClose) {
      this.props.onClose()
    }
  }

  onSubmit = async () => {
    const {profile, profileStore, onChange, notify} = this.props
    const vm = this.viewModel!

    if (onChange) {
      this.isProcessing = true

      try {
        if (!vm.id) {
          const input: CreateExperienceInput = {
            userId: profile!.userId,
            profileId: profile!.id,
            employerName: vm.employerName,
            employerLocation: vm.employerLocation,
            jobTitle: vm.jobTitle,
            startDate: this.getISODateFromMonthYearString(vm.startDate),
            endDate: vm.endDate ? this.getISODateFromMonthYearString(vm.endDate, true) : null,
            jobDescription: vm.jobDescription,
            agencyType: vm.isProject ? vm.agencyType : AgencyType.None,
            agencyName: vm.isProject ? vm.agencyName : null,
            projectName: vm.isProject ? vm.projectName : null,
            projectLocation: vm.isProject ? vm.projectLocation : null,
            projectDollarAmount: vm.isProject ? moneyToNumberFormat(vm.projectDollarAmount, 0) : 0,
          }

          let experience = await profileStore?.createExperience(input)

          if (experience) {
            profile!.experiences.push(experience)
            this.loadExperiences()
            const update = await this.updateProfile()
            if (update) {
              onChange(update)
            } else {
              onChange(profile!)
            }
            this.isProcessing = false
            this.handleClose()
          }
        } else {
          const input: UpdateExperienceInput = {
            id: vm.id,
            employerName: vm.employerName,
            employerLocation: vm.employerLocation,
            jobTitle: vm.jobTitle,
            startDate: vm.startDate ? this.getISODateFromMonthYearString(vm.startDate) : null,
            endDate: vm.endDate ? this.getISODateFromMonthYearString(vm.endDate, true) : null,
            jobDescription: vm.jobDescription,
            agencyType: vm.isProject ? vm.agencyType : AgencyType.None,
            agencyName: vm.isProject ? vm.agencyName : null,
            projectName: vm.isProject ? vm.projectName : null,
            projectLocation: vm.isProject ? vm.projectLocation : null,
            projectDollarAmount: vm.isProject ? moneyToNumberFormat(vm.projectDollarAmount, 0) : 0,
          }

          let experience = await profileStore?.updateExperience(input)

          if (experience) {
            const index = profile!.experiences.findIndex((e: Experience) => e.id === vm.id)
            if (index >= 0) {
              profile!.experiences[index] = experience
            }
            this.loadExperiences()
            const update = await this.updateProfile()
            if (update) {
              onChange(update)
            } else {
              onChange(profile!)
            }
            this.isProcessing = false
            this.handleClose()
          }
        }
      } catch (error) {
        notify!.show("error", getErrorMessage(error))
        this.isProcessing = false
      }

      this.isProcessing = false
    }
  }

  updateProfile = async () : Promise<Profile | undefined> => {
    const { profile, profileStore } = this.props

    // Update profile if federal or total experience changed
    if (profile!.federalExperience !== this.fedYears || profile!.totalExperience !== this.totalYears) {
      const input: UpdateProfileInput = {
        id: profile!.id,
        federalExperience: this.fedYears,
        totalExperience: this.totalYears
      }
      const update = profileStore!.updateProfile(input)
      if (update) {
        return update
      }
    }

    return undefined
  }

  loadExperiences = () => {
    const { profile } = this.props

    if (!profile) {
      return
    }

    this.totalYears = 0
    this.fedYears = 0
    // let employers: string[] = []

    let experiences: Experience[] = []
    let totalRanges: DateRange[] = []
    let fedRanges: DateRange[] = []

    if (profile!.experiences && profile!.experiences.length > 0) {
      profile!.experiences.forEach((experience: Experience) => {
        experiences.push(experience)

        const startDate = (experience.startDate) ? new Date(experience.startDate) : null
        const endDate = (experience.endDate) ? new Date(experience.endDate) : new Date()
        if (startDate && !isNaN(startDate.getTime()) && !isNaN(endDate.getTime()) && startDate.getTime() < endDate.getTime()) {
          const dateRange = new DateRange(startDate, endDate)
          totalRanges.push(dateRange)
          if (experience.agencyType === AgencyType.Federal) {
            fedRanges.push(dateRange)
          }
        }
      })
      if (totalRanges.length > 0) {
        const totalMerged = mergeOverlappingDateRanges(totalRanges)
        const totalDays = totalMerged.reduce((acc: number, curr: DateRange): number => acc + differenceInDays(curr.end, curr.start), 0)
        this.totalYears = totalDays / 365.0
      } else {
        this.totalYears = 0
      }
      if (fedRanges.length > 0) {
        const fedMerged = mergeOverlappingDateRanges(fedRanges)
        const fedDays = fedMerged.reduce((acc: number, curr: DateRange): number => acc + differenceInDays(curr.end, curr.start), 0)
        this.fedYears = fedDays / 365.0
      }

      // Sort in reverse startDate order
      experiences.sort((a: Experience, b: Experience) => String(b.startDate).localeCompare(String(a.startDate)))
    }

    this.experiences = experiences
  }

  getISODateFromMonthYearString(input: string | null | undefined, monthEnd: boolean = false) {
    if (input) {
      let date = parse(input, "MM/yyyy", new Date())
      if (monthEnd) {
        date = endOfMonth(date)
        date.setMinutes(-date.getTimezoneOffset())
      }
      return date.toISOString().substr(0, 10)
    } else {
      return ""
    }
  }

  getDateFromMonthYearString(input: string | null | undefined, monthEnd: boolean = false) {
    if (input) {
      let date = parse(input, "MM/yyyy", new Date())
      if (monthEnd) {
        date = endOfMonth(date)
      }
      return date
    } else {
      return undefined
    }
  }
}

export default withTheme(withStyles(styles)(withWidth()(ProfileWorkExperience)))
