import {
  Checkbox,
  createStyles,
  FormControlLabel,
  Grid,
  Theme,
  Typography,
  withStyles,
  WithStyles,
  WithTheme,
  withTheme,
  withWidth,
  WithWidth
} from "@material-ui/core";
import { makeObservable, observable, when } from 'mobx';
import { inject, observer } from "mobx-react";
import React from "react";
import Notify from "../../../components/notify/Notify";
import Profile from "../../../model/Profile";
import ProfileStore from "../../../stores/ProfileStore";
import { getErrorMessage } from "../../../stores/StoreUtilities";
import UserStore from "../../../stores/UserStore";
import FilePreviewWithDialog from "../../../components/file/FilePreviewWithDialog";
import { IFileStorageLevel } from "../../../components/file/FileDialog";

const styles = (theme: Theme) => createStyles({
  content: {
    display: "flex",
    flexDirection: "row"
  },
  resumeItem: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
  },
  titleBar: {
    display: "flex",
    flexDirection: "row",
    paddingRight: 216
  },
  aboutDiv: {
    flexGrow: 1,
    paddingRight: theme.spacing(2)
  },
  resumeDiv: {
    flexGrow: 0,
    width: 200,
    minWidth: 200,
    textAlign: "right"
  },
  docPreview: {
    position: "relative",
    top: -24
  },
  textarea: {
    fontFamily: [
      'Roboto',
      'sans-serif'
    ].join(','),
    minWidth: "100%",
    maxWidth: "100%",
    padding: 8,
    fontSize: 16
  },
})

export interface IUploadResumeDialogProps {
  profile: Profile
  onChange?: (profile: Profile) => any
  // children?: DocPreviewChildren
  buttonText?: string 
  buttonTracking?: string 

  userStore?: UserStore
  profileStore?: ProfileStore
  notify?: Notify
}

@inject("userStore", "profileStore", 'notify')
@observer
class UploadResumeDialog extends React.Component<WithStyles<typeof styles> & WithTheme & WithWidth & IUploadResumeDialogProps> {

  @observable shouldAutofillProfile: boolean = false
  @observable isDialogOpen: boolean = false 

  constructor(props: WithStyles<typeof styles> & WithTheme & WithWidth & IUploadResumeDialogProps) {
    super(props);
    makeObservable(this);
  }

  componentDidMount() {
    when(
      () => Boolean(this.props.profileStore && this.props.profile),
      () => {
        this.shouldAutofillProfile = this.props.profile!.isEmpty
      }
    )
  }

  _handleSaveResume = async (resumeKey: string) => {
    const { profile, profileStore, onChange, notify } = this.props
    try {
      await this._handleDeleteResume()
      let updatedProfile
      if (this.shouldAutofillProfile) {
        // update profile with resume data
        updatedProfile = await profileStore!.updateProfileForResume(profile!.id, resumeKey)
      } else {
        // just updated the resume key
        updatedProfile = await profileStore!.updateProfile({ id: profile!.id, resumeKey })
      }
      if (onChange && updatedProfile) {
        onChange(updatedProfile)
      }
    } catch (err) {
      notify!.show('error', getErrorMessage(err))
    }
  }

  _handleDeleteResume = async (key?: string, fileUrl?: string) => {
    const { profile, notify, onChange } = this.props
    try {
      const updatedProfile = await this.props.userStore!.updateProfile({
        id: profile!.id,
        resumeKey: null,
      })
      if (onChange) {
        onChange(updatedProfile!)
      }
    } catch (err) {
      notify!.show('error', getErrorMessage(err))
    }
  }

  fileFolder = () => {
    const { profile } = this.props
    return `users/${profile?.userId}/profile`
  }

  render() {
    const { profile, buttonText, buttonTracking } = this.props

    if (!profile) {
      return null
    }

    const level: IFileStorageLevel = "public"

    return (
      <FilePreviewWithDialog
        buttonText={buttonText}
        buttonTracking={buttonTracking}
        onClickOpenDialog={() => {
          this.isDialogOpen = true 
        }}
        filePreviewProps={{
          level: level,
          fileKey: profile.resumeKey ?? null,
          emptyLabel: "Add a Resume", 
          label: "View Resume",
          onClickEmpty: () => { this.isDialogOpen = true }
        }}
        fileDialogProps={{
          isLoading: false,
          fileKey: profile.resumeKey ?? null, 
          title: 'Add Resume',
          uploadButtonText: 'Upload',
          open: this.isDialogOpen,
          onSaveWithFile: this._handleSaveResume,
          onDeleteFile: this._handleDeleteResume,
          onSaveDialog: async () => {
            if (profile.resumeKey) {
              await this._handleSaveResume(profile.resumeKey)
            }
          },
          onClose: () => { this.isDialogOpen = false }, 
          keyGenerator: (file?: File, ext?: string) => `${this.fileFolder()}/resume${ext!}`,
          fileNameGetter: () => {
            if (profile.resumeKey) {
              const chunks = profile.resumeKey.split(`${this.fileFolder()}/`)
              return chunks[chunks.length - 1]
            }
            return null 
          },
          level: level,
          // description: this.renderFileDialogDescription
          dropzoneText: 'Click here to browse for a Microsoft Doc or PDF, or you can drag and drop it here.',
          acceptedFileTypesText: 'Upload file (.docx or .pdf)', 
          children: (
            <Grid container direction="column">
              <Grid item>
                <Typography paragraph>
                  Check the box labeled "Autofill profile from resume" when uploading your resume and the system will attempt fill in the details of your profile. 
                </Typography>
                <Typography paragraph>
                  Please manually check for any mistakes, as your resume may not parse completely accurately. 
                </Typography>
              </Grid>
              <Grid item>
                <FormControlLabel
                  label={(
                    <Typography
                      color="textPrimary"
                    >
                      Autofill profile from resume
                      {!profile.isEmpty && <span> (profile must be empty)</span>}
                    </Typography>
                  )}
                  disabled={!profile.isEmpty}
                  control={
                    <Checkbox
                      checked={this.shouldAutofillProfile}
                      onChange={({ target: { checked } }) => this.shouldAutofillProfile = checked}
                    />
                  }
                />
              </Grid>
            </Grid>
          ),
        }}
      />
    )
  }

}

export default withTheme(withStyles(styles)(withWidth()(UploadResumeDialog)))
