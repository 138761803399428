import { observable, makeObservable } from "mobx";
import axios from "axios"
import Logger from "../Logger";
import * as config from 'react-global-configuration';
import * as React from "react";
import {Link} from "@reach/router";

export type NotifyVariant =
  | "success"
  | "info"
  | "warning"
  | "error";

class Notify {
  @observable open: boolean = false
  @observable variant: NotifyVariant = "success"
  @observable message: string = ''

  onClose: any
  interval: any
  
  constructor() {
    makeObservable(this);
  }

  show = (variant: NotifyVariant, message: any, onClose?: any) => {
    this.open = true
    this.variant = variant
    this.message = message
    this.onClose = onClose
  }

  close = () => {
    this.open = false
    if (this.onClose) {
      this.onClose()
    }
  }

  onClickReload = () => {
    this.close()
    window.location.reload()
  }


  checkForNewBuild = () => {
    console.log("checkForNewBuild")
    if (this.open) {
      // Don't load on top of another notification
      return
    }
    const notify = this
    const url = `/build.txt`
    axios.get(url)
      .then(function (response) {
        if (response && response.data && response.data.length > 0)
        {
          const currentBuild = config.get("build")
          const newBuild = response.data.replace("\n", "").trim()
          if (newBuild && newBuild.length > 0 && currentBuild && currentBuild.length > 0 &&
            newBuild > currentBuild) {
            const message = <span>
            An updated version is available.&nbsp;&nbsp;
              <Link to="." onClick={notify.onClickReload}>Click to reload.</Link>
          </span>;
            notify.show("warning", message)
          }
        }
      })
      .catch(function (error) {
        Logger.debug("Error getting build", error);
        if (error.response && error.response.status === 404) {
          // Stop waiting if no build file is found
          clearInterval(notify.interval)
        }
      })

    if (!this.interval) {
      // Set a recurring interval
      const checkBuildInterval = config.get("checkBuildInterval")
      if (checkBuildInterval) {
        this.interval = setInterval(this.checkForNewBuild, checkBuildInterval)
      }
    }
  }
}

export default Notify