import { observable, makeObservable } from "mobx";

type ConfirmCallback = () => boolean | Promise<boolean>
type CancelCallback = () => void

class Confirm {
  @observable open: boolean = false
  @observable title: string = ''
  @observable content: any
  @observable buttons?: string[]
  @observable isProcessing: boolean = false 

  onConfirm?: ConfirmCallback
  onCancel?: CancelCallback

  show = (title: string, content: any, buttons?: string[], onConfirm?: ConfirmCallback, onCancel?: CancelCallback) => {
    this.open = true
    this.title = title
    this.content = content
    this.onCancel = onCancel
    this.onConfirm = onConfirm
    this.buttons = buttons
  }

  confirm = () => {
    if (this.onConfirm) {
      const result = this.onConfirm()
      if (typeof result === 'boolean') {
        this.open = !result
      } else if (result instanceof Promise) {
        this.isProcessing = true; 
        (result as Promise<boolean>).then((confirmed:boolean) => {
          this.isProcessing = false 
          this.open = !confirmed
        })
      } 
    }
  }

  cancel = () => {
    this.open = false
    if (this.onCancel) {
      this.onCancel()
    }
  }

  constructor() {
    makeObservable(this);
  }
}

export default Confirm